import React, { useState } from "react";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, Button, Label } from "ui-components";
import { ChevronDown, ChevronUp } from "components/Icons";
import { iconThemes } from "ui-components/theme";
import get from "lodash/get";
import { scrollToDomNode } from "utils/helpers";
import each from "lodash/each";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

/**
 * The component is to provide expand/collapse esim supported devices
 * All the devices and tbalets that are esim supported are provided through application configs.
 * @param {Object} props
 */
const ESimCompatibleList = props => {
  const {
    title,
    expandDefault = false,
    themeColor,
    supportedPhones = [],
    supportedPhonesByGroup = [],
    noteOnPhones,
    phonesLabel,
    tabletsLabel,
    supportedTablets = [],
    noteOnTablets,
    headerMessage,
    showLearnMore = false,
    learnMore,
    showHR = false,
    containerStyles = { mb: 8 }
  } = props.data;
  const [showDetails, setShowDetails] = useState(expandDefault);
  const onClick = () => {
    setShowDetails(!showDetails);
  };
  const ctaColor =
    get(themeColor, "primary") ||
    get(props.themeColors, "cta", iconThemes.blue);
  const scrollToFaq = () => {
    let node = document.getElementById("page-faqs");
    scrollToDomNode(node);
  };

  const hasAtLeastOnePhoneInGroup = () => {
    let totalPhoneCount = 0;

    each(supportedPhonesByGroup, phoneData => {
      totalPhoneCount += get(phoneData, "list", []).length;
    });

    return totalPhoneCount ? true : false;
  };

  const renderSupportedPhones = () => {
    return (
      <div>
        {phonesLabel && (
          <FormattedMessage html id={phonesLabel}>
            {phonesText => <Label fontWeight="bold">{phonesText}</Label>}
          </FormattedMessage>
        )}
        <ul>
          {map(supportedPhones, (phone, index) => (
            <li key={index} style={{ padding: "0.25rem" }}>
              <FormattedMessage html id={phone} />
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderSupportedPhonesByGroup = () => {
    return map(supportedPhonesByGroup, (phoneData, index) => {
      return (
        <div key={index}>
          {get(phoneData, "label") && (
            <FormattedMessage html id={get(phoneData, "label")}>
              {label => (
                <Label fontWeight="bold" my={6}>
                  {label}
                </Label>
              )}
            </FormattedMessage>
          )}
          <ul>
            {map(phoneData?.list, (phone, index) => {
              return (
                <li key={index} style={{ padding: "0.25rem" }}>
                  {phone}
                </li>
              );
            })}
          </ul>
        </div>
      );
    });
  };

  return (
    <Box sx={containerStyles}>
      <Flex flexDirection="column" alignItems="start" width="100%">
        {headerMessage && (
          <Box mt={2}>
            <FormattedMessage html id={headerMessage} />
          </Box>
        )}
        {showLearnMore ? (
          <Box mt={2} mb={8}>
            <FormattedMessage html id={learnMore}>
              {msg => (
                <div>
                  <button
                    style={{
                      color: ctaColor,
                      fontWeight: "bold",
                      cursor: "pointer"
                    }}
                    onClick={scrollToFaq}
                  >
                    {msg}
                  </button>
                </div>
              )}
            </FormattedMessage>
          </Box>
        ) : null}
      </Flex>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        onClick={onClick}
      >
        <FormattedMessage html id={title}>
          {msg => (
            <span style={{ color: ctaColor, fontWeight: "bold" }}>{msg}</span>
          )}
        </FormattedMessage>
        <Button variant="closeButton" as="a">
          {showDetails ? (
            <ChevronUp size={"30px"} color={ctaColor} />
          ) : (
            <ChevronDown size={"30px"} color={ctaColor} />
          )}
        </Button>
      </Flex>
      {showDetails && (
        <>
          <Box pt={6} pl={4}>
            {!isEmpty(supportedPhones) && renderSupportedPhones()}
            {hasAtLeastOnePhoneInGroup() && renderSupportedPhonesByGroup()}

            {(!isEmpty(supportedPhones) || hasAtLeastOnePhoneInGroup()) &&
              noteOnPhones && (
                <Label sx={{ fontWeight: "normal !important" }} my={4}>
                  <FormattedMessage html id={noteOnPhones} />
                </Label>
              )}
          </Box>
          {(tabletsLabel || !isEmpty(supportedTablets)) && (
            <Box pt={6} pb={6} pl={4}>
              {tabletsLabel && (
                <FormattedMessage html id={tabletsLabel}>
                  {tabletsText => (
                    <Label fontWeight="bold">{tabletsText}</Label>
                  )}
                </FormattedMessage>
              )}
              <ul>
                {map(supportedTablets, (tablet, index) => (
                  <li key={index} style={{ padding: "0.25rem" }}>
                    <FormattedMessage html id={tablet} />
                  </li>
                ))}
              </ul>
              {!isEmpty(supportedTablets) && noteOnTablets && (
                <FormattedMessage html id={noteOnTablets}>
                  {noteForTablets => (
                    <Label sx={{ fontWeight: "normal !important" }} my={4}>
                      {noteForTablets}
                    </Label>
                  )}
                </FormattedMessage>
              )}
            </Box>
          )}
        </>
      )}
      {showHR ? <hr style={{ padding: "1rem" }} /> : null}
    </Box>
  );
};

export default ESimCompatibleList;
