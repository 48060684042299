const styles = {
  wrapper: { mb: 8, textAlign: "center" },
  title: { mb: 6, fontWeight: "700", fontFamily: "Roboto" },
  number: {
    fontFamily: "ROBOTO",
    fontWeight: "500",
    fontSize: [32, 34, 36, 38],
    mb: 4
  },
  price: {
    fontFamily: "ROBOTO",
    fontWeight: "500",
    fontSize: "1.2rem",
    mb: 4
  },
  specialMessage: {
    width: "fit-content",
    margin: "auto",
    py: "0.5rem",
    px: ["0.5rem", "1rem"],
    border: "2px solid #0000FF",
    borderRadius: "4px",
    boxShadow: "4px 4px 0px 0px rgba(0,0,255,1)",
    fontSize: ["0.8rem"],
    fontWeight: "bold"
  }
};

export default styles;
