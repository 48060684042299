import React from "react";
import { Box } from "ui-components";
import PayItemCard from "components/PayItemCard";

const PAY_MONTHLY_CARD = ({
  payMonthlyItems,
  data: { append = [], ...rest }
}) => {
  let items = [...payMonthlyItems.items, ...append];

  return (
    <Box mb={6}>
      <PayItemCard
        {...payMonthlyItems}
        items={items}
        title={"pay_monthly"}
        {...rest}
      />
    </Box>
  );
};

export default PAY_MONTHLY_CARD;
