import { isAndroid, isIOS } from "../helpers";
import { v4 as uuid } from "uuid";

/**
 * NativeAppFunctions is like a web bridge that can be used to
 * get or pass the data between native App (IOS/Android ) to web-front side
 * Calling methods should be implemented in Native App Side
 */

export const APP_BRIDGE_TAG = "POVO_BRIDGE";

export const DEVICE_ESIM = {
  SUPPORTED: "SUPPORTED",
  UNSUPPORTED: "UNSUPPORTED",
  CANNOT_IDENTIFY: "CANNOT_IDENTIFY"
};

const promisesMap = new Map();

const getDeviceESIMCompatibilityStatus = (deviceESimCompatible = {}) => {
  const { esimCompatible, nativeVerification } = deviceESimCompatible;

  if (nativeVerification) {
    return esimCompatible ? DEVICE_ESIM.SUPPORTED : DEVICE_ESIM.UNSUPPORTED;
  } else {
    return DEVICE_ESIM.CANNOT_IDENTIFY;
  }
};

/*
 * call by ios app with promise id and data
 * */
// eslint-disable-next-line no-unused-vars
function setIsDeviceEsimCompatible(promiseId, data) {
  if (promiseId && data && promisesMap.get(promiseId)?.resolve) {
    console.debug(APP_BRIDGE_TAG, "Response got from IOS device ->> " + data);
    const deviceESimCompatibleStatus = getDeviceESIMCompatibilityStatus(data);
    promisesMap.get(promiseId).resolve(deviceESimCompatibleStatus);
  }
  // remove reference to stored promise
  promisesMap.delete(promiseId);
}

/**
 * call ios native function to get esim compatibility
 * IOS app will call the 'setIsDeviceEsimCompatible' function
 *  @returns {Promise}
 */
function getESimSupportAsyncIos() {
  return new Promise(function(resolve, reject) {
    const promiseId = uuid();
    promisesMap.set(promiseId, { resolve, reject });

    console.debug(
      APP_BRIDGE_TAG,
      "Called IOS web bridge with promise id --" + promiseId
    );
    try {
      window.webkit.messageHandlers.deviceEsimCompatibleHandler.postMessage({
        promiseId: promiseId
      });
    } catch (exception) {
      reject("Error occur when calling ios native function");
    }
  });
}

/**
 * call android native function to get esim compatibility
 * POVO_ANDROID_WEB_BRIDGE -  Android bridge name
 * isDeviceEsimCompatible - function defined in Android native app
 * @returns {Promise}
 */
function getESimSupportAsyncAndroid() {
  return new Promise((resolve, reject) => {
    try {
      if (window.POVO_ANDROID_WEB_BRIDGE) {
        console.debug(APP_BRIDGE_TAG, "Web bridge already initialized");
        //calling android native function
        const deviceESimDetails = window.POVO_ANDROID_WEB_BRIDGE.isDeviceEsimCompatible();

        if (deviceESimDetails) {
          console.debug(
            APP_BRIDGE_TAG,
            "Response got from android ->> ",
            JSON.parse(deviceESimDetails)
          );
          const deviceESimCompatibleStatus = getDeviceESIMCompatibilityStatus(
            JSON.parse(deviceESimDetails)
          );
          resolve(deviceESimCompatibleStatus);
        } else {
          reject("DeviceESimDetails are undefined");
        }
      } else {
        reject("Android bridge have not been initialized");
      }
    } catch (e) {
      reject("Error occur when calling android native function");
    }
  });
}

/**
 * This function use to get device e-sim  compatibility status from Native app
 * @returns {Promise}
 */
const getIsESimCompatible = () => {
  if (isAndroid()) {
    return getESimSupportAsyncAndroid();
  } else if (isIOS()) {
    return getESimSupportAsyncIos();
  } else {
    return new Promise((resolve, reject) => {
      reject("Device is not supported");
    });
  }
};

/**
 * call android native function to request download esim profile
 * autoActivateESim - function defined in Android native app
 */
function downloadEsimProfileOnAndroid(activationCode) {
  try {
    if (window.POVO_ANDROID_WEB_BRIDGE) {
      console.debug(APP_BRIDGE_TAG, "Web bridge already initialized");
      console.debug(APP_BRIDGE_TAG, " -- activationCode --" + activationCode);
      //calling android native function
      window.POVO_ANDROID_WEB_BRIDGE.autoActivateESim(activationCode);
    } else {
      console.debug("Android bridge have not been initialized");
    }
  } catch (e) {
    console.debug(
      "Error occur when calling autoActivateESim android native function -" +
        e.message()
    );
  }
}

/**
 * call android native function to request download esim profile
 */
function downloadEsimProfileOnIos(smdpUrl, macthingID) {
  try {
    console.debug(APP_BRIDGE_TAG + " -- smdpUrl --", smdpUrl);
    console.debug(APP_BRIDGE_TAG + " -- macthingID --", macthingID);
    window.webkit.messageHandlers.autoActivateESimHandler.postMessage({
      smdpUrl: smdpUrl,
      macthingID: macthingID
    });
  } catch (e) {
    console.debug(
      "Error occur when calling autoActivateESimHandler android native function -" +
        e.message()
    );
  }
}

/**
 * This function use to request download esim profile to device
 */
const requestDownloadEsimProfile = ({
  smdpUrl,
  macthingID,
  androidActivationCode
}) => {
  if (isAndroid()) {
    return downloadEsimProfileOnAndroid(androidActivationCode);
  } else if (isIOS()) {
    return downloadEsimProfileOnIos(smdpUrl, macthingID);
  }
};

export const NativeAppFunctions = {
  getIsESimCompatible,
  requestDownloadEsimProfile
};

window.setIsDeviceEsimCompatible = setIsDeviceEsimCompatible;
