import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import { createSelector } from "reselect";
import { validateIccIdSelector } from "./validateIccId";

export const eligiblePlansDomain = state =>
  get(state, "api.eligiblePlans.data", {});

export const eligiblePlansChannel = state =>
  get(state, "api.eligiblePlans", {});

// ***************************************************************
// ******************** eligiblePlans selectors ********************
// ***************************************************************
export const eligiblePlansSelector = createSelector(
  eligiblePlansDomain,
  validateIccIdSelector,
  (eligiblePlans, iccIdDetails) => {
    const iccIdPlanName = get(iccIdDetails, "plan.name");
    const iccIdPlan = find(eligiblePlans, { name: iccIdPlanName });
    // treat only sim plan as eligible it is valid
    if (!isEmpty(iccIdPlan)) return [iccIdPlan];
    return eligiblePlans;
  }
);

export const makeSelectEligibleToppings = () =>
  createSelector(
    eligiblePlansDomain,
    eligiblePlans => eligiblePlans[0]?.eligible_toppings,
    []
  );

export const makeSelectEligiblePlanRequestChannel = () =>
  createSelector(
    eligiblePlansChannel,
    eligibleChannels => eligibleChannels.params?.channel || ""
  );

// ***************************************************************
// ******************* eligiblePlans makeSelect ********************
// ***************************************************************

export const makeSelectEligiblePlans = () => eligiblePlansSelector;
