import isFunction from "lodash/isFunction";
import isEmpty from "lodash/isEmpty";
import AnalyticsFactory from "./AnalyticsFactory";
import get from "lodash/get";
import keys from "lodash/keys";
import find from "lodash/find";
import includes from "lodash/includes";
import { forms } from "utils/constants";
import head from "lodash/head";
import { emailRegex } from "validations";
import { emailWithoutPlusSignRegex } from "validations";
import { getChannelFromUrl } from "utils/helpers";

const FIELD_BLURRED = "field_blurred";
const PUSH_PROFILE_EVENTS = [
  "field_blurred",
  "SelfActivationForm_submitted",
  "loggedInUserDetails_api_call_succeeded",
  "postTerminateNumberRequest_api_call_succeeded",
  "outstandingBillPayments_api_call_succeeded"
];
const BLUR_MONITORED_FIELDS = ["email", "contact_number"];

const SET_SUBMIT = "submitted";
const SET_SUBMIT_SUCCEEDED_FORMS = [
  forms.PERSONAL_DETAILS_FORM,
  forms.DELIVERY_INFO_FORM,
  forms.PORT_IN_FORM,
  forms.PRE_CHECKOUT_FORM,
  forms.SELF_ACTIVATION_FORM,
  forms.PHONES_FORM
];

function getSuccessStatus(key, data) {
  switch (key) {
    case "paymentStatus":
    case "paymentReturn": {
      const statusValue = get(data, "payment_status", "");
      return statusValue.toLocaleLowerCase() === "success";
    }
    default: {
      return true;
    }
  }
}

let reduxStore = {};

/**
 * This is called saga file where saga fill invoke handleAnalytics on every action
 *
 * @param {Object} action
 */
export const handleAnalytics = action => {
  const { type } = action;
  const handler = analyticsConstructor[type];
  if (handler && isFunction(handler)) {
    const eventData = handler(action, reduxStore);
    if (!isEmpty(eventData)) {
      const { event, data, message } = eventData;
      if (
        event === "postTerminateNumberRequest_api_call_succeeded" ||
        event === "requestMNPReservationNumber_api_call_succeeded"
      ) {
        handleTerminationEmailConsent(event, data);
      }

      if (includes(PUSH_PROFILE_EVENTS, event)) {
        AnalyticsFactory.pushProfile(event, data, message);
      }
      AnalyticsFactory.pushEvent(event, data, message);
    }
  }
};

const handleTerminationEmailConsent = (event, data) => {
  let terminationEmailConcent = null;
  if (event === "postTerminateNumberRequest_api_call_succeeded") {
    terminationEmailConcent = getStateData(
      reduxStore,
      "form.TerminateNumberForm.values.permission_terms_and_conditions_agreed",
      null
    );
  } else if (event === "requestMNPReservationNumber_api_call_succeeded") {
    terminationEmailConcent = getStateData(
      reduxStore,
      "form.RequestPortOutForm.values.permission_terms_and_conditions_agreed",
      null
    );
  }

  if (terminationEmailConcent !== null) {
    data.terminationEmailConcent = terminationEmailConcent;
  }

  const { forms } = data;
  const { ReportLostSimForm, ...otherforms } = forms;
  data.forms = otherforms;

  window.clevertap.profile.push({
    Site: {
      permission_terms_and_conditions_agreed: terminationEmailConcent
    }
  });
};

/**
 * handleAnalytics method will pick the correct action from the analyticsConstructor and returns event name and data object.
 *
 * @returns {{ event: String, data: Object }}
 */
const analyticsConstructor = {
  FETCH_REQUESTED: ({ payload = {} }) => {
    const { key, params = {} } = payload;
    return {
      event: `${key}_api_call_requested`,
      data: params
    };
  },
  FETCH_SUCCEEDED: ({ payload = {} }) => {
    const { key, data } = payload;
    const planService = getStateData(reduxStore, "api.planService.data", {});
    const terminationEmailConcent = getStateData(
      reduxStore,
      "form.TerminateNumberForm.values.permission_terms_and_conditions_agreed",
      null
    );
    const consents = {};
    if (terminationEmailConcent !== null) {
      consents["promotionalEmailConcent"] = terminationEmailConcent;
    }

    const plan_name = planService?.name || getChannelFromUrl();

    if (!getSuccessStatus(key, data)) return {};

    const userOrder =
      head(getStateData(reduxStore, "api.userOrders.data")) || {};
    const orderParams = getStateData(reduxStore, "api.order.params");
    const order = getStateData(reduxStore, "api.order.data");
    const referral_code = getStateData(
      reduxStore,
      "api.referralCode.data.referral_code",
      ""
    );
    const referral_code_type = getStateData(
      reduxStore,
      "api.referralCode.data.code_type",
      ""
    );
    const forms = getStateData(reduxStore, "form", {});
    const currency = getStateData(
      reduxStore,
      "api.appSettings.data.currency.currency_code",
      ""
    );
    return {
      event: `${key}_api_call_succeeded`,
      data: {
        planService,
        plan_name,
        referral_code,
        referral_code_type,
        userOrder,
        forms,
        order,
        orderParams,
        currency,
        ...consents,
        ...data
      }
    };
  },
  FETCH_FAILED: ({ payload = {} }) => {
    const { key, error } = payload;
    return {
      event: `${key}_api_call_failed`,
      data: { error }
    };
  },
  "@@redux-form/BLUR": ({ payload, meta = {} }) => {
    const { form, field } = meta;
    if (BLUR_MONITORED_FIELDS.includes(field) && payload) {
      const formValues = getStateData(reduxStore, `form.${form}.values`, {});
      const loggedInUserEmail = getStateData(
        reduxStore,
        "api.loggedInUserDetails.data.email",
        ""
      );
      const referralCodeData = getStateData(
        reduxStore,
        "api.referralCode.data",
        {}
      );

      if (loggedInUserEmail) {
        formValues["loggedInUserEmail"] = loggedInUserEmail;
      }

      if (!isEmpty(referralCodeData)) {
        formValues["referral_code_type"] = referralCodeData?.code_type || "";
        formValues["referral_code"] = referralCodeData?.referral_code || "";
      }

      // making sure only valid emails are sent as events
      if (
        field === "email" &&
        !isValidEmail(formValues.email) &&
        !isEmailWithouthPlusSign(formValues.email)
      ) {
        return;
      }

      formValues[field] = payload;
      return {
        event: FIELD_BLURRED,
        data: { form, field, payload, formValues }
      };
    }
  },
  "@@redux-form/SET_SUBMIT_SUCCEEDED": ({ type, meta = {} }) => {
    const { form } = meta;
    const formValues = getStateData(reduxStore, `form.${form}.values`, {});
    if (SET_SUBMIT_SUCCEEDED_FORMS.includes(form)) {
      return {
        event: `${form}_${SET_SUBMIT}`,
        data: { form, formValues }
      };
    }
  },
  SET_DATA: ({ payload = {} }, reduxStore) => {
    const { key, data } = payload;
    const selectedAddon = keys(data)[0];
    const additionalProducts = getStateData(
      reduxStore,
      "api.planService.data.additional_products"
    );
    const addon = find(additionalProducts, { id: selectedAddon });
    return {
      event: `${key}_requested`,
      data: { addon, selected: data?.[selectedAddon] }
    };
  }
};

export const currencyCode = () => {
  return getStateData(
    reduxStore,
    "api.appSettings.data.currency.currency_code"
  );
};

export const initPushNotification = store => {
  try {
    reduxStore = store;
    const stateAPI = getStateData(store, "api");
    const notificationDelay = get(
      stateAPI,
      "appSettings.data.plugins.clevertap.data.notifications.delay",
      3000
    );

    const translations = get(stateAPI, "locale.data", {});
    setTimeout(() => {
      if (!window.clevertapDisabled && window.clevertap) {
        window.clevertap.notifications.push({
          titleText: translations.clevertap_webPushTitleText,
          bodyText: translations.clevertap_webPushBodyText,
          okButtonText: translations.clevertap_webPushOkButtonText,
          rejectButtonText: translations.clevertap_webPushRejectButtonText,
          okButtonColor: "#f28046",
          hidePoweredByCT: true
        });
        console.log("CleverTap notification initialized");
      } else {
        console.log("CleverTap is undefined. Skipping push notification");
      }
    }, Number(notificationDelay));
  } catch (e) {
    console.error(
      "An error encountered during clevertap notifications initialization"
    );
    console.error(e);
  }
};

const getStateData = (store, path, defaultValue) => {
  let state = {};
  if (isFunction(store.getState)) {
    state = store.getState();
  }
  return get(state, path, defaultValue);
};

const isValidEmail = value => {
  return new RegExp(emailRegex, "i").test(value);
};

const isEmailWithouthPlusSign = value => {
  return new RegExp(emailWithoutPlusSignRegex, "i").test(value);
};
