import DELIVERY_ADDRESS_FORM_3 from "smart-components/DELIVERY_ADDRESS_FORM_3/index";

const BILLING_ADDRESS_FORM_3 = props => {
  return DELIVERY_ADDRESS_FORM_3({
    ...props,
    fieldPrefix: "billing",
    title: "personal_details_billing_header"
  });
};

export default BILLING_ADDRESS_FORM_3;
