import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import SmartRender from "containers/SmartRender";
import { createStructuredSelector } from "reselect";
import {
  activateZendeskWidget,
  getChannelFromUrl,
  gotoUrl,
  isWebView
} from "utils/helpers";
import Spinner, { styles } from "components/Spinner";
import { reduxForm } from "redux-form";
import { forms, paths } from "utils/constants";
import validate from "validations";
import {
  makeSelectGuestOrder,
  makeSelectPageValidations,
  makeSelectPlanDetails,
  makeSelectzEIdentifyUserData
} from "ducks/selectors";
import {
  makeSelectPanChangeDetailsForm,
  makeSelectPanChangeKycOption,
  makeSelectPlanChangePayload
} from "ducks/selectors/forms/palnChangeDetails";
import {
  APP_BRIDGE_TAG,
  DEVICE_ESIM,
  NativeAppFunctions
} from "utils/app-bridge/NativeAppDetails";
import { submitReplaceSimToTelco, UserService } from "ducks/actions";
import isEmpty from "lodash/isEmpty";

const LIQUID = "LIQUID";

const PlanChangeHOC = Component =>
  function Comp(props) {
    const { order } = props;
    const [deviceESimStatus, setDeviceESimStatus] = useState(
      DEVICE_ESIM.CANNOT_IDENTIFY
    );
    const [isDetectingESimStatus, setIsDetectingESimStatus] = useState(true);

    useEffect(() => {
      if (isWebView()) {
        NativeAppFunctions.getIsESimCompatible()
          .then(eSIMStatus => {
            setDeviceESimStatus(eSIMStatus);
            setIsDetectingESimStatus(false);
          })
          .catch(error => {
            setDeviceESimStatus(DEVICE_ESIM.CANNOT_IDENTIFY);
            setIsDetectingESimStatus(false);
            console.debug(APP_BRIDGE_TAG, error);
          });
      } else {
        setDeviceESimStatus(DEVICE_ESIM.CANNOT_IDENTIFY);
        setIsDetectingESimStatus(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isDetectingESimStatus ? (
      <Spinner size={200} sx={styles.fullPage} />
    ) : (
      <Component
        {...props}
        {...order}
        eSimCompatibilityStatus={deviceESimStatus}
        toggleSwitchRefresh={isDetectingESimStatus}
      />
    );
  };

const mapStateToProps = createStructuredSelector({
  formValidations: makeSelectPageValidations(forms.PLAN_CHANGE_DETAILS_FORM),
  formValues: makeSelectPanChangeDetailsForm(),
  planDetails: makeSelectPlanDetails(),
  payload: makeSelectPlanChangePayload(),
  order: makeSelectGuestOrder(),
  zEIdentify: makeSelectzEIdentifyUserData(),
  selectedKycOption: makeSelectPanChangeKycOption()
});

export function mapDispatchToProps(dispatch) {
  return {
    submitSimConfigToTelco: (headers, payload, callback) => {
      dispatch(submitReplaceSimToTelco(headers, payload, callback));
    },
    fetchLiquidUrl: (bodyParams, onSuccess) => {
      dispatch(UserService.V4.fetchLiquidUrl(bodyParams, onSuccess));
    }
  };
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  submitSimConfigToTelco: () => {
    const headers = {};
    dispatchProps.submitSimConfigToTelco(
      headers,
      stateProps.payload,
      (_, error, status) => {
        if (isEmpty(error) || status === 409) {
          if (stateProps.selectedKycOption === LIQUID) {
            gotoUrl(
              paths.PLAN_CHANGED_SIM_STATUS.replace(
                ":channel",
                getChannelFromUrl()
              )
            );
          } else {
            // open chat
            activateZendeskWidget(stateProps.zEIdentify);
            // goto manage order with kycChat=true
            gotoUrl(paths.MANAGE_ORDER, true, { kycChat: true });
          }
        } else {
          console.error("Error on submitPlanChange callback", error);
        }
      }
    );
  }
});

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export default compose(
  withConnect,
  reduxForm({
    form: forms.PLAN_CHANGE_DETAILS_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(PlanChangeHOC(SmartRender));
