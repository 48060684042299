import Analytics from "../Analytics";
import Session from "session";
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";
import moment from "moment";
import {
  getURLParams,
  isMobile,
  compactJoin,
  lower,
  flattenObject,
  removeSensitiveData
} from "utils/helpers";
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_TYPES,
  CLEVERTAP_EVENTS
} from "constants/AnalyticsTypeConstants";
import { getOrderRef } from "utils/localStorage";

const CLEVERTAP_TIMEOUT = 60000;
const CLEVERTAP_INTERVAL = 1000;

class CleverTapAnalytics extends Analytics {
  logStart(event, info, data) {
    super.logEventStart(ANALYTICS_TYPES.cleverTap, event, info, data);
  }

  logFinish(event, info, data) {
    super.logEventFinish(ANALYTICS_TYPES.cleverTap, event, info, data);
  }

  trackPage() {
    super.trackPage(ANALYTICS_TYPES.cleverTap);
  }

  pushEvent(event, data) {
    this.trackPage();
    this.onClevertapDefined(() => {
      this.logStart(ANALYTICS_EVENT_TYPES.PUSH_EVENT, event);
      const eventData = this.constructEventData(event, data);
      window.clevertap.event.push(event, eventData);
      console.log(`Pushed CleverTap Analytics Event: ${event}`, eventData);
      this.logFinish(ANALYTICS_EVENT_TYPES.PUSH_EVENT, event, eventData);
      if (eventData.customEvent) {
        this.logStart(
          ANALYTICS_EVENT_TYPES.PUSH_EVENT,
          eventData.customEvent,
          eventData
        );
        window.clevertap.event.push(eventData.customEvent, eventData);
        console.log(
          `Pushed Custom CleverTap Event: ${eventData.customEvent}`,
          eventData
        );
        this.logFinish(
          ANALYTICS_EVENT_TYPES.PUSH_EVENT,
          eventData.customEvent,
          eventData
        );
      }
      if (eventData.charged) {
        this.pushChargedEvent(data);
      }
    });
  }

  pushChargedEvent(data) {
    this.logStart(ANALYTICS_EVENT_TYPES.PUSH_EVENT, CLEVERTAP_EVENTS.CHARGED);
    const chargedData = this.constructChargedData(data);
    if (!isEmpty(chargedData)) {
      window.clevertap.event.push(CLEVERTAP_EVENTS.CHARGED, chargedData);
      console.log("Pushed CleverTap Charged Event ", chargedData);
      if (
        lower(get(chargedData, "Payment Status")) === "success" ||
        get(data, "status") === "so_generated"
      ) {
        const profileData = { Site: { isProspect: false } };
        this.logStart(
          ANALYTICS_EVENT_TYPES.PUSH_PROFILE,
          CLEVERTAP_EVENTS.PROFILE
        );
        window.clevertap.profile.push(profileData);
        console.log("Pushed CleverTap Profile ", profileData);
        this.logFinish(
          ANALYTICS_EVENT_TYPES.PUSH_PROFILE,
          CLEVERTAP_EVENTS.PROFILE,
          profileData
        );
      }
    }
    this.logFinish(
      ANALYTICS_EVENT_TYPES.PUSH_EVENT,
      CLEVERTAP_EVENTS.CHARGED,
      chargedData
    );
  }

  pushProfile(_, data) {
    this.onClevertapDefined(() => {
      this.logStart(
        ANALYTICS_EVENT_TYPES.PUSH_PROFILE,
        CLEVERTAP_EVENTS.PROFILE
      );
      const {
        formValues,
        field,
        telco_info: telcoInfo = {},
        referral_code_type
      } = data;
      const onUserLoginData = !isEmpty(telcoInfo)
        ? this.constructProfileDataFromUserServiceTelcoInfo({
            ...telcoInfo,
            referral_code_type
          })
        : this.constructProfileData(formValues);

      if (!isEmpty(onUserLoginData)) {
        console.log("UserLoginData", onUserLoginData);
        window.clevertap.onUserLogin.push(onUserLoginData);
        console.log("Pushed User Profile: ", onUserLoginData);
        this.logFinish(
          ANALYTICS_EVENT_TYPES.PUSH_PROFILE,
          CLEVERTAP_EVENTS.PROFILE,
          onUserLoginData
        );
      }

      field &&
        setTimeout(() => {
          const fieldEvent = `ecomm_${field}_entered`;
          this.logStart(ANALYTICS_EVENT_TYPES.PUSH_EVENT, fieldEvent);
          console.log("CleverTap event:", fieldEvent);
          window.clevertap.event.push(fieldEvent);
          this.logFinish(ANALYTICS_EVENT_TYPES.PUSH_EVENT, fieldEvent);
        }, 2000);
    });
  }

  constructProfileData = ({
    loggedInUserEmail = "",
    email = "",
    country_code = "",
    contact_number = "",
    dob = "",
    full_name = "",
    first_name = "",
    middle_name = "",
    last_name = "",
    sim_count = "",
    referral_code_type: CouponCode_Type = "",
    referral_code: CouponCode = ""
  } = {}) => {
    const userEmail = email || loggedInUserEmail;
    if (!userEmail) return {}; // email is mandatory for profile data
    const fullName = compactJoin([first_name, middle_name, last_name], " ");
    const name = full_name || fullName;
    const phone = `${country_code}${contact_number}`;
    const DOB = dob && new Date(moment(dob, "DD-MM-YYYY").toISOString());

    return {
      Site: {
        Name: name,
        Phone: phone,
        DOB,
        Identity: userEmail,
        Email: userEmail,
        email_address: userEmail,
        phone_number: phone,
        profilesource: "ecomm",
        Lifecycle_Stage: "lead_ecomm",
        isProspect: !getOrderRef(),
        orderRef: getOrderRef(),
        CouponCode,
        CouponCode_Type,
        ...(sim_count && { sim_count })
      }
    };
  };

  constructProfileDataFromUserServiceTelcoInfo = ({
    isd_code,
    phone_no,
    circles_info: circlesInfo = {},
    service_instance_no: sin = "",
    service_account_email: email = "",
    referral_code_type: CouponCode_Type = ""
  } = {}) => {
    const {
      order_reference_number: orderRef = "",
      referral_code: CouponCode = "",
      service_instance_base_plan_name: planName = "",
      user_name: userName = {}
    } = circlesInfo;
    const { name: { first = "", last = "", middle = "" } = {} } = userName;

    if (!email) return {}; // email is mandatory for profile data
    const name = compactJoin([first, middle, last], " ");
    const phone = `${isd_code}${phone_no}`;

    return {
      Site: {
        Name: name,
        Phone: phone,
        Identity: sin || email,
        Email: email,
        profilesource: "ecomm",
        Lifecycle_Stage: sin ? "customer" : "lead_ecomm",
        isProspect: !orderRef,
        orderRef,
        CouponCode,
        CouponCode_Type,
        planName
      }
    };
  };

  constructChargedData = data => {
    const { totalOrderValue, purchaseID, customerID, paymentStatus, discount } =
      get(data, "order_product_info") || {};
    const chargedID = purchaseID || get(data, "order.order_ref");
    const amount =
      totalOrderValue ||
      get(data, "orderParams.order_summary.onetime_total_fee");
    const {
      promoCode,
      promoCodeDiscountAmt,
      promoCodeCategory,
      promoCodeDescription
    } = discount || {};
    if (!chargedID) return {};
    return {
      "Payment mode": "Credit Card",
      "Charged ID": chargedID,
      "Customer ID": customerID || Session.getDeviceId(),
      "Payment Status": paymentStatus || get(data, "payment_status"),
      "Promo Code": promoCode || get(data, "referral_code"),
      "Promo Code Discount Amount":
        promoCodeDiscountAmt ||
        get(
          data,
          "orderParams.order_summary.onetime_referral_code_discount_fee"
        ),
      "Promo Code Category": promoCodeCategory || "",
      "Promo Code Description": promoCodeDescription || "",
      Channel: get(data, "channel") || get(data, "order.channel"),
      PlanName: get(data, "plan_name"),
      Amount: amount,
      Items: map(
        get(data, "order_product_info.item") ||
          get(data, "orderParams.order_items"),
        item => this.constructChargedItem(item, get(data, "plan_name"))
      )
    };
  };

  constructChargedItem = (item, planName) => {
    const {
      quantity: Quantity = 1,
      price,
      productInfo,
      sku,
      name,
      title,
      item_type
    } = item;
    const Price = get(price, "basePrice", 0);
    const {
      productName: Name = name || title,
      productCategory: Category = item_type || "ADDON",
      productType: Type = item_type || "ADDON",
      planName: Plan = planName,
      productDiscount: Discount = 0,
      productID: ID = sku,
      productBrand: Brand = "LibertyWireless",
      productSize: Size = "NA",
      productColour: Colour = "NA",
      productDescription: Description = "NA"
    } = productInfo || {};

    return {
      Name,
      Category,
      Type,
      Plan,
      Discount,
      Quantity,
      Price,
      ID,
      Brand,
      Size,
      Colour,
      Description
    };
  };

  onClevertapDefined = pushDataToClevertap => {
    if (!window.clevertapDisabled) {
      const intervalID = setInterval(() => {
        // check if clevertapDisabled is set in between and stop polling
        if (window.clevertapDisabled) {
          console.log("clevertapDisabled, stopped polling...");
          window.clearInterval(intervalID);
          return;
        }
        const cleverTapDefined = window.clearOldCleverTapIDStarted
          ? window.clearOldCleverTapIDDone
          : window.clevertap;
        if (cleverTapDefined) {
          pushDataToClevertap();
          window.clearInterval(intervalID);
        } else {
          console.log("cleverTapDefined is false, delaying...");
        }
      }, CLEVERTAP_INTERVAL);
      setTimeout(() => clearInterval(intervalID), CLEVERTAP_TIMEOUT);
    }
  };

  clearOldCleverTapID = () => {
    if (!window.clevertapDisabled) {
      console.log("clearOldCleverTapIDStarted");
      window.clearOldCleverTapIDStarted = true;
      const intervalID = setInterval(() => {
        // check if clevertapDisabled is set in between and stop polling
        if (window.clevertapDisabled) {
          console.log("clevertapDisabled, stopped polling...");
          window.clearInterval(intervalID);
          return;
        }
        if (window.clevertap?.getCleverTapID) {
          const cleverTapID = window.clevertap.getCleverTapID();
          console.log("OldCleverTapID: ", cleverTapID);
          if (cleverTapID) {
            console.log(
              "clearOldCleverTapID: start ",
              window.clevertap.getCleverTapID()
            );
            window.clevertap.clear();
            console.log(
              "clearOldCleverTapID: end ",
              window.clevertap.getCleverTapID()
            );
          }
          window.clearOldCleverTapIDDone = true;
          window.clearInterval(intervalID);
        } else {
          console.log("getCleverTapID undefined, delaying...");
        }
      }, CLEVERTAP_INTERVAL);
      setTimeout(() => clearInterval(intervalID), CLEVERTAP_TIMEOUT);
    }
  };

  constructEventData = (event, data = {}) => {
    let eventData = this.getEventData(event, data);
    eventData = isPlainObject(eventData) ? eventData : { eventData };
    return {
      ...eventData,
      ...getURLParams(),
      env: isMobile() ? "mobile" : "desktop",
      host: window.location.host,
      path: window.location.pathname,
      sessionId: get(eventData, "customer_id") || Session.getDeviceId()
      // ipAddress: Session.getIpAddress()
    };
  };

  getEventData = (event, data) => {
    let eventData = {};
    switch (event) {
      case "paymentStatus_api_call_succeeded":
      case "paymentReturn_api_call_succeeded":
        {
          const order_ref = data?.order?.order_ref || data?.order_ref;
          const payment_status = data?.payment_status;
          const customer_id = data?.customer_id || Session.getDeviceId();
          eventData = { order_ref, payment_status, customer_id, charged: true };
        }
        break;
      case "selfActivationDeliveryOrder_api_call_succeeded":
        {
          const {
            order_ref,
            payment_status,
            id: customer_id,
            family_plan_sim_count
          } = data || {};
          eventData = {
            order_ref,
            payment_status,
            customer_id,
            charged: true,
            ...(family_plan_sim_count && { sim_count: family_plan_sim_count })
          };
        }
        break;
      case "numbers_api_call_succeeded":
        eventData = {
          customEvent: CLEVERTAP_EVENTS.SELECTED_NUMBER,
          ...get(data, "selected_number", {})
        };
        break;
      case "referralCode_api_call_requested":
        eventData = {
          customEvent: CLEVERTAP_EVENTS.SELECTED_PROMOTION,
          ...data
        };
        break;
      case "referralCode_api_call_succeeded":
        eventData = {
          customEvent: CLEVERTAP_EVENTS.PROMOTION_STATUS,
          ...this.flattenData(data)
        };
        break;
      case "verifyIdentity_api_call_succeeded":
        eventData = { success: get(data, "success") };
        break;
      case "paymentRequest_api_call_succeeded":
      case "order_api_call_succeeded":
        eventData = { order_ref: get(data, "order_ref") };
        break;
      case "field_blurred":
        {
          const { form, field, payload } = data || {};
          eventData = { form, field, payload };
        }
        break;
      case "PortInForm_submitted":
        eventData = {
          customEvent: CLEVERTAP_EVENTS.SELECTED_NUMBER_PORT_IN,
          ...this.flattenData(get(data, "formValues", {}))
        };
        break;
      case "PersonalDetailsForm_submitted":
        eventData = removeSensitiveData(
          this.flattenData(get(data, "formValues", {}))
        );
        break;
      case "order_api_call_requested":
        eventData = removeSensitiveData(this.flattenData(data));
        break;
      case "appSettings_api_call_requested":
      case "appSettings_api_call_succeeded":
      case "locale_api_call_succeeded":
      case "verifyIdentity_api_call_requested":
      case "screenshot_api_call_requested":
      case "paymentRequest_api_call_requested":
      case "liquidOCRRegister_api_call_requested":
      case "userKycDetails_api_call_requested":
        eventData = {};
        break;
      default:
        eventData = removeSensitiveData(this.flattenData(data));
        break;
    }
    return eventData;
  };

  flattenData = data => flattenObject(data, "", "->");
}

export default new CleverTapAnalytics();
