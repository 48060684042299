import get from "lodash/get";
import { createSelector } from "reselect";

const betaUserAuthDomain = state =>
  get(state, "api.betaUserAuthenticate.data", {});

const isAuthenticatedBetaUser = createSelector(betaUserAuthDomain, authData => {
  return !!authData.auth_token;
});

export const makeSelectIsAuthenticatedBetaUser = () => isAuthenticatedBetaUser;
