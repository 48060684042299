import DELIVERY_ADDRESS_FORM_5 from "smart-components/DELIVERY_ADDRESS_FORM_5/index";

const BILLING_ADDRESS_FORM_5 = props => {
  return DELIVERY_ADDRESS_FORM_5({
    ...props,
    fieldPrefix: "billing",
    linkedFieldPrefix: "delivery",
    title: "personal_details_billing_header"
  });
};

export default BILLING_ADDRESS_FORM_5;
