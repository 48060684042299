import React from "react";
import get from "lodash/get";
import { Flex, Text } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import { safeEval } from "utils/helpers";

const CLOSE_ICON = props => {
  const { component: { items } = {} } = props;

  return (
    <Flex
      pb={2}
      data-testid="close-icon"
      sx={{ cursor: "pointer" }}
      onClick={() => safeEval(get, props.formValues, items.onClick, "", props)}
    >
      <Text pr={2}>X</Text>
      <FormattedMessage id="CLOSE" />
    </Flex>
  );
};

export default CLOSE_ICON;
