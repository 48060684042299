import Analytics from "../Analytics";
import {
  ADOBE_EVENTS_MAPPING,
  ADOBE_PROFILE_MAPPING,
  ANALYTICS_TYPES,
  ANALYTICS_EVENT_TYPES,
  SATELLITE_EVENTS
} from "../../constants/AnalyticsTypeConstants";
import {
  BRAND_NAME,
  SIM_ACTIVATION_CHANNELS,
  PHONE_ONLY_CHANNEL,
  COMBO_CHANNEL
} from "utils/constants";
import {
  getChannelFromUrl,
  isDesktop,
  adobeOrderItemsFormatter,
  getURLParams
} from "utils/helpers";
import { currencyCode } from "../index";
import Session from "session";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import concat from "lodash/concat";

class AdobeAnalytics extends Analytics {
  eventsMapping = {};
  profileMapping = {};
  constructor() {
    super();
    for (const key in ADOBE_EVENTS_MAPPING) {
      this.eventsMapping[key] = ADOBE_EVENTS_MAPPING[key];
    }
    for (const key in ADOBE_PROFILE_MAPPING) {
      this.profileMapping[key] = ADOBE_PROFILE_MAPPING[key];
    }
  }

  logStart(event, info, data) {
    super.logEventStart(ANALYTICS_TYPES.adobe, event, info, data);
  }

  logFinish(event, info, data) {
    super.logEventFinish(ANALYTICS_TYPES.adobe, event, info, data);
  }

  pushEvent(event, data) {
    let eventData = this.eventsMapping[event];
    if (this.isFlowExcluded(data)) {
      console.log("Adobe Event Mapping excluded, Ignoring.....");
      return;
    }
    if (!eventData) {
      this.trackPage();
      console.log("Adobe Event Mapping Undefined, Ignoring.....");
      return;
    }
    if (eventData[data.selected]) {
      eventData = eventData[data.selected];
    }
    if (eventData.transaction) {
      this.updateTransactionData(data, eventData);
    }
    this.updateDigitalData(data, eventData);
    if (eventData.event) {
      this.sendEvent(eventData);
    }
    if (eventData.trackEvent) {
      const trackEventData = this.getTrackEventData(data, eventData);
      this.trackAdobeEvent(eventData.trackEvent, trackEventData);
    }
  }

  trackPage() {
    this.logStart(ANALYTICS_EVENT_TYPES.TRACK_PAGE);
    let digitalData = window.digitalData || {};
    let page =
      digitalData.page && Array.isArray(digitalData.page)
        ? digitalData.page[0]
        : {};
    page.pageInfo = this.getPageObj();
    const pageName = get(page, "pageInfo.pageName");
    digitalData.page = [page];
    const channel = getChannelFromUrl();
    if ([COMBO_CHANNEL, PHONE_ONLY_CHANNEL].includes(channel)) {
      const urlParams = getURLParams();
      digitalData.transaction = {
        purchaseID: urlParams.order_ref,
        planName: channel
      };
    }
    this.logFinish(ANALYTICS_EVENT_TYPES.TRACK_PAGE, pageName, digitalData);
  }

  updateDigitalData(data, eventData) {
    this.logStart(ANALYTICS_EVENT_TYPES.UPDATE_PAGE_DATA);
    if (!eventData.category) {
      return;
    }
    if (eventData.object) {
      data = data[eventData.object];
    }
    let digitalData = window.digitalData || {};
    let page =
      digitalData.page && Array.isArray(digitalData.page)
        ? digitalData.page[0]
        : {};
    page.pageInfo = this.getPageObj();
    let productInfo = page.productInfo || [];
    if (data && data.sku) {
      productInfo.push(this.getProductsInfo(eventData, data));
    }
    page.productInfo = productInfo;
    const pageName = get(page, "pageInfo.pageName");
    digitalData.page = [page];
    this.logFinish(
      ANALYTICS_EVENT_TYPES.UPDATE_PAGE_DATA,
      pageName,
      digitalData
    );
  }

  getPageObj(locationObj) {
    return {
      pageName: super.getAdobePageName(locationObj),
      pageType: super.getPageType(),
      site: super.getSiteName(),
      sysEnv: isDesktop() ? "desktop" : "mobile",
      pageLoad: "true",
      customerID: Session.getDeviceId()
      // ipAddress: Session.getIpAddress()
    };
  }

  updateTransactionData(data, eventData) {
    this.logStart(ANALYTICS_EVENT_TYPES.UPDATE_PAGE_DATA);
    if (eventData.object) {
      data = data[eventData.object];
    }
    let digitalData = window.digitalData || {};
    let page =
      digitalData.page && Array.isArray(digitalData.page)
        ? digitalData.page[0]
        : {};
    let productInfo = [];
    if (data) {
      if (data.item) {
        for (let item of data.item) {
          productInfo.push(this.getTransactionProductsInfo(item));
        }
      }
      if (data.order_items) {
        for (let item of data.order_items) {
          productInfo.push(
            this.getTransactionOrderItemInfo(item),
            data.plan_name
          );
        }
      }
      const purchaseID = data.purchaseID || data.order_ref;
      this.logStart(ANALYTICS_EVENT_TYPES.UPDATE_TRANSACTION, purchaseID);
      const familyPlanSimCount = get(data, "family_plan_sim_count");
      const simCount = familyPlanSimCount
        ? familyPlanSimCount
        : get(data, "is_multi_sim")
        ? 2
        : 1;
      digitalData.transaction = {
        purchaseID: purchaseID,
        customerID: Session.getDeviceId(),
        paymentStatus: data.paymentStatus,
        currency: currencyCode(),
        discount: this.getDiscount(
          data.discount,
          data.referral_code,
          data.orderParams?.order_summary?.onetime_referral_code_discount_fee
        ),
        productInfo: productInfo,
        planName: data.plan_name,
        simCount
      };
      if (eventData.event === "phone details") {
        digitalData.transaction.phoneDetails = data.formValues;
      }
      this.logFinish(
        ANALYTICS_EVENT_TYPES.UPDATE_TRANSACTION,
        purchaseID,
        digitalData
      );
    }
    page.pageInfo = this.getPageObj();
    const pageName = get(page, "pageInfo.pageName");
    digitalData.page = [page];
    this.logFinish(
      ANALYTICS_EVENT_TYPES.UPDATE_PAGE_DATA,
      pageName,
      digitalData
    );
  }

  getDiscount(discount, referralCode, discountAmount) {
    return {
      promoCode: discount?.promoCode || referralCode,
      promoCodeDiscountAmt: discount?.promoCodeDiscountAmt || discountAmount
    };
  }

  getTransactionProductsInfo(data) {
    data = data.productInfo;
    return {
      planName: data.planName,
      productBrand: BRAND_NAME,
      productCategory: data.productCategory,
      productDescription: "NA",
      productID: data.productID,
      productName: data.productName
    };
  }

  getTransactionOrderItemInfo(item, planName) {
    return {
      planName: planName,
      productBrand: BRAND_NAME,
      productCategory: item.item_type,
      productDescription: "NA",
      productID: item.sku,
      productName: item.name
    };
  }

  sendEvent(eventData) {
    if (eventData && eventData.event) {
      this.logStart(eventData.event, "", eventData);
      window.digitalData.events = eventData.event;
      this.logFinish(eventData.event, "", eventData);
    }
  }

  getProductsInfo(eventData, data) {
    return {
      planName: this.getName(data),
      productBrand: BRAND_NAME,
      productCategory: eventData.category,
      productDescription: "NA",
      productID: data.sku,
      productName: this.getName(data),
      productPrice: this.getPrice(data)
    };
  }

  getTrackEventData(data = {}, eventData = {}) {
    const { trackEvent: event, object } = eventData;
    const { userOrder } = object ? get(data, object, {}) : data;
    let trackEventData = undefined;

    switch (event) {
      case SATELLITE_EVENTS.PURCHASE:
        {
          const purchaseID =
            get(data, "purchaseID") ||
            get(data, "order_product_info.purchaseID") ||
            get(data, "order.order_ref");
          // Capture purchase event only if the purchaseID is present
          if (purchaseID) {
            const itemData =
              get(data, "order_product_info.item") ||
              get(data, "orderParams.order_items", []);
            const discountData =
              get(data, "discount") || get(data, "order_product_info.discount");
            const paymentStatus = get(data, "payment_status", {});
            const customerID =
              get(data, "customer_id", "") || Session.getDeviceId();
            const discount =
              this.getDiscount(
                discountData,
                get(data, "referral_code"),
                get(
                  data,
                  "orderParams.order_summary.onetime_referral_code_discount_fee"
                )
              ) || {};
            const currency = currencyCode();
            const categories = [];
            const products = [];
            const units = [];
            const prices = [];
            const orderRef =
              get(data, "order.order_ref") || get(data, "order_ref");
            const simType =
              get(userOrder, "sim_type") ||
              get(data, "forms.ConnectionDetailsForm.values.sim_type") ||
              get(data, "orderParams.sim_type");
            const numberTransfer =
              get(data, "forms.ConnectionDetailsForm.values.number_type") ||
              get(data, "orderParams.number_type");
            const phoneNumber =
              get(userOrder, "contact_number") ||
              get(data, "orderParams.user_detail.phone_number");
            const planName = get(data, "plan_name") || getChannelFromUrl();
            const channel = get(data, "channel") || get(data, "order.channel");
            const planItem = {
              quantity: 1,
              productInfo: {
                productCategory: "PLAN",
                productName: planName
              }
            };

            for (const item of concat([planItem], itemData)) {
              const category =
                get(item, "productInfo.productCategory") ||
                get(item, "item_type", "ADDON");
              const product =
                get(item, "productInfo.productName") ||
                get(item, "name") ||
                get(item, "title", "");
              const unit = get(item, "quantity", 1);
              const price = `${get(
                item,
                "price.basePrice",
                get(item, "price", 0)
              )}`;
              categories.push(category);
              products.push(product);
              units.push(unit);
              prices.push(price);
            }
            products.unshift(planName);

            trackEventData = {
              category: categories.join(";"),
              product: products.join(";"),
              units: units.join(";"),
              price: prices.join(";"),
              journeyType: this.getJourneyType(),
              transactionId: purchaseID,
              purchaseID: purchaseID,
              paymentStatus: paymentStatus,
              currency,
              promoCode: get(discount, "promoCode", ""),
              promoCodeDiscountAmt: get(discount, "promoCodeDiscountAmt", ""),
              customerID,
              customer: { customerID },
              orderRef,
              simType,
              planName,
              phoneNumber,
              plan_type: "SIM-only",
              number_transfer: numberTransfer,
              channel
            };
          }
        }
        break;
      case SATELLITE_EVENTS.SADPURCHASE:
        {
          const planName = get(data, "plan_name") || getChannelFromUrl();
          const referralCode = get(data, "referral_code", "");
          const orderRef = get(data, "order_ref");
          const familyPlanSimCount = get(data, "family_plan_sim_count");
          const simCount = familyPlanSimCount
            ? familyPlanSimCount
            : get(data, "is_multi_sim")
            ? 2
            : 1;
          const PlanItem = {
            quantity: 1,
            price: { basePrice: 1 },
            productInfo: {
              productID: planName,
              planName: planName,
              productBrand: "circles",
              productCategory: planName,
              productSize: "n/a",
              productColour: "n/a",
              productDiscount: 0,
              productDescription: planName,
              productType: planName
            }
          };
          const addons = adobeOrderItemsFormatter(
            get(data, "order_items", []),
            planName
          );
          const items = [PlanItem].concat(addons);
          trackEventData = {
            discount: { ...(referralCode && { promoCode: referralCode }) },
            purchaseID: orderRef,
            sadPurchaseID: orderRef,
            paymentStatus: "n/a",
            item: items,
            planName:
              !planName &&
              [COMBO_CHANNEL, PHONE_ONLY_CHANNEL].includes(getChannelFromUrl())
                ? getChannelFromUrl()
                : planName,
            simCount
          };
        }
        break;
      case SATELLITE_EVENTS.ACTIVATE:
        {
          const activationStatus = get(data, "success") ? "success" : "failed";
          const orderRef = get(data, "order_ref");
          const phoneNumber = get(userOrder, "contact_number");
          trackEventData = {
            orderRef,
            phoneNumber,
            activationStatus
          };
        }
        break;
      default:
        break;
    }
    return trackEventData;
  }

  getJourneyType() {
    return SIM_ACTIVATION_CHANNELS.includes(getChannelFromUrl())
      ? "SAD"
      : "Non-SAD New";
  }

  getName(data) {
    if (data.name) {
      return data.name;
    }
    return undefined;
  }

  getPrice(data) {
    if (data.basicPrice) {
      return data.basicPrice;
    } else if (data.price) {
      return data.price;
    }
    return 0;
  }

  pushProfile(_, data) {
    const profile = this.profileMapping[data.field];
    if (this.isFlowExcluded(data)) {
      console.log("Adobe Event Mapping excluded, Ignoring.....");
      return;
    }
    if (!profile) {
      console.log("Adobe Event Mapping Undefined, Ignoring.....");
      return;
    }
    const trackingData = isFunction(profile.getData)
      ? profile.getData(data.formValues)
      : undefined;
    this.trackAdobeEvent(profile.event, trackingData);
  }

  trackAdobeEvent(event, data) {
    super.trackEvent(ANALYTICS_TYPES.adobe, event, data);
  }
}

export default new AdobeAnalytics();
