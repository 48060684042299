import { createSelector } from "reselect";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

// ***************************************************************
// ******************** Verify Identify **************************
// ***************************************************************

export const verifyIdentityDomain = state =>
  get(state.api, "verifyIdentity.data", {});

export const verifyIdentitySelector = createSelector(
  verifyIdentityDomain,
  verifyIdentity => verifyIdentity
);

export const makeSelectIsIdentityVerified = () =>
  createSelector(
    verifyIdentitySelector,
    verifyIdentity => verifyIdentity.success
  );

export const makeSelectIsIdentityVerificationFailed = () =>
  createSelector(
    state => get(state.api, "verifyIdentity.error"),
    error => {
      return !isEmpty(error);
    }
  );

// ***************************************************************
// ************ Get Identity Verification Status *****************
// ***************************************************************

export const identityVerificationStatusDomain = state =>
  get(state.api, "identityVerificationStatus.data", {});

export const identityVerificationStatusSelector = createSelector(
  identityVerificationStatusDomain,
  identityVerificationStatus => identityVerificationStatus
);

export const makeSelectIdentityVerificationStatus = () =>
  createSelector(
    identityVerificationStatusSelector,
    identityVerificationStatus =>
      get(identityVerificationStatus, "result", null)
  );

export const makeSelectReuploadIdentityUrl = () =>
  createSelector(
    identityVerificationStatusSelector,
    identityVerificationStatus =>
      get(identityVerificationStatus, "reupload_identity_url")
  );

export const makeSelectIdentityVerificationStatusFailed = () =>
  createSelector(
    state => get(state.api, "identityVerificationStatus.error"),
    error => !isEmpty(error)
  );
