import React from "react";
import get from "lodash/get";
import ToggleSwitch from "components/ToggleSwitch";

const FIELD_TOGGLE = props => {
  const { data, valueAttribute = "value", formValues, change } = props;
  const field = get(data, "field");
  const value = get(formValues, field);
  return (
    <ToggleSwitch
      {...props}
      value={value}
      valueAttribute={valueAttribute}
      onClick={item => {
        change && change(field, get(item, [valueAttribute]));
      }}
      data={data}
      {...data}
    />
  );
};

export default FIELD_TOGGLE;
