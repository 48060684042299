import React from "react";
import get from "lodash/get";
import RadioGroup from "components/FormFields/RadioGroup";

const REGISTERED_UNDER_OWN_NRIC = ({ data, ...props }) => {
  const options = get(data, "options", []);
  return (
    <RadioGroup
      options={options}
      width={[1, 1 / 3, 1 / 3]}
      {...props}
    ></RadioGroup>
  );
};

export default REGISTERED_UNDER_OWN_NRIC;
