import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Image, Text } from "ui-components";
import { COLORS } from "ui-components/theme";
import { Flex } from "rebass";
import HTML_LINK from "smart-components/HTML_LINK";

const defaultStyle = {
  marginLeft: "5px"
};

const defaultSuccessIconUrl =
  "https://pjp-povo-marketing.s3.ap-northeast-1.amazonaws.com/004-WebfrontAssets/GreenCheck.png";

const FieldSuccessMessage = ({
  touched,
  message,
  values,
  sx = defaultStyle,
  containerProps,
  iconUrl,
  onClickHtmlLink,
  htmlLinkMessage
}) => {
  return touched && message ? (
    <Flex sx={{ marginTop: "4px" }}>
      <Image
        src={iconUrl || defaultSuccessIconUrl}
        sx={{ height: "1rem", width: "1rem", marginTop: "2px" }}
      />

      <FormattedMessage id={message} values={values}>
        {msg => (
          <Text
            fontWeight="900"
            className="field-error"
            mt={1}
            size="xs"
            color={COLORS.green[6]}
            sx={sx}
          >
            {msg}
          </Text>
        )}
      </FormattedMessage>
      {htmlLinkMessage && (
        <HTML_LINK
          {...containerProps}
          underLine={true}
          textColor={COLORS.green[6]}
          label={htmlLinkMessage}
          containerStyles={{
            marginTop: "0 !important",
            marginLeft: "0 !important"
          }}
          textStyle={{
            padding: "0 !important",
            fontWeight: "900",
            fontSize: "12px"
          }}
          onsubmit={onClickHtmlLink}
        />
      )}
    </Flex>
  ) : null;
};

FieldSuccessMessage.defaultProps = {
  values: {}
};

export default FieldSuccessMessage;
