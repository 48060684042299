import React, { useState } from "react";
import get from "lodash/get";
import { Box } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import RadioGroup from "components/FormFields/RadioGroup";

const TELCO_LIST_RADIO = ({ telcoList, data, ...props }) => {
  const defaultNoOfTelcosToDisplay = get(data, "display_count", 0);
  const [seeMore, setToggle] = useState(false);
  const slicedTelcos = telcoList.slice(
    0,
    seeMore === false ? defaultNoOfTelcosToDisplay : telcoList.length
  );

  return (
    <RadioGroup options={slicedTelcos} width={[1 / 2, 1 / 3, 1 / 3]} {...props}>
      <Box
        onClick={() => setToggle(!seeMore)}
        textAlign="center"
        color="blue.4"
        fontWeight="500"
        sx={{ cursor: "pointer" }}
      >
        {seeMore ? (
          <FormattedMessage id="see_less" />
        ) : (
          <FormattedMessage id="see_more" />
        )}
      </Box>
    </RadioGroup>
  );
};

export default TELCO_LIST_RADIO;
