import React, { useState, useEffect, memo } from "react";
import { useParams } from "react-router-dom";
import { compose } from "redux";
import { useSelector, useDispatch, connect } from "react-redux";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import forEach from "lodash/forEach";
import Alert from "react-s-alert";

import Spinner, { styles } from "components/Spinner";

import {
  forms,
  paths,
  CIRCLES_PAYMENT_PLUGIN_ORIGIN_KEY,
  RESPONSE_STATUS,
  PAIDY_API_KEY,
  PAYMENT_METHODS,
  DEFAULT_CHANNEL,
  DATA_ONLY_CHANNERL,
  publicPaths,
  DUPLICATE_CONTACT_NUMBER_ERROR_CODES
} from "utils/constants";

import {
  makeSelectOrder,
  makeSelectPaymentForm,
  makeSelectOrderSummaryPayload,
  makeSelectIsFetchingData,
  makeSelectUserDetails,
  makeSelectCurrencyCode,
  makeSelectGuestOrderOrderRef,
  makeSelectUserSIN,
  makeSelectSessionToken,
  makeSelectPaymentDetails,
  makeSelectProcessPayment,
  makeSelectCardTypesMap,
  makeSelectCardTypes,
  makeSelectPaymentErrorCodes,
  makeSelectIsModalView,
  makeSelectModalType,
  MakeSelectPaidyConfigs,
  makeSelectGA4PlanDetails,
  makeSelectSelectedToppings
} from "ducks/selectors";
import {
  fetchUpfrontPaymentDetails,
  processUpfrontPayment,
  fetchPaymentStatus,
  clearData,
  Payment,
  OMS,
  setData,
  dataOnlyContactNumVerification,
  changeFormField
} from "ducks/actions";
import { getOMSToken } from "ducks/actions/uiActions";
import SmartRender from "containers/SmartRender";
import {
  loadScript,
  getChannelFromUrl,
  gotoUrl,
  urlParam,
  pathWithChannel,
  isWebView
} from "utils/helpers";
import usePaymentResponseHandler from "hooks/usePaymentResponseHandler";
import { getTxnId } from "utils/localStorage";
import {
  featureFlagDataOnlyPlanEnabled,
  featureFlagPaidyEnabled
} from "utils/featureFlags";
import useGMOGateway from "hooks/paymentPartners/useGMOGateway";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";
import usePaymentStatus from "hooks/usePaymentStatus";

const UPDATE_FROM = {
  MOBILE: "mobile",
  WEB: "web"
};
const PAIDY_CLOSED = "closed";
const PAYMENT_SCENARIOS = {
  NEW_ORDER: "NEW_ORDER_PAYMENT"
};

const PaymentHOC = Component =>
  function HOC(props) {
    const {
      isFetchingPaymentRequest,
      paymentsPayload,
      userSIN,
      currencyCode,
      orderRef,
      change,
      clearPaymentDetails,
      locale,
      errorCodes,
      userDetails,
      paidyModalConfigs,
      GA4EventPlanDetails,
      match
    } = props;

    const {
      initGMOScript,
      makePaymentForNewOrder,
      pollingStarted: fetchingGMOPaymentStatus
    } = useGMOGateway();
    const [paymentScenario, setPaymentScenario] = useState(null);
    const {
      pollingStarted: fetchingPaidyPaymentStatus,
      startPaymentStatusPolling
    } = usePaymentStatus();

    const { method: paymentMethod } = useParams() || {};
    const [isValidCardDetails, setIsValidCardDetails] = useState(false);
    const [onPaidyScriptLoaded, setOnPaidyScriptLoaded] = useState(false);
    const [paidyInProgress, setPaidyInProgress] = useState(false);
    const [initOnboardingWithPaidy, setInitOnboardingWithPaidy] = useState(
      false
    );
    const dispatch = useDispatch();
    const isPaymentExecuted = useSelector(
      ({ ui }) => ui?.isGetTokenExecuted || false
    );
    const {
      paidy_modal_logo,
      paidy_scrip_url: PAIDY_SCRIPT,
      store_name: storeName
    } = paidyModalConfigs || {};
    const { channel } = get(match, "params", {});
    const isDataOnlyUser = channel === DATA_ONLY_CHANNERL;

    const { first_name, telco_info } = userDetails || {};

    // on mount
    useEffect(() => {
      clearPaymentDetails();
      dispatch(getOMSToken(false));
      initGMOScript();
      if (featureFlagPaidyEnabled) {
        loadScript(
          "PAIDY_SCRIPT",
          PAIDY_SCRIPT,
          () => {
            setOnPaidyScriptLoaded(true);
          },
          3
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      // call handlePaidyPay function for updating the user payment method
      if (
        onPaidyScriptLoaded &&
        urlParam("update_from") &&
        paymentMethod === PAYMENT_METHODS.PAIDY
      ) {
        handlePaidyPay();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onPaidyScriptLoaded]);

    useEffect(() => {
      if (initOnboardingWithPaidy) {
        handlePaidyPay();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initOnboardingWithPaidy]);

    const paidyCallBack = (callBackData = {}) => {
      setPaidyInProgress(false);
      const redirectUrl =
        channel === DEFAULT_CHANNEL ? paths.PAYMENT : paths.DATA_ONLY_PAYMENT;

      const { status } = callBackData;
      if (status === PAIDY_CLOSED) {
        if (initOnboardingWithPaidy) {
          return gotoUrl(pathWithChannel(redirectUrl), true, {}, false);
        } else {
          return gotoUrl(
            pathWithChannel(paths.PAYMENT_DETAILS_PAGE),
            true,
            {},
            false
          );
        }
      }
      const data = [callBackData.id];
      onGetToken(null, data);
    };

    const onboardingWithPaidy = () => {
      setInitOnboardingWithPaidy(true);
    };

    const handlePaidyPay = () => {
      if (window.Paidy && onPaidyScriptLoaded) {
        const config = {
          api_key: PAIDY_API_KEY,
          logo_url: paidy_modal_logo,
          on_close: paidyCallBack,
          token: {
            wallet_id: "default",
            type: "recurring"
          }
        };
        const paidyHandler = window.Paidy.configure(config);
        const userData = {
          email: userDetails.email,
          name1: first_name,
          name2: telco_info?.katakana_name
        };
        const payload = {
          store_name: storeName,
          buyer: userData
        };
        setPaidyInProgress(true);
        paidyHandler.launch(payload);
      } else {
        console.log("Oops paidy didnt load");
      }
    };

    const getCardDetailsPayload = (token, cardDetails, method) => {
      if (featureFlagPaidyEnabled) {
        const creditCardAdditionalData =
          method === PAYMENT_METHODS.PAIDY
            ? {}
            : getCreditCardAdditionalData(cardDetails);
        return {
          service_instance_number: userSIN,
          additional_data: {
            custom_payment_method: method,
            token: token,
            ...creditCardAdditionalData
          },
          paymentMethod: method,
          currency: currencyCode,
          txn_id: orderRef,
          uuid: orderRef
        };
      } else {
        const creditCardAdditionalData = getCreditCardAdditionalData(
          cardDetails
        );
        return {
          service_instance_number: userSIN,
          additional_data: {
            custom_payment_method: "card",
            token: token,
            ...creditCardAdditionalData
          },
          paymentMethod: "card",
          currency: currencyCode,
          txn_id: orderRef,
          uuid: orderRef
        };
      }
    };

    const getUpdateCardPayload = (token, cardDetails, method) => {
      if (featureFlagPaidyEnabled) {
        const creditCardAdditionalData =
          method === PAYMENT_METHODS.PAIDY
            ? {}
            : getCreditCardAdditionalData(cardDetails);
        return {
          service_instance_number: userSIN,
          additional_data: {
            custom_payment_method: method,
            token: token,
            ...creditCardAdditionalData
          },
          paymentMethod: method,
          currency: currencyCode,
          txn_id: urlParam("txn_id") || orderRef,
          uuid: urlParam("txn_id") || orderRef
        };
      } else {
        const creditCardAdditionalData = getCreditCardAdditionalData(
          cardDetails
        );
        return {
          service_instance_number: userSIN,
          additional_data: {
            custom_payment_method: "card",
            token: token,
            ...creditCardAdditionalData
          },
          paymentMethod: "card",
          currency: currencyCode,
          txn_id: urlParam("txn_id") || orderRef,
          uuid: urlParam("txn_id") || orderRef
        };
      }
    };

    const getCreditCardAdditionalData = ({ cardNumber, cardType }) => {
      if (cardNumber && cardNumber.length > 10) {
        return {
          first_six_digits: cardNumber.substring(0, 6),
          last_four_digits: cardNumber.substring(cardNumber.length - 4),
          card_type: cardType?.value
        };
      }
    };

    const dispatchAction = action => {
      dispatch(
        action.request(
          action.payload,
          (result, error, statusCode) => {
            const isPaidyFaliure = get(result, "gateway");
            if (result.status !== RESPONSE_STATUS.FAILURE && !error) {
              if (
                action.isUpdateCall &&
                urlParam("return_url") &&
                isWebView()
              ) {
                gotoUrl(action.redirect, false, {}, true);
              } else if (action.isUpdateCall) {
                dispatch(
                  setData({
                    key: "isModalView",
                    data: {
                      isModalView: true,
                      type:
                        action.method === PAYMENT_METHODS.PAIDY
                          ? "paidySuccessfulUpdateModal"
                          : "cardSuccessfulUpdateModal"
                    }
                  })
                );
              } else {
                if (featureFlagDataOnlyPlanEnabled() && isDataOnlyUser) {
                  startPaymentStatusPolling();
                } else {
                  GA4EventsService.publish(
                    GA4EventsService.publish(
                      purchaseInitEventData(GA4EventPlanDetails)
                    )
                  );
                  gotoUrl(action.redirect, false, {}, true);
                }
              }
            } else if (
              featureFlagPaidyEnabled &&
              (result.form_data?.form_type === PAYMENT_METHODS.PAIDY ||
                isPaidyFaliure === PAYMENT_METHODS.PAIDY)
            ) {
              dispatch(
                setData({
                  key: "isModalView",
                  data: {
                    isModalView: true,
                    type: "paidyPaymentFaliureModal"
                  }
                })
              );
            } else {
              // Get the error code from payment service api response.
              const errorCode = get(result, "error_code");
              const duplicateContactNoError = get(result, "code");

              if (
                isDataOnlyUser &&
                !action.isUpdateCall &&
                DUPLICATE_CONTACT_NUMBER_ERROR_CODES.includes(
                  duplicateContactNoError
                )
              ) {
                const errorMessage = get(result, "message");
                Alert.error(errorMessage);
                resetContactNumberAndNavigateToUserRegistration();
                return;
              }

              /*
               * Check if the error code matches with ccm payment service
               * GMO related error codes.
               */
              if (errorCodes[errorCode]) {
                Alert.error(get(locale, errorCodes[errorCode]));
              } else {
                Alert.error(
                  get(
                    locale,
                    "saving_or_updating_payment_error",
                    "There was an error saving/updating the payment method. Please try again."
                  )
                );
              }
            }
          },
          "v3"
        )
      );
    };

    const onGetToken = (cardDetails, paidyTokens) => {
      if (window.Multipayment && cardDetails?.isValid) {
        window.Multipayment.getToken(
          {
            cardno: cardDetails.cardNumber,
            expire: cardDetails.expiryDate,
            securitycode: cardDetails.code,
            tokennumber: 2
          },
          result => {
            const token = result?.tokenObject?.token;
            const data = {
              cardDetails,
              paymentTokens: token,
              method: PAYMENT_METHODS.CREDIT_CARD
            };
            handleAndSaveTokens(data);
          }
        );
      } else if (paidyTokens && featureFlagPaidyEnabled) {
        const data = {
          paymentTokens: paidyTokens,
          method: PAYMENT_METHODS.PAIDY
        };
        handleAndSaveTokens(data);
      } else {
        dispatch(getOMSToken(false));
      }
    };

    const handleAndSaveTokens = ({
      cardDetails = {},
      paymentTokens = [],
      method
    }) => {
      dispatch(getOMSToken(false));
      const token = paymentTokens;
      if (Array.isArray(token) && !isEmpty(token)) {
        const deepLink = urlParam("deepLink") || urlParam("return_url");
        // when opening update-card from webfront we need to pass update_from url param to identify the request is coming from
        // external or within webfront.
        const updateFrom =
          urlParam("update_from") || (deepLink ? "mobile" : false);
        const transactionId = urlParam("txn_id");
        let payment_method = paymentsPayload.payment_methods;
        payment_method.additional_data = getCreditCardAdditionalData(
          cardDetails
        );
        const paymentActions = {
          save: {
            request: OMS.V4.postPaymentRequest,
            payload: {
              ...paymentsPayload,
              token
            },
            redirect: paths.PAYMENT_STATUS.replace(
              ":channel",
              getChannelFromUrl()
            )
          },
          update: {
            request: Payment.V3.processCardUpdatePayment,
            payload: getUpdateCardPayload(token, cardDetails, method),
            redirect:
              deepLink ||
              paths.DASHBOARD.replace(":channel", getChannelFromUrl()),
            isUpdateCall: true,
            method: method
          }
        };

        // if txn_id is passed in url params we open the update card page in mobile (for app running on older versions before paidy).
        if (updateFrom === UPDATE_FROM.MOBILE && transactionId) {
          dispatchAction(paymentActions.update);

          // if update card page opened from webfront then register transaction and proceed with the card update flow
        } else if (
          updateFrom === UPDATE_FROM.WEB ||
          updateFrom === UPDATE_FROM.MOBILE
        ) {
          dispatch(
            Payment.V3.saveAndGetPaymentCardDetails(
              { ...getCardDetailsPayload(token, cardDetails, method) },
              (data, error) => {
                if (!error) {
                  const { payload } = paymentActions.update;
                  const { payment_reference_id } = data || {};
                  payload.payment_reference_id = payment_reference_id;
                  dispatchAction(paymentActions.update);
                } else if (error && PAYMENT_METHODS.PAIDY === method) {
                  dispatch(
                    setData({
                      key: "isModalView",
                      data: {
                        isModalView: true,
                        type: "paidyPaymentFaliureModal"
                      }
                    })
                  );
                } else {
                  Alert.error(
                    get(
                      locale,
                      "invalid_card_details",
                      "There was an error getting your card details."
                    )
                  );
                }
              },
              "v3"
            )
          );

          // save card payment information
        } else {
          // Refactor: This scenario is now moved to useGMOGateway hook
          dispatchAction(paymentActions.save);
        }

        // error to show when there is a problem generating the token from GMO side
      } else {
        Alert.error(
          get(
            locale,
            "invalid_card_details",
            "There was an error with the payment. Please try again."
          )
        );
      }
    };

    const onPayment = (_, payload, data) => {
      change("payment_method", get(data, "next.payment_method", "card"));
      dispatch(getOMSToken(true));
    };

    const onGetCardDetails = (cardDetails, paidyTokens) => {
      switch (paymentScenario) {
        case PAYMENT_SCENARIOS.NEW_ORDER:
          makePaymentForNewOrder(cardDetails);
          break;
        default:
          onGetToken(cardDetails, paidyTokens);
          break;
      }
    };

    const newOrderPayment = (...params) => {
      onPayment(...params);
      setPaymentScenario(PAYMENT_SCENARIOS.NEW_ORDER);
    };

    const onCardUpdate = () => {
      dispatch(getOMSToken(true));
    };

    const onChange = cardDetails => {
      if (cardDetails) {
        const { isValid = false } = cardDetails;

        if (isValidCardDetails !== isValid) {
          GA4EventsService.publish(
            addPaymentInfoData({ ...GA4EventPlanDetails })
          );
          setIsValidCardDetails(isValid);
        }
      }
    };

    const resetContactNumberAndNavigateToUserRegistration = () => {
      dispatch(
        changeFormField({
          form: forms.DATA_ONLY_USER_REGISTRATION_FORM,
          field: "contact_number",
          value: ""
        })
      );
      dispatch(
        dataOnlyContactNumVerification({
          contact_num_verified: false,
          contact_number: ""
        })
      );
      gotoUrl(
        publicPaths.USER_DETAILS_REGISTRATION.replace(
          ":channel",
          getChannelFromUrl()
        )
      );
    };

    if (featureFlagPaidyEnabled) {
      if (paidyInProgress) return <Spinner size={200} sx={styles.fullPage} />;

      return isFetchingPaymentRequest &&
        ((featureFlagDataOnlyPlanEnabled() && !isDataOnlyUser) ||
          !featureFlagDataOnlyPlanEnabled()) ? (
        <Spinner size={200} sx={styles.fullPage} />
      ) : (
        <Component
          isPaymentExecuted={isPaymentExecuted}
          onGetToken={onGetCardDetails}
          onCardPayment={onPayment}
          onCardUpdate={onCardUpdate}
          newOrderPayment={newOrderPayment}
          onChange={onChange}
          isValidCardDetails={isValidCardDetails}
          handlePaidyPay={onboardingWithPaidy}
          loading={
            featureFlagDataOnlyPlanEnabled() &&
            isDataOnlyUser &&
            (fetchingGMOPaymentStatus ||
              isFetchingPaymentRequest ||
              fetchingPaidyPaymentStatus)
          }
          {...props}
        />
      );
    } else {
      return isFetchingPaymentRequest ? (
        <Spinner size={200} sx={styles.fullPage} />
      ) : (
        <Component
          isPaymentExecuted={isPaymentExecuted}
          onGetToken={onGetToken}
          onCardPayment={onPayment}
          onCardUpdate={onCardUpdate}
          onChange={onChange}
          isValidCardDetails={isValidCardDetails}
          {...props}
        />
      );
    }
  };

const mapStateToProps = createStructuredSelector({
  originkey: () => CIRCLES_PAYMENT_PLUGIN_ORIGIN_KEY,
  token: () => urlParam("token"),
  returnUrl: () => urlParam("return_url") || urlParam("deepLink"),
  order: makeSelectOrder(),
  paymentsForm: makeSelectPaymentForm(),
  paymentsPayload: makeSelectOrderSummaryPayload(),
  userDetails: makeSelectUserDetails(),
  userSIN: makeSelectUserSIN(),
  currencyCode: makeSelectCurrencyCode(),
  orderRef: makeSelectGuestOrderOrderRef(),

  isFetchingPaymentRequest: makeSelectIsFetchingData([
    "paymentRequest",
    "paymentDetails",
    "processPayment"
  ]),
  isFetchingPaymentDetails: makeSelectIsFetchingData("paymentDetails"),
  paymentDetails: makeSelectPaymentDetails(),
  processPaymentData: makeSelectProcessPayment(),
  orderSessionToken: makeSelectSessionToken(),
  webTxnId: () => getTxnId(),
  cardMap: makeSelectCardTypesMap(),
  cardTypes: makeSelectCardTypes(),
  errorCodes: makeSelectPaymentErrorCodes(),
  isModalView: makeSelectIsModalView(),
  modalType: makeSelectModalType(),
  paidyModalConfigs: MakeSelectPaidyConfigs(),
  GA4EventPlanDetails: makeSelectGA4PlanDetails(),
  selectedToppings: makeSelectSelectedToppings()
});

export function mapDispatchToProps(dispatch) {
  return {
    onSubmit: (payload, paymentMode) => {
      const payloadWithPaymentMethod = {
        ...payload,
        payment_methods: {
          code: paymentMode.code,
          channel_code: paymentMode.channel_code
        }
      };

      if (!isEmpty(paymentMode)) {
        dispatch(
          OMS.V4.postPaymentRequest(
            payloadWithPaymentMethod,
            usePaymentResponseHandler
          )
        );
      }
    },
    saveAndGetPaymentCardDetails: (params, callback, version) => {
      dispatch(
        Payment.V3.saveAndGetPaymentCardDetails(params, callback, version)
      );
    },
    fetchUpfrontPaymentDetails: (params, callback) => {
      dispatch(fetchUpfrontPaymentDetails(params, callback));
    },
    processCardUpdatePayment: (params, callback) => {
      dispatch(Payment.V3.processCardUpdatePayment(params, callback));
    },
    processUpfrontPayment: (params, callback) => {
      dispatch(processUpfrontPayment(params, callback));
    },
    fetchPaymentStatus: (params, callback) => {
      dispatch(fetchPaymentStatus(params, callback));
    },
    clearPaymentDetails: () => {
      dispatch(clearData("paymentDetails"));
    },
    showPaidyMoreInfoModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "PaidyMoreInfoModal" }
        })
      );
    },
    hidePaidyMoreInfoModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
    },
    showTermsAndConditionsModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "TermsAndConditionsModal" }
        })
      );
    },
    closePaidyFailureModal: fromRoute => {
      const url =
        fromRoute === "data_only" ? paths.DATA_ONLY_PAYMENT : paths.PAYMENT;
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
      gotoUrl(pathWithChannel(url), true, {});
    },
    closePaymentMethodUpdateModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
      const defaultReturnUrl = pathWithChannel(
        paths.PAYMENT_DETAILS_PAGE,
        "manage"
      );
      // supporting older app versions before Paidy
      if (urlParam("return_url") && isWebView()) {
        const returnUrl = urlParam("return_url");
        gotoUrl(returnUrl, false, {}, true);
      } else {
        const returnUrl = urlParam("deepLink") || defaultReturnUrl;
        gotoUrl(returnUrl, true, {}, false);
      }
    }
  };
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  verifyPaymentHiddenFormSubmission: () => {
    const actionObject = get(
      stateProps,
      "processPaymentData.action_object",
      {}
    );
    const { url, method, data } = actionObject;

    if (url && !isEmpty(data)) {
      const form = document.createElement("form");
      form.setAttribute("method", method);
      form.setAttribute("action", url);

      forEach(data, (value, key) => {
        const hiddenField = document.createElement("input");
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("name", key);
        hiddenField.setAttribute("value", value);
        form.appendChild(hiddenField);
      });

      document.body.appendChild(form);
      form.submit();
    }
  }
});

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.PAYMENT_FORM,
    destroyOnUnmount: false
  }),
  memo
)(PaymentHOC(SmartRender));

const addPaymentInfoData = (details = {}) => {
  const {
    customer_id,
    plan_name,
    plan_type,
    coupon,
    coupon_type,
    sim_type,
    iccid,
    journey_type,
    number_type,
    currency,
    item_name,
    item_id,
    item_category,
    item_variant,
    order_ref
  } = details;

  return {
    event: GA4EVENTS.ADD_PAYMENT_INFO,
    ecommerce: {
      customer_id,
      order_ref: iccid || order_ref,
      plan_name,
      journey_type,
      plan_type,
      sim_type,
      number_type,
      coupon,
      coupon_type,
      currency,
      value: 0,
      tax: 0,
      shipping: 0,
      sim_count: 1,
      payment_type: "credit-card",
      items: [
        {
          item_name,
          item_id,
          price: 0,
          item_brand: "povo",
          item_category,
          item_variant,
          index: 0,
          quantity: 1
        }
      ]
    }
  };
};

const purchaseInitEventData = (details = {}) => {
  const {
    plan_name,
    plan_type,
    coupon,
    coupon_type,
    sim_type,
    iccid,
    journey_type,
    number_type,
    customer_id,
    order_ref,
    tax,
    shipping,
    currency
  } = details;

  return {
    event: GA4EVENTS.PURCHASE_INITIATED,
    ecommerce: {
      customer_id,
      transaction_id: iccid || order_ref,
      plan_name,
      journey_type,
      plan_type,
      sim_type,
      number_type,
      value: 0,
      tax,
      shipping,
      currency,
      coupon,
      coupon_type,
      sim_count: 1
    }
  };
};
