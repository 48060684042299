import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Text } from "ui-components";
import { COLORS } from "ui-components/theme";
import { isMobile } from "utils/helpers";
import get from "lodash/get";

const primaryBlue = COLORS.blue[4];
const lightBlue = COLORS.blue[0];
const gray = COLORS.gray[0];
const darkGray = COLORS.gray[3];
const white = COLORS.white;

const NumberSelectButton = ({
  label,
  value,
  selected,
  onSelect,
  buttonTheme
}) => {
  const primaryColor = get(buttonTheme, ["color"], primaryBlue);
  const bgColor = get(buttonTheme, ["backgroundColor"], lightBlue);
  const color = selected ? `${primaryColor} !important` : darkGray;
  const backgroundColor = selected ? `${bgColor} !important` : white;
  const pickerStyles = {
    lineHeight: 1.5,
    margin: "0 !important",
    "&:hover": {
      backgroundColor: `${isMobile() ? white : bgColor}`,
      borderColor: primaryColor
    },
    borderRadius: 12,
    minWidth: "90px !important",
    padding: "0.5rem 0.25rem",
    backgroundColor: backgroundColor,
    color: color,
    borderColor: selected ? primaryColor : gray
  };

  return (
    <Box sx={{ px: 1, py: 1 }} width={["49%", "32%"]}>
      <Button
        data-testid="number-select-button"
        width={1}
        sx={pickerStyles}
        onClick={() => onSelect(value)}
      >
        <Text fontWeight="bold" color={"inherit"} fontSize={[12, 14, 16]}>
          {label}
        </Text>
      </Button>
    </Box>
  );
};

NumberSelectButton.defaultProps = {
  label: "",
  value: ""
};

NumberSelectButton.propTypes = {
  onSelect: PropTypes.func,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

export default NumberSelectButton;
