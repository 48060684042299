import { createSelector } from "reselect";
import get from "lodash/get";

export const userKYCDetailsDomain = state =>
  get(state, "api.userKycDetails.data", []);

export const userLineNumberSelector = createSelector(
  userKYCDetailsDomain,
  kycDetails => {
    const lineNumbers = get(kycDetails, "phone_numbers");
    return lineNumbers
      ? lineNumbers.map(number => ({ label: number, value: number }))
      : [];
  }
);

export const noActiveAUNumbersSelector = createSelector(
  userKYCDetailsDomain,
  kycDetails => {
    const lineNumbers = get(kycDetails, "phone_numbers", []) || [];
    return lineNumbers.length < 1;
  }
);

export const userDOBFromKYCDetailsSelector = createSelector(
  userKYCDetailsDomain,
  kycDetails => get(kycDetails, "birthday")
);

export const makeSelectNoActiveAUNumbersSelector = () =>
  noActiveAUNumbersSelector;
export const makeSelectUserLineNumbers = () => userLineNumberSelector;
export const makeSelectUserDOBFromKYCDetailsSelector = () =>
  userDOBFromKYCDetailsSelector;
