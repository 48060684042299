import { createSelector } from "reselect";
import get from "lodash/get";

export const liquidOcrDataDomain = state =>
  get(state.api, "liquidOcrData.data", {});

// ***************************************************************
// ********************** locale makeSelect **********************
// ***************************************************************

export const liquidOcrDataSelector = createSelector(
  liquidOcrDataDomain,
  liquidOcrData => liquidOcrData
);

export const makeSelectLiquidOcrData = () => liquidOcrDataSelector;
