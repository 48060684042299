import { createSelector } from "reselect";
import {
  deliveryInfoSelector,
  paymentModeSelector,
  personalDetailsSelector,
  localeSelector,
  numberFormatSelector,
  deliverySlotsSelector,
  userDetailsConfirmationSelector,
  completeOrderParamsSelector,
  paymentReturnChannelsWithoutDateTimeSelector
} from "ducks/selectors";
import {
  addressParams,
  constructLocalizedDeliveryAddress,
  constructLocalizedDeliverySlotDetails,
  constructLocalizedDeliverySlotLabel,
  formatDisplayNumber,
  compactJoin,
  constructDeliveryDateTime,
  getChannelFromUrl,
  formatContactNumber,
  formatMessage
} from "utils/helpers";
import orderBy from "lodash/orderBy";
import values from "lodash/values";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import includes from "lodash/includes";

export const makeSelectDeliveryDetailsItems = () =>
  createSelector(
    deliveryInfoSelector,
    personalDetailsSelector,
    userDetailsConfirmationSelector,
    completeOrderParamsSelector(),
    localeSelector,
    numberFormatSelector,
    deliverySlotsSelector,
    paymentReturnChannelsWithoutDateTimeSelector,
    (
      deliveryInfo,
      personalDetails,
      userDetailsConfirmation,
      orderParams,
      locale,
      numberFormat,
      deliverySlots,
      paymentReturnChannelsWithoutDateTime
    ) => {
      const formData = isEmpty(deliveryInfo)
        ? isEmpty(personalDetails)
          ? userDetailsConfirmation
          : personalDetails
        : deliveryInfo;
      if (isEmpty(formData)) return {};
      const full_name =
        get(formData, "full_name") ||
        get(orderParams, "user_detail.full_name") ||
        get(personalDetails, "full_name");
      const first_name =
        get(formData, "first_name") ||
        get(orderParams, "user_detail.first_name") ||
        get(personalDetails, "first_name");
      const middle_name =
        get(formData, "middle_name") ||
        get(orderParams, "user_detail.middle_name") ||
        get(personalDetails, "middle_name");
      const last_name =
        get(formData, "last_name") ||
        get(orderParams, "user_detail.last_name") ||
        get(personalDetails, "last_name");
      const name =
        full_name || compactJoin([first_name, middle_name, last_name], " ");
      const countryCode =
        get(formData, "country_code") || get(numberFormat, "country_code");

      const birthDate =
        get(formData, "dobDay") ||
        get(orderParams, "user_detail.dobDay") ||
        get(personalDetails, "dobDay");
      const birthMonth =
        get(formData, "dobMonth") ||
        get(orderParams, "user_detail.dobMonth") ||
        get(personalDetails, "dobMonth");
      const birthYear =
        get(formData, "dobYear") ||
        get(orderParams, "user_detail.dobYear") ||
        get(personalDetails, "dobYear");
      const dateOfBirth = `${birthYear?.label} ${birthMonth?.label} ${birthDate?.label}`;

      const contact_number = get(
        formData,
        "contact_number",
        get(
          orderParams,
          "user_detail.phone_number",
          get(personalDetails, "contact_number", "")
        )
      );
      const contactNumber = formatContactNumber(
        contact_number,
        numberFormat,
        countryCode
      );
      const contactEmail = get(
        formData,
        "email",
        get(personalDetails, "email", "")
      );
      const slotDateTime = get(formData, "delivery_slot_full_data") || "";
      const deliverySlotLabel = get(formData, "delivery_slot_label") || "";
      const slotId = get(formData, "delivery_slot_id") || 0;
      const slotData = find(deliverySlots, {
        id: slotId,
        date_time: slotDateTime
      });
      const hideDeliveryDateTime = includes(
        paymentReturnChannelsWithoutDateTime,
        getChannelFromUrl()
      );
      const slot = constructLocalizedDeliverySlotDetails(slotDateTime, locale);
      const { deliveryDate, deliveryTime } = slot;
      const endDate = get(slotData, "range_end_date");
      const deliveryEndDate = endDate
        ? constructLocalizedDeliverySlotLabel(endDate, locale)
        : null;
      const deliveryAddressDetails = addressParams(formData, "delivery");
      const deliveryAddress = constructLocalizedDeliveryAddress(
        deliveryAddressDetails,
        locale
      );
      const billingAddressDetails = addressParams(formData, "billing");
      const billingAddress = constructLocalizedDeliveryAddress(
        billingAddressDetails,
        locale
      );
      const deliveryDateTime = hideDeliveryDateTime
        ? undefined
        : constructDeliveryDateTime({
            deliveryDate: compactJoin([deliveryDate, deliveryEndDate], " - "),
            deliveryTime,
            locale
          });
      const selected_number = get(orderParams, "selected_number");
      const selectedNumber = formatDisplayNumber(selected_number, numberFormat);
      const portNumber =
        get(formData, "port_number") ||
        get(orderParams, "portin_detail.port_number");
      const isPortInRequest = get(formData, "is_port_in_request", portNumber);
      const portDonor =
        get(formData, "port_donor") ||
        get(orderParams, "portin_detail.port_donor");
      const portinNumber = portNumber
        ? formatDisplayNumber(portNumber, numberFormat)
        : "";
      const portinNumberFromDonorMessage = get(
        locale,
        "portin_number_from_donor",
        "{portin_number} from {donor}"
      );
      const portinNumberFromDonor =
        portinNumber && portDonor
          ? formatMessage(portinNumberFromDonorMessage, {
              portin_number: portinNumber,
              donor: portDonor
            })
          : portinNumber;
      return {
        name,
        isPortInRequest,
        portDonor,
        portinNumber,
        portinNumberFromDonor,
        selectedNumber,
        alternateContactNumber: contactNumber,
        currentContactNumber: contactNumber,
        firstName: first_name,
        middleName: middle_name,
        lastName: last_name,
        dateOfBirth,
        contactNumber,
        contactEmail,
        deliveryAddress,
        billingAddress,
        deliveryDate,
        deliveryTime,
        deliveryDateTime,
        deliverySlotLabel,
        ...deliveryAddressDetails
      };
    }
  );

export const makeSelectPaymentModes = () =>
  createSelector(paymentModeSelector, paymentModes => {
    return orderBy(values(paymentModes), ["display_sequence"], ["asc"])
      .filter(paymentMode => paymentMode.enable)
      .map(method => {
        return {
          ...method,
          id: method.action,
          label: method.label,
          value: method.action
        };
      });
  });
