import { createSelector } from "reselect";
import get from "lodash/get";

const paymentDetailsDomain = state => get(state, "ui.paymentUpdate", {});

export const getSelectedPaymentTypeToEdit = createSelector(
  paymentDetailsDomain,
  paymentDetails => get(paymentDetails, "type", false)
);

export const makeSelectedPaymentTypeToEdit = () => getSelectedPaymentTypeToEdit;
