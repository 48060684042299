import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectIsFetchingData,
  makeSelectOrderRef,
  makeSelectSessionToken
} from "ducks/selectors";
import { useState, useEffect } from "react";
import { gotoUrl, pathWithChannel } from "utils/helpers";
import { OMS, setPollingPaymentStatus } from "ducks/actions";
import { makeSelectPollingPaymentStatus } from "ducks/selectors/ui/payment";
import { DATA_ONLY_CHANNERL, paths, PAYMENT_STATUSES } from "utils/constants";

function usePaymentStatus() {
  const dispatch = useDispatch();
  const order_ref = useSelector(makeSelectOrderRef());
  const inprogressPolling = useSelector(makeSelectPollingPaymentStatus());
  const token = useSelector(makeSelectSessionToken());
  const isFetchingPaymentReturn = useSelector(
    makeSelectIsFetchingData("paymentReturn")
  );

  const [pollingStarted, setPollingStarted] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    //console logs are added for testing purpose
    console.log("BB inprogressPolling", inprogressPolling);
    if (inprogressPolling) {
      console.log("BB setPollingStarted in use effect");
      // setPollingStarted(true);
      //startPaymentStatusPolling();
    }
    // Clean up function to remove the stored pollingStarted value
    return () => {
      dispatch(setPollingPaymentStatus(false));
    };
  }, [dispatch, inprogressPolling]);

  const startPaymentStatusPolling = () => {
    console.log("BB inprogressPolling called");
    setPollingStarted(true);
    dispatch(setPollingPaymentStatus(true));
    const pollPaymentStatus = () => {
      if (!isFetchingPaymentReturn) {
        const params = { order_ref, token };
        dispatch(
          OMS.V4.fetchPaymentReturn(params, (result = {}, error) => {
            if (!error && result.payment_status === PAYMENT_STATUSES.SUCCESS) {
              setPaymentStatus(PAYMENT_STATUSES.SUCCESS);
              gotoUrl(
                pathWithChannel(paths.E_SIM_PROCESSING, DATA_ONLY_CHANNERL)
              );
            } else if (
              !error &&
              result.payment_status === PAYMENT_STATUSES.FAILED
            ) {
              setPaymentStatus(PAYMENT_STATUSES.FAILED);
            } else {
              setTimeout(pollPaymentStatus, 3000); // Poll every 5 seconds
            }
          })
        );
      }
    };
    pollPaymentStatus();
  };

  return {
    pollingStarted,
    startPaymentStatusPolling,
    paymentStatus
  };
}

export default usePaymentStatus;
