import React, { useState } from "react";
import { Flex, Box, Text, Button } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import get from "lodash/get";
import { getButtonColorVariant } from "ui-components/theme";
import styles from "./styles";
import { Field } from "redux-form";

const PlanTypeSelection = props => {
  const {
    themeColors,
    next,
    planTypes: eligiblePlans = [],
    change,
    field
  } = props;
  const buttonTheme = get(themeColors, "button", {});
  const nextButtonVariant = get(next, "variant", "primary");
  const nextButtonWidth = get(next, "width", "fit-content");
  const nextButtonStyles = getButtonColorVariant(
    buttonTheme,
    nextButtonVariant
  );

  const [selectedPlan, setSelectedPlan] = useState({});

  const handleOnclick = planItem => {
    setSelectedPlan(planItem.plan_type);
    change(field, planItem.plan_type);
  };
  const PlanType = () => {
    return (
      <>
        {eligiblePlans.map((plan, index) => {
          const planItem = {
            title_message: plan?.title_message,
            sub_title_message: plan?.sub_title_message,
            message: plan?.message,
            button_lable: plan?.button_lable,
            plan_type: plan?.plan_type
          };
          return (
            <PlanTypes
              planItem={planItem}
              index={index}
              onClick={handleOnclick}
              nextButtonStyles={nextButtonStyles}
              nextButtonWidth={nextButtonWidth}
              selectedPlan={selectedPlan}
            />
          );
        })}
      </>
    );
  };

  return (
    <Flex flexWrap="wrap" mx={-1} mb={3} flexDirection="column">
      <Field name={"selected_plan_type"} component={PlanType} />
    </Flex>
  );
};

export const PlanTypes = props => {
  const {
    planItem,
    onClick,
    nextButtonWidth,
    nextButtonStyles,
    selectedPlan
  } = props;
  return (
    <Flex
      flexWrap="wrap"
      mx={-1}
      mb={3}
      flexDirection="column"
      style={
        selectedPlan === planItem.plan_type
          ? styles.selectedFlex
          : styles.unSelectedFlex
      }
    >
      <Box style={{ padding: "11px", fontSize: "14px", lineHeight: "1.2" }}>
        <Text>
          <FormattedMessage html id={planItem.title_message} />
          <Text>
            <FormattedMessage html id={planItem.sub_title_message} />
          </Text>
        </Text>
      </Box>
      <Box
        style={{
          backgroundColor: "#F0F3F7",
          padding: "11px",
          fontSize: "14px",
          lineHeight: "1.6",
          borderRadius: "0 0 12px 12px"
        }}
      >
        <Text>
          <FormattedMessage html id={planItem.message} />
        </Text>
        <Box style={{ textAlign: "center" }}>
          <Button
            onClick={() => onClick(planItem)}
            disabled={false}
            data-testid={"next-button"}
            width={nextButtonWidth}
            sx={nextButtonStyles}
            style={
              selectedPlan === planItem.plan_type
                ? styles.selectedFlexButton
                : styles.unSelectedFlexButton
            }
            padding="0.25rem"
          >
            <Text
              style={
                selectedPlan === planItem.plan_type
                  ? styles.selectedButtonColors
                  : {}
              }
            >
              {selectedPlan === planItem.plan_type ? (
                <FormattedMessage html id={"choose"} />
              ) : (
                <FormattedMessage html id={planItem.button_lable} />
              )}
            </Text>
          </Button>
        </Box>
      </Box>
    </Flex>
  );
};

export default PlanTypeSelection;
