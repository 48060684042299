import React, { useState } from "react";
import Spinner, { styles } from "components/Spinner";
import PAGE_NAVIGATION from "smart-components/PAGE_NAVIGATION";

const defaultIframeStyles = {
  height: "100%",
  width: "100%"
};

const defaultBtnData = {
  data: {
    next: {
      label: "close",
      ignoreLoader: true,
      ignoreForm: true,
      onsubmit: "hideModal",
      styles: {
        borderRadius: "24px",
        padding: "0.5rem",
        marginTop: "20px",
        width: "80%"
      },
      width: "100%"
    }
  }
};

const IFrame = (props = {}) => {
  const { data = {} } = props;
  const {
    style = {},
    src,
    title = "",
    buttonData = {},
    showActionButton = false
  } = data;

  const [iFrameLoading, setIsIFrameLoading] = useState(true);

  return (
    <>
      {iFrameLoading && <Spinner sx={styles.halfModal} />}
      <iframe
        src={src}
        style={{ ...defaultIframeStyles, ...style }}
        title={title}
        onLoad={() => setIsIFrameLoading(false)}
        onError={() => setIsIFrameLoading(false)}
      />

      {showActionButton && (
        <PAGE_NAVIGATION {...props} {...defaultBtnData} {...buttonData} />
      )}
    </>
  );
};

export default IFrame;
