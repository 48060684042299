import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import SmartRender from "containers/SmartRender";
import Spinner, { styles } from "components/Spinner";
import {
  gotoUrl,
  handleOnLoadAction,
  nextPageWithParams,
  isWebView,
  pathWithChannel
} from "utils/helpers";
import {
  makeSelectOrderRef,
  makeSelectSessionToken,
  makeSelectEsimQRImage,
  makeSelectIsEsimQRFetching,
  makeSelectFormattedSelectedNumber,
  makeSelectAuthToken,
  makeSelectGuestOrderEsimProfile,
  makeSelectWorkflow,
  makeSelectVerifiedEmail,
  makeSelectESimDownloadOption,
  makeSelectGuestOrder,
  makeSelectIsFetchingData,
  makeSelectUserEmail
} from "ducks/selectors";
import { forms, paths } from "utils/constants";
import { OMS } from "ducks/actions";
import { NativeAppFunctions } from "utils/app-bridge/NativeAppDetails";
import validate from "validations";
import get from "lodash/get";
import { featureFlagDataOnlyPlanEnabled } from "utils/featureFlags";
import { ADJUST_EVENTS } from "constants/AnalyticsTypeConstants";
import AdjustAnalytics from "analytics/type/AdjustAnalytics/AdjustAnalytics";

const ESimActivationHOC = Component =>
  function Comp(props) {
    const { fetchUserOrders, esimProfile = {}, isFetchingOrderState } = props;
    const { smdp_server_address: smdpAddress, matching_id: activationCode } =
      esimProfile || {};

    const dispatch = useDispatch();
    const workflow = useSelector(makeSelectWorkflow());
    const ORDER_REF = useSelector(makeSelectOrderRef());
    const SESSION_TOKEN = useSelector(makeSelectSessionToken());
    const isFetchingQRCode = useSelector(makeSelectIsEsimQRFetching());
    const QR_IMG_BASE64_STRING = useSelector(makeSelectEsimQRImage());
    const currentPath = window.location.pathname;
    /**
     * Makes the API call to fetch Esim activation url
     */
    const getEsimQR = () => {
      const data = {
        order_ref: ORDER_REF,
        session_token: SESSION_TOKEN
      };
      dispatch(OMS.V4.fetchEsimQR({}, data));
    };

    useEffect(() => {
      if (currentPath === pathWithChannel(paths.E_SIM_PROCESSING)) {
        AdjustAnalytics.pushEvent(ADJUST_EVENTS.ESIM_PROCESSING);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Download the Server provided Esim QR code
     */
    const downloadQr = () => {
      const downloadAnchor = document.createElement("a");
      downloadAnchor.href = `${QR_IMG_BASE64_STRING}`;
      downloadAnchor.download = "esim_activation_qrcode.jpeg";
      downloadAnchor.click();
    };

    /**
     * Download the Server provided Esim QR code
     */
    const downloadEsimProfile = () => {
      const { smdp_server_address: smdpAddress, matching_id } =
        props.esimProfile || {};
      NativeAppFunctions.requestDownloadEsimProfile({
        smdpUrl: smdpAddress,
        macthingID: matching_id,
        androidActivationCode: `LPA:1$${smdpAddress}$${matching_id}`
      });
    };

    useEffect(() => {
      ORDER_REF && handleOnLoadAction({ ...props, workflow, getEsimQR });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ORDER_REF]);

    const refreshAndHandleNavigation = () => {
      fetchUserOrders((result, error) => {
        if (!error && result && result.length > 0) {
          const userOrder = result[0];
          const nextStepAction = get(userOrder, "next_step.action", "");
          if (nextStepAction === "configure_esim") {
            if (isWebView()) {
              gotoUrl(pathWithChannel(paths.E_SIM_SET_UP_BY_API));
            } else {
              gotoUrl(pathWithChannel(paths.E_SIM_SET_UP));
            }
          }
        }
      });
    };

    /**
     * Loading screen is shown until sim qr code is available
     */
    if (isFetchingQRCode) return <Spinner size={200} sx={styles.fullPage} />;

    return (
      <Component
        {...props}
        getEsimQR={getEsimQR}
        downloadQr={downloadQr}
        downloadEsimProfile={downloadEsimProfile}
        baseUrl={QR_IMG_BASE64_STRING}
        smdpAddress={smdpAddress}
        activationCode={activationCode}
        refreshAndHandleNavigation={refreshAndHandleNavigation}
        loading={featureFlagDataOnlyPlanEnabled() && isFetchingOrderState}
      />
    );
  };

const mapStateToProps = createStructuredSelector({
  selectedNumber: makeSelectFormattedSelectedNumber(),
  authToken: makeSelectAuthToken(),
  esimProfile: makeSelectGuestOrderEsimProfile(),
  verifiedEmail: makeSelectVerifiedEmail(),
  eSimDownloadOption: makeSelectESimDownloadOption(),
  order: makeSelectGuestOrder(),
  isFetchingOrderState: makeSelectIsFetchingData("userOrders"),
  LoggedUserEmail: makeSelectUserEmail()
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  nextPage: nextPageWithParams(
    paths.DASHBOARD,
    stateProps.selectedNumber,
    stateProps.authToken
  )
});

export function mapDispatchToProps(dispatch) {
  return {
    fetchUserOrders: callBack => {
      dispatch(OMS.V4.fetchUserOrders({}, callBack));
    }
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.E_SIM_SETUP_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(ESimActivationHOC(SmartRender));
