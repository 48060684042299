import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Field } from "redux-form";
import map from "lodash/map";
import { Label, Flex, Box, Button, Text } from "ui-components";
import FieldError from "./FieldError";
import { COLORS } from "ui-components/theme";

const RadioButtonGroup = props => {
  const {
    options,
    field,
    label,
    required,
    change,
    onChange,
    singleOptionRow
  } = props;
  const name = field || "unnamed_delivery_slot_group";

  return (
    <Box mb={2} data-testid={name}>
      {label && (
        <Label>
          <FormattedMessage id={label} />
          &nbsp;
          {required && (
            <Text as="span" color={COLORS.red[1]}>
              *
            </Text>
          )}
        </Label>
      )}

      <Field
        component={RadioOptions}
        name={name}
        required={required}
        options={options}
        change={change}
        onChange={onChange}
        singleOptionRow={singleOptionRow}
      />
    </Box>
  );
};

const baseButtonStyles = {
  borderRadius: 2,
  fontFamily: "'Roboto', sans-serif !important",
  backgroundColor: "white !important",
  width: "100%",
  margin: "0 !important",
  "&:hover": {
    color: "blue.4",
    borderColor: "blue.4"
  }
};

const RadioOptions = props => {
  const {
    singleOptionRow,
    onChange,
    input,
    meta: { touched, error },
    options
  } = props;

  return (
    <>
      <Flex flexWrap="wrap" mx={-1}>
        {map(options, o => {
          const selected = o.value === input.value;
          const buttonStyles = {
            ...baseButtonStyles,
            color: selected ? "blue.4" : "gray.1",
            borderColor: selected ? "blue.4" : "#c6c6c6"
          };

          return (
            <Box
              key={o.value}
              sx={{ p: 1 }}
              width={singleOptionRow ? [1, 1, 1] : [1 / 2, 1 / 3, 1 / 3]}
            >
              <Button
                type="button"
                onClick={() => {
                  onChange ? onChange(o.value) : input.onChange(o.value);
                }}
                sx={{ ...buttonStyles }}
              >
                <Box>
                  <Text size="sm" fontWeight={700}>
                    {o.label}
                  </Text>
                </Box>

                {o.description && (
                  <Box mt={1} sx={{ height: "auto" }}>
                    <Text size="xs">{o.description}</Text>
                  </Box>
                )}
              </Button>
            </Box>
          );
        })}
      </Flex>

      <FieldError touched={touched} error={error} />
    </>
  );
};

RadioButtonGroup.defaultProps = {
  options: [],
  label: ""
};

export default RadioButtonGroup;
