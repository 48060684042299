import React from "react";
import FormattedMessage from "components/FormattedMessage";
import Addons from "components/Addons";
import PlanDetails from "components/PlanDetails";
import TotalPrice from "components/TotalPrice";
import PromoCodeBenefits from "components/PromoCodeBenefits";
import NumberItem from "components/NumberItem";
import DeliveryFeeItem from "components/DeliveryFeeItem";
import TaxAmountItem from "components/TaxAmountItem";
import { Box, H1 } from "ui-components";
import { iconThemes } from "ui-components/theme";
import get from "lodash/get";
import round from "lodash/round";
import { PREPAID_PLAN_SUB_TYPES } from "utils/constants";

const OrderSummaryCard = ({
  addons,
  defaultPlanAddons,
  planDetails,
  payMonthlyItems,
  payTodayItems,
  selectedNumber,
  referralCode,
  data = {},
  themeColors
}) => {
  const planName = get(planDetails, "name", "");
  const planSubType = get(planDetails, "sub_type", "");
  const isPrepaid = PREPAID_PLAN_SUB_TYPES.includes(planSubType);
  const paySummaryItems = isPrepaid ? payTodayItems : payMonthlyItems;
  const totalAmountWithoutTax = get(
    paySummaryItems,
    "totalAmountWithoutTax",
    0
  );
  const totalTaxAmount = get(paySummaryItems, "totalTaxAmount", 0);
  const totalTaxPrice = get(paySummaryItems, "totalTaxPrice");
  const totalTaxPercent = round(
    (totalTaxAmount / totalAmountWithoutTax) * 100,
    2
  );
  const planColors = get(data, ["planColors", planName]) || {};
  const primary = get(data, "primaryColor", planColors.primary);
  const primaryColor =
    get(iconThemes, [primary]) ||
    get(themeColors, "primary") ||
    iconThemes.blue;
  const textColor =
    get(iconThemes, [planColors.text], planColors.text) ||
    get(data, "titleColor", primaryColor);
  const priceColor =
    get(iconThemes, [planColors.price], planColors.price) ||
    get(data, "priceColor", iconThemes.white);
  const iconColor =
    get(iconThemes, [planColors.icon], planColors.icon) ||
    get(themeColors, "icon", primaryColor);
  const excludeTax = get(data, "excludeTax");
  const priceFontSize = get(data, "priceFontSize");
  const summaryLabel = get(data, "summaryLabel");
  const monthlyAddonLabel = get(data, "monthlyAddonLabel");
  const disclaimerText = get(data, "disclaimerText");

  const defaultAddonsTitle = get(
    data,
    "defaultAddonsTitle",
    "default_addons_title"
  );
  return (
    <Box
      variant="borderSection"
      mb={8}
      px={[0, 0]}
      py={"20px"}
      sx={{ color: primaryColor, borderColor: primaryColor }}
    >
      <Box px={"20px"} mb={4}>
        <H1 color={textColor}>
          <FormattedMessage html id={get(data, "title", "payment_summary")} />
        </H1>
      </Box>
      <TotalPrice
        {...paySummaryItems}
        bgColor={primaryColor}
        priceColor={priceColor}
        priceFontSize={priceFontSize}
        summaryLabel={summaryLabel}
      />
      <PlanDetails
        planDetails={planDetails}
        items={get(paySummaryItems, "items")}
        primaryColor={iconColor}
        {...data}
      />
      <NumberItem selectedNumber={selectedNumber} primaryColor={iconColor} />
      <Addons
        data-testid="default-addons"
        addons={defaultPlanAddons}
        primaryColor={iconColor}
        excludeTax={excludeTax}
        title={defaultAddonsTitle}
        monthlyAddonLabel={monthlyAddonLabel}
      />
      <Addons
        data-testid="addons"
        addons={addons}
        primaryColor={iconColor}
        excludeTax={excludeTax}
        monthlyAddonLabel={monthlyAddonLabel}
      />
      <DeliveryFeeItem payTodayItems={payTodayItems} primaryColor={iconColor} />
      <PromoCodeBenefits
        items={get(payTodayItems, "items")}
        referralCode={referralCode}
      />
      {excludeTax && totalTaxAmount > 0 && (
        <TaxAmountItem
          taxPrice={totalTaxPrice}
          taxPercent={totalTaxPercent}
          primaryColor={iconColor}
        />
      )}
      {disclaimerText && (
        <Box px="1rem" color={textColor}>
          <FormattedMessage html id={disclaimerText} />
        </Box>
      )}
    </Box>
  );
};

OrderSummaryCard.defaultProps = {
  addons: [],
  planDetails: {},
  payTodayItems: {},
  selectedNumber: {},
  referralCode: {},
  data: {}
};

export default OrderSummaryCard;
