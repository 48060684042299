import React from "react";
import { Flex, Box, Text } from "ui-components";
import { COLORS } from "ui-components/theme";
import FormattedMessage from "components/FormattedMessage";
import get from "lodash/get";

const DataAddonSummaryTile = props => {
  const { data, selectedToppings = [], styles, appSettings } = props;
  const currencySymbol = get(
    appSettings,
    "generic.currency_symbol_after_price"
  );
  return (
    <Box>
      <Flex
        flexWrap="wrap"
        mx={-1}
        mb={3}
        flexDirection="column"
        style={styles}
      >
        <Box
          variant="borderSection"
          sx={{ borderColor: COLORS.gray[1] }}
          style={{
            padding: "8px",
            borderRadius: "20px 20px 0 0",
            height: "5rem"
          }}
        >
          {selectedToppings?.map((item, index) => {
            return (
              <Text
                key={index}
                style={{
                  padding: "8px",
                  borderRadius: "20px 20px 0 0",
                  top: "15px",
                  position: "relative"
                }}
              >
                1. {item.name}
              </Text>
            );
          })}
        </Box>
        <Box
          variant="borderSection"
          sx={{ borderColor: "#333333" }}
          style={{
            borderRadius: "0 0 20px 20px",
            position: "relative",
            height: "3.5rem",
            padding: "8px",
            backgroundColor: "#333333"
          }}
        >
          <Text style={{ color: "#F7F7F7", position: "relative", top: "16px" }}>
            <FormattedMessage id={data.messages} />
          </Text>
          <Text style={{ color: "#F7F7F7", float: "right" }}>
            {totalPurchacePrice(selectedToppings, currencySymbol)}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

export const totalPurchacePrice = (data, currencySymbol) => {
  let totalPrice = 0;
  let finalPrice;
  for (const item of data) {
    if (typeof item?.price === "string") {
      finalPrice = item?.price;
    } else {
      finalPrice = totalPrice += Number(item?.price) + currencySymbol;
    }
  }
  return finalPrice ? finalPrice : currencySymbol;
};

export default DataAddonSummaryTile;
