import { BASE, TELCO_RE_INITIATE_NUMBER_PORT_IN } from "utils/constants";
import { fetchRequested } from "ducks/actions/baseActions";

export const reInitiatePortIn = (params, callback) => {
  return fetchRequested({
    key: "reInitiatePortIn",
    params,
    path: TELCO_RE_INITIATE_NUMBER_PORT_IN,
    base: BASE.TELCO_PORT_IN_SERVICE,
    method: "POST",
    callback
  });
};

export default {
  reInitiatePortIn
};
