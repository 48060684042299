import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, Text } from "ui-components";
import { COLORS, iconThemes } from "ui-components/theme";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import find from "lodash/find";
import { CheckOutline } from "components/Icons";
import * as IconComponents from "components/Icons";

const PlanDetails = ({
  planDetails,
  items,
  hidePlanName,
  hidePlanPrice,
  hidePlanTitle,
  features,
  primaryColor,
  excludeTax,
  planPriceLabel,
  planDetailFilters = {},
  planDetailIcon = {}
}) => {
  const planName = get(planDetails, "textName", "");
  const planItem = find(items, { type: "plan" });
  const planPrice = excludeTax
    ? get(planItem, "price_without_tax")
    : get(planItem, "price");
  const planLabel = get(planItem, "label");
  const {
    hidePayMonthlyItems,
    hidePlanFeatures,
    hideAdditionalFeatures
  } = planDetailFilters;
  const monthlyItems = hidePayMonthlyItems
    ? []
    : map(get(planDetails, "pay_monthly"), "label");
  const planFeatures = hidePlanFeatures
    ? []
    : map(get(planDetails, "features"), "text");
  const additionalFeatures = hideAdditionalFeatures
    ? []
    : map(features, "text");
  const planItems = [...monthlyItems, ...planFeatures, ...additionalFeatures];

  const ItemIcon = ({ iconName, ...props }) => {
    const IconComponent = IconComponents[iconName] || CheckOutline;
    return <IconComponent {...props} />;
  };
  const ItemDetails = ({ items, iconName }) => {
    if (isEmpty(items)) return null;

    return map(items, (item, index) => (
      <Flex py={1} alignItems="flex-start" key={index}>
        <Box minWidth={["24px"]}>
          <ItemIcon iconName={iconName} size="20px" color={primaryColor} />
        </Box>
        <Box px={[1, 2]} textAlign="left">
          <Text>{item}</Text>
        </Box>
      </Flex>
    ));
  };

  return isEmpty(planItems) ? null : (
    <Box mb={5} px={"1rem"} data-testid="plan-details">
      {!hidePlanTitle && (
        <Flex py={1} justifyContent="space-between" alignItems="center">
          <Box paddingRight={1} justifySelf="flex-start" flex={1}>
            <Text color={COLORS.gray[3]} fontWeight="bolder" py="4px">
              {!hidePlanName ? planName : <FormattedMessage id={planLabel} />}
            </Text>
          </Box>
          <Box width={["fit-content"]}>
            {!hidePlanPrice && planPrice && (
              <Text color={primaryColor} fontWeight="bolder" py="4px">
                {planPriceLabel ? (
                  <FormattedMessage
                    id={planPriceLabel}
                    values={{ planPrice }}
                  />
                ) : (
                  planPrice
                )}
              </Text>
            )}
          </Box>
        </Flex>
      )}
      <ItemDetails
        items={monthlyItems}
        iconName={planDetailIcon["payMonthlyItems"]}
      />
      <ItemDetails
        items={planFeatures}
        iconName={planDetailIcon["planFeatures"]}
      />
      <ItemDetails
        items={additionalFeatures}
        iconName={planDetailIcon["additionalFeatures"]}
      />
    </Box>
  );
};

PlanDetails.defaultProps = {
  planDetails: {},
  items: [],
  hidePlanName: false,
  features: [],
  primaryColor: iconThemes.blue
};

export default PlanDetails;
