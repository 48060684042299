import { createSelector } from "reselect";
import get from "lodash/get";
import find from "lodash/find";
import {
  paymentModeSelector,
  orderSelector,
  planServiceSelector,
  formattedSelectedNumberSelector,
  sessionTokenSelector,
  paymentFormSelector
} from "ducks/selectors";
import { paths, BRAND_NAME } from "utils/constants";
import { getChannelFromUrl } from "utils/helpers";

const orderSummaryDomain = state =>
  get(state, "form.OrderSummaryForm.values", {});

export const orderSummarySelector = createSelector(
  orderSummaryDomain,
  orderSummary => orderSummary
);

export const makeSelectOrderSummaryForm = () => orderSummarySelector;

export const makeSelectOrderSummaryFormInitialValues = () =>
  createSelector(paymentModeSelector, paymentModes => {
    const defaultPaymentMode =
      find(paymentModes, paymentMode => {
        return paymentMode.default;
      }) || {};

    return {
      terms_of_service_agreed: true,
      critical_info_consent: false,
      payment_method: defaultPaymentMode
    };
  });

export const makeSelectOrderSummaryPayload = () =>
  createSelector(
    orderSummarySelector,
    orderSelector,
    planServiceSelector,
    formattedSelectedNumberSelector,
    sessionTokenSelector,
    paymentFormSelector,
    (
      orderSummary,
      order,
      planDetails,
      formattedSelectedNumber,
      sessionToken,
      paymentForm
    ) => {
      const { code, channel_code } =
        get(paymentForm, "payment_method") ||
        get(orderSummary, "payment_method") ||
        {};
      const payment_methods = { code, channel_code };
      const order_id = get(order, "order_ref", "");
      const planName = get(planDetails, "textName", BRAND_NAME);
      const selectedNumber = get(formattedSelectedNumber, "formattedNumber");
      const description = `${planName} - ${selectedNumber} #${order_id}`;
      const channel = getChannelFromUrl();
      const origin = window.location.origin;
      const status_path = paths.PAYMENT_STATUS.replace(":channel", channel);
      const status_url = `${origin}${status_path}?order_ref=${order_id}&token=${sessionToken}`;

      return {
        order_id,
        description,
        payment_methods,
        success_redirect_url: status_url,
        failure_redirect_url: status_url,
        token: sessionToken
      };
    }
  );
