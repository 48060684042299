import { getURLParams, urlParam } from "utils/helpers";
import {
  IS_PATHS_SECURED,
  LOCALE,
  SOCIAL_MEDIA_TYPES,
  VALID_LOCALES
} from "utils/constants";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import { v4 as uuid } from "uuid";

export const SOCIAL_MEDIA_STATE = "SocialMediaState";
export const SOCIAL_MEDIA_CLIENT_ID = "SocialMediaClientId";
export const APP_REDIRECT_PATH = "post-login-redirect";
export const USER_TYPE = "user_type";
export const ONE_STOP_PORT_IN_PARAMS = "oneStopPortInParams";
export const ONE_STOP_PORT_OUT_PARAMS = "oneStopPortOutParams";

export const setIpAddress = () => {
  fetch("https://jsonip.com", { mode: "cors" })
    .then(res => res.json())
    .then(res => {
      localStorage.setItem("ipAddress", res.ip);
      console.log("setIpAddress success", res.ip);
    })
    .catch(err => console.log("setIpAddress error", err));
};

export const getIpAddress = () => localStorage.getItem("ipAddress");

export const isWebview = () => getWebview() === "1";

export const getWebview = () => localStorage.getItem("webview");

export const setWebview = () => localStorage.setItem("webview", "1");

export const resetWebview = () => localStorage.removeItem("webview");

export const isNative = () => getNative() === "1";

export const getNative = () => localStorage.getItem("native");

export const setNative = () => localStorage.setItem("native", "1");

export const resetNative = () => localStorage.removeItem("native");

export const setQAParam = () => localStorage.setItem("qa", "1");

const getQAParam = () => localStorage.getItem("qa");

export const isQAApp = () => getQAParam() === "1";

export const isActivationSuccess = () =>
  localStorage.getItem("ActivationSuccess") === "1";

export const setActivationSuccess = () =>
  localStorage.setItem("ActivationSuccess", "1");

export const resetActivationSuccess = () =>
  localStorage.removeItem("ActivationSuccess");

export const getDeviceId = () => localStorage.getItem("deviceId");

export const setDeviceId = deviceId =>
  localStorage.setItem("deviceId", deviceId);

export const resetDeviceId = () => {
  localStorage.removeItem("deviceId");
};

export const getSessionToken = () => localStorage.getItem("sessionToken");

export const setSessionToken = sessionToken =>
  localStorage.setItem("sessionToken", sessionToken);

export const getOrderId = () => localStorage.getItem("orderId");

export const getLocalStorageKey = str => {
  return Object.keys(localStorage).find(key => key.includes(str));
};

export const getYellowmessengerChatHistory = () => {
  const key = getLocalStorageKey("conversation_history");
  if (key) {
    return { key, value: getItem(key) };
  }
  return {};
};

export const getYellowMessengerChatOpen = () => {
  return getItem("yellow-messenger-chat-open");
};

export const setOrderId = orderId => localStorage.setItem("orderId", orderId);

export const setYellowmessengerChatHistory = (key, value) =>
  localStorage.setItem(key, value);

export const setYellowMessengerChatOpen = value => {
  setItem("yellow-messenger-chat-open", value);
};

export const resetOrderId = () => {
  localStorage.removeItem("orderId");
};

export const getOrderRef = () => localStorage.getItem("orderRef");

export const setOrderRef = orderRef =>
  localStorage.setItem("orderRef", orderRef);

export const resetOrderRef = () => {
  localStorage.removeItem("orderRef");
};

export const getLandingPage = () => localStorage.getItem("landingPage") || "/";

export const setLandingPage = landingPage =>
  localStorage.setItem("landingPage", landingPage);

export const resetLandingPage = () => localStorage.removeItem("landingPage");

export const getLandingPageVisited = () =>
  localStorage.getItem("landingPageVisited");

export const setLandingPageVisited = landingPageVisited =>
  localStorage.setItem("landingPageVisited", landingPageVisited);

export const resetLandingPageVisited = () =>
  localStorage.removeItem("landingPageVisited");

export const getLocale = () => localStorage.getItem("locale");

export const getLocaleOrDefault = () => {
  const explicitLocale = (
    urlParam("setlocale") ||
    localStorage?.getItem("locale") ||
    ""
  ).toLowerCase();
  const defaultLocale = (LOCALE || "en").toLowerCase();
  const currentLocale = includes(VALID_LOCALES, explicitLocale)
    ? explicitLocale
    : defaultLocale;
  setLocale(currentLocale);
  return currentLocale;
};

export const setLocale = locale => localStorage?.setItem("locale", locale);

export const setDefaultAddons = addons =>
  localStorage.setItem("default_addons", addons);

export const getDefaultAddons = () => {
  const addonsValue = localStorage.getItem("default_addons");
  let addons = [];
  if (addonsValue) {
    try {
      const parsed = JSON.parse(addonsValue);
      addons = isArray(parsed) ? parsed : [addonsValue];
    } catch (error) {
      console.log(`Error Parsing default addons ${addonsValue} => `, error);
      addons = [addonsValue];
    }
  }
  return addons;
};

export const isLinkAccount = () => getLinkAccount() !== null;

export const getLinkAccount = () => localStorage.getItem("link_account");

export const setLinkAccount = linkAccount =>
  localStorage.setItem("link_account", linkAccount);

export const getRedirectURL = () => localStorage.getItem("return_url");

export const setRedirectURL = redirectURL =>
  localStorage.setItem("return_url", redirectURL);

export const getSupportESimApiFlag = () => localStorage.getItem("use_esim_api");

export const isEnableESimActivationApi = () => getSupportESimApiFlag() === "1";

export const setSupportESimActivationApi = eSimApiSupport =>
  localStorage.setItem("use_esim_api", eSimApiSupport);

export const isAUAccount = () =>
  localStorage.getItem(USER_TYPE) === SOCIAL_MEDIA_TYPES.AU_LOGIN;

export const storeUrlParams = () => {
  if (urlParam("webview")) {
    setWebview();
  }

  if (urlParam("native")) {
    setNative();
  }

  if (urlParam("device_id") && IS_PATHS_SECURED) {
    const deviceIdFromUrl = urlParam("device_id");
    setDeviceId(deviceIdFromUrl);
  }

  if (urlParam("setlocale")) {
    setLocale(urlParam("setlocale"));
  }

  if (urlParam("alpha")) {
    localStorage.setItem("alpha", urlParam("alpha"));
  }

  if (urlParam("localize")) {
    localStorage.setItem("localize", urlParam("localize"));
  }

  if (urlParam("da") || urlParam("addons")) {
    setDefaultAddons(urlParam("da") || urlParam("addons"));
  }

  if (urlParam("link_account")) {
    setLinkAccount(urlParam("link_account"));
  }

  if (urlParam("return_url")) {
    setRedirectURL(urlParam("return_url"));
  }

  if (urlParam("qa")) {
    setQAParam();
  }

  if (urlParam("use_esim_api")) {
    setSupportESimActivationApi(urlParam("use_esim_api"));
  }

  //storing one stop port out url params
  if (urlParam("callback") || urlParam("onestop") || urlParam("param")) {
    setOneStopPortOutParams({
      callback: urlParam("callback"),
      onestop: urlParam("onestop"),
      param: urlParam("param")
    });
  }

  if (urlParam("conviva")) {
    localStorage.setItem("conviva", "true");
  }
};

export const setItem = (key, value) => localStorage.setItem(key, value);
export const getItem = key => localStorage.getItem(key);
export const removeItem = key => localStorage.removeItem(key);
export const removeTimerKey = key =>
  localStorage.removeItem(`${key}.timer.startrecord`);
export const getAndRemoveItem = key => {
  let value = getItem(key);
  removeItem(key);
  return value;
};

export const clearLocalStorage = () => {
  const orderId = getOrderId();
  const { key: ymKey, value: ymHistory } = getYellowmessengerChatHistory();
  const ymChatOpen = getYellowMessengerChatOpen();
  localStorage.clear();
  if (!ymChatOpen !== null) {
    //to keep yellowmessenger chat box open and close as it is on page navigation - (AU only)
    setYellowMessengerChatOpen(ymChatOpen);
  }
  if (ymKey && ymHistory) {
    //to keep yellowmessenger chat box chat data in the localstorage - (AU only)
    setYellowmessengerChatHistory(ymKey, ymHistory);
  }
  if (orderId && orderId !== "null") {
    setOrderId(orderId);
  } else {
    resetOrderId();
  }
};

export const getTxnId = () => {
  const { txn_id } = getURLParams();
  if (txn_id) {
    setItem("txn_id", txn_id);
    return txn_id;
  }

  const txnId = getItem("txn_id");
  if (txnId) return txnId;

  const newTxnId = uuid();
  setItem("txn_id", newTxnId);
  return newTxnId;
};

export const getOneStopPortInParams = () => {
  let oneStopParams = localStorage.getItem(ONE_STOP_PORT_IN_PARAMS);
  return JSON.parse(oneStopParams);
};

export const setOneStopPortInParams = data =>
  localStorage.setItem(ONE_STOP_PORT_IN_PARAMS, JSON.stringify(data));

export const getOneStopPortOutParams = () => {
  let oneStopParams = localStorage.getItem(ONE_STOP_PORT_OUT_PARAMS);
  return JSON.parse(oneStopParams);
};

export const setOneStopPortOutParams = data =>
  localStorage.setItem(ONE_STOP_PORT_OUT_PARAMS, JSON.stringify(data));

export const resetOneStopParams = () => {
  localStorage.removeItem(ONE_STOP_PORT_IN_PARAMS);
  localStorage.removeItem(ONE_STOP_PORT_OUT_PARAMS);
};
