import React from "react";
import AddOnSelection from "components/AddOnSelection";

const ADDONS_CHECKBOX = ({
  addOns,
  defaultAddons,
  handleSelectAddon,
  data,
  themeColors
}) => (
  <AddOnSelection
    items={addOns}
    defaultAddons={defaultAddons}
    onSelect={handleSelectAddon}
    {...data}
    themeColors={themeColors}
  />
);

export default ADDONS_CHECKBOX;
