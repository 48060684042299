import { createSelector } from "reselect";
import get from "lodash/get";
import { localeSelector } from "ducks/selectors";
import { constructPostOfficeDeliverySlots } from "utils/helpers";

export const cityListDomain = state =>
  get(state, "api.cityList.data.cities", []);

export const districtListDomain = state =>
  get(state, "api.districtList.data.districts", []);

export const streetListDomain = state =>
  get(state, "api.streetList.data.streets", []);

export const storeListDomain = state =>
  get(state, "api.storeList.data.stores", []);

export const cityListSelector = createSelector(
  cityListDomain,
  cityList => cityList
);

export const districtListSelector = createSelector(
  districtListDomain,
  districtList => districtList
);

export const streetListSelector = createSelector(
  streetListDomain,
  streetList => streetList
);

export const storeListSelector = createSelector(
  storeListDomain,
  storeList => storeList
);

export const makeSelectCityList = () => cityListSelector;

export const makeSelectDistrictList = () => districtListSelector;

export const makeSelectStreetList = () => streetListSelector;

export const makeSelectStoreList = () =>
  createSelector(storeListSelector, localeSelector, (storeList, locale) => {
    const { delivery_info_working_hours } = locale;
    return constructPostOfficeDeliverySlots(
      storeList,
      delivery_info_working_hours
    );
  });
