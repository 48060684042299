import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, Label, Text } from "ui-components";
import { COLORS, iconThemes } from "ui-components/theme";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import filter from "lodash/filter";
import { PlusOutline } from "components/Icons";

const Addons = ({
  addons,
  primaryColor,
  excludeTax,
  monthlyAddonLabel,
  title = "addons"
}) => {
  const selectedAddons = filter(addons, a => a.selected);

  return isEmpty(selectedAddons) ? null : (
    <Box my={5} px={"1rem"}>
      <Label color={COLORS.gray[3]} mb={2}>
        <FormattedMessage id={title} />
      </Label>
      {map(selectedAddons, (item, index) => {
        const addonsPrice = excludeTax ? item.price_without_tax : item.price;
        return (
          <Flex
            py={1}
            data-testid="addon-box"
            justifyContent="space-between"
            alignItems="center"
            key={index}
          >
            <Box width={["24px"]}>
              <PlusOutline color={primaryColor} />
            </Box>
            <Box px={[1, 2]} justifySelf="flex-start" flex={1}>
              <Text>{item.label}</Text>
            </Box>
            <Box width={["fit-content"]}>
              <Text color={primaryColor} fontWeight="bold">
                {monthlyAddonLabel ? (
                  <FormattedMessage
                    id={monthlyAddonLabel}
                    values={{ addonsPrice }}
                  />
                ) : (
                  addonsPrice
                )}
              </Text>
            </Box>
          </Flex>
        );
      })}
    </Box>
  );
};
Addons.defaultProps = {
  addons: [],
  primaryColor: iconThemes.blue
};

export default Addons;
