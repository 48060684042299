const styles = {
  wrapperStyles: {
    alignItems: "top",
    margin: "0 12px"
  },
  badgeStyles: {
    width: 16,
    height: 16,
    borderRadius: 8,
    "&.processing": {
      // border: "2px solid green"
    }
  }
};

export default styles;
