import React from "react";
import StepperBreadCrumb from "components/StepperBreadCrumb";

const STEPPER_BREAD_CRUMB = ({ data, themeColors, standalone }) => (
  <StepperBreadCrumb
    {...data}
    themeColors={themeColors}
    standalone={standalone}
  />
);

export default STEPPER_BREAD_CRUMB;
