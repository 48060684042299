import { createSelector } from "reselect";
import get from "lodash/get";

const liquidOcrErrorDomain = state => get(state.api, "liquidURL.error", {});
const liquidOcrSuccessDomain = state => get(state.api, "liquidURL.data", {});
const liquidOcrDomain = state => get(state.api, "liquidURL", {});

const liquidSubmitOcrErrorDomain = state =>
  get(state.api, "liquidOCRRegister.error", {});

// ***************************************************************
// ********************** liquid error  makeSelect **********************
// ***************************************************************

export const liquidOcrErrorDataSelector = createSelector(
  liquidOcrErrorDomain,
  liquidUrl => liquidUrl
);

export const liquidSubmitOcrErrorDataSelector = createSelector(
  liquidSubmitOcrErrorDomain,
  liquidSubmitError => liquidSubmitError
);

const liquidUrlSelector = createSelector(
  liquidOcrSuccessDomain,
  ({ application_url } = {}) => application_url
);

const liquidUrlRequestTime = createSelector(liquidOcrDomain, data => {
  return get(data, "completedAt");
});

export const makeSelectLiquidUrlErrorData = () => liquidOcrErrorDataSelector;

export const makeSelectLiquidSubmitErrorData = () =>
  liquidSubmitOcrErrorDataSelector;

export const makeSelectLiquidUrl = () => liquidUrlSelector;
export const makeSelectLiquidUrlRequestTime = () => liquidUrlRequestTime;
