import {
  BASE,
  BILL_DETAILS,
  BILL_PAYMENT_STATUS,
  BILL_PAYMENT_INTENT
} from "utils/constants";
import { fetchRequested } from "ducks/actions/baseActions";

export const fetchOutstandingBillsInfo = (params, callback) => {
  return fetchRequested({
    key: "outstandingBillPayments",
    base: BASE.BILLING,
    path: BILL_DETAILS,
    method: "GET",
    params,
    callback,
    override: true
  });
};

export const fetchOutstandingPaymentStatus = (params, callback) => {
  return fetchRequested({
    key: "outstandingBillPaymentStatus",
    base: BASE.BILLING,
    path: BILL_PAYMENT_STATUS,
    method: "GET",
    params,
    callback,
    override: true
  });
};

export const fetchOutstandingPaymentIntent = (params, callback) => {
  return fetchRequested({
    key: "outstandingBillPaymentIntent",
    base: BASE.BILLING,
    path: BILL_PAYMENT_INTENT,
    method: "POST",
    params,
    callback,
    override: true
  });
};

export default {
  fetchOutstandingBillsInfo,
  fetchOutstandingPaymentStatus,
  fetchOutstandingPaymentIntent
};
