import React, { useState } from "react";
import ReactModal from "react-modal";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, Text, Label, Button } from "ui-components";
import CheckboxField from "components/FormFields/CheckboxField";
import { isMobile } from "utils/helpers";
import { useLoadingSpinner } from "hooks";
import { getButtonColorVariant } from "ui-components/theme";
import get from "lodash/get";

const CriticalInfoModal = props => {
  const {
    id,
    field,
    isOpen,
    closeModal,
    onConfirm,
    details = "details",
    critical_info = "critical_info",
    themeColors,
    publishGA4Events = () => { },
    hideCheckBox,
    hideDefaultCloseButton = false,
    showConfirmButton,
    modalStyle = {}
  } = props;
  const buttonTheme = get(themeColors, "button", {});
  const buttonStyles = {
    ...getButtonColorVariant(buttonTheme)
  };

  const manadatoryReadsEnabled = !!props.mandatory_read_message;

  // If manadatory read is enabled then we show a loader unil the read is ready. Only then the acknowledge checkbox will be available
  const [iFrameLoading, setIFrameLoading] = useState(manadatoryReadsEnabled);
  const { LoadingSpinner } = useLoadingSpinner();

  /**
   * If user provides the consent, close the popup after a delay
   * The delay is to provide a window to the user to see the selection
   * @param {HTMLInputElement} param
   */
  const onAcknowledgementSelect = ({ target }) => {
    if (target.checked) setTimeout(closeModal, 500);
    publishGA4Events(field, target.checked);
  };

  const modalContentHeight = isMobile() ? "87%" : "92%";

  const onIFrameLoad = () => {
    setIFrameLoading(false);
  };

  const onIFrameError = e => {
    setIFrameLoading(false);
    console.error("CriticalInfoModal: onIFrameError", e);
  };

  return (
    <ReactModal isOpen={isOpen} style={{ ...defaultModalStyle, ...modalStyle }}>
      {!hideDefaultCloseButton && (
        <Flex
          onClick={() => closeModal(field)}
          sx={{ cursor: "pointer", marginTop: "2px" }}
        >
          <Text pr={2} sx={{ marginLeft: "auto" }}>
            X
          </Text>
        </Flex>
      )}

      <Box
        p={4}
        sx={{
          backgroundColor: "#fff",
          borderRadius: "8px",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          height: modalContentHeight
        }}
      >
        {
          <FormattedMessage html id={details}>
            {messageContent => (
              <>
                {iFrameLoading && <LoadingSpinner />}
                <iframe
                  src={messageContent}
                  title={critical_info}
                  style={{ height: "100%", width: "100%" }}
                  onLoad={onIFrameLoad}
                  onError={onIFrameError}
                ></iframe>
              </>
            )}
          </FormattedMessage>
        }
      </Box>
      {!hideCheckBox && (
        <Flex mt={4} pb={5}>
          <Box mr={2}>
            <Label>
              <CheckboxField
                id={id}
                field={field}
                label=""
                onChange={onAcknowledgementSelect}
                checkBoxOnly
                themeColors={themeColors}
              />
            </Label>
          </Box>
          <Box sx={{ fontWeight: "normal" }} pl={2}>
            <FormattedMessage html id={critical_info} />
          </Box>
        </Flex>
      )}
      {showConfirmButton && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              ...buttonStyles,
              width: "100%",
              maxWidth: "400px",
              marginTop: "40px !important"
            }}
            onClick={() => onConfirm()}
          >
            <FormattedMessage id="agree_and_close" />
          </Button>
        </Box>
      )}
    </ReactModal>
  );
};

const defaultModalStyle = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    zIndex: 2000
  },
  content: {
    top: "0.5rem",
    left: "0.25rem",
    right: "0.25rem",
    bottom: "0.5rem",
    position: "fixed",
    border: "1px solid #ccc",
    background: "rgb(210 210 210)",
    overflow: "auto",
    borderRadius: "8px",
    outline: "none",
    padding: "10px",
    margin: "auto",
    inset: "0.5rem"
  }
};

export default CriticalInfoModal;
