import get from "lodash/get";
import { createSelector } from "reselect";
import { portinProviders, urlParam, setNewRelicAttribute } from "utils/helpers";
import { genericSelector } from "ducks/selectors";
import { BETA_USER_EMAILS } from "utils/constants";

const authTokenDomain = state =>
  get(state, "api.userAuthenticate.data.auth_token", null);

const userAuthenticateDomain = state => get(state, "api.userAuthenticate", {});

const loggedInUserDomain = state =>
  get(state, "api.loggedInUserDetails.data", {});

const telcoUserProfileDomain = state =>
  get(state, "api.telcoUserProfile.data", {});

const telcoUserProfileAPI = state => get(state, "api.telcoUserProfile", {});

const currentOtpDomain = state => get(state, "api.currentOtp.data", {});

const newEmailOtpDomain = state => get(state, "api.newEmailOtp.data", {});

const editEmailTimerKeyDomain = state =>
  get(state, "api.editEmailTimerKey.data", {});

const socialLoginEmails = state =>
  get(state, "api.socialMediaMultipleEmails.data.email_adrs", []);

const refreshTokenDomain = state => get(state, "ui.token", {});

export const isTokenRefreshInProgress = createSelector(
  refreshTokenDomain,
  refreshToken => get(refreshToken, "refresh.is_fetching", false)
);

/* 
  Return the SIN and profile image until we get those data from user data
*/
const telcoUserProfileData = createSelector(
  telcoUserProfileDomain,
  ({ picture }) => ({
    picture
  })
);

const isFetchingTelcoProfile = createSelector(
  telcoUserProfileAPI,
  telcoProfileAPI => get(telcoProfileAPI, "loading", false)
);

const serviceInstanceNumber = createSelector(loggedInUserDomain, userDetails =>
  get(userDetails, "telco_info.service_instance_no")
);

export const kanjiName = createSelector(loggedInUserDomain, userDetails =>
  get(userDetails, "telco_info.kanji_name")
);

export const billingAddressSelector = createSelector(
  loggedInUserDomain,
  userDetails => get(userDetails, "telco_info.circles_info.billing_address", {})
);

export const katakanaName = createSelector(loggedInUserDomain, userDetails =>
  get(userDetails, "telco_info.katakana_name")
);

export const userDOB = createSelector(loggedInUserDomain, userDetails =>
  get(userDetails, "dob")
);

/**
 * Returns if user is logged in or not. This is determined if token is available/not available
 */
const isUserLoggedIn = createSelector(authTokenDomain, authToken => {
  if (authToken) return true;
  return false;
});

/**
 * Returns if user is logged in or not. This is determined if token is available/not available
 */
const isFetchingAuthToken = createSelector(userAuthenticateDomain, userAuth => {
  return get(userAuth, "loading", false);
});

/**
 * Returns logged in user details
 */
const loggedInUserData = createSelector(
  loggedInUserDomain,
  userDetails => userDetails
);

/**
 * Returns logged in user details
 */
const isLoggedInUserDataAvailable = createSelector(
  loggedInUserDomain,
  userDetails => userDetails
);

/**
 * Returns the current auth token value
 */
export const authTokenSelector = createSelector(
  authTokenDomain,
  authToken => authToken || urlParam("user_token") || ""
);

/**
 * Returns if user is a telco user or not
 */
export const isTelcoUser = createSelector(loggedInUserDomain, userDetails =>
  get(userDetails, "telco_user", false)
);

/**
 * Returns if user is an active telco user or not
 */
export const isActiveTelcoUser = createSelector(
  loggedInUserDomain,
  userDetails =>
    get(userDetails, "telco_user", false) &&
    get(userDetails, "telco_info.status") === "Active"
);

/**
 * Returns the logged in user type
 */
export const userTypeSelector = createSelector(
  loggedInUserDomain,
  userDetails => get(userDetails, "user_type", "")
);

/**
 * Returns the logged in user details
 */
export const userDetailsSelector = createSelector(
  loggedInUserDomain,
  telcoUserProfileData,
  (userDetails, telcoProfile) => ({ ...userDetails, ...telcoProfile })
);
/**
 * Returns the logged in user email
 */
export const userEmailSelector = createSelector(
  loggedInUserDomain,
  userDetails => get(userDetails, "email")
);

/**
 * Returns the social login email list
 */
export const userSocialEmailsSelector = createSelector(
  socialLoginEmails,
  emailsList => {
    return emailsList.map(email => ({ label: email, value: email })) || [];
  }
);

/**
 * Returns the logged in user external id
 */
export const externalUserIdSelector = createSelector(
  loggedInUserDomain,
  userDetails => get(userDetails, "external_id")
);
/**
 * Returns AU (https://www.au.com/my-au/) login params
 */
const auLoginParams = createSelector(genericSelector, generic =>
  get(generic, "au_login")
);

const auInstructionsURL = createSelector(genericSelector, generic =>
  get(generic, "au_to_povo_instructions", "")
);

/**
 * Returns all social media options
 */
const socialMediaOptions = createSelector(genericSelector, generic =>
  get(generic, "social_media_signin_options", {})
);

const currentOtpRequestedSelector = createSelector(
  currentOtpDomain,
  currentOtp => get(currentOtp, "otpRequested")
);

const currentOtpVerifiedSelector = createSelector(
  currentOtpDomain,
  currentOtp => get(currentOtp, "otpVerified")
);

const currentOtpErrorMessageSelector = createSelector(
  currentOtpDomain,
  newEmailOtpDomain,
  (mobileOtp, emailOtp) =>
    get(emailOtp, "errorMessage") || get(mobileOtp, "errorMessage")
);

const editEmailTimerKeyRequestedSelector = createSelector(
  editEmailTimerKeyDomain,
  editEmailTimerKey => get(editEmailTimerKey, "editEmailTimer")
);

const newEmailOtpRequestedSelector = createSelector(
  newEmailOtpDomain,
  newEmailOtp => get(newEmailOtp, "otpRequested")
);

export const userSINSelector = createSelector(
  userDetailsSelector,
  userDetails => get(userDetails, "telco_info.service_instance_no")
);

export const isBetaUserSelector = createSelector(
  userEmailSelector,
  loggedinUserEmail => {
    const DELIMITER = ",";
    const betaUserEmails = BETA_USER_EMAILS || "";
    const betaUserEmailsList = betaUserEmails.split(DELIMITER);

    return betaUserEmailsList.includes(loggedinUserEmail);
  }
);

/**
 * Returns AU Lost Service Details
 */
const auLostServiceParams = createSelector(genericSelector, generic =>
  get(generic, "social_media_signin_options.AU_LOST_SERVICE")
);

/*
  This is to get whether the logged in user is an porting user from a different account (e.g.: AU to POVO).
*/
export const isPortingUserSelector = createSelector(
  userDetailsSelector,
  userDetails => {
    // user service sends which account user used to login.
    const socialInfo = get(userDetails, "social_info") || {};
    let isPortingUser = false;
    // define the social login name that should be use to identify an porting account.
    const portinAccounts = portinProviders();

    // iterate through the defined porting accounts in the config and if match found return it
    for (const account in socialInfo) {
      if (
        Object.prototype.hasOwnProperty.call(socialInfo, account) &&
        portinAccounts.includes(account)
      ) {
        isPortingUser = !!socialInfo[account];
        // setting only auth_provider since socialInfo obj is too large to encrypt
        setNewRelicAttribute(
          "authProvider",
          socialInfo[account]?.auth_provider
        );
      }
    }

    setNewRelicAttribute("isPortingUser", isPortingUser);
    return isPortingUser;
  }
);

export const unavailableUserRequiredData = createSelector(
  kanjiName,
  katakanaName,
  userDOB,
  (kanjiName, katakanaName, userDOB) => {
    return !kanjiName || !katakanaName || !userDOB;
  }
);

// ---- User Details ---
export const makeSelectIsUserLoggedIn = () => isUserLoggedIn;
export const makeSelectLoggedInUserData = () => loggedInUserData;
export const makeSelectIsLoggedInDataAvailalble = () =>
  isLoggedInUserDataAvailable;
export const makeSelectUserType = () => userTypeSelector;
export const makeSelectUserDetails = () => userDetailsSelector;
export const makeSelectUserEmail = () => userEmailSelector;
export const makeSelectSocialLoginEmails = () => userSocialEmailsSelector;
export const makeSelectUserSIN = () => userSINSelector;
export const makeSelectIsActiveTelcoUser = () => isActiveTelcoUser;
export const makeSelectIsTelcoUser = () => isTelcoUser;
export const makeSelectExternalUserId = () => externalUserIdSelector;
export const makeSelectIsFetchingTelcoUser = () => isFetchingTelcoProfile;
export const makeSelectServiceInstanceNumber = () => serviceInstanceNumber;
export const makeSelectKanjiName = () => kanjiName;
export const makeSelectKatakanaName = () => katakanaName;
export const makeSelectLoggedinUserDOB = () => userDOB;

// ---- Auth Details ---
export const makeSelectIsFetchingAuthToken = () => isFetchingAuthToken;
export const makeSelectAuthToken = () => authTokenSelector;
export const makeSelectAULoginParams = () => auLoginParams;
export const makeSelectAUInstructionsURL = () => auInstructionsURL;
export const makeSelectAULostServiceParams = () => auLostServiceParams;
export const makeSelectCurrentOtpRequested = () => currentOtpRequestedSelector;
export const makeSelectCurrentOtpVerified = () => currentOtpVerifiedSelector;
export const makeSelectNewEmailOtpRequested = () =>
  newEmailOtpRequestedSelector;
export const makeSelectOtpErrorMessage = () => currentOtpErrorMessageSelector;
export const makeSelectEditEmailTimerKey = () =>
  editEmailTimerKeyRequestedSelector;
export const makeSelectIsPortingUser = () => isPortingUserSelector;
export const makeSelectSocialMediaOptions = () => socialMediaOptions;
export const makeSelectUnavailableUserRequiredData = () =>
  unavailableUserRequiredData;
export const makeSelectIsBetaUser = () => isBetaUserSelector;
