import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import MobileDetect from "mobile-detect";
import axios from "axios";
import Currency from "currency-formatter";
import JSEncrypt from "jsencrypt";
import CryptoJS from "crypto-js";
import isNil from "lodash/isNil";
import get from "lodash/get";
import moment from "moment-timezone";
import each from "lodash/each";
import map from "lodash/map";
import reduce from "lodash/reduce";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import trim from "lodash/trim";
import trimStart from "lodash/trimStart";
import head from "lodash/head";
import includes from "lodash/includes";
import split from "lodash/split";
import sortBy from "lodash/sortBy";
import orderBy from "lodash/orderBy";
import forEach from "lodash/forEach";
import isFunction from "lodash/isFunction";
import isPlainObject from "lodash/isPlainObject";
import compact from "lodash/compact";
import join from "lodash/join";
import startsWith from "lodash/startsWith";
import endsWith from "lodash/endsWith";
import ceil from "lodash/ceil";
import keys from "lodash/keys";
import indexOf from "lodash/indexOf";
import isObject from "lodash/isObject";
import DOMPurify from "dompurify";
import {
  COUNTRY,
  GOOGLE_API_KEY,
  ECOM_API_KEY,
  ACTION_URLS,
  PAYMENT_STATUS_URLS,
  paths,
  RESTRICTED_STEP_ACTIONS,
  WEB_CHANNELS,
  WORKFLOW_CHANNELS,
  ONE_TIME_DISCOUNT_TYPES,
  TELCO_BENEFIT_TYPES,
  TELCO_WAIVER_TYPES,
  EXPRESS_DELIVERY,
  DELIVERY_METHOD_TYPE,
  OUTSTANDING_BILL_REFERRAL_FROM_TYPE,
  publicPaths,
  MYINFO_ENDPOINT,
  MYINFO_CLIENT_ID,
  DOB_FORMAT,
  SECURITY_DOB_FORMAT,
  TERMINATE_ACTION_STATUS,
  TERMINATION_PENDING_STATUSES,
  PORTOUT_PENDING_STATUSES,
  PORTOUT_IN_PROGRESS,
  PORTOUT_FAILED_STATUSES,
  PORTOUT_COMPLETED_STATUSES,
  MOBILE_APP_DEEP_LINK_QA,
  MOBILE_APP_DEEP_LINK,
  PORTING_ACCOUNTS,
  UPDATE_TOKEN_TO_APP_URI,
  MOBILE_APP_BROWSER_PROTOCOL,
  NETWORK_TYPES,
  SPECIALPATHS
} from "utils/constants";

import history from "./history";
import queryString from "query-string";
import Sha256 from "crypto-js/sha256";
import Hex from "crypto-js/enc-hex";
import {
  getLocaleOrDefault,
  getDeviceId,
  isActivationSuccess,
  isWebview,
  getLocale,
  getWebview,
  getLandingPage,
  isQAApp,
  isNative
} from "./localStorage";
import isArray from "lodash/isArray";
import scrollIntoView from "scroll-into-view-if-needed";
import uniqBy from "lodash/uniqBy";
import { featureFlag5GSAEnabled } from "./featureFlags";

// const PHONE_ORDER = {
//   huawei: 3,
//   samsung: 2,
//   iphone: 1
// };

// export const sortPhones = phones => {
//   return phones
//     .sort((next, prev) => {
//       const nextKey = Object.keys(PHONE_ORDER).find(k => {
//         return next.name.toLowerCase().includes(k);
//       });

//       const nextPriority = PHONE_ORDER[nextKey];

//       const prevKey = Object.keys(PHONE_ORDER).find(k => {
//         return prev.name.toLowerCase().includes(k);
//       });
//       const prevPriority = PHONE_ORDER[prevKey];

//       return nextPriority > prevPriority ? -1 : 1;
//     })
//     .sort((next, prev) => {
//       return next.out_of_stock === prev.out_of_stock
//         ? 0
//         : next.out_of_stock
//         ? 1
//         : -1;
//     });
// };

export const randomNumber = () => {
  const min = 1111111;
  const max = 9999999;
  return Math.floor(Math.random() * (max - min)) + min;
};

export const minutesDifference = (t1, t2) => {
  const diff = t1 - t2;
  return Math.floor(diff / 1000 / 60);
};

export const upper = value => value && value.toUpperCase();
export const lower = value => value && value.toLowerCase();

export const isPostalCodeResult = results => {
  let isPostalCodeSearch = false;
  const address = results[0];
  const types = address.types;
  const searchType = types.filter(type => type === "postal_code");
  isPostalCodeSearch = searchType !== null && searchType.length > 0;
  return isPostalCodeSearch;
};

export const parseAddress = results => {
  const {
    address_components: addressComponents,
    formatted_address: formattedAddress
  } = head(results) || {};

  let subPremise;
  let premise;
  let subLocality1;
  let subLocality2;
  let subLocality3;
  let subLocality4;
  let blockHouseNo;
  let streetName;
  let postalCode;
  let country;

  const items = [
    ["subPremise", "subpremise", "long_name"],
    ["premise", "premise", "long_name"],
    ["subLocality1", "sublocality_level_1", "long_name"],
    ["subLocality2", "sublocality_level_2", "long_name"],
    ["subLocality3", "sublocality_level_3", "long_name"],
    ["subLocality4", "sublocality_level_4", "long_name"],
    ["blockHouseNo", "street_number", "long_name"],
    ["streetName", "route", "long_name"],
    ["postalCode", "postal_code", "long_name"],
    ["city", "locality", "long_name"],
    ["state", "administrative_area_level_1", "short_name"],
    ["country", "country", "long_name"],
    ["countryCode", "country", "short_name"],
    ["administrativeDivision", "administrative_area_level_2", "long_name"]
  ];

  const result = items.reduce(
    (accumulator, item) => {
      let addressComponent = addressComponents.filter(component =>
        includes(component.types, item[1])
      );

      if (addressComponent !== null && addressComponent.length > 0) {
        return { ...accumulator, [item[0]]: addressComponent[0][item[2]] };
      } else {
        return accumulator;
      }
    },
    {
      subPremise,
      premise,
      subLocality1,
      subLocality2,
      subLocality3,
      subLocality4,
      blockHouseNo,
      streetName,
      postalCode,
      country
    }
  );

  return {
    ...result,
    state: result.state || "",
    city: compactJoin([
      result.administrativeDivision,
      result.city,
      result.subLocality1
    ]),
    streetName: result.subLocality2,
    blockHouseNo: compactJoin([result.subPremise, result.blockHouseNo], "/"),
    formattedAddress
  };
};

export async function getAddress(addr) {
  let geocodeByAddressResponse = await geocodeByAddress(addr);
  let getLatLngResponse = await getLatLng(geocodeByAddressResponse[0]);

  if (isPostalCodeResult(geocodeByAddressResponse)) {
    const coords = `${getLatLngResponse.lat},${getLatLngResponse.lng}`;
    const requestUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords}&key=${GOOGLE_API_KEY}`;

    let apiRequest = await axios.get(requestUrl);
    return parseAddress(apiRequest.data.results);
  } else {
    return parseAddress(geocodeByAddressResponse);
  }
}

export const urlParam = key => {
  const url = new URL(window.location.href);
  return url && url.searchParams && key ? url.searchParams.get(key) : undefined;
};

export const getURLParams = () => {
  let params = {};
  const query = window.location.search.replace("?", "");
  const vars = query ? query.split("&") : [];
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }

  return params;
};

export const getURLParamValue = param => {
  const params = getURLParams();
  return params[param] === "true" ? true : false;
};

export const addUrlParam = (key, val, currentUrl) => {
  const isParamsAvaialble = currentUrl.split("?").length > 1;
  if (isParamsAvaialble) return `${currentUrl}&${key}=${val}`;
  return `${currentUrl}?${key}=${val}`;
};

export const isMobile = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }
  return false;
};

export const isIOS = () => {
  const md = new MobileDetect(window.navigator.userAgent);
  return md.is("iPhone") || md.is("iPad");
};

export const isAndroid = () => {
  const platformString =
    (window.navigator.userAgentData &&
      window.navigator.userAgentData.platform) ||
    window.navigator.userAgent;
  return /Android/.test(platformString);
};

export const isIOSSafari = () => {
  var ua = window.navigator.userAgent;
  var webkit = !!ua.match(/WebKit/i);
  return isIOS() && webkit && !ua.match(/CriOS/i);
};

export const isDesktop = () => {
  const md = new MobileDetect(window.navigator.userAgent);
  return md.mobile() == null;
};

export const isTabletOrDeskTop = () => {
  const md = new MobileDetect(window.navigator.userAgent);
  return md.mobile() == null || md.tablet();
};

export const allFilled = (object, fields) => {
  if (object === undefined) {
    return false;
  }

  return fields.every(field => {
    if (object[field] === undefined) {
      return false;
    }

    return object[field].length > 0;
  });
};

export const resetAddressField = (change, field) => {
  change(field, "");
};
export const resetAddressFields = (change, type, whitelistFields = []) => {
  // change(`${type}_location`, ""); // not resetting so we can see text in google field as well

  const dict = [
    `${type}_zip_code`,
    `${type}_hse_blk_tower`,
    `${type}_street_name`,
    `${type}_country`,
    `${type}_building_name`,
    `${type}_unit_no`,
    `${type}_location`,
    `${type}_slot_full_data`,
    `${type}_slot_id`,
    `${type}_slot`,
    "pop_station_outlet_full_data" // no type variable as only available for pop station
  ];

  each(dict, field => {
    if (whitelistFields.indexOf(field) === -1) {
      resetAddressField(change, field);
    }
  });
};

export const setAddress = (
  change,
  type,
  address,
  validatePostalCode,
  data,
  callback
) => {
  getAddress(address).then(result => {
    const {
      blockHouseNo,
      streetName,
      postalCode,
      city,
      state,
      country,
      formattedAddress
    } = result;
    console.log("$$$ setAddress result", result, address);

    if (get(data, "validate", false)) {
      if (!allFilled(result, data.validate)) {
        change(
          `${type}_location_error`,
          "click_here_to_fill_in_your_address_manually"
        );

        resetAddressFields(change, type);
        return;
      } else {
        change(`${type}_location_error`, "");
      }
    }

    change(`${type}_location`, formattedAddress || address);
    change(`${type}_hse_blk_tower`, blockHouseNo);
    change(`${type}_street_name`, streetName);
    change(`${type}_city`, city);
    change(`${type}_state`, state);
    change(`${type}_country`, country);
    change(`${type}_building_name`, "");
    change(`${type}_unit_no`, "");
    change(`${type}_zip_code`, postalCode);
    isFunction(validatePostalCode) &&
      validatePostalCode(postalCode, `${type}_zip_code`);
    isFunction(callback) && callback(result);
  });
};

export const setPostOfficeAddressAsDeliveryAddress = (change, postOffice) => {
  const { full_address, address, delivery_slots } = postOffice;
  change("delivery_location", full_address);
  change("delivery_street_name", address.street_name);
  change("delivery_building_name", address.building_name);
  change("delivery_hse_blk_tower", address.hse_blk_tower);
  change("delivery_zip_code", address.zip_code);
  change("delivery_floor_no", address.floor_no);
  change("delivery_unit_no", address.unit_no);
  change("delivery_city", address.city);
  change("delivery_country", address.country);
  change("delivery_delivery_note", "");
  change("delivery_slot_id", get(delivery_slots, "0.id", ""));
  change("delivery_slot_fee", get(delivery_slots, "0.cost", 0));
  change("delivery_date", get(delivery_slots, "0.date_string", ""));
  change("delivery_slot", get(delivery_slots, "0.courier_slot_name", ""));
  change("same_as_delivery_address", false);
};

export const prepareImageDataForUpload = (file, binaryStr) => {
  const data = binaryStr.split(";");
  const fileType = data[0].split("data:")[1];
  const base64ImageData = data[1].split("base64,")[1];

  return {
    content_type: fileType,
    data: base64ImageData,
    file_name: file.name
  };
};

export const prepareScreenshotDataForUpload = (file, binaryStr) => {
  const data = binaryStr.split(";");
  const fileType = data[0].split("data:")[1];
  const base64ImageData = data[1].split("base64,")[1];

  return {
    content_type: fileType,
    data: base64ImageData,
    file_name: file.name,
    browser_details: getBrowser(),
    ip_address: "127.0.0.1"
  };
};

const getBrowser = () => {
  var ua = navigator.userAgent,
    tem,
    M =
      ua.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR\/(\d+)/);

    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }

  return {
    name: M[0],
    version: M[1]
  };
};

export const formatCurrency = (amount, format, forceSign, forcedPrecision) => {
  if (isNil(amount) || !format) return null;
  const prefix = forceSign ? (amount < 0 ? "-" : amount > 0 ? "+" : "") : "";
  const precision = isNaN(forcedPrecision) ? format.precision : forcedPrecision;
  const formattedCurrency = Currency.format(amount, { ...format, precision });
  return !prefix || formattedCurrency.startsWith(prefix)
    ? formattedCurrency
    : `${prefix} ${formattedCurrency}`;
};

export const formatDisplayNumber = (number, format) => {
  const pattern = get(format, "pattern", "");
  const zeroPadded = get(format, "zero_padded", false);
  let numberArray = trim(number).split("");
  let patternArray;

  // pattern key can contain array of patterns:string or just one pattern:string
  if (Array.isArray(pattern)) {
    patternArray = pattern
      .find(pat => {
        // remove the white spaces and get masking characters to determine the correct
        // formatting pattern for the given number
        return pat.replace(/\W/gm, "")?.length === numberArray.length;
      })
      ?.split("");
  } else {
    patternArray = pattern.split("");
  }

  if (!zeroPadded && numberArray[0] === "0") {
    numberArray.shift();
  }

  const result = numberArray.reduce(
    (accumulator, currentValue = "") => {
      let concatString = "";
      let index = accumulator.index;
      let nextIndex = 0;
      let string = accumulator.string;
      let patternString = patternArray?.[index] || "";

      if (patternString === "X") {
        concatString = concatString + currentValue;
      } else {
        nextIndex = nextIndex + 1;
        concatString = patternString + concatString + currentValue;
      }

      nextIndex = nextIndex + 1;

      return {
        index: index + nextIndex,
        string: string + concatString
      };
    },
    { index: 0, string: "" }
  );

  return result.string;
};

export const formatContactNumber = (number, format, countryCode) => {
  const formattedNumber = formatDisplayNumber(number, format);
  const hideCountryCode = get(format, "hide_country_code", false);
  return hideCountryCode
    ? formattedNumber
    : compactJoin([countryCode, trimStart(formattedNumber, "0")], " ");
};

export const formatInvoiceDate = ({
  unix,
  locale,
  monthOptions,
  dateOptions
}) => {
  let formattedInvoiceDate = "";
  let unixCopy = unix;

  if (!unixCopy) return formattedInvoiceDate;
  if (unixCopy.toString().length === 13) unixCopy = Math.floor(unixCopy / 1000);

  const invoiceDateFormat = get(
    locale,
    "invoice_date_format",
    "{yearLabel} {monthLabel} {startDateLabel}-{endDateLabel}"
  );
  const yearFormat = get(locale, "year_label", "{year}");
  const date = new Date(unixCopy * 1000);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  // if month is last month then the end month should be january
  const endMonth = month === 12 ? 1 : month + 1;
  // if month is last month then the end year should be next year
  const endYear = month === 12 ? year + 1 : year;

  const startDate = 1;
  const endDate = date.getDate();

  const paddedMonth = month.toString().padStart(2, "0");
  const paddedEndMonth = endMonth.toString().padStart(2, "0");
  const paddedStartDate = startDate.toString().padStart(2, "0");
  const paddedEndDate = endDate.toString().padStart(2, "0");

  const yearLabel = formatMessage(yearFormat, { year });
  const endYearLabel = formatMessage(yearFormat, { year: endYear });
  const monthLabel =
    find(monthOptions, { value: paddedMonth })?.label || paddedMonth;
  const endMonthLabel =
    find(monthOptions, { value: paddedEndMonth })?.label || paddedEndMonth;
  const startDateLabel =
    find(dateOptions, { value: paddedStartDate })?.label || paddedStartDate;
  const endDateLabel =
    find(dateOptions, { value: paddedEndDate })?.label || paddedEndDate;

  formattedInvoiceDate = formatMessage(invoiceDateFormat, {
    year,
    month,
    startDate,
    endDate,
    yearLabel,
    startYearLabel: yearLabel,
    endYearLabel,
    monthLabel,
    startMonthLabel: monthLabel,
    endMonthLabel,
    startDateLabel,
    endDateLabel
  });

  return formattedInvoiceDate;
};

export const formatDisplayInvoiceDate = ({
  unix,
  format = "[{first}] D MMM YYYY",
  firstDayPrefix = "1 -",
  locale = {}
}) => {
  let formattedInvoiceDate = "";
  let unixCopy = unix;
  let formattedDate = "";

  if (!unixCopy) return formattedInvoiceDate;
  if (unixCopy.toString().length === 13) unixCopy = Math.floor(unixCopy / 1000);

  const date = moment
    .unix(unixCopy)
    .subtract(1, "days")
    .endOf("month");

  if (!isEmpty(locale?.month)) {
    const month = date.month();
    const localizedMonth = locale?.month?.[month - 1]?.label;

    if (localizedMonth) {
      // exclude formatting month by moment js
      const updatedFormat = format.replace(/M{2,3}(?=\s)/gm, "[{$&}]");
      // replace month format wild card using localized month
      formattedDate = date
        .format(updatedFormat)
        .replace(/{M{2,3}}/, localizedMonth);
    } else {
      formattedDate = date.format(format);
    }
  } else {
    formattedDate = date.format(format);
  }

  if (formattedDate === "Invalid date") return formattedDate;

  formattedInvoiceDate = formattedDate.replace("{first}", firstDayPrefix); //eg. 1 - 30 Nov 2020
  return formattedInvoiceDate;
};

export const getDeliverySlots = (items, maxCounter, condition) => {
  let res = reduce(
    items,
    (accumulator, item) => {
      const { items, counter } = accumulator;

      if (!isEmpty(item) && condition(item) && counter < maxCounter) {
        return {
          items: items.concat(item),
          counter: counter + 1
        };
      } else {
        return accumulator;
      }
    },
    { items: [], counter: 0 }
  );

  return res.items;
};

export const formatMessage = (template = "", values = {}) => {
  let message = template;
  each(values, (value, key) => {
    message = message.replace(`{${key}}`, value);
  });
  return message;
};

export const generateSubpathComponents = (components, subcomponents) =>
  map(components, component => {
    const subcomponent = find(subcomponents, ({ name, subname }) =>
      subname
        ? subname === get(component, "subname") &&
          name === get(component, "name")
        : name === get(component, "name")
    );
    return subcomponent ? { ...component, ...subcomponent } : component;
  });

export const generateSubpathWorkflow = (workflow = {}, subpath = {}) => {
  let subWorkflow = cloneDeep(subpath);
  let subpathWorkflow = {};
  if (workflow.path && subWorkflow.path) {
    subWorkflow.path = workflow.path + subWorkflow.path;
    const subcomponents = cloneDeep(subWorkflow.subcomponents);
    delete subWorkflow.subcomponents;
    subpathWorkflow = { ...workflow, ...subWorkflow };
    delete subpathWorkflow.subpaths;
    subpathWorkflow.components = generateSubpathComponents(
      subpathWorkflow.components,
      subcomponents
    );
  }

  return subpathWorkflow;
};

export const generateWorkflowForSubpaths = workflows => {
  const generatedWorkflows = [];
  each(workflows, workflow => {
    generatedWorkflows.push(workflow);
    if (!isEmpty(workflow.subpaths)) {
      each(workflow.subpaths, subpath => {
        const subpathWorkflow = generateSubpathWorkflow(workflow, subpath);
        if (!isEmpty(subpathWorkflow)) {
          generatedWorkflows.push(subpathWorkflow);
        }
      });
    }
  });

  return generatedWorkflows;
};

export const constructDeliverySlotDates = (slots, locale, currencyFormat) => {
  const mappedSlots = map(slots, slot => {
    const dateLabel = constructLocalizedDeliverySlotLabel(slot.date, locale);
    const endDateLabel = constructLocalizedDeliverySlotLabel(
      slot.range_end_date,
      locale
    );
    const labels = [dateLabel, endDateLabel];
    return {
      label: compactJoin(labels, " - "),
      description: slot.display_time,
      value: slot.date_time,
      slotId: slot.id,
      deliverySlot: slot.courier_slot_name,
      date: slot.date_string || head(split(slot.date, "T")),
      deliveryDate: slot.date_time.split(" ")[0],
      endDate: head(split(slot.range_end_date, "T")),
      time: slot.display_time,
      cost: slot.cost,
      special: slot.special,
      formattedCost: formatCurrency(slot.cost, currencyFormat)
    };
  });

  return sortBy(mappedSlots, "date");
};

export const constructFreeDeliverySlots = slots =>
  map(slots, slot => ({
    label: slot.display_time,
    value: slot.date_time,
    slotId: slot.id,
    cost: slot.cost,
    deliverySlot: slot.courier_slot_name
  }));

export const constructSpecialDeliverySlots = (slots, currencyFormat) =>
  map(slots, slot => ({
    label: slot.display_time,
    value: slot.date_time,
    slotId: slot.id,
    cost: slot.cost,
    formattedCost: formatCurrency(slot.cost, currencyFormat),
    deliverySlot: slot.courier_slot_name
  }));

export const constructPostOfficeRegions = regions =>
  map(regions, region => ({
    label: region.name,
    value: region.id
  }));

export const constructTerminationReasons = reasons =>
  map(reasons, reason => ({
    label: reason.title,
    value: reason.title,
    winbackId: reason.winback_id
  }));

export const constructTerminationDates = dates =>
  map(dates, ({ date }) => ({
    label: moment(date).format("ddd, DD MMM YYYY"),
    value: date
  }));

export const constructDocTypes = types => {
  const docTypes = [];
  forEach(types, (item, key) => {
    if (item && item.title) {
      docTypes.push({
        label: item.title,
        value: key,
        display_sequence: item.display_sequence
      });
    }
  });
  return orderBy(docTypes, ["display_sequence"], ["asc"]);
};

export const specialDeliveryCost = (cost, format) =>
  `Express Delivery: ${formatCurrency(cost, format, true)}`;

export const getDeliverySlotsForDate = (slots, date) =>
  filter(slots, slot => {
    return includes(slot.date, date) || slot.date_string === date;
  });

export const getFreeDeliverySlots = slots =>
  constructFreeDeliverySlots(filter(slots, slot => slot.cost <= 0));

export const getSpecialDeliverySlots = slots =>
  constructSpecialDeliverySlots(filter(slots, slot => slot.cost > 0));

export const getScheduleDeliveryDetailsForSubmit = state => {
  const formValues = get(state, "form.ScheduleDeliveryForm.values", {});
  const {
    alternate_number,
    delivery_zip_code,
    delivery_slot_full_data,
    post_office_id,
    delivery_street_name,
    delivery_building_name,
    delivery_hse_blk_tower,
    delivery_floor_no,
    delivery_unit_no,
    delivery_method,
    delivery_slot_id,
    order_type,
    delivery_type,
    token
  } = formValues;

  const street_building_name = delivery_building_name
    ? `${delivery_street_name}, ${delivery_building_name}`
    : delivery_street_name;

  const payload = {
    order_type,
    delivery_type,
    token,
    alternate_number,
    delivery_method: get(delivery_method, "value"),
    delivery_date: delivery_slot_full_data
      ? head(delivery_slot_full_data.split(" "))
      : null,
    fail_reason: "",
    hse_blk_tower: delivery_hse_blk_tower,
    post_office_id: post_office_id,
    slot_id: delivery_slot_id,
    street_building_name,
    floor_no: delivery_floor_no,
    unit_no: delivery_unit_no,
    zip_code: delivery_zip_code
  };

  return payload;
};

export const safeEval = (
  get,
  formValues,
  condition,
  defaultValue,
  props,
  changedValue
) => {
  let result = defaultValue || condition;

  if (condition) {
    try {
      // eslint-disable-next-line
      result = eval(condition);
    } catch (_) {
      result = defaultValue || condition;
    }
  }
  return result;
};

export const evalProp = (props, condition, defaultValue) => {
  let result = defaultValue || condition;
  if (condition) {
    try {
      // eslint-disable-next-line
      result = eval(condition);
    } catch (_) {
      result = defaultValue || condition;
    }
  }
  return result;
};

export const constructPortInNumberValidation = ({ data, loading }) => {
  const { status: valid, message } = data || {};
  const inlineMessage = isEmpty(data)
    ? ""
    : valid
    ? "Nice ✔"
    : "Number Already Exists ✘";
  return {
    valid,
    loading,
    message,
    inlineMessage
  };
};

export const constructPostalCodeValidation = postalCodeValidation => {
  const result = {};
  forEach(postalCodeValidation, (value, key) => {
    const { data = {}, loading } = value;
    const { status: valid, message } = data;
    const inlineMessage = "";
    result[key] = {
      valid,
      loading,
      message,
      inlineMessage
    };
  });
  return result;
};

export const constructPostOfficeDeliverySlots = (
  slots,
  delivery_info_working_hours
) =>
  map(slots, slot => {
    const {
      id,
      name,
      address,
      formatted_address,
      working_hours,
      instant_pickup_start_date_time,
      delivery_slots,
      map_image
    } = slot;

    const monWorkingHours = get(working_hours, [1]);
    const satWorkingHours = get(working_hours, [6]);
    const sunWorkingHours = get(working_hours, [0]);
    const formattedWorkingHours = formatMessage(delivery_info_working_hours, {
      monWorkingHours,
      satWorkingHours,
      sunWorkingHours
    });
    return {
      id,
      name,
      address,
      fullAddress: formatted_address,
      workingHours: formattedWorkingHours,
      pickUpDateTime: instant_pickup_start_date_time,
      deliverySlot: delivery_slots[0],
      delivery_slots,
      mapImage: map_image
    };
  });

export const gotoUrl = (
  url,
  preserveQuery,
  queryOverride = {},
  reload,
  resetQueryParams,
  newUrl
) => {
  let targetUrl = url;
  const queryParams = preserveQuery
    ? queryString.parse(window.location.search)
    : {};
  const query = {
    ...queryParams,
    relaunch: null,
    ...queryOverride
  };
  targetUrl = queryString.stringifyUrl({ url, query }, { skipNull: true });

  //remove the exsisting  query params and add new query paramas to url
  if (resetQueryParams) {
    targetUrl = queryString.stringifyUrl(
      { url, query: resetQueryParams },
      { skipNull: true }
    );
  }
  console.log("Going to Url => ", targetUrl);
  const currentUrl = `${window.location.pathname}${window.location.search}`;
  if (
    targetUrl &&
    targetUrl !== "undefined" &&
    (targetUrl !== currentUrl || reload)
  ) {
    if (includes(targetUrl, "://")) {
      setTimeout(() => {
        window.location = targetUrl;
      }, 0);
    }
    //subcomponents redirect url
    else if (newUrl) {
      setTimeout(() => {
        window.location = `${window.location.origin}${targetUrl}`;
      }, 0);
    } else {
      history.push(targetUrl);
      if (reload) {
        setTimeout(() => {
          window.location.reload();
        }, 0);
      }
    }
  }
};

export const goBack = () => history.go(-1);

export const getReferralCodeBenefitsForPayToday = (
  referralCodeBenefits,
  currencyFormat
) =>
  map(
    filter(referralCodeBenefits, benefit =>
      includes(ONE_TIME_DISCOUNT_TYPES, benefit.type)
    ),
    benefit => {
      const value = -(get(benefit, "product.price.value", 0) / 100);
      const price =
        value === 0 ? null : formatCurrency(value, currencyFormat, true);

      return {
        label: benefit.title,
        price,
        value,
        type: "benefit"
      };
    }
  );

export const getReferralCodeBenefitsForPayMonthly = (
  referralCodeBenefits,
  currencyFormat
) =>
  map(
    filter(
      referralCodeBenefits,
      benefit => !includes(ONE_TIME_DISCOUNT_TYPES, benefit.type)
    ),
    benefit => {
      const negativeValue =
        benefit.type === TELCO_WAIVER_TYPES.TELCO_BILL_WAIVER;

      const priceValue = get(benefit, "product.price.value", 0) / 100;
      const value =
        priceValue !== 0 && negativeValue ? -Math.abs(priceValue) : priceValue;
      const price =
        value === 0 ? null : formatCurrency(value, currencyFormat, true);
      const data =
        benefit.type === TELCO_BENEFIT_TYPES.TELCO_DATA
          ? kbToGB(get(benefit, "product.kb"))
          : undefined;

      return {
        label: benefit.title,
        price,
        value,
        type: "benefit",
        data
      };
    }
  );

export const isWebView = () => {
  return localStorage.getItem("webview") === "1";
};

export const constructVerifyIdentityParams = payload => {
  const docType = get(payload, "doc_type.value");
  const nric = get(payload, "nric");
  const dobYear = get(payload, "dobYear.value", "");
  const dobMonth = get(payload, "dobMonth.value", "");
  const dobDay = get(payload, "dobDay.value", "");

  const verificationParams = {
    firstName: get(payload, "first_name"),
    middleName: get(payload, "middle_name"),
    lastName: get(payload, "last_name"),
    email: get(payload, "email"),
    dob: `${dobYear}-${dobMonth}-${dobDay}`
  };

  // HACKS, sorry, in a rush
  if (docType === "au_passport") {
    verificationParams.passportDetails = true;
    verificationParams.countryCode = "AUS";
    verificationParams.passportNumber = nric;
  }

  if (docType === "international_passport") {
    verificationParams.passportDetails = true;
    verificationParams.countryCode = get(payload, "country_of_issue.value", "");
    verificationParams.passportNumber = nric;
  }

  if (docType === "driving_licence") {
    verificationParams.drivingLicence = true;
    verificationParams.stateCode = get(payload, "state_of_issue.value", "");
    verificationParams.licenceNumber = nric;
  }

  return verificationParams;
};

export const handleOnLoadAction = props => {
  const currentWorkflowPage = find(
    props.workflow,
    page => page.path === window.location.pathname
  );
  const onLoadAction =
    get(currentWorkflowPage, "onload") || get(currentWorkflowPage, "onLoad");
  const onLoadActionHandler = onLoadAction && get(props, onLoadAction);
  isFunction(onLoadActionHandler) && onLoadActionHandler();
};

export const getOnSubmitActionHandler = props => {
  const currentWorkflowPage = find(
    props.workflow,
    page => page.path === window.location.pathname
  );
  const onSubmitWorkflow =
    get(currentWorkflowPage, "onsubmit") ||
    get(currentWorkflowPage, "onSubmit");
  const onSubmitAction = get(props, "onSubmitOverride") || onSubmitWorkflow;
  const onSubmitActionHandler = onSubmitAction && get(props, onSubmitAction);
  return onSubmitActionHandler;
};

export const constructOnChangeHandler = props => {
  return changedValue => {
    const on_change = props.on_change;
    on_change &&
      safeEval(get, props.formValues, on_change, false, props, changedValue);
  };
};

export const kbToGB = kb => (kb ? Number(kb) / (1024 * 1024) : 0);

export const formatAddonsForPayMonthly = (
  addons = [],
  selectedAddons = [],
  currencyFormat,
  basicTax = 0
) =>
  map(addons, addon => {
    const precision = get(currencyFormat, "precision", 2);
    const taxPercent = Number(get(addon, "tax_percentage", basicTax));
    const price = addon.price || addon.value || 0;
    const price_without_tax = ceil(price / (1 + taxPercent / 100), precision);
    return {
      id: addon.id,
      label: addon.title || addon.name,
      name: addon.name,
      data: addon.kb ? kbToGB(addon.kb) : 0,
      description: addon.description_short || addon.description_full,
      value: price,
      product_link: addon.product_link,
      value_without_tax: price_without_tax,
      price: formatCurrency(price, currencyFormat, true),
      price_without_tax: formatCurrency(
        price_without_tax,
        currencyFormat,
        true
      ),
      highlightText: get(addon, "specs.highlightText", null),
      selected: get(selectedAddons, addon.id, false)
    };
  });

const zendeskOptions = {
  rootElementId: "zendesk-widget",
  accountKey: "2ebAafrJ0bqZLcwX4GK8jqYJSNmJQjsL",
  theme: "normal",
  hideMinimizeButton: false,
  hideChatButton: false,
  userId: urlParam("userid"),
  disableEmail: true,
  anonymous: true,
  hideOfflineForm: true,
  hideOnInit: false,
  offlineMessage:
    getLocaleOrDefault() === "id"
      ? "Maaf, kami sedang offline saat ini. Mohon hubungi kami kembali  sesuai jam operasional Live Chat: <b>Senin-Jumat 08.00-23.00 WIB, Sabtu-Minggu dan Hari Libur Nasional 08.00-20.00 WIB.</b><br/> Silahkan kunjungi <a target='_blank' style='color: blue; font-weight: bold;' href='https://support.liveon.id/hc/id/'>FAQs</a> kami atau silahkan mengirimkan email ke <a target='_blank' style='color: blue; font-weight: bold;' href='mailto:happiness@liveon.id'>happiness@liveon.id</a>"
      : "Sorry, we are offline at the moment. Please check back during our live chat operation hours: <b>Mon-Fri: 8am to 11pm , Sat-Sun and Public Holiday: 8am to 8pm.</b> <br/> Please check our <a target='_blank' style='color: blue; font-weight: bold;' href='https://support.liveon.id/hc/en-id/'>FAQs</a> or send your message via email to <a target='_blank' style='color: blue; font-weight: bold;' href='mailto:happiness@liveon.id'>happiness@liveon.id</a>",
  redact: true
};

export const activateZendeskWidget = zEIdentify => {
  const standaloneChatUrl = window.STANDALONE_ZENDESK_CHAT_URL;
  const currentPath = window.location.pathname;

  if (currentPath in SPECIALPATHS) {
    const chatUrl = SPECIALPATHS[currentPath];
    const finalchatUrl = isWebview()
      ? chatUrl.replace("https", MOBILE_APP_BROWSER_PROTOCOL)
      : chatUrl;
    window.open(finalchatUrl, "_blank");
  } else if (standaloneChatUrl) {
    const finalUrl = isWebview()
      ? standaloneChatUrl.replace("https", MOBILE_APP_BROWSER_PROTOCOL)
      : standaloneChatUrl;
    window.open(finalUrl);
  } else {
    // If required to prefil the zendesk form pass in the zEIdentify object
    if (zEIdentify && window.zE) {
      const { name = "", email = "", phone = "" } = zEIdentify || {};
      if (get(window, "zE.identify")) {
        window.zE.identify(zEIdentify);
      }
      window.zE("webWidget", "prefill", {
        name: {
          value: name,
          readOnly: !!name // readOnly if value is prefilled
        },
        email: {
          value: email,
          readOnly: !!email // readOnly if value is prefilled
        },
        phone: {
          value: phone,
          readOnly: !!phone // readOnly if value is prefilled
        }
      });
    }
    if (window.YellowMessengerPlugin) {
      window.YellowMessengerPlugin.show();
      window.YellowMessengerPlugin.openBot();
      localStorage.setItem("yellow-messenger-chat-open", true);
    } else if (window.activateZendesk) {
      const options = {
        ...zendeskOptions
      };
      window.activateZendesk(options);
    } else {
      if (window.Intercom) {
        window.Intercom("show");
      }
    }
  }
};

export const activateZendeskApp = () => {
  if (window.activateZendesk) {
    const options = {
      ...zendeskOptions,
      rootElementId: "zendesk-app",
      theme: "standalone",
      hideMinimizeButton: true,
      hideChatButton: true
    };
    window.activateZendesk(options);
  }
};

export const processPlugin = (plugin, pluginData) => {
  const js = get(plugin, "js_libs") || [];
  const css = get(plugin, "css_libs") || [];
  let script = get(plugin, "escaped_script") || "";
  if (script) {
    script = script.replace(/\\\//g, "/");
    forEach(pluginData, (value, key) => {
      script = script.replace(`~${key}~`, value);
    });
  }
  let noScript = get(plugin, "noscript") || "";
  return { js, css, script, noScript };
};

export const processPlugins = (plugins = {}, pluginData = {}) => {
  const jsLibs = [];
  const cssLibs = [];
  const escapedScripts = [];
  const noScripts = [];

  forEach(plugins, (plugin, key) => {
    if (plugin.enabled) {
      const { js, css, script, noScript } = processPlugin(plugin, pluginData);
      jsLibs.push(...js);
      cssLibs.push(...css);
      script && escapedScripts.push(script);
      noScript && noScripts.push(noScript);
    } else {
      window[`${key}Disabled`] = true;
    }
  });

  const escapedScript = escapedScripts.join("\n");

  return { jsLibs, cssLibs, escapedScript, noScripts };
};

export const isIccIdValid = (iccIdInUrl, generic, iccIdField, networkType) => {
  //TODO after 5g release remove this line and uncomment the below line
  const iccIdPrefixName =
    featureFlag5GSAEnabled() && networkType === NETWORK_TYPES.SA
      ? "icc_id_sa.prefix"
      : "icc_id.prefix";
  const iccIdRegexName =
    featureFlag5GSAEnabled() && networkType === NETWORK_TYPES.SA
      ? "icc_id_sa.validation_regex"
      : "icc_id.validation_regex";
  //const iccIdPrefixName = (networkType === NETWORK_TYPES.SA) ? "icc_id_sa.prefix" : "icc_id.prefix";
  const prefix = get(generic, iccIdPrefixName, "");
  const regex = get(generic, iccIdRegexName, "");
  const iccIdFieldValid = iccIdField && RegExp(regex).test(iccIdField);
  const iccIdInUrlValid =
    iccIdInUrl &&
    iccIdInUrl.startsWith(prefix) &&
    RegExp(regex).test(iccIdInUrl.replace(prefix, ""));
  return iccIdInUrlValid || iccIdFieldValid;
};

export const handleRouteLoading = setRouteLoading => {
  setNewRelicAttribute("deviceId", getDeviceId());
  setNewRelicAttribute("locale", getLocaleOrDefault());
  setNewRelicAttribute("webview", isWebview());
  setNewRelicAttribute("native", isNative());
  setNewRelicAttribute("reset", urlParam("reset"));
  setNewRelicAttribute("pathname", window?.location?.pathname);
  if ((!getDeviceId() || isActivationSuccess()) && isWorkflowChannel()) {
    setRouteLoading(true);
    resetApp(isActivationSuccess());
    return true;
  } else {
    setRouteLoading(false);
    return false;
  }
};

export const isWorkflowChannel = () =>
  WORKFLOW_CHANNELS.includes(getChannelFromUrl());

export const resetApp = goHome => {
  const queryParams = {
    reset: true,
    locale: getLocale(),
    webview: getWebview()
  };
  !goHome
    ? gotoUrl(window.location.pathname, true, queryParams, true)
    : isWebview()
    ? gotoUrl(paths.DASHBOARD)
    : gotoUrl(getLandingPage(), true, queryParams, true);
};

export const resetSession = () => {
  const queryParams = {
    reset: true,
    locale: getLocale(),
    webview: getWebview()
  };
  const landingPage = isWorkflowChannel()
    ? getLandingPage()
    : window.location.pathname;
  gotoUrl(landingPage, true, queryParams, true);
};

export const getAuthorizationHeader = () => {
  const timeStamp = new Date().getTime();
  const hashHexDigest = Sha256(ECOM_API_KEY + timeStamp).toString(Hex);
  const token = hashHexDigest.toUpperCase();
  return {
    token,
    timeStamp
  };
};

export const isAlphaFlow = () => {
  const path = window.location.pathname;
  const alphaFlows = [
    ...WORKFLOW_CHANNELS,
    "shop",
    "infinite",
    "manage",
    "self_act_del",
    "schedule_delivery",
    "surveys",
    "identity",
    "plan",
    "profile",
    "wirecard",
    "verify-identity",
    "orders",
    "partners",
    "ekyc_verify"
  ];

  const PUBLIC_PATHS = map(publicPaths, path => path);
  const alphaPaths = [
    paths.LANDING_PAGE,
    paths.PING,
    paths.ERROR_404,
    paths.ERROR_500,
    paths.MAINTENANCE,
    paths.DASHBOARD,
    paths.LOGGED_OUT,
    paths.WEBLOGIN,
    paths.APPLOGIN,
    paths.CLOSEWEBVIEW,
    paths.UPDATE_CARD_SUCCESS,
    paths.PROFILE_UPDATE_EMAIL_SUCESS,
    paths.SCANNER,
    paths.ZENDESK,
    paths.IDENTITY_VERIFICATION,
    paths.PAYMENT_RETURN_V3,
    paths.OUTSTANDING_BILLS,
    paths.DIRECT_LINK_EXPIRED,
    paths.PLAN_SELECTION,
    paths.PROFILE,
    paths.PAYMENT_AMAZON,
    paths.OUTSTANDING_BILLS,
    paths.DIRECT_LINK_EXPIRED,
    paths.OUTSTANDING_BILLS_PAYMENT_STATUS,
    paths.OUTSTANDING_BILLS_PAYMENT_STATUS_SUCCESS,
    paths.OUTSTANDING_BILLS_PAYMENT_STATUS_FAILURE,
    paths.OUTSTANDING_BILLS_PAYMENT_STATUS_IN_PROGRESS,
    paths.OUTSTANDING_BILL_PAYMENT,
    paths.PAYMENT_VERIFY,
    ...PUBLIC_PATHS
  ];

  if (alphaPaths.includes(path)) return true;
  for (const flow of alphaFlows) {
    if (path.match(`/${flow}/`)) {
      return true;
    }
  }
};

export const getDateDetails = date => {
  const dateValue = head(split(date, "T"));
  const dateMoment = moment(dateValue);
  const day = dateMoment.format("DD");
  const month = dateMoment.format("MM");
  const year = dateMoment.format("YYYY");
  const dayOfWeek = dateMoment.format("ddd");
  const currentDate = moment().format("YYYY-MM-DD");
  const dayDiff = dateMoment.diff(currentDate, "days");
  const today = dayDiff === 0;
  const tomorrow = dayDiff === 1;
  return [day, month, year, dayOfWeek, today, tomorrow];
};

export const constructLocalizedDeliverySlotLabel = (dateTime, locale) => {
  if (!dateTime) return "";
  const [date, month, year, dayofWeek, today, tomorrow] = getDateDetails(
    dateTime
  );

  const localizedDayOfWeek = get(
    locale,
    `day_long_${dayofWeek.toLocaleLowerCase()}`,
    ""
  );
  const localizedMonth = get(locale, `month_long_${month}`, "");

  const localizedDate = get(locale, `date_${date}`, "");

  const prefix = today
    ? get(locale, "today", "")
    : tomorrow
    ? get(locale, "tomorrow", "")
    : "";

  const deliverySlotLabel = get(
    locale,
    "delivery_slot_label",
    "{month} {date}, {day}"
  );

  const localizedLabel = formatMessage(deliverySlotLabel, {
    date: localizedDate,
    month: localizedMonth,
    year: year,
    day: localizedDayOfWeek
  });

  return prefix ? `${prefix}, ${localizedLabel}` : localizedLabel;
};

export const constructLocalizedDeliverySlotDetails = (
  deliverySlotFullData = "",
  locale
) => {
  const slotData = split(deliverySlotFullData, " ");
  const slotDate = get(slotData, [0], "");
  const deliveryTime = get(slotData, [1], "");
  const deliveryDate = constructLocalizedDeliverySlotLabel(slotDate, locale);
  return { deliveryDate, deliveryTime };
};

export const addressParams = (info, prefix) => {
  if (isEmpty(info)) return {};
  let unitNo = get(info, `${prefix}_unit_no`);
  let hseBlkTower = get(info, `${prefix}_hse_blk_tower`);
  let streetName = get(info, `${prefix}_street_name`);
  let zipCode = formatZipCode(get(info, `${prefix}_zip_code`) || "");

  // [OG-6277] TODO - move this AU specific logic to config.
  if (lower(COUNTRY) === "au") {
    // [OG-5860] Prefix 'Unit' if not already present in unit_no
    unitNo =
      unitNo && !startsWith(lower(unitNo), "unit") ? `Unit ${unitNo}` : unitNo;
    // [OG-5860] join the hseBlkTower(streetNo) as part of street name
    streetName = compactJoin([hseBlkTower, streetName], " ");
    hseBlkTower = "";
  }

  //For pop station, use the postal code OF THE POP STATION. Not the postal code field that user type in.
  if (prefix === DELIVERY_METHOD_TYPE.POP_STATION) {
    zipCode = get(info, "pop_station_outlet_full_data.postal_code");
  }

  const buildingName = get(info, `${prefix}_building_name`);
  const villageName = getValueOrLabel(get(info, `${prefix}_village`));
  const streetBuildingName = compactJoin(
    [streetName, buildingName, villageName],
    ", "
  );

  return {
    zip_code: zipCode,
    unit_no: unitNo || "",
    floor_no: get(info, `${prefix}_floor_no`) || "",
    street_name: streetName || "",
    street_building_name: streetBuildingName || "",
    building_name: buildingName || "",
    hse_blk_tower: hseBlkTower || "",
    state: getValueOrLabel(get(info, `${prefix}_state`)) || "",
    city: getValueOrLabel(get(info, `${prefix}_city`)) || "",
    district: getValueOrLabel(get(info, `${prefix}_district`)) || "",
    village: getValueOrLabel(get(info, `${prefix}_village`)) || "",
    country: get(info, `${prefix}_country`) || "",
    prefecture: getValueOrLabel(get(info, `${prefix}_state`)) || "",
    address_line_1: streetBuildingName || "",
    address_line_2: hseBlkTower || ""
  };
};

export const getDeliveryDates = (
  deliverySlots,
  deliverDetails,
  combinedSlots
) => {
  const {
    selectedDeliveryMethod,
    scheduleDeliveryDetails,
    locale,
    currencyFormat
  } = deliverDetails || {};

  const currencyFormatter = combinedSlots ? currencyFormat : null;

  let availableDeliverySlots = get(scheduleDeliveryDetails, "slots", []);

  if (isEmpty(availableDeliverySlots)) availableDeliverySlots = deliverySlots;

  const uniqByType = combinedSlots ? "date_time" : "date_string";
  availableDeliverySlots = uniqBy(availableDeliverySlots, uniqByType);

  const slotConfigs = selectedDeliveryMethod?.slot_configs;
  const maxFree = get(slotConfigs, "max_free", 0);
  const maxSpecial = get(slotConfigs, "max_special", 0);

  const freeDeliverySlots = getDeliverySlots(
    availableDeliverySlots,
    maxFree,
    deliverySlot => deliverySlot.cost <= 0
  );

  const specialDeliverySlots = getDeliverySlots(
    availableDeliverySlots,
    maxSpecial,
    deliverySlot => deliverySlot.cost > 0
  );

  const allDeliverySlots = freeDeliverySlots.concat(specialDeliverySlots);

  return constructDeliverySlotDates(
    allDeliverySlots,
    locale,
    currencyFormatter
  );
};

export const deliverySlotParams = (deliveryInfo, personalDetails = {}) => {
  const info = isEmpty(deliveryInfo) ? personalDetails : deliveryInfo;
  let delivery_slot = {};
  const slotDate = head(split(get(info, "delivery_slot_full_data"), " "));
  const delivery_date = get(info, "delivery_date") || slotDate;
  const delivery_end_date = get(info, "delivery_end_date") || delivery_date;
  if (!isNil(info.delivery_slot_id)) {
    delivery_slot = {
      delivery_method: get(info, "delivery_method.value"),
      slot_id: get(info, "delivery_slot_id"),
      delivery_date,
      delivery_end_date,
      delivery_slot: get(info, "delivery_slot"),
      post_office_id: get(info, "post_office_id"),
      delivery_slot_fee: get(info, "delivery_slot_fee") || 0,
      delivery_slot_full_data: get(info, "delivery_slot_full_data")
    };
  }
  return { delivery_slot };
};

export const eLOAParams = formValues => {
  const { dob, doi_dob, rName, rEmail, rNric } = formValues;
  return {
    doi: doi_dob || dob,
    is11BHolder: get(formValues, "is11BHolder", false),
    rName,
    rEmail,
    rNric
  };
};

export const identityELOAParams = formValues => {
  return {
    loa: {
      order_id: get(formValues, "order_id", ""),
      order: eLOAParams(formValues)
    }
  };
};

export const popStationParams = formValues => {
  const {
    pop_station_slot,
    pop_station_slot_id,
    building_name,
    pop_station_zip_code,
    pop_station_slot_full_data,
    pop_station_hse_blk_tower,
    pop_station_outlet_full_data
  } = formValues;
  return {
    delivery_method: get(DELIVERY_METHOD_TYPE, "POP_STATION", "pop_station"),
    delivery_slot: pop_station_slot_full_data,
    hse_blk_tower: pop_station_hse_blk_tower,
    popstation_outlet_id: get(pop_station_outlet_full_data, "outlet_id"),
    slot_id: pop_station_slot_id,
    slot_name: pop_station_slot,
    street_building_name: building_name,
    unit_no: get(pop_station_outlet_full_data, "unit_number"),
    zip_code: pop_station_zip_code
  };
};

export const userDetailParams = (
  personalDetails,
  deliveryInfo,
  userDetails
) => {
  let user_detail = {};
  const details = !isEmpty(personalDetails) ? personalDetails : deliveryInfo;

  if (!isEmpty(details) || !isEmpty(userDetails)) {
    user_detail = {
      first_name: get(details, "first_name", get(details, "full_name", "")),
      middle_name: get(details, "middle_name"),
      last_name: get(details, "last_name"),
      email: get(details, "email") || get(userDetails, "email"),
      country_code: get(details, "country_code"),
      phone_number: get(details, "contact_number"),
      nationality: get(details, "nationality.value"),
      dob: get(details, "dob"),
      nric: get(details, "nric")
    };
  }
  return { user_detail };
};

export const documentsParams = formValues => {
  let documents = {};
  const primary_id = get(formValues, "nric") || get(formValues, "primary_id");
  const secondary_id = get(formValues, "secondary_id");
  const doc_id =
    get(formValues, "documents.primary.front_image") ||
    get(formValues, "documents.primary.back_image") ||
    get(formValues, "documents.secondary.front_image") ||
    get(formValues, "documents.secondary.back_image");
  const primary_doc_type =
    get(formValues, "doc_type.value") ||
    get(formValues, "primary_doc_type.value") ||
    (get(formValues, "nric") ? "nric" : "");
  const secondary_doc_type = get(formValues, "secondary_doc_type.value");

  let issued_by;
  if (primary_doc_type === "international_passport") {
    issued_by = get(formValues, "country_of_issue.value");
  } else if (primary_doc_type === "driving_licence") {
    issued_by = get(formValues, "state_of_issue.value");
  }

  if (!isEmpty(primary_id)) {
    documents = {
      doc_id,
      details: [
        {
          doc_id,
          doc_type: primary_doc_type,
          category: "primary",
          value: primary_id,
          issued_by
        }
      ]
    };
    if (!isEmpty(secondary_id)) {
      documents.details.push({
        doc_id,
        doc_type: secondary_doc_type,
        category: "secondary",
        value: secondary_id
      });
    }
  }

  return documents;
};

export const deliverDetailParams = (deliveryInfo, personalDetails, channel) => {
  // delivery details may come from personal details page
  const info = isEmpty(deliveryInfo) ? personalDetails : deliveryInfo;
  let prefix = "delivery";

  let deliver_detail = {};
  if (!isEmpty(info)) {
    deliver_detail = addressParams(info, prefix);
    deliver_detail["delivery_note"] = get(info, "delivery_note");
  }

  return { deliver_detail };
};

export const sanitizeDeliveryAddressLabel = (
  addressDetails,
  deliveryAddressLabel
) => {
  let sanitizedLabel = deliveryAddressLabel;

  const fieldRegex = /{[a-zA-Z_0-9]+}/g;
  const matches = deliveryAddressLabel.match(fieldRegex);
  const fields = map(matches, match => trim(match, "{}"));

  let allAddressFieldsEmpty = !isEmpty(fields);

  each(fields, field => {
    const value = get(addressDetails, field);
    if (!value) {
      const regex = new RegExp(`{${field}}[-, ]?`, "g");
      sanitizedLabel = sanitizedLabel.replace(regex, "");
    } else {
      allAddressFieldsEmpty = false;
    }
  });

  return allAddressFieldsEmpty ? "" : trim(sanitizedLabel, "-, <br/>");
};

export const constructLocalizedDeliveryAddress = (
  addressDetails,
  locale,
  label = "delivery_address_label"
) => {
  const deliveryAddressLabel = get(
    locale,
    label,
    "{zip_code} {city} {district} {street_building_name}"
  );

  // Remove fields in the target format if they are empty. ex: {unit_no}, {street_building_name} => {street_building_name}
  // This is to avoid dangling commas if the optional field unit_no is not specified.
  const sanitizedLabel = sanitizeDeliveryAddressLabel(
    addressDetails,
    deliveryAddressLabel
  );

  return formatMessage(sanitizedLabel, addressDetails);
};

export const getValueOrLabel = value => getValueOrField(value, "label");

export const getValueOrField = (value, field) =>
  isPlainObject(value) ? value[field] : value;

export const compactJoin = (values, separator = "") =>
  join(compact(values), separator);

export const getZipcodePrefixAndSuffix = (zipcode, separator = "-") => {
  return includes(zipcode, separator)
    ? split(zipcode, separator)
    : [zipcode?.substring(0, 3), zipcode?.substring(3)];
};

export const formatZipCode = (zipCode = "", separator = "-") => {
  // Check if the zip code is already in the correct format
  if (zipCode && includes(zipCode, separator)) {
    return zipCode; // Return as is
  }

  if (!zipCode || typeof zipCode !== "string" || zipCode.length !== 7) {
    return "";
  }

  const prefix = zipCode.substring(0, 3);
  const suffix = zipCode.substring(3);

  return `${prefix}${separator}${suffix}`;
};

export const calculatePayMonthlyItems = ({
  basicPrice,
  items,
  currencyFormat,
  referralCode,
  addons,
  defaultPlanAddons,
  basicTax = 0,
  phonesForm,
  discount,
  numberType,
  planDetails,
  selectedNumberType,
  selfActivation,
  generic
}) => {
  let price =
    !isEmpty(discount) &&
    includes(get(discount, "allowed_plans"), get(planDetails, "name")) &&
    ((numberType === "port_in" && isEmpty(selectedNumberType)) ||
      selectedNumberType.isPortIn)
      ? basicPrice - discount.value
      : basicPrice;

  const label =
    !isEmpty(discount) &&
    includes(get(discount, "allowed_plans"), get(planDetails, "name")) &&
    ((numberType === "port_in" && isEmpty(selectedNumberType)) ||
      selectedNumberType.isPortIn)
      ? "port_in_discount"
      : get(planDetails, "name") === "CirclesParent"
      ? "circles_family_base_price"
      : "base_price";

  if (get(selfActivation, "sim_count")) {
    const sim_count = get(selfActivation, "sim_count") || 1;
    const child_sim_count = sim_count - 1;
    const child_sim_cost =
      child_sim_count * get(generic, "family_plan_child_sim.price");
    if (child_sim_count) {
      price = price + child_sim_cost;
    }
  }
  const precision = get(currencyFormat, "precision", 2);
  const price_without_tax = ceil(price / (1 + basicTax / 100), precision);
  const basePrice = {
    label,
    value: price,
    value_without_tax: price_without_tax,
    type: "plan",
    price: formatCurrency(price, currencyFormat),
    price_without_tax: formatCurrency(price_without_tax, currencyFormat)
  };

  const baseItems = map(items, item => {
    const itemTaxPercent = Number(get(item, "tax_percentage", basicTax));
    const price_without_tax =
      item.value === 0
        ? null
        : formatCurrency(
            item.value / (1 + itemTaxPercent / 100),
            currencyFormat
          );
    const price =
      item.value === 0 ? null : formatCurrency(item.value, currencyFormat);

    return {
      ...item,
      price,
      price_without_tax
    };
  });

  const referralCodeBenefits = getReferralCodeBenefitsForPayMonthly(
    referralCode.benefits,
    currencyFormat
  );

  const selectedAddons = addons.filter(addon => addon.selected);
  const defaultAddons = formatAddonsForPayMonthly(
    defaultPlanAddons,
    currencyFormat,
    basicTax
  );

  const phoneDetails = getPhoneDetailsForPayMonthly(phonesForm, currencyFormat);

  const payMonthlyItems = [
    basePrice,
    ...baseItems,
    ...defaultAddons,
    ...selectedAddons,
    ...referralCodeBenefits,
    ...phoneDetails
  ];

  return payMonthlyItems;
};

export const calculatePayTodayItems = ({
  items,
  currencyFormat,
  referralCode,
  deliveryInfo,
  personalDetails,
  selectedNumber,
  selectedNumberType,
  portInForm,
  page,
  numberFormat,
  locale,
  phonesForm,
  basicTax = 0,
  selfActivationForm,
  preActivationDeliveryAddon
}) => {
  const isPortIn = selectedNumberType.isPortIn;

  const payTodayItems = map(items, item => {
    const itemTaxPercent = Number(get(item, "tax_percentage", basicTax));
    const price_without_tax = formatCurrency(
      item.value || 0 / (1 + itemTaxPercent / 100),
      currencyFormat,
      true
    );
    const price = formatCurrency(item.value || 0, currencyFormat, true);
    return {
      ...item,
      price,
      description: price,
      price_without_tax
    };
  });

  let selectedNumberItem = [];
  if (page !== "PreCheckout") {
    const { number, price, sku } = selectedNumber || {};
    let numberTypeText = getNumberType({ sku, isPortIn });

    const numberTypeSelectedNumber = isPortIn
      ? get(portInForm, "post_paid_number")
      : formatDisplayNumber(number, numberFormat);

    numberTypeText = get(locale, numberTypeText, numberTypeText);
    const numberItem = {
      label: `${numberTypeText}: ${numberTypeSelectedNumber}`,
      type: "number"
    };

    if (!isPortIn && price > 0) {
      numberItem["value"] = price;
      numberItem["price"] = formatCurrency(price, currencyFormat, true);
    }

    selectedNumberItem.push(numberItem);
  }

  const referralCodeBenefits = getReferralCodeBenefitsForPayToday(
    referralCode.benefits,
    currencyFormat
  );

  const deliveryForm = isEmpty(deliveryInfo)
    ? isEmpty(personalDetails)
      ? selfActivationForm
      : personalDetails
    : deliveryInfo;
  const deliveryAddOnPrice = find(preActivationDeliveryAddon, {
    name: EXPRESS_DELIVERY
  });
  const deliverySlotFee =
    get(deliveryForm, "delivery_slot_fee", 0) ||
    get(deliveryAddOnPrice, "price", 0);
  const deliverySlotItems = [];
  const slotLabel = "pay_today_special_delivery_slot_fee";
  if (deliverySlotFee > 0) {
    deliverySlotItems.push({
      label: get(locale, slotLabel, slotLabel),
      price: formatCurrency(deliverySlotFee, currencyFormat, true),
      value: deliverySlotFee,
      type: "delivery"
    });
  }

  const phoneDetails = getPhoneDetailsForPayToday(phonesForm, currencyFormat);

  const allPayTodayItems = [
    ...payTodayItems,
    ...selectedNumberItem,
    ...deliverySlotItems,
    ...referralCodeBenefits,
    ...phoneDetails
  ];

  return allPayTodayItems;
};

export const mapAddressComponentOption = (component = {}) => ({
  label: component.name,
  value: component.id
});

export const getChannelFromUrl = () => {
  const urlParts = get(window, "location.pathname", "").split("/");
  const channel = urlParts.length > 1 ? urlParts[1] : "web";
  return channel;
};

export const getNumberType = ({ sku, isPortIn }) => {
  const numberType = {
    "TEL-NUMGEN": "free_number",
    "TEL-NUMSLVR": "silver_number",
    "TEL-NUMGOLD": "golden_number"
  };
  return isPortIn ? "portin_number" : get(numberType, sku, "new_number");
};

export const canAllowAction = (action, steps) =>
  RESTRICTED_STEP_ACTIONS.includes(action)
    ? !isEmpty(
        find(steps, step =>
          isArray(step.action)
            ? step.action.includes(action)
            : step.action === action
        )
      )
    : true;

export const getActionUrl = (action = "", orderChannel = "web") => {
  const channel = WEB_CHANNELS.includes(orderChannel) ? "web" : orderChannel;
  const url = get(ACTION_URLS, lower(action), "");
  return url.replace(":channel", channel).replace(":type", action);
};

export const getPaymentStatusUrl = (status = "") => {
  const url = get(PAYMENT_STATUS_URLS, lower(status), paths.PAYMENT_STATUS);
  return url.replace(":channel", getChannelFromUrl());
};

export const constructOrderDetails = (
  details,
  paymentStatus,
  numberFormat,
  locale,
  orderParams,
  userProfileDetails
) => {
  if (isEmpty(details) && isEmpty(paymentStatus)) return null;

  const addressDetails = get(
    details,
    "order_deliver_detail",
    get(orderParams, "deliver_detail", {})
  );

  const deliveryAddress = constructLocalizedDeliveryAddress(
    addressDetails,
    locale
  );

  const deliveryAddressHtml = constructLocalizedDeliveryAddress(
    addressDetails,
    locale,
    "delivery_address_html_format"
  );

  const selected_number = get(
    details,
    "selected_number",
    get(orderParams, "selected_number")
  );
  const selectedNumber = formatDisplayNumber(selected_number, numberFormat);
  const countryCode = get(
    details,
    "country_code",
    get(orderParams, "user_detail.country_code")
  );
  const current_contact_number = get(
    details,
    "customer_current_contact_number",
    get(orderParams, "user_detail.customer_current_contact_number")
  );
  const alternate_contact_number = get(
    details,
    "contact_number",
    get(orderParams, "user_detail.phone_number")
  );
  const data_only_contact_number = get(
    userProfileDetails,
    "contact_number",
    get(details, "contact_number")
  );
  const contact_number = current_contact_number || alternate_contact_number;
  const contactNumber = formatContactNumber(
    contact_number,
    numberFormat,
    countryCode
  );
  const currentContactNumber = formatContactNumber(
    current_contact_number,
    numberFormat,
    countryCode
  );
  const alternateContactNumber = formatContactNumber(
    alternate_contact_number,
    numberFormat,
    countryCode
  );

  const dataOnlyContactNumber = formatContactNumber(
    data_only_contact_number,
    numberFormat,
    countryCode
  );

  const slotData = get(
    details,
    "delivery_slot_full_data",
    get(orderParams, "delivery_slot.delivery_slot_full_data")
  );
  const slotDetails = constructLocalizedDeliverySlotDetails(slotData, locale);
  const { deliveryDate, deliveryTime } = slotDetails;
  const deliveryDateTime = constructDeliveryDateTime({
    deliveryDate,
    deliveryTime,
    locale
  });
  const contactEmail = get(
    details,
    "contact_email",
    get(orderParams, "user_detail.email")
  );

  const fullName = get(details, "name");
  const firstName = get(
    details,
    "order_user_detail.first_name",
    get(orderParams, "user_detail.first_name")
  );
  const middleName = get(
    details,
    "order_user_detail.middle_name",
    get(orderParams, "user_detail.middle_name")
  );
  const lastName = get(
    details,
    "order_user_detail.last_name",
    get(orderParams, "user_detail.last_name")
  );
  const dateOfBirth = get(
    details,
    "order_user_detail.dob",
    get(orderParams, "user_detail.dob")
  );

  const name = fullName || compactJoin([firstName, middleName, lastName], " ");

  const portNumber = get(
    details,
    "port_number",
    get(orderParams, "portin_detail.port_number")
  );
  const isPortInRequest = get(details, "is_port_in_request", portNumber);
  const portDonor = get(
    details,
    "port_donor",
    get(orderParams, "portin_detail.port_donor")
  );
  const portinNumber = portNumber
    ? formatDisplayNumber(portNumber, numberFormat)
    : "";
  const portinNumberFromDonorMessage = get(
    locale,
    "portin_number_from_donor",
    "{portin_number} from {donor}"
  );
  const portinNumberFromDonor =
    portinNumber && portDonor
      ? formatMessage(portinNumberFromDonorMessage, {
          portin_number: portinNumber,
          donor: portDonor
        })
      : portinNumber;
  const idVerificationStatus = get(
    details,
    "order_product_info.idVerificationStatus"
  );
  const authorizeEnabled = get(details, "authorize_enabled", false);
  const authorizerName = get(details, "authoriser_name");

  const current_sim =
    get(details, "sim_replacement_detail.current_sim") ||
    get(details, "sim_type");
  const requested_sim = get(
    details,
    "sim_replacement_detail.requested_sim",
    null
  );

  const currentNetworkType =
    get(details, "sim_replacement_detail.current_network_type") ||
    get(details, "network_type");

  const requestedNetworkType = get(
    details,
    "sim_replacement_detail.requested_network_type",
    null
  );

  return {
    orderRef: get(details, "order_ref"),
    items: get(details, "items"),
    steps: get(details, "steps", []),
    state: get(details, "state"),
    name: name?.trim(),
    firstName: firstName?.trim(),
    middleName: middleName?.trim(),
    lastName: lastName?.trim(),
    dateOfBirth: moment(dateOfBirth).format("YYYY MM DD"),
    contactEmail,
    contactNumber,
    currentContactNumber,
    alternateContactNumber,
    dataOnlyContactNumber,
    selectedNumber,
    portinNumber,
    portinNumberFromDonor,
    deliveryDate,
    deliveryTime,
    deliveryDateTime,
    deliveryAddress,
    deliveryAddressHtml,
    deliveryActions: get(details, "delivery_actions", []),
    orderActions: get(details, "order_actions"),
    orderActionName: get(details, "order_action_name"),
    idVerificationStatus,
    isPortInRequest,
    portDonor,
    idUploadPending: get(details, "id_upload_pending"),
    channel: get(details, "channel"),
    paymentStatus: get(details, "payment_status"),
    trackingNumber: get(details, "tracking_number"),
    trackingUrl: get(details, "tracking_url"),
    simType: current_sim,
    requestedSim: requested_sim,
    contractType: get(details, "contract_type"),
    contractPlan: get(details, "channel"),
    // networkType: get(details, "network_type"),
    ...(featureFlag5GSAEnabled() && {
      networkType: currentNetworkType,
      requestedNetworkType: requestedNetworkType
    }),
    nextStep: get(details, "next_step"),
    authorizeEnabled,
    authorizerName,
    id: get(details, "id"),
    otpActionType: get(details, "otp_action_type"),
    kycOrgCode: get(details, "kyc_org_code", "")
  };
};

export const constructDeliveryDateTime = ({
  deliveryDate,
  deliveryTime,
  locale
}) =>
  formatMessage(
    get(
      locale,
      "delivery_date_and_time_format",
      "{deliveryDate} {deliveryTime}"
    ),
    { deliveryDate, deliveryTime }
  ).trim();

export const flattenObject = (obj, prefix = "", separator = ".") => {
  let flattenedObject = {};
  try {
    flattenedObject = keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? prefix + separator : "";
      if (typeof obj[k] === "object") {
        Object.assign(acc, flattenObject(obj[k], pre + k, separator));
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});
  } catch (err) {
    console.log("error during flattenObject ", err);
  }

  return flattenedObject;
};

export const removeSensitiveData = data => {
  const sensitiveFieldsPattern = /(documents|nric|primary_id|secondary_id|token|auth|session_token|auth_token)/g;
  const fieldsToRemove = filter(keys(data), field => {
    const remove = field && field.match(sensitiveFieldsPattern);
    return remove;
  });
  for (const field of fieldsToRemove) {
    data[field] && delete data[field];
  }
  return data;
};

export const getGopayActionUrls = result => {
  const actions = get(result, "form_data.gopay.actions", []);
  const deepLinkUrl = get(find(actions, { name: "deeplink-redirect" }), "url");
  const qrCodeUrl = get(find(actions, { name: "generate-qr-code" }), "url");
  return { deepLinkUrl, qrCodeUrl };
};

export const generateHtmlElement = (key, value, type) => {
  const field = document.createElement("input");
  field.setAttribute("type", type);
  field.setAttribute("name", key);
  field.setAttribute("value", value);
  return field;
};

export const loadScript = (scriptName, url, callback, maximumRetries = 3) => {
  let retryScript = 0;

  const onLoad = () => {
    const existingScript = document.getElementById(scriptName);

    if (!existingScript) {
      const script = document.createElement("script");
      script.src = url;
      script.id = scriptName;

      script.onerror = () => {
        console.error(`Unable to load script: ${url}`);
        if (retryScript < maximumRetries) {
          retryScript++;
          console.log(`Retrying to load the following script (${scriptName})`);
          script.remove();
          onLoad();
        }
      };

      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    }
    if (existingScript && callback) callback();
  };
  onLoad();
};

export const removeScript = key => {
  const scriptElement = document.getElementById(key);
  if (scriptElement !== null) {
    scriptElement.remove();
  }
};

export const getDeviceType = () => {
  return isMobile() ? "Mobile" : "Web";
};

export const constructPhoneItem = phonesForm => {
  const { phoneVariant, phonePaymentOption } = phonesForm || {};
  if (phoneVariant && phonePaymentOption) {
    const { nameWithSpecs, sku } = phoneVariant;
    const {
      pay_monthly,
      pay_monthly_months,
      pay_monthly_total,
      pay_now,
      price,
      discount,
      monthly_discount,
      original_pay_now,
      original_pay_monthly
    } = get(phoneVariant, `paymentOption.${phonePaymentOption}`);

    return {
      item_type: "PHONE",
      quantity: 1,
      name: nameWithSpecs,
      sku,
      price,
      discount,
      monthly_discount,
      original_pay_now,
      original_pay_monthly,
      pay_now,
      pay_monthly,
      pay_monthly_months,
      pay_monthly_total
    };
  }
};

export const getPhoneDetailsForPayMonthly = (phonesForm, currencyFormat) => {
  const phoneItem = constructPhoneItem(phonesForm);
  const details = [];
  if (!isEmpty(phoneItem)) {
    details.push({
      type: "phone",
      label: "Monthly Phone Cost",
      value: phoneItem.pay_monthly,
      price: formatCurrency(phoneItem.original_pay_monthly, currencyFormat)
    });
    if (phoneItem.monthly_discount > 0) {
      details.push({
        type: "phone",
        benefit: true,
        label: "Phone Rebate",
        price: formatCurrency(-phoneItem.monthly_discount, currencyFormat, true)
      });
    }
    details.push({
      type: "phone",
      label: "Phone Payment Period",
      price: `${phoneItem.pay_monthly_months} months`
    });
  }
  return details;
};

export const getPhoneDetailsForPayToday = (phonesForm, currencyFormat) => {
  const phoneItem = constructPhoneItem(phonesForm);
  const details = [];
  if (!isEmpty(phoneItem)) {
    details.push({
      type: "phone",
      label: phoneItem.name,
      value: phoneItem.pay_now,
      price: formatCurrency(phoneItem.original_pay_now, currencyFormat)
    });
    if (phoneItem.discount > 0 && phoneItem.monthly_discount <= 0) {
      details.push({
        type: "phone",
        benefit: true,
        label: "Phone Rebate",
        price: formatCurrency(-phoneItem.discount, currencyFormat, true)
      });
    }
  }
  return details;
};

export const pathWithChannel = (path, channel) =>
  path.replace(":channel", channel || getChannelFromUrl());

export const nextPageWithParams = (path, selectedNumber, authToken) => {
  const msisdn = get(selectedNumber, "number");
  let nextPage = path;
  if (msisdn) {
    nextPage = addUrlParam("msisdn", msisdn, nextPage);
  }
  if (isWebview()) {
    nextPage = addUrlParam("auth_token", authToken, nextPage);
  }
  return nextPage;
};

export const formatAddressForPopStationItem = popStationInformation => {
  let unitNumber = get(popStationInformation, "unit_number", "");
  let storey = get(popStationInformation, "storey", "");
  let buildingName = get(popStationInformation, "building_name", "");
  let streetName = get(popStationInformation, "streetName", "");
  let country = get(popStationInformation, "country", "");
  let postalCode = get(popStationInformation, "postal_code", "");
  let formattedAddress = [];
  let orderedAddressParam = [
    unitNumber,
    storey,
    buildingName,
    streetName,
    country,
    postalCode
  ];

  formattedAddress = orderedAddressParam.filter(prop => {
    if (prop) return prop;

    return false;
  });

  return formattedAddress.join(", ");
};

export const autoScrollToError = () => {
  (() =>
    setTimeout(() => {
      let element = document.querySelectorAll(".field-error");
      if (element.length > 0) {
        element[0].scrollIntoView(true);
        let scrolledY = window.scrollY;
        if (scrolledY) {
          window.scroll({
            top: scrolledY - 150,
            left: 0,
            behavior: "smooth"
          });
        }
      }
    }, 0))();
};

export const getOutstandingBillReferralType = () => {
  let directTypeCondition = urlParam("token");

  let referralType = OUTSTANDING_BILL_REFERRAL_FROM_TYPE.MANAGE_ORDER;

  if (directTypeCondition)
    referralType = OUTSTANDING_BILL_REFERRAL_FROM_TYPE.DIRECT_LINK;

  return referralType;
};

export const scrollToDomNode = domNode => {
  if (domNode) {
    scrollIntoView(domNode, { behavior: "smooth", scrollMode: "if-needed" });
  }
};

//parse condition_variable into our regular condition string
export const parseConditionVariables = (
  appSettingConditions = {},
  conditionVariable = "",
  level = 1,
  _iteration = 0
) => {
  if (!conditionVariable) return "";

  let found = findCurlyBraceValues(conditionVariable);
  let condition =
    replaceCurlyBraceValues(found, conditionVariable, appSettingConditions) ||
    "";

  //check nested condition variable
  if (_iteration < level) {
    condition = parseConditionVariables(
      appSettingConditions,
      condition,
      level,
      _iteration + 1
    );
  }

  return condition;
};

export const findCurlyBraceValues = (conditionVariable = "") => {
  let found = [],
    reg = /{([^}]+)}/g,
    currentMatch;

  while ((currentMatch = reg.exec(conditionVariable))) {
    found.push(currentMatch[1]);
  }

  return found;
};

export const replaceCurlyBraceValues = (
  values = [],
  conditionVariable = "",
  appSettingConditions = {}
) => {
  let condition = conditionVariable;

  if (!appSettingConditions) return condition;

  values.forEach(value => {
    let appSettingCondition = appSettingConditions[value];
    condition = condition.replace(`{${value}}`, `${appSettingCondition}`);

    if (!appSettingCondition && typeof appSettingConditions !== "boolean")
      console.warn(
        `Invalid condition_variable - ${value}. Not defined in appSettings.conditions. Please check this condition_variable.`
      );
  });

  return condition;
};

export const adobeOrderItemsFormatter = (items, planName) =>
  items
    .filter(item => item.item_type !== "SIM")
    .map(item => ({
      quantity: 1,
      price: {
        basePrice: item.pay_now || 0
      },
      productInfo: {
        productID: item.sku,
        productCategory: item.item_type,
        planName: planName,
        productName: item.name,
        productBrand: "circles",
        productSize: "n/a",
        productColour: "n/a",
        productDescription: "n/a",
        productType: item.item_type,
        productDiscount: item.discount
      }
    }));

export const handleRelatedTargetAfterBlur = event => {
  event && event.persist();
  event &&
    event.relatedTarget &&
    !endsWith(event.relatedTarget.id, "_DropIdentity") &&
    event.relatedTarget.click();
};

/**
 * Controls if the country should support eligibility API in manage order
 * @returns {boolean} if the country is eligibility API supported
 */
export const isEligibilityAPISupported = () => {
  const ELIGIBILITY_API_SUPPORTED_COUNTRIES = ["JP"];
  const countryInUpperCase = COUNTRY?.toUpperCase();
  return ELIGIBILITY_API_SUPPORTED_COUNTRIES.includes(countryInUpperCase);
};

export const getPaymentOption = type => {
  switch (type) {
    case "f":
    case "full":
      return "full";
    case "ipp":
    case "z":
      return "installment";
    case "s":
      return "split";
    default:
      return "full";
  }
};

export const convertCamelCase = input => {
  const converted = input
    .replace(/([A-Z])/g, " $1")
    .trim()
    .replace(" ", "_")
    .toLowerCase();
  return converted;
};

export const getMyInfoAuthorisedURL = () => {
  const redirectUri = window.location.origin + window.location.pathname;

  const parameters = {
    client_id: MYINFO_CLIENT_ID || "",
    attributes: "uinfin,name,nationality,dob,email,mobileno,mailadd",
    purpose: "Circles.life mobile plan registration",
    state: redirectUri,
    redirect_uri: redirectUri
  };

  const authoriseUrl = queryString.stringifyUrl({
    url: MYINFO_ENDPOINT || "",
    query: parameters
  });

  return authoriseUrl;
};

export const removeQueryParams = (url = "", queryParamsToRemove = []) => {
  const parsedQuery = queryString.parse(window.location.search);
  let targetQuery = {};

  forEach(parsedQuery, (val, key) => {
    if (indexOf(queryParamsToRemove, key) === -1) {
      targetQuery[key] = val;
    }
  });

  return queryString.stringifyUrl({
    url: head(url.split("?")),
    query: targetQuery
  });
};

export const getDOBSecurityHeader = dob => {
  if (!dob) return {};
  const securityDOB = moment(dob, DOB_FORMAT).format(SECURITY_DOB_FORMAT);
  const dobDetails = JSON.stringify({ dateOfBirth: securityDOB });
  return { "X-SECURITY-CONTROL": window.btoa(dobDetails) };
};

export const isContainWhiteSpace = (value = "") => {
  const regex = /\s/;
  return regex.test(value);
};

export const splitJapaneseName = (japaneseName = "") => {
  const names = japaneseName.includes("　")
    ? japaneseName.split("　")
    : japaneseName.split(" ");
  return compact(names.map(name => trim(name, "　 ")));
};

export const constructLocalizedDateLabel = (
  currentDate,
  dateOptions,
  monthOptions,
  expiryDateLabelFormat
) => {
  const currentDay = currentDate.date();
  const currentMonth = currentDate.month() + 1;
  const currentYear = currentDate.year();
  const paddedDay = currentDay.toString().padStart(2, "0");
  const paddedMonth = currentMonth.toString().padStart(2, "0");
  const localizedDay =
    find(dateOptions, ["value", paddedDay])?.label || paddedDay;
  const localizedMonth =
    find(monthOptions, ["value", paddedMonth])?.label || paddedMonth;
  const label = expiryDateLabelFormat
    .replace("YYYY", currentYear)
    .replace("DD", localizedDay)
    .replace("MMM", localizedMonth)
    .replace("MM", localizedMonth);
  return label;
};

export const sanitizeHTML = html => DOMPurify.sanitize(html);

export const setNewRelicAttribute = (key, _value, securedBy = "") => {
  let value;

  if (isObject(_value)) {
    try {
      value = JSON.stringify(_value);
    } catch (e) {
      value = e.message;
    }
  } else {
    value = _value;
  }

  switch (securedBy?.toUpperCase()) {
    case "RSA":
      // reversible with private key
      value = getEncrypted(value);
      break;
    case "HASH":
      // non reversible
      value = getHash(value);
      break;
    default:
      break;
  }

  const setAttribute = window?.newrelic?.setCustomAttribute;
  if (isFunction(setAttribute) && key) {
    setAttribute(key, value ?? "N/A");
    console.debug("Setting newrelic attribute", { key, value });
  }
};

// Ref: https://docs.newrelic.com/docs/browser/new-relic-browser/browser-apis/noticeerror/
export const setNRError = (errorObj, customAttributes = {}) => {
  try {
    const deviceId = getDeviceId();
    const defaultAttributes = {
      deviceId,
      currentPath: window.location?.pathTracking?.current,
      previousPath: window.location?.pathTracking?.previous
    };

    console.log("defaultAttributes", defaultAttributes);
    const noticeError = window?.newrelic?.noticeError;

    if (isFunction(noticeError)) {
      noticeError(errorObj, { ...defaultAttributes, ...customAttributes });
    }
  } catch (e) {
    console.error("Failed setting NR Error via noticeError", e);
  }
};

export const contructTerminationStatusStep = (
  eligibilityActionsStatus,
  locale
) => {
  let terminationStatusStep;

  const accountTerminationStatus = upper(
    get(eligibilityActionsStatus, "account_termination.status")
  );
  if (TERMINATION_PENDING_STATUSES.includes(accountTerminationStatus)) {
    const pendingAccountTermination = formatMessage(
      get(locale, "pending_account_termination")
    );
    const terminationAfterHoursMessage = formatMessage(
      get(locale, "termination_after_hours_message")
    );
    terminationStatusStep = {
      state: "processing",
      title: pendingAccountTermination,
      description: terminationAfterHoursMessage
    };
  } else if (accountTerminationStatus === TERMINATE_ACTION_STATUS.FAIL) {
    const terminationFail = formatMessage(get(locale, "termination_fail"));
    terminationStatusStep = { state: "fail", title: terminationFail };
  }

  return terminationStatusStep;
};

export const contructPortoutStatusStep = (eligibilityActionsStatus, locale) => {
  let portoutStatusStep;
  const portoutStatus = upper(get(eligibilityActionsStatus, "portout.status"));

  if (PORTOUT_PENDING_STATUSES.includes(portoutStatus)) {
    let portoutStatusMessage = formatMessage(get(locale, "mnp_being_issued"));
    let portoutValidityMessage = formatMessage(get(locale, "mnp_will_be_sent"));

    if (PORTOUT_IN_PROGRESS === portoutStatus) {
      // if date is not available moment will pick the curent date
      const valid_until_date = moment(
        get(eligibilityActionsStatus, "portout.transactionIdValidity")
      ).format("YYYY/MM/DD");
      const mnp_reservation_number = get(
        eligibilityActionsStatus,
        "portout.mnp_reservation_number"
      );
      const values = {
        mnp_reservation_number,
        valid_until_date
      };
      portoutStatusMessage = formatMessage(get(locale, "mnp_issued"));
      portoutValidityMessage = formatMessage(
        get(locale, "portout_validity_message_with_mnp"),
        values
      );
    }
    portoutStatusStep = {
      state: "processing",
      title: portoutStatusMessage,
      description: portoutValidityMessage
    };
  } else if (PORTOUT_FAILED_STATUSES.includes(portoutStatus)) {
    const portoutFail = formatMessage(get(locale, "portout_failed"));
    portoutStatusStep = { state: "fail", title: portoutFail };
  } else if (PORTOUT_COMPLETED_STATUSES.includes(portoutStatus)) {
    portoutStatusStep = {
      state: "success",
      title: formatMessage(get(locale, "portout_success"))
    };
  }

  return portoutStatusStep;
};

export const generateRandomNumber = (numDigits = 1) => {
  /**
   * Generate random number of numDigits length.
   * @param {number} numDigits Desired number of digits of random number. Default to generate 1 number.
   * @return {number} A random integer of numDigits length
   */
  const dotIndex = 2;
  const numberLengthIndex = dotIndex + numDigits;

  if (
    numDigits <= 0 ||
    !Number.isInteger(numDigits) ||
    !(typeof numDigits === "number")
  )
    return; // only accept number datatype and integers

  const num = Math.random()
    .toString()
    .substring(dotIndex, numberLengthIndex); // construct random number
  return num;
};

/**
 * Returns URL params object required for user details API
 * @returns {object} user details url params object
 */
export const loggedInUserDetailsAPIParams = () => ({
  include_telco: true,
  include_ekyc: true,
  include_social: PORTING_ACCOUNTS
});

/**
 * Returns portin providers as an array
 * @returns {Array} portin providers
 */
export const portinProviders = () => {
  if (isEmpty(PORTING_ACCOUNTS)) return [];
  else return PORTING_ACCOUNTS.split(",");
};

export const getDeepLink = () =>
  (isQAApp() && MOBILE_APP_DEEP_LINK_QA) || MOBILE_APP_DEEP_LINK;

/**
 * Calls webview specific url to update app with new token
 * @param {string} auth_token token value to update back to app
 * @returns void
 */
export const updateTokenToApp = auth_token => {
  if (!isWebview()) return;

  try {
    const params = {
      auth_token,
      deviceId: getDeviceId()
    };
    gotoUrl(UPDATE_TOKEN_TO_APP_URI, false, params);
  } catch (e) {
    console.error("Failed to update token to app", e);
  }
};

export const getEncrypted = value => {
  if (!value) return value;

  const PUBLIC_KEY = `-----BEGIN PUBLIC KEY-----
  MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDCoZW6o/z9llhLaHBvKewHJWZt
  G8DbmVnuR2PaIJp/0dm6wwX9l41uC/rmcbvLWwMHE+Z+Whc78bHvhXTuRf5EUrAu
  ABzNyy8WYpQzF6r6ju0JfbrFb10uLcLtF+wsefjmWE9Fs9xPyuHRNneqfNNiN3wD
  js0nAkbZujJABfTcRQIDAQAB
  -----END PUBLIC KEY-----`;

  try {
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLIC_KEY);
    const encryptedValue = encrypt.encrypt(value);

    console.debug("encryptedValue: ", encryptedValue);
    return encryptedValue;
  } catch (e) {
    console.error("Encryption failed:", e);
    return;
  }
};

export const getHash = value => {
  if (!value) return value;

  const hashedValue = CryptoJS.SHA256(value).toString();
  console.debug("hashedValue: ", hashedValue);
  return hashedValue;
};

export const sortAndFilterOrders = userOrders =>
  orderBy(
    filter(userOrders, order => !includes(["IC", "TER", "CAN"], order.state)),
    ["id"],
    ["asc"]
  );

/**
 * Checks if current time is within the start and end time based on the given timezone
 * @param {string} timezone valid timezone database name Ref: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
 * @param {string} activationStartTime valid time in 24h format HH:MM:SS
 * @param {string} activationEndTime valid time in 24h format HH:MM:SS
 * @returns {boolean} true if during online hours, false otherwise
 * @throws {Error} if any of the params are missing
 *
 * Example usage
 * isDuringOnlineHours("Japan", "09:30:00", "20:00:00");
 */
export const isDuringOnlineHours = (
  timezone,
  activationStartTime,
  activationEndTime
) => {
  // If any of the params are missing we do not want to go ahead with the calculation on Moments default values
  if (!timezone || !activationStartTime || !activationEndTime) {
    const paramsReceived = JSON.stringify(
      {
        timezone,
        activationStartTime,
        activationEndTime
      },
      (k, v) => (v === undefined ? null : v)
    );
    throw new Error("Missing required params: " + paramsReceived);
  }

  const dateToday = moment().format("YYYY-MM-DD");
  // Converting current time to provided timezone
  const currentTimeInTimezone = moment.tz(timezone);

  // Converting start-time and end-time to provided timezone
  const startTimeForTimezone = moment.tz(
    `${dateToday} ${activationStartTime}`,
    timezone
  );
  const endTimeForTimezone = moment.tz(
    `${dateToday} ${activationEndTime}`,
    timezone
  );

  // It is during online hours if current time is greater than the start-time and less than end-time
  return (
    currentTimeInTimezone.diff(startTimeForTimezone) > 0 &&
    currentTimeInTimezone.diff(endTimeForTimezone) <= 0
  );
};

export const getTimeDifferent = previousTime => {
  if (previousTime) {
    const preTime = new Date(previousTime);
    const currentTime = new Date();

    const diff = Math.abs(currentTime - preTime);

    return Math.floor(diff / 1000 / 60);
  }
  return -1;
};

export const OTP_ERROR_MAP = {
  40071: "error_invalid_email_with_plus",
  4000361: "error_invalid_otp",
  generic: "error_invalid_otp"
};

export const isAUPortinUser = userDetails => {
  // user service sends which account user used to login.
  const socialInfo = get(userDetails, "social_info") || {};
  let isPortingUser = false;
  // define the social login name that should be use to identify an porting account.
  const portinAccounts = portinProviders();

  // iterate through the defined porting accounts in the config and if match found return it
  for (const account in socialInfo) {
    if (
      Object.prototype.hasOwnProperty.call(socialInfo, account) &&
      portinAccounts.includes(account)
    ) {
      isPortingUser = !!socialInfo[account];
      // setting only auth_provider since socialInfo obj is too large to encrypt
      setNewRelicAttribute("authProvider", socialInfo[account]?.auth_provider);
    }
  }

  setNewRelicAttribute("isPortingUser", isPortingUser);
  return isPortingUser;
};

export const maintenanceOnError = (result, error) => {
  if (result?.maintenanceMode || !isEmpty(error)) {
    gotoUrl(result?.maintenancePage || paths.MAINTENANCE);
  }
};
