import React, { useState } from "react";
import { useSelector } from "react-redux";
import map from "lodash/map";
import { Flex, Box, Text } from "ui-components";
import get from "lodash/get";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import PageHeader from "components/PageHeader";
import {
  RadioboxBlank,
  RadioboxMarked,
  ChevronDown,
  ChevronUp,
  ChevronRight
} from "components/Icons";
import { COLORS, buttonThemes } from "ui-components/theme";
import Spinner, { styles } from "components/Spinner";
import { safeEval } from "utils/helpers";
import { PAYMENT_METHODS, PAYMENT_TYPE } from "utils/constants";
import AmazonLoginBtnWidget from "containers/SmartRender/components/amazomPay/amazonLoginButtonWidget";
import ApplePay from "containers/SmartRender/components/applePay";
import { makeSelectOrderParams } from "ducks/selectors";
import GooglePay from "containers/SmartRender/components/googlePay";

const TYPE = {
  BUTTON: "button",
  RADIO: "radio"
};

const PaymentMethodPicker = props => {
  const {
    selectedValue,
    onClick,
    onSelect,
    type = TYPE.RADIO,
    paymentMethods,
    title,
    isFetchingPaymentRequest,
    themeColors
  } = props;

  const { order_summary } = useSelector(makeSelectOrderParams());

  const getPickerComponent = (item, buttonTheme, method) => {
    switch (item.name) {
      case PAYMENT_METHODS.AMAZON_PAY:
        return <AmazonLoginBtnWidget {...method} />;
      case PAYMENT_METHODS.APPLE_PAY:
        return <ApplePay item={item} {...method} />;
      case PAYMENT_METHODS.GOOGLE_PAY:
        return <GooglePay item={item} {...method} />;
      default:
        return (
          <PickerItem
            item={item}
            selectedValue={selectedValue}
            onSelect={onSelect}
            onClick={onClick}
            type={type}
            isFetchingPaymentRequest={isFetchingPaymentRequest}
            buttonTheme={buttonTheme}
          />
        );
    }
  };

  return (
    <Box>
      <PageHeader title={title} themeColors={themeColors} />
      <Flex flexWrap="wrap" mx={-1} mb={3} flexDirection="column">
        {map(
          orderBy(paymentMethods, ["display_sequence"], ["asc"]),
          (method, index) => {
            const buttonTheme = get(themeColors, "button") || buttonThemes.blue;
            const paymentChannels = orderBy(
              get(method, "payment_channels", {}),
              ["display_sequence"],
              ["asc"]
            );
            const code = method.code;
            const selected = selectedValue === code;
            const enableMethod =
              method.condition && method.enable
                ? safeEval(get, props.formValues, method.condition, true, props)
                : method.enable;
            const item = {
              code,
              label: method.label,
              subLable: method.subLable,
              logo: method.logo,
              value: code,
              enable: enableMethod,
              action: method.action,
              path: method.path,
              name: method.name,
              customLogoStyles: method.customLogoStyles,
              onClick: method.onClick
            };

            /* Hide payment method if item is apple pay and it is not support to browser Or
             if Payment method is wallet payment and payable amount is zero*/
            if (
              (method.type === PAYMENT_TYPE.WALLET_PAYMENT &&
                order_summary.onetime_total_fee <= 0) ||
              (item.name === PAYMENT_METHODS.APPLE_PAY &&
                !window.ApplePaySession)
            ) {
              item.enable = false;
              console.log(
                `${method} is not available. Reason can be browser is not supported or payable amount is zero`
              );
            }
            return !item.enable ? null : !isEmpty(paymentChannels) ? (
              <PickerAccordion
                key={code}
                item={item}
                paymentChannels={paymentChannels}
                selectedValue={selectedValue}
                onSelect={onSelect}
                onClick={onClick}
                type={type}
                isFetchingPaymentRequest={isFetchingPaymentRequest}
                buttonTheme={buttonTheme}
                {...props}
              />
            ) : (
              <Box
                variant="borderSection"
                padding={[2, 2]}
                sx={{
                  px: 1,
                  py: 1,
                  borderWidth: "2px",
                  cursor: "pointer",
                  borderColor: selected ? buttonTheme.color : COLORS.gray[1],
                  backgroundColor: selected
                    ? buttonTheme.backgroundColor
                    : COLORS.white
                }}
                mb={2}
                width={[1]}
                key={code}
              >
                {getPickerComponent(item, buttonTheme, method)}
              </Box>
            );
          }
        )}
      </Flex>
    </Box>
  );
};

const PickerAccordion = props => {
  const {
    item,
    paymentChannels,
    selectedValue,
    onSelect,
    onClick,
    type,
    isFetchingPaymentRequest,
    buttonTheme
  } = props;
  const selected = !isEmpty(find(paymentChannels, { code: selectedValue }));
  const [seeMore, setSeeMore] = useState(false);
  const toggleSeeMore = () => setSeeMore(!seeMore);
  const { code, label, logo, action, path } = item;
  return !item.enable ? null : (
    <Box
      variant="borderSection"
      padding={[0, 0]}
      sx={{
        borderWidth: "2px",
        cursor: "pointer",
        borderColor: selected ? buttonTheme.color : COLORS.gray[1],
        backgroundColor:
          selected && !seeMore ? buttonTheme.backgroundColor : COLORS.white
      }}
      mb={2}
      width={[1]}
    >
      <Flex
        data-testid="see-more-toggle"
        px={2}
        py={3}
        justifyContent="space-between"
        alignItems="center"
        onClick={toggleSeeMore}
      >
        <Box width={["36px"]} variant="paymentLogo" mx={2}>
          <img alt={label} src={logo} />
        </Box>
        <Box px={[2]} justifySelf="flex-start" flex={1}>
          <Text>{label}</Text>
        </Box>
        <Box width={["36px"]} mx={1}>
          {isFetchingPaymentRequest && selected ? (
            <Spinner
              sx={{
                ...styles.inlineMessage,
                size: 26,
                color: buttonTheme.color
              }}
            />
          ) : seeMore ? (
            <ChevronUp size="36px" color={buttonTheme.color} />
          ) : (
            <ChevronDown size="36px" color={buttonTheme.color} />
          )}
        </Box>
      </Flex>
      {seeMore &&
        map(paymentChannels, channel => {
          const isEnabled =
            channel.condition && channel.enable
              ? safeEval(get, props.formValues, channel.condition, true, props)
              : channel.enable;
          if (!isEnabled) return null;

          const channel_code = channel.code;
          const selected = selectedValue === channel_code;
          return !channel.enable ? null : (
            <Box
              key={channel_code}
              p={2}
              sx={{
                borderTop: "1px solid",
                borderRadius: "0",
                borderColor: COLORS.gray[1],
                backgroundColor: selected
                  ? buttonTheme.backgroundColor
                  : COLORS.white,
                "&:last-child": {
                  borderRadius: "0 0 6px 6px"
                }
              }}
            >
              <PickerItem
                item={{
                  code: code,
                  channel_code,
                  label: channel.label,
                  logo: channel.logo,
                  value: channel_code,
                  enable: channel.enable,
                  action,
                  path
                }}
                selectedValue={selectedValue}
                onSelect={onSelect}
                onClick={onClick}
                type={type}
                isFetchingPaymentRequest={isFetchingPaymentRequest}
                buttonTheme={buttonTheme}
              />
            </Box>
          );
        })}
    </Box>
  );
};

const PickerItem = ({
  item,
  selectedValue,
  onSelect,
  onClick,
  isFetchingPaymentRequest,
  buttonTheme,
  type
}) => {
  const { label, logo, value, enable, subLable, customLogoStyles } = item;
  const selected = selectedValue === value;
  const color = buttonTheme.color;

  return !enable ? null : (
    <Flex
      py={1}
      justifyContent="space-between"
      alignItems="center"
      onClick={() => (type === "button" ? onClick(item) : onSelect(item))}
    >
      <Box
        width={["36px"]}
        variant="paymentLogo"
        mx={2}
        style={customLogoStyles}
      >
        {logo && <img alt={label} src={logo} />}
      </Box>
      <Box
        px={[2]}
        justifySelf="flex-start"
        flex={1}
        style={{ textAlign: "right" }}
      >
        <Text>{label}</Text>
        <Text>{subLable}</Text>
      </Box>
      <Box width={["36px"]} mx={1}>
        {type === TYPE.BUTTON && <ChevronRight size="30px" color={color} />}
        {type === TYPE.RADIO && (
          <>
            {isFetchingPaymentRequest && selected ? (
              <Spinner
                sx={{ ...styles.inlineMessage, size: 26, color: color }}
              />
            ) : selected ? (
              <RadioboxMarked size="30px" color={color} />
            ) : (
              <RadioboxBlank size="30px" color={color} />
            )}
          </>
        )}
      </Box>
    </Flex>
  );
};

PaymentMethodPicker.defaultProps = {
  onSelect: () => {}
};

export default PaymentMethodPicker;
