import {
  BASE,
  TELCO_EMAIL_SERVICE_AGREEMENT,
  USE_API_GATEWAY,
  TELCO_REQUEST_TERMINATE,
  OMS_SUBMIT_TERMINATION,
  REVERSE_PORTIN,
  CREATE_PORTOUT_REQUEST,
  CREATE_PORTOUT_REQUEST_ONESTOP,
  TELCO_REQUEST_PIN_UPDATE
} from "utils/constants";
import {
  fetchRequested,
  fetchRequestedWithCaptcha
} from "ducks/actions/baseActions";

export const UpdatePIN = (headers, params, callback) => {
  return fetchRequested({
    key: "requestUpdatePIN",
    base: BASE.TELCO_SERVICE,
    headers,
    params,
    path: TELCO_REQUEST_PIN_UPDATE,
    method: "POST",
    callback
  });
};

export const emailAgreementForm = callback => {
  return fetchRequestedWithCaptcha({
    key: "emailAgreementForm",
    base: BASE.TELCO_SERVICE,
    headers: {},
    params: {},
    path: TELCO_EMAIL_SERVICE_AGREEMENT,
    method: "POST",
    callback,
    meta: {
      captchaAction: "email_contract"
    }
  });
};

export const postTerminateNumberRequest = (headers, params, callback) => {
  const path = USE_API_GATEWAY
    ? TELCO_REQUEST_TERMINATE
    : OMS_SUBMIT_TERMINATION;
  const base = USE_API_GATEWAY ? BASE.TELCO_SERVICE : BASE.OMS;
  return fetchRequested({
    key: "postTerminateNumberRequest",
    headers,
    params,
    path,
    base,
    method: "POST",
    callback
  });
};
const requestReversePortin = (headers, params, callback) => {
  return fetchRequested({
    key: "requestReversePortin",
    base: BASE.TELCO_SERVICE,
    path: REVERSE_PORTIN,
    method: "POST",
    headers,
    params,
    callback
  });
};

const requestMNPReservationNumber = (headers, params, callback) => {
  return fetchRequested({
    key: "requestMNPReservationNumber",
    base: BASE.TELCO_SERVICE,
    headers,
    params,
    path: CREATE_PORTOUT_REQUEST,
    method: "POST",
    callback
  });
};

const requestMNPReservationNumberOnestop = (headers, params, callback) => {
  return fetchRequested({
    key: "requestMNPReservationNumber",
    base: BASE.TELCO_SERVICE,
    headers,
    params,
    path: CREATE_PORTOUT_REQUEST_ONESTOP,
    method: "POST",
    callback
  });
};

export const cancelPortOutRequest = (params, callback) => {
  return fetchRequested({
    key: "cancelPortOut",
    base: BASE.TELCO_SERVICE,
    path: CREATE_PORTOUT_REQUEST,
    method: "PATCH",
    callback,
    params
  });
};

export default {
  emailAgreementForm,
  requestReversePortin,
  requestMNPReservationNumber,
  requestMNPReservationNumberOnestop,
  postTerminateNumberRequest,
  cancelPortOutRequest,
  UpdatePIN
};
