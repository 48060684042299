import React, { useState, useEffect } from "react";
import get from "lodash/get";
import map from "lodash/map";
import head from "lodash/head";
import find from "lodash/find";
import filter from "lodash/filter";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import ToggleSwitch from "components/ToggleSwitch";
import PlanDetailsCard from "components/PlanDetailsCard";
import { COMBO_CHANNEL } from "utils/constants";
import { urlParam, formatCurrency } from "utils/helpers";

const PLAN_SELECTION = props => {
  const {
    currencyFormat,
    eligiblePlans,
    planDetails,
    selectPlan,
    iccIdDetails,
    tabType,
    data,
    themeColors
  } = props;
  const [allowedPlans, setAllowedPlans] = useState(eligiblePlans);
  const { badges, icons } = data || {};
  const allowedEligiblePlans = get(data, "allowedPlans");
  const features = get(data, "features", []);
  const urlPlanName = urlParam("plan_name");

  useEffect(
    () => {
      if (!isEmpty(eligiblePlans && !get(iccIdDetails, COMBO_CHANNEL))) {
        // eligiblePlans will contain only the sim plan if it is valid.
        // otherwise all eligible plans for channel will be present
        let allowedPlans = eligiblePlans;
        if (!isEmpty(allowedEligiblePlans)) {
          // GROW-1312: filter eligiblePlans to control plans available for customer selection
          allowedPlans = filter(eligiblePlans, ({ name }) =>
            includes(allowedEligiblePlans, name)
          );
        }
        let plan = head(allowedPlans);
        if (urlPlanName) {
          const urlPlan = find(eligiblePlans, { name: urlPlanName });
          if (!isEmpty(urlPlan)) {
            // show only urlPlan if it is eligible
            plan = urlPlan;
            allowedPlans = [urlPlan];
          }
        }
        if (!isEmpty(planDetails)) {
          const selectedPlan = find(eligiblePlans, {
            name: planDetails.name
          });
          if (!isEmpty(selectedPlan)) {
            // clear plan to avoid plan selection
            // if an allowed plan is already selected
            plan = undefined;
            // show only selectedPlan if it is eligible but allowedPlans is empty
            if (isEmpty(allowedPlans)) {
              allowedPlans = [selectedPlan];
            }
          }
        }
        !isEmpty(plan) && selectPlan(plan);
        // set allowedPlans to control the options shown for user selection
        setAllowedPlans(allowedPlans);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      planDetails,
      eligiblePlans,
      allowedEligiblePlans,
      urlPlanName,
      setAllowedPlans
    ]
  );

  const label = get(data, "toggle_label", "data_gb");
  const items = map(allowedPlans, ({ name, data, basicPrice }) => ({
    name,
    label,
    value: name,
    data,
    basicPrice,
    totalData: data,
    totalAmount: basicPrice,
    totalPrice: formatCurrency(basicPrice, currencyFormat),
    totalFixedPrice: formatCurrency(basicPrice, currencyFormat, false, 0)
  }));
  const valueAttribute = "name";
  const onClick = plan =>
    selectPlan(find(allowedPlans, { [valueAttribute]: plan[valueAttribute] }));

  const showSummary =
    get(data, "showSummary") || get(data, "cardType") === "payMonthlyDetails";

  return (
    <>
      {items.length > 1 && (
        <ToggleSwitch
          items={items}
          value={get(planDetails, valueAttribute)}
          valueAttribute={valueAttribute}
          onClick={onClick}
          type={tabType}
          features={features}
          badges={badges}
          icons={icons}
          themeColors={themeColors}
        />
      )}
      <PlanDetailsCard {...props} data={{ ...data, showSummary }} />
    </>
  );
};

export default PLAN_SELECTION;
