import React, { useState } from "react";
import SmartRender from "containers/SmartRender";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectOrderSummaryPayload,
  makeSelectSessionToken
} from "ducks/selectors";
import { gotoUrl } from "utils/helpers";
import { paths } from "utils/constants";
import Alert from "react-s-alert";
import queryString from "query-string";
import { OMS } from "ducks/actions";

const PaymentAmazonHOC = Component =>
  function AmazonPaymentRedirection(props) {
    const dispatch = useDispatch();
    const [amazonPayOrderRefId, setAmazonPayOrderRefId] = useState(null);
    const configData = get(
      props.appSettings,
      "generic.payment_methods.amazonPay"
    );
    const queryParams = queryString.parse(window.location.search);
    const paymentsPayload = useSelector(makeSelectOrderSummaryPayload());
    const token = useSelector(makeSelectSessionToken());

    const amazonPayCallBack = (_, error) => {
      if (!error) {
        gotoUrl(
          paths.PAYMENT_STATUS.replace(
            ":channel",
            queryParams && queryParams.channel ? queryParams.channel : "web"
          )
        );
      } else {
        Alert.error(
          get(
            props.locale,
            "invalid_card_details",
            "There was an error with the payment. Please try again."
          )
        );
      }
    };

    const continuePayment = () => {
      if (amazonPayOrderRefId !== null) {
        let payloadWithPaymentMethod = {
          ...paymentsPayload,
          token,
          payment_methods: {
            code: configData?.code,
            channel_code: configData?.channel_code,
            additional_data: {
              amazonOrderReferenceID: amazonPayOrderRefId
            }
          }
        };
        dispatch(
          OMS.V4.postPaymentRequest(payloadWithPaymentMethod, amazonPayCallBack)
        );
      }
    };

    const onSelectPaymentCard = amazonPayOrderRefId => {
      setAmazonPayOrderRefId(amazonPayOrderRefId);
    };

    return (
      <Component
        onPayWithAmazon={continuePayment}
        onSelectPaymentCard={onSelectPaymentCard}
        isValidAmazonrderRefId={amazonPayOrderRefId !== null}
        {...props}
      />
    );
  };

export default PaymentAmazonHOC(SmartRender);
