import React from "react";
import queryString from "query-string";

import { GOOGLE_EMBED_MAP_URL, GOOGLE_API_KEY } from "utils/constants";

// Google Map Embed
// docs: https://developers.google.com/maps/documentation/embed/guide

const EmbeddedMap = props => {
  const { mapImage, locationName, style = {} } = props;

  if (!locationName) return "";
  if (!style.height) style.height = 300;

  const params = queryString.stringify({
    key: GOOGLE_API_KEY,
    q: mapImage || locationName
  });
  const url = `${GOOGLE_EMBED_MAP_URL}?${params}`;

  return (
    <div style={style}>
      <iframe
        data-testid="embedded-map-iframe"
        title={locationName}
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: 0 }}
        src={url}
        allowFullScreen
      />
    </div>
  );
};

export default EmbeddedMap;
