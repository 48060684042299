import React from "react";
import { Box, Text, Flex } from "ui-components";
import FormattedMessage from "../FormattedMessage";
import PAGE_NAVIGATION from "smart-components/PAGE_NAVIGATION";

const PostalCodeSearch = props => {
  const { title, actionData = {}, containerStyles = {} } = props.data || {};
  return (
    <Box sx={containerStyles}>
      <Flex sx={{ alignItems: "center" }}>
        <Text
          style={{
            wordBreak: "break-word",
            fontWeight: "800",
            textAlign: "end",
            fontSize: "13px",
            paddingTop: "2px",
            marginRight: "1.8rem"
          }}
        >
          {title && <FormattedMessage id={title} />}
        </Text>
        <PAGE_NAVIGATION {...props} {...actionData} />
      </Flex>
    </Box>
  );
};

export default PostalCodeSearch;
