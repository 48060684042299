import { createSelector } from "reselect";
import get from "lodash/get";
import { currencyFormatSelector } from "ducks/selectors";
import { formatCurrency } from "utils/helpers";

const transactionDetailsDomain = state =>
  get(state, "api.transactionDetails.data", {});
export const transactionDetailsSelector = createSelector(
  transactionDetailsDomain,
  transactionDetails => transactionDetails
);

export const makeSelectTransactionDetails = () => transactionDetailsSelector;

export const makeSelectTransactionAmount = () =>
  createSelector(
    transactionDetailsSelector,
    currencyFormatSelector,
    (transactionDetails, currencyFormat) => {
      const amount = get(transactionDetails, "amount", 0) / 100;
      return formatCurrency(amount, currencyFormat, false);
    }
  );
