import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, H3, Text } from "ui-components";
import { COLORS, iconThemes } from "ui-components/theme";

const TotalPrice = ({
  title,
  totalData,
  totalPrice,
  totalFixedPrice,
  price_label,
  bgColor,
  priceColor,
  priceFontSize,
  summaryLabel
}) => (
  <Box mb={5}>
    <Flex
      py={1}
      justifyContent="space-between"
      alignItems="center"
      backgroundColor={bgColor}
      px={"1rem"}
    >
      <Box paddingRight={1} justifySelf="flex-start" flex={1}>
        <H3>
          <Text
            fontWeight="bolder"
            py="4px"
            fontSize={priceFontSize}
            color={priceColor || COLORS.white}
          >
            <FormattedMessage id={title} />
          </Text>
        </H3>
      </Box>
      <Box width={["fit-content"]}>
        <H3>
          <Text
            fontSize={priceFontSize}
            fontWeight="bolder"
            py="4px"
            color={priceColor || COLORS.white}
          >
            {price_label || summaryLabel ? (
              <FormattedMessage
                id={price_label || summaryLabel}
                values={{ totalData, totalPrice, totalFixedPrice }}
              />
            ) : (
              totalPrice
            )}
          </Text>
        </H3>
      </Box>
    </Flex>
  </Box>
);

TotalPrice.defaultProps = {
  title: "total_price",
  totalData: "",
  totalPrice: "",
  totalFixedPrice: "",
  price_label: "",
  bgColor: iconThemes.magenta
};

export default TotalPrice;
