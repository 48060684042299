import React, { memo } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import SmartRender from "containers/SmartRender";
import { createStructuredSelector } from "reselect";
import { reduxForm } from "redux-form";
import { forms, MOBILE_APP_BROWSER_PROTOCOL, paths } from "utils/constants";
import validate from "validations";
import { makeSelectOrderRef } from "ducks/selectors";
import {
  makeSelectPlanChangeNetworkType,
  makeSelectPlanChangeSimType
} from "ducks/selectors/forms/palnChangeDetails";
import { OMS, UserService } from "ducks/actions";
import { getDeepLink, gotoUrl } from "utils/helpers";
import { isWebview } from "utils/localStorage";
import get from "lodash/get";

const PlanChangeStatusHOC = Component =>
  function Comp(props) {
    return <Component {...props} />;
  };

const mapStateToProps = createStructuredSelector({
  orderRef: makeSelectOrderRef(),
  simType: makeSelectPlanChangeSimType(),
  networkType: makeSelectPlanChangeNetworkType()
});

export function mapDispatchToProps(dispatch) {
  return {
    fetchUserOrders: navigate => {
      dispatch(
        OMS.V4.fetchUserOrders({}, () => {
          navigate && navigate();
        })
      );
    },
    fetchLiquidUrl: (bodyParams, onSuccess) => {
      dispatch(UserService.V4.fetchLiquidUrl(bodyParams, onSuccess));
    }
  };
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  getLiquidUrl: () => {
    const deeplink = getDeepLink();
    const bodyParams = {
      order_id: stateProps.orderRef,
      redirect_url: isWebview()
        ? `${deeplink}${paths.WEBLOGIN}`
        : `${window.location.origin}${paths.USER_DETAILS}`
    };

    const onSuccess = (result, errors, status) => {
      const liquidUrl = get(result, "application_url");
      if (!errors && liquidUrl) {
        const finalUrl = isWebview()
          ? liquidUrl.replace("https", MOBILE_APP_BROWSER_PROTOCOL)
          : liquidUrl;

        gotoUrl(finalUrl, false, {}, true);
        gotoUrl(paths.MANAGE_ORDER);
      } else {
        if (status === 409) {
          gotoUrl(paths.USER_DETAILS);
        }
      }
    };

    dispatchProps.fetchLiquidUrl(bodyParams, onSuccess);
  }
});

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export default compose(
  withConnect,
  reduxForm({
    form: forms.PLAN_CHANGE_DETAILS_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(PlanChangeStatusHOC(SmartRender));
