import React from "react";
import TextField from "components/FormFields/TextField";

const CIS_PARTNERS_FORM = props => {
  const { formFields } = props;
  return (
    <>
      {formFields.map(field => {
        return <TextField {...props} {...field} />;
      })}
    </>
  );
};

export default CIS_PARTNERS_FORM;
