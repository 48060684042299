import React from "react";
import { Box, Button, Flex, Text } from "ui-components";
import FormattedMessage from "components/FormattedMessage";

const PaymentLaterForm = props => {
  const { pay_later_text, submit_order_text, onSubmit } = props;

  return (
    <Flex flexWrap="wrap">
      <Text>
        <FormattedMessage html id={pay_later_text} />
      </Text>

      <Box margin="auto">
        <Button onClick={onSubmit} data-testid="pay-later-submit-button">
          <Text color="white">
            <FormattedMessage id={submit_order_text} />
          </Text>
        </Button>
      </Box>
    </Flex>
  );
};

export default PaymentLaterForm;
