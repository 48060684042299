import { createSelector } from "reselect";
import get from "lodash/get";
import {
  CONTRACT_TYPES,
  ORDER_CHANNEL_MAPPING,
  SIM_TYPES,
  CONTACT_NUM_OTP_MODAL,
  EMAIL_OTP_MODAL,
  ONE_TIME_DISCOUNT_TYPES
} from "utils/constants";
import { dataOnlyUserDetailFormSelector } from "./forms/userRegistrationForm";
import { selectedToppingIdSelector } from "./forms/toppingSelectionForm";
import { planServiceSelector } from "./planService";
import { referralCodeSelector } from "./referralCode";
import { addressParams } from "utils/helpers";
import { dataOnlyUserDetailsSelector } from "./ui/userRegistration";
import sum from "lodash/sum";
import map from "lodash/map";
import filter from "lodash/filter";
import includes from "lodash/includes";

const isModalViewDomain = state => get(state, "api.isModalView.data", {});

export const dataOnlyUserEmailDomain = state =>
  get(state.api, "dataOnlyUserEmailOTP.data", {});

export const dataOnlyUserContactNumDomain = state =>
  get(state.api, "dataOnlyUserContactNoOTP.data", {});

export const isEmailOTPModalViewSelector = createSelector(
  isModalViewDomain,
  isModalView =>
    get(isModalView, "isModalView", false) &&
    get(isModalView, "type") === EMAIL_OTP_MODAL
);

export const isContactNumOTPModalViewSelector = createSelector(
  isModalViewDomain,
  isModalView =>
    get(isModalView, "isModalView", false) &&
    get(isModalView, "type") === CONTACT_NUM_OTP_MODAL
);

export const userAuthIdsSelector = createSelector(
  dataOnlyUserEmailDomain,
  dataOnlyUserContactNumDomain,
  (emailAuthData, contactAuthData) => {
    return {
      email: emailAuthData.auth_id,
      contact_number: contactAuthData.auth_id
    };
  }
);

const dataOnlyCreateOrderPayloadSelector = createSelector(
  planServiceSelector,
  referralCodeSelector,
  dataOnlyUserDetailsSelector,
  dataOnlyUserDetailFormSelector,
  selectedToppingIdSelector,
  (
    planService,
    referralCode,
    userDetails,
    userRegistrationData,
    selectedToppingID
  ) => {
    const one_time_discount_benefits = filter(referralCode.benefits, benefit =>
      includes(ONE_TIME_DISCOUNT_TYPES, benefit.type)
    );

    const one_time_discount_values = map(
      one_time_discount_benefits,
      benefit => get(benefit, "product.price.value", 0) / 100
    );
    const onetime_referral_code_discount_fee = sum(one_time_discount_values);

    return {
      channel_name: ORDER_CHANNEL_MAPPING.data_only,
      dvs_id: "",
      referral_detail: {
        is_valid: referralCode.valid,
        referral_code: referralCode.value ? referralCode.value : null
      },
      selected_planid: planService.sku,
      selected_addons: [{ product_id: selectedToppingID }],
      plan_source: "DEFAULT",
      sim_type: SIM_TYPES.E_SIM,
      number_type: CONTRACT_TYPES.NEW,
      msisdn_source: CONTRACT_TYPES.NEW,
      id_upload_pending: false,
      payment_type: "upfront",
      order_wallet_payments_attributes: [{}],
      phone_only: false,
      order_summary: {
        onetime_referral_code_discount_fee: onetime_referral_code_discount_fee
      },
      user_detail: userDetails,
      billing_detail: addressParams(userRegistrationData, "delivery")
    };
  }
);
export const makeSelectDataOnlyAuthIds = () => userAuthIdsSelector;
export const makeSelectIsContactNumOTPModalView = () =>
  isContactNumOTPModalViewSelector;
export const makeSelectIsEmailOTPModalView = () => isEmailOTPModalViewSelector;
export const makeSelectDataOnlyCreateOrderPayload = () =>
  dataOnlyCreateOrderPayloadSelector;
