import React, { useEffect } from "react";
import get from "lodash/get";
import head from "lodash/head";
import isArray from "lodash/isArray";
import DeliveryMethodPicker from "components/DeliveryMethodPicker";

const DELIVERY_METHOD_PICKER = ({
  change,
  formValues,
  deliveryMethods,
  data,
  ...props
}) => {
  const selectedDeliveryMethod = get(formValues, "delivery_method", {});
  const enabledDeliveryMethods = get(data, "enabled_methods", []);
  let allowedDeliveryMethods = deliveryMethods;

  if (isArray(enabledDeliveryMethods) && enabledDeliveryMethods.length !== 0) {
    const methods = [];
    deliveryMethods.forEach(m => {
      if (enabledDeliveryMethods.includes(m.action)) {
        methods.push(m);
      }
    });

    if (methods.length !== 0) {
      allowedDeliveryMethods = methods;
    }
  }

  useEffect(() => {
    if (allowedDeliveryMethods.length === 1) {
      change("delivery_method", head(allowedDeliveryMethods));
    }
    // eslint-disable-next-line
  }, []);

  return (
    allowedDeliveryMethods.length > 1 && (
      <DeliveryMethodPicker
        value={selectedDeliveryMethod.value}
        deliveryMethods={allowedDeliveryMethods}
        onSelect={deliveryMethod => {
          change("delivery_method", deliveryMethod);
        }}
        {...props}
        data={data}
      />
    )
  );
};

export default DELIVERY_METHOD_PICKER;
