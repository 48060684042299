import React from "react";
import get from "lodash/get";
import SocialMediaIcons from "components/SocialMediaIcons";

const SOCIAL_MEDIA_SIGNIN_OPTIONS = ({ generic, options, data }) => {
  const socialMediaOptions = get(generic, "social_media_signin_options", {});
  const supportSocialMediaOptions = [];
  options &&
    options.forEach(option => {
      if (socialMediaOptions[option.name]) {
        supportSocialMediaOptions.push({
          ...option,
          ...socialMediaOptions[option.name]
        });
      }
    });
  return <SocialMediaIcons options={supportSocialMediaOptions} data={data} />;
};

export default SOCIAL_MEDIA_SIGNIN_OPTIONS;
