import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { urlParam } from "utils/helpers";
import useAuthTokenFromUrl from "hooks/useAuthTokenFromUrl";
import get from "lodash/get";

const PublicRoute = props => {
  const requestPath = get(props, "path", "/");
  const validateTokenAndAllowAccess = useAuthTokenFromUrl(requestPath);
  const authTokenFromURl = urlParam("auth_token");

  /**
   * This will validate the user with the provided token
   * and if token is invalid the user will be marked as not logged in
   */
  useEffect(() => {
    if (authTokenFromURl) {
      validateTokenAndAllowAccess(authTokenFromURl);
    }
  }, [authTokenFromURl, validateTokenAndAllowAccess]);

  return <Route {...props} />;
};

export default PublicRoute;
