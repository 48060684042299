import React from "react";
import { Box, Flex, H3, Text } from "ui-components";
import { COLORS, iconThemes } from "ui-components/theme";
import get from "lodash/get";
import FormattedMessage from "../FormattedMessage";
import isEmpty from "lodash/isEmpty";

const PayDeviceCard = ({ data, themeColors, selectedPhone }) => {
  if (isEmpty(selectedPhone)) return null;
  const { primaryColor: primary, secondaryColor: secondary } = data || {};
  const { paymentType, paymentTerm, label, paymentOption } = selectedPhone;

  const defaultColor = get(themeColors, "primary") || iconThemes.blue;
  const title = () => {
    if (paymentType === "full") {
      return (
        <Text fontWeight="bold" py="4px" data-testid="title_full_payment">
          <FormattedMessage id={get(data, "fullPayment", "full_payment")} />
        </Text>
      );
    }
    return (
      <Text fontWeight="bold" py="4px" data-testid="title_installment_payment">
        {paymentTerm}{" "}
        <FormattedMessage
          id={get(data, "installmentPayment", "installment_payment")}
        />
      </Text>
    );
  };
  const primaryColor = primary ? iconThemes[primary] || primary : defaultColor;
  const secondaryColor = secondary
    ? iconThemes[secondary] || secondary
    : defaultColor;
  const { originalTransactionAmount, original_price } = get(
    paymentOption,
    paymentType,
    {}
  );
  return (
    <Box
      data-testid="pay-device-card"
      variant="borderSection"
      mb={8}
      padding={[0, 0]}
      paddingBottom={"20px"}
      sx={{
        color: primaryColor,
        borderColor: primaryColor,
        borderWidth: "6px"
      }}
    >
      <Box mb={5}>
        <Flex
          py={1}
          justifyContent="space-between"
          alignItems="center"
          backgroundColor={secondaryColor}
          px={"1rem"}
        >
          <Box paddingRight={1} justifySelf="flex-start" flex={1}>
            <H3>
              <Text fontWeight="bolder" py="4px" color={COLORS.white}>
                <FormattedMessage id={get(data, "title", "pay_device")} />
              </Text>
            </H3>
          </Box>
        </Flex>
      </Box>
      <Flex
        pt={1}
        pb={5}
        justifyContent="space-between"
        alignItems="center"
        px={"1rem"}
      >
        <Box paddingRight={4} justifySelf="flex-start" flex={1}>
          {title()}
          <Text py="4px">{label}</Text>
        </Box>
        <Box width={["fit-content"]}>
          <Text
            fontWeight="bold"
            py="4px"
            textAlign="end"
            color={COLORS.blue[4]}
          >
            {originalTransactionAmount}
          </Text>
          {paymentType === "installment" && (
            <Text py="4px" textAlign="end" color={COLORS.blue[4]}>
              {`($${original_price} in ${paymentTerm} mo)`}
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

PayDeviceCard.defaultProps = {
  selectedPhone: {}
};

export default PayDeviceCard;
