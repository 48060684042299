import React, { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import StripeCheckoutForm from "components/StripeCheckout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { paths, STATUS_TYPE } from "utils/constants";
import { getChannelFromUrl, gotoUrl } from "utils/helpers";
import { setItem } from "utils/localStorage";
import Spinner, { styles } from "components/Spinner";

// upfront payment components mainly use OrderSummary container, while update payment component
// use orderSessionToken in upfront payment component we use token from order summary container.
function PaymentUpfrontStripeCheckout({
  isFetchingPaymentDetails,
  paymentDetails,
  fetchUpfrontPaymentDetails,
  token,
  orderSessionToken,
  orderRef
}) {
  const loading = isEmpty(paymentDetails) || isFetchingPaymentDetails;

  useEffect(() => {
    if (isEmpty(paymentDetails) && !isFetchingPaymentDetails && token) {
      fetchUpfrontPaymentDetails({
        token
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPaymentSuccess = () => {
    setItem("checkDvsStatus", true);
    gotoUrl(
      paths.PAYMENT_STATUS.replace(":channel", getChannelFromUrl()),
      true,
      {
        type: STATUS_TYPE.TRANSACTION,
        order_ref: orderRef,
        token: orderSessionToken || token
      },
      true
    );
  };

  if (!loading) {
    const {
      stripe: {
        publishable_api_key,
        intent_confirmation,
        intent: { client_secret, amount }
      }
    } = paymentDetails;
    const stripePromise = loadStripe(publishable_api_key);

    return (
      <Elements stripe={stripePromise}>
        <StripeCheckoutForm
          clientSecret={client_secret}
          intentConfirmation={intent_confirmation}
          amount={amount}
          onPaymentSuccess={onPaymentSuccess}
        />
      </Elements>
    );
  }

  return <Spinner size={200} sx={styles.modalContainer} />;
}

export default PaymentUpfrontStripeCheckout;
