import styled from "@emotion/styled";
import { variant } from "styled-system";
import { Button as RebassButton } from "rebass";

const buttonSize = variant({
  prop: "size",
  key: "buttonSizes"
});

const Button = styled(RebassButton)`
  ${buttonSize}
  outline: 0;
  cursor: pointer;
  :disabled {
    pointer-events: none;
    opacity: 0.4;
  }
`;

Button.defaultProps = {
  my: 1,
  variant: "primary",
  minWidth: 120,
  sx: {
    borderRadius: 24
  }
};

export default Button;
