import { createSelector } from "reselect";
import get from "lodash/get";

export const mockPaymentDomain = state =>
  get(state, "api.mockPayment.data", {});
export const mockPaymentSelector = createSelector(
  mockPaymentDomain,
  mockPayment => mockPayment
);

export const makeSelectMockPayment = () => mockPaymentSelector;
