import styled from "@emotion/styled";
import { Text as RebassText } from "rebass";
import { variant } from "styled-system";

const textSize = variant({
  prop: "size",
  key: "textSizes"
});

const Text = styled(RebassText)`
  ${textSize}
`;

Text.defaultProps = {
  color: "gray.3"
};

export default Text;
