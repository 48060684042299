import React from "react";
import { Box, Text } from "ui-components";
import FormattedMessage from "components/FormattedMessage";

const SCROLLABLE_SECTION = ({ label, message, ...props }) => {
  const scrollToBottomCheck = event => {
    if (!props.consentReviewed) return;

    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const margin = 100;
    const bottomReached = scrollHeight - scrollTop < clientHeight + margin;

    // debug log to check the function in different browser versions
    console.debug("scrollToBottomCheck", {
      scrollHeight,
      scrollTop,
      clientHeight,
      rest: event.target
    });

    if (bottomReached) {
      props.consentReviewed();
    }
  };
  return (
    <Box mb={3}>
      <Box mb={3}>
        <Text fontWeight="700" fontSize="1rem">
          <FormattedMessage id={label} html />
        </Text>
      </Box>
      <Box
        p={1}
        maxHeight="15rem"
        mb={3}
        mt="1rem"
        overflowX="scroll"
        sx={{ border: "1px solid #999999" }}
        onScroll={scrollToBottomCheck}
      >
        <Text fontSize="0.9rem">
          <FormattedMessage id={message} html />
        </Text>
      </Box>
    </Box>
  );
};

export default SCROLLABLE_SECTION;
