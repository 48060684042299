import React, { memo, useCallback, useEffect, useState } from "react";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  makeSelectGuestOrderOrderRef,
  makeSelectIsFetchingData,
  makeSelectCanCancelTermination,
  makeSelectCanRequestTermination,
  makeSelectTerminationDetails,
  makeSelectTerminationMessageData,
  makeSelectTerminationActionType,
  makeSelectDateOptions,
  makeSelectMonthOptions,
  makeSelectYearOptions,
  makeSelectIsWinbackModalView,
  makeSelectLoggedInUserDetails,
  makeSelectTerminationPayLoad,
  makeSelectwinback,
  makeSelectSelectedWinback,
  makeSelectWinbackEligible,
  makeSelectTerminationEligibility,
  makeSelectTermination,
  makeSelectPageValidations,
  makeSelectWorkflow
} from "ducks/selectors";
import { forms, TELCO_API_ERRORS } from "utils/constants";
import {
  setData,
  clearData,
  requestToWinback,
  Telco,
  Rewards
} from "ducks/actions";
import { getDOBSecurityHeader, handleOnLoadAction } from "utils/helpers";
import get from "lodash/get";
import Spinner, { styles } from "components/Spinner";
import RequestTermination from "./RequestTermination";
import CancelTermination from "./CancelTermination";
import TerminationStatus from "./TerminationStatus";
import FormattedMessage from "components/FormattedMessage";

export const TerminateNumberAction = props => {
  const {
    isFetchingOrderDetails,
    isSubmittingTermination,
    isSubmittingWinback,
    isCancellingTermination,
    canCancelTermination,
    canRequestTermination,
    match,
    canRequestTerminationByEligibility,
    terminationPayLoad = {}
  } = props;

  const dispatch = useDispatch();
  const dob = props?.formValues?.dob;
  const [showOTPModal, setShowOTPModal] = useState(false);

  useEffect(() => {
    handleOnLoadAction(props);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const terminationStatus = get(match, "params.status", "");

  /*
   * moved postTerminateNumberRequest function  mapDispatchToProps to
   * inside the component since useState value cannot access in
   * the mapDispatchToProps
   * */
  const postTerminateNumberRequest = useCallback(
    (navigate, data = {}) => {
      const { otp_payload } = data;
      const headers = getDOBSecurityHeader(dob);
      const params = {
        ...terminationPayLoad,
        otp_payload
      };
      dispatch(
        Telco.V1.postTerminateNumberRequest(headers, params, (_, error) => {
          if (!error) {
            setShowOTPModal(false);
            dispatch(clearData(["order", "userOrders"]));
            setTimeout(() => {
              navigate && navigate();
            }, 0);
          } else {
            const { title } = error || {};
            if (title !== TELCO_API_ERRORS.UNAUTHORISED) {
              setShowOTPModal(false);
            }
          }
        })
      );
      dispatch(
        setData({
          key: "isWinbackModalView",
          data: { isWinbackModalView: false }
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dob, terminationPayLoad]
  );

  if (
    isFetchingOrderDetails ||
    isSubmittingTermination ||
    isSubmittingWinback ||
    isCancellingTermination
  )
    return <Spinner size={200} sx={styles.fullPage} />;

  if (terminationStatus) {
    return (
      <TerminationStatus
        {...props}
        postTerminateNumberRequest={postTerminateNumberRequest}
        showOTPModal={showOTPModal}
        setShowOTPModal={setShowOTPModal}
      />
    );
  }

  if (canCancelTermination) {
    return <CancelTermination {...props} />;
  }

  if (canRequestTermination || canRequestTerminationByEligibility) {
    return <RequestTermination {...props} />;
  }

  return <FormattedMessage html id="sorry_you_are_not_eligible" />;
};

const mapStateToProps = createStructuredSelector({
  orderRef: makeSelectGuestOrderOrderRef(),
  isFetchingOrderDetails: makeSelectIsFetchingData("orderDetails"),
  isSubmittingTermination: makeSelectIsFetchingData("submitTermination"),
  isSubmittingWinback: makeSelectIsFetchingData("submitWinback"),
  isCancellingTermination: makeSelectIsFetchingData("cancelTermination"),
  canCancelTermination: makeSelectCanCancelTermination(),
  canRequestTermination: makeSelectCanRequestTermination(),
  orderDetails: makeSelectTerminationDetails(),
  messageData: makeSelectTerminationMessageData(),
  monthOptions: makeSelectMonthOptions(),
  dateOptions: makeSelectDateOptions(),
  yearOptions: makeSelectYearOptions(),
  actionType: makeSelectTerminationActionType(),
  isWinbackModalView: makeSelectIsWinbackModalView(),
  loggedInUserDetail: makeSelectLoggedInUserDetails(),
  terminationPayLoad: makeSelectTerminationPayLoad(),
  winbackOptions: makeSelectwinback(),
  rewards: makeSelectSelectedWinback(),
  winbackEligible: makeSelectWinbackEligible(),
  canRequestTerminationByEligibility: makeSelectTerminationEligibility(),
  formValues: makeSelectTermination(),
  formValidations: makeSelectPageValidations(forms.TERMINATE_NUMBER_FORM),
  workflow: makeSelectWorkflow()
});

export function mapDispatchToProps(dispatch) {
  return {
    requestWinback: navigate => {
      dispatch(
        requestToWinback(response => {
          if (!response.termination_eligibility) {
            dispatch(
              setData({
                key: "isWinbackModalView",
                data: { isWinbackModalView: false }
              })
            );
            navigate && navigate();
          }
        })
      );
      dispatch(
        setData({
          key: "isWinbackModalView",
          data: { isWinbackModalView: true }
        })
      );
    },
    showWinbackModal: () => {
      dispatch(
        setData({
          key: "isWinbackModalView",
          data: { isWinbackModalView: true }
        })
      );
    },
    cancelTermination: (params, navigate) => {
      dispatch(
        Rewards.V4.cancelTerminationNumber(params, (_, error) => {
          dispatch(
            setData({
              key: "isWinbackModalView",
              data: { isWinbackModalView: false }
            })
          );
          if (!error) {
            setTimeout(() => {
              navigate && navigate();
            }, 0);
          }
        })
      );
    },
    hideWinbackModal: () => {
      dispatch(
        setData({
          key: "isWinbackModalView",
          data: { isWinbackModalView: false }
        })
      );
    }
  };
}
export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  cancelTermination: navigate => {
    const { title, id } = stateProps?.rewards || {};
    if (!(title && id)) return;
    const params = {
      reward_title: title,
      reward_id: id,
      cancel_termination: false
    };
    dispatchProps.cancelTermination(params, navigate);
  },
  requestWinback: navigate => {
    dispatchProps.showWinbackModal();
    dispatchProps.requestWinback(navigate);
  },
  ignoreWinback: navigate => {
    dispatchProps.hideWinbackModal();
    navigate && navigate();
  }
});

TerminateNumberAction.defaultProps = {
  fetchOrderDetails: () => {}
};

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(withConnect, memo)(TerminateNumberAction);
