import {
  BASE,
  QUILT_ELIGIBILITY,
  QUILT_PROFILE_AGGREGATION
} from "utils/constants";
import { fetchRequested } from "ducks/actions/baseActions";

export const fetchEligibleServices = (headers, params, callback) => {
  return fetchRequested({
    key: "eligibilitySettings",
    base: BASE.QUILT_SERVICE,
    path: QUILT_ELIGIBILITY,
    method: "GET",
    headers,
    params,
    callback
  });
};
export const fetchUserProfileDetails = () => {
  return fetchRequested({
    key: "userProfileDetails",
    base: BASE.QUILT_SERVICE,
    path: QUILT_PROFILE_AGGREGATION,
    replace: true
  });
};
export default {
  fetchEligibleServices,
  fetchUserProfileDetails
};
