import React, { useState } from "react";
import { Flex, Box, Text } from "ui-components";
import { Plus, Minus } from "components/Icons";
import FormattedMessage from "components/FormattedMessage";

const FAQItem = ({
  question,
  answer,
  id,
  open = false,
  messageData = {},
  themeColors
}) => {
  const [seeMore, setSeeMore] = useState(open);
  const toggleSeeMore = () => setSeeMore(!seeMore);
  const { icon: iconColor, info: infoColor } = themeColors || {};
  return question && answer ? (
    <Flex data-testid="faq-item" id={id} className="faq-item">
      <Flex
        flexDirection="row"
        justifyContent="space-between"
        className={seeMore ? "expanded" : ""}
        padding="1rem 1.5rem"
        onClick={toggleSeeMore}
        sx={{ cursor: "pointer" }}
        backgroundColor={seeMore ? infoColor : ""}
      >
        <Flex
          flexDirection="column"
          justifyItems="center"
          justifyContent="center"
        >
          <Text fontSize="16px" fontWeight="bold">
            <FormattedMessage id={question} values={messageData} />
          </Text>
        </Flex>
        <Flex alignItems="center" minWidth="24px">
          <Flex className="see-more-icon" fontSize="1rem">
            {seeMore ? <Minus color={iconColor} /> : <Plus color={iconColor} />}
          </Flex>
        </Flex>
      </Flex>
      {seeMore && (
        <Flex flexDirection="column" justifyContent="center">
          <Box padding="1rem 1.5rem">
            <Text lineHeight={1.5}>
              <FormattedMessage html id={answer} values={messageData} />
            </Text>
          </Box>
        </Flex>
      )}
    </Flex>
  ) : null;
};

export default FAQItem;
