import React from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import NumberSelection from "components/NumberSelection";

const SILVER_NUMBER_SELECTION = ({
  silverNumbers,
  silverNumbersPrice,
  selectNumber,
  specialNumberAllowed,
  formValues,
  data,
  themeColors
}) =>
  !get(formValues, "hideNumberSelection") &&
  !isEmpty(silverNumbers) &&
  specialNumberAllowed ? (
    <NumberSelection
      items={silverNumbers}
      price={silverNumbersPrice}
      onSelect={selectNumber}
      themeColors={themeColors}
      {...data}
    />
  ) : null;

export default SILVER_NUMBER_SELECTION;
