import get from "lodash/get";
import { createSelector } from "reselect";
import { PAYMENT_GATEWAYS, PAYMENT_METHODS } from "utils/constants";

const userDetailsDomain = state => get(state, "api.loggedInUserDetails.data");
const userDetailsApiDomain = state => get(state, "api.loggedInUserDetails");
const userProfileDomain = state => get(state, "api.userProfileDetails", {});

export const currentPhoneNumberSelector = createSelector(
  userDetailsDomain,
  userDetails => get(userDetails, "telco_info.phone_no")
);

export const loggedInUserDetailsSelector = createSelector(
  userDetailsApiDomain,
  userProfileApi => get(userProfileApi, "data", {})
);

export const loggedInUserPlanNameSelector = createSelector(
  loggedInUserDetailsSelector,
  userDetails =>
    get(
      userDetails,
      "telco_info.circles_info.service_instance_base_plan_name",
      ""
    )
);

/**
 * Returns the user profile details
 */
export const userProfileDetailsSelector = createSelector(
  userProfileDomain,
  userProfileDetails => userProfileDetails.data || {}
);

const registeredPaidySelector = createSelector(
  userProfileDomain,
  userProfileData => {
    const paymentMethods = get(
      userProfileData,
      "data.customer_instruments",
      []
    );
    return paymentMethods.find(
      method =>
        method.gateway === PAYMENT_METHODS.PAIDY && !method.is_fraudulent
    );
  }
);

const registerdCreditCardSelector = createSelector(
  userProfileDomain,
  userProfileData => {
    const paymentMethods = get(
      userProfileData,
      "data.customer_instruments",
      []
    );
    return paymentMethods.find(
      method => method.gateway === PAYMENT_GATEWAYS.GMO && !method.is_fraudulent
    );
  }
);

/**
 * Current payment is irrespective of fradulent state whats the currently selected card type
 * Eg: User has selected CC and the CC is deleted from Kirk. At this case CC still remains as
 * current payment method
 */
const currentPaymentMethodSelector = createSelector(
  userProfileDomain,
  (userProfileData = {}) => {
    const currentPaymentMethod = get(userProfileData, "data.gateway", []);
    const paymentMethods = get(
      userProfileData,
      "data.customer_instruments",
      []
    );
    return paymentMethods.find(
      method => method.gateway === currentPaymentMethod
    );
  }
);

/**
 * Active payment method is the one that is
 * - the current payment method AND it is not fradulent
 */
const currentActivePaymentMethodSelector = createSelector(
  userProfileDomain,
  (userProfileData = {}) => {
    const currentPaymentMethod = get(userProfileData, "data.gateway", []);
    const paymentMethods = get(
      userProfileData,
      "data.customer_instruments",
      []
    );
    return paymentMethods.find(
      method => method.gateway === currentPaymentMethod && !method.is_fraudulent
    );
  }
);

const allRegisteredPaymentMethodsSelector = createSelector(
  userProfileDomain,
  (userProfileData = {}) => {
    const customerInstruments = get(
      userProfileData,
      "data.customer_instruments",
      []
    );
    return customerInstruments.filter(payment => !payment.is_fraudulent);
  }
);

const paymentDetailsInitialValues = createSelector(
  userProfileDomain,
  (userProfileData = {}) => {
    const currentPaymentMethod = get(userProfileData, "data.gateway");
    return {
      payment_method: currentPaymentMethod
    };
  }
);

const formattedUserCurrentAddressSelector = createSelector(
  userProfileDetailsSelector,
  (userProfileData = {}) => {
    const { post_code, state, city, street, building_no } =
      userProfileData.billing_address || {};

    return `〒${post_code}<br/>${state}${city}${street}<br/>${building_no}`;
  }
);

export const makeSelectCurrentPhoneNumber = () => currentPhoneNumberSelector;
export const makeSelectLoggedInUserDetails = () => loggedInUserDetailsSelector;
export const makeSelectUserProfileDetails = () => userProfileDetailsSelector;
export const makeSelectFormattedUserCurrentAddress = () =>
  formattedUserCurrentAddressSelector;
export const makeSelectRegisteredPaidy = () => registeredPaidySelector;
export const makeSelectRegisteredCreditCard = () => registerdCreditCardSelector;
export const makeSelectAllRegisteredPaymentMethods = () =>
  allRegisteredPaymentMethodsSelector;
export const makeSelectCurrentActivePaymentMethod = () =>
  currentActivePaymentMethodSelector;
export const makeSelectCurrentPaymentMethod = () =>
  currentPaymentMethodSelector;
export const makeSelectFormInitialValues = () => paymentDetailsInitialValues;
export const makeSelectLoggedinUserPlanName = () =>
  loggedInUserPlanNameSelector;
