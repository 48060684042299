const styles = {
  bmMenu: {
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)"
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.3)"
  }
};

export default styles;
