import React from "react";

import { Flex, Box, P, Button } from "ui-components";
import { buttonThemes } from "ui-components/theme";
import { paths } from "utils/constants";
import { gotoUrl } from "utils/helpers";

import FormattedMessage from "components/FormattedMessage";

const _imageStyle = {
  width: 250,
  mt: 80
};

const _messageStyle = {
  mt: 57,
  mx: 20,
  textAlign: "center !important",
  fontWeight: "bold"
};

const backgroundColor = `${buttonThemes?.povodarkgray?.color} !important`;
const hoverColor = `${buttonThemes?.povodarkgray?.hoverColor} !important`;
const _buttonStyle = {
  background: backgroundColor,
  color: "#FFF",
  borderColor: backgroundColor,
  minWidth: "10rem !important",
  py: 12,
  borderRadius: 23,
  ":hover": {
    background: hoverColor,
    borderColor: hoverColor
  }
};

const PageError = ({ errorPagesConfig }) => {
  const config = errorPagesConfig?.["500"] || {};
  const errorImage = config.image || "/assets/images/500-povo.png";
  const imageStyle = { ...(config.imageStyle || {}), ..._imageStyle };
  const imageAlt = config.imageAlt || "";
  const message = config.message || "error_message_500";
  const messageStyle = { ...(config.messageStyle || {}), ..._messageStyle };
  const buttonText = config.buttonText;
  const buttonStyle = { ...(config.buttonStyle || {}), ..._buttonStyle };
  const redirectUrl = config.configUrl || paths.MANAGE_ORDER;

  const handleRedirectOnClick = () => {
    gotoUrl(redirectUrl, true, null, true);
  };

  return (
    <Flex alignItems="center" flexDirection="column">
      <Box sx={imageStyle}>
        <img alt={imageAlt} src={errorImage} width="100%" height="auto" />
      </Box>
      <P sx={messageStyle}>
        <FormattedMessage html id={message} />
      </P>
      <Box sx={{ mt: 90 }}>
        <Button
          data-testid="500_error_go_back"
          sx={buttonStyle}
          onClick={handleRedirectOnClick}
        >
          {buttonText || <FormattedMessage id="go_back" />}
        </Button>
      </Box>
    </Flex>
  );
};

export default PageError;
