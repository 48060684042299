import { createSelector } from "reselect";
import get from "lodash/get";

const esimNumberChangeDomain = state =>
  get(state, "api.dataOnlyEsimUpdateContactNo.data", {});

export const dataOnlyEsimNumberChange = createSelector(
  esimNumberChangeDomain,
  contactAuthData => {
    return {
      contactAuthData: contactAuthData.auth_id
    };
  },
  {}
);

export const makeSelectEsimNumberChangeAuthID = () => dataOnlyEsimNumberChange;
