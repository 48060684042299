import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { paths, publicPaths } from "utils/constants";
import {
  getChannelFromUrl,
  gotoUrl,
  urlParam,
  pathWithChannel,
  loggedInUserDetailsAPIParams
} from "utils/helpers";
import { makeSelectAuthToken } from "ducks/selectors";
import { updateAuthToken, UserService } from "ducks/actions";
import useFetchOrdersAndRedirect from "./useFetchOrdersAndRedirect";
import { getRedirectURL, isLinkAccount, isWebview } from "utils/localStorage";

const useAuthTokenFromUrl = requestPath => {
  const dispatch = useDispatch();
  const fetchOrdersAndRedirect = useFetchOrdersAndRedirect();
  const currentAuthToken = useSelector(makeSelectAuthToken());
  const redirectChannel = getChannelFromUrl();
  const [isInvalidToken, setIsInvalidToken] = useState(false);

  useEffect(() => {
    if (isInvalidToken) {
      redirectOnFail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInvalidToken]);

  /**
   * Returns only allowed login channels. If any url path channel
   * is not registered as valid login channel it will return the default channel
   */
  const getChannel = () => {
    const defaultChannel = "web";
    const allowedChannels = ["web", "au_to_povo"];
    const currentChannel = getChannelFromUrl();
    /**
     * some channels have its own login routes.
     * If a channel other than allowed chanel requests a login it will be
     * redirected to default channel login workflow
     */
    const channel =
      allowedChannels.indexOf(currentChannel) >= 0
        ? currentChannel
        : defaultChannel;

    return channel;
  };

  const redirectOnFail = () => {
    if (isLinkAccount() && isWebview()) {
      if (getRedirectURL()) return gotoUrl(getRedirectURL());
      return gotoUrl(paths.CLOSEWEBVIEW);
    }
    const channel = getChannel();

    const redirect =
      urlParam("redirect") || pathWithChannel(requestPath, redirectChannel);
    const loginPath = pathWithChannel(publicPaths.LOGIN_PAGE, channel);

    return gotoUrl(loginPath, true, {}, false, {
      redirect,
      auth_token: null
    });
  };

  /**
   * need to fetch user data to determine if the token is valid.
   * If this is successful user will be redirected to the requested page,
   * else redirects to login page
   * @param {string} authToken
   */
  const fetchUserData = authToken => {
    const header = { "X-AUTH": authToken };
    const params = loggedInUserDetailsAPIParams();
    dispatch(
      UserService.V4.fetchLoggedInUserDetails(header, params, (_, errors) => {
        if (errors) {
          console.log(
            "Failed to fetch user data from the token provided in the url"
          );
          // need to remove existing token since the failure means the token is invalid
          dispatch(updateAuthToken(null, null));
          setIsInvalidToken(true);
        } else {
          // Need to check. This is coz of Saga cancellation
          if ((!_ && !errors) || isLinkAccount()) return;
          fetchOrdersAndRedirect(
            getChannel(),
            pathWithChannel(requestPath, redirectChannel)
          );
        }
      })
    );
  };

  const validateTokenAndAllowAccess = authTokenFromURl => {
    if (!authTokenFromURl) return;

    const receivedNewToken = currentAuthToken !== authTokenFromURl;

    // do only if you notice a change in token
    if (receivedNewToken) {
      dispatch(updateAuthToken(authTokenFromURl));
      fetchUserData(authTokenFromURl);
    } else {
      /**
       * We redirect to requested path if the token received is same as what we have in store right now.
       * Since the token we have at store is already validated at this point, we can allow the user to proceed
       */
      gotoUrl(pathWithChannel(requestPath, redirectChannel));
    }
  };

  return validateTokenAndAllowAccess;
};

export default useAuthTokenFromUrl;
