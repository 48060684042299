import React from "react";
import isEmpty from "lodash/isEmpty";
import { Box, Link } from "ui-components";
import { COLORS } from "ui-components/theme";
import FormattedMessage from "components/FormattedMessage";
import CheckboxField from "components/FormFields/CheckboxField";

const TERMS_AND_CONDITIONS = ({ data, checkboxStyles, ...props }) => {
  const { linkUrl, linkText, styles } = data || {};
  const style = styles || { color: COLORS.blue[4] };
  return (
    <Box px={0} mb={3}>
      {!isEmpty(data) && (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={linkUrl}
          sx={style}
        >
          <FormattedMessage id={linkText} />
        </Link>
      )}
      <CheckboxField sx={checkboxStyles} {...props} />
    </Box>
  );
};

export default TERMS_AND_CONDITIONS;
