import { createSelector } from "reselect";
import get from "lodash/get";
import { orderRefSelector, sessionTokenSelector } from "../../selectors";

const switchToEsimDomain = state => get(state, "form.SwitchToEsimForm.values");

// ***************************************************************
// ************** reportLostSimForm selectors ******************
// ***************************************************************

export const switchToEsimSelector = createSelector(
  switchToEsimDomain,
  switchToEsim => switchToEsim
);

// ***************************************************************
// *************** reportLostSimForm makeSelect *******************
// ***************************************************************

export const makeSelectSwitchToEsimForm = () => switchToEsimSelector;

export const makeSelectSwitchToEsimPayload = () =>
  createSelector(
    switchToEsimSelector,
    orderRefSelector,
    sessionTokenSelector,
    (switchToEsim, order_ref, session_token) => ({
      order_ref,
      session_token,
      nric: get(switchToEsim, "nric"),
      sim_type: null,
      switch_to_esim: true
    })
  );
