const styles = {
  wrapperStyles: {
    alignItems: "top",
    margin: "16px auto",
    backgroundColor: "white",
    borderRadius: 10,
    flexDirection: "column",
    padding: "1.5rem",
    justifySelf: "center",
    boxShadow: "0 2px 8px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06)",
    width: "100%",
    maxWidth: "568px"
  },
  linkStyles: {
    cursor: "pointer",
    color: "#0000FF",
    fontWeight: "bold",
    fontSize: "12px"
  }
};

export default styles;
