import Rollbar from "rollbar";
import { ENV, ROLLBAR_ACCESS_TOKEN } from "utils/constants";

export default new Rollbar({
  accessToken: ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: ENV
  }
});
