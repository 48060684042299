import { createSelector } from "reselect";
import get from "lodash/get";
import map from "lodash/map";
import head from "lodash/head";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import groupBy from "lodash/groupBy";
import includes from "lodash/includes";
import toLower from "lodash/toLower";
import trim from "lodash/trim";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import compact from "lodash/compact";
import { formatCurrency, compactJoin, formatMessage } from "utils/helpers";
import { currencyFormatSelector } from "./appSettings";
import { walletDevicePointsSelector } from "./wallet";
import { phonesFormSelector } from "./forms/phones";
import { PHONE_PAYMENT_OPTIONS } from "utils/constants";
import { localeSelector } from "ducks/selectors";

export const phonesDomain = state => get(state.api, "phones.data", {});
export const phonesSelector = createSelector(
  phonesDomain,
  currencyFormatSelector,
  walletDevicePointsSelector,
  phonesFormSelector,
  localeSelector,
  (phones, currencyFormat, walletDevicePoints, phoneForm, locale) => {
    if (isEmpty(phones)) return [];
    const availablePhones = [];
    const comingSoonPhones = [];
    const outOfStockPhones = [];
    for (const ph of phones) {
      if (ph && ph.enabled) {
        const phone = formatPhone(
          ph,
          currencyFormat,
          walletDevicePoints,
          phoneForm,
          locale
        );
        if (phone.outOfStock) {
          outOfStockPhones.push(phone);
        } else if (phone.comingSoon) {
          comingSoonPhones.push(phone);
        } else {
          availablePhones.push(phone);
        }
      }
    }
    const sortedAvailablePhones = orderBy(
      availablePhones,
      ["priority"],
      ["asc"]
    );
    const sortedComingSoonPhones = orderBy(
      comingSoonPhones,
      ["priority"],
      ["asc"]
    );
    const sortedOutOfStockPhones = orderBy(
      outOfStockPhones,
      ["priority"],
      ["asc"]
    );
    return [
      ...sortedAvailablePhones,
      ...sortedComingSoonPhones,
      ...sortedOutOfStockPhones
    ];
  }
);

export const filteredPhonesSelector = createSelector(
  phonesSelector,
  phonesFormSelector,
  (phones, phonesForm) => {
    if (isEmpty(phones)) return [];
    let filteredPhones = phones;
    const filterCriteria = groupBy(
      get(phonesForm, "filter_criteria"),
      "category"
    );
    const filterPriceRange = get(phonesForm, "filter_price_range");
    const excludeOutOfStock = get(phonesForm, "exclude_out_of_stock");
    if (excludeOutOfStock) {
      filteredPhones = filter(filteredPhones, phone => !phone.outOfStock);
    }

    if (!isEmpty(filterPriceRange)) {
      filteredPhones = filter(filteredPhones, phone =>
        phoneHasVariantInPriceRange(phone, filterPriceRange)
      );
    }
    for (const criteria in filterCriteria) {
      filteredPhones = filter(filteredPhones, phone =>
        phoneHasMatchingVariant(phone, filterCriteria[criteria])
      );
    }
    return filteredPhones;
  }
);

const phoneHasVariantInPriceRange = (phone, filterPriceRange) => {
  let match = false;
  const minPrice = Number(get(filterPriceRange, 0, 0));
  const maxPrice = Number(get(filterPriceRange, 1, 9999));
  for (const variant of phone.allPhoneVariants) {
    const variantCost = Number(variant.cost || 0);
    match = minPrice <= variantCost && variantCost <= maxPrice;
    if (match) break;
  }
  return match;
};

const phoneHasMatchingVariant = (phone, filterCriteria) => {
  let match = false;
  for (const option of filterCriteria) {
    const { test, attribute, value, ignoreCase = true } = option;
    const testMethod = testMethods[test] || testMethods.equals;
    for (const variant of phone.allPhoneVariants) {
      const attributeValue = trim(variant[attribute]);
      const expectedValue = trim(value);
      match =
        attributeValue &&
        expectedValue &&
        testMethod(attributeValue, expectedValue, ignoreCase);
      if (match) break;
    }
    if (match) break;
  }
  return match;
};

const testMethods = {
  includes: (attributeValue, expectedValue, ignoreCase) =>
    ignoreCase
      ? includes(toLower(attributeValue), toLower(expectedValue))
      : includes(attributeValue, expectedValue),
  equals: (attributeValue, expectedValue, ignoreCase) =>
    ignoreCase
      ? toLower(attributeValue) === toLower(expectedValue)
      : attributeValue === expectedValue
};

const formatPhone = (
  phone,
  currencyFormat,
  walletDevicePoints,
  phonesForm = null,
  locale
) => {
  const {
    id,
    name,
    phone_variants,
    all_phone_variants,
    phone_image: phoneImage,
    out_of_stock: outOfStock,
    coming_soon: comingSoon,
    current_variant_stock: currentVariantStock
  } = phone;
  const priority = get(phone, "priority") || 9999;
  const stockStatus = currentVariantStock;
  const lowestPriceVariant = head(orderBy(phone_variants, ["cost"], ["asc"]));
  const installmentMonths =
    get(phonesForm, "paymentTerm") ||
    get(lowestPriceVariant, "max_installment_month") ||
    24;
  const phoneCost = calculatePhoneCost(
    lowestPriceVariant,
    currencyFormat,
    walletDevicePoints,
    installmentMonths,
    locale
  );
  const allPhoneVariants = map(
    filter(all_phone_variants, ({ quantity }) => quantity && quantity > 0),
    phone =>
      formatPhoneVariant(
        phone,
        currencyFormat,
        name,
        id,
        walletDevicePoints,
        get(phonesForm, "paymentTerm") || get(phone, "max_installment_month"),
        locale
      )
  );

  const variantColors = uniqBy(
    compact(
      map(allPhoneVariants, ({ colorName: name, colorCode: code }) => {
        if (name && code) {
          return { name, code };
        }
      })
    ),
    "name"
  );

  const variantColorNames = uniq(map(allPhoneVariants, "colorName"));
  const variantColorCodes = uniq(map(allPhoneVariants, "colorCode"));
  const variantCapacities = uniq(map(allPhoneVariants, "formattedCapacity"));

  return {
    id,
    name,
    priority,
    allPhoneVariants,
    variantColors,
    variantColorNames,
    variantColorCodes,
    variantCapacities,
    phoneImage,
    outOfStock,
    comingSoon,
    stockStatus,
    currentVariantStock,
    ...phoneCost
  };
};

const formatPhoneVariant = (
  phone,
  currencyFormat,
  phoneName,
  phoneId,
  walletDevicePoints,
  installmentMonths,
  locale
) => {
  const {
    id,
    sku,
    capacity,
    color_code: colorCode,
    color_name: colorName,
    min_installment_month: minInstallmentMonth,
    max_installment_month: maxInstallmentMonth,
    installment_step: installmentStep
  } = phone;
  const phoneCost = calculatePhoneCost(
    phone,
    currencyFormat,
    walletDevicePoints,
    installmentMonths,
    locale
  );
  const formattedCapacity = `${capacity} GB`;

  const specs = compactJoin([formattedCapacity, colorName], " - ");
  const name = get(phone, "name") || phoneName;
  const nameWithSpecs = compactJoin(
    [name, formattedCapacity, colorName],
    " - "
  );

  return {
    id,
    phoneId,
    sku,
    name,
    specs,
    nameWithSpecs,
    minInstallmentMonth,
    maxInstallmentMonth,
    installmentStep,
    capacity: Number(capacity),
    formattedCapacity,
    colorCode,
    colorName,
    ...phoneCost
  };
};

const calculatePhoneCost = (
  phone,
  currencyFormat,
  walletDevicePoints = 0,
  totalMonth,
  locale
) => {
  const max_installment_month = get(phone, "max_installment_month");
  const min_installment_month = get(phone, "min_installment_month");
  const installment_step = get(phone, "installment_step");
  const installmentMonths = totalMonth || 24;
  const minInstallmentCost = 200;
  const originalCost = Number(get(phone, "cost")) || 0;
  const discount =
    walletDevicePoints > originalCost ? originalCost : walletDevicePoints;
  const monthlyDiscount = Number((discount / installmentMonths).toFixed(2));
  const cost = originalCost - discount;
  const formattedOriginalCost = formatCurrency(
    originalCost,
    currencyFormat,
    false,
    0
  );
  const formattedCost = formatCurrency(cost, currencyFormat, false, 0);
  const originalInstallmentCost =
    cost >= minInstallmentCost
      ? Number((originalCost / installmentMonths).toFixed(2))
      : 0;
  const formattedOriginalInstallmentCost = formatCurrency(
    originalInstallmentCost,
    currencyFormat,
    false,
    2
  );
  const installmentCost =
    cost >= minInstallmentCost
      ? Number((cost / installmentMonths).toFixed(2))
      : 0;
  const formattedInstallmentCost = formatCurrency(
    installmentCost,
    currencyFormat,
    false,
    2
  );
  const comboUpfrontAmount =
    Number(get(phone, "credit_card_upfront_cost")) || 0;
  const comboUpfrontCost =
    cost < comboUpfrontAmount ? cost : comboUpfrontAmount;
  const formattedComboUpfrontCost = formatCurrency(
    comboUpfrontCost,
    currencyFormat,
    false,
    0
  );
  const originalComboInstallmentAmount = Number(
    (originalCost - comboUpfrontCost).toFixed(2)
  );
  const comboInstallmentAmount = Number((cost - comboUpfrontCost).toFixed(2));
  const originalComboInstallmentCost =
    comboInstallmentAmount >= minInstallmentCost
      ? Number((originalComboInstallmentAmount / installmentMonths).toFixed(2))
      : 0;
  const comboInstallmentCost =
    comboInstallmentAmount >= minInstallmentCost
      ? Number((comboInstallmentAmount / installmentMonths).toFixed(2))
      : 0;
  const formattedOriginalComboInstallmentCost = formatCurrency(
    originalComboInstallmentCost,
    currencyFormat,
    false,
    2
  );
  const formattedComboInstallmentCost = formatCurrency(
    comboInstallmentCost,
    currencyFormat,
    false,
    2
  );
  const fullPaymentOption = {
    originalTransactionAmount: formattedOriginalCost,
    transactionAmount: formattedCost,
    eligible: true,
    price: cost,
    discount,
    monthly_discount: 0,
    original_price: originalCost,
    original_pay_now: originalCost,
    original_pay_monthly: 0,
    pay_now: cost,
    pay_monthly: 0,
    pay_monthly_months: 0,
    pay_monthly_total: 0
  };

  const pricePerMonthLabel = get(locale, "price_per_month", "{price}/mth");
  const installmentPaymentEligible = installmentCost > 0;
  const installmentPaymentOption = {
    originalTransactionAmount: formatMessage(pricePerMonthLabel, {
      price: formattedOriginalInstallmentCost
    }),
    transactionAmount: formatMessage(pricePerMonthLabel, {
      price: formattedInstallmentCost
    }),
    eligible: installmentPaymentEligible,
    price: cost,
    discount,
    monthly_discount: monthlyDiscount,
    original_price: originalCost,
    original_pay_now: 0,
    original_pay_monthly: originalInstallmentCost,
    pay_now: 0,
    pay_monthly: installmentCost,
    pay_monthly_months: installmentMonths,
    pay_monthly_total: cost
  };

  const splitPaymentEligible = comboUpfrontCost > 0 && comboInstallmentCost > 0;
  const splitPaymentOption = {
    originalTransactionAmount: `${formattedComboUpfrontCost} + ${formatMessage(
      pricePerMonthLabel,
      { price: formattedOriginalComboInstallmentCost }
    )}`,
    transactionAmount: `${formattedComboUpfrontCost} + ${formatMessage(
      pricePerMonthLabel,
      { price: formattedComboInstallmentCost }
    )}`,
    eligible: splitPaymentEligible,
    price: cost,
    discount,
    monthly_discount: monthlyDiscount,
    original_price: originalCost,
    original_pay_now: comboUpfrontCost,
    original_pay_monthly: originalComboInstallmentCost,
    pay_now: comboUpfrontCost,
    pay_monthly: comboInstallmentCost,
    pay_monthly_months: installmentMonths,
    pay_monthly_total: splitPaymentEligible ? comboInstallmentAmount : 0
  };

  const paymentOption = {
    [PHONE_PAYMENT_OPTIONS.FULL]: fullPaymentOption,
    [PHONE_PAYMENT_OPTIONS.IPP]: installmentPaymentOption,
    [PHONE_PAYMENT_OPTIONS.SPLIT]: splitPaymentOption
  };

  return {
    cost,
    formattedCost,
    installmentMonths,
    minInstallmentCost,
    installmentCost,
    formattedInstallmentCost,
    comboUpfrontCost,
    formattedComboUpfrontCost,
    comboInstallmentCost,
    formattedComboInstallmentCost,
    paymentOption,
    discount,
    originalCost,
    formattedOriginalCost,
    originalInstallmentCost,
    formattedOriginalInstallmentCost,
    originalComboInstallmentCost,
    formattedOriginalComboInstallmentCost,
    max_installment_month: max_installment_month,
    min_installment_month: min_installment_month,
    installment_step: installment_step
  };
};

export const makeSelectPhones = () => phonesSelector;
export const makeSelectFilteredPhones = () => filteredPhonesSelector;
