import React, { memo } from "react";
import { Box, StyledLink } from "ui-components";
import { compose } from "redux";
import SmartRender from "containers/SmartRender/index";
import { reduxForm } from "redux-form";
import { forms, paths } from "utils/constants";
import validate from "validations";
import { createStructuredSelector } from "reselect";
import { connect, useDispatch } from "react-redux";
import {
  makeSelectPageValidations,
  makeSelectCountryCodeSelector,
  makeSelectContactNumber,
  makeSelectNewContactNumber,
  makeSelectEsimNumberChangeAuthID,
  makeSelectEditEmailTimerKey,
  makeSelectIsResendActivationEnabled
} from "ducks/selectors";
import { setData, UserService, disableResendActivation } from "ducks/actions";
import { gotoUrl } from "utils/helpers";
import get from "lodash/get";
import FormattedMessage from "components/FormattedMessage";

const ContactNumberChange = Component =>
  function Comp(props) {
    const { countryCode, newNumber, authID } = props;
    const { new_esim_number } = newNumber;
    const dispatch = useDispatch();

    const privateComponents = {
      RESEND_ACTIVATION_CODE_LINK: props => (
        <ResendActivationCode
          {...props}
          requestContactNumOTP={requestContactNumOTP}
        />
      )
    };

    const updateNewContactNumber = () => {
      dispatch(
        setData({
          key: "editEmailTimerKey",
          data: { editEmailTimer: Date.now() }
        })
      );
      const bodyParams = {
        new_contact_number: new_esim_number,
        isd_code: countryCode?.toString()
      };
      const onSuccess = (_, errors) => {
        if (!errors) {
          gotoUrl(paths.ESIM_NUMBER_CHANGE_OTP);
        }
      };
      dispatch(
        UserService.V4.updateNewEsimContactNumber(bodyParams, onSuccess)
      );
    };

    const requestContactNumOTP = () => {
      dispatch(
        setData({
          key: "editEmailTimerKey",
          data: { editEmailTimer: Date.now() }
        })
      );
      dispatch(disableResendActivation());
      const bodyParams = {
        new_contact_number: new_esim_number,
        isd_code: countryCode?.toString()
      };

      dispatch(UserService.V4.updateNewEsimContactNumber(bodyParams));
    };

    const validateNewContactNumber = (_, { current_otp }) => {
      const bodyParams = {
        auth_id: authID?.contactAuthData,
        otp: current_otp
      };

      const onSuccess = (_, errors) => {
        if (!errors) {
          gotoUrl(paths.MANAGE_ORDER);
        }
      };
      dispatch(
        UserService.V4.validateNewEsimContactNumber(bodyParams, onSuccess)
      );
    };

    return (
      <Component
        {...props}
        privateComponents={privateComponents}
        validateNewContactNumber={validateNewContactNumber}
        updateNewContactNumber={updateNewContactNumber}
        requestContactNumOTP={requestContactNumOTP}
      />
    );
  };

const mapStateToProps = createStructuredSelector({
  formValidations: makeSelectPageValidations(forms.CONTACT_NUMBER_CHANGE),
  formValues: makeSelectNewContactNumber(),
  countryCode: makeSelectCountryCodeSelector(),
  newNumber: makeSelectContactNumber(),
  authID: makeSelectEsimNumberChangeAuthID(),
  timerKey: makeSelectEditEmailTimerKey(),
  isDisable: makeSelectIsResendActivationEnabled()
});

const mapDispatchToProps = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: forms.CONTACT_NUMBER_CHANGE,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(ContactNumberChange(SmartRender));

export const ResendActivationCode = props => {
  const { label, themeColors, requestContactNumOTP, isDisable } = props;

  const linkColor = get(themeColors, "cta");

  const resendActivationCode = () => {
    if (!isDisable) return;
    requestContactNumOTP();
  };

  return (
    <Box mt={[24, 36]} sx={{ textAlign: "center" }}>
      <StyledLink
        onClick={resendActivationCode}
        data-testid={"resend_code_link"}
        disabled={!isDisable}
        color={linkColor}
        sx={{ textDecoration: "underline" }}
      >
        <FormattedMessage id={label} />
      </StyledLink>
    </Box>
  );
};
