import React from "react";
import history from "utils/history";
import ToggleSwitch from "components/ToggleSwitch";

const SWITCH_TOGGLE = props => {
  const {
    data,
    value = window.location.pathname,
    valueAttribute = "href",
    onClick = item => history.replace(item.href),
    ...rest
  } = props;
  return (
    <ToggleSwitch
      value={value}
      valueAttribute={valueAttribute}
      onClick={onClick}
      data={data}
      {...data}
      {...rest}
    />
  );
};

export default SWITCH_TOGGLE;
