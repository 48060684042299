import React from "react";
import NewNumberDisplay from "components/NewNumberDisplay";

const NEW_NUMBER_DISPLAY = ({ formattedSelectedNumber, data, themeColors }) => (
  <NewNumberDisplay
    {...formattedSelectedNumber}
    {...data}
    themeColors={themeColors}
  />
);

export default NEW_NUMBER_DISPLAY;
