import React, { useState } from "react";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, Text, Image, Button } from "ui-components";
import { buttonThemes } from "ui-components/theme";
import VisaLogo from "assets/images/visa.png";
import get from "lodash/get";
import OTPModal from "components/OTPModal";
import { gotoUrl } from "utils/helpers";
import { ORDER_ACTION_TYPES, paths } from "utils/constants";

const OutstandingPaymentCard = ({
  themeVariant,
  padding,
  outstandingBillsDomain,
  payOutstandingBills,
  isOtpVerified,
  validateSignatureDomain,
  formValues,
  pay_text,
  update_card_text,
  outstandingBillToken,
  isFetchingPayOutstandingBills
}) => {
  const payment_info = get(outstandingBillsDomain, "payment_info");
  const total = get(outstandingBillsDomain, "total");
  const serviceInstanceNumber = get(
    outstandingBillsDomain,
    "serviceInstanceNumber"
  );
  const outstandingPaymentToken = get(validateSignatureDomain, "session_token");
  const redirectUrl = window.location.href;

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);

  const onOTPSubmitSuccess = sessionToken => {
    gotoUrl(
      `${paths.PAYMENT_CARD_UPDATE}?sin=${serviceInstanceNumber}&return_path=${redirectUrl}&cc_update=true`
    );
  };

  return (
    <>
      <Box variant={themeVariant} padding={padding}>
        <Flex width={1} flexDirection={"column"}>
          <Flex flexDirection={"row"} justifyContent={"space-between"}>
            <Flex flexDirection={"column"}>
              <Image
                src={VisaLogo}
                sx={{ height: "6rem", width: "6rem" }}
                ml={6}
              />
            </Flex>
            <Flex flexDirection={"column"}>
              {payment_info && (
                <Text>{"**** **** **** " + payment_info.card_no}</Text>
              )}

              <Button
                onClick={() => {
                  if (!isOtpVerified) {
                    setShowModal(true);
                  } else {
                    gotoUrl(
                      `${paths.PAYMENT_CARD_UPDATE}?sin=${serviceInstanceNumber}&return_path=${redirectUrl}&cc_update=true`
                    );
                  }
                }}
                sx={{ color: "#0000FF" }}
                style={{ background: "none", border: "none" }}
              >
                <FormattedMessage id={update_card_text} />
              </Button>
            </Flex>
          </Flex>

          <Button
            onClick={() => {
              let params = { token: outstandingBillToken };
              payOutstandingBills(params);
            }}
            disabled={isFetchingPayOutstandingBills}
            sx={{ height: "3rem", width: "8rem", margin: "auto" }}
          >
            {total && (
              <FormattedMessage
                html
                id={"Pay " + total.prefix + total.amount}
              />
            )}
          </Button>
        </Flex>
      </Box>

      <OTPModal
        outstandingPaymentToken={outstandingPaymentToken}
        orderActionName={ORDER_ACTION_TYPES.OUTSTANDING_BILLS}
        showModal={showModal}
        closeModal={closeModal}
        buttonTheme={buttonThemes.blue}
        onSuccess={onOTPSubmitSuccess}
      />
    </>
  );
};

OutstandingPaymentCard.defaultProps = {
  pay_text: "pay_text",
  update_card_text: "update_card_text",
  data: {},
  themeVariant: "main",
  invoices: [],
  outstandingBillsDomain: {}
};

export default OutstandingPaymentCard;
