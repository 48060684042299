import React from "react";
import { Image } from "rebass";

const ImageFromBase64 = props => {
  const { baseUrl, data } = props;
  return (
    <div>
      {baseUrl ? (
        <Image src={baseUrl} {...data} width={["240px"]} margin="2rem auto" />
      ) : null}
    </div>
  );
};

export default ImageFromBase64;
