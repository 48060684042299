import get from "lodash/get";
import { createSelector } from "reselect";

const contractDetailsDomain = state =>
  get(state, "form.ContractDetailsForm.values", {});

export const contractDetailsSelector = createSelector(
  contractDetailsDomain,
  contractDetails => contractDetails
);

export const userSimTypeSelector = createSelector(
  contractDetailsDomain,
  ({ sim_type }) => sim_type
);

export const userContractTypeSelector = createSelector(
  contractDetailsDomain,
  ({ number_type }) => number_type
);

export const userNetworkTypeSelector = createSelector(
  contractDetailsDomain,
  ({ network_type }) => network_type
);

export const userloginEmailSelector = createSelector(
  contractDetailsDomain,
  ({ email }) => {
    return email;
  }
);

export const makeSelectContactDetailsSimType = () => userSimTypeSelector;
export const makeSelectContactDetailsContractType = () =>
  userContractTypeSelector;
export const makeSelectContactDetailsNetworkType = () =>
  userNetworkTypeSelector;
export const makeSelectContactDetailsLoginEmail = () => userloginEmailSelector;
export const makeSelectContractDetailsForm = () => contractDetailsSelector;
