import React from "react";
import { Flex, Box, Text, H2 } from "ui-components";
import map from "lodash/map";
import PageHeader from "components/PageHeader";
import TimeLineItem from "components/TimeLineItem";
import FormattedMessage from "components/FormattedMessage";
import { OrderDetail } from "components/OrderDetailsCard";
import { clearLocalStorage } from "utils/localStorage";
import { sanitizeHTML } from "utils/helpers";

const badgeColorMapping = {
  inactive: "#efefef",
  fail: "#CB1818",
  success: "#67BC01",
  processing: "#31ea40"
};

const PaymentPendingEcPay = props => {
  const { steps } = props;

  setTimeout(() => {
    clearLocalStorage();
  }, 1000);

  return (
    <Box data-testid="payment-success" pt="4" variant="main" padding={[4, 6]}>
      <PageHeader title="payment_return_please_pay_at_shop" />

      <OrderDetailsCard {...props} />

      <PaymentReminderCard />

      <Flex
        as="ul"
        sx={{
          flexDirection: "column",
          listStyle: "none",
          padding: 0,
          margin: 0,
          mb: 4
        }}
      >
        {map(steps, ({ title, description, state }) => {
          return (
            <TimeLineItem
              key={`step-${title}`}
              color={badgeColorMapping[state]}
            >
              <Text fontWeight="500">{title}</Text>
              <Text
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(description) }}
              />
            </TimeLineItem>
          );
        })}
      </Flex>

      <Text lineHeight={1.4}>
        <FormattedMessage html id="payment_return_steps_extra_details" />
      </Text>
    </Box>
  );
};

const OrderDetailsCard = ({
  orderRef,
  isPortInRequest,
  portDonor,
  selectedNumber,
  deliveryAddress
}) => {
  const numberLabel = isPortInRequest ? "port_in_number" : "new_number";
  const numberMessage = isPortInRequest
    ? `${selectedNumber} from ${portDonor}`
    : selectedNumber;

  const details = [
    { label: numberLabel, value: numberMessage },
    { label: "your_order_ref", value: orderRef },
    { label: "address", value: deliveryAddress }
  ];

  return (
    <Box mb={6}>
      <Flex width={1} mb={4}>
        <H2>
          <FormattedMessage id="order" />
        </H2>
      </Flex>

      {details.map((detail, index) => {
        return detail.value ? <OrderDetail key={index} {...detail} /> : null;
      })}
    </Box>
  );
};

const PaymentReminderCard = () => {
  return (
    <Box mb={6}>
      <H2>
        <FormattedMessage id="payment_return_payment" />
      </H2>

      <Text lineHeight={1.4}>
        <FormattedMessage html id="payment_return_payment_reminder_text" />
      </Text>
    </Box>
  );
};

export default PaymentPendingEcPay;
