import { createSelector } from "reselect";
import get from "lodash/get";
import map from "lodash/map";
import range from "lodash/range";
import memoize from "lodash/memoize";

export const localeDomain = state => get(state.api, "locale.data", {});

// ***************************************************************
// ********************** locale makeSelect **********************
// ***************************************************************

export const localeSelector = createSelector(localeDomain, locale => locale);

export const dateOptionsSelector = createSelector(localeDomain, localeState => {
  return map(range(31), n => {
    let paddedValue = (n + 1).toString().padStart(2, "0");
    const string = localeState[`date_${paddedValue}`];

    return {
      label: string,
      value: paddedValue
    };
  });
});

export const monthOptionsSelector = createSelector(
  localeDomain,
  localeState => {
    return map(range(12), n => {
      let paddedValue = (n + 1).toString().padStart(2, "0");
      const string = localeState[`month_short_${paddedValue}`];

      return {
        label: string,
        value: paddedValue
      };
    });
  }
);

export const genericErrorSelector = createSelector(localeDomain, locales =>
  get(locales, "ERROR_GENERIC_MESSAGE", "")
);

export const errorSelectorByKey = createSelector(localeDomain, locales =>
  memoize(key => get(locales, key))
);

export const makeSelectLocale = () => localeSelector;
export const makeSelectMessages = () => localeSelector;
export const makeSelectDateOptions = () => dateOptionsSelector;
export const makeSelectMonthOptions = () => monthOptionsSelector;
export const makeSelectGenericErrorSelector = () => genericErrorSelector;
