import React, { memo, useCallback, useState } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect, useSelector, useDispatch } from "react-redux";

import validate from "validations";
import { forms, paths, TELCO_API_ERRORS } from "utils/constants";
import SmartRender from "containers/SmartRender";
import { Telco } from "ducks/actions";
import {
  makeSelectThemeColors,
  makeSelectPageValidations,
  makeSelectReversePortInFormInitialValues,
  makeSelectServiceInstanceNumber,
  makeSelectCurrentPhoneNumber,
  makeSelectFormValues,
  makeSelectIsFetchingData,
  makeSelectMonthOptions,
  makeSelectDateOptions,
  makeSelectYearOptions,
  makeSelectMinimumAge,
  makeSelectGuestOrder,
  makeSelectDeliveryDetailsItems
} from "ducks/selectors";
import { createStructuredSelector } from "reselect";
import { getDOBSecurityHeader, gotoUrl, pathWithChannel } from "utils/helpers";

const ReversePortIn = Component =>
  function Comp(props) {
    const [showOTPModal, setShowOTPModal] = useState(false);
    const dispatch = useDispatch();
    const accountNumber = useSelector(makeSelectServiceInstanceNumber());
    const msisdn = useSelector(makeSelectCurrentPhoneNumber());
    const dob = props?.formValues?.dob;

    const onSubmit = useCallback(
      (_, data = {}) => {
        const { otp_payload } = data;
        const headers = getDOBSecurityHeader(dob);
        const requestPayLoad = {
          accountNumber,
          msisdn,
          otp_payload
        };
        dispatch(
          Telco.V1.requestReversePortin(
            headers,
            requestPayLoad,
            (result, errors) => {
              if (!errors) {
                setShowOTPModal(false);
                setTimeout(() => {
                  gotoUrl(pathWithChannel(paths.MANAGE_ORDER));
                }, 0);
              } else {
                const { title } = errors || {};
                if (title !== TELCO_API_ERRORS.UNAUTHORISED) {
                  setShowOTPModal(false);
                }
              }
            }
          )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },
      [dob, accountNumber, msisdn, dispatch]
    );

    return (
      <Component
        {...props}
        onSubmit={onSubmit}
        showOTPModal={showOTPModal}
        setShowOTPModal={setShowOTPModal}
      />
    );
  };

const mapStateToProps = createStructuredSelector({
  formValidations: makeSelectPageValidations(forms.REVERSE_PORTIN_FORM),
  formValues: makeSelectFormValues(forms.REVERSE_PORTIN_FORM),
  isFetchingDetails: makeSelectIsFetchingData("requestReversePortin"),
  initialValues: makeSelectReversePortInFormInitialValues(),
  monthOptions: makeSelectMonthOptions(),
  dateOptions: makeSelectDateOptions(),
  yearOptions: makeSelectYearOptions(),
  minimumAge: makeSelectMinimumAge(),
  themeColors: makeSelectThemeColors(),
  order: makeSelectGuestOrder(),
  deliveryDetailsItems: makeSelectDeliveryDetailsItems()
});
const mapDispatchToProps = {};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.REVERSE_PORTIN_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(ReversePortIn(SmartRender));
