import React from "react";
import { useSelector, useDispatch } from "react-redux";
import get from "lodash/get";

import { Box, StyledLink } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import { UserService, disableResendActivation } from "ducks/actions";
import {
  makeSelectLoginEmail,
  makeSelectContactDetailsLoginEmail,
  makeSelectIsResendActivationEnabled
} from "ducks/selectors";
import { publicPaths, AUTH_MODES } from "utils/constants";
import {
  gotoUrl,
  getChannelFromUrl,
  getOnSubmitActionHandler
} from "utils/helpers";
import isFunction from "lodash/isFunction";
/**
 * Resend OTP Activation code link component
 * @param {object} param
 */
const ResendActivationCode = props => {
  const { label, themeColors } = props;
  const dispatch = useDispatch();
  const email = useSelector(makeSelectLoginEmail());
  const contractEmail = useSelector(makeSelectContactDetailsLoginEmail());
  const isDisable = !useSelector(makeSelectIsResendActivationEnabled());
  const VerifyOTPPath = publicPaths.VERIFY_OTP.replace(
    ":channel",
    getChannelFromUrl()
  );
  const linkColor = get(themeColors, "cta");
  /**
   * Dispatches the request, OTP for email.
   */
  const requestOTPForEmail = () => {
    const additionalHeaders = {};
    const bodyParams = {
      email: email || contractEmail,
      auth_mode: AUTH_MODES.ENHANCED_EMAIL_OTP
    };

    /**
     * redirect user to OTP page if successful
     * @param {object} response success response result
     * @param {object} errors API errors only if failed
     */
    const onSubmitCallback = (response, errors) => {
      const success = !errors;
      if (success) {
        dispatch(disableResendActivation());
        gotoUrl(VerifyOTPPath, true, { key: Date.now() }, true);
      } else {
        props.setErrorMessage(response.message);
        console.error("Request OTP failed. Cause: ", errors);
      }
    };

    dispatch(
      UserService.V4.userLoginOTP(
        additionalHeaders,
        bodyParams,
        onSubmitCallback
      )
    );
  };

  const resendActivationCode = () => {
    if (isDisable) return;
    const onSubmitActionHandler = getOnSubmitActionHandler(props);
    isFunction(onSubmitActionHandler)
      ? onSubmitActionHandler()
      : requestOTPForEmail();
  };

  return (
    <Box mt={[24, 36]} sx={{ textAlign: "center" }}>
      <StyledLink
        onClick={resendActivationCode}
        data-testid={"resend_code_link"}
        disabled={isDisable}
        color={linkColor}
        sx={{ textDecoration: "underline" }}
      >
        <FormattedMessage id={label} />
      </StyledLink>
    </Box>
  );
};

export default ResendActivationCode;
