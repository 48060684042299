import React, { memo, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import {
  clearData,
  setData,
  selectPlan,
  changeFormField,
  UserService,
  clearSocialLoginData
} from "ducks/actions";
import {
  makeSelectAddons,
  makeSelectDefaultPlanAddons,
  makeSelectPayMonthlyItems,
  makeSelectPayTodayItems,
  makeSelectReferralCode,
  makeSelectDefaultPromoCodeOffers,
  makeSelectPlanFAQ,
  makeSelectPlanName,
  makeSelectEligiblePlans,
  makeSelectPlanDetails,
  makeSelectIccIdDetails,
  makeSelectIsFetchingData,
  makeSelectFormattedSelectedNumber,
  makeSelectDeliveryDetailsItems,
  makeSelectValidateIccId,
  makeSelectIsUserLoggedIn,
  makeSelectSelectedSimType,
  makeSelectPrecheckoutInfo,
  makeSelectFetchPlanCounter,
  makeSelectSelectedNumberType,
  makeSelectNumberType,
  makeSelectIsTerminatedNumber,
  makeSelectIsPortingUser,
  makeSelectPlanType,
  makeSelectIsSelectedPlanType,
  makeSelectGA4PlanDetails
} from "ducks/selectors";
import SmartRender from "containers/SmartRender";
import {
  forms,
  INTERVAL_TIME,
  paths,
  publicPaths,
  DATA_ONLY_CHANNERL,
  AU_TO_POVO_CHANNEL
} from "utils/constants";
import Spinner, { styles } from "components/Spinner";
import {
  loggedInUserDetailsAPIParams,
  urlParam,
  gotoUrl,
  pathWithChannel,
  getChannelFromUrl,
  maintenanceOnError,
  getURLParams
} from "utils/helpers";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import { getDefaultAddons, getDeviceId } from "utils/localStorage";
import { Rewards, OMS } from "ducks/actions";
import isFunction from "lodash/isFunction";
import { logoutUserCallback } from "ducks/sagas/utils";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";
import { featureFlagDataOnlyPlanEnabled } from "utils/featureFlags";

const planTypes = {
  DATA_ONLY: "data_only",
  VOICE_AND_DATA: "voice_data"
};

const PreCheckoutHOC = Component =>
  function Comp(props) {
    const {
      selectPlan,
      isFetchingEligiblePlans,
      validateReferralCode,
      eligiblePlans,
      planDetails,
      orderDetails: { selected_referral_code, is_combo },
      planType,
      GA4EventPlanDetails,
      fetchEligiblePlans,
      planName
    } = props;

    const selectedPlanName = get(planDetails, "name");
    const channel = getChannelFromUrl();

    const planParams = {};
    if (channel) {
      planParams["channel"] = channel;
    }
    const { isBetaUser } = getURLParams();

    useEffect(() => {
      if (planName) {
        GA4EventsService.publish(checkoutEventData(GA4EventPlanDetails));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planName]);

    useEffect(() => {
      if (
        (featureFlagDataOnlyPlanEnabled() && channel === AU_TO_POVO_CHANNEL) ||
        !featureFlagDataOnlyPlanEnabled() ||
        (featureFlagDataOnlyPlanEnabled() && !isBetaUser)
      ) {
        fetchEligiblePlans(planParams);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(
      () => {
        if (
          ((channel === AU_TO_POVO_CHANNEL &&
            featureFlagDataOnlyPlanEnabled()) ||
            !featureFlagDataOnlyPlanEnabled() ||
            (featureFlagDataOnlyPlanEnabled() && !isBetaUser)) &&
          !isEmpty(eligiblePlans) &&
          !isFetchingEligiblePlans
        ) {
          selectPlan(head(eligiblePlans));
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [eligiblePlans, isFetchingEligiblePlans]
    );

    useEffect(() => {
      selected_referral_code &&
        validateReferralCode(
          selected_referral_code,
          selectedPlanName,
          is_combo
        );
    }, [
      selected_referral_code,
      validateReferralCode,
      is_combo,
      selectedPlanName
    ]);

    const handlePlanAndNavigate = () => {
      if (planType === planTypes.DATA_ONLY) {
        gotoUrl(
          pathWithChannel(
            publicPaths.DATA_ONLY_TOPPING_SELECTION,
            DATA_ONLY_CHANNERL
          ),
          false,
          {},
          false
        );
      } else if (planType === planTypes.VOICE_AND_DATA) {
        gotoUrl(pathWithChannel(paths.CONTRACT_DETAILS_PAGE), false, {}, false);
      }
    };

    return isFetchingEligiblePlans ? (
      <Spinner size={200} sx={styles.fullPage} />
    ) : (
      <Component {...props} handlePlanAndNavigate={handlePlanAndNavigate} />
    );
  };

const mapStateToProps = createStructuredSelector({
  formValues: makeSelectPrecheckoutInfo(),
  addOns: makeSelectAddons(),
  payMonthlyItems: makeSelectPayMonthlyItems(),
  payTodayItems: makeSelectPayTodayItems(),
  referralCode: makeSelectReferralCode(),
  defaultPromoCodeOffers: makeSelectDefaultPromoCodeOffers(),
  planFAQ: makeSelectPlanFAQ(),
  planName: makeSelectPlanName(),
  isFetchingPlan: makeSelectIsFetchingData("planService"),
  isFetchingEligiblePlans: makeSelectIsFetchingData("eligiblePlans"),
  isCreatingOrder: makeSelectIsFetchingData("order"),
  isFetchingPaymentRequest: makeSelectIsFetchingData("paymentRequest"),
  eligiblePlans: makeSelectEligiblePlans(),
  planDetails: makeSelectPlanDetails(),
  iccIdDetails: makeSelectIccIdDetails(),
  addons: makeSelectAddons(),
  orderDetails: makeSelectValidateIccId(),
  selectedNumber: makeSelectFormattedSelectedNumber(),
  selectedSimType: makeSelectSelectedSimType(),
  deliveryDetailsItems: makeSelectDeliveryDetailsItems(),
  defaultAddons: getDefaultAddons,
  defaultPlanAddons: makeSelectDefaultPlanAddons(),
  isLoggedIn: makeSelectIsUserLoggedIn(),
  fetchPlanCounter: makeSelectFetchPlanCounter(),
  selectedNumberType: makeSelectSelectedNumberType(),
  numberType: makeSelectNumberType(),
  isTerminatedNumber: makeSelectIsTerminatedNumber(),
  isPortingUser: makeSelectIsPortingUser(),
  planType: makeSelectPlanType(),
  selectedPlanType: makeSelectIsSelectedPlanType(),
  GA4EventPlanDetails: makeSelectGA4PlanDetails(),
  initialValues: state => {
    return {
      sim_type: makeSelectSelectedSimType()(state),
      _fetch_plan_counter: makeSelectFetchPlanCounter()(state),
      number_type: makeSelectNumberType()(state)
    };
  }
});

export const mapDispatchToProps = dispatch => {
  return {
    selectPlan: plan => dispatch(selectPlan(plan)),
    validateReferralCode: (params, callback) =>
      dispatch(Rewards.V1.fetchReferralCode(params, callback)),
    resetReferralCode: () => dispatch(clearData("referralCode")),
    handleSelectAddon: (addonId, selected) =>
      dispatch(
        setData({ key: "selectedAddons", data: { [addonId]: selected } })
      ),
    changeField: (field, value) => {
      dispatch(
        changeFormField({
          form: forms.PRE_CHECKOUT_FORM,
          field: field,
          value: value
        })
      );
    },
    clearSocialLoginData: () => dispatch(clearSocialLoginData()),
    fetchLoggedInUserDetails: callBack => {
      const params = loggedInUserDetailsAPIParams();
      const newToken = urlParam("auth_token");
      const tokenHeader = newToken ? { "X-AUTH": newToken } : {};
      dispatch(
        UserService.V4.fetchLoggedInUserDetails(tokenHeader, params, callBack)
      );
    },
    logoutUser: (external_id, callback) => {
      const headers = {};
      const params = {
        device_id: getDeviceId(),
        user_id: external_id
      };
      dispatch(UserService.V4.userLogout(headers, params, callback));
    },
    fetchEligiblePlans: params => {
      dispatch(OMS.V4.fetchEligiblePlans(params, maintenanceOnError));
    }
  };
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  fetchLoggedInUserDetailsAndClearAUData: navigate => {
    dispatchProps.clearSocialLoginData();
    dispatchProps.fetchLoggedInUserDetails((_, errors) => {
      if (!errors) {
        window.setTimeout(() => {
          isFunction(navigate) && navigate();
        }, INTERVAL_TIME);
      } else {
        dispatchProps.logoutUser(stateProps.external_id, logoutUserCallback);
      }
    });
  },
  validateReferralCode: (referral_code, plan_name, device_sku) => {
    dispatchProps.validateReferralCode(
      {
        referral_code,
        plan_name,
        ...(device_sku && { device_sku })
      },
      (data, errors) => {
        if (!errors) {
          GA4EventsService.publish(
            promocodeEventData({
              ...stateProps.GA4EventPlanDetails,
              coupon: referral_code,
              coupon_type: data.code_type
            })
          );
        }
      }
    );
  }
});

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.PRE_CHECKOUT_FORM,
    destroyOnUnmount: false
  }),
  memo
)(PreCheckoutHOC(SmartRender));

const checkoutEventData = planDetails => {
  const { customer_id, plan_name, plan_type, currency, value } = planDetails;

  return {
    event: GA4EVENTS.PRE_CHECKOUT_PAGE_VIEW,
    ecommerce: {
      customer_id,
      plan_name,
      journey_type: "",
      plan_type,
      currency,
      value
    }
  };
};

const promocodeEventData = (details = {}) => {
  const {
    customer_id,
    plan_name,
    plan_type,
    coupon,
    coupon_type,
    sim_type,
    number_type,
    journey_type
  } = details;

  return {
    event: GA4EVENTS.PROMOCODE_APPLIED,
    ecommerce: {
      order_ref: "",
      plan_name,
      customer_id,
      journey_type,
      plan_type,
      sim_type,
      number_type,
      coupon,
      coupon_type
    }
  };
};
