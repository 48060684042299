import React, { memo, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { forms, OUTSTANDING_PAYMENT_STATUS_URLS } from "utils/constants";
import { gotoUrl, urlParam } from "utils/helpers";
import {
  makeSelectOutstandingBillAmountWithSymbol,
  makeSelectFormattedOutstandingBillsList,
  makeSelectUserProfileDetails,
  makeSelectIsFetchingData,
  makeSelectCardTypesMap,
  makeSelectCardTypes,
  makeSelectIsModalView,
  makeSelectModalType,
  makeSelectIsOutstandingBillPaymentConfirmationVisible,
  makeSelectIsActiveTelcoUser,
  makeSelectOutstandingBillDetails,
  makeSelectIsOutstandingPayable,
  makeSelectOutstandingStatusLabel,
  makeSelectOutstandingStatus,
  makeSelectOutstandingBills,
  makeSelectPaymentErrorCodes
} from "ducks/selectors";
import {
  fetchUserProfileDetails,
  setData,
  clearData,
  Billing
} from "ducks/actions";

import SmartRender from "containers/SmartRender";
import { showAlert } from "components/Alert";
import Spinner, { styles } from "components/Spinner";

const OutstandingBillPaymentHOC = Component =>
  function HOC(props) {
    const {
      isLoadingOutstandingBillsInProgress,
      fetchOutstandingBillsInfo,
      outstandingBillDetails,
      isActiveTelcoUser,
      userProfileDetails,
      isLoadingProfileData,
      getUserProfileDetails,
      clearPreviousOutstandingBillPayment
    } = props;

    const isFetchOutstandingBills = urlParam("reload_bills");

    // this use effect will load outstanding bills if not available in store
    useEffect(() => {
      if (
        (isActiveTelcoUser &&
          isEmpty(outstandingBillDetails) &&
          !isLoadingOutstandingBillsInProgress) ||
        isFetchOutstandingBills
      ) {
        clearPreviousOutstandingBillPayment();
        fetchOutstandingBillsInfo();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // this use effect will load details we use to show credit card details if not available in store
    useEffect(() => {
      if (
        isActiveTelcoUser &&
        isEmpty(userProfileDetails) &&
        !isLoadingProfileData
      ) {
        getUserProfileDetails();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoadingOutstandingBillsInProgress || isLoadingProfileData) {
      return <Spinner size={200} sx={styles.fullPage} />;
    } else {
      return <Component {...props} />;
    }
  };

const mapStateToProps = createStructuredSelector({
  invoices: makeSelectFormattedOutstandingBillsList(),
  isLoadingOutstandingBillsInProgress: makeSelectIsFetchingData(
    "outstandingBillPayments"
  ),
  outstandingBillAmount: makeSelectOutstandingBillAmountWithSymbol(),
  outstandingBillDetails: makeSelectOutstandingBillDetails(),
  isOutstandingPayable: makeSelectIsOutstandingPayable(),
  outstandingBillStatus: makeSelectOutstandingStatus(),
  outstandingBillStatusLabel: makeSelectOutstandingStatusLabel(),
  outstandingBills: makeSelectOutstandingBills(),

  userProfileDetails: makeSelectUserProfileDetails(),
  isLoadingProfileData: makeSelectIsFetchingData("userProfileDetails"),
  cardMap: makeSelectCardTypesMap(),
  cardTypes: makeSelectCardTypes(),

  isLoadingPaymentIntent: makeSelectIsFetchingData(
    "outstandingBillPaymentIntent"
  ),

  isActiveTelcoUser: makeSelectIsActiveTelcoUser(),
  isModalView: makeSelectIsModalView(),
  modalType: makeSelectModalType(),
  isConfirmationVisible: makeSelectIsOutstandingBillPaymentConfirmationVisible(),

  errorCodes: makeSelectPaymentErrorCodes()
});

export function mapDispatchToProps(dispatch) {
  return {
    fetchOutstandingBillsInfo: () =>
      dispatch(Billing.V4.fetchOutstandingBillsInfo()),
    getUserProfileDetails: () => {
      dispatch(fetchUserProfileDetails());
    },
    showOutstandingBillPaymentConfirmation: state => {
      dispatch(
        setData({
          key: "isModalView",
          data: {
            isModalView: state,
            type: "outstandingBillPaymentConfirmation"
          }
        })
      );
    },
    fetchOutstandingPaymentIntent: (params, callback) => {
      dispatch(Billing.V4.fetchOutstandingPaymentIntent(params, callback));
    },
    clearPreviousOutstandingBillPayment: () =>
      dispatch(
        clearData([
          "outstandingBillPaymentIntent",
          "outstandingBillPaymentStatus"
        ])
      )
  };
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  showPaymentConfirmation: () => {
    dispatchProps.showOutstandingBillPaymentConfirmation(true);
  },
  hidePaymentConfirmation: () =>
    dispatchProps.showOutstandingBillPaymentConfirmation(false),
  payNowAndNavigate: () => {
    dispatchProps.fetchOutstandingPaymentIntent({}, (response, error) => {
      const { code, correlation_id } = response;

      dispatchProps.showOutstandingBillPaymentConfirmation(false);

      if (code === 0 && correlation_id) {
        gotoUrl(OUTSTANDING_PAYMENT_STATUS_URLS.STATUS, true);
      } else {
        // Get the error code from payment service api response.
        const errorCode = get(response, "error_code");
        const { errorCodes, locale } = stateProps;

        /*
         * Check if the error code matches with ccm payment service
         * GMO related error codes.
         */
        if (errorCodes[errorCode]) {
          showAlert.error(get(locale, errorCodes[errorCode]));
        } else {
          showAlert.error(error);
        }
      }
    });
  }
});

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.OUTSTANDING_BILL_PAYMENT_FORM,
    destroyOnUnmount: false
  }),
  memo
)(OutstandingBillPaymentHOC(SmartRender));
