import React, { useEffect } from "react";
import map from "lodash/map";
import isFunction from "lodash/isFunction";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import SimplePhoneCard from "./SimplePhoneCard";
import PhoneBanner from "./PhoneBanner";
import { getChannelFromUrl, gotoUrl } from "utils/helpers";
import { Flex, Box, Text } from "ui-components";
import { paths } from "utils/constants";
import { urlParam } from "utils/helpers";
import DropdownField from "components/FormFields/DropdownField";
import RangeField from "components/FormFields/RangeField";
import FormattedMessage from "components/FormattedMessage";

const handleClick = id => {
  gotoUrl(paths.PHONE_DETAILS.replace(":channel", getChannelFromUrl()), true, {
    id
  });
};

const PhoneList = ({ change, fetchPhones, ...props }) => {
  useEffect(
    () => {
      if (urlParam("setPhoneOnly") !== null) {
        change("phoneOnly", true);
      }
      isFunction(fetchPhones) && fetchPhones();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      {renderFilterSection(props)}
      {renderPhoneList(props)}
      <PhoneBanner cardList={get(props, "data.allowed_card_list", [])} />
    </>
  );
};

const renderFilterSection = props => {
  return (
    <Box p={2}>
      <Flex flexDirection="column">{renderFilterDropdown(props)}</Flex>
    </Box>
  );
};
const renderResultCount = props => {
  const count = get(props, "filteredPhones.length", 0);
  const label = get(
    props,
    "data.result_count_label",
    "showing_available_devices"
  );
  return (
    <Flex justifyContent="flex-end" width="100%">
      <Text
        sx={{
          textAlign: "right",
          alignSelf: "flex-end",
          whiteSpace: "nowrap",
          fontWeight: "700",
          mb: "32px",
          padding: "0.3rem"
        }}
      >
        <FormattedMessage id={label} values={{ count }} />
      </Text>
    </Flex>
  );
};

const renderFilterDropdown = props => {
  const priceRangeProps = get(props, "data.price_range", {});
  const filterProps = get(props, "data.filter", {});
  const options = get(filterProps, "options");
  const sx = {
    boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.3)",
    p: 2,
    borderRadius: "12px",
    backgroundColor: "white",
    mb: "0.5rem !important"
  };
  const formatValue = value => `$${value}`;
  return isEmpty(options) ? null : (
    <Flex justifyContent="space-between" flexWrap="wrap" width="100%" mb="1rem">
      <Box width={["100%", "49%"]}>
        <DropdownField {...filterProps} sx={sx} />
      </Box>
      <Box width={["100%", "49%"]}>
        <RangeField {...priceRangeProps} sx={sx} formatValue={formatValue} />
        {renderResultCount(props)}
      </Box>
    </Flex>
  );
};

const renderPhoneList = ({ filteredPhones }) => {
  return (
    <Flex flexWrap="wrap" p={1}>
      {map(filteredPhones, item => {
        return (
          <Box
            key={item.id}
            px={0}
            py={20}
            mb={"2rem"}
            width={[1 / 2, 1 / 3, 1 / 3]}
          >
            <SimplePhoneCard {...item} onClick={() => handleClick(item.id)} />
          </Box>
        );
      })}
    </Flex>
  );
};

export default PhoneList;
