import React from "react";
import { Flex, Text } from "ui-components";
import * as Icons from "components/Icons";
import FormattedMessage from "components/FormattedMessage";
import { sanitizeHTML } from "utils/helpers";

const StaticContentBox = ({
  content,
  message,
  backgroundColor,
  iconColor,
  icon,
  themeColors,
  mode,
  alert,
  color = "black",
  messageData = {},
  style = {},
  propValue,
  sx = {},
  ...props
}) => {
  const messageId = content || message || props?.[propValue];
  const iconType = alert ? "AlertCircle" : icon;
  const IconComponent = iconType && Icons[iconType];
  const bgColor =
    backgroundColor || style?.backgroundColor || themeColors?.info;
  const icColor = iconColor || style?.color || themeColors?.icon;
  const textColor = style?.color || color;
  const customBackgroundSize = props.isMobile
    ? sx?.mobileBackgroundSize
    : sx?.webBackgroundSize;
  const customHeights = props.isMobile ? sx?.mobileHeight : sx?.webHeight;
  return (
    <Flex
      p="4"
      mb="4"
      variant={mode}
      backgroundColor={bgColor}
      alignItems="center"
      width="100%"
      style={{ ...style, backgroundColor: bgColor }}
      sx={{
        ...sx,
        backgroundSize: customBackgroundSize,
        height: customHeights
      }}
    >
      {IconComponent && (
        <Flex minWidth={"24px"}>
          <IconComponent color={icColor} />
        </Flex>
      )}
      {messageId && (
        <Flex paddingLeft={IconComponent ? "1rem" : 0}>
          <FormattedMessage html id={messageId} values={messageData}>
            {messageContent => (
              <Text
                fontWeight="500"
                size="sm"
                color={textColor}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHTML(messageContent)
                }}
              />
            )}
          </FormattedMessage>
        </Flex>
      )}
    </Flex>
  );
};

StaticContentBox.defaultProps = {
  mode: "light",
  content: "",
  alert: false
};

export default StaticContentBox;
