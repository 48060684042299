import React from "react";
import { Box, Tiles, Avatar } from "ui-components";
import LineIcon from "./LineIcon";
import YahooIcon from "./YahooIcon";
import GoogleIcon from "./GoogleIcon";
import FacebookIcon from "./FacebookIcon";
import filter from "lodash/filter";
import AppleIcon from "./AppleIcon";
import AUIcon from "./AUIcon";
import AuIPDPIcon from "./AuIPDPIcon";

const SocialMediaIcons = ({ options, data }) => {
  const largeIcons = data?.largeIcons || false;
  const activeIconsList = filter(options, "enable");

  const MAX_ICONS_FOR_ROW = 6;
  const COLUMN_COUNT = activeIconsList.length > 3 ? 3 : activeIconsList.length;
  const socialMediaMapping = {
    FACEBOOK: props => <FacebookIcon key={props.name} {...props} />,

    GOOGLE: props => <GoogleIcon key={props.name} {...props} />,

    LINE: props => <LineIcon key={props.name} {...props} />,

    YAHOO: props => <YahooIcon key={props.name} {...props} />,

    TWITTER: ({ name, iconUrl }) => (
      <Avatar
        key={name}
        src={iconUrl}
        data-testid="social-icon"
        style={{ cursor: "pointer" }}
      />
    ),

    APPLE: props => <AppleIcon key={props.name} {...props} />,
    AU: props => <AUIcon key={props.name} {...props} />,
    AU_IPDB: props => <AuIPDPIcon key={props.name} {...props} />
  };

  const itemsPerRow =
    activeIconsList.length > MAX_ICONS_FOR_ROW
      ? MAX_ICONS_FOR_ROW
      : activeIconsList.length;
  return (
    <Box data-testid={"social-icon-container"}>
      <Tiles
        columns={largeIcons ? 1 : [COLUMN_COUNT, itemsPerRow, itemsPerRow]}
        mx={24}
      >
        {activeIconsList.map(socialMediaItem => {
          const SocialMediaComponent = socialMediaMapping[socialMediaItem.name];
          return !SocialMediaComponent ? null : (
            <SocialMediaComponent
              key={socialMediaItem.name}
              largeIcons={largeIcons}
              {...socialMediaItem}
            />
          );
        })}
      </Tiles>
    </Box>
  );
};

export default SocialMediaIcons;
