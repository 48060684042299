import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Button, Box, Flex } from "ui-components";
import map from "lodash/map";
import FAQItem from "components/FAQItem";
import PageHeader from "components/PageHeader";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { iconThemes, getButtonColorVariant } from "ui-components/theme";

const PageFaqs = ({
  title,
  faq,
  more,
  titleProps = {},
  buttonProps = {},
  messageData,
  themeColors
}) => {
  return isEmpty(faq) && isEmpty(more) ? null : (
    <Box variant="faqs" id="page-faqs">
      {title && (
        <Box padding="1.5rem" paddingBottom="0">
          <PageHeader
            title={title}
            themeColors={{ secondary: iconThemes.purple }}
            {...titleProps}
          />
        </Box>
      )}
      {map(faq, (faq, index) => {
        return (
          <FAQItem
            key={index}
            {...faq}
            messageData={messageData}
            themeColors={themeColors}
          />
        );
      })}
      <Flex justifyContent="space-evenly" padding="1rem">
        {!isEmpty(more) && (
          <Button
            as="a"
            variant="outline"
            sx={getButtonColorVariant(themeColors?.button, "outline")}
            target="_blank"
            href={get(more, "link")}
            {...buttonProps}
          >
            <FormattedMessage id={get(more, "label")} />
          </Button>
        )}
      </Flex>
    </Box>
  );
};

PageFaqs.defaultProps = {
  title: "",
  faq: [],
  more: {},
  messageData: {}
};

export default PageFaqs;
