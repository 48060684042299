import {
  BASE,
  USE_API_GATEWAY,
  CANCEL_TERMINATION,
  OMS_CANCEL_TERMINATION
} from "utils/constants";
import { fetchRequested } from "ducks/actions/baseActions";

export const cancelTerminationNumber = (params, callback) => {
  const path = USE_API_GATEWAY ? CANCEL_TERMINATION : OMS_CANCEL_TERMINATION;
  const base = USE_API_GATEWAY ? BASE.REWARDS_SERVICE : BASE.OMS;
  return fetchRequested({
    key: "cancelTermination",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export default {
  cancelTerminationNumber
};
