import { createSelector } from "reselect";
import get from "lodash/get";
import filter from "lodash/filter";
import map from "lodash/map";
import sum from "lodash/sum";
import max from "lodash/max";
import sumBy from "lodash/sumBy";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import find from "lodash/find";
import moment from "moment";
import {
  planServiceSelector,
  selectedNumberSelector,
  personalDetailsSelector,
  portInSelector,
  deliveryInfoSelector,
  referralCodeSelector,
  addonsSelector,
  defaultAddonsSelector,
  defaultInvisibleAddonsSelector,
  validateIccIdSelector,
  verifyIdentitySelector,
  simVerificationSelector,
  phonesFormSelector,
  connectionDetailsSelector,
  userDetailsSelector,
  selectAppSettings,
  preCheckoutSelector,
  selfActivationSelector,
  orderFromVerificationSelector,
  multiSimOrderSelector,
  userDetailsOrderUpdatePayloadSelector,
  genericSelector,
  workflowSelector,
  simTypeSelector,
  connectionDetailsNetworkTypeSelector,
  contractTypeSelector
} from "../selectors";
import { guestOrderSelector } from "./guestOrder";
import {
  addressParams,
  deliverySlotParams,
  getValueOrField,
  userDetailParams,
  documentsParams,
  deliverDetailParams,
  getChannelFromUrl,
  constructPhoneItem
} from "utils/helpers";
import {
  PREPAID_PLAN_SUB_TYPES,
  ONE_TIME_DISCOUNT_TYPES,
  ORDER_CHANNEL_MAPPING,
  COMBO_CHANNEL,
  PHONE_ONLY_CHANNEL,
  SIM_TYPES,
  CONTRACT_TYPES,
  SELF_ACT_DEL,
  SELF_ACTIVATION_SIM,
  SIM_ACTIVATION_CHANNELS,
  paths,
  DELIVERY_METHOD_TYPE,
  ORDER_CANCEL_REASON,
  UNPROCESSABLE_ENTITY
} from "utils/constants";
import { walletSelector } from "./wallet";
import { isWebview, setOrderRef } from "utils/localStorage";
import { featureFlag5GSAEnabled } from "utils/featureFlags";

const orderDomain = state =>
  getChannelFromUrl() === SELF_ACTIVATION_SIM
    ? get(state, "api.order.data.order", get(state, "api.order.data", {}))
    : get(state, "api.order.data", {});

const sadOrderDomain = state => get(state, "api.order.data", {});

const orderErrorDomain = state => get(state, "api.order.error", []);

const orderCancellationStatusDomain = state =>
  get(state, "api.fetchOrderCancellationStatus.data", {});

const jumioDomain = state => get(state, "api.jumio", {});

const selectedNumberTypeDomain = state =>
  get(state, "api.selectedNumberType.data", {});

const selectedManageOrder = state => get(state, "api.userOrders.data", []);

const screenshotDomain = state => get(state, "api.screenshot.data", {});

const portinStatusDomain = state => get(state, "api.fetchPortinStatus.data");

export const portinStatusSelector = createSelector(
  portinStatusDomain,
  portinStatus => portinStatus
);

export const screenshotSelector = createSelector(
  screenshotDomain,
  screenshot => screenshot
);

export const orderSelector = createSelector(
  orderDomain,
  orderErrorDomain,
  (order, error) => ({
    ...order,
    error
  })
);

export const sadOrderSelector = createSelector(
  sadOrderDomain,
  orderErrorDomain,
  (order, error) => ({
    ...order,
    error
  })
);
export const orderRefSelector = createSelector(
  orderSelector,
  guestOrderSelector,
  (order, guestOrder) => {
    const orderRef =
      get(guestOrder, "order_ref") || get(order, "order_ref") || "";
    setOrderRef(orderRef);
    return orderRef;
  }
);

export const orderedSimTypeSelector = createSelector(
  orderSelector,
  guestOrderSelector,
  (order, guestOrder) => {
    const simType = get(guestOrder, "sim_type") || get(order, "sim_type") || "";
    return simType;
  }
);

export const orderedContactTypeSelector = createSelector(
  orderSelector,
  guestOrderSelector,
  (order, guestOrder) => {
    const contactType =
      get(guestOrder, "contract_type") || get(order, "contract_type") || "";
    return contactType;
  }
);

export const orderIdSelector = createSelector(
  orderSelector,
  guestOrderSelector,
  (order, guestOrder) => get(guestOrder, "id") || get(order, "id") || ""
);

export const orderCancellationStatusSelector = createSelector(
  orderCancellationStatusDomain,
  orderCancellationStatus => orderCancellationStatus
);

const orderCancellationPayloadSelector = createSelector(
  orderCancellationStatusSelector,
  orderSelector,
  guestOrderSelector,
  (orderCancellationStatus, order, guestOrder) => {
    let currentDate = new Date();
    const terminationDate = currentDate.setDate(currentDate.getDate() + 1);
    const requestPayLoad = {
      order_id: get(orderCancellationStatus, "order_id"),
      order_ref: get(guestOrder, "order_ref") || get(order, "order_ref") || "",
      token: "",
      action_type: get(orderCancellationStatus, "state"),
      feedback: "",
      reason: ORDER_CANCEL_REASON,
      winback_code: "",
      winback_id: "",
      termination_date: moment(terminationDate).toISOString(),
      phone_number: get(orderCancellationStatus, "phone_number"),
      winback_description: "",
      primary_id: null
    };
    return requestPayLoad;
  }
);

const jumioRedirectUrlSelector = createSelector(
  jumioDomain,
  orderRefSelector,
  (jumio, orderRef) => {
    const jumioUrl = get(jumio, "data.redirectUrl", "");
    const appUrl = `${paths.EKYC_VERIFY}?order_ref=${orderRef}`;
    return isWebview() && jumioUrl && orderRef ? appUrl : jumioUrl;
  }
);

const jumioDataSelector = createSelector(jumioDomain, jumio => jumio);

export const makeSelectOrderCancellationStatus = () =>
  orderCancellationStatusSelector;

export const makeSelectScreenshot = () => screenshotSelector;

export const makeSelectOrder = () => orderSelector;

export const makeSelectOrderedSimType = () => orderedSimTypeSelector;

export const makeSelectOrderedContractType = () => orderedContactTypeSelector;

export const makeSelectOrderRef = () => orderRefSelector;

export const makeSelectOrderId = () => orderIdSelector;

export const makeSelectJumioRedirectUrl = () => jumioRedirectUrlSelector;
export const makeSelectJumioData = () => jumioDataSelector;
export const makeSelectOrderCancellationPayload = () =>
  orderCancellationPayloadSelector;

export const makeSelectReversePortinStatus = () =>
  createSelector(portinStatusSelector, portinStatus =>
    get(portinStatus, "reverse_port_in", false)
  );

export const selectedNumberTypeSelector = createSelector(
  selectedNumberTypeDomain,
  selectedNumberType => selectedNumberType
);

export const makeSelectSelectedNumberType = () => selectedNumberTypeSelector;

export const isGuestOrderAPIHaveDataSelector = createSelector(
  selectedManageOrder,
  userOrders => userOrders.length > 0
);

export const makeSelectIsGuestOrderAPIHaveData = () =>
  isGuestOrderAPIHaveDataSelector;

export const sadOrderParamsSelector = () =>
  createSelector(
    personalDetailsSelector,
    portInSelector,
    selectedNumberSelector,
    addonsSelector,
    selectedNumberTypeSelector,
    orderFromVerificationSelector,
    referralCodeSelector,
    planServiceSelector,
    multiSimOrderSelector,
    (
      personalDetails,
      portInDetails,
      selectedNumberDetails,
      addOns,
      selectedNumberType,
      order,
      referralCode,
      planService,
      multiSim
    ) => {
      const one_time_discount_benefits = filter(
        get(referralCode, "benefits"),
        benefit => includes(ONE_TIME_DISCOUNT_TYPES, benefit.type)
      );
      const one_time_discount_values = map(
        one_time_discount_benefits,
        benefit => get(benefit, "product.price.value", 0) / 100
      );
      const onetime_referral_code_discount_fee = sum(one_time_discount_values);
      return {
        order: {
          channel_name: get(
            ORDER_CHANNEL_MAPPING,
            getChannelFromUrl(),
            getChannelFromUrl()
          ),
          nric: get(personalDetails, "nric"),
          iccid: get(order, "iccid"),
          order_billing_detail_attributes: addressParams(
            personalDetails,
            "billing"
          ),
          selected_planid: get(planService, "sku"),
          refferal_code: get(referralCode, "value"),
          order_summary_attributes: {
            onetime_referral_code_discount_fee
          },
          order_items_attributes: filter(addOns, addon => addon.selected).map(
            addon => {
              return {
                sku: addon.id,
                price: addon.value,
                title: addon.label,
                name: addon.label,
                item_type: "ADDON",
                pay_monthly: addon.value,
                pay_now: 0,
                quantity: 1
              };
            }
          ),
          order_user_detail_attributes: {
            country_code: get(personalDetails, "country_code"),
            dob: get(personalDetails, "dob"),
            email: get(personalDetails, "email"),
            first_name: get(personalDetails, "full_name"),
            nationality: get(personalDetails, "nationality.value"),
            phone_number: get(personalDetails, "contact_number")
          },
          multi_sim_attributes: {
            multi_sim_order: multiSim
          },
          ...(selectedNumberType.isPortIn && {
            port_in_details: {
              port_auth_pending:
                get(portInDetails, "registered_under_own_nric_id") === "false",
              port_donor: get(portInDetails, "telco"),
              port_id_number: get(portInDetails, "post_paid_number")
            }
          }),
          selected_number_uniq_index: selectedNumberType.isPortIn
            ? get(portInDetails, "post_paid_number")
            : get(selectedNumberDetails, "number"),
          ...(!selectedNumberType.isPortIn && {
            selected_componentids: ""
          }),
          sim_count: get(personalDetails, "sim_count")
        },
        order_id: get(order, "order_ref")
      };
    }
  );

export const planSourceSelector = createSelector(genericSelector, generic =>
  get(generic, "plan_source_value", "")
);

export const comboPlanSourceSelector = createSelector(
  genericSelector,
  generic => get(generic, "combo_plan_source_value", "")
);

export const orderParamsSelector = orderChannel =>
  createSelector(
    planServiceSelector,
    selectedNumberTypeSelector,
    selectedNumberSelector,
    personalDetailsSelector,
    deliveryInfoSelector,
    portInSelector,
    referralCodeSelector,
    addonsSelector,
    defaultAddonsSelector,
    defaultInvisibleAddonsSelector,
    screenshotSelector,
    validateIccIdSelector,
    verifyIdentitySelector,
    simVerificationSelector,
    phonesFormSelector,
    walletSelector,
    connectionDetailsSelector,
    userDetailsSelector,
    selectAppSettings,
    preCheckoutSelector,
    selfActivationSelector,
    planSourceSelector,
    comboPlanSourceSelector,
    workflowSelector,
    simTypeSelector,
    contractTypeSelector,
    connectionDetailsNetworkTypeSelector,
    (
      planService,
      selectedNumberType,
      selectedNumber,
      personalDetailsForm,
      deliveryInfoForm,
      portIn,
      referralCode,
      addons,
      defaultAddons,
      defaultInvisibleAddons,
      screenshot,
      validateIccId,
      verifyIdentity,
      simVerification,
      phonesForm,
      wallet,
      connectionDetails,
      userDetails,
      appSettings,
      preCheckoutForm,
      selfActivationDeliveryForm,
      planSourceValue,
      comboPlanSourceValue,
      workflow,
      userSimType,
      numberType,
      networkType
    ) => {
      const channel = orderChannel || getChannelFromUrl();
      const selectedSimType = isEmpty(selfActivationDeliveryForm)
        ? preCheckoutForm
        : selfActivationDeliveryForm;
      const personalDetails =
        channel === SELF_ACT_DEL
          ? selfActivationDeliveryForm
          : personalDetailsForm;
      const deliveryInfo =
        channel === SELF_ACT_DEL
          ? selfActivationDeliveryForm
          : deliveryInfoForm;
      const id_upload_pending = get(personalDetails, "upload_id_later", false);
      const existing_account_number = get(personalDetails, "account_number");
      const portInScreenshotDocId = get(screenshot, "doc_id", "");
      const dvs_id = get(verifyIdentity, "dvs_id.$oid", "");
      let channel_name =
        channel === SELF_ACT_DEL
          ? SELF_ACT_DEL
          : get(selectedSimType, "sim_type") === SIM_TYPES.ESIM
          ? SIM_TYPES.ESIM
          : get(ORDER_CHANNEL_MAPPING, channel, channel);

      // Overide Channel to instant pickup if selected delivery method instant_pickup
      if (
        get(deliveryInfo, "delivery_method.action") ===
        DELIVERY_METHOD_TYPE.INSTANT_PICK_UP
      ) {
        channel_name = "instant_pickup";
      }
      const sim_type =
        userSimType || simType(selectedSimType) || SIM_TYPES.PHYSICAL_SIM;
      const number_type = selectedNumberType.isPortIn
        ? CONTRACT_TYPES.PORT_IN
        : numberType || CONTRACT_TYPES.NEW;
      const network_type = networkType;
      const dummy_document_data = get(
        appSettings,
        "generic.dummy_document_data",
        {}
      );

      const currentWorkflowPage = find(
        workflow,
        page => page.path === window.location.pathname
      );
      const pageData = get(currentWorkflowPage, "data", {});
      const billingAddressPrefix = get(pageData, "billing_address_prefix");

      let orderParams = {
        channel_name,
        ...(channel_name !== SIM_TYPES.ESIM && {
          sim_type,
          number_type,
          // network_type
          ...(featureFlag5GSAEnabled() && { network_type })
        }),
        msisdn_source: number_type,
        selected_number:
          channel === SELF_ACT_DEL ? null : get(selectedNumber, "number"),
        selected_planid: planService.sku,
        payment_type: "upfront",
        refferal_code: referralCode.value,
        id_upload_pending,
        existing_account_number,
        dvs_id,
        ...(getChannelFromUrl() === COMBO_CHANNEL && planService.sku
          ? {
              plan_source: comboPlanSourceValue
            }
          : {
              plan_source: planSourceValue
            }),
        ...(getChannelFromUrl() === PHONE_ONLY_CHANNEL && {
          plan_source: planSourceValue
        }),
        order_items: orderItems(addons, phonesForm),
        referral_detail: {
          referral_code: referralCode.value,
          is_valid: referralCode.valid
        },
        documents: {
          ...documentsParams({ ...personalDetails, ...dummy_document_data })
        },
        ...userDetailParams(personalDetails, deliveryInfo, userDetails),
        ...billingDetailParams(
          deliveryInfo,
          id_upload_pending,
          personalDetails,
          selectedSimType,
          channel,
          billingAddressPrefix
        ),
        ...orderSummaryParams({
          planService,
          deliveryInfo,
          personalDetails,
          referralCode,
          addons,
          defaultAddons,
          defaultInvisibleAddons,
          selectedNumber,
          phonesForm
        }),
        ...phoneParams(phonesForm, planService),
        order_wallet_payments_attributes: orderWalletPaymentsAttributes(
          phonesForm,
          wallet
        )
      };

      if (selectedNumberType.isPortIn) {
        orderParams = {
          ...orderParams,
          ...portInDetailParams(portIn, portInScreenshotDocId)
        };
      }

      if (!isEmpty(validateIccId)) {
        orderParams["iccid"] = get(validateIccId, "iccid", "");
        orderParams["selected_planid"] = get(
          validateIccId,
          "plan.id",
          planService.sku
        );
        orderParams["third_party_tenant"] = get(
          simVerification,
          "third_party_tenant.value",
          ""
        );
        orderParams["third_party_order_ref"] = get(
          simVerification,
          "third_party_order_ref",
          ""
        );
      }

      if (channel !== "sim_activation") {
        orderParams = {
          ...orderParams,
          ...deliverySlotParams(deliveryInfo, personalDetails),
          ...deliverDetailParams(deliveryInfo, personalDetails)
        };
      }

      const canCreateOrder =
        orderParams.selected_planid ||
        (orderParams.phone_only && orderParams.phone_sku);

      return canCreateOrder ? orderParams : {};
    }
  );

export const completeOrderParamsSelector = channel =>
  createSelector(
    orderParamsSelector(channel),
    userDetailsOrderUpdatePayloadSelector,
    (orderParams, userDetailsParams) => ({
      ...orderParams,
      ...userDetailsParams
    })
  );

export const portNumberSelector = createSelector(
  orderSelector,
  guestOrderSelector,
  (order, guestOrder) =>
    get(guestOrder, "port_number") || get(order, "port_number") || ""
);

export const makeSelectOrderParams = channel => orderParamsSelector(channel);
export const makeSelectCompleteOrderParams = channel =>
  completeOrderParamsSelector(channel);
export const makeSelectTotalPrepaidParams = () => prepaidTotalFeeSelector();

export const makeSelectSadOrderParams = () => sadOrderParamsSelector();

const simType = selectedSimType => {
  switch (get(selectedSimType, "sim_type")) {
    case "esim":
      return SIM_TYPES.E_SIM;
    case "physicalsim":
      return SIM_TYPES.PHYSICAL_SIM;
    default:
      return undefined;
  }
};

const billingDetailParams = (
  deliveryInfo,
  id_upload_pending,
  personalDetails,
  selectedSimType,
  channel,
  billingAddressPrefix
) => {
  // billing details may come from personal details page or channel is sim_activation
  const info = isEmpty(deliveryInfo) ? personalDetails : deliveryInfo;
  let prefix =
    get(info, "same_as_delivery_address", true) &&
    !id_upload_pending &&
    get(selectedSimType, "sim_type") !== SIM_TYPES.ESIM
      ? "delivery"
      : "billing";

  if (SIM_ACTIVATION_CHANNELS.includes(channel)) {
    prefix = "billing";
  }

  //override prefix with page data billing_address_prefix if defined in config mgr
  if (billingAddressPrefix) {
    prefix = billingAddressPrefix;
  }

  return {
    billing_detail: addressParams(info, prefix)
  };
};

const portInDetailParams = (portIn, portInScreenshotDocId) => {
  const selectedNumber = get(portIn, "post_paid_number");
  const is_postpaid = get(portIn, "port_in_existing_plan_type") === "postpaid";
  const account_number = get(portIn, "post_paid_telco_account_number");
  const port_donor = getValueOrField(get(portIn, "telco"), "value");
  const port_auth_pending =
    get(portIn, "registered_under_own_nric_id") === "false";

  return {
    selected_number: selectedNumber,
    portin_detail: {
      port_donor,
      port_number: selectedNumber,
      port_auth_pending,
      port_date: "",
      custom_port_in_date: "false",
      is_postpaid,
      port_telco_account_number: is_postpaid ? account_number : "",
      screenshotDocId: portInScreenshotDocId
    }
  };
};

const orderSummaryParams = ({
  planService,
  deliveryInfo,
  personalDetails,
  referralCode,
  addons,
  defaultAddons,
  defaultInvisibleAddons,
  selectedNumber,
  phonesForm
}) => {
  const phone_summary = phoneSummary(phonesForm);
  const {
    onetime_device_fee = 0,
    device_installment_month_fee = 0
  } = phone_summary;
  const subscription_plan_fee = get(planService, "basicPrice", 0);
  const subscription_addon_fee = sumBy(
    [
      ...defaultInvisibleAddons,
      ...defaultAddons,
      ...filter(addons, addon => addon.selected)
    ],
    "value"
  );
  const subscription_total_fee =
    subscription_plan_fee +
    subscription_addon_fee +
    device_installment_month_fee;
  const planSubType = get(planService, "sub_type", "");
  const prepaid_total_fee = PREPAID_PLAN_SUB_TYPES.includes(planSubType)
    ? subscription_total_fee
    : 0;
  const delivery_slot_fee =
    get(
      deliveryInfo,
      "delivery_slot_fee",
      get(personalDetails, "delivery_slot_fee")
    ) || 0;
  const onetime_sim_activation_fee = get(planService, "activation_charge", 0);
  const one_time_discount_benefits = filter(referralCode.benefits, benefit =>
    includes(ONE_TIME_DISCOUNT_TYPES, benefit.type)
  );
  const one_time_discount_values = map(
    one_time_discount_benefits,
    benefit => get(benefit, "product.price.value", 0) / 100
  );
  const onetime_referral_code_discount_fee = sum(one_time_discount_values);
  const onetime_number_fee = get(selectedNumber, "price", 0);

  const onetime_total_fee = max([
    0,
    delivery_slot_fee +
      prepaid_total_fee +
      onetime_sim_activation_fee +
      onetime_device_fee +
      onetime_number_fee -
      onetime_referral_code_discount_fee
  ]);

  return {
    order_summary: {
      subscription_plan_fee,
      subscription_addon_fee,
      subscription_total_fee,
      delivery_slot_fee,
      onetime_sim_activation_fee,
      onetime_number_fee,
      onetime_referral_code_discount_fee,
      onetime_total_fee,
      ...phone_summary
    }
  };
};

const orderItems = (addons, phonesForm) => {
  const items = filter(addons, addon => addon.selected).map(addon => {
    return { sku: addon.id, price: addon.value || 0, title: addon.label };
  });
  const phone = constructPhoneItem(phonesForm);
  if (phone) {
    items.push(phone);
  }
  return items;
};

export const phoneSummary = phonesForm => {
  const phone = constructPhoneItem(phonesForm);
  const summary = {};
  if (!isEmpty(phone)) {
    summary.phone_details = get(phone, "name");
    summary.phone_sku = get(phone, "sku");
    summary.phone_payment_option = get(phonesForm, "phonePaymentOption");
    summary.onetime_device_discount = 0; // Device discount applicable only for referral code
    summary.onetime_device_fee = get(phone, "pay_now", 0);
    summary.device_installment_month_fee = get(phone, "pay_monthly", 0);
    summary.device_installment_months = get(phone, "pay_monthly_months", 0);
    summary.device_installment_total_fee = get(phone, "pay_monthly_total", 0);
  }
  return summary;
};

const phoneParams = (phonesForm, planService) => {
  const phoneOnly =
    getChannelFromUrl() === PHONE_ONLY_CHANNEL ||
    (getChannelFromUrl() === COMBO_CHANNEL && !get(planService, "sku"));
  const phoneItem = constructPhoneItem(phonesForm);
  const device_installment = get(phoneItem, "pay_monthly", 0);
  const isIpp =
    device_installment > 0 ||
    get(phonesForm, "phonePaymentOption") === "installment";

  return {
    phone_only: phoneOnly,
    phone_sku: get(phoneItem, "sku"),
    payment_type: isIpp ? "ipp" : "upfront"
  };
};

const orderWalletPaymentsAttributes = (phonesForm, wallet) => {
  const phone = constructPhoneItem(phonesForm);
  const attributes = {};
  const discount = get(phone, "discount");
  if (discount > 0) {
    attributes.wallet_type = "PVD";
    attributes.amount_in_cents = discount * 100;
    attributes.status = "pending";
    attributes.customer_id = get(wallet, "customer_id", "");
  }
  return [attributes];
};

export const prepaidTotalFeeSelector = () =>
  createSelector(
    planServiceSelector,
    addonsSelector,
    defaultAddonsSelector,
    defaultInvisibleAddonsSelector,
    phonesFormSelector,
    (
      planService,
      addons,
      defaultAddons,
      defaultInvisibleAddons,
      phonesForm
    ) => {
      const phone_summary = phoneSummary(phonesForm);
      const { device_installment_month_fee = 0 } = phone_summary;
      const subscription_plan_fee = get(planService, "basicPrice", 0);
      const subscription_addon_fee = sumBy(
        [
          ...defaultInvisibleAddons,
          ...defaultAddons,
          ...filter(addons, addon => addon.selected)
        ],
        "value"
      );
      const subscription_total_fee =
        subscription_plan_fee +
        subscription_addon_fee +
        device_installment_month_fee;
      const planSubType = get(planService, "sub_type", "");
      return PREPAID_PLAN_SUB_TYPES.includes(planSubType)
        ? subscription_total_fee
        : 0;
    }
  );

export const hasUnprocessableEntityErrorSelector = createSelector(
  orderErrorDomain,
  ({ status }) => status === UNPROCESSABLE_ENTITY
);

export const makeSelectHasUnprocessableEntityError = () =>
  hasUnprocessableEntityErrorSelector;

export const makeSelectPlanType = () =>
  createSelector(preCheckoutSelector, formValues => {
    if (formValues.selected_plan_type) {
      return formValues.selected_plan_type;
    }
    return null;
  });

export const makeSelectIsSelectedPlanType = () =>
  createSelector(preCheckoutSelector, formValues => {
    return formValues.selected_plan_type ? true : false;
  });
