import { GTM_ID } from "utils/constants";
import { getDeviceId } from "utils/localStorage";
import TagManager from "react-gtm-module";
import { isAndroid, isIOS, isWebView } from "utils/helpers";
import { featureFlagGA4Enabled } from "utils/featureFlags";

export const GA4EVENTS = {
  PRE_CHECKOUT_PAGE_VIEW: "pre_checkout_page_view",
  PROMOCODE_APPLIED: "promo_code_applied",
  EMAIL_SUBMITTED: "email_submitted",
  EMAIL_AUTHENTICATED: "email_authenticated",
  USER_LOGGED_IN: "user_logged_in",
  TNC_CHECKED: "terms_and_conditions_click",
  PURCHASE_INITIATED: "purchase_initiated",
  ADD_PAYMENT_INFO: "add_payment_info",
  WEB_PURCHASE: "web_purchase",
  PERSONAL_DETAILS_FORM_START: "personal_details_form_start",
  PERSONAL_DETAILS_FORM_COMPLETE: "personal_details_form_complete",
  // DOB_SUBMITTED: "dob_submitted",
  CONTACT_NO_SUBMITTED: "contact_no_submitted",
  // APP_VERIFIED: "application_verified",
  SIM_ACTIVATION: "sim_activation",
  DELIVERY_DETAILS_FORM_START: "delivery_details_form_start",
  DELIVERY_DETAILS_FORM_COMPLETE: "delivery_details_form_complete",
  ADD_SHIPPING_INFO: "add_shipping_info",
  MNP_PHONE_NUMBER_SUBMITTED: "mnp_phone_number_submitted",
  TRANSFER_PHONE_NUMBER_SUBMITTED: "transfer_phone_no_submitted"
  // MOVE_ON_CLICK: "move_on_click",
  // RESIDENCE_DETAILS_FORM_START: "residence_details_form_start",
  // RESIDENCE_DETAILS_FORM_COMPLETE: "residence_details_form_complete",
  // POSTAL_CODE_SUBMITTED: "postal_code_submitted",
  // PREFECTURE_SUBMITTED: "prefecture_submitted",
  // MUNICIPALITY_SUBMITTED: "municipality_submitted",
  // HOUSE_NO_SUBMITTED: "house_no_submitted",
  // BUILDING_NAME_SUBMITTED: "building_name_submitted",
  // CONFIRMATION_CHOICE_SELECTED: "confirmation_choice_selected",
  // APP_SUBMITTED_FOR_VERIFICATION: "application_submitted_for_verification",
  // SEND_CONTRACT_BY_EMAIL: "send_contract_by_email",
  // ISSUE_ESIM_CLICK: "issue_esim_click",
  // REPLACE_AND_REISSUE_CLICK: "replacement_and_reissue_click",
  // CANCEL_CLICK: "cancel_click",
  // DELIVERY_PHONE_NO_SUBMITTED: "delivery_phone_no_submitted",
  // ACTIVATE_SIM_CARD_CLICK: "activate_sim_card_click",
  // SCAN_FOR_BARCODE_CLICK: "scan_for_barcode_click",
  // ENTER_CODE_MANUALLY_CLICK: "enter_code_manually_click",
  // LOGIN_WITH_AU_ID: "login_with_au_id",
  // LOGOUT_FROM_AU_ID: "logout_from_au_id",
  // DELIVERY_POSTAL_CODE_SUBMITTED: "delivery_postal_code_submitted",
  // DELIVERY_PREFECTURE_SUBMITTED: "delivery_prefecture_submitted",
  // DELIVERY_MUNICIPALITY_SUBMITTED: "delivery_municipality_submitted",
  // DELIVERY_HOUSE_NO_SUBMITTED: "delivery_house_no_submitted",
  // DELIVERY_BUILDING_NAME_SUBMITTED: "delivery_building_name_submitted",
}

const PLATFORMS = {
  WEB: "web",
  IOS: "ios",
  ANDROID: "android",
  IOS_WEB: "iosweb",
  ANDROID_WEB: "androidweb"
};

function GA4Events() {
  const getOrderPlatform = () => {
    if (isWebView()) {
      if (isAndroid()) return PLATFORMS.ANDROID;
      if (isIOS()) return PLATFORMS.IOS;
    } else {
      if (isAndroid()) return PLATFORMS.ANDROID_WEB;
      if (isIOS()) return PLATFORMS.IOS_WEB;
    }

    return PLATFORMS.WEB;
  };

  const sendEvent = (e = {}) => {
    try {
      const ECOMMERCE_GENERIC = {
        customer_id: getDeviceId(),
        order_platform: getOrderPlatform()
      };

      const { event, ecommerce } = e;
      const ecommerceData = {
        ...ECOMMERCE_GENERIC,
        ...ecommerce
      };

      if (event) {
        const tagManagerArgs = {
          gtmId: GTM_ID,
          dataLayer: { event, ecommerce: ecommerceData }
        };

        if (featureFlagGA4Enabled()) {
          console.log("GA4 data", tagManagerArgs.dataLayer);
          // push to Google Tag Manager
          TagManager.initialize({
            gtmId: GTM_ID,
            dataLayer: { ecommerce: null }
          });
          TagManager.initialize(tagManagerArgs);
        }
      } else {
        console.error("Missing required param for GA4. event: ", event);
      }
    } catch (e) {
      console.error("Error in building GA4 event object.", e);
    }
  };

  return { publish: sendEvent };
}

export default GA4Events();
