import React, { useEffect } from "react";
import get from "lodash/get";
import find from "lodash/find";
import split from "lodash/split";
import last from "lodash/last";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import DeliveryAddressForm4 from "components/DeliveryAddress/Form4";
import { mapAddressComponentOption, getValueOrLabel } from "utils/helpers";

const DELIVERY_ADDRESS_FORM_4 = ({
  addressComponentMappings,
  findAddressLookup,
  change,
  formValues,
  fieldPrefix = "delivery",
  generic,
  postalCodeValidation,
  disable_address_form,
  setPostalCodeValidation = () => {}
}) => {
  const { states, allCities, allDistricts, villages, address_lookups } =
    addressComponentMappings || {};

  const statesCount = get(states, "length", 0);
  useEffect(() => {
    if (!statesCount) {
      findAddressLookup && findAddressLookup({});
    } else {
      const zipCode = get(formValues, `${fieldPrefix}_zip_code`);
      change(`${fieldPrefix}_street_name`, null);
      change(`${fieldPrefix}_building_name`, null);
      zipCode && onZipCodeChange({ target: { value: zipCode } }, formValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statesCount]);

  const selectedDeliveryMethod = get(formValues, "delivery_method", null);
  if (!selectedDeliveryMethod) return null;
  const fields = get(selectedDeliveryMethod, "fields");
  const selected_state_id = get(formValues, `${fieldPrefix}_state.value`);
  const selected_city_id = get(formValues, `${fieldPrefix}_city.value`);
  const selected_district_id = get(formValues, `${fieldPrefix}_district.value`);

  const resetFields = (fields = []) => {
    for (const field of fields) {
      change(`${fieldPrefix}_${field}`, null);
    }
    if (includes(fields, "zip_code")) {
      setPostalCodeValidation(`${fieldPrefix}_zip_code`, { status: false });
    }
  };

  const onStateChange = ({ value } = {}) => {
    resetFields(["zip_code", "village", "district", "city"]);
    findAddressLookup({ state_id: value });
  };

  const onCityChange = ({ value } = {}) => {
    resetFields(["zip_code", "village", "district"]);
    findAddressLookup({ city_id: value, state_id: selected_state_id });
  };

  const onDistrictChange = ({ value } = {}) => {
    resetFields(["zip_code", "village"]);
    findAddressLookup({
      district_id: value,
      city_id: selected_city_id,
      state_id: selected_state_id
    });
  };

  const onVillageChange = ({ value } = {}) => {
    const query = {
      village_id: value,
      district_id: selected_district_id,
      city_id: selected_city_id,
      state_id: selected_state_id
    };
    const { code } = find(address_lookups, query) || {};
    change(`${fieldPrefix}_zip_code`, code);
    setPostalCodeValidation(`${fieldPrefix}_zip_code`, {
      status: !isEmpty(code)
    });
  };

  const onZipCodeChange = (zipCode, prefill) => {
    const code = get(zipCode, "target.value");
    const error_message = get(generic, "zip_code.error_msg");
    const regex = get(generic, "zip_code.validation_regex");
    const maxLength = get(generic, "zip_code.max_length", 1);
    const codeValid =
      code && (regex ? code.match(regex) : code.length === maxLength);
    if (codeValid) {
      const query = { code };
      setPostalCodeValidation(
        `${fieldPrefix}_zip_code`,
        { status: false },
        true
      );
      findAddressLookup(query, (data, _) => {
        const { address_lookups, cities, districts, villages } = data;
        const foundLookup = find(address_lookups, query) || {};
        if (!isEmpty(foundLookup)) {
          const { state_id, city_id, district_id, village_id } = foundLookup;

          const villageName =
            getValueOrLabel(get(prefill, `${fieldPrefix}_village`)) ||
            last(split(get(prefill, "street_building_name"), ", "));
          const villageSelector = villageName
            ? { name: villageName }
            : { id: village_id };
          const village = mapAddressComponentOption(
            find(villages, villageSelector)
          );

          const districtName = getValueOrLabel(
            get(prefill, `${fieldPrefix}_district`)
          );
          const districtSelector = districtName
            ? { name: districtName }
            : { id: district_id };
          const district = mapAddressComponentOption(
            find(districts, districtSelector)
          );

          const cityName = getValueOrLabel(get(prefill, `${fieldPrefix}_city`));
          const citySelector = cityName ? { name: cityName } : { id: city_id };
          const city = mapAddressComponentOption(find(cities, citySelector));

          const stateName = getValueOrLabel(
            get(prefill, `${fieldPrefix}_state`)
          );
          const stateSelector = stateName
            ? { label: stateName }
            : { value: state_id };
          const state = find(states, stateSelector);

          !isEmpty(village) && change(`${fieldPrefix}_village`, village);
          !isEmpty(district) && change(`${fieldPrefix}_district`, district);
          !isEmpty(city) && change(`${fieldPrefix}_city`, city);
          !isEmpty(state) && change(`${fieldPrefix}_state`, state);
        }
        setPostalCodeValidation(`${fieldPrefix}_zip_code`, {
          status: !isEmpty(foundLookup)
        });
      });
    } else {
      resetFields(["village", "district", "city", "state"]);
      setPostalCodeValidation(`${fieldPrefix}_zip_code`, {
        status: false,
        message: error_message
      });
    }
  };

  return (
    <DeliveryAddressForm4
      fields={fields}
      fieldPrefix={fieldPrefix}
      formValues={formValues}
      stateOptions={states}
      cityOptions={allCities}
      districtOptions={allDistricts}
      villageOptions={villages}
      onZipCodeChange={onZipCodeChange}
      onStateChange={onStateChange}
      onCityChange={onCityChange}
      onDistrictChange={onDistrictChange}
      onVillageChange={onVillageChange}
      generic={generic}
      disable={disable_address_form}
      postalCodeValidation={postalCodeValidation}
    />
  );
};

export default DELIVERY_ADDRESS_FORM_4;
