import { createSelector } from "reselect";
import get from "lodash/get";

const surveyDomain = state => get(state.api, "survey.data", {});

export const surveySelector = createSelector(surveyDomain, survey => {
  return survey;
});

export const makeSelectSurvey = () =>
  createSelector(surveySelector, survey => survey);
