import React from "react";
import { Flex } from "ui-components";
import FormattedMessage from "components/FormattedMessage";

const MaskedCreditCardNumber = ({
  gaps,
  label,
  cardNumber,
  maskCharacter = "X",
  separator = " ",
  fontWeight = 600
}) => {
  // gaps are coming as [4, 4, 4, 4] format from config.
  // We are adding all gap separated digits to figure out the total length of the card.
  const max = gaps.reduce((prev, number) => prev + number, 0);

  // Create a string, relevant length with mask character.
  const mask = Array(max - cardNumber.toString().length)
    .fill(maskCharacter, 0, max - cardNumber.toString().length)
    .join("");

  const unformattedNumber = `${mask}${cardNumber}`;

  // Add separator according to gaps pattern.
  let prevIndex = 0;
  const formattedNumber = gaps
    .map(gap => {
      const str = unformattedNumber.slice(prevIndex, gap + prevIndex);
      prevIndex = prevIndex + gap;
      return str;
    })
    .join(separator);

  return (
    <Flex flexDirection="column" alignItems="flex-end" pl=".5rem" pt=".1rem">
      <Flex fontSize="18px" fontWeight={fontWeight}>
        {formattedNumber}
      </Flex>
      {label && (
        <Flex marginTop="10px" fontSize="14px">
          <FormattedMessage id={label} />
        </Flex>
      )}
    </Flex>
  );
};

export default MaskedCreditCardNumber;
