import React from "react";
import { Box } from "ui-components";
import NextStepsCard from "components/NextStepsCard";

const NEXT_STEPS_CARD = props => {
  const { data, themeColors } = props;
  return (
    <Box mb={6}>
      <NextStepsCard {...data} themeColors={themeColors} />
    </Box>
  );
};

export default NEXT_STEPS_CARD;
