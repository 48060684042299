import { createSelector } from "reselect";
import { orderDetailsSelector } from "ducks/selectors";
import get from "lodash/get";
import find from "lodash/find";
import { userSINSelector } from "../authInfo";
import { formValuesSelector } from "ducks/selectors";
import { forms } from "utils/constants";

// ***************************************************************
// ************** TerminationForm selectors ******************
// ***************************************************************

export const terminationSelector = formValuesSelector(
  forms.TERMINATE_NUMBER_FORM
);

const postTerminateRequestDomain = state =>
  get(state, "api.postTerminateNumberRequest.data", null);

// ***************************************************************
// ************** TerminationForm makeSelect *****************
// ***************************************************************

export const makeSelectTermination = () =>
  createSelector(
    terminationSelector,
    orderDetailsSelector,
    (termination, orderDetails) => {
      const actionType = "TERMINATE";
      const terminationReason = get(termination, "termination_reason.value");
      const terminationDate = get(termination, "termination_date.value");
      const terminationFeedback = get(termination, "termination_feedback", "");
      const winbackId = get(termination, "termination_reason.winbackId");
      const winbacks = get(orderDetails, "winback", []);
      const selectedWinback = find(winbacks, ["id", winbackId]);
      const winbackCode = get(selectedWinback, "value", "");
      const winbackOffer = get(selectedWinback, "description", "");
      return {
        actionType,
        terminationReason,
        terminationDate,
        terminationFeedback,
        winbackId,
        winbackCode,
        winbackOffer,
        selectedWinback,
        ...termination
      };
    }
  );

export const makeSelectTerminationPayLoad = () =>
  createSelector(
    userSINSelector,
    terminationSelector,
    (accountNumber, terminationForm) => {
      const { termination_reason, other_reason, termination_feedback } =
        terminationForm || {};
      const terminationReason =
        termination_reason?.value === "other"
          ? other_reason
          : termination_reason?.label;
      return {
        accountNumber,
        statusType: "TERMINATE_BY_CUSTOMER",
        reason: terminationReason || "I want to go",
        feedback: termination_feedback || ""
      };
    }
  );

const isTerminatedNumber = createSelector(
  postTerminateRequestDomain,
  terminationDetails => {
    return terminationDetails !== null;
  }
);

export const makeSelectIsTerminatedNumber = () => isTerminatedNumber;
