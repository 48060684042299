import { createSelector } from "reselect";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import map from "lodash/map";
import filter from "lodash/filter";
import includes from "lodash/includes";
import omitBy from "lodash/omitBy";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import { deliveryInfoSelector } from "ducks/selectors";
import { mapAddressComponentOption } from "utils/helpers";
import { personalDetailsSelector } from "./forms/personalDetails";

const addressComponentMappingsDomain = state =>
  get(state, "api.addressComponentMappings.data", []);

export const addressComponentMappingsSelector = createSelector(
  addressComponentMappingsDomain,
  addressComponentMappings => addressComponentMappings
);

const filterItemsForAddressLookup = (
  address_lookups,
  filterOptions,
  idField,
  items
) => {
  const validFilterOptions = omitBy(filterOptions, isNil);
  const filteredAddressLookups = filter(address_lookups, validFilterOptions);
  const filteredIds = map(filteredAddressLookups, idField);
  return filter(items, item => includes(filteredIds, item.id));
};

export const makeSelectAddressComponentMappings = () =>
  createSelector(
    addressComponentMappingsSelector,
    deliveryInfoSelector,
    personalDetailsSelector,
    (addressComponentMappings, deliveryInfo, personalDetails) => {
      const formValues = !isEmpty(deliveryInfo)
        ? deliveryInfo
        : personalDetails;
      const state_id = get(formValues, "delivery_state.value");
      const city_id = get(formValues, "delivery_city.value");
      const district_id = get(formValues, "delivery_district.value");

      const {
        states,
        cities,
        districts,
        villages,
        address_lookups
      } = addressComponentMappings;

      const citiesByState = state_id
        ? filterItemsForAddressLookup(
            address_lookups,
            { state_id },
            "city_id",
            cities
          )
        : [];

      const districtsByCity = city_id
        ? filterItemsForAddressLookup(
            address_lookups,
            { state_id, city_id },
            "district_id",
            districts
          )
        : [];

      const villagesByDistrict = district_id
        ? filterItemsForAddressLookup(
            address_lookups,
            { state_id, city_id, district_id },
            "village_id",
            villages
          )
        : [];

      return {
        states: map(orderBy(states, ["id"], ["asc"]), state =>
          mapAddressComponentOption(state)
        ),
        cities: map(orderBy(citiesByState, ["id"], ["asc"]), city =>
          mapAddressComponentOption(city)
        ),
        districts: map(orderBy(districtsByCity, ["id"], ["asc"]), district =>
          mapAddressComponentOption(district)
        ),
        villages: map(orderBy(villagesByDistrict, ["id"], ["asc"]), village =>
          mapAddressComponentOption(village)
        ),
        allCities: map(orderBy(cities, ["id"], ["asc"]), city =>
          mapAddressComponentOption(city)
        ),
        allDistricts: map(orderBy(districts, ["id"], ["asc"]), district =>
          mapAddressComponentOption(district)
        ),
        allVillages: map(orderBy(villages, ["id"], ["asc"]), village =>
          mapAddressComponentOption(village)
        ),
        address_lookups
      };
    }
  );
