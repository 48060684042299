import { createSelector } from "reselect";
import get from "lodash/get";

const addressLookupDomain = state => get(state, "api.addressLookup.data", []);

export const addressLookupSelector = createSelector(
  addressLookupDomain,
  addressLookup => addressLookup
);

export const makeSelectPostalCodeOptions = () =>
  createSelector(addressLookupSelector, addressLookup => {
    return addressLookup.map(({ city, state, postal_code }) => {
      const label = `${city} ${state} ${postal_code}`;
      const value = `${city}-${state}-${postal_code}`;
      return { label, value };
    });
  });
