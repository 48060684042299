import React, { useEffect } from "react";
import get from "lodash/get";
import queryString from "query-string";
import useLoadAmazonScript from "hooks/useLoadAmazonSript";
import { Box } from "ui-components";
import Alert from "react-s-alert";
import isString from "lodash/isString";

const AmazonWalletWidget = ({ styles, generic, onSelectPaymentCard }) => {
  let configData = get(generic, "payment_methods.amazonPay");
  const { js_sdk_url, seller_id, client_id, enable } = configData;
  const queryParams = queryString.parse(window.location.search);
  const { accessToken } = queryParams;
  let amazonPayOrderRefId = null;

  const { isScriptLoaded } = useLoadAmazonScript({ js_sdk_url, client_id });

  //Load the wallet widget
  const showWalletWidget = () => {
    // Wallet
    new window.OffAmazonPayments.Widgets.Wallet({
      sellerId: seller_id,
      onReady: orderReference => {
        amazonPayOrderRefId = orderReference?.getAmazonOrderReferenceId();
      },
      onPaymentSelect: values => {
        onSelectPaymentCard(amazonPayOrderRefId);
      },
      design: { designMode: "responsive" },
      onError: error => {
        Alert.error(error.getErrorMessage());
      }
    }).bind("walletWidgetDiv");
  };

  useEffect(() => {
    if (isScriptLoaded) {
      showWalletWidget();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScriptLoaded]);

  useEffect(() => {
    if (isString(accessToken) && accessToken.match(/^Atza/)) {
      document.cookie = `amazon_Login_accessToken=${accessToken};path=/;secure`;
    }
  }, [accessToken]);

  return !enable ? null : (
    <Box id="walletWidgetDiv" sx={styles}>
      Loading
    </Box>
  );
};
export default AmazonWalletWidget;
