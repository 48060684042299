import styled from "@emotion/styled";
import { Link as RebassLink } from "rebass";

const Link = styled(RebassLink)`
  cursor: pointer;
  text-decoration: none;
`;

const StyledLink = styled(RebassLink)`
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  color: ${({ disabled, color = "#3333FF", disabledColor = "#c7c7c7cc" }) =>
    disabled ? disabledColor : color};
`;
StyledLink.defaultProps = {
  cursor: "pointer",
  padding: "12px",
  color: "#3333FF"
};

export { StyledLink };
export default Link;
