import { memo } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { reduxForm } from "redux-form";
import SmartRender from "containers/SmartRender";
import validate from "validations";
import {
  makeSelectFormValues,
  makeSelectReportLostSimPayload,
  makeSelectPageValidations,
  makeSelectReportLostFormInitialValues,
  makeSelectSIMReplacementReasons,
  makeSelectIsSimReplacementSuspendableReason
} from "ducks/selectors";
import { getDOBSecurityHeader, gotoUrl } from "utils/helpers";
import { paths, forms } from "utils/constants";
import { submitReplaceSimToTelco } from "ducks/actions";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { activateZendeskWidget } from "utils/helpers";

const mapStateToProps = (state, props) =>
  createStructuredSelector({
    formValues: makeSelectFormValues(forms.REPORT_LOST_SIM_FORM),
    payload: makeSelectReportLostSimPayload(),
    formValidations: makeSelectPageValidations(forms.REPORT_LOST_SIM_FORM),
    messageData: () => ({
      phoneNumber: get(props, "orderDetails.phone_number"),
      simReplacementFee: get(props, "orderDetails.sim_replacement_fee"),
      address: get(props, "orderDetails.address"),
      deliveryDate: get(props, "orderDetails.delivery_date"),
      deliverySlot: get(props, "orderDetails.delivery_slot"),
      stateMessage: get(props, "orderDetails.state_message"),
      instantPickupStartDateTime: get(
        props,
        "orderDetails.instant_pickup_start_date_time"
      ),
      postOfficeName: get(props, "orderDetails.post_office_name")
    }),
    initialValues: makeSelectReportLostFormInitialValues(),
    reasons: makeSelectSIMReplacementReasons(),
    isSuspendableReason: makeSelectIsSimReplacementSuspendableReason()
  });

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmitSimConfigToTelco: (headers, payload, callback) => {
      dispatch(submitReplaceSimToTelco(headers, payload, callback));
    },
    openLiveChat: () => {
      activateZendeskWidget(ownProps.zEIdentify);
    }
  };
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const submitSimConfigToTelco = (navigate, payload, override, data) => {
    const headers = getDOBSecurityHeader(stateProps?.formValues?.dob);
    dispatchProps.onSubmitSimConfigToTelco(
      headers,
      stateProps.payload,
      (_, error, status) => {
        if (isEmpty(error) || status === 409) {
          if (get(data, "next.path")) {
            navigate();
          } else {
            gotoUrl(
              paths.MANAGE_REPLACE_SIM_STATUS.replace(":status", "submit")
            );
          }
        } else {
          console.error("Error on submitReplaceSim callback", error);
        }
      }
    );
  };

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    submitSimConfigToTelco
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.REPORT_LOST_SIM_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(SmartRender);
