import React from "react";
import { default as ReactAlert } from "react-s-alert";
import "./styles.css";

const Alert = props => {
  return <ReactAlert {...props} />;
};

export const showAlert = ReactAlert;
export default Alert;
