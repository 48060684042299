import { call, put, all, takeEvery, select } from "redux-saga/effects";
import request from "utils/request";
import {
  fetchRequested,
  fetchSucceeded,
  fetchFailed,
  userLogout
} from "ducks/actions";
import {
  BASE,
  USER_LOGOUT_PATH,
  USER_SERVICE_REFRESH_TOKEN
} from "utils/constants";
import Alert from "react-s-alert";
import rollbar from "services/rollbar";
import { handleAnalytics } from "analytics";
import {
  authTokenSelector,
  whiteListedErrorCodes,
  externalUserIdSelector,
  loginEmailSelector,
  makeSelectIsFetchingData,
  userEmailSelector,
  userSINSelector,
  orderRefSelector
} from "ducks/selectors";
import refreshToken from "./refreshTokenSaga";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import isPlainObject from "lodash/isPlainObject";
import fetchMedia from "./fetchMediaSaga";
import {
  api,
  isTokenInvalidError,
  isTokenRefreshRequired,
  validateSession,
  isWhiteListedError,
  getErrorMessageForCode,
  logoutUserCallback,
  isRetryableRecaptchaError
} from "./utils";
import { getDeviceId } from "utils/localStorage";
import { setNewRelicAttribute } from "utils/helpers";

const TOKEN_REFRESH_MAX_RETRY_ATTEMPTS = 1;
const RECAPTCHA_TOKEN_MAX_RETRY_ATTEMPTS = 2;

export function* fetch({
  payload,
  retryAttempt = 0,
  retryRecaptchaAttempt = 0
}) {
  const {
    base: BASE_TYPE = BASE.OMS,
    key,
    path,
    headers,
    params,
    method,
    callback,
    captcha,
    override,
    replace,
    mediaStream = false,
    base64conversion,
    successData = {},
    version,
    meta: metaData = {},
    whitelistedCodes = []
  } = payload;

  // Get auth token from store if available
  const authToken = yield select(authTokenSelector);
  const loginEmail = yield select(loginEmailSelector);
  const userEmail = yield select(userEmailSelector);
  const email = userEmail || loginEmail;
  const orderRef = yield select(orderRefSelector);
  const userSIN = yield select(userSINSelector);
  const externalUserId = yield select(externalUserIdSelector);
  const whitelistedErrorCodesFromConfig = yield select(whiteListedErrorCodes);
  const whitelistedErrorCodesList = [
    ...whitelistedErrorCodesFromConfig,
    ...whitelistedCodes
  ];

  const { requestURL, requestWithCaptcha, options } = api()
    .baseFor(BASE_TYPE)
    .apiVersionFor(BASE_TYPE, version)
    .apiOptions(method, path, headers, params, authToken, metaData)
    .build();

  // Handle mediastream type API requests
  if (mediaStream) {
    return yield call(
      fetchMedia,
      key,
      override,
      replace,
      requestURL,
      options,
      callback,
      base64conversion
    );
  }

  try {
    setNewRelicAttribute("userID", externalUserId);
    setNewRelicAttribute("orderRef", orderRef);
    setNewRelicAttribute("sin", userSIN);
    // can be used to extract email when email is unknown - but userId is available in newrelic results
    setNewRelicAttribute("registrationId", email, "rsa");
    // can be used to query when email is known
    setNewRelicAttribute("registrationIdHash", email, "hash");

    // if recaptcha is enabled for the API it will be executed with recaptcha token
    const makeRequest = captcha ? requestWithCaptcha : request;
    const response = yield call(
      fetchResponse,
      makeRequest,
      requestURL,
      options,
      payload,
      retryAttempt,
      retryRecaptchaAttempt
    );

    // this is a small hack to ignore the failed fetchResponses due to token expiry
    if (!response) return;

    const fetchError = get(response, "error");
    const { success, result, code } = response || {};

    if (success || code === 0 || !fetchError) {
      let data = result || response || {};

      if (callback) callback(result || response);

      // merge successData only for plainObject response to avoid converting array to object
      if (isPlainObject(data)) {
        data = { ...data, ...successData };
      }

      yield put(fetchSucceeded({ key, data, override, replace }));
    } else {
      let { error, errorReason, status, statusText, failure } = response || {};
      // Message obtained from result. This takes the highest priority
      const resultMessage = get(result, "message");
      // Error message obtained from error response
      const apiFailureMessage = yield getErrorMessageForCode(failure || error);
      const errorMessage =
        resultMessage || apiFailureMessage || error?.message || error;

      /**
       * Checks if the error code is whitelisted, because
       * at some cases it was needed to not to show error banner for some error codes.
       * The unique error codes are maintained in configuration manager.
       * However, there are situations we need to whitelist a HTTP error code just for one action. For this,
       * the action itself can provide error codes to whitelist.
       * (Provided via whitelistedCodes attribute from the action)
       */
      const showErrorBanner = !isWhiteListedError(
        whitelistedErrorCodesList,
        result || { code: Number(code || status) }
      );

      if (isEmpty(error)) {
        error = errorMessage && [errorMessage];
      }

      if (showErrorBanner) {
        Alert.error(errorMessage || error);
      }

      if (callback)
        callback(result || {}, failure || error, status, statusText);
      yield put(fetchFailed({ key, error, errorReason }));
    }
  } catch (err) {
    const errorMessage = err?.message || err;
    const errorBody = err?.response || {};

    callback && callback({}, errorMessage, errorBody);

    const showErrorBanner = !isWhiteListedError(
      whitelistedErrorCodesList,
      errorBody
    );

    if (showErrorBanner && errorMessage) {
      Alert.error(errorMessage);
      rollbar.error(errorMessage);
    }

    yield put(fetchFailed({ key, error: errorBody }));
  }
}

/**
 * Fetches the response. If an exception occured, it will
 * build up an error object and return as response
 * @param {Function} makeRequest request function type
 * @param {string} requestURL request url
 * @param {object} options request options
 * @param {object} payload request payload
 */
export function* fetchResponse(
  makeRequest,
  requestURL,
  options,
  payload,
  retryAttempt,
  retryRecaptchaAttempt
) {
  let response = {};
  try {
    response = yield call(makeRequest, requestURL, options);
  } catch (err) {
    if (isRetryableRecaptchaError(err)) {
      const maxRetryRecaptchaReached =
        retryRecaptchaAttempt >= RECAPTCHA_TOKEN_MAX_RETRY_ATTEMPTS;
      if (!maxRetryRecaptchaReached) {
        return yield call(fetch, {
          payload: payload,
          retryAttempt: retryAttempt,
          retryRecaptchaAttempt: ++retryRecaptchaAttempt
        });
      }
    } else if (
      isTokenRefreshRequired(err) &&
      !requestURL.includes(USER_LOGOUT_PATH)
    ) {
      // Refresh token handling
      const maxRetryReached = retryAttempt >= TOKEN_REFRESH_MAX_RETRY_ATTEMPTS;
      if (maxRetryReached && requestURL.includes(USER_SERVICE_REFRESH_TOKEN)) {
        yield logoutUser();
        console.error(
          "Refresh token handling didnt succeed after max retry attempts. Redirecting to login page"
        );
      } else {
        const authHeader = "X-AUTH";
        const requestedToken = get(options, `headers.${authHeader}`);
        // expiredToken will have updated value if token was refreshed while the original request was in progress with requestedToken
        // we shouldn't use requestedToken for refresh, as it will return 401/403 in this case
        // use requestedToken only to compare with the newToken and retry the api call
        const expiredToken = yield select(authTokenSelector);
        yield call(
          refreshToken,
          expiredToken,
          ++retryAttempt,
          retryRecaptchaAttempt,
          requestURL
        );

        const newToken = yield select(authTokenSelector);
        console.log(
          new Date(),
          " : refreshToken(): Resume after successful refresh.",
          requestURL
        );

        // Some APIs can provide x-auth token as part of payload headers. If that token is invalid;
        // we do not want to resend the same bad token. Therefore we do a x-auth header cleanup before making the next retry
        const clonedHeaders = {
          ...payload?.headers
        };
        // telling API builder to fill the x-auth with the latest available
        delete clonedHeaders[authHeader];
        const updatedPayload = {
          ...payload,
          headers: clonedHeaders
        };

        // this will basically avoid calling the same api again if token has not changed
        if (newToken && newToken !== requestedToken) {
          return yield call(fetch, {
            payload: updatedPayload,
            retryAttempt: ++retryAttempt,
            retryRecaptchaAttempt
          });
        }
      }
    }

    // Refresh token error handling (Can happen due to: Invalid/Incorrect auth-token)
    if (isTokenInvalidError(err)) {
      const currentAuthToken = yield select(authTokenSelector);

      // Retry logout only if authToken is available.
      // If the failure is from logout API, we do not want to try logout api again.
      if (currentAuthToken && !requestURL.includes(USER_LOGOUT_PATH)) {
        yield logoutUser();
      } else {
        // wait for 2secs before navigate to login and reload the page.
        setTimeout(logoutUserCallback, 2000);
      }
    }

    const errorData = err?.response || {};

    response = {
      ...errorData,
      error: errorData.message || errorData?.error || err,
      errorReason: errorData.message_code,
      status: err.status,
      statusText: err.statusText
    };
  }

  return response;
}

export function* logoutUser() {
  const headers = {};
  const externalId = yield select(externalUserIdSelector);
  const params = {
    device_id: getDeviceId(),
    user_id: externalId
  };

  const logoutInProgress = yield select(makeSelectIsFetchingData("userLogout"));
  if (!logoutInProgress) {
    const FORCE_LOGOUT_MESSAGE = yield getErrorMessageForCode({
      title: "logout_message"
    });
    Alert.error(FORCE_LOGOUT_MESSAGE);
    return yield put(userLogout(headers, params, logoutUserCallback));
  }
}

export default function* rootSaga() {
  yield takeEvery("*", handleAnalytics);
  yield takeEvery("*", validateSession);
  yield takeEvery("REFRESH_TOKEN", refreshToken);
  yield all([takeEvery(fetchRequested, fetch)]);
}
