import React, { useEffect } from "react";
import get from "lodash/get";
import PaymentBox from "components/PaymentBox";

const PAYMENT_BOX = ({
  isCreatingOrder,
  isFetchingPaymentRequest,
  order,
  paymentRequest,
  fetchPaymentRequest,
  token
}) => {
  useEffect(() => {
    const order_ref = get(order, "order_ref");
    const payment_ref = get(paymentRequest, "order_ref");
    if (order_ref && order_ref !== payment_ref && !isFetchingPaymentRequest) {
      fetchPaymentRequest({ order_id: order_ref, token });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, paymentRequest, isFetchingPaymentRequest]);
  const payment = get(paymentRequest, "form_data", {});

  return <PaymentBox payment={payment} />;
};

export default PAYMENT_BOX;
