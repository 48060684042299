import React from "react";
import get from "lodash/get";
import find from "lodash/find";

import { PREPAID_PLAN_SUB_TYPES } from "utils/constants";
import { Box, Text, P } from "ui-components";
import { iconThemes, COLORS } from "ui-components/theme";

import Banner from "components/Banner";
import Addons from "components/Addons";
import PlanDetails from "components/PlanDetails";
import PlanPrice from "components/PlanPrice";
import PromoCodeBenefits from "components/PromoCodeBenefits";
import FormattedMessage from "components/FormattedMessage";
import { featureFlagDataOnlyPlanEnabled } from "utils/featureFlags";

const ExtendedPlanDetailsCard = ({
  planDetails,
  payTodayItems,
  payMonthlyItems,
  data = {},
  themeColors,
  appSettings,
  addons,
  defaultPlanAddons,
  referralCode
}) => {
  const planName = get(
    planDetails,
    "name",
    featureFlagDataOnlyPlanEnabled() ? "povo2.0" : ""
  );
  const planSubType = get(planDetails, "sub_type", "");
  const productLink = get(planDetails, "product_link");
  const isPrepaid = PREPAID_PLAN_SUB_TYPES.includes(planSubType);
  const paySummaryItems = isPrepaid ? payTodayItems : payMonthlyItems;
  const planItem = find(get(paySummaryItems, "items"), { type: "plan" });
  const showSummary = get(data, "showSummary");
  const excludeTax = get(data, "excludeTax");
  const defaultAddonsTitle = get(
    data,
    "defaultAddonsTitle",
    "default_addons_title"
  );
  const planPrice = excludeTax
    ? get(planItem, "price_without_tax")
    : get(planItem, "price");
  const totalPrice = excludeTax
    ? get(paySummaryItems, "totalPriceWithoutTax")
    : get(paySummaryItems, "totalPrice");
  const planMessage = get(data, "message", "");
  const fitPillContent = get(data, "fitPillContent", false);
  const hidePlanPrice = get(data, "hidePlanPrice", false);
  const description = get(data, "description", "");
  const planColors = get(data, ["planColors", planName]) || {};
  const primaryColor =
    get(iconThemes, [planColors.primary]) ||
    get(themeColors, "primary") ||
    iconThemes.blue;
  const priceColor =
    get(iconThemes, [planColors.price], planColors.price) ||
    get(data, "priceColor", COLORS.white);
  const tertiaryColor =
    get(iconThemes, [planColors.tertiary]) ||
    get(themeColors, "tertiary") ||
    iconThemes.cyan;
  const iconColor =
    get(iconThemes, [planColors.icon], planColors.icon) ||
    get(themeColors, "icon") ||
    iconThemes.blue;
  const totalData = get(paySummaryItems, "totalData");
  const activePromoCode = get(referralCode, "valid")
    ? get(referralCode, "value")
    : "";
  const hidePlanDetails = data?.hidePlanDetails;
  const planDescription = data?.planDescription?.[planName];

  return (
    <Box
      variant="borderSection"
      mb={8}
      px={[0, 0]}
      py={[2, 2]}
      sx={{
        borderWidth: "6px",
        color: primaryColor,
        borderColor: primaryColor
      }}
    >
      <Banner
        types={["promoBanners", "dataBanners", "planBanners"]}
        typeValues={{
          promoBanners: activePromoCode,
          dataBanners: totalData,
          planBanners: planName
        }}
        data={data}
        appSettings={appSettings}
      />
      {!hidePlanDetails && (
        <PlanDetails
          planDetails={planDetails}
          items={get(paySummaryItems, "items")}
          primaryColor={iconColor}
          {...data}
        />
      )}
      {planDescription && (
        <Box py="0.5rem" px="1rem">
          <P>
            <FormattedMessage id={planDescription} html />
          </P>
        </Box>
      )}
      {showSummary && (
        <>
          <Addons
            data-testid="default-addons"
            addons={defaultPlanAddons}
            primaryColor={iconColor}
            excludeTax={excludeTax}
            title={defaultAddonsTitle}
          />
          <Addons
            data-testid="addons"
            addons={addons}
            primaryColor={iconColor}
            excludeTax={excludeTax}
          />
          <PromoCodeBenefits
            data-testid="plan-promos"
            items={get(paySummaryItems, "items")}
            referralCode={referralCode}
          />
        </>
      )}
      {!hidePlanPrice && (
        <PlanPrice
          id="plan-price"
          price={showSummary ? totalPrice : planPrice}
          price_label={get(data, "price_label")}
          paySummaryItems={paySummaryItems}
          message={planMessage}
          textColor={priceColor}
          bgColor={primaryColor}
          pillColor={tertiaryColor}
          fitPillContent={fitPillContent}
          isPrepaid={isPrepaid}
          productLink={productLink}
        />
      )}
      {description && (
        <Box mb={4} px={"1rem"}>
          <Box
            px={5}
            py={3}
            backgroundColor={themeColors?.info}
            style={{ borderRadius: "8px" }}
            textAlign="left"
          >
            <Text>
              <FormattedMessage id={description} />
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};

ExtendedPlanDetailsCard.defaultProps = {
  addons: [],
  planDetails: {},
  payTodayItems: {},
  data: {}
};

export default ExtendedPlanDetailsCard;
