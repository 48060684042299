import React, { memo, useState } from "react";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import Alert from "react-s-alert";
import get from "lodash/get";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import SmartRender from "containers/SmartRender";
import {
  forms,
  paths,
  STEP_ACTIONS,
  WEB_CHANNELS,
  OLD_WEBFRONT_URL,
  CONTRACT_TYPES,
  SELF_ACT_DEL,
  NETWORK_TYPES
} from "utils/constants";
import validate from "validations";
import {
  makeSelectSimVerificationForm,
  makeSelectSimVerificationFormInitialValues,
  makeSelectPageValidations,
  makeSelectIccIdPrefix,
  makeSelectSimVerificationPayload,
  makeSelectSimActivationPayload,
  makeSelectIccIdValid,
  makeSelectIccIdError,
  makeSelectThirdPartySimVerification,
  makeSelectIccIdDetails,
  makeSelectEligiblePlans,
  makeSelectAppSettings,
  makeSelectValidateIccidStatus,
  makeSelectContractType,
  makeSelectGuestOrder,
  makeSelectMinimumAge,
  makeSelectMonthOptions,
  makeSelectDateOptions,
  makeSelectYearOptions,
  makeSelectIsModalView,
  makeSelectModalType,
  makeSelectSAIccIdPrefix,
  makeSelectGA4PlanDetails
} from "ducks/selectors";
import {
  validateIccId,
  clearData,
  fetchPlan,
  selectPlan,
  validateIccIdForSelfActivation,
  initiatePortIn,
  setData,
  OMS
} from "ducks/actions";
import { urlParam, gotoUrl, getChannelFromUrl } from "utils/helpers";
import { isWebview } from "utils/localStorage";
import AdjustAnalytics from "analytics/type/AdjustAnalytics/AdjustAnalytics";
import { ADJUST_EVENTS } from "constants/AnalyticsTypeConstants";
import { featureFlag5GSAEnabled } from "utils/featureFlags";
// import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";

const SimVerificationHOC = Component =>
  function Comp(props) {
    const dispatch = useDispatch();
    const icc_id = get(props, "formValues.icc_id");
    const networkType =
      get(props, "order.requestedNetworkType") ||
      get(props, "order.networkType");
    const [manualMode, setManualMode] = useState(!isWebview() || icc_id);

    const onNextClickInOffHours = () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "offlineHourAction" }
        })
      );
    };

    if (featureFlag5GSAEnabled()) {
      return (
        <Component
          {...props}
          manualMode={manualMode}
          setManualMode={setManualMode}
          onNextClickInOffHours={onNextClickInOffHours}
          iccIdPrefix={
            networkType === NETWORK_TYPES.SA
              ? props.iccIdSAPrefix
              : props.iccIdPrefix
          }
        />
      );
    } else {
      return (
        <Component
          {...props}
          manualMode={manualMode}
          setManualMode={setManualMode}
          onNextClickInOffHours={onNextClickInOffHours}
        />
      );
    }
  };

export const mapStateToProps = (_, props) =>
  createStructuredSelector({
    formValues: makeSelectSimVerificationForm(),
    formValidations: makeSelectPageValidations(forms.SIM_VERIFICATION_FORM),
    iccIdPrefix: makeSelectIccIdPrefix(),
    iccIdSAPrefix: makeSelectSAIccIdPrefix(),
    initialValues: makeSelectSimVerificationFormInitialValues(),
    iccIdInUrlParam: () => urlParam("iccid"),
    iccIdValid: makeSelectIccIdValid(urlParam("iccid")),
    iccIdError: makeSelectIccIdError(urlParam("iccid")),
    payload: makeSelectSimVerificationPayload(props, urlParam("iccid")),
    simActivationPayload: makeSelectSimActivationPayload(
      props,
      urlParam("iccid")
    ),
    thirdParty: makeSelectThirdPartySimVerification(props),
    minimumAge: makeSelectMinimumAge(),
    monthOptions: makeSelectMonthOptions(),
    dateOptions: makeSelectDateOptions(),
    yearOptions: makeSelectYearOptions(),
    iccIdDetails: makeSelectIccIdDetails(),
    eligiblePlans: makeSelectEligiblePlans(),
    appSettings: makeSelectAppSettings(),
    loading: makeSelectValidateIccidStatus(),
    numberType: makeSelectContractType(),
    order: makeSelectGuestOrder(),
    isModalView: makeSelectIsModalView(),
    modalType: makeSelectModalType(),
    GA4EventPlanDetails: makeSelectGA4PlanDetails()
  });

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: (navigate, params) => {
    const generateAlert = () => {
      Alert.error(get(ownProps, "locale.invalid_sim_card", "Invalid Sim Card"));
    };
    dispatchProps.validateIccId(
      params,
      ({ channel, order_ref, order_paid, plan, iccid, status, required }) => {
        const currentChannel = getChannelFromUrl();
        const currentChannelVariants = get(
          stateProps,
          `appSettings.channel_variants.${currentChannel}`
        );
        const variantChannel = includes(currentChannelVariants, channel);
        const sameChannel = channel === currentChannel;
        const validChannel =
          sameChannel ||
          variantChannel ||
          get(channel, "name_map") === SELF_ACT_DEL;

        if (order_paid) {
          const redirectUrl =
            WEB_CHANNELS.includes(channel) ||
              get(channel, "name_map") === SELF_ACT_DEL
              ? OLD_WEBFRONT_URL + paths.MANAGE_ORDER
              : paths.MANAGE_ORDER;
          const orderRefParams = order_ref
            ? {
              order_ref,
              order_reference_number: order_ref
            }
            : {};
          gotoUrl(redirectUrl, true, {
            ...orderRefParams,
            showActivate: true,
            redirect: STEP_ACTIONS.ACTIVATE_SIM_CARD,
            reset: true
          });
        } else if (validChannel && (status || iccid)) {
          // sim plan name takes higher priority than plan_name in query param
          const plan_name = get(plan, "name") || urlParam("plan_name");
          if (plan_name) {
            const selectedPlan = find(stateProps.eligiblePlans, {
              name: plan_name
            });
            if (!isEmpty(selectedPlan)) {
              dispatchProps.selectPlan(selectedPlan);
              setTimeout(navigate, 0);
            } else {
              dispatchProps.fetchPlan({ plan_name }, res => {
                !isEmpty(res) && navigate();
              });
            }
          } else {
            dispatchProps.fetchPlan({}, res => {
              !isEmpty(res) && navigate();
            });
          }
        } else {
          !required
            ? generateAlert()
            : !required.includes("dob") && generateAlert();
        }
      }
    );
  },
  activateSimCardAndNavigate: navigate => {
    const isPortIn =
      get(stateProps, "order.contractType") === CONTRACT_TYPES.PORT_IN;
    const activateMethod = isPortIn
      ? dispatchProps.initiatePortIn
      : dispatchProps.activateSimCard;

    activateMethod(stateProps.simActivationPayload, (_, error) => {
      if (isEmpty(error)) {
        AdjustAnalytics.pushEvent(ADJUST_EVENTS.PLAN_ACTIVATED);
        // GA4EventsService.publish(
        //   genericEventData(
        //     GA4EVENTS.ACTIVATE_SIM_CARD_CLICK,
        //     stateProps.GA4EventPlanDetails
        //   )
        // );
        navigate();
      }
    });
  },

  activateReplaceSimCardAndNavigate: navigate => {
    dispatchProps.activateReplaceSimCard(
      stateProps.simActivationPayload,
      (_, error) => {
        if (isEmpty(error)) {
          navigate();
        }
      }
    );
  },
  sendScanBarcodeEvent: () => { },
  // GA4EventsService.publish(
  //   genericEventData(
  //     GA4EVENTS.SCAN_FOR_BARCODE_CLICK,
  //     stateProps.GA4EventPlanDetails
  //   )
  // ),
  sendManualBarcodeEvent: () => { }
  // GA4EventsService.publish(
  //   genericEventData(
  //     GA4EVENTS.ENTER_CODE_MANUALLY_CLICK,
  //     stateProps.GA4EventPlanDetails
  //   )
  // )
});

export const mapDispatchToProps = dispatch => {
  return {
    resetValidateIccId: () => dispatch(clearData("validateIccId")),
    validateIccId: (params, callback) => {
      if (get(params, "third_party_tenant") === SELF_ACT_DEL) {
        return dispatch(validateIccIdForSelfActivation(params, callback));
      } else {
        return dispatch(validateIccId(params, callback));
      }
    },
    hideModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
    },
    resetIccIdError: () =>
      dispatch(setData({ key: "validateIccId", error: "" })),
    fetchPlan: (params, callback) => dispatch(fetchPlan(params, callback)),
    selectPlan: plan => dispatch(selectPlan(plan)),
    activateSimCard: (params, callback) =>
      dispatch(OMS.V4.activateSimCard(params, callback)),
    initiatePortIn: (params, callback) =>
      dispatch(initiatePortIn(params, callback)),
    activateReplaceSimCard: (params, callback) =>
      dispatch(OMS.V4.activateReplaceSimCard(params, callback))
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.SIM_VERIFICATION_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(SimVerificationHOC(SmartRender));

// const genericEventData = (event, details = {}) => {
//   const {
//     customer_id,
//     plan_name,
//     plan_type,
//     sim_type,
//     order_ref,
//     contract_type,
//     journey_type,
//     number_type
//   } = details;

//   return {
//     event,
//     ecommerce: {
//       customer_id,
//       order_ref,
//       plan_name,
//       journey_type,
//       contract_type,
//       plan_type,
//       sim_type,
//       number_type
//     }
//   };
// };
