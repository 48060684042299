import React from "react";
import RadioGroup from "components/FormFields/RadioGroup";

const PREPAID_POSTPAID_RADIO = ({
  data: { ...dataProps },
  disable_on_select,
  messageData,
  ...props
}) => {
  return (
    <RadioGroup
      width={[1 / 2, 1 / 3, 1 / 3]}
      {...dataProps}
      {...props}
      disable_on_select={disable_on_select}
      messageData={messageData}
    ></RadioGroup>
  );
};

export default PREPAID_POSTPAID_RADIO;
