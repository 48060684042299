import React from "react";
import DropdownField from "components/FormFields/DropdownField";
import { constructOnChangeHandler } from "utils/helpers";

const SUSPENSION_END_DATE = props => {
  const { suspensionEndDates } = props;

  return (
    <DropdownField
      options={suspensionEndDates}
      {...props}
      onChange={constructOnChangeHandler(props)}
    />
  );
};

export default SUSPENSION_END_DATE;
