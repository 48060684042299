import React from "react";
import ReactModal from "react-modal";
import styles from "./styles";
import VerifyOTP from "containers/VerifyOTP";

const OTPModal = ({ showModal, modalStyles = { overlay: {}, content: {} }, ...props }) => {
  return (
    <ReactModal style={{
      overlay: {
        ...styles.otpModalStyle.overlay,
        ...modalStyles.overlay
      },
      content: {
        ...styles.otpModalStyle.content,
        ...modalStyles.content
      }
    }} isOpen={showModal}>
      <VerifyOTP {...props} isModal={true} />
    </ReactModal>
  );
};

OTPModal.defaultProps = {
  showModal: false,
  closeModal: () => { },
  buttonTheme: {},
  onSuccess: () => { }
};

export default OTPModal;
