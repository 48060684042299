import { createSelector } from "reselect";
import get from "lodash/get";

const contractualAgreementDomain = state =>
  get(state, "api.contractualAgreement.data");

const contractualAgreementPDF = createSelector(
  contractualAgreementDomain,
  contractualAgreement => {
    const byteStreamString = contractualAgreement;
    return byteStreamString;
  }
);

export const makeSelectContractualAgreementForm = () => contractualAgreementPDF;
