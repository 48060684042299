import { createSelector } from "reselect";
import get from "lodash/get";
import head from "lodash/head";
import split from "lodash/split";
import reverse from "lodash/reverse";

import { screenshotSelector, guestOrderSelector } from "ducks/selectors";

const portInDomain = state => get(state, "form.PortInForm.values");

// ***************************************************************
// ************** portInForm selectors ******************
// ***************************************************************

export const portInSelector = createSelector(portInDomain, portIn => portIn);

// ***************************************************************
// *************** portInForm makeSelect *******************
// ***************************************************************

export const makeSelectPortInForm = () => portInSelector;

export const makeSelectPortInFormInitialValues = () =>
  createSelector(guestOrderSelector, guestOrder => {
    const portInPhoneNumber = get(guestOrder, "is_port_in_request", "");
    const authorizeTransfer = false;
    const donorTelco = get(guestOrder, "port_donor", "");
    const portInAccountNo = get(
      guestOrder,
      "port_detais.port_telco_account_number",
      ""
    ); //To Verify with OMS
    const portInType = get(guestOrder, "is_postpaid", false)
      ? "postpaid"
      : "prepaid"; //To Verify with OMS

    return {
      donorTelco,
      portInPhoneNumber,
      portInAccountNo,
      authorizeTransfer,
      portInType,
      beginReinitiatePortin: null
    };
  });

export const makeSelectPortInFormPayload = () =>
  createSelector(
    guestOrderSelector,
    screenshotSelector,
    portInSelector,
    (guestOrder, screenshot, portIn) => {
      const orderRef = get(guestOrder, "order_ref");
      const dob = reverse(
        split(head(split(get(guestOrder, "order_user_detail.dob"), "T")), "-")
      ).join("-"); // from 2000-01-01T...... to 01-01-2000

      return {
        reinitiate: {
          ...portIn,
          birthday: dob,
          authorizeTransfer: undefined,
          beginReinitiatePortin: undefined,
          portInAccountNo: get(portIn, "portInAccountNo"),
          donorTelco: get(portIn, "donorTelco.value"),
          orderRefNo: orderRef,
          orderRef,
          screenShotId: get(screenshot, "doc_id", "")
        },
        initiate: {
          order_ref: orderRef
        }
      };
    }
  );
