import React from "react";
import HTML_MESSAGE from "../HTML_MESSAGE";
import HTML_LINK from "../HTML_LINK";
import { Flex } from "../../ui-components";

const HTML_LINK_MESSAGE = props => {
  const {
    data: { elementArray = [] }
  } = props;
  return (
    <Flex>
      {elementArray.map((element = {}, index) => {
        const { type, properties } = element;
        const rowKey = `${type}-${index}`;
        return type === "HTML_LINK" ? (
          <HTML_LINK key={rowKey} {...props} {...properties} />
        ) : (
          <HTML_MESSAGE key={rowKey} {...props} {...properties} />
        );
      })}
    </Flex>
  );
};

export default HTML_LINK_MESSAGE;
