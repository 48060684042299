import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import slice from "lodash/slice";
import get from "lodash/get";
import includes from "lodash/includes";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import FormattedMessage from "components/FormattedMessage";
import AddOnCard from "./AddOnCard";
import { Flex, Box, H2 } from "ui-components";
import { ChevronUp, ChevronDown } from "components/Icons";
import { COLORS } from "ui-components/theme";

const AddOnSelection = ({
  title,
  items,
  defaultAddons,
  initialItems,
  onSelect,
  see_more_text,
  see_less_text,
  themeColors,
  hidden_addons,
  visible_addons,
  excludeTax = true,
  postfix_label
}) => {
  const visibleAddons = filter(items, item => {
    return (
      (!includes(hidden_addons, item.name) && isUndefined(visible_addons)) ||
      includes(visible_addons, item.name)
    );
  });
  const itemCount = get(visibleAddons, "length", 0);
  const [moreItemCount, setMoreItemCount] = useState(initialItems || itemCount);
  const limitItems = initialItems > 0 && itemCount > initialItems;
  const moreToShow = itemCount > moreItemCount;
  const iconColor = get(themeColors, "icon", COLORS.blue[4]);
  const ctaColor = get(themeColors, "cta", COLORS.blue[4]);

  useEffect(() => {
    if (!isEmpty(defaultAddons)) {
      for (const item of items) {
        if (
          !item.selected &&
          includes(defaultAddons, item.name || item.title || item.id)
        ) {
          onSelect(item.id, true);
        }
      }
    }
  }, [defaultAddons, items, onSelect]);

  useEffect(() => {
    if (!moreItemCount && !initialItems && itemCount) {
      setMoreItemCount(itemCount);
    }
  }, [moreItemCount, initialItems, itemCount]);

  return (
    <Box id="addon-toggle">
      {title && !isEmpty(visibleAddons) && (
        <H2 data-testid="addon-select-title" mb={4}>
          <FormattedMessage id={title} />
        </H2>
      )}

      {map(slice(visibleAddons, 0, moreItemCount), (item, index) => (
        <AddOnCard
          key={`addOn-${item.id}`}
          {...item}
          index={index}
          onSelect={onSelect}
          iconColor={iconColor}
          excludeTax={excludeTax}
          postfix_label={postfix_label}
          moreInfo={item.moreInfo}
          themeColors={themeColors}
        />
      ))}

      {limitItems && (
        <Flex
          data-testid="addon-select-toggle"
          textAlign="center"
          alignItems="center"
          justifyContent="center"
          onClick={() => {
            setMoreItemCount(moreToShow ? itemCount : initialItems);
          }}
          color={ctaColor}
          fontWeight="500"
          fontSize={["14px", "16px"]}
          sx={{ cursor: "pointer", "& > svg": { margin: 0 } }}
        >
          <FormattedMessage id={moreToShow ? see_more_text : see_less_text} />
          &nbsp;
          {moreToShow ? (
            <ChevronDown color={ctaColor} />
          ) : (
            <ChevronUp color={ctaColor} />
          )}
        </Flex>
      )}
    </Box>
  );
};

AddOnSelection.defaultProps = {
  title: "",
  items: [],
  see_more_text: "see_more_addons",
  see_less_text: "see_less_addons",
  theme: "blue"
};

AddOnSelection.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
};

export default AddOnSelection;
