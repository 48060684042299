import React, { useEffect } from "react";
import { Avatar } from "ui-components";
import { loadScript, removeScript, urlParam } from "utils/helpers";
import { CALL_BACK_URL } from "utils/constants";
import useSocialAuthUserServiceHandler from "hooks/useSocialAuthUserServiceHandler";
import { APP_REDIRECT_PATH, setItem } from "utils/localStorage";

const AppleIcon = ({ iconUrl, channelId, overrideParams }) => {
  const SCRIPT_URL =
    "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
  const APPLE_SCRIPT_NAME = "SCRIPT_APPLE";
  const scope = "name email";
  const redirect = urlParam("redirect");
  const callUserServiceAPI = useSocialAuthUserServiceHandler();

  /**
   * OverrideParams can be used to override default values or to inject any
   * additional params.It is coming from generic.social_media_signin_options
   */
  const initAppleAuthentication = () => {
    if (window.AppleID) {
      window.AppleID.auth.init({
        clientId: channelId,
        scope: scope,
        redirectURI: CALL_BACK_URL.APPLE,
        state: "origin:web",
        usePopup: true,
        ...overrideParams
      });
    }
  };

  useEffect(() => {
    loadScript(APPLE_SCRIPT_NAME, SCRIPT_URL, initAppleAuthentication);

    return () => {
      removeScript(APPLE_SCRIPT_NAME);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SCRIPT_URL]);

  const signInApple = async () => {
    try {
      // Sets redirect url to redirect after loggin
      redirect && setItem(APP_REDIRECT_PATH, redirect);

      //Calling app signing
      const authData = await window.AppleID.auth.signIn();
      console.log(authData);
      if (authData?.authorization?.id_token) {
        callUserServiceAPI({
          id_token: authData.authorization.id_token,
          provider: "APPLE"
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Avatar
      src={iconUrl}
      onClick={() => signInApple()}
      data-testid="apple-icon"
      style={{ cursor: "pointer" }}
    />
  );
};
export default AppleIcon;
