import { GOOGLE_RECAPTCHA_CLIENT_KEY } from "utils/constants";

/**
 * Do a silent recaptcha and execute the callback function provided
 * @param {Function} callbackFn function to exeute with recaptchaToken
 * @param {object} params callback function parameters
 * @param {object} options Grecaptcha options (eg: actions)
 */
const submitWithRecaptcha = (callbackFn, params, options = {}) => {
  const { action = "submit" } = options;

  const execAction = recaptchaToken => {
    callbackFn({ ...params, recaptchaToken });
  };

  /**
   * Gets you the recaptcha token and then executes the callback function with
   * provided params and recaptcha token
   */
  const executeGRecaptcha = () => {
    try {
      window.grecaptcha.execute(GOOGLE_RECAPTCHA_CLIENT_KEY, { action }).then(
        function(token) {
          execAction(token);
        },
        function(error) {
          console.error("Failed to execute Google Recaptcha. Cause: ", error);
          execAction();
        }
      );
    } catch (error) {
      console.error(
        "Error occured trying to submit Google Recaptcha. Cause: ",
        error
      );
      execAction();
    }
  };

  /**
   * Gets you the enterprice recaptcha token and then executes the callback function with
   * provided params and enterprise recaptcha token
   */
  const executeGRecaptchaEnterprise = () => {
    try {
      window.grecaptcha.enterprise
        .execute(GOOGLE_RECAPTCHA_CLIENT_KEY, { action })
        .then(
          function(token) {
            execAction(token);
          },
          function(error) {
            console.error("Failed to execute Google Recaptcha. Cause: ", error);
            execAction();
          }
        );
    } catch (error) {
      console.error(
        "Error occured trying to submit Google Recaptcha. Cause: ",
        error
      );
      execAction();
    }
  };

  /**
   * Request recaptcha when Google recaptcha is ready
   */
  const onRecaptchaSubmit = () => {
    if (!window.grecaptcha) {
      console.debug("Google Recaptcha is not available");
      execAction();
    }

    try {
      // Once all countries migrate to captcha enterprise version we should be using executeGRecaptchaEnterprise iin all scenarios
      if (window.IS_RECAPTCHA_ENTERPRISE)
        window.grecaptcha.enterprise.ready(executeGRecaptchaEnterprise);
      else window.grecaptcha.ready(executeGRecaptcha);
    } catch (e) {
      execAction();
    }
  };

  onRecaptchaSubmit();
};

export default submitWithRecaptcha;
