import React from "react";
import { Avatar } from "ui-components";
import { Box, Flex, Text, Button } from "ui-components";
import { COLORS, getButtonColorVariant, iconThemes } from "ui-components/theme";
import { ReactComponent as EditIcon } from "assets/icons/icon-edit.svg";
import { PaymentCardTypes } from "components/PaymentCardDetails";
import { ChevronRight, Circle, IconStack, Information } from "components/Icons";
import FormattedMessage from "components/FormattedMessage";
import { gotoUrl, sanitizeHTML } from "utils/helpers";
import Alert from "react-s-alert";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import { PAYMENT_METHODS } from "utils/constants";
import { useSelector } from "react-redux";
import { makeSelectFormattedUserCurrentAddress } from "ducks/selectors";

const DetailBox = ({
  header,
  content,
  type,
  icon,
  generic,
  userImageUrl,
  userProfileDetails,
  userDetails,
  path,
  phoneNumber,
  reload,
  action,
  isActiveTelcoUser,
  themeColors,
  showContactCSInfoIcon = false,
  showToolTipOrEditIcon = false,
  honorific,
  mobileNoLabel,
  locale,
  loggedInEmail,
  newCard = null,
  styles = {},
  userUpdatedAddress,
  ...rest
}) => {
  const iconColor = get(themeColors, "primary", iconThemes.povoyellow);
  const iconForeColor = get(themeColors, "secondary", iconThemes.povodarkgray);

  const cardTypes = get(generic, "payment_cards", {});
  const {
    gateway,
    cardType,
    cardDetails,
    userName,
    userNumber,
    userEmail = loggedInEmail
  } = userProfileDetails || {};

  const currentAddress = useSelector(makeSelectFormattedUserCurrentAddress());

  const onClickEvent = () => {
    const onClickAction = action && get(rest, action);
    if (isFunction(onClickAction)) return onClickAction();

    if (type === "email" && !phoneNumber) {
      Alert.error(
        get(
          locale,
          "add_phone_number_to_update_email",
          "To update email you need to add a phone number first"
        )
      );
    } else {
      path && gotoUrl(path, false, {}, reload);
    }
  };

  const renderActivePaymentMethod = () => {
    const paidyConfig = get(generic, "payment_methods.paidy", {});
    return gateway === PAYMENT_METHODS.PAIDY ? (
      <Flex alignItems="center" marginLeft="0">
        <Box width={["70px"]}>
          <img alt={paidyConfig.code} src={paidyConfig.logo} />
        </Box>
        <Text px={4}>
          {paidyConfig.label && <FormattedMessage id={paidyConfig.label} />}
        </Text>
      </Flex>
    ) : (
      <Flex alignItems="center" marginLeft="0">
        <PaymentCardTypes
          types={cardTypes}
          display="single"
          isEnabled
          imageClass={true}
          typeOfCard={cardType}
        />
        <Text>{cardDetails && `XXXX-XXXX-XXXX-${cardDetails}`}</Text>
      </Flex>
    );
  };

  const renderDebitCard = () => {
    if (newCard) {
      const buttonTheme = get(themeColors, "button", {});
      const label = newCard.label;
      const buttonStyles = {
        ...getButtonColorVariant(buttonTheme, "outline"),
        ...newCard?.button_styles
      };
      return (
        <>
          <Box width={1} data-testid={"add_new_card_box"}>
            <Box width={0.9}>
              <Text fontWeight="600" size="sm" marginBottom="8px">
                {header && <FormattedMessage id={header}></FormattedMessage>}
              </Text>
            </Box>
            <Flex sx={{ float: "right" }}>
              <Button
                onClick={onClickEvent}
                sx={buttonStyles}
                data-testid="add_new_card_button"
              >
                {label && <FormattedMessage id={label} />}
              </Button>
            </Flex>
          </Box>
        </>
      );
    } else if (gateway === PAYMENT_METHODS.PAIDY) {
      return (
        <>
          <Box width={1}>
            <Text fontWeight="900" size="sm" marginBottom="8px">
              {header && <FormattedMessage id={header} />}
            </Text>
            <Flex>
              <Box width={0.9}>{renderActivePaymentMethod()}</Box>
              {isActiveTelcoUser && (
                <Box
                  onClick={onClickEvent}
                  sx={{ cursor: "pointer" }}
                  data-testid="update_card_button"
                >
                  <EditIcon />
                </Box>
              )}
            </Flex>
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Box width={1}>
            <Text fontWeight="900" size="sm" marginBottom="8px">
              {header && <FormattedMessage id={header} />}
            </Text>
            <Flex>
              <Box width={0.9}>{renderActivePaymentMethod()}</Box>
              {isActiveTelcoUser && (
                <Box
                  onClick={onClickEvent}
                  sx={{ cursor: "pointer" }}
                  data-testid="update_card_button"
                >
                  <EditIcon />
                </Box>
              )}
            </Flex>
          </Box>
        </>
      );
    }
  };

  const renderCommonCardWithIcon = () => {
    const contentFormatted = type === "email" ? userEmail : content;
    return (
      <>
        <Box width={0.9} data-testid="common_card">
          <Text fontWeight="900" size="sm" marginBottom="8px">
            {header && <FormattedMessage id={header} />}
          </Text>
          {contentFormatted && (
            <Text>
              <FormattedMessage html id={contentFormatted} />
            </Text>
          )}
        </Box>

        {icon === "edit" ? (
          type === "email" && !phoneNumber ? null : (
            <Box
              data-testid="edit_button"
              onClick={onClickEvent}
              sx={{ cursor: "pointer" }}
            >
              <EditIcon />
            </Box>
          )
        ) : (
          icon === "arrow" && (
            <Box
              data-testid="arrow_button"
              onClick={onClickEvent}
              sx={{ cursor: "pointer" }}
            >
              <ChevronRight color={"black"} />
            </Box>
          )
        )}
      </>
    );
  };

  const renderProfileImageWithName = () => {
    return (
      <Box width={1} data-testid="profile-image--box">
        <Flex alignItems="center">
          <Box width={0.15} marginRight="0.5rem">
            <Avatar
              src={
                userImageUrl
                  ? userImageUrl
                  : "https://storage.googleapis.com/pidgc-ecom/assets/images/profile-icon.png"
              }
              onClick={onClickEvent}
              data-testid="profile-image"
              style={{
                cursor: "pointer",
                marginLeft: "0",
                borderColor: "#FFFFFF",
                boxShadow: "0 0px 0px 0 rgba(0,0,0,0)",
                flexShrink: "0",
                height: "48px",
                marginRight: "10px",
                marginTop: "2px",
                padding: "0",
                width: "48px"
              }}
            />
          </Box>
          <Box width={0.75}>
            <Text fontWeight="900" size="sm" marginBottom="8px">
              {userName} {honorific && <FormattedMessage id={honorific} />}
            </Text>
            <Text>
              {mobileNoLabel && <FormattedMessage id={mobileNoLabel} />}{" "}
              {userNumber}
            </Text>
          </Box>
          {showContactCSInfoIcon && (
            <Box
              height={16}
              width={16}
              onClick={onClickEvent}
              sx={{ cursor: "pointer" }}
            >
              <IconStack size="16px">
                <Circle size="14px" color={iconForeColor} />
                <Information color={iconColor} size="16px" />
              </IconStack>
            </Box>
          )}
        </Flex>
      </Box>
    );
  };

  const renderAddress = address => {
    return (
      <>
        {address && (
          <Box width={1} data-testid="address_box">
            {header && (
              <Text fontWeight="600" size="sm" marginBottom="8px">
                {header && <FormattedMessage id={header} />}
              </Text>
            )}
            <Flex>
              <Box width={0.9} paddingRight="10%">
                <Text
                  dangerouslySetInnerHTML={{ __html: sanitizeHTML(address) }}
                />
              </Box>
              {showToolTipOrEditIcon && (
                <>
                  {isActiveTelcoUser && (
                    <Box
                      onClick={onClickEvent}
                      sx={{ cursor: "pointer" }}
                      data-testid="update_address"
                    >
                      <EditIcon />
                    </Box>
                  )}
                </>
              )}
              {showContactCSInfoIcon && (
                <Box
                  height="16px"
                  width="16px"
                  onClick={onClickEvent}
                  sx={{ cursor: "pointer" }}
                >
                  <IconStack size="16px">
                    <Circle size="14px" color={iconForeColor} />
                    <Information color={iconColor} size="16px" />
                  </IconStack>
                </Box>
              )}
            </Flex>
          </Box>
        )}
      </>
    );
  };

  const renderPaymentMethod = () => {
    return (
      <Box
        variant="borderSection"
        padding={[3, 3]}
        sx={{
          px: 1,
          py: 1,
          borderWidth: "2px",
          cursor: "pointer",
          borderColor: "#ffff",
          backgroundColor: COLORS.white,
          borderRadius: 0,
          padding: 0
        }}
        mb={2}
        width={[1]}
      >
        <Box width={1}>
          <Flex>
            <Box>{renderActivePaymentMethod()}</Box>
          </Flex>
        </Box>
      </Box>
    );
  };

  const renderSwitch = () => {
    switch (type) {
      case "card":
        return renderDebitCard();
      case "profile":
        return renderProfileImageWithName();
      case "address":
        return renderAddress(currentAddress);
      case "updated_address":
        return renderAddress(userUpdatedAddress);
      case "payment_method":
        return renderPaymentMethod();
      default:
        return renderCommonCardWithIcon();
    }
  };

  return (
    <Box
      color="blue.4"
      fontWeight="500"
      sx={{
        marginBottom: "15px",
        padding: "10px",
        ...styles
      }}
    >
      <Flex>{renderSwitch()}</Flex>
    </Box>
  );
};

export default DetailBox;
