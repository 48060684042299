import { createSelector } from "reselect";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

const authorizeUserDomain = state =>
  get(state, "api.authorizerDetails.data", {});

const authorizeDeliveryDomain = state =>
  get(state, "api.submitAuthorizeDelivery.data", {});

// ***************************************************************
// **************** authorizerUserDetails selectors **********************
// ***************************************************************

export const authorizerUserDetailsSelector = createSelector(
  authorizeUserDomain,
  authorizerUserDetails =>
    isEmpty(authorizerUserDetails) ? null : authorizerUserDetails
);
export const authorizerDeliverySelector = createSelector(
  authorizeDeliveryDomain,
  authorizeDelivery => (isEmpty(authorizeDelivery) ? null : authorizeDelivery)
);

// ***************************************************************
// *************** authorizerUserDetails makeSelect **********************
// ***************************************************************

export const makeAuthorizerUserDetailsDetails = () =>
  authorizerUserDetailsSelector;

export const makeSelectAuthorizedDeliveryDetails = () =>
  authorizerDeliverySelector;

export const makeSelectAuthorizedUserValues = props =>
  createSelector(authorizerUserDetailsSelector, authorizeUser => {
    if (authorizeUser) {
      const {
        name: order_user_name,
        email: order_user_email,
        nric: order_user_nric
      } = authorizeUser;

      return {
        order_user_name,
        order_user_email,
        order_user_nric
      };
    }
  });
