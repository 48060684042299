import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Text } from "ui-components";
import { COLORS } from "ui-components/theme";

const FieldError = ({ touched, error, values, sx }) => {
  return touched && error ? (
    <FormattedMessage id={error} values={values}>
      {msg => (
        <Text
          fontWeight="500"
          className="field-error"
          mt={1}
          size="xs"
          color={COLORS.red[1]}
          sx={sx}
        >
          {msg}
        </Text>
      )}
    </FormattedMessage>
  ) : null;
};

FieldError.defaultProps = {
  values: {}
};

export default FieldError;
