import React from "react";
import PropTypes from "prop-types";
import { Box, Flex, Text, Link } from "ui-components";
import { CheckCircle, PlusCircleOutline } from "components/Icons";
import FormattedMessage from "components/FormattedMessage";
import HighlightText from "components/HighlightText";

const AddOnCard = ({
  onSelect,
  id,
  index,
  label,
  description,
  price,
  selected,
  iconColor,
  price_without_tax,
  excludeTax,
  product_link,
  highlightText,
  postfix_label,
  themeColors
}) => {
  const addOnPrice = excludeTax ? price_without_tax : price;
  return (
    <Box
      sx={{
        borderTop: "1px solid silver",
        borderWidth: index ? "1px 0" : "0",
        padding: product_link ? "1rem 0 1.5rem 0" : "1rem 0 1rem 0"
      }}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        data-testid="addon-card"
        sx={{
          paddingBottom: "0.8rem"
        }}
      >
        <Flex width="80%" alignItems="center">
          <Box
            width="150"
            data-testid="addon-card-check"
            sx={{ cursor: "pointer" }}
            onClick={() => onSelect(id, !selected)}
            minWidth="36px"
          >
            {selected ? (
              <CheckCircle size="36px" color={iconColor} />
            ) : (
              <PlusCircleOutline size="36px" color={iconColor} />
            )}
          </Box>

          <Box
            width={1}
            sx={{ cursor: "pointer", wordBreak: "break-word" }}
            onClick={() => onSelect(id, !selected)}
          >
            <Text mx={6} mb={1} lineHeight="1.2" fontWeight="700">
              {label}
            </Text>
            {highlightText && (
              <HighlightText
                text={highlightText}
                {...(themeColors?.badge || {})}
              />
            )}
            <Text size="sm" mx={6} lineHeight="1.2">
              {description}
            </Text>
          </Box>
        </Flex>

        <Box minWidth="100px" textAlign="right">
          {postfix_label && (
            <FormattedMessage id={postfix_label} values={{ addOnPrice }} />
          )}
          {!postfix_label && <Text lineHeight="1.2">{addOnPrice}</Text>}
        </Box>
      </Flex>
      {product_link && (
        <Link
          href={product_link}
          target="_blank"
          sx={{
            pl: 60,
            lineHeight: "1.2",
            opacity: "0.8",
            textDecoration: "underline !important"
          }}
        >
          <FormattedMessage id="more_details" />
        </Link>
      )}
    </Box>
  );
};

AddOnCard.defaultProps = {
  label: "",
  description: "",
  price: "",
  value: 0
};

AddOnCard.propTypes = {
  onSelect: PropTypes.func,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.number.isRequired
};

export default AddOnCard;
