import React, { useRef, useEffect } from "react";
import PageNavigation from "components/PageNavigation";
import { useDispatch, useSelector } from "react-redux";
import OTPModal from "components/OTPModal";
import isFunction from "lodash/isFunction";
import get from "lodash/get";
import { buttonThemes } from "ui-components/theme";
import {
  makeSelectUserEmail,
  makeSelectIsOTPFail,
  makeSelectUserAuthOTPPayload,
  makeSelectOtpSecuredActionPayload
} from "ducks/selectors";
import { UserService } from "ducks/actions";
import { removeTimerKey } from "utils/localStorage";

const PageNavigationOTPSecured = props => {
  const DEFAULT_TIMER_KEY = "page_nav_otp_secured";
  const timerKey = props.otpBoxes?.timerKey || DEFAULT_TIMER_KEY;
  const secureOtpRequired = props.otpBoxes?.secureOtpAPI || false;
  const dispatch = useDispatch();
  const userEmail = useSelector(makeSelectUserEmail());
  const otpPayload = useSelector(makeSelectUserAuthOTPPayload());
  const securedActionPayload = useSelector(makeSelectOtpSecuredActionPayload());
  const isOTPFail = useSelector(
    makeSelectIsOTPFail(props?.data?.otpBoxes?.errorAPIKey)
  );

  /**
   * container should pass showOTPModal,setShowOTPModal as props
   * showOTPModal - useState params
   * setShowOTPModal - useState set function
   */
  const { showOTPModal, setShowOTPModal } = props;
  const onSubmitCallbackRef = useRef(null);

  useEffect(() => {
    return () => {
      isFunction(setShowOTPModal) && setShowOTPModal(false);
      onSubmitCallbackRef.current = null;
    };
  }, [setShowOTPModal]);

  /**
   * Dispatches the reuest OTP API call
   * If success the promise will be resolved with reponse payload, else rejected with error
   * @returns {Promise}
   */
  const requestOTP = () => {
    removeTimerKey(timerKey);
    return new Promise((resolve, reject) => {
      if (secureOtpRequired) {
        const payload = {
          request_type: otpPayload.request_type,
          auth_mode: otpPayload.auth_mode
        };
        dispatch(
          UserService.V4.requestSecureUserAuthOTP(
            payload,
            (response, errors) => {
              if (errors) reject(errors);
              else resolve(response);
            }
          )
        );
      } else {
        dispatch(
          UserService.V4.requestUserAuthOTP(otpPayload, (response, errors) => {
            if (errors) reject(errors);
            else resolve(response);
          })
        );
      }
    });
  };

  /**
   * Page navigation button on submit handler
   * The function will request to send OTP from User service
   * Once the request is successful it will show the OTP modal
   * @param {function} navigate
   * @param {any} payload
   * @param  {...any} otherParams
   */
  const onSubmitHandler = async (navigate, payload, ...otherParams) => {
    try {
      await requestOTP();
      isFunction(setShowOTPModal) && setShowOTPModal(true);
      // need to store the callback handler so once otp is verified the action can be continued
      onSubmitCallbackRef.current = data => {
        const { current_otp, securedActionPayload = {} } = data || {};
        const secureActionPayload = {
          otp_code: current_otp,
          ...securedActionPayload
        };
        // current otp value is injected into data object, so the callback can get it
        props.onSubmit(
          navigate,
          { ...payload, otp_payload: secureActionPayload },
          ...otherParams
        );
      };
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Renders OTP modal
   * @returns {React.ReactElement} OTP Modal
   */
  const renderOTPModal = () => {
    const buttonTheme = get(
      props.themeColors,
      "button",
      buttonThemes.povodarkgray
    );
    const { modalTitle, modalSubtitle, infoMessage } =
      props?.data?.otpModal || {};

    const DEFAULT_HEADER_STYLES = {
      alignItems: "center",
      textAlign: "center",
      padding: "0.5rem",
      width: "100%",
      marginBottom: "2rem",
      overflowWrap: "anywhere"
    };
    const DEFAULT_OTP_BOXES_PROPS = {
      size: 6,
      buttonColor: buttonTheme,
      onSubmit,
      buttonWidth: ["100%", "60%"],
      timerKey: timerKey
    };
    const DEFAULT_OTP_BOX_TIMER_PROPS = {
      label: "expiring_in",
      durationInSeconds: 120,
      enableResendLinkInSeconds: 60,
      resendLinkCallback: null
    };

    const {
      timer: timerPropsFromCofig,
      errorMessage,
      ...otpBoxesPropsFromCofig
    } = props?.data?.otpBoxes || {};
    const otpBoxesProps = {
      ...DEFAULT_OTP_BOXES_PROPS,
      ...otpBoxesPropsFromCofig,
      timer: {
        ...DEFAULT_OTP_BOX_TIMER_PROPS,
        ...timerPropsFromCofig
      },
      errorMessage: isOTPFail ? errorMessage : null
    };
    const otpModalStyles = props?.data?.otpModalStyles || { overlay: {}, content: {} };

    function onSubmit(_, { current_otp }) {
      if (isFunction(onSubmitCallbackRef?.current)) {
        onSubmitCallbackRef.current({ current_otp, securedActionPayload });
      } else {
        console.error("onSubmitCallbackRef.currennt is not a function");
      }
    }

    const resend = async () => {
      try {
        await requestOTP();
      } catch (e) {
        console.error(e);
      }
    };

    const hideModal = () => {
      isFunction(setShowOTPModal) && setShowOTPModal(false);
      onSubmitCallbackRef.current = null;
    };

    return (
      <OTPModal
        modal_title={modalTitle}
        modal_subtitle={modalSubtitle}
        showModal={showOTPModal}
        showCloseButton={false}
        closeModal={hideModal}
        buttonTheme={buttonTheme}
        hideOtpResend={true}
        enableBackButton={true}
        info_message={infoMessage}
        headerStyles={DEFAULT_HEADER_STYLES}
        headerValues={{
          email: userEmail
        }}
        otpBoxes={otpBoxesProps}
        modalStyles={otpModalStyles}
        resendOTPLink={{
          resend: resend,
          isDisable: false
        }}
      />
    );
  };

  return (
    <>
      {showOTPModal && renderOTPModal()}
      <PageNavigation
        {...props}
        otpModalOpen={showOTPModal}
        onSubmit={onSubmitHandler}
      />
    </>
  );
};

export default PageNavigationOTPSecured;
