import get from "lodash/get";
import { createSelector } from "reselect";
import { dataOnlyUserDetailFormSelector } from "../forms/userRegistrationForm";
import { countryCodeSelector } from "../scheduleDeliveryDetails";

const dataUserRegistrationDomain = state =>
  get(state, "ui.dataOnlyUserRegistration", {});

const hasVerifiedEmailSelector = createSelector(
  dataUserRegistrationDomain,
  ({ emailVerification }) => emailVerification?.email_verified
);

const hasVerifiedContactSelector = createSelector(
  dataUserRegistrationDomain,
  ({ contactNumVerification }) => contactNumVerification?.contact_num_verified
);

export const verifiedEmailSelector = createSelector(
  dataUserRegistrationDomain,
  ({ emailVerification }) => {
    return emailVerification?.email_verified ? emailVerification.email : "";
  }
);

export const verifiedContactNumSelector = createSelector(
  dataUserRegistrationDomain,
  ({ contactNumVerification }) => {
    return contactNumVerification?.contact_num_verified
      ? contactNumVerification.contact_number
      : "";
  }
);

export const dataOnlyUserDetailsSelector = createSelector(
  dataOnlyUserDetailFormSelector,
  verifiedEmailSelector,
  verifiedContactNumSelector,
  countryCodeSelector,
  (userDetails = {}, verifiedEmail, verifiedContactNumber, countryCode) => {
    const { first_name, last_name, dob } = userDetails;
    return {
      first_name,
      last_name,
      dob,
      phone_number: verifiedContactNumber,
      email: verifiedEmail,
      contact_no: verifiedContactNumber,
      country_code: `+${countryCode}`
    };
  }
);

export const makeSelectIsEmailVerified = () => hasVerifiedEmailSelector;
export const makeSelectIsContactNumVerified = () => hasVerifiedContactSelector;
export const makeSelectVerifiedEmail = () => verifiedEmailSelector;
