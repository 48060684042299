import React, { useEffect } from "react";
import FormattedMessage from "components/FormattedMessage";
import { Field } from "redux-form";
import { Box, Label as RebassLabel, Checkbox } from "ui-components";
import Label from "./Label";
import FieldError from "./FieldError";
import { iconThemes } from "ui-components/theme";
import isFunction from "lodash/isFunction";
import get from "lodash/get";
import { handleRelatedTargetAfterBlur, safeEval } from "utils/helpers";

const RenderField = ({
  input,
  label,
  type,
  placeholder,
  meta: { touched, error },
  render,
  data: { checked_message, labelStyles = {} },
  checkBoxOnly,
  onBlur,
  sx,
  iconColor,
  disabled,
  defaultChecked,
  onClick,
  required,
  optional
}) => {
  useEffect(() => {
    // this is used instead of input.checked because it will
    // trigger textfield to be disabled after this field is prefilled.
    // for example middle name field.
    defaultChecked && input.onChange(defaultChecked);
    // eslint-disable-next-line
  }, [defaultChecked]);
  const styleOverride = sx || {};
  const handleOnBlur = (event, newValue, previousValue, name) => {
    if (isFunction(onBlur)) {
      onBlur(event, newValue, previousValue, name);
    } else if (isFunction(input.onBlur)) {
      input.onBlur(event, newValue, previousValue, name);
    }
    handleRelatedTargetAfterBlur(event);
  };
  const handleLabelOnclick = () => {
    input.onChange(!input.value);
  };

  return (
    <>
      {label && (
        <Box>
          <RebassLabel alignItems="center" {...labelStyles}>
            <Checkbox
              data-testid="checkbox_with_label"
              value={input.value || defaultChecked}
              onClick={onClick}
              {...input}
              onBlur={handleOnBlur}
              placeholder={placeholder}
              type={type}
              sx={{ color: iconColor, ...styleOverride }}
              disabled={disabled}
            />
            <Box sx={{ paddingLeft: "1rem", width: "100%", ...styleOverride }}>
              {!required ? (
                <FormattedMessage html id={label} />
              ) : (
                <Label
                  label={<FormattedMessage html id={label} />}
                  sx={{ marginBottom: "0 !important" }}
                  required={required}
                  optional={optional}
                  onClick={!disabled && handleLabelOnclick}
                />
              )}
            </Box>
          </RebassLabel>
        </Box>
      )}

      {checkBoxOnly && (
        <Box>
          <RebassLabel alignItems="center">
            <Checkbox
              {...input}
              data-testid="checkbox_without_label"
              onBlur={handleOnBlur}
              placeholder={placeholder}
              type={type}
              onClick={onClick}
              sx={{ color: iconColor }}
              disabled={disabled}
            />
          </RebassLabel>
        </Box>
      )}

      {input.checked && checked_message && (
        <RebassLabel>
          <FormattedMessage html id={checked_message} />
        </RebassLabel>
      )}

      <FieldError touched={touched} error={error} />

      {render && render({ isChecked: input.checked, message: checked_message })}
    </>
  );
};

const CheckboxField = props => {
  const {
    field,
    pattern,
    label,
    placeholder,
    onChange,
    normalize,
    render,
    data,
    sx,
    boxSx = {},
    checkBoxOnly = false,
    themeColors,
    disabled,
    defaultChecked,
    onClick = () => { },
    required,
    optional,
    formValues
  } = props;

  const checkBoxDissabled = safeEval(get, formValues, disabled, false, props);

  const name = field || "unnamedcheckbox";
  const iconColor = get(themeColors, "icon", iconThemes.blue);
  return (
    <Box mb={8} data-testid={name} sx={boxSx}>
      <Field
        defaultChecked={defaultChecked}
        name={name}
        component={RenderField}
        type="checkbox"
        pattern={pattern}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        normalize={normalize}
        render={render}
        data={data}
        sx={sx}
        onClick={onClick}
        disabled={checkBoxDissabled}
        checkBoxOnly={checkBoxOnly}
        iconColor={iconColor}
        required={required}
        optional={optional}
      />
    </Box>
  );
};

CheckboxField.defaultProps = {
  data: {}
};

export default CheckboxField;
