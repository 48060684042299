import React from "react";
import PropTypes from "prop-types";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, Text } from "ui-components";
import { COLORS } from "ui-components/theme";
import PageHeader from "components/PageHeader";
import map from "lodash/map";

const NextStepsCard = props => {
  return (
    <>
      <Title {...props} />
      <Steps {...props} />
    </>
  );
};

const Title = ({ title, themeColors }) => {
  return !title ? null : (
    <PageHeader
      data-testid="next-steps-card-title"
      title={title}
      themeColors={themeColors}
    />
  );
};

const Step = ({
  assetFolder,
  icon,
  title,
  description,
  lastStep,
  titleColor = COLORS.purple[4],
  descriptionColor = COLORS.gray[3],
  lineColor = COLORS.magenta[3],
  styles = {},
  additionalItems = []
}) => {
  const iconUrl = `${assetFolder}${icon}`;
  const style = lastStep
    ? {}
    : {
        backgroundRepeat: "repeat-y",
        backgroundPosition: "center",
        backgroundSize: "2px",
        backgroundImage: `repeating-linear-gradient(180deg, #FFFFFF 0 5px, ${lineColor} 0 15px)`
      };
  return (
    <Flex>
      <Flex width={["fit-content"]} style={style}>
        <Box width={["36px"]} variant="paymentLogo">
          <img alt={icon} src={iconUrl} />
        </Box>
      </Flex>
      <Flex
        paddingLeft={5}
        mb={"2rem"}
        justifySelf="flex-start"
        flexWrap={"wrap"}
        width={"80%"}
        flexDirection="column"
      >
        {title && (
          <Text
            sx={{ ...styles }}
            color={titleColor}
            py={2}
            fontWeight={"bolder"}
          >
            <FormattedMessage id={title} html />
          </Text>
        )}
        {description && (
          <Text
            sx={{ ...styles }}
            color={descriptionColor}
            fontSize="0.8rem"
            lineHeight={1.5}
            py={2}
          >
            <FormattedMessage id={description} html />
          </Text>
        )}
        {map(additionalItems, (item, index) => {
          return (
            <Text
              key={index}
              fontSize="0.8rem"
              color={titleColor}
              sx={{ ...item.styles }}
            >
              <FormattedMessage id={item.message} />
            </Text>
          );
        })}
      </Flex>
    </Flex>
  );
};

const Steps = ({
  assetFolder,
  steps,
  titleColor,
  descriptionColor,
  lineColor,
  styles
}) =>
  map(steps, (step, index) => {
    const lastStep = index === steps.length - 1;
    return (
      <Step
        key={index}
        {...step}
        assetFolder={assetFolder}
        lastStep={lastStep}
        titleColor={titleColor}
        descriptionColor={descriptionColor}
        lineColor={lineColor}
        styles={styles}
      />
    );
  });

NextStepsCard.defaultProps = {
  title: "",
  steps: [],
  assetFolder: "/"
};

NextStepsCard.propTypes = {
  title: PropTypes.string,
  steps: PropTypes.array,
  assetFolder: PropTypes.string
};

export default NextStepsCard;
