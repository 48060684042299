import {
  defaultDeliveryModeSelector,
  referralCodeSelector,
  addonsSelector,
  planServiceSelector,
  localeSelector
} from "ducks/selectors";
import { createSelector } from "reselect";
import get from "lodash/get";
import filter from "lodash/filter";
import { ORDER_CHANNEL_MAPPING, DELIVERY_METHOD_TYPE } from "utils/constants";
import {
  addressParams,
  getChannelFromUrl,
  constructLocalizedDeliveryAddress
} from "utils/helpers";

const selfActivationDomain = state =>
  get(state, "form.SelfActivationForm.values", {});

const PrecheckoutDomain = state =>
  get(state, "form.PreCheckoutForm.values", {});

export const showCreateOrderSectionSelector = state =>
  get(state, "api.showOrderCreation.data.success", false);

export const showOrderInfoModalSelector = state =>
  get(state, "api.showOrderInfoModal.data.success", false);

export const showTransferNumberModalSelector = state =>
  get(state, "api.showTransferNumberModal.data.success", false);

export const selfActivationSelector = createSelector(
  selfActivationDomain,
  selfActivation => selfActivation
);

export const PreCheckoutFormSelector = createSelector(
  PrecheckoutDomain,
  preCheckoutForm => preCheckoutForm
);

export const selectedSADeliveryMethodSelector = createSelector(
  defaultDeliveryModeSelector,
  selfActivationSelector,
  (defaultDeliveryMethod, selfActivation) => {
    return get(selfActivation, "delivery_method", defaultDeliveryMethod);
  }
);

export const selectedSANumberTypeSelector = createSelector(
  selfActivationSelector,
  PreCheckoutFormSelector,
  (selfActivation, preCheckoutForm) => {
    return get(
      selfActivation,
      "number_type",
      get(preCheckoutForm, "number_type")
    );
  }
);

export const makeSelectDeliveryLocation = () =>
  createSelector(
    selfActivationSelector,
    localeSelector,
    (selfActivation, locale) => {
      let hse_blk_tower, street_building_name, unit_no, city, zip_code;

      switch (get(selfActivation, "delivery_method.action")) {
        case DELIVERY_METHOD_TYPE.POP_STATION: {
          const addressData = get(
            selfActivation,
            "pop_station_outlet_full_data"
          );
          hse_blk_tower = get(addressData, "house_block_number", "");
          street_building_name = `${get(addressData, "street_name", "")} ${get(
            addressData,
            "building_name",
            ""
          )}`;
          unit_no = get(addressData, "unit_number", "");
          city = get(addressData, "country", "");
          zip_code = get(addressData, "postal_code", "");

          break;
        }

        case DELIVERY_METHOD_TYPE.HOME:
          hse_blk_tower = get(selfActivation, "delivery_hse_blk_tower", "");
          street_building_name = `${get(
            selfActivation,
            "delivery_street_name",
            ""
          )} ${get(selfActivation, "delivery_building_name", "")}`;
          unit_no = get(selfActivation, "delivery_unit_no", "");
          city = get(selfActivation, "delivery_city", "");
          zip_code = get(selfActivation, "delivery_zip_code", "");

          break;
        default:
      }

      return constructLocalizedDeliveryAddress(
        {
          hse_blk_tower,
          street_building_name,
          unit_no,
          city,
          zip_code,
          floor_no: "",
          district: " "
        },
        locale
      );
    }
  );

export const makeSelectSelfActivationForm = () => selfActivationSelector;

export const makeSelectShowTransferNumberModalSelector = () =>
  showTransferNumberModalSelector;

export const makeSelectSelfActivationOrderParams = () =>
  createSelector(
    selfActivationSelector,
    referralCodeSelector,
    addonsSelector,
    planServiceSelector,
    (formValues, referralCode, addOns, planService) => {
      const deliveryMethod = get(formValues, "delivery_method.action");

      const orderParams = {
        order: {
          slot_id:
            deliveryMethod === DELIVERY_METHOD_TYPE.POP_STATION
              ? get(formValues, "pop_station_slot_id")
              : get(formValues, "delivery_slot_id"),
          refferal_code: get(referralCode, "value"),
          delivery_slot_full_data:
            deliveryMethod === DELIVERY_METHOD_TYPE.POP_STATION
              ? get(formValues, "pop_station_slot_full_data")
              : get(formValues, "delivery_slot_full_data"),
          delivery_slot:
            deliveryMethod === DELIVERY_METHOD_TYPE.POP_STATION
              ? get(formValues, "pop_station_slot")
              : get(formValues, "delivery_slot"),
          delivery_method:
            deliveryMethod === DELIVERY_METHOD_TYPE.HOME
              ? "Home"
              : deliveryMethod,
          selected_planid: get(planService, "sku"),
          channel_name: get(
            ORDER_CHANNEL_MAPPING,
            getChannelFromUrl(),
            getChannelFromUrl()
          ),
          nric: get(formValues, "nric"),
          order_items_attributes: filter(addOns, addon => addon.selected).map(
            addon => {
              return {
                sku: addon.id,
                price: addon.value,
                title: addon.label,
                name: addon.label,
                item_type: "ADDON",
                pay_monthly: addon.value,
                pay_now: 0,
                quantity: 1
              };
            }
          ),
          order_user_detail_attributes: {
            first_name: get(formValues, "full_name"),
            email: get(formValues, "email"),
            country_code: get(formValues, "country_code"),
            phone_number: get(formValues, "contact_number"),
            dob: get(formValues, "dob")
          },
          order_deliver_detail_attributes: addressParams(
            formValues,
            deliveryMethod === DELIVERY_METHOD_TYPE.HOME
              ? "delivery"
              : deliveryMethod
          ),
          multi_sim_attributes: {
            multi_sim_order: get(formValues, "multi_sim", false),
            multi_sim_quantity: get(formValues, "multi_sim") ? 1 : 0
          },
          sim_count: get(formValues, "sim_count"),
          popstation_outlet_id: get(
            formValues,
            "pop_station_outlet_full_data.outlet_id",
            null
          ),
          post_office_id: null
        }
      };
      return orderParams;
    }
  );

export const makeSelectshowOrderCreationSection = () =>
  showCreateOrderSectionSelector;
