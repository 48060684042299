import React from "react";
import FormattedMessage from "components/FormattedMessage";
import Hide from "hidden-styled";
import CirclesLifeLogo from "assets/images/circles-life-logo.png";
import LiveOnLogo from "assets/images/live-on-logo.png";
import PovoLogo from "assets/images/povo-logo.png";
import { ReactComponent as IconHelp } from "assets/icons/icon-help-white-filled.svg";
import { ReactComponent as IconChat } from "assets/icons/icon-chat-white-filled.svg";
import { Menu } from "components/Icons";
import { Button, Box, Flex, Text } from "ui-components";
import {
  activateZendeskWidget,
  gotoUrl,
  getChannelFromUrl
} from "utils/helpers";
import { BRAND_NAME, DATA_ONLY } from "utils/constants";
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import {
  iconThemes,
  buttonThemes,
  getButtonColorVariant
} from "ui-components/theme";
import ProfileIcon from "components/ProfileIcon";
import { useSelector } from "react-redux";
import { selectzEIdentifyUserData, makeSelectPageData } from "ducks/selectors";
import { isNative } from "utils/localStorage";

const Logos = {
  "circles.life": CirclesLifeLogo,
  "live.on": LiveOnLogo,
  povo: PovoLogo
};
const HelpIcons = {
  help: IconHelp,
  chat: IconChat
};
const brandLogo = BRAND_NAME && Logos[BRAND_NAME.toLowerCase()];
const Logo = brandLogo || CirclesLifeLogo;

const Header = ({
  setOpen,
  buttons,
  appSettings,
  themeColors,
  userDetails,
  ...props
}) => {
  const primaryColor = get(themeColors, "primary") || iconThemes.blue;
  const buttonTheme = get(themeColors, "button") || buttonThemes.blue;
  const helpColor =
    get(appSettings, "layout.header.help.color") || primaryColor;
  const helpText = get(appSettings, "layout.header.help.text", "help");
  const helpIcon = get(appSettings, "layout.header.help.icon", "help");
  const HelpIcon = get(HelpIcons, helpIcon, IconHelp);

  const faqText = get(appSettings, "layout.header.faq.text", "help");
  const FaqIcon = get(appSettings, "layout.header.faq.icon", "help");
  const channel = getChannelFromUrl();

  const helpHidden = get(appSettings, "layout.header.help.hidden", false);
  const pageLogoUrl = get(useSelector(makeSelectPageData()), "pageLogoUrl");
  const marketingPageUrl = get(appSettings, "layout.header.marketingPageUrl");
  const profileImageUrl = get(userDetails, "profile_image");
  const zEIdentify = useSelector(selectzEIdentifyUserData);
  const prefilzEForm = get(
    appSettings,
    "layout.header.help.prefilZendeskForm",
    true
  );

  const setupAndActivateZendeskWidget = () => {
    if (prefilzEForm) {
      activateZendeskWidget(zEIdentify);
    } else {
      activateZendeskWidget();
    }
  };

  return (
    <>
      <Flex
        as="nav"
        sx={{
          zIndex: 1000,
          position: "fixed",
          width: "100%",
          backgroundColor: "white",
          boxShadow: "0 4px 5px -3px rgba(0, 0, 0, 0.2)"
        }}
        height={77}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex alignItems="center">
          <Box mx={[1, 4]} sx={{ cursor: "pointer" }}>
            {!isEmpty(buttons) && (
              <Hide md lg onClick={() => setOpen(true)}>
                <Menu />
              </Hide>
            )}
          </Box>

          <Box as="a" href={pageLogoUrl || marketingPageUrl}>
            <img
              style={{ height: "2rem", cursor: "pointer", minWidth: "5rem" }}
              alt={BRAND_NAME}
              src={Logo}
            />
          </Box>
        </Flex>

        <Flex justifyContent="space-around">
          <Hide sm xs>
            <Box mx={4}>
              {map(buttons, ({ label, url, variant }, index) => (
                <Button
                  key={index}
                  mx={1}
                  variant={variant}
                  sx={getButtonColorVariant(buttonTheme, variant)}
                  onClick={() => gotoUrl(url, false, {}, true)}
                >
                  <FormattedMessage id={label} />
                </Button>
              ))}
            </Box>
          </Hide>
        </Flex>

        <Flex alignItems="center">
          {!isNative() && !helpHidden && (
            <Flex
              mx={1}
              alignItems="center"
              justifyContent="flex-end"
            >
              {channel === DATA_ONLY && (
                <Flex
                  mx={1}
                  alignItems="center"
                  justifyContent="space-between"
                  style={{
                    cursor: "pointer",
                    background: helpColor,
                    borderRadius: "25px",
                    padding: 4,
                    height: "35px",
                    marginRight: "4px"
                  }}
                >
                  <Box>
                    <img src={FaqIcon} alt="faq-icon" />
                  </Box>
                  {faqText && (
                    <Box
                      sx={{
                        pl: 1,
                        pr: 1,
                        fontSize: ["0.7rem", "0.9rem", "1rem"]
                      }}
                    >
                      <Text style={{ color: "#FFFFFF" }}>
                        <FormattedMessage id={faqText} html />
                      </Text>
                    </Box>
                  )}
                </Flex>
              )}
              <Flex
                mx={1}
                alignItems="center"
                justifyContent="space-between"
                style={{
                  cursor: "pointer",
                  background: helpColor,
                  borderRadius: "25px",
                  padding: 4,
                  height: "35px",
                  marginRight: "4px"
                }}
                onClick={setupAndActivateZendeskWidget}
              >
                <Box>
                  <HelpIcon />
                </Box>
                {helpText && (
                  <Box
                    sx={{
                      pl: 1,
                      pr: 1,
                      fontSize: ["0.7rem", "0.9rem", "1rem"]
                    }}
                  >
                    <Text style={{ color: "#FFFFFF" }}>
                      <FormattedMessage id={helpText} />
                    </Text>
                  </Box>
                )}
              </Flex>
            </Flex>
          )}
          {!isNative() && (
            <ProfileIcon
              {...props}
              visibility={props.isLoggedIn}
              data={props.data}
              profileImageUrl={profileImageUrl}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
};

Header.defaultProps = {};

export default Header;
