import React from "react";
import HTML_MESSAGE from "../HTML_MESSAGE";
import HTML_LINK from "../HTML_LINK";

const HTML_LINK_WITH_CTA = props => {
  const {
    data: { elementArray = [] },
    styles
  } = props;

  return (
    <div style={styles}>
      {elementArray.map((element = {}, index) => {
        const {
          type,
          properties: { styles, ...properties }
        } = element;
        const allStyles = {
          ...styles,
          display: "inline"
        };
        const rowKey = `${type}-${index}`;
        return type === "HTML_LINK" ? (
          <HTML_LINK
            key={rowKey}
            {...props}
            {...properties}
            styles={allStyles}
            containerStyles={{ display: "inline" }}
          />
        ) : (
          <HTML_MESSAGE
            key={rowKey}
            {...props}
            {...properties}
            styles={allStyles}
          />
        );
      })}
    </div>
  );
};

export default HTML_LINK_WITH_CTA;
