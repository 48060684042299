import React from "react";
import { Box, Flex } from "ui-components";
import TextField from "components/FormFields/TextField";
import DropdownField from "components/FormFields/DropdownField";
import get from "lodash/get";

const DeliveryAddressForm = ({
  fields = {},
  fieldPrefix = "delivery",
  cityOptions,
  districtOptions,
  onZipCodeChange,
  onCityChange,
  onDistrictChange,
  postalCodeValidation
}) => {
  const { city, district, street_name, zip_code } = fields;
  const postalCodeField = `${fieldPrefix}_${zip_code.field}`;
  const inlineValidation = get(postalCodeValidation, [postalCodeField], {});

  return (
    <>
      <Flex flexWrap="wrap" mx={-2} alignItems="baseline">
        {get(zip_code, "enable") && (
          <Box width={[1, 1 / 5, 1 / 5]} px={2}>
            <TextField
              inputStyle={{ minHeight: "42px" }}
              onChange={onZipCodeChange}
              {...zip_code}
              field={postalCodeField}
              inlineValidation={inlineValidation}
            />
          </Box>
        )}

        {get(city, "enable") && (
          <Box width={[1, 2 / 5, 2 / 5]} px={2}>
            <DropdownField
              onChange={onCityChange}
              options={cityOptions}
              {...city}
              field={`${fieldPrefix}_${city.field}`}
            />
          </Box>
        )}

        {get(district, "enable") && (
          <Box width={[1, 2 / 5, 2 / 5]} px={2}>
            <DropdownField
              onChange={onDistrictChange}
              options={districtOptions}
              {...district}
              field={`${fieldPrefix}_${district.field}`}
            />
          </Box>
        )}
      </Flex>

      <Flex flexWrap="wrap" mx={-2}>
        {get(street_name, "enable") && (
          <Box width={[1]} px={2}>
            <TextField
              {...street_name}
              field={`${fieldPrefix}_${street_name.field}`}
            />
          </Box>
        )}
      </Flex>
    </>
  );
};

export default DeliveryAddressForm;
