import React from "react";
import StaticContentBox from "components/StaticContentBox";

const STATIC_CONTENT_BOX = ({ data, messageData, themeColors, ...props }) => (
  <StaticContentBox
    {...data}
    {...props}
    messageData={messageData}
    themeColors={themeColors}
  />
);

export default STATIC_CONTENT_BOX;
