import React from "react";
import get from "lodash/get";

import PaymentCardDetails from "components/PaymentCardDetails";

export default {
  PAYMENT_CARD_FORM: props => {
    const {
      onGetToken,
      onChange,
      isPaymentExecuted,
      generic = {},
      enable,
      autoComplete,
      ...otherProps
    } = props;
    const cardTypes = get(generic, "payment_cards", {});

    return (
      <PaymentCardDetails
        cardTypes={cardTypes}
        onPay={onGetToken}
        onChange={onChange}
        isPaymentExecuted={isPaymentExecuted}
        isEnabled={enable}
        isAutoComplete={autoComplete}
        {...otherProps}
      />
    );
  }
};
