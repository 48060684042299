import { COLORS } from "ui-components/theme";
import { SECONDARY_COLOR, PRIMARY_COLOR } from "utils/constants";

const styles = {
  fullPage: {
    color: SECONDARY_COLOR || COLORS.blue[4],
    size: 150,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    paddingTop: "35vh",
    justifyContent: "center"
  },
  modalContainer: {
    size: 100,
    color: PRIMARY_COLOR,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center"
  },
  button: {
    size: 19,
    width: "100%",
    height: "100%",
    color: COLORS.white,
    alignItems: "center",
    justifyContent: "center"
  },
  inlineMessage: {
    size: 19,
    width: "100%",
    height: "100%",
    color: COLORS.blue[4],
    alignItems: "center",
    justifyContent: "center"
  },
  halfModal: {
    color: SECONDARY_COLOR || COLORS.blue[4],
    size: 150,
    backgroundColor: "white",
    position: "absolute",
    top: 0,
    left: 0,
    width: "inherit",
    height: "100%",
    paddingTop: "50%",
    justifyContent: "center",
  },
};

export default styles;
