import { useDispatch, useSelector } from "react-redux";
import { makeSelectAuthToken } from "ducks/selectors";
import { paths } from "utils/constants";
import { getRedirectURL, isWebview } from "utils/localStorage";
import { gotoUrl, pathWithChannel } from "utils/helpers";
import { setData, UserService } from "ducks/actions";

/**
 * The hook is taking the ownership of linking social account details
 * and redirecting to correct route on both success and failure scenarios
 * @returns function to post linking social account details
 */
function useLinkUserAccount() {
  const dispatch = useDispatch();
  const currentAuthToken = useSelector(makeSelectAuthToken());

  const postLinkUserDetails = payload => {
    dispatch(
      UserService.V4.requestLinkSocialAccount(
        payload,
        onPostLinkDetailsCallback
      )
    );
  };

  const showModal = type => {
    dispatch(
      setData({
        key: "isModalView",
        data: { isModalView: true, type }
      })
    );
  };

  const onPostLinkDetailsCallback = (_, errors) => {
    const mobileParams = {
      auth_token: currentAuthToken
    };
    if (errors) {
      if (isWebview()) showModal("errorLinkAccount");
      else
        gotoUrl(pathWithChannel(paths.LOG_IN, "web"), false, {}, false, {
          reset: true
        });
    } else {
      // redirect back to mobile app by calling  redirect url
      if (isWebview()) showModal("linkAccountSuccess");
      else gotoUrl(getRedirectURL(), false, {}, true, mobileParams);
    }
  };

  return postLinkUserDetails;
}

export default useLinkUserAccount;
