import { createSelector } from "reselect";
import get from "lodash/get";
import { constructPostalCodeValidation } from "utils/helpers";

export const validatePostalCodeDomain = state =>
  get(state, "api.validatePostalCode", {});

export const validatePostalCodeSelector = createSelector(
  validatePostalCodeDomain,
  constructPostalCodeValidation
);

export const makeSelectPostalCodeValidation = () => validatePostalCodeSelector;
