import { call, put, select, delay } from "redux-saga/effects";
import makeRequest from "utils/request";
import {
  USER_SERVICE_URL,
  USER_SERVICE_VERSION_PATH,
  USER_SERVICE_REFRESH_TOKEN,
  DEFAULT_API_VERSION,
  BASE
} from "utils/constants";
import { getDeviceId } from "utils/localStorage";
import { updateAuthToken, refreshTokenInProgress } from "ducks/actions";
import { isTokenRefreshInProgress } from "ducks/selectors";
import { fetchResponse } from "./";
import { updateTokenToApp } from "utils/helpers";

/**
 * requests the new token as app refresh tokeninterval has expired
 * Once the new token is received it will be updated in the state
 * with the new expiry time
 * @param {string} currentToken current auth toke
 */
export default function* refreshToken(
  currentToken,
  retryAttempt = 0,
  retryRecaptchaAttempt = 0,
  requestedURL
) {
  const requestURL = `${USER_SERVICE_URL}${USER_SERVICE_VERSION_PATH}${USER_SERVICE_REFRESH_TOKEN}`.replace(
    "{VERSION}",
    DEFAULT_API_VERSION[BASE.USER_SERVICE]
  );
  const headers = {
    accept: "application/json",
    "X-AUTH": currentToken,
    "X-DeviceId": getDeviceId()
  };
  const options = {
    method: "GET",
    headers
  };
  try {
    const isFetching = yield select(isTokenRefreshInProgress);
    if (isFetching) {
      log("Refresh token in progress. Going to sleep", requestedURL);
      // This is basically to provide a window to finish refresh token call, before the next retry
      yield sleepUntilRefreshTokenSuccess(requestedURL);
      log("Sleep finish for ", requestedURL);
      return;
    } else {
      yield put(refreshTokenInProgress());
      log("Setting refresh token call inprogress", requestedURL);
    }

    const { success, result = {}, error } = yield call(
      fetchResponse,
      makeRequest,
      requestURL,
      options,
      null,
      retryAttempt,
      retryRecaptchaAttempt
    );
    yield put(refreshTokenInProgress(false));

    if (error) {
      console.error("Refresh token failed", error, error.response);
      return;
    }

    if (success) {
      const { auth_token: newToken } = result;
      yield put(updateAuthToken(newToken));
      updateTokenToApp(newToken);
      log("Update token completed", requestedURL);
    }
  } catch (error) {
    yield put(refreshTokenInProgress(false));
    console.error(
      "Refresh token request threw an error",
      error,
      error.response
    );
  }
}

function* sleepUntilRefreshTokenSuccess() {
  const MAX_ATTEMPTS = 30;
  const INTERVAL = 2000;

  for (let attempt = 0; attempt < MAX_ATTEMPTS; attempt++) {
    yield delay(INTERVAL);
    const isRefreshTokenFetching = yield select(isTokenRefreshInProgress);
    if (!isRefreshTokenFetching) break;
  }
  return;
}

function log(...args) {
  console.log(`${new Date()}: refreshToken():`, ...args);
}
