import React from "react";
import { Box, Text } from "ui-components";
import EmbeddedMap from "components/EmbeddedMap";

const mapStyle = {
  marginTop: 16,
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 0
};

const PostOfficeCard = ({
  name,
  fullAddress,
  workingHours,
  mapImage,
  showMap
}) => {
  return (
    <Box p={1}>
      <Text lineHeight={1.5} fontSize={2} fontWeight="bold">
        {name}
      </Text>
      <Text lineHeight={1.5} fontWeight="300" size="sm">
        {fullAddress}
      </Text>
      <Text lineHeight={1.5} fontWeight="300" size="sm">
        {workingHours}
      </Text>
      {showMap && (
        <EmbeddedMap
          mapImage={mapImage}
          locationName={fullAddress}
          style={mapStyle}
        />
      )}
    </Box>
  );
};

PostOfficeCard.defaultProps = {
  name: "",
  fullAddress: "",
  workingHours: "",
  mapImage: null
};

export default PostOfficeCard;
