import styled from "@emotion/styled";
import { Text } from "rebass";

const P = styled(Text)``;

P.defaultProps = {
  color: "gray.3",
  as: "p",
  fontSize: [14, 16, 16],
  letterSpacing: "0.5px",
  lineHeight: 1.5,
  textAlign: "justify"
};

export default P;
