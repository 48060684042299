import React from "react";
import { Flex, Text } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import Label from "components/FormFields/Label";
import TextField from "components/FormFields/TextField";
import { normalizerTypes } from "normalizers";

const ICC_ID_NRIC = ({
  iccIdPrefix,
  label,
  iccIdValid,
  iccIdError,
  required,
  resetValidateIccId,
  themeColors,
  ...props
}) => {
  return (
    <Flex
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "fit-content",
        flexDirection: "column",
        margin: "0 auto"
      }}
    >
      {label && (
        <Flex sx={{ justifyContent: "flex-start", width: "100%" }}>
          <Label label={<FormattedMessage id={label} />} required={required} />
        </Flex>
      )}
      <Flex sx={{ justifyContent: "center", alignItems: "baseline" }}>
        <Text mb={8} mr={2}>
          <input
            type="text"
            readOnly
            value={iccIdPrefix}
            style={{ textAlign: "center" }}
          />
        </Text>
        <TextField
          {...props}
          onChange={() => resetValidateIccId && resetValidateIccId()}
        />
      </Flex>
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "baseline",
          width: "100%"
        }}
      >
        <TextField
          label="personal_details_nric"
          placeholder="personal_details_nric_placeholder"
          normalize={normalizerTypes.UPPERCASE({ type: "UPPERCASE" })}
          required
          field="nric"
          sx={{ width: "100%" }}
          onChange={() => resetValidateIccId && resetValidateIccId()}
        />
      </Flex>
    </Flex>
  );
};

export default ICC_ID_NRIC;
