import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { getURLParams, gotoUrl } from "utils/helpers";
import { getTxnId } from "utils/localStorage";
import { loadStripe } from "@stripe/stripe-js";
import { Flex } from "ui-components";
import { Elements } from "@stripe/react-stripe-js";
import Spinner, { styles } from "components/Spinner";
import StripeCheckoutForm from "components/StripeCheckout";
import FormattedMessage from "components/FormattedMessage";

const StripeUpdateCard = ({
  isFetchingPaymentCardDetails,
  paymentDetails,
  saveAndGetPaymentCardDetails,
  currencyCode,
  userSIN,
  data
}) => {
  const maxTimer = get(data, "max_timer", 5);
  const loading = isEmpty(paymentDetails) || isFetchingPaymentCardDetails;
  const { sin, return_path } = getURLParams();
  const serviceInstanceNumber = sin || userSIN;
  const returnPath = return_path || get(data, "return_path");
  const updateSuccessMessage = get(data, "update_success_msg");

  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [timer, setTimer] = useState(maxTimer);
  const history = useHistory();

  useEffect(() => {
    if (isEmpty(paymentDetails) && !isFetchingPaymentCardDetails) {
      saveAndGetPaymentCardDetails(
        {
          paymentMethod: "card",
          txn_id: getTxnId(),
          service_instance_number: serviceInstanceNumber,
          amount: 0,
          currency: currencyCode,
          additional_data: {
            custom_payment_method: "card"
          },
          notification_channel: {},
          instrument_data: {
            redirect_url: window.location.href
          }
        },
        null,
        "v3"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDetails, isFetchingPaymentCardDetails]);

  const publishable_api_key = get(paymentDetails, "stripe.publishable_api_key");
  const intent_confirmation = get(paymentDetails, "stripe.intent_confirmation");
  const client_secret = get(paymentDetails, "stripe.intent.client_secret");
  const amount = get(paymentDetails, "stripe.intent.amount");

  const stripePromise = publishable_api_key && loadStripe(publishable_api_key);

  const onPaymentSuccess = () => {
    setUpdateSuccess(true);
    let num = 1;
    const interval = setInterval(() => {
      if (num >= maxTimer) {
        clearInterval(interval);
        if (returnPath) {
          gotoUrl(
            returnPath,
            true,
            { sin: undefined, return_path: undefined },
            false
          );
        } else {
          history.goBack();
        }
      }
      setTimer(maxTimer - num);
      num++;
    }, 1000);
  };

  return (
    <Flex
      minHeight={"282px"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection="column"
    >
      {loading ? (
        <Spinner size={200} sx={styles.modalContainer} />
      ) : (
        <Elements stripe={stripePromise}>
          <StripeCheckoutForm
            clientSecret={client_secret}
            intentConfirmation={intent_confirmation}
            amount={amount}
            onPaymentSuccess={onPaymentSuccess}
          />
        </Elements>
      )}
      {updateSuccess && (
        <Flex
          style={{
            borderRadius: "14px",
            backgroundColor: "rgba(121,222,0,0.2)",
            padding: "16px 25px",
            marginTop: "10px"
          }}
        >
          <FormattedMessage id={updateSuccessMessage} values={{ timer }} />
        </Flex>
      )}
    </Flex>
  );
};

StripeUpdateCard.defaultProps = {
  data: { update_success_msg: "update_credit_card_success_msg" },
  saveAndGetPaymentCardDetails: () => {}
};

export default StripeUpdateCard;
