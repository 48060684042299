const styles = {
  cursor: "pointer",
  borderRadius: 32,
  py: 2,
  mx: -1,
  width: "calc(50% + 0.25rem)"
};

const pillStyles = {
  cursor: "pointer",
  borderRadius: 32,
  py: 2,
  width: "100%",
  height: "fit-content"
};

export { styles, pillStyles };
