import React from "react";
import DropdownField from "components/FormFields/DropdownField";
import { constructOnChangeHandler } from "utils/helpers";

const COUNTRY_OF_ISSUE = ({ issuingCountries, ...props }) => (
  <DropdownField
    options={issuingCountries}
    {...props}
    onChange={constructOnChangeHandler(props)}
  />
);

export default COUNTRY_OF_ISSUE;
