import React, { useEffect, useState } from "react";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, Text, Tiles, H2, Image, Badge } from "ui-components";
import { styles, pillStyles } from "./styles";
import { tabTypes } from "utils/constants";
import map from "lodash/map";
import get from "lodash/get";
import find from "lodash/find";
import { safeEval } from "utils/helpers";

const SwitchContainer = props => {
  const {
    type,
    items,
    itemWidth = "100%",
    themeColors,
    inactiveTabColor
  } = props;
  const bgColor =
    inactiveTabColor || get(themeColors, "button.backgroundColor", "blue.0");
  const regularScreenItemsPerRow = items.length;
  const mobileScreenItemsPerRow = items.length > 3 ? 3 : items.length;
  switch (type) {
    case tabTypes.PILL:
      return (
        <Tiles
          style={{ alignItems: "end", textAlign: "center", width: itemWidth }}
          columns={[mobileScreenItemsPerRow, regularScreenItemsPerRow]}
          mb={6}
          data-testid="pill-container"
        >
          {props.children}
        </Tiles>
      );
    default:
      return (
        <Flex
          mb={6}
          justifyContent={items.length > 1 ? "center" : "start"}
          bg={items.length > 1 ? bgColor : "transparent"}
          style={{ borderRadius: 32, width: "100%" }}
          data-testid="default-container"
        >
          {props.children}
        </Flex>
      );
  }
};

const Tab = props => {
  const {
    item,
    type,
    selected,
    applyImageStyles,
    activeColor = "blue.4",
    inactiveColor = "blue.0",
    additionalStyles = {},
    onClick,
    readOnly,
    badges = {},
    themeColors
  } = props;

  const { value, name } = item;
  const badge = get(badges, name, {});
  const onTabClick = () => !readOnly && onClick(item);

  const selectedTabStyle = selected
    ? { bg: activeColor, zIndex: 100 }
    : { zIndex: 99 };
  const defaultStyles = { ...selectedTabStyle, ...additionalStyles };

  switch (type) {
    case tabTypes.PILL: {
      const positioningStyles = !applyImageStyles && {
        justifyContent: "center"
      };
      return (
        <Box>
          <Badge {...badge} themeColors={themeColors} />
          <Flex
            sx={{
              bg: inactiveColor,
              borderRadius: "1rem",
              ...pillStyles,
              ...positioningStyles,
              ...defaultStyles
            }}
            mx="auto"
            data-testid={`pill-toggle-${value}`}
            onClick={onTabClick}
          >
            {props.children}
          </Flex>
        </Box>
      );
    }
    default:
      return (
        <Box
          sx={{ ...styles, ...defaultStyles }}
          data-testid={`toggle-${value}`}
          onClick={onTabClick}
        >
          {props.children}
        </Box>
      );
  }
};

const ToggleSwitch = props => {
  const {
    type,
    items: defaultItems = [],
    value,
    valueAttribute,
    onClick,
    badges,
    icons,
    themeColors,
    textColor,
    inactiveTextColor,
    tabColor,
    inactiveTabColor,
    shouldHide,
    data,
    override_items,
    toggleSwitchRefresh
  } = props;

  const title = get(data, "title");
  const readOnly = get(data, "readOnly");
  const [items, setItem] = useState(defaultItems);

  useEffect(() => {
    let itemList = defaultItems;
    if (!toggleSwitchRefresh) {
      if (override_items) {
        const isOverrideItems = safeEval(
          get,
          {},
          override_items.condition,
          false,
          props
        );
        itemList = isOverrideItems ? override_items.items : defaultItems;
        setItem(itemList);
      }
      if (!value) {
        const defaultItem = find(itemList, "default");
        const defaultValue = get(defaultItem, valueAttribute);
        defaultValue && onClick(defaultItem);
      }
    }
    // eslint-disable-next-line
  }, [toggleSwitchRefresh]);

  if (shouldHide) return <></>;

  return (
    <>
      {title && (
        <H2 data-testid="toggle_switch_title" mb={4}>
          <FormattedMessage id={title} />
        </H2>
      )}
      <SwitchContainer {...props} width="100%">
        {map(items, item => {
          const name = get(item, "name", "");
          const selected = get(item, valueAttribute) === value;
          const iconImageUrl = selected
            ? get(icons, `${name}.imageUrl`)
            : get(icons, `${name}.inactiveImageUrl`);
          const tabTextColor = selected
            ? get(icons, `${name}.textColor`) || textColor
            : get(icons, `${name}.inactiveTextColor`) || inactiveTextColor;
          const activeColor =
            get(icons, `${name}.tabColor`) ||
            tabColor ||
            get(themeColors, "button.color", "blue.4");
          const inactiveColor =
            get(icons, `${name}.inactiveTabColor`) ||
            inactiveTabColor ||
            get(themeColors, "button.backgroundColor", "blue.0");
          const showOnlyWhenSelected = get(
            icons,
            `${name}.showOnlyWhenSelected`,
            false
          );
          const showImage =
            !!iconImageUrl && (showOnlyWhenSelected ? selected : true);
          const textPaddingForImage = showImage
            ? { padding: "inherit", paddingBottom: "inherit" }
            : { padding: "8px", paddingBottom: "8px" };
          const iconSize = [30, 36, 36];
          const paddingRight = [10, 36, 36];
          const customStyles = get(item, "sx", {});
          return (
            <Tab
              key={item.value}
              type={type}
              item={item}
              selected={selected}
              onClick={onClick}
              readOnly={readOnly}
              applyImageStyles={showImage}
              badges={badges}
              activeColor={activeColor}
              inactiveColor={inactiveColor}
              themeColors={themeColors}
            >
              <Flex alignItems="center" width="100%">
                {iconImageUrl && (
                  <Box
                    sx={{
                      width: iconSize,
                      height: iconSize,
                      m: ["0 8px"]
                    }}
                  >
                    {showImage && (
                      <Image
                        src={iconImageUrl}
                        sx={{
                          width: iconSize,
                          height: iconSize,
                          borderRadius: 9999
                        }}
                      />
                    )}
                  </Box>
                )}
                <Flex
                  alignItems="center"
                  height={iconSize}
                  width={showImage ? "calc(100% - 52px)" : "100%"}
                  pr={showImage ? paddingRight : 0}
                  justifyContent="space-around"
                >
                  <FormattedMessage id={item.label || "unknown"} values={item}>
                    {msg => (
                      <Text
                        color={tabTextColor || "white"}
                        textAlign="center"
                        fontWeight="bold"
                        lineHeight="1.2"
                        sx={{
                          ...textPaddingForImage,
                          padding: "0px",
                          fontSize: ["0.8rem", "1.4rem", "1.4rem"],
                          ...customStyles
                        }}
                      >
                        {msg}
                      </Text>
                    )}
                  </FormattedMessage>
                </Flex>
              </Flex>
            </Tab>
          );
        })}
      </SwitchContainer>
    </>
  );
};

ToggleSwitch.defaultProps = {
  items: []
};

export default ToggleSwitch;
