import React from "react";
import get from "lodash/get";
import { Box } from "ui-components";
import PromoCodeBox from "components/PromoCodeBox";

const PROMO_CODE_BOX = props => {
  const {
    defaultPromoCodeOffers,
    resetReferralCode,
    validateReferralCode,
    referralCode,
    locale,
    data = {},
    themeColors,
    planName,
    selectedPhone
  } = props;
  const { loading, valid, reason, value, title, benefits } = referralCode || {};
  const deviceSku = selectedPhone ? selectedPhone.deviceSku : null;

  return (
    <Box mb={8}>
      <PromoCodeBox
        loading={loading}
        valid={valid}
        reason={reason}
        defaultValue={value}
        defaultPromoCodeOffers={defaultPromoCodeOffers}
        onChange={resetReferralCode}
        title={title}
        benefits={benefits}
        locale={locale}
        onSubmit={code => validateReferralCode(code, planName, deviceSku)}
        label={get(data, "title")}
        readonly={get(data, "readonly")}
        hideBenefits={get(data, "hideBenefits")}
        themeColors={themeColors}
      />
    </Box>
  );
};

export default PROMO_CODE_BOX;
