import { Box, Flex, Label as RebassLabel, Text } from "ui-components";
import React from "react";
import { Field } from "redux-form";
import { COLORS } from "ui-components/theme";
import map from "lodash/map";
import { RadioboxBlank, RadioboxMarked } from "../Icons";
import FormattedMessage from "../FormattedMessage";
import { ReactComponent as EditIcon } from "assets/icons/icon-edit.svg";
import { Radio } from "../FormFields/RadioGroupProviders";
import isFunction from "lodash/isFunction";
import { safeEval } from "utils/helpers";
import get from "lodash/get";

export const RadioOptions = props => {
  const { input, options = [], appSettings, onEditPayment } = props;

  const onClickEvent = ({ type, path }) => {
    if (onEditPayment && isFunction(onEditPayment)) {
      onEditPayment && onEditPayment(type, path);
    }
  };

  return (
    <>
      {map(options, item => {
        const {
          disabled_switch_method,
          imageMaxWidth,
          method_name,
          message_data,
          method_value,
          urlLookUp
        } = item || {};
        const checked = item.id.toString() === input.value.toString();
        const enable = safeEval(get, {}, item.condition, false, props);
        const paymentMethodUrl = get(appSettings, urlLookUp);

        if (enable) {
          return (
            <Box
              variant="borderSection"
              key={item.id}
              padding={[2, 2]}
              sx={{
                px: 1,
                py: 1,
                borderWidth: "2px",
                cursor: "pointer",
                borderColor: "#ffff",
                backgroundColor: COLORS.white
              }}
              mb={5}
              width={[1]}
            >
              <RebassLabel
                my={1}
                mr="5px"
                style={{
                  cursor: disabled_switch_method ? "default" : "pointer",
                  padding: "1px",
                  paddingLeft: "10px",
                  paddingRight: "10px"
                }}
              >
                <Flex
                  sx={{
                    alignSelf: "center",
                    opacity: disabled_switch_method ? 0.4 : 1
                  }}
                >
                  <Radio
                    {...input}
                    sx={{ paddingTop: "10px", alignSelf: "center" }}
                    value={item.id}
                    checked={checked}
                    disabled={disabled_switch_method}
                  />
                  <Box
                    width="25px"
                    sx={{ alignSelf: "center", marginRight: "20px" }}
                  >
                    {checked ? (
                      <RadioboxMarked
                        size="20px"
                        style={{ margin: 0 }}
                        color="black"
                        alignSelf="center"
                      />
                    ) : (
                      <RadioboxBlank
                        size="20px"
                        style={{ margin: 0 }}
                        color="#333"
                        alignSelf="center"
                      />
                    )}
                  </Box>
                  <Box width={[imageMaxWidth]} mx={2}>
                    <img alt={"label"} src={paymentMethodUrl} />
                  </Box>
                </Flex>

                <Box sx={{ alignSelf: "center", width: "100%" }}>
                  <Flex sx={{ justifyContent: "right" }}>
                    <Box mx={2}>
                      {method_name && (
                        <Text
                          style={{
                            wordBreak: "break-word",
                            fontWeight: "500"
                          }}
                        >
                          <FormattedMessage id={method_name} />
                        </Text>
                      )}

                      {method_value && (
                        <Text
                          style={{
                            wordBreak: "break-word",
                            fontWeight: "800",
                            textAlign: "end",
                            fontSize: "13px",
                            paddingTop: "2px"
                          }}
                        >
                          <FormattedMessage
                            id={method_value}
                            values={
                              message_data
                                ? { last_four_digits: get(props, message_data) }
                                : {}
                            }
                          />
                        </Text>
                      )}
                    </Box>

                    <Box
                      data-testid="edit_button"
                      onClick={() => onClickEvent(item)}
                      sx={{
                        cursor: "pointer",
                        alignSelf: "center",
                        marginLeft: "2px"
                      }}
                    >
                      <EditIcon />
                    </Box>
                  </Flex>
                </Box>
              </RebassLabel>
            </Box>
          );
        }
      })}
    </>
  );
};

const PaymentMethodRadioGroup = props => {
  const { field, data: { supportedPaymentMethods, path } = {} } = props;
  return (
    <Box data-testid={field}>
      <Field
        {...props}
        component={RadioOptions}
        name={field}
        path={path}
        options={supportedPaymentMethods}
      />
    </Box>
  );
};

export default PaymentMethodRadioGroup;
