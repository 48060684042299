import React, { useState, useEffect, useRef } from "react";
import { Flex, Box, Button } from "ui-components";
import { Field } from "redux-form";
import FormattedMessage from "components/FormattedMessage";
import Label from "components/FormFields/Label";
import FieldError from "components/FormFields/FieldError";
import SignatureCanvas from "react-signature-canvas";
import { Restore } from "components/Icons";
import get from "lodash/get";
import DropIdentity from "components/DropIdentity";
import {
  getButtonColorVariant,
  buttonThemes,
  iconThemes
} from "ui-components/theme";

const renderField = ({
  tooltip,
  height = 180,
  signatureCanvasRef,
  label,
  required,
  meta: { touched, error },
  resetSignature,
  enableUploadSignature,
  uploadButtonText,
  buttonTheme,
  onUpload,
  onBegin,
  onEnd,
  isWebview,
  isMobile
}) => {
  return (
    <>
      <Flex
        flexWrap="wrap"
        flexDirection="column"
        mb={4}
        alignItems="flex-start"
      >
        {label && (
          <Label
            label={<FormattedMessage id={label} />}
            tooltip={tooltip}
            required={required}
          />
        )}
        <SignatureCanvas
          ref={signatureCanvasRef}
          onBegin={onBegin}
          onEnd={onEnd}
          penColor="black"
          clearOnResize={false}
          canvasProps={{
            style: {
              height,
              width: "100%",
              margin: "0.5rem 0",
              border: "1px dashed #999999"
            }
          }}
          backgroundColor="white"
        />
        <Flex>
          {isWebview || isMobile ? null : (
            <DropIdentity
              uploadButton={enableUploadSignature}
              onUpload={onUpload}
              uploadButtonText={uploadButtonText}
              uploadButtonSx={{
                ...getButtonColorVariant(buttonTheme, "outline"),
                ml: "1rem",
                mr: "3rem",
                mt: "4px",
                mb: "4px"
              }}
            />
          )}
          <Button
            sx={getButtonColorVariant(buttonTheme, "outline")}
            p={"0.25rem 0.5rem"}
            variant="outline"
            onClick={resetSignature}
          >
            <Flex alignItems="center">
              <Restore
                size={"30px"}
                color={get(buttonTheme, "color", iconThemes.blue)}
              />
              <FormattedMessage id="reset_signature" />
            </Flex>
          </Button>
        </Flex>
        {enableUploadSignature && (
          <Box mt={2}>
            <FormattedMessage id="upload_signature_constraints" />
          </Box>
        )}
        <FieldError touched={touched} error={error} />
      </Flex>
    </>
  );
};

const ESignField = ({
  field,
  label,
  change,
  readOnly,
  required,
  sx,
  formValues,
  themeColors,
  enableUploadSignature = false,
  uploadButtonText = "upload_signature_button",
  isWebview,
  isMobile
}) => {
  const signatureCanvasRef = useRef();
  const value = get(formValues, field);
  const [signatureUploaded, setSignatureUploaded] = useState(false);
  const buttonTheme = get(themeColors, "button") || buttonThemes.blue;

  useEffect(() => {
    if (get(signatureCanvasRef, "current")) {
      signatureCanvasRef.current.isEmpty() &&
        signatureCanvasRef.current.fromDataURL(value);
    }
  }, [value, signatureCanvasRef]);

  useEffect(() => {
    if (get(signatureCanvasRef, "current") && signatureUploaded) {
      signatureCanvasRef.current.clear();
      signatureCanvasRef.current.fromDataURL(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signatureUploaded]);

  if (!field) {
    return null;
  }
  const getESignBase64ImageFromCanvas = signatureCanvasRef => {
    const data = signatureCanvasRef?.current
      ?.getTrimmedCanvas()
      ?.toDataURL("image/png");
    change(field, data);
    return data;
  };

  const resetSignature = () => {
    get(signatureCanvasRef, "current") && signatureCanvasRef.current.clear();
    change(field, null);
    setSignatureUploaded(false);
  };

  const onBegin = () => {
    if (signatureUploaded) {
      get(signatureCanvasRef, "current") && signatureCanvasRef.current.clear();
      return setSignatureUploaded(false);
    }
  };

  const onEnd = () => {
    signatureCanvasRef?.current &&
      getESignBase64ImageFromCanvas(signatureCanvasRef);
  };

  return (
    <Box mb={8} data-testid={field}>
      <Field
        readOnly={readOnly}
        name={field}
        required={required}
        component={renderField}
        label={label}
        isWebview={isWebview}
        isMobile={isMobile}
        sx={sx}
        buttonTheme={buttonTheme}
        resetSignature={resetSignature}
        value={value}
        signatureCanvasRef={signatureCanvasRef}
        getESignBase64ImageFromCanvas={getESignBase64ImageFromCanvas}
        onUpload={imageStr => {
          change(field, imageStr);
          enableUploadSignature && setSignatureUploaded(true);
        }}
        onBegin={onBegin}
        onEnd={onEnd}
        enableUploadSignature
        uploadButtonText={uploadButtonText}
      />
    </Box>
  );
};

ESignField.defaultProps = {
  label: ""
};

export default ESignField;
