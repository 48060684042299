import get from "lodash/get";
import { forms } from "utils/constants";
import { createSelector } from "reselect";

const paymentMethodDetailsDomain = state =>
  get(state, `form.${forms.PAYMENT_METHOD_UPDATE_FORM}.values`, {});

export const switchPaymentMethodSelector = createSelector(
  paymentMethodDetailsDomain,
  Details => get(Details, "payment_method")
);

export const makeSelectSwitchPaymentMethod = () => switchPaymentMethodSelector;
