import React from "react";
import RadioGroup from "components/FormFields/RadioGroup";
import { evalProp } from "utils/helpers";

const VERTICAL_RADIO = ({ data: { ...dataProps }, ...props }) => {
  return (
    <RadioGroup
      {...dataProps}
      {...props}
      options={evalProp(props, dataProps.options, [])}
      width={"100%"}
    ></RadioGroup>
  );
};

export default VERTICAL_RADIO;
