import React, { useEffect } from "react";
import map from "lodash/map";
import get from "lodash/get";
import FormattedMessage from "components/FormattedMessage";
import { Flex } from "ui-components";
import DropdownField from "components/FormFields/DropdownField";
import TextField from "components/FormFields/TextField";
import FieldError from "components/FormFields/FieldError";
import { Magnify, Close, EmoticonHappyOutline } from "components/Icons";

const NumberSearch = ({
  numberSeries,
  numberLockError,
  clearNumberLockError,
  selectNumber,
  formattedSelectedNumber,
  formValues,
  change,
  data = {},
  themeColors
}) => {
  const selectedNumber = get(formattedSelectedNumber, "number", "");
  const prefix = get(formValues, "numberSearchPrefix.value", "");
  const suffix = get(formValues, "numberSearchSuffix", "");
  const searchedNumber = prefix + suffix;
  const searchSelected = searchedNumber === selectedNumber;
  const numberSeriesOptions = map(numberSeries, number => ({
    label: number,
    value: number
  }));
  const minLength = get(data, "minLength", 0);
  const valid = prefix && suffix && suffix.length >= minLength;
  const message = get(data, "message");

  const handleNumberSearch = () =>
    numberLockError
      ? change("numberSearchSuffix", "") && clearNumberLockError()
      : valid && !searchSelected && selectNumber(searchedNumber);

  useEffect(() => {
    if (searchedNumber && !searchSelected) {
      const { readonly, autoSelectFirstOption, autoSelectOnlyOption } = data;
      if (!(readonly || autoSelectFirstOption || autoSelectOnlyOption)) {
        change("numberSearchPrefix", "");
      }
      change("numberSearchSuffix", "");
      clearNumberLockError();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNumber]);

  return (
    <>
      {message && (
        <Flex
          sx={{
            justifyContent: "center",
            alignItems: "baseline",
            maxWidth: "30rem",
            margin: "auto",
            marginBottom: "1rem"
          }}
        >
          <FormattedMessage id={message} />
        </Flex>
      )}
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "baseline",
          maxWidth: "18rem",
          margin: "auto",
          marginBottom: "0"
        }}
      >
        <DropdownField
          sx={{ flex: 1, maxWidth: "8rem", paddingLeft: "2rem" }}
          options={numberSeriesOptions}
          field="numberSearchPrefix"
          {...data}
        />
        <Flex sx={{ flex: 1 }} justifyContent="space-between">
          <TextField
            type="number"
            normalize={value => (value ? value.replace(/\D/g, "") : value)}
            field="numberSearchSuffix"
            inputStyle={{
              minHeight: "42px",
              alignSelf: "flex-end",
              width: "100%"
            }}
            onChange={() => {
              numberLockError && clearNumberLockError();
            }}
            onEnter={handleNumberSearch}
            {...data}
          />
          <Flex
            onClick={handleNumberSearch}
            sx={{
              cursor: valid ? "pointer" : "not-allowed",
              width: "fit-content",
              marginBottom: "32px",
              position: "relative",
              right: "2rem"
            }}
          >
            {searchSelected ? (
              <EmoticonHappyOutline color={themeColors?.success} />
            ) : numberLockError ? (
              <Close color={themeColors?.error} />
            ) : (
              <Magnify theme={!valid ? "gray" : themeColors?.icon} />
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        sx={{
          minHeight: "16px",
          position: "relative",
          bottom: "18px",
          justifyContent: "center"
        }}
      >
        {numberLockError && (
          <FieldError touched={true} error={"number_not_available"} />
        )}
      </Flex>
    </>
  );
};

export default NumberSearch;
