import React from "react";
import DropIdentity from "components/DropIdentity";
import { Field } from "redux-form";
import { Box } from "ui-components";
import FieldError from "./FieldError";

const renderField = ({
  input,
  testid,
  label,
  type,
  frontOrBack,
  loading,
  onFileDrop,
  completed,
  accept,
  maxSize,
  meta: { touched, error }
}) => {
  const dropIdentityProps = {
    testid,
    label,
    type,
    frontOrBack,
    loading,
    onDrop: onFileDrop,
    onChange: input.onChange,
    completed,
    accept,
    maxSize
  };

  return (
    <Box data-testid={`${testid}_box`}>
      <DropIdentity {...dropIdentityProps} />
      <FieldError touched={touched} error={error} />
    </Box>
  );
};

const DropIdentityField = props => {
  const {
    field,
    pattern,
    label,
    type,
    frontOrBack,
    loading,
    onDrop,
    completed,
    accept,
    placeholder,
    onChange,
    normalize,
    render,
    data,
    validate,
    testid
  } = props;
  const name = field || "unnamedidentityuploader";
  return (
    <Box mb={8}>
      <Field
        name={name}
        component={renderField}
        type={type}
        frontOrBack={frontOrBack}
        loading={loading}
        onFileDrop={onDrop}
        completed={completed}
        accept={accept}
        pattern={pattern}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        normalize={normalize}
        render={render}
        data={data}
        validate={validate}
        testid={testid}
      />
    </Box>
  );
};

DropIdentityField.defaultProps = {
  data: {},
  label: ""
};

export default DropIdentityField;
