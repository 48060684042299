import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Box, Button, Flex, MaskedInput, IMask } from "ui-components";
import moment from "moment";
import FormattedMessage from "components/FormattedMessage";
import Label from "components/FormFields/Label";
import {
  buttonThemes,
  iconThemes,
  getButtonColorVariant
} from "ui-components/theme";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import forEach from "lodash/forEach";
import sum from "lodash/sum";

import PaymentCardTypes from "./PaymentCardTypes";

const CardFieldContainer = styled.div`
  position: relative;
`;

const CardTypeIndicator = styled(PaymentCardTypes)`
  position: absolute;
  right: -8px;
  top: -3px;
`;

const SecurityCodeImage = styled.img`
  width: 55px;
  margin: -38px 0 0 auto;
`;

const DetailsContainer = styled(Flex)`
  > div + div {
    margin-top: 35px;
  }
`;

const getPattern = (pattern, index) =>
  Array.isArray(pattern?.[index]) ? pattern[index] : pattern;

function PaymentCardDetails({
  cardTypes,
  isPaymentExecuted,
  isEnabled,
  onPay,
  onChange,
  themeColors,
  isAutoComplete,
  data = {
    hideButton: true,
    hideBorder: true,
    hideCardTypes: false,
    supportedCards: []
  }
}) {
  const [cardNumber, setCardNumber] = useState(null);
  const [expiryDate, setExpiryDate] = useState("");
  const [code, setCode] = useState("");
  const [cardType, setCardType] = useState(null);
  const [isValid, setIsValid] = useState(false);
  const [cardMask, setCardMask] = useState("0000 0000 0000 0000");
  const [gapPattern, setGapPattern] = useState(
    getPattern(cardType?.gapPattern, 0)
  );

  const getCardNumberLength = sum(gapPattern || [16]);
  const getCardLength = cardType?.securityCode?.length || 3;
  const getSecurityCodeImage = cardType?.securityCode?.image || "";

  const isCardValid = useCallback(() => {
    return (
      !!cardNumber &&
      cardNumber.length === getCardNumberLength &&
      !!expiryDate &&
      expiryDate.length === 5 &&
      moment(expiryDate, "MM-YY").isAfter(new Date(), "year month") &&
      !!code &&
      code.length === getCardLength
    );
  }, [cardNumber, expiryDate, code, getCardLength, getCardNumberLength]);

  const buildCardObject = useCallback(
    () => ({
      cardNumber,
      expiryDate: moment(expiryDate, "MM-YY").format("YYMM"),
      code,
      cardType,
      isValid
    }),
    [cardNumber, expiryDate, code, cardType, isValid]
  );

  // run validation on data change
  useEffect(() => {
    setIsValid(isCardValid());
  }, [cardNumber, expiryDate, code, setIsValid, isCardValid]);

  // isPaymentExecuted prop is used to run onPay method remotely
  useEffect(() => {
    if (isPaymentExecuted) {
      onPay(buildCardObject());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentExecuted]);

  // run onChange method on every data change
  useEffect(() => {
    onChange(buildCardObject());
  }, [buildCardObject, onChange]);

  useEffect(() => {
    setCardMask(getCardMask(gapPattern || [4, 4, 4, 4]));
  }, [cardType, gapPattern]);

  if (!isEnabled) return null;

  const getCardMask = gapPattern =>
    gapPattern.map(gap => "0".repeat(gap)).join("{ }");

  const onCardNumberChange = value => {
    setCardNumber(value.replace(/\W/gim, ""));
  };

  const onExpiryDateChange = value => {
    setExpiryDate(value);
  };

  const onCodeChange = value => {
    setCode(value);
  };

  const onCardTypeChange = (type, gapPatternIndex) => {
    setGapPattern(getPattern(type?.gapPattern, gapPatternIndex));
    setCardType(type);
  };

  const onPayClick = () => {
    onPay(buildCardObject());
  };

  const themeColor = get(themeColors, "cta", iconThemes.blue);
  const textColor = get(themeColors, "text", iconThemes.black);
  const buttonTheme = get(themeColors, "button", buttonThemes.blue);
  const buttonStyles = getButtonColorVariant(buttonTheme);
  const hideBorder = get(data, "hideBorder", false);
  const hideButton = get(data, "hideButton", false);
  const hideCardTypes = get(data, "hideCardTypes", false);
  const supportedCards = get(data, "supportedCards", []);
  const styles = get(data, "styles", {});
  let supportedCardTypes = {};

  if (!isEmpty(supportedCards)) {
    forEach(supportedCards, card => {
      if (!isEmpty(cardTypes[card])) {
        supportedCardTypes[card] = cardTypes[card];
      }
    });
  } else {
    supportedCardTypes = cardTypes;
  }

  return (
    <Flex justifyContent="space-around" sx={styles}>
      <Box
        variant={hideBorder ? "transparent" : "borderSection"}
        mb={8}
        padding={[0, 0]}
        sx={{
          color: themeColor,
          borderColor: themeColor,
          width: "100%",
          maxWidth: "568px",
          justifyContent: "center"
        }}
      >
        <DetailsContainer
          mt={[20]}
          pb={hideButton ? 20 : 0}
          flexDirection="column"
          px={hideBorder ? "0.5rem" : "1.5rem"}
          maxWidth="100%"
        >
          {!hideCardTypes && (
            <PaymentCardTypes types={supportedCardTypes} isEnabled />
          )}
          <Flex width={[1]} flexDirection="column">
            <FormattedMessage id="card_number">
              {label => (
                <Label
                  sx={{ color: textColor }}
                  label={label}
                  tooltip={get(data, "fields.card_number.tooltip")}
                  themeColors={themeColors}
                ></Label>
              )}
            </FormattedMessage>
            <CardFieldContainer>
              <MaskedInput
                mask={cardMask}
                placeholderChar="_"
                value={!cardNumber ? "" : String(cardNumber)}
                onChange={onCardNumberChange}
                unmask={true}
                type="tel"
                name="cardnumber"
                autoComplete={isAutoComplete ? "cc-number" : "off"}
              />
              <CardTypeIndicator
                cardNumber={cardNumber}
                types={cardTypes}
                display="single"
                onChange={onCardTypeChange}
                isEnabled
              />
            </CardFieldContainer>
          </Flex>
          <Flex width={[1]} sx={{ display: "grid", gridGap: 35 }}>
            <Flex width={[1 / 2]} flexDirection="column">
              <FormattedMessage id="expiry_data">
                {label => (
                  <Label
                    sx={{ color: textColor }}
                    label={label}
                    tooltip={get(data, "fields.expiry_date.tooltip")}
                    themeColors={themeColors}
                  ></Label>
                )}
              </FormattedMessage>
              <MaskedInput
                marginTop="auto"
                mask="m{/}y"
                blocks={{
                  m: {
                    mask: IMask.MaskedRange,
                    placeholderChar: "M",
                    from: 1,
                    to: 12,
                    maxLength: 2
                  },
                  y: {
                    mask: IMask.MaskedRange,
                    placeholderChar: "Y",
                    from: moment().format("YY"),
                    to: 99,
                    maxLength: 2
                  }
                }}
                type="tel"
                placeholder="MM/YY"
                value={expiryDate}
                name="exp-date"
                autoComplete={isAutoComplete ? "cc-exp" : "off"}
                onChange={onExpiryDateChange}
              />
            </Flex>
            <Flex width={[1 / 2]} flexDirection="column">
              <FormattedMessage id="security_code">
                {label => (
                  <Label
                    sx={{ color: textColor }}
                    label={label}
                    tooltip={get(data, "fields.security_code.tooltip")}
                    themeColors={themeColors}
                    bdColor={"none"}
                  />
                )}
              </FormattedMessage>

              <MaskedInput
                mask={Array.from({
                  length: cardType?.securityCode?.length || 3
                })
                  .map(() => "0")
                  .join("")}
                placeholder={cardType?.securityCode?.name || "CVV"}
                value={code}
                onChange={onCodeChange}
                type="tel"
                name="cvc"
                autoComplete={isAutoComplete ? "cc-csc" : "off"}
              />
              <Flex>
                {getSecurityCodeImage && (
                  <FormattedMessage id="security_code">
                    {label => (
                      <SecurityCodeImage
                        src={getSecurityCodeImage}
                        alt={label}
                      />
                    )}
                  </FormattedMessage>
                )}
              </Flex>
            </Flex>
          </Flex>
        </DetailsContainer>
        {!hideButton && (
          <Flex justifyContent="space-around" pt="2rem" pb="1rem">
            <Button
              disabled={!isCardValid()}
              width="fit-content"
              onClick={onPayClick}
              data-testid="pay-by-card-btn"
              sx={buttonStyles}
            >
              <FormattedMessage id="confirm" />
            </Button>
          </Flex>
        )}
      </Box>
    </Flex>
  );
}

PaymentCardDetails.propTypes = {
  cardTypes: PropTypes.object,
  isEnabled: PropTypes.bool,
  isAutoComplete: PropTypes.bool,
  isPaymentExecuted: PropTypes.bool,
  onPay: PropTypes.func,
  onChange: PropTypes.func
};

PaymentCardDetails.defaultProps = {
  cardTypes: {},
  isEnabled: false,
  isAutoComplete: false,
  isPaymentExecuted: false,
  onPay: () => {},
  onChange: () => {}
};

export default PaymentCardDetails;
export { PaymentCardTypes };
