import React, { memo } from "react";
import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import queryString from "query-string";
import SmartRender from "containers/SmartRender";
import { createStructuredSelector } from "reselect";
import moment from "moment-timezone";
import usePostDocumentAPI from "hooks/usePostDocumentAPI";
import validate from "validations";
import get from "lodash/get";
import { makeSelectPageValidations } from "ducks/selectors";

// Currently used to test 1stop MNP source provider login
const OneStopMNPPortinLoginHOC = Component =>
  function Comp(props) {
    const formValues = useSelector(state =>
      get(state, "form.mnoSampleLoginPage.values", {})
    );
    const queryParams = queryString.parse(window.location.search);
    const { submitPostApi } = usePostDocumentAPI("mylogin-1stop");

    const postRequestToMNO = () => {
      const callbackUrl = queryParams?.callback;
      const immediate = formValues.valid_param === "immediate";
      const delayInMins = formValues.portout_delay;
      const params = {
        onestop: "1",
        param: Date.now(),
        mno: 11, // Docomo
        valid: immediate
          ? "000000000000"
          : generateDynamicValidTime("Japan", delayInMins)
      };
      submitPostApi(callbackUrl, params);
    };

    return (
      <Component
        {...props}
        formValues={formValues}
        postRequestToMNO={postRequestToMNO}
      />
    );
  };

const generateDynamicValidTime = (timezone, delayInMins) => {
  const currentTimeInTimezone = moment.tz(timezone);
  currentTimeInTimezone.add(delayInMins, "minutes");
  return currentTimeInTimezone.format("YYYYMMDDHHmm");
};

const mapStateToProps = createStructuredSelector({
  formValidations: makeSelectPageValidations("mnoSampleLoginPage")
});

export function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  reduxForm({
    form: "mnoSampleLoginPage",
    destroyOnUnmount: true,
    validate
  }),
  memo
)(OneStopMNPPortinLoginHOC(SmartRender));
