import React, { memo, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { IntlProvider } from "react-intl";
import get from "lodash/get";
import { setUserId } from "@convivainc/conviva-js-appanalytics";

import {
  fetchCustomerInfo,
  fetchPhones,
  changeFormField,
  selectPlan,
  fetchMyInfoDetails
} from "ducks/actions";
import {
  makeSelectMessages,
  makeSelectIsFetchingRequired,
  makeSelectRequiresMaintenance,
  makeSelectReferralCode,
  makeSelectCustomerInfo,
  makeSelectPlanName,
  makeSelectPhones,
  makeSelectEligiblePlans,
  makeSelectPhonesForm,
  makeSelectMyInfoCode,
  makeSelectExternalUserId
} from "ducks/selectors";
import {
  COMBO_CHANNEL,
  DEFAULT_KEYS_TO_FETCH,
  DEFAULT_CHANNEL,
  forms,
  paths
} from "utils/constants";
import {
  urlParam,
  upper,
  gotoUrl,
  getChannelFromUrl,
  getPaymentOption,
  setNRError
} from "utils/helpers";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import round from "lodash/round";
import { OMS, Rewards } from "ducks/actions";
import { featureFlagDataOnlyPlanEnabled } from "utils/featureFlags";
import useOTPSecureRoute from "hooks/useOTPSecureRoute";

export const DataLoader = props => {
  const {
    requiresFetching,
    render,
    messages,
    fetchData,
    referralCode,
    validateReferralCode,
    customerInfo,
    externalUserId,
    fetchCustomerInfo,
    fetchPhones,
    planName,
    phones = [],
    selectPlan,
    setSelectedPhone,
    eligiblePlans,
    phonesForm = {},
    requiresMaintenance,
    match
  } = props;
  const pvid = urlParam("pvid");
  const paymentTerm = urlParam("payment_term");
  const paymentOption = urlParam("payment_type") || urlParam("ppo");
  const paymentType = getPaymentOption(paymentOption);
  const selectedPlanName = urlParam("plan_selection") || urlParam("plan_name");
  const channel = getChannelFromUrl();
  const hasPhones = phones?.length;
  const selectedPaymentTerm = phonesForm?.paymentTerm;
  const selectedPhoneVariant = phonesForm?.phoneVariant?.id;

  const { OTPVerificationRequired, showOTPModal } = useOTPSecureRoute();

  useEffect(
    () => {
      if (requiresFetching || urlParam("relaunch")) {
        let params = get(match, "params");
        if (!get(params, "channel")) params = { channel: DEFAULT_CHANNEL };
        fetchData(params, featureFlagDataOnlyPlanEnabled());
      }
      const account_number = upper(
        urlParam("account_no") || urlParam("accountNo")
      );
      const ott = urlParam("ott");
      if (
        (account_number &&
          account_number !== get(customerInfo, "account_number")) ||
        (ott && isEmpty(customerInfo))
      ) {
        fetchCustomerInfo(account_number, ott);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const externalId = externalUserId || "GUEST";
    setUserId(externalId);
  }, [externalUserId]);

  useEffect(() => {
    if (channel === COMBO_CHANNEL && !requiresFetching) {
      fetchPhones();
    }
  }, [requiresFetching, channel, fetchPhones]);

  // validateReferralCode on change of planName
  useEffect(
    () => {
      // the authentication url check is to avoid considering code url params in social media redirection
      const isAuthenticationRedirection = window.location.pathname.includes(
        "authentication"
      );
      const referralCodeParam = !isAuthenticationRedirection
        ? upper(urlParam("referral_code") || urlParam("referral"))
        : "";
      const appliedReferralCode = get(referralCode, "value");
      // give priority to appliedReferralCode
      const code = appliedReferralCode || referralCodeParam;

      const myInfoCondition = !isEmpty(code) && !isEmpty(urlParam("state")); //myinfo redirects with ?code=aaa&state=bbb . We treat the presence of these 2 params tgt as myInfo condition.

      if (code && planName && !myInfoCondition) {
        validateReferralCode(code, planName, urlParam("pvid"));
        if (referralCodeParam) {
          // Remove promocode query param, once it is applied.
          gotoUrl(window.location.pathname, true, {
            code: null,
            referral: null,
            reset: null
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [planName]
  );

  useEffect(() => {
    if (channel === COMBO_CHANNEL && !isEmpty(phones)) {
      if (selectedPlanName !== planName) {
        const selectedPlan = find(eligiblePlans, { name: selectedPlanName });
        selectedPlan && selectPlan(selectedPlan);
      }
      if (pvid) {
        let allPhoneVariants = [];
        phones.forEach(phone => {
          if (
            !phone.outOfStock &&
            !phone.comingSoon &&
            !isEmpty(phone.allPhoneVariants)
          ) {
            allPhoneVariants = [...allPhoneVariants, ...phone.allPhoneVariants];
          }
        });
        const getPhoneVariant = find(allPhoneVariants, { id: pvid });
        if (getPhoneVariant) {
          setSelectedPhone("phoneVariant", getPhoneVariant);
          setSelectedPhone("phoneColorCode", getPhoneVariant.colorCode);
          setSelectedPhone("phoneColorName", getPhoneVariant.colorName);
          setSelectedPhone("phoneCapacity", getPhoneVariant.capacity);
          setSelectedPhone("phonePaymentOption", paymentType);

          if (paymentTerm && paymentType === "installment") {
            const {
              minInstallmentMonth,
              maxInstallmentMonth,
              installmentStep
            } = getPhoneVariant;
            const paymentMonth = parseInt(paymentTerm);
            const value =
              paymentMonth >= maxInstallmentMonth
                ? maxInstallmentMonth
                : paymentMonth <= minInstallmentMonth
                  ? minInstallmentMonth
                  : paymentMonth / installmentStep === 0
                    ? paymentMonth
                    : round(paymentMonth / installmentStep) * installmentStep;
            setSelectedPhone("paymentTerm", value);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasPhones,
    pvid,
    selectedPlanName,
    eligiblePlans,
    paymentType,
    selectedPaymentTerm,
    selectedPhoneVariant,
    planName
  ]);

  return (
    <IntlProvider locale="en" messages={messages}>
      {render({
        loading: requiresFetching,
        requiresMaintenance,
        OTPVerificationRequired,
        showOTPModal
      })}
    </IntlProvider>
  );
};

const mapStateToProps = createStructuredSelector({
  messages: makeSelectMessages(),
  requiresFetching: makeSelectIsFetchingRequired(DEFAULT_KEYS_TO_FETCH),
  requiresMaintenance: makeSelectRequiresMaintenance(DEFAULT_KEYS_TO_FETCH),
  referralCode: makeSelectReferralCode(),
  customerInfo: makeSelectCustomerInfo(),
  planName: makeSelectPlanName(),
  phones: makeSelectPhones(),
  eligiblePlans: makeSelectEligiblePlans(),
  phonesForm: makeSelectPhonesForm(),
  myInfoCode: makeSelectMyInfoCode(),
  externalUserId: makeSelectExternalUserId()
});

const maintenanceOnError = (result, error) => {
  if (result?.maintenanceMode || !isEmpty(error)) {
    setNRError(error);
    gotoUrl(result?.maintenancePage || paths.MAINTENANCE);
  }
};

export function mapDispatchToProps(dispatch) {
  return {
    fetchData: (params, isFeatureFlag) => {
      dispatch(OMS.V4.fetchAppSettings(maintenanceOnError));
      dispatch(OMS.V4.fetchLocale(maintenanceOnError));
      !isFeatureFlag &&
        dispatch(OMS.V4.fetchEligiblePlans(params, maintenanceOnError));
    },
    fetchPhones: () => {
      dispatch(fetchPhones());
    },
    fetchCustomerInfo: (account_number, ott) =>
      dispatch(fetchCustomerInfo({ account_number, ott })),
    validateReferralCode: (referral_code, plan_name, device_sku = null) => {
      const data = {
        referral_code,
        plan_name,
        ...(device_sku && { device_sku: true })
      };
      dispatch(Rewards.V1.fetchReferralCode(data));
    },
    setSelectedPhone: (field, value) => {
      dispatch(
        changeFormField({
          form: forms.PHONES_FORM,
          field: field,
          value: value
        })
      );
    },
    selectPlan: plan => dispatch(selectPlan(plan)),
    fetchMyInfoDetails: (params, callback) => {
      dispatch(fetchMyInfoDetails(params, callback));
    }
  };
}

DataLoader.defaultProps = {
  match: {},
  requiresFetching: false,
  messages: [],
  render: () => { }
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(DataLoader);
