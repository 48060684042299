import React from "react";
import { Avatar, Flex, Box, Text } from "ui-components";
import { COLORS } from "ui-components/theme";
import { ChevronRight } from "components/Icons";
import FormattedMessage from "components/FormattedMessage";

const SocialMediaCard = props => {
  const { onClick, label = "label", iconUrl } = props;

  return (
    <Box
      variant="borderSection"
      padding={[2, 2]}
      sx={{
        px: 1,
        py: 1,
        borderWidth: "2px",
        cursor: "pointer",
        borderColor: COLORS.gray[4],
        backgroundColor: COLORS.white
      }}
      width={[1]}
      onClick={onClick}
    >
      <Flex py={1} justifyContent="space-between" alignItems="center">
        <Avatar
          src={iconUrl}
          data-testid="au-icon"
          style={{ borderRadius: 9999 }}
        />
        <Box px={[4]} justifySelf="flex-start" flex={1}>
          <Text>
            <FormattedMessage html id={label} />
          </Text>
        </Box>
        <Box width={["36px"]} mx={1}>
          <ChevronRight size="30px" color={COLORS.gray[2]} />
        </Box>
      </Flex>
    </Box>
  );
};

export default SocialMediaCard;
