import React from "react";
import get from "lodash/get";
import { Button, Flex, Text } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import { formatAddressForPopStationItem } from "utils/helpers";
import { buttonThemes } from "ui-components/theme";

const PopStationItem = props => {
  const { popStation, onClick, isSelected, themeColors } = props;

  const buttonTheme = get(themeColors, "button") || buttonThemes.blue;

  const buttonStyles = {
    borderRadius: 2,
    fontFamily: "'Roboto', sans-serif !important",
    width: "100%",
    margin: "0 !important",
    padding: "12px",
    backgroundColor: isSelected ? buttonTheme.backgroundColor : "white",
    color: isSelected ? buttonTheme.color : "gray.1",
    borderColor: isSelected ? buttonTheme.color : "#c6c6c6",
    "&:hover": {
      color: buttonTheme.color,
      backgroundColor: buttonTheme.backgroundColor,
      borderColor: buttonTheme.color
    }
  };

  let title = get(popStation, "outlet_name", "");
  let outletId = get(popStation, "outlet_id", "");
  let operatingHours = get(popStation, "operating_hours", "");
  let formattedAddress = formatAddressForPopStationItem(popStation);
  let formattedOperatingHours = "pop_station_operating_hours";

  return (
    <Flex
      data-outlet-id={outletId}
      data-item="pop-station-item"
      className="pop-station-item"
      onClick={onClick}
      sx={{ p: 1 }}
      mb={3}
    >
      <Button type="button" sx={{ ...buttonStyles }}>
        <Flex flexDirection="column">
          <Text fontSize="16px" fontWeight="bold">
            {title}
          </Text>
        </Flex>

        <Flex flexDirection="column">
          <Text fontSize="14px" fontWeight="500">
            {formattedAddress}
          </Text>
        </Flex>

        {operatingHours ? (
          <Flex flexDirection="column">
            <Text fontSize="14px" fontWeight="500">
              <FormattedMessage
                id={formattedOperatingHours}
                values={{ operatingHours: operatingHours }}
              />
            </Text>
          </Flex>
        ) : null}
      </Button>
    </Flex>
  );
};

PopStationItem.defaultProps = {
  onClick: () => {},
  isSelected: false
};

export default PopStationItem;
