import React, { useState } from "react";
import FormattedMessage from "components/FormattedMessage";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import get from "lodash/get";
import has from "lodash/has";
import { Flex, Button, Box, Text } from "ui-components";
import TimeLineItem from "components/TimeLineItem";
import {
  getActionUrl,
  formatMessage,
  isContainWhiteSpace,
  sanitizeHTML
} from "utils/helpers";
import { COLORS, getButtonColorVariant } from "ui-components/theme";
import OTPModal from "components/OTPModal";

const badgeColorMapping = {
  inactive: "#CBD2D9",
  fail: "#CB1818",
  success: "#79DE00",
  processing: "#EFC100"
};

const OrderStepsCard = props => {
  // const { steps, buttonTheme, channel, data } = props;
  const {
    steps = [],
    buttonTheme,
    channel,
    onActionClicked,
    orderRef,
    orderActionName,
    locale,
    fetchOrderDetailsAndNavigate,
    data
  } = props;

  const [showModal, setShowModal] = useState(false);
  const closeModal = () => setShowModal(false);
  const [fullyQualifiedURL, setFullyQualifiedURL] = useState("");
  const [anchorTarget, setAnchorTarget] = useState("_blank");

  const onOTPSubmitSuccess = sessionToken => {
    fetchOrderDetailsAndNavigate({
      ...fetchOrderDetailsParam,
      fullyQualifiedURL,
      anchorTarget,
      sessionToken
    });
  };

  const fetchOrderDetailsParam = {
    orderRef,
    sessionToken: "",
    orderActionName,
    closeModal,
    setShowModal,
    locale
  };

  const onClick = (steps, index, action) => {
    const appendedAction = `${action}_${channel}`; //this should not be passed out of this method. For action_config purpose only.

    const fullyQualifiedURL =
      formatMessage(
        get(data, ["action_config", appendedAction, "url"]) ||
          get(data, ["action_config", action, "url"]),
        steps[index]
      ) ||
      getActionUrl(action, channel) ||
      get(steps[index], "url");
    const actionType =
      get(data, ["action_config", appendedAction, "action_type"]) ||
      get(data, ["action_config", action, "action_type"]) ||
      action;

    const requireOTPCall = has(data, [
      "otp_required_action_config",
      appendedAction
    ])
      ? has(data, ["otp_required_action_config", appendedAction], false)
      : has(data, ["otp_required_action_config", action], false);
    const anchorTarget = get(data, ["action_config", appendedAction])
      ? get(data, ["action_config", appendedAction, "target"], "blank")
      : get(data, ["action_config", action, "target"], "_blank");
    setAnchorTarget(anchorTarget);
    setFullyQualifiedURL(fullyQualifiedURL);

    onActionClicked(
      fetchOrderDetailsParam,
      action,
      actionType,
      anchorTarget,
      fullyQualifiedURL,
      requireOTPCall
    );
  };

  return (
    <>
      <Flex
        as="ul"
        sx={{
          flexDirection: "column",
          listStyle: "none",
          padding: 0,
          margin: "0 auto",
          width: "fit-content",
          minWidth: ["100%", "90%", "75%"],
          marginTop: "1rem",
          lineHeight: 1.5
        }}
      >
        {map(
          steps,
          (
            { title, description, reason, url, url_title, state, action },
            index
          ) => {
            const errorMsgColor = COLORS.red[1];
            const styleColor = COLORS.blue[4];
            const isTranslationKey = !isContainWhiteSpace(reason);
            const actions = isArray(action) ? action : action ? [action] : [];
            return (
              <TimeLineItem
                key={`step-${title}`}
                color={badgeColorMapping[state]}
                className={state}
                lastStep={index === steps.length - 1}
              >
                <Text fontWeight="bold">{title}</Text>
                {description && (
                  <Text
                    mt="1"
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHTML(description)
                    }}
                    sx={{
                      a: {
                        color: styleColor
                      }
                    }}
                  />
                )}
                {reason && !isTranslationKey && (
                  <Text
                    mt="1"
                    data-testid="step_error"
                    color={errorMsgColor}
                    dangerouslySetInnerHTML={{ __html: sanitizeHTML(reason) }}
                    sx={{
                      a: {
                        color: styleColor
                      }
                    }}
                  />
                )}
                {reason && isTranslationKey && (
                  <Text
                    data-testid="translation_step_error"
                    mt="1"
                    color={errorMsgColor}
                    sx={{
                      a: {
                        color: styleColor
                      }
                    }}
                  >
                    <FormattedMessage html id={reason} values={props} />
                  </Text>
                )}
                {url && isEmpty(actions) && (
                  <Box mt="2">
                    <Button
                      as="a"
                      href={url}
                      target="_blank"
                      variant="outline"
                      padding={"0.25rem 1rem"}
                      data-testid="step_no_action_btn"
                      sx={getButtonColorVariant(buttonTheme, "outline")}
                    >
                      {url_title}
                    </Button>
                  </Box>
                )}
                {!isEmpty(actions) && (
                  <Flex mt="2" flexWrap="wrap">
                    {map(actions, action => (
                      <Button
                        as="a"
                        data-testid="step_action_btn"
                        onClick={() => onClick(steps, index, action)}
                        variant="outline"
                        padding={"0.25rem 1rem"}
                        marginRight={"0.25rem"}
                        key={action}
                        sx={getButtonColorVariant(buttonTheme, "outline")}
                      >
                        <FormattedMessage id={action} />
                      </Button>
                    ))}
                  </Flex>
                )}
              </TimeLineItem>
            );
          }
        )}
      </Flex>
      <OTPModal
        {...props}
        showModal={showModal}
        closeModal={closeModal}
        buttonTheme={buttonTheme}
        onSuccess={onOTPSubmitSuccess}
      />
    </>
  );
};

export default OrderStepsCard;
