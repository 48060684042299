import { createSelector } from "reselect";
import get from "lodash/get";

export const validateSignatureDomain = state =>
  get(state, "api.validateSignature.data", {});

export const validateSignatureDomainSelector = createSelector(
  validateSignatureDomain,
  data => data
);

export const makeSelectValidateSignatureDomain = () =>
  validateSignatureDomainSelector;
