import { createSelector } from "reselect";
import get from "lodash/get";
import { forms } from "utils/constants";

const contactNumberChangeDomain = state =>
  get(state, `form.${forms.CONTACT_NUMBER_CHANGE}.values`, {});

export const newContactNumberSelector = createSelector(
  contactNumberChangeDomain,
  newNumberForm => newNumberForm
);

export const NumberSelector = createSelector(
  contactNumberChangeDomain,
  numberSelector => numberSelector || ""
);

export const newContactNumberInitialSelector = createSelector(
  newContactNumberSelector,
  data => data
);

export const makeSelectContactNumber = () => NumberSelector;
export const makeSelectNewContactNumber = () => newContactNumberInitialSelector;
