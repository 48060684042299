import { createSelector } from "reselect";
import get from "lodash/get";
import orderBy from "lodash/orderBy";

import { simDetailsSelector } from "./appSettings";
import { reportLostSimSelector } from "./forms/reportLostSim";
import { userOrdersSelector } from "./guestOrder";
import head from "lodash/head";
import { featureFlag5GSAEnabled } from "utils/featureFlags";

// ***************************************************************
// ****************** simDetails selectors ***********************
// ***************************************************************
export const simReplacementReasonSelector = createSelector(
  simDetailsSelector,
  simDetails => {
    const reasons = [];
    const simChangeReasons = get(simDetails, "sim_change.reasons", {});

    if (featureFlag5GSAEnabled()) {
      Object.keys(get(simDetails, "sim_change.reasons", {})).forEach(reason => {
        if (simChangeReasons[reason].enable_in_client) {
          reasons.push({
            label: simChangeReasons[reason].label,
            value: reason,
            isSuspendable: simChangeReasons[reason].is_spendable
          });
        }
      });
    } else {
      Object.keys(get(simDetails, "sim_change.reasons", {})).forEach(reason => {
        if (simChangeReasons[reason].enable) {
          reasons.push({
            label: simChangeReasons[reason].label,
            value: reason,
            isSuspendable: simChangeReasons[reason].is_spendable
          });
        }
      });
    }

    const orderedReasons = orderBy(reasons, ["label"], ["asc"]);
    return orderedReasons;
  }
);

export const planChangeReasonSelector = createSelector(
  simDetailsSelector,
  userOrdersSelector,
  (simDetails, orderDetails) => {
    const userOrder = head(orderDetails);
    const currentNetworkType = get(userOrder, "network_type", "");
    const simChangeReasons = get(simDetails, "sim_change.reasons", "");
    const simChangeReasonsMap = new Map(Object.entries(simChangeReasons));
    for (let [key, value] of simChangeReasonsMap.entries()) {
      if (
        value?.eligible_network_type?.toUpperCase() ===
        currentNetworkType?.toUpperCase()
      ) {
        return key;
      }
    }
    return null;
  }
);

export const isSimReplacementSuspendableReasonSelector = createSelector(
  reportLostSimSelector,
  form => get(form, "sim_replacement_reason.isSuspendable", false)
);

// ***************************************************************
// ****************** simDetails makeSelect **********************
// ***************************************************************
export const makeSelectSIMReplacementReasons = () =>
  simReplacementReasonSelector;

export const makeSelectIsSimReplacementSuspendableReason = () =>
  isSimReplacementSuspendableReasonSelector;
