import React from "react";
import { Box, Flex, Text } from "ui-components";
import { PlusOutline } from "components/Icons";
import FormattedMessage from "components/FormattedMessage";

const TaxAmountItem = ({ primaryColor, taxPrice, taxPercent }) => {
  return (
    <>
      <Box py={1} px={"1rem"}>
        <hr width="100%" />
      </Box>
      <Flex
        pb={"1rem"}
        p={"1rem"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box width={["24px"]}>
          <PlusOutline color={primaryColor} />
        </Box>
        <Box px={[1, 2]} justifySelf="flex-start" flex={1} color={primaryColor}>
          <FormattedMessage id="tax_item_label" values={{ taxPercent }} />
        </Box>
        <Box width={["fit-content"]}>
          <Text color={primaryColor} fontWeight="bold">
            {`+ ${taxPrice}`}
          </Text>
        </Box>
      </Flex>
    </>
  );
};

export default TaxAmountItem;
