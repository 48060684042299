import { select } from "redux-saga/effects";
import Alert from "react-s-alert";
import Session from "session";
import { resetSession, pathWithChannel, gotoUrl } from "utils/helpers";
import {
  IS_PATHS_SECURED,
  paths,
  RECAPTURE_TOKEN_BROWSER_ERROR,
  SESSION_TIMEOUT,
  TOKEN_INVALID_HTTP_CODE,
  UNAUTHORIZED_HTTP_CODE
} from "utils/constants";
import { getDeviceId, isWebview } from "utils/localStorage";
import includes from "lodash/includes";
import { genericErrorSelector, errorSelectorByKey } from "ducks/selectors";
/**
 * Returns true if the error is invlaid, else false
 * @param {object} errorObj
 */
export function isTokenInvalidError(errorObj = {}) {
  // Token validation is not applicable for countries that has not user authentication
  if (!IS_PATHS_SECURED) return false;

  const { status } = errorObj;
  return status === TOKEN_INVALID_HTTP_CODE;
}

/**
 * Returns true if the error is because of token expiry and need a refresh token call, else false
 * @param {object} errorObj
 */
export function isTokenRefreshRequired(errorObj = {}) {
  // Token refresh is not applicable for countries that has not user authentication
  if (!IS_PATHS_SECURED) return false;

  const { status } = errorObj;
  return status === UNAUTHORIZED_HTTP_CODE;
}

export function isRetryableRecaptchaError(errorObj = {}) {
  const { response: { result: { code } = {} } = {} } = errorObj;
  return code === RECAPTURE_TOKEN_BROWSER_ERROR;
}

/**
 * Validate session is only done if path security is not in place.
 * Validate session based on deviceid. DeviceId is considered to be a timestamp here
 */
export function validateSession() {
  if (IS_PATHS_SECURED) return;

  const sessionDuration = getDeviceId() ? Session.minutes() : 0;
  if (sessionDuration && sessionDuration > SESSION_TIMEOUT) {
    Alert.warning("Your session has been reset");
    resetSession();
  }
}

/**
 * Handles the redirection after user logout
 */
export function logoutUserCallback() {
  if (isWebview()) {
    gotoUrl(paths.LOGGED_OUT, false, {}, true, { reset: true });
  } else {
    gotoUrl(pathWithChannel(`${paths.LOG_IN}`, "web"), false, {}, true, {
      reset: true
    });
  }
}

/**
 * Check for errors that are whitelisted. these error codes are expected and will not be shown to the user
 * @param {object} errorBody
 */
export function isWhiteListedError(whitelistedErrorCodesList, errorBody = {}) {
  if (errorBody === null) {
    return false;
  }
  const { code } = errorBody;
  return includes(whitelistedErrorCodesList, code);
}

/**
 * Returns the user message for consumer, according to the locale
 * @param {object} failureObject failure response object from API
 * @returns User Message
 */
export function* getErrorMessageForCode(failureObject = {}) {
  const { title: errorTitle } = failureObject;
  // Return undefined if errorTitle is not available.
  if (!errorTitle) return;

  const memoizedSelector = yield select(state => errorSelectorByKey(state));
  const errorMessageForKey = memoizedSelector(errorTitle);

  if (errorMessageForKey) {
    return errorMessageForKey;
  } else {
    const genericErrorMessage = yield select(genericErrorSelector);
    return genericErrorMessage;
  }
}
