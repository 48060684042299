import React, { useState, useEffect } from "react";
import { Input } from "ui-components";
import isFunction from "lodash/isFunction";

/**
 * Individual number box
 */
const NumberBox = props => {
  const {
    updateValidity,
    defaultValue = "",
    clearBox = false,
    onPressEnter
  } = props;
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (clearBox) setValue(defaultValue);
  }, [clearBox, defaultValue]);
  /**
   * Jump to next available sibling and trigger a focus
   * @param {HTMLElement} target event triggered element
   */
  const focusOnNextBox = target => {
    const nextSibling = target.nextElementSibling;
    if (nextSibling === null) return;

    nextSibling.focus();
  };
  /**
   * Jump to previous sibling (if available) and trigger a focus
   * @param {HTMLElement} target event triggered element
   */
  const focusOnPrevBox = target => {
    const prevSibling = target.previousElementSibling;
    if (prevSibling === null) return;

    // setting cursor at the end of input value
    const end = prevSibling.value?.length || 0;
    prevSibling.setSelectionRange(end, end);
    prevSibling.focus();
  };
  /**
   * Input on change handler
   * This will set the value only if it is an integer.
   * Everyother input will not be accepted as the value.
   * @param {KeyboardEvent} event
   */
  const onChange = ({ target }) => {
    const newValue = target.value;
    const numValue = Number.parseInt(newValue);

    if (Number.isInteger(numValue)) {
      setValue(numValue);
      updateValidity(true, numValue);
      return focusOnNextBox(target);
    } else {
      setValue("");
      updateValidity(false);
    }
  };
  /**
   * Input keydown handler
   * This is registered to capture the backspace key
   * When user makes a backspace from an empty input it will
   * jump into previous sibling
   * @param {KeyboardEvent} event
   */
  const onKeyDown = ({ keyCode, target }) => {
    const BACKSPACE_KEY_CODE = 8;
    const ENTER_KEY_CODE = 13;

    // jump to previous only if previous value is empty.
    if (keyCode === BACKSPACE_KEY_CODE && value === "") {
      focusOnPrevBox(target);
    } else if (keyCode === ENTER_KEY_CODE) {
      isFunction(onPressEnter) && onPressEnter();
    }
  };

  return (
    <Input
      onChange={onChange}
      onKeyDown={onKeyDown}
      value={value}
      type="tel"
      min="0"
      max="9"
      maxLength={1}
      textAlign="center"
      data-testid="number-box"
    />
  );
};

export default NumberBox;
