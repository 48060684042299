import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Flex, H2, Text } from "ui-components";
import DropdownField from "components/FormFields/DropdownField";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import get from "lodash/get";
import find from "lodash/find";
import { COLORS } from "ui-components/theme";
import toLower from "lodash/toLower";
import isPlainObject from "lodash/isPlainObject";
import { sanitizeHTML } from "utils/helpers";
import "./styles.css";
import { urlParam } from "utils/helpers";
import { DATA_ONLY_CHANNERL } from "utils/constants";

export const OrderDetail = ({
  label,
  value,
  value_label,
  value_data = {},
  html,
  labelStyles
}) => (
  <Flex width={1} mb={4}>
    <Flex className="label" style={labelStyles}>
      <Text>
        <FormattedMessage id={label} />
      </Text>
    </Flex>
    <Flex className="value">
      {value_label ? (
        <FormattedMessage html id={value_label} values={value_data} />
      ) : html ? (
        <Text dangerouslySetInnerHTML={{ __html: sanitizeHTML(value) }} />
      ) : (
        <Text>{value}</Text>
      )}
    </Flex>
  </Flex>
);

const OrderSelection = ({ orderOptions, selectUserOrder }) => (
  <Flex width={1} alignItems="baseline">
    <Flex className="label" pr="1rem">
      <Text fontSize="1rem" fontWeight="700">
        <FormattedMessage id="order" />
        &nbsp;#&nbsp;
      </Text>
    </Flex>
    <Flex
      className="value"
      width="100%"
      maxWidth="220px"
      marginBottom={-4}
      marginTop={-3}
    >
      <DropdownField
        field={"orderRefOrPhone"}
        options={orderOptions}
        onChange={option => {
          selectUserOrder(option.value);
        }}
        sx={{
          width: "100%",
          mb: -4,
          mt: -2,
          ml: -2
        }}
        autoSelectFirstOption={true}
      />
    </Flex>
  </Flex>
);

const OrderDetailsCard = ({
  items,
  orderRef,
  trackingNumber,
  trackingUrl,
  userOrderRefs,
  selectUserOrder,
  simType,
  requestedSim,
  contractType,
  contractPlan,
  networkType,
  requestedNetworkType,
  order,
  deliveryDetailsItems,
  esimProfile,
  data
}) => {
  const detailsItems =
    get(data, "useOrderDetails") || isEmpty(deliveryDetailsItems)
      ? order
      : deliveryDetailsItems;
  const {
    name,
    firstName,
    middleName,
    dateOfBirth,
    lastName,
    contactNumber,
    currentContactNumber,
    alternateContactNumber,
    dataOnlyContactNumber,
    portinNumber,
    portinNumberFromDonor,
    selectedNumber,
    contactEmail,
    deliveryAddress,
    deliveryAddressHtml,
    deliveryDateTime,
    deliverySlotLabel
  } = detailsItems || {};
  const { smdp_server_address: smdpAddress, matching_id: activationCode } =
    esimProfile || {};
  const newNumber = isPlainObject(selectedNumber)
    ? selectedNumber?.formattedNumber
    : selectedNumber;
  const numberLabel = portinNumberFromDonor ? "port_in_number" : "new_number";
  const numberMessage = portinNumberFromDonor || newNumber;

  const selectedNetworkType = networkType || urlParam("network_type");

  const supportedDetails = [
    {
      type: "order_number",
      label: "order_number",
      value: orderRef
    },
    { type: "items", label: "items", value: items },
    {
      type: "sim_type",
      label: requestedSim ? "current_sim_type" : "sim_type",
      value: simType,
      value_label: `sim_type_${toLower(simType)}`
    },
    {
      type: "new_sim_type",
      label: "new_sim_type",
      value: requestedSim,
      value_label: `sim_type_${toLower(requestedSim)}`
    },
    {
      type: "contract_type",
      label:
        contractPlan === DATA_ONLY_CHANNERL
          ? "contract_type_data_only"
          : "contract_type",
      value: contractType,
      value_label: `contract_type_${toLower(contractType)}`
    },
    {
      type: "contract_plan",
      label: "contract_plan",
      value: contractType,
      value_label: `contract_plan_${toLower(contractPlan)}`
    },
    {
      type: "plan_type",
      label: requestedNetworkType ? "current_plan_type" : "plan_type",
      value: selectedNetworkType,
      value_label: `plan_type_${toLower(selectedNetworkType)}`
    },
    {
      type: "new_plan_type",
      label: "new_plan_type",
      value: requestedNetworkType,
      value_label: `plan_type_${toLower(requestedNetworkType)}`
    },
    { type: "number", label: numberLabel, value: numberMessage },
    { type: "number_data_only", label: "number_data_only", value: newNumber },
    { type: "new_number", label: "new_number", value: newNumber },
    {
      type: "port_in_number_from_donor",
      label: "port_in_number",
      value: portinNumberFromDonor
    },
    { type: "port_in_number", label: "port_in_number", value: portinNumber },
    { type: "name", label: "name", value: name },
    {
      type: "first_name",
      label: "first_name",
      value: firstName,
      value_label: "first_name_format",
      value_data: {
        first_name: firstName
      }
    },
    { type: "middle_name", label: "middle_name", value: middleName },
    { type: "last_name", label: "last_name", value: lastName },
    { type: "contact_number", label: "contact_number", value: contactNumber },
    {
      type: "current_contact_number",
      label: "current_contact_number",
      value: currentContactNumber
    },
    {
      type: "alternate_contact_number",
      label: "alternate_contact_number",
      value: alternateContactNumber
    },
    {
      type: "data_only_contact_number",
      label: "alternate_contact_number",
      value: dataOnlyContactNumber
    },
    { type: "contact_email", label: "contact_email", value: contactEmail },
    { type: "address", label: "address", value: deliveryAddress },
    {
      type: "delivery_address",
      label: "order_details_delivery_address",
      value: deliveryAddress
    },
    {
      type: "delivery_address_html",
      label: "order_details_delivery_address",
      value: deliveryAddressHtml,
      html: true
    },
    {
      type: "delivery_date_and_time",
      label: "delivery_date_and_time",
      value: deliveryDateTime
    },
    {
      type: "delivery_schedule",
      label: "delivery_schedule",
      value: deliverySlotLabel
    },
    {
      type: "smdp_address",
      label: "smdp_address",
      value: smdpAddress
    },
    {
      type: "smdp_address_android",
      label: "smdp_code_android",
      value_data: {
        smdpAddress,
        activationCode
      },
      value_label: "smdp_address_android_html",
      value: smdpAddress
    },
    {
      type: "activation_code",
      label: "activation_code",
      value: activationCode
    },
    {
      type: "birthday",
      label: "date_of_birth",
      value: dateOfBirth
    }
  ];

  const allowedDetails = get(data, "details", [
    "items",
    "number",
    "contact_number",
    "contact_email",
    "address",
    "delivery_date_and_time"
  ]);
  const hideOrderRef = get(data, "hideOrderRef");
  const labelOverrides = data?.labelOverrides || {};

  const details = [];

  for (const type of allowedDetails) {
    let detail = find(supportedDetails, { type });
    if (!isEmpty(detail)) {
      const override = labelOverrides?.[type];

      // check whether we are overriding just label or detail object
      // override object can contain value_label and other detail props
      if (isPlainObject(override)) {
        detail = { ...detail, ...override };
      } else if (override) {
        detail.label = override;
      }

      details.push(detail);
    }
  }

  const orderOptions = map(userOrderRefs, ref => ({
    label: ref,
    value: ref
  }));
  const showOrderOptions = orderOptions.length > 1;
  return (
    <>
      {!hideOrderRef &&
        (showOrderOptions ? (
          <OrderSelection
            orderOptions={orderOptions}
            selectUserOrder={selectUserOrder}
          />
        ) : (
          <Flex width={1} mb={4}>
            <H2>
              <FormattedMessage id="order" />
              &nbsp;#&nbsp;{orderRef}
            </H2>
          </Flex>
        ))}

      {details.map((detail, index) => {
        return !isEmpty(detail.value) ? (
          <OrderDetail
            key={index}
            {...detail}
            labelStyles={data?.styles?.labels}
          />
        ) : null;
      })}

      {trackingNumber && (
        <Flex width={1} mb={4}>
          <Flex className="label">
            <Text>
              <FormattedMessage id={"tracking_label"} />
            </Text>
          </Flex>
          <Flex className="value" sx={{ a: { color: COLORS.blue[4] } }}>
            <FormattedMessage
              html
              id={"tracking_link"}
              values={{ trackingNumber, trackingUrl }}
            />
          </Flex>
        </Flex>
      )}
    </>
  );
};

OrderDetailsCard.defaultProps = {
  items: "-",
  orderRef: "-",
  deliveryAddress: "",
  deliveryDate: ""
};

export default OrderDetailsCard;
