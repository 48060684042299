import { createSelector } from "reselect";
import get from "lodash/get";

const toppingSelectionForm = state =>
  get(state, "form.ToppingSelectionForm.values", {});

export const toppingSelectionTermsAndConditionSelector = createSelector(
  toppingSelectionForm,
  termsAndConditions => {
    if ("term_conditions_accepted_data_only" in termsAndConditions) {
      return { is_proceed_to_user_details: true };
    }
  }
);

export const makeSelectTermsAndConditions = () =>
  toppingSelectionTermsAndConditionSelector;

export const makeSelectToppingFormValue = () => toppingSelectionForm;
