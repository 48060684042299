import React, { useState } from "react";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import FormattedMessage from "components/FormattedMessage";
import PlacesAutocomplete from "react-places-autocomplete";
import { Box, Text } from "ui-components";
import TextField from "components/FormFields/TextField";
import { COLORS } from "ui-components/theme";

const LocationField = props => {
  const {
    onSelect,
    onError = () => {},
    placeholder,
    error,
    country,
    value,
    field
  } = props;
  const [address, setAddress] = useState(value || "");

  const options = {};
  if (country) {
    options["componentRestrictions"] = { country };
  }

  return (
    <Box mb={8}>
      <div>
        <PlacesAutocomplete
          searchOptions={options}
          value={address}
          onChange={setAddress}
          onSelect={address => {
            onSelect(address);
            setAddress(address);
          }}
          onError={(_, clearSuggestion) => {
            clearSuggestion();
            onError();
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => {
            return (
              <>
                <FormattedMessage id={placeholder}>
                  {placeholderMsg => (
                    <TextField
                      {...getInputProps({
                        placeholder: placeholderMsg
                      })}
                      autoComplete="off"
                      {...props}
                      onBlur={e => {
                        e && e.preventDefault();
                      }}
                    />
                  )}
                </FormattedMessage>

                {error && (
                  <FormattedMessage id={error}>
                    {msg => (
                      <Text mt={2} size="xs" color={COLORS.red[1]}>
                        {msg}
                      </Text>
                    )}
                  </FormattedMessage>
                )}

                <Box
                  mt={isEmpty(suggestions) ? 2 : -7}
                  className={`${field}__menu`}
                  sx={
                    isEmpty(suggestions)
                      ? {}
                      : {
                          borderRadius: 0,
                          borderColor: "gray.0",
                          borderWidth: 1,
                          borderStyle: "solid",
                          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.3)"
                        }
                  }
                >
                  {loading && <div />}
                  {map(suggestions, suggestion => (
                    <Box
                      my={0}
                      p={4}
                      sx={{
                        borderRadius: 0,
                        borderColor: "gray.0",
                        borderWidth: 1,
                        borderStyle: "solid",
                        cursor: "pointer",
                        fontWeight: 500,
                        ":hover": {
                          borderColor: "blue.4",
                          borderWidth: 1,
                          fontWeight: 600,
                          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.3)"
                        }
                      }}
                      bg="white"
                      {...getSuggestionItemProps(suggestion)}
                      className={`${field}__option`}
                    >
                      <Text size="sm">{suggestion.description}</Text>
                    </Box>
                  ))}
                </Box>
              </>
            );
          }}
        </PlacesAutocomplete>
      </div>
    </Box>
  );
};

LocationField.defaultProps = {
  onSelect: () => {},
  placeholder: "type_to_search"
};

export default LocationField;
