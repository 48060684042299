import {
  BASE,
  BSSMW_SUBMIT_ESIM_TRANSFER,
  BSSMW_REQUEST_OTP
} from "utils/constants";
import { fetchRequested } from "ducks/actions/baseActions";

export const submitESimTransfer = (params, callback, whitelistedCodes) => {
  return fetchRequested({
    key: "submitESimTransfer",
    params,
    path: BSSMW_SUBMIT_ESIM_TRANSFER,
    base: BASE.BSSMW,
    method: "POST",
    callback,
    whitelistedCodes
  });
};

export const requestOTP = (params, callback, whitelistedCodes) => {
  return fetchRequested({
    key: "bssmwRequestOTP",
    params,
    path: BSSMW_REQUEST_OTP,
    base: BASE.BSSMW,
    method: "POST",
    callback,
    whitelistedCodes
  });
};

export default {
  submitESimTransfer,
  requestOTP
};
