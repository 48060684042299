import React from "react";
import { Box, Flex, Text } from "ui-components";
import { COLORS } from "ui-components/theme";
import FormattedMessage from "components/FormattedMessage";

const SimplePhoneCard = props => {
  const { onClick, outOfStock, comingSoon } = props;
  return (
    <Box
      onClick={outOfStock || comingSoon ? () => {} : onClick}
      sx={{
        opacity: outOfStock || comingSoon ? 0.5 : 1,
        minHeight: 120,
        height: "100%",
        mt: [12],
        m: [1],
        fontSize: ["12px", "14px", "16px"],
        bg: "white",
        py: 2,
        borderRadius: 12,
        boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.3)",
        cursor: outOfStock || comingSoon ? "default" : "pointer"
      }}
    >
      <Flex
        mb={2}
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
      >
        {renderPhoneImage(props)}
        {renderName(props)}
        {renderPriceAndStatus(props)}
      </Flex>
    </Box>
  );
};

const renderPhoneImage = ({ phoneImage }) => (
  <Box sx={{ mt: -60, mx: "auto" }}>
    <img width={[60]} src={phoneImage} alt="phone" />
  </Box>
);

const renderName = ({ name }) => (
  <Flex justifyContent="center">
    <Text
      px={2}
      sx={{
        fontSize: "1rem",
        fontWeight: "500",
        textAlign: "center"
      }}
    >
      {name}
    </Text>
  </Flex>
);

/*
const renderColors = ({ variantColors }) => (
  <Flex justifyContent="center" mb={1} px={2}>
    {map(variantColors, color => {
      return (
        <Box
          sx={{
            width: 15,
            height: 15,
            borderRadius: 7.5,
            mr: 1,
            backgroundColor: color.code,
            boxShadow: "0px 1px 4px rgba(0,0,0,0.7)"
          }}
          key={color.code}
        />
      );
    })}
  </Flex>
);
*/

const renderPriceAndStatus = props => (
  <Flex width="100%" flexDirection="column" justifyContent="space-evenly">
    {renderPrice(props)}
    {renderComingSoon(props)}
    {renderOutOfStock(props)}
  </Flex>
);

const renderInstallmentPrice = ({
  installmentCost,
  formattedInstallmentCost,
  originalInstallmentCost,
  formattedOriginalInstallmentCost
}) => (
  <Flex flexDirection="column">
    <Text as="p" color="blue.4" fontSize={["1.2rem", "1.4rem"]}>
      <FormattedMessage
        id="price_per_month"
        values={{ price: formattedInstallmentCost }}
      />
    </Text>
    {installmentCost < originalInstallmentCost && (
      <Text
        as="p"
        my={1}
        style={{ textDecoration: "line-through" }}
        fontWeight={300}
        fontSize={["1.2rem", "1.4rem"]}
      >
        <FormattedMessage
          id="price_per_month"
          values={{ price: formattedOriginalInstallmentCost }}
        />
      </Text>
    )}
  </Flex>
);

const renderUpfrontPrice = ({
  cost,
  formattedCost,
  originalCost,
  formattedOriginalCost
}) => (
  <Flex flexDirection="column">
    <Text as="p" color="blue.4" fontSize={["1.2rem", "1.4rem"]}>
      {formattedCost}
    </Text>
    {cost < originalCost && (
      <Text
        as="p"
        my={1}
        style={{ textDecoration: "line-through" }}
        fontWeight={300}
        fontSize={["1.2rem", "1.4rem"]}
      >
        {formattedOriginalCost}
      </Text>
    )}
  </Flex>
);
const renderPrice = props => {
  const { installmentCost } = props;
  return (
    <Flex mb={1} justifyContent="center" alignItems="center">
      <Text as="p" color="gray.1" fontSize={"0.7rem"} fontWeight="500" pr="2px">
        <FormattedMessage id="from" />
      </Text>
      {installmentCost > 0
        ? renderInstallmentPrice(props)
        : renderUpfrontPrice(props)}
    </Flex>
  );
};

const renderComingSoon = ({ comingSoon }) =>
  !comingSoon ? null : (
    <Text
      fontSize="0.9rem"
      fontWeight="500"
      color={COLORS.green[1]}
      textAlign="center"
    >
      <FormattedMessage id="coming_soon" />
    </Text>
  );

const renderOutOfStock = ({ outOfStock }) =>
  !outOfStock ? null : (
    <Text
      fontSize="0.9rem"
      fontWeight="500"
      color={COLORS.red[1]}
      textAlign="center"
    >
      <FormattedMessage id="out_of_stock" />
    </Text>
  );

export default SimplePhoneCard;
