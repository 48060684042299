import { createSelector } from "reselect";
import get from "lodash/get";
import keys from "lodash/keys";
import isEmpty from "lodash/isEmpty";

// do not change this order
export * from "./appSettings";
export * from "./locale";
export * from "./forms/generic";
export * from "./forms/deliveryInfo";
export * from "./forms/manageOrder";
export * from "./forms/orderSummary";
export * from "./forms/personalDetails";
export * from "./forms/connectionDetails";
export * from "./forms/portIn";
export * from "./forms/selfActivation";
export * from "./forms/simVerification";
export * from "./forms/terminateNumber";
export * from "./forms/uploadIdentity";
export * from "./forms/reportLostSim";
export * from "./forms/phones";
export * from "./forms/requestPortout";
export * from "./forms/reversePortIn";
export * from "./forms/orderCancellation";
export * from "./forms/profile";
export * from "./forms/loginForm";
export * from "./forms/eSimTransfer";
export * from "./forms/confirmKYCForm";
export * from "./forms/contractDetails";
export * from "./forms/paymentMethodDetails";
export * from "./forms/userRegistrationForm";
export * from "./forms/esimSetupForm";
export * from "./addressComponentMappings";
export * from "./addressLookup";
export * from "./deliverySlots";
export * from "./eSignature";
export * from "./guestOrder";
export * from "./numbers";
export * from "./order";
export * from "./orderDetails";
export * from "./orderSummary";
export * from "./paymentRequest";
export * from "./paymentReturn";
export * from "./planService";
export * from "./eligiblePlans";
export * from "./referralCode";
export * from "./requestOTP";
export * from "./scheduleDeliveryDetails";
export * from "./survey";
export * from "./suspensionPaymentDetails";
export * from "./uploadIdentityDetails";
export * from "./verifyIdentity";
export * from "./verifyOTP";
export * from "./validatePortInNumber";
export * from "./validatePostalCode";
export * from "./validateIccId";
export * from "./mockPayment";
export * from "./postOfficeList";
export * from "./transactionDetails";
export * from "./forms/userDetailsConfirmation";
export * from "./phones";
export * from "./customerInfo";
export * from "./wallet";
export * from "./forms/paymentForm";
export * from "./authInfo";
export * from "./userProfileDetails";
export * from "./simActivation";
export * from "./updateCard";
export * from "./kycLiquidUrl";
export * from "./ekyc";
export * from "./contractualAgreement";
export * from "./eligibilitySettings";
export * from "./forms/selfActivation";
export * from "./userKYCDetails";
export * from "./popStations";
export * from "./forms/outstandingBills";
export * from "./validateSignature";
export * from "./submitSuspension";
export * from "./authorizerDetails";
export * from "./forms/switchToEsim";
export * from "./zendeskCustomerData";
export * from "./myInfo";
export * from "./outstandingBillPayments";
export * from "./auIpDBAuthenticate";
export * from "./simDetails";
export * from "./eSimTransfer";
export * from "./simReplacement";
export * from "./linkSocialAccount";
export * from "./sendAuthUserOTP";
export * from "./contractDetails";
export * from "./numberChange";
export * from "./forms/addressUpdate";
export * from "./googleAnalyticsEvents";
export * from "./forms/termsAndConditionsForm";
export * from "./userRegistration";
export * from "./ui/userRegistration";
export * from "./forms/toppingSelectionForm";
export * from "./forms/contactNumberChange";
export * from "./betaUserAuthInfo";

export const selectApi = state => get(state, "api");

export const makeSelectIsFetchingRequired = keys =>
  createSelector(selectApi, apiStore => {
    return (
      keys.filter(key => {
        return !get(apiStore, `${key}.data`);
      }).length > 0
    );
  });

export const makeSelectRequiresMaintenance = keys =>
  createSelector(selectApi, apiStore => {
    return (
      keys.filter(key => {
        return !isEmpty(get(apiStore, `${key}.error`));
      }).length > 0
    );
  });

export const makeSelectIsFetchingData = keys =>
  createSelector(selectApi, apiStore => {
    if (Array.isArray(keys)) {
      return (
        keys.filter(key => {
          return get(apiStore, `${key}.loading`, false);
        }).length > 0
      );
    } else {
      return get(apiStore, `${keys}.loading`, false);
    }
  });

export const makeSelectIsFetchingAnyData = () =>
  createSelector(
    selectApi,
    apiStore =>
      keys(apiStore).filter(key => get(apiStore, `${key}.loading`, false))
        .length > 0
  );

export const makeSelectIsImageUploaded = key =>
  createSelector(selectApi, apiStore => {
    return get(apiStore, `${key}.data.doc_id`, false);
  });

export const makeSelectImageUploading = () =>
  createSelector(selectApi, apiStore => ({
    primary: {
      front: get(apiStore, "primary_front_ImagesUpload.loading", false),
      back: get(apiStore, "primary_back_ImagesUpload.loading", false)
    },
    secondary: {
      front: get(apiStore, "secondary_front_ImagesUpload.loading", false),
      back: get(apiStore, "secondary_back_ImagesUpload.loading", false)
    }
  }));

export const makeSelectImageUploaded = () =>
  createSelector(selectApi, apiStore => ({
    primary: {
      front: get(apiStore, "primary_front_ImagesUpload.data.doc_id", false),
      back: get(apiStore, "primary_back_ImagesUpload.data.doc_id", false)
    },
    secondary: {
      front: get(apiStore, "secondary_front_ImagesUpload.data.doc_id", false),
      back: get(apiStore, "secondary_back_ImagesUpload.data.doc_id", false)
    }
  }));
