import { createSelector } from "reselect";
import get from "lodash/get";
import { forms } from "utils/constants";

/**
 * Extracting eSim Transfer OTP from values
 * @param {object} state
 */
const eSimTransferOtpDomain = state =>
  get(state, `form.${forms.ESIM_TRANSFER_OTP_FORM}.values`, {});

/**
 * ************ eSim Transfer OTP Form Selectors ************
 */
export const isValidESimTransferOTP = (
  fieldName = "esimtrasnfer_otp",
  otpLength = 6
) =>
  createSelector(eSimTransferOtpDomain, formValues => {
    const otpValuesArray = get(formValues, `${fieldName}`, [""]);

    if (otpValuesArray.length === otpLength) {
      return otpValuesArray.find(item => item === "") === undefined;
    } else {
      return false;
    }
  });

/**
 * ************ Make Selectors ************
 */
export const makeSelectIsValidESimTransferOTP = (fieldName, otpLength) =>
  isValidESimTransferOTP(fieldName, otpLength);
