import React, { useState } from "react";
import { Text } from "ui-components";
import RadioGroup from "components/FormFields/RadioGroup";
import FormattedMessage from "components/FormattedMessage";

const NATIONALITY_RADIO = ({ topCountries, otherCountries, ...props }) => {
  const [seeMore, setToggle] = useState(false);
  const allNationalities = seeMore
    ? topCountries.concat(otherCountries)
    : topCountries;

  return (
    <RadioGroup options={allNationalities} width={[1, 1 / 3, 1 / 3]} {...props}>
      <Text
        onClick={() => setToggle(!seeMore)}
        textAlign="center"
        color="blue.4"
        fontWeight="500"
        mb={6}
      >
        <FormattedMessage id={seeMore ? "see_less" : "see_more"} />
      </Text>
    </RadioGroup>
  );
};

export default NATIONALITY_RADIO;
