import { createSelector } from "reselect";
import get from "lodash/get";

const selectForm = state => get(state, "form");

// ***************************************************************
// ************** form selectors ******************
// ***************************************************************

export const formValuesSelector = form =>
  createSelector(selectForm, formStore => get(formStore, `${form}.values`, {}));

// ***************************************************************
// ************** form makeSelect *****************
// ***************************************************************

export const makeSelectFormValues = form => formValuesSelector(form);
