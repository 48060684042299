import React from "react";
import get from "lodash/get";
import DeliveryMethodPicker from "components/DeliveryMethodPicker";

const PAYMENT_METHOD_PICKER = ({ change, formValues, paymentModes }) => {
  const selectedDeliveryMethod = get(formValues, "payment_method", {});

  return (
    <DeliveryMethodPicker
      value={selectedDeliveryMethod.value}
      deliveryMethods={paymentModes}
      onSelect={paymentMode => {
        change("payment_method", paymentMode);
      }}
    />
  );
};

export default PAYMENT_METHOD_PICKER;
