import React from "react";

import Spinner, { styles } from "components/Spinner";
import SmartRender from "containers/SmartRender";

import { getDeepLink } from "utils/helpers";
import { isWebview } from "utils/localStorage";

const DeepLinkHOC = Component =>
  function Comp(props) {
    const launchDeepLink = () => {
      window.open(`${getDeepLink()}${window.location.pathname}`);
    };

    if (isWebview()) {
      return <Spinner size={200} sx={styles.fullPage} />;
    }

    return <Component launchDeepLink={launchDeepLink} {...props} />;
  };

export default DeepLinkHOC(SmartRender);
