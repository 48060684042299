import React from "react";
import { Box, Flex, Button, H1 } from "ui-components";
import isFunction from "lodash/isFunction";
import Spinner, { styles } from "components/Spinner";
import FormattedMessage from "components/FormattedMessage";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import "./styles.css";

const MockPaymentBox = props => {
  const { isMockingPayment } = props;

  const renderMockPaymentButtons = ({
    mockPaymentSuccess,
    mockPaymentFailure,
    txnId
  }) => (
    <>
      <Button
        my={2}
        width={[0.9, 0.75]}
        className="success-button"
        onClick={() => {
          isFunction(mockPaymentSuccess) && mockPaymentSuccess(txnId);
        }}
      >
        <FormattedMessage id="mock_payment_success" />
      </Button>

      <Button
        my={2}
        width={[0.9, 0.75]}
        className="failure-button"
        onClick={() => {
          isFunction(mockPaymentFailure) && mockPaymentFailure(txnId);
        }}
      >
        <FormattedMessage id="mock_payment_failure" />
      </Button>
    </>
  );

  const renderMockPaymentStatus = ({ mockPayment }) => {
    const success = get(mockPayment, "status") === "success";
    const status = success ? "Success ✔" : "Failure ✘";

    return (
      <Flex>
        <H1
          className={success ? "success" : "failure"}
        >{`Payment ${status}`}</H1>
      </Flex>
    );
  };

  const renderMockPayment = props =>
    isEmpty(props.mockPayment)
      ? renderMockPaymentButtons(props)
      : renderMockPaymentStatus(props);

  const className = isMockingPayment ? "loading" : "";
  return (
    <Box className={className} id="mock-payment-box">
      <Flex id="payment-box-content">
        {isMockingPayment ? (
          <Spinner sx={styles.modalContainer} />
        ) : (
          renderMockPayment(props)
        )}
      </Flex>
    </Box>
  );
};

export default MockPaymentBox;
