import React from "react";
import get from "lodash/get";
import map from "lodash/map";
import { Flex, Button } from "ui-components";
import Spinner, { styles } from "components/Spinner";
import FormattedMessage from "components/FormattedMessage";
import { safeEval, isTabletOrDeskTop } from "utils/helpers";

const MODAL_BUTTONS = props => {
  const {
    component: { items },
    isOrderCreating,
    modalButtonsFlexDirection
  } = props;

  const flexDirection = modalButtonsFlexDirection
    ? modalButtonsFlexDirection
    : !isTabletOrDeskTop()
    ? "column-reverse"
    : "row";
  return (
    <Flex
      mt={3}
      justifyContent="center"
      alignItems="center"
      flexDirection={flexDirection}
    >
      {map(items, element => (
        <FormattedMessage id={element.text} key={element.text}>
          {msg =>
            element.type === "link" ? (
              <a
                style={element.styles}
                href={element.href}
                onClick={() =>
                  safeEval(get, props.formValues, element.onClick, "", props)
                }
              >
                {msg}
              </a>
            ) : (
              <Button
                disabled={isOrderCreating}
                style={element.style}
                mx={1}
                variant={element.variant}
                onClick={() =>
                  safeEval(get, props.formValues, element.onClick, "", props)
                }
              >
                {isOrderCreating && element.showLoader ? (
                  <Spinner size={19} sx={styles.button} />
                ) : (
                  msg
                )}
              </Button>
            )
          }
        </FormattedMessage>
      ))}
    </Flex>
  );
};

export default MODAL_BUTTONS;
