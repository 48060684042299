import React from "react";
import MDIcon from "@mdi/react";
import styled from "@emotion/styled";
import {
  mdiCheckCircle,
  mdiCheckOutline,
  mdiCircle,
  mdiCircleOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiPlusOutline,
  mdiPlusCircle,
  mdiMinusCircle,
  mdiPlusCircleOutline,
  mdiMinusCircleOutline,
  mdiMagnify,
  mdiClose,
  mdiRestore,
  mdiChevronLeftCircleOutline,
  mdiRadioboxBlank,
  mdiRadioboxMarked,
  mdiMenu,
  mdiCart,
  mdiSim,
  mdiViewList,
  mdiCellphone,
  mdiFilterOutline,
  mdiTruckOutline,
  mdiCardAccountDetailsOutline,
  mdiSimOutline,
  mdiCellphoneArrowDown,
  mdiInformation,
  mdiInformationOutline,
  mdiAlertCircle,
  mdiAlertCircleOutline,
  mdiHelpCircle,
  mdiHelpCircleOutline,
  mdiEmoticonHappy,
  mdiEmoticonHappyOutline,
  mdiEmoticonSad,
  mdiEmoticonSadOutline,
  mdiEmoticonExcited,
  mdiEmoticonExcitedOutline,
  mdiEmoticonCool,
  mdiEmoticonCoolOutline,
  mdiMapMarker,
  mdiContentCopy
} from "@mdi/js";
import { iconThemes } from "ui-components/theme";

export const CheckCircle = props => Icon({ ...props, path: mdiCheckCircle });
export const CheckOutline = props => Icon({ ...props, path: mdiCheckOutline });
export const Circle = props => Icon({ ...props, path: mdiCircle });
export const CircleOutline = props =>
  Icon({ ...props, path: mdiCircleOutline });
export const Plus = props => Icon({ ...props, path: mdiPlus });
export const Minus = props => Icon({ ...props, path: mdiMinus });
export const PlusOutline = props => Icon({ ...props, path: mdiPlusOutline });
export const PlusCircle = props => Icon({ ...props, path: mdiPlusCircle });
export const MinusCircle = props => Icon({ ...props, path: mdiMinusCircle });
export const PlusCircleOutline = props =>
  Icon({ ...props, path: mdiPlusCircleOutline });
export const MinusCircleOutline = props =>
  Icon({ ...props, path: mdiMinusCircleOutline });
export const ChevronLeftCircleOutline = props =>
  Icon({ ...props, path: mdiChevronLeftCircleOutline });
export const ChevronDown = props => Icon({ ...props, path: mdiChevronDown });
export const ChevronUp = props => Icon({ ...props, path: mdiChevronUp });
export const ChevronRight = props => Icon({ ...props, path: mdiChevronRight });
export const Magnify = props => Icon({ ...props, path: mdiMagnify });
export const Close = props =>
  Icon({ color: iconThemes.red, ...props, path: mdiClose });
export const Restore = props => Icon({ ...props, path: mdiRestore });
export const RadioboxBlank = props =>
  Icon({ ...props, path: mdiRadioboxBlank });
export const RadioboxMarked = props =>
  Icon({ ...props, path: mdiRadioboxMarked });
export const Menu = props =>
  Icon({ color: iconThemes.black, ...props, path: mdiMenu });
export const Cart = props => Icon({ ...props, path: mdiCart });
export const Sim = props => Icon({ ...props, path: mdiSim });
export const ViewList = props => Icon({ ...props, path: mdiViewList });
export const Cellphone = props => Icon({ ...props, path: mdiCellphone });
export const FilterOutline = props =>
  Icon({ ...props, path: mdiFilterOutline });
export const TruckOutline = props => Icon({ ...props, path: mdiTruckOutline });
export const CardAccountDetailsOutline = props =>
  Icon({ ...props, path: mdiCardAccountDetailsOutline });
export const SimOutline = props => Icon({ ...props, path: mdiSimOutline });
export const CellphoneArrowDown = props =>
  Icon({ ...props, path: mdiCellphoneArrowDown });
export const Information = props => Icon({ ...props, path: mdiInformation });
export const InformationOutline = props =>
  Icon({ ...props, path: mdiInformationOutline });
export const AlertCircle = props => Icon({ ...props, path: mdiAlertCircle });
export const AlertCircleOutline = props =>
  Icon({ ...props, path: mdiAlertCircleOutline });
export const HelpCircle = props => Icon({ ...props, path: mdiHelpCircle });
export const HelpCircleOutline = props =>
  Icon({ ...props, path: mdiHelpCircleOutline });
export const EmoticonHappy = props =>
  Icon({ ...props, path: mdiEmoticonHappy });
export const EmoticonHappyOutline = props =>
  Icon({ ...props, path: mdiEmoticonHappyOutline });
export const EmoticonSad = props => Icon({ ...props, path: mdiEmoticonSad });
export const EmoticonSadOutline = props =>
  Icon({ ...props, path: mdiEmoticonSadOutline });
export const EmoticonExcited = props =>
  Icon({ ...props, path: mdiEmoticonExcited });
export const EmoticonExcitedOutline = props =>
  Icon({ ...props, path: mdiEmoticonExcitedOutline });
export const EmoticonCool = props => Icon({ ...props, path: mdiEmoticonCool });
export const EmoticonCoolOutline = props =>
  Icon({ ...props, path: mdiEmoticonCoolOutline });
export const EmoticonMapMarker = props =>
  Icon({ ...props, path: mdiMapMarker, color: "#FFE600" });
export const Copy = props => Icon({ ...props, path: mdiContentCopy });

const Icon = ({ path, size = "24px", theme, color }) => {
  const iconColor = iconThemes[theme] || color || iconThemes.blue;
  return <MDIcon path={path} size={size} color={iconColor} />;
};

const Stacked = styled.div`
  display: inline-flex;
  position: relative;

  > .layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const IconStack = ({ children, size }) => {
  return (
    <Stacked style={{ width: size, height: size }}>
      {React.Children.map(children, child => (
        <div className="layer">{child}</div>
      ))}
    </Stacked>
  );
};
