import React, { memo, useEffect } from "react";
import { compose } from "redux";
import SmartRender from "containers/SmartRender/index";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  makeSelectIsModalView,
  makeSelectModalType,
  makeSelectRegisteredCreditCard,
  makeSelectRegisteredPaidy,
  makeSelectUserProfileDetails,
  makeSelectUserSIN,
  makeSelectSwitchPaymentMethod,
  makeSelectAllRegisteredPaymentMethods,
  makeSelectIsFetchingAnyData,
  makeSelectFormInitialValues,
  makeSelectCurrentPaymentMethod
} from "ducks/selectors";
import { reduxForm, reset } from "redux-form";
import { forms } from "utils/constants";
import validate from "validations";
import {
  Payment,
  Quilt,
  setData,
  setSelectedPaymentTypeToEdit
} from "ducks/actions";
import { v4 as uuid } from "uuid";
import Spinner, { styles } from "components/Spinner";
import { gotoUrl, urlParam } from "utils/helpers";

const PaymentDetails = Component =>
  function Comp(props) {
    const refreshUserDetails = !urlParam("fromProfile");
    const dispatch = useDispatch();

    const {
      userSIN,
      hideModal,
      isActionInProgress,
      switchPaymentMethod,
      currentPaymentMethod,
      getUserProfileDetails,
      confirmChangeDefaultPaymentCard,
      allRegisteredPaymentMethods = [],
      showChangePaymentMethodInfoModal
    } = props;

    useEffect(() => {
      if (refreshUserDetails) {
        getUserProfileDetails();
      }
    }, [refreshUserDetails, getUserProfileDetails]);

    useEffect(() => {
      if (!currentPaymentMethod || !switchPaymentMethod) return;

      if (currentPaymentMethod?.gateway !== switchPaymentMethod) {
        confirmChangeDefaultPaymentCard();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [switchPaymentMethod]);

    const updatePaymentMethod = () => {
      const instrument = allRegisteredPaymentMethods.find(
        method => method.gateway === switchPaymentMethod
      );
      const params = {
        service_instance_number: userSIN,
        additional_data: {
          custom_payment_method: switchPaymentMethod
        },
        paymentMethod: switchPaymentMethod,
        uuid: uuid(),
        instrument_id: instrument?.id,
        status: "active"
      };

      const onSuccess = (_, errors) => {
        if (!errors) {
          getUserProfileDetails();
          hideModal();
        } else {
          resetFormAndHideModal();
        }
      };
      dispatch(Payment.V3.paymentMethodSwitchOrDelete(params, onSuccess));
    };

    const onEditPayment = (type, path) => {
      dispatch(setSelectedPaymentTypeToEdit(type));
      path && gotoUrl(path, true);
    };

    const resetFormAndHideModal = () => {
      dispatch(reset(forms.PAYMENT_METHOD_UPDATE_FORM));
      hideModal();
    };

    return isActionInProgress ? (
      <Spinner size={200} sx={styles.fullPage} />
    ) : (
      <Component
        {...props}
        onClickPaymentInfo={showChangePaymentMethodInfoModal}
        switchPaymentMethod={updatePaymentMethod}
        onEditPayment={onEditPayment}
        resetFormAndHideModal={resetFormAndHideModal}
      />
    );
  };

const mapStateToProps = createStructuredSelector({
  initialValues: makeSelectFormInitialValues(),
  userProfileDetails: makeSelectUserProfileDetails(),
  isModalView: makeSelectIsModalView(),
  modalType: makeSelectModalType(),
  registeredPaidy: makeSelectRegisteredPaidy(),
  userSIN: makeSelectUserSIN(),
  switchPaymentMethod: makeSelectSwitchPaymentMethod(),
  registeredCreditCard: makeSelectRegisteredCreditCard(),
  allRegisteredPaymentMethods: makeSelectAllRegisteredPaymentMethods(),
  currentPaymentMethod: makeSelectCurrentPaymentMethod(),
  isActionInProgress: makeSelectIsFetchingAnyData()
});

export function mapDispatchToProps(dispatch) {
  return {
    getUserProfileDetails: () => {
      dispatch(Quilt.V1.fetchUserProfileDetails());
    },
    showPaidyMoreInfoModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "PaidyMoreInfoModal" }
        })
      );
    },
    hideModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
    },
    showChangePaymentMethodInfoModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "ChangePaymentMethodInfoModal" }
        })
      );
    },
    confirmChangeDefaultPaymentCard: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "changeDefaultPaymentCard" }
        })
      );
    }
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.PAYMENT_METHOD_UPDATE_FORM,
    destroyOnUnmount: false,
    validate,
    enableReinitialize: true
  }),
  memo
)(PaymentDetails(SmartRender));
