import { createBrowserHistory } from "history";
const history = createBrowserHistory();

if (!window.location.pathTracking) {
  window.location.pathTracking = {
    current: window.location.pathname,
    previous: window.location.pathname
  };
}

history.listen(data => {
  const pathTracking = {
    current: data.pathname,
    previous: window.location?.pathTracking?.current
  };
  window.location.pathTracking = pathTracking;
});

export default history;
