import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, Text, Label } from "ui-components";
import { COLORS } from "ui-components/theme";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import filter from "lodash/filter";

const PromoCodeBenefits = ({ items, referralCode, themeColors }) => {
  const code = get(referralCode, "value", "");
  const benefits = filter(items, { type: "benefit" });
  return isEmpty(benefits) ? null : (
    <Box my={5} px={"1rem"}>
      <Label color={COLORS.gray[3]} mb={2}>
        <FormattedMessage id={"promo_code"} />
        &nbsp;:&nbsp;
        <Text color={themeColors?.success}>{code}</Text>
      </Label>
      {map(benefits, (item, index) => (
        <Flex
          py={1}
          justifyContent="space-between"
          alignItems="center"
          key={index}
        >
          <Box justifySelf="flex-start" flex={1} paddingRight={"8px"}>
            <Text color={themeColors?.success}>{item.label}</Text>
          </Box>
          <Box width={["fit-content"]}>
            <Text color={themeColors?.error} fontWeight="bold">
              {item.price}
            </Text>
          </Box>
        </Flex>
      ))}
    </Box>
  );
};

PromoCodeBenefits.defaultProps = {
  items: [],
  referralCode: {}
};

export default PromoCodeBenefits;
