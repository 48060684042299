import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IntlProvider } from "react-intl";

import { makeSelectErrorPageConfigs, makeSelectLocale } from "ducks/selectors";

import Layout from "components/Layout";
import Error404 from "./Error404";
import Error500 from "./Error500";
import Maintenance from "./Maintenance";
import MaintenanceSignup from "./MaintenanceSignup";
import { getLocaleOrDefault } from "utils/localStorage";
import Header from "components/Layout/Header";
import { Box, Flex } from "ui-components";

const ERROR_COMPONENT_MAP = {
  404: Error404,
  500: Error500,
  maintenance: Maintenance,
  maintenance_signup: MaintenanceSignup,
  signup_maintenance: MaintenanceSignup
};

export const DEFAULT_MESSAGES = {
  ja: {
    retry: "リトライ",
    go_back: "戻る",
    chat: "チャット",
    error_message_404: "お探しのページは見つかりません",
    error_message_500:
      "エラー発生の為、表示することが出来ません。ご迷惑をおかけしております。",
    maintenance_message: "現在メンテナンス中です。後ほどご確認ください",
    maintenance_signup_message: `アクセスが集中しているため、お申し込みの受付を一時停止しております。
    <br/><br/>
    大変申し訳ございませんが、受付再開までお待ちください。ご不明点については、povoホームページをご覧ください。`
  },
  en: {
    retry: "Retry",
    go_back: "Go back",
    chat: "Chat",
    error_message_404: "Uh oh, the page you are looking for cannot be found.",
    error_message_500:
      "An internal server error has occurred. Sorry about this!",
    maintenance_message:
      "Currently undergoing maintenance. Please check later!",
    maintenance_signup_message: `We are very sorry.<br/>
      Due to the fact that we have received more applications for povo2.0 than we expected, we are temporarily suspending new registrations.
      <br/><br/>
      Please re-subscribe after a while.<br/>
      If you have any questions, please contact us.`
  }
};

export const APP_SETTINGS = {
  layout: {
    footer: {
      enable: false
    },
    header: {
      marketingPageUrl: "https://povo.jp",
      buttons: [],
      enable: true,
      help: {
        hidden: true, // hide help in error pages as zendesk might not be loaded
        color: "#767778",
        icon: "chat",
        text: "chat"
      }
    }
  }
};

const layoutStyles = {
  maxWidth: 700,
  paddingTop: 100
};

/**
 * This container can be use to display error pages. Current this has 404 and maintenance page.
 * @param {*} Component
 */
const PageError = props => {
  const { unknown } = useParams();
  const errorPagesConfig = useSelector(makeSelectErrorPageConfigs());
  const localeMessages = useSelector(makeSelectLocale());
  const Component = ERROR_COMPONENT_MAP[unknown] || Error404;
  const ErrorComponent = props?.showHeader ? Layout(Component) : Component;
  const locale = getLocaleOrDefault() || "en";
  const messages = {
    ...DEFAULT_MESSAGES[getLocaleOrDefault()],
    ...localeMessages
  };

  return (
    <IntlProvider locale={locale} messages={messages}>
      <Header appSettings={APP_SETTINGS} />
      <Flex justifyContent="center">
        <Box as="main" sx={layoutStyles}>
          <ErrorComponent
            {...props}
            errorPagesConfig={errorPagesConfig}
            defaultAppSettings={APP_SETTINGS}
            defaultMessages={messages}
          />
        </Box>
      </Flex>
    </IntlProvider>
  );
};

export default PageError;
