import React from "react";
import get from "lodash/get";
import DateOfBirthField from "components/FormFields/DateOfBirthField";
import { safeEval } from "utils/helpers";
import { useSelector } from "react-redux";
import { makeSelectGA4PlanDetails } from "ducks/selectors/googleAnalyticsEvents";

const DOB = props => {
  const { disabled, readOnly, formValues, autoComplete } = props;

  const isDisabled =
    readOnly || safeEval(get, formValues, disabled, false, props);

  const GA4EventPlanDetails = useSelector(makeSelectGA4PlanDetails())
  return (
    <DateOfBirthField
      {...props}
      disabled={isDisabled}
      autoComplete={autoComplete}
      GA4EventPlanDetails={GA4EventPlanDetails}
    />
  );
};

export default DOB;
