import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import FormattedMessage from "components/FormattedMessage";
import { Field } from "redux-form";
import styled from "@emotion/styled";
import { Box, Flex } from "ui-components";
import FieldError from "./FieldError";
import { COLORS, iconThemes, selectOptionStyles } from "ui-components/theme";
import Label from "./Label";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { ChevronDown, ChevronUp, Close } from "components/Icons";
import find from "lodash/find";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? COLORS.white : COLORS.gray[3],
    ...selectOptionStyles.option
  }),
  placeholder: provided => ({
    ...provided,
    color: COLORS.gray[0]
  }),
  indicatorSeparator: () => {
    return { width: 0 };
  },
  dropdownIndicator: (props, state) => {
    return {
      ...props,
      ...selectOptionStyles.dropdownIndicator,
      color: state.isDisabled ? COLORS.gray[3] : COLORS.blue[4]
    };
  },
  control: props => {
    return {
      ...props,
      ...selectOptionStyles.control
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const overflow = "visible";

    return { ...provided, opacity, transition, overflow };
  },
  multiValue: provided => {
    const fontSize = "1.1rem";
    const backgroundColor = COLORS.blue[0];
    const color = COLORS.black;

    return { ...provided, fontSize, backgroundColor, color };
  },
  multiValueLabel: provided => {
    const color = COLORS.black;
    return { ...provided, color };
  },
  clearIndicator: (provided, state) => {
    const color = state.isDisabled ? COLORS.gray[3] : COLORS.blue[4];
    return { ...provided, color };
  }
};

const DropdownIndicator = props => {
  const menuIsOpen = get(props, "selectProps.menuIsOpen");
  const iconColor = get(props, "selectProps.iconColor");
  return (
    <components.DropdownIndicator {...props}>
      {menuIsOpen ? (
        <ChevronUp color={iconColor} />
      ) : (
        <ChevronDown color={iconColor} />
      )}
    </components.DropdownIndicator>
  );
};

const IntoTipContainer = styled(Flex)`
  font-size: 12px;
  border-radius: 8px;
  padding: 20px 30px 20px 20px;
  position: relative;
  margin-top: 10px;

  > .close-btn {
    position: absolute;
    right: 7px;
    top: 5px;
    cursor: pointer;
  }
`;

const SelectInput = ({
  isMulti,
  isSearchable = true,
  closeMenuOnSelect = true,
  input,
  options = [],
  placeholder,
  onInputChange,
  disabled,
  hidden,
  readonly,
  autoSelectOnlyOption,
  autoSelectFirstOption,
  meta: { touched, error },
  infoTip,
  themeColors,
  label,
  required,
  tooltip,
  formValues,
  labelMessage,
  defaultValue
}) => {
  const [showInfoTip, setShowInfoTip] = useState(
    get(infoTip, "visibleByDefault", false)
  );
  const infoTipBackground = get(
    infoTip,
    "background",
    themeColors?.info || COLORS.cyan[0]
  );
  const infoTipBackgroundStyles = get(infoTip, "backgroundStyles", {});
  const textColor = get(themeColors, "text", COLORS.gray[3]);
  const iconColor = get(themeColors, "icon", iconThemes.blue);

  useEffect(() => {
    const autoSelect = hidden || readonly || autoSelectFirstOption;
    const autoSelectOnly = autoSelectOnlyOption && options.length === 1;
    const autoSelectable = options.length && isEmpty(input.value);
    if ((autoSelect || autoSelectOnly) && autoSelectable) {
      input.onChange(options[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const onInfoIconClick = () => {
    setShowInfoTip(!showInfoTip);
  };

  useEffect(() => {
    // this is used instead of input.value because it will
    // trigger hidden field after this field is prefilled.
    // for example id type dropdown field.
    defaultValue && input.onChange(defaultValue);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <>
      {label && (
        <Label
          label={<FormattedMessage id={label} />}
          required={required}
          tooltip={tooltip}
          infoTip={infoTip}
          formValues={formValues}
          labelMessage={labelMessage}
          messageData={formValues}
          onInfoIconClick={onInfoIconClick}
          themeColors={themeColors}
        />
      )}

      <Select
        styles={customStyles}
        {...input}
        options={options}
        value={input.value}
        onChange={value => input.onChange(value)}
        onInputChange={value => {
          onInputChange && onInputChange(value);
        }}
        onBlur={() => {}}
        // onBlur={() => input.onBlur(input.value)}
        isMulti={isMulti}
        isSearchable={isSearchable}
        closeMenuOnSelect={closeMenuOnSelect}
        isDisabled={disabled || readonly}
        placeholder={placeholder && <FormattedMessage id={placeholder} />}
        components={{ DropdownIndicator }}
        iconColor={iconColor}
        className={input?.name}
        classNamePrefix={input?.name}
      />

      <FieldError touched={touched} error={error} />

      {isPlainObject(infoTip) && !isEmpty(infoTip) && (
        <IntoTipContainer
          style={{
            backgroundColor: infoTipBackground,
            display: showInfoTip ? "block" : "none",
            ...infoTipBackgroundStyles
          }}
        >
          <div className="close-btn" onClick={onInfoIconClick}>
            <Close size="17px" color={textColor} />
          </div>
          {<FormattedMessage id={get(infoTip, "message", "")} />}
        </IntoTipContainer>
      )}
    </>
  );
};

const DropdownField = props => {
  const {
    field,
    label,
    required,
    options,
    onChange,
    onInputChange,
    placeholder,
    isSearchable,
    isMulti,
    closeMenuOnSelect,
    hidden,
    readonly,
    autoSelectOnlyOption,
    autoSelectFirstOption,
    disabled,
    sx,
    tooltip,
    formValues,
    labelMessage,
    infoTip,
    themeColors
  } = props;

  if (!field) {
    return null;
  }

  const defaultValue = find(
    options,
    option => option.value && option.value === get(formValues, field)
  );

  return (
    <Box mb={8} hidden={hidden} data-testid={field} sx={sx}>
      <Field
        onInputChange={onInputChange}
        onChange={onChange}
        defaultValue={defaultValue}
        name={field}
        placeholder={placeholder}
        options={options}
        component={SelectInput}
        infoTip={infoTip}
        isSearchable={isSearchable}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        disabled={disabled}
        hidden={hidden}
        readonly={readonly}
        autoSelectOnlyOption={autoSelectOnlyOption}
        autoSelectFirstOption={autoSelectFirstOption}
        label={label}
        required={required}
        tooltip={tooltip}
        formValues={formValues}
        labelMessage={labelMessage}
        messageData={formValues}
        themeColors={themeColors}
      />
    </Box>
  );
};

DropdownField.defaultProps = {
  isMulti: false,
  isSearchable: false,
  label: "",
  options: []
};

export default DropdownField;
