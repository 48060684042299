import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import find from "lodash/find";
import map from "lodash/map";
import orderBy from "lodash/orderBy";

/* 
  "payment_cards": {
    "amex": {
      "value": "American Express",
      "validation": ["^3[47][0-9]{13}$"],
      "securityCode": {
        "length": 3,
        "name": "CSC"
      },
      "imageURL": "https://storage.googleapis.com/pidgc-ecom/assets/images/payment/card-icons/amex.png",
    },
  }
*/

const CardTypesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > .card-type {
    width: 52px;
    height: 32px;
    border-radius: 5px;
    margin: 0 8px;
  }

  .remove-margin {
    margin: 0 8px 0 0;
  }
`;

const DISPLAY_TYPES = {
  SINGLE: "single",
  ALL: "all"
};

function PaymentCardTypeSelector({
  types,
  cardNumber,
  display,
  isEnabled,
  className,
  onChange,
  typeOfCard,
  imageClass
}) {
  const [selectedCardType, setSelectedCardType] = useState(null);
  const [selectedPattern, setSelectedPattern] = useState(0);
  const cardTypes = orderBy(
    Object.values(types),
    ["display_sequence"],
    ["asc"]
  );

  useEffect(() => {
    if (!selectedCardType) {
      // load first card type as the default
      const defaultType = find(cardTypes, "default");
      const firstType = cardTypes?.[0];
      onChange(defaultType || firstType, selectedPattern);
    } else {
      onChange(selectedCardType, selectedPattern);
    }
  }, [selectedCardType, cardTypes, onChange, selectedPattern]);

  if (!isEnabled) return null;

  if (display === DISPLAY_TYPES.SINGLE) {
    let regex;
    let isMatched = false;
    let cardType = null;
    let patternIndex = 0;

    if (cardNumber) {
      cardType = find(cardTypes, card => {
        const validation = card?.validation || null;

        if (validation?.length) {
          for (let pattern = 0; pattern < validation.length; pattern += 1) {
            regex = new RegExp(validation[pattern], "img");
            isMatched = regex.test(cardNumber);

            if (isMatched) {
              patternIndex = pattern;
              break;
            }
          }
        }

        return isMatched;
      });

      if (cardType?.value !== selectedCardType?.value) {
        setSelectedCardType(cardType);
        setSelectedPattern(patternIndex);
      }

      return selectedCardType ? (
        <CardTypesContainer className={className}>
          <div
            key={`${selectedCardType.value}`}
            className={!imageClass ? "card-type" : "card-type remove-margin"}
          >
            <img alt={selectedCardType.value} src={selectedCardType.imageURL} />
          </div>
        </CardTypesContainer>
      ) : null;
    } else if (typeOfCard) {
      const cardDetailsArray = Object.values(types || {});
      const selectedCard =
        cardDetailsArray.find(
          card => card?.value?.toLowerCase() === typeOfCard?.toLowerCase()
        ) || types[typeOfCard.toLowerCase()];
      return selectedCard ? (
        <CardTypesContainer className={className}>
          <div
            key={`${selectedCard.value}`}
            className={!imageClass ? "card-type" : "card-type remove-margin"}
          >
            <img alt={selectedCard.value} src={selectedCard.imageURL} />
          </div>
        </CardTypesContainer>
      ) : null;
    }

    return null;
  }

  return (
    <CardTypesContainer className={className}>
      {map(cardTypes, card => (
        <div key={`${card.value}`} className="card-type">
          <img alt={card.value} src={card.imageURL} />
        </div>
      ))}
    </CardTypesContainer>
  );
}

PaymentCardTypeSelector.propTypes = {
  className: PropTypes.string,
  display: PropTypes.oneOf(Object.values(DISPLAY_TYPES)),
  types: PropTypes.object,
  isEnabled: PropTypes.bool,
  cardNumber: PropTypes.string,
  onChange: PropTypes.func,
  imageClass: PropTypes.bool,
  typeOfCard: PropTypes.string
};

PaymentCardTypeSelector.defaultProps = {
  className: "",
  displayTpe: DISPLAY_TYPES.ALL,
  types: {},
  isEnabled: false,
  cardNumber: null,
  imageClass: false,
  onChange: () => {},
  typeOfCard: null
};

export default PaymentCardTypeSelector;
