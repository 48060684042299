import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Tiles, Box, Button, Text } from "ui-components";
import { COLORS } from "ui-components/theme";
import FormattedMessage from "components/FormattedMessage";
import NumberBox from "components/NumberBox";
import Timer from "components/Timer";
import { enableResendActivation } from "ducks/actions";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import { buttonThemes, getButtonColorVariant } from "ui-components/theme";
import { getOnSubmitActionHandler, isMobile } from "utils/helpers";

/**
 * Container to hold multiple NumberBox components
 * @param {object} props
 */
const OTPBoxes = props => {
  const {
    size: boxCount,
    enable = true,
    label,
    field = "current_otp",
    change,
    instruction,
    onSubmit,
    clearData,
    timerKey,
    timer: {
      label: timerLabel,
      durationInSeconds = 0,
      enableResendLinkInSeconds,
      resendLinkCallback,
      resetOnExpire
    },
    themeColors,
    navigate,
    onsubmit,
    errorMessage,
    messageData,
    buttonColor,
    buttonWidth,
    listenToValidInputChange,
    children
  } = props;

  const buttonTheme =
    buttonColor || get(themeColors, "button", buttonThemes.blue);
  const hideSubmitButton = get(props, "hide_default_submit_button", false);
  const invalidOtpLabel = get(props, "invalid_otp_label", false);

  const dispatch = useDispatch();
  const [isValidInput, setIsValidInput] = useState(false);
  const [otp, setOTP] = useState([]);
  const [validationsArr, setValidationsArr] = useState(() =>
    generateArray(boxCount, false)
  );

  // Default items. The styling is calculated base on this
  const DEFAULT_SIZE = 6;
  const LARGE_SCREEN_FACTOR = 4;
  const margin = isMobile()
    ? []
    : [
        `${DEFAULT_SIZE / boxCount}rem`,
        `${(DEFAULT_SIZE / boxCount) * LARGE_SCREEN_FACTOR}rem`
      ];

  const OTP_TIMER_ID = timerKey || "general-otp-timer";
  const durationInMiliSeconds = durationInSeconds * 1000;

  const updateValidity = (valid, value = "", index) => {
    const validations = [...validationsArr];
    validations[index] = valid;

    const otpValues = [...otp];
    otpValues[index] = value;

    setOTP([...otpValues]);
    setValidationsArr([...validations]);
  };
  /**
   * Returns true if all elements have valid input
   */
  const isValid = useCallback(() => {
    let item = validationsArr.find(item => !item);
    setIsValidInput(item === undefined);
  }, [validationsArr]);

  /**
   * Watchout for validationsArr changes
   */
  useEffect(() => {
    isValid();
  }, [isValid, validationsArr]);

  useEffect(() => {
    isFunction(change) && change(field, otp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otp]);

  useEffect(() => {
    if (clearData) {
      setOTP([]);
      setIsValidInput(false);
      setValidationsArr(() => generateArray(boxCount, false));
    }
  }, [clearData, boxCount]);

  useEffect(() => {
    isFunction(listenToValidInputChange) &&
      listenToValidInputChange(boxCount, isValidInput);
  }, [boxCount, isValidInput, listenToValidInputChange]);

  const enableResendOTPLink = () => {
    console.debug("OTP one min expired");
    dispatch(enableResendActivation());
    resendLinkCallback && resendLinkCallback();
  };

  const onSubmitActionHandler = getOnSubmitActionHandler(props);
  const handleSubmit = isFunction(onSubmitActionHandler)
    ? onSubmitActionHandler
    : onSubmit;
  const onClickContinueButton = () => {
    // Maintaining the values in an OTP array. Thus joining them to build the otp string
    const otpValue = otp.join("");
    handleSubmit(navigate, { [field]: otpValue }, onsubmit);
  };

  //Render nothing if the component is disabled
  if (!enable) return null;

  // onPressEnter event is triggered only for valid otp
  const onPressEnterWrapper = () => {
    isValidInput && onClickContinueButton();
  };

  return (
    <>
      {instruction && (
        <Box textAlign="center">
          <FormattedMessage html id={instruction} values={messageData} />
        </Box>
      )}

      <Tiles width="32px" height={[null, "3rem"]} mx={margin}>
        {[...Array(boxCount)].map((_, index) => (
          <NumberBox
            key={index}
            clearBox={clearData}
            updateValidity={(valid, value) =>
              updateValidity(valid, value, index)
            }
            onPressEnter={onPressEnterWrapper}
          />
        ))}
      </Tiles>

      {errorMessage && (
        <Box>
          <Text
            my="1rem"
            textAlign="center"
            color={`${COLORS.red[1]} !important`}
          >
            {invalidOtpLabel ? (
              <FormattedMessage id={invalidOtpLabel} />
            ) : (
              <FormattedMessage html id={errorMessage} />
            )}
          </Text>
        </Box>
      )}

      <Box my="1rem" textAlign="center">
        <Timer
          id={OTP_TIMER_ID}
          durationInMiliSeconds={durationInMiliSeconds}
          label={timerLabel}
          checkpoints={[
            {
              time: enableResendLinkInSeconds * 1000,
              callback: enableResendOTPLink
            }
          ]}
          resetOnExpire={resetOnExpire}
        />
      </Box>

      {children}

      {!hideSubmitButton && (
        <Box sx={{ textAlign: "center" }}>
          <Button
            data-testid="otp-submit-button"
            disabled={!isValidInput}
            px={[64, 128]}
            width={buttonWidth}
            onClick={onClickContinueButton}
            sx={getButtonColorVariant(buttonTheme)}
          >
            {label && <FormattedMessage id={label} />}
          </Button>
        </Box>
      )}
    </>
  );
};

/**
 * Creates an array for given size and populate it with default values
 * @param {Number} size
 * @param {any} defaultValue
 */
const generateArray = (size, defaultValue) => {
  const emptyArray = [...Array(size)];
  return emptyArray.map(() => defaultValue);
};

export default OTPBoxes;
