import { createSelector } from "reselect";
import get from "lodash/get";

const linkSocialAccErrorDomain = state =>
  get(state.api, "linkSocialAccount.error", {});

// ***************************************************************
// ********************** Link Social Accoutn makeSelect **********************
// ***************************************************************

export const linkSocialAccErrorSelector = createSelector(
  linkSocialAccErrorDomain,
  (errorData = {}) => errorData?.response
);

export const linkSocialAccJWLErrorSelector = createSelector(
  linkSocialAccErrorSelector,
  errorData => {
    const apiError = errorData?.result?.message;
    return { jwlError: apiError };
  }
);

export const makeSelectLinkSocialAccJWLError = () =>
  linkSocialAccJWLErrorSelector;
