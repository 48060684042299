import React from "react";
import DropdownField from "components/FormFields/DropdownField";
import { constructOnChangeHandler } from "utils/helpers";

const STATE_OF_ISSUE = ({ issuingStates, ...props }) => (
  <DropdownField
    options={issuingStates}
    {...props}
    onChange={constructOnChangeHandler(props)}
  />
);

export default STATE_OF_ISSUE;
