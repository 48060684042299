import React from "react";
import history from "utils/history";
import { paths } from "utils/constants";
import PayLaterForm from "components/PayLaterForm";

const PAY_LATER_FORM = ({
  data,
  order: { order_ref },
  confirmPaymentOption
}) => {
  const onSubmit = () => {
    console.log("onSubmit!");
    confirmPaymentOption(order_ref, () => {
      history.push(`${paths.PAYMENT_RETURN_V3}?order_ref=${order_ref}`);
    });
  };

  return <PayLaterForm {...data} onSubmit={onSubmit} />;
};

export default PAY_LATER_FORM;
