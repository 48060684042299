import React, { memo } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import SmartRender from "containers/SmartRender";
import { createStructuredSelector } from "reselect";
import { reduxForm } from "redux-form";
import { urlParam } from "utils/helpers";
import { BSSMW } from "ducks/actions";
import { forms } from "utils/constants";
import {
  makeSelectOrderSummaryForm,
  makeSelectOrder,
  makeSelectSimType,
  makeSelectPlanDetails,
  makeSelectPlanName
} from "ducks/selectors";

const ESimTransferTermsConditions = Component =>
  function Comp(props) {
    const dispatch = useDispatch();

    const requestToken = urlParam("requestToken");

    const requestOTPForSimTransfer = callback => {
      const payload = {
        requestToken
      };
      const errorCodesToWhitelist = [400034, 400033, 400];

      dispatch(BSSMW.V2.requestOTP(payload, callback, errorCodesToWhitelist));
    };

    const agreeToSimTransfer = navigate => {
      requestOTPForSimTransfer((_, errors) => {
        if (!errors) navigate && navigate();
      });
    };

    return <Component {...props} agreeToSimTransfer={agreeToSimTransfer} />;
  };

const mapStateToProps = createStructuredSelector({
  formValues: makeSelectOrderSummaryForm(),
  order: makeSelectOrder(),
  simType: makeSelectSimType(),
  planDetails: makeSelectPlanDetails(),
  planName: makeSelectPlanName()
});
export function mapDispatchToProps() {
  return {};
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  reduxForm({
    form: forms.ORDER_SUMMARY_FORM,
    destroyOnUnmount: true
  }),
  memo
)(ESimTransferTermsConditions(SmartRender));
