import React, { memo, useState } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  makeSelectAuthId,
  makeSelectThemeColors,
  makeSelectSocialMediaDetails,
  makeSelectLoginFormMessageData,
  makeSelectIsSignUpWithIPDB,
  makeSelectGA4PlanDetails,
  makeSelectIsModalView,
  makeSelectModalType
} from "ducks/selectors";
import SmartRender from "containers/SmartRender";
import ResendActivationCode from "./ResendActivationCode";
import { getDeviceId } from "utils/localStorage";
import {
  getURLParams,
  isMobile,
  urlParam,
  OTP_ERROR_MAP,
  setNRError,
  gotoUrl,
  pathWithChannel
} from "utils/helpers";
import { ERROR_CODES, forms, MODAL_TYPE, publicPaths } from "utils/constants";
import useAuthorizedUserRedirection from "hooks/useAuthorizedUserRedirection";
import authenticateUserAndSaveToken from "services/userAuthenticate";
import useLoadingSpinner from "hooks/useLoadingSpinner";
import { makeSelectIsValidOTP } from "ducks/selectors/forms/userLoginOTPForm";
import get from "lodash/get";
import isArray from "lodash/isArray";
import { setData, UserService } from "ducks/actions";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";

const OTPSubmitPage = Component =>
  function Comp(props) {
    const { isSignUpWithAuIPDB, showErrorModal } = props;
    const dispatch = useDispatch();
    const redirectUser = useAuthorizedUserRedirection();
    const [isLoading, setisLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const { LoadingSpinner } = useLoadingSpinner();
    const { isSocialLogin } = getURLParams();
    const timerKey = urlParam("key") || props.timerKey;
    const [clearOTP, setClearOTP] = useState(false);
    const auth_id = useSelector(makeSelectAuthId());
    const themeColors = useSelector(makeSelectThemeColors());
    const socialMediaDetails = useSelector(makeSelectSocialMediaDetails());
    const isValidOTP = useSelector(makeSelectIsValidOTP());
    const loginFormMessageData = useSelector(makeSelectLoginFormMessageData());

    const combinedMessageData = {
      ...props.messageData,
      ...loginFormMessageData
    };

    const privateComponents = {
      RESEND_ACTIVATION_CODE_LINK: props => (
        <ResendActivationCode
          {...props}
          setErrorMessage={setErrorMessage}
          messageData={combinedMessageData}
        />
      )
    };

    /**
     * Submit a login request with user entered OTP and auth_id response
     * On success, navigates to correct landing page
     * @param {string} otp_code user provided OTP
     */
    const loginWithOTP = (_, payload) => {
      if (!isValidOTP) return;
      const additionalHeaders = {};
      const current_otp = get(payload, "current_otp");
      const otp_code = isArray(current_otp)
        ? current_otp.join("")
        : current_otp;
      const bodyParams = {
        device: {
          // TODO: Should this be dynamic?
          app_type: "ecosystem",
          device_id: getDeviceId(),
          device_type: isMobile ? "Mobile" : "web"
        },
        email_auth: {
          otp_code,
          auth_id
        }
      };

      if (isSocialLogin && !isSignUpWithAuIPDB) {
        bodyParams.social_auth = socialMediaDetails;
      }

      /**
       * Triggers user routing upon successful authentication
       * @param {object} authResponse success response result
       * @param {object} errors API errors only if failed
       */
      const onUserAuthenticateCallback = (authResponse, errors) => {
        // setting to fall only when there are errors. No need for other case because user gets redirected
        // Checking for error status code and displaying relevant message
        if (errors) {
          if (authResponse.code === ERROR_CODES.planNotAllowed) {
            showErrorModal(MODAL_TYPE.PLAN_NOT_ALLOWED);
          }
          const error_locale =
            OTP_ERROR_MAP[authResponse.code] || OTP_ERROR_MAP.generic;
          setErrorMessage(error_locale);
          isSignUpWithAuIPDB &&
            setNRError("AU LOGIN ERROR", {
              API: "auIpdbUserAuthenticate",
              au_auth_code: bodyParams?.email_auth?.auth_code
            });
          return setisLoading(false);
        } else {
          GA4EventsService.publish(
            userAuthenticatedEventData(props.GA4EventPlanDetails, isSocialLogin)
          );
          redirectUser(authResponse, errors);
        }
      };
      setClearOTP(true);
      setisLoading(true);

      /**
       * For ipdb user new otp authentication api is called
       */
      if (isSignUpWithAuIPDB) {
        bodyParams.email_auth.auth_code = socialMediaDetails.authorization_code;
        dispatch(
          UserService.V4.auIpdbUserAuthenticate(
            additionalHeaders,
            bodyParams,
            onUserAuthenticateCallback
          )
        );
      } else {
        authenticateUserAndSaveToken(
          additionalHeaders,
          bodyParams,
          onUserAuthenticateCallback,
          dispatch
        );
      }
    };

    if (isLoading) return <LoadingSpinner />;

    return (
      <Component
        {...props}
        privateComponents={privateComponents}
        clearData={clearOTP}
        timerKey={timerKey}
        onSubmit={loginWithOTP}
        themeColors={themeColors}
        nobox
        errorMessage={errorMessage}
        isValidOTP={isValidOTP}
        messageData={combinedMessageData}
      />
    );
  };

const mapStateToProps = createStructuredSelector({
  themeColors: makeSelectThemeColors(),
  isSignUpWithAuIPDB: makeSelectIsSignUpWithIPDB(),
  GA4EventPlanDetails: makeSelectGA4PlanDetails(),
  isModalView: makeSelectIsModalView(),
  modalType: makeSelectModalType()
});
const mapDispatchToProps = dispatch => {
  return {
    showErrorModal: type => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: type }
        })
      );
    },
    hideModalAndNavigate: () => {
      dispatch(
        setData({ key: "isModalView", data: { isModalView: false, type: "" } })
      );
      gotoUrl(pathWithChannel(publicPaths.LOGIN_PAGE), false, { reset: true });
    }
  };
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.USER_LOGIN_OTP_FORM,
    destroyOnUnmount: false
  }),
  memo
)(OTPSubmitPage(SmartRender));

const userAuthenticatedEventData = (details = {}, isSocialLogin) => {
  const { customer_id, plan_name, plan_type, journey_type } = details;

  return {
    event: GA4EVENTS.EMAIL_AUTHENTICATED,
    ecommerce: {
      customer_id,
      plan_name,
      journey_type: journey_type || (isSocialLogin ? "au-portin" : ""),
      plan_type
    }
  };
};
