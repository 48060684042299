import styled from "@emotion/styled";
import { Image } from "rebass";

const Avatar = styled(Image)``;

Avatar.defaultProps = {
  sx: {
    borderRadius: 9999,
    border: "1px solid rgba(158, 158, 158, 0.5)",
    padding: 1,
    width: [36, 54],
    height: [36, 54],
    margin: "auto",
    boxShadow: "0 2px 8px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06)"
  }
};

export default Avatar;
