import { createSelector } from "reselect";
import {
  genericSelector,
  uploadIdentityDetailsSelector,
  countriesSelector,
  primaryDocTypesSelector,
  dateOptionsSelector,
  monthOptionsSelector,
  yearOptionsSelector,
  verifyIdentitySelector,
  statesSelector,
  deliveryInfoSelector,
  uploadIdentityDetailsDomain,
  sessionTokenSelector,
  guestOrderSelector
} from "ducks/selectors";
import get from "lodash/get";
import find from "lodash/find";
import moment from "moment";
import {
  constructVerifyIdentityParams,
  deliverySlotParams,
  userDetailParams,
  documentsParams,
  deliverDetailParams,
  identityELOAParams
} from "utils/helpers";

const uploadIdentityDomain = state => {
  return get(state, "form.UploadIdentityForm.values", {});
};

export const uploadIdentitySelector = createSelector(
  uploadIdentityDomain,
  uploadIdentity => uploadIdentity
);
export const makeSelectUploadIdentityForm = () => uploadIdentitySelector;

export const makeSelectCanUploadIdentity = () =>
  createSelector(uploadIdentityDetailsDomain, uploadIdentity => {
    return get(uploadIdentity, "eligibility") === true;
  });

export const makeSelectUploadIdentityPayload = () =>
  createSelector(
    verifyIdentitySelector,
    uploadIdentitySelector,
    deliveryInfoSelector,
    sessionTokenSelector,
    (verifyIdentity, formValues, deliveryInfo, token) => {
      const { type, receive_my_package } = formValues;
      return {
        identity_verified: get(verifyIdentity, "success", false),
        ...deliverySlotParams(deliveryInfo, formValues),
        ...userDetailParams(formValues),
        documents: {
          ...documentsParams(formValues)
        },
        type: type,
        token: token,
        ...deliverDetailParams(deliveryInfo, formValues),
        verificationPayload: { ...constructVerifyIdentityParams(formValues) },
        ...(receive_my_package ? identityELOAParams(formValues) : {})
      };
    }
  );

export const makeSelectUploadIdentityFormInitialValues = props =>
  createSelector(
    genericSelector,
    countriesSelector,
    primaryDocTypesSelector,
    dateOptionsSelector,
    monthOptionsSelector,
    yearOptionsSelector,
    uploadIdentityDetailsSelector,
    statesSelector,
    guestOrderSelector,
    (
      generic,
      countries,
      docTypes,
      dateOptions,
      monthOptions,
      yearOptions,
      uploadIdentityDetails,
      states,
      guestOrder
    ) => {
      const { type } = get(props.match, "params", {});

      const orderUserDetail = get(
        uploadIdentityDetails,
        "order_user_detail",
        {}
      );

      const orderDocumentDetail = get(
        uploadIdentityDetails,
        "order_document_detail",
        {}
      );

      const nationality = find(
        countries,
        nationality => nationality.value === get(orderUserDetail, "nationality")
      );
      const docType = find(
        docTypes,
        type => type.value === get(orderDocumentDetail, "doc_type")
      );

      const state_of_issue = find(
        states,
        type => type.value === get(orderDocumentDetail, "issued_by")
      );

      const country_of_issue = find(
        countries,
        type => type.value === get(orderDocumentDetail, "issued_by")
      );

      const dob = moment(orderUserDetail.dob, "YYYY-MM-DD");
      const date = dob
        .date()
        .toString()
        .padStart(2, "0");
      const month = (dob.month() + 1).toString().padStart(2, "0");

      const dobDay = find(
        dateOptions,
        option => option.value === (date < 10 ? `${date}` : `${date}`)
      );

      const dobMonth = find(
        monthOptions,
        option => option.value === (month < 10 ? `${month}` : `${month}`)
      );
      const dobYear = find(yearOptions, option => option.value === dob.year());
      const updatedDobDay = get(dobDay, "value");
      const updatedDobMonth = get(dobMonth, "value");
      const updatedDobYear = get(dobYear, "value");
      const dobString =
        updatedDobDay && updatedDobMonth && updatedDobYear
          ? `${updatedDobDay}-${updatedDobMonth}-${updatedDobYear}`
          : undefined;
      const age = dobString
        ? moment().diff(moment(dobString, "DD-MM-YYYY"), "years")
        : undefined;

      return {
        country_code: get(generic, "contact_number.prefix"),
        first_name: get(orderUserDetail, "name"),
        last_name: get(orderUserDetail, "last_name"),
        middle_name: get(orderUserDetail, "middle_name"),
        nationality,
        contact_number: get(orderUserDetail, "phone_number"),
        email: get(orderUserDetail, "email"),
        doc_type: docType,
        nric: get(orderDocumentDetail, "nric"),
        state_of_issue,
        country_of_issue,
        dobDay,
        dobMonth,
        dobYear,
        dob: dobString,
        age,
        type,
        same_as_delivery_address: true,
        delivery_location_error: "",
        billing_location_error: "",
        delivery_show_all_address_fields: false,
        billing_show_all_address_fields: false,
        order_id: get(guestOrder, "id", "")
      };
    }
  );
export const makeSelecMessageData = props =>
  createSelector(uploadIdentityDetailsSelector, uploadIdentityDetails => {
    const orderUserDetail = get(uploadIdentityDetails, "order_user_detail", {});
    return {
      first_name: get(orderUserDetail, "name"),
      last_name: get(orderUserDetail, "last_name"),
      middle_name: get(orderUserDetail, "middle_name")
    };
  });
