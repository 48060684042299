import React, { useState } from "react";
import FormattedMessage from "components/FormattedMessage";
import { Field } from "redux-form";
import styled from "@emotion/styled";
import { Box, Input, Text, Flex, MaskedInput, Button } from "ui-components";
import Label from "./Label";
import FieldError from "./FieldError";
import Spinner, { styles } from "components/Spinner";
import { EmoticonHappyOutline, EmoticonSadOutline } from "components/Icons";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
import { Close } from "../Icons";
import { COLORS, getButtonColorVariant } from "ui-components/theme";
import { handleRelatedTargetAfterBlur } from "utils/helpers";
import FieldSuccessMessage from "./FieldSuccessMessage";

const IntoTipContainer = styled(Flex)`
  font-size: 12px;
  border-radius: 8px;
  padding: 20px 30px 20px 20px;
  position: relative;
  margin-top: 10px;

  > .close-btn {
    position: absolute;
    right: 7px;
    top: 5px;
    cursor: pointer;
  }
`;

const RenderField = ({
  tooltip,
  infoTip,
  input,
  label,
  type,
  required,
  optional,
  placeholder,
  minLength,
  maxLength,
  onEnter,
  onBlur,
  readOnly,
  inputStyle,
  meta: { asyncValidating, touched, error },
  inlineValidation = {},
  formValues,
  labelMessage,
  mask,
  themeColors,
  isAutoComplete,
  autoCompleteFieldName,
  showActionButton,
  actionButtonText,
  onClickActionBtn,
  containerProps,
  successMessageData: {
    showSuccessMessage,
    fieldSuccessMessage,
    successMessageIconUrl,
    htmlLinkMessage,
    onClickHtmlLink
  } = {}
}) => {
  const [showInfoTip, setShowInfoTip] = useState(
    get(infoTip, "visibleByDefault", false)
  );
  const {
    inlineMessage: inlineValidationMessage,
    valid: inlineValid,
    loading: inlineValidationLoading
  } = inlineValidation;
  const infoTipBackground = get(
    infoTip,
    "background",
    themeColors?.info || COLORS.cyan[0]
  );
  const textColor = get(themeColors, "text", COLORS.gray[3]);
  const infoTipBackgroundStyles = get(infoTip, "backgroundStyles", {});
  const buttonTheme = get(themeColors, "button", {});
  const buttonStyles = getButtonColorVariant(buttonTheme, "outline");

  const onKeyDown = e => {
    if (get(e, "key") === "Enter" && isFunction(onEnter)) {
      try {
        // eslint-disable-next-line no-unused-expressions
        e?.target?.blur();
      } catch (error) {
        console.log("Error Blurring input", e);
      }
      onEnter();
    }
  };

  const handleOnBlur = (event, newValue, previousValue, name) => {
    if (isFunction(onBlur)) {
      onBlur(event, newValue, previousValue, name);
    } else if (isFunction(input.onBlur)) {
      input.onBlur(event, newValue, previousValue, name);
    }
    !onClickActionBtn && handleRelatedTargetAfterBlur(event);
  };

  /**
   * If it's a masked input, masked input will send event and value params in the call back.
   * The value contains the unmasked value. The same has been done in the phone field component.
   */
  const handleMaskInputOnBlur = (event, value) => {
    if (isFunction(onBlur)) {
      onBlur(event, value);
    } else if (isFunction(input.onBlur)) {
      // for the redux form event send value and if value is not present (if not a masked input) send the event object.
      input.onBlur(value || event);
    }

    handleRelatedTargetAfterBlur(event);
  };

  const onInfoIconClick = () => {
    setShowInfoTip(!showInfoTip);
  };

  const inputRenderer = msg => {
    const InputElement = !isEmpty(mask) ? MaskedInput : Input;
    const onBlurHandler = !isEmpty(mask) ? handleMaskInputOnBlur : handleOnBlur;

    return (
      <InputElement
        style={inputStyle}
        data-testid={`${label}_text_field`}
        {...input}
        {...(msg && { placeholder: msg })}
        type={type}
        disabled={readOnly}
        autoComplete={isAutoComplete ? autoCompleteFieldName : "off"}
        minLength={minLength}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        onBlur={onBlurHandler}
        {...(!isEmpty(mask) && { ...mask })}
      />
    );
  };

  return (
    <>
      {label && (
        <Label
          label={<FormattedMessage id={label} />}
          tooltip={tooltip}
          infoTip={infoTip}
          formValues={formValues}
          required={required}
          optional={optional}
          labelMessage={labelMessage}
          messageData={formValues}
          onBlur={handleOnBlur}
          onInfoIconClick={onInfoIconClick}
          themeColors={themeColors}
        />
      )}

      {!showActionButton &&
        (placeholder ? (
          <FormattedMessage id={placeholder}>
            {msg => inputRenderer(msg)}
          </FormattedMessage>
        ) : (
          inputRenderer()
        ))}

      {showActionButton && (
        <Flex>
          {placeholder ? (
            <FormattedMessage id={placeholder}>
              {msg => inputRenderer(msg)}
            </FormattedMessage>
          ) : (
            inputRenderer()
          )}
          <Box
            width={0.25}
            sx={{
              display: "flex",
              alignItems: "flex-end"
            }}
          >
            <FormattedMessage id={actionButtonText}>
              {msg => (
                <Button
                  sx={{
                    ...buttonStyles,
                    minWidth: "85px !important",
                    maxHeight: "30px",
                    marginBottom: "0px !important",
                    padding: "5px",
                    paddingTop: "6px",
                    fontSize: "15px"
                  }}
                  disabled={error || readOnly}
                  onClick={onClickActionBtn}
                >
                  {msg}
                </Button>
              )}
            </FormattedMessage>
          </Box>
        </Flex>
      )}
      {
        <Flex
          variant="inlineMessage"
          className={inlineValid ? "valid" : "invalid"}
          alignItems="center"
        >
          {asyncValidating || inlineValidationLoading ? (
            <Spinner sx={styles.inlineMessage} />
          ) : (
            <>
              <Text
                color={inlineValid ? themeColors?.success : themeColors?.error}
              >
                {inlineValidationMessage}&nbsp;
              </Text>
              {inlineValid === true && (
                <EmoticonHappyOutline color={themeColors?.success} />
              )}
              {inlineValid === false && (
                <EmoticonSadOutline color={themeColors?.error} />
              )}
            </>
          )}
        </Flex>
      }

      <FieldError touched={touched} error={error} />
      {showSuccessMessage && (
        <FieldSuccessMessage
          touched={touched}
          message={fieldSuccessMessage}
          iconUrl={successMessageIconUrl}
          htmlLinkMessage={htmlLinkMessage}
          onClickHtmlLink={onClickHtmlLink}
          containerProps={containerProps}
        />
      )}

      {isPlainObject(infoTip) && !isEmpty(infoTip) && (
        <IntoTipContainer
          style={{
            backgroundColor: infoTipBackground,
            display: showInfoTip ? "block" : "none",
            ...infoTipBackgroundStyles
          }}
        >
          <div className="close-btn" onClick={onInfoIconClick}>
            <Close size="17px" color={textColor} />
          </div>
          {<FormattedMessage id={get(infoTip, "message", "")} />}
        </IntoTipContainer>
      )}
    </>
  );
};

const TextField = props => {
  const {
    field,
    type,
    pattern,
    label,
    tooltip,
    infoTip,
    placeholder,
    onChange,
    onEnter,
    onBlur,
    normalize,
    readOnly,
    hidden,
    required,
    disabled,
    optional,
    inlineValidation,
    minLength,
    maxLength,
    sx,
    inputStyle,
    formValues,
    mask,
    themeColors,
    style = {},
    autoComplete,
    showActionButton,
    actionButtonText,
    clickTextFieldActionBtn,
    showTextFieldSuccessMessage,
    fieldSuccessMessage,
    successMessageIconUrl,
    onClickHtmlLink,
    htmlLinkMessage
  } = props;
  if (!field) {
    return null;
  }

  const inputType = type === "number" ? "text" : type;
  const inputPattern = type === "number" && !pattern ? "[0-9]" : pattern;

  return (
    <Box mb={8} hidden={hidden} data-testid={field} sx={sx} style={style}>
      <Field
        readOnly={readOnly || disabled}
        name={field}
        required={required}
        optional={optional}
        component={RenderField}
        type={inputType}
        pattern={inputPattern}
        label={label}
        showActionButton={showActionButton}
        placeholder={placeholder}
        onChange={onChange}
        onEnter={onEnter}
        onBlur={onBlur}
        normalize={normalize}
        tooltip={tooltip}
        infoTip={infoTip}
        formValues={formValues}
        inlineValidation={inlineValidation}
        inputStyle={inputStyle}
        minLength={minLength}
        maxLength={maxLength}
        mask={mask}
        themeColors={themeColors}
        isAutoComplete={autoComplete}
        autoCompleteFieldName={field}
        onClickActionBtn={clickTextFieldActionBtn}
        actionButtonText={actionButtonText}
        containerProps={props}
        successMessageData={{
          showSuccessMessage: showTextFieldSuccessMessage,
          fieldSuccessMessage,
          successMessageIconUrl,
          htmlLinkMessage,
          onClickHtmlLink
        }}
      />
    </Box>
  );
};

TextField.defaultProps = {
  type: "text",
  isAutoComplete: false
};

export default TextField;
