import React from "react";
import get from "lodash/get";
import TextField from "components/FormFields/TextField";
import { constructOnChangeHandler } from "utils/helpers";

const POSTAL_CODE = ({ data, postalCodeValidation, style = {}, ...props }) => {
  const field = `${get(data, "prefix")}_${props.field}`;
  const inlineValidation = get(postalCodeValidation, [field], {});

  return (
    <TextField
      {...props}
      style={style}
      inlineValidation={inlineValidation}
      field={field}
      onChange={constructOnChangeHandler(props)}
    />
  );
};

export default POSTAL_CODE;
