import { createAction } from "redux-actions";

export const updateUI = createAction("UPDATE_UI");
export const initiateGetTokenCall = createAction("INITIATE_GET_TOKEN_CALL");

export const enableResendActivation = () => {
  return updateUI({
    key: "otp.resend_activation",
    data: true
  });
};

export const disableResendActivation = () => {
  return updateUI({
    key: "otp.resend_activation",
    data: false
  });
};

export const refreshTokenInProgress = (value = true) => {
  return updateUI({
    key: "token.refresh.is_fetching",
    data: value
  });
};

export const esimTransferUserConcent = (value = true) => {
  return updateUI({
    key: "esimTransfer.concent",
    data: value
  });
};

export const setContractDetailsPageVisited = () => {
  return updateUI({
    key: "contractDetails.pageVisited",
    data: true
  });
};

export const esimTransferPlanChangeConsent = (value = true) => {
  return updateUI({
    key: "esimTransfer.consent.plan_change",
    data: value
  });
};

export const setSelectedPaymentTypeToEdit = type => {
  return updateUI({
    key: "paymentUpdate.type",
    data: type
  });
};

export const dataOnlyUserRegistration = (value = {}) => {
  return updateUI({
    key: "dataOnlyUserRegistration.emailVerification",
    data: value
  });
};

export const dataOnlyContactNumVerification = (value = {}) => {
  return updateUI({
    key: "dataOnlyUserRegistration.contactNumVerification",
    data: value
  });
};

export const setPollingPaymentStatus = value => {
  return updateUI({
    key: "payment.pollingPaymentDetails",
    data: value
  });
};

export const getOMSToken = isInitiated => initiateGetTokenCall(isInitiated);
