import React, { memo, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import SmartRender from "containers/SmartRender";
import { setActivationSuccess } from "utils/localStorage";
import { nextPageWithParams, getChannelFromUrl, urlParam } from "utils/helpers";
import {
  makeSelectAuthToken,
  makeSelectFormattedSelectedNumber,
  makeSelectIosDevice,
  makeSelectOrderDetails,
  makeSelectOrderSummaryForm,
  makeSelectPlanDetails,
  makeSelectPersonalDetailsForm,
  makeSelectOrderParams
} from "ducks/selectors";
import get from "lodash/get";
import AnalyticsFactory from "analytics/AnalyticsFactory";
import { paths, SELF_ACTIVATION_SIM, forms } from "utils/constants";

// HOC = higher order component, like a wrapper
const SimActivationSuccessHOC = Component =>
  function Comp(props) {
    const { initialValues, personalDetailFormsValues, orderParams } = props;

    useEffect(() => {
      if (getChannelFromUrl() !== SELF_ACTIVATION_SIM) setActivationSuccess();

      AnalyticsFactory.pushEvent("success_page_visit", {
        order_ref: get(initialValues, "order_ref"),
        forms: {
          [forms.PERSONAL_DETAILS_FORM]: {
            values: personalDetailFormsValues
          }
        },
        orderParams: {
          ...orderParams
        }
      });
    }, [initialValues, orderParams, personalDetailFormsValues]);

    return <Component {...props} />;
  };

const mapStateToProps = createStructuredSelector({
  selectedNumber: makeSelectFormattedSelectedNumber(),
  authToken: makeSelectAuthToken(),
  formValues: makeSelectOrderSummaryForm(),
  planDetails: makeSelectPlanDetails(),
  orderDetails: makeSelectOrderDetails(),
  isIosDevice: makeSelectIosDevice(),
  personalDetailFormsValues: makeSelectPersonalDetailsForm(),
  initialValues: () => ({
    order_ref: urlParam("order_ref")
  }),
  orderParams: makeSelectOrderParams()
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  nextPage: nextPageWithParams(
    paths.DASHBOARD,
    stateProps.selectedNumber,
    stateProps.authToken
  )
});

export function mapDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(withConnect, memo)(SimActivationSuccessHOC(SmartRender));
