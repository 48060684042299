import React from "react";
import AmazonWalletWidget from "containers/SmartRender/components/amazomPay/amazanWalletWidget";

const PAYMENT_AMAZON_WIDGETS = ({ generic, styles, onSelectPaymentCard }) => (
  <AmazonWalletWidget
    generic={generic}
    styles={styles}
    onSelectPaymentCard={onSelectPaymentCard}
  />
);

export default PAYMENT_AMAZON_WIDGETS;
