import get from "lodash/get";
import { createSelector } from "reselect";
import { getDeviceId } from "utils/localStorage";
import {
  countryCodeSelector,
  loggedInUserDetailsSelector
} from "ducks/selectors";
import { MANAGE_PROFILE_OTP_MAPPINGS } from "utils/constants";

export const requestUserAuthOTPDomain = state =>
  get(state.api, "requestUserAuthOTP.data", {});

export const otpSecuredActionPayload = createSelector(
  requestUserAuthOTPDomain,
  userAuthOTPDomain => {
    return {
      auth_id: userAuthOTPDomain?.auth_id,
      device: {
        device_id: getDeviceId(),
        device_type: "Mobile"
      }
    };
  }
);

export const userAuthOTPPayloadSelector = createSelector(
  loggedInUserDetailsSelector,
  countryCodeSelector,
  (userDetails, countryCode) => {
    const { telco_info = {} } = userDetails || {};
    return {
      request_type: MANAGE_PROFILE_OTP_MAPPINGS.GENERIC_VERIFY_MOBILE,
      auth_mode: MANAGE_PROFILE_OTP_MAPPINGS.ENHANCED_SMS,
      app_type: MANAGE_PROFILE_OTP_MAPPINGS.APP_TYPE,
      phone_no: telco_info.phone_no,
      isd_code: countryCode?.toString()
    };
  }
);

export const makeSelectUserAuthOTPPayload = () => userAuthOTPPayloadSelector;
export const makeSelectOtpSecuredActionPayload = () => otpSecuredActionPayload;
