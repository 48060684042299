import React from "react";
import { Box, Flex } from "ui-components";
import TextField from "components/FormFields/TextField";
import get from "lodash/get";

const DeliveryAddressForm = ({ fields = {}, fieldPrefix = "delivery" }) => {
  const { hse_blk_tower, unit_no, building_name, street_name } = fields;

  return (
    <>
      <Flex flexWrap="wrap" mx={-2}>
        {get(hse_blk_tower, "enable") && (
          <Box width={[1, 1 / 4, 1 / 4]} px={2}>
            <TextField
              {...hse_blk_tower}
              field={`${fieldPrefix}_${hse_blk_tower.field}`}
            />
          </Box>
        )}

        {get(street_name, "enable") && (
          <Box width={[1, 3 / 4, 3 / 4]} px={2}>
            <TextField
              {...street_name}
              field={`${fieldPrefix}_${street_name.field}`}
            />
          </Box>
        )}
      </Flex>

      <Flex flexWrap="wrap" mx={-2}>
        {get(unit_no, "enable") && (
          <Box width={[1, 1 / 4, 1 / 4]} px={2}>
            <TextField {...unit_no} field={`${fieldPrefix}_${unit_no.field}`} />
          </Box>
        )}

        {get(building_name, "enable") && (
          <Box width={[1, 3 / 4, 3 / 4]} px={2}>
            <TextField
              {...building_name}
              field={`${fieldPrefix}_${building_name.field}`}
            />
          </Box>
        )}
      </Flex>
    </>
  );
};

export default DeliveryAddressForm;
