import React, { useEffect, useState } from "react";
import { Range, getTrackBackground } from "react-range";
import FormattedMessage from "components/FormattedMessage";
import { Field } from "redux-form";
import { Box, Flex } from "ui-components";
import FieldError from "./FieldError";
import { COLORS, iconThemes, ROBOTO } from "ui-components/theme";
import Label from "./Label";
import isFunction from "lodash/isFunction";
import isEmpty from "lodash/isEmpty";

const RangeInput = ({
  input,
  disabled,
  readonly,
  meta: { touched, error },
  min,
  max,
  step,
  formatValue,
  initialValues
}) => {
  const defaultValues = !isEmpty(initialValues) ? initialValues : [min, max];
  const [rangeValues, setRangeValues] = useState(defaultValues);

  useEffect(() => {
    input && input.onChange(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatText = value => {
    return isFunction(formatValue) ? formatValue(value) : value.toFixed(0);
  };

  const renderTrack = ({ props, children }) => (
    <Flex
      flexDirection="column"
      onMouseDown={props.onMouseDown}
      onTouchStart={props.onTouchStart}
      style={{
        ...props.style,
        height: "36px",
        display: "flex",
        width: "100%"
      }}
    >
      <div
        ref={props.ref}
        style={{
          height: "8px",
          width: "100%",
          borderRadius: "8px",
          background: getTrackBackground({
            values: rangeValues,
            colors: [COLORS.gray[0], iconThemes.blue, COLORS.gray[0]],
            min: min,
            max: max
          }),
          alignSelf: "center"
        }}
      >
        {children}
      </div>
      <Flex justifyContent="space-between" width="100%">
        <div
          style={{
            paddingTop: "1rem",
            color: iconThemes.gray,
            fontWeight: "bold",
            fontSize: "14px",
            fontFamily: ROBOTO,
            marginLeft: "-0.5rem",
            borderRadius: "4px",
            backgroundColor: "transparent"
          }}
        >
          {formatText(min)}
        </div>
        <div
          style={{
            paddingTop: "1rem",
            color: iconThemes.gray,
            fontWeight: "bold",
            fontSize: "14px",
            fontFamily: ROBOTO,
            marginRight: "-1rem",
            borderRadius: "4px",
            backgroundColor: "transparent"
          }}
        >
          {formatText(max)}
        </div>
      </Flex>
    </Flex>
  );

  const renderThumb = ({ index, props, isDragged }) => (
    <>
      <div
        {...props}
        style={{
          ...props.style,
          height: isDragged ? "26px" : "24px",
          width: isDragged ? "26px" : "24px",
          borderRadius: "50%",
          backgroundColor: iconThemes.blue,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.3)"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "-1.5rem",
            color: iconThemes.blue,
            fontWeight: "bold",
            fontSize: "14px",
            fontFamily: ROBOTO,
            padding: "8px",
            borderRadius: "4px",
            backgroundColor: "transparent"
          }}
        >
          {formatText(rangeValues[index])}
        </div>
      </div>
    </>
  );

  return (
    <Flex
      variant="transparent"
      justifyContent="center"
      p={"1.75rem 1.5rem 0.13rem 1rem"}
    >
      <Range
        {...input}
        onChange={setRangeValues}
        onFinalChange={input.onChange}
        onBlur={() => {}}
        isDisabled={disabled || readonly}
        min={min}
        max={max}
        step={step}
        values={rangeValues}
        renderThumb={renderThumb}
        renderTrack={renderTrack}
      />
      <FieldError touched={touched} error={error} />
    </Flex>
  );
};

const RangeField = props => {
  const {
    field,
    label,
    required,
    options,
    onChange,
    placeholder,
    hidden,
    readonly,
    disabled,
    sx,
    tooltip,
    formValues,
    labelMessage,
    min,
    max,
    step,
    initialValues,
    formatValue
  } = props;

  if (!field) {
    return null;
  }

  return (
    <Box mb={8} hidden={hidden} data-testid={field} sx={sx}>
      {label && (
        <Label
          label={<FormattedMessage id={label} />}
          required={required}
          tooltip={tooltip}
          formValues={formValues}
          labelMessage={labelMessage}
          messageData={formValues}
        />
      )}

      <Field
        onChange={onChange}
        name={field}
        placeholder={placeholder}
        options={options}
        component={RangeInput}
        disabled={disabled}
        hidden={hidden}
        readonly={readonly}
        min={min}
        max={max}
        step={step}
        initialValues={initialValues}
        formatValue={formatValue}
      />
    </Box>
  );
};

RangeField.defaultProps = {
  min: 0,
  max: 100,
  step: 10
};

export default RangeField;
