export default {
  modalStyle: {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.25)",
      zIndex: 999
    },
    content: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "16px",
      right: "16px",
      border: "1px solid #ccc",
      background: "#fff",
      overflow: "auto",
      WebkitOverflowScrolling: "touch",
      borderRadius: "12px",
      outline: "none",
      padding: "1rem",
      maxWidth: "700px",
      maxHeight: "1000px",
      height: "fit-content",
      margin: "auto"
    }
  },
  canvasStyle: {
    height: 200,
    width: "75%",
    margin: "1em auto 0"
  },
  signedBoxStyle: {
    mx: -1,
    my: 1,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderColor: "gray.0",
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: 1
  },
  signatureStyle: {
    width: "100%",
    borderRadius: 12
  }
};
