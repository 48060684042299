import React from "react";
import get from "lodash/get";
import DropdownField from "components/FormFields/DropdownField";
import { constructOnChangeHandler } from "utils/helpers";

const DOC_TYPE = ({ docTypes, data, ...props }) => {
  const options = get(docTypes, get(data, "type", "primary"), []);
  return (
    <DropdownField
      options={options}
      {...props}
      onChange={constructOnChangeHandler(props)}
    />
  );
};

export default DOC_TYPE;
