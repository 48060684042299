import React, { useState, memo, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  makeSelectAppSettings,
  makeSelectLocale,
  makeSelectThemeColors,
  makeSelectUserDetails,
  makeSelectIsUserLoggedIn,
  makeSelectIsBetaUser
} from "ducks/selectors";
import SideMenu from "./SideMenu";
import Header from "./Header";
import Footer from "./Footer";
import get from "lodash/get";
import { Box } from "ui-components";
import { isWebview } from "utils/localStorage";
import { PHONE_ONLY_CHANNEL } from "utils/constants";
import { getChannelFromUrl } from "utils/helpers";
import { supportedFeatureFlags } from "utils/featureFlags";

const layoutHOC = WrappedComponent => props => {
  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [stickyFooterHeight, setStickyFooterHight] = useState(0);
  const data = get(props, "appSettings.layout.header.profile.data");
  const channel = getChannelFromUrl();
  const phoneOnlyWebview = channel === PHONE_ONLY_CHANNEL && isWebview();
  const showHeader =
    get(props, "appSettings.layout.header.enable", true) && !phoneOnlyWebview;
  const showFooter =
    get(props, "appSettings.layout.footer.enable") && !isWebview();
  let baseStyles = { mx: "auto" };
  let layoutStyles = {
    ...baseStyles,
    maxWidth: 700,
    bg: "transparent",
    p: 0,
    paddingTop: showHeader ? 100 : 20,
    paddingBottom: 100
  };

  // Hiding the top banner for esim transfer use case
  const isEsimTransferChannel =
    channel?.toLowerCase() === "esim_transfer" ||
    (channel?.toLowerCase() === "api" &&
      window.location.pathname.includes("/validate_sim_transfer"));

  const defaultButtons = [
    {
      label: "manage_order",
      url: "/manage/order?reset=true",
      variant: "primary",
      icon: "ViewList"
    }
  ];
  const buttons = get(
    props,
    "appSettings.layout.header.buttons",
    defaultButtons
  );

  useEffect(() => {
    stickyFooterHeight !== window.stickyFooterHeight &&
      setStickyFooterHight(window.stickyFooterHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.stickyFooterHeight]);

  return (
    <>
      {showHeader && (
        <SideMenu
          {...props}
          open={sideMenuOpen}
          setOpen={setSideMenuOpen}
          buttons={buttons}
        />
      )}
      <div
        id="page-wrap"
        style={{ marginBottom: stickyFooterHeight, overflowX: "auto" }}
      >
        {showHeader && !isEsimTransferChannel && (
          <Header
            {...props}
            open={sideMenuOpen}
            setOpen={setSideMenuOpen}
            buttons={buttons}
            data={data}
          />
        )}
        <Box as="main" sx={layoutStyles}>
          <WrappedComponent {...props} />
        </Box>
        {showFooter && <Footer {...props} />}
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  appSettings: makeSelectAppSettings(),
  locale: makeSelectLocale(),
  themeColors: makeSelectThemeColors(),
  userDetails: makeSelectUserDetails(),
  isLoggedIn: makeSelectIsUserLoggedIn(),
  isBetaUser: makeSelectIsBetaUser(),
  featureFlags: supportedFeatureFlags
});

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default component => compose(withConnect, memo)(layoutHOC(component));
