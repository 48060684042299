import { createSelector } from "reselect";
import get from "lodash/get";

import { isPortingUserSelector } from "../authInfo";
import { userLineNumberSelector } from "../userKYCDetails";
import { contractDetailsSelector } from "./contractDetails";

const connectionDetailsDomain = state =>
  get(state, "form.ConnectionDetailsForm.values", {});

// ***************************************************************
// ************** ConnectionDetailsForm selectors ******************
// ***************************************************************

export const connectionDetailsSelector = createSelector(
  connectionDetailsDomain,
  connectionDetails => connectionDetails
);
export const simTypeSelector = createSelector(
  connectionDetailsDomain,
  contractDetailsSelector,
  (connectionDetails, controctDetails) => {
    return (
      get(connectionDetails, "sim_type") || get(controctDetails, "sim_type")
    );
  }
);
export const contractTypeSelector = createSelector(
  connectionDetailsDomain,
  contractDetailsSelector,
  (connectionDetails, contractDetails) => {
    return (
      get(connectionDetails, "number_type") ||
      get(contractDetails, "number_type")
    );
  }
);

export const connectionDetailsNetworkTypeSelector = createSelector(
  connectionDetailsDomain,
  contractDetailsSelector,
  (connectionDetails, contractDetails) => {
    return (
      get(connectionDetails, "network_type") ||
      get(contractDetails, "network_type")
    );
  }
);

export const isSwitchActiveLineSelector = createSelector(
  connectionDetailsDomain,
  ({ switch_active_line }) => switch_active_line === "true"
);

export const hasActiveLineSelector = createSelector(
  userLineNumberSelector,
  lineNumbers => lineNumbers?.length > 0
);

export const isPortingUserWithPortingNumberSelector = createSelector(
  isPortingUserSelector,
  isSwitchActiveLineSelector,
  hasActiveLineSelector,
  (isPortingUser, isSwitchActiveLine, hasActiveLine) => {
    return isPortingUser && isSwitchActiveLine && hasActiveLine;
  }
);

export const accountPortInDetailsSelector = createSelector(
  connectionDetailsDomain,
  isPortingUserWithPortingNumberSelector,
  (
    { port_number, mnp_reservation_number, mnp_expiry_date },
    isPortingUserWithPortingNumber
  ) => ({
    switchActiveLine: isPortingUserWithPortingNumber,
    portInNumber: get(port_number, "value"),
    mnpReservationNumber: mnp_reservation_number,
    mnpExpiryDate: get(mnp_expiry_date, "value")
  })
);

// ***************************************************************
// ************** ConnectionDetailsForm makeSelect *****************
// ***************************************************************

export const makeSelectConnectionDetailsForm = () => connectionDetailsSelector;
export const makeSelectSimType = () => simTypeSelector;
export const makeSelectContractType = () => contractTypeSelector;
export const makeSelectNetworkType = () => connectionDetailsNetworkTypeSelector;
export const makeSelectIsPortingUserWithPortingNumber = () =>
  isPortingUserWithPortingNumberSelector;
