import React from "react";
import { Box, Flex } from "ui-components";
import TextField from "components/FormFields/TextField";
import DropdownField from "components/FormFields/DropdownField";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

const DeliveryAddressForm = ({
  fields = {},
  fieldPrefix = "delivery",
  stateOptions,
  disable,
  cityOptions,
  districtOptions,
  villageOptions,
  onZipCodeChange,
  onCityChange,
  onStateChange,
  onDistrictChange,
  onVillageChange,
  postalCodeValidation
}) => {
  const { state, city, district, hse_blk_tower, village, zip_code } = fields;
  const postalCodeField = `${fieldPrefix}_${zip_code.field}`;
  const inlineValidation = get(postalCodeValidation, [postalCodeField], {});

  return (
    <>
      <Flex flexWrap="wrap" mx={-2}>
        {get(zip_code, "enable") && (
          <Box width={[1, 1 / 5, 1 / 5]} px={2}>
            <TextField
              onChange={onZipCodeChange}
              inlineValidation={inlineValidation}
              disabled={disable}
              {...zip_code}
              field={`${fieldPrefix}_${zip_code.field}`}
            />
          </Box>
        )}
        {get(hse_blk_tower, "enable") && (
          <Box width={[1, 4 / 5, 4 / 5]} px={2}>
            <TextField
              {...hse_blk_tower}
              disabled={disable}
              field={`${fieldPrefix}_${hse_blk_tower.field}`}
            />
          </Box>
        )}
      </Flex>
      <Flex flexWrap="wrap" mx={-2}>
        {get(state, "enable") && (
          <Box width={[1, 1 / 2, 1 / 2]} px={2}>
            <DropdownField
              onChange={onStateChange}
              options={stateOptions}
              disabled={isEmpty(stateOptions) || disable}
              {...state}
              field={`${fieldPrefix}_${state.field}`}
            />
          </Box>
        )}

        {get(city, "enable") && (
          <Box width={[1, 1 / 2, 1 / 2]} px={2}>
            <DropdownField
              onChange={onCityChange}
              options={cityOptions}
              disabled={isEmpty(cityOptions) || disable}
              {...city}
              field={`${fieldPrefix}_${city.field}`}
            />
          </Box>
        )}

        {get(district, "enable") && (
          <Box width={[1, 1 / 2, 1 / 2]} px={2}>
            <DropdownField
              onChange={onDistrictChange}
              options={districtOptions}
              disabled={isEmpty(districtOptions) || disable}
              {...district}
              field={`${fieldPrefix}_${district.field}`}
            />
          </Box>
        )}

        {get(village, "enable") && (
          <Box width={[1, 1 / 2, 1 / 2]} px={2}>
            <DropdownField
              onChange={onVillageChange}
              options={villageOptions}
              disabled={isEmpty(villageOptions) || disable}
              {...village}
              field={`${fieldPrefix}_${village.field}`}
            />
          </Box>
        )}
      </Flex>
    </>
  );
};

export default DeliveryAddressForm;
