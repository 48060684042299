import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";
import OTPModal from "components/OTPModal";
import { getDeviceId } from "utils/localStorage";
import { UserService } from "ducks/actions";
import { buttonThemes } from "ui-components/theme";
import { isMobile, OTP_ERROR_MAP, urlParam } from "utils/helpers";
import {
  makeSelectAuthId,
  makeSelectRestrictedRoutes,
  makeSelectThemeColors,
  makeSelectIsAuthenticatedBetaUser,
  makeSelectIsEnableRestrictionRoute
} from "ducks/selectors";

import { AUTH_MODES, OTP_SECURE_ROUTE_EMAIL } from "utils/constants";

const useOTPSecureRoute = () => {
  const dispatch = useDispatch();
  const restrictedRoutes = useSelector(makeSelectRestrictedRoutes());
  const isAuthenticatedBetaUser = useSelector(
    makeSelectIsAuthenticatedBetaUser()
  );
  const auth_id = useSelector(makeSelectAuthId());
  const themeColors = useSelector(makeSelectThemeColors());
  const buttonTheme = get(themeColors, "button", buttonThemes.povodarkgray);
  const [emailOTPErrorMessage, setEmailOTPErrorMessage] = useState(null);
  const email = OTP_SECURE_ROUTE_EMAIL;
  const haIsBetaUserUrlParams = urlParam("isBetaUser");
  const enableRestrictionRoute = useSelector(
    makeSelectIsEnableRestrictionRoute()
  );

  const isRestrictedRoute =
    restrictedRoutes?.length > 0 &&
    restrictedRoutes.includes(window.location.pathname);

  useEffect(() => {
    if (
      enableRestrictionRoute &&
      isRestrictedRoute &&
      haIsBetaUserUrlParams &&
      !isAuthenticatedBetaUser
    ) {
      callActionAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restrictedRoutes]);

  const requestOTPForEmail = email => {
    const additionalHeaders = {};
    const bodyParams = {
      email,
      auth_mode: AUTH_MODES.ENHANCED_EMAIL_OTP
    };
    dispatch(UserService.V4.userLoginOTP(additionalHeaders, bodyParams));
  };

  const callActionAPI = () => {
    const deviceId = getDeviceId();
    const headers = {};
    const bodyParams = {
      email,
      device_id: deviceId
    };

    const onSuccess = (_, errors) => {
      if (!errors) {
        requestOTPForEmail(email);
      }
    };
    dispatch(UserService.V4.userLogin(headers, bodyParams, onSuccess));
  };

  const validateEmail = (_, { current_otp }) => {
    const bodyParams = {
      device: {
        app_type: "ecosystem",
        device_id: getDeviceId(),
        device_type: isMobile ? "Mobile" : "web"
      },
      email_auth: {
        otp_code: current_otp,
        auth_id: auth_id
      }
    };
    const onSuccess = (authResponse, errors) => {
      if (errors) {
        const error_locale =
          OTP_ERROR_MAP[authResponse.code] || OTP_ERROR_MAP.generic;
        setEmailOTPErrorMessage(error_locale);
      }
    };
    dispatch(UserService.V4.betaUserAuthenticate({}, bodyParams, onSuccess));
  };

  const modalDetails = {
    modalSubtitle: "verify_account_by_otp_instructions",
    headerValues: { email },
    timerKey: "email_otp",
    onSubmit: validateEmail,
    errorMessage: emailOTPErrorMessage
  };

  const renderOTPModal = (data = modalDetails) => {
    //callActionAPI();
    const {
      modalSubtitle,
      headerValues,
      onSubmit,
      timerKey,
      errorMessage
    } = data;

    return (
      <OTPModal
        modal_title="title_verify_code"
        modal_subtitle={modalSubtitle}
        showModal={true}
        showCloseButton={false}
        closeModal={() => {}}
        buttonTheme={buttonTheme}
        hideOtpResend={true}
        enableBackButton={false}
        styleCloseBtn={{ color: "#000000" }}
        info_message={"otp_help_faq"}
        headerStyles={{
          alignItems: "center",
          textAlign: "center",
          padding: "0.5rem",
          width: "100%",
          marginBottom: "2rem",
          overflowWrap: "anywhere"
        }}
        headerValues={headerValues}
        otpBoxes={{
          size: 6,
          onSubmit: onSubmit,
          buttonColor: buttonTheme,
          instruction: "otp_code",
          label: "continue",
          timerKey: timerKey,
          buttonWidth: ["100%", "60%"],
          timer: {
            label: "expiring_in",
            durationInSeconds: 120,
            enableResendLinkInSeconds: 60,
            resendLinkCallback: null
          },
          errorMessage: errorMessage
        }}
        resendOTPLink={null}
      />
    );
  };

  return {
    showOTPModal: renderOTPModal,
    OTPVerificationRequired:
      isRestrictedRoute && haIsBetaUserUrlParams && !isAuthenticatedBetaUser
  };
};

export default useOTPSecureRoute;
