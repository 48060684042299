import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import SmartRender from "containers/SmartRender";
import { } from "ducks/actions";
import { makeSelectIsSimActivated, makeSelectSimType, makeSelectGA4PlanDetails } from "ducks/selectors";
import { pathWithChannel } from "utils/helpers";
import { paths } from "utils/constants";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";

const SimActivationStatusPolling = Component =>
  function Comp(props) {
    const activationSuccess = useSelector(makeSelectIsSimActivated());
    const GA4EventPlanDetails = useSelector(makeSelectGA4PlanDetails());
    const simType = useSelector(makeSelectSimType());


    useEffect(() => {
      if (window.location.pathname === pathWithChannel(paths.SIM_ACTIVATION_PENDING, "manage")) {
        GA4EventsService.publish(
          personalDetailsSubmitEventData(
            GA4EVENTS.SIM_ACTIVATION,
            GA4EventPlanDetails
          )
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
      <Component
        {...props}
        simType={simType}
        simActivated={activationSuccess}
      />
    );
  };

export default SimActivationStatusPolling(SmartRender);

const personalDetailsSubmitEventData = (event, details = {}) => {
  const {
    customer_id,
    plan_name,
    plan_type,
    sim_type,
    order_ref,
    journey_type,
    number_type
  } = details;

  return {
    event,
    ecommerce: {
      customer_id,
      order_ref,
      plan_name,
      journey_type,
      plan_type,
      sim_type,
      number_type
    }
  };
};