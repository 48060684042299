import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import FormattedMessage from "components/FormattedMessage";
import { Box, Text } from "ui-components";
import styles from "./styles";
import isEmpty from "lodash/isEmpty";
import random from "lodash/random";
import get from "lodash/get";
import { getNumberType } from "utils/helpers";
import { COLORS, iconThemes } from "ui-components/theme";

const NewNumberDisplay = ({
  title,
  formattedNumber,
  price,
  formattedPrice,
  specialMessages,
  theme,
  themeColors,
  sku = ""
}) => {
  const showPrice = price > 0;
  const message = title || `your_${getNumberType({ sku })}`;

  const tertiaryColor = get(themeColors, "tertiary", COLORS.blue[4]);
  const themeColor = theme ? iconThemes[theme] || theme : tertiaryColor;
  const [specialMessage, setSpecialMessage] = useState("");
  useEffect(() => {
    !isEmpty(specialMessages) &&
      setSpecialMessage(
        get(specialMessages, random(specialMessages.length - 1))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formattedNumber]);

  return (
    <Box sx={styles.wrapper}>
      <Text sx={styles.title}>
        <FormattedMessage html id={message} />
      </Text>

      <Text
        sx={styles.number}
        mb={showPrice ? 4 : 6}
        data-testid="selected-new-number"
      >
        {formattedNumber}
      </Text>
      {showPrice && <Text sx={styles.price}>{formattedPrice}</Text>}

      {specialMessage && (
        <Box
          sx={{
            ...styles.specialMessage,
            borderColor: themeColor,
            boxShadow: `4px 4px 0px 0px ${themeColor}`
          }}
        >
          <FormattedMessage html id={specialMessage} />
        </Box>
      )}
    </Box>
  );
};

NewNumberDisplay.defaultProps = {
  price: 0,
  formattedPrice: ""
};

NewNumberDisplay.propTypes = {
  formattedNumber: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  formattedPrice: PropTypes.string.isRequired
};

export default NewNumberDisplay;
