import { createSelector } from "reselect";
import get from "lodash/get";

const suspensionSuspensionDomain = state =>
  get(state.api, "submitSuspension.data", {});

// ***************************************************************
// **************** submitSuspension selectors **********************
// ***************************************************************

export const submitSuspensionSelector = createSelector(
  suspensionSuspensionDomain,
  suspensionSuspensionDetail => {
    return suspensionSuspensionDetail;
  }
);

// ***************************************************************
// *************** submitSuspension makeSelect **********************
// ***************************************************************

export const makeSelectSubmitSuspensionDetails = () => submitSuspensionSelector;
