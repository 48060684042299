import React from "react";
import get from "lodash/get";
import DropdownField from "components/FormFields/DropdownField";
import { constructOnChangeHandler } from "utils/helpers";

const TERMINATION_DATE = ({ orderDetails, data, ...props }) => {
  const terminationDates = get(orderDetails, "terminationDates", []);
  return (
    <DropdownField
      options={terminationDates}
      {...props}
      {...data}
      onChange={constructOnChangeHandler(props)}
    />
  );
};

export default TERMINATION_DATE;
