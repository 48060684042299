import { createSelector } from "reselect";
import get from "lodash/get";
import find from "lodash/find";
import split from "lodash/split";
import replace from "lodash/replace";
import pickBy from "lodash/pickBy";
import isEmpty from "lodash/isEmpty";
import trim from "lodash/trim";
import trimStart from "lodash/trimStart";
import moment from "moment";
import { orderRefSelector, portNumberSelector } from "../order";
import { liquidOcrDataSelector } from "../ekyc";
import { isSimReplacementKYCSelector } from "../guestOrder";
import {
  accountPortInDetailsSelector,
  connectionDetailsSelector
} from "../forms/connectionDetails";
import {
  genericSelector,
  numberFormatSelector,
  yearOptionsSelector
} from "../appSettings";
import { dateOptionsSelector, monthOptionsSelector } from "../locale";
import { selectedDeliveryMethodSelector } from "./deliveryInfo";
import {
  addressParams,
  deliverySlotParams,
  formatDisplayNumber,
  compactJoin,
  getZipcodePrefixAndSuffix
} from "utils/helpers";
import {
  userKYCDetailsDomain,
  userDOBFromKYCDetailsSelector
} from "../userKYCDetails";
import { DOB_FORMAT, JP_DOB_FORMAT, KYC_DOB_FORMAT } from "utils/constants";
import { getOneStopPortInParams } from "utils/localStorage";
import {
  billingAddressSelector,
  kanjiName,
  katakanaName,
  userDOB
} from "../authInfo";
import { getFormSyncErrors } from "redux-form";

const userDetailsConfirmationDomain = state =>
  get(state, "form.UserDetailsConfirmationForm.values", {});

const mnpProviderNameDomain = state =>
  get(
    state,
    "form.UserDetailsConfirmationForm.values.select_mnp_providers",
    ""
  );

const isConsentReviewedDomain = state =>
  get(state, "api.isConsentReviewed.data", {});

const isModalViewDomain = state => get(state, "api.isModalView.data", {});

const planChangeVerifyKycDomain = state =>
  get(state, "api.PlanChangeVerifyKyc.data", {});

const userLostServiceDataDomain = state =>
  get(state, "api.userLostServiceData.data", {});

const parentalConsentErrorDomain = state =>
  get(state, "api.parentalConsentOtp.data.errorMessage", "");

// ***************************************************************
// ************** PersonalDetailsForm selectors ******************
// ***************************************************************

export const userDetailsConfirmationSelector = createSelector(
  userDetailsConfirmationDomain,
  userDetailsConfirmation => userDetailsConfirmation
);

export const userDetailsConfirmationFormErrorsSelector = createSelector(
  getFormSyncErrors("UserDetailsConfirmationForm"),
  userDetailsConfirmation => userDetailsConfirmation
);

export const mnpProvdierSelectedNameSelector = createSelector(
  mnpProviderNameDomain,
  mnpProviderSelected => mnpProviderSelected
);

export const onestopMnoProvidersSelector = createSelector(
  genericSelector,
  generic => get(generic, "mnp_providers_options", [])
);

export const onestopMvnoProvidersSelector = createSelector(
  genericSelector,
  generic => get(generic, "mvno_providers_options", [])
);

const isConsentReviewedSelector = createSelector(
  isConsentReviewedDomain,
  isConsentReviewed => get(isConsentReviewed, "isConsentReviewed", false)
);

export const isModalViewSelector = createSelector(
  isModalViewDomain,
  isModalView => get(isModalView, "isModalView", false)
);

const userConfirmLostServiceSelector = createSelector(
  userLostServiceDataDomain,
  userLostServiceData =>
    get(userLostServiceData, "userConfirmLostService", false)
);

const onFailureRedirectUrlSelector = createSelector(
  userLostServiceDataDomain,
  userLostServiceData => get(userLostServiceData, "onFailureRedirectUrl")
);

const hasSingleAuLineSelector = createSelector(
  userLostServiceDataDomain,
  userLostServiceData => get(userLostServiceData, "au_line_count", 0) === 1
);

export const modalTypeSelector = createSelector(
  isModalViewDomain,
  isModalView => get(isModalView, "type", "")
);

export const userInVerifyKycStateSelector = createSelector(
  planChangeVerifyKycDomain,
  data => get(data, "inPlanChangeVerifyKycState", false)
);

export const userDetailsPayloadSelector = createSelector(
  userDetailsConfirmationSelector,
  orderRefSelector,
  accountPortInDetailsSelector,
  (userDetailsConfirmation, orderRef, accountPortInDetails) => {
    const {
      first_name,
      last_name,
      dob,
      country_code,
      contact_number: phone_number,
      sex,
      nationality,
      billing_zip_code,
      billing_state,
      billing_city,
      billing_hse_blk_tower,
      billing_street_name,
      port_number,
      mnp_reservation_number,
      contractor_is_user,
      other_user_dob,
      have_mnp_reservation
    } = userDetailsConfirmation;
    const dobMoment = dob ? moment(dob, "DD-MM-YYYY") : undefined;
    const birthday = dobMoment ? dobMoment.format(KYC_DOB_FORMAT) : undefined;
    const kycParams = {
      first_name: trim(first_name),
      last_name: trim(last_name),
      birthday,
      phone_number,
      nationality,
      sex,
      zip_code: replace(billing_zip_code, "-", ""),
      address2: billing_hse_blk_tower,
      address1: billing_street_name,
      city: billing_city,
      prefecture: billing_state,
      order_id: orderRef
    };
    let orderParams = {
      order_ref: orderRef,
      user_detail: {
        first_name: trim(first_name),
        last_name: trim(last_name),
        dob,
        country_code,
        phone_number
      },
      parental_consent: {
        contractor_is_user,
        other_user_dob: contractor_is_user === "false" ? other_user_dob : ""
      },
      deliver_detail: addressParams(userDetailsConfirmation, "delivery"),
      ...deliverySlotParams(userDetailsConfirmation),
      billing_detail: addressParams(userDetailsConfirmation, "billing"),
      ...(port_number && { selected_number: port_number })
    };
    const isOneStop = have_mnp_reservation !== "true";

    // get porting details from the porting account or normal porting details
    const {
      portInNumber,
      mnpReservationNumber,
      switchActiveLine
    } = accountPortInDetails;
    // creating one stop portin payload
    const onestopPayload = isOneStop
      ? (() => {
        const storedOnestopData = getOneStopPortInParams() || {};
        return {
          onestop: storedOnestopData.onestop,
          mno: storedOnestopData.mno,
          valid: storedOnestopData.valid
        };
      })()
      : {};

    if (switchActiveLine) {
      orderParams = {
        ...orderParams,
        selected_number: portInNumber,
        portin_detail: {
          ...onestopPayload,
          port_number: portInNumber,
          mnp_reservation_number: mnpReservationNumber,
          switch_active_line: switchActiveLine
        }
      };
    } else {
      orderParams = {
        ...orderParams,
        portin_detail: {
          ...onestopPayload,
          port_number,
          mnp_reservation_number,
          switch_active_line: switchActiveLine
        }
      };
    }

    return { kycParams, orderParams };
  }
);

export const userDetailsKycPayloadSelector = createSelector(
  userDetailsPayloadSelector,
  userDetailsPayload => get(userDetailsPayload, "kycParams", {})
);

export const userDOBSelector = format =>
  createSelector(
    userDetailsConfirmationSelector,
    userDOBFromKYCDetailsSelector,
    (userDetails, dobFromKYCDetails) => {
      // giving precedence to BOD from KYC details since the DOB in userDetailsConfirmation form is read only
      // IF at all DOB in userDetailsConfirmation form is editable precedence should be switched
      let dob = dobFromKYCDetails || get(userDetails, "dob");
      // dob value should be present to avoid considering today as dob
      if (!dob) return dob;

      if (moment(dob).isValid()) {
        // Checking the validity for general dob format
        dob = moment(dob).format(format);
      } else if (moment(dob, DOB_FORMAT).isValid()) {
        // Checking the validity for DOB field dob format
        dob = moment(dob, DOB_FORMAT).format(format);
      } else if (moment(dob, KYC_DOB_FORMAT).isValid()) {
        // Checking the validity for KYC localized dob format
        dob = moment(dob, KYC_DOB_FORMAT).format(format);
      } else if (moment(dob, JP_DOB_FORMAT).isValid()) {
        // Checking the validity for JP localized dob format
        dob = moment(dob, JP_DOB_FORMAT).format(format);
      }
      return dob;
    }
  );

export const userDetailsOrderUpdatePayloadSelector = createSelector(
  userDetailsPayloadSelector,
  userDetailsPayload => get(userDetailsPayload, "orderParams", {})
);

export const connectionDetailsFormPortInNumberSelector = createSelector(
  connectionDetailsSelector,
  numberFormatSelector,
  (connectionDetails, numberFormat) => {
    const portNumber = get(connectionDetails, "port_number.value");
    return portNumber
      ? formatDisplayNumber(portNumber, numberFormat)
      : portNumber;
  }
);

export const parentalConsentPayloadSelector = createSelector(
  userDetailsConfirmationSelector,
  userDetailsConfirmation => {
    const {
      parent_email: email,
      parent_phone_number: phone_number,
      contractor_is_user,
      parent_signature: signature,
      other_user_dob,
      opt_out_reason
    } = userDetailsConfirmation;
    // filtering is enabled by default for minors
    const enable_filtering = true;

    return {
      contractor_is_user,
      other_user_dob: contractor_is_user === "false" ? other_user_dob : "",
      phone_number,
      email,
      signature,
      enable_filtering,
      opt_out_reason
    };
  }
);

const portInUpdatePayloadSelector = createSelector(
  userDetailsConfirmationSelector,
  orderRefSelector,
  (userDetailsConfirmation, orderRef) => {
    const {
      delivery_zip_code,
      delivery_state,
      delivery_city,
      delivery_hse_blk_tower,
      delivery_street_name,
      port_number,
      mnp_reservation_number,
      mnp_expiry_date
    } = userDetailsConfirmation;

    return {
      order_ref: orderRef,
      deliver_detail: {
        zip_code: delivery_zip_code,
        address_line_2: delivery_hse_blk_tower,
        address_line_1: delivery_street_name,
        city: delivery_city,
        prefecture: delivery_state
      },
      ...deliverySlotParams(userDetailsConfirmation),
      portin_detail: {
        port_number,
        mnp_reservation_number,
        mnp_expiry_date: get(mnp_expiry_date, "value")
      }
    };
  }
);

// ***************************************************************
// ************** PersonalDetailsForm makeSelect *****************
// ***************************************************************

export const makeSelectUserDetailsConfirmation = () =>
  userDetailsConfirmationSelector;

export const makeSelectUserDetailsConfirmationFormErrors = () =>
  userDetailsConfirmationFormErrorsSelector;

export const makeSelectMnpProviderName = () => mnpProvdierSelectedNameSelector;
export const makeSelectOnestopMnoProviders = () => onestopMnoProvidersSelector;
export const makeSelectOnestopMvnoProviders = () =>
  onestopMvnoProvidersSelector;

export const makeSelectUserDetailsInitialValues = () =>
  createSelector(
    liquidOcrDataSelector,
    yearOptionsSelector,
    monthOptionsSelector,
    dateOptionsSelector,
    selectedDeliveryMethodSelector,
    genericSelector,
    userDetailsConfirmationSelector,
    userKYCDetailsDomain,
    portNumberSelector,
    kanjiName,
    katakanaName,
    userDOB,
    billingAddressSelector,
    isSimReplacementKYCSelector,
    userInVerifyKycStateSelector,
    (
      liquidOcrData,
      yearOptions,
      monthOptions,
      dateOptions,
      delivery_method,
      generic,
      userDetailsConfirmationForm,
      portingUserKYCDetails,
      portNumber,
      telcoFirstName,
      telcoLastName,
      telcoUserDOB,
      telcoBillingAddress,
      isSimReplacementKYC,
      userInVerifyKycState
    ) => {
      const year = telcoUserDOB?.year;
      const month = telcoUserDOB?.month.toString().padStart(2, "0");
      const day = telcoUserDOB?.day.toString().padStart(2, "0");
      const telcoDOB = telcoUserDOB ? `${year}${month}${day}` : undefined;
      const data = {};
      const updatedFormData = pickBy(
        userDetailsConfirmationForm,
        value => !isEmpty(value)
      );
      const port_number = get(updatedFormData, "port_number") || portNumber;
      const name = get(liquidOcrData, "name") || get(data, "name");
      const [last_name_kanji, first_name_kanji] = split(trim(name), " ");
      const dobDate =
        isSimReplacementKYC || userInVerifyKycState
          ? telcoDOB
          : get(liquidOcrData, "birthday") ||
          get(portingUserKYCDetails, "birthday") ||
          get(data, "birthday");

      const dobMoment = dobDate ? moment(dobDate, KYC_DOB_FORMAT) : undefined;
      const age =
        userDetailsConfirmationForm?.age ||
        (dobMoment ? moment().diff(dobMoment, "years") : undefined);
      const dob = dobMoment ? dobMoment.format("DD-MM-YYYY") : undefined;
      const dobYear = dob
        ? find(yearOptions, { value: dobMoment.year() })
        : undefined;
      const dobMonth = dob
        ? find(monthOptions, { value: dobMoment.format("MM") })
        : undefined;
      const dobDay = dob
        ? find(dateOptions, { value: dobMoment.format("DD") })
        : undefined;

      // kyc details that we get for a porting user. This details comes through the fetch_mno_kyc api call
      const kycDataLoaded = !isEmpty(portingUserKYCDetails);
      let portingUserDetails = {};
      if (kycDataLoaded) {
        const {
          first_name,
          last_name,
          zip_code,
          address_1: state,
          address_2: city,
          address_3: street_name,
          address_4: hse_blk_tower
        } = portingUserKYCDetails;

        portingUserDetails = {
          first_name: trimStart(first_name),
          last_name: trimStart(last_name),
          delivery_zip_code: zip_code,
          delivery_state: state,
          delivery_city: city,
          delivery_street_name: street_name,
          delivery_hse_blk_tower: hse_blk_tower,
          billing_zip_code: zip_code,
          billing_state: state,
          billing_city: city,
          billing_street_name: street_name,
          billing_hse_blk_tower: hse_blk_tower
        };
      }
      const finalFormData = {
        ...portingUserDetails,
        ...updatedFormData
      };
      const ocr_kanji_name = compactJoin(
        [last_name_kanji, first_name_kanji],
        " "
      );
      const kanji_names = [
        finalFormData?.last_name_kanji,
        finalFormData?.first_name_kanji
      ];
      const kana_names = [
        finalFormData?.last_name_katakana,
        finalFormData?.first_name_katakana
      ];
      const first_name =
        finalFormData?.first_name ||
        compactJoin(kanji_names, " ") ||
        ocr_kanji_name;
      const last_name =
        finalFormData?.last_name || compactJoin(kana_names, " ");

      const userDetailsInitialValues = {
        last_name_kanji,
        first_name_kanji,
        age,
        dob,
        dobYear,
        dobMonth,
        dobDay,
        country_code: get(generic, "contact_number.prefix"),
        ...finalFormData,
        // sending empty as garbage value when sex is not available from Liquid response
        sex:
          finalFormData?.sex ||
          get(liquidOcrData, "sex") ||
          get(data, "sex") ||
          "",
        nationality:
          finalFormData?.nationality ||
          get(liquidOcrData, "nationality") ||
          get(data, "nationality"),
        billing_zip_code:
          finalFormData?.billing_zip_code ||
          get(liquidOcrData, "zip_code") ||
          get(data, "zip_code") ||
          get(telcoBillingAddress, "post_code"),
        billing_state:
          finalFormData?.billing_state ||
          get(liquidOcrData, "address_pref") ||
          get(data, "address_pref") ||
          get(telcoBillingAddress, "state"),
        billing_city:
          finalFormData?.billing_city ||
          get(liquidOcrData, "address_city") ||
          get(data, "address_city") ||
          get(telcoBillingAddress, "city"),
        billing_street_name:
          finalFormData?.billing_street_name ||
          get(liquidOcrData, "address_other") ||
          get(data, "address") ||
          get(telcoBillingAddress, "street"),
        delivery_method: finalFormData?.delivery_method || delivery_method,
        delivery_zip_code:
          finalFormData?.delivery_zip_code ||
          get(liquidOcrData, "zip_code") ||
          get(data, "zip_code"),
        delivery_state:
          finalFormData?.delivery_state ||
          get(liquidOcrData, "address_pref") ||
          get(data, "address_pref"),
        delivery_city:
          finalFormData?.delivery_city ||
          get(liquidOcrData, "address_city") ||
          get(data, "address_city"),
        delivery_street_name:
          finalFormData?.delivery_street_name ||
          get(liquidOcrData, "address_other") ||
          get(data, "address"),
        first_name:
          isSimReplacementKYC || userInVerifyKycState
            ? telcoFirstName
            : first_name,
        last_name:
          isSimReplacementKYC || userInVerifyKycState
            ? telcoLastName
            : last_name,
        ocrDataLoaded: !isEmpty(liquidOcrData),
        kycDataLoaded,
        port_number
      };

      if (isSimReplacementKYC || userInVerifyKycState) {
        userDetailsInitialValues.dob = dob;
        userDetailsInitialValues.dobYear = dobYear;
        userDetailsInitialValues.dobMonth = dobMonth;
        userDetailsInitialValues.dobDay = dobDay;
      }

      if (userInVerifyKycState) {
        userDetailsInitialValues.billing_hse_blk_tower = get(
          telcoBillingAddress,
          "house_blk_tower"
        );
      }

      const [billingPrefix, billingSuffix] = getZipcodePrefixAndSuffix(
        userDetailsInitialValues.billing_zip_code
      );
      const [deliveryPrefix, deliverySuffix] = getZipcodePrefixAndSuffix(
        userDetailsInitialValues.delivery_zip_code
      );

      userDetailsInitialValues.billing_zip_code_part1 = billingPrefix;
      userDetailsInitialValues.billing_zip_code_part2 = billingSuffix;
      userDetailsInitialValues.delivery_zip_code_part1 = deliveryPrefix;
      userDetailsInitialValues.delivery_zip_code_part2 = deliverySuffix;

      return userDetailsInitialValues;
    }
  );

export const parentalOTPErrorMessageSelector = createSelector(
  parentalConsentErrorDomain,
  error => error
);

export const makeSelectUserDetailsPayload = () => userDetailsPayloadSelector;

export const makeSelectUserDetailsKycPayload = () =>
  userDetailsKycPayloadSelector;

export const makeSelectUserDetailsOrderUpdatePayload = () =>
  userDetailsOrderUpdatePayloadSelector;

export const makeSelectConsent = () => isConsentReviewedSelector;

export const makeSelectIsModalView = () => isModalViewSelector;

export const makeSelectModalType = () => modalTypeSelector;

export const makeSelectUserConfirmLostService = () =>
  userConfirmLostServiceSelector;

export const makeSelectOnFailureRedirectUrlSelector = () =>
  onFailureRedirectUrlSelector;

export const makeSelectHasSingleAuLineSelector = () => hasSingleAuLineSelector;

export const makeSelectParentalConsentPayloadSelector = () =>
  parentalConsentPayloadSelector;

export const makeSelectPortInUpdatePayload = () => portInUpdatePayloadSelector;

export const makeSelectParentalOTPErrorMessage = () =>
  parentalOTPErrorMessageSelector;

export const makeSelectUserDOB = format => userDOBSelector(format);

export const makeSelectPortInNumber = () =>
  connectionDetailsFormPortInNumberSelector;

export const makeSelectUserInVerifyKycState = () =>
  userInVerifyKycStateSelector;
