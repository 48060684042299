import React from "react";
import DropdownField from "components/FormFields/DropdownField";

const TELCO_LIST_DROPDOWN = ({ telcoList, onChange, ...props }) => {
  const onDropdownChange = value => {
    const change = props.change;
    const on_change = props.on_change;
    on_change &&
      // eslint-disable-next-line no-eval
      ((change, value, on_change) => eval(on_change))(change, value, on_change);
    onChange && onChange(value);
  };
  return (
    <DropdownField options={telcoList} {...props} onChange={onDropdownChange} />
  );
};

export default TELCO_LIST_DROPDOWN;
