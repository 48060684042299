import { createSelector } from "reselect";
import get from "lodash/get";
import { genericSelector } from "./appSettings";
import { simVerificationSelector } from "./forms/simVerification";
import { isIccIdValid } from "utils/helpers";
import { networkTypeSelector } from "./guestOrder";

export const validateIccIdDomain = state => get(state, "api.validateIccId", {});

export const validateIccIdSelector = createSelector(
  validateIccIdDomain,
  validateIccId => get(validateIccId, "data", {})
);

export const iccIdErrorSelector = iccIdInUrl =>
  createSelector(
    validateIccIdDomain,
    genericSelector,
    simVerificationSelector,
    networkTypeSelector,
    (validateIccId, generic, simVerificationForm, networkType) =>
      isIccIdValid(
        iccIdInUrl,
        generic,
        get(simVerificationForm, "icc_id", ""),
        networkType
      )
        ? get(validateIccId, "error", "")
        : iccIdInUrl
        ? "invalid_sim_card"
        : ""
  );

export const iccIdValidSelector = iccIdInUrl =>
  createSelector(
    genericSelector,
    simVerificationSelector,
    (generic, simVerificationForm) =>
      isIccIdValid(iccIdInUrl, generic, get(simVerificationForm, "icc_id", ""))
  );

export const validateIccIdLoadingSelector = state =>
  get(state, "api.validateIccId.loading", false);

export const makeSelectValidateIccId = () => validateIccIdSelector;

export const makeSelectIccIdError = iccIdErrorSelector;

export const makeSelectIccIdValid = iccIdValidSelector;

export const makeSelectIccIdDetails = () => validateIccIdSelector;

export const makeSelectValidateIccidStatus = () => validateIccIdLoadingSelector;
