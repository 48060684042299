import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { safeEval } from "utils/helpers";
import { ChevronRight } from "components/Icons";
import FormattedMessage from "components/FormattedMessage";
import { Flex, Box, Text } from "ui-components";
import { COLORS } from "ui-components/theme";
import CriticalInfoModal from "../CriticalInfoSummary/criticalInfoModal";
import CheckboxField from "components/FormFields/CheckboxField";

const TermsAndConditionsList = props => {
  const {
    themeColors,
    data,
    change,
    selectedColor,
    termsAndConditions,
    formValues
  } = props;

  const { supportedTermsAndConditions } = data || [];
  const [hasBeenSelected, setHasBeenSelected] = useState(false);
  const isSelected = selectedColor ? selectedColor : "#64C8A0";
  const [modalData, setModalData] = useState({ isOpen: false });
  const closeModal = () => setModalData({ isOpen: false });

  const onConfirmedTerms = () => {
    const { name } = modalData || {};
    change(name, true);
    setModalData({ isOpen: false });
  };

  const onclickMethod = (data, props) => {
    const { onClick, name } = data || {};
    if (!formValues[name]) {
      setModalData({ isOpen: true, ...data });
      safeEval(get, {}, onClick, "", props);
    }
  };

  useEffect(() => {
    if (termsAndConditions?.is_proceed_to_user_details) {
      setHasBeenSelected(true);
    }
  }, [termsAndConditions]);

  return (
    <Box>
      <Flex flexWrap="wrap" mx={-1} mb={3} flexDirection="column">
        {supportedTermsAndConditions.map((method, index) => {
          const item = {
            ...method,
            label: method.label,
            path: method.path,
            name: method.name,
            onClick: method.onClick,
            selectedColor: isSelected
          };

          return (
            <TermsItem
              key={index}
              item={item}
              hasBeenSelected={hasBeenSelected}
              onClick={onclickMethod}
              componentProps={props}
              themeColors={themeColors}
              formValues={formValues}
            />
          );
        })}
        {modalData.isOpen && (
          <CriticalInfoModal
            id={modalData.name}
            field={modalData.name}
            isOpen={modalData.isOpen}
            closeModal={closeModal}
            onConfirm={onConfirmedTerms}
            hideModal={closeModal}
            details={modalData.details}
            mandatory_read_message={true}
            themeColors={themeColors}
            iframe={true}
            pdfViewerData={modalData.pdfViewerData}
            hideCheckBox={true}
            showConfirmButton={true}
            hideDefaultCloseButton={false}
            modalStyle={{
              content: {
                top: "0.5rem",
                left: "0.25rem",
                right: "0.25rem",
                bottom: "0.5rem",
                position: "fixed",
                border: "1px solid rgb(204, 204, 204)",
                background: "#ffffff",
                overflow: "auto",
                borderRadius: "12px",
                outline: "none",
                padding: "10px",
                margin: "auto",
                inset: "0.5rem"
              }
            }}
          />
        )}
      </Flex>
    </Box>
  );
};

export const TermsItem = props => {
  const { item, themeColors, onClick, componentProps, formValues } = props;

  return (
    <Box
      variant="borderSection"
      padding={[2, 2]}
      onClick={() => {
        onClick(item, componentProps);
      }}
      sx={{
        px: 1,
        py: 1,
        borderWidth: "2px",
        cursor: "pointer",
        backgroundColor: COLORS.white,
        marginTop: "20px",
        "box-shadow": "none",
        borderColor: COLORS.povodarkgray[0],
        svg: {
          fill: COLORS.povodarkgray[4]
        }
      }}
      mb={2}
      width={[1]}
    >
      <Flex alignItems="center" p={1} pl={3}>
        <CheckboxField
          id={item.name}
          field={item.name}
          value={formValues[item.name]}
          checkBoxOnly
          disabled={!formValues[item.name]}
          boxSx={{ marginBottom: "0px !important" }}
          themeColors={themeColors}
          borderColor={COLORS.povodarkgray[0]}
        />
        <Text ml={2} sx={{ fontSize: "14px" }}>
          {" "}
          <FormattedMessage id={item.label} />
        </Text>
        <Box ml="auto">
          <ChevronRight color={get(themeColors, "button").color} />
        </Box>
      </Flex>
    </Box>
  );
};
TermsAndConditionsList.defaultProps = {
  onSelect: () => {}
};

export default TermsAndConditionsList;
