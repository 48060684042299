import React, { useEffect } from "react";
import get from "lodash/get";
import { Flex } from "ui-components";
import { iconThemes } from "ui-components/theme";
import { PlusCircleOutline } from "components/Icons";
import FormattedMessage from "components/FormattedMessage";

const HIDE_NUMBER_SELECTION = ({ change, formValues, data, themeColors }) => {
  const hideNumberSelection = get(formValues, "hideNumberSelection");
  const label = get(data, "label", "choose_another_number");
  const primaryColor = get(themeColors, "primary") || iconThemes.blue;
  useEffect(() => {
    if (hideNumberSelection === undefined) {
      change("hideNumberSelection", true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideNumberSelection]);
  return hideNumberSelection ? (
    <Flex
      data-testid="number-select-toggle"
      textAlign="center"
      alignItems="center"
      justifyContent="center"
      onClick={() => {
        change("hideNumberSelection", false);
      }}
      color={primaryColor}
      fontWeight="500"
      sx={{ cursor: "pointer", "& > svg": { margin: 0 } }}
    >
      <FormattedMessage id={label} />
      &nbsp;
      <PlusCircleOutline color={primaryColor} />
    </Flex>
  ) : null;
};

export default HIDE_NUMBER_SELECTION;
