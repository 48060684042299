import { createSelector } from "reselect";
import get from "lodash/get";
import { formatCurrency } from "utils/helpers";
import { currencyFormatSelector } from "./appSettings";

const suspensionPaymentDetailsDomain = state =>
  get(state.api, "suspensionPaymentDetails.data", {});

// ***************************************************************
// **************** suspensionPaymentDetails selectors **********************
// ***************************************************************

export const suspensionPaymentDetailsSelector = createSelector(
  suspensionPaymentDetailsDomain,
  suspensionPaymentDetails => {
    return suspensionPaymentDetails;
  }
);

// ***************************************************************
// *************** suspensionPaymentDetails makeSelect **********************
// ***************************************************************

export const makeSelectSuspensionPaymentDetails = () =>
  suspensionPaymentDetailsSelector;

export const makeSelectShowCardChargePrompt = () =>
  createSelector(
    suspensionPaymentDetailsSelector,
    suspensionPaymentDetails => true
  );

export const makeSelectSuspensionPaymentDetailFormattedAmount = () =>
  createSelector(
    suspensionPaymentDetailsSelector,
    currencyFormatSelector,
    (suspensionPaymentDetails, currencyFormat) => {
      return {
        formattedPrice: formatCurrency(
          get(suspensionPaymentDetails, "amount"),
          currencyFormat
        )
      };
    }
  );
