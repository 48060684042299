const styles = {
  wrapperStyle: {
    flexDirection: "column",
    bg: "#333333",
    mt: [0, 8],
    px: [20, 150],
    py: [40, 40]
  },
  socialIconStyles: {
    mr: 2
  },
  linkStyles: {
    color: "white",
    fontWeight: "500"
  }
};

export default styles;
