import React from "react";
import find from "lodash/find";
import StaticTooltip from "components/StaticTooltip";
import { Flex } from "ui-components";
import get from "lodash/get";
import ToggleSwitch from "components/ToggleSwitch";

const DeliveryMethodPicker = props => {
  const { value, deliveryMethods, onSelect, data, ...rest } = props;
  const selectedDeliveryMethod = find(deliveryMethods, ["value", value]);
  const tooltipMessage = get(selectedDeliveryMethod, "tooltip.message");
  const tooltipPosition = get(selectedDeliveryMethod, "tooltip.position");
  const deliveryMethodsCount = deliveryMethods.length;

  return (
    <>
      {tooltipMessage && tooltipPosition === "top" && (
        <StaticTooltip
          content={tooltipMessage}
          position={tooltipPosition}
          testid="delivery-method-picker-tooltip-top"
        />
      )}
      <Flex mx={-1} mb={3}>
        <ToggleSwitch
          {...rest}
          items={deliveryMethods}
          value={value}
          valueAttribute={"value"}
          onClick={onSelect}
          shouldHide={deliveryMethodsCount < 2}
          {...data}
        />
      </Flex>
      {tooltipMessage && tooltipPosition === "bottom" && (
        <StaticTooltip
          content={tooltipMessage}
          position={tooltipPosition}
          testid="delivery-method-picker-tooltip-bottom"
        />
      )}
    </>
  );
};

DeliveryMethodPicker.defaultProps = {
  deliveryMethods: []
};

export default DeliveryMethodPicker;
