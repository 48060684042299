import React, { useEffect } from "react";
import get from "lodash/get";
import SpecialAddOnCard from "components/SpecialAddonnSelection";
import { getURLParams } from "utils/helpers";

const SPECIAL_ADDON = ({
  data,
  changeField,
  formValues,
  isMultiSimOrder,
  themeColors
}) => {
  const { ms } = getURLParams();
  useEffect(() => {
    changeField(get(data, "type"), !!ms || isMultiSimOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMultiSimOrder]);

  return (
    <SpecialAddOnCard
      themeColors={themeColors}
      selected={get(formValues, get(data, "type"), false)}
      {...data}
      onSelect={() =>
        changeField(get(data, "type"), !get(formValues, get(data, "type")))
      }
    />
  );
};

export default SPECIAL_ADDON;
