import { createSelector } from "reselect";
import {
  selectedDeliveryMethodSelector,
  deliveryInfoSelector,
  selfActivationSelector,
  channelSelector
} from "ducks/selectors";
import { scheduleDeliveryDetailsSelector } from "./scheduleDeliveryDetails";
import { uploadIdentitySelector } from "./forms/uploadIdentity";
import { currencyFormatSelector } from "./appSettings";
import { localeSelector } from "ducks/selectors";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import map from "lodash/map";
import find from "lodash/find";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import {
  constructDeliverySlotDates,
  getDeliverySlots,
  getDeliverySlotsForDate,
  constructFreeDeliverySlots,
  constructSpecialDeliverySlots,
  constructPostOfficeDeliverySlots,
  getDeliveryDates
} from "utils/helpers";
import { SELF_ACT_DEL } from "utils/constants";
import { userDetailsConfirmationSelector } from "./forms/userDetailsConfirmation";

const deliverySlotsDomain = state => get(state, "api.deliverySlots.data", []);
export const deliverySlotsSelector = createSelector(
  deliverySlotsDomain,
  deliverySlots =>
    Array.isArray(deliverySlots) ? deliverySlots : Object.values(deliverySlots)
);

const postOfficeDeliverySlotsDomain = state =>
  get(state, "api.postOfficeDeliverySlots.data");

const fetchDeliverySlotsDetailsDomain = state => {
  return get(state, "api.deliverySlots.params", {});
};

export const postOfficeDeliverySlotsSelector = createSelector(
  postOfficeDeliverySlotsDomain,
  postOfficeDeliverySlots => postOfficeDeliverySlots
);

export const deliverySlotAPIParamsSelector = createSelector(
  fetchDeliverySlotsDetailsDomain,
  params => params
);

export const deliveryDetailsSelector = createSelector(
  selectedDeliveryMethodSelector,
  scheduleDeliveryDetailsSelector,
  localeSelector,
  currencyFormatSelector,
  (
    selectedDeliveryMethod,
    scheduleDeliveryDetails,
    locale,
    currencyFormat
  ) => ({
    selectedDeliveryMethod,
    scheduleDeliveryDetails,
    locale,
    currencyFormat
  })
);

// ***************************************************************
// ***************** delivery slots makeSelect *******************
// ***************************************************************
export const deliveryDatesSelector = createSelector(
  deliverySlotsSelector,
  deliveryDetailsSelector,
  (deliverySlots, deliveryDetails) =>
    getDeliveryDates(deliverySlots, deliveryDetails, false)
);

export const combinedDeliveryDatesSelector = createSelector(
  deliverySlotsSelector,
  deliveryDetailsSelector,
  (deliverySlots, deliveryDetails) =>
    getDeliveryDates(deliverySlots, deliveryDetails, true)
);

export const cvsDeliveryDatesSelector = createSelector(
  selectedDeliveryMethodSelector,
  deliverySlotsSelector,
  scheduleDeliveryDetailsSelector,
  localeSelector,
  currencyFormatSelector,
  (
    selectedDeliveryMethod,
    deliverySlots,
    scheduleDeliveryDetails,
    locale,
    currencyFormat
  ) => {
    let availableDeliverySlots = get(
      scheduleDeliveryDetails,
      "slots",
      deliverySlots
    );
    availableDeliverySlots = uniqBy(
      filter(availableDeliverySlots, { courier_slot_name: "CVSSTD" }),
      "date_string"
    );

    const slotConfigs = selectedDeliveryMethod.slot_configs;
    const maxFree = get(slotConfigs, "max_free", 0);
    const maxSpecial = get(slotConfigs, "max_special", 0);

    const freeDeliverySlots = getDeliverySlots(
      availableDeliverySlots,
      maxFree,
      deliverySlot => deliverySlot.cost <= 0
    );

    const specialDeliverySlots = getDeliverySlots(
      availableDeliverySlots,
      maxSpecial,
      deliverySlot => deliverySlot.cost > 0
    );

    const allDeliverySlots = freeDeliverySlots.concat(specialDeliverySlots);

    return constructDeliverySlotDates(allDeliverySlots, locale, currencyFormat);
  }
);

export const makeSelectDeliveryDates = () => deliveryDatesSelector;
export const makeSelectCombinedDeliveryDates = () =>
  combinedDeliveryDatesSelector;
export const makeSelectCvsDeliveryDates = () => cvsDeliveryDatesSelector;
export const makeSelectFetchDeliverySlotAPIParams = () =>
  deliverySlotAPIParamsSelector;
export const makeSelectDeliveryDetailsSelector = () => deliveryDetailsSelector;

export const freeDeliverySlotsSelector = createSelector(
  selectedDeliveryMethodSelector,
  deliverySlotsSelector,
  deliveryInfoSelector,
  scheduleDeliveryDetailsSelector,
  uploadIdentitySelector,
  selfActivationSelector,
  userDetailsConfirmationSelector,
  channelSelector,
  (
    selectedDeliveryMethod,
    deliverySlots,
    deliveryInfoForm,
    scheduleDeliveryDetails,
    uploadIdentity,
    selfActivationForm,
    userDetailsConfirmationForm,
    channel
  ) => {
    const deliveryInfo =
      channel === SELF_ACT_DEL ? selfActivationForm : deliveryInfoForm;
    let availableDeliverySlots = get(scheduleDeliveryDetails, "slots", []);

    if (isEmpty(availableDeliverySlots)) availableDeliverySlots = deliverySlots;

    availableDeliverySlots = uniqBy(availableDeliverySlots, "date_time");
    const date = get(
      deliveryInfo,
      "delivery_date",
      get(
        uploadIdentity,
        "delivery_date",
        get(userDetailsConfirmationForm, "delivery_date")
      )
    );
    if (!date) return [];

    const slotConfigs = selectedDeliveryMethod.slot_configs;
    const maxFree = get(slotConfigs, "max_free", 0);
    const freeDeliverySlots = getDeliverySlots(
      getDeliverySlotsForDate(availableDeliverySlots, date),
      maxFree,
      deliverySlot => deliverySlot.cost <= 0
    );

    return constructFreeDeliverySlots(freeDeliverySlots);
  }
);

export const makeSelectFreeDeliverySlots = () => freeDeliverySlotsSelector;
export const makeSelectDeliverySlots = () => deliverySlotsSelector;

export const specialDeliverySlotsSelector = createSelector(
  selectedDeliveryMethodSelector,
  deliverySlotsSelector,
  deliveryInfoSelector,
  scheduleDeliveryDetailsSelector,
  uploadIdentitySelector,
  currencyFormatSelector,
  selfActivationSelector,
  userDetailsConfirmationSelector,
  channelSelector,
  (
    selectedDeliveryMethod,
    deliverySlots,
    deliveryInfoForm,
    scheduleDeliveryDetails,
    uploadIdentity,
    currencyFormat,
    selfActivationForm,
    userDetailsConfirmationForm,
    channel
  ) => {
    const deliveryInfo =
      channel === SELF_ACT_DEL ? selfActivationForm : deliveryInfoForm;
    let availableDeliverySlots = get(
      scheduleDeliveryDetails,
      "slots",
      deliverySlots
    );
    availableDeliverySlots = uniqBy(availableDeliverySlots, "date_time");
    const date = get(
      deliveryInfo,
      "delivery_date",
      get(
        uploadIdentity,
        "delivery_date",
        get(userDetailsConfirmationForm, "delivery_date")
      )
    );
    if (!date) return [];

    const slotConfigs = selectedDeliveryMethod.slot_configs;
    const maxSpecial = get(slotConfigs, "max_special", 0);
    const specialDeliverySlots = getDeliverySlots(
      getDeliverySlotsForDate(availableDeliverySlots, date),
      maxSpecial,
      deliverySlot => deliverySlot.cost > 0
    );

    return constructSpecialDeliverySlots(specialDeliverySlots, currencyFormat);
  }
);

export const makeSelectSpecialDeliverySlots = () =>
  specialDeliverySlotsSelector;

export const makeSelectPostOfficeRegions = () =>
  createSelector(postOfficeDeliverySlotsSelector, postOfficeDeliverySlots => {
    return map(get(postOfficeDeliverySlots, "regions", []), region => {
      return { label: region.name, value: region.id };
    });
  });

export const makeSelectPostOfficeDeliverySlots = () =>
  createSelector(
    postOfficeDeliverySlotsSelector,
    localeSelector,
    (postOfficeDeliverySlots, locale) => {
      const { delivery_info_working_hours } = locale;
      const slots = get(
        postOfficeDeliverySlots,
        "post_office_delivery_slots",
        []
      );

      return constructPostOfficeDeliverySlots(
        slots,
        delivery_info_working_hours
      );
    }
  );

export const makeSelectSelectedPostOffice = () =>
  createSelector(
    postOfficeDeliverySlotsDomain,
    deliveryInfoSelector,
    uploadIdentitySelector,
    (postOfficeDeliverySlots, deliveryInfo, uploadIdentity) => {
      const post_office_id = get(
        deliveryInfo,
        "post_office_id",
        get(uploadIdentity, "post_office_id")
      );
      return find(
        get(postOfficeDeliverySlots, "post_office_delivery_slots", []),
        slot => slot.id === parseInt(post_office_id)
      );
    }
  );
