import { createSelector } from "reselect";
import get from "lodash/get";
import split from "lodash/split";
import isEmpty from "lodash/isEmpty";
import {
  genericSelector,
  defaultDeliveryModeSelector,
  deliveryModesSelector
} from "../selectors";
import { DELIVERY_METHOD_TYPE } from "utils/constants";

const scheduleDeliveryDetailsDomain = state =>
  get(state, "api.scheduleDeliveryDetails.data", {});

const appSettingsDomain = state => get(state, "api.appSettings.data");

// ***************************************************************
// **************** scheduleDeliveryDetails selectors **********************
// ***************************************************************

export const scheduleDeliveryDetailsSelector = createSelector(
  scheduleDeliveryDetailsDomain,
  scheduleDeliveryDetails =>
    isEmpty(scheduleDeliveryDetails) ? null : scheduleDeliveryDetails
);

export const countryCodeSelector = createSelector(
  appSettingsDomain,
  appSettings => get(appSettings, "generic.country_code")
);

// ***************************************************************
// *************** scheduleDeliveryDetails makeSelect **********************
// ***************************************************************

export const makeSelectScheduleDeliveryDetails = () =>
  scheduleDeliveryDetailsSelector;

export const makeSelectCountryCodeSelector = () => countryCodeSelector;

export const makeSelectScheduleDeliveryMessageData = () =>
  createSelector(scheduleDeliveryDetailsSelector, scheduleDeliveryDetails => {
    const rescheduleLimit = get(
      scheduleDeliveryDetails,
      "pre_delivery_change_limit",
      0
    );
    const { name: fullName } = get(
      scheduleDeliveryDetails,
      "order_user_detail",
      {}
    );
    const messageData = { fullName, rescheduleLimit };

    return messageData;
  });

export const makeSelectScheduleDeliveryInitialValues = props =>
  createSelector(
    scheduleDeliveryDetailsSelector,
    genericSelector,
    defaultDeliveryModeSelector,
    deliveryModesSelector,
    (
      scheduleDeliveryDetails,
      generic,
      defaultDeliveryMethod,
      deliveryModes
    ) => {
      if (isEmpty(scheduleDeliveryDetails)) return {};

      const {
        orderType: order_type,
        deliveryType: delivery_type,
        token
      } = props;
      const {
        zip_code: delivery_zip_code,
        floor_no: delivery_floor_no,
        unit_no: delivery_unit_no,
        hse_blk_tower,
        street_building_name,
        delivery_slot: delivery_slot_full_data,
        state,
        prefecture,
        address_line_1,
        address_line_2,
        city: delivery_city,
        district: delivery_district
      } = get(scheduleDeliveryDetails, "order_deliver_detail", {});

      const alternate_number = get(
        scheduleDeliveryDetails,
        "order_user_detail.phone_number",
        ""
      );
      const contact_number = get(
        scheduleDeliveryDetails,
        "order_user_detail.phone_number",
        ""
      );
      const generic_country_code = get(generic, "contact_number.prefix", "");
      const country_code =
        get(scheduleDeliveryDetails, "order_user_detail.country_code") ||
        generic_country_code;

      const deliveryMethodName = get(
        scheduleDeliveryDetails,
        "last_delivery_method",
        ""
      );
      const [
        street_name,
        delivery_building_name,
        delivery_village_name
      ] = split(street_building_name, ", ");

      const delivery_state = state || prefecture || "";
      const delivery_street_name = street_name || address_line_1 || "";
      const delivery_hse_blk_tower = hse_blk_tower || address_line_2 || "";
      const location = `${delivery_hse_blk_tower} ${delivery_street_name}, ${delivery_state} ${delivery_city}`.trim();

      let initialValues = {
        same_as_delivery_address: true,
        delivery_method: get(
          deliveryModes,
          deliveryMethodName,
          defaultDeliveryMethod
        ),
        order_type,
        delivery_type,
        token,
        delivery_zip_code,
        delivery_state,
        delivery_city,
        delivery_district,
        street_building_name,
        delivery_village_name,
        delivery_street_name,
        delivery_building_name,
        delivery_hse_blk_tower,
        delivery_floor_no,
        delivery_unit_no,
        delivery_slot_full_data,
        contact_number,
        alternate_number,
        country_code,
        order_ref: get(scheduleDeliveryDetails, "order_ref", "")
      };
      initialValues = {
        ...initialValues,
        ...(deliveryMethodName === get(DELIVERY_METHOD_TYPE, "HOME", "")
          ? { location }
          : {})
      };
      return initialValues;
    }
  );

export const makeSelectScheduleDeliveryUserInitialValues = props =>
  createSelector(scheduleDeliveryDetailsSelector, scheduleDeliveryDetails => {
    const {
      name: order_user_name,
      email: order_user_email,
      nric: order_user_nric
    } = get(scheduleDeliveryDetails, "orderDetails", {});

    return {
      order_user_name,
      order_user_email,
      order_user_nric
    };
  });
