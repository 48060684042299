import { IS_PATHS_SECURED } from "utils/constants";
import { urlParam, randomNumber, minutesDifference } from "utils/helpers";
import { getIpAddress, setIpAddress } from "utils/localStorage";

const Session = {
  getIpAddress: () => {
    return getIpAddress();
  },
  getDeviceId: () => {
    const deviceIdFromUrl = urlParam("device_id");
    if (deviceIdFromUrl && IS_PATHS_SECURED) {
      localStorage.setItem("deviceId", deviceIdFromUrl);
      return deviceIdFromUrl;
    }

    let deviceId = localStorage.getItem("deviceId");
    if (deviceId) return deviceId;

    deviceId = Session.generateDeviceId();
    localStorage.setItem("deviceId", deviceId);
    return deviceId;
  },

  generateDeviceId: () => {
    const currentTime = new Date().getTime();
    const randNumber = randomNumber();
    window.setTimeout(() => {
      setIpAddress();
    }, 1000);
    return `${currentTime}R${randNumber}`;
  },

  timetampOfDeviceId: () => {
    const deviceId = localStorage.getItem("deviceId");
    return deviceId.split("R")[0];
  },

  minutes: () => {
    return minutesDifference(
      new Date().getTime(),
      Session.timetampOfDeviceId()
    );
  }
};

export default Session;
