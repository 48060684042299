import { createSelector } from "reselect";
import isEmpty from "lodash/isEmpty";

import {
  isModalViewSelector,
  modalTypeSelector,
  monthOptionsSelector,
  dateOptionsSelector,
  localeSelector,
  currencyFormatSelector
} from "ducks/selectors";
import { formatCurrency, formatInvoiceDate } from "utils/helpers";
import {
  OUTSTANDING_PAYMENT_STATUS,
  PENDING,
  SUCCESS,
  FAILURE
} from "utils/constants";

export const outstandingBillPaymentsDomain = state =>
  state?.api?.outstandingBillPayments?.data || {};

export const outstandingBillPaymentStatusDomain = state =>
  state?.api?.outstandingBillPaymentStatus?.data || {};

export const outstandingPaymentIntentDomain = state =>
  state?.api?.outstandingBillPaymentIntent?.data || {};

export const outstandingBillDetailsSelector = createSelector(
  outstandingBillPaymentsDomain,
  outstandingBillDetails => outstandingBillDetails
);

export const outstandingBillsSelector = createSelector(
  outstandingBillDetailsSelector,
  details => details?.invoices || []
);

export const outstandingBillAmountSelector = createSelector(
  outstandingBillDetailsSelector,
  ({ total }) => total?.amount ?? 0
);

export const outstandingBillAmountWithSymbolSelector = createSelector(
  outstandingBillAmountSelector,
  currencyFormatSelector,
  (amount, currencyFormat) => formatCurrency(amount, currencyFormat)
);

export const formattedOutstandingBillsListSelector = createSelector(
  outstandingBillsSelector,
  monthOptionsSelector,
  dateOptionsSelector,
  localeSelector,
  currencyFormatSelector,
  (outstandingBills, monthOptions, dateOptions, locale, currencyFormat) => {
    if (!isEmpty(outstandingBills)) {
      return outstandingBills.map(({ invoice, invoiceDate, amount }) => ({
        invoice,
        period: formatInvoiceDate({
          unix: invoiceDate,
          locale,
          monthOptions,
          dateOptions
        }),
        amount: formatCurrency(amount ?? 0, currencyFormat)
      }));
    }
  }
);

export const outstandingStatusSelector = createSelector(
  outstandingBillDetailsSelector,
  ({ outstandingStatus }) => outstandingStatus
);

export const outstandingStatusLabelSelector = createSelector(
  outstandingStatusSelector,
  status => `outstanding_bills_status_${status?.toLowerCase()}`
);

export const isOutstandingPayableSelector = createSelector(
  outstandingBillDetailsSelector,
  ({ hasOutstanding }) => hasOutstanding
);

export const isOutstandingBillPaymentConfirmationVisibleSelector = createSelector(
  isModalViewSelector,
  modalTypeSelector,
  (isModalVisible, modalType) =>
    isModalVisible && modalType === "outstandingBillPaymentConfirmation"
);

export const billPaymentStatusSelector = createSelector(
  outstandingBillPaymentStatusDomain,
  ({ status }) => {
    switch (status) {
      case OUTSTANDING_PAYMENT_STATUS.PENDING:
      case OUTSTANDING_PAYMENT_STATUS.IN_PROGRESS:
        return PENDING;
      case OUTSTANDING_PAYMENT_STATUS.SUCCESS:
        return SUCCESS;
      default:
        // we are getting various error statuses as "PF_NOT_HONORED/REJECTED/PF_OTHER/PF...."
        // and not limited to certain no. of error codes. Because of that if the status key
        // is not available (first run of the status api call) we return status as PENDING.
        // When status key gets available and status is not either PENDING or IN_PROGRESS or SUCCESS
        // We return the status FAILURE.
        return status ? FAILURE : PENDING;
    }
  }
);

export const isBillPaymentStatusPendingSelector = createSelector(
  billPaymentStatusSelector,
  status => status === PENDING
);

export const outstandingBillPaymentCorrelationIdSelector = createSelector(
  outstandingPaymentIntentDomain,
  ({ correlation_id }) => correlation_id
);

export const makeSelectOutstandingBillDetails = () =>
  outstandingBillDetailsSelector;
export const makeSelectOutstandingBills = () => outstandingBillsSelector;
export const makeSelectOutstandingBillAmount = () =>
  outstandingBillAmountSelector;
export const makeSelectOutstandingBillAmountWithSymbol = () =>
  outstandingBillAmountWithSymbolSelector;
export const makeSelectFormattedOutstandingBillsList = () =>
  formattedOutstandingBillsListSelector;
export const makeSelectOutstandingStatus = () => outstandingStatusSelector;
export const makeSelectIsOutstandingPayable = () =>
  isOutstandingPayableSelector;
export const makeSelectOutstandingStatusLabel = () =>
  outstandingStatusLabelSelector;
export const makeSelectBillPaymentStatus = () => billPaymentStatusSelector;
export const makeSelectIsBillPaymentStatusPending = () =>
  isBillPaymentStatusPendingSelector;
export const makeSelectOutstandingBillPaymentCorrelationId = () =>
  outstandingBillPaymentCorrelationIdSelector;
export const makeSelectIsOutstandingBillPaymentConfirmationVisible = () =>
  isOutstandingBillPaymentConfirmationVisibleSelector;
