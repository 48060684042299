import React from "react";
import get from "lodash/get";
import { Box, Flex } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import DropIdentityField from "components/FormFields/DropIdentityField";
import Label from "components/FormFields/Label";

const PRIMARY_ID_UPLOADER = ({
  frontImageUploading,
  frontImageUploaded,
  backImageUploading,
  backImageUploaded,
  uploadImage,
  appSettings,
  dropIdentityValidations,
  data,
  ...props
}) => {
  const config = get(appSettings, props.lookup, {});
  const { label, tooltip, required } = props;
  const type = get(data, "type") || "primary";
  const dropIdentityValidate = get(dropIdentityValidations, [type]);

  return (
    <>
      {label && (
        <Label
          label={<FormattedMessage id={label} />}
          tooltip={tooltip}
          required={required}
        />
      )}
      <Flex>
        {/* {config.front_image && ( */}
        <Box width={[1 / 2]} px={3}>
          <DropIdentityField
            label={config.front_image_label}
            type={type}
            frontOrBack="front"
            loading={frontImageUploading}
            onDrop={uploadImage}
            completed={frontImageUploaded}
            field={`${props.lookup}.front_image`}
            accept="image/*,application/pdf"
            validate={dropIdentityValidate}
          />
        </Box>
        {/* )} */}

        {/* {config.back_image && ( */}
        <Box width={[1 / 2]} px={3}>
          <DropIdentityField
            label={config.back_image_label}
            type={type}
            frontOrBack="back"
            loading={backImageUploading}
            onDrop={uploadImage}
            completed={backImageUploaded}
            field={`${props.lookup}.back_image`}
            accept="image/*,application/pdf"
            validate={dropIdentityValidate}
          />
        </Box>
        {/* )} */}
      </Flex>
    </>
  );
};

export default PRIMARY_ID_UPLOADER;
