import React from "react";
import SmartRender from "containers/SmartRender";
import { Box } from "ui-components";

const SimActivationStatusSuccess = Component =>
  function Comp(props) {
    const privateComponents = {
      ACTIVATION_SUCCESS_IMG: props => <Box {...props}>Activation Success!</Box>
    };

    return <Component {...props} privateComponents={privateComponents} />;
  };

export default SimActivationStatusSuccess(SmartRender);
