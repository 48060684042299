import { createSelector } from "reselect";
import get from "lodash/get";
import { PAYMENT_STATUSES } from "utils/constants";
import { upper } from "utils/helpers";
import { errorCodesSelector } from "./appSettings";

const paymentRequestDomain = state => get(state, "api.paymentRequest.data", {});
const paymentDetailsDomain = state => get(state, "api.paymentDetails.data", {});
const processPaymentDomain = state => get(state, "api.processPayment.data", {});
const paymentStatusDomain = state => get(state, "api.paymentStatus.data", {});

// ***************************************************************
// **************** paymentRequest selectors **********************
// ***************************************************************

export const paymentRequestSelector = createSelector(
  paymentRequestDomain,
  paymentRequest => paymentRequest
);

export const paymentDetailsSelector = createSelector(
  paymentDetailsDomain,
  paymentDetails => paymentDetails
);

export const processPaymentSelector = createSelector(
  processPaymentDomain,
  processPayment => processPayment
);

export const paymentStatusDetailsSelector = createSelector(
  paymentStatusDomain,
  statusDetails => statusDetails
);

export const paymentStatusSelector = createSelector(
  paymentStatusDomain,
  statusDetails => statusDetails?.payment_status || PAYMENT_STATUSES.PENDING
);

export const paymentStatusPendingSelector = createSelector(
  paymentStatusSelector,
  status => upper(status) === upper(PAYMENT_STATUSES.PENDING) || status === ""
);

export const paymentStatusSuccessSelector = createSelector(
  paymentStatusSelector,
  status => upper(status) === upper(PAYMENT_STATUSES.SUCCESS)
);

export const paymentStatusFailureSelector = createSelector(
  paymentStatusSelector,
  status =>
    ![
      upper(PAYMENT_STATUSES.SUCCESS),
      upper(PAYMENT_STATUSES.PENDING)
    ].includes(upper(status))
);

export const paymentErrorCodesSelector = createSelector(
  errorCodesSelector,
  errorCodes => get(errorCodes, "payment_service", {})
);

// ***************************************************************
// *************** paymentRequest makeSelect *********************
// ***************************************************************

export const makeSelectPaymentRequest = () =>
  createSelector(paymentRequestSelector, paymentRequest => paymentRequest);
export const makeSelectPaymentDetails = () => paymentDetailsSelector;
export const makeSelectProcessPayment = () => processPaymentSelector;
export const makeSelectPaymentStatusPending = () =>
  paymentStatusPendingSelector;
export const makeSelectPaymentStatusSuccess = () =>
  paymentStatusSuccessSelector;
export const makeSelectPaymentStatusFailure = () =>
  paymentStatusFailureSelector;
export const makeSelectPaymentStatus = () => paymentStatusSelector;
export const makeSelectPaymentErrorCodes = () => paymentErrorCodesSelector;
