import React from "react";
import get from "lodash/get";
import DeliveryAddressForm2 from "components/DeliveryAddress/Form2";

const DELIVERY_ADDRESS_FORM_2 = ({
  formValues,
  fieldPrefix = "delivery",
  title = "personal_details_delivery_header"
}) => {
  const selectedDeliveryMethod = get(formValues, "delivery_method", null);
  if (!selectedDeliveryMethod) return null;
  const fields = get(selectedDeliveryMethod, "fields");
  const minimize = !get(
    formValues,
    `${fieldPrefix}_show_all_address_fields`,
    false
  );

  return (
    <DeliveryAddressForm2
      title={title}
      fields={fields}
      fieldPrefix={fieldPrefix}
      minimize={minimize}
    />
  );
};

export default DELIVERY_ADDRESS_FORM_2;
