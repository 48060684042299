import React from "react";
import { Box } from "ui-components";
import { renderPageComponents } from "../renderPageComponent";

const GROUP_COMPONENT = props => {
  const {
    styles,
    pageProps,
    appSettings,
    screenshotEnabled,
    subComponents: pageComponents
  } = props;

  return (
    <Box style={{ padding: "10px", ...styles }}>
      {renderPageComponents({
        pageComponents,
        pageProps,
        appSettings,
        screenshotEnabled
      })}
    </Box>
  );
};
export default GROUP_COMPONENT;
