import get from "lodash/get";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import isFunction from "lodash/isFunction";
import { safeEval } from "utils/helpers";

export const emailRegex =
  "^[a-z0-9]+([-_a-z0-9]+){0,}([.|+|~][-_a-z0-9]+){0,}@((?:[-a-z0-9]+.)+[a-z]{2,63})$";

export const emailWithoutPlusSignRegex =
  "^[a-z0-9]+([-_a-z0-9]+){0,}([.||~][-_a-z0-9]+){0,}@((?:[-a-z0-9]+.)+[a-z]{2,63})$";

export const required = ({ formValue, message }) => {
  return formValue || typeof formValue === "number" ? undefined : message;
};

export const empty = ({ formValue, message }) => {
  return isEmpty(formValue) ? undefined : message;
};

export const matchFieldsValues = ({
  formValues,
  formValue,
  message,
  match_field
}) => {
  return formValue === formValues[match_field] ? undefined : message;
};

export const email = props =>
  regex({
    ...props,
    value: emailRegex
  });

const email_without_plus_sign = props =>
  regex({
    ...props,
    value: emailWithoutPlusSignRegex
  });

export const kanji_name = props =>
  regex({
    ...props,
    value: "^[一-龯]+$"
  });

export const kanji_name_with_space = props =>
  regex({
    ...props,
    value: "^[一-龯]+(([',. -][一-龯])?[一-龯]*)*$"
  });

export const kanji_name_with_alphabets_and_space = props =>
  regex({
    ...props,
    value: "^[一-龯a-zA-Z]+(([',. -][一-龯a-zA-Z])?[一-龯a-zA-Z]*)*$"
  });

export const kanji_name_with_alphabets = props =>
  regex({
    ...props,
    value: "^[一-龯a-zA-Z]+$"
  });

export const kana_name_with_alphabets = props =>
  regex({
    ...props,
    value: "^[ぁ-んァ-ンa-zA-Z]+$"
  });

export const kana_name = props =>
  regex({
    ...props,
    value: "^[ぁ-んァ-ン]+$"
  });

export const kana_name_with_space = props =>
  regex({
    ...props,
    value: "^[ぁ-んァ-ン]+(([',. -][ぁ-んァ-ン])?[ぁ-んァ-ン]*)*$"
  });

export const kana_name_with_alphabets_and_space = props =>
  regex({
    ...props,
    value:
      "^[ぁ-んァ-ンa-zA-Z]+(([',. -][ぁ-んァ-ンa-zA-Z])?[ぁ-んァ-ンa-zA-Z]*)*$"
  });

export const alphabets_and_space = props =>
  regex({
    ...props,
    value: "^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$"
  });

// For first/middle/last names
export const alphabets_and_special_chars = props =>
  regex({
    ...props,
    value: "^[-a-zA-Z()/\\\\.,~^\"'‘’` ]*$"
  });

export const min_value = ({ formValue, value, message }) => {
  return formValue && formValue < value ? message : undefined;
};

export const jp_zipcode = ({ formValues = {}, message }) => {
  const VALID_ZIP_CODE_LENGTH = 7;
  const { zip_code_part1 = "", zip_code_part2 = "" } = formValues;

  return (zip_code_part1 + zip_code_part2).length === VALID_ZIP_CODE_LENGTH
    ? undefined
    : message;
};

export const regex = ({ formValue, message, value, regex }) =>
  formValue && !new RegExp(value || regex, "i").test(formValue)
    ? message
    : undefined;

export const length_between = ({
  formValues,
  formValue,
  message,
  min_length,
  max_length
}) => {
  const minLength = safeEval(get, formValues, min_length);
  const maxLength = safeEval(get, formValues, max_length);
  const formValueLength = formValue ? formValue.length : 0;
  if (minLength && formValueLength < minLength) {
    return message;
  } else if (maxLength && formValueLength > maxLength) {
    return message;
  } else {
    return undefined;
  }
};

export const port_in_number = async ({
  formValue,
  field,
  regex,
  message,
  props
}) => {
  const prevFieldValue = get(props, ["formValues", field]);
  const orderRef = get(props, "orderRef", "");
  if (regex) {
    const validationRegex = RegExp(regex);
    const {
      validatePortInNumber,
      resetPortInNumberValidation,
      portInNumberValidation = {}
    } = props;

    if (validationRegex.test(formValue)) {
      if (prevFieldValue !== formValue) {
        isFunction(validatePortInNumber) &&
          validatePortInNumber(formValue, orderRef);
        return undefined;
      } else {
        const { valid, message: validationMessage } = portInNumberValidation;
        return !valid ? validationMessage : undefined;
      }
    } else {
      isFunction(resetPortInNumberValidation) && resetPortInNumberValidation();
      return message;
    }
  }
};

export const postal_code = async ({
  formValue,
  field,
  regex,
  message,
  props
}) => {
  const prevFieldValue = get(props, ["formValues", field]);

  if (!prevFieldValue && !formValue) {
    return undefined;
  }

  if (regex) {
    const validationRegex = RegExp(regex);
    const {
      validatePostalCode,
      resetPostalCodeValidation,
      postalCodeValidation = {}
    } = props;

    if (validationRegex.test(formValue)) {
      if (prevFieldValue !== formValue) {
        isFunction(validatePostalCode) && validatePostalCode(formValue, field);
        return undefined;
      } else {
        const { valid } = get(postalCodeValidation, [field], {});
        if (isNil(valid)) return undefined;

        return !isEmpty(postalCodeValidation) && !valid ? message : undefined;
      }
    } else {
      isFunction(resetPostalCodeValidation) && resetPostalCodeValidation(field);
      return message;
    }
  }
};

export const nric_singapore = ({ formValue, regex, message }) => {
  let i;
  let theAlpha;
  let weight;
  let parsedStr = formValue;
  if (parsedStr.length !== 9) {
    return message;
  }
  parsedStr = parsedStr.toUpperCase();
  i = 0;
  const icArray = [];
  i = 0;
  while (i < 9) {
    icArray[i] = parsedStr.charAt(i);
    i++;
  }
  icArray[1] = parseInt(icArray[1], 10) * 2;
  icArray[2] = parseInt(icArray[2], 10) * 7;
  icArray[3] = parseInt(icArray[3], 10) * 6;
  icArray[4] = parseInt(icArray[4], 10) * 5;
  icArray[5] = parseInt(icArray[5], 10) * 4;
  icArray[6] = parseInt(icArray[6], 10) * 3;
  icArray[7] = parseInt(icArray[7], 10) * 2;
  weight = 0;
  i = 1;
  while (i < 8) {
    weight += icArray[i];
    i++;
  }
  const offset = icArray[0] === "T" || icArray[0] === "G" ? 4 : 0;
  const temp = (offset + weight) % 11;
  const st = ["J", "Z", "I", "H", "G", "F", "E", "D", "C", "B", "A"];
  const fg = ["X", "W", "U", "T", "R", "Q", "P", "N", "M", "L", "K"];
  theAlpha = 0;
  if (icArray[0] === "S" || icArray[0] === "T") {
    theAlpha = st[temp];
  } else if (icArray[0] === "F" || icArray[0] === "G") {
    theAlpha = fg[temp];
  }
  if (icArray[8] === theAlpha) {
    return null;
  }
  return message;
};

export const national_id_taiwan = ({ formValue, regex, message }) => {
  if (formValue.length !== 10 || (regex && !formValue.match(regex))) {
    return message;
  }
  const taiwanLocationCode = {
    A: "10",
    B: "11",
    C: "12",
    D: "13",
    E: "14",
    F: "15",
    G: "16",
    H: "17",
    I: "34",
    J: "18",
    K: "19",
    L: "20",
    M: "21",
    N: "22",
    O: "35",
    P: "23",
    Q: "24",
    R: "25",
    S: "26",
    T: "27",
    U: "28",
    V: "29",
    W: "32",
    X: "30",
    Y: "31",
    Z: "33"
  };

  const validateNationalId = (location, nationalId) => {
    const nationalIdLength = nationalId.length - 1;
    let totalSum =
      Number(nationalId[nationalId.length - 1]) +
      (Number(location[0]) * 1 + Number(location[1]) * 9);
    for (let i = 1; i <= 8; i++) {
      totalSum += Number(nationalId[nationalIdLength - i]) * i;
    }
    return totalSum % 10;
  };

  const nationalId = formValue.split("");
  const location = taiwanLocationCode[nationalId[0]];
  return validateNationalId(location, nationalId) === 0 ? null : message;
};

const mapping = {
  presence: required,
  empty,
  regex,
  email,
  email_without_plus_sign,
  alphabets_and_space,
  alphabets_and_special_chars,
  min_value,
  length_between,
  jp_zipcode,
  nric_singapore,
  national_id_taiwan,
  kanji_name,
  kanji_name_with_space,
  kanji_name_with_alphabets_and_space,
  kana_name,
  kana_name_with_alphabets_and_space,
  kana_name_with_space,
  kanji_name_with_alphabets,
  kana_name_with_alphabets,
  match_fields_value: matchFieldsValues
};

const asyncMapping = {
  port_in_number,
  postal_code
};

const validate = (formValues, props) => {
  const errors = {};
  const { validations = [], appSettings = {} } = props.formValidations || {};

  validations.forEach(validation => {
    const { type, field, condition, async } = validation;
    let formValue = get(formValues, field);
    const validationNeeded = safeEval(get, formValues, condition, true, props);

    if (get(errors, field, false) || !validationNeeded || async) {
      return;
    }

    // custom validation lookup from various shared configuration files

    if ("lookup" in validation) {
      let sharedValidations = get(appSettings, validation.lookup);
      if ("lookup_type" in validation) {
        const lookupType = safeEval(
          get,
          formValues,
          validation.lookup_type,
          {},
          props
        );
        sharedValidations = sharedValidations[lookupType] || {};
      }
      const requiredInConfig = get(sharedValidations, "required");
      const errorMessage = get(
        sharedValidations,
        "error_msg",
        "error_cannot_be_blank"
      );

      if (field === "age" || field === "other_user_age") {
        set(
          errors,
          field,
          mapping["min_value"]({
            formValue,
            value: sharedValidations.value,
            message: errorMessage
          })
        );
      } else if (field.match(/^((contact|alternate)_number|icc_id)$/)) {
        set(
          errors,
          field,
          mapping["regex"]({
            formValue,
            value: sharedValidations.validation_regex,
            message: errorMessage
          })
        );
      } else if (field === "nric") {
        const docType = get(formValues, "doc_type.value", {});
        validation = sharedValidations[docType] || {};

        set(
          errors,
          field,
          mapping["nric_singapore"]({
            formValue,
            regex: validation.validation_regex,
            message: validation.error_msg
          })
        );
      } else if (field.match(/^(billing|delivery)_/)) {
        const field_key = field.replace(/^(billing|delivery)_/, "");
        const validation = get(sharedValidations, `fields.${field_key}`, {});
        const required = get(validation, "required", false);

        required &&
          set(
            errors,
            field,
            mapping["presence"]({
              formValue,
              message: validation.error_msg
            })
          );
      } else {
        let validationType = sharedValidations.validation_type || type;
        validationType =
          !validationType && sharedValidations.validation_regex
            ? "regex"
            : validationType;
        const validationFunction = mapping[validationType];
        if (isFunction(validationFunction)) {
          set(
            errors,
            field,
            validationFunction({
              regex: sharedValidations.validation_regex,
              message: errorMessage,
              formValues,
              formValue,
              props,
              ...validation
            })
          );
        }
      }

      if (!errors[field] && requiredInConfig) {
        set(
          errors,
          field,
          mapping["presence"]({
            formValue,
            message: errorMessage
          })
        );
      }
    } else {
      if (isFunction(mapping[type])) {
        set(
          errors,
          field,
          mapping[type]({ formValues, formValue, ...validation })
        );
      }
    }
  });

  return errors;
};

export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export const asyncValidate = async (formValues, dispatch, props) => {
  return new Promise(resolve => resolve()).then(async () => {
    const errors = {};
    const { validations = [], appSettings = {} } = props.formValidations || {};

    await asyncForEach(validations, async validation => {
      const { type, field, condition, async } = validation;
      let formValue = get(formValues, field);
      const validationNeeded = safeEval(
        get,
        formValues,
        condition,
        true,
        props
      );

      if (get(errors, field, false) || !validationNeeded || !async) {
        return;
      }

      if (isFunction(asyncMapping[type])) {
        const asyncMappingProps = {
          field,
          formValues,
          formValue,
          dispatch,
          props,
          ...validation
        };

        if ("lookup" in validation) {
          const sharedValidations = get(appSettings, validation.lookup);
          asyncMappingProps["regex"] = sharedValidations.validation_regex;
          asyncMappingProps["message"] = sharedValidations.error_msg;
        }

        const message = await asyncMapping[type](asyncMappingProps);
        if (message) {
          errors[field] = message;
        }
      }
    });
    if (!isEmpty(errors)) {
      throw errors;
    }
  });
};

export default validate;
