import React, { useState } from "react";
import { connect } from "react-redux";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, Button } from "ui-components";
import PageHeader from "components/PageHeader";
import styles from "./styles";
import ReactModal from "react-modal";
import get from "lodash/get";
import { cancelTermination } from "ducks/actions";
import { paths } from "utils/constants";
import { gotoUrl } from "utils/helpers";

const CancelTermination = props => {
  const {
    submitCancelTermination,
    messageData,
    orderRef,
    winbackOffer
  } = props;

  const [showModal, setShowModal] = useState(false);
  const submit = () => {
    submitCancelTermination({ orderRef });
  };
  const terminationPhoneNumber = get(messageData, "terminationPhoneNumber", "");
  const terminationDate = get(messageData, "terminationDate", "");

  return (
    <Box data-testid="cancel-termination" variant="main" padding="2rem">
      <PageHeader title="order_action_terminate_number_title" />

      <Box width={9 / 10} mx="auto">
        <FormattedMessage
          id="order_action_terminate_number_scheduled_on"
          values={{ terminationPhoneNumber, terminationDate }}
        />
      </Box>
      <br />

      {winbackOffer && (
        <>
          <Box width={9 / 10} mx="auto">
            <FormattedMessage
              id="order_action_terminate_number_cancel_termination_winback"
              values={{ winbackOffer }}
              html
            />
          </Box>
          <br />
        </>
      )}

      <Flex justifyContent="space-around" padding="1rem">
        <Button
          onClick={() => setShowModal(true)}
          data-testid="cancel-terminate-btn"
        >
          <FormattedMessage id="order_action_terminate_number_cancel_termination" />
        </Button>
      </Flex>
      <ReactModal style={styles.modalStyle} isOpen={showModal}>
        <PageHeader title="order_action_terminate_number_cancel_termination" />

        <Box mx="auto">
          <FormattedMessage
            id="order_action_terminate_number_cancel_termination_confirm"
            values={messageData}
          />
        </Box>
        <br />

        <Flex justifyContent="center">
          <Button onClick={submit} data-testid="modal-confirm">
            <FormattedMessage id="confirm" />
          </Button>
          <Button
            variant="outline"
            onClick={() => setShowModal(false)}
            data-testid="modal-cancel"
          >
            <FormattedMessage id="cancel" />
          </Button>
        </Flex>
      </ReactModal>
    </Box>
  );
};

const mapStateToProps = () => {
  return {};
};

export function mapDispatchToProps(dispatch) {
  return {
    submitCancelTermination: ({ orderRef }) => {
      const params = {
        order_ref: orderRef
      };

      const successPath = paths.CANCEL_TERMINATION_SUCCESS;

      const callback = () => {
        try {
          if (successPath) {
            gotoUrl(successPath);
          }
        } catch (err) {
          console.error("Error on cancelTermination callback", err);
        }
      };

      dispatch(cancelTermination(params, callback));
    }
  };
}

CancelTermination.defaultProps = {
  suspensionPhoneNumber: "",
  suspensionFee: "",
  suspensionAmount: "",
  startDate: "",
  endDate: ""
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelTermination);
