import React, { memo, useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { fetchPaymentStatus, clearData, UserService } from "ducks/actions";
import {
  makeSelectPaymentReturn,
  makeSelectIsFetchingData,
  makeSelectOrderRef,
  makeSelectSessionToken,
  makeSelectAuthToken,
  makeSelectLiquidUrlErrorData,
  makeSelectSimType,
  makeSelectContractType,
  makeSelectPaymentStatusPending,
  makeSelectPaymentStatusSuccess,
  makeSelectPaymentStatusFailure,
  makeSelectPaymentStatus,
  makeSelectPaymentDetails,
  makeSelectIsPortingUserWithPortingNumber,
  makeSelectOrder,
  makeSelectNetworkType,
  makeSelectGA4PlanDetails
} from "ducks/selectors";
import SmartRender from "containers/SmartRender";
import {
  urlParam,
  gotoUrl,
  getPaymentStatusUrl,
  lower,
  getDeepLink,
  getHash
} from "utils/helpers";
import {
  paths,
  PAYMENT_STATUS_POLL_INTERVAL,
  MOBILE_APP_BROWSER_PROTOCOL,
  STATUS_TYPE,
  UNAUTHORIZED_HTTP_CODE
} from "utils/constants";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { isWebview } from "utils/localStorage";
import { OMS } from "ducks/actions";
import AdjustAnalytics from "analytics/type/AdjustAnalytics/AdjustAnalytics";
import { ADJUST_EVENTS } from "constants/AnalyticsTypeConstants";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";

const PaymentStatusHOC = Component =>
  function Comp(props) {
    const {
      isFetchingPaymentReturn,
      isFetchingPaymentStatus,
      fetchPaymentReturn,
      orderRef,
      orderDetails,
      token,
      paymentPending,
      paymentStatus,
      fetchPaymentStatus,
      paymentDetails,
      GA4EventPlanDetails
    } = props;
    const [polling, setPolling] = useState(false);
    const order_ref = urlParam("order_ref");
    const paymentRef = get(paymentDetails, "payment_reference_id", "");

    // we use this param to identify whether we need to invoke transaction/status api call
    const isTransaction = urlParam("type") === STATUS_TYPE.TRANSACTION;

    useEffect(() => {
      if (token && orderRef && !isTransaction) {
        fetchPaymentReturn(orderRef, token);
      }

      if (isTransaction) {
        fetchPaymentStatus({ token, order_ref: paymentRef });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // here we are polling to transaction/status api until we receive status as pending, success
    // or failed. Based on the status we are navigating to the routes defined as payment-[status]
    useEffect(() => {
      if (isTransaction && paymentRef) {
        if (paymentPending && !isFetchingPaymentStatus && !polling) {
          setPolling(true);
          window.setTimeout(() => {
            fetchPaymentStatus({ token, order_ref: paymentRef });
            setPolling(false);
          }, PAYMENT_STATUS_POLL_INTERVAL);
        }

        const statusUrl = paymentStatus && getPaymentStatusUrl(paymentStatus);

        // a non empty string of statusUrl is true and if the pathname is not the same as statusUrl
        if (statusUrl && window.location.pathname !== statusUrl) {
          gotoUrl(statusUrl, true);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      paymentPending,
      paymentStatus,
      isTransaction,
      order_ref,
      isFetchingPaymentStatus,
      fetchPaymentStatus,
      token,
      orderRef,
      paymentRef
    ]);

    useEffect(
      () => {
        const urlOrderRef = urlParam("order_ref");
        if (
          token &&
          orderRef &&
          (!urlOrderRef || orderRef === urlOrderRef) &&
          !isTransaction
        ) {
          if (
            (!isFetchingPaymentReturn && isEmpty(orderDetails)) ||
            (!isEmpty(orderDetails) && orderDetails.orderRef !== orderRef)
          ) {
            fetchPaymentReturn(orderRef, token);
          } else {
            const status = lower(get(orderDetails, "paymentStatus", ""));
            if (status === "pending" && !isFetchingPaymentReturn && !polling) {
              setPolling(true);
              window.setTimeout(() => {
                fetchPaymentReturn(orderRef, token);
                setPolling(false);
              }, PAYMENT_STATUS_POLL_INTERVAL);
            }

            const statusUrl = status && getPaymentStatusUrl(status);

            if (statusUrl && window.location.pathname !== statusUrl) {
              AdjustAnalytics.pushEvent(ADJUST_EVENTS.PLAN_ORDERED);
              GA4EventsService.publish(purchaseEventData(GA4EventPlanDetails));
              gotoUrl(statusUrl, true);
            }
          }
        } else if (!isTransaction) {
          gotoManageOrder();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [orderRef, orderDetails, isFetchingPaymentReturn]
    );

    return <Component {...props} />;
  };

const gotoManageOrder = () =>
  gotoUrl(paths.MANAGE_ORDER, true, { redirect: "payment_status" });

const mapStateToProps = createStructuredSelector({
  orderDetails: makeSelectPaymentReturn(),
  orderRef: makeSelectOrderRef(),
  order: makeSelectOrder(),
  isFetchingPaymentReturn: makeSelectIsFetchingData("paymentReturn"),
  isFetchingPaymentStatus: makeSelectIsFetchingData("paymentStatus"),
  token: makeSelectSessionToken(),
  userToken: makeSelectAuthToken(),
  error: makeSelectLiquidUrlErrorData(),
  simType: makeSelectSimType(),
  numberType: makeSelectContractType(),
  contractType: makeSelectContractType(),
  networkType: makeSelectNetworkType(),

  // payment transaction statuses
  paymentPending: makeSelectPaymentStatusPending(),
  paymentSuccess: makeSelectPaymentStatusSuccess(),
  paymentFailure: makeSelectPaymentStatusFailure(),
  paymentStatus: makeSelectPaymentStatus(),
  paymentDetails: makeSelectPaymentDetails(),
  isPortingNumber: makeSelectIsPortingUserWithPortingNumber(),
  GA4EventPlanDetails: makeSelectGA4PlanDetails()
});

export function mapDispatchToProps(dispatch) {
  return {
    fetchPaymentReturn: (order_ref, token) => {
      const params = { order_ref, token };
      const type = urlParam("type");
      if (type) {
        params["type"] = type;
      }
      dispatch(
        OMS.V4.fetchPaymentReturn(params, (_, err, status) => {
          /* redirect user to manage order if error is not an unauthorized error.
           Checked this unauthorized error to prevent repeatedly redirecting user
           to payment_status -> manage/order and manage/order to paymnet_status routes*/
          if (err && status !== UNAUTHORIZED_HTTP_CODE) {
            // redirect to manage order on error while fetching payment details
            gotoManageOrder();
          }
        })
      );
    },
    fetchLiquidUrl: (bodyParams, onSuccess) => {
      dispatch(UserService.V4.fetchLiquidUrl(bodyParams, onSuccess));
    },
    fetchPaymentStatus: (params, callback) => {
      dispatch(fetchPaymentStatus(params, callback));
    },
    clearOrderAndNavigate: navigate => {
      dispatch(clearData("order"));
      setTimeout(() => {
        navigate && navigate();
      }, 0);
    }
  };
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  getLiquidUrl: () => {
    const deeplink = getDeepLink();
    const bodyParams = {
      order_id: stateProps.orderRef,
      redirect_url: isWebview()
        ? `${deeplink}${paths.WEBLOGIN}`
        : `${window.location.origin}${paths.USER_DETAILS}`
    };

    const onSuccess = (result, errors, status) => {
      const liquidUrl = get(result, "application_url");
      if (!errors && liquidUrl) {
        const finalUrl = isWebview()
          ? liquidUrl.replace("https", MOBILE_APP_BROWSER_PROTOCOL)
          : liquidUrl;

        gotoUrl(finalUrl, false, {}, true);
        gotoUrl(paths.MANAGE_ORDER);
      } else {
        if (status === 409) {
          gotoUrl(paths.USER_DETAILS);
        }
      }
    };

    dispatchProps.fetchLiquidUrl(bodyParams, onSuccess);
    // GA4EventsService.publish(moveOnEventData(stateProps.GA4EventPlanDetails));
  },
  clearOrderAndNavigate: navigate => {
    dispatchProps.clearOrderAndNavigate(navigate);
  }
});

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(withConnect, memo)(PaymentStatusHOC(SmartRender));

const purchaseEventData = (details = {}) => {
  const {
    email,
    customer_id,
    plan_name,
    plan_type,
    coupon,
    coupon_type,
    sim_type,
    iccid,
    journey_type,
    number_type,
    currency,
    item_name,
    item_id,
    item_category,
    item_variant,
    tax,
    shipping,
    order_ref
  } = details;

  return ({
    event: GA4EVENTS.WEB_PURCHASE,
    ecommerce: {
      customer_id,
      transaction_id: iccid || order_ref,
      plan_name,
      journey_type,
      plan_type,
      sim_type,
      number_type,
      coupon,
      coupon_type,
      value: 0,
      tax,
      shipping,
      currency,
      sim_count: 1,
      payment_type: "credit-card",
      items: [
        {
          item_name,
          item_id,
          price: 0,
          item_brand: "povo",
          item_category,
          item_variant,
          index: 0,
          quantity: 1
        }
      ],
      enhanced_conversion_data: {
        customerBillingEmail: getHash(email),
        customerBillingPhone: getHash(""),
        customerBillingFirstName: getHash(""),
        customerBillingLastName: getHash(""),
        customerBillingStreet: getHash(""),
        customerBillingCity: getHash(""),
        customerBillingRegion: getHash(""),
        customerBillingCountry: getHash(""),
        customerBillingPostalCode: getHash(""),
        event_id: "939ae1df-ded2-4ffb-9278-e358551c1e56"
      }
    }
  })
};

// const moveOnEventData = (details = {}) => {
//   const {
//     customer_id,
//     plan_name,
//     plan_type,
//     sim_type,
//     iccid,
//     contract_type,
//     journey_type,
//     number_type,
//     order_ref
//   } = details;

//   return {
//     event: GA4EVENTS.MOVE_ON_CLICK,
//     ecommerce: {
//       customer_id,
//       order_ref: iccid || order_ref,
//       plan_name,
//       journey_type,
//       contract_type,
//       plan_type,
//       sim_type,
//       number_type
//     }
//   };
// };
