import get from "lodash/get";
import { createSelector } from "reselect";

export const popStations = state => get(state, "api.popStations.data", {});

export const popStationsSelector = createSelector(popStations, popStations => {
  return get(popStations, "popstations", []);
});

export const popStationsDeliverySlotSelector = createSelector(
  popStations,
  popStations => {
    return get(popStations, "delivery_slots", []);
  }
);

export const makeSelectPopStations = () => popStationsSelector;
export const makeSelectPopStationDeliverySlots = () =>
  popStationsDeliverySlotSelector;
