import React, { useState, useEffect } from "react";
import { Flex, Box, Text } from "ui-components";
import { Field } from "redux-form";
import isFunction from "lodash/isFunction";
import { isEmpty } from "lodash";
import { sanitizeHTML } from "utils/helpers";

const ToppingTiles = props => {
  const {
    field,
    change,
    addonData: addonDataList = [],
    selectedToppings
  } = props;
  const [selectedTopping, setSelectedTopping] = useState([]);
  const [showToppingMoreInfo, setShowToppingMoreInfo] = useState(false);

  const toppingMoreInfo = () => {
    if (!showToppingMoreInfo) return null;
    const selectedItem = selectedTopping.find(
      item => item.product_id === selectedTopping[0].product_id
    );
    const description = selectedItem?.description?.replace(/\n/g, "</br>");

    return (
      <div
        style={{
          backgroundColor: "#E4E7EB",
          padding: "1.3rem",
          marginTop: "10px",
          borderRadius: "20px",
          lineHeight: "2rem"
        }}
      >
        <p style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
          {selectedItem.name}
        </p>
        <Text dangerouslySetInnerHTML={{ __html: sanitizeHTML(description) }} />
        {selectedItem?.learn_more && (
          <div style={{ textDecoration: "underline", marginTop: "1rem" }}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                selectedItem?.learn_more?.action?.data?.web_view?.link || ""
              }
            >
              {selectedItem?.learn_more?.title}
            </a>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    isFunction(change) && change(field, selectedTopping);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTopping]);

  useEffect(() => {
    if (!isEmpty(selectedToppings) && isEmpty(selectedTopping)) {
      setSelectedTopping(selectedToppings);
      setShowToppingMoreInfo(true);
    }
  }, [selectedToppings, selectedTopping.length, selectedTopping]);

  const handleClick = item => {
    const {
      name,
      description,
      product_id,
      tile,
      terms_condition,
      learn_more
    } = item;
    const { text } = tile || {};
    const { fourth_line } = text || {};

    if (selectedTopping.includes(product_id)) {
      return;
    }
    const selectedToppingDetails = {};
    const updatedSelectedTopping = [];
    selectedToppingDetails["name"] = name;
    selectedToppingDetails["description"] = description;
    selectedToppingDetails["product_id"] = product_id;
    selectedToppingDetails["price"] = fourth_line.text;
    selectedToppingDetails["learn_more"] = learn_more;
    selectedToppingDetails["terms_condition"] = terms_condition;
    updatedSelectedTopping.push(selectedToppingDetails);
    setSelectedTopping(updatedSelectedTopping);
    setShowToppingMoreInfo(true);
  };

  const tile = () => {
    return (
      <Box
        mb={6}
        style={{
          display: addonDataList.length >= 3 ? "flex" : "block",
          justifyContent: "center"
        }}
      >
        <Flex flexWrap="wrap" mx={-2} mb={3}>
          {addonDataList.map((item, index) => {
            const { tile, product_id } = item;
            const { text } = tile || {};
            const { first_line, second_line, third_line, fourth_line } =
              text || {};

            return !isEmpty(item.tile) ? (
              <Box key={index} width={["50%"]} px={2} mb={3}>
                <button
                  onClick={() => handleClick(item)}
                  className="border-none bg-transparent p-0 block w-full"
                >
                  <Box
                    sx={{
                      display: "flex",
                      borderRadius: "10px",
                      border: selectedTopping.some(
                        product => product.product_id === product_id
                      )
                        ? "2px solid black"
                        : "",
                      borderColor: "black",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
                    }}
                  >
                    <div style={{ display: "flex", width: "60px" }}>
                      <img
                        src={item.tile?.logo_url}
                        style={{ height: "120px", borderRadius: "7px" }}
                        alt="Icon"
                      />
                    </div>
                    <div
                      style={{
                        margin: "10px",
                        width: "-webkit-fill-available"
                      }}
                    >
                      <div className="mb-2 ">
                        <p
                          style={{
                            backgroundColor: first_line?.text
                              ? first_line["background-color"]
                              : "none",
                            width: "90px",
                            borderRadius: "7px",
                            padding: "5px",
                            fontSize: "10px",
                            color: first_line?.text
                              ? first_line["text-color"]
                              : "black"
                          }}
                        >
                          {first_line?.text || null}
                        </p>
                      </div>
                      <div
                        className="mb-1"
                        style={{ textAlignLast: "left", fontSize: "12px" }}
                      >
                        <p>{second_line?.text || null}</p>
                      </div>
                      <div
                        className="mb-1"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          fontSize: "12px"
                        }}
                      >
                        <p>{third_line?.text || null}</p>
                      </div>
                      <div
                        className="mb-1"
                        style={{ textAlignLast: "right", fontSize: "12px" }}
                      >
                        <p>{fourth_line?.text || null}</p>
                      </div>
                    </div>
                  </Box>
                </button>
              </Box>
            ) : null;
          })}
          {toppingMoreInfo()}
        </Flex>
      </Box>
    );
  };
  return (
    <Box>
      <Flex flexWrap="wrap" mx={-1} mb={3} flexDirection="column">
        <Field
          name={"topping"}
          component={tile}
          value={"value"}
          field={field}
          change={change}
        />
      </Flex>
    </Box>
  );
};
export default ToppingTiles;
