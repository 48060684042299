import { createSelector } from "reselect";
import get from "lodash/get";

export const requestOTPDomain = state => get(state.api, "requestOTP.data", {});
export const validateOTPDomain = state => get(state, "api.requestOTPV4.data");
export const validateNewEmailOTPDomain = state =>
  get(state, "api.validateOTPTrigger.data");
export const userLoginOTPDomain = state =>
  get(state.api, "userLoginOTP.data", {});
export const userSocialMediaData = state =>
  get(state.api, "socialMediaAuthentication.data", {});

export const OTPTokenSelector = createSelector(requestOTPDomain, requestOTP => {
  return requestOTP.token;
});

export const AuthIdSelector = createSelector(
  userLoginOTPDomain,
  userLoginOTP => {
    return userLoginOTP.auth_id || "";
  }
);

export const SocialMediaDetailsSelector = createSelector(
  userSocialMediaData,
  socialLoginDetails => {
    return socialLoginDetails;
  }
);

export const RequestOtpAuthTokenSelector = createSelector(
  validateOTPDomain,
  requestOTP => get(requestOTP, "auth_id")
);

export const RequestOtpAuthTokenNewEmailSelector = createSelector(
  validateNewEmailOTPDomain,
  requestOTPNewEmail => get(requestOTPNewEmail, "auth_id")
);

export const makeSelectOTPToken = () => OTPTokenSelector;
export const makeSelectAuthId = () => AuthIdSelector;
export const makeSelectSocialMediaDetails = () => SocialMediaDetailsSelector;
export const makeSelectRequestOtpAuthId = () => RequestOtpAuthTokenSelector;
export const makeSelectRequestOtpAuthIdNewEmail = () =>
  RequestOtpAuthTokenNewEmailSelector;
