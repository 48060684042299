import React from "react";
import styled from "@emotion/styled";
import FormattedMessage from "components/FormattedMessage";
import { Text } from "ui-components";
import get from "lodash/get";

const sizeMap = {
  xs: {
    fontSize: 12,
    clipPolygon:
      "polygon(100% 0, calc(100% - 7px) 52%, 100% 100%, 0% 100%, 7px 51%, 0% 0%)"
  }
};

const BadgeComponent = styled.div(({ size }) => ({
  fontSize: sizeMap[size].fontSize,
  textTransform: "uppercase",
  fontWeight: "bold",
  backgroundColor: "#FF00FF",
  padding: "4px 18px 2px 18px",
  marginBottom: 3,
  position: "relative",
  display: "inline-block",
  clipPath: sizeMap[size].clipPolygon,
  color: "#FFF"
}));

const Badge = ({
  label = "details",
  style = {},
  enable = false,
  size = "xs",
  themeColors
}) => {
  if (!enable) return null;
  const badgeTheme = get(themeColors, "badge", {});
  return (
    <BadgeComponent
      data-testid="badge-icon"
      size={size}
      style={{ ...badgeTheme, ...style }}
    >
      <FormattedMessage id={label}>
        {convertedText => (
          <Text
            sx={{ textAlign: "center", marginTop: "inherit" }}
            color={get(style, "color", badgeTheme?.color || "#FFF")}
          >
            {convertedText}
          </Text>
        )}
      </FormattedMessage>
    </BadgeComponent>
  );
};

export default Badge;
