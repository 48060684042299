import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import { isEqual } from "lodash";
import { normalizerTypes } from "normalizers";
import Spinner from "components/Spinner";
import { Flex, Label, Text, Box } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import TextField from "components/FormFields/TextField";
import { COUNTRY, GOOGLE_API_KEY, LOCALE } from "utils/constants";
import { compactJoin, parseAddress } from "utils/helpers";

import { makeSelectAddressFieldsDetails } from "ducks/selectors";

const VALID_ZIP_CODE_LENGTH = 7;
const VALID_ZIP_CODE_WITH_SEPARATOR_LENGTH = 8;
const GoogleAddressSearch = props => {
  const geoCodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?region=${COUNTRY}&language=${LOCALE}&key=${GOOGLE_API_KEY}`;

  const {
    change,
    fields,
    data: { fieldPrefix, linkedFieldPrefix = "" } = {},
    readOnly,
    formValues,
    initialValues = {},
    field_placeholder
  } = props;
  const { zip_code, state, city, hse_blk_tower, street_name, country } =
    fields || {};
  const stateField = fieldPrefix
    ? `${fieldPrefix}_${state?.field}`
    : state?.field;
  const cityField = fieldPrefix ? `${fieldPrefix}_${city?.field}` : city?.field;
  const zipCodeField = fieldPrefix
    ? `${fieldPrefix}_${zip_code?.field}`
    : zip_code?.field;
  const streetNameField = fieldPrefix
    ? `${fieldPrefix}_${street_name?.field}`
    : street_name?.field;
  const hseBlkTowerfield = fieldPrefix
    ? `${fieldPrefix}_${hse_blk_tower?.field}`
    : hse_blk_tower?.field;
  const countryField = fieldPrefix
    ? `${fieldPrefix}_${country?.field}`
    : country?.field;
  const postalCodePrefixFieldName = fieldPrefix
    ? `${fieldPrefix}_${zip_code?.field}_part1`
    : `${zip_code?.field}_part1`;
  const postalCodeSuffixFieldName = fieldPrefix
    ? `${fieldPrefix}_${zip_code?.field}_part2`
    : `${zip_code?.field}_part2`;
  const separator = get(zip_code, "separator", "-");

  const [selectedAddress, setSelectedAddress] = useState(null);
  const [noValidAddressFound, setNoValidAddressFound] = useState(false);
  const [notJapanseZipCode, setNotJapanseZipCode] = useState(false);
  const [postalCodePrefix, setPostalCodePrefix] = useState("");
  const [postalCodeSuffix, setPostalCodeSuffix] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const postalCode = `${postalCodePrefix}${postalCodeSuffix}`;

  useEffect(() => {
    if (
      initialValues[postalCodePrefixFieldName] &&
      (!postalCodePrefix || !postalCodeSuffix)
    ) {
      setPostalCodePrefix(initialValues[postalCodePrefixFieldName]);
      setPostalCodeSuffix(initialValues[postalCodeSuffixFieldName]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    if (selectedAddress && change) {
      if (!isEmpty(selectedAddress)) {
        const {
          state,
          city,
          country,
          streetName,
          countryCode = ""
        } = selectedAddress;
        change(stateField, state);
        onLinkedFieldChange(stateField, state);
        // Setting address not found error if no valid result returned for JP country
        if (
          postalCode.length === VALID_ZIP_CODE_LENGTH &&
          countryCode.toUpperCase() === COUNTRY.toUpperCase() &&
          isEmpty(city) &&
          isEmpty(state)
        ) {
          setNoValidAddressFound(true);
        } else {
          setNoValidAddressFound(false);
        }

        change(cityField, city);
        onLinkedFieldChange(cityField, city);

        change(streetNameField, streetName);
        onLinkedFieldChange(streetNameField, streetName);

        change(countryField, country);
        onLinkedFieldChange(countryField, country);
      } else if (isEqual(selectedAddress, {})) {
        const { countryCode = "" } = selectedAddress;
        postalCode.length === VALID_ZIP_CODE_LENGTH &&
          countryCode.toUpperCase() === COUNTRY.toUpperCase() &&
          setNoValidAddressFound(true);
        postalCode.length === VALID_ZIP_CODE_LENGTH &&
          countryCode.toUpperCase() !== COUNTRY.toUpperCase() &&
          setNotJapanseZipCode(true);
        change(stateField, "");
        change(cityField, "");
        change(streetNameField, "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAddress]);

  const clearAddressFields = () => {
    change(stateField, "");
    change(cityField, "");
    change(streetNameField, "");
  };

  const searchAddressByZipCode = (zipCode = "") => {
    if (zipCode.length === VALID_ZIP_CODE_LENGTH) {
      const url = `${geoCodeUrl}&address=${zipCode}`;
      setIsSearching(true);
      axios
        .get(url)
        .then(response => {
          setIsSearching(false);
          if (response?.data?.results && response.data.results.length > 0) {
            const formattedAddress = parseAddress(response.data.results);
            const { countryCode = "" } = formattedAddress || {};
            if (countryCode.toUpperCase() === COUNTRY.toUpperCase()) {
              setSelectedAddress(formattedAddress);
              return setNotJapanseZipCode(false);
            }
          }
          setSelectedAddress({});
        })
        .catch(error => {
          setIsSearching(false);
          setSelectedAddress({});
          console.error("Error fetching address:", error);
        });
    }
  };

  /**
   * If there is a linkedFieldPrefix(in ccm config),
   * change the values of linked field
   * field - field name
   * value - field value
   */
  const onLinkedFieldChange = (field, value) => {
    const linkedField = field?.replace(fieldPrefix, linkedFieldPrefix);
    const linkedFieldValue = get(formValues, linkedField);
    if (isFunction(change)) {
      if (
        linkedFieldPrefix &&
        linkedFieldPrefix !== fieldPrefix &&
        linkedFieldValue !== value
      ) {
        change(linkedField, value);
      }
    }
  };

  /**
   * This function trigger when changing zip code prefix
   */
  const processPostalCodePrefix = value => {
    const prefix = value?.toString();
    clearAddressFields();
    if (isValidNumberInput(prefix)) {
      searchAddressByZipCode(value + postalCodeSuffix);
      const formattedZipCode = compactJoin(
        [prefix, postalCodeSuffix],
        separator
      );
      if (formattedZipCode.length === VALID_ZIP_CODE_WITH_SEPARATOR_LENGTH) {
        change(zipCodeField, formattedZipCode);
        onLinkedFieldChange(zipCodeField, formattedZipCode);
      }
      setPostalCodePrefix(prefix);
      onLinkedFieldChange(postalCodePrefixFieldName, prefix);
    }
  };

  /**
   * This function trigger when changing zip code Suffix
   */
  const processPostalCodeSuffix = value => {
    const suffix = value?.toString();
    clearAddressFields();
    if (isValidNumberInput(suffix)) {
      searchAddressByZipCode(postalCodePrefix + suffix);
      const formattedZipCode = compactJoin(
        [postalCodePrefix, suffix],
        separator
      );
      if (formattedZipCode.length === VALID_ZIP_CODE_WITH_SEPARATOR_LENGTH) {
        change(zipCodeField, formattedZipCode);
        onLinkedFieldChange(zipCodeField, formattedZipCode);
      }
      setPostalCodeSuffix(suffix);
      onLinkedFieldChange(postalCodeSuffixFieldName, suffix);
    }
  };

  const isValidNumberInput = input => {
    try {
      const prefixAsNum = Number(input);
      return !isNaN(prefixAsNum);
    } catch (error) {
      return false;
    }
  };

  return (
    <Box width={[1]}>
      <Flex flexWrap="wrap" alignItems="baseline">
        <Text>〒</Text>
        <Flex width={"22%"} px={2}>
          <TextField
            field={postalCodePrefixFieldName}
            onChange={e => processPostalCodePrefix(e.target.value)}
            onEnter={processPostalCodePrefix}
            type={"tel"}
            sx={{ width: "100%" }}
            maxLength={3}
            disabled={readOnly}
            normalize={normalizerTypes.NUMBER_WITH_MAX_LENGTH({
              max_length: 3
            })}
            placeholder={field_placeholder?.postal_code_prefix || ""}
          />
        </Flex>
        <Text>-</Text>
        <Flex pl={2} flex="1">
          <TextField
            field={postalCodeSuffixFieldName}
            type={"tel"}
            sx={{ width: "100%" }}
            disabled={readOnly}
            maxLength={4}
            onChange={e => processPostalCodeSuffix(e.target.value)}
            onEnter={() => searchAddressByZipCode(postalCode)}
            normalize={normalizerTypes.NUMBER_WITH_MAX_LENGTH({
              max_length: 4
            })}
            placeholder={field_placeholder?.postal_code_suffix || ""}
          />
        </Flex>
      </Flex>
      {notJapanseZipCode &&
        noValidAddressFound &&
        postalCode.length === VALID_ZIP_CODE_LENGTH && (
          <div
            style={{ color: "#FF0046", fontSize: "14px", marginBottom: "1rem" }}
          >
            <FormattedMessage id="error_invalid_zip_code" />
          </div>
        )}
      {notJapanseZipCode &&
        !noValidAddressFound &&
        postalCode.length === VALID_ZIP_CODE_LENGTH && (
          <div
            style={{ color: "#FF0046", fontSize: "14px", marginBottom: "1rem" }}
          >
            <FormattedMessage id="error_not_jp_zip_code" />
          </div>
        )}

      {isSearching && <Spinner size={200} sx={{ justifyContent: "center" }} />}

      <Box
        sx={{
          display: postalCode.length === 7 && !isSearching ? "block" : "none"
        }}
      >
        <Flex sx={{ alignItems: "baseline" }}>
          <Box sx={{ width: "25%" }}>
            <Label>
              <FormattedMessage id={state?.label} />
              {": "}
            </Label>
          </Box>
          <Box sx={{ width: "75%" }}>
            <TextField
              {...state}
              field={stateField}
              normalize={normalizerTypes.TRIM_START()}
              label={null}
              onChange={event =>
                onLinkedFieldChange(stateField, get(event, "target.value"))
              }
            />
          </Box>
        </Flex>
        <Flex sx={{ alignItems: "baseline" }}>
          <Box sx={{ width: "25%" }}>
            <Label flex={1}>
              <FormattedMessage id={city?.label} />
              {": "}
            </Label>
          </Box>
          <Box sx={{ width: "75%" }}>
            <TextField
              {...city}
              field={cityField}
              normalize={normalizerTypes.TRIM_START()}
              label={null}
              onChange={event =>
                onLinkedFieldChange(cityField, get(event, "target.value"))
              }
              placeholder={
                field_placeholder?.city_placeholder || city.placeholder
              }
            />
          </Box>
        </Flex>
        <Box sx={{ marginTop: "15px" }}>
          <TextField
            {...street_name}
            disabled={readOnly}
            field={streetNameField}
            onChange={event =>
              onLinkedFieldChange(streetNameField, get(event, "target.value"))
            }
            placeholder={
              field_placeholder?.building_placeholder || street_name.placeholder
            }
          />
        </Box>
        <Box sx={{ marginTop: "40px" }}>
          <TextField
            {...hse_blk_tower}
            disabled={readOnly}
            field={hseBlkTowerfield}
            onChange={event =>
              onLinkedFieldChange(hseBlkTowerfield, get(event, "target.value"))
            }
            placeholder={
              field_placeholder?.street_placeholder || hse_blk_tower.placeholder
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

const GoogleAddressSearchFormField = props => {
  const { field = "address_search_field", styles } = props;

  const deliveryMethodDetails = useSelector(makeSelectAddressFieldsDetails());
  const fields = get(deliveryMethodDetails, "fields");

  return (
    <Box data-testid={field} sx={{ ...styles }}>
      <GoogleAddressSearch {...props} fields={fields} />
    </Box>
  );
};

export default GoogleAddressSearchFormField;
