import React from "react";
import SmartRender from "containers/SmartRender";
import DataActivationControllerHelper from "../DataActivationControllerHelper";
import { urlParam } from "utils/helpers";

const ESimTransferStaticContent = SmartRender =>
  function Comp(props) {
    const matchedParams = props?.match?.params;
    const msisdn = urlParam("msisdn");

    function dismissNative() {
      DataActivationControllerHelper.dismiss();
    }

    return (
      <SmartRender
        {...props}
        messageData={{
          ...matchedParams,
          msisdn
        }}
        dismiss={dismissNative}
      />
    );
  };

export default ESimTransferStaticContent(SmartRender);
