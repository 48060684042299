import { createSelector } from "reselect";
import { formValuesSelector } from "ducks/selectors";
import get from "lodash/get";
import { forms } from "utils/constants";

export const orderFromVerificationSelector = state =>
  get(state, "api.validateIccId.data", {});

export const multiSimOrderSelector = createSelector(
  orderFromVerificationSelector,
  orderDetails => get(orderDetails, "order.multi_sim_order", false)
);

// ***************************************************************
// ************** PersonalDetailsForm selectors ******************
// ***************************************************************

export const personalDetailsSelector = formValuesSelector(
  forms.PERSONAL_DETAILS_FORM
);

// ***************************************************************
// ************** PersonalDetailsForm makeSelect *****************
// ***************************************************************

export const makeSelectIsUploadIDLater = () =>
  createSelector(personalDetailsSelector, personalDetails => {
    return get(personalDetails, "upload_id_later", false);
  });

export const makeSelectPersonalDetailsForm = () => personalDetailsSelector;
