import { useState, useEffect, useRef } from "react";
import { loadScript, removeScript } from "utils/helpers";

export const AMAZON_SCRIPT_NAME = "AMAZON_TOKEN_SCRIPT";

const useLoadAmazonScript = ({ js_sdk_url, client_id }) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const isMountedRef = useRef(null);

  const onCompleteAmazonScriptLoad = () => {
    window.onAmazonLoginReady = () => {
      window.amazon.Login.setClientId(client_id);
    };

    window.onAmazonPaymentsReady = () => {
      isMountedRef.current && setIsScriptLoaded(true);
    };

    if (window.onAmazonPaymentsReady) {
      isMountedRef.current && setIsScriptLoaded(true);
    }
  };

  useEffect(() => {
    isMountedRef.current = true;
    loadScript(AMAZON_SCRIPT_NAME, js_sdk_url, onCompleteAmazonScriptLoad);

    return () => {
      isMountedRef.current = false;
      removeScript(AMAZON_SCRIPT_NAME);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isScriptLoaded
  };
};

export default useLoadAmazonScript;
