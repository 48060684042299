import { createSelector } from "reselect";
import {
  deliveryModesSelector,
  sessionTokenSelector,
  userOrdersDeliveryDetailsSelector
} from "ducks/selectors";
import get from "lodash/get";
import find from "lodash/find";
import values from "lodash/values";
import orderBy from "lodash/orderBy";
import isEmpty from "lodash/isEmpty";
import {
  addressParams,
  deliverySlotParams,
  getURLParams,
  getChannelFromUrl,
  eLOAParams,
  popStationParams
} from "utils/helpers";
import { ORDER_CHANNEL_MAPPING } from "utils/constants";
import head from "lodash/head";

const deliveryInfoDomain = state =>
  get(state, "form.DeliveryInfoForm.values", {});

// const postOfficeDeliverySlotsDomain = state =>
//   get(state, "api.postOfficeDeliverySlots.data");

// ***************************************************************
// ************** deliveryInfoForm selectors ******************
// ***************************************************************

export const deliveryInfoSelector = createSelector(
  deliveryInfoDomain,
  deliveryInfo => deliveryInfo
);

export const deliveryMethodsSelector = createSelector(
  deliveryModesSelector,
  deliveryModes => {
    const urlParams = getURLParams();
    return orderBy(values(deliveryModes), ["display_sequence"], ["asc"])
      .filter(
        deliveryMethod =>
          deliveryMethod.enable ||
          Object.keys(urlParams).find(param => {
            const queryString = get(deliveryMethod, "query_string", null);

            return (
              queryString &&
              Object.prototype.hasOwnProperty.call(queryString, param) &&
              urlParams[param] === queryString[param].toString()
            );
          })
      )
      .map(method => {
        return {
          ...method,
          id: method.action,
          label: method.label,
          value: method.action,
          name: method.action
        };
      });
  }
);

export const defaultDeliveryModeSelector = createSelector(
  deliveryMethodsSelector,
  deliveryMethods => {
    const defaultDeliveryMethod =
      find(deliveryMethods, deliveryMethod => {
        return deliveryMethod.default;
      }) || {};

    return defaultDeliveryMethod;
  }
);

export const isDeliveryInfoAvailable = createSelector(
  deliveryInfoDomain,
  deliveryInfo => !isEmpty(get(deliveryInfo, "values", {}))
);

export const channelSelector = () =>
  get(ORDER_CHANNEL_MAPPING, getChannelFromUrl());

export const selectedDeliveryMethodSelector = createSelector(
  defaultDeliveryModeSelector,
  deliveryInfoSelector,
  (defaultDeliveryMethod, deliveryInfo) => {
    return get(deliveryInfo, "delivery_method", defaultDeliveryMethod);
  }
);

// ***************************************************************
// *************** deliveryInfoForm makeSelect *******************
// ***************************************************************

export const makeSelectDeliveryInfoForm = () => deliveryInfoSelector;

export const makeSelectDeliveryMethods = () => deliveryMethodsSelector;

export const makeSelectDeliveryMethod = () => selectedDeliveryMethodSelector;

export const makeSelectIsDeliveryInfoAvailable = () => isDeliveryInfoAvailable;

export const makeSelectScheduleDeliveryPayload = () =>
  createSelector(
    deliveryInfoSelector,
    sessionTokenSelector,
    userOrdersDeliveryDetailsSelector,
    (formValues, sessionToken, userOrder) => {
      if (isEmpty(formValues)) return {};
      const {
        alternate_number,
        order_type,
        delivery_type,
        token,
        fail_reason,
        receive_my_package,
        order_ref,
        delivery_method
      } = formValues;

      const { delivery_slot = {} } = deliverySlotParams(formValues);

      const payload = {
        order_type,
        delivery_type,
        token: token || sessionToken,
        order_ref,
        fail_reason: get(fail_reason, "value", ""),
        alternate_number:
          get(formValues, "contact_number") ||
          alternate_number ||
          get(head(userOrder), "contact_number"),
        ...(get(delivery_method, "action", "home") === "pop_station"
          ? popStationParams(formValues)
          : addressParams(formValues, "delivery")),
        ...delivery_slot,
        ...(receive_my_package ? eLOAParams(formValues) : {})
      };

      return payload;
    }
  );

const addressFieldsDetailsSelector = createSelector(
  selectedDeliveryMethodSelector,
  data => data
);

export const makeSelectAddressFieldsDetails = () =>
  addressFieldsDetailsSelector;
