import React from "react";
import { Box } from "ui-components";
import FAQ from "components/FAQ";

const PLAN_FAQ = props => {
  return (
    <Box mt={8}>
      <FAQ items={[{ title: "", description: props.planFAQ }]} />
    </Box>
  );
};

export default PLAN_FAQ;
