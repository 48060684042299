import React from "react";
import PageHeader from "components/PageHeader";

const PAGE_HEADER = props => {
  const { data, messageData, themeColors } = props;
  return (
    <PageHeader
      {...props}
      {...data}
      {...messageData}
      themeColors={themeColors}
    />
  );
};

export default PAGE_HEADER;
