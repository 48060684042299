import { createSelector } from "reselect";
import get from "lodash/get";
import { forms } from "utils/constants";

/**
 * Returns order cancellation form values state
 * @param {object} state redux state
 */
const orderCancellationFormDomain = state =>
  get(state, `form.${forms.ORDER_CANCELLATION_FORM}.values`);

export const orderCancellationSelector = createSelector(
  orderCancellationFormDomain,
  orderCancellation => orderCancellation
);

/**
 * Returns order cancellation form values
 */
export const makeSelectOrderCancellationForm = () => orderCancellationSelector;
