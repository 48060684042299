import forEach from "lodash/forEach";
import get from "lodash/get";
import {
  generateHtmlElement,
  getChannelFromUrl,
  getGopayActionUrls,
  gotoUrl,
  isMobile
} from "utils/helpers";
import { paths, POST_PAYMENT_REQUEST_ACTION_TYPES } from "utils/constants";

const usePaymentResponseHandler = result => {
  const request_url = get(result, "request_url");
  const { deepLinkUrl, qrCodeUrl } = getGopayActionUrls(result);
  const { action, action_object: actionData } = get(result, "form_data");

  if (action === POST_PAYMENT_REQUEST_ACTION_TYPES.SUBMIT) {
    const hiddenFormData = actionData?.body;
    const form = document.createElement("form");

    form.setAttribute("method", actionData?.type);
    form.setAttribute("action", actionData?.url);

    forEach(hiddenFormData, (value, key) => {
      const hiddenField = generateHtmlElement(key, value, "hidden");
      form.appendChild(hiddenField);
    });

    document.body.appendChild(form);
    form.submit();
  } else if (isMobile() && deepLinkUrl) {
    // redirect to deep link
    window.location.href = deepLinkUrl;
  } else if (qrCodeUrl) {
    // redirect to qr code page
    gotoUrl(paths.PAYMENT_QR.replace(":channel", getChannelFromUrl()));
  } else if (request_url) {
    window.location.href = request_url;
  }
};

export default usePaymentResponseHandler;
