import {
  BASE,
  LOGISTIC_DELIVERY_SLOTS,
  OMS_DELIVERY_SLOTS,
  USE_API_GATEWAY
} from "utils/constants";
import { fetchRequested } from "ducks/actions/baseActions";

export const fetchDeliverySlots = (params, callback) => {
  const path = USE_API_GATEWAY ? LOGISTIC_DELIVERY_SLOTS : OMS_DELIVERY_SLOTS;
  const base = USE_API_GATEWAY ? BASE.LOGISTIC : BASE.OMS;
  return fetchRequested({
    key: "deliverySlots",
    path,
    base,
    params,
    callback
  });
};

export default {
  fetchDeliverySlots
};
