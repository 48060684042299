import { combineReducers } from "redux";
import produce from "immer";
import { reducer as formReducer } from "redux-form";
import { handleActions } from "redux-actions";
import get from "lodash/get";
import set from "lodash/set";
import merge from "lodash/merge";
import isPlainObject from "lodash/isPlainObject";
import {
  clearData,
  setData,
  resetLoadingApis,
  fetchRequested,
  fetchSucceeded,
  fetchFailed,
  clearAllData
} from "ducks/actions";
import { uiReducer } from "./uiReducer";

const initialState = {};
export const apiReducer = handleActions(
  {
    [clearData]: produce((draftState, { payload }) => {
      if (Array.isArray(payload)) {
        payload.forEach(key => {
          set(draftState, key, {});
        });
      } else {
        const key = payload;
        set(draftState, key, {});
      }
    }),
    [clearAllData]: () => {
      return initialState;
    },
    [setData]: produce(
      (draftState, { payload: { key, data, loading = false, error } }) => {
        const currentData = get(draftState, `${key}.data`);
        set(draftState, `${key}.data`, merge(currentData, data));
        set(draftState, `${key}.updatedAt`, new Date());
        set(draftState, `${key}.loading`, loading);
        set(draftState, `${key}.error`, error);
      }
    ),
    [resetLoadingApis]: produce(draftState => {
      Object.keys(draftState).forEach(key => {
        const { loading, requestedAt } = get(draftState, key, {});
        // requestedAt will be a string only if the store is rehydrated on reload
        if (loading && typeof requestedAt === "string") {
          console.log(`resetLoadingApis: resetting ${key}`);
          set(draftState, `${key}.updatedAt`, new Date());
          set(draftState, `${key}.loading`, false);
        }
      });
    }),
    [fetchRequested]: produce((draftState, { payload: { key, params } }) => {
      set(draftState, `${key}.loading`, true);
      set(draftState, `${key}.requestedAt`, new Date());
      set(draftState, `${key}.params`, params);
    }),
    [fetchSucceeded]: produce(
      (draftState, { payload: { key, data, override, replace } }) => {
        set(draftState, `${key}.loading`, false);
        set(draftState, `${key}.completedAt`, new Date());
        set(draftState, `${key}.error`, undefined);
        set(draftState, `${key}.errorReason`, undefined);

        const currentData = get(draftState, `${key}.data`);

        if (isPlainObject(data) && isPlainObject(currentData)) {
          if (override) {
            for (const attr of Object.keys(data)) {
              set(draftState, `${key}.data.${attr}`, data[attr]);
            }
          } else if (replace) {
            set(draftState, `${key}.data`, data);
          } else {
            set(draftState, `${key}.data`, merge(currentData, data));
          }
        } else {
          set(draftState, `${key}.data`, data);
        }
      }
    ),
    [fetchFailed]: produce(
      (draftState, { payload: { key, error, errorReason } }) => {
        set(draftState, `${key}.loading`, false);
        set(draftState, `${key}.completedAt`, new Date());
        set(draftState, `${key}.error`, error);
        set(draftState, `${key}.errorReason`, errorReason);
      }
    )
  },
  initialState
);

export default combineReducers({
  api: apiReducer,
  ui: uiReducer,
  form: formReducer
});
