import React, { useEffect } from "react";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import PostOfficeCard from "components/PostOfficeCard";
import RadioButtonGroup from "components/FormFields/RadioButtonGroup";
import { setPostOfficeAddressAsDeliveryAddress } from "utils/helpers";

const STORE_PICKER_2 = ({
  cityList,
  districtList,
  streetList,
  storeList,
  fetchCityList,
  fetchDistrictList,
  fetchStreetList,
  fetchStoreList,
  resetDistrictList,
  resetStreetList,
  resetStoreList,
  formValues,
  changeField,
  change,
  data
}) => {
  useEffect(() => {
    if (!cityList.length) {
      fetchCityList();
    }
    // eslint-disable-next-line
  }, [cityList]);
  const CITY_FIELD = "delivery_city_id";
  const DISTRICT_FIELD = "delivery_district_id";
  const STREET_FIELD = "delivery_street_id";
  const STORE_FIELD = "post_office_id";
  const showMap = get(data, "showMap");

  const constructOptions = data =>
    map(data, option => {
      return {
        label: option.value,
        value: option.id
      };
    });

  const constructStoreOptions = data =>
    map(data, option => {
      return {
        ...option,
        label: <PostOfficeCard {...option} showMap={showMap} />,
        value: option.id
      };
    });

  const changeHandler = {
    [CITY_FIELD]: value => {
      const fieldValue = formValues[CITY_FIELD];
      changeField(DISTRICT_FIELD);
      changeField(STREET_FIELD);
      changeField(STORE_FIELD);
      resetDistrictList();
      resetStreetList();
      resetStoreList();
      if (value === fieldValue) {
        setTimeout(() => changeField(CITY_FIELD), 0);
      } else {
        changeField(CITY_FIELD, value);
        const params = {
          city: value
        };
        fetchDistrictList(params);
      }
    },
    [DISTRICT_FIELD]: value => {
      const fieldValue = formValues[DISTRICT_FIELD];
      changeField(STREET_FIELD);
      changeField(STORE_FIELD);
      resetStreetList();
      resetStoreList();
      if (value === fieldValue) {
        setTimeout(() => changeField(DISTRICT_FIELD), 0);
      } else {
        changeField(DISTRICT_FIELD, value);
        const params = {
          city: formValues[CITY_FIELD],
          district: value
        };
        fetchStreetList(params);
      }
    },
    [STREET_FIELD]: value => {
      const fieldValue = formValues[STREET_FIELD];
      changeField(STORE_FIELD);
      resetStoreList();
      if (value === fieldValue) {
        setTimeout(() => changeField(STREET_FIELD), 0);
      } else {
        changeField(STREET_FIELD, value);
        const params = {
          city: formValues[CITY_FIELD],
          district: formValues[DISTRICT_FIELD],
          street: value
        };
        fetchStoreList(params);
      }
    },
    [STORE_FIELD]: value => {
      const fieldValue = formValues[STORE_FIELD];
      if (value === fieldValue) {
        setTimeout(() => changeField(STORE_FIELD), 0);
      } else {
        changeField(STORE_FIELD, value);
        const selectedStore = find(storeList, ["id", value]);
        !isEmpty(selectedStore) &&
          setPostOfficeAddressAsDeliveryAddress(change, selectedStore);
      }
    }
  };

  const renderRadioGroup = (options, field, label, singleOptionRow) => {
    const fieldValue = formValues[field];
    const fieldOptions = fieldValue
      ? filter(options, ["value", fieldValue])
      : options;
    return isEmpty(options) ? null : (
      <RadioButtonGroup
        required
        options={fieldOptions}
        onChange={changeHandler[field]}
        field={field}
        label={fieldValue ? "" : label}
        singleOptionRow={singleOptionRow || !!fieldValue}
      />
    );
  };

  return (
    <>
      {renderRadioGroup(
        constructOptions(cityList),
        CITY_FIELD,
        "delivery_info_choose_city"
      )}
      {renderRadioGroup(
        constructOptions(districtList),
        DISTRICT_FIELD,
        "delivery_info_choose_district"
      )}
      {renderRadioGroup(
        constructOptions(streetList),
        STREET_FIELD,
        "delivery_info_choose_street"
      )}
      {renderRadioGroup(
        constructStoreOptions(storeList),
        STORE_FIELD,
        "delivery_info_choose_store",
        true
      )}
    </>
  );
};

export default STORE_PICKER_2;
