import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import createSagaMiddleware from "redux-saga";
import rootReducer from "ducks/reducers";
import mySaga from "ducks/sagas";
import forEach from "lodash/forEach";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [],
  migrate: (state = {}) => {
    forEach(state.form, form => {
      delete form["registeredFields"];
    });

    return Promise.resolve(state);
  }
};

/**
 * Logs all actions and states after they are dispatched.
 */

const logger = store => next => action => {
  // console.group(action.type);
  // console.info("dispatching", action);
  let result = next(action);
  // console.log("next state", store.getState());
  // console.groupEnd();
  return result;
};

export default function configureStore(initialState = {}) {
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, thunkMiddleware, logger];
  const middlewareEnhancer = applyMiddleware(...middlewares);
  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);
  const store = createStore(persistedReducer, initialState, composedEnhancers);
  const persistor = persistStore(store);
  store.runSaga = sagaMiddleware.run(mySaga);

  return { store, persistor };
}
