import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Flex, H1, H2, H3, Text, Button, Box } from "ui-components";
import isNil from "lodash/isNil";
import get from "lodash/get";
import { gotoUrl, safeEval } from "utils/helpers";
import { COLORS, iconThemes } from "ui-components/theme";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
import { Circle, IconStack, Information } from "../Icons";

const pageHeaderProps = {
  alignSelf: "self-start",
  flexDirection: "column"
};

const PageHeader = props => {
  const {
    title,
    subtitle,
    section_title,
    section_styled_title,
    subtitleWithTooltip,
    padding,
    paddingBottom,
    style = {}
  } = props;

  if (!isNil(padding)) {
    pageHeaderProps["padding"] = padding;
  }
  pageHeaderProps["paddingBottom"] = paddingBottom || [4, 5];

  return (
    <Flex {...pageHeaderProps} style={style}>
      {title && <PageTitle {...props} />}
      {subtitle && <PageSubtitle {...props} />}
      {subtitleWithTooltip && <PageSubtitleWithTooltip {...props} />}
      {section_title && <PageSectionTitle {...props} />}
      {section_styled_title && <PageStyledSectionTitle {...props} />}
    </Flex>
  );
};

const PageTitle = props => {
  const {
    title,
    changeButton,
    themeColors,
    title_color,
    title_bg_color,
    title_highlight_color,
    padding_left,
    center,
    titleStyles
  } = props;
  const changeUrl = get(changeButton, "url", "");
  const changeLabel = get(changeButton, "label", "change");
  const highlightColor =
    title_highlight_color || get(themeColors, "secondary", COLORS.blue[4]);
  const buttonColor = get(themeColors, "primary", COLORS.blue[4]);
  let titleStyle = titleStyles || {};
  if (title_bg_color) {
    titleStyle = {
      backgroundColor: title_bg_color,
      borderRadius: "0 2.5rem 2.5rem 0",
      padding: "0.5rem 2rem"
    };
    if (padding_left) {
      titleStyle["marginLeft"] = "-" + padding_left;
      titleStyle["paddingLeft"] = padding_left;
    }
  }
  if (title_color) {
    titleStyle["color"] = title_color;
  }
  var centerStyle = center
    ? { display: "flex", margin: "auto", maxWidth: 300 }
    : {};

  return (
    <Flex
      justifyContent="space-between"
      mb={2}
      sx={{
        ".page-title > span > span ": { color: `${highlightColor} !important` }
      }}
    >
      <H1
        mb={1}
        className="page-title"
        style={{ ...titleStyle, ...centerStyle }}
      >
        <FormattedMessage html id={title} values={props} />
      </H1>
      {changeUrl && (
        <Button
          data-testid="change_url_btn"
          as={"a"}
          variant="closeButton"
          sx={{ fontSize: "1rem", color: buttonColor }}
          onClick={() => {
            gotoUrl(changeUrl);
          }}
        >
          <FormattedMessage id={changeLabel} />
        </Button>
      )}
    </Flex>
  );
};

const PageSubtitle = props => {
  const { subtitle } = props;

  return (
    <Text size="sm" mb={1}>
      <FormattedMessage html id={subtitle} values={props} />
    </Text>
  );
};

const PageSubtitleWithTooltip = props => {
  const { subtitleWithTooltip, infoTip, themeColors } = props;
  const iconColor = get(themeColors, "primary", iconThemes.blue);
  const iconForeColor = get(infoTip, "iconForeColor", "transparent");
  const onClick = get(infoTip, "onClickPaymentInfo", "transparent");
  const onClickTooltip = safeEval(get, {}, onClick, "", props);

  return (
    <Flex>
      <Text size="sm" sx={{ fontWeight: 600 }} mt={1} mb={1}>
        <FormattedMessage html id={subtitleWithTooltip} values={props} />
      </Text>
      {isPlainObject(infoTip) && !isEmpty(infoTip) && (
        <>
          &nbsp;
          <Box ml={1} height={20} width={16} onClick={onClickTooltip}>
            <IconStack size="20px">
              <Circle size="14px" color={iconForeColor} />
              <Information color={iconColor} size="20px" />
            </IconStack>
          </Box>
        </>
      )}
    </Flex>
  );
};

const PageSectionTitle = props => {
  const { section_title, style } = props;

  return (
    <H2 style={style}>
      <FormattedMessage html id={section_title} values={props} />
    </H2>
  );
};

const PageStyledSectionTitle = props => {
  const {
    section_styled_title,
    themeColors,
    title_color,
    title_bg_color,
    title_highlight_color,
    padding_left,
    center,
    titleStyles
  } = props;
  const highlightColor =
    title_highlight_color || get(themeColors, "secondary", COLORS.blue[4]);
  let titleStyle = titleStyles || {};
  if (title_bg_color) {
    titleStyle = {
      ...titleStyle,
      backgroundColor: title_bg_color,
      borderRadius: "0 2.5rem 2.5rem 0",
      padding: "0.5rem 2rem"
    };
    if (padding_left) {
      titleStyle["marginLeft"] = "-" + padding_left;
      titleStyle["paddingLeft"] = padding_left;
    }
  }
  if (title_color) {
    titleStyle = {
      ...titleStyle,
      color: title_color
    };
  }
  const centerStyle = center ? { display: "flex", margin: "auto" } : {};

  return (
    <Flex
      data-testid="pageStyled-section_title"
      justifyContent="space-between"
      mb={2}
      sx={{
        ".page-title > span > span ": { color: `${highlightColor} !important` }
      }}
    >
      <H3
        mb={1}
        className="page-title"
        style={{ ...titleStyle, ...centerStyle }}
      >
        <FormattedMessage html id={section_styled_title} values={props} />
      </H3>
    </Flex>
  );
};

PageHeader.defaultProps = {
  title: "",
  subtitle: "",
  section_title: "",
  section_styled_title: ""
};

export default PageHeader;
