import React from "react";
import get from "lodash/get";
import RadioGroup from "components/FormFields/RadioGroup";

const RADIO = ({ data: { ...dataProps } = {}, styles, ...props }) => {
  const width = get(styles, "width", [1 / 2, 1 / 3, 1 / 3]);
  return <RadioGroup {...dataProps} {...props} width={width}></RadioGroup>;
};

export default RADIO;
