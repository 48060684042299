import React, { useState } from "react";
import { Flex, Box, Text } from "ui-components";
import { ReactComponent as ArrowUp } from "assets/icons/icon-arrow-up.svg";
import { ReactComponent as ArrowDown } from "assets/icons/icon-arrow-down.svg";
import styles from "./styles";
import get from "lodash/get";
import map from "lodash/map";

const ProductItem = ({
  id,
  title,
  sub_title,
  description,
  specs,
  price,
  type
}) => {
  const [seeMore, setSeeMore] = useState(false);
  const toggleSeeMore = () => setSeeMore(!seeMore);
  return (
    <Flex
      data-testid="product-item"
      sx={styles.wrapperStyles}
      className="product-item"
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Flex flexDirection="column" justifyItems="center">
          <Text fontSize="16px" fontWeight="bold">
            {title}
          </Text>
          <Text>{sub_title}</Text>
        </Flex>
        <Flex alignItems="center">
          <Text fontSize="16px" fontWeight="bold">{`${get(
            price,
            "prefix",
            ""
          )} ${get(price, "value")}`}</Text>
          <Flex onClick={toggleSeeMore} style={styles.linkStyles}>
            {seeMore ? <ArrowUp /> : <ArrowDown />}
          </Flex>
        </Flex>
      </Flex>
      {seeMore && (
        <Flex flexDirection="column" justifyContent="center">
          <hr width="100%" />
          <Box>
            <Text>{description}</Text>
          </Box>
          <Box>
            {map(get(specs, "default_products"), ({ id }) => (
              <Text>{id}</Text>
            ))}
          </Box>
          <Box>
            {map(get(specs, "additional_products"), ({ id }) => (
              <Text>{id}</Text>
            ))}
          </Box>
        </Flex>
      )}
    </Flex>
  );
};

export default ProductItem;
