import React from "react";
import CheckboxField from "components/FormFields/CheckboxField";
import FormattedMessage from "components/FormattedMessage";
import { Box, Label } from "ui-components";

const TCInfoBox = props => {
    const {
        field,
        required,
        data: {
            checkbox_label,
            sections = [],
            selectedToppingTnClocale,
            parentSx = { marginBottom: "1rem" }
        },
        themeColors,
        messageData
    } = props;

    const Section = (props) => {
        const {
            header,
            details,
            styles = {}
        } = props;

        return (
            <div style={{ marginTop: "1rem", ...styles }}>
                <div style={{ fontWeight: "bolder", color: "#000000", fontSize: "16px", marginBottom: "0.5rem" }}>
                    {header && <FormattedMessage html id={header} values={messageData} />}
                </div>
                <p style={{ color: "#333333", fontWeight: "400", fontSize: "14px" }}>
                    {details && <FormattedMessage html id={details} values={messageData} />}
                </p>
            </div>
        )
    }

    return (
        <Box sx={parentSx}>
            <div style={{ border: "1px solid #D6D6D6", borderRadius: "12px 12px 0 0", borderBottom: "0px", padding: "0.5rem 1rem 1rem 1rem" }}>
                {
                    sections.map(({ header, details, styles }) => <Section key={header} header={header} details={details} styles={styles} />)
                }
                {
                    selectedToppingTnClocale && props.selectedToppingTermsConditions && <Section details={selectedToppingTnClocale} />
                }
            </div>
            <Box style={{ backgroundColor: "#F0F3F7", border: "1px solid #D6D6D6", borderRadius: "0 0 1rem 1rem" }}>
                <div style={{ margin: "auto", marginBottom: "0px", lineHeight: "3rem", paddingLeft: "1rem" }}>
                    <Label>
                        <CheckboxField
                            id={field}
                            field={field}
                            label={checkbox_label}
                            themeColors={themeColors}
                            required={required}
                            boxSx={{ marginBottom: "0px !important" }}
                        />
                    </Label>
                </div>
            </Box>
        </Box>
    )
}

export default TCInfoBox;