import React, { useEffect, useState } from "react";
import { ChevronRight } from "components/Icons";
import { Box, Flex, Button } from "ui-components";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectOrderParams,
  makeSelectCountryIso0Code,
  makeSelectCurrencyCode,
  makeSelectOrderSummaryPayload,
  makeSelectPlanName,
  makeSelectTotalPrepaidParams,
  makeSelectPhonesForm,
  phoneSummary,
  makeSelectSessionToken
} from "ducks/selectors";
import { postPaymentRequest, applePayMerchantValidation } from "ducks/actions";
import get from "lodash/get";
import { gotoUrl, getChannelFromUrl } from "utils/helpers";
import { paths } from "utils/constants";

const ApplePay = props => {
  const dispatch = useDispatch();
  const { order_summary } = useSelector(makeSelectOrderParams());
  const currencyCode = useSelector(makeSelectCurrencyCode());
  const countryCode = useSelector(makeSelectCountryIso0Code());
  const paymentsPayload = useSelector(makeSelectOrderSummaryPayload());
  const planName = useSelector(makeSelectPlanName());
  const totalPrePaidFee = useSelector(makeSelectTotalPrepaidParams());
  const phonesForm = useSelector(makeSelectPhonesForm());
  const token = useSelector(makeSelectSessionToken());
  const {
    login_btn_config,
    merchant_capabilities,
    supported_networks,
    versionNumber,
    line_item_labels
  } = props;
  const configData = get(props.appSettings, "generic.payment_methods.applePay");
  const [availableApplePay, setApplePayAvailability] = useState(false);

  const getLineItem = (key, amount) => {
    const label = get(line_item_labels, key, key);
    return {
      label: label,
      amount: amount
    };
  };

  const getLineItemList = () => {
    const phone_summary = phoneSummary(phonesForm);
    const { onetime_device_fee = 0 } = phone_summary;

    let lineItems = [];

    if (order_summary.delivery_slot_fee > 0) {
      lineItems.push(
        getLineItem("delivery_slot_fee", order_summary.delivery_slot_fee)
      );
    }
    if (order_summary.onetime_sim_activation_fee > 0) {
      lineItems.push(
        getLineItem(
          "sim_activation_fee",
          order_summary.onetime_sim_activation_fee
        )
      );
    }

    if (order_summary.onetime_number_fee > 0) {
      lineItems.push(
        getLineItem("one_time_number_fee", order_summary.onetime_number_fee)
      );
    }

    if (onetime_device_fee > 0) {
      lineItems.push(getLineItem("device_fee", onetime_device_fee));
    }

    if (totalPrePaidFee > 0) {
      lineItems.push(getLineItem("total_prepaid_fee", totalPrePaidFee));
    }

    if (order_summary.onetime_referral_code_discount_fee > 0) {
      lineItems.push(
        getLineItem(
          "discount",
          order_summary.onetime_referral_code_discount_fee
        )
      );
    }
    return lineItems;
  };

  const onApplePayButtonClicked = () => {
    // Define ApplePayPaymentRequest
    const request = {
      countryCode: countryCode,
      currencyCode: currencyCode,
      merchantCapabilities: merchant_capabilities,
      supportedNetworks: supported_networks,
      lineItems: getLineItemList(),
      total: {
        label: planName,
        type: "final",
        amount: order_summary.onetime_total_fee
      }
    };
    // Create ApplePaySession
    const session = new window.ApplePaySession(versionNumber, request);

    session.onvalidatemerchant = event => {
      // Call  server to request a new merchant session.
      dispatch(
        applePayMerchantValidation(event.validationURL, (response, error) => {
          if (!error) {
            session.completeMerchantValidation(response);
          }
        })
      );
    };

    session.onpaymentauthorized = event => {
      // Define ApplePayPaymentAuthorizationResult
      let paymentStatus = null;
      const payloadWithPaymentMethod = {
        ...paymentsPayload,
        token,
        payment_methods: {
          code: configData?.code,
          channel_code: configData?.channel_code,
          additional_data: {
            brandToken: event.payment.token
          }
        }
      };
      dispatch(
        postPaymentRequest(payloadWithPaymentMethod, (_, error) => {
          if (!error) {
            paymentStatus = window.ApplePaySession.STATUS_SUCCESS;
            gotoUrl(
              paths.PAYMENT_STATUS.replace(":channel", getChannelFromUrl())
            );
          } else {
            paymentStatus = window.ApplePaySession.STATUS_FAILURE;
          }
          session.completePayment(paymentStatus);
          window.showConfirmation();
        })
      );
    };
    session.begin();
  };

  useEffect(() => {
    if (window.ApplePaySession && window.ApplePaySession.canMakePayments()) {
      setApplePayAvailability(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !availableApplePay ? null : (
    <Flex justifyContent="space-between" alignItems="center">
      <Button
        id="ApplePayButton"
        data-testid="apple-pay-icon"
        style={login_btn_config}
        onClick={onApplePayButtonClicked}
      />
      <Box width={["36px"]} mx={1}>
        {<ChevronRight size="30px" color={"#333333"} />}
      </Box>
    </Flex>
  );
};

export default ApplePay;
