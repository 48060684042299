import React, { useEffect } from "react";
import { Box, Text } from "ui-components";
import get from "lodash/get";
import startsWith from "lodash/startsWith";
import isFunction from "lodash/isFunction";
import Spinner, { styles } from "components/Spinner";
import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import { setAddress, getChannelFromUrl } from "utils/helpers";
import { iconThemes } from "ui-components/theme";

const AcyenStorePicker = ({
  change,
  formValues,
  data,
  cvsDeliverySlotDates,
  themeColors,
  fetchDeliverySlots
}) => {
  const acyenIFrameUrl = get(formValues, "acyenIFrameUrl");
  const acyenStoreDetails = get(formValues, "acyenStoreDetails", {});
  const deliverySlotFullData = get(head(cvsDeliverySlotDates), "value");
  const deliverySlotId = get(head(cvsDeliverySlotDates), "slotId");
  const deliverySlot = get(head(cvsDeliverySlotDates), "deliverySlot");
  const deliverySlotLabel = get(head(cvsDeliverySlotDates), "label");
  const borderColor = get(themeColors, "cta", iconThemes.blue);

  const updateAddress = storeDetails => {
    if (isFunction(change) && !isEmpty(storeDetails)) {
      const { yamatoStoreAdd, yamatoStoreName } = storeDetails;
      change("acyenStoreDetails", storeDetails);
      yamatoStoreAdd &&
        setAddress(change, "delivery", yamatoStoreAdd, false, data, () => {
          change("delivery_street_name", yamatoStoreAdd);
          change("delivery_hse_blk_tower", yamatoStoreName);
          change("delivery_slot_full_data", deliverySlotFullData);
          change("delivery_slot_id", deliverySlotId);
          change("delivery_slot", deliverySlot);
          change("delivery_slot_label", deliverySlotLabel);
        });
    }
  };

  const fetchDeliverySlotsAndUpdateAddress = event => {
    const storeDetails = constructStoreDetails(event);
    if (fetchDeliverySlots) {
      console.debug("storeDetails", storeDetails);
      const zipCode = storeDetails.yamatoStoreZip || "";
      const formattedZipCode = `${zipCode.slice(0, 3)}-${zipCode.slice(
        3,
        zipCode.length
      )}`;
      const params = {
        channel: getChannelFromUrl(),
        postal_code: formattedZipCode,
        identifier: formattedZipCode,
        portin: false,
        // TODO: should we make this dynamic?
        type: "sim-only"
      };
      fetchDeliverySlots(params, (_, errors) => {
        if (!errors) updateAddress(storeDetails);
      });
    } else {
      updateAddress(storeDetails);
    }
  };

  useEffect(() => {
    if (acyenIFrameUrl) {
      window.addEventListener(
        "message",
        event => {
          console.log("Window message event", event);
          if (startsWith(acyenIFrameUrl, event.origin)) {
            console.log("Window message event from Iframe", event);
            fetchDeliverySlotsAndUpdateAddress(event);
          }
        },
        false
      );
    } // eslint-disable-next-line
  }, [acyenIFrameUrl]);

  return (
    <>
      <Box variant="borderSection" height={"600px"} sx={{ borderColor }} mb={6}>
        {acyenIFrameUrl ? (
          <iframe
            id="acyen-store-picker"
            src={acyenIFrameUrl}
            style={{ height: "100%", width: "100%" }}
            title="Acyen Store Picker"
          ></iframe>
        ) : (
          <Spinner sx={styles.modalContainer} />
        )}
      </Box>
      {!isEmpty(acyenStoreDetails) && (
        <Box variant="borderSection" sx={{ borderColor }} mb={6}>
          <Text>{acyenStoreDetails.yamatoStoreName}</Text>
          <Text>{acyenStoreDetails.yamatoStoreZip}</Text>
          <Text>{acyenStoreDetails.yamatoStoreAdd}</Text>
          <Text>{deliverySlotFullData}</Text>
        </Box>
      )}
    </>
  );
};

const constructStoreDetails = event => {
  const yamatoStoreAdd = get(event, "data.ADR");
  const yamatoStoreZip = get(event, "data.ZIP");
  const yamatoStoreName = get(event, "data.NM");
  const yamatoCombiniStoreCode = get(event, "data.DCD");
  const storeType = get(event, "data.F");
  const lockerSelected = get(event, "data.LOGO_KB");
  const lockerShippingDate = get(event, "data.SDATE");
  const lockerRequestedDeliveryDate = get(event, "data.RDATE");
  const lockerReservationEndDate = get(event, "data.LDATE");

  const storeDetails = {
    yamatoStoreAdd,
    yamatoStoreZip,
    yamatoStoreName,
    yamatoCombiniStoreCode,
    storeType,
    lockerSelected,
    lockerShippingDate,
    lockerRequestedDeliveryDate,
    lockerReservationEndDate
  };
  return storeDetails;
};

export default AcyenStorePicker;
