import React from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import debounce from "lodash/debounce";
import TextField from "components/FormFields/TextField";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";
import { makeSelectGA4PlanDetails } from "ducks/selectors/googleAnalyticsEvents";

const PortInNumberField = ({ data, portInNumberValidation, ...props }) => {
  const {
    field,
  } = props;
  const GA4EventPlanDetails = useSelector(makeSelectGA4PlanDetails());
  const useContactNumberFormat = get(data, "useContactNumberFormat");
  const mask = data?.mask || get(props, "generic.contact_number.mask", {});

  const onChange = (value) => debouncedSendGA4Analytics(value, field, GA4EventPlanDetails);

  return (
    <TextField
      onChange={onChange}
      inlineValidation={portInNumberValidation}
      {...(useContactNumberFormat && { mask })}
      {...props}
      {...data}
    />
  );
};

PortInNumberField.defaultProps = {
  label: ""
};

export default PortInNumberField;

const debouncedSendGA4Analytics = debounce((value, field, GA4EventPlanDetails) => {
  if (value.length === 11) {
    switch (field) {
      case "port_number":
        GA4EventsService.publish(sendGA4Analytics(GA4EVENTS.MNP_PHONE_NUMBER_SUBMITTED, GA4EventPlanDetails));
        break;
      default:
        break;
    }
  }
}, 1000, { maxWait: 1500 });

const sendGA4Analytics = (event, details = {}) => {
  console.log({ event, details })
  const {
    customer_id,
    plan_name,
    plan_type,
    sim_type,
    order_ref,
    journey_type,
    number_type
  } = details;

  return {
    event,
    ecommerce: {
      customer_id,
      order_ref,
      plan_name,
      journey_type,
      plan_type,
      sim_type,
      number_type
    }
  }
};