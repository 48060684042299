import React, { useEffect, useState } from "react";
import { Box } from "ui-components";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import Spinner, { styles } from "components/Spinner";
import "./styles.css";

const PaymentBox = ({ payment }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = get(payment, "token", "");
    const originkey = get(payment, "origin", "");
    if (token && originkey) {
      const circlesPaymentConfig = {
        rootElementId: "payment-box",
        header: false,
        footer: false,
        locale: "en-US",
        environment: "test",
        token,
        originkey
      };

      const initPayment = setInterval(() => {
        if (
          loading &&
          isFunction(window.CirclesPayment) &&
          isFunction(window.AdyenCheckout)
        ) {
          console.log("Init CirclesPayment");
          clearInterval(initPayment);
          setLoading(false);
          window.CirclesPayment(circlesPaymentConfig);
        }
      }, 1000);

      if (!loading) {
        clearInterval(initPayment);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  return (
    <Box className={loading ? "loading" : ""} id="payment-box">
      {loading && <Spinner sx={styles.modalContainer} />}
    </Box>
  );
};

export default PaymentBox;
