import React, { memo, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { compose } from "redux";
import get from "lodash/get";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import validate from "validations";
import SmartRender from "containers/SmartRender";
import {
  CONTACT_NUM_OTP_MODAL,
  DATA_ONLY_CHANNERL,
  EMAIL_OTP_MODAL,
  forms
} from "utils/constants";
import OTPModal from "components/OTPModal";
import {
  clearUIData,
  dataOnlyContactNumVerification,
  dataOnlyUserRegistration,
  OMS,
  selectPlan,
  setData,
  UserService
} from "ducks/actions";
import { buttonThemes } from "ui-components/theme";
import { getDeviceId, removeTimerKey } from "utils/localStorage";
import { isMobile, OTP_ERROR_MAP } from "utils/helpers";
import {
  makeSelectCountryCodeSelector,
  makeSelectDateOptions,
  makeSelectMonthOptions,
  makeSelectPageValidations,
  makeSelectThemeColors,
  makeSelectYearOptions,
  makeSelectDataOnlyAuthIds,
  makeSelectIsEmailVerified,
  makeSelectIsEmailOTPModalView,
  makeSelectIsContactNumOTPModalView,
  makeSelectDataOnlyContactNumber,
  makeSelectDataOnlyRegisterEmail,
  makeSelectUserRegistrationInitialValues,
  makeSelectIsContactNumVerified,
  makeSelectDataOnlyCreateOrderPayload,
  makeSelectDataOnlyMinimumAge,
  makeSelectEligiblePlanRequestChannel
} from "ducks/selectors";
import head from "lodash/head";
import isEmpty from "lodash/isEmpty";
import Alert from "react-s-alert";

const emailTimerKey = "email_otp";
const smsTimerKey = "sms_otp";

const UserRegistration = Component =>
  function Comp(props) {
    const {
      showEmailOTPModal,
      showContactNumberOTPModal,
      hideModal,
      openEmailOTPModal,
      openContactNumOTPModal,
      themeColors,
      orderParams,
      hasVerifiedEmail,
      hasVerifiedContactNumber,
      storedEligiblePlanChannel,
      fetchEligiblePlans,
      selectPlan,
      change,
      locale
    } = props;

    const buttonTheme = get(themeColors, "button", buttonThemes.povodarkgray);
    const email = useSelector(makeSelectDataOnlyRegisterEmail());
    const contactNumber = useSelector(makeSelectDataOnlyContactNumber());
    const countryCode = useSelector(makeSelectCountryCodeSelector());
    const authIds = useSelector(makeSelectDataOnlyAuthIds());
    const dispatch = useDispatch();
    const [emailOTPErrorMessage, setEmailOTPErrorMessage] = useState(null);
    const [contactNumOTPErrorMessage, setContactNumOTPErrorMessage] = useState(
      null
    );

    const resendEmailOTP = timerKey => {
      timerKey && removeTimerKey(timerKey);
      requestEmailOTP(null, true);
    };

    const resendContactNumOTP = timerKey => {
      timerKey && removeTimerKey(timerKey);
      requestContactNumberOTP(null, true);
    };

    const requestEmailOTP = (event, isResendOTP) => {
      setEmailOTPErrorMessage(null);
      const deviceId = getDeviceId();
      const bodyParams = {
        email,
        device_id: deviceId
      };

      const onSuccess = (_, errors) => {
        if (!errors && !isResendOTP) {
          showEmailOTPModal();
        }
      };
      dispatch(UserService.V4.dataOnlyEmailRegistration(bodyParams, onSuccess));
    };

    const requestContactNumberOTP = () => {
      setContactNumOTPErrorMessage(null);
      const bodyParams = {
        request_type: "PLAN_USER_VERIFY_MOBILE",
        auth_mode: "ENHANCED_SMS",
        isd_code: countryCode?.toString(),
        phone_no: contactNumber
      };

      const onSuccess = (_, errors) => {
        if (!errors) {
          showContactNumberOTPModal();
        }
      };
      dispatch(UserService.V4.contactNumberValidation(bodyParams, onSuccess));
    };

    const validateEmail = (_, { current_otp }) => {
      const bodyParams = {
        device: {
          app_type: "ecosystem",
          device_id: getDeviceId(),
          device_type: isMobile ? "Mobile" : "web"
        },
        email_auth: {
          otp_code: current_otp,
          auth_id: authIds?.email
        }
      };
      const onSuccess = (authResponse, errors) => {
        let emailVerified = false;
        if (!errors) {
          emailVerified = true;
          hideModal();
        } else {
          const error_locale =
            OTP_ERROR_MAP[authResponse.code] || OTP_ERROR_MAP.generic;
          setEmailOTPErrorMessage(error_locale);
        }
        dispatch(
          dataOnlyUserRegistration({
            email_verified: emailVerified,
            email
          })
        );
      };
      dispatch(UserService.V4.dataOnlyValidateEmailOTP(bodyParams, onSuccess));
    };

    const validateContactNumberOTP = (_, { current_otp }) => {
      const bodyParams = {
        otp: current_otp,
        auth_id: authIds.contact_number
      };
      const onSuccess = (response, errors) => {
        let contactNoVerified = false;
        if (!errors) {
          contactNoVerified = true;
          hideModal();
        } else {
          const error_locale =
            OTP_ERROR_MAP[response.code] || OTP_ERROR_MAP.generic;
          setContactNumOTPErrorMessage(error_locale);
        }
        dispatch(
          dataOnlyContactNumVerification({
            contact_num_verified: contactNoVerified,
            contact_number: contactNumber
          })
        );
      };
      dispatch(UserService.V4.validateContactNumberOTP(bodyParams, onSuccess));
    };

    const validateAddressParam = (
      billing_detail = {},
      mandatoryFields = ["city", "state", "street_name"]
    ) => {
      let isValidAddressData = true;
      mandatoryFields.every(field => {
        if (
          isEmpty(billing_detail) ||
          !billing_detail[field] ||
          billing_detail[field] === ""
        ) {
          isValidAddressData = false;
          return false;
        }
        return true;
      });
      return isValidAddressData;
    };

    const createDataOnlyOrder = navigate => {
      //if there is no valida address data APi call is not trigger
      if (!validateAddressParam(orderParams?.billing_detail)) {
        Alert.error(
          get(locale, "select_valid_address", "Some thing went wrong")
        );
        return;
      }

      const placeOrder = orderAPIParams => {
        const onSuccess = (_, errors) => {
          if (!errors) {
            navigate();
          }
        };
        dispatch(OMS.V4.createDataOnlyOrder(orderAPIParams, onSuccess));
      };

      if (storedEligiblePlanChannel !== DATA_ONLY_CHANNERL) {
        const params = { channel: DATA_ONLY_CHANNERL };
        fetchEligiblePlans(params, (response, error) => {
          if (!error) {
            const selectedPlan = head(response);
            selectPlan(selectedPlan);
            orderParams.selected_planid = selectedPlan?.id;
            placeOrder(orderParams);
          }
        });
      } else {
        placeOrder(orderParams);
      }
    };

    const renderOTPModal = (data = {}) => {
      const {
        modalSubtitle,
        headerValues,
        onSubmit,
        showModal,
        requestResendOTP,
        timerKey,
        errorMessage
      } = data;

      return (
        <OTPModal
          modal_title="title_verify_code"
          modal_subtitle={modalSubtitle}
          showModal={showModal}
          showCloseButton={true}
          closeModal={hideModal}
          buttonTheme={buttonTheme}
          hideOtpResend={true}
          enableBackButton={false}
          styleCloseBtn={{ color: "#000000" }}
          info_message={"otp_help_faq"}
          headerStyles={{
            alignItems: "center",
            textAlign: "center",
            padding: "0.5rem",
            width: "100%",
            marginBottom: "2rem",
            overflowWrap: "anywhere"
          }}
          headerValues={headerValues}
          otpBoxes={{
            size: 6,
            onSubmit: onSubmit,
            buttonColor: buttonTheme,
            instruction: "otp_code",
            label: "continue",
            timerKey: timerKey,
            buttonWidth: ["100%", "60%"],
            timer: {
              label: "expiring_in",
              durationInSeconds: 120,
              enableResendLinkInSeconds: 60,
              resendLinkCallback: null
            },
            errorMessage: errorMessage
          }}
          resendOTPLink={{
            resend: requestResendOTP,
            isDisable: false
          }}
        />
      );
    };

    const otpModalDetails = {
      email: {
        modalSubtitle: "verify_account_by_otp_instructions",
        headerValues: { email },
        showModal: openEmailOTPModal,
        timerKey: emailTimerKey,
        onSubmit: validateEmail,
        requestResendOTP: resendEmailOTP,
        errorMessage: emailOTPErrorMessage
      },
      contact_number: {
        modalSubtitle: "verify_contact_no_by_otp_instructions",
        headerValues: { contact_number: contactNumber },
        showModal: openContactNumOTPModal,
        timerKey: smsTimerKey,
        onSubmit: validateContactNumberOTP,
        requestResendOTP: resendContactNumOTP,
        errorMessage: contactNumOTPErrorMessage
      }
    };

    const resetEmailAndContactNumber = () => {
      change("email", "");
      change("contact_number", "");
      dispatch(clearUIData(["dataOnlyUserRegistration"]));
    };

    const resetContactNumber = () => {
      change("contact_number", "");
      dispatch(
        dataOnlyContactNumVerification({
          contact_num_verified: false,
          contact_number: ""
        })
      );
    };

    return (
      <>
        <Component
          {...props}
          clickTextFieldActionBtn={requestEmailOTP}
          clickPhoneFieldActionBtn={requestContactNumberOTP}
          createDataOnlyOrder={createDataOnlyOrder}
          showTextFieldSuccessMessage={hasVerifiedEmail}
          showPhoneFieldSuccessMessage={hasVerifiedContactNumber}
          resetEmailAndContactNumber={resetEmailAndContactNumber}
          resetContactNumber={resetContactNumber}
        />
        {renderOTPModal(otpModalDetails.email)}
        {renderOTPModal(otpModalDetails.contact_number)}
      </>
    );
  };

const mapStateToProps = createStructuredSelector({
  formValidations: makeSelectPageValidations(
    forms.DATA_ONLY_USER_REGISTRATION_FORM
  ),
  initialValues: makeSelectUserRegistrationInitialValues(),
  formValues: makeSelectUserRegistrationInitialValues(),
  monthOptions: makeSelectMonthOptions(),
  dateOptions: makeSelectDateOptions(),
  yearOptions: makeSelectYearOptions(),
  themeColors: makeSelectThemeColors(),
  orderParams: makeSelectDataOnlyCreateOrderPayload(),
  openEmailOTPModal: makeSelectIsEmailOTPModalView(),
  openContactNumOTPModal: makeSelectIsContactNumOTPModalView(),
  hasVerifiedEmail: makeSelectIsEmailVerified(),
  hasVerifiedContactNumber: makeSelectIsContactNumVerified(),
  minimumAge: makeSelectDataOnlyMinimumAge(),
  storedEligiblePlanChannel: makeSelectEligiblePlanRequestChannel()
});

export const mapDispatchToProps = dispatch => {
  return {
    showEmailOTPModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: EMAIL_OTP_MODAL }
        })
      );
    },
    showContactNumberOTPModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: CONTACT_NUM_OTP_MODAL }
        })
      );
    },
    hideModal: () => {
      removeTimerKey(emailTimerKey);
      removeTimerKey(smsTimerKey);
      dispatch(
        setData({ key: "isModalView", data: { isModalView: false, type: "" } })
      );
    },
    fetchEligiblePlans: (params, callBack) => {
      dispatch(OMS.V4.fetchEligiblePlans(params, callBack));
    },
    selectPlan: plan => dispatch(selectPlan(plan))
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  reduxForm({
    form: forms.DATA_ONLY_USER_REGISTRATION_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(UserRegistration(SmartRender));
