import { flex } from "styled-system";
import get from "lodash/get";
import { PRIMARY_COLOR } from "utils/constants";

export const ROBOTO = "'Roboto', sans-serif";
const CURSOR_POINTER = { cursor: "pointer" };
const SOLID_BORDER = { borderStyle: "solid" };

export const COLORS = {
  black: "#000",
  white: "#fff",
  gray: ["#CBD2D9", "#7c7c7c", "#555555", "#333333", "#D8D8D8", "#767778"],
  povodarkgray: [
    "#D6D6D6",
    "#ADADAD",
    "#858585",
    "#5C5C5C",
    "#333333",
    "#222222"
  ],
  povoyellow: [
    "#FFFDCC",
    "#FFFB99",
    "#FFF966",
    "#FFF733",
    "#FFF500",
    "#FFE500"
  ],
  navy: [
    "#CCB8E3",
    "#998AC7",
    "#665CAB",
    "#332E8F",
    "#000073",
    "#000067",
    "#00005E",
    "#000056",
    "#000049"
  ],
  blue: [
    "#CCCCFF",
    "#9999FF",
    "#6666FF",
    "#3333FF",
    "#0000FF",
    "#0000CC",
    "#0099FF"
  ],
  cyan: ["#EEFFFF", "#CCFFFF", "#99FFFF", "#59FFFF", "#00FFFF", "#00CCCC"],
  purple: ["#E7CCF8", "#CF99F1", "#66FFFF", "#9F33E3", "#8700DC", "#8700AA"],
  magenta: ["#FFCCFF", "#FF99FF", "#FF66FF", "#FF4DFF", "#FF00FF", "#CC00CC"],
  red: ["#FFCCCC", "#FF0046", "#CC0046", "#FEDEDE", "#EBCCCC"],
  green: [
    "#CCFFCC",
    "#00CC00",
    "#009900",
    "#DFF0D8",
    "#D0E9C6",
    "#3C763D",
    "#64C8A0"
  ],
  gold: ["#FFFAD2", "#D4AF37", "#D48837"],
  silver: ["#E0E0E0", "#808080", "#606060", "#FCFCFC"]
};

export const iconThemes = {
  black: COLORS.black,
  white: COLORS.white,
  gray: COLORS.gray[1],
  navy: COLORS.navy[4],
  blue: COLORS.blue[4],
  cyan: COLORS.cyan[4],
  purple: COLORS.purple[4],
  magenta: COLORS.magenta[4],
  red: COLORS.red[1],
  green: COLORS.green[1],
  gold: COLORS.gold[1],
  silver: COLORS.silver[1],
  povoyellow: COLORS.povoyellow[4],
  povodarkgray: COLORS.povodarkgray[4]
};

export const buttonThemes = {
  blue: {
    color: COLORS.blue[4],
    hoverColor: COLORS.blue[5],
    backgroundColor: COLORS.blue[0]
  },
  navy: {
    color: COLORS.navy[4],
    hoverColor: COLORS.navy[5],
    backgroundColor: COLORS.navy[0]
  },
  purple: {
    color: COLORS.purple[4],
    hoverColor: COLORS.purple[5],
    backgroundColor: COLORS.purple[0]
  },
  gold: {
    color: COLORS.gold[1],
    hoverColor: COLORS.gold[2],
    backgroundColor: COLORS.gold[0]
  },
  silver: {
    color: COLORS.silver[1],
    hoverColor: COLORS.silver[2],
    backgroundColor: COLORS.silver[0]
  },
  black: {
    color: COLORS.black,
    hoverColor: COLORS.gray[1],
    backgroundColor: COLORS.gray[0]
  },
  cyan: {
    color: COLORS.cyan[4],
    hoverColor: COLORS.cyan[5],
    backgroundColor: COLORS.cyan[0]
  },
  magenta: {
    color: COLORS.magenta[4],
    hoverColor: COLORS.magenta[5],
    backgroundColor: COLORS.magenta[0]
  },
  red: {
    color: COLORS.red[1],
    hoverColor: COLORS.red[2],
    backgroundColor: COLORS.red[0]
  },
  green: {
    color: COLORS.green[1],
    hoverColor: COLORS.green[2],
    backgroundColor: COLORS.green[0]
  },
  povoyellow: {
    color: COLORS.povoyellow[4],
    hoverColor: COLORS.povoyellow[5],
    backgroundColor: COLORS.povoyellow[0]
  },
  povodarkgray: {
    color: COLORS.povodarkgray[4],
    hoverColor: COLORS.povodarkgray[5],
    backgroundColor: COLORS.povodarkgray[0]
  }
};

export const baseTheme = {
  breakpoints: ["40em", "52em", "64em"],
  fontSizes: [12, 14, 16, 18, 20, 24, 30],
  space: [0, 4, 8, 12, 16, 20, 24, 28, 32],
  radii: [0, 4, 8, 12],
  colors: COLORS
};
const heading = {
  fontFamily: "heading",
  lineHeight: "heading",
  fontWeight: "heading"
};

const baseInputStyles = {
  backgroundColor: COLORS.white,
  outline: 0,
  fontFamily: ROBOTO,
  fontSize: baseTheme.fontSizes[2],
  p: 3,
  color: COLORS.gray[3],
  borderRadius: baseTheme.radii[0],
  borderWidth: "0 0 1px 0",
  borderColor: COLORS.gray[0],
  boxShadow: "0 0 0 #CBD2D9 inset;",
  "&:disabled": {
    opacity: 0.4
  },
  "&:focus": {
    borderColor: PRIMARY_COLOR || COLORS.blue[4],
    borderWidth: "0 0 2px 0"
  },
  "::placeholder, ::-webkit-input-placeholder": {
    color: COLORS.gray[0]
  },
  ":-ms-input-placeholder": {
    color: COLORS.gray[0]
  },
  "&.valid": {
    fontWeight: "bold",
    color: COLORS.green[1],
    borderColor: COLORS.green[1],
    borderWidth: "0 0 2px 0"
  },
  "&.invalid": {
    fontWeight: "bold",
    color: COLORS.red[1],
    borderColor: COLORS.red[1],
    borderWidth: "0 0 2px 0"
  }
};

export const selectOptionStyles = {
  control: {
    minHeight: 42,
    fontSize: baseTheme.fontSizes[2],
    boxShadow: "0 0 0 #CBD2D9 inset;",
    borderRadius: baseTheme.radii[0],
    borderColor: COLORS.gray[0],
    borderWidth: "0 0 1px 0"
  },
  option: {
    padding: "1rem",
    minHeight: 42,
    fontSize: baseTheme.fontSizes[2]
  },
  dropdownIndicator: {
    color: COLORS.blue[4]
  }
};

const theme = {
  ...baseTheme,
  svg: {
    margin: 0
  },
  fonts: {
    body: ROBOTO,
    heading: ROBOTO
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125
  },
  text: {
    heading,
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.1em"
    }
  },
  variants: {
    dark: {
      backgroundColor: COLORS.blue[2],
      borderColor: COLORS.blue[2],
      color: COLORS.white,
      a: {
        color: COLORS.cyan[4],
        textDecoration: "none",
        fontWeight: "bold",
        "&:hover": {
          color: COLORS.cyan[2]
        },
        "&:visited": {
          color: COLORS.cyan[4]
        }
      },
      borderWidth: 0,
      borderRadius: baseTheme.radii[1],
      ...SOLID_BORDER
    },
    light: {
      backgroundColor: COLORS.cyan[0],
      borderColor: COLORS.cyan[0],
      color: COLORS.gray[3],
      a: {
        color: COLORS.blue[4],
        textDecoration: "none",
        fontWeight: "bold",
        "&:hover": {
          color: COLORS.blue[5]
        },
        "&:visited": {
          color: COLORS.blue[3]
        }
      },
      borderWidth: 0,
      borderRadius: baseTheme.radii[1],
      ...SOLID_BORDER
    },
    main: {
      borderRadius: [0, 2],
      bg: "white",
      p: 6,
      boxShadow: "0 2px 8px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06)",
      "label a": {
        color: COLORS.blue[4],
        textDecoration: "none",
        "&:hover": {
          color: COLORS.blue[4]
        },
        "&:visited": {
          color: COLORS.blue[4]
        }
      },
      ".page-title > span > span": {
        color: COLORS.blue[4]
      },
      ul: {
        marginLeft: "1rem",
        listStyle: "disc inside"
      },
      ol: {
        marginLeft: "1rem",
        listStyle: "decimal inside"
      },
      ".__react_component_tooltip.show": {
        opacity: 1
      }
    },
    transparent: {
      bg: "transparent",
      boxShadow: "none"
    },
    banner: {
      borderRadius: [0, 2],
      bg: "transparent",
      p: 0,
      img: {
        borderRadius: [0, 2]
      }
    },
    curvedBanner: {
      borderRadius: [3],
      bg: "transparent",
      p: 0,
      img: {
        borderRadius: [3]
      },
      boxShadow: "2px 2px 4px 0 rgba(0,0,0,0.3)"
    },
    roundedBanner: {
      borderRadius: [1],
      bg: "transparent",
      p: 0,
      img: {
        borderRadius: [1]
      },
      boxShadow: "2px 2px 4px 0 rgba(0,0,0,0.3)"
    },
    paymentLogo: {
      borderWidth: "1px",
      borderColor: COLORS.gray[1],
      borderRadius: "18px",
      width: "36px",
      height: "36px",
      display: flex,
      bg: COLORS.gray[4]
    },
    borderSection: {
      ...SOLID_BORDER,
      padding: [3, 4],
      boxShadow: "2px 2px 4px 0 rgba(0,0,0,0.3)",
      borderWidth: "3px",
      borderRadius: baseTheme.radii[3],
      borderColor: COLORS.blue[4],
      color: COLORS.blue[4],
      svg: {
        fill: COLORS.blue[4]
      },
      img: {
        borderRadius: [1]
      }
    },
    inlineMessage: {
      float: "right",
      marginRight: "1rem",
      marginTop: "-2rem",
      fontWeight: "500",
      "&.valid": {
        color: COLORS.green[1]
      },
      "&.invalid": {
        color: COLORS.red[1]
      }
    },
    staticContentBoxError: {
      backgroundColor: COLORS.red[3],
      borderColor: COLORS.red[4],
      color: COLORS.red[2]
    },
    staticContentBoxSuccess: {
      backgroundColor: COLORS.green[3],
      borderColor: COLORS.green[4],
      color: COLORS.green[5]
    },
    faqs: {
      borderRadius: [0, 2],
      bg: "white",
      p: 0,
      mt: "3rem",
      boxShadow: "0 2px 8px 0 rgba(0,0,0,.1), 0 1px 2px 0 rgba(0,0,0,.06)",
      lineHeight: "1.5",
      ".faq-item a": {
        fontWeight: "bold",
        color: COLORS.blue[4],
        textDecoration: "none",
        "&:hover": {
          color: COLORS.blue[4]
        },
        "&:visited": {
          color: COLORS.blue[4]
        }
      },
      ".page-title > span > span": {
        color: COLORS.purple[3]
      },
      "svg > path:nth-of-type(2)": {
        fill: COLORS.purple[3]
      },
      ".faq-item": {
        alignItems: "top",
        margin: "auto",
        marginBottom: "1rem",
        backgroundColor: "white",
        borderRadius: 0,
        flexDirection: "column",
        padding: "0",
        justifySelf: "center",
        boxShadow: "0 4px 12px 0 rgba(0,0,0,0.1)",
        width: ["100%", "calc(100% - 3rem)"],
        ".see-more-icon": {
          cursor: "pointer",
          fontWeight: "bold",
          fontSize: "12px"
        },
        ul: {
          marginLeft: "1rem",
          padding: "1rem",
          listStyle: "disc"
        },
        ol: {
          marginLeft: "1rem",
          padding: "1rem",
          listStyle: "decimal"
        }
      }
    },
    stickyFooter: {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      zIndex: 1000,
      padding: "0.75rem",
      height: "77px",
      marginTop: "-77px",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: COLORS.white,
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 5px 3px",
      ".summary": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        padding: "0 0.5rem"
      },
      ".summary-title": {
        fontFamily: ROBOTO,
        fontSize: "1rem",
        marginBottom: "4px"
      },
      ".summary-label": {
        fontWeight: 700,
        fontFamily: ROBOTO,
        fontSize: ["1.4rem", "1.5rem", "1.6rem"],
        color: COLORS.blue[4]
      },
      ".summary-message": {
        fontFamily: ROBOTO,
        fontSize: "0.8rem",
        marginBottom: "4px"
      },
      svg: {
        margin: "auto 0"
      }
    },
    stickyFooterBar: {
      position: "fixed",
      bottom: 0,
      left: 0,
      width: "100%",
      zIndex: 1000,
      padding: "1.5rem",
      height: "140px",
      marginTop: "-77px",
      display: "list-item",
      justifyContent: "space-between",
      backgroundColor: COLORS.white,
      boxShadow: "rgba(0, 0, 0, 0.2) 0px 4px 5px 3px",
      svg: {
        margin: "auto 0"
      }
    }
  },
  buttons: {
    primary: {
      ...CURSOR_POINTER,
      fontFamily: ROBOTO,
      fontWeight: 900,
      padding: "0.75rem 1rem",
      color: COLORS.white,
      backgroundColor: COLORS.blue[4],
      borderColor: COLORS.blue[4],
      borderWidth: 2,
      borderRadius: 48,
      ...SOLID_BORDER,
      "&:hover": {
        backgroundColor: COLORS.blue[5],
        borderColor: COLORS.blue[5]
      },
      ":disabled": {
        pointerEvents: "none",
        cursor: "not-allowed",
        opacity: 0.4
      }
    },
    secondary: {
      variant: "buttons.primary",
      backgroundColor: COLORS.white,
      borderColor: COLORS.white,
      color: COLORS.blue[4],
      "&:hover": {
        backgroundColor: COLORS.blue[0],
        borderColor: COLORS.blue[5]
      }
    },
    outline: {
      variant: "buttons.primary",
      backgroundColor: COLORS.white,
      borderColor: COLORS.blue[4],
      color: COLORS.blue[4],
      "&:hover": {
        backgroundColor: COLORS.blue[0]
      }
    },
    link: {
      variant: "buttons.primary",
      backgroundColor: "transparent",
      borderColor: "transparent",
      color: COLORS.blue[4],
      textDecoration: "underline",
      fontWeight: "normal",
      "&:hover": {
        backgroundColor: "transparent",
        borderColor: "transparent"
      }
    },
    closeButton: {
      float: "right",
      margin: "0 !important",
      fontWeight: "500",
      fontSize: "1.5rem",
      color: COLORS.red[1],
      padding: "0 !important",
      width: "auto",
      minWidth: "0 !important",
      borderWidth: "0"
    },
    icon: {
      padding: "0 !important",
      width: "auto",
      minWidth: "0 !important",
      borderWidth: "0",
      margin: "auto 0 !important"
    },
    headerButton: {
      variant: "buttons.outline",
      borderColor: COLORS.white,
      color: COLORS.blue[4],
      width: "fit-content",
      borderRadius: "0 !important",
      padding: "0.5rem !important",
      minWidth: "0 !important",
      "&:hover": {
        borderColor: COLORS.white,
        color: COLORS.blue[6]
      }
    },
    sideMenuLink: {
      backgroundColor: COLORS.gray[3],
      color: COLORS.white,
      fontWeight: "500",
      fontSize: "1.2rem",
      borderRadius: "0 !important",
      margin: "0 !important",
      "&:hover": {
        color: COLORS.blue[6]
      }
    }
  },
  textSizes: {
    xs: {
      fontSize: baseTheme.fontSizes[0]
    },
    sm: {
      fontSize: baseTheme.fontSizes[1]
    },
    lg: {
      fontSize: baseTheme.fontSizes[2]
    },
    xl: {
      fontSize: baseTheme.fontSizes[3]
    }
  },
  buttonSizes: {
    small: {
      fontSize: baseTheme.fontSizes[2],
      padding: "4px 18px"
    },
    large: {
      fontSize: baseTheme.fontSizes[4],
      padding: "12px 48px"
    }
  },
  forms: {
    input: baseInputStyles,
    select: baseInputStyles,
    textarea: {
      resize: "vertical"
    },
    label: {
      fontSize: "1rem",
      mb: 1
    },
    radio: {},
    checkbox: {
      svg: {
        fill: COLORS.blue[4]
      }
    }
  }
};

export const getButtonColorVariant = (
  buttonTheme,
  variant = "primary",
  isButtonOfflineMode
) => {
  let style = get(theme, `variants.buttons.${variant}`, {});
  const color = get(buttonTheme, "color", COLORS.blue[4]);
  const darkColor = get(buttonTheme, "hoverColor", COLORS.blue[5]);
  const lightColor = get(buttonTheme, "backgroundColor", COLORS.blue[0]);
  switch (variant) {
    case "primary":
      style = {
        ...style,
        color: COLORS.white,
        backgroundColor: color,
        borderColor: color,
        "&:hover": {
          backgroundColor: darkColor,
          borderColor: darkColor
        }
      };
      break;
    case "secondary":
      style = {
        ...style,
        color: color,
        backgroundColor: lightColor,
        borderColor: color,
        "&:hover": {
          backgroundColor: lightColor,
          borderColor: darkColor
        }
      };
      break;
    case "outline":
      style = {
        ...style,
        backgroundColor: "transparent",
        borderColor: color,
        color: color,
        "&:hover": {
          backgroundColor: lightColor,
          borderColor: color
        }
      };
      break;
    case "link":
      style = {
        ...style,
        variant: "buttons.primary",
        backgroundColor: "transparent",
        borderColor: "transparent",
        color: color,
        textDecoration: "underline",
        fontWeight: "normal",
        "&:hover": {
          backgroundColor: "transparent",
          borderColor: "transparent"
        }
      };
      break;
    case "headerButton":
      style = {
        ...style,
        color: color,
        "&:hover": {
          color: darkColor
        }
      };
      break;
    default:
      break;
  }
  if (isButtonOfflineMode) {
    style.opacity = "0.2";
  }
  return style;
};

export default theme;
