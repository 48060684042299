const styles = {
  selectedFlex: {
    border: "1px solid black",
    borderRadius: "12px",
    minHeight: "8rem"
  },
  selectedFlexButton: {
    backgroundColor: "#222222",
    width: "-webkit-fill-available",
    marginTop: "11px"
  },
  unSelectedFlex: {
    border: "1px solid #D6D6D6",
    borderRadius: "12px",
    minHeight: "8rem"
  },
  unSelectedFlexButton: {
    backgroundColor: "white",
    width: "-webkit-fill-available",
    marginTop: "11px"
  },
  selectedButtonColors: {
    color: "white"
  }
};

export default styles;
