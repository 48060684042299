import React from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import NumberSelection from "components/NumberSelection";

const GOLDEN_NUMBER_SELECTION = ({
  goldenNumbers,
  goldenNumbersPrice,
  selectNumber,
  specialNumberAllowed,
  formValues,
  data,
  themeColors
}) =>
  !get(formValues, "hideNumberSelection") &&
  !isEmpty(goldenNumbers) &&
  specialNumberAllowed ? (
    <NumberSelection
      items={goldenNumbers}
      price={goldenNumbersPrice}
      onSelect={selectNumber}
      themeColors={themeColors}
      {...data}
    />
  ) : null;

export default GOLDEN_NUMBER_SELECTION;
