import get from "lodash/get";
import { forms } from "utils/constants";
import { createSelector } from "reselect";

const esimSetupFormDomain = state =>
  get(state, `form.${forms.E_SIM_SETUP_FORM}.values`, {});

export const selectedESimDownloadOptionSelector = createSelector(
  esimSetupFormDomain,
  data => get(data, "esim_setup")
);

export const makeSelectESimDownloadOption = () =>
  selectedESimDownloadOptionSelector;
