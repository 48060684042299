import React from "react";
import map from "lodash/map";
import { Box, Text } from "ui-components";
import { ROBOTO } from "ui-components/theme";
import FormattedMessage from "components/FormattedMessage";
import Accordion from "react-tiny-accordion";
import { sanitizeHTML } from "utils/helpers";

const FAQ = ({ title, items }) => {
  return (
    <Accordion>
      <div
        data-header={
          <Box
            sx={{
              p: 4,
              borderWidth: 1,
              borderStyle: "solid",
              borderColor: "gray.0",
              cursor: "pointer"
            }}
          >
            <FormattedMessage id={title}>
              {msg => (
                <Text size="lg" fontFamily={ROBOTO} fontWeight={500}>
                  {msg}
                </Text>
              )}
            </FormattedMessage>
          </Box>
        }
      >
        <Box
          sx={{
            p: 4,
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "gray.0"
          }}
        >
          {map(items, ({ title, description }, index) => {
            return (
              <Box data-testid="faq-item" mb={6} key={`faq-${index}`}>
                <Text
                  fontFamily={ROBOTO}
                  fontWeight={500}
                  mb={1}
                  dangerouslySetInnerHTML={{ __html: sanitizeHTML(title) }}
                />
                <Text
                  size="sm"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHTML(description)
                  }}
                />
              </Box>
            );
          })}
        </Box>
      </div>
    </Accordion>
  );
};

FAQ.defaultProps = {
  title: "most_asked_questions",
  items: []
};

export default FAQ;
