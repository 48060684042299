import React from "react";
import { Flex, Text } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import { sanitizeHTML } from "utils/helpers";

const StaticTooltip = ({ content, mode, position, testid }) => {
  return (
    <Flex p="4" mb="4" variant={mode} data-testid={testid}>
      <FormattedMessage html id={content}>
        {messageContent => (
          <Text
            fontWeight="500"
            size="sm"
            dangerouslySetInnerHTML={{ __html: sanitizeHTML(messageContent) }}
          />
        )}
      </FormattedMessage>
    </Flex>
  );
};

StaticTooltip.defaultProps = {
  mode: "light",
  content: "",
  position: "bottom"
};

export default StaticTooltip;
