import React, { useState } from "react";
import FormattedMessage from "components/FormattedMessage";
import { Field, FieldArray } from "redux-form";
import styled from "@emotion/styled";
import { Box, Input, Text, Flex, MaskedInput, Button } from "ui-components";
import Label from "./Label";
import FieldError from "./FieldError";
import Spinner, { styles } from "components/Spinner";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
import {
  Close,
  EmoticonHappyOutline,
  EmoticonSadOutline
} from "components/Icons";
import { COLORS } from "ui-components/theme";
import { handleRelatedTargetAfterBlur } from "utils/helpers";

const IntoTipContainer = styled(Flex)`
  font-size: 12px;
  border-radius: 8px;
  padding: 20px 30px 20px 20px;
  position: relative;
  margin-top: 10px;

  > .close-btn {
    position: absolute;
    right: 7px;
    top: 5px;
    cursor: pointer;
  }
`;

const AddButton = styled(Button)`
  border-radius: 8px;
  border: none;
  display: flex;
`;

const renderExtraField = ({
  fields,
  meta: { error },
  formValues,
  buttonlabel,
  placeholder
}) => (
  <Box>
    {fields.map((domain, index) => (
      <Field
        key={index}
        name={domain}
        type="text"
        component={RenderField}
        formValues={formValues}
        // label={`${index + 2}`}
        placeholder={placeholder}
      />
    ))}
    {error && <li className="error">{error}</li>}
    <AddButton variant="outline" onClick={() => fields.push()}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="16px"
        viewBox="0 0 24 24"
        width="16px"
        fill="#0000FF"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11h-4v4h-2v-4H7v-2h4V7h2v4h4v2z" />
      </svg>
      <span>Add Email Domain</span>
      {/* <FormattedMessage id={buttonlabel}/> */}
    </AddButton>
  </Box>
);

const RenderField = ({
  tooltip,
  infoTip,
  input,
  label,
  type,
  required,
  optional,
  placeholder,
  minLength,
  maxLength,
  onEnter,
  onBlur,
  readOnly,
  inputStyle,
  meta: { asyncValidating, touched, error },
  inlineValidation = {},
  formValues,
  labelMessage,
  mask,
  themeColors
}) => {
  const [showInfoTip, setShowInfoTip] = useState(
    get(infoTip, "visibleByDefault", false)
  );
  const {
    inlineMessage: inlineValidationMessage,
    valid: inlineValid,
    loading: inlineValidationLoading
  } = inlineValidation;
  const infoTipBackground = get(
    infoTip,
    "background",
    themeColors?.info || COLORS.cyan[0]
  );
  const textColor = get(themeColors, "text", COLORS.gray[3]);
  const infoTipBackgroundStyles = get(infoTip, "backgroundStyles", {});

  const onKeyDown = e => {
    if (get(e, "key") === "Enter" && isFunction(onEnter)) {
      try {
        // eslint-disable-next-line no-unused-expressions
        e?.target?.blur();
      } catch (error) {
        console.log("Error Blurring input", e);
      }
      onEnter();
    }
  };

  const handleOnBlur = (event, newValue, previousValue, name) => {
    if (isFunction(onBlur)) {
      onBlur(event, newValue, previousValue, name);
    } else if (isFunction(input.onBlur)) {
      input.onBlur(event, newValue, previousValue, name);
    }
    handleRelatedTargetAfterBlur(event);
  };

  const onInfoIconClick = () => {
    setShowInfoTip(!showInfoTip);
  };

  const inputRenderer = msg => {
    const InputElement = !isEmpty(mask) ? MaskedInput : Input;
    const { onChange: inputOnChange, ...otherInputProps } = input;

    return (
      <InputElement
        style={inputStyle}
        {...otherInputProps}
        {...(msg && { placeholder: msg })}
        type={type}
        disabled={readOnly}
        autoComplete="off"
        minLength={minLength}
        maxLength={maxLength}
        onKeyDown={onKeyDown}
        onBlur={handleOnBlur}
        {...(!isEmpty(mask)
          ? { onAccept: inputOnChange, ...mask }
          : { onChange: inputOnChange })}
      />
    );
  };

  return (
    <>
      {label && (
        <Label
          label={<FormattedMessage id={label} />}
          tooltip={tooltip}
          infoTip={infoTip}
          formValues={formValues}
          required={required}
          optional={optional}
          labelMessage={labelMessage}
          messageData={formValues}
          onBlur={handleOnBlur}
          onInfoIconClick={onInfoIconClick}
          themeColors={themeColors}
        />
      )}

      {placeholder ? (
        <FormattedMessage id={placeholder}>
          {msg => inputRenderer(msg)}
        </FormattedMessage>
      ) : (
        inputRenderer()
      )}
      {
        <Flex
          variant="inlineMessage"
          className={inlineValid ? "valid" : "invalid"}
          alignItems="center"
        >
          {asyncValidating || inlineValidationLoading ? (
            <Spinner sx={styles.inlineMessage} />
          ) : (
            <>
              <Text color="inherit">{inlineValidationMessage}&nbsp;</Text>
              {inlineValid === true && (
                <EmoticonHappyOutline color={themeColors?.success} />
              )}
              {inlineValid === false && (
                <EmoticonSadOutline color={themeColors?.error} />
              )}
            </>
          )}
        </Flex>
      }

      <FieldError touched={touched} error={error} />

      {isPlainObject(infoTip) && !isEmpty(infoTip) && (
        <IntoTipContainer
          style={{
            backgroundColor: infoTipBackground,
            display: showInfoTip ? "block" : "none",
            ...infoTipBackgroundStyles
          }}
        >
          <div className="close-btn" onClick={onInfoIconClick}>
            <Close size="17px" color={textColor} />
          </div>
          {get(infoTip, "message", "")}
        </IntoTipContainer>
      )}
    </>
  );
};

const MultiTextField = ({
  field,
  type,
  pattern,
  label,
  tooltip,
  infoTip,
  placeholder,
  onChange,
  onEnter,
  onBlur,
  normalize,
  readOnly,
  hidden,
  required,
  disabled,
  optional,
  inlineValidation,
  minLength,
  maxLength,
  sx,
  inputStyle,
  formValues,
  mask,
  themeColors,
  buttonlabel,
  style = {}
}) => {
  if (!field) {
    return null;
  }

  const inputType = type === "number" ? "text" : type;
  const inputPattern = type === "number" && !pattern ? "[0-9]" : pattern;

  return (
    <Box mb={8} hidden={hidden} data-testid={field} sx={sx} style={style}>
      <Field
        readOnly={readOnly || disabled}
        name={field}
        required={required}
        optional={optional}
        component={RenderField}
        type={inputType}
        pattern={inputPattern}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        onEnter={onEnter}
        onBlur={onBlur}
        normalize={normalize}
        tooltip={tooltip}
        infoTip={infoTip}
        formValues={formValues}
        inlineValidation={inlineValidation}
        inputStyle={inputStyle}
        minLength={minLength}
        maxLength={maxLength}
        mask={mask}
        themeColors={themeColors}
        buttonlabel={buttonlabel}
      />
      <FieldArray
        name="email_domains"
        placeholder={placeholder}
        buttonlabel={buttonlabel}
        component={renderExtraField}
      />
    </Box>
  );
};

MultiTextField.defaultProps = {
  type: "text"
};

export default MultiTextField;
