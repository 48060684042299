import React, { useEffect, useState } from "react";
import SmartRender from "containers/SmartRender";
import { loadScript } from "utils/helpers";

const WebSDKPlaygroundHOC = Component =>
  function Comp(props) {
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const initWebSDK = () => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const clientId = params.get("clientid");
      const deviceId = params.get("deviceId") || "sample-" + Date.now();

      window.povowebsdk.init("root", { clientId, deviceId });
    };

    useEffect(() => {
      loadScript(
        "websdk",
        "/websdk/openapiwebsdk.main.4ecd06b65bd7cd9d8980.js",
        () => {
          setScriptLoaded(true);
        }
      );
    }, []);

    return (
      <Component
        {...props}
        initWebSDK={initWebSDK}
        scriptLoaded={scriptLoaded}
      />
    );
  };

export default WebSDKPlaygroundHOC(SmartRender);
