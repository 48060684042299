import React, { useState, useRef } from "react";
import ReactModal from "react-modal";
import SignatureCanvas from "react-signature-canvas";
import moment from "moment";
import FormattedMessage from "components/FormattedMessage";
import { Box, Button, Flex, H2, Text } from "ui-components";
import DropIdentity from "components/DropIdentity";
import styles from "./styles";

const ESignCard = props => {
  const { title, description, toggle_modal_button_text } = props;
  const signatureCanvasRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [signature, setSignature] = useState(null);
  const [signedAndConfirmed, setSignedAndConfirmed] = useState(false);

  return (
    <>
      <Flex flexWrap="wrap" mb={4}>
        <H2 mb={4}>
          <FormattedMessage html id={title} />
        </H2>

        <Text mb={4}>
          <FormattedMessage html id={description} />
        </Text>

        {!signedAndConfirmed && (
          <Box margin="auto">
            <Button
              onClick={() => setShowModal(true)}
              data-testid="esign-agree-button"
            >
              <Text color="white">
                <FormattedMessage id={toggle_modal_button_text} />
              </Text>
            </Button>
          </Box>
        )}
      </Flex>

      {signedAndConfirmed &&
        signature &&
        renderSignedBox({
          ...props,
          signatureCanvasRef,
          signature,
          setSignature,
          showModal,
          setShowModal,
          resetSignatureAndOpenModal,
          signedAndConfirmed,
          setSignedAndConfirmed
        })}

      {renderModal({
        ...props,
        signatureCanvasRef,
        signature,
        setSignature,
        showModal,
        setShowModal,
        resetSignatureAndOpenModal,
        signedAndConfirmed,
        setSignedAndConfirmed
      })}
    </>
  );
};

const renderModal = props => {
  const {
    onUpload,
    confirm_button_text,
    disclaimer_text,
    sign_here_text,
    upload_signature_image_text,
    signatureCanvasRef,
    signature,
    setSignature,
    showModal,
    setShowModal,
    resetSignatureAndOpenModal,
    setSignedAndConfirmed
  } = props;

  const handleSubmit = ({ fileUploadResult }) => {
    onUpload(fileUploadResult || signature);
    setShowModal(false);
    setSignedAndConfirmed(true);
  };

  const handleFileUpload = ({ fileUploadResult }) => {
    setSignature(fileUploadResult);
    handleSubmit({ fileUploadResult });
  };

  const dropIdentityProps = {
    testid: "esign_image_uploader",
    label: upload_signature_image_text,
    onDrop: (_type, _frontOrBack, _file, result) =>
      handleFileUpload({ fileUploadResult: result })
  };

  const today = moment(new Date()).format("MM/DD/YYYY");

  return (
    <ReactModal style={styles.modalStyle} isOpen={showModal}>
      <Flex flexWrap="wrap" flexDirection="row" mb={4} alignItems="center">
        <SignatureCanvas
          ref={signatureCanvasRef}
          onEnd={() =>
            setSignature(getESignBase64ImageFromCanvas(signatureCanvasRef))
          }
          penColor="black"
          canvasProps={{ style: styles.canvasStyle }}
          backgroundColor="white"
        />

        <DropIdentity {...dropIdentityProps} />
      </Flex>

      <Text textAlign="center" mb={2}>
        <FormattedMessage id={sign_here_text} />
      </Text>

      <Flex
        justifyContent="space-between"
        sx={{
          borderTop: "1px dashed #B2B2B2",
          mb: 4,
          pt: 2
        }}
      >
        <Box>
          <Text>
            <FormattedMessage id="date" />: {today}
          </Text>
        </Box>
        <Box>
          <Text
            data-testid="esign-clear-button"
            onClick={() =>
              resetSignatureAndOpenModal({
                signatureCanvasRef,
                setSignature,
                setShowModal,
                setSignedAndConfirmed
              })
            }
            color="blue.4"
            sx={{ cursor: "pointer" }}
          >
            <FormattedMessage id="clear" />
          </Text>
        </Box>
      </Flex>

      <Flex mb={4}>
        <Button
          margin="auto"
          disabled={!signature}
          onClick={handleSubmit}
          data-testid="esign-confirm-button"
        >
          <Text color="white">
            <FormattedMessage id={confirm_button_text} />
          </Text>
        </Button>
      </Flex>

      <Text lineHeight={1.4}>
        <FormattedMessage html id={disclaimer_text} />
      </Text>
    </ReactModal>
  );
};

const renderSignedBox = props => {
  const {
    resetSignatureAndOpenModal,
    setShowModal,
    setSignature,
    setSignedAndConfirmed,
    signature,
    sign_here_text,
    signatureCanvasRef
  } = props;
  return (
    <Box>
      <Text size="xs">
        <FormattedMessage id={sign_here_text} />
      </Text>

      <Box sx={styles.signedBoxStyle}>
        <img src={signature} alt="eSignature" style={styles.signatureStyle} />
      </Box>

      <Text
        onClick={() =>
          resetSignatureAndOpenModal({
            signatureCanvasRef,
            setSignature,
            setShowModal,
            setSignedAndConfirmed
          })
        }
        color="blue.4"
        sx={{ curźor: "pointer", textAlign: "right" }}
      >
        <FormattedMessage id="clear" />
      </Text>
    </Box>
  );
};

const getESignBase64ImageFromCanvas = signatureCanvasRef => {
  return signatureCanvasRef.current.getTrimmedCanvas().toDataURL("image/png");
};

const resetSignatureAndOpenModal = ({
  signatureCanvasRef,
  setSignature,
  setShowModal,
  setSignedAndConfirmed
}) => {
  if (signatureCanvasRef.current) signatureCanvasRef.current.clear();
  setSignature(null);
  setSignedAndConfirmed(false);
  setShowModal(true);
};

export default ESignCard;
