import React from "react";
import { Box } from "ui-components";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";
import concat from "lodash/concat";
import { isMobile, safeEval } from "utils/helpers";
import { getLocaleOrDefault } from "utils/localStorage";

const Banner = props => {
  const { type, value, data } = props;
  const { style, boxVariant, boxPadding, boxMarginBottom } = data || {};
  const bannerTypes = get(data, "bannerTypes") || [];
  const types = get(props, "types") || [];
  const typeValues = get(props, "typeValues") || {};
  const device = isMobile() ? "mobile" : "desktop";
  const locale = getLocaleOrDefault();

  // bannerTypeSet specifies the order of priority for showing banners
  const bannerTypeSet = new Set(concat(bannerTypes, types));
  if (type) {
    bannerTypeSet.add(type);
    if (value) {
      typeValues[type] = value;
    }
  }

  let src, alt;
  for (const type of bannerTypeSet) {
    const value = get(typeValues, type);
    src = getTypeBannerSrc({ data, type, value, props, device, locale });
    // Choose the first available banner and ignore the rest
    if (src) {
      alt = `${type}-${value}-Banner`;
      break;
    }
  }
  src = src || get(data, "src");
  alt = alt || get(data, "alt") || "Banner";

  return isEmpty(src) ? null : (
    <Box
      variant={boxVariant || "roundedBanner"}
      padding={boxPadding || "0"}
      mb={boxMarginBottom || 4}
      mx={"0.5rem"}
      sx={{ borderRadius: [1, 1] }}
      data-testid={alt}
    >
      <img
        data-testid={`${alt}-image`}
        alt={alt}
        src={src}
        style={{ ...style, borderRadius: "4px" }}
        width="100%"
        height="auto"
      />
    </Box>
  );
};

const getTypeBannerSrc = ({ data, type, value, props, device, locale }) => {
  const typeBanners = get(data, type);
  const banners = isPlainObject(typeBanners)
    ? typeBanners
    : typeBanners
    ? safeEval(get, {}, typeBanners, {}, props)
    : {};
  return value ? get(banners, [value, locale, device]) : "";
};

Banner.defaultProps = {
  type: "",
  value: "",
  types: [],
  typeValues: {},
  data: {},
  locale: {},
  appSettings: {}
};

export default Banner;
