import React, { useEffect } from "react";
import map from "lodash/map";
import FormattedMessage from "components/FormattedMessage";
import { Field } from "redux-form";
import { Flex, Box, Text, Label as RebassLabel } from "ui-components";
import Label from "./Label";
import { RadioboxBlank, RadioboxMarked } from "components/Icons";
import FieldError from "./FieldError";
import get from "lodash/get";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import StaticTooltip from "../StaticTooltip";

const Radio = props => {
  return <input {...props} type="radio" />;
};

const RadioOptions = props => {
  const {
    input,
    meta: { touched, error },
    options,
    render,
    width,
    iconColor,
    disabled,
    boxStyle,
    hidden,
    readonly,
    autoSelectFirstOption,
    autoSelectOnlyOption
  } = props;

  useEffect(() => {
    const defaultOption = find(options, "default");
    const autoSelect =
      hidden || disabled || readonly || autoSelectFirstOption || defaultOption;
    const autoSelectOnly = autoSelectOnlyOption && options.length === 1;
    const autoSelectable = options.length && isEmpty(input.value);
    const firstOption = head(options);
    const selectedOption =
      !isEmpty(defaultOption) && !autoSelectFirstOption
        ? defaultOption
        : firstOption;
    const selectedValue = get(selectedOption, "value");
    if ((autoSelect || autoSelectOnly) && autoSelectable) {
      input.onChange(selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const selectedOption = find(
    options,
    o => o.value.toString() === input.value.toString()
  );
  const selectedMessageId = get(selectedOption, "selected.message");
  const selectedMessagePosition = get(selectedOption, "selected.position");
  const boxBorder = boxStyle ? "borderSection" : "";
  const radioButtonBoxStyle = boxStyle
    ? {
        color: iconColor,
        borderColor: iconColor,
        mb: "2rem"
      }
    : {};
  return (
    <>
      <Flex flexWrap="wrap">
        {map(options, o => {
          const checked = o.value.toString() === input.value.toString();

          return (
            <Box
              variant={boxBorder}
              sx={radioButtonBoxStyle}
              key={o.value}
              alignItems="center"
              width={width}
            >
              {boxStyle ? (
                <Text
                  style={{
                    wordBreak: "break-word",
                    padding: "0.5rem 1rem",
                    fontWeight: "100"
                  }}
                >
                  {render ? (
                    render(o)
                  ) : o.header ? (
                    <FormattedMessage id={o.header} />
                  ) : null}
                </Text>
              ) : null}

              <RebassLabel
                my={2}
                style={{
                  cursor: disabled ? "default" : "pointer",
                  opacity: disabled && 0.4
                }}
              >
                <Radio
                  {...input}
                  value={o.value}
                  checked={checked}
                  disabled={disabled || readonly}
                />
                <Box width="28px">
                  {checked ? (
                    <RadioboxMarked
                      size="28px"
                      style={{ margin: 0 }}
                      color={iconColor}
                    />
                  ) : (
                    <RadioboxBlank
                      size="28px"
                      style={{ margin: 0 }}
                      color={iconColor}
                    />
                  )}
                </Box>

                <Text
                  style={{
                    wordBreak: "break-word",
                    padding: "0.5rem 1rem",
                    fontWeight: "500"
                  }}
                >
                  {render ? render(o) : <FormattedMessage id={o.label} />}
                </Text>
              </RebassLabel>
            </Box>
          );
        })}
      </Flex>
      {selectedMessageId && (
        <StaticTooltip
          content={selectedMessageId}
          position={selectedMessagePosition}
        />
      )}
      <Box>
        <FieldError touched={touched} error={error} />
      </Box>
    </>
  );
};

const RadioGroup = props => {
  const {
    children,
    options,
    field,
    label,
    required,
    render,
    width,
    themeColors,
    disable_on_select,
    messageData,
    boxStyle,
    disabled,
    hidden,
    readonly,
    autoSelectOnlyOption,
    autoSelectFirstOption
  } = props;
  const iconColor = get(themeColors, "icon");
  const isDisabled =
    disabled ||
    readonly ||
    (disable_on_select && get(messageData, "telcoType") ? true : false);

  if (!field) {
    return null;
  }

  return (
    <Box mb={8} hidden={hidden} data-testid={field}>
      {label && (
        <Label label={<FormattedMessage id={label} />} required={required} />
      )}
      <Field
        component={RadioOptions}
        name={field}
        required={required}
        options={options}
        render={render}
        width={width}
        iconColor={iconColor}
        boxStyle={boxStyle}
        disabled={isDisabled}
        hidden={hidden}
        readonly={readonly}
        autoSelectOnlyOption={autoSelectOnlyOption}
        autoSelectFirstOption={autoSelectFirstOption}
      />
      {children}
    </Box>
  );
};

RadioGroup.defaultProps = {
  width: 1,
  options: [],
  label: ""
};

export default RadioGroup;
