import React, { useState } from "react";
import { Flex, Text } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import get from "lodash/get";
import "./styles.css";

const Tooltip = ({
  mode,
  position,
  tooltipLabel,
  tooltipImageUrl,
  tooltipMessage,
  tooltipId
}) => {
  const [show, setShow] = useState(false);
  const [top, setTop] = useState(0);

  const showTooltip = () => {
    const top = getTop();
    setTop(top);
    setShow(true);
  };

  const hideTooltip = () => {
    setShow(false);
  };

  const toggleTooltip = () => {
    show ? hideTooltip() : showTooltip();
  };

  const getTop = () => {
    const parent = document.getElementById(`${tooltipId}-link`);
    const parentHeight = get(parent, "clientHeight", 0);
    const tooltip = document.getElementById(tooltipId);
    const tooltipHeight = get(tooltip, "clientHeight", 0);
    return position === "top" ? 0 - tooltipHeight - parentHeight : parentHeight;
  };

  return (
    <>
      <Flex
        data-testid="tool-tip"
        id={`${tooltipId}-link`}
        onClick={toggleTooltip}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onBlur={hideTooltip}
        width="fit-content"
      >
        <Text as="a" className="tooltip-link">
          <FormattedMessage id={tooltipLabel} />
        </Text>
      </Flex>
      <Flex
        className="tooltip-container"
        variant={mode}
        sx={{ visibility: show ? "visible" : "hidden" }}
      >
        <Flex
          id={tooltipId}
          className={`tooltip-box tooltip-box-${position}`}
          variant={mode}
          sx={{ top }}
          onClick={hideTooltip}
        >
          {tooltipImageUrl && (
            <img
              className="tooltip-image"
              src={tooltipImageUrl}
              alt={tooltipId}
            />
          )}
          {tooltipMessage && (
            <Flex className="tooltip-message">
              <FormattedMessage id={tooltipMessage} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </>
  );
};

Tooltip.defaultProps = {
  mode: "light",
  content: "",
  position: "top"
};

export default Tooltip;
