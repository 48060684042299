import React, { memo, useEffect } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect, useSelector, useDispatch } from "react-redux";
import validate from "validations";
import moment from "moment";
import { forms } from "utils/constants";
import SmartRender from "containers/SmartRender";
import Spinner, { styles } from "components/Spinner";
import { OMS } from "ducks/actions";
import {
  makeSelectThemeColors,
  makeSelectPageValidations,
  makeSelectGuestOrderOrderRef,
  makeSelectOrderCancellationStatus,
  makeSelectOrderCancellationForm,
  makeSelectIsFetchingData
} from "ducks/selectors";
import { createStructuredSelector } from "reselect";
import get from "lodash/get";

const OrderCancellation = Component =>
  function Comp(props) {
    const dispatch = useDispatch();
    const themeColors = useSelector(makeSelectThemeColors());

    useEffect(() => {
      const payload = {
        order_ref: get(props, "orderRef", "")
      };
      dispatch(OMS.V4.fetchOrderCancellationStatus(payload));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (props.isFetchingDetails)
      return <Spinner size={200} sx={styles.fullPage} />;

    return <Component {...props} themeColors={themeColors} />;
  };

const mapStateToProps = createStructuredSelector({
  formValidations: makeSelectPageValidations(forms.ORDER_CANCELLATION_FORM),
  orderRef: makeSelectGuestOrderOrderRef(),
  orderCancellationStatus: makeSelectOrderCancellationStatus(),
  formValues: makeSelectOrderCancellationForm(),
  isFetchingDetails: makeSelectIsFetchingData([
    "fetchOrderCancellationStatus",
    "submitOrderCancellation"
  ])
});
export const mapDispatchToProps = dispatch => {
  return {
    cancelOrder: (payload, navigate) => {
      dispatch(
        OMS.V4.submitOrderCancellation(payload, (result, errors) => {
          if (!errors) {
            setTimeout(() => {
              navigate && navigate();
            }, 0);
          } else console.log("Order cancellation failed. ", result, errors);
        })
      );
    }
  };
};
export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  cancelWithOMS: navigate => {
    let currentDate = new Date();
    const terminationDate = currentDate.setDate(currentDate.getDate() + 1);
    const { orderCancellationStatus, formValues } = stateProps;
    const requestPayLoad = {
      order_id: get(orderCancellationStatus, "order_id"),
      order_ref: get(stateProps, "orderRef", ""),
      token: "",
      action_type: get(orderCancellationStatus, "state"),
      feedback: "",
      reason: get(formValues, "other_reason", ""),
      winback_code: "",
      winback_id: "",
      termination_date: moment(terminationDate).toISOString(),
      phone_number: get(orderCancellationStatus, "phone_number"),
      winback_description: "",
      primary_id: null
    };

    dispatchProps.cancelOrder(requestPayLoad, navigate);
  }
});
const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.ORDER_CANCELLATION_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(OrderCancellation(SmartRender));
