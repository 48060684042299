import React from "react";
import Addons from "components/Addons";
import PlanDetails from "components/PlanDetails";
import TotalPrice from "components/TotalPrice";
import PromoCodeBenefits from "components/PromoCodeBenefits";
import PhoneDetailsItem from "components/PhoneDetailsItem";
import TaxAmountItem from "components/TaxAmountItem";
import { Box } from "ui-components";
import { iconThemes } from "ui-components/theme";
import get from "lodash/get";
import round from "lodash/round";

const PayMonthlyCard = ({
  addons,
  planDetails,
  payMonthlyItems,
  referralCode,
  data = {},
  themeColors
}) => {
  const { hideTotalPrice, price_label, primaryColor: primary } = data;

  const defaultColor = get(themeColors, "primary") || iconThemes.blue;
  const primaryColor = primary ? iconThemes[primary] || primary : defaultColor;
  const priceColor = get(data, "priceColor", iconThemes.white);
  const iconColor = get(themeColors, "icon") || iconThemes.blue;
  const excludeTax = get(data, "excludeTax");
  const totalAmountWithoutTax = get(
    payMonthlyItems,
    "totalAmountWithoutTax",
    0
  );
  const totalTaxAmount = get(payMonthlyItems, "totalTaxAmount", 0);
  const totalTaxPrice = get(payMonthlyItems, "totalTaxPrice");
  const totalTaxPercent = round(
    (totalTaxAmount / totalAmountWithoutTax) * 100,
    2
  );

  return (
    <Box
      variant="borderSection"
      mb={8}
      padding={[0, 0]}
      paddingBottom={"20px"}
      sx={{ color: primaryColor, borderColor: primaryColor }}
    >
      {!hideTotalPrice && (
        <TotalPrice
          title={get(data, "title", "pay_monthly")}
          {...payMonthlyItems}
          price_label={price_label}
          bgColor={primaryColor}
          priceColor={priceColor}
        />
      )}
      <PlanDetails
        planDetails={planDetails}
        items={get(payMonthlyItems, "items")}
        primaryColor={iconColor}
        {...data}
      />
      <Addons
        addons={addons}
        primaryColor={iconColor}
        excludeTax={excludeTax}
      />
      <PhoneDetailsItem items={get(payMonthlyItems, "items")} />
      <PromoCodeBenefits
        items={get(payMonthlyItems, "items")}
        referralCode={referralCode}
      />
      {excludeTax && totalTaxAmount > 0 && (
        <TaxAmountItem
          taxPrice={totalTaxPrice}
          taxPercent={totalTaxPercent}
          primaryColor={iconColor}
        />
      )}
    </Box>
  );
};

PayMonthlyCard.defaultProps = {
  addons: [],
  planDetails: {},
  payTodayItems: {},
  selectedNumber: {},
  referralCode: {},
  data: {}
};

export default PayMonthlyCard;
