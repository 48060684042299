import { createSelector } from "reselect";
import get from "lodash/get";
import head from "lodash/head";
import { forms } from "utils/constants";

const toppingSelectionFormDomain = state =>
  get(state, `form.${forms.TOPPING_SELECTION_FORM}.values`, {});

export const toppingsSelector = createSelector(
  toppingSelectionFormDomain,
  ToppingSelectionForm => ToppingSelectionForm.topping || []
);

export const selectedToppingLearnMoreSelector = createSelector(
  toppingsSelector,
  selectedToppingsList => {
    const learnMore = head(selectedToppingsList)?.learn_more;
    return (
      learnMore && {
        link: learnMore?.action?.data?.web_view?.link,
        title: learnMore?.title
      }
    );
  }
);

export const selectedToppingTnCs = createSelector(
  toppingsSelector,
  selectedToppingsList => {
    const termsAndConditionsArray = head(selectedToppingsList)?.terms_condition;
    return head(termsAndConditionsArray);
  }
);

export const dataOnlyToppingFormSelector = createSelector(
  toppingSelectionFormDomain,
  toppingDetails => toppingDetails || []
);

export const selectedToppingIdSelector = createSelector(
  toppingSelectionFormDomain,
  ({ topping = [] } = {}) => {
    return topping.length > 0 ? topping[0].product_id : "";
  }
);

export const makeSelectSelectedToppings = () => toppingsSelector;
export const makeSelectSelectedToppingLearnMore = () =>
  selectedToppingLearnMoreSelector;
export const makeSelectSelectedToppingTnCs = () => selectedToppingTnCs;
