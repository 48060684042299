import DETAIL_BOX from "smart-components/DETAIL_BOX";
import HTML_LINK from "smart-components/HTML_LINK";
import STATIC_CONTENT_BOX from "smart-components/STATIC_CONTENT_BOX";
import HR from "smart-components/HR";
import IMAGE from "smart-components/IMAGE";
import IMAGE_WITH_BASE64 from "smart-components/IMAGE_WITH_BASE64";
import TEXT_FIELD from "smart-components/TEXT_FIELD";
import MULTI_TEXT_FIELD from "smart-components/MULTI_TEXT_FIELD";
import CHECKBOX_FIELD from "smart-components/CHECKBOX_FIELD";
import HTML_MESSAGE from "smart-components/HTML_MESSAGE";
import DROPDOWN_FIELD from "smart-components/DROPDOWN_FIELD";
import STEPPER from "smart-components/STEPPER";
import STEPPER_BREAD_CRUMB from "smart-components/STEPPER_BREAD_CRUMB";
import PAGE_HEADER from "smart-components/PAGE_HEADER";
import NOT_ELIGIBLE from "smart-components/NOT_ELIGIBLE";
import PROMO_CODE_BOX from "smart-components/PROMO_CODE_BOX";
import PAY_MONTHLY_CARD from "smart-components/PAY_MONTHLY_CARD";
import PAY_TODAY_CARD from "smart-components/PAY_TODAY_CARD";
import PLAN_SELECTION from "smart-components/PLAN_SELECTION";
import PAY_DEVICE_CARD from "smart-components/PAY_DEVICE_CARD";
import PAY_MONTHLY_DETAILS_CARD from "smart-components/PAY_MONTHLY_DETAILS_CARD";
import PAY_MONTHLY_SUMMARY_CARD from "smart-components/PAY_MONTHLY_SUMMARY_CARD";
import PAY_TODAY_SUMMARY_CARD from "smart-components/PAY_TODAY_SUMMARY_CARD";
import ORDER_SUMMARY_CARD from "smart-components/ORDER_SUMMARY_CARD";
import PLAN_DETAILS_CARD from "smart-components/PLAN_DETAILS_CARD";
import ORDER_DETAILS_CARD from "smart-components/ORDER_DETAILS_CARD";
import ORDER_STEPS_CARD from "smart-components/ORDER_STEPS_CARD";
import ORDER_ACTIONS_CARD from "smart-components/ORDER_ACTIONS_CARD";
import NEXT_STEPS_CARD from "smart-components/NEXT_STEPS_CARD";
import ESIGN_CARD from "smart-components/ESIGN_CARD";
import SPECIAL_ADDON from "smart-components/SPECIAL_ADDON";
import ADDONS_CHECKBOX from "smart-components/ADDONS_CHECKBOX";
import PORT_IN_AGREE_TO_TNC_CHECKBOX from "smart-components/PORT_IN_AGREE_TO_TNC_CHECKBOX";
import ADDONS_DROPDOWN from "smart-components/ADDONS_DROPDOWN";
import STATE_OF_ISSUE from "smart-components/STATE_OF_ISSUE";
import COUNTRY_OF_ISSUE from "smart-components/COUNTRY_OF_ISSUE";
import PAGE_NAVIGATION from "smart-components/PAGE_NAVIGATION";
import QUICK_ORDER_VIEW from "smart-components/QUICK_ORDER_VIEW";
import VIDEO_PLAYER from "smart-components/VIDEO_PLAYER";
import MODAL_BUTTONS from "smart-components/MODAL_BUTTONS";
import PLAN_BANNER from "smart-components/PLAN_BANNER";
import PLAN_FAQ from "smart-components/PLAN_FAQ";
import FAQ_LIST from "smart-components/FAQ_LIST";
import SWITCH_TOGGLE from "smart-components/SWITCH_TOGGLE";
import FIELD_TOGGLE from "smart-components/FIELD_TOGGLE";
import NEW_NUMBER_DISPLAY from "smart-components/NEW_NUMBER_DISPLAY";
import NUMBER_SEARCH from "smart-components/NUMBER_SEARCH";
import HIDE_NUMBER_SELECTION from "smart-components/HIDE_NUMBER_SELECTION";
import GENERAL_NUMBER_SELECTION from "smart-components/GENERAL_NUMBER_SELECTION";
import SILVER_NUMBER_SELECTION from "smart-components/SILVER_NUMBER_SELECTION";
import GOLDEN_NUMBER_SELECTION from "smart-components/GOLDEN_NUMBER_SELECTION";
import ICC_ID_NRIC from "smart-components/ICC_ID_NRIC";
import ICC_ID from "smart-components/ICC_ID";
import POSTAL_CODE from "smart-components/POSTAL_CODE";
import DOI from "smart-components/DOI";
import DOB from "smart-components/DOB";
import CONTACT_NUMBER from "smart-components/CONTACT_NUMBER";
import ALTERNATE_NUMBER from "smart-components/ALTERNATE_NUMBER";
import POST_PAID_NUMBER from "smart-components/POST_PAID_NUMBER";
import PORT_IN_NUMBER from "smart-components/PORT_IN_NUMBER";
import POST_PAID_TELCO_ACCOUNT_NUMBER from "smart-components/POST_PAID_TELCO_ACCOUNT_NUMBER";
import DOC_TYPE from "smart-components/DOC_TYPE";
import UPLOAD_ID_LATER from "smart-components/UPLOAD_ID_LATER";
import BILLING_ADDRESS_SAME_AS_DELIVERY_ADDRESS from "smart-components/BILLING_ADDRESS_SAME_AS_DELIVERY_ADDRESS";
import PRIMARY_ID_UPLOADER from "smart-components/PRIMARY_ID_UPLOADER";
import ID_UPLOADER from "smart-components/ID_UPLOADER";
import CIS_PARTNERS_FORM from "smart-components/CIS_PARTNERS_FORM";
import DELIVERY_ADDRESS_FORM_1 from "smart-components/DELIVERY_ADDRESS_FORM_1";
import DELIVERY_ADDRESS_FORM_2 from "smart-components/DELIVERY_ADDRESS_FORM_2";
import DELIVERY_ADDRESS_FORM_3 from "smart-components/DELIVERY_ADDRESS_FORM_3";
import DELIVERY_ADDRESS_FORM_4 from "smart-components/DELIVERY_ADDRESS_FORM_4";
import DELIVERY_ADDRESS_FORM_5 from "smart-components/DELIVERY_ADDRESS_FORM_5";
import ADDRESS_LOOKUP from "smart-components/ADDRESS_LOOKUP";
import BILLING_ADDRESS_FORM from "smart-components/BILLING_ADDRESS_FORM";
import BILLING_ADDRESS_FORM_2 from "smart-components/BILLING_ADDRESS_FORM_2";
import BILLING_ADDRESS_FORM_3 from "smart-components/BILLING_ADDRESS_FORM_3";
import BILLING_ADDRESS_FORM_5 from "smart-components/BILLING_ADDRESS_FORM_5";
import NATIONALITY_DROPDOWN from "smart-components/NATIONALITY_DROPDOWN";
import RADIO from "smart-components/RADIO";
import VERTICAL_RADIO from "smart-components/VERTICAL_RADIO";
import PREPAID_POSTPAID_RADIO from "smart-components/PREPAID_POSTPAID_RADIO";
import NATIONALITY_RADIO from "smart-components/NATIONALITY_RADIO";
import TELCO_LIST_RADIO from "smart-components/TELCO_LIST_RADIO";
import TELCO_LIST_DROPDOWN from "smart-components/TELCO_LIST_DROPDOWN";
import REGISTERED_UNDER_OWN_NRIC from "smart-components/REGISTERED_UNDER_OWN_NRIC";
import DELIVERY_METHOD_PICKER from "smart-components/DELIVERY_METHOD_PICKER";
import PAYMENT_METHOD_PICKER from "smart-components/PAYMENT_METHOD_PICKER";
import PAYMENT_METHOD_PICKER_2 from "smart-components/PAYMENT_METHOD_PICKER_2";
import PAYMENT_QR_VIEWER from "smart-components/PAYMENT_QR_VIEWER";
import GOOGLE_PREDICT_FIELD from "smart-components/GOOGLE_PREDICT_FIELD";
import ADDRESS_ERROR_HANDLER from "smart-components/ADDRESS_ERROR_HANDLER";
import DELIVERY_DATE_PICKER from "smart-components/DELIVERY_DATE_PICKER";
import FREE_DELIVERY_SLOT_PICKER from "smart-components/FREE_DELIVERY_SLOT_PICKER";
import SPECIAL_DELIVERY_SLOT_PICKER from "smart-components/SPECIAL_DELIVERY_SLOT_PICKER";
import STORE_PICKER_1 from "smart-components/STORE_PICKER_1";
import STORE_PICKER_2 from "smart-components/STORE_PICKER_2";
import SMART_MODAL from "smart-components/SMART_MODAL";
import POP_STATION_PICKER from "smart-components/POP_STATION_PICKER";
import SELF_ACTIVATION_DELIVERY_MODAL from "smart-components/SELF_ACTIVATION_DELIVERY_MODAL";
import SCHEDULE_NUMBER_CHANGE_MODAL from "smart-components/SCHEDULE_NUMBER_CHANGE_MODAL";
import SUSPENSION_START_DATE from "smart-components/SUSPENSION_START_DATE";
import SUSPENSION_END_DATE from "smart-components/SUSPENSION_END_DATE";
import REQUEST_SUSPENSION_MODAL from "smart-components/REQUEST_SUSPENSION_MODAL";
import TEXTAREA_FIELD from "smart-components/TEXTAREA_FIELD";
import ACYEN_STORE_PICKER from "smart-components/ACYEN_STORE_PICKER";
import TERMINATION_REASON from "smart-components/TERMINATION_REASON";
import DELIVERY_FAIL_REASON from "smart-components/DELIVERY_FAIL_REASON";
import TERMINATION_DATE from "smart-components/TERMINATION_DATE";
import TERMS_AND_CONDITIONS from "smart-components/TERMS_AND_CONDITIONS";
import SCROLLABLE_SECTION from "smart-components/SCROLLABLE_SECTION";
import ESIGN_FIELD from "smart-components/ESIGN_FIELD";
import REQUEST_TERMINATION_MODAL from "smart-components/REQUEST_TERMINATION_MODAL";
import TERMINATION_STATUS from "smart-components/TERMINATION_STATUS";
import PAYMENT_BOX from "smart-components/PAYMENT_BOX";
import UPDATE_PAYMENT_CARD from "smart-components/UPDATE_PAYMENT_CARD";
import SHOP_ORDER_SUMMARY from "smart-components/SHOP_ORDER_SUMMARY";
import PAY_LATER_FORM from "smart-components/PAY_LATER_FORM";
import LEGACY_ADYEN_FORM from "smart-components/LEGACY_ADYEN_FORM";
import SOCIAL_MEDIA_SIGNIN_OPTIONS from "smart-components/SOCIAL_MEDIA_SIGNIN_OPTIONS";
import HR_WITH_TEXT from "smart-components/HR_WITH_TEXT";
import INLINE_PAGE_HEADERS from "smart-components/INLINE_PAGE_HEADERS";
import SUBMIT_BUTTON from "smart-components/SUBMIT_BUTTON";
import OTP_BOXES from "smart-components/OTP_BOXES";
import NAVIGATE_LINK from "smart-components/NAVIGATE_LINK";
import PARAGRAPH from "smart-components/PARAGRAPH";
import SIM_COUNT_SELECTOR from "smart-components/SIM_COUNT_SELECTOR";
import ORDER_SUCCESS_VIEW from "smart-components/ORDER_SUCCESS_VIEW";
import PAYMENT_AMAZON_WIDGETS from "smart-components/PAYMENT_AMAZON_WIDGETS";
import PHONE_LIST from "smart-components/PHONE_LIST";
import PHONE_DETAILS from "smart-components/PHONE_DETAILS";
import OUTSTANDING_INVOICE_CARD from "smart-components/OUTSTANDING_INVOICE_CARD";
import OUTSTANDING_PAYMENT_CARD from "smart-components/OUTSTANDING_PAYMENT_CARD";
import SIM_DETAILS_CARD from "smart-components/SIM_DETAILS_CARD";
import CRITICAL_INFO_DETAILS_CARD from "smart-components/CRITICAL_INFO_DETAILS_CARD";
import ESIM_COMPATIBLE_LIST from "smart-components/ESIM_COMPATIBLE_LIST";
import ACCORDION from "smart-components/ACCORDION";
import CLOSE_ICON from "smart-components/CLOSE_ICON";
import payments from "containers/SmartRender/components/payments";
import RECEIVE_MY_PACKAGE from "smart-components/RECEIVE_MY_PACKAGE";
import AUTHORIZATION_FORM from "smart-components/AUTHORIZATION_FORM";
import ORDER_AUTHORIZATION_CARD from "smart-components/ORDER_AUTHORIZATION_CARD";
import PAYMENT_UPDATE_CARD_STRIPE_CHECKOUT from "smart-components/PAYMENT_UPDATE_CARD_STRIPE_CHECKOUT";
import STRIPE_UPDATE_CARD_CHECKOUT from "smart-components/STRIPE_UPDATE_CARD_CHECKOUT";
import PAYMENT_UPFRONT_STRIPE_CHECKOUT from "smart-components/PAYMENT_UPFRONT_STRIPE_CHECKOUT";
import MY_INFO_BOX from "smart-components/MY_INFO_BOX";
import PAYMENT_CARD_DETAILS from "smart-components/PAYMENT_CARD_DETAILS";
import EXTENDED_PLAN_SELECTION from "smart-components/EXTENDED_PLAN_SELECTION";
import PAGE_NAVIGATION_OTP_SECURED from "./PAGE_NAVIGATION_OTP_SECURED";
import PAGE_NAVIGATION_STACK_FOOTER from "../components/PageNavigationStackFooter";
import TC_DETAILS from "./TC_DETAILS";
import RADIO_GROUP_PROVIDERS from "./RADIO_GROUP_PROVIDERS";
import TERMS_AND_CONDITIONS_LIST from "components/TermsAndConditionsList";
import DATA_ADDON_PRICE_TILE from "components/DataAddonSummaryTile";
import TOPPING_TILES from "components/ToppingTile";
import TAB_VIEW from "components/TabView/index";
import PAYMENT_METHOD_RADIO_GROUP from "../components/PaymentMethod";
import HTML_LINK_MESSAGE from "./HTML_LINK_MESSAGE";
import HTML_LINK_WITH_CTA from "./HTML_LINK_WITH_CTA";
import PLAN_TYPE_SELECTION from "components/PlanTypeSelection";
import GOOGLE_ADDRESS_SEARCH from "components/FormFields/GoogleAddressSearch";
import POSTAL_CODE_SEARCH from "components/PostalCodeSearch";
import GROUP_COMPONENT from "./GROUP_COMPONENT";
import COPYABLE_TEXT_VIEW from "./COPYABLE_TEXT_VIEW";
import IFRAME_COMPONENT from "components/IFrame";
import TC_INFO_BOX from "components/TCInfoBox";

const featureMapping = {
  HTML_LINK,
  DETAIL_BOX,
  STATIC_CONTENT_BOX,
  HR,
  IMAGE,
  IMAGE_WITH_BASE64,
  TEXT_FIELD,
  MULTI_TEXT_FIELD,
  CHECKBOX_FIELD,
  HTML_MESSAGE,
  HTML_LINK_MESSAGE,
  HTML_LINK_WITH_CTA,
  DROPDOWN_FIELD,
  STEPPER,
  STEPPER_BREAD_CRUMB,
  PAGE_HEADER,
  NOT_ELIGIBLE,
  PROMO_CODE_BOX,
  PAY_MONTHLY_CARD,
  PAY_TODAY_CARD,
  PLAN_SELECTION,
  PAY_DEVICE_CARD,
  PAY_MONTHLY_DETAILS_CARD,
  PAY_MONTHLY_SUMMARY_CARD,
  PAY_TODAY_SUMMARY_CARD,
  ORDER_SUMMARY_CARD,
  PLAN_DETAILS_CARD,
  ORDER_DETAILS_CARD,
  ORDER_STEPS_CARD,
  ORDER_ACTIONS_CARD,
  NEXT_STEPS_CARD,
  ESIGN_CARD,
  SPECIAL_ADDON,
  ADDONS_CHECKBOX,
  PORT_IN_AGREE_TO_TNC_CHECKBOX,
  ADDONS_DROPDOWN,
  STATE_OF_ISSUE,
  COUNTRY_OF_ISSUE,
  PAGE_NAVIGATION,
  PAGE_NAVIGATION_OTP_SECURED,
  PAGE_NAVIGATION_STACK_FOOTER,
  QUICK_ORDER_VIEW,
  VIDEO_PLAYER,
  MODAL_BUTTONS,
  PLAN_BANNER,
  PLAN_FAQ,
  FAQ_LIST,
  SWITCH_TOGGLE,
  FIELD_TOGGLE,
  NEW_NUMBER_DISPLAY,
  NUMBER_SEARCH,
  HIDE_NUMBER_SELECTION,
  GENERAL_NUMBER_SELECTION,
  SILVER_NUMBER_SELECTION,
  GOLDEN_NUMBER_SELECTION,
  ICC_ID_NRIC,
  ICC_ID,
  POSTAL_CODE,
  DOI,
  DOB,
  CONTACT_NUMBER,
  ALTERNATE_NUMBER,
  POST_PAID_NUMBER,
  PORT_IN_NUMBER,
  POST_PAID_TELCO_ACCOUNT_NUMBER,
  DOC_TYPE,
  UPLOAD_ID_LATER,
  BILLING_ADDRESS_SAME_AS_DELIVERY_ADDRESS,
  PRIMARY_ID_UPLOADER,
  ID_UPLOADER,
  CIS_PARTNERS_FORM,
  DELIVERY_ADDRESS_FORM_1,
  DELIVERY_ADDRESS_FORM_2,
  DELIVERY_ADDRESS_FORM_3,
  DELIVERY_ADDRESS_FORM_4,
  DELIVERY_ADDRESS_FORM_5,
  ADDRESS_LOOKUP,
  BILLING_ADDRESS_FORM,
  BILLING_ADDRESS_FORM_2,
  BILLING_ADDRESS_FORM_3,
  BILLING_ADDRESS_FORM_5,
  NATIONALITY_DROPDOWN,
  RADIO,
  RADIO_GROUP_PROVIDERS,
  VERTICAL_RADIO,
  PREPAID_POSTPAID_RADIO,
  NATIONALITY_RADIO,
  TELCO_LIST_RADIO,
  TELCO_LIST_DROPDOWN,
  REGISTERED_UNDER_OWN_NRIC,
  DELIVERY_METHOD_PICKER,
  PAYMENT_METHOD_PICKER,
  PAYMENT_METHOD_PICKER_2,
  PAYMENT_QR_VIEWER,
  GOOGLE_PREDICT_FIELD,
  ADDRESS_ERROR_HANDLER,
  DELIVERY_DATE_PICKER,
  FREE_DELIVERY_SLOT_PICKER,
  SPECIAL_DELIVERY_SLOT_PICKER,
  STORE_PICKER_1,
  STORE_PICKER_2,
  SMART_MODAL,
  POP_STATION_PICKER,
  SELF_ACTIVATION_DELIVERY_MODAL,
  SCHEDULE_NUMBER_CHANGE_MODAL,
  SUSPENSION_START_DATE,
  SUSPENSION_END_DATE,
  REQUEST_SUSPENSION_MODAL,
  TEXTAREA_FIELD,
  ACYEN_STORE_PICKER,
  TERMINATION_REASON,
  DELIVERY_FAIL_REASON,
  TERMINATION_DATE,
  TERMS_AND_CONDITIONS,
  SCROLLABLE_SECTION,
  ESIGN_FIELD,
  REQUEST_TERMINATION_MODAL,
  TERMINATION_STATUS,
  PAYMENT_BOX,
  UPDATE_PAYMENT_CARD,
  SHOP_ORDER_SUMMARY,
  PAY_LATER_FORM,
  LEGACY_ADYEN_FORM,
  SOCIAL_MEDIA_SIGNIN_OPTIONS,
  HR_WITH_TEXT,
  INLINE_PAGE_HEADERS,
  SUBMIT_BUTTON,
  OTP_BOXES,
  NAVIGATE_LINK,
  PARAGRAPH,
  SIM_COUNT_SELECTOR,
  ORDER_SUCCESS_VIEW,
  PAYMENT_AMAZON_WIDGETS,
  PHONE_LIST,
  PHONE_DETAILS,
  OUTSTANDING_INVOICE_CARD,
  OUTSTANDING_PAYMENT_CARD,
  SIM_DETAILS_CARD,
  CRITICAL_INFO_DETAILS_CARD,
  ESIM_COMPATIBLE_LIST,
  ACCORDION,
  CLOSE_ICON,
  ...payments,
  RECEIVE_MY_PACKAGE,
  AUTHORIZATION_FORM,
  ORDER_AUTHORIZATION_CARD,
  PAYMENT_UPDATE_CARD_STRIPE_CHECKOUT,
  STRIPE_UPDATE_CARD_CHECKOUT,
  PAYMENT_UPFRONT_STRIPE_CHECKOUT,
  MY_INFO_BOX,
  PAYMENT_CARD_DETAILS,
  EXTENDED_PLAN_SELECTION,
  TC_DETAILS,
  PAYMENT_METHOD_RADIO_GROUP,
  GOOGLE_ADDRESS_SEARCH,
  POSTAL_CODE_SEARCH,
  TERMS_AND_CONDITIONS_LIST,
  DATA_ADDON_PRICE_TILE,
  TOPPING_TILES,
  TAB_VIEW,
  PLAN_TYPE_SELECTION,
  GROUP_COMPONENT,
  COPYABLE_TEXT_VIEW,
  IFRAME_COMPONENT,
  TC_INFO_BOX
};

export default featureMapping;
