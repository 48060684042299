import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Flex } from "ui-components";
export { default as styles } from "./styles";

const Spinner = ({ sx }) => {
  return (
    <Flex data-testid="spinner" sx={sx}>
      <ClipLoader sizeUnit="px" size={sx.size} color={sx.color} loading />
    </Flex>
  );
};

Spinner.defaultProps = {
  sx: {}
};

export default Spinner;
