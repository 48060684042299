import React, { useState } from "react";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, Button } from "ui-components";
import { ChevronDown, ChevronUp } from "components/Icons";
import { iconThemes } from "ui-components/theme";
import get from "lodash/get";

/**
 * The component is to provide expand/collapse esim supported devices
 * All the devices and tbalets that are esim supported are provided through application configs.
 * @param {Object} props
 */
const Accordian = props => {
  const { title, content, expandDefault = false, themeColor } = props.data;
  const [showDetails, setShowDetails] = useState(expandDefault);
  const ctaColor =
    get(themeColor, "primary") ||
    get(props.themeColors, "cta", iconThemes.blue);

  const onClick = () => setShowDetails(!showDetails);
  return (
    <Box m={2}>
      <Flex
        data-testid="accordion-expand-onclick"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        onClick={onClick}
      >
        <FormattedMessage html id={title}>
          {msg => <span style={{ color: ctaColor }}>{msg}</span>}
        </FormattedMessage>
        <Button
          variant="closeButton"
          as="a"
          data-testid="accordion-chevron-button"
        >
          {showDetails ? (
            <ChevronUp size={"30px"} color={ctaColor} />
          ) : (
            <ChevronDown size={"30px"} color={ctaColor} />
          )}
        </Button>
      </Flex>
      <hr style={{ paddingTop: "1rem", color: "black" }} />
      {showDetails && (
        <>
          <FormattedMessage html id={content} />
          <div style={{ marginBottom: "3rem" }} />
        </>
      )}
    </Box>
  );
};

export default Accordian;
