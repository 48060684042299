import React from "react";
import get from "lodash/get";
import CheckboxField from "components/FormFields/CheckboxField";
import { constructOnChangeHandler, safeEval } from "utils/helpers";

const CHECKBOX_FIELD = ({ data, ...props }) => {
  const defaultChecked = safeEval(
    get,
    props.formValues,
    get(props, "defaultChecked"),
    false,
    props
  );
  return (
    <CheckboxField
      {...props}
      {...data}
      data={data}
      defaultChecked={defaultChecked}
      onChange={constructOnChangeHandler(props)}
    />
  );
};

export default CHECKBOX_FIELD;
