import React from "react";
import get from "lodash/get";
import TextField from "components/FormFields/TextField";
import { constructOnChangeHandler, safeEval } from "utils/helpers";

const TEXT_FIELD = ({ data, ...props }) => {
  const { disabled, formValues } = props;

  const isDisabled = safeEval(get, formValues, disabled, false, props);

  return (
    <TextField
      {...props}
      {...data}
      onChange={constructOnChangeHandler(props)}
      disabled={isDisabled}
    />
  );
};

export default TEXT_FIELD;
