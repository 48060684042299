import React, { useEffect } from "react";
import Slider from "rc-slider";
import FormattedMessage from "components/FormattedMessage";
import { Field } from "redux-form";
import { Box, Flex } from "ui-components";
import FieldError from "../FormFields/FieldError";
import { iconThemes } from "ui-components/theme";
import Label from "../FormFields/Label";
import isFunction from "lodash/isFunction";
import "./style.css";

const RenderSlider = ({
  input,
  disabled,
  meta: { touched, error },
  min,
  max,
  marks,
  step,
  defaultValue
}) => {
  const defaultVal = defaultValue;

  useEffect(() => {
    input && input.onChange(defaultVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = val => {
    if (isFunction(input.onChange)) {
      input.onChange(val);
    }
  };

  return (
    <Flex
      variant="transparent"
      justifyContent="center"
      p={"1.75rem 1.5rem 0.13rem 1rem"}
    >
      <Slider
        data-testid="slider-bar"
        min={min}
        max={max}
        marks={marks}
        step={step}
        included={false}
        disabled={disabled}
        defaultValue={defaultVal}
        onChange={handleOnChange}
        handleStyle={{
          backgroundColor: iconThemes.blue
        }}
        trackStyle={{}}
        railStyle={{}}
      />
      <FieldError touched={touched} error={error} />
    </Flex>
  );
};

const NumberSlider = props => {
  const {
    // for label component
    label,
    hidden,
    sx,
    formValues,
    labelMessage,
    justifyContent,

    // for field component
    field, // [string] the id of the field.
    min, // [number] The minimum value of the slider.
    max, // [number] The maximum value of the slider.
    marks, // [number] Marks on the slider. The key determines the position, and the value determines what will show.
    step, // [number] Value to be added or subtracted on each step the slider makes. Must be greater than zero, and max - min should be evenly divisible by the step value.
    disabled, // [boolean] If true, handles can't be moved.
    onChange, // [function] onChange will be triggered while the value of Slider changing.
    defaultValue // [number] number 0	Set initial value of slider.
  } = props;

  if (!field) {
    return null;
  }

  return (
    <Box mb={8} hidden={hidden} data-testid={field} sx={sx}>
      <Box>
        {label && (
          <Label
            justifyContent={justifyContent}
            label={<FormattedMessage id={label} />}
            labelMessage={labelMessage}
            messageData={formValues}
          />
        )}
      </Box>
      <Field
        component={RenderSlider}
        onChange={onChange}
        name={field}
        min={min}
        max={max}
        marks={marks}
        step={step}
        disabled={disabled}
        defaultValue={defaultValue}
        hidden={hidden}
      />
    </Box>
  );
};

NumberSlider.defaultProps = {
  min: 1,
  max: 5,
  step: 1,
  marks: {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5
  }
};

export default NumberSlider;
