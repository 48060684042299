import React, { useState } from "react";
import get from "lodash/get";
import * as Icons from "components/Icons";
import { Box, Button, Flex, Text } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import { getButtonColorVariant } from "ui-components/theme";
import { useIntl } from "react-intl";

const CopyableText = props => {
  const { data, themeColors } = props;
  const {
    icon,
    displayValue,
    secondaryDisplayValue,
    value_label,
    btnCopyLabel,
    btnStyles = {},
    containerStyles = {}
  } = data || {};
  const intl = useIntl();
  let copyableText = props[displayValue] || props[secondaryDisplayValue];

  //if the values is coming as a localize message using useIntl convert the message
  if (value_label) {
    copyableText = intl.formatMessage({ id: value_label }, props);
  }

  const NextButtonIconComponent = Icons[icon];
  const buttonTheme = get(themeColors, "button", {});
  const nextButtonVariant = get(data, "variant", "primary");

  const buttonDefaultStyles = getButtonColorVariant(
    buttonTheme,
    nextButtonVariant,
    false
  );

  const [isCopied, setIsCopied] = useState(false);
  const copyBtnText = isCopied ? "text_copied" : btnCopyLabel;

  const handleCopyClick = () => {
    navigator.clipboard.writeText(copyableText);
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <Flex sx={{ "align-items": "baseline" }}>
      <Box
        sx={{
          width: "60%",
          "word-break": "break-word",
          ...containerStyles.label
        }}
      >
        {value_label ? (
          <FormattedMessage id={value_label} values={props} />
        ) : (
          <Text>{copyableText}</Text>
        )}
      </Box>
      <Flex
        style={{
          width: "40%",
          justifyContent: "flex-end",
          ...containerStyles.button
        }}
      >
        <Button
          onClick={handleCopyClick}
          style={{
            ...buttonDefaultStyles,
            ...btnStyles
          }}
        >
          <Flex p={0} alignItems="center" width="100%">
            {NextButtonIconComponent && (
              <Box px={1}>
                <NextButtonIconComponent size={"16px"} color={"#000000"} />
              </Box>
            )}
            <Box mx={"auto"} p={"0.25rem"} pr={"0.5rem"}>
              <FormattedMessage id={copyBtnText} />
            </Box>
          </Flex>
        </Button>
      </Flex>
    </Flex>
  );
};

export default CopyableText;
