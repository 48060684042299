import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Field } from "redux-form";
import map from "lodash/map";
import get from "lodash/get";
import split from "lodash/split";
import moment from "moment";
import { Label, Flex, Box, Button, Text } from "ui-components";
import FieldError from "./FieldError";
import { COLORS, buttonThemes, iconThemes } from "ui-components/theme";
import forEach from "lodash/forEach";
import trim from "lodash/trim";
import Spinner, { styles } from "components/Spinner";
import { STD, SECONDARY_COLOR } from "utils/constants";
import { compactJoin } from "utils/helpers";

const DeliverySlotsSpinnerStyle = {
  ...styles.modalContainer,
  size: 30,
  position: "relative",
  top: -10,
  color: SECONDARY_COLOR
};

const DeliverySlotGroup = props => {
  const {
    options,
    field,
    label,
    required,
    change,
    timings = true,
    special = true,
    columns = [1, 2, 2],
    freeFirst,
    themeColors,
    isLoading,
    twenty_four_hours_format = false
  } = props;
  const name = field || "unnamed_delivery_slot_group";
  const buttonTheme = get(themeColors, "button") || buttonThemes.blue;
  const primaryColor = get(themeColors, "primary") || iconThemes.blue;

  const specialSlots = [];
  const freeSlots = [];
  forEach(options, (option = {}) => {
    option.cost > 0 ? specialSlots.push(option) : freeSlots.push(option);
  });
  const slots = special
    ? freeFirst
      ? freeSlots.concat(specialSlots)
      : specialSlots.concat(freeSlots)
    : freeSlots;

  return isLoading ? (
    <Spinner sx={DeliverySlotsSpinnerStyle} />
  ) : (
    <Box mb={6} data-testid={name}>
      {label && (
        <Label>
          <FormattedMessage id={label} />
          &nbsp;
          {required && (
            <Text as="span" color={COLORS.red[1]}>
              *
            </Text>
          )}
        </Label>
      )}
      <Field
        component={RadioOptions}
        name={name}
        required={required}
        options={slots}
        change={change}
        timings={timings}
        columns={columns}
        buttonTheme={buttonTheme}
        primaryColor={primaryColor}
        twentyFourHoursFormat={twenty_four_hours_format}
      />
    </Box>
  );
};

const RadioOptions = props => {
  const {
    change,
    input,
    meta: { touched, error },
    options,
    timings,
    columns,
    buttonTheme,
    primaryColor,
    twentyFourHoursFormat
  } = props;

  const smallWidth = Number(get(columns, 0) || 1);
  const mediumWidth = Number(get(columns, 1) || 2);
  const largeWidth = Number(get(columns, 2) || 3);
  const responsiveWidth = [1 / smallWidth, 1 / mediumWidth, 1 / largeWidth];

  const slotsSTD = options.filter(slotName => slotName.deliverySlot === STD);
  const withoutSTDSlots = options.filter(
    slotName => slotName.deliverySlot !== STD
  );
  const combineOptions = [...slotsSTD, ...withoutSTDSlots];
  const allOptions = slotsSTD ? combineOptions : options;

  return (
    <>
      <Flex flexWrap="wrap" mx={-1}>
        {map(allOptions, o => {
          const selected = o.value === input.value;
          const buttonStyles = {
            borderRadius: 2,
            fontFamily: "'Roboto', sans-serif !important",
            width: "100%",
            margin: "0 !important",
            padding: "12px",
            backgroundColor: selected ? buttonTheme.backgroundColor : "white",
            color: selected ? buttonTheme.color : "gray.1",
            borderColor: selected ? buttonTheme.color : "#c6c6c6",
            fontWeight: selected ? 700 : 500,
            "&:hover": {
              color: buttonTheme.color,
              backgroundColor: selected ? buttonTheme.backgroundColor : "white",
              borderColor: buttonTheme.color
            }
          };
          let description = o.description;
          if (twentyFourHoursFormat) {
            const convert12hTo24h = time =>
              moment(time, ["h:mm A"]).format("HH:mm");
            const [startTime, endTime] = map(split(description, "-"), trim);
            const startTime24h = startTime && convert12hTo24h(startTime);
            const endTime24h = endTime && convert12hTo24h(endTime);
            description = compactJoin([startTime24h, endTime24h], " - ");
          }

          return (
            <Box key={o.value} sx={{ p: 1 }} width={responsiveWidth}>
              <Button
                type="button"
                onClick={() => {
                  input.onChange(o.value);
                  change("delivery_slot_fee", o.cost);

                  if (input.name === "delivery_date") {
                    change("delivery_slot_full_data", "");
                  }

                  if (o.slotId) {
                    change("delivery_slot_id", o.slotId);
                  }

                  if (o.deliverySlot) {
                    change("delivery_slot", o.deliverySlot);
                  }

                  if (o.label) {
                    change("delivery_slot_label", o.label);
                  }

                  if (o.endDate) {
                    change("delivery_end_date", o.endDate);
                  }
                }}
                sx={{ ...buttonStyles }}
              >
                {o.deliverySlot === STD ? (
                  <Box>
                    <Text size="sm">
                      <FormattedMessage id={"std_label"} />
                    </Text>
                  </Box>
                ) : (
                  <>
                    <Box>
                      <Text size="sm">{o.label}</Text>
                    </Box>

                    {o.description && timings && (
                      <Box mt={1} sx={{ height: "auto" }}>
                        <Text size="xs">{description}</Text>
                      </Box>
                    )}

                    {o.cost > 0 && (
                      <Box mt={1} sx={{ height: "auto" }}>
                        <Text size="xs" color={primaryColor}>
                          <FormattedMessage
                            id={"special_delivery_slot_fee"}
                            values={{ fee: o.formattedCost }}
                          />
                        </Text>
                      </Box>
                    )}
                  </>
                )}
              </Button>
            </Box>
          );
        })}
      </Flex>

      <FieldError touched={touched} error={error} />
    </>
  );
};

DeliverySlotGroup.defaultProps = {
  options: [],
  label: ""
};

export default DeliverySlotGroup;
