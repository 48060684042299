import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import FormattedMessage from "components/FormattedMessage";
import PayLineItem from "./PayLineItem";
import { Flex, H2 } from "ui-components";

const PayItemCard = ({
  title,
  price_label,
  items,
  totalPrice,
  totalFixedPrice,
  totalData
}) => {
  return (
    <>
      <Flex justifyContent="space-between" mb={4}>
        <H2 data-testid="pay-item-card-title">
          <FormattedMessage id={title} />
        </H2>

        <H2>
          {price_label ? (
            <FormattedMessage
              id={price_label}
              values={{ totalPrice, totalFixedPrice, totalData }}
            />
          ) : (
            totalPrice
          )}
        </H2>
      </Flex>

      {map(items, (item, index) => (
        <PayLineItem key={`${item.label}-${index}`} {...item} />
      ))}
    </>
  );
};

PayItemCard.defaultProps = {
  title: "",
  items: []
};

PayItemCard.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired
};

export default PayItemCard;
