import { handleActions } from "redux-actions";
import produce from "immer";
import set from "lodash/set";
import { updateUI, initiateGetTokenCall } from "../actions/uiActions";
import { clearUIData } from "../actions";

const initialState = {
  isGetTokenExecuted: false,
  isGettingToken: false
};

export const uiReducer = handleActions(
  {
    [updateUI]: produce((draftState, { payload: { key, data } }) => {
      set(draftState, `${key}`, data);
    }),
    [initiateGetTokenCall]: produce((draftState, { payload }) => {
      set(draftState, "isGetTokenExecuted", payload);
      set(draftState, "isGettingToken", payload);
    }),
    [clearUIData]: produce((draftState, { payload }) => {
      if (Array.isArray(payload)) {
        payload.forEach(key => {
          set(draftState, key, {});
        });
      } else {
        const key = payload;
        set(draftState, key, {});
      }
    })
  },
  initialState
);
