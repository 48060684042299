import React from "react";
import { Box, Flex, Text } from "ui-components";
import { COLORS, iconThemes } from "ui-components/theme";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import FormattedMessage from "components/FormattedMessage";

const RegistrationFeeItem = ({ payTodayItems = {}, primaryColor }) => {
  const registrationItem = find(payTodayItems.items, { type: "base" });
  return isEmpty(registrationItem) ? null : (
    <Box my={5}>
      <Flex
        py={1}
        justifyContent="space-between"
        alignItems="center"
        px={"1rem"}
      >
        <Box paddingRight={1} justifySelf="flex-start" flex={1}>
          <Text fontWeight="bold" py="4px" color={COLORS.gray[3]}>
            <FormattedMessage id={registrationItem.label} />
          </Text>
        </Box>
        <Box width={["fit-content"]}>
          <Text fontWeight="bold" py="4px" color={primaryColor}>
            {registrationItem.price}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

RegistrationFeeItem.defaultProps = {
  payTodayItems: {},
  primaryColor: iconThemes.blue
};

export default RegistrationFeeItem;
