import React from "react";
import get from "lodash/get";
import NumberSelection from "components/NumberSelection";

const GENERAL_NUMBER_SELECTION = ({
  availableNumbers,
  selectNumber,
  formValues,
  data,
  themeColors
}) =>
  !get(formValues, "hideNumberSelection") ? (
    <NumberSelection
      items={availableNumbers}
      onSelect={selectNumber}
      themeColors={themeColors}
      {...data}
    />
  ) : null;

export default GENERAL_NUMBER_SELECTION;
