/**
 * A custom Hook to trigger non XHR post requests.
 * This is useful when we expect the browser to react for 302 returns
 * @typedef { Object } PostDocumentAPIHookProps
 * @property {String} [formId] - Custom form Id
 *
 * @param {PostDocumentAPIHookProps} props
 * @returns {{ submitPostApi, cleanup }}
 */
const usePostDocumentAPI = (props = {}) => {
  const { formId } = props;

  const HTTP_POST_METHOD = "POST";
  const FORM_ID = formId || "povohiddenform";

  /**
   * @param {Object} postBody body params for post request
   * The body params will be passed as content-type: application/x-www-form-urlencoded
   */
  const createInvisibleForm = (postBody = {}) => {
    let invisibleForm = document.createElement("form");

    // Each body param will be bounded to form as an input element
    for (let key in postBody) {
      let input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = postBody[key];

      invisibleForm.append(input);
    }

    invisibleForm.setAttribute("id", FORM_ID);
    invisibleForm.setAttribute("name", "povo hidden form");
    invisibleForm.setAttribute("style", "display: none !important;");

    return invisibleForm;
  };

  /**
   * Sets the method and action for a provided form element
   * @param {HTMLFormElement} invisibleForm
   * @param {String} url Full URL of the post API request
   */
  const setPostDataAndAppendForm = (invisibleForm, url) => {
    invisibleForm.method = HTTP_POST_METHOD;
    invisibleForm.action = url;

    document.body.appendChild(invisibleForm);
  };

  /**
   * Aggregator method that will be exposed by the Hook
   * @param {String} url Full URL of the post API request
   * @param {Object} bodyParams POST API body params
   */
  const submitPostApiViaHTMLForm = (url, bodyParams) => {
    const invisibleForm = createInvisibleForm(bodyParams);
    setPostDataAndAppendForm(invisibleForm, url);
    invisibleForm.submit();
  };

  /**
   * Remove the first invisible form element found in dom
   */
  const deleteForm = () => document.getElementById(FORM_ID)?.remove();

  return {
    submitPostApi: submitPostApiViaHTMLForm,
    cleanup: deleteForm
  };
};

export default usePostDocumentAPI;
