import React, { memo, useEffect } from "react";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import {
  forms,
  INFO_TIP_NAMECHANGE_URL,
  MANAGE_PROFILE_OTP_MAPPINGS,
  MANAGE_PROFILE_REQUEST_TYPE_MAPPINGS,
  MOBILE_APP_BROWSER_PROTOCOL,
  paths
} from "utils/constants";
import { reduxForm, reset } from "redux-form";
import { createStructuredSelector } from "reselect";
import {
  makeSelectProfileForm,
  makeSelectCurrentOtpRequested,
  makeSelectCurrentOtpVerified,
  makeSelectNewEmailOtpRequested,
  makeSelectWorkflow,
  makeSelectRequestOtpAuthIdNewEmail,
  makeSelectAuthToken,
  makeSelectCountryCodeSelector,
  makeSelectExternalUserId,
  makeSelectIsActiveTelcoUser,
  makeSelectThemeColors,
  makeSelectPageValidations,
  makeSelectGuestOrderOrderRef,
  makeSelectOtpErrorMessage,
  makeSelectUserProfileDetails,
  makeSelectIsFetchingData
} from "ducks/selectors";
import { setData, clearData, updateAuthToken, OMS } from "ducks/actions";
import { Quilt, UserService } from "ducks/actions";

import SmartRender from "containers/SmartRender";
import {
  makeSelectCurrentPhoneNumber,
  makeSelectUserEmail,
  makeSelectRequestOtpAuthId,
  makeSelectEditEmailTimerKey,
  makeSelectIsModalView,
  makeSelectModalType,
  makeSelectzEIdentifyUserData,
  makeSelectLocale,
  makeSelectIsGuestOrderAPIHaveData
} from "ducks/selectors";
import { getDeviceId, isWebview } from "utils/localStorage";
import get from "lodash/get";
import isArray from "lodash/isArray";
import {
  gotoUrl,
  urlParam,
  pathWithChannel,
  loggedInUserDetailsAPIParams,
  updateTokenToApp,
  activateZendeskWidget
} from "utils/helpers";
import Alert from "react-s-alert";
import validate from "validations";
import Spinner, { styles } from "components/Spinner";
import { featureFlagDataOnlyPlanEnabled } from "utils/featureFlags";

const Profile = Component =>
  function Comp(props) {
    const REFRESH_REQUIRED_FLAG = "refresh";
    const refreshRequired = urlParam(REFRESH_REQUIRED_FLAG) || false;
    const { phoneNumber, formValues, isFetchingData, orderRef } = props || {};
    const messageData = {
      phoneNumber,
      email: get(formValues, "new_email"),
      newEmail: get(formValues, "new_email")
    };

    const authToken = useSelector(makeSelectAuthToken());

    useEffect(
      () => {
        if (orderRef) {
          props.getUserProfileDetails();
        }
        if (refreshRequired) {
          props.fetchLoggedInUserDetails();
        }
        if (!props.isGuestOrderAPIHaveData) {
          props.fetchUserOrders();
        }

        return () => props.resetAll();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    const hideModalAndRedirect = () => {
      props.hideModal();

      if (isWebview()) {
        const mobilePage = urlParam("return_url") || paths.CLOSEWEBVIEW;
        const mobileParams = {
          device_id: getDeviceId(),
          auth_token: authToken
        };
        gotoUrl(mobilePage, false, {}, true, mobileParams);
      } else {
        gotoUrl(`${paths.PROFILE}?refresh=true&auth_token=${authToken}`);
      }
    };

    return isFetchingData ? (
      <Spinner size={200} sx={styles.fullPage} />
    ) : (
      <Component
        {...props}
        authToken={authToken}
        messageData={messageData}
        hideModalAndRedirect={hideModalAndRedirect}
      />
    );
  };

const mapStateToProps = createStructuredSelector({
  workflow: makeSelectWorkflow(),
  formValues: makeSelectProfileForm(),
  formValidations: makeSelectPageValidations(forms.PROFILE_FORM),
  locale: makeSelectLocale(),
  currentOtpRequested: makeSelectCurrentOtpRequested(),
  currentOtpVerified: makeSelectCurrentOtpVerified(),
  newEmailOtpRequested: makeSelectNewEmailOtpRequested(),
  phoneNumber: makeSelectCurrentPhoneNumber(),
  loggedInEmail: makeSelectUserEmail(),
  authId: makeSelectRequestOtpAuthId(),
  authIdNewEmailOTPVerified: makeSelectRequestOtpAuthIdNewEmail(),
  userProfileDetails: makeSelectUserProfileDetails(),
  isActiveTelcoUser: makeSelectIsActiveTelcoUser(),
  countryCode: makeSelectCountryCodeSelector(),
  external_id: makeSelectExternalUserId(),
  themeColors: makeSelectThemeColors(),
  orderRef: makeSelectGuestOrderOrderRef(),
  errorMessage: makeSelectOtpErrorMessage(),
  timerKey: makeSelectEditEmailTimerKey(),
  isFetchingData: makeSelectIsFetchingData([
    "userProfileDetails",
    "loggedInUserDetails",
    "userLogout",
    "userOrders"
  ]),
  isModalView: makeSelectIsModalView(),
  modalType: makeSelectModalType(),
  zEIdentify: makeSelectzEIdentifyUserData(),
  isGuestOrderAPIHaveData: makeSelectIsGuestOrderAPIHaveData()
});

export function mapDispatchToProps(dispatch) {
  return {
    setData: payload => {
      dispatch(setData(payload));
    },
    clearData: payload => {
      dispatch(clearData(payload));
    },
    fetchOTPSecured: (stateProps, callback) => {
      if (featureFlagDataOnlyPlanEnabled()) {
        const AUTH_MODE = MANAGE_PROFILE_OTP_MAPPINGS.ENHANCED_SMS;
        const REQUEST_TYPE = MANAGE_PROFILE_REQUEST_TYPE_MAPPINGS.VERIFY_MOBILE;

        const params = {
          auth_mode: AUTH_MODE,
          request_type: REQUEST_TYPE
        };
        dispatch(UserService.V4.fetchOTPSecured(params, callback));
      } else {
        const phoneNumber = stateProps.phoneNumber;
        const AUTH_MODE = MANAGE_PROFILE_OTP_MAPPINGS.ENHANCED_SMS;
        const REQUEST_TYPE = MANAGE_PROFILE_REQUEST_TYPE_MAPPINGS.VERIFY_MOBILE;
        const email = get(stateProps, "userProfileDetails.userEmail");
        const deviceId = getDeviceId();
        const uuid = Date.now();
        const isd_code = `${get(stateProps, "countryCode")}`;

        const params = {
          phone_no: phoneNumber,
          auth_mode: AUTH_MODE,
          email,
          request_type: REQUEST_TYPE,
          device_id: deviceId,
          uuid,
          isd_code
        };
        dispatch(UserService.V4.fetchOTPSecured(params, callback));
      }
    },
    fetchValidateOTP: (stateProps, callback) => {
      const newEmailOTP = get(stateProps, "formValues").new_email_otp.join("");
      const auth_id = get(stateProps, "authIdNewEmailOTPVerified", "");
      const params = {
        auth_id: auth_id,
        otp: newEmailOTP
      };
      dispatch(UserService.V4.fetchValidateOTP(params, callback));
    },
    fetchValidateOTPTrigger: (stateProps, callback) => {
      if (featureFlagDataOnlyPlanEnabled()) {
        const AUTH_MODE = MANAGE_PROFILE_OTP_MAPPINGS.ENHANCED_EMAIL_OTP;
        const REQUEST_TYPE =
          MANAGE_PROFILE_REQUEST_TYPE_MAPPINGS.VERIFY_EMAIL_CHANGE_OTP;
        const email = get(stateProps, "formValues.new_email");
        const auth_id = get(stateProps, "authId", "");
        const current_otp = get(stateProps, "formValues.current_otp");
        const otp = isArray(current_otp) ? current_otp.join("") : current_otp;
        const params = {
          auth_id,
          auth_mode: AUTH_MODE,
          email,
          request_type: REQUEST_TYPE,
          otp
        };
        dispatch(UserService.V4.fetchValidateOTPTrigger(params, callback));
      } else {
        const AUTH_MODE = MANAGE_PROFILE_OTP_MAPPINGS.ENHANCED_EMAIL_OTP;
        const REQUEST_TYPE =
          MANAGE_PROFILE_REQUEST_TYPE_MAPPINGS.VERIFY_EMAIL_CHANGE_OTP;
        const email = get(stateProps, "formValues.new_email");
        const deviceId = getDeviceId();
        const uuid = Date.now();
        const auth_id = get(stateProps, "authId", "");
        const current_otp = get(stateProps, "formValues.current_otp");
        const otp = isArray(current_otp) ? current_otp.join("") : current_otp;
        const params = {
          auth_id,
          auth_mode: AUTH_MODE,
          email,
          request_type: REQUEST_TYPE,
          device_id: deviceId,
          uuid,
          otp
        };
        dispatch(UserService.V4.fetchValidateOTPTrigger(params, callback));
      }
    },
    fetchUpdateNewEmail: (auth_id, callback) => {
      const params = {
        auth_id
      };
      dispatch(UserService.V4.fetchUpdateNewEmail(params, callback));
    },
    fetchLoggedInUserDetails: () => {
      const params = loggedInUserDetailsAPIParams();
      const newToken = urlParam("auth_token");
      const tokenHeader = newToken ? { "X-AUTH": newToken } : {};
      dispatch(UserService.V4.fetchLoggedInUserDetails(tokenHeader, params));
    },
    updateAuthToken: auth_token => {
      dispatch(updateAuthToken(auth_token));
    },
    fetchLogoutUser: (stateProps, callback) => {
      const headers = {};
      const params = {
        device_id: getDeviceId(),
        user_id: stateProps.external_id
      };
      dispatch(UserService.V4.userLogout(headers, params, callback));
    },
    fetchDeleteUser: callback => {
      dispatch(UserService.V4.deleteUserData(callback));
    },
    resetAll: () => {
      dispatch(reset(forms.PROFILE_FORM));
      dispatch(
        clearData([
          "currentOtp",
          "newEmailOtp",
          "editEmailTimerKey",
          "isModalView"
        ])
      );
    },
    getUserProfileDetails: () => {
      dispatch(Quilt.V1.fetchUserProfileDetails());
    },
    hideModal: () => {
      dispatch(
        setData({ key: "isModalView", data: { isModalView: false, type: "" } })
      );
    },
    contactCustomerService: zEIdentify => {
      activateZendeskWidget(zEIdentify);
    },
    profileNameChangecontactCustomerService: () => {
      const finalchatUrl = isWebview()
        ? INFO_TIP_NAMECHANGE_URL.replace("https", MOBILE_APP_BROWSER_PROTOCOL)
        : INFO_TIP_NAMECHANGE_URL;
      window.open(finalchatUrl, "_blank");
    },
    fetchUserOrders: () => {
      dispatch(OMS.V4.fetchUserOrders({}));
    }
  };
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  validatePhoneNumber: () => {
    const { locale } = stateProps;
    if (!stateProps.phoneNumber) {
      Alert.error(
        get(
          locale,
          "add_phone_number_to_update_email",
          "To update email you need to add a phone number first"
        )
      );
      gotoUrl(paths.PROFILE, true);
    }
  },
  requestCurrentOtp: () => {
    const { locale } = stateProps;
    if (stateProps.phoneNumber) {
      dispatchProps.fetchOTPSecured(stateProps, (success, error) => {
        if (!error) {
          dispatchProps.setData({
            key: "currentOtp",
            data: { otpRequested: true }
          });
        } else {
          const errorMessage = get(
            success,
            "message",
            "Oops.. Something went wrong"
          );
          Alert.error(errorMessage);
        }
      });
    } else {
      Alert.error(
        get(
          locale,
          "add_phone_number_to_update_email",
          "To update email you need to add a phone number first"
        )
      );
      gotoUrl(paths.PROFILE, true);
    }
  },
  resendCurrentOtp: () => {
    dispatchProps.setData({
      key: "editEmailTimerKey",
      data: { editEmailTimer: Date.now() }
    });
    dispatchProps.fetchOTPSecured(stateProps, (result, error) => {
      const errorMessage = error ? result.message : null;
      dispatchProps.setData({
        key: "currentOtp",
        data: { errorMessage: errorMessage }
      });
    });
  },
  verifyCurrentOtp: () => {
    dispatchProps.setData({
      key: "currentOtp",
      data: { otpVerified: true }
    });
  },
  resendNewEmailOtp: () => {
    dispatchProps.setData({
      key: "editEmailTimerKey",
      data: { editEmailTimer: Date.now() }
    });
    dispatchProps.fetchValidateOTPTrigger(stateProps, (result, error) => {
      const errorMessage = error ? result.message : null;
      dispatchProps.setData({
        key: "newEmailOtp",
        data: { errorMessage: errorMessage }
      });
    });
  },
  requestNewEmailOtp: () => {
    dispatchProps.fetchValidateOTPTrigger(stateProps, (result, error) => {
      if (!error) {
        dispatchProps.setData({
          key: "currentOtp",
          data: { otpVerified: true, errorMessage: null }
        });
        dispatchProps.setData({
          key: "newEmailOtp",
          data: { otpRequested: true }
        });
      } else {
        dispatchProps.setData({
          key: "currentOtp",
          data: { otpVerified: false, errorMessage: result.message }
        });
      }
    });
  },
  updateName: () => {
    dispatchProps.setData({
      key: "isModalView",
      data: { isModalView: true, type: "updateName" }
    });
  },
  updateAddress: () => {
    dispatchProps.setData({
      key: "isModalView",
      data: { isModalView: true, type: "updateAddress" }
    });
  },
  updateEmail: () => {
    dispatchProps.fetchValidateOTP(stateProps, (success, error) => {
      if (!error) {
        dispatchProps.fetchUpdateNewEmail(success.auth_id, (success, error) => {
          if (!error) {
            /**
             * Following successful email update the auth token gets changed.
             * Therefore need to update the redux store with the new token
             */
            const auth_token = get(success, "auth_token", null);
            dispatchProps.updateAuthToken(auth_token);
            updateTokenToApp(auth_token);

            // reset state for a fresh run at edit email
            dispatchProps.resetAll();

            dispatchProps.setData({
              key: "isModalView",
              data: { isModalView: true, type: "emailChangeSuccess" }
            });
          }
        });
      } else {
        dispatchProps.setData({
          key: "newEmailOtp",
          data: { otpVerified: false, errorMessage: success.message }
        });
      }
    });
  },
  logoutUserPopUp: () => {
    dispatchProps.setData({
      key: "isModalView",
      data: { isModalView: true, type: "logoutUserPopUp" }
    });
  },
  logoutUser: () => {
    dispatchProps.fetchLogoutUser(stateProps, () => {
      if (isWebview())
        gotoUrl(paths.LOGGED_OUT, false, {}, true, { reset: true });
      else {
        gotoUrl(pathWithChannel(`${paths.LOG_IN}`, "web"), false, {}, true, {
          reset: true
        });
      }
    });
  },
  showDeleteUserAccountPopUp: () => {
    dispatchProps.setData({
      key: "isModalView",
      data: { isModalView: true, type: "deleteUserAccountPopUp" }
    });
  },
  deleteUserAccount: () => {
    dispatchProps.fetchDeleteUser((success, error) => {
      if (!error) {
        dispatchProps.fetchLogoutUser(stateProps, () => {
          if (isWebview())
            gotoUrl(paths.LOGGED_OUT, false, {}, true, { reset: true });
          else {
            gotoUrl(
              pathWithChannel(`${paths.LOG_IN}`, "web"),
              false,
              {},
              true,
              {
                reset: true
              }
            );
          }
        });
      }
    });
  },
  contactCustomerService: () => {
    dispatchProps.contactCustomerService(stateProps.zEIdentify);
    dispatchProps.hideModal();
  }
});

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.PROFILE_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(Profile(SmartRender));
