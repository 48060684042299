import { createSelector } from "reselect";
import {
  planServiceSelector,
  simTypeSelector,
  contractTypeSelector,
  orderedContactTypeSelector,
  orderRefSelector,
  guestOrderSelector,
  externalUserIdSelector,
  userEmailSelector,
  isPortingUserSelector,
  validReferralCodeResponseSelector,
  userLineNumberSelector
} from "ducks/selectors";

export const makeSelectGA4PlanDetails = () =>
  createSelector(
    planServiceSelector,
    simTypeSelector,
    contractTypeSelector,
    orderedContactTypeSelector,
    orderRefSelector,
    guestOrderSelector,
    externalUserIdSelector,
    userEmailSelector,
    isPortingUserSelector,
    validReferralCodeResponseSelector,
    userLineNumberSelector,
    (
      planService,
      sim_type,
      journey_type,
      order_contract_type,
      orderRef,
      guestOrder,
      externalUserId,
      email,
      isPortingUser,
      referralCodeResponse,
      portInNumbers
    ) => {
      const {
        name,
        type,
        basicPrice,
        sku,
        contract_type,
        kyc_org_code,
      } = planService;

      const { referral_code, code_type } = referralCodeResponse;
      const contractType = contract_type || order_contract_type;

      const journeyType = () => {
        if (
          journey_type === "PORT_IN" ||
          (contractType === "PORT_IN" && kyc_org_code !== "AU")
        ) {
          return portInNumbers.length > 0 ? "au-portin" : "MNP";
        } else if (journey_type === "NEW" || contractType === "NEW") {
          return "new";
        } else if (kyc_org_code === "AU" || isPortingUser) {
          return "au-portin";
        } else return "";
      };

      const getNumberType = () => {
        if (journey_type === "PORT_IN" || contractType === "PORT_IN") return "transfer_number";
        else if (kyc_org_code === "AU" || isPortingUser) return "transfer_number";
        else if (journey_type === "NEW" || contractType === "NEW") return "new_number";
        else return ""
      }

      return {
        email,
        customer_id: externalUserId || "",
        currency: "JPY",
        item_brand: "povo",
        coupon: referral_code,
        coupon_type: code_type,
        plan_name: name || "povo2.0",
        plan_type: type || "voice",
        contract_type: "New regulation contract",
        value: basicPrice,
        item_id: sku,
        item_name: name,
        price: basicPrice,
        item_category: "PLAN",
        item_variant: "PLAN",
        tax: 0,
        shipping: 0,
        sim_type:
          sim_type?.toLowerCase() || guestOrder?.sim_type?.toLowerCase() || "",
        order_ref: orderRef,
        number_type: getNumberType(),
        journey_type: journeyType(),
      };
    }
  );