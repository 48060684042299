import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import FormattedMessage from "components/FormattedMessage";
import { Button, Flex, Box, Text, StyledLink } from "ui-components";
import TextField from "components/FormFields/TextField";
import Spinner, { styles } from "components/Spinner";
import PageHeader from "components/PageHeader";
import Alert from "react-s-alert";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import isEmpty from "lodash/isEmpty";
import { createStructuredSelector } from "reselect";
import { fetchOTP, verifyOTP, changeFormField } from "ducks/actions";
import { activateZendeskWidget } from "utils/helpers";
import {
  makeSelectOTPToken,
  makeSelectManageOrderFormOTP,
  makeSelectIsOTPVerified,
  makeSelectSessionToken,
  makeSelectIsFetchingAnyData,
  makeSelectLocale,
  makeSelectGuestOrder,
  makeSelectPageData,
  makeSelectThemeColors
} from "ducks/selectors";
import { MANAGE_ORDER_OTP_MAPPINGS, forms } from "utils/constants";
import { getButtonColorVariant, baseTheme } from "ui-components/theme";
import OTPBoxes from "components/OTPBoxes";

const VerifyOTP = ({
  outstandingPaymentToken,
  orderRef,
  orderOrPhone,
  dob,
  otpActionType,
  orderActionName,
  requestOTP,
  isRequestingOTP,
  verifyOTP,
  isVerifyingOTP,
  isFetchingAnyData,
  otp,
  otpToken,
  closeModal,
  clearOTP,
  onSuccess,
  buttonTheme,
  locale,
  guestOrder,
  data,
  pageData,
  isModal = false,
  showCloseButton = true,
  modal_title = "manage_order_validate_otp",
  modal_subtitle,
  onSubmitOTP,
  otpBoxes,
  resendOTPLink,
  themeColors,
  headerStyles,
  headerValues,
  enableBackButton,
  info_message,
  styleCloseBtn = {}
}) => {
  const [initialOTPRequest, setInitialOTPRequest] = useState(true);
  const [triggerResendOTP, setTriggerResendOTP] = useState(false);
  const [disableResendOTPButton, setDisableResendOTPButton] = useState(true);
  const [showResendOTPLoader, setShowResendOTPLoader] = useState(false);
  const [showResendOTPMessage, setShowResendOTPMessage] = useState(false);

  const hideOtpResend = get(pageData, "hideOtpResend", true);
  const hideOtpChat = get(pageData, "hideOtpChat", true);
  const linkColor = get(themeColors, "cta");
  const popupHeaderStyles = headerStyles || { lineHeight: "1.4" };

  useEffect(
    () => {
      if (initialOTPRequest || triggerResendOTP) {
        clearOTP();
        requestOTP(
          orderRef || orderOrPhone,
          dob,
          otpActionType || orderActionName,
          outstandingPaymentToken,
          (res, err) => {
            if (isEmpty(res) || err) {
              closeModal();
            }

            if (triggerResendOTP) setShowResendOTPMessage(true);
            setTriggerResendOTP(false);
            setDisableResendOTPButton(false);
            setShowResendOTPLoader(false);
            setInitialOTPRequest(false);
          }
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialOTPRequest, triggerResendOTP]
  );

  if (
    !triggerResendOTP &&
    (isRequestingOTP || isVerifyingOTP || isFetchingAnyData)
  )
    return <Spinner sx={styles.modalContainer} />;

  const otpType =
    MANAGE_ORDER_OTP_MAPPINGS[otpActionType || orderActionName] ||
    MANAGE_ORDER_OTP_MAPPINGS["default"];

  const otpSentToNumber = get(guestOrder, "contactNumber", "");
  const otpSentToEmail = get(guestOrder, "contactEmail", "");

  const submit = () => {
    onSubmitOTP
      ? onSubmitOTP()
      : verifyOTP(
          orderRef || orderOrPhone,
          dob,
          otp,
          otpToken,
          outstandingPaymentToken,
          get(locale, "invalid_otp", "Invalid OTP"),
          onSuccess,
          closeModal,
          (res, err) => {
            if (!get(res, "is_valid_otp") || err) {
              setShowResendOTPMessage(false);
            }
          }
        );
  };

  const resendOTP = () => {
    //trigger requestOTP
    setTriggerResendOTP(true);
    setDisableResendOTPButton(true);
    setShowResendOTPLoader(true);
    setShowResendOTPMessage(false);
  };

  const buttonWidth = [1, 0.8, 0.6];

  return (
    <>
      <Box>
        {isModal && showCloseButton && (
          <Button
            variant="closeButton"
            as="a"
            onClick={closeModal}
            data-testid="close-otp-button"
            sx={styleCloseBtn}
          >
            &times;
          </Button>
        )}
        <PageHeader
          style={popupHeaderStyles}
          title={modal_title}
          subtitle={
            modal_subtitle
              ? modal_subtitle
              : otpSentToNumber || otpSentToEmail
              ? `manage_order_enter_otp_message_receive_at_${otpType}`
              : `manage_order_enter_otp_message_${otpType}`
          }
          otpSentToNumber={otpSentToNumber}
          otpSentToEmail={otpSentToEmail}
          {...headerValues}
        />
      </Box>

      {otpBoxes && (
        <OTPBoxes {...otpBoxes}>
          {resendOTPLink && (
            <Box mt={[36, 36]} mb={[36, 36]} sx={{ textAlign: "center" }}>
              <StyledLink
                data-testid="resend_otp-button"
                onClick={() => resendOTPLink.resend(otpBoxes?.timerKey)}
                disabled={resendOTPLink.isDisable}
                color={linkColor}
                sx={{ textDecoration: "underline" }}
              >
                <FormattedMessage id={"resend_otp"} />
              </StyledLink>
            </Box>
          )}
          {info_message && (
            <Box mb={[12, 24]} sx={{ textAlign: "center" }}>
              <FormattedMessage html id={info_message} />
            </Box>
          )}
        </OTPBoxes>
      )}

      {!otpBoxes && (
        <TextField
          required
          field="otp"
          onEnter={submit}
          label="manage_order_enter_otp"
          placeholder={`manage_order_enter_otp_placeholder_${otpType}`}
          data-testid="otp-input"
        />
      )}

      {!otpBoxes && (
        <FormattedMessage id="manage_order_verify_otp">
          {msg => (
            <Flex justifyContent="center">
              <Button
                width={buttonWidth}
                onClick={submit}
                sx={getButtonColorVariant(buttonTheme)}
                data-testid="verify-otp-button"
              >
                {msg}
              </Button>
            </Flex>
          )}
        </FormattedMessage>
      )}

      {(!hideOtpResend || !hideOtpChat) && (
        <>
          <hr style={{ margin: "1rem 0" }} />
          <Text size="sm" mt={4} mb={4} textAlign="center">
            <FormattedMessage id="manage_order_not_able_to_get_otp" />
          </Text>
        </>
      )}

      {!hideOtpResend && (
        <FormattedMessage id="manage_order_resend_otp">
          {msg => (
            <Flex justifyContent="center">
              <Button
                disabled={disableResendOTPButton}
                width={buttonWidth}
                sx={getButtonColorVariant(buttonTheme, "outline")}
                onClick={resendOTP}
                data-testid="resend-otp-button"
              >
                <span>{msg}</span>
                {showResendOTPLoader && (
                  <>
                    &nbsp;&nbsp;
                    <span
                      style={{
                        verticalAlign: "middle",
                        display: "inline-block"
                      }}
                    >
                      <Spinner sx={{ ...styles.inlineMessage, size: 14 }} />
                    </span>
                  </>
                )}
              </Button>
            </Flex>
          )}
        </FormattedMessage>
      )}

      {!hideOtpChat && (
        <FormattedMessage id="manage_order_click_to_chat">
          {msg => (
            <Flex justifyContent="center">
              <Button
                sx={getButtonColorVariant(buttonTheme, "outline")}
                width={buttonWidth}
                onClick={() => activateZendeskWidget()}
                data-testid="chat-with-us-button"
              >
                {msg}
              </Button>
            </Flex>
          )}
        </FormattedMessage>
      )}

      {!hideOtpResend && showResendOTPMessage && (
        <FormattedMessage id="manage_order_resend_otp_sent">
          {msg => (
            <Flex
              mt={1}
              justifyContent="center"
              fontSize={baseTheme.fontSizes[0]}
            >
              {msg}
            </Flex>
          )}
        </FormattedMessage>
      )}

      {enableBackButton && (
        <Flex justifyContent="center">
          <Button
            width={otpBoxes.buttonWidth}
            onClick={closeModal}
            px={[64, 128]}
            sx={{
              ...getButtonColorVariant(buttonTheme, "outline"),
              textAlign: "center"
            }}
            data-testid="go_back"
          >
            <FormattedMessage id="go_back" />
          </Button>
        </Flex>
      )}
    </>
  );
};

VerifyOTP.defaultProps = {
  requestOTP: () => {}
};

const mapStateToProps = createStructuredSelector({
  otpToken: makeSelectOTPToken(),
  otp: makeSelectManageOrderFormOTP(),
  isOTPVerified: makeSelectIsOTPVerified(),
  sessionToken: makeSelectSessionToken(),
  isFetchingAnyData: makeSelectIsFetchingAnyData(),
  locale: makeSelectLocale(),
  guestOrder: makeSelectGuestOrder(),
  pageData: makeSelectPageData(),
  themeColors: makeSelectThemeColors()
});

export function mapDispatchToProps(dispatch) {
  return {
    requestOTP: (order_or_phone, dob, action_type, token, callback) => {
      if (action_type) {
        if (token || (order_or_phone && dob)) {
          const params = token
            ? { token, action_type }
            : { order_or_phone, dob, action_type };
          dispatch(fetchOTP(params, callback));
        }
      }
    },
    verifyOTP: (
      order_or_phone,
      dob,
      otp,
      otp_token,
      token,
      errorMessage,
      onSuccess,
      closeModal,
      callback
    ) => {
      if (otp && otp_token) {
        const params = { otp, otp_token };

        if (order_or_phone && dob) {
          params["order_or_phone"] = order_or_phone;
          params["dob"] = dob;
        }

        if (token) {
          params["token"] = token;
        }

        dispatch(
          verifyOTP(params, (res, err) => {
            const valid = get(res, "is_valid_otp");
            const sessionToken = get(res, "session_token");
            if (valid && sessionToken) {
              dispatch(
                changeFormField({
                  form: forms.MANAGE_ORDER_FORM,
                  field: "sessionToken",
                  value: sessionToken
                })
              );
              isFunction(closeModal) && closeModal();
              isFunction(onSuccess) && onSuccess(sessionToken);
            } else {
              Alert.error(errorMessage);
            }

            callback(res, err);
          })
        );
      }
    },
    clearOTP: () => {
      dispatch(
        changeFormField({
          form: forms.MANAGE_ORDER_FORM,
          field: "otp",
          value: ""
        })
      );
    }
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withConnect(VerifyOTP);
