import React from "react";
import { useSelector } from "react-redux";
import Spinner, { styles } from "components/Spinner";
import isFunction from "lodash/isFunction";

/**
 * A custom hook to show/hode Loading spinner. Hook will be returning
 * @returns {isLoading: boolean, LoadingSpinner: React.Component }
 *
 * @param  {...any} selectors array of selectors to derrive loading
 * Hook will be returning true if any of the selector functions returns true
 */
const useLoadingSpinner = (...selectors) => {
  const isLoading = useSelector(state => {
    let result = false;
    selectors.map(selectorFn => {
      if (isFunction(selectorFn)) {
        const values = selectorFn(state);
        result = result || !!values;
      } else {
        console.error(`${selectorFn} is not a function`);
      }
      return result;
    });
    return result;
  });

  /**
   * Loading Spinner Component
   * @param {object} props
   */
  const LoadingSpinner = props => {
    const { size = 200, sx = styles.fullPage, ...spinnerProps } = props;
    return <Spinner size={size} sx={sx} {...spinnerProps} />;
  };

  return {
    isLoading,
    LoadingSpinner
  };
};

export default useLoadingSpinner;
