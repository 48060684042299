import { createSelector } from "reselect";
import get from "lodash/get";
import filter from "lodash/filter";
import includes from "lodash/includes";
import sumBy from "lodash/sumBy";
import max from "lodash/max";
import ceil from "lodash/ceil";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import {
  formatCurrency,
  calculatePayMonthlyItems,
  calculatePayTodayItems,
  formatAddonsForPayMonthly,
  formatMessage,
  urlParam
} from "utils/helpers";
import {
  currencyFormatSelector,
  referralCodeSelector,
  deliveryInfoSelector,
  personalDetailsSelector,
  selectedNumberSelector,
  selectedNumberTypeSelector,
  portInSelector,
  numberFormatSelector,
  localeSelector,
  phonesFormSelector,
  selfActivationSelector,
  genericSelector,
  selectedSANumberTypeSelector,
  multiSimOrderSelector
} from "ducks/selectors";
import { PREPAID_PLAN_SUB_TYPES, SIM_TYPES } from "utils/constants";

export const selectPlanService = state =>
  get(state.api, "planService.data", {});

export const planServiceSelector = selectPlanService;

export const selectSelectedAddons = state =>
  get(state.api, "selectedAddons.data", {});

export const preSelectedOrderAddonsSelector = state =>
  get(state.api, "validateIccId.data.selected_paid_addons", []);

// ***************************************************************
// ******************** planService selectors ********************
// ***************************************************************

export const basicPriceSelector = createSelector(
  planServiceSelector,
  planService => get(planService, "basicPrice") || 0
);

export const basicTaxSelector = createSelector(
  planServiceSelector,
  genericSelector,
  (planService, generic) =>
    get(planService, "tax_percentage") || get(generic, "tax_percentage") || 0
);

export const multiSimSelector = urlParam("ms");

export const basicDataSelector = createSelector(
  planServiceSelector,
  planService => get(planService, "data") || 0
);

export const enabledAddonTypesSelector = createSelector(
  genericSelector,
  generic => get(generic, "enabledAddonTypes", [])
);

export const planAddonsSelector = createSelector(
  planServiceSelector,
  enabledAddonTypesSelector,
  (planService, enabledAddonTypes) => {
    if (isArray(get(planService, "allowed_products"))) {
      return filter(get(planService, "additional_products"), product =>
        includes(get(planService, "allowed_products"), product.id)
      );
    } else if (!isEmpty(enabledAddonTypes)) {
      // Only the Additional Product types that are in the enabledAddonTypes should be included
      return filter(get(planService, "additional_products"), product =>
        includes(enabledAddonTypes, product.type)
      );
    }

    return get(planService, "additional_products");
  }
);

export const defaultPlanAddonsSelector = createSelector(
  planServiceSelector,
  planService =>
    filter(get(planService, "default_products", []), addon =>
      get(addon, "visible")
    )
);

export const defaultInvisiblePlanAddonsSelector = createSelector(
  planServiceSelector,
  planService =>
    filter(
      get(planService, "default_products", []),
      addon => !get(addon, "visible")
    )
);

export const payMonthlySelector = createSelector(
  planServiceSelector,
  planService => get(planService, "pay_monthly", [])
);

export const payTodaySelector = createSelector(
  planServiceSelector,
  planService => get(planService, "pay_today", [])
);

export const planSubTypeSelector = createSelector(
  planServiceSelector,
  planService => get(planService, "sub_type", "")
);

export const addonsSelector = createSelector(
  currencyFormatSelector,
  planAddonsSelector,
  selectSelectedAddons,
  basicTaxSelector,
  genericSelector,
  (currencyFormat, planAddons, selectedAddons, basicTax, generic) => {
    const addons = get(generic, "show_only_actionable_addons")
      ? filter(planAddons, addon => addon.action)
      : planAddons;
    return formatAddonsForPayMonthly(
      addons,
      selectedAddons,
      currencyFormat,
      basicTax
    );
  }
);

export const defaultAddonsSelector = createSelector(
  currencyFormatSelector,
  defaultPlanAddonsSelector,
  basicTaxSelector,
  (currencyFormat, defaultAddons, basicTax) => {
    const selectedAddons = {};
    // all default addons are selected by default
    for (const addon of defaultAddons) {
      selectedAddons[addon.id] = true;
    }
    return formatAddonsForPayMonthly(
      defaultAddons,
      selectedAddons,
      currencyFormat,
      basicTax
    );
  }
);

export const defaultInvisibleAddonsSelector = createSelector(
  currencyFormatSelector,
  defaultInvisiblePlanAddonsSelector,
  basicTaxSelector,
  (currencyFormat, defaultInvisibleAddons, basicTax) => {
    const selectedAddons = {};
    // all default addons are selected by default
    for (const addon of defaultInvisibleAddons) {
      selectedAddons[addon.id] = true;
    }
    return formatAddonsForPayMonthly(
      defaultInvisibleAddons,
      selectedAddons,
      currencyFormat,
      basicTax
    );
  }
);

// ***************************************************************
// ******************* planService makeSelect ********************
// ***************************************************************

export const makeSelectPayMonthlyItems = () =>
  createSelector(
    basicPriceSelector,
    basicTaxSelector,
    payMonthlySelector,
    currencyFormatSelector,
    referralCodeSelector,
    defaultPlanAddonsSelector,
    planSubTypeSelector,
    basicDataSelector,
    phonesFormSelector,
    genericSelector,
    selectedSANumberTypeSelector,
    planServiceSelector,
    additionalAddonsSelector,
    selectedNumberTypeSelector,
    selfActivationSelector,
    genericSelector,
    (
      basicPrice,
      basicTax,
      monthlyItems,
      currencyFormat,
      referralCode,
      defaultPlanAddons,
      planSubType,
      basicData,
      phonesForm,
      genericData,
      numberType,
      planDetails,
      addons,
      selectedNumberType,
      selfActivation,
      generic
    ) => {
      const precision = get(currencyFormat, "precision", 2);
      const payMonthlyItems = includes(PREPAID_PLAN_SUB_TYPES, planSubType)
        ? []
        : calculatePayMonthlyItems({
            basicPrice,
            basicTax,
            items: monthlyItems,
            currencyFormat,
            referralCode,
            addons,
            defaultPlanAddons,
            phonesForm,
            discount: get(genericData, "transfer_number_discount", {}),
            planDetails,
            numberType,
            selectedNumberType,
            selfActivation,
            generic
          });

      const originalPrice = max([
        sumBy(payMonthlyItems, ({ value }) => max([value, 0])),
        0
      ]);
      const originalFixedPrice = formatCurrency(
        originalPrice,
        currencyFormat,
        false,
        0
      );
      const originalPriceWithoutTax = ceil(
        max([
          sumBy(payMonthlyItems, ({ value_without_tax }) =>
            max([value_without_tax, 0])
          ),
          0
        ]),
        precision
      );
      const originalFixedPriceWithoutTax = formatCurrency(
        originalPriceWithoutTax,
        currencyFormat,
        false,
        0
      );

      const totalAmount = max([sumBy(payMonthlyItems, "value"), 0]);
      const totalAmountWithoutTax = ceil(
        max([sumBy(payMonthlyItems, "value_without_tax"), 0]),
        precision
      );
      const totalTaxAmount = ceil(
        totalAmount - totalAmountWithoutTax,
        precision
      );
      const totalData = max([sumBy(payMonthlyItems, "data"), 0]) + basicData;

      return {
        items: payMonthlyItems,
        originalPrice: formatCurrency(originalPrice, currencyFormat),
        originalFixedPrice: formatCurrency(originalFixedPrice, currencyFormat),
        originalPriceWithoutTax: formatCurrency(
          originalPriceWithoutTax,
          currencyFormat
        ),
        originalFixedPriceWithoutTax,
        totalPrice: formatCurrency(totalAmount, currencyFormat),
        totalPriceWithoutTax: formatCurrency(
          totalAmountWithoutTax,
          currencyFormat
        ),
        totalTaxPrice: formatCurrency(totalTaxAmount, currencyFormat),
        totalFixedPrice: formatCurrency(totalAmount, currencyFormat, false, 0),
        totalFixedPriceWithoutTax: formatCurrency(
          totalAmountWithoutTax,
          currencyFormat,
          false,
          0
        ),
        totalData,
        totalAmount,
        totalAmountWithoutTax,
        totalTaxAmount
      };
    }
  );

export const makeSelectPayTodayItems = page =>
  createSelector(
    payTodaySelector,
    payMonthlySelector,
    currencyFormatSelector,
    referralCodeSelector,
    deliveryInfoSelector,
    personalDetailsSelector,
    selectedNumberSelector,
    selectedNumberTypeSelector,
    portInSelector,
    basicPriceSelector,
    basicTaxSelector,
    addonsSelector,
    defaultPlanAddonsSelector,
    planSubTypeSelector,
    numberFormatSelector,
    localeSelector,
    phonesFormSelector,
    genericSelector,
    selectedSANumberTypeSelector,
    selfActivationSelector,
    preSelectedOrderAddonsSelector,
    (
      todayItems,
      monthlyItems,
      currencyFormat,
      referralCode,
      deliveryInfo,
      personalDetails,
      selectedNumber,
      selectedNumberType,
      portInForm,
      basicPrice,
      basicTax,
      addons,
      defaultPlanAddons,
      planSubType,
      numberFormat,
      locale,
      phonesForm,
      genericData,
      numberType,
      selfActivationForm,
      preActivationDeliveryAddon
    ) => {
      const payMonthlyItems = !includes(PREPAID_PLAN_SUB_TYPES, planSubType)
        ? []
        : calculatePayMonthlyItems({
            basicPrice,
            basicTax,
            items: monthlyItems,
            currencyFormat,
            referralCode,
            addons,
            defaultPlanAddons,
            phonesForm,
            discount: get(genericData, "transfer_number_discount", {}),
            numberType
          });

      const payTodayItems = calculatePayTodayItems({
        items: todayItems,
        currencyFormat,
        referralCode,
        deliveryInfo,
        personalDetails,
        selectedNumber,
        selectedNumberType,
        portInForm,
        page,
        numberFormat,
        locale,
        phonesForm,
        basicTax,
        selfActivationForm,
        preActivationDeliveryAddon
      });

      const allPayTodayItems = [...payMonthlyItems, ...payTodayItems];

      const totalAmount = max([sumBy(allPayTodayItems, "value"), 0]);

      return {
        items: allPayTodayItems,
        totalPrice: formatCurrency(totalAmount, currencyFormat),
        totalFixedPrice: formatCurrency(totalAmount, currencyFormat, false, 0),
        totalAmount
      };
    }
  );

export const additionalAddonsSelector = createSelector(
  addonsSelector,
  selfActivationSelector,
  currencyFormatSelector,
  genericSelector,
  multiSimOrderSelector,
  ([...addOns], selfActivation, currencyFormat, generic, multiSim) => {
    const multi_sim = get(selfActivation, "multi_sim", false);
    const sim_type = get(selfActivation, "sim_type");
    if ((multiSim || multi_sim) && sim_type !== SIM_TYPES.ESIM) {
      const multi_sim_price = get(generic, "multi_sim.price");
      addOns.unshift({
        label: `${get(generic, "multi_sim.label")}`,
        value: multi_sim_price,
        selected: true,
        type: "sim",
        price: formatCurrency(multi_sim_price, currencyFormat, true)
      });
    }
    return addOns;
  }
);

export const makeSelectAddons = () => addonsSelector;

export const makeSelectDefaultPlanAddons = () => defaultAddonsSelector;

export const makeSelectSelectedAddons = () => additionalAddonsSelector;

export const makeSelectconfirmedAddon = () =>
  createSelector(additionalAddonsSelector, ([...addOns]) =>
    addOns
      .filter(addon => addon.selected && get(addon, "type") !== "sim")
      .map(addon => addon.label)
      .join()
  );

export const makeSelectSimAddons = () =>
  createSelector(additionalAddonsSelector, ([...addOns]) =>
    addOns
      .filter(addon => addon.selected && get(addon, "type") === "sim")
      .map(addon => addon.label)
      .join()
  );

export const makeSelectSimCount = () =>
  createSelector(selfActivationSelector, self_activation =>
    get(self_activation, "sim_count")
  );

export const makeSelectPreSelectedAddons = () => preSelectedOrderAddonsSelector;

export const planDetailsSelector = createSelector(
  selfActivationSelector,
  localeSelector,
  planServiceSelector,
  (selfActivation, locale, { ...planData }) => {
    let payMonthlyItems = get(planData, "pay_monthly", false);
    const sim_count = get(selfActivation, "sim_count");
    if (sim_count && payMonthlyItems) {
      const data = formatMessage(get(locale, "family_plan_sims"), {
        sim_count
      });
      planData["pay_monthly"] = [
        ...payMonthlyItems,
        { label: data, type: "sim", value: 0 }
      ];
    }
    return planData;
  }
);

export const makeSelectPlanDetails = () => planDetailsSelector;

export const makeSelectPlanFAQ = () =>
  createSelector(planServiceSelector, planService => get(planService, "faq"));

export const makeSelectPlanName = () =>
  createSelector(planServiceSelector, planService => get(planService, "name"));

export const makeSelectPlanTextName = () =>
  createSelector(planServiceSelector, planService =>
    get(planService, "textName")
  );

export const makeSelectSpecialNumberAllowed = () =>
  createSelector(planServiceSelector, planService =>
    get(planService, "special_number_allowed")
  );

export const makeSelectFamilyPlanSims = () =>
  createSelector(planDetailsSelector, ({ ...plan }) => {
    let payMonthly = get(plan, "pay_monthly", []);
    let simItems = filter(payMonthly, plan => get(plan, "type") === "sim")
      .reduce((acc, item) => {
        acc.push(item.label);
        return acc;
      }, [])
      .join(",");
    return simItems;
  });

export const makeSelectPayDevice = () =>
  createSelector(
    phonesFormSelector,
    ({ phoneVariant, phonePaymentOption, paymentTerm }) => {
      if (phoneVariant) {
        return {
          label: phoneVariant.nameWithSpecs || phoneVariant.title,
          paymentType: phonePaymentOption,
          paymentTerm: paymentTerm,
          paymentOption: phoneVariant.paymentOption,
          deviceSku: phoneVariant.id
        };
      }
      return null;
    }
  );
