import React, { memo, useEffect } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect, useSelector, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import validate from "validations";
import {
  forms,
  publicPaths,
  AUTH_MODES,
  LOGIN_REDIRECT_CHANNEL_MAPPING
} from "utils/constants";
import SmartRender from "containers/SmartRender";
import Spinner, { styles } from "components/Spinner";
import { getDeviceId, getLinkAccount, isLinkAccount } from "utils/localStorage";
import {
  gotoUrl,
  pathWithChannel,
  getChannelFromUrl,
  getURLParams,
  isMobile
} from "utils/helpers";
import {
  makeSelectPageValidations,
  makeSelectIsFetchingData,
  makeSelectThemeColors,
  makeSelectIsUserLoggedIn,
  makeSelectUserEmail,
  makeSelectLoginFormMessageData,
  makeSelectSocialLoginEmails,
  makeSelectLoginEmail,
  makeSelectLoggedInUserData,
  makeSelectSocialMediaDetails,
  makeSelectSocialMediaOptions,
  makeSelectGA4PlanDetails
} from "ducks/selectors";
import { useParams } from "react-router-dom";
import authenticateUserAndSaveToken from "services/userAuthenticate";
import {
  useFetchOrdersAndRedirect,
  useAuthorizedUserRedirection,
  useAutoRedirectToSocialLogin
} from "hooks";
import { clearSocialLoginData, UserService } from "ducks/actions";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";

const Login = Component =>
  function Comp(props) {
    const { isFetchingRequiredData, isLoggedIn } = props;
    const dispatch = useDispatch();
    const { channel } = useParams();
    const linkingSocialAccountName = getLinkAccount();
    const redirectChannel = get(
      LOGIN_REDIRECT_CHANNEL_MAPPING,
      channel,
      channel
    );

    const redirectLoggedInUser = useFetchOrdersAndRedirect(redirectChannel);
    const redirectUser = useAuthorizedUserRedirection(redirectChannel);
    const socialMediaDetails = useSelector(makeSelectSocialMediaDetails());
    const socialMediaOptions = useSelector(makeSelectSocialMediaOptions());
    const email = useSelector(makeSelectLoginEmail());
    const {
      isSocialLogin,
      autologin: autologinSocialAccountName,
      continueWithoutOtp
    } = getURLParams();
    const redirectToSocialLoginPage = useAutoRedirectToSocialLogin(
      autologinSocialAccountName || linkingSocialAccountName
    );
    const isUserVerified = useSelector(makeSelectLoggedInUserData());
    const VerifyOTPPath = publicPaths.VERIFY_OTP.replace(
      ":channel",
      getChannelFromUrl()
    );
    const currentPath = window.location.pathname;
    /**
     * if continueWithoutOtp url param is true,
     * call user service auth api
     * if not Dispatches the User Login action event.
     * Upon response this will dispatch the OTP request
     */
    const onClickContinueButton = () => {
      if (props.invalid) return;
      continueWithoutOtp
        ? loginWithSelectedSocialMediaEmail()
        : callOtpLoginFlow();
    };

    const callOtpLoginFlow = () => {
      dispatch(clearSocialLoginData());
      const deviceId = getDeviceId();
      const headers = {};
      const bodyParams = {
        email,
        device_id: deviceId
      };

      const onSuccess = (_, errors) => {
        if (!errors) {
          requestOTPForEmail(email);
        }
      };

      dispatch(UserService.V4.userLogin(headers, bodyParams, onSuccess));
      GA4EventsService.publish(
        emailEventData(GA4EVENTS.EMAIL_SUBMITTED, props.GA4EventPlanDetails)
      );
    };

    /**
     * * Triggers after user select a email address for signup
     */
    const loginWithSelectedSocialMediaEmail = () => {
      const additionalHeaders = {};
      const bodyParams = {
        device: {
          // TODO: Should this be dynamic?
          app_type: "ecosystem",
          device_id: getDeviceId(),
          device_type: isMobile ? "Mobile" : "web"
        },
        social_auth: {
          ...socialMediaDetails,
          email: email?.value
        }
      };

      /**
       * Triggers user routing upon successful authentication
       * @param {object} authResponse success response result
       * @param {object} errors API errors only if failed
       */
      const onUserAuthenticateCallback = (authResponse, errors) => {
        // redirect user to next screen if there is no error
        if (!errors) {
          redirectUser(authResponse, errors);
        }
      };

      authenticateUserAndSaveToken(
        additionalHeaders,
        bodyParams,
        onUserAuthenticateCallback,
        dispatch
      );
    };

    /**
     * Dispatches the OTP for email request.
     * Upon successful response this will redirect user to OTP page
     * @param {string} email user provided email
     */
    const requestOTPForEmail = email => {
      const additionalHeaders = {};
      const bodyParams = {
        email,
        auth_mode: AUTH_MODES.ENHANCED_EMAIL_OTP
      };

      /**
       * redirect user to OTP page if successful
       * @param {object} response success response result
       * @param {object} errors API errors only if failed
       */
      const onSubmitCallback = (response, errors) => {
        const success = !errors;
        if (success) {
          const queryParams = isSocialLogin
            ? { key: Date.now(), isSocialLogin }
            : { key: Date.now() };
          gotoUrl(VerifyOTPPath, true, queryParams);
        } else {
          console.error("Request OTP failed. Cause: ", errors);
        }
      };

      dispatch(
        UserService.V4.userLoginOTP(
          additionalHeaders,
          bodyParams,
          onSubmitCallback
        )
      );
    };

    /**
     * This use effect controls the redirection for logged in users
     * Already logged in users will not be allow to login again.
     * Instead they will be redirected based on their telco status
     */
    useEffect(() => {
      /**
       * If user is already signed in, its not required to show the login/signup page
       */
      const isLoginOrSignupPath =
        currentPath === pathWithChannel(publicPaths.SIGNUP_PAGE) ||
        currentPath === pathWithChannel(publicPaths.LOGIN_PAGE);
      if (isLoggedIn && isLoginOrSignupPath && !isLinkAccount()) {
        redirectLoggedInUser(getChannelFromUrl());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    /**
     * According to the "autologin" url param value ,
     * user will be redirect to related social login page
     */
    useEffect(() => {
      if (
        autologinSocialAccountName ||
        (linkingSocialAccountName && !isEmpty(isUserVerified))
      ) {
        return redirectToSocialLoginPage();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [autologinSocialAccountName, linkingSocialAccountName, isUserVerified]);

    const onClickAULogout = (navigate, event, data = {}) => {
      const { auLogoutUrl } = socialMediaOptions["AU_IPDB"] || {};
      const urlParams = {
        targeturl: `${window.location.origin}${data.next?.path}`
      };
      if (auLogoutUrl) {
        // GA4EventsService.publish(
        //   emailEventData(GA4EVENTS.LOGOUT_FROM_AU_ID, props.GA4EventPlanDetails)
        // );
        //redirect user to AU logout Page
        gotoUrl(auLogoutUrl, false, {}, true, urlParams);
      }
    };

    if (isFetchingRequiredData || isLoggedIn) {
      return <Spinner size={200} sx={styles.fullPage} />;
    }

    return autologinSocialAccountName || isLinkAccount() ? null : (
      <Component
        {...props}
        onSubmit={onClickContinueButton}
        onEnter={onClickContinueButton}
        onClickAULogout={onClickAULogout}
      />
    );
  };

const mapStateToProps = createStructuredSelector({
  formValidations: makeSelectPageValidations(forms.USER_LOGIN_FORM),
  userEmail: makeSelectUserEmail(),
  socialMultipleEmailList: makeSelectSocialLoginEmails(),
  loginEmail: makeSelectLoginEmail(),
  themeColors: makeSelectThemeColors(),
  messageData: makeSelectLoginFormMessageData(),
  isFetchingRequiredData: makeSelectIsFetchingData(),
  isLoggedIn: makeSelectIsUserLoggedIn(),
  GA4EventPlanDetails: makeSelectGA4PlanDetails()
});
const mapDispatchToProps = {};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  reduxForm({
    form: forms.USER_LOGIN_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(Login(SmartRender));

const emailEventData = (event, details = {}) => {
  const { customer_id, plan_name, plan_type, journey_type } = details;

  return {
    event,
    ecommerce: {
      customer_id,
      plan_name,
      journey_type: journey_type || "",
      plan_type
    }
  };
};
