import React, { useEffect } from "react";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import { gotoUrl, getChannelFromUrl } from "utils/helpers";
import { getTxnId } from "utils/localStorage";
import { loadStripe } from "@stripe/stripe-js";
import { Flex } from "ui-components";
import { Elements } from "@stripe/react-stripe-js";
import Spinner, { styles } from "components/Spinner";
import StripeCheckoutForm from "components/StripeCheckout";
import { paths, STATUS_TYPE } from "utils/constants";

const PaymentUpdateCardStripeCheckout = ({
  isFetchingPaymentDetails,
  paymentDetails,
  saveAndGetPaymentCardDetails,
  currencyCode,
  token,
  returnUrl,
  orderSessionToken
}) => {
  const loading = isEmpty(paymentDetails) || isFetchingPaymentDetails;

  useEffect(() => {
    if (isEmpty(paymentDetails) && !isFetchingPaymentDetails) {
      saveAndGetPaymentCardDetails({
        paymentMethod: "card",
        txn_id: getTxnId(),
        amount: 0,
        currency: currencyCode,
        additional_data: {
          custom_payment_method: "card"
        },
        notification_channel: {},
        instrument_data: {
          redirect_url: window.location.href
        },
        token: token || orderSessionToken
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const publishable_api_key = get(paymentDetails, "stripe.publishable_api_key");
  const intent_confirmation = get(paymentDetails, "stripe.intent_confirmation");
  const client_secret = get(paymentDetails, "stripe.intent.client_secret");
  const amount = get(paymentDetails, "stripe.intent.amount");

  const stripePromise = publishable_api_key && loadStripe(publishable_api_key);

  const onPaymentSuccess = () => {
    gotoUrl(
      paths.PAYMENT_STATUS.replace(":channel", getChannelFromUrl()),
      true,
      { type: STATUS_TYPE.TRANSACTION, return_url: returnUrl, reset: null },
      true
    );
  };

  return (
    <Flex
      minHeight={"282px"}
      alignItems={"center"}
      justifyContent={"center"}
      flexDirection="column"
    >
      {loading ? (
        <Spinner size={200} sx={styles.modalContainer} />
      ) : (
        <Elements stripe={stripePromise}>
          <StripeCheckoutForm
            clientSecret={client_secret}
            intentConfirmation={intent_confirmation}
            amount={amount}
            onPaymentSuccess={onPaymentSuccess}
          />
        </Elements>
      )}
    </Flex>
  );
};

PaymentUpdateCardStripeCheckout.defaultProps = {
  data: { update_success_msg: "update_credit_card_success_msg" },
  saveAndGetPaymentCardDetails: () => {}
};

export default PaymentUpdateCardStripeCheckout;
