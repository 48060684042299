import React from "react";
import { FormattedMessage as IntlFormattedMessage } from "react-intl";
import { FormattedHTMLMessage as IntlFormattedHTMLMessage } from "react-intl";
import { Text } from "ui-components";

const FormattedMessage = props => {
  const localize = localStorage.getItem("localize") !== "0";

  const { html, id } = props;

  if (!localize) {
    return <Text color="green">{id}</Text>;
  }

  const Message = html ? IntlFormattedHTMLMessage : IntlFormattedMessage;
  return <Message {...props} />;
};

export default FormattedMessage;
