import React from "react";
import PropTypes from "prop-types";
import { Flex, Text } from "ui-components";
import { COLORS } from "ui-components/theme";

const colorMapping = {
  // base: COLORS.gray[4],
  // addon: COLORS.gray[4],
  benefit: COLORS.green[1]
};

const PayLineItem = ({ type, label, price }) => {
  const fontColor = colorMapping[type];

  return (
    <Flex data-testid="pay-item" justifyContent="space-between">
      <Text mb={2} width={[7 / 10]} color={fontColor}>
        {label}
      </Text>
      <Text color={fontColor}>{price}</Text>
    </Flex>
  );
};

PayLineItem.defaultProps = {
  type: "",
  label: "",
  price: ""
};

PayLineItem.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  price: PropTypes.string
};

export default PayLineItem;
