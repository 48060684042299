import React, { useEffect } from "react";
import get from "lodash/get";
import head from "lodash/head";
import replace from "lodash/replace";
import uniqBy from "lodash/uniqBy";
import isEmpty from "lodash/isEmpty";
import DeliverySlotGroup from "components/FormFields/DeliverySlotGroup";
import {
  PHONE_ONLY_CHANNEL,
  DELIVERY_SLOT_TYPE_PHONE,
  DELIVERY_SLOT_TYPE_SIM,
  COMBO_CHANNEL,
  DELIVERY_SLOT_TYPE_SIM_AND_PHONE
} from "utils/constants";
import { formatZipCode, getChannelFromUrl } from "utils/helpers";

const DELIVERY_DATE_PICKER = ({
  change,
  formValues,
  combinedDeliverySlotDates,
  deliverySlotDates,
  selectedNumber,
  fetchDeliverySlots,
  resetDeliverySlots,
  skipPostalCodeValidation = false,
  postalCodeValidation,
  data = {},
  phoneVariant,
  isFetchingDeliverySlots,
  changeField,
  generic,
  ...props
}) => {
  const deliveryZipCode = get(formValues, "delivery_zip_code");
  const selectedDeliveryMethod = get(formValues, "delivery_method", {});
  const deliveryZipCodeValid =
    skipPostalCodeValidation ||
    get(data, "skip_postal_code_validation", false) ||
    get(postalCodeValidation, "delivery_zip_code.valid", false);
  const selectedHomeDelivery = selectedDeliveryMethod.action === "home";
  const selectedPhoneSku = get(phoneVariant, "sku");
  const combinedSlots = data.combined;
  const courier = get(data, "courier", null);
  const autoSelectDeliverySlots = get(data, "auto_select_delivery_slots");
  const showAutoSelectedDeliverySlots = get(
    data,
    "show_auto_selected_delivery_slots"
  );
  const phoneOnly = getChannelFromUrl() === PHONE_ONLY_CHANNEL;
  const combo = getChannelFromUrl() === COMBO_CHANNEL;

  let slotType;
  if (phoneOnly) {
    slotType = DELIVERY_SLOT_TYPE_PHONE;
  } else if (combo) {
    slotType = DELIVERY_SLOT_TYPE_SIM_AND_PHONE;
  } else {
    slotType = replace(props.orderType, "_", "-") || DELIVERY_SLOT_TYPE_SIM;
  }

  if (!combinedSlots) {
    deliverySlotDates = uniqBy(deliverySlotDates, "label").map(item => {
      return {
        ...item,
        value: item.date,
        description: null,
        cost: null,
        formattedCost: null
      };
    });
  }

  const slots = combinedSlots ? combinedDeliverySlotDates : deliverySlotDates;
  const field = combinedSlots ? "delivery_slot_full_data" : "delivery_date";
  const number = get(selectedNumber, "number");
  useEffect(
    () => {
      if (deliveryZipCodeValid && deliveryZipCode && selectedHomeDelivery) {
        const formattedZipCode = formatZipCode(deliveryZipCode);
        fetchDeliverySlots(
          {
            channel: getChannelFromUrl(),
            postal_code: formattedZipCode,
            identifier: phoneOnly ? selectedPhoneSku : deliveryZipCode,
            number,
            portin: false,
            type: slotType,
            courier
          },
          () => {
            // clear the selected slot if the zip-code is changed
            changeField("delivery_slot_full_data", null);
          }
        );
      } else {
        !isEmpty(slots) && resetDeliverySlots();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deliveryZipCodeValid, deliveryZipCode, selectedHomeDelivery]
  );

  useEffect(() => {
    if (autoSelectDeliverySlots && !isEmpty(slots)) {
      change("delivery_slot_full_data", head(slots).value);
      change("delivery_slot_fee", head(slots).cost);
      change("delivery_slot_id", head(slots).slotId);
      change("delivery_slot", head(slots).deliverySlot);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slots]);

  return selectedHomeDelivery &&
    (!autoSelectDeliverySlots || showAutoSelectedDeliverySlots) ? (
    <DeliverySlotGroup
      change={change}
      options={slots}
      isLoading={isFetchingDeliverySlots}
      {...props}
      {...data}
      field={field}
    />
  ) : null;
};

export default DELIVERY_DATE_PICKER;
