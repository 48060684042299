import React from "react";
import { Box, Flex, Text } from "ui-components";
import { COLORS, iconThemes } from "ui-components/theme";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";

const DeliveryFeeItem = ({ payTodayItems = {}, primaryColor }) => {
  const deliveryItem = find(payTodayItems.items, { type: "delivery" });
  return isEmpty(deliveryItem) ? null : (
    <Box my={5}>
      <Flex
        py={1}
        justifyContent="space-between"
        alignItems="center"
        px={"1rem"}
      >
        <Box paddingRight={1} justifySelf="flex-start" flex={1}>
          <Text fontWeight="bold" py="4px" color={COLORS.gray[3]}>
            {deliveryItem.label}
          </Text>
        </Box>
        <Box width={["fit-content"]}>
          <Text fontWeight="bold" py="4px" color={primaryColor}>
            {deliveryItem.price}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

DeliveryFeeItem.defaultProps = {
  payTodayItems: {},
  primaryColor: iconThemes.blue
};

export default DeliveryFeeItem;
