import React, { memo, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import moment from "moment-timezone";
import { createStructuredSelector } from "reselect";
import { v4 as uuid } from "uuid";
import SmartRender from "containers/SmartRender";
import { Billing, OMS, Quilt, Telco } from "ducks/actions";
import {
  makeSelectIsActiveTelcoUser,
  makeSelectIsFetchingData,
  makeSelectGuestOrder,
  makeSelectUserOrders,
  makeSelectOrderRef,
  makeSelectManageOrderForm,
  makeSelectIsOutstandingPayable,
  makeSelectEligibleActions,
  makeSelectMNPValidityInDays,
  makeSelectServiceInstanceNumber,
  makeSelectCurrentPhoneNumber,
  makeSelectOneStopMnpPortOutParams,
  makeSelectOneStopMnpPortOutDelayConfig,
  makeSelectOnestopPortoutStatus,
  makeSelectExistingMNPDetails,
  makeSelectOutstandingBillAmount,
  makeSelectKddiOct,
  makeSelectkddiSubBrandsHosts
} from "ducks/selectors";
import Spinner, { styles } from "components/Spinner";
import isEmpty from "lodash/isEmpty";
import { gotoUrl } from "utils/helpers";
import { paths } from "utils/constants";
import usePostDocumentAPI from "hooks/usePostDocumentAPI";
import { getOneStopPortOutParams } from "utils/localStorage";

const OneStopMNPPortoutDashboardHOC = Component =>
  function Comp(props) {
    const {
      fetchEligibleActions,
      fetchOutstandingBillsInfo,
      fetchUserOrders,
      isFetchingData,
      isActiveTelcoUser,
      order,
      serviceInstanceNumber,
      currentPhoneNumber,
      oneStopMnpPortOutParams,
      oneStopMnpPortOutDelayConfig,
      kddiOct,
      kddiSubBrandsHosts
    } = props;

    const dispatch = useDispatch();
    const { submitPostApi } = usePostDocumentAPI(
      "submit-portout-1stop-dashboard"
    );

    useEffect(() => {
      fetchEligibleActions();
      fetchUserOrders();

      if (isActiveTelcoUser) {
        fetchOutstandingBillsInfo();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const requestCancelPortOut = () => {
      const payload = {
        accountNumber: serviceInstanceNumber,
        msisdn: currentPhoneNumber,
        status: "CANCEL_REQUEST"
      };
      dispatch(
        Telco.V1.cancelPortOutRequest(payload, (_, errors) => {
          if (isEmpty(errors)) {
            gotoUrl(paths.ONESTOP_MNP_DASHBOARD, true, {}, true);
          }
        })
      );
    };

    const redirectToDestinationProvider = () => {
      const { callback, onestop, param } = getOneStopPortOutParams() || {};
      let { mno, valid } = oneStopMnpPortOutParams;
      let { timezone, delayInMins } = oneStopMnpPortOutDelayConfig;
      // Providing valid in oneStopMnpPortOutParams can override the default behavior of dinamic time
      if (!valid) {
        valid = generateDynamicValidTime(timezone, delayInMins);
      }
      const params = {
        onestop,
        param,
        valid,
        mno
      };

      if (isKDDISubBrand(callback)) {
        params["kddioct"] = kddiOct;
      }
      submitPostApi(callback, params);
    };

    const isKDDISubBrand = callbackUrl => {
      if (callbackUrl) {
        const urlObj = new URL(callbackUrl);
        const callbackUrlHost = urlObj.hostname;
        return kddiSubBrandsHosts.includes(callbackUrlHost);
      } else {
        return false;
      }
    };

    /**
     * Will be providing the portout request completion time in future
     * @param {String} timezone moment timezone
     * @param {Number} delayInMins delay to add
     * @returns YYYYMMDDHHmm with delay added to the given timestamp
     */
    const generateDynamicValidTime = (timezone, delayInMins) => {
      const currentTimeInTimezone = moment.tz(timezone);
      currentTimeInTimezone.add(delayInMins, "minutes");
      return currentTimeInTimezone.format("YYYYMMDDHHmm");
    };

    return isFetchingData ? (
      <Spinner size={200} sx={styles.fullPage} />
    ) : (
      <Component
        {...props}
        {...order}
        requestCancelPortOut={requestCancelPortOut}
        redirectToDestinationProvider={redirectToDestinationProvider}
      />
    );
  };

const mapStateToProps = createStructuredSelector({
  formValues: makeSelectManageOrderForm(),
  order: makeSelectGuestOrder(),
  userOrders: makeSelectUserOrders(),
  isFetchingData: makeSelectIsFetchingData([
    "eligibilitySettings",
    "outstandingBillPayments",
    "userOrders",
    "cancelPortOut"
  ]),
  isActiveTelcoUser: makeSelectIsActiveTelcoUser(),
  hasOutstandingPayments: makeSelectIsOutstandingPayable(),
  eligibileActions: makeSelectEligibleActions(),
  mnpNoValidityInDays: makeSelectMNPValidityInDays(),
  onestopPortoutStatus: makeSelectOnestopPortoutStatus(),
  orderRef: makeSelectOrderRef(),
  serviceInstanceNumber: makeSelectServiceInstanceNumber(),
  currentPhoneNumber: makeSelectCurrentPhoneNumber(),
  oneStopMnpPortOutParams: makeSelectOneStopMnpPortOutParams(),
  oneStopMnpPortOutDelayConfig: makeSelectOneStopMnpPortOutDelayConfig(),
  existingMNPDetails: makeSelectExistingMNPDetails(),
  outstandingBillAmount: makeSelectOutstandingBillAmount(),
  kddiOct: makeSelectKddiOct(),
  kddiSubBrandsHosts: makeSelectkddiSubBrandsHosts()
});

export function mapDispatchToProps(dispatch) {
  return {
    fetchEligibleActions: () => {
      const headers = { "X-REQUEST-ID": uuid() };
      dispatch(Quilt.V1.fetchEligibleServices(headers));
    },
    fetchOutstandingBillsInfo: () => {
      dispatch(Billing.V4.fetchOutstandingBillsInfo());
    },
    fetchUserOrders: () => {
      dispatch(OMS.V4.fetchUserOrders({}));
    }
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  memo
)(OneStopMNPPortoutDashboardHOC(SmartRender));
