import React, { useEffect } from "react";
import Alert from "react-s-alert";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import axios from "axios";
import { Flex } from "ui-components";
import { setItem } from "utils/localStorage";
import Spinner, { styles } from "components/Spinner";
import LegacyAdyenForm from "components/LegacyAdyenForm";
import { paths } from "utils/constants";
import { gotoUrl, getChannelFromUrl } from "utils/helpers";

const LEGACY_ADYEN_FORM = ({
  isCreatingOrder,
  isFetchingPaymentRequest,
  order,
  paymentRequest,
  fetchPaymentRequest,
  token
}) => {
  const loading =
    isEmpty(order) ||
    isEmpty(paymentRequest) ||
    isCreatingOrder ||
    isFetchingPaymentRequest;
  useEffect(() => {
    const order_ref = get(order, "order_ref");
    if (order_ref && isEmpty(paymentRequest) && !isFetchingPaymentRequest) {
      fetchPaymentRequest({ order_id: order_ref, token });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, paymentRequest, isFetchingPaymentRequest]);
  const channel = getChannelFromUrl();
  const paymentReturnPath = paths.PAYMENT_RETURN.replace(":channel", channel);
  const orderSummaryPath = paths.ORDER_SUMMARY.replace(":channel", channel);
  const verifyPayment = (order, paymentRequest, paymentResponse) => {
    const orderRef = order.order_ref || paymentRequest.order_ref;
    const returnPath = `${paymentReturnPath}?order_ref=${orderRef}&token=${token}`;

    const payload = {
      payload: paymentResponse.payload,
      id: order.id,
      order_ref: orderRef
    };
    console.log("verifyPayment for order_ref", orderRef);

    if (!isEmpty(paymentRequest)) {
      axios
        .post(paymentRequest?.form_data?.verify_url, payload)
        .then(response => {
          console.log("verify payment response", response);

          if (paymentResponse.resultCode === "authorised") {
            setItem("checkDvsStatus", true);
            gotoUrl(returnPath, true, {}, true);
          } else {
            Alert.error("Payment unsuccessful please try again...");
            setTimeout(() => {
              gotoUrl(orderSummaryPath, true, {}, true);
            }, 3000);
          }
        });
    }
  };

  return (
    <>
      {loading ? (
        <Spinner size={200} sx={styles.modalContainer} />
      ) : (
        <Flex mb={4} p={2}>
          <LegacyAdyenForm
            paymentRequest={paymentRequest.form_data}
            onSuccess={paymentResponse => {
              console.log("success paymentResponse", paymentResponse);
              verifyPayment(order, paymentRequest, paymentResponse);
            }}
            onFail={paymentResponse => {
              console.log("failed paymentResponse", paymentResponse);
              verifyPayment(order, paymentRequest, paymentResponse);
            }}
          />
        </Flex>
      )}
    </>
  );
};

export default LEGACY_ADYEN_FORM;
