import { createSelector } from "reselect";
import get from "lodash/get";

const updateCardDetailsDomain = state =>
  get(state.api, "updateCardDetails.data", {});

// ***************************************************************
// **************** paymentRequest selectors **********************
// ***************************************************************

export const updateCardDetailsSelector = createSelector(
  updateCardDetailsDomain,
  updateCardDetails => {
    return updateCardDetails;
  }
);

// ***************************************************************
// *************** paymentRequest makeSelect **********************
// ***************************************************************

export const makeSelectUpdateCardDetails = () =>
  createSelector(
    updateCardDetailsSelector,
    updateCardDetails => updateCardDetails
  );
