import React, { Suspense, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { DesignCore } from "ui-components";
import history from "utils/history";
import { publicRoutes, authorizedRoutes } from "routes";
import ReactModal from "react-modal";
import map from "lodash/map";
import DataLoader from "containers/DataLoader";
import Alert from "components/Alert";
import Helmet from "components/Helmet";
import AuthorizedRoute from "components/AuthorizedRoute";
import PublicRoute from "components/PublicRoute";
import Layout from "components/Layout";
import Spinner, { styles } from "components/Spinner";
import { gotoUrl, handleRouteLoading } from "utils/helpers";
import AnalyticsFactory from "analytics/AnalyticsFactory";
import { initPushNotification } from "analytics";
import { resetLoadingApis } from "ducks/actions";
import PageError from "containers/PageError";
import { paths } from "utils/constants";
import { ADJUST_APP_ENVIRONMENT } from "utils/constants";
import AdjustAnalytics from "analytics/type/AdjustAnalytics/AdjustAnalytics";
// import { makeSelectIsEnableRestrictionRoute } from "ducks/selectors";

// import useMockAPIForDebug from "hooks/useMockAPIForDebug";
// import mockedAppSettingsAPIResponse from "__mocks__/app_settings_api.json";

// dynamic appSettings update
// import fetchRequiredDataHelper from "hooks/useFetchRequiredData";

const { ThemeProvider, theme, Global, globalStyles } = DesignCore;
ReactModal.setAppElement("#root");

function App(props) {
  // mocking app_settings api...
  // useMockAPIForDebug("/checkout/app_setting", mockedAppSettingsAPIResponse);
  const [routeLoading, setRouteLoading] = useState(true);
  const dispatch = useDispatch();
  const enableRestrictionRoute = false; //useSelector(makeSelectIsEnableRestrictionRoute());

  // For Dev testing
  // fetchRequiredDataHelper();

  useEffect(() => {
    dispatch(resetLoadingApis());
    initPushNotification(props.store);
    AnalyticsFactory.pushEvent("page_visit");
    handleRouteLoading(setRouteLoading);

    if (ADJUST_APP_ENVIRONMENT) {
      //initialize Adjust analytic
      AdjustAnalytics.initializedAdjust();
    }

    return history.listen(() => {
      AnalyticsFactory.pushEvent("page_visit");
      handleRouteLoading(setRouteLoading);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Renders the route rpovided component encapsulated with few HOCs
   * @param {object} componentProps
   * @param {ReactNode} ComponentToRender
   */
  const renderComponent = (componentProps, ComponentToRender) => {
    window.scrollTo(0, 0);
    return (
      <DataLoader
        {...componentProps}
        render={({
          loading,
          requiresMaintenance,
          OTPVerificationRequired,
          showOTPModal
        }) => {
          if (requiresMaintenance) {
            gotoUrl(paths.MAINTENANCE);
          } else if (loading || routeLoading) {
            return <Spinner size={200} sx={styles.fullPage} />;
          } else if (enableRestrictionRoute && OTPVerificationRequired) {
            return showOTPModal();
          }

          return (
            <>
              <Helmet />
              <ComponentToRender {...componentProps} />
            </>
          );
        }}
      />
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />

      <Router history={history}>
        <Suspense fallback={<Spinner size={200} sx={styles.fullPage} />}>
          <Switch>
            {map(authorizedRoutes, ({ path, Component, auth }) => {
              const ComponentToRender = Layout(Component);
              return (
                <AuthorizedRoute
                  key={path}
                  path={path}
                  auth={auth}
                  exact
                  render={props => renderComponent(props, ComponentToRender)}
                />
              );
            })}

            {map(publicRoutes, ({ path, Component }) => {
              const ComponentToRender = Layout(Component);
              return (
                <PublicRoute
                  key={path}
                  path={path}
                  exact
                  render={props => renderComponent(props, ComponentToRender)}
                />
              );
            })}

            <Route path="/:unknown" exact component={PageError} />
            <Route component={PageError} />
          </Switch>
        </Suspense>
      </Router>

      <Alert stack={{ limit: 1 }} html position="top" effect="stackslide" />
    </ThemeProvider>
  );
}

export default App;
