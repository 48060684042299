import React, { useState, useEffect, useCallback } from "react";
import Video from "components/Video";
import FormattedMessage from "components/FormattedMessage";

const VIDEO_PLAYER = ({ data, ...props }) => {
  const [showVideo, setShowVideo] = useState(false);

  const {
    video_src,
    close_icon,
    styles,
    title,
    src,
    subtitle,
    componentStyles
  } = data;

  const videoHandler = useCallback(
    event => {
      if (event.target.className === "video_container") return;
      if (showVideo) {
        setShowVideo(false);
      }
    },
    [showVideo]
  );

  useEffect(
    () => {
      window.addEventListener("click", videoHandler);
      return () => {
        window.removeEventListener("click", videoHandler);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showVideo, videoHandler]
  );
  return (
    <>
      {title && (
        <div style={{ display: "flex", marginTop: 30, ...componentStyles }}>
          <img src={src} alt="success" style={{ height: 50, width: 60 }} />
          <div>
            <FormattedMessage html id={title} />
            <div class="video_container" onClick={() => setShowVideo(true)}>
              <FormattedMessage html id={subtitle} />
            </div>
          </div>
        </div>
      )}
      {showVideo && (
        <Video
          closeIcon={close_icon}
          videoUrl={video_src}
          styles={styles}
          hidePlayer={() => setShowVideo(false)}
        />
      )}
    </>
  );
};

export default VIDEO_PLAYER;
