import { gotoUrl, randomNumber } from "utils/helpers";
import { CALL_BACK_URL, SOCIAL_MEDIA_TYPES } from "utils/constants";
import {
  setItem,
  SOCIAL_MEDIA_CLIENT_ID,
  SOCIAL_MEDIA_STATE
} from "utils/localStorage";
import { useSelector } from "react-redux";
import { makeSelectSocialMediaOptions } from "ducks/selectors";
import { useLocation } from "react-router-dom";

const useAutoRedirectToSocialLogin = socialMediaItem => {
  const autoRedirectSocialMediaItem = socialMediaItem
    ? socialMediaItem.toUpperCase()
    : null;
  const socialMediaOptions = useSelector(makeSelectSocialMediaOptions());
  const { channelId, socialLoginUrl, overrideParams = {} } =
    socialMediaOptions[autoRedirectSocialMediaItem] || {};
  const { pathname } = useLocation();

  return () => {
    if (!socialLoginUrl) {
      console.error(
        "Social media login option is not available for: ",
        autoRedirectSocialMediaItem
      );
      return gotoUrl(pathname);
    }
    const path = pathname.replace(/\//g, "z");
    const state = `${randomNumber()}${path}`;
    const defaultScope = "openid profile email";

    /**
     * OverrideParams can be used to override default values or to inject any
     * additional params.It is coming from generic.social_media_signin_options
     */
    const urlParams = {
      response_type: "code",
      client_id: channelId,
      state: state,
      scope: defaultScope,
      nonce: randomNumber(),
      redirect_uri: CALL_BACK_URL[autoRedirectSocialMediaItem],
      ...overrideParams
    };
    //store state in localstorage
    setItem(SOCIAL_MEDIA_STATE, state);
    if (
      autoRedirectSocialMediaItem === SOCIAL_MEDIA_TYPES.GOOGLE ||
      autoRedirectSocialMediaItem === SOCIAL_MEDIA_TYPES.AU_LOGIN
    ) {
      //store google,au client_id to local storage since client_id is needed to sent for auth/social-link api
      setItem(SOCIAL_MEDIA_CLIENT_ID, channelId);
    }
    return gotoUrl(socialLoginUrl, true, {}, false, urlParams);
  };
};
export default useAutoRedirectToSocialLogin;
