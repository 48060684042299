import React from "react";
import { Flex } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import PaymentCardTypeSelector from "components/PaymentCardDetails/PaymentCardTypes";
import MaskedCreditCardNumber from "components/MaskedCreditCardNumber";
import isEmpty from "lodash/isEmpty";

const getCardLayout = (display, showCardImage) => {
  if (display === "wide") {
    return showCardImage ? "space-between" : "flex-end";
  }

  return "center";
};

const PaymentCardDetails = ({
  paymentDetails: _paymentDetails,
  userProfileDetails,
  isFetchingPaymentDetails,
  isLoadingProfileData,
  cardMap,
  cardTypes,
  data = {}
}) => {
  const {
    showCardImage = true,
    noCardDetailsMessage,
    showBorder = true,
    fontWeight = 600,
    display = "wide",
    style
  } = data;
  let paymentDetails = _paymentDetails;

  if (isEmpty(paymentDetails)) {
    paymentDetails = {
      card_number: userProfileDetails?.cardDetails,
      card_type: userProfileDetails?.cardType
    };
  }

  const cardNumber = paymentDetails?.card_number;
  const cardType =
    cardMap?.[
      paymentDetails?.card_type?.toLocaleLowerCase().replace(/\s/g, "")
    ];
  const selectedCardType = cardTypes?.[cardType];
  const gaps = selectedCardType?.gapPattern || [4, 4, 4, 4];

  const styleOverrides = {
    ...(!showBorder && { boxShadow: "none" }),
    ...style
  };

  const RenderCardDetails = () => {
    return (
      <Flex
        sx={{
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.3)",
          borderRadius: "8px",
          marginBottom: "30px",
          alignItems: "center"
        }}
        style={styleOverrides}
        paddingX={display === "wide" ? 32 : 0}
        paddingY={24}
        justifyContent={getCardLayout(display, showCardImage)}
      >
        <PaymentCardTypeSelector
          types={cardTypes}
          isEnabled={showCardImage}
          display="single"
          typeOfCard={cardType}
        />
        <MaskedCreditCardNumber
          cardNumber={cardNumber}
          gaps={gaps}
          fontWeight={fontWeight}
          {...data}
        />
      </Flex>
    );
  };

  const RenderNoCardDetailsMessage = () => {
    return (
      noCardDetailsMessage && (
        <Flex
          sx={{
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
            marginBottom: "30px",
            alignItems: "center",
            justifyContent: "center"
          }}
          paddingX={32}
          paddingY={24}
        >
          <FormattedMessage id={noCardDetailsMessage} />
        </Flex>
      )
    );
  };

  if (isFetchingPaymentDetails || isLoadingProfileData) return null;

  return cardNumber ? (
    <RenderCardDetails />
  ) : noCardDetailsMessage ? (
    <RenderNoCardDetailsMessage />
  ) : null;
};

export default PaymentCardDetails;

PaymentCardDetails.defaultProps = {
  themeVariant: "main"
};
