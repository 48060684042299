import React, { useEffect, useCallback, useState } from "react";
import FormattedMessage from "components/FormattedMessage";
import Spinner, { styles } from "components/Spinner";
import { Button, Flex, Box } from "ui-components";
import history from "utils/history";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import throttle from "lodash/throttle";
import html2canvas from "html2canvas";
import * as Icons from "components/Icons";
import { safeEval, gotoUrl, goBack, isDuringOnlineHours } from "utils/helpers";
import { MANAGE_ORDER_URL } from "utils/constants";
import { COLORS, getButtonColorVariant, iconThemes } from "ui-components/theme";
import { useSelector } from "react-redux";
import { makeSelectActivationHoursDetails } from "ducks/selectors";
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";
import { Circle, IconStack, Information } from "../Icons";

const PageNavigation = props => {
  const {
    onSubmit = navigate => navigate(),
    handleSubmit,
    nextPage,
    data,
    payload = {},
    uploadScreenshot,
    form,
    screenshotEnabled,
    planTextName: planName,
    payTodayItems,
    payMonthlyItems = {},
    transactionAmount,
    formValues,
    themeColors,
    fallbackUrl,
    phoneVariant,
    phonePaymentOption,
    loading = false,
    sim_count,
    screenshot,
    clearScreenshot,
    isFetchingAnyData,
    outstandingBillAmount,
    appSettings,
    onNextClickInOffHours,
    next,
    prev,
    waitForScreenshot,
    stickyFooter,
    showSummary,
    showMonthlySummary,
    monthlySummaryLabel,
    hideSummaryTitle,
    summaryTitle,
    summaryLabel,
    summaryMessage,
    showCard,
    cardMessage,
    cardInfo,
    cardStyles = {},
    planInfo,
    hidePrevButton,
    showPhoneDetails,
    time_sensitive = {},
    otpModalOpen = false
  } = props;
  const {
    messageStyle = {},
    containerStyle = {},
    infoMessageStyle = {},
    bottomInfoMessageStyle = {}
  } = cardStyles;
  const infoTip = data?.infoTip;
  const iconColor = get(themeColors, "primary", iconThemes.blue);
  const iconForeColor = get(infoTip, "iconForeColor", "transparent");
  const ignoreLoader = get(data, "next.ignoreLoader", false);
  const isLoading = !ignoreLoader && (loading || isFetchingAnyData);
  const disableNext =
    isLoading ||
    safeEval(get, formValues, get(data, "next.disabled"), false, props) ||
    otpModalOpen;
  const nextPath = get(data, "next.path", "") || nextPage;
  const reload = get(data, "next.reload", false);
  const preserveQuery = get(data, "next.preserve", true);
  const queryOverride = get(data, "next.override", {
    reset: undefined,
    locale: undefined
  });
  const navigate = () =>
    nextPath && gotoUrl(nextPath, preserveQuery, queryOverride, reload);
  const onSubmitOverride =
    get(data, "next.onsubmit") || get(data, "next.onSubmit");
  const submit = values =>
    onSubmit(
      navigate,
      isEmpty(payload) ? values : payload,
      onSubmitOverride,
      data
    );

  const payNowPrice = get(payTodayItems, "totalPrice");
  const ignoreForm = get(data, "next.ignoreForm", false);
  const onClickTooltip = get(data, "infoTip.onClickTooltipHandler");
  const onClickToolTipHandler = onClickTooltip && get(props, onClickTooltip);
  const toolTipStyles = get(data, "infoTip.toolTipStyles");
  const hidePrevIcon =
    get(appSettings, "ui.hide_prev_icon") && !get(data, "prev.showIcon");
  const pageNavigationInfoMessage = get(data, "page_nav_info_message");

  const onNextClick =
    handleSubmit && !ignoreForm ? handleSubmit(submit) : submit;

  const {
    originalFixedPrice,
    originalFixedPriceWithoutTax,
    originalPrice,
    originalPriceWithoutTax,
    totalData,
    totalFixedPrice,
    totalFixedPriceWithoutTax,
    totalPrice,
    totalPriceWithoutTax
  } = payMonthlyItems;

  const { start, end, timezone } = useSelector(
    makeSelectActivationHoursDetails()
  );
  const [isButtonOfflineMode, setIsButtonOfflineMode] = useState(false);

  useEffect(() => {
    if (time_sensitive.enabled) {
      try {
        if (!isDuringOnlineHours(timezone, start, end)) {
          setIsButtonOfflineMode(true);
        }
      } catch (e) {
        console.log(e.message());
      }
    }
  }, [end, start, time_sensitive.enabled, timezone]);

  useEffect(() => {
    if (waitForScreenshot && get(screenshot, "doc_id")) {
      onNextClick();
      clearScreenshot();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenshot]);

  const onClickHandler = useCallback(
    (...args) => {
      if (screenshotEnabled) {
        const setScreenshotStyles = () => {
          // Update styles to fix screenshot
          const tncCheckBox = tncCheckBoxElement();
          tncCheckBox &&
            tncCheckBox.setAttribute(
              "style",
              "opacity: 1; width: 24px; height: 20px; top: 22px; z-index: 2; display: block; visibility: visible; position: relative;"
            );
          const nav = navElement();
          nav &&
            nav.setAttribute("style", "position: relative; margin-top: 77px;");
        };

        const removeScreenshotStyles = () => {
          const tncCheckBox = tncCheckBoxElement();
          tncCheckBox && tncCheckBox.setAttribute("style", "");
          const nav = navElement();
          nav && nav.setAttribute("style", "");
        };

        window.scrollTo(0, 0);
        console.log(form, " Screenshot Start", new Date());
        setScreenshotStyles();

        html2canvas(document.body)
          .then(canvas => {
            console.log(form, " Screenshot End", new Date());
            return canvas.toDataURL("image/png", 0.1);
          })
          .then(screenshot => {
            console.log(form, " Screenshot Saved", new Date());
            isFunction(uploadScreenshot) &&
              uploadScreenshot({ form, screenshot });

            if (!waitForScreenshot) onNextClick(...args);

            removeScreenshotStyles();
          })
          .catch(e => {
            console.log(form, " Screenshot Save Failed", e);

            if (!waitForScreenshot) {
              onNextClick(...args);
            }

            removeScreenshotStyles();
          });
      } else {
        if (time_sensitive.enabled) {
          if (isDuringOnlineHours(timezone, start, end)) {
            setIsButtonOfflineMode(false);
            onNextClick(...args);
          } else {
            setIsButtonOfflineMode(true);
            onNextClickInOffHours && onNextClickInOffHours(...args);
          }
        } else {
          onNextClick(...args);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [
      screenshotEnabled,
      form,
      uploadScreenshot,
      waitForScreenshot,
      onNextClick,
      time_sensitive.enabled,
      timezone,
      start,
      end,
      onNextClickInOffHours
    ]
  );

  // Here we throttle the on click so until the store gets updated user can't click multiple times. This will prevent
  // calling multiple API calls.
  const onClick = useCallback(throttle(onClickHandler, 1000), [onClickHandler]);

  const tncCheckBoxElement = () =>
    document.querySelector("input[name='terms_and_conditions_agreed']");

  const navElement = () =>
    document.querySelector("div[data-testid='page-navigation']");

  const buttonTheme = get(themeColors, "button", {});
  const primaryColor = get(buttonTheme, "color", COLORS.blue[4]);
  const messageData = {
    originalPrice,
    originalFixedPrice,
    originalPriceWithoutTax,
    originalFixedPriceWithoutTax,
    totalData,
    totalPrice,
    totalFixedPrice,
    totalPriceWithoutTax,
    totalFixedPriceWithoutTax,
    planName,
    transactionAmount,
    payNowPrice,
    sim_count,
    outstandingBillAmount
  };

  const summaryLabelLocaleKey = monthlySummaryLabel || summaryLabel;

  const renderPhoneDetails = () => (
    <Box className="summary" maxWidth="60%">
      <p className="summary-title">{get(phoneVariant, "name")}</p>
      <p className="summary-label" style={{ color: primaryColor }}>
        {get(
          phoneVariant,
          `paymentOption.${phonePaymentOption}.transactionAmount`
        )}
      </p>
      <p className="summary-message">{get(phoneVariant, "specs")}</p>
    </Box>
  );

  const renderSummary = () => (
    <Box className="summary" maxWidth="60%">
      {!hideSummaryTitle && (
        <p className="summary-title">
          {summaryTitle ? (
            <FormattedMessage id={summaryTitle} values={messageData} />
          ) : showMonthlySummary ? (
            <FormattedMessage id={"pay_monthly"} />
          ) : (
            planName
          )}
        </p>
      )}
      <p className="summary-label" style={{ color: primaryColor }}>
        {summaryLabelLocaleKey ? (
          <FormattedMessage
            id={summaryLabelLocaleKey}
            values={messageData}
            html
          />
        ) : showMonthlySummary ? (
          totalPrice
        ) : (
          transactionAmount || payNowPrice
        )}
      </p>
      <p className="summary-message">
        {summaryMessage ? (
          <FormattedMessage id={summaryMessage} values={messageData} />
        ) : (
          planInfo
        )}
      </p>
    </Box>
  );
  if (stickyFooter) {
    window.stickyFooterHeight = 85;
  }
  const ChevronLeftCircleOutline = Icons["ChevronLeftCircleOutline"];
  const prevIconMode = !hidePrevIcon && stickyFooter && window.innerWidth < 420;
  const prevButtonVariant = prevIconMode ? "icon" : "outline";
  const showPrevButton = (prev || stickyFooter) && !hidePrevButton;
  const singleButtonMode = !(
    showPrevButton ||
    showSummary ||
    showMonthlySummary ||
    showPhoneDetails
  );
  const prevPath = get(prev, "path", "");
  const reDirectPath = get(fallbackUrl, "path", MANAGE_ORDER_URL);
  const nextButtonAlign = get(next, "align");
  const nextButtonVariant = get(next, "variant", "primary");
  const nextButtonWidth = get(next, "width", "fit-content");
  const nextButtonIcon = get(next, "icon");
  const nextButtonIconSize = get(next, "iconSize") || "36px";
  const NextButtonIconComponent = Icons[nextButtonIcon];
  const navigateBack = () => {
    const reload = get(prev, "reload", false);
    prevPath
      ? gotoUrl(prevPath, false, {}, reload)
      : history.length > 1
      ? goBack()
      : gotoUrl(reDirectPath, false, {}, reload);
  };

  const PrevButton = ({ children, ...props }) => (
    <Button
      variant={prevButtonVariant}
      onClick={navigateBack}
      data-testid={"prev-button"}
      sx={getButtonColorVariant(buttonTheme, prevButtonVariant)}
      {...props}
    >
      {children}
    </Button>
  );

  const stickyPrevButtonProps = prevIconMode
    ? {}
    : {
        width: "fit-content",
        p: hidePrevIcon ? "0.5rem 1rem" : "2px",
        my: "auto",
        minHeight: hidePrevIcon ? "36px" : "44px",
        minWidth: hidePrevIcon ? "36px" : "120px"
      };

  const renderPrevButton = () =>
    stickyFooter && !singleButtonMode ? (
      <Box
        minWidth={"36px"}
        width={"fit-content"}
        style={{ visibility: !showPrevButton ? "hidden" : "visible" }}
      >
        <PrevButton {...stickyPrevButtonProps}>
          <Flex alignItems={"center"} p={"0.1rem"}>
            {hidePrevIcon ? null : (
              <ChevronLeftCircleOutline size="36px" color={primaryColor} />
            )}
            {prevIconMode ? null : (
              <FormattedMessage id={get(prev, "label", "go_back")} />
            )}
          </Flex>
        </PrevButton>
      </Box>
    ) : showPrevButton ? (
      <PrevButton>
        <FormattedMessage id={get(prev, "label")} />
      </PrevButton>
    ) : null;

  const nextButtonStyles = getButtonColorVariant(
    buttonTheme,
    nextButtonVariant,
    isButtonOfflineMode
  );

  const openNewWindowProps = next?.openNewWindow
    ? {
        as: "a",
        target: "_blank",
        href: next?.href || next?.path,
        onClick: () => {}
      }
    : {};

  const NextButton = props =>
    next ? (
      <>
        {isPlainObject(infoTip) && !isEmpty(infoTip) ? (
          <div>
            <Button
              onClick={!disableNext ? onClick : () => {}}
              disabled={disableNext}
              data-testid={"next-button"}
              width={nextButtonWidth}
              sx={nextButtonStyles}
              style={{ ...next.styles }}
              {...props}
              padding="0.25rem"
              {...openNewWindowProps}
            >
              <Flex p={0} alignItems="center" width="100%">
                {NextButtonIconComponent && (
                  <Box px={2}>
                    <NextButtonIconComponent
                      size={nextButtonIconSize}
                      color={nextButtonStyles.color}
                    />
                  </Box>
                )}
                <Box
                  mx={"auto"}
                  p={"0.25rem"}
                  pr={NextButtonIconComponent ? "1.5rem" : "0.5rem"}
                >
                  {isLoading ? (
                    <Spinner size={19} sx={styles.button} />
                  ) : (
                    <FormattedMessage id={next.label} />
                  )}
                </Box>
              </Flex>
            </Button>
            <Box
              ml={1}
              height={20}
              width={16}
              onClick={() => onClickToolTipHandler && onClickToolTipHandler()}
              style={toolTipStyles}
            >
              <IconStack size="20px">
                <Circle size="14px" color={iconForeColor} />
                <Information color={iconColor} size="20px" />
              </IconStack>
            </Box>
          </div>
        ) : (
          <>
            <Button
              onClick={!disableNext ? onClick : () => {}}
              disabled={disableNext}
              data-testid={"next-button"}
              width={nextButtonWidth}
              sx={nextButtonStyles}
              style={{ ...next.styles }}
              {...props}
              padding="0.25rem"
              {...openNewWindowProps}
            >
              <Flex p={0} alignItems="center" width="100%">
                {NextButtonIconComponent && (
                  <Box px={2}>
                    <NextButtonIconComponent
                      size={nextButtonIconSize}
                      color={nextButtonStyles.color}
                    />
                  </Box>
                )}
                <Box
                  mx={"auto"}
                  p={"0.25rem"}
                  pr={NextButtonIconComponent ? "1.5rem" : "0.5rem"}
                >
                  {isLoading ? (
                    <Spinner size={19} sx={styles.button} />
                  ) : (
                    <FormattedMessage id={next.label} />
                  )}
                </Box>
              </Flex>
            </Button>
          </>
        )}
      </>
    ) : pageNavigationInfoMessage ? (
      <>
        <Box mt={4} {...bottomInfoMessageStyle}>
          <FormattedMessage id={pageNavigationInfoMessage} html />
        </Box>
      </>
    ) : null;

  const stickyNextButtonProps = {
    width: "fit-content",
    px: "4px",
    py: "8px",
    my: "auto",
    minHeight: "36px",
    minWidth: "100px"
  };

  const renderNextButton = () =>
    stickyFooter ? (
      <Box minWidth={"36px"}>
        <NextButton {...stickyNextButtonProps} />
      </Box>
    ) : (
      <NextButton />
    );

  return (
    <Box
      variant={
        showCard
          ? "borderSection"
          : stickyFooter
          ? "stickyFooter"
          : "transparent"
      }
      data-testid={"page-navigation"}
      sx={
        showCard
          ? {
              color: primaryColor,
              borderColor: primaryColor,
              mb: "2rem",
              ...containerStyle
            }
          : {}
      }
    >
      {showCard && cardMessage && (
        <Flex
          p="0.5rem"
          justifyContent="space-around"
          fontWeight={"700"}
          {...messageStyle}
        >
          <FormattedMessage id={cardMessage} values={messageData} html />
        </Flex>
      )}
      <Flex
        justifyContent={
          singleButtonMode ? nextButtonAlign || "space-evenly" : "space-between"
        }
        alignItems={"center"}
        width={"100%"}
        maxWidth={"680px"}
        margin={"0 auto"}
      >
        {renderPrevButton()}
        {stickyFooter && (showSummary || showMonthlySummary) && renderSummary()}
        {stickyFooter && showPhoneDetails && renderPhoneDetails()}
        {renderNextButton()}
      </Flex>
      {showCard && cardInfo && (
        <Flex
          p="0.5rem"
          justifyContent="space-around"
          fontWeight={"500"}
          fontSize={"0.9rem"}
          {...infoMessageStyle}
        >
          <FormattedMessage id={cardInfo} html />
        </Flex>
      )}
    </Box>
  );
};

export default PageNavigation;
