import React from "react";

const Video = ({ styles, videoUrl, hidePlayer, closeIcon }) => {
  return (
    <div className="container max-w-2xl" style={{ ...styles }}>
      <div>
        <img
          src={closeIcon}
          style={{
            position: "absolute",
            right: "-15px",
            top: "-20px",
            width: 25,
            height: 25
          }}
          onClick={() => hidePlayer()}
          alt="close_icon"
          data-testid="video-close-icon"
        />
        <iframe
          width="100%"
          height="300"
          src={videoUrl}
          title="bill education"
          style={{ borderRadius: 10 }}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export default Video;
