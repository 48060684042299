import { fetchRequested } from "ducks/actions/baseActions";
import {
  BASE,
  SAVE_AND_GET_CARD_DETAILS,
  PROCESS_CARD_UPDATE_PAYMENT_METHOD,
  PAYMENT_METHOD_SWITCH_OR_DELETE
} from "utils/constants";

export const paymentMethodSwitchOrDelete = (params, callback) => {
  return fetchRequested({
    key: "switchOrDeletePaymentMethod",
    base: BASE.PAYMENTS_SERVICE,
    params,
    path: PAYMENT_METHOD_SWITCH_OR_DELETE,
    method: "POST",
    callback
  });
};

export const saveAndGetPaymentCardDetails = (params, callback) => {
  return fetchRequested({
    key: "paymentDetails",
    base: BASE.PAYMENTS_SERVICE,
    params,
    path: SAVE_AND_GET_CARD_DETAILS,
    method: "POST",
    callback
  });
};

export const processCardUpdatePayment = (params, callback) => {
  return fetchRequested({
    key: "processPayment",
    base: BASE.PAYMENTS,
    params,
    path: PROCESS_CARD_UPDATE_PAYMENT_METHOD,
    method: "POST",
    version: "v3",
    callback
  });
};
export default {
  saveAndGetPaymentCardDetails,
  processCardUpdatePayment,
  paymentMethodSwitchOrDelete
};
