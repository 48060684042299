import React from "react";
import { constructOnChangeHandler, evalProp } from "utils/helpers";
import DropdownField from "components/FormFields/DropdownField";

const DROPDOWN_FIELD = ({ options, data, ...props }) => {
  return (
    <DropdownField
      {...props}
      {...data}
      options={evalProp(props, options, [])}
      onChange={constructOnChangeHandler(props)}
    />
  );
};

export default DROPDOWN_FIELD;
