import { createSelector } from "reselect";
import get from "lodash/get";
import { constructPortInNumberValidation } from "utils/helpers";

export const validatePortInNumberDomain = state =>
  get(state, "api.validatePortInNumber", {});

export const validatePortInNumberSelector = createSelector(
  validatePortInNumberDomain,
  constructPortInNumberValidation
);

export const makeSelectPortInNumberValidation = () =>
  validatePortInNumberSelector;
