import React from "react";
import map from "lodash/map";
import FormattedMessage from "components/FormattedMessage";
import { Field } from "redux-form";
import { Flex, Box, Text, Label as RebassLabel } from "ui-components";
import Label from "./Label";
import { RadioboxBlank, RadioboxMarked } from "components/Icons";
import FieldError from "./FieldError";
import get from "lodash/get";
import find from "lodash/find";
import StaticTooltip from "../StaticTooltip";

export const Radio = props => {
  return <input {...props} type="radio" />;
};

const RadioOptions = props => {
  const {
    input,
    meta: { touched, error },
    options,
    render,
    width,
    iconColor,
    disabled,
    readonly,
    border,
    margin,
    borderRadius,
    padding
  } = props;

  const selectedOption = find(
    options,
    o => o.id.toString() === input.value.toString()
  );
  const selectedMessageId = get(selectedOption, "selected.message");
  const selectedMessagePosition = get(selectedOption, "selected.position");
  const radioButtonBoxStyle = {
    color: iconColor,
    borderColor: iconColor,
    mb: "2rem",
    borderRadius: borderRadius,
    margin: margin,
    padding: padding
  };
  return (
    <>
      <Flex flexWrap="wrap">
        {map(options, o => {
          const checked = o.id.toString() === input.value.toString();

          return (
            <Box
              variant=""
              sx={radioButtonBoxStyle}
              key={o.id}
              alignItems="center"
              width={width}
              style={{
                border: checked ? border[0] : border[1]
              }}
            >
              <RebassLabel
                my={2}
                style={{
                  cursor: disabled ? "default" : "pointer",
                  opacity: disabled && 0.4
                }}
              >
                <Radio
                  {...input}
                  value={o.id}
                  checked={checked}
                  disabled={disabled || readonly}
                />
                <Box width="28px">
                  {checked ? (
                    <RadioboxMarked
                      size="28px"
                      style={{ margin: 0 }}
                      color="red"
                    />
                  ) : (
                    <RadioboxBlank
                      size="28px"
                      style={{ margin: 0 }}
                      color="#333"
                    />
                  )}
                </Box>

                <Text
                  style={{
                    wordBreak: "break-word",
                    padding: "0.5rem 1rem",
                    fontWeight: "500"
                  }}
                >
                  {render ? render(o) : <FormattedMessage id={o.label} />}
                </Text>
              </RebassLabel>
            </Box>
          );
        })}
      </Flex>
      {selectedMessageId && (
        <StaticTooltip
          content={selectedMessageId}
          position={selectedMessagePosition}
        />
      )}
      <Box>
        <FieldError touched={touched} error={error} />
      </Box>
    </>
  );
};

const RadioGroupProviders = props => {
  const {
    children,
    border,
    options,
    field,
    label,
    required,
    render,
    width,
    margin,
    borderRadius,
    padding,
    themeColors,
    disable_on_select,
    messageData,
    disabled,
    hidden,
    readonly,
    isHTMLText
  } = props;

  const iconColor = get(themeColors, "icon");
  const isDisabled =
    disabled ||
    readonly ||
    (disable_on_select && get(messageData, "telcoType") ? true : false);

  if (!field) {
    return null;
  }

  return (
    <Box mb={8} hidden={hidden} data-testid={field}>
      {label &&
        (isHTMLText ? (
          <Text>
            <FormattedMessage html id={label} />
          </Text>
        ) : (
          <Label label={<FormattedMessage id={label} />} required={required} />
        ))}

      <Field
        component={RadioOptions}
        name={field}
        required={required}
        options={options}
        render={render}
        width={width}
        iconColor={iconColor}
        disabled={isDisabled}
        hidden={hidden}
        readonly={readonly}
        border={border}
        margin={margin}
        borderRadius={borderRadius}
        padding={padding}
      />
      {children}
    </Box>
  );
};

RadioGroupProviders.defaultProps = {
  width: 1,
  options: [],
  label: "",
  isHTMLText: false
};

export default RadioGroupProviders;
