import React, { memo, useEffect } from "react";
import { compose } from "redux";
import { v4 as uuid } from "uuid";
import { connect, useSelector } from "react-redux";
import {
  makeSelectActivationHoursDetails,
  makeSelectAuthToken,
  makeSelectIsModalView,
  makeSelectModalType,
  makeSelectPinChangeEligibility,
  makeSelectHasRequestedEligibility,
  makeSelectIsFetchingData
} from "ducks/selectors";
import SmartRender from "containers/SmartRender/index";
import { gotoUrl, isDuringOnlineHours, urlParam } from "utils/helpers";
import { paths } from "utils/constants";
import { getDeviceId, isWebview } from "utils/localStorage";
import { setData } from "ducks/actions";
import { createStructuredSelector } from "reselect";
import { Quilt } from "ducks/actions";
import Spinner, { styles } from "components/Spinner";

const ConfirmPinChange = Component =>
  function Comp(props) {
    const {
      hasRequestedEligibility,
      eligibleForPINChange,
      fetchEligibleActions,
      showNotEligiblePopup,
      hideModal,
    } = props;
    const { start, end, timezone } = useSelector(
      makeSelectActivationHoursDetails()
    );
    const authToken = useSelector(makeSelectAuthToken());

    const isNonServiceHour = () => {
      try {
        if (!isDuringOnlineHours(timezone, start, end)) {
          return true;
        }
      } catch (e) {
        console.log(e);
      }
      return false;
    };

    useEffect(() => {
      fetchEligibleActions();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      hideModal();
      if (hasRequestedEligibility && !eligibleForPINChange) {
        showNotEligiblePopup();
      } else if (isNonServiceHour()) {
        //Show non service hour  modal
        props.showOutOfServiceModal();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasRequestedEligibility]);

    const navigateToProfile = () => {
      props.hideModal();
      if (isWebview()) {
        const mobilePage = urlParam("return_url") || paths.CLOSEWEBVIEW;
        const mobileParams = {
          device_id: getDeviceId(),
          auth_token: authToken
        };
        return gotoUrl(mobilePage, false, {}, true, mobileParams);
      }
      return gotoUrl(paths.PROFILE);
    };

    if (props.isActionInProgress) return <Spinner size={200} sx={styles.fullPage} />;
    return <Component {...props} navigateToProfile={navigateToProfile} />;
  };

const mapStateToProps = createStructuredSelector({
  isModalView: makeSelectIsModalView(),
  modalType: makeSelectModalType(),
  eligibleForPINChange: makeSelectPinChangeEligibility(),
  hasRequestedEligibility: makeSelectHasRequestedEligibility(),
  isActionInProgress: makeSelectIsFetchingData([
    "eligibilitySettings"
  ]),
});

export function mapDispatchToProps(dispatch) {
  return {
    fetchEligibleActions: () => {
      const headers = { "X-REQUEST-ID": uuid() };
      dispatch(Quilt.V1.fetchEligibleServices(headers));
    },
    showOutOfServiceModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "OutOfServiceHour" }
        })
      );
    },
    hideModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
    },
    showNotEligiblePopup: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "notEligibleForPINUpdate" }
        })
      );
    },
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  memo
)(ConfirmPinChange(SmartRender));
