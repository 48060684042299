import React from "react";
import { Box } from "ui-components";
import FAQ from "components/FAQ";

const FAQ_LIST = ({ data }) => (
  <Box mt={8}>
    <FAQ {...data} />
  </Box>
);

export default FAQ_LIST;
