import React, { useState } from "react";
import ReactModal from "react-modal";
import { Box, Flex, H1, Button } from "ui-components";
import FormattedMessage from "components/FormattedMessage";

const SCHEDULE_NUMBER_CHANGE_MODAL = props => {
  const [showModal, setShowModal] = useState(false);

  const {
    styles,
    handleSubmit,
    submitNumberChange,
    orderRef,
    selectedNumber,
    oldPhoneNumber,
    invalid
  } = props;

  const submit = values => {
    submitNumberChange({
      orderRef,
      selectedNumber,
      oldPhoneNumber,
      ...values
    });
  };

  return (
    <>
      <Flex justifyContent="center">
        <FormattedMessage id="order_action_change_number_schedule_number_change">
          {msg => (
            <Button onClick={() => setShowModal(true)} disabled={invalid}>
              {msg}
            </Button>
          )}
        </FormattedMessage>
      </Flex>

      <ReactModal style={styles.modalStyle} isOpen={showModal}>
        <Box mb={8}>
          <FormattedMessage id="order_action_change_number_please_confirm_change_number">
            {msg => <H1>{msg}</H1>}
          </FormattedMessage>
        </Box>

        <Box>
          <FormattedMessage
            id="order_action_change_number_old_phone_number_to_new_phone_number"
            values={{ oldPhoneNumber, newPhoneNumber: selectedNumber }}
          />
        </Box>

        <Flex justifyContent="center">
          <Button onClick={handleSubmit(submit)}>
            <FormattedMessage id="confirm" />
          </Button>
        </Flex>
      </ReactModal>
    </>
  );
};

export default SCHEDULE_NUMBER_CHANGE_MODAL;
