import React, { useEffect } from "react";
import { setPostOfficeAddressAsDeliveryAddress } from "utils/helpers";
import PostOfficePickUp from "components/PostOfficePickUp";

const STORE_PICKER_1 = ({
  change,
  fetchPostOfficeDeliverySlots,
  postOfficeDeliverySlots,
  postOfficeRegions,
  selectedNumber,
  selectedPostOffice
}) => {
  useEffect(
    () => {
      if (selectedPostOffice)
        setPostOfficeAddressAsDeliveryAddress(change, selectedPostOffice);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPostOffice]
  );

  return (
    <PostOfficePickUp
      regions={postOfficeRegions}
      selectedNumber={selectedNumber}
      fetchPostOfficeDeliverySlots={fetchPostOfficeDeliverySlots}
      onRegionChange={({ value }) => {
        const params = {
          number: selectedNumber,
          portin: false,
          selectedRegion: value,
          type: "sim-only"
        };
        fetchPostOfficeDeliverySlots(params);
      }}
      deliverySlots={postOfficeDeliverySlots}
    />
  );
};

export default STORE_PICKER_1;
