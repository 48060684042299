import { createSelector } from "reselect";
import get from "lodash/get";

const ESimQRDomain = state => get(state, "api.esimQR");
const SimActivationStatusDomain = state =>
  get(state, "api.simActivationStatus");

// --- ESim selectors ---
const isQRFetching = createSelector(ESimQRDomain, esimQR =>
  get(esimQR, "loading")
);
const activateESimQRImage = createSelector(ESimQRDomain, esimQR => {
  const base64Flag = "data:image/jpeg;base64,";
  const byteStreamString = get(esimQR, "data");

  return `${base64Flag}${byteStreamString}`;
});

const hasFetchedESimQRSelector = createSelector(
  ESimQRDomain,
  esimQR => !!get(esimQR, "data")
);

// --- Sim Activation selectors ---
// TODO; Make required changes once the api details are available
const simActivated = createSelector(SimActivationStatusDomain, simActivation =>
  get(simActivation, "data.active")
);

export const makeSelectEsimQRImage = () => activateESimQRImage;
export const makeSelectIsEsimQRFetching = () => isQRFetching;
export const makeSelectIsSimActivated = () => simActivated;
export const makeSelectHasFetchedESimQR = () => hasFetchedESimQRSelector;
