import React from "react";
import get from "lodash/get";
import LocationField from "components/FormFields/LocationField";
import { setAddress, resetAddressFields } from "utils/helpers";

const GOOGLE_PREDICT_FIELD = ({
  change,
  formValues,
  type = "delivery",
  validatePostalCode,
  data,
  ...props
}) => {
  const selectedDeliveryMethod = get(formValues, "delivery_method", {});
  if (!selectedDeliveryMethod.google_predict) return null;
  const skipValidatePostalCode = get(data, "skipValidatePostalCode");
  const validatePostalCodeMethod = !skipValidatePostalCode
    ? validatePostalCode
    : () => {};
  return (
    <LocationField
      onSelect={address => {
        change(props.field, address);
        setAddress(change, type, address, validatePostalCodeMethod, data);
      }}
      onError={() => {
        change(
          `${type}_location_error`,
          "click_here_to_fill_in_your_address_manually"
        );
        resetAddressFields(change, type, [`${type}_location`]);
      }}
      {...props}
    />
  );
};

export default GOOGLE_PREDICT_FIELD;
