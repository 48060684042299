import { call, put } from "redux-saga/effects";
import { requestDataStream } from "utils/request";
import { fetchSucceeded } from "ducks/actions";
import Alert from "react-s-alert";

/**
 * Dedicated function to fetch a stream of data
 * @param {string} key api key to be used to store date in redux state
 * @param {boolean} override override existing value
 * @param {string} requestURL API request url
 * @param {object} options API options
 * @param {Function} callback callback function
 */
function* fetchMedia(
  key,
  override,
  replace,
  requestURL,
  options,
  callback,
  base64conversion
) {
  let response = {};
  try {
    response = yield call(
      requestDataStream,
      requestURL,
      options,
      base64conversion
    );

    const { success, result } = response || {};
    if (success) {
      yield put(fetchSucceeded({ key, data: result, override, replace }));
      callback && callback(result);
    }
  } catch (error) {
    const errorResponse = error.response ? JSON.parse(error.response) : {};
    const errorBody = errorResponse.result;
    callback && callback({}, error.message, errorBody);
    Alert.error(error);
  }
}

export default fetchMedia;
