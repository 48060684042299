import React from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import PaymentMethodPicker from "components/PaymentMethodPicker";
import { gotoUrl, safeEval } from "utils/helpers";

const PAYMENT_METHOD_PICKER_2 = props => {
  const {
    change,
    handleSubmit,
    formValues,
    generic,
    field = "payment_method",
    isFetchingPaymentRequest,
    data,
    transactionDetails,
    payTodayItems,
    orderParams,
    themeColors,
    paymentsPayload = {},
    onSubmit
  } = props;
  const selectedValue = get(formValues, `${field}.value`, "");
  const paymentMethods = get(generic, "payment_methods", {});
  // Billing transaction returns amount in cents
  const transactionAmount = (get(transactionDetails, "amount") || 0) / 100;
  const payTodayAmount = get(payTodayItems, "totalAmount");
  const totalAmount = transactionAmount || payTodayAmount || 0;
  let onClick = () => {};

  const { supportedPaymentMethods } = data;
  let allowedPaymentMethods = {};

  // filter out supported payment methods from the set of paymentMethods defined in generic config
  if (!isEmpty(supportedPaymentMethods) && !isEmpty(paymentMethods)) {
    supportedPaymentMethods.forEach(method => {
      if (paymentMethods[method.name]) {
        allowedPaymentMethods[method.name] = {
          ...paymentMethods[method.name],
          /*
            method object contains the following object
            method: {
              action: <"submit" | "redirect"> // if it's a submit we are going use onSubmit action and for redirect we then check whether path key is available
              path: <url> // if the path key is available then redirect user to that path
            }
          */
          ...method
        };
      }
    });
  } else {
    allowedPaymentMethods = paymentMethods;
  }

  // Payment method picker has 2 main types, "button" and "radio". If the type is button we need to fire onSubmit action immediately.
  // If it's a radio type then we store the payment method in redux form.
  if (data.type === "button") {
    onClick = paymentMode => {
      // set the clicked payment method
      change(field, paymentMode);

      if (paymentMode?.action === "submit") {
        handleSubmit(values => {
          onSubmit(
            isEmpty(paymentsPayload) ? values : paymentsPayload,
            paymentMode
          );
        })();
      } else if (paymentMode?.action === "redirect") {
        gotoUrl(paymentMode.path);
      } else if (paymentMode?.action === "onClickMethodCall") {
        safeEval(get, {}, paymentMode.onClick, "", props);
      }
    };
  }

  return (
    <PaymentMethodPicker
      selectedValue={selectedValue}
      onClick={onClick}
      onSelect={paymentMode => {
        change(field, paymentMode);
      }}
      paymentMethods={allowedPaymentMethods}
      isFetchingPaymentRequest={isFetchingPaymentRequest}
      themeColors={themeColors}
      payTodayItems={payTodayItems}
      transactionDetails={transactionDetails}
      totalAmount={totalAmount}
      formValues={formValues}
      orderParams={orderParams}
      {...data}
    />
  );
};

export default PAYMENT_METHOD_PICKER_2;
