import React from "react";
import get from "lodash/get";
import DropdownField from "components/FormFields/DropdownField";
import { constructOnChangeHandler } from "utils/helpers";

const TERMINATION_REASON = ({ orderDetails, data, ...props }) => {
  const terminationReasons = get(orderDetails, "terminationReasons", []);
  return (
    <DropdownField
      options={terminationReasons}
      {...props}
      {...data}
      onChange={constructOnChangeHandler(props)}
    />
  );
};

export default TERMINATION_REASON;
