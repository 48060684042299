import React from "react";
import FormattedMessage from "components/FormattedMessage";
import Select, { components } from "react-select";
import { Field } from "redux-form";
import moment from "moment";
import get from "lodash/get";
import filter from "lodash/filter";
import isArray from "lodash/isArray";
import { Box, Flex } from "ui-components";
import Label from "./Label";
import FieldError from "./FieldError";
import { COLORS, iconThemes, selectOptionStyles } from "ui-components/theme";
import { compactJoin, evalProp } from "utils/helpers";
import { ChevronDown, ChevronUp } from "components/Icons";
import { DOB_FORMAT, DOB_SEPARATOR } from "utils/constants";

let dobDay;
let dobMonth;
let dobYear;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? COLORS.white : COLORS.gray[3],
    ...selectOptionStyles.option
  }),
  placeholder: provided => ({
    ...provided,
    color: COLORS.gray[0]
  }),
  indicatorSeparator: () => {
    return { width: 0 };
  },
  dropdownIndicator: props => {
    return {
      ...props,
      ...selectOptionStyles.dropdownIndicator
    };
  },
  control: props => {
    return {
      ...props,
      ...selectOptionStyles.control
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    const overflow = "visible";

    return { ...provided, opacity, transition, overflow };
  }
};

const DropdownIndicator = props => {
  const menuIsOpen = get(props, "selectProps.menuIsOpen");
  const iconColor = get(props, "selectProps.iconColor");
  return (
    <components.DropdownIndicator {...props}>
      {menuIsOpen ? (
        <ChevronUp color={iconColor} />
      ) : (
        <ChevronDown color={iconColor} />
      )}
    </components.DropdownIndicator>
  );
};

const Input = props => {
  const { autoComplete = props.autoComplete } = props.selectProps;
  return (
    <components.Input
      {...props}
      autoComplete={autoComplete}
      name={autoComplete}
    />
  );
};

const SelectInput = ({
  isSearchable,
  input,
  options,
  name,
  id,
  placeholder,
  iconColor,
  isDisabled,
  dataTestId,
  autoCompleteFieldName,
  isAutoComplete
}) => {
  return (
    <Select
      dataTestId={dataTestId}
      styles={customStyles}
      {...input}
      autoComplete={isAutoComplete ? autoCompleteFieldName : "off"}
      id={id}
      name={name}
      options={options}
      value={input.value}
      onChange={value => input.onChange(value)}
      onBlur={() => { }}
      isSearchable={isSearchable}
      placeholder={<FormattedMessage id={placeholder} />}
      components={{ DropdownIndicator, Input }}
      iconColor={iconColor}
      className={input?.name}
      classNamePrefix={input?.name}
      isDisabled={isDisabled}
    />
  );
};

const renderDobError = ({ meta: { touched, error } }) => (
  <FieldError touched={touched} error={error} />
);

const renderAgeError = ({ values, meta: { error } }) => (
  <FieldError touched error={error} values={values} />
);

const renderDay = (props, change, dateOptions) => {
  const dayField = compactJoin([props.field_prefix, "dobDay"], "_");
  const iconColor = get(props, "themeColors.icon", iconThemes.blue);
  return (
    <Box key={dayField} width={1 / 3} px={2} data-testid={dayField}>
      <Field
        dataTestId="day-field"
        ref={input => (dobDay = input)}
        isSearchable={true}
        name={dayField}
        options={dateOptions}
        component={SelectInput}
        onChange={val => change && onChange(change, dayField, val, props)}
        placeholder={
          props.day_placeholder ? props.day_placeholder : "calendar_day"
        }
        iconColor={iconColor}
        isDisabled={props.disabled}
        autoCompleteFieldName={dayField}
        isAutoComplete={props.autoComplete}
      />
    </Box>
  );
};

const renderMonth = (props, change, monthOptions) => {
  const monthField = compactJoin([props.field_prefix, "dobMonth"], "_");
  const iconColor = get(props, "themeColors.icon", iconThemes.blue);
  return (
    <Box key={monthField} width={1 / 3} px={2} data-testid={monthField}>
      <Field
        dataTestId="month-field"
        ref={input => (dobMonth = input)}
        isSearchable={true}
        name={monthField}
        options={monthOptions}
        component={SelectInput}
        onChange={val => change && onChange(change, monthField, val, props)}
        placeholder={
          props.month_placeholder ? props.month_placeholder : "calendar_month"
        }
        iconColor={iconColor}
        isDisabled={props.disabled}
        autoCompleteFieldName={monthField}
        isAutoComplete={props.autoComplete}
      />
    </Box>
  );
};

const renderYear = (props, change, yearOptions) => {
  const yearField = compactJoin([props.field_prefix, "dobYear"], "_");
  const iconColor = get(props, "themeColors.icon", iconThemes.blue);
  return (
    <Box key={yearField} width={1 / 3} px={2} data-testid={yearField}>
      <Field
        dataTestId="year-field"
        ref={input => (dobYear = input)}
        isSearchable={true}
        name={yearField}
        options={yearOptions}
        component={SelectInput}
        onChange={val => change && onChange(change, yearField, val, props)}
        placeholder={
          props.year_placeholder ? props.year_placeholder : "calendar_year"
        }
        iconColor={iconColor}
        isDisabled={props.disabled}
        autoCompleteFieldName={yearField}
        isAutoComplete={props.autoComplete}
      />
    </Box>
  );
};

const validateDOB = (dob = "") => {
  const age = moment().diff(moment(dob, DOB_FORMAT), "years");
  return isNaN(age) ? "error_invalid_date_of_birth" : undefined;
};

const onChange = (change, key, value, props) => {
  let updatedDobDay = get(dobDay, "value.value");
  let updatedDobMonth = get(dobMonth, "value.value");
  let updatedDobYear = get(dobYear, "value.value");

  const dayField = compactJoin([props.field_prefix, "dobDay"], "_");
  const monthField = compactJoin([props.field_prefix, "dobMonth"], "_");
  const yearField = compactJoin([props.field_prefix, "dobYear"], "_");

  const ageField = compactJoin([props.field_prefix, "age"], "_");
  const dobField = compactJoin([props.field_prefix, "dob"], "_");

  switch (key) {
    case dayField:
      updatedDobDay = value.value;
      break;
    case monthField:
      updatedDobMonth = value.value;
      break;
    case yearField:
      updatedDobYear = value.value;
      break;
    default:
  }

  const dob = compactJoin(
    [updatedDobDay, updatedDobMonth, updatedDobYear],
    DOB_SEPARATOR
  );
  const age = moment().diff(moment(dob, DOB_FORMAT), "years");

  const isDOBValid = moment(dob, DOB_FORMAT, true).isValid();
  if (isDOBValid) {
    change(ageField, age);
    change(dobField, dob.toString());
    // GA4EventsService.publish(triggerDOBChangeGA4Event(props.GA4EventPlanDetails));
  } else {
    change(ageField, undefined);
    change(dobField, null);
    updatedDobMonth && updatedDobYear && change(dayField, null);
  }
  props.on_change && evalProp(props, props.on_change);
};

const DateOfBirthField = ({
  required,
  change,
  label,
  field,
  minimumAge,
  dateOptions,
  monthOptions,
  yearOptions,
  tooltip,
  locale,
  yearFirst,
  ...props
}) => {
  const ageField = compactJoin([props.field_prefix, "age"], "_");
  const dobField = compactJoin([props.field_prefix, "dob"], "_");
  const monthField = compactJoin([props.field_prefix, "dobMonth"], "_");
  const yearField = compactJoin([props.field_prefix, "dobYear"], "_");
  const labelMessage = get(props, "data.labelMessage");
  const month = get(props, ["formValues", monthField, "value"]);
  const year = get(props, ["formValues", yearField, "value"]);
  let validDateOptions = dateOptions;
  if (year && month) {
    validDateOptions = filter(dateOptions, date => {
      const dob = `${date?.value}-${month}-${year}`;
      const valid = moment(dob, DOB_FORMAT, true).isValid();
      return valid;
    });
  }

  const fieldOrderMap = {
    day: renderDay(props, change, validDateOptions),
    month: renderMonth(props, change, monthOptions),
    year: renderYear(props, change, yearOptions)
  };

  const fieldOrder = get(
    props,
    ["data", "fieldOrder"],
    isArray(get(props, "fieldOrder"))
      ? get(props, "fieldOrder")
      : yearFirst
        ? props.yearFirstFieldOrder
        : props.defaultFieldOrder
  );

  return (
    <Box mb={8}>
      <Label
        label={<FormattedMessage id={label} />}
        required={required}
        tooltip={tooltip}
        labelMessage={labelMessage}
        messageData={{ age: minimumAge }}
      />

      <Flex mx={-2} mt={-2}>
        {fieldOrder.map(fieldType => fieldOrderMap[fieldType])}
      </Flex>

      <Field
        name={dobField}
        component={renderDobError}
        validate={validateDOB}
      />
      <Field
        name={ageField}
        component={renderAgeError}
        values={{ age: minimumAge }}
      />
    </Box>
  );
};

DateOfBirthField.defaultProps = {
  validate: [],
  label: "manage_order_date_of_birth",
  defaultFieldOrder: ["day", "month", "year"],
  yearFirstFieldOrder: ["year", "month", "day"],
  disabled: false,
  autoComplete: false
};

export default DateOfBirthField;


/**
const triggerDOBChangeGA4Event = (
  details
) => {

  if (!details) return {};

  const {
    customer_id,
    plan_name,
    plan_type,
    sim_type,
    order_ref,
    journey_type,
    number_type
  } = details;

  return {
    event: GA4EVENTS.DOB_SUBMITTED,
    ecommerce: {
      customer_id,
      order_ref,
      plan_name,
      journey_type,
      plan_type,
      sim_type,
      number_type,
    }
  };
};
*/