import get from "lodash/get";
import { createSelector } from "reselect";

const paymentUIDomain = state =>
  get(state, "ui.payment.pollingPaymentDetails", false);

export const pollingPaymentStatusSelector = createSelector(
  paymentUIDomain,
  value => {
    return value || false;
  }
);

export const makeSelectPollingPaymentStatus = () =>
  pollingPaymentStatusSelector;
