import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, Text } from "ui-components";
import OutstandingInvoiceItem from "components/OutstandingInvoiceItem";
import map from "lodash/map";

const OutstandingInvoiceCard = ({ themeVariant, data, title, invoices }) => {
  const {
    showCardTitle = true,
    padding,
    rowPadding,
    showBorder = true,
    noLastRowBorder = false
  } = data;
  const layoutOverrides = {
    ...(padding && { p: padding })
  };

  const styleOverrides = {
    ...(!showBorder && { boxShadow: "none" })
  };

  const renderInvoiceItem = () => {
    return map(invoices, (invoiceData, index) => {
      const {
        invoice,
        formattedPrice,
        formattedDate,
        period,
        amount
      } = invoiceData;
      return (
        <OutstandingInvoiceItem
          key={invoice}
          formattedDate={formattedDate || period}
          formattedAmount={formattedPrice || amount}
          rowPadding={rowPadding}
          {...(noLastRowBorder && { noBorder: index === invoices.length - 1 })}
        />
      );
    });
  };

  return (
    <Box variant={themeVariant} style={styleOverrides} {...layoutOverrides}>
      <Flex width={1} flexDirection={"column"}>
        {showCardTitle && (
          <Flex>
            <Text mb={8} width={[1]} lineHeight={1.2}>
              <FormattedMessage id={title} />
            </Text>
          </Flex>
        )}

        <Flex flexDirection={"column"}>{renderInvoiceItem()}</Flex>
      </Flex>
    </Box>
  );
};

OutstandingInvoiceCard.defaultProps = {
  title: "outstanding_bills_invoice_card_title",
  data: {},
  themeVariant: "main",
  invoices: []
};

export default OutstandingInvoiceCard;
