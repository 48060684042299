import { createSelector } from "reselect";
import get from "lodash/get";
import { forms } from "utils/constants";

const phonesDomain = state =>
  get(state, `form.${forms.PHONES_FORM}.values`, {});

// ***************************************************************
// ************** PhonesForm selectors ******************
// ***************************************************************

export const phonesFormSelector = createSelector(phonesDomain, phonesForm => {
  return phonesForm;
});

export const phoneVariantSelector = createSelector(phonesDomain, phonesForm => {
  return get(phonesForm, "phoneVariant");
});

export const phonePaymentOptionSelector = createSelector(
  phonesDomain,
  phonesForm => {
    return get(phonesForm, "phonePaymentOption");
  }
);

// ***************************************************************
// *************** PhonesForm makeSelect *******************
// ***************************************************************

export const makeSelectPhonesForm = () => phonesFormSelector;

export const makeSelectPhoneVariant = () => phoneVariantSelector;

export const makeSelectPhonePaymentOption = () => phonePaymentOptionSelector;
