import { createSelector } from "reselect";
import get from "lodash/get";
import map from "lodash/map";
import { urlParam } from "utils/helpers";
import {
  formatDisplayNumber,
  formatCurrency,
  getNumberType
} from "utils/helpers";
import { numberFormatSelector, currencyFormatSelector } from "./appSettings";
import { selectedNumberTypeSelector } from "./order";
import { SIM_TYPES, NUMBER_TYPES } from "utils/constants";

const numbersDomain = state => get(state, "api.numbers.data", {});

const selectedSimTypeSelector = () =>
  urlParam("sim_type") === SIM_TYPES.ESIM
    ? SIM_TYPES.ESIM
    : SIM_TYPES.PHYSICALSIM;

export const preCheckoutSelector = state =>
  get(state, "form.PreCheckoutForm.values", {});

export const numberTypeSelector = () =>
  urlParam("number_type") === NUMBER_TYPES.PORT_IN
    ? NUMBER_TYPES.PORT_IN
    : NUMBER_TYPES.NEW_NUMBER;

const selectedNumberDomain = state => {
  return get(state, "api.selectedNumber.data");
};

// ***************************************************************
// ********************** numbers selectors **********************
// ***************************************************************

export const selectedNumberSelector = createSelector(
  selectedNumberDomain,
  numbersDomain,
  (selectedNumber, numbers) => {
    return selectedNumber || get(numbers, "selected_number");
  }
);

export const availableNumbersSelector = createSelector(
  numbersDomain,
  numbers => {
    return get(numbers, "available_numbers.numbers");
  }
);

export const numberLockErrorSelector = createSelector(
  numbersDomain,
  numbers => {
    return get(numbers, "error");
  }
);

export const numberSeriesSelector = createSelector(numbersDomain, numbers => {
  return get(numbers, "series");
});

export const silverNumbersSelector = createSelector(numbersDomain, numbers => {
  return get(numbers, "silver_numbers.numbers");
});

export const goldenNumbersSelector = createSelector(numbersDomain, numbers => {
  return get(numbers, "golden_numbers.numbers");
});

export const silverNumbersPriceSelector = createSelector(
  numbersDomain,
  numbers => {
    return get(numbers, "silver_numbers.price");
  }
);

export const goldenNumbersPriceSelector = createSelector(
  numbersDomain,
  numbers => {
    return get(numbers, "golden_numbers.price");
  }
);

// ***************************************************************
// ********************* numbers makeSelect **********************
// ***************************************************************

export const makeSelectAvailableNumbers = () =>
  createSelector(
    numberFormatSelector,
    availableNumbersSelector,
    selectedNumberSelector,
    (format, numbers, selectedNumber) => {
      return map(numbers, number => {
        return {
          id: number,
          label: formatDisplayNumber(number, format),
          value: number,
          selected: number === get(selectedNumber, "number")
        };
      });
    }
  );

export const makeSelectSilverNumbers = () =>
  createSelector(
    numberFormatSelector,
    silverNumbersSelector,
    selectedNumberSelector,
    (format, numbers, selectedNumber) => {
      return map(numbers, number => {
        return {
          id: number,
          label: formatDisplayNumber(number, format),
          value: number,
          selected: number === get(selectedNumber, "number")
        };
      });
    }
  );

export const makeSelectGoldenNumbers = () =>
  createSelector(
    numberFormatSelector,
    goldenNumbersSelector,
    selectedNumberSelector,
    (format, numbers, selectedNumber) => {
      return map(numbers, number => {
        return {
          id: number,
          label: formatDisplayNumber(number, format),
          value: number,
          selected: number === get(selectedNumber, "number")
        };
      });
    }
  );

export const makeSelectSelectedNumber = () => selectedNumberSelector;

export const formattedSelectedNumberSelector = createSelector(
  numberFormatSelector,
  currencyFormatSelector,
  selectedNumberSelector,
  selectedNumberTypeSelector,
  (numberFormat, currencyFormat, selectedNumber, selectedNumberType) => {
    const { number, price, sku } = selectedNumber || {};
    const { isPortIn } = selectedNumberType || {};
    return {
      sku,
      number,
      price,
      type: getNumberType({ sku, isPortIn }),
      formattedNumber: formatDisplayNumber(number, numberFormat),
      formattedPrice: formatCurrency(price, currencyFormat, true)
    };
  }
);

export const makeSelectFormattedSelectedNumber = () =>
  formattedSelectedNumberSelector;

export const makeSelectSilverNumbersPrice = () =>
  createSelector(
    currencyFormatSelector,
    silverNumbersPriceSelector,
    (currencyFormat, price) => {
      return {
        price,
        formattedPrice: formatCurrency(price, currencyFormat, true)
      };
    }
  );

export const makeSelectGoldenNumbersPrice = () =>
  createSelector(
    currencyFormatSelector,
    goldenNumbersPriceSelector,
    (currencyFormat, price) => {
      return {
        price,
        formattedPrice: formatCurrency(price, currencyFormat, true)
      };
    }
  );

export const fetchPlanCounter = createSelector(
  preCheckoutSelector,
  formValues => {
    return get(formValues, "_fetch_plan_counter", {
      acc: 0,
      max: 3
    });
  }
);

export const makeSelectFetchPlanCounter = () => fetchPlanCounter;

export const makeSelectNumberSeries = () => numberSeriesSelector;

export const makeSelectNumberLockError = () => numberLockErrorSelector;

export const makeSelectSelectedSimType = () => selectedSimTypeSelector;

export const makeSelectPrecheckoutInfo = () => preCheckoutSelector;

export const makeSelectNumberType = () => numberTypeSelector;
