import get from "lodash/get";
import { createSelector } from "reselect";

export const customerInfoDomain = state =>
  get(state, "api.customerInfo.data", {});

// ***************************************************************
// ******************** customerInfo selectors ********************
// ***************************************************************
export const customerInfoSelector = createSelector(
  customerInfoDomain,
  customerInfo => {
    return customerInfo;
  }
);
// ***************************************************************
// ******************* customerInfo makeSelect ********************
// ***************************************************************

export const makeSelectCustomerInfo = () => customerInfoSelector;
