import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Field } from "redux-form";
import { Box, Button, Flex, Input, MaskedInput } from "ui-components";
import FieldError from "./FieldError";
import Label from "./Label";
import isFunction from "lodash/isFunction";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import debounce from "lodash/debounce";
import { handleRelatedTargetAfterBlur } from "utils/helpers";
import { getButtonColorVariant } from "ui-components/theme";
import FieldSuccessMessage from "./FieldSuccessMessage";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";
import { useSelector } from "react-redux";
import { makeSelectGA4PlanDetails } from "ducks/selectors/googleAnalyticsEvents";

const renderField = ({
  input,
  label,
  type,
  placeholder,
  readOnly,
  disabled,
  mask,
  meta: { touched, error },
  showActionButton,
  actionButtonText,
  buttonStyles,
  onClickActionBtn,
  containerProps,
  successMessageData: {
    showSuccessMessage,
    fieldSuccessMessage,
    successMessageIconUrl,
    htmlLinkMessage,
    onClickHtmlLink
  } = {},
  sendGA4Analytics
}) => {
  const handleInputOnBlur = (event, newValue, previousValue, name) => {
    if (isFunction(input.onBlur)) {
      input.onBlur(event, newValue, previousValue, name);
    }
    handleRelatedTargetAfterBlur(event);
  };

  const handleMaskInputOnBlur = (event, value) => {
    if (isFunction(input.onBlur)) {
      input.onBlur(value || event);
    }

    !onClickActionBtn && handleRelatedTargetAfterBlur(event);
  };

  const onChangeForAnalytics = (value) => {
    sendGA4Analytics && sendGA4Analytics(value);
  };

  const inputRenderer = msg => {
    const InputElement = !isEmpty(mask) ? MaskedInput : Input;
    const onBlurHandler = !isEmpty(mask)
      ? handleMaskInputOnBlur
      : handleInputOnBlur;

    return (
      <InputElement
        {...input}
        {...(msg && { placeholder: msg })}
        placeholder={msg}
        type={type}
        autoComplete="off"
        onBlur={onBlurHandler}
        readOnly={readOnly}
        disabled={disabled}
        onChangeForAnalytics={onChangeForAnalytics}
        {...(!isEmpty(mask) && { ...mask })}
      />
    );
  };

  return (
    <>
      {label && (
        <label>
          <FormattedMessage id={label} />
        </label>
      )}
      {!showActionButton &&
        (placeholder ? (
          <FormattedMessage id={placeholder}>
            {msg => inputRenderer(msg)}
          </FormattedMessage>
        ) : (
          inputRenderer()
        ))}
      {showActionButton && (
        <Flex>
          {placeholder ? (
            <FormattedMessage id={placeholder}>
              {msg => inputRenderer(msg)}
            </FormattedMessage>
          ) : (
            inputRenderer()
          )}
          <Box
            width={0.25}
            sx={{
              display: "flex",
              alignItems: "flex-end"
            }}
          >
            {actionButtonText && (
              <FormattedMessage id={actionButtonText}>
                {msg => (
                  <Button
                    id="action-button"
                    sx={{
                      ...buttonStyles,
                      minWidth: "85px !important",
                      maxHeight: "30px",
                      marginBottom: "0px !important",
                      padding: "5px",
                      paddingTop: "6px",
                      fontSize: "15px"
                    }}
                    disabled={error || disabled}
                    onClick={onClickActionBtn}
                  >
                    {msg}
                  </Button>
                )}
              </FormattedMessage>
            )}
          </Box>
        </Flex>
      )}
      <FieldError touched={touched} error={error} />
      {showSuccessMessage && (
        <FieldSuccessMessage
          touched={touched}
          message={fieldSuccessMessage}
          iconUrl={successMessageIconUrl}
          htmlLinkMessage={htmlLinkMessage}
          onClickHtmlLink={onClickHtmlLink}
          containerProps={containerProps}
        />
      )}
    </>
  );
};

const PhoneField = props => {
  const {
    field,
    label,
    placeholder,
    normalize,
    required,
    optional,
    data,
    disabled,
    formValues,
    generic,
    readOnly,
    themeColors,
    clickPhoneFieldActionBtn,
    successMessageIconUrl,
    showPhoneFieldSuccessMessage
  } = props;

  const GA4EventPlanDetails = useSelector(makeSelectGA4PlanDetails());
  const hideCountryCode = get(
    generic,
    "contact_number.hide_country_code",
    false
  );
  const genericMask = get(generic, "contact_number.mask");
  const contactNumberMask = data?.mask || genericMask;
  const buttonTheme = get(themeColors, "button", {});
  const buttonStyles = getButtonColorVariant(buttonTheme, "outline");
  const {
    showActionButton,
    actionButtonText,
    fieldSuccessMessage,
    htmlLinkMessage,
    onClickHtmlLink
  } = data || {};

  const debouncedSendGA4Analytics = debounce(
    value => {
      if (value.length === 10 || value.length === 11) {
        switch (field) {
          case "contact_number":
            // to avoid firing the event when focused
            value !== formValues.contact_number &&
              GA4EventsService.publish(
                sendGA4Analytics(
                  GA4EVENTS.CONTACT_NO_SUBMITTED,
                  GA4EventPlanDetails
                )
              );
            break;
          default:
            break;
        }
      }
    },
    1000,
    { maxWait: 1500 }
  );

  return !field ? null : (
    <Box mb={8} style={data?.style || {}}>
      {label && (
        <Label
          label={<FormattedMessage id={label} />}
          formValues={formValues}
          required={required}
          optional={optional}
          messageData={formValues}
          {...data}
        />
      )}

      <Flex mx={-2}>
        {!hideCountryCode && (
          <Box width={1 / 4} px={2} data-testid={"country_code"}>
            <Field name="country_code" component={renderField} readOnly />
          </Box>
        )}
        <Box
          width={showActionButton ? "100%" : 3 / 4}
          px={2}
          data-testid={field}
          style={data?.style?.numberField || {}}
        >
          <Field
            name={field}
            readOnly={readOnly}
            component={renderField}
            placeholder={placeholder}
            normalize={normalize}
            disabled={disabled}
            mask={contactNumberMask}
            showActionButton={showActionButton}
            actionButtonText={actionButtonText}
            onClickActionBtn={clickPhoneFieldActionBtn}
            containerProps={props}
            successMessageData={{
              showSuccessMessage: showPhoneFieldSuccessMessage,
              fieldSuccessMessage,
              successMessageIconUrl,
              htmlLinkMessage,
              onClickHtmlLink
            }}
            successMessageIconUrl={successMessageIconUrl}
            buttonStyles={buttonStyles}
            sendGA4Analytics={debouncedSendGA4Analytics}
          />
        </Box>
      </Flex>
    </Box>
  );
};

export default PhoneField;

const sendGA4Analytics = (event, details = {}) => {
  console.log({ event, details });
  const {
    customer_id,
    plan_name,
    plan_type,
    sim_type,
    order_ref,
    journey_type,
    number_type
  } = details;

  return {
    event,
    ecommerce: {
      customer_id,
      order_ref,
      plan_name,
      journey_type,
      plan_type,
      sim_type,
      number_type
    }
  };
};
