import { createSelector } from "reselect";
import get from "lodash/get";
import head from "lodash/head";
import { urlParam, isIOS } from "utils/helpers";
import { orderSelector, sadOrderSelector } from "../../selectors/order";
import {
  guestOrderSelector,
  userOrdersDeliveryDetailsSelector
} from "../../selectors/guestOrder";
import { getSessionToken } from "utils/localStorage";
import {
  outstandingBillsFormSelector,
  formValuesSelector
} from "ducks/selectors";
import { forms } from "utils/constants";

const manageOrderFormSelector = formValuesSelector(forms.MANAGE_ORDER_FORM);

const IosDeviceSelector = () => isIOS();

export const sessionTokenSelector = createSelector(
  manageOrderFormSelector,
  orderSelector,
  guestOrderSelector,
  sadOrderSelector,
  userOrdersDeliveryDetailsSelector,
  (formValues, order, guestOrder, sadOrder, userOrder) => {
    return (
      get(formValues, "sessionToken") ||
      get(guestOrder, "session_token") ||
      get(order, "session_token") ||
      get(sadOrder, "session_token") ||
      urlParam("token") ||
      getSessionToken() ||
      get(head(userOrder), "session_token") ||
      ""
    );
  }
);

export const makeSelectManageOrderFormOTP = () =>
  createSelector(
    manageOrderFormSelector,
    outstandingBillsFormSelector,
    (manageFormValue, outstandingBillsformValues) => {
      return get(
        manageFormValue,
        "otp",
        get(outstandingBillsformValues, "otp")
      );
    }
  );

export const makeSelectManageOrderForm = () => manageOrderFormSelector;
export const makeSelectSessionToken = () => sessionTokenSelector;
export const makeSelectIosDevice = () => IosDeviceSelector;
