import React, { useState, useEffect } from "react";
import PageHeader from "components/PageHeader";
import PaymentBox from "components/PaymentBox";

const UPDATE_PAYMENT_CARD = ({ originkey, token, data }) => {
  const [payment, setPayment] = useState({ origin: originkey, token });
  useEffect(
    () => {
      setPayment({ origin: originkey, token });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [originkey, token]
  );

  return (
    <>
      <PageHeader {...data} />
      <PaymentBox payment={payment} />
    </>
  );
};

export default UPDATE_PAYMENT_CARD;
