import styled from "@emotion/styled";
import { Label as RebassLabel } from "@rebass/forms";

const Label = styled(RebassLabel)`
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  font-size: 1rem;
`;

Label.defaultProps = {
  mb: 2
};

export default Label;
