import {
  BASE,
  USE_API_GATEWAY,
  REWARDS_VALIDATE_REFERRAL_CODE,
  OMS_VALIDATE_REFERRAL_CODE
} from "utils/constants";
import { fetchRequestedWithCaptcha } from "ducks/actions/baseActions";

const fetchReferralCode = ({ referral_code, plan_name }, callback) => {
  const params = {
    referral_code,
    plan_name,
    service: "telco",
    team: "ECOM"
  };
  let path = USE_API_GATEWAY
    ? REWARDS_VALIDATE_REFERRAL_CODE
    : OMS_VALIDATE_REFERRAL_CODE;
  const base = USE_API_GATEWAY ? BASE.REWARDS : BASE.OMS;
  path = path.replace("{REFERRAL_CODE}", referral_code);
  const successData = {
    referral_code
  };
  return fetchRequestedWithCaptcha({
    key: "referralCode",
    path,
    base,
    params,
    successData,
    whitelistedCodes: [400],
    meta: {
      captchaAction: "promos_validate"
    },
    callback
  });
};
export default {
  fetchReferralCode
};
