import React, { useEffect, memo } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { compose } from "redux";
import queryString from "query-string";
import SmartRender from "containers/SmartRender";
import Spinner, { styles } from "components/Spinner";
import { gotoUrl, pathWithChannel, setNRError } from "utils/helpers";
import { createStructuredSelector } from "reselect";
import { publicPaths, paths } from "utils/constants";
import { submitAuIdAssertion, setData } from "ducks/actions";
import {
  makeSelectIsUserPINVerifiedSuccess,
  makeSelectIsUserPINVerifiedFailure,
  makeSelectIsModalView,
  makeSelectModalType,
  makeSelectHasSingleAuLineSelector,
  makeSelectSocialMediaDetails
} from "ducks/selectors";

const VerifyAuPINAuthorizeCode = Component =>
  function Comp(props) {
    const { showModalView, hasSingleAuLine } = props;
    const queryParams = queryString.parse(window.location.search) || {};
    const code = queryParams.code;

    const dispatch = useDispatch();
    const userPINVerifiedSuccess = useSelector(
      makeSelectIsUserPINVerifiedSuccess()
    );
    const userPINVerifiedFailure = useSelector(
      makeSelectIsUserPINVerifiedFailure()
    );
    const socialMediaDetails = useSelector(makeSelectSocialMediaDetails());

    useEffect(() => {
      if (code) {
        dispatch(
          submitAuIdAssertion({
            auth_code: socialMediaDetails.authorization_code,
            pin_auth_code: code,
            redirect_uri: `${window.location.origin}${publicPaths.AU_IPDB_PIN_VERIFY}`
          })
        );
      } else {
        dispatch(
          setData({
            key: "isModalView",
            data: { isModalView: true, type: "wrongPin" }
          })
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Redirect to email selection on success
    useEffect(() => {
      if (userPINVerifiedSuccess) {
        gotoUrl(pathWithChannel(publicPaths.SELECT_EMAIL, "web"));
      }
    }, [hasSingleAuLine, userPINVerifiedSuccess]);

    useEffect(() => {
      if (
        userPINVerifiedFailure ||
        Object.prototype.hasOwnProperty.call(queryParams, "error") ||
        !code
      ) {
        setNRError("AU LOGIN ERROR", {
          API: "submitAuIdAssertion",
          au_auth_code: socialMediaDetails.authorization_code
        });
        dispatch(
          setData({
            key: "isModalView",
            data: { isModalView: true, type: "wrongPin" }
          })
        );
      }
      return () => props.hideModal();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userPINVerifiedFailure]);

    return showModalView ? (
      <Component {...props} />
    ) : (
      <Spinner size={200} sx={styles.fullPage} />
    );
  };

const mapStateToProps = createStructuredSelector({
  showModalView: makeSelectIsModalView(),
  modalType: makeSelectModalType(),
  hasSingleAuLine: makeSelectHasSingleAuLineSelector()
});

export function mapDispatchToProps(dispatch) {
  return {
    hideModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
    },
    hideModalAndRedirectOnError: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
      gotoUrl(
        pathWithChannel(paths.PRE_CHECKOUT, "au_to_povo"),
        false,
        {},
        true,
        { reset: true }
      );
    }
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  memo
)(VerifyAuPINAuthorizeCode(SmartRender));
