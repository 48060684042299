import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import { Box, Flex, Text } from "ui-components";
import Spinner, { styles } from "components/Spinner";
import { COLORS, iconThemes } from "ui-components/theme";
import isFunction from "lodash/isFunction";

function StripeCheckoutForm(props) {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [clientSideError, setClientSideError] = useState(null);
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const cardStyle = {
    hidePostalCode: true,
    style: {
      base: {
        color: iconThemes.navy,
        width: "100%",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: COLORS.silver[0]
        }
      },
      invalid: {
        color: iconThemes.red,
        iconColor: iconThemes.red
      }
    }
  };
  const handleChange = async event => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty || event.error || !event.complete);
    setClientSideError(event.error ? event.error.message : "");
  };
  const handleSubmit = async ev => {
    ev.preventDefault();
    setDisabled(true);
    setProcessing(true);
    console.log(elements.getElement(CardElement));
    const payload = {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    };
    let stripeResponse;
    if (props.intentConfirmation === "confirmCardSetup") {
      stripeResponse = await stripe.confirmCardSetup(
        props.clientSecret,
        payload
      );
    } else {
      stripeResponse = await stripe.confirmCardPayment(
        props.clientSecret,
        payload
      );
    }

    setProcessing(false);
    if (stripeResponse.error) {
      setError(`Payment failed! ${stripeResponse.error.message}`);
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      setSucceeded(true);
      isFunction(props.onPaymentSuccess) && props.onPaymentSuccess();
      props.successRedirectPath &&
        props.history.push(props.successRedirectPath);
    }
  };

  return (
    <Flex width={["100%", "80%", "70%"]} mb={3} mx={"auto"}>
      <Box
        width={"100%"}
        variant="borderSection"
        sx={{
          borderColor: COLORS.silver[1],
          borderWidth: "1px",
          backgroundColor: COLORS.silver[3]
        }}
      >
        <Box mb={"1rem"} p={"1rem"}>
          <Text fontWeight="700">
            <FormattedMessage id="payment.card_details" />
          </Text>
        </Box>

        <Box px={["1rem", "2rem", "2rem"]} mb={"2rem"}>
          <form id="payment-form" onSubmit={handleSubmit}>
            <Box style={{ display: processing ? "block" : "none" }}>
              {<Spinner sx={styles.modalContainer} />}
            </Box>

            <Box
              id="stripe-wrap"
              style={{ display: processing ? "none" : "block" }}
            >
              <CardElement
                id="card-element"
                options={cardStyle}
                onChange={handleChange}
                className="sr-input sr-card-element"
              />

              {clientSideError && (
                <div
                  className="card-error"
                  role="alert"
                  style={{
                    color: cardStyle.style.invalid.color,
                    margin: "7px 0"
                  }}
                >
                  {clientSideError}
                </div>
              )}
              {
                <button
                  disabled={processing || disabled || succeeded}
                  id="submit"
                  className="stripe-pay-button"
                  style={{
                    "margin-top": "18px",
                    width: "100%",
                    color: "#fff",
                    "background-color": iconThemes.blue,
                    "border-color": iconThemes.blue,
                    display: "inline-block",
                    "font-weight": "normal",
                    "line-height": "1.25",
                    "text-align": "center",
                    "white-space": "nowrap",
                    "vertical-align": "middle",
                    cursor: "pointer",
                    "user-select": "none",
                    border: "1px solid transparent",
                    padding: "0.5rem 1rem",
                    "font-size": "1rem",
                    "border-radius": "0.25rem"
                  }}
                >
                  <div id="button-text">
                    <FormattedMessage
                      html
                      id={
                        props.amount > 0
                          ? "payment.pay_amount"
                          : "payment.confirm"
                      }
                    />
                    {props.amount > 0 && (props.amount / 100).toFixed(2)}
                  </div>
                </button>
              }

              {error && (
                <div
                  className="card-error"
                  role="alert"
                  style={{
                    color: cardStyle.style.invalid.color,
                    margin: "7px 0"
                  }}
                >
                  {error}
                </div>
              )}
            </Box>
          </form>
        </Box>
      </Box>
    </Flex>
  );
}

export default withRouter(StripeCheckoutForm);
