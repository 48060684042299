import { isAndroid, isIOS } from "utils/helpers";
import { ADJUST_APP_ENVIRONMENT, ADJUST_APP_KEY } from "utils/constants";
import { isWebview } from "utils/localStorage";
import { Adjust, AdjustConfig, AdjustEvent } from "./adjust_bridge/index";
class AdjustAnalytics {
  initializeAndroidAdjust = () => {
    const adjustConfig = new AdjustConfig(
      ADJUST_APP_KEY,
      ADJUST_APP_ENVIRONMENT
    );
    adjustConfig.setLogLevel(AdjustConfig.LogLevelVerbose);
    Adjust.onCreate(adjustConfig);
  };

  setupWebViewJavascriptBridge(callback) {
    if (window.WebViewJavascriptBridge) {
      return callback(window.WebViewJavascriptBridge);
    }
    if (window.WVJBCallbacks) {
      return window.WVJBCallbacks.push(callback);
    }
    window.WVJBCallbacks = [callback];
    let WVJBIframe = document.createElement("iframe");
    WVJBIframe.style.display = "none";
    WVJBIframe.src = "https://__bridge_loaded__";
    document.documentElement.appendChild(WVJBIframe);
    setTimeout(function() {
      document.documentElement.removeChild(WVJBIframe);
    }, 0);
  }

  initializeIOSAdjust() {
    this.setupWebViewJavascriptBridge(function(bridge) {
      const adjustConfigIOS = new window.AdjustConfig(
        ADJUST_APP_KEY,
        ADJUST_APP_ENVIRONMENT
      );
      adjustConfigIOS.setLogLevel(AdjustConfig.LogLevelVerbose);
      window.Adjust.appDidLaunch(adjustConfigIOS);
    });
  }

  initializedAdjust = () => {
    try {
      if (!isWebview()) return;
      if (isAndroid()) {
        this.initializeAndroidAdjust();
      }
      if (isIOS()) {
        this.initializeIOSAdjust();
      }
    } catch (e) {
      console.error(e);
    }
  };

  pushEvent(eventKey) {
    if (!isWebview()) {
      return;
    }
    try {
      if (isAndroid()) {
        const adjustEvent = new AdjustEvent(eventKey);
        Adjust.trackEvent(adjustEvent);
      }

      if (isIOS()) {
        const adjustEvent = new window.AdjustEvent(eventKey);
        window.Adjust.trackEvent(adjustEvent);
      }
    } catch (e) {
      console.error(e);
    }
  }
}
export default new AdjustAnalytics();
