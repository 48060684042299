import React, { memo } from "react";
import { compose } from "redux";
import SmartRender from "containers/SmartRender/index";
import { reduxForm } from "redux-form";
import {
  forms,
  paths,
  PAYMENT_GATEWAYS,
  PAYMENT_METHODS
} from "utils/constants";
import validate from "validations";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {
  makeSelectAllRegisteredPaymentMethods,
  makeSelectCurrentActivePaymentMethod,
  makeSelectIsModalView,
  makeSelectModalType,
  makeSelectRegisteredPaidy,
  makeSelectUserSIN,
  makeSelectRegisteredCreditCard,
  makeSelectIsFetchingAnyData
} from "ducks/selectors";
import { Payment, setData } from "ducks/actions";
import { gotoUrl, pathWithChannel } from "utils/helpers";
import { makeSelectedPaymentTypeToEdit } from "ducks/selectors/paymentDetails";
import { v4 as uuid } from "uuid";
import Spinner, { styles } from "components/Spinner";

const PaymentAddEditDelete = Component =>
  function Comp(props) {
    const { isFetchingPaymentDetails } = props;
    return isFetchingPaymentDetails ? (
      <Spinner size={200} sx={styles.fullPage} />
    ) : (
      <Component {...props} />
    );
  };

const mapStateToProps = createStructuredSelector({
  //Modal Implementation
  isModalView: makeSelectIsModalView(),
  modalType: makeSelectModalType(),
  userSIN: makeSelectUserSIN(),
  registeredPaidy: makeSelectRegisteredPaidy(),
  registeredCreditCard: makeSelectRegisteredCreditCard(),
  activePaymentMethod: makeSelectCurrentActivePaymentMethod(),
  selectedPaymentTypeToEditing: makeSelectedPaymentTypeToEdit(),
  allRegisteredPaymentMethods: makeSelectAllRegisteredPaymentMethods(),
  isFetchingPaymentDetails: makeSelectIsFetchingAnyData()
});

export function mapDispatchToProps(dispatch) {
  return {
    showCreditCardMoreInfoModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "CreditCardMoreInfoModal" }
        })
      );
    },
    showRemoveCardConfirmModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "RemoveCardConfirmModal" }
        })
      );
    },
    showRemovePaidyConfirmModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "RemovePaidyConfirmModal" }
        })
      );
    },
    showUpdateConfirmModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "UpdateConfirmModal" }
        })
      );
    },
    showPaidyRegisterConfirmModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "paidyRegisterConfirmModal" }
        })
      );
    },
    handleUpdateCardRedirect: () => {
      dispatch(gotoUrl("/manage/update-card", true, {}));
    },
    showPaidyTermsAndConditions: () => {
      gotoUrl(pathWithChannel(paths.PAIDY_REGISTRATION_TERMS_CONDITIONS), true);
    },
    showDeleteCardMoreInfoModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "DeleteCardMoreInfoModal" }
        })
      );
    },
    showPaidyMoreInfoModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "PaidyMoreInfoModal" }
        })
      );
    },
    showDeletePaidyMoreInfoModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "DeletePaidyMoreInfoModal" }
        })
      );
    },
    showErrorOnActiveCardDeletionModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: {
            isModalView: true,
            type: "ErrorCannotDeleteActivePaymentCard"
          }
        })
      );
    },
    showErrorOnActivePaidyDeletionModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: {
            isModalView: true,
            type: "ErrorCannotDeleteActivePaymentPaidy"
          }
        })
      );
    },
    hideMoreInfoModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
    },
    hideAndNavigate: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
      gotoUrl(pathWithChannel(paths.PAYMENT_DETAILS_PAGE), true, {});
    },
    deletePaymentMethod: payload => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
      if (payload.paymentMethod === PAYMENT_METHODS.PAIDY) {
        dispatch(
          Payment.V3.paymentMethodSwitchOrDelete(payload, (success, error) => {
            if (success) {
              dispatch(
                setData({
                  key: "isModalView",
                  data: { isModalView: true, type: "paidyDeleteSucessModal" }
                })
              );
            } else if (error) {
              gotoUrl(pathWithChannel(paths.PAYMENT_DETAILS_PAGE), true, true);
            }
          })
        );
      } else if (payload.paymentMethod === PAYMENT_METHODS.CREDIT_CARD) {
        dispatch(
          Payment.V3.paymentMethodSwitchOrDelete(payload, (success, error) => {
            if (success) {
              dispatch(
                setData({
                  key: "isModalView",
                  data: { isModalView: true, type: "cardDeleteSucessModal" }
                })
              );
            } else if (error) {
              gotoUrl(pathWithChannel(paths.PAYMENT_DETAILS_PAGE), true, true);
            }
          })
        );
      }
    },
    closeDeleteSucessModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
      gotoUrl(pathWithChannel(paths.PAYMENT_DETAILS_PAGE), true, {}, false);
    }
  };
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onClickConfirmDeleteCard: () => {
    const {
      activePaymentMethod,
      userSIN,
      allRegisteredPaymentMethods = []
    } = stateProps;
    if (activePaymentMethod?.gateway === PAYMENT_GATEWAYS.GMO) {
      dispatchProps.showErrorOnActiveCardDeletionModal();
    } else {
      const instrument = allRegisteredPaymentMethods.find(
        method => method.gateway === PAYMENT_GATEWAYS.GMO
      );
      const params = {
        service_instance_number: userSIN,
        additional_data: {
          custom_payment_method: PAYMENT_METHODS.CREDIT_CARD
        },
        paymentMethod: PAYMENT_METHODS.CREDIT_CARD,
        uuid: uuid(),
        instrument_id: instrument?.id,
        status: "remove"
      };
      dispatchProps.deletePaymentMethod(params);
    }
  },
  onClickConfirmDeletePaidy: () => {
    const {
      activePaymentMethod,
      userSIN,
      allRegisteredPaymentMethods = []
    } = stateProps;
    if (activePaymentMethod?.gateway === PAYMENT_GATEWAYS.PAIDY) {
      dispatchProps.showErrorOnActivePaidyDeletionModal();
    } else {
      const instrument = allRegisteredPaymentMethods.find(
        method => method.gateway === PAYMENT_GATEWAYS.PAIDY
      );
      const params = {
        service_instance_number: userSIN,
        additional_data: {
          custom_payment_method: PAYMENT_METHODS.PAIDY
        },
        paymentMethod: PAYMENT_METHODS.PAIDY,
        uuid: uuid(),
        instrument_id: instrument?.id,
        status: "remove"
      };
      dispatchProps.deletePaymentMethod(params);
    }
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps, mergeProps),
  reduxForm({
    form: forms.PAYMENT_ADD_EDIT_DELETE_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(PaymentAddEditDelete(SmartRender));
