import React, { memo } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import validate from "validations";
import { forms } from "utils/constants";
import SmartRender from "containers/SmartRender";

const OneStopMnpFallBackPage = Component =>
  function Comp(props) {
    return <Component {...props} />;
  };

const mapStateToProps = createStructuredSelector({});
const mapDispatchToProps = {};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  reduxForm({
    form: forms.ONE_STOP_MNP_FALLBACK_PAGE,
    destroyOnUnmount: true,
    validate
  }),
  memo
)(OneStopMnpFallBackPage(SmartRender));
