import React from "react";
import { useSelector } from "react-redux";
import { Box, Flex, Text } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import {
  makeSelectSimType,
  makeSelectContractType
} from "ducks/selectors/forms/connectionDetails";
import {
  makeSelectOrderedContractType,
  makeSelectOrderedSimType,
  makeSelectPortInNumber,
  makeSelectUserDOB
} from "ducks/selectors";

const SimDetailsCard = ({ data = {} }) => {
  const {
    title,
    showSimType,
    showContractType,
    showDOB,
    showPortInNumber,
    showCurrentSimType,
    showCurrentContractType,
    dobFormat
  } = data;
  const simType = useSelector(makeSelectSimType());
  const contractType = useSelector(makeSelectContractType());
  const dob = useSelector(makeSelectUserDOB(dobFormat));
  const selectedPortInNumber = useSelector(makeSelectPortInNumber());

  //values are select form manage order API for plan change terms and condition
  const currentSimType = useSelector(makeSelectOrderedSimType());
  const currentContractType = useSelector(makeSelectOrderedContractType());

  return (
    <Box my={4}>
      {title && (
        <Text fontWeight="bolder" py="4px" mb={6} data-testid="simdetail-title">
          <FormattedMessage id={title} />
        </Text>
      )}
      {showSimType && simType && (
        <Flex my={4} data-testid="simdetail-type">
          <FormattedMessage id="sim_type" />
          <span style={{ paddingRight: "4px" }}>:</span>
          <FormattedMessage id={`sim_type_${simType.toLowerCase()}`} />
        </Flex>
      )}
      {showCurrentSimType && currentSimType && (
        <Flex my={4} data-testid="simdetail-type">
          <FormattedMessage id="sim_type" />
          <span style={{ paddingRight: "4px" }}>:</span>
          <FormattedMessage id={`sim_type_${currentSimType.toLowerCase()}`} />
        </Flex>
      )}
      {showContractType && contractType && (
        <Flex my={4} data-testid="simdetail-contract">
          <FormattedMessage id="contract_type" />
          <span style={{ paddingRight: "4px" }}>:</span>
          <FormattedMessage
            id={`contract_type_${contractType.toLowerCase()}`}
          />
        </Flex>
      )}
      {showCurrentContractType && currentContractType && (
        <Flex my={4} data-testid="simdetail-contract">
          <FormattedMessage id="contract_type" />
          <span style={{ paddingRight: "4px" }}>:</span>
          <FormattedMessage
            id={`contract_type_${currentContractType.toLowerCase()}`}
          />
        </Flex>
      )}
      {showDOB && dob && (
        <Flex my={4}>
          <FormattedMessage id="personal_details_date_of_birth" />
          <span style={{ paddingRight: "4px" }}>:</span>
          <Text>{dob}</Text>
        </Flex>
      )}
      {showPortInNumber && selectedPortInNumber && (
        <Flex my={4}>
          <FormattedMessage id="line_Number" />
          <span style={{ paddingRight: "4px" }}>:</span>
          <Text>{selectedPortInNumber}</Text>
        </Flex>
      )}
    </Box>
  );
};

export default SimDetailsCard;
