import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import * as actions from "ducks/actions";
import { reduxForm } from "redux-form";
import SmartRender from "containers/SmartRender";
import validate from "validations";
import { forms } from "utils/constants";
import { makeSelectTermination } from "ducks/selectors";

const mapStateToProps = createStructuredSelector({
  termination: makeSelectTermination()
});

export const mapDispatchToProps = dispatch => ({
  manageAnotherOrder: () => {
    return (
      dispatch(actions.clearData("guestOrder")) &&
      dispatch(actions.clearData("orderDetails"))
    );
  }
});

const enhance = compose(
  reduxForm({
    form: forms.TERMINATE_NUMBER_FORM,
    destroyOnUnmount: false,
    validate
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(SmartRender);
