import React, { useEffect } from "react";
import get from "lodash/get";
import { resetAddressFields } from "utils/helpers";
import PopStationBox from "components/PopStationBox";

const POP_STATION_PICKER = ({
  change,
  changeField,
  formValues,
  postalCodeValidation,
  fetchPopStations,
  themeColors,
  data,
  popStationsDeliverySlots,
  isFetchingPopStations,
  ...props
}) => {
  const deliveryInfoVariant = get(data, "variant");
  const prefix = "pop_station";

  const deliveryZipCode = get(formValues, "pop_station_zip_code");
  const selectedDeliveryMethod = get(formValues, "delivery_method", {});
  const deliveryZipCodeValid = get(
    postalCodeValidation,
    "pop_station_zip_code.valid",
    false
  );
  const selectedPopStation = selectedDeliveryMethod.action === "pop_station";
  const validationRegex = RegExp(/^[\d]{6}$/);

  const deliverySlot = get(
    popStationsDeliverySlots.slice().sort((a, b) => a.cost - b.cost),
    "0",
    {}
  );

  const updateFormValues = data => {
    const unit_no = get(data, "unit_number");
    const street_name = get(data, "street_name");
    const building_name = get(data, "building_name");
    const hse_blk_tower = get(data, "house_block_number");
    const city = get(data, "city");
    const state = get(data, "state");
    const country = get(data, "country");
    const deliverySlotCost = get(deliverySlot, "cost", 0);

    changeField(`${prefix}_unit_no`, unit_no);
    changeField(`${prefix}_street_name`, street_name);
    changeField(`${prefix}_building_name`, building_name);
    changeField(`${prefix}_hse_blk_tower`, hse_blk_tower);
    changeField(`${prefix}_city`, city); // Need to check
    changeField(`${prefix}_state`, state); // Need to check
    changeField(`${prefix}_country`, country);

    changeField(`${prefix}_slot_full_data`, deliverySlot.date_time);
    changeField(`${prefix}_slot_id`, deliverySlot.id);
    changeField(`${prefix}_slot`, deliverySlot.courier_slot_name);
    changeField(`${prefix}_outlet_full_data`, data);

    if (deliverySlotCost > 0) {
      changeField(`${prefix}_slot_fee`, deliverySlotCost);
    }
  };

  useEffect(() => {
    if (isFetchingPopStations) {
      change("pop_station_outlet_full_data", {});
      resetAddressFields(change, prefix, [
        `${prefix}_zip_code`,
        `${prefix}_location`
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingPopStations]);

  useEffect(() => {
    return () => {
      resetAddressFields(change, prefix, [
        `${prefix}_zip_code`,
        `${prefix}_location`,
        "pop_station_outlet_full_data"
      ]);
      changeField(`${prefix}_slot_fee`, 0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      //temp solution

      if (
        deliveryZipCodeValid &&
        validationRegex.test(deliveryZipCode) &&
        selectedPopStation
      ) {
        fetchPopStations({ postal_code: deliveryZipCode });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deliveryZipCodeValid, deliveryZipCode, selectedPopStation]
  );

  return (
    <PopStationBox
      {...props}
      {...data}
      formValues={formValues}
      themeColors={themeColors}
      deliveryInfoVariant={deliveryInfoVariant}
      popStations={props.popStations}
      popStationsDeliverySlots={popStationsDeliverySlots}
      isFetchingPopStations={isFetchingPopStations}
      updateFormValues={updateFormValues}
    ></PopStationBox>
  );
};

export default POP_STATION_PICKER;
