import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import Alert from "react-s-alert";
import {
  paths,
  GMO_SHOP_ID,
  GMO_TOKEN_URL,
  RESPONSE_STATUS,
  DATA_ONLY_CHANNERL,
  DUPLICATE_CONTACT_NUMBER_ERROR_CODES,
  publicPaths,
  forms
} from "utils/constants";
import {
  changeFormField,
  dataOnlyContactNumVerification,
  OMS
} from "ducks/actions";
import { getOMSToken } from "ducks/actions/uiActions";
import {
  makeSelectLocale,
  makeSelectOrderSummaryPayload,
  makeSelectErrorCodes
} from "ducks/selectors";
import {
  loadScript,
  removeScript,
  gotoUrl,
  getChannelFromUrl
} from "utils/helpers";
import usePaymentStatus from "../usePaymentStatus";
import { useParams } from "react-router-dom";

const SCRIPT_ID = "GMO_TOKEN_SCRIPT";
const TOKEN_NUMBER = 2;

function useGMOGateway() {
  const dispatch = useDispatch();
  const { channel } = useParams();
  const isDataOnlyUser = channel === DATA_ONLY_CHANNERL;
  const errorCodes = useSelector(makeSelectErrorCodes());
  const locale = useSelector(makeSelectLocale());
  const newOrderPayPayload = useSelector(makeSelectOrderSummaryPayload());
  const {
    pollingStarted,
    startPaymentStatusPolling,
    paymentStatus
  } = usePaymentStatus();

  const createGMOPayload = cardDetails => ({
    cardno: cardDetails.cardNumber,
    expire: cardDetails.expiryDate,
    securitycode: cardDetails.code,
    tokennumber: TOKEN_NUMBER
  });

  const getCreditCardAdditionalData = ({ cardNumber, cardType }) => {
    if (cardNumber && cardNumber.length > 10) {
      return {
        first_six_digits: cardNumber.substring(0, 6),
        last_four_digits: cardNumber.substring(cardNumber.length - 4),
        card_type: cardType?.value
      };
    }
  };

  const showError = localeKey => {
    Alert.error(get(locale, localeKey, localeKey));
  };

  const initScript = successCallback => {
    loadScript(SCRIPT_ID, GMO_TOKEN_URL, () => {
      if (window.Multipayment) {
        window.Multipayment.init(GMO_SHOP_ID);
        successCallback && successCallback();
      }
    });
  };

  const resetContactNumberAndNavigateToUserRegistration = errorMessage => {
    showError(errorMessage);
    dispatch(
      changeFormField({
        form: forms.DATA_ONLY_USER_REGISTRATION_FORM,
        field: "contact_number",
        value: ""
      })
    );
    dispatch(
      dataOnlyContactNumVerification({
        contact_num_verified: false,
        contact_number: ""
      })
    );
    gotoUrl(
      publicPaths.USER_DETAILS_REGISTRATION.replace(
        ":channel",
        getChannelFromUrl()
      )
    );
  };

  /**
   * Generate the GMO token and make payment for new order
   * @param {object} cardDetails user entered info
   */
  const makePaymentForNewOrder = cardDetails => {
    if (window.Multipayment && cardDetails?.isValid) {
      window.Multipayment.getToken(createGMOPayload(cardDetails), result => {
        const token = result?.tokenObject?.token;
        if (Array.isArray(token) && !isEmpty(token)) {
          const payload = { ...newOrderPayPayload, token };
          let payment_method = payload.payment_methods;
          payment_method.additional_data = getCreditCardAdditionalData(
            cardDetails
          );
          const callback = (result, error, statusCode) => {
            if (result.status !== RESPONSE_STATUS.FAILURE && !error) {
              if (isDataOnlyUser) {
                startPaymentStatusPolling();
              } else {
                const paymentStatusRoute = paths.PAYMENT_STATUS.replace(
                  ":channel",
                  getChannelFromUrl()
                );
                gotoUrl(paymentStatusRoute, false, {}, true);
              }
            } else {
              dispatch(getOMSToken(false));
              // Get the error code from payment service api response.
              const errorCode = get(result, "error_code");

              const duplicateContactNoError = get(result, "code");
              if (
                isDataOnlyUser &&
                DUPLICATE_CONTACT_NUMBER_ERROR_CODES.includes(
                  duplicateContactNoError
                )
              ) {
                const errorMessage = get(result, "message");
                Alert.error(errorMessage);
                resetContactNumberAndNavigateToUserRegistration(errorMessage);
                return;
              }

              showError(
                errorCodes[errorCode] || "saving_or_updating_payment_error"
              );
            }
          };
          dispatch(OMS.V4.postPaymentRequest(payload, callback));
        } else {
          dispatch(getOMSToken(false));
          showError("invalid_card_details");
        }
      });
    } else {
      dispatch(getOMSToken(false));
    }
  };

  const deleteScript = () => removeScript(SCRIPT_ID);

  return {
    initGMOScript: initScript,
    makePaymentForNewOrder,
    deleteScript,
    pollingStarted,
    paymentStatus
  };
}

export default useGMOGateway;
