import { createSelector } from "reselect";
import get from "lodash/get";
import { userSINSelector } from "../authInfo";
import { forms } from "utils/constants";
import {
  orderRefSelector,
  sessionTokenSelector,
  orderDetailsSelector,
  genericSelector,
  userOrdersDetailsSelector,
  formValuesSelector
} from "ducks/selectors";

// ***************************************************************
// ************** reportLostSimForm selectors ******************
// ***************************************************************

export const reportLostSimSelector = formValuesSelector(
  forms.REPORT_LOST_SIM_FORM
);

// ***************************************************************
// *************** reportLostSimForm makeSelect *******************
// ***************************************************************

export const makeSelectReportLostSimForm = () => reportLostSimSelector;

export const makeSelectReportLostSimPayload = () =>
  createSelector(
    reportLostSimSelector,
    orderRefSelector,
    sessionTokenSelector,
    orderDetailsSelector,
    userSINSelector,
    (reportLostSim, order_ref, session_token, orderDetails, accountNumber) => ({
      accountNumber,
      order_ref,
      session_token,
      sim_type: get(reportLostSim, "replacement_sim_type.value"),
      primary_id: get(reportLostSim, "primary_id"),
      switch_to_esim: false,
      skip_form_submission:
        get(orderDetails, "state") === "DELIVERY_FAILED" ||
        get(orderDetails, "in_progress") ||
        get(reportLostSim, "replacement_sim_type.value") === "ESIM",
      inventoryType: get(reportLostSim, "sim_replacement_type"),
      reason: get(reportLostSim, "sim_replacement_reason.value"),
      nric: get(reportLostSim, "primary_id") || get(reportLostSim, "nric")
    })
  );

export const makeSelectReportLostFormInitialValues = () =>
  createSelector(
    genericSelector,
    orderDetailsSelector,
    userOrdersDetailsSelector,
    (generic, orderDetails, userOrdersDetails) => {
      return {
        country_code: get(generic, "contact_number.prefix"),
        ...userOrdersDetails,
        contact_number: get(
          userOrdersDetails,
          "contact_number",
          get(orderDetails, "phone_number")
        )
      };
    }
  );
