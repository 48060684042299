import React, { useEffect, useCallback } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import SmartRender from "containers/SmartRender";
import {
  makeSelectAuthToken,
  makeSelectIsFetchingData,
  makeSelectOrderRef,
  makeSelectBillPaymentStatus,
  makeSelectIsBillPaymentStatusPending,
  makeSelectSessionToken,
  makeSelectOutstandingBillPaymentCorrelationId
} from "ducks/selectors";
import {
  OUTSTANDING_PAYMENT_STATUS_URLS,
  PAYMENT_STATUS_POLL_INTERVAL
} from "utils/constants";
import { gotoUrl } from "utils/helpers";
import get from "lodash/get";
import { Billing } from "ducks/actions";
import { showAlert } from "components/Alert";

const OutstandingBillsPaymentStatusHOC = Component =>
  function Comp(props) {
    const {
      billPaymentStatus,
      fetchOutstandingPaymentStatus,
      isFetchingBillPaymentStatus,
      billPaymentPending,
      correlationId
    } = props;

    const fetchPaymentStatus = useCallback(() => {
      fetchOutstandingPaymentStatus({ correlationId }, (_, error) => {
        if (error) {
          setTimeout(() => {
            showAlert.error(error?.message);
          }, 0);
        }
      });
    }, [correlationId, fetchOutstandingPaymentStatus]);

    useEffect(() => {
      if (correlationId) {
        fetchPaymentStatus();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (correlationId) {
        if (billPaymentPending && !isFetchingBillPaymentStatus) {
          window.setTimeout(() => {
            fetchPaymentStatus();
          }, PAYMENT_STATUS_POLL_INTERVAL);
        }

        const statusUrl = get(
          OUTSTANDING_PAYMENT_STATUS_URLS,
          billPaymentStatus,
          OUTSTANDING_PAYMENT_STATUS_URLS.STATUS
        );

        // a non empty string of statusUrl is true and if the pathname is not the same as statusUrl
        if (statusUrl && window.location.pathname !== statusUrl) {
          gotoUrl(statusUrl, true);
        }
      }
    }, [
      billPaymentStatus,
      isFetchingBillPaymentStatus,
      fetchPaymentStatus,
      correlationId,
      billPaymentPending
    ]);

    return <Component {...props} />;
  };

const mapStateToProps = createStructuredSelector({
  userToken: makeSelectAuthToken(),
  token: makeSelectSessionToken(),
  orderRef: makeSelectOrderRef(),
  billPaymentPending: makeSelectIsBillPaymentStatusPending(),
  billPaymentStatus: makeSelectBillPaymentStatus(),
  correlationId: makeSelectOutstandingBillPaymentCorrelationId(),
  isFetchingBillPaymentStatus: makeSelectIsFetchingData(
    "outstandingBillPaymentStatus"
  )
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps
});

export const mapDispatchToProps = dispatch => {
  return {
    fetchOutstandingPaymentStatus: (params, callback) => {
      dispatch(Billing.V4.fetchOutstandingPaymentStatus(params, callback));
    }
  };
};

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(withConnect)(
  OutstandingBillsPaymentStatusHOC(SmartRender)
);
