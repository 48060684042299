import isFunction from "lodash/isFunction";
import get from "lodash/get";
import isNil from "lodash/isNil";
import trimStart from "lodash/trimStart";
import { upper, lower } from "utils/helpers";

export const normalizerTypes = {
  NUMBER_WITH_MAX_LENGTH: (normalizerConfig = {}) => value => {
    if (isNil(value)) return value;
    const { max_length } = normalizerConfig;
    let slicedValue = max_length ? value.slice(0, max_length) : value;
    return slicedValue.replace(/\D/g, "");
  },
  WORD_WITH_MAX_LENGTH: (normalizerConfig = {}) => value => {
    if (isNil(value)) return value;
    const { max_length } = normalizerConfig;
    let slicedValue = max_length ? value.slice(0, max_length) : value;
    return slicedValue.replace(/[\W_]/g, "");
  },
  ALPHANUMERIC_WITH_MAX_LENGTH: (normalizerConfig = {}) => value => {
    if (isNil(value)) return value;
    const { max_length, upper_case, lower_case } = normalizerConfig;
    let slicedValue = max_length ? value.slice(0, max_length) : value;
    slicedValue = lower_case ? lower(slicedValue) : slicedValue;
    slicedValue = upper_case ? upper(slicedValue) : slicedValue;
    return slicedValue.replace(/[^0-9a-zA-Z]/g, "");
  },
  NAME: (normalizerConfig = {}) => value => {
    if (isNil(value)) return value;
    const { max_length, upper_case, lower_case } = normalizerConfig;
    let slicedValue = max_length ? value.slice(0, max_length) : value;
    slicedValue = lower_case ? lower(slicedValue) : slicedValue;
    slicedValue = upper_case ? upper(slicedValue) : slicedValue;
    return slicedValue.replace(/[^a-zA-Z '-]/g, "");
  },
  TRIM_START: () => value => {
    if (isNil(value)) return value;

    return trimStart(value);
  },
  ALPHABETS_AND_SPACE: (normalizerConfig = {}) => value => {
    if (isNil(value)) return value;
    const { max_length, upper_case, lower_case } = normalizerConfig;
    let slicedValue = max_length ? value.slice(0, max_length) : value;
    slicedValue = lower_case ? lower(slicedValue) : slicedValue;
    slicedValue = upper_case ? upper(slicedValue) : slicedValue;
    return slicedValue.replace(/[^a-zA-Z ]/g, "");
  },
  ALPHANUMERIC_AND_SPACE: (normalizerConfig = {}) => value => {
    if (isNil(value)) return value;
    const { max_length, upper_case, lower_case } = normalizerConfig;
    let slicedValue = max_length ? value.slice(0, max_length) : value;
    slicedValue = lower_case ? lower(slicedValue) : slicedValue;
    slicedValue = upper_case ? upper(slicedValue) : slicedValue;
    return slicedValue.replace(/[^0-9a-zA-Z ]/g, "");
  },
  BLOCK_SPECIAL_CHARACTER: (normalizerConfig = {}) => value => {
    if (isNil(value)) return value;
    const { max_length, upper_case, lower_case } = normalizerConfig;
    let slicedValue = max_length ? value.slice(0, max_length) : value;
    slicedValue = lower_case ? lower(slicedValue) : slicedValue;
    slicedValue = upper_case ? upper(slicedValue) : slicedValue;
    return slicedValue.replace(/[,;]/g, "");
  },
  THIRD_PARTY_ORDER_REF: (normalizerConfig = {}) => value => {
    if (isNil(value)) return value;
    const { max_length, upper_case, lower_case } = normalizerConfig;
    let slicedValue = max_length ? value.slice(0, max_length) : value;
    slicedValue = lower_case ? lower(slicedValue) : slicedValue;
    slicedValue = upper_case ? upper(slicedValue) : slicedValue;
    return slicedValue.replace(/[^0-9a-zA-Z/_: -]/g, "");
  },
  REPLACE_REGEX: (normalizerConfig = {}) => value => {
    if (isNil(value)) return value;
    const {
      max_length,
      upper_case,
      lower_case,
      regex,
      replacement = ""
    } = normalizerConfig;
    let slicedValue = max_length ? value.slice(0, max_length) : value;
    slicedValue = lower_case ? lower(slicedValue) : slicedValue;
    slicedValue = upper_case ? upper(slicedValue) : slicedValue;
    return regex
      ? slicedValue.replace(RegExp(regex, "g"), replacement)
      : slicedValue;
  },
  UPPERCASE: () => upper,
  LOWERCASE: () => lower
};

const normalizerMappings = {
  CONTACT_NUMBER: {
    type: "NUMBER_WITH_MAX_LENGTH",
    lookup: "generic.contact_number"
  },
  ALTERNATE_NUMBER: {
    type: "NUMBER_WITH_MAX_LENGTH",
    lookup: "generic.contact_number"
  },
  POST_PAID_NUMBER: {
    type: "NUMBER_WITH_MAX_LENGTH",
    lookup: "generic.port_in_number"
  },
  PORT_IN_NUMBER: {
    type: "NUMBER_WITH_MAX_LENGTH",
    lookup: "generic.port_in_number"
  },
  POSTAL_CODE: { type: "NUMBER_WITH_MAX_LENGTH", lookup: "generic.zip_code" },
  ICC_ID: { type: "NUMBER_WITH_MAX_LENGTH", lookup: "generic.icc_id" },
  FIRST_NAME: {
    type: "TRIM_START"
  },
  LAST_NAME: {
    type: "TRIM_START"
  }
};

const getDefaultNormalizerConfig = component => {
  const componentName = component?.name;

  if (componentName === "TEXT_FIELD") {
    const fieldName = component?.field;
    if (fieldName && ["first_name", "last_name"].includes(fieldName)) {
      return get(normalizerMappings, upper(fieldName), {});
    }
  }

  return get(normalizerMappings, componentName, {});
};

const normalizers = (component, appSettings) => {
  const defaultNormalizer = getDefaultNormalizerConfig(component);
  const componentNormalizer = {
    ...defaultNormalizer,
    ...get(component, "normalizer", {})
  };
  const lookupNormalizer = get(appSettings, componentNormalizer.lookup, {});
  const normalizerConfig = { ...lookupNormalizer, ...componentNormalizer };
  const type = normalizerConfig.type;
  const normalizerConstructor = type && normalizerTypes[type];
  const normalizer = isFunction(normalizerConstructor)
    ? normalizerConstructor(normalizerConfig)
    : value => value;
  return normalizer;
};

export default normalizers;
