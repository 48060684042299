const styles = {
  containerStyle: {
    mb: [30, 40, 60]
  },
  stepWrapperStyle: {
    justifyContent: "space-between",
    mb: 8
  },
  baseCircleStyle: {
    backgroundColor: "gray.0",
    borderRadius: 19,
    borderColor: "white",
    borderWidth: 3,
    borderStyle: "solid",
    width: 38,
    height: 38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  activeCircleStyle: {
    backgroundColor: "magenta.3"
  },
  baseCircleTextStyle: {
    fontSize: 14,
    fontWeight: "500",
    color: "white !important"
  },
  barStyle: {
    marginTop: -52,
    borderWidth: 2,
    borderColor: "gray.0",
    borderStyle: "solid"
  }
};

export default styles;
