import React, { useEffect } from "react";
import { Box, Flex, Text } from "ui-components";
import TextField from "components/FormFields/TextField";
import FormattedMessage from "components/FormattedMessage";
import Label from "components/FormFields/Label";
import get from "lodash/get";
import split from "lodash/split";
import includes from "lodash/includes";
import isFunction from "lodash/isFunction";
import { compactJoin } from "utils/helpers";
import { normalizerTypes } from "normalizers";

const JPZipCode = ({
  fieldPrefix,
  zip_code,
  formValues,
  change,
  setPostalCodeValidation,
  readOnly
}) => {
  const label = get(zip_code, "label");

  const prefix = get(zip_code, "prefix", "〒");
  const separator = get(zip_code, "separator", "-");
  const part1Length = get(zip_code, "part1Length", 3);
  const part2Length = get(zip_code, "part2Length", 4);
  const field = `${fieldPrefix}_${zip_code.field}`;
  const part1Field = `${fieldPrefix}_${zip_code.field}_part1`;
  const part2Field = `${fieldPrefix}_${zip_code.field}_part2`;
  const fieldValue = get(formValues, field);
  const part1FieldValue = get(formValues, part1Field);
  const part2FieldValue = get(formValues, part2Field);
  const pattern = get(zip_code, "pattern");
  const required = get(zip_code, "required", null);
  const updatePostalCodeValidation = value => {
    const status = value && pattern && RegExp(pattern).test(value);
    isFunction(setPostalCodeValidation) &&
      setPostalCodeValidation(field, { status });
  };
  const onPart1Change = (_, value) => {
    const fieldValue = compactJoin([value, part2FieldValue], separator);
    change(field, fieldValue);
    updatePostalCodeValidation(fieldValue);
  };
  const onPart2Change = (_, value) => {
    const fieldValue = compactJoin([part1FieldValue, value], separator);
    change(field, fieldValue);
    updatePostalCodeValidation(fieldValue);
  };
  useEffect(() => {
    if (
      // fieldValue && // consider empty field value to reset the zip code parts
      fieldValue !== compactJoin([part1FieldValue, part2FieldValue], separator)
    ) {
      const [part1, part2] = includes(fieldValue, separator)
        ? split(fieldValue, separator)
        : [
            fieldValue?.substring(0, part1Length),
            fieldValue?.substring(part1Length)
          ];
      change(part1Field, part1 || "");
      change(part2Field, part2 || "");
      const value = compactJoin([part1, part2], separator);
      change(field, value);
      updatePostalCodeValidation(value);
    }
    // eslint-disable-next-line
  }, [
    fieldValue,
    part1FieldValue,
    part2FieldValue,
    part1Field,
    part2Field,
    separator
  ]);
  return (
    <Box width={[1]} px={2}>
      {label && (
        <Label required={required} label={<FormattedMessage id={label} />} />
      )}
      <Flex flexWrap="wrap" alignItems="baseline">
        <Text>{prefix}</Text>
        <Flex width={"25%"} px={2}>
          <TextField
            field={part1Field}
            onChange={onPart1Change}
            type={"number"}
            sx={{ width: "100%" }}
            disabled={readOnly}
            normalize={normalizerTypes.NUMBER_WITH_MAX_LENGTH({
              max_length: part1Length
            })}
          />
        </Flex>
        <Text>{separator}</Text>
        <Flex pl={2} flex="1">
          <TextField
            field={part2Field}
            onChange={onPart2Change}
            type={"number"}
            sx={{ width: "100%" }}
            disabled={readOnly}
            normalize={normalizerTypes.NUMBER_WITH_MAX_LENGTH({
              max_length: part2Length
            })}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
const DeliveryAddressForm = ({
  fields = {},
  fieldPrefix = "delivery",
  linkedFieldPrefix = "",
  formValues,
  change,
  setPostalCodeValidation,
  readOnly
}) => {
  const { zip_code, state, city, hse_blk_tower, street_name } = fields;
  const onFieldChange = (field, value) => {
    const linkedField = field.replace(fieldPrefix, linkedFieldPrefix);
    const linkedFieldValue = get(formValues, linkedField);
    const fieldValue = get(formValues, field);
    if (isFunction(change) && fieldValue !== value) {
      change(field, value);
      if (
        linkedFieldPrefix &&
        linkedFieldPrefix !== fieldPrefix &&
        linkedFieldValue !== value
      ) {
        change(linkedField, value);
      }
    }
  };
  const stateField = `${fieldPrefix}_${state.field}`;
  const cityField = `${fieldPrefix}_${city.field}`;
  const streetNameField = `${fieldPrefix}_${street_name.field}`;
  const hseBlkTowerfield = `${fieldPrefix}_${hse_blk_tower.field}`;
  return (
    <>
      <Flex flexWrap="wrap" mx={-2}>
        {get(zip_code, "enable") && (
          <JPZipCode
            zip_code={zip_code}
            fieldPrefix={fieldPrefix}
            formValues={formValues}
            change={onFieldChange}
            setPostalCodeValidation={setPostalCodeValidation}
            readOnly={readOnly}
          />
        )}
      </Flex>

      <Flex flexWrap="wrap" mx={-2}>
        {get(state, "enable") && (
          <Box width={[1]} px={2}>
            <TextField
              disabled={readOnly}
              {...state}
              normalize={normalizerTypes.TRIM_START()}
              field={stateField}
              onChange={event =>
                onFieldChange(stateField, get(event, "target.value"))
              }
            />
          </Box>
        )}
      </Flex>
      <Flex flexWrap="wrap" mx={-2}>
        {get(city, "enable") && (
          <Box width={[1]} px={2}>
            <TextField
              {...city}
              field={cityField}
              disabled={readOnly}
              normalize={normalizerTypes.TRIM_START()}
              onChange={event =>
                onFieldChange(cityField, get(event, "target.value"))
              }
            />
          </Box>
        )}
      </Flex>
      <Flex flexWrap="wrap" mx={-2}>
        {get(street_name, "enable") && (
          <Box width={[1]} px={2}>
            <TextField
              {...street_name}
              field={streetNameField}
              disabled={readOnly}
              normalize={normalizerTypes.TRIM_START()}
              onChange={event =>
                onFieldChange(streetNameField, get(event, "target.value"))
              }
            />
          </Box>
        )}
      </Flex>
      <Flex flexWrap="wrap" mx={-2}>
        {get(hse_blk_tower, "enable") && (
          <Box width={[1]} px={2}>
            <TextField
              {...hse_blk_tower}
              field={hseBlkTowerfield}
              disabled={readOnly}
              normalize={normalizerTypes.TRIM_START()}
              onChange={event =>
                onFieldChange(hseBlkTowerfield, get(event, "target.value"))
              }
            />
          </Box>
        )}
      </Flex>
    </>
  );
};

export default DeliveryAddressForm;
