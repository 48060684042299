import { createSelector } from "reselect";
import get from "lodash/get";
import { forms } from "utils/constants";

/**
 * Extracting Login OTP from values
 * @param {object} state
 */
const userLoginOtpDomain = state =>
  get(state, `form.${forms.USER_LOGIN_OTP_FORM}.values`, {});

/**
 * ************ Login OTP Form Selectors ************
 */
export const isValidOTP = (fieldName = "current_otp", otpLength = 6) =>
  createSelector(userLoginOtpDomain, formValues => {
    const otpValuesArray = get(formValues, `${fieldName}`, [""]);

    if (otpValuesArray.length === otpLength) {
      return otpValuesArray.find(item => item === "") === undefined;
    } else {
      return false;
    }
  });

/**
 * ************ Make Selectors ************
 */
export const makeSelectIsValidOTP = (fieldName, otpLength) =>
  isValidOTP(fieldName, otpLength);
