import get from "lodash/get";
import { forms } from "utils/constants";
import { createSelector } from "reselect";

const confirmKYCForPlanChangeDomain = state =>
  get(state, `form.${forms.CONFIRM_KYC_FOR_PLAN_CHANGE}.values`, {});

export const confirmKYCForPlanChangeSelector = createSelector(
  confirmKYCForPlanChangeDomain,
  confirmKYCForPlanChange => confirmKYCForPlanChange
);

export const makeSelectConfirmKYCForPlanChange = () =>
  confirmKYCForPlanChangeSelector;
