import { paths, publicPaths } from "utils/constants";
import SmartRender from "containers/SmartRender";
import PreCheckout from "containers/Plan/PreCheckout";
import ConnectionDetails from "containers/Plan/ConnectionDetails";
import OrderSummary from "containers/Plan/OrderSummary";
import Payment from "containers/Payment";
import PaymentStatus from "containers/PaymentStatus";
import PaymentReturn from "containers/PaymentReturn";
import ManageOrder from "containers/ManageOrder";
import ReportLostSim from "containers/OrderActions/ReportLostSimAction";
import ScheduleDelivery from "containers/Plan/ScheduleDelivery";
import TerminateNumber from "containers/OrderActions/TerminateNumberAction";
import SimVerification from "containers/SimActivation/SimVerification";
import RequestPortOut from "containers/RequestPortOut";
import ReversePortIn from "containers/ReversePortIn";
import SimActivationSuccess from "containers/SimActivation/SimActivationSuccess";
import OutstandingBills from "containers/OrderActions/OutstandingBillsAction";
import OutstandingBillsPaymentStatus from "containers/OutstandingBillsPaymentStatus";
import Ping from "components/Ping";
import DeepLink from "containers/DeepLink";
import ESimActivation from "containers/SimActivation/ESimActivation";
import SimActivationStatus from "containers/SimActivation/SimActivationStatus";
import SimActivationStatusPolling from "containers/SimActivation/SimActivationStatus/SimActivationStatusPolling";
import SimActivationStatusSuccess from "containers/SimActivation/SimActivationStatus/SimActivationStatusSuccess";
import Login from "containers/Login";
import ContractDetails from "containers/Plan/ContractDetails";
import OTPSubmitPage from "containers/VerifyOTP/OTPSubmitPage";
import OrderCancellation from "containers/OrderCancellation";
import UserDetailsConfirmation from "containers/UserDetailsConfirmation";
import SocialAuthRedirection from "containers/SocialAuthRedirection";
import Profile from "containers/Profile";
import AmazonPayment from "containers/Payment/amazon";
import EmailSelection from "containers/SocialEmailselection/EmailSelection";
import OutstandingBillPayment from "containers/OutstandingBillPayment";
import ESimTransferStaticContent from "containers/ESimTransfer/StaticContent";
import ContactNumberChange from "containers/ContactNumberChange";
import ESimTransferVerifyUser from "containers/ESimTransfer/VerifyUser";
import VerifyAuPINAuthorizeCode from "containers/SocialAuthRedirection/AuIPDPAuthentication/VerifyAuPINAuthorizeCode";
import OneStopMNPPortinLogin from "containers/OneStopMNP/Portin";
import OneStopMnpFallBackPage from "containers/OneStopMNP/FallBackPage";
import OneStopMNPPortoutDashboard from "containers/OneStopMNP/Portout/Dashboard";
import OneStopMnpBrowserInit from "containers/OneStopMNP/BrowserInit";
import ESimTransferTermsConditions from "containers/ESimTransfer/ESimTransferTermsConditions";
import PlanChangeHOC from "containers/Plan/PlanChange";
import PlanChangeStatus from "containers/Plan/PlanChange/PlanChangeStatus";
import ConfirmKYCMethod from "containers/KYC/confirmKYCMethod";
import ConfirmPinChange from "containers/Profile/ConfirmPinChange";
import UpdatePIN from "containers/Profile/UpdatePIN";
import PaymentDetails from "containers/Profile/PaymentMethodUpdate/PaymentDetails";
import PaymentAddEditDelete from "containers/Profile/PaymentMethodUpdate/PaymentAddEditDelete/index";
import AddressUpdate from "containers/Profile/AddressUpdate";
import ToppingSelectionPage from "containers/DataOnly/ToppingSelectionPage";
import UserRegistration from "containers/DataOnly/UserRegistration";
import WebSDKPlayground from "containers/PageError/WebSDKPlayground";

export const authorizedRoutes = [
  {
    path: paths.ORDER_SUMMARY,
    Component: OrderSummary
  },
  {
    path: paths.PLAN_CHANGE_TERMS_AND_CONDITIONS,
    Component: OrderSummary
  },
  {
    path: paths.PLAN_CHANGE_SIM_SELECTION,
    Component: PlanChangeHOC
  },
  {
    path: paths.PLAN_CHANGED_SIM_STATUS,
    Component: PlanChangeStatus
  },
  {
    path: paths.CONNECTION_DETAILS,
    Component: ConnectionDetails
  },
  {
    path: paths.PROFILE,
    Component: Profile
  },
  {
    path: paths.PROFILE_EMAIL,
    Component: Profile
  },
  {
    path: paths.MANAGE_SIM_ACTIVATION,
    Component: SimVerification
  },
  {
    path: paths.MANAGE_ORDER,
    Component: ManageOrder
  },
  {
    path: paths.MANAGE_ESIM_QR,
    Component: ESimActivation
  },
  {
    path: paths.MANAGE_ESIM_SETTING,
    Component: ESimActivation
  },
  {
    path: paths.SIM_ACTIVATION_STATUS,
    Component: SimActivationStatus
  },
  {
    path: paths.SIM_ACTIVATION_PENDING,
    Component: SimActivationStatusPolling
  },
  {
    path: paths.SIM_ACTIVATION_SUCCESS,
    Component: SimActivationStatusSuccess
  },
  {
    path: paths.USER_DETAILS_CONFIRMATION,
    Component: UserDetailsConfirmation
  },
  {
    path: paths.USER_DETAILS,
    Component: UserDetailsConfirmation
  },
  {
    path: paths.AU_USER_DETAILS,
    Component: UserDetailsConfirmation
  },
  {
    path: paths.OWNER_DETAILS,
    Component: UserDetailsConfirmation
  },
  {
    path: paths.PORTIN_DETAILS,
    Component: UserDetailsConfirmation
  },
  {
    path: paths.PORTIN_DETAILS_ONE_STOP,
    Component: UserDetailsConfirmation
  },
  {
    path: paths.DELIVERY_DETAILS,
    Component: UserDetailsConfirmation
  },
  {
    path: paths.CONTACT_DETAILS,
    Component: UserDetailsConfirmation
  },
  {
    path: paths.MNP_LANDING_PAGE,
    Component: UserDetailsConfirmation
  },
  {
    path: paths.MNP_FALLBACK_ERROR_PAGE,
    Component: OneStopMnpFallBackPage
  },
  {
    path: paths.PAYMENT_CARD_UPDATE,
    Component: Payment
  },
  {
    path: paths.SIM_REPLACEMENT_CONNECTION_DETAILS,
    Component: ReportLostSim
  },
  {
    path: paths.MANAGE_REPLACE_SIM,
    Component: ReportLostSim
  },
  {
    path: paths.MANAGE_REPLACE_SIM_STATUS,
    Component: ReportLostSim
  },
  {
    path: paths.SIM_REPLACEMENT_ESIM_ACTIVATION,
    Component: ESimActivation
  },
  {
    path: paths.SIM_REPLACEMENT_DELIVERY_DETAILS,
    Component: ReportLostSim
  },
  {
    path: paths.SIM_REPLACEMENT_CONTACT_DETAILS,
    Component: ReportLostSim
  },
  {
    path: paths.ORDER_CANCELLATION,
    Component: OrderCancellation
  },
  {
    path: paths.TERMINATE_NUMBER,
    Component: TerminateNumber
  },
  {
    path: paths.TERMINATE_INFO,
    Component: TerminateNumber
  },
  {
    path: paths.REVERSE_PORTIN,
    Component: ReversePortIn
  },
  {
    path: paths.REQUEST_PORT_OUT,
    Component: RequestPortOut
  },
  {
    path: paths.SCHEDULE_DELIVERY,
    Component: ScheduleDelivery
  },
  {
    path: paths.ONESTOP_MNP_DASHBOARD,
    Component: OneStopMNPPortoutDashboard
  },
  {
    path: paths.ONESTOP_MNP_PORT_OUT_REQUEST_CONFIRM,
    Component: RequestPortOut
  },
  {
    path: paths.DASHBOARD,
    Component: DeepLink
  },
  {
    path: paths.PAYMENT_STATUS,
    Component: PaymentStatus
  },
  {
    path: paths.PAYMENT_PENDING,
    Component: PaymentStatus
  },
  {
    path: paths.PAYMENT_SUCCESS,
    Component: PaymentStatus
  },
  {
    path: paths.PAYMENT_FAILED,
    Component: PaymentStatus
  },
  {
    path: paths.PAYMENT_CARD,
    Component: Payment
  },
  {
    path: paths.PAYMENT,
    Component: Payment
  },
  {
    path: paths.TERMS_AND_CONDITIONS,
    Component: Payment
  },
  {
    path: paths.REPORT_LOST_SIM,
    Component: ReportLostSim
  },
  {
    path: paths.PAYMENT_RETURN_V3,
    Component: PaymentReturn
  },
  {
    path: paths.PAYMENT_RETURN,
    Component: PaymentReturn
  },
  {
    path: paths.LEGACY_PAYMENT_RETURN,
    Component: PaymentReturn
  },
  {
    path: paths.OUTSTANDING_BILLS,
    Component: OutstandingBills
  },
  {
    path: paths.UPDATE_CARD_SUCCESS,
    Component: DeepLink
  },
  {
    path: paths.PROFILE_UPDATE_EMAIL_SUCESS,
    Component: DeepLink
  },
  {
    path: paths.OUTSTANDING_BILLS_PAYMENT_STATUS,
    Component: OutstandingBillsPaymentStatus
  },
  {
    path: paths.MANAGE_ACTIVATE_SIM_CARD,
    Component: SimVerification
  },
  {
    path: paths.PAYMENT_AMAZON,
    Component: AmazonPayment
  },
  {
    path: paths.SIM_VERIFICATION,
    Component: SimVerification
  },
  {
    path: paths.CONFIRM_KYC_METHOD,
    Component: ConfirmKYCMethod
  },
  {
    path: paths.CONFIRM_PIN_CHANGE,
    Component: ConfirmPinChange
  },
  {
    path: paths.UPDATE_USER_PIN,
    Component: UpdatePIN
  },
  {
    path: paths.PIN_UPDATE_SUCCESS,
    Component: UpdatePIN
  },
  {
    path: paths.PAYMENT_DETAILS_PAGE,
    Component: PaymentDetails
  },
  {
    path: paths.PAYMENT_ADD_EDIT_DELETE,
    Component: PaymentAddEditDelete
  },
  {
    path: paths.UPDATE_REGISTER_PAIDY,
    Component: Payment
  },
  {
    path: paths.PAIDY_REGISTRATION_TERMS_CONDITIONS,
    Component: PaymentAddEditDelete
  },
  {
    path: paths.ADDRESS_UPDATE_PAGE,
    Component: AddressUpdate
  },
  {
    path: paths.ADDRESS_UPDATE_CONFIRM_PAGE,
    Component: AddressUpdate
  },
  {
    path: paths.E_SIM_SET_UP,
    Component: ESimActivation
  },
  {
    path: paths.DATA_ONLY_PAYMENT,
    Component: Payment
  },
  {
    path: paths.CHANGE_CONTACT_NUMBER_ESIM,
    Component: ContactNumberChange
  },
  {
    path: paths.ESIM_NUMBER_CHANGE_OTP,
    Component: ContactNumberChange
  },
  {
    path: paths.PORT_OUT_REQUEST_CONFIRM,
    Component: RequestPortOut
  }
];

// These are the routes exposed openly by the app (For countries that has secured turned on)
export const publicRoutes = [
  {
    path: publicPaths.MNP_PORTOUT_LOGIN_PAGE,
    Component: Login
  },
  {
    path: publicPaths.E_SIM_TRANSFER_TERMS_CONDITIONS,
    Component: ESimTransferTermsConditions
  },
  {
    path: publicPaths.SIGNUP_PAGE,
    Component: Login
  },
  {
    path: publicPaths.LOGIN_PAGE,
    Component: Login
  },
  {
    path: publicPaths.CONFIRM_EMAIL,
    // Same login container is used since from this path onwards it is same as a user login
    Component: Login
  },
  {
    path: publicPaths.SELECT_EMAIL,
    Component: EmailSelection
  },
  {
    path: publicPaths.VERIFY_OTP,
    Component: OTPSubmitPage
  },
  {
    path: publicPaths.LINE_AUTHENTICATION,
    Component: SocialAuthRedirection
  },
  {
    path: publicPaths.YAHOO_AUTHENTICATION,
    Component: SocialAuthRedirection
  },
  {
    path: publicPaths.GOOGLE_AUTHENTICATION,
    Component: SocialAuthRedirection
  },
  {
    path: publicPaths.FACEBOOK_AUTHENTICATION,
    Component: SocialAuthRedirection
  },
  {
    path: publicPaths.AU_AUTHENTICATION,
    Component: SocialAuthRedirection
  },
  {
    path: publicPaths.ESIM_TRANSFER_OTHER_ORDER_INPROGRESS,
    Component: ESimTransferStaticContent
  },
  {
    path: publicPaths.ESIM_TRANSFER_BLOCKED,
    Component: ESimTransferStaticContent
  },
  {
    path: publicPaths.ESIM_TRANSFER_BLOCKED_TOURIST_ESIM,
    Component: ESimTransferStaticContent
  },
  {
    path: publicPaths.ESIM_TRANSFER_OUT_OF_SERVICE,
    Component: ESimTransferStaticContent
  },
  {
    path: publicPaths.ESIM_TRANSFER_NO_POVO_CUSTOMER,
    Component: ESimTransferStaticContent
  },
  {
    path: publicPaths.ESIM_TRANSFER_TARGET_DEVICE_INVALID,
    Component: ESimTransferStaticContent
  },
  {
    path: publicPaths.ESIM_TRANSFER_PLAN_CHANGE,
    Component: ESimTransferStaticContent
  },
  {
    path: publicPaths.ESIM_TRANSFER_FALLBACK,
    Component: ESimTransferStaticContent
  },
  {
    path: publicPaths.ESIM_TRANSFER_UNEXPECTED_ERROR,
    Component: ESimTransferStaticContent
  },
  {
    path: publicPaths.AU_IPDB_PIN_VERIFY,
    Component: VerifyAuPINAuthorizeCode
  },
  {
    path: publicPaths.ESIM_TRANSFER_VERIFY_USER,
    Component: ESimTransferVerifyUser
  },
  {
    path: publicPaths.ESIM_TRANSFER_USER_CONCENT,
    Component: ESimTransferVerifyUser
  },
  {
    path: publicPaths.ESIM_TRANSFER_PLAN_CHANGE_CONSENT,
    Component: ESimTransferVerifyUser
  },
  {
    path: publicPaths.ESIM_TRANSFER_USER_CONCENT_WITH_POST_DATA,
    Component: ESimTransferVerifyUser
  },
  {
    path: publicPaths.ESIM_TRANSFER_SUBMIT_STATUS,
    Component: ESimTransferVerifyUser
  },
  {
    path: publicPaths.ONESTOP_MNP_LOGIN,
    Component: OneStopMNPPortinLogin
  },
  // For 1stop MNP portin testing
  {
    path: publicPaths.ONESTOP_MNP_SOURCE_LOGIN,
    Component: OneStopMNPPortinLogin
  },
  {
    path: publicPaths.ONESTOP_MNP_WEB_LOGIN_ERROR,
    Component: OneStopMNPPortinLogin
  },
  {
    path: publicPaths.ONESTOP_MNP_DESTINATION_PROVIDER_LANDING,
    Component: OneStopMNPPortinLogin
  },
  {
    path: publicPaths.ONESTOP_MNP_INITIATE_PORTOUT,
    Component: OneStopMNPPortinLogin
  },
  {
    path: publicPaths.CONTRACT_DETAILS_PAGE,
    Component: ContractDetails
  },
  {
    path: paths.LANDING_PAGE,
    Component: SmartRender
  },
  {
    path: paths.PRE_CHECKOUT,
    Component: PreCheckout
  },
  {
    path: paths.CLOSEWEBVIEW,
    Component: DeepLink
  },
  {
    path: paths.WEBLOGIN,
    Component: DeepLink
  },
  {
    path: paths.APPLOGIN,
    Component: DeepLink
  },
  {
    path: paths.CANCEL_TERMINATION,
    Component: TerminateNumber
  },
  {
    path: paths.OUTSTANDING_BILL_PAYMENT,
    Component: OutstandingBillPayment
  },
  {
    path: paths.MANAGE_SIM_ACTIVATION_SUCCESS,
    Component: SimActivationSuccess
  },
  {
    path: paths.PING,
    Component: Ping
  },
  {
    path: paths.SCANNER,
    Component: DeepLink
  },
  {
    path: paths.LOGGED_OUT,
    Component: DeepLink
  },
  {
    path: paths.APP_LINK_AU_ACCOUNT_SUCCESS,
    Component: DeepLink
  },
  {
    path: paths.APP_LINK_MANAGE_PIN_CLOSE,
    Component: DeepLink
  },
  {
    path: paths.ONESTOP_MNP_BROWSER_INIT,
    Component: OneStopMnpBrowserInit
  },
  {
    path: paths.ONESTOP_MNP_LAUNCH_APP,
    Component: DeepLink
  },
  {
    path: publicPaths.DATA_ONLY_TOPPING_SELECTION,
    Component: ToppingSelectionPage
  },
  {
    path: publicPaths.USER_DETAILS_REGISTRATION,
    Component: UserRegistration
  },
  {
    path: paths.E_SIM_SET_UP,
    Component: ESimActivation
  },
  {
    path: paths.E_SIM_PROCESSING,
    Component: ESimActivation
  },
  {
    path: paths.E_SIM_SET_UP_BY_API,
    Component: ESimActivation
  },
  {
    path: paths.WEBSDK_PLAYGROUND,
    Component: WebSDKPlayground
  }
];
