import React from "react";

import { Box, StyledLink } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import { gotoUrl } from "utils/helpers";
import get from "lodash/get";

const NavigateLink = props => {
  const { enable = true, targetRoute = "", label, themeColors } = props;
  const navigateTo = () => {
    if (!enable) return;
    gotoUrl(targetRoute);
  };

  const textColor = get(themeColors, "text");
  return (
    <Box mt={[24, 36]} sx={{ textAlign: "center" }}>
      <StyledLink
        data-testid="navigate_link"
        onClick={navigateTo}
        disabled={!enable}
        color={textColor}
      >
        <FormattedMessage id={label} />
      </StyledLink>
    </Box>
  );
};

export default NavigateLink;
