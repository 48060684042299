import React from "react";
import { Flex, H2 } from "ui-components";
import FormattedMessage from "components/FormattedMessage";

const INLINE_PAGE_HEADERS = ({ headers }) => (
  <Flex>
    {headers.map(pageHeader => {
      const { enable = false, label, ...headerProps } = pageHeader;
      if (!enable) return null;

      return (
        <FormattedMessage key={label} id={label}>
          {greeting => (
            <H2 mb={[12, 24]} {...headerProps}>
              {greeting} &nbsp;
            </H2>
          )}
        </FormattedMessage>
      );
    })}{" "}
  </Flex>
);

export default INLINE_PAGE_HEADERS;
