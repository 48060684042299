import React from "react";
import get from "lodash/get";
import DeliverySlotGroup from "components/FormFields/DeliverySlotGroup";

const FREE_DELIVERY_SLOT_PICKER = ({
  change,
  formValues,
  freeDeliverySlotTime,
  data = {},
  ...props
}) => {
  const deliveryDate = get(formValues, "delivery_date");

  return deliveryDate ? (
    <DeliverySlotGroup
      change={change}
      options={freeDeliverySlotTime}
      {...props}
      {...data}
    />
  ) : null;
};

export default FREE_DELIVERY_SLOT_PICKER;
