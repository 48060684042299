import React, { useEffect } from "react";
import get from "lodash/get";
import { getURLParams } from "utils/helpers";
import SIMCountSelector from "components/SIMCountSelector/index";

const SIM_COUNT_SELECTOR = ({ data, change, formValues, ...props }) => {
  const { sim_count } = getURLParams();
  const { maxNumber, minNumber, fieldName } = data;
  useEffect(() => {
    let count;
    if (sim_count) {
      if (+sim_count >= maxNumber) {
        count = maxNumber;
      } else if (+sim_count <= 0) {
        count = minNumber;
      } else {
        count = +sim_count;
      }
    } else {
      count = minNumber;
    }

    handleChange(
      fieldName,
      get(props, "initialValues.sim_count")
        ? get(props, "initialValues.sim_count")
        : count
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (field, simCount) => {
    change(field, simCount);
  };

  return (
    <SIMCountSelector
      handleChange={handleChange}
      {...data}
      simCount={formValues[fieldName]}
    />
  );
};

export default SIM_COUNT_SELECTOR;
