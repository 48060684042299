import React from "react";
import { push as Menu } from "react-burger-menu";
import FormattedMessage from "components/FormattedMessage";
import { Flex, Box, Button } from "ui-components";
import styles from "./styles";
import map from "lodash/map";
import get from "lodash/get";
import head from "lodash/head";
import split from "lodash/split";
import * as Icons from "components/Icons";
import {
  COLORS,
  getButtonColorVariant,
  buttonThemes
} from "ui-components/theme";
import { gotoUrl } from "utils/helpers";

const SideMenu = ({ open, setOpen, buttons, themeColors }) => (
  <Menu
    styles={styles}
    pageWrapId="page-wrap"
    outerContainerId="root"
    onStateChange={state => setOpen(state.isOpen)}
    isOpen={open}
    customBurgerIcon={false}
    customCrossIcon={false}
    disableAutoFocus
  >
    {map(buttons, ({ label, url = "", icon = "" }, index) => {
      const Icon = get(Icons, icon);
      const path = head(split(url, "?"));
      const selected = window.location.pathname === path;
      const variant = selected ? "primary" : "sideMenuLink";
      const buttonTheme = get(themeColors, "button") || buttonThemes.blue;
      return (
        <Button
          key={index}
          px={4}
          py={"26px"}
          variant={"sideMenuLink"}
          as={"a"}
          onClick={() => gotoUrl(url, false, {}, true)}
          sx={getButtonColorVariant(buttonTheme, variant)}
        >
          <Flex alignItems={"center"}>
            <Box width={"48px"}>{Icon && <Icon color={COLORS.white} />}</Box>
            <FormattedMessage id={label} />
          </Flex>
        </Button>
      );
    })}
  </Menu>
);

export default SideMenu;
