import React from "react";
import get from "lodash/get";
import { Text } from "ui-components";
import DropdownField from "components/FormFields/DropdownField";
import FormattedMessage from "components/FormattedMessage";
import { constructOnChangeHandler } from "utils/helpers";

const DELIVERY_FAIL_REASON = ({ scheduleDeliveryDetails, data, ...props }) => {
  const { title, subtitle } = data || {};
  const failureReasons = get(
    scheduleDeliveryDetails,
    "failure_reasons",
    []
  ).map(item => {
    return {
      label: item.name,
      value: item.value
    };
  });
  failureReasons.splice(0, 1);

  return (
    <>
      <DropdownField
        label={title}
        options={failureReasons}
        field="fail_reason"
        required={true}
        onChange={constructOnChangeHandler(props)}
      />
      {subtitle && (
        <Text size="sm" mt={-5} mb={5}>
          <FormattedMessage html id={subtitle} />
        </Text>
      )}
    </>
  );
};

export default DELIVERY_FAIL_REASON;
