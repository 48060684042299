import { createSelector } from "reselect";
import moment from "moment";
import get from "lodash/get";
import orderBy from "lodash/orderBy";
import map from "lodash/map";
import keys from "lodash/keys";
import values from "lodash/values";
import find from "lodash/find";
import each from "lodash/each";
import includes from "lodash/includes";
import forEach from "lodash/forEach";
import { generateWorkflowForSubpaths, constructDocTypes } from "utils/helpers";
import { getLocaleOrDefault } from "utils/localStorage";
import { CALENDAR_YEAR_OFFSET, PARTNERS_CHANNEL } from "utils/constants";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";
import isPlainObject from "lodash/isPlainObject";
import { iconThemes, buttonThemes, COLORS } from "ui-components/theme";
import {
  getChannelFromUrl,
  constructLocalizedDateLabel,
  formatMessage
} from "utils/helpers";
import {
  dateOptionsSelector,
  monthOptionsSelector,
  localeSelector
} from "./locale";

export const selectAppSettings = state =>
  get(state, "api.appSettings.data", {});

export const genericSelector = createSelector(selectAppSettings, appSettings =>
  get(appSettings, "generic")
);

export const currencyFormatSelector = createSelector(
  selectAppSettings,
  appSettings =>
    get(
      appSettings,
      `currency.currency_format.${getLocaleOrDefault()}.format.options`
    ) || get(appSettings, "currency.currency_format.en.format.options")
);

export const numberFormatSelector = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "formatting.numbers")
);

export const countriesSelector = createSelector(
  selectAppSettings,
  appSettings => {
    const countries = get(appSettings, "countries");
    const mappedCountries = map(values(countries), country => {
      return {
        id: country.iso3_code,
        label: country.name,
        value: country.iso3_code,
        display_sequence: country.display_sequence
      };
    });

    return orderBy(mappedCountries, ["display_sequence"], ["desc"]);
  }
);

export const statesSelector = createSelector(selectAppSettings, appSettings => {
  const states = get(appSettings, "states");
  const mappedStates = map(states, (state, stateKey) => {
    return {
      id: state.name,
      label: state.name,
      value: stateKey
    };
  });

  return mappedStates;
});

export const workflowSelector = createSelector(
  selectAppSettings,
  appSettings => {
    const planPath =
      window.location.pathname.split("/")[1] ||
      get(appSettings, "workflows.default", "web");
    const planWorkflowName = `workflows.${planPath}.workflow`;
    const globalWorkflowName = "global.workflow";
    const workflow = get(appSettings, planWorkflowName, []);
    const globalWorkflow = get(appSettings, globalWorkflowName, []);
    const otherWorkflows = [];
    each(get(appSettings, "workflows"), ({ workflow }, channel) => {
      if (channel !== planPath && isArray(workflow)) {
        otherWorkflows.push(...workflow);
      }
    });
    const generatedWorkflow = generateWorkflowForSubpaths([
      ...workflow,
      ...otherWorkflows,
      ...globalWorkflow
    ]);

    return generatedWorkflow;
  }
);

export const pageValidationsSelector = formName =>
  createSelector(
    selectAppSettings,
    workflowSelector,
    (appSettings, workflow) => {
      const channel = getChannelFromUrl();
      const urlParts = get(window, "location.pathname", "").split("/");
      const firstUrlPart = urlParts.length > 1 ? urlParts[2] : "web";
      let currentWorkflowPage;
      if (channel === PARTNERS_CHANNEL && firstUrlPart !== "success") {
        currentWorkflowPage = find(
          workflow,
          page => page.path === "/partners/:partnerID"
        );
      } else {
        currentWorkflowPage = find(
          workflow,
          page => page.path === window.location.pathname
        );
      }

      // array of common validations for a given form name. This can be empty or undefined.
      const genericFormValidations =
        appSettings?.formValidations?.[formName] || [];
      // array of page level form validations.
      const pageLevelFormValidations = currentWorkflowPage?.validations || [];

      return {
        validations: [...genericFormValidations, ...pageLevelFormValidations],
        appSettings,
        formName
      };
    }
  );

export const primaryDocTypesSelector = createSelector(
  selectAppSettings,
  appSettings => {
    const docTypes = get(appSettings, "documents.primary.types");
    const result = map(keys(docTypes), key => {
      const item = get(docTypes, [key]) || {};

      return {
        label: item.title,
        value: key,
        display_sequence: item.display_sequence
      };
    });

    return orderBy(result, ["display_sequence"], ["asc"]);
  }
);

export const docTypesSelector = createSelector(
  selectAppSettings,
  appSettings => {
    return {
      primary: constructDocTypes(get(appSettings, "documents.primary.types")),
      secondary: constructDocTypes(
        get(appSettings, "documents.secondary.types")
      )
    };
  }
);

export const dropIdentityValidationsSelector = createSelector(
  selectAppSettings,
  appSettings => {
    const validations = {};
    forEach(get(appSettings, "documents"), (config, type) => {
      validations[type] = value =>
        value ? undefined : get(config, "validation_message");
    });
    return validations;
  }
);

export const minimumAgeSelector = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "profile.age_validation.min.value")
);

export const dataOnlyMinAgeSelector = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "profile.data_only_age_validation.min.value")
);

export const paymentReturnChannelsWithoutDateTimeSelector = createSelector(
  selectAppSettings,
  appSettings => {
    const paymentReturnChannelsWithoutDateTime = get(
      appSettings,
      "payment_return.channels_without_date_time"
    );
    return paymentReturnChannelsWithoutDateTime;
  }
);

export const deliveryModesSelector = createSelector(
  selectAppSettings,
  appSettings => {
    const deliveryModes = get(appSettings, "delivery.modes");
    return deliveryModes;
  }
);

export const telcoListSelector = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "portin.donor_telcos")
);

export const prepaidTelcoListSelector = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "portin.prepaid_telcos")
);

export const postpaidTelcoListSelector = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "portin.postpaid_telcos")
);

export const telcoAccountNumberLengthSelector = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "portin.telco_account_number_length")
);

export const telcoAccountNumberImagesSelector = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "portin.telco_account_number_images")
);

export const yearOptionsSelector = createSelector(
  selectAppSettings,
  localeSelector,
  (appSettings, locale) => {
    const minimumAge = get(appSettings, "profile.age_validation.min.value");
    const lastYearOption = new Date().getFullYear() - minimumAge;
    const years = [];
    const calendarYearOffset = get(
      CALENDAR_YEAR_OFFSET,
      get(appSettings, "generic.calendar_type"),
      0
    );
    const yearLabel = get(locale, "year_label", "{year}");

    let startYear = lastYearOption - 100;

    while (startYear <= lastYearOption) {
      let year = startYear++;
      const label = year - calendarYearOffset;
      const localizedLabel = formatMessage(yearLabel, { year: label });
      if (label >= 0) years.push({ label: localizedLabel, value: year });
    }

    return years.reverse();
  }
);

export const nricIssueYearOptionsSelector = createSelector(
  selectAppSettings,
  localeSelector,
  (appSettings, locale) => {
    const lastYearOption = new Date().getFullYear();
    const years = [];
    const calendarYearOffset = get(
      CALENDAR_YEAR_OFFSET,
      get(appSettings, "generic.calendar_type"),
      0
    );
    const yearLabel = get(locale, "year_label", "{year}");

    let startYear = lastYearOption - 100;

    while (startYear <= lastYearOption) {
      let year = startYear++;
      const label = year - calendarYearOffset;
      const localizedLabel = formatMessage(yearLabel, { year: label });
      if (label >= 0) years.push({ label: localizedLabel, value: year });
    }

    return years.reverse();
  }
);

export const portinExpiryDateOptionsSelector = createSelector(
  selectAppSettings,
  dateOptionsSelector,
  monthOptionsSelector,
  (appSettings, dateOptions, monthOptions) => {
    const minExpiryDays = get(appSettings, "portin.expiry_days.min", 13);
    const maxExpiryDays = get(appSettings, "portin.expiry_days.max", 15);
    const expiryDateLabelFormat = get(
      appSettings,
      "portin.expiry_days.label_format",
      "DD-MMM-YYYY"
    );
    const expiryDateValueFormat = get(
      appSettings,
      "portin.expiry_days.value_format",
      "DD-MM-YYYY"
    );
    const minDate = moment().add(minExpiryDays, "days");
    const maxDate = moment().add(maxExpiryDays, "days");
    let currentDate = minDate;
    const expiryDates = [];
    while (currentDate.isBetween(minDate, maxDate, undefined, "[]")) {
      const label = constructLocalizedDateLabel(
        currentDate,
        dateOptions,
        monthOptions,
        expiryDateLabelFormat
      );
      const value = currentDate.format(expiryDateValueFormat);

      expiryDates.push({ label, value });
      currentDate = currentDate.add(1, "days");
    }

    return expiryDates;
  }
);

export const whiteListedErrorCodes = createSelector(
  selectAppSettings,
  appSettings => {
    /** The default codes are here to handle any errors before the app_settings api call brings
     * the full list of white listed error codes
     */
    const defaultCodes = [400104, 401003, 403001, -14493];
    return get(appSettings, "generic.white_listed_error_codes", defaultCodes);
  }
);

export const iccIdPrefixSelector = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "generic.icc_id.prefix")
);

export const iccIdSAPrefixSelector = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "generic.icc_id_sa.prefix")
);

export const parentalConsent = createSelector(selectAppSettings, appSettings =>
  get(appSettings, "generic.parent_filtering_config")
);

export const maxAgeParentalConsent = createSelector(
  parentalConsent,
  parentalConsent => get(parentalConsent, "consent_required.max")
);

export const paymentModeSelector = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "payment.modes")
);

export const themeColorsSelector = createSelector(
  selectAppSettings,
  appSettings => {
    const primary = get(appSettings, "themeColors.primary", "blue");
    const secondary = get(appSettings, "themeColors.secondary", "magenta");
    const tertiary = get(appSettings, "themeColors.tertiary", "cyan");
    const text = get(appSettings, "themeColors.text", primary);
    const button = get(appSettings, "themeColors.button", primary);
    const buttonTheme = isPlainObject(button) ? button : buttonThemes[button];
    const cta = get(appSettings, "themeColors.cta", buttonTheme?.color);
    const icon = get(appSettings, "themeColors.icon", buttonTheme?.color);
    const info = get(appSettings, "themeColors.info", COLORS.cyan[0]);
    const error = get(appSettings, "themeColors.error", "red");
    const success = get(appSettings, "themeColors.success", "green");
    const pending = get(appSettings, "themeColors.pending", "yellow");
    const card = get(appSettings, "themeColors.card", primary);
    const stepper = get(appSettings, "themeColors.stepper", {
      activeColor: COLORS.magenta[3],
      inactiveColor: COLORS.gray[0]
    });
    const badge = get(appSettings, "themeColors.badge", {
      backgroundColor: COLORS.magenta[3],
      color: COLORS.white
    });
    return {
      primary: iconThemes[primary] || primary,
      secondary: iconThemes[secondary] || secondary,
      tertiary: iconThemes[tertiary] || tertiary,
      text: iconThemes[text] || text,
      button: buttonTheme,
      cta: iconThemes[cta] || cta,
      icon: iconThemes[icon] || icon,
      info: iconThemes[info] || info,
      error: iconThemes[error] || error,
      success: iconThemes[success] || success,
      pending: iconThemes[pending] || pending,
      card: iconThemes[card] || card,
      stepper,
      badge
    };
  }
);

export const selectCurrencyCode = createSelector(
  selectAppSettings,
  appSettings => appSettings.currency?.currency_code
);

export const selectCountryIso0Code = createSelector(
  selectAppSettings,
  appSettings => appSettings.currency?.iso0_code
);

export const hideRequiredStarSelector = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "ui.hide_required_star")
);

export const oneStopPortOutParams = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "generic.onestopMNPPortoutCallbackParams", {})
);

export const oneStopMnpOnlyIOSBrowserSupportingTelcos = createSelector(
  selectAppSettings,
  appSettings =>
    get(appSettings, "generic.oneStopMnpOnlyIOSBrowserSupportingTelcos", [])
);

export const oneStopMnpProvidersNameCodeMap = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "generic.oneStopMnpProvidersNameCodeMap", {})
);

export const oneStopPortOutDelayConfig = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "generic.onestopMNPPortoutDelay", {})
);

export const kddiSubBrandsHosts = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "generic.kddiSubBrandsHosts", [])
);

export const paidyConfigs = createSelector(selectAppSettings, appSettings =>
  get(appSettings, "generic.payment_methods.paidy")
);

export const selectErrorPageConfigs = createSelector(
  selectAppSettings,
  appSettings => appSettings?.ui?.error_pages_config || {}
);

export const errorCodesSelector = createSelector(genericSelector, generic =>
  get(generic, "error_codes", {})
);

export const countryCodePrefixSelector = createSelector(
  genericSelector,
  generic => get(generic, "country_code", "").toString()
);

export const simDetailsSelector = createSelector(
  selectAppSettings,
  appSettings => get(appSettings, "sim")
);

export const supportedPlanIdMap = createSelector(genericSelector, generic =>
  get(generic, "supported_plans", {})
);

// ***************************************************************
// ******************* appSettings makeSelect ********************
// ***************************************************************

export const makeSelectAppSettings = () => selectAppSettings;
export const makeSelectWorkflow = () => workflowSelector;
export const makeSelectPageValidations = form => pageValidationsSelector(form);
export const makeSelectStates = () => statesSelector;
export const makeSelectCountries = () => countriesSelector;
export const makeSelectOtherCountries = () =>
  createSelector(selectAppSettings, appSettings => {
    return [];
  });
export const makeSelectRawCountryData = () =>
  createSelector(selectAppSettings, appSettings => {
    return get(appSettings, "countries", []);
  });
export const makeSelectPrimaryDocTypes = () => primaryDocTypesSelector;
export const makeSelectDocTypes = () => docTypesSelector;
export const makeSelectMinimumAge = () => minimumAgeSelector;
export const makeSelectDataOnlyMinimumAge = () => dataOnlyMinAgeSelector;
export const makeSelectYearOptions = () => yearOptionsSelector;
export const makeSelectNricIssueYearOptions = () =>
  nricIssueYearOptionsSelector;
export const makeSelectSAIccIdPrefix = () => iccIdSAPrefixSelector;
export const makeSelectIccIdPrefix = () => iccIdPrefixSelector;
export const makeSelectThemeColors = () => themeColorsSelector;
export const makeSelectCurrencyFormat = () => currencyFormatSelector;
export const makeSelectMaxAgeParentalConsent = () => maxAgeParentalConsent;

export const makeSelectTelcoList = () =>
  createSelector(
    telcoListSelector,
    prepaidTelcoListSelector,
    postpaidTelcoListSelector,
    telcoAccountNumberLengthSelector,
    telcoAccountNumberImagesSelector,
    (
      telcoList,
      prepaidTelcoList,
      postpaidTelcoList,
      telcoAccountNumberLength,
      telcoAccountNumberImages
    ) => {
      const mappedTelcoList = map(
        keys(telcoList)
          .filter(key => {
            return telcoList[key].enable;
          })
          .map(key => {
            const { display_name, display_sequence } = telcoList[key];
            const telco = {
              label: display_name,
              value: key,
              displaySequence: display_sequence
            };
            const portInType = includes(prepaidTelcoList, key)
              ? "prepaid"
              : includes(postpaidTelcoList, key)
              ? "postpaid"
              : "";

            const accountNumberLength = get(
              telcoAccountNumberLength,
              [key],
              get(telcoAccountNumberLength, "default")
            );
            const accountNumberImage = get(telcoAccountNumberImages, [key]);
            if (!isEmpty(portInType)) {
              telco["portInType"] = portInType;
            }
            if (!isEmpty(accountNumberLength)) {
              telco["accountNumberLength"] = accountNumberLength;
            }
            if (!isEmpty(accountNumberImage)) {
              telco["accountNumberImage"] = accountNumberImage;
            }
            return telco;
          })
      );

      return orderBy(mappedTelcoList, ["displaySequence"], ["asc"]);
    }
  );

export const makeSelectDefaultPromoCodeOffers = () =>
  createSelector(selectAppSettings, appSettings => {
    return get(appSettings, "promo_code_offer.value.default", []);
  });

export const makeSelectPlugin = () =>
  createSelector(selectAppSettings, appSettings => {
    return get(appSettings, "plugin", {});
  });

export const makeSelectPlugins = () =>
  createSelector(selectAppSettings, appSettings => {
    return get(appSettings, "plugins", {});
  });

export const makeSelectTitle = () =>
  createSelector(selectAppSettings, appSettings => {
    return get(appSettings, "title", "Circles.Life");
  });

export const makeSelectDropIdentityValidations = () =>
  dropIdentityValidationsSelector;

export const makeSelectPortinExpiryDateOptions = () =>
  portinExpiryDateOptionsSelector;

export const makeSelectWhiteListedErrorCodes = () => whiteListedErrorCodes;

export const makeSelectCurrencyCode = () => selectCurrencyCode;
export const makeSelectCountryIso0Code = () => selectCountryIso0Code;

export const makeSelectPageData = path =>
  createSelector(workflowSelector, workflow => {
    const currentWorkflowPage = find(
      workflow,
      page => page.path === window.location.pathname
    );
    const pageData = get(currentWorkflowPage, "data", {});
    return path ? get(pageData, path) : pageData;
  });

export const makeSelectCardTypes = () =>
  createSelector(genericSelector, generic => {
    return get(generic, "payment_cards", {});
  });

export const makeSelectRestrictedRoutes = () =>
  createSelector(genericSelector, generic => {
    return get(generic, "restricted_route_config.restricted_route", []);
  });

export const makeSelectIsEnableRestrictionRoute = () =>
  createSelector(genericSelector, generic => {
    return get(
      generic,
      "restricted_route_config.enableRouteRestriction",
      false
    );
  });

export const makeSelectCardTypesMap = () =>
  createSelector(genericSelector, generic => {
    return get(generic, "payment_card_map", {});
  });

export const makeSelectActivationHoursDetails = () =>
  createSelector(genericSelector, generic => {
    return get(generic, "activation_hours", {});
  });

export const makeSelectIsIdentityVerificationCheckEnabled = () =>
  createSelector(selectAppSettings, appSettings => {
    return get(
      appSettings,
      "identity_verification_status_check.payment_return",
      {}
    );
  });

export const makeSelectHideRequiredStar = () => hideRequiredStarSelector;
export const MakeSelectPaidyConfigs = () => paidyConfigs;
export const makeSelectOneStopMnpPortOutParams = () => oneStopPortOutParams;
export const makeSelectOneStopMnpPortOutDelayConfig = () =>
  oneStopPortOutDelayConfig;
export const makeSelectkddiSubBrandsHosts = () => kddiSubBrandsHosts;
export const makeSelectErrorPageConfigs = () => selectErrorPageConfigs;
export const makeSelectErrorCodes = () => errorCodesSelector;
export const makeSelectSIMDetails = () => simDetailsSelector;
export const makeSelectCountryCodePrefixSelector = () =>
  countryCodePrefixSelector;
export const makeSelectOneStopMnpOnlyIOSBrowserSupportingTelcos = () =>
  oneStopMnpOnlyIOSBrowserSupportingTelcos;
export const makeSelectOneStopMnpProvidersNameCodeMap = () =>
  oneStopMnpProvidersNameCodeMap;
export const makeSelectSupportedPlanIdMap = () => supportedPlanIdMap;
