import {
  fetchRequested,
  fetchRequestedWithCaptcha
} from "ducks/actions/baseActions";
import {
  BASE,
  USE_API_GATEWAY,
  API_GATEWAY_APP_SETTINGS,
  OMS_APP_SETTINGS,
  API_GATEWAY_ELIGIBLE_PLANS,
  OMS_ELIGIBLE_PLANS,
  API_GATEWAY_LOCALE,
  OMS_LOCALE,
  API_GATEWAY_CREATE_ORDER,
  OMS_CREATE_ORDER_TEMP,
  API_GATEWAY_PAYMENT_DETAILS,
  OMS_PAYMENT_RETURN,
  API_GATEWAY_UPDATE_PARENTAL_CONSENT,
  API_GATEWAY_UPDATE_ORDER,
  API_GATEWAY_SUBMIT_DELIVERY,
  API_GATEWAY_ESIM_QR,
  API_GATEWAY_DELIVERY_DETAILS,
  API_GATEWAY_PAYMENT_REQUEST,
  API_GATEWAY_ACTIVATE_SIM_CARD,
  API_GATEWAY_DOWNLOAD_SERVICE_AGREEMENT,
  API_GATEWAY_MANAGE_ORDER,
  OMS_GUEST_ORDER,
  API_GATEWAY_ORDER_CANCELLATION,
  OMS_ORDER_CANCELLATION,
  API_GATEWAY_ORDER_CANCELLATION_STATUS,
  OMS_ORDER_CANCELLATION_STATUS,
  API_GATEWAY_ACTIVATE_REPLACE_SIM_CARD,
  OMS_ACTIVATE_SIM_CARD,
  API_GATEWAY_RESUBMIT_PORTIN_DETAILS,
  API_GATEWAY_CREATE_DATA_ONLY_ORDER
} from "utils/constants";
import { getLocaleOrDefault } from "utils/localStorage";

const fetchAppSettings = callback => {
  const path = USE_API_GATEWAY ? API_GATEWAY_APP_SETTINGS : OMS_APP_SETTINGS;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({ key: "appSettings", path, base, callback });
};

const fetchEligiblePlans = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_ELIGIBLE_PLANS
    : OMS_ELIGIBLE_PLANS;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "eligiblePlans",
    path,
    base,
    params,
    callback
  });
};

const fetchLocale = callback => {
  const locale = getLocaleOrDefault();
  const path = USE_API_GATEWAY ? API_GATEWAY_LOCALE : OMS_LOCALE;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "locale",
    path,
    base,
    params: { locale },
    callback
  });
};

export const createInitialOrder = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_CREATE_ORDER
    : OMS_CREATE_ORDER_TEMP;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "order",
    params,
    path,
    base,
    method: "POST",
    callback,
    meta: {
      captchaAction: "create_order"
    }
  });
};

export const postPaymentRequest = (params, callback) => {
  const path = API_GATEWAY_PAYMENT_REQUEST;
  const base = BASE.API_GATEWAY;

  return fetchRequestedWithCaptcha({
    key: "paymentRequest",
    path,
    base,
    method: "POST",
    params,
    callback,
    meta: {
      captchaAction: "payment_request"
    }
  });
};

const fetchPaymentReturn = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_PAYMENT_DETAILS
    : OMS_PAYMENT_RETURN;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "paymentReturn",
    path,
    base,
    method: "POST",
    params,
    callback,
    meta: {
      captchaAction: "payment_details"
    }
  });
};

export const updateParentalConsent = (headers, params, callback) => {
  return fetchRequested({
    key: "updateParentalConsent",
    base: BASE.API_GATEWAY,
    path: API_GATEWAY_UPDATE_PARENTAL_CONSENT,
    method: "POST",
    headers,
    params,
    callback
  });
};

export const updateOrder = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_UPDATE_ORDER
    : OMS_CREATE_ORDER_TEMP;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "order",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const submitScheduleDeliveryNewSim = (params, callback) => {
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;

  return fetchRequested({
    key: "submitScheduleDelivery",
    params,
    path: API_GATEWAY_SUBMIT_DELIVERY,
    base,
    method: "POST",
    callback
  });
};

export const fetchEsimQR = (headers, params, callback) => {
  return fetchRequested({
    key: "esimQR",
    base: BASE.API_GATEWAY,
    headers,
    params,
    path: API_GATEWAY_ESIM_QR,
    method: "POST",
    callback,
    mediaStream: true
  });
};

export const fetchDeliveryDetails = params => {
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "scheduleDeliveryDetails",
    path: API_GATEWAY_DELIVERY_DETAILS,
    base,
    params
  });
};

export const activateSimCard = (params, callback) => {
  const path = API_GATEWAY_ACTIVATE_SIM_CARD;
  const base = BASE.API_GATEWAY;
  return fetchRequestedWithCaptcha({
    key: "activateSimCard",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const fetchAgreementForm = (headers, params, callback) => {
  return fetchRequested({
    key: "contractualAgreement",
    base: BASE.API_GATEWAY,
    headers,
    params,
    path: API_GATEWAY_DOWNLOAD_SERVICE_AGREEMENT,
    method: "GET",
    callback,
    mediaStream: true,
    base64conversion: false
  });
};
const fetchGuestOrder = (params, callback) => {
  const path = USE_API_GATEWAY ? API_GATEWAY_MANAGE_ORDER : OMS_GUEST_ORDER;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;

  return fetchRequestedWithCaptcha({
    key: "guestOrder",
    path,
    base,
    method: "POST",
    params,
    callback,
    override: true
  });
};

const fetchUserOrders = (params, callback) => {
  const path = USE_API_GATEWAY ? API_GATEWAY_MANAGE_ORDER : OMS_GUEST_ORDER;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "userOrders",
    path,
    base,
    method: "POST",
    params,
    callback,
    override: true
  });
};

export const submitOrderCancellation = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_ORDER_CANCELLATION
    : OMS_ORDER_CANCELLATION;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "submitOrderCancellation",
    base,
    path,
    method: "POST",
    params,
    callback
  });
};

export const fetchOrderCancellationStatus = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_ORDER_CANCELLATION_STATUS
    : OMS_ORDER_CANCELLATION_STATUS;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "fetchOrderCancellationStatus",
    base,
    path,
    method: "GET",
    params,
    callback
  });
};

export const activateReplaceSimCard = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_ACTIVATE_REPLACE_SIM_CARD
    : OMS_ACTIVATE_SIM_CARD;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "lostSimActivate",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const updatePortInDetails = (params, callback) => {
  return fetchRequested({
    key: "updatePortInDetails",
    base: BASE.API_GATEWAY,
    path: API_GATEWAY_RESUBMIT_PORTIN_DETAILS,
    method: "POST",
    params,
    callback
  });
};

export const createDataOnlyOrder = (params, callback) => {
  return fetchRequestedWithCaptcha({
    key: "order",
    params,
    path: API_GATEWAY_CREATE_DATA_ONLY_ORDER,
    base: BASE.API_GATEWAY,
    method: "POST",
    callback,
    meta: {
      captchaAction: "create_data_only_order"
    }
  });
};

export default {
  fetchAppSettings,
  fetchEligiblePlans,
  fetchLocale,
  createInitialOrder,
  postPaymentRequest,
  fetchRequestedWithCaptcha,
  fetchPaymentReturn,
  updateParentalConsent,
  updateOrder,
  submitScheduleDeliveryNewSim,
  fetchEsimQR,
  fetchDeliveryDetails,
  activateSimCard,
  fetchAgreementForm,
  fetchUserOrders,
  fetchGuestOrder,
  submitOrderCancellation,
  fetchOrderCancellationStatus,
  activateReplaceSimCard,
  updatePortInDetails,
  createDataOnlyOrder
};
