import React from "react";
import { Avatar } from "ui-components";
import { randomNumber, gotoUrl, urlParam } from "utils/helpers";
import { CALL_BACK_URL } from "utils/constants";
import { useLocation } from "react-router-dom";
import {
  setItem,
  SOCIAL_MEDIA_STATE,
  SOCIAL_MEDIA_CLIENT_ID,
  APP_REDIRECT_PATH
} from "utils/localStorage";

const GoogleIcon = ({
  iconUrl,
  socialLoginUrl: googleAuthUrl,
  channelId,
  overrideParams = {}
}) => {
  const { pathname } = useLocation();
  const scope = "openid profile email";
  const redirect = urlParam("redirect");

  /**
   * OverrideParams can be used to override default values or to inject any
   * additional params.It is coming from generic.social_media_signin_options
   */
  const onClickEvent = () => {
    const path = pathname.replace(/\//g, "z");
    const state = `${randomNumber()}${path}`;
    const urlParams = {
      response_type: "code",
      client_id: channelId,
      redirect_uri: CALL_BACK_URL.GOOGLE,
      state: state,
      scope: scope,
      nonce: randomNumber(),
      ...overrideParams
    };
    if (googleAuthUrl) {
      setItem(SOCIAL_MEDIA_STATE, state);
      setItem(SOCIAL_MEDIA_CLIENT_ID, channelId);
      // Sets redirect url to redirect after loggin
      redirect && setItem(APP_REDIRECT_PATH, redirect);
      gotoUrl(googleAuthUrl, false, {}, false, urlParams);
    }
  };

  return (
    <Avatar
      src={iconUrl}
      onClick={onClickEvent}
      data-testid="google-icon"
      style={{ cursor: "pointer" }}
    />
  );
};
export default GoogleIcon;
