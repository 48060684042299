import React, { useState } from "react";
import FormattedMessage from "components/FormattedMessage";
import map from "lodash/map";
import { Flex, Button } from "ui-components";
import { MANAGE_ORDER_TRANSLATION_MAPPINGS } from "utils/constants";
import { urlParam, getActionUrl } from "utils/helpers";
import { getButtonColorVariant } from "ui-components/theme";
import OTPModal from "components/OTPModal";
import get from "lodash/get";
import has from "lodash/has";
import includes from "lodash/includes";

const OrderActionsCard = props => {
  const {
    fetchOrderDetailsAndNavigate,
    orderRef,
    orderActions,
    buttonTheme,
    locale,
    orderActionName,
    data,
    onActionClicked
  } = props;

  const [showModal, setShowModal] = useState(false);

  const closeModal = () => setShowModal(false);
  const [fullyQualifiedURL, setFullyQualifiedURL] = useState("");
  const [anchorTarget, setAnchorTarget] = useState("_self");

  const fetchOrderDetailsParam = {
    orderRef,
    sessionToken: "",
    orderActionName,
    closeModal,
    setShowModal,
    locale
  };

  const onOTPSubmitSuccess = sessionToken => {
    fetchOrderDetailsAndNavigate({
      ...fetchOrderDetailsParam,
      fullyQualifiedURL,
      anchorTarget,
      sessionToken
    });
  };

  const onClick = action => {
    const fullyQualifiedURL =
      get(data, ["action_config", action, "url"]) || getActionUrl(action);
    const requireOTPCall = !has(data, ["otp_skip_action_config", action], true);
    const actionType =
      get(data, ["action_config", action, "action_type"]) || action;
    const anchorTarget = get(
      data,
      ["action_config", action, "target"],
      "_self"
    );

    setAnchorTarget(anchorTarget);
    setFullyQualifiedURL(fullyQualifiedURL);
    onActionClicked(
      fetchOrderDetailsParam,
      action,
      actionType,
      anchorTarget,
      fullyQualifiedURL,
      requireOTPCall
    );
  };

  const buttonWidth = get(data, "buttonWidth", [1, 0.8, 0.6]);
  const buttonVariant = get(data, "buttonVariant", "outline");
  const queryParamsConditions = get(data, "query_param_required", {});
  const unsupportedActions = get(data, "unsupportedActions", []);

  return (
    <>
      <Flex flexDirection="column" alignItems="center">
        {map(orderActions, action => {
          const queryParamRequired = queryParamsConditions[action];
          const allowAction = queryParamRequired
            ? urlParam(queryParamRequired) === "true"
            : true;
          const actionLabel = MANAGE_ORDER_TRANSLATION_MAPPINGS[action];
          return !includes(unsupportedActions, action) &&
            allowAction &&
            actionLabel ? (
            <Button
              key={`action-${action}`}
              data-testid={`order-action-${action}-button`}
              my={1}
              variant={buttonVariant}
              sx={getButtonColorVariant(buttonTheme, buttonVariant)}
              width={buttonWidth}
              onClick={() => onClick(action)}
            >
              <FormattedMessage id={actionLabel} />
            </Button>
          ) : null;
        })}
      </Flex>

      <OTPModal
        {...props}
        showModal={showModal}
        closeModal={closeModal}
        buttonTheme={buttonTheme}
        onSuccess={onOTPSubmitSuccess}
      />
    </>
  );
};

export default OrderActionsCard;
