import { getDeviceId, setItem, USER_TYPE } from "utils/localStorage";
import { getChannelFromUrl, getDeviceType, gotoUrl } from "utils/helpers";
import {
  storeSocialLoginData,
  storeSocialMultipleEmails,
  UserService
} from "ducks/actions";
import { publicPaths, SOCIAL_MEDIA_TYPES } from "utils/constants";
import { useDispatch, useSelector } from "react-redux";
import useAuthorizedUserRedirection from "./useAuthorizedUserRedirection";
import {
  makeSelectIsSignUpWithIPDB,
  makeSelectSocialMediaOptions
} from "ducks/selectors";

const useSocialAuthUserServiceHandler = (
  channel,
  onFailureRedirectUrl,
  redirectChannel
) => {
  const dispatch = useDispatch();
  const redirectUser = useAuthorizedUserRedirection(redirectChannel);
  const socialMediaOptions = useSelector(makeSelectSocialMediaOptions());
  const isSignUpWithAuIPDB = useSelector(makeSelectIsSignUpWithIPDB());

  const callUserServiceLoginApi = (socialAuthData = {}) => {
    const additionalHeaders = {};
    const bodyParams = {
      device: {
        device_id: getDeviceId(),
        device_type: getDeviceType(),
        app_type: "ecosystem"
      },
      social_auth: socialAuthData
    };
    if (isSignUpWithAuIPDB) {
      bodyParams.isIPDB = true;
    }

    const onComplete = (result, errors, errorBody) => {
      // if there is a error redirect to previous route, otherwise call handleUserServiceResponse method
      if (errors) {
        if (onFailureRedirectUrl) gotoUrl(onFailureRedirectUrl);
      } else {
        setItem(USER_TYPE, socialAuthData.provider);
        handleUserServiceResponse(result, errors, socialAuthData);
      }
    };

    dispatch(
      UserService.V4.userSocialLoginAuthenticate(
        additionalHeaders,
        bodyParams,
        onComplete
      )
    );
  };

  const redirectToRoute = (path, queryParam) => {
    gotoUrl(
      path.replace(
        ":channel",
        redirectChannel ? redirectChannel : getChannelFromUrl
      ),
      false,
      null,
      false,
      queryParam
    );
  };

  const handleUserServiceResponse = (result, errors, socialAuthData) => {
    const EMAIL_RETRIEVE_FAIL_CODE = 200104;
    const MULTIPLE_EMAIL_RETRIEVE_CODE = 200112;
    const { code: statusCode, auth_token, email_adrs, request_uri } =
      result || {};

    //User service returned auth token if user has already signed up
    if (auth_token) {
      return redirectUser(result, errors, channel);
    }

    if (isSignUpWithAuIPDB) {
      dispatch(storeSocialMultipleEmails({ email_adrs }));
      dispatch(storeSocialLoginData(socialAuthData));
      const {
        auPinRequest: { url: pinAuthenticationUrl, pinRequestParams = {} } = {}
      } = socialMediaOptions["AU_IPDB"] || {};
      const params = {
        ...pinRequestParams,
        request_uri: request_uri
      };
      //redirect user to AU PIN Page;
      pinAuthenticationUrl &&
        gotoUrl(pinAuthenticationUrl, false, {}, false, params);
    } else if (socialAuthData.provider === SOCIAL_MEDIA_TYPES.AU_LOGIN) {
      dispatch(storeSocialMultipleEmails({ email_adrs }));
      dispatch(storeSocialLoginData(socialAuthData, onFailureRedirectUrl));
      redirectToRoute(publicPaths.SELECT_EMAIL);
    } else {
      //handling the social media auth response except AU
      if (statusCode === EMAIL_RETRIEVE_FAIL_CODE) {
        // redirects to collect email and continue login flow
        dispatch(storeSocialLoginData(socialAuthData));
        redirectToRoute(publicPaths.CONFIRM_EMAIL, { isSocialLogin: true });
      } else if (statusCode === MULTIPLE_EMAIL_RETRIEVE_CODE) {
        // redirects to select a email and continue login flow
        const { metadata: emailList } = result;
        dispatch(storeSocialLoginData(socialAuthData));
        dispatch(storeSocialMultipleEmails(emailList));
        redirectToRoute(publicPaths.SELECT_EMAIL, { continueWithoutOtp: true });
      } else redirectUser(result, errors, channel);
    }
  };

  return socialAuthData => callUserServiceLoginApi(socialAuthData);
};

export default useSocialAuthUserServiceHandler;
