import React, { memo, useEffect } from "react";
import { compose } from "redux";
import SmartRender from "containers/SmartRender/index";
import { reduxForm } from "redux-form";
import {
  forms,
  publicPaths,
  AUTH_MODES,
  MOBILE_APP_BROWSER_PROTOCOL,
  paths
} from "utils/constants";
import validate from "validations";
import { connect, useSelector, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  makeSelectContractDetailsForm,
  makeSelectAUInstructionsURL,
  makeSelectPageValidations,
  makeSelectExternalUserId,
  makeSelectIsFetchingData,
  makeSelectEligiblePlans,
  makeSelectEligiblePlanRequestChannel
} from "ducks/selectors";
import { makeSelectContactDetailsLoginEmail } from "ducks/selectors/forms/contractDetails";
import { clearLocalStorage, getDeviceId, isWebview } from "utils/localStorage";
import {
  clearSocialLoginData,
  UserService,
  setContractDetailsPageVisited,
  clearAllData,
  OMS,
  selectPlan
} from "ducks/actions";
import {
  gotoUrl,
  getChannelFromUrl,
  isAndroid,
  maintenanceOnError
} from "utils/helpers";
import { logoutUserCallback } from "ducks/sagas/utils/helpers";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import head from "lodash/head";
import { featureFlagDataOnlyPlanEnabled } from "utils/featureFlags";

const AU_TO_POVO = "AU_TO_POVO";

const ContractDetails = SmartRender =>
  function Comp(props) {
    const {
      fetchEligiblePlans,
      isFetchingEligiblePlans,
      eligiblePlans,
      match,
      eligiblePlanChannel,
      selectPlan,
      formValues,
      auToPovoInstructionsURL,
      external_id
    } = props;

    const { channel } = get(match, "params", {});

    const email = useSelector(makeSelectContactDetailsLoginEmail());
    const dispatch = useDispatch();

    const onNextClickGuestUser = navigate => {
      if (formValues.number_type === AU_TO_POVO) {
        navigateToAUInstructionsPage();
      } else if (formValues.sim_type && !props.invalid) {
        loginWithEmailId();
      }
    };

    const loginWithEmailId = () => {
      dispatch(clearSocialLoginData());
      const deviceId = getDeviceId();
      const headers = {};
      const bodyParams = {
        email,
        device_id: deviceId
      };

      const onSuccess = (_, errors) => {
        if (!errors) {
          requestOTPForEmail(email);
        }
      };

      dispatch(UserService.V4.userLogin(headers, bodyParams, onSuccess));
    };

    const logoutAndStartAUPortin = () => {
      const callBack = isWebview() ? logoutUserCallback : undefined;
      const bodyParams = {
        device_id: getDeviceId(),
        user_id: external_id
      };

      if (isAndroid()) {
        dispatch(
          UserService.V4.userLogout({}, bodyParams, (_, error) => {
            navigateToAUInstructionsPage();
            gotoUrl(paths.LOGGED_OUT, false, {}, true, { reset: true });
          })
        );
      } else {
        setTimeout(() => {
          navigateToAUInstructionsPage();
        }, 100);
        dispatch(UserService.V4.userLogout({}, bodyParams, callBack));
      }
    };

    const requestOTPForEmail = email => {
      const additionalHeaders = {};
      const bodyParams = {
        email,
        auth_mode: AUTH_MODES.ENHANCED_EMAIL_OTP
      };

      const onSubmitCallback = (response, errors) => {
        const success = !errors;
        const VerifyOTPPath = publicPaths.VERIFY_OTP.replace(
          ":channel",
          getChannelFromUrl()
        );

        if (success) {
          const queryParams = { key: Date.now() };
          gotoUrl(VerifyOTPPath, true, queryParams);
        } else {
          console.error("Request OTP failed. Cause: ", errors);
        }
      };

      dispatch(
        UserService.V4.userLoginOTP(
          additionalHeaders,
          bodyParams,
          onSubmitCallback
        )
      );
    };

    const navigateToAUInstructionsPage = (
      targetWebApp = "_self",
      targetWebview = "_blank"
    ) => {
      if (isWebview()) {
        // open in browser
        window.open(
          auToPovoInstructionsURL.replace("https", MOBILE_APP_BROWSER_PROTOCOL),
          targetWebview
        );
      } else {
        dispatch(clearAllData());
        clearLocalStorage();
        window.open(auToPovoInstructionsURL, targetWebApp);
      }
    };

    useEffect(() => {
      dispatch(setContractDetailsPageVisited());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const planParams = {};
    if (channel) {
      planParams["channel"] = channel;
    }

    useEffect(
      () => {
        if (
          (isEmpty(eligiblePlans) || eligiblePlanChannel !== channel) &&
          !isFetchingEligiblePlans &&
          featureFlagDataOnlyPlanEnabled()
        ) {
          fetchEligiblePlans(planParams);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [eligiblePlans, isFetchingEligiblePlans, eligiblePlanChannel]
    );

    useEffect(() => {
      if (
        !isEmpty(eligiblePlans) &&
        !isFetchingEligiblePlans &&
        featureFlagDataOnlyPlanEnabled()
      ) {
        selectPlan(head(eligiblePlans));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectPlan, eligiblePlans, isFetchingEligiblePlans]);

    return (
      <SmartRender
        {...props}
        onNextClickGuestUser={onNextClickGuestUser}
        onEnter={onNextClickGuestUser}
        logoutAndStartAUPortin={logoutAndStartAUPortin}
      />
    );
  };

const mapStateToProps = createStructuredSelector({
  formValues: makeSelectContractDetailsForm(),
  formValidations: makeSelectPageValidations(forms.CONTRACT_DETAILS_FORM),
  auToPovoInstructionsURL: makeSelectAUInstructionsURL(),
  external_id: makeSelectExternalUserId(),
  initialValues: _ => {
    return {
      number_type: "PORT_IN"
    };
  },
  isFetchingEligiblePlans: makeSelectIsFetchingData("eligiblePlans"),
  eligiblePlans: makeSelectEligiblePlans(),
  eligiblePlanChannel: makeSelectEligiblePlanRequestChannel()
});

export function mapDispatchToProps(dispatch) {
  return {
    fetchEligiblePlans: params => {
      dispatch(OMS.V4.fetchEligiblePlans(params, maintenanceOnError));
    },
    selectPlan: plan => dispatch(selectPlan(plan))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.CONTRACT_DETAILS_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(ContractDetails(SmartRender));
