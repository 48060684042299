import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "@css/app.scss";
import "@css/tailwind.css";
// import { IntlProvider } from "react-intl";
// import legacyConfigureStore from "@legacy_redux/store";
// import LegacyApp from "@legacy/App";
// import LegacyErrorBoundary from "@legacy_components/ErrorBoundary";
// import LegacySession from "@legacy_session";
// import {
//   OMS_URL as LEGACY_OMS_URL,
//   OMS_VERSION_PATH as LEGACY_OMS_VERSION_PATH
// } from "@legacy_constants";
// import { messages } from "@legacy_country/config.js";
// import * as COUNTRY from "@legacy_country/constants";
// import { urlParam as legacyUrlParam } from "@legacy_utility";
// import axios from "axios";

import configureStore from "./configureStore";
import App from "containers/App";
import { urlParam, isAlphaFlow } from "utils/helpers";
import { storeUrlParams, clearLocalStorage } from "utils/localStorage";
import CleverTapAnalytics from "analytics/type/CleverTapAnalytics";
import { LEGACY_APP_ENABLED } from "utils/constants";
import initConviva from "utils/convivaSetup";

const { persistor, store } = configureStore();

const conditionToRenderAlpha =
  !LEGACY_APP_ENABLED ||
  localStorage.getItem("alpha") ||
  urlParam("alpha") ||
  isAlphaFlow();

if (conditionToRenderAlpha) {
  if (urlParam("reset")) {
    clearLocalStorage();
    persistor.purge();
    // Clear Old CleverTap ID to maintain unique users
    CleverTapAnalytics.clearOldCleverTapID();
    window.dataLayer = [];
  }

  storeUrlParams();

  initConviva();
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App persistor={persistor} store={store} />
      </PersistGate>
    </Provider>,
    document.getElementById("root")
  );
  // } else {
  //   const legacyStore = legacyConfigureStore();

  //   if (legacyUrlParam("reset")) {
  //     legacyStore.persistor.purge();
  //     clearLocalStorage();
  //   }

  //   axios.defaults.baseURL = `${LEGACY_OMS_URL}${LEGACY_OMS_VERSION_PATH}`;
  //   axios.defaults.headers.DeviceId = LegacySession.generateId();
  //   axios.defaults.timeout = 30000;

  //   ReactDOM.render(
  //     <IntlProvider
  //       locale={COUNTRY.defaultLanguage}
  //       messages={messages[COUNTRY.defaultLanguage]}
  //     >
  //       <Provider store={legacyStore.store}>
  //         <PersistGate loading={null} persistor={legacyStore.persistor}>
  //           <LegacyErrorBoundary>
  //             <LegacyApp
  //               persistor={legacyStore.persistor}
  //               store={legacyStore.store}
  //             />
  //           </LegacyErrorBoundary>
  //         </PersistGate>
  //       </Provider>
  //     </IntlProvider>,
  //     document.getElementById("root")
  //   );
  //
}
