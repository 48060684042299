import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { OMS, updateAuthToken } from "ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  urlParam,
  gotoUrl,
  pathWithChannel,
  isWebView,
  isAUPortinUser
} from "utils/helpers";
import { DATA_ONLY_CHANNERL, paths, publicPaths } from "utils/constants";
import {
  getAndRemoveItem,
  APP_REDIRECT_PATH,
  isAUAccount
} from "utils/localStorage";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import includes from "lodash/includes";
import get from "lodash/get";
import {
  makeSelectHasVisitedContractDetailsPage,
  makeSelectPlanTextName
} from "ducks/selectors";
import {
  featureFlagDataOnlyPlanEnabled,
  featureFlagMergeStepEnabled
} from "utils/featureFlags";
import { head } from "lodash";

const useFetchOrdersAndRedirect = redirectChannel => {
  const { channel: onPageChannel } = useParams();
  const [channelName, setChannelName] = useState("");
  const redirect = urlParam("redirect");
  const urlAuthToken = urlParam("auth_token");
  const dispatch = useDispatch();
  const hasVisitedContractDetailsPage = useSelector(
    makeSelectHasVisitedContractDetailsPage()
  );

  //TODO remove hasVisitedPreCheckoutPage after mergeStep is enable
  const hasVisitedPreCheckoutPage = !!useSelector(makeSelectPlanTextName());
  useEffect(() => {
    setChannelName(() => onPageChannel);

    // eslint-disable-next-line
  }, []);

  const fetchOrdersAndRedirect = (channel, redirectPath, userDetails) => {
    const isTelcoUser = get(userDetails, "telco_user");

    dispatch(
      OMS.V4.fetchUserOrders({}, (userOrders, errors) => {
        const orders = filter(
          userOrders,
          order => !includes(["IC", "TER", "CAN"], order.state)
        );
        const reload = isWebView();
        if (errors) {
          // If errors invalidate the token and redirect to login page
          dispatch(updateAuthToken(null, null));
          return gotoUrl(pathWithChannel(publicPaths.LOGIN_PAGE, channel));
        }

        const redirectFromSocialLogin = getAndRemoveItem(APP_REDIRECT_PATH);
        if (redirectFromSocialLogin)
          return gotoUrl(redirectFromSocialLogin, false, {}, reload);
        if (redirect) return gotoUrl(redirect, false, {}, reload);

        // we are clearing the auth_token param to load manage/update-card page.
        if (redirectPath) {
          return gotoUrl(redirectPath, true, { auth_token: null, reset: null });
        }

        //if user is logging in webview without auth_token in url, then user redirect to weblogin
        if (isWebView() && !urlAuthToken && isTelcoUser && !isAUAccount()) {
          return gotoUrl(paths.DASHBOARD, false, {}, reload);
        }

        if (!isEmpty(orders)) {
          if (channelName === "onestop-mnp") {
            return gotoUrl(
              paths.ONESTOP_MNP_DASHBOARD,
              false,
              {
                showOnestopPortout: true
              },
              reload
            );
          } else {
            if (featureFlagDataOnlyPlanEnabled()) {
              const isDataOnlyUser =
                get(head(orders), "plan_type", "") === DATA_ONLY_CHANNERL;
              const nextStepAction = get(orders[0], "next_step.action", "");
              if (isDataOnlyUser) {
                let nextPath = paths.E_SIM_PROCESSING;
                if (nextStepAction === "configure_esim") {
                  nextPath = isWebView()
                    ? paths.E_SIM_SET_UP_BY_API
                    : paths.E_SIM_SET_UP;
                }
                return gotoUrl(pathWithChannel(nextPath, DATA_ONLY_CHANNERL));
              } else {
                return gotoUrl(paths.MANAGE_ORDER, false, {}, reload);
              }
            } else {
              return gotoUrl(paths.MANAGE_ORDER, false, {}, reload);
            }
          }
        }

        if (featureFlagMergeStepEnabled()) {
          // checking if the user is previously AU portin tried user
          const isPreviousAUUser = isAUPortinUser(userDetails);

          // no orders & previously AU portin tried user. (Acc is AU linked)
          if (isPreviousAUUser) {
            return gotoUrl(
              pathWithChannel(paths.CONNECTION_DETAILS, redirectChannel),
              false,
              {},
              reload
            );
          }

          let nextRoute = hasVisitedContractDetailsPage
            ? paths.ORDER_SUMMARY
            : hasVisitedPreCheckoutPage
            ? paths.CONNECTION_DETAILS
            : paths.PRE_CHECKOUT;

          if (isWebView() && featureFlagDataOnlyPlanEnabled()) {
            nextRoute = publicPaths.CONTRACT_DETAILS_PAGE;
          }

          return gotoUrl(
            pathWithChannel(nextRoute, redirectChannel),
            false,
            {},
            reload
          );
        } else {
          const nextRoute = hasVisitedPreCheckoutPage
            ? paths.CONNECTION_DETAILS
            : paths.PRE_CHECKOUT;
          return gotoUrl(
            pathWithChannel(nextRoute, redirectChannel),
            false,
            {},
            reload
          );
        }
      })
    );
  };

  return fetchOrdersAndRedirect;
};

export default useFetchOrdersAndRedirect;
