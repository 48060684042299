import {
  fetchRequestedWithCaptcha,
  fetchRequested
} from "ducks/actions/baseActions";
import {
  BASE,
  USER_SERVICE_LOGIN,
  USER_SERVICE_OTP,
  USER_SERVICE_AUTHENTICATE,
  USER_SERVICE_LIQUID_URL,
  USER_SERVICE_USER_KYC,
  USER_SERVICE_LOGGEDIN_USER_DETAILS,
  USER_SERVICE_LIQUID_OCR_REGISTER,
  USER_SERVICE_REQUEST_OTP_V4_SECURE,
  USER_SERVICE_VALIDATE_OTP,
  USER_SERVICE_VALIDATE_OTP_TRIGGER,
  USER_SERVICE_UPDATE_EMAIL,
  USER_SERVICE_LOGOUT,
  USER_SERVICE_LINK_SOCIAL,
  USER_SERVICE_LIQUID_OCR_DATA,
  USER_SERVICE_SUBMIT_USER_KYC,
  USER_SERVICE_IPDB_AUTHENTICATE,
  USER_SERVICE_GUEST_OTP,
  USER_SERVICE_DELETE,
  USER_SERVICE_SOCIAL_LOGIN_AUTHENTICATE,
  USER_SERVICE_AUTH_OTP_REQUEST,
  USER_SERVICE_ADDRESS_UPDATE,
  USER_SERVICE_AUTH_SECURE_OTP_REQUEST,
  USER_SERVICE_DATA_ONLY_EMAIL_OTP,
  USER_SERVICE_DATA_ONLY_EMAIL_OTP_VALIDATE,
  USER_SERVICE_SMS_OTP,
  USER_SERVICE_SMS_VALIDATE_OTP,
  USER_SERVICE_ESIM_CONTACT_UPDATE,
  USER_SERVICE_ESIM_VALIDAT_CONTACT_NO_UPDATE
} from "utils/constants";

const userLogout = (headers, params, callback) => {
  return fetchRequested({
    key: "userLogout",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_LOGOUT,
    method: "POST",
    callback
  });
};

const userLogin = (headers, params, callback) => {
  return fetchRequestedWithCaptcha({
    key: "userLogin",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_LOGIN,
    method: "POST",
    callback,
    meta: {
      captchaAction: "Login"
    }
  });
};

const userLoginOTP = (headers, params, callback) => {
  return fetchRequested({
    key: "userLoginOTP",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_OTP,
    method: "POST",
    callback
  });
};

const userAuthenticate = (headers, params, callback) => {
  return fetchRequested({
    key: "userAuthenticate",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_AUTHENTICATE,
    method: "POST",
    callback
  });
};

const betaUserAuthenticate = (headers, params, callback) => {
  return fetchRequested({
    key: "betaUserAuthenticate",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_AUTHENTICATE,
    method: "POST",
    callback
  });
};

const userSocialLoginAuthenticate = (headers, params, callback) => {
  return fetchRequested({
    key: "userSocialAuthenticate",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_SOCIAL_LOGIN_AUTHENTICATE,
    method: "POST",
    callback
  });
};

const fetchLiquidUrl = (params, callback) => {
  return fetchRequested({
    key: "liquidURL",
    base: BASE.USER_SERVICE,
    params,
    path: USER_SERVICE_LIQUID_URL,
    method: "POST",
    callback
  });
};
export const fetchOTPSecured = (params, callback) => {
  return fetchRequested({
    key: "requestOTPV4",
    base: BASE.USER_SERVICE,
    path: USER_SERVICE_REQUEST_OTP_V4_SECURE,
    method: "POST",
    params,
    callback
  });
};

export const fetchUserKycDetails = (params, callback) => {
  return fetchRequested({
    key: "userKycDetails",
    base: BASE.USER_SERVICE,
    params,
    path: USER_SERVICE_USER_KYC,
    method: "GET",
    callback,
    whitelistedCodes: [400118]
  });
};

export const fetchLoggedInUserDetails = (headers, params, callback) => {
  return fetchRequested({
    key: "loggedInUserDetails",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_LOGGEDIN_USER_DETAILS,
    callback,
    replace: true
  });
};
export const fetchValidateOTP = (params, callback) => {
  return fetchRequestedWithCaptcha({
    key: "validateOTP",
    base: BASE.USER_SERVICE,
    path: USER_SERVICE_VALIDATE_OTP,
    method: "POST",
    params,
    callback
  });
};

export const fetchValidateOTPTrigger = (params, callback) => {
  return fetchRequestedWithCaptcha({
    key: "validateOTPTrigger",
    base: BASE.USER_SERVICE,
    path: USER_SERVICE_VALIDATE_OTP_TRIGGER,
    method: "POST",
    params,
    callback
  });
};

export const fetchUpdateNewEmail = (params, callback) => {
  return fetchRequested({
    key: "updateNewEmail",
    base: BASE.USER_SERVICE,
    path: USER_SERVICE_UPDATE_EMAIL,
    method: "PATCH",
    params,
    callback
  });
};
export const registerOcrData = (params, callback) => {
  return fetchRequested({
    key: "liquidOCRRegister",
    base: BASE.USER_SERVICE,
    params,
    method: "POST",
    path: USER_SERVICE_LIQUID_OCR_REGISTER,
    callback
  });
};

export const requestLinkSocialAccount = (params, callback) => {
  return fetchRequested({
    key: "linkSocialAccount",
    base: BASE.USER_SERVICE,
    params,
    path: USER_SERVICE_LINK_SOCIAL,
    method: "POST",
    // https://libertywireless.atlassian.net/wiki/spaces/KDDI/pages/2531885318/JP-740+auID+Link+and+Delink+with+povo+account?focusedCommentId=2578841611#comment-2578841611
    whitelistedCodes: [40066, 4000013, 5000013],
    callback
  });
};

export const getLiquidOcrData = (orderRef, callback) => {
  const path = `${USER_SERVICE_LIQUID_OCR_DATA}/${orderRef}`;
  const successData = { orderRef };

  return fetchRequested({
    key: "liquidOcrData",
    base: BASE.USER_SERVICE,
    path,
    callback,
    successData
  });
};

export const submitUserKycDetails = (params, callback) => {
  return fetchRequested({
    key: "submitUserKycDetails",
    base: BASE.USER_SERVICE,
    params,
    path: USER_SERVICE_SUBMIT_USER_KYC,
    method: "POST",
    callback
  });
};

export const auIpdbUserAuthenticate = (headers, params, callback) => {
  return fetchRequested({
    key: "userAuthenticate",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_IPDB_AUTHENTICATE,
    method: "POST",
    callback
  });
};

export const requestGuestOTP = (
  headers,
  params,
  callback,
  whitelistedCodes
) => {
  return fetchRequested({
    key: "guestOTP",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_GUEST_OTP,
    method: "POST",
    callback,
    whitelistedCodes
  });
};

export const deleteUserData = callback => {
  return fetchRequested({
    key: "deleteUserData",
    base: BASE.USER_SERVICE,
    method: "POST",
    path: USER_SERVICE_DELETE,
    callback
  });
};

export const requestUserAuthOTP = (params, callback) => {
  return fetchRequested({
    key: "requestUserAuthOTP",
    base: BASE.USER_SERVICE,
    path: USER_SERVICE_AUTH_OTP_REQUEST,
    method: "POST",
    params,
    callback
  });
};

export const requestSecureUserAuthOTP = (params, callback) => {
  return fetchRequested({
    key: "requestUserAuthOTP",
    base: BASE.USER_SERVICE,
    path: USER_SERVICE_AUTH_SECURE_OTP_REQUEST,
    method: "POST",
    params,
    callback
  });
};

export const updateAddress = (params, callback) => {
  return fetchRequested({
    key: "updateAddress",
    base: BASE.USER_SERVICE,
    path: USER_SERVICE_ADDRESS_UPDATE,
    method: "POST",
    params,
    callback
  });
};

const dataOnlyEmailRegistration = (params, callback) => {
  return fetchRequested({
    key: "dataOnlyUserEmailOTP",
    base: BASE.USER_SERVICE,
    params,
    path: USER_SERVICE_DATA_ONLY_EMAIL_OTP,
    method: "POST",
    callback
  });
};

const dataOnlyValidateEmailOTP = (params, callback) => {
  return fetchRequested({
    key: "userAuthenticate",
    base: BASE.USER_SERVICE,
    params,
    path: USER_SERVICE_DATA_ONLY_EMAIL_OTP_VALIDATE,
    method: "POST",
    whitelistedCodes: [4000361],
    callback
  });
};

const contactNumberValidation = (params, callback) => {
  return fetchRequested({
    key: "dataOnlyUserContactNoOTP",
    base: BASE.USER_SERVICE,
    params,
    path: USER_SERVICE_SMS_OTP,
    method: "POST",
    callback
  });
};

const validateContactNumberOTP = (params, callback) => {
  return fetchRequested({
    key: "dataOnlyContactNoValidateOTP",
    base: BASE.USER_SERVICE,
    params,
    path: USER_SERVICE_SMS_VALIDATE_OTP,
    method: "POST",
    whitelistedCodes: [4000361],
    callback
  });
};

const updateNewEsimContactNumber = (params, callback) => {
  return fetchRequested({
    key: "dataOnlyEsimUpdateContactNo",
    base: BASE.USER_SERVICE,
    params,
    path: USER_SERVICE_ESIM_CONTACT_UPDATE,
    method: "POST",
    callback
  });
};

const validateNewEsimContactNumber = (params, callback) => {
  return fetchRequested({
    key: "dataOnlyEsimValidateContactNo",
    base: BASE.USER_SERVICE,
    params,
    path: USER_SERVICE_ESIM_VALIDAT_CONTACT_NO_UPDATE,
    method: "POST",
    callback
  });
};

export default {
  userLogin,
  userLoginOTP,
  fetchOTPSecured,
  fetchValidateOTP,
  fetchValidateOTPTrigger,
  fetchUpdateNewEmail,
  userAuthenticate,
  fetchLiquidUrl,
  fetchUserKycDetails,
  fetchLoggedInUserDetails,
  registerOcrData,
  userLogout,
  requestLinkSocialAccount,
  getLiquidOcrData,
  submitUserKycDetails,
  auIpdbUserAuthenticate,
  requestGuestOTP,
  deleteUserData,
  userSocialLoginAuthenticate,
  requestUserAuthOTP,
  requestSecureUserAuthOTP,
  updateAddress,
  dataOnlyEmailRegistration,
  dataOnlyValidateEmailOTP,
  contactNumberValidation,
  validateContactNumberOTP,
  updateNewEsimContactNumber,
  validateNewEsimContactNumber,
  betaUserAuthenticate
};
