import React from "react";
import map from "lodash/map";
import { Box, Flex } from "ui-components";
import FormattedMessage from "components/FormattedMessage";

const PhoneBanner = ({ cardList }) => {
  return (
    <Box textAlign="center" m={7} py={8}>
      <Box mb={3}>
        <FormattedMessage
          html
          id={"spend_little_upfront_and_pay_installment"}
        />
      </Box>

      <Box mb={6}>
        <FormattedMessage html id={"accepted_cards_for_installments"} />
      </Box>

      <Flex justifyContent="center">
        {map(cardList, card => (
          <Box mx="1" key={card.alt}>
            <img
              style={{ height: 125, width: 125 }}
              src={card.src}
              alt={card.alt}
            />
          </Box>
        ))}
      </Flex>
    </Box>
  );
};

export default PhoneBanner;
