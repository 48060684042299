import { connect } from "react-redux";
import React, { useEffect, memo } from "react";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { matchPath } from "react-router";
import get from "lodash/get";
import find from "lodash/find";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import history from "utils/history";
import { Box } from "ui-components";
import Spinner, { styles } from "components/Spinner";
import Session from "session";
import {
  STANDALONE_WORKFLOW_PATHS,
  paths,
  PARTNERS_CHANNEL,
  STANDALONE_WORKFLOW_ROUTES,
  IS_PATHS_SECURED
} from "utils/constants";
import {
  gotoUrl,
  isMobile,
  getChannelFromUrl,
  isWorkflowChannel,
  getOnSubmitActionHandler,
  isAndroid,
  isIOS
} from "utils/helpers";
import {
  makeSelectWorkflow,
  makeSelectAppSettings,
  makeSelectCurrencyFormat,
  makeSelectPlanName,
  makeSelectPlanTextName,
  makeSelectPayTodayItems,
  makeSelectPayMonthlyItems,
  makeSelectPhoneVariant,
  makeSelectPhonePaymentOption,
  makeSelectUserDetails,
  makeSelectIsFetchingAnyData,
  makeSelectThemeColors
} from "ducks/selectors";
import {
  isWebview,
  isNative,
  setLandingPage,
  resetLandingPage,
  getLandingPage,
  setLandingPageVisited,
  getLandingPageVisited
} from "utils/localStorage";
import {
  renderPageBanner,
  renderPageComponents,
  renderPageFaqs,
  renderPageStepper
} from "smart-components/renderPageComponent";

const SmartRender = pageProps => {
  const { workflow, appSettings, messageData, match, themeColors } = pageProps;
  useEffect(() => {
    const channel = getChannelFromUrl();
    const channelWorkflow = find(workflow, ({ path }) =>
      path.match(`/${channel}`)
    );
    const landingPage = get(channelWorkflow, "path");
    isWorkflowChannel() ? setLandingPage(landingPage) : resetLandingPage();
    if (window.location.pathname === landingPage) {
      setLandingPageVisited(true);
    }
    if (window.location.pathname === paths.LANDING_PAGE) {
      const landingPagePath = get(workflow, "0.path", "");
      if (landingPagePath && window.location.pathname !== landingPagePath) {
        Session.getDeviceId();
        gotoUrl(landingPagePath, true, { reset: undefined });
      }
    } else {
      if (
        isWorkflowChannel() &&
        !getLandingPageVisited() &&
        !includes(STANDALONE_WORKFLOW_PATHS, window.location.pathname) &&
        !includes(STANDALONE_WORKFLOW_ROUTES, match?.path) &&
        !IS_PATHS_SECURED // disable landing page visited as login will manage workflow
      ) {
        gotoUrl(getLandingPage(), true, { reset: undefined });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflow]);

  const channel = getChannelFromUrl();
  const urlParts = get(window, "location.pathname", "").split("/");
  const firstUrlPart = urlParts.length > 1 ? urlParts[2] : "web";
  let currentWorkflowPage;
  if (channel === PARTNERS_CHANNEL && firstUrlPart !== "success") {
    currentWorkflowPage = find(
      workflow,
      page => page.path === "/partners/:partnerID"
    );
  } else {
    const pathname = window.location.pathname;
    currentWorkflowPage = find(workflow, page => {
      const match = matchPath(pathname, {
        path: page.path,
        exact: true,
        strict: false
      });
      return !!match;
    });
  }
  const maintenanceRedirectPage = get(appSettings, [
    "maintenanceRedirectPages",
    window.location.pathname
  ]);

  if (appSettings?.maintenanceMode) {
    gotoUrl(
      maintenanceRedirectPage ||
        appSettings?.maintenancePage ||
        paths.MAINTENANCE
    );
  }

  const redirectPage =
    get(currentWorkflowPage, "redirect") || maintenanceRedirectPage;
  if (redirectPage) {
    gotoUrl(redirectPage);
  }
  const pageComponents = get(currentWorkflowPage, "components");
  const pageBanner = get(currentWorkflowPage, "banner");
  const pageStepper = get(currentWorkflowPage, "stepper");
  const pageFaqs = get(currentWorkflowPage, "faqs");
  const themeVariant = get(currentWorkflowPage, "themeVariant", "main");
  const padding = get(currentWorkflowPage, "padding", [6, 8]);
  const screenshotEnabled = get(currentWorkflowPage, "screenshot", false);
  const flexMode = get(currentWorkflowPage, "flex", true);
  // Putting a validation saying the path will be available if the previous path is from one of the mentioned in allowedPrevPaths
  const allowedPrevPaths = get(currentWorkflowPage, "allowedPrevPaths");
  if (allowedPrevPaths) {
    const prevPath = window.location?.pathTracking?.previous;
    const currentPath = window.location?.pathTracking?.current;

    // if previous path is not in allowed paths take the user back to previous path
    // prevPath !== currentPath to allow reloads from same page
    if (prevPath !== currentPath && allowedPrevPaths.indexOf(prevPath) === -1) {
      return history.push(prevPath);
    }
  }

  const containerProps = {
    variant: themeVariant,
    padding: padding
  };

  const flexProps = !flexMode
    ? {}
    : {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between"
      };

  return (
    <div data-testid="smart-render">
      {renderPageBanner({ pageBanner })}
      {renderPageStepper({ pageStepper, padding })}
      <Box {...containerProps} {...flexProps}>
        {isEmpty(pageComponents) ? (
          <Spinner size={200} sx={styles.fullPage} />
        ) : (
          renderPageComponents({
            pageComponents,
            pageProps,
            appSettings,
            screenshotEnabled
          })
        )}
      </Box>
      {renderPageFaqs({ pageFaqs, messageData, themeColors })}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  workflow: makeSelectWorkflow(),
  appSettings: makeSelectAppSettings(),
  currencyFormat: makeSelectCurrencyFormat(),
  webview: isWebview,
  isWebview: isWebview,
  isNative: isNative,
  isMobile: isMobile,
  isAndroid: isAndroid,
  isIOS: isIOS,
  planName: makeSelectPlanName(),
  planTextName: makeSelectPlanTextName(),
  payTodayItems: makeSelectPayTodayItems(),
  payMonthlyItems: makeSelectPayMonthlyItems(),
  phoneVariant: makeSelectPhoneVariant(),
  phonePaymentOption: makeSelectPhonePaymentOption(),
  userDetails: makeSelectUserDetails(),
  isFetchingAnyData: makeSelectIsFetchingAnyData(),
  themeColors: makeSelectThemeColors()
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: (navigate, payload, onSubmitOverride, data) => {
    const combinedProps = {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      onSubmitOverride
    };
    const onSubmitActionHandler = getOnSubmitActionHandler(combinedProps);
    isFunction(onSubmitActionHandler)
      ? onSubmitActionHandler(navigate, payload, data)
      : isFunction(combinedProps.onSubmit)
      ? combinedProps.onSubmit(navigate, payload)
      : navigate();
  }
});

const withConnect = connect(mapStateToProps, mergeProps);
export default compose(withConnect, memo)(SmartRender);
