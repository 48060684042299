import React from "react";
import PageNavigation from "components/PageNavigation";
import { Box } from "ui-components";
import PageNavigationOTPSecured from "../PageNavigationOTPSecured";

const PageNavigationStackFooter = props => {
  const {
    top: topBtnProps = {},
    bottom: bottomBtnProps = {},
    containerStyles = {}
  } = props;
  const { data: topBtnData } = topBtnProps;
  const { data: bottomBtnData } = bottomBtnProps;

  return (
    <Box variant="stickyFooterBar" sx={containerStyles}>
      {topBtnProps.otp_secured ? (
        <PageNavigationOTPSecured
          {...props}
          {...topBtnProps}
          {...topBtnData}
          stickyFooter={false}
        />
      ) : (
        <PageNavigation
          {...props}
          {...topBtnProps}
          {...topBtnData}
          stickyFooter={false}
        />
      )}

      <PageNavigation
        {...props}
        {...bottomBtnProps}
        {...bottomBtnData}
        stickyFooter={false}
      />
    </Box>
  );
};

export default PageNavigationStackFooter;
