import React, { useEffect } from "react";
import { ChevronRight } from "components/Icons";
import { Box, Flex } from "ui-components";
import {
  getChannelFromUrl,
  gotoUrl,
  loadScript,
  removeScript
} from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectCountryIso0Code,
  makeSelectCurrencyCode,
  makeSelectOrderParams,
  makeSelectOrderSummaryPayload,
  makeSelectSessionToken
} from "ducks/selectors";
import { postPaymentRequest } from "ducks/actions";
import { GMO_SHOP_ID, NODE_ENVIRONMENT, paths } from "utils/constants";
import get from "lodash/get";

const GooglePay = props => {
  const dispatch = useDispatch();
  const { order_summary } = useSelector(makeSelectOrderParams());
  const currencyCode = useSelector(makeSelectCurrencyCode());
  const countryCode = useSelector(makeSelectCountryIso0Code());
  const paymentsPayload = useSelector(makeSelectOrderSummaryPayload());
  const token = useSelector(makeSelectSessionToken());
  const configData = get(
    props.appSettings,
    "generic.payment_methods.googlePay"
  );
  const {
    js_sdk_url,
    version_info,
    login_btn_config,
    payment_method_info,
    allowed_card_auth_method,
    supported_networks,
    gateway
  } = props;
  let paymentsClient = null;

  const baseCardPaymentMethod = {
    type: payment_method_info?.payment_type,
    parameters: {
      allowedAuthMethods: allowed_card_auth_method,
      allowedCardNetworks: supported_networks
    }
  };

  const tokenizationSpecification = {
    type: payment_method_info?.token_type,
    parameters: {
      gateway: gateway,
      gatewayMerchantId: GMO_SHOP_ID
    }
  };

  const cardPaymentMethod = Object.assign({}, baseCardPaymentMethod, {
    tokenizationSpecification: tokenizationSpecification
  });

  const getGooglePaymentsClient = () => {
    const environment =
      process.env.NODE_ENV === NODE_ENVIRONMENT.PRODUCTION
        ? "PRODUCTION"
        : "TEST";
    if (paymentsClient === null) {
      paymentsClient = new window.google.payments.api.PaymentsClient({
        environment: environment
      });
    }
    return paymentsClient;
  };

  const baseRequest = {
    apiVersion: version_info?.api_version,
    apiVersionMinor: version_info?.api_min_version
  };

  const getGoogleIsReadyToPayRequest = () => {
    return Object.assign({}, baseRequest, {
      allowedPaymentMethods: [baseCardPaymentMethod]
    });
  };

  /**
   * Configure support for the Google Pay API
   * @returns {object} PaymentDataRequest fields
   */
  const getGooglePaymentDataRequest = () => {
    const paymentDataRequest = Object.assign({}, baseRequest);
    paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod];
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
    paymentDataRequest.merchantInfo = {
      // @todo a merchant ID is available for a production environment after approval by Google
      // merchantId: '12345678901234567890',
      //TODO need set correct merchant name
      merchantName: "Circle_life"
    };
    return paymentDataRequest;
  };

  const getGoogleTransactionInfo = () => {
    return {
      countryCode: countryCode,
      currencyCode: currencyCode,
      totalPriceStatus: "FINAL",
      totalPrice: order_summary.onetime_total_fee
    };
  };

  const paymentCallBack = (_, error) => {
    if (!error) {
      gotoUrl(paths.PAYMENT_STATUS.replace(":channel", getChannelFromUrl()));
    }
  };

  const processPayment = paymentData => {
    // show returned data in developer console for debugging
    console.log(paymentData);
    const googlePayToken = get(
      paymentData,
      "paymentMethodData.tokenizationData.token",
      null
    );
    if (googlePayToken === null) return;

    const payloadWithPaymentMethod = {
      ...paymentsPayload,
      token,
      payment_methods: {
        code: configData?.code,
        channel_code: configData?.channel_code,
        additional_data: {
          token: googlePayToken
        }
      }
    };
    dispatch(postPaymentRequest(payloadWithPaymentMethod, paymentCallBack));
  };

  /**
   * Show Google Pay payment sheet when Google Pay payment button is clicked
   */
  const onGooglePaymentButtonClicked = () => {
    const paymentDataRequest = getGooglePaymentDataRequest();
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient
      .loadPaymentData(paymentDataRequest)
      .then(paymentData => {
        processPayment(paymentData);
      })
      .catch(err => {
        // show error in developer console for debugging
        console.error(err);
      });
  };

  const addGooglePayButton = () => {
    const paymentsClient = getGooglePaymentsClient();
    const button = paymentsClient.createButton({
      ...login_btn_config,
      onClick: onGooglePaymentButtonClicked
    });
    document.getElementById("googlePayButton").appendChild(button);
  };

  /**
   * Initialize Google PaymentsClient after Google-hosted JavaScript has loaded
   * Display a Google Pay payment button after confirmation of the viewer's
   * ability to pay.
   */
  const onGooglePayLoaded = () => {
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient
      .isReadyToPay(getGoogleIsReadyToPayRequest())
      .then(response => {
        if (response.result) {
          addGooglePayButton();
        }
      })
      .catch(err => {
        // show error in developer console for debugging
        console.error(err);
      });
  };

  useEffect(() => {
    const GOOGLE_PAY_SCRIPT_NAME = "google_pay_script";
    loadScript(GOOGLE_PAY_SCRIPT_NAME, js_sdk_url, onGooglePayLoaded);

    return () => {
      removeScript(GOOGLE_PAY_SCRIPT_NAME);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Box id="googlePayButton" data-testid="google-pay-button" />
      <Box width={["36px"]} mx={1}>
        {<ChevronRight size="30px" color={"#333333"} />}
      </Box>
    </Flex>
  );
};

export default GooglePay;
