import React, { useEffect, useState, useCallback } from "react";
import { Flex, Box, Text } from "ui-components";
import get from "lodash/get";
import map from "lodash/map";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import OrderDetailsCard from "components/OrderDetailsCard";
import OrderActionsCard from "components/OrderActionsCard";
import PageHeader from "components/PageHeader";
import TimeLineItem from "components/TimeLineItem";
import FormattedMessage from "components/FormattedMessage";
import { paths, SIM_TYPES, ESIM_FAQ_URL } from "utils/constants";
import { clearLocalStorage, getItem } from "utils/localStorage";
import { gotoUrl, sanitizeHTML } from "utils/helpers";
import Video from "components/Video";
import { ReactComponent as CreditCardImage } from "assets/icons/icon-credit-card.svg";
import { renderPageComponents } from "smart-components/renderPageComponent";

const badgeColorMapping = {
  inactive: "#efefef",
  fail: "#CB1818",
  success: "#67BC01",
  processing: "#31ea40"
};

const PaymentSuccess = props => {
  const {
    steps,
    orderActions,
    appSettings,
    idVerificationStatus,
    channel,
    pageData
  } = props;
  const [showVideo, setShowVideo] = useState(false);
  const { video_url, close_icon, bill_info_img } = get(
    appSettings,
    "bill_education",
    {}
  );

  const isIdentityVerificationCheckEnabled = get(
    appSettings,
    "identity_verification_status_check.payment_return",
    false
  );

  const smartComponents = renderPageComponents({
    pageComponents: get(
      pageData,
      "nested_container_components.PaymentSuccess.components"
    ),
    pageProps: props
  });
  const genericActionConfig = get(appSettings, "generic.action_config", {});

  if (
    isIdentityVerificationCheckEnabled &&
    !idVerificationStatus &&
    getItem("checkDvsStatus")
  ) {
    gotoUrl(paths.IDENTITY_VERIFICATION, true); // url, preserveQuery
  }
  if (getItem("checkDvsStatus")) {
    setTimeout(() => {
      clearLocalStorage();
    }, 1000);
  }

  const videoHandler = useCallback(
    event => {
      if (event.target.className === "video_container") return;
      if (showVideo) {
        setShowVideo(false);
      }
    },
    [showVideo]
  );

  useEffect(
    () => {
      window.addEventListener("click", videoHandler);
      return () => {
        window.removeEventListener("click", videoHandler);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showVideo, videoHandler]
  );

  const hidePlayer = () => setShowVideo(false);

  const showPlayer = () => setShowVideo(true);

  const renderFaqButton = () => (
    <a
      href={ESIM_FAQ_URL}
      className="nav-button inline-block mt-6"
      target="_blank"
      rel="noopener noreferrer"
    >
      See FAQs
    </a>
  );

  const onActionClicked = (
    params,
    action,
    otpActionType,
    anchorTarget,
    fullyQualifiedURL,
    requireOTPCall
  ) => {
    const URLToUse = fullyQualifiedURL
      ? fullyQualifiedURL
      : get(find(orderActions, { action }) || {}, "url", "");

    if (anchorTarget === "_self") {
      gotoUrl(URLToUse);
    } else {
      window.open(URLToUse, anchorTarget);
    }
  };

  if (!isEmpty(smartComponents)) {
    return (
      <Box data-testid="payment-success" pt="4" variant="main" padding={[4, 6]}>
        {smartComponents}
      </Box>
    );
  }

  return (
    <Box data-testid="payment-success" pt="4" variant="main" padding={[4, 6]}>
      <PageHeader title="payment_return_yay_your_order_is_successful" />

      <OrderDetailsCard {...props} />

      {channel === SIM_TYPES.ESIM && (
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          marginTop="30px"
        >
          <CreditCardImage />
          <Flex marginTop="15px" fontSize="24px">
            <FormattedMessage id="esim_payment_success" />
          </Flex>
          <Flex
            marginTop="15px"
            textAlign="center"
            fontSize="14px"
            lineHeight="17.5px"
          >
            <FormattedMessage id="payment_success_send_esim" />
          </Flex>
          <Flex>{renderFaqButton()}</Flex>
        </Flex>
      )}

      <Flex
        as="ul"
        sx={{
          flexDirection: "column",
          listStyle: "none",
          padding: 0,
          margin: 0
        }}
      >
        {map(steps, ({ title, description, state }) => {
          return (
            <TimeLineItem
              key={`step-${title}`}
              color={badgeColorMapping[state]}
            >
              <Text fontWeight="500">{title}</Text>
              <Text
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(description) }}
              />
            </TimeLineItem>
          );
        })}
      </Flex>

      {showVideo && video_url && (
        <Video
          closeIcon={close_icon}
          videoUrl={video_url}
          hidePlayer={hidePlayer}
          styles={{
            position: "fixed",
            transform: "translate(-50% ,-50%)",
            width: "90%",
            zIndex: 500,
            left: "50%",
            top: "50%"
          }}
        />
      )}

      {bill_info_img && video_url && (
        <Flex
          className="text-md"
          mt={8}
          sx={{ margin: "0 auto", width: "90%" }}
        >
          <Box>
            <img
              src={bill_info_img}
              alt="success"
              style={{ height: 50, width: 60 }}
            />
          </Box>
          <Box width={1} style={{ paddingLeft: 16, lineHeight: 1.5 }}>
            <FormattedMessage html id="first_bill_info_title" />
            <div
              className="video_container"
              onClick={showPlayer}
              style={{
                color: "purple",
                cursor: "pointer",
                textDecoration: "underline"
              }}
            >
              <FormattedMessage html id="click_watch_video" />
            </div>
          </Box>
        </Flex>
      )}

      <Box px={8} my={8}>
        {!isEmpty(orderActions) && (
          <Text size="sm" fontWeight={500} mb={4}>
            <FormattedMessage id="payment_return_in_case_you_need_to_you_can" />
          </Text>
        )}

        <OrderActionsCard
          {...props}
          onActionClicked={onActionClicked}
          orderActions={map(orderActions, orderAction =>
            get(orderAction, "action")
          )}
          data={{
            ...get(props, "data", {}),
            action_config: genericActionConfig
          }}
        />
      </Box>
    </Box>
  );
};

export default PaymentSuccess;
