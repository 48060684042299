import React, { memo, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { updateLatitudePaymentStatus, clearData } from "ducks/actions";
import {
  makeSelectPaymentReturn,
  makeSelectIsFetchingData,
  makeSelectIsPaymentPendingEcPay,
  makeSelectIsPaymentPending,
  makeSelectIsPaymentSuccess,
  makeSelectIsPaymentFailed,
  makeSelectAppSettings,
  makeSelectSessionToken,
  makeSelectDeliveryDetailsItems,
  makeSelectPageData
} from "ducks/selectors";
import Spinner, { styles } from "components/Spinner";
import FormattedMessage from "components/FormattedMessage";
import NotEligible from "components/NotEligible";
import { urlParam, getChannelFromUrl } from "utils/helpers";
import {
  PAYMENT_ENVIRONMENT,
  PAYMENT_STATUS_POLL_INTERVAL
} from "utils/constants";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailure from "./PaymentFailure";
import PaymentPending from "./PaymentPending";
import PaymentPendingEcPay from "./PaymentPendingEcPay";
import isEmpty from "lodash/isEmpty";
import { OMS } from "ducks/actions";

export const PaymentReturn = props => {
  const {
    order,
    deliveryDetailsItems,
    isPaymentPendingEcPay,
    isPaymentPending,
    isPaymentFailed,
    isPaymentSuccess,
    fetchPaymentReturn,
    isFetchingPaymentReturn,
    clearPaymentReturn,
    appSettings,
    token,
    updateLatitudePaymentStatus,
    pageData
  } = props;
  const status = urlParam("status");
  const merchantReference = urlParam("merchantReference");
  const transactionReference = urlParam("transactionReference");
  const gatewayReference = urlParam("gatewayReference");
  // clear on mount and then token change
  useEffect(() => {
    clearPaymentReturn();
    token && getChannelFromUrl() !== "wirecard" && fetchPaymentReturn(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(
    () => {
      if (merchantReference && transactionReference && gatewayReference) {
        updateLatitudePaymentStatus({
          payment_config_category: PAYMENT_ENVIRONMENT,
          merchant_reference: merchantReference,
          transaction_reference: transactionReference,
          gateway_reference: gatewayReference
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [merchantReference, transactionReference, gatewayReference]
  );

  useEffect(
    () => {
      if (!isFetchingPaymentReturn && token && isPaymentPending) {
        window.setTimeout(() => {
          fetchPaymentReturn(token);
        }, PAYMENT_STATUS_POLL_INTERVAL);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      fetchPaymentReturn,
      token,
      isFetchingPaymentReturn,
      isPaymentPending,
      order
    ]
  );
  if (getChannelFromUrl() === "wirecard") {
    return (
      <NotEligible
        showButton={false}
        description={<FormattedMessage id="wirecard_payment_processing" />}
      />
    );
  }

  if (isEmpty(order) && isFetchingPaymentReturn) {
    return <Spinner size={200} sx={styles.fullPage} />;
  } else {
    if (isPaymentPendingEcPay) {
      return <PaymentPendingEcPay status={status} {...order} />;
    }

    if (isPaymentPending) {
      return <PaymentPending status={status} {...props} />;
    }

    if (isPaymentFailed) {
      return <PaymentFailure status={status} {...props} />;
    }

    if (isPaymentSuccess) {
      return (
        <PaymentSuccess
          pageData={pageData}
          status={status}
          {...order}
          order={order}
          deliveryDetailsItems={deliveryDetailsItems}
          appSettings={appSettings}
          messageData={{ ...order }}
        />
      );
    }

    return <NotEligible />;
  }
};

const mapStateToProps = createStructuredSelector({
  order: makeSelectPaymentReturn(),
  isFetchingPaymentReturn: makeSelectIsFetchingData("paymentReturn"),
  isPaymentPendingEcPay: makeSelectIsPaymentPendingEcPay(),
  isPaymentPending: makeSelectIsPaymentPending(),
  isPaymentSuccess: makeSelectIsPaymentSuccess(),
  isPaymentFailed: makeSelectIsPaymentFailed(),
  appSettings: makeSelectAppSettings(),
  token: makeSelectSessionToken(),
  deliveryDetailsItems: makeSelectDeliveryDetailsItems(),
  pageData: makeSelectPageData()
});

export function mapDispatchToProps(dispatch) {
  return {
    clearPaymentReturn: () => {
      dispatch(clearData("paymentReturn"));
    },
    fetchPaymentReturn: token => {
      let params = { order_ref: urlParam("order_ref"), token };
      const type = urlParam("type");
      if (type) {
        params["type"] = type;
      }
      params["source"] = "payment_return";
      dispatch(OMS.V4.fetchPaymentReturn(params));
    },
    updateLatitudePaymentStatus: params => {
      dispatch(updateLatitudePaymentStatus(params));
    }
  };
}

PaymentReturn.defaultProps = {
  clearPaymentReturn: () => {},
  fetchPaymentReturn: () => {}
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(PaymentReturn);
