import React from "react";
import get from "lodash/get";
import map from "lodash/map";
import { Box, Flex, Text, H2, H3, Button } from "ui-components";
import { COLORS, getButtonColorVariant } from "ui-components/theme";
import FormattedMessage from "components/FormattedMessage";
import { paths } from "utils/constants";
import { getChannelFromUrl, getGopayActionUrls } from "utils/helpers";

const PAYMENT_QR_VIEWER = ({
  order,
  payTodayItems,
  paymentRequest,
  data,
  themeColors
}) => {
  const {
    completedPaymentButton,
    qrCodePageHeader,
    qrCodeSteps,
    qrPaymentLabel
  } = data || {};

  const payNowPrice = get(payTodayItems, "totalPrice");
  const { qrCodeUrl } = getGopayActionUrls(paymentRequest) || {
    qrCodeUrl: "placeholder-url"
  };
  const orderRef = get(order, "order_ref", "");
  const channel = getChannelFromUrl();
  const origin = window.location.origin;
  const statusPath = paths.PAYMENT_STATUS.replace(":channel", channel);
  const statusUrl = `${origin}${statusPath}?order_ref=${orderRef}`;

  return (
    <>
      <Box textAlign="center">
        <H2 mb={5} color={COLORS.gray[3]}>
          <FormattedMessage id={qrCodePageHeader} />
        </H2>
        {map(qrCodeSteps, (step, index) => (
          <H3 key={`step-${index}`} mb={15} color={themeColors.primary}>
            <Text as="span" pr={1} color={themeColors.primary}>
              {index + 1}.
            </Text>
            <FormattedMessage id={step} />
          </H3>
        ))}
      </Box>
      <Flex
        mx="auto"
        sx={{
          border: "7px solid",
          borderColor: themeColors.primary,
          borderRadius: "10px 10px 0px 0px",
          width: "280px",
          height: "280px"
        }}
      >
        <img src={qrCodeUrl} width="280px" height="280px" alt="QR Code" />
      </Flex>
      <Flex
        justifyContent="space-between"
        mx="auto"
        sx={{
          p: "6px",
          border: "7px solid",
          borderColor: themeColors.primary,
          borderRadius: "0px 0px 10px 10px",
          width: "280px",
          backgroundColor: themeColors.primary
        }}
      >
        <H3 color={COLORS.white} mr={40}>
          <FormattedMessage id={qrPaymentLabel} />
        </H3>
        <H3 color={COLORS.white} minWidth="90px">
          {payNowPrice}
        </H3>
      </Flex>
      <Flex justifyContent="center">
        <Button
          my={30}
          minWidth={[1 / 2, 1 / 3, 1 / 3]}
          sx={getButtonColorVariant(themeColors.button)}
          onClick={() => {
            window.location.href = statusUrl;
          }}
        >
          <FormattedMessage id={completedPaymentButton} />
        </Button>
      </Flex>
    </>
  );
};

export default PAYMENT_QR_VIEWER;
