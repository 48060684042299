import get from "lodash/get";
import { createSelector } from "reselect";

export const myInfoDomain = state => get(state, "api.myInfo.data", {});
export const myInfoDetailsDomain = state =>
  get(state, "api.myInfoDetails.data", {});
export const myInfoOTPTokenDomain = state =>
  get(state, "api.myInfoOTPToken.data", {});

// ***************************************************************
// ******************** myInfo selectors *************************
// ***************************************************************
export const myInfoSelector = createSelector(myInfoDomain, myInfo => {
  return myInfo;
});

export const myInfoIsUsedSelector = createSelector(myInfoDomain, myInfo => {
  return get(myInfo, "isUsed", false);
});

export const myInfoCodeSelector = createSelector(myInfoDomain, myInfo => {
  return get(myInfo, "code", "");
});

export const myInfoDetailsSelector = createSelector(
  myInfoDetailsDomain,
  myInfoDetails => {
    return myInfoDetails;
  }
);

// ***************************************************************
// ******************* myInfo makeSelect *************************
// ***************************************************************
export const makeSelectMyInfo = () => myInfoSelector;

export const makeSelectMyInfoIsUsed = () => myInfoIsUsedSelector;
export const makeSelectMyInfoCode = () => myInfoCodeSelector;

export const makeSelectMyInfoDetails = () => myInfoDetailsSelector;

export const makeSelectMyInfoOTPToken = () =>
  createSelector(myInfoOTPTokenDomain, myInfoOTPToken => {
    return myInfoOTPToken;
  });
