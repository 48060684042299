import React from "react";
import DropdownField from "components/FormFields/DropdownField";
import get from "lodash/get";

const AddressLookup = props => {
  const {
    change,
    formValues,
    fetchAddressLookup,
    postalCodeOptions,
    type = "delivery",
    placeholder = "type_to_search",
    field
  } = props;
  const selectedDeliveryMethod = get(formValues, "delivery_method", {});

  return (
    <DropdownField
      placeholder={placeholder}
      options={postalCodeOptions}
      {...selectedDeliveryMethod.fields.zip_code}
      isSearchable={true}
      field={field}
      onInputChange={value => {
        if (value.length >= 2) fetchAddressLookup({ q: value });
      }}
      onChange={({ value }) => {
        const [city, state, zipCode] = value.split("-");
        change(field, value);
        change(`${type}_state`, state);
        change(`${type}_city`, city);
        change(`${type}_zip_code`, zipCode);
      }}
    />
  );
};

export default AddressLookup;
