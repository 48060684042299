import React from "react";
import { connect } from "react-redux";
import { Box, Button, Flex } from "ui-components";
import PageHeader from "components/PageHeader";
import { COMBO_CHANNEL, paths, PHONE_ONLY_CHANNEL } from "utils/constants";
import FormattedMessage from "components/FormattedMessage";
import { gotoUrl, getChannelFromUrl } from "utils/helpers";

const PaymentFailure = ({ status }) => {
  const channel = getChannelFromUrl();
  const orderSummaryPath = paths.ORDER_SUMMARY.replace(":channel", channel);
  const preCheckoutPath = paths.PRE_CHECKOUT.replace(":channel", channel);

  let amendOrderPath = preCheckoutPath;
  if (channel === PHONE_ONLY_CHANNEL) {
    amendOrderPath = paths.PHONES.replace(":channel", channel);
  } else if (channel === COMBO_CHANNEL) {
    amendOrderPath = paths.PERSONAL_DETAILS.replace(":channel", channel);
  }

  return (
    <Box data-testid="payment-failure" variant="main">
      <PageHeader
        title={`payment_${status || "failed"}`}
        subtitle={`payment_${status || "failed"}_message`}
      />
      <Flex flexDirection={"column"} alignItems={"center"}>
        <Button
          my={2}
          width={[0.9, 0.75]}
          onClick={() => {
            gotoUrl(orderSummaryPath, true, {}, true);
          }}
        >
          <FormattedMessage id="retry_payment" />
        </Button>

        <Button
          my={2}
          width={[0.9, 0.75]}
          onClick={() => {
            gotoUrl(amendOrderPath, true, {}, true);
          }}
        >
          <FormattedMessage id="amend_order" />
        </Button>
      </Flex>
    </Box>
  );
};

const mapStateToProps = state => {
  return {};
};

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentFailure);
