import React from "react";
import { Flex, Text } from "ui-components";

const OutstandingInvoiceItem = ({
  formattedDate,
  formattedAmount,
  rowPadding = "1rem",
  noBorder = false,
  borderColor = "#AEAEAE"
}) => {
  return (
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      padding={`${rowPadding} 0`}
      {...(!noBorder && {
        style: { borderBottom: `1px solid ${borderColor}` }
      })}
    >
      <Flex>{formattedDate}</Flex>

      <Flex>
        <Text fontWeight="bolder" py="4px">
          {formattedAmount}
        </Text>
      </Flex>
    </Flex>
  );
};

OutstandingInvoiceItem.defaultProps = {
  formattedDate: "",
  formattedCurformattedAmountrency: ""
};

export default OutstandingInvoiceItem;
