import React, { memo } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { fetchOrderDetails, fetchDeliverySlots } from "ducks/actions";
import {
  makeSelectIsFetchingData,
  makeSelectGuestOrderOrderRef,
  makeSelectOrderDetails,
  makeSelectMonthOptions,
  makeSelectDateOptions,
  makeSelectYearOptions,
  makeSelectInProgressReplaceSim
} from "ducks/selectors";
import Spinner, { styles } from "components/Spinner";
import { ORDER_ACTION_TYPES } from "utils/constants";
import ReportLostSim from "./ReportLostSim";
import isEmpty from "lodash/isEmpty";

export const ReportLostSIMAction = props => {
  const {
    isFetchingOrderDetails,
    isSubmittingReplaceSim,
    orderDetails = {},
    isLoggedIn = false
  } = props;

  if (
    isFetchingOrderDetails ||
    isSubmittingReplaceSim ||
    (!isLoggedIn && isEmpty(orderDetails))
  )
    return <Spinner size={200} sx={styles.fullPage} />;

  return <ReportLostSim {...props}></ReportLostSim>;
};

const mapStateToProps = createStructuredSelector({
  orderRef: makeSelectGuestOrderOrderRef(),
  orderDetails: makeSelectOrderDetails(),
  inProgressReplaceSim: makeSelectInProgressReplaceSim(),
  isFetchingOrderDetails: makeSelectIsFetchingData("orderDetails"),
  isSubmittingReplaceSim: makeSelectIsFetchingData("submitReplaceSim"),
  monthOptions: makeSelectMonthOptions(),
  dateOptions: makeSelectDateOptions(),
  yearOptions: makeSelectYearOptions()
});

export const mapDispatchToProps = dispatch => {
  return {
    fetchOrderDetails: orderRef => {
      const params = {
        type: ORDER_ACTION_TYPES.REPORT_LOST_SIM_CARD,
        order_ref: orderRef
      };

      dispatch(fetchOrderDetails(params));
    },
    fetchDeliverySlots: params => {
      dispatch(fetchDeliverySlots(params));
    }
  };
};

ReportLostSIMAction.defaultProps = {
  fetchOrderDetails: () => {}
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(ReportLostSIMAction);
