import React from "react";
import DropdownField from "components/FormFields/DropdownField";
import { constructOnChangeHandler } from "utils/helpers";

const ADDONS_DROPDOWN = ({ addOns, defaultAddons, ...props }) => (
  <DropdownField
    options={addOns}
    defaultAddons={defaultAddons}
    {...props}
    onChange={constructOnChangeHandler(props)}
  />
);

export default ADDONS_DROPDOWN;
