import get from "lodash/get";
import { forms } from "utils/constants";
import { createSelector } from "reselect";

const dataOnlyRegistrationFormDomain = state =>
  get(state, `form.${forms.DATA_ONLY_USER_REGISTRATION_FORM}.values`, {});

export const userRegistrationFormsValuesSelector = createSelector(
  dataOnlyRegistrationFormDomain,
  userRegistrationFormData => userRegistrationFormData
);

export const registerEmailSelector = createSelector(
  dataOnlyRegistrationFormDomain,
  registrationForm => {
    return get(registrationForm, "email");
  }
);

export const contactNumberSelector = createSelector(
  dataOnlyRegistrationFormDomain,
  registrationForm => {
    return get(registrationForm, "contact_number");
  }
);

export const dataOnlyUserDetailFormSelector = createSelector(
  dataOnlyRegistrationFormDomain,
  userDetails => userDetails
);

export const userRegistrationInitialValuesSelector = createSelector(
  userRegistrationFormsValuesSelector,
  data => data
);

export const makeSelectDataOnlyContactNumber = () => contactNumberSelector;
export const makeSelectDataOnlyRegisterEmail = () => registerEmailSelector;
export const makeSelectUserRegistrationInitialValues = () =>
  userRegistrationInitialValuesSelector;
