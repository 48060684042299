import React from "react";
import get from "lodash/get";
import { H4 } from "ui-components";
import FormattedMessage from "components/FormattedMessage";

const QUICK_ORDER_VIEW = ({ messageData, ...props }) => {
  const { title, subtitle, onclick } = get(props, "component.data", {});
  return (
    <>
      {title && (
        <H4
          pt={3}
          style={{ fontFamily: "Roboto", fontWeight: 700 }}
          onClick={props[onclick]}
        >
          <FormattedMessage style={{ color: "blue" }} html id={title} />
        </H4>
      )}
      {subtitle && (
        <H4 pt={2} pb={2} style={{ fontFamily: "Roboto", fontWeight: 500 }}>
          {" "}
          <FormattedMessage html id={subtitle} values={messageData} />
        </H4>
      )}
    </>
  );
};

export default QUICK_ORDER_VIEW;
