import { createSelector } from "reselect";
import get from "lodash/get";
import {
  numberFormatSelector,
  localeSelector,
  completeOrderParamsSelector,
  paymentStatusDetailsSelector
} from "ducks/selectors";
import { PAYMENT_STATUSES, PAYMENT_STATES } from "utils/constants";
import { constructOrderDetails } from "utils/helpers";

const paymentReturnDomain = state => get(state, "api.paymentReturn.data", {});

// ***************************************************************
// **************** paymentReturn selectors **********************
// ***************************************************************

export const paymentReturnSelector = createSelector(
  paymentReturnDomain,
  paymentReturn => {
    return paymentReturn;
  }
);

// ***************************************************************
// *************** paymentReturn makeSelect **********************
// ***************************************************************

export const makeSelectPaymentReturn = () =>
  createSelector(
    paymentReturnSelector,
    paymentStatusDetailsSelector,
    numberFormatSelector,
    localeSelector,
    completeOrderParamsSelector(),
    constructOrderDetails
  );

export const makeSelectIsPaymentPendingEcPay = () =>
  createSelector(
    paymentReturnSelector,
    order => get(order, "state") === PAYMENT_STATES.PENDING_ECPAY
  );
export const makeSelectIsPaymentPending = () =>
  createSelector(
    paymentReturnSelector,
    order => get(order, "payment_status") === PAYMENT_STATUSES.PENDING
  );

export const makeSelectIsPaymentSuccess = () =>
  createSelector(
    paymentReturnSelector,
    order => get(order, "payment_status") === PAYMENT_STATUSES.SUCCESS
  );

export const makeSelectIsPaymentFailed = () =>
  createSelector(
    paymentReturnSelector,
    order => get(order, "payment_status") === PAYMENT_STATUSES.FAILED
  );
