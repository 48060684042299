import React, { useState } from "react";
import get from "lodash/get";
import { useSelector } from "react-redux";
import { Box, Flex, Label } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import CriticalInfoModal from "./criticalInfoModal";
import CheckboxField from "components/FormFields/CheckboxField";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";
import { makeSelectGA4PlanDetails } from "ducks/selectors";

const CriticalInfoSummary = ({
  data = {},
  themeColors,
  formValues = {},
  change
}) => {
  const consents = get(data, "consents", []);
  const disableModal = get(data, "disableModal", false);
  const [modalData, setModalData] = useState({ isOpen: false });
  const GA4EventPlanDetails = useSelector(makeSelectGA4PlanDetails());

  const openModal = (data = {}) => {
    if (disableModal) return () => {};

    const { field } = data;
    field && publishGA4Events(field, formValues[field] || false);
    setModalData({ isOpen: true, ...data });
  };
  const closeModal = field => {
    setModalData({ isOpen: false });
  };

  const publishGA4Events = (field, checked) => {
    GA4EventsService.publish(
      tncEventData({
        ...GA4EventPlanDetails,
        checked,
        field
      })
    );
  };

  return (
    <>
      {consents.map(
        ({
          field,
          label,
          details,
          detailsList, // Use to construct a label where only certain areas of the label are clickable
          mandatory_read_message,
          disabled,
          pdfViewerData = {},
          iframe = false
        }) => (
          <Flex key={field}>
            <Box
              minWidth={"1.2rem"}
              flex={0}
              onClick={() =>
                mandatory_read_message && !formValues[`${field}_read`]
                  ? openModal({
                      field,
                      label,
                      details,
                      mandatory_read_message,
                      pdfViewerData,
                      iframe
                    })
                  : null
              }
            >
              <Label>
                <CheckboxField
                  id={field}
                  field={field}
                  label=""
                  checkBoxOnly
                  disabled={
                    mandatory_read_message && !formValues[`${field}_read`]
                  }
                  themeColors={themeColors}
                  onChange={({ target }) =>
                    publishGA4Events(field, target.checked)
                  }
                />
              </Label>
            </Box>
            <Box
              sx={{
                cursor: "text",
                paddingLeft: "1rem"
              }}
            >
              {detailsList ? (
                detailsList.map(({ label, details, popupLabel }) => (
                  <span
                    key={popupLabel || label}
                    style={{
                      cursor: details && !disableModal ? "pointer" : "text"
                    }}
                    onClick={() => {
                      // only if we have details we would open the modal. Otherwise the label is just rendered without any click actions
                      if (details) {
                        openModal({
                          field,
                          label: popupLabel || label,
                          details,
                          mandatory_read_message,
                          pdfViewerData,
                          iframe
                        });
                      }
                    }}
                  >
                    <FormattedMessage html id={label} />
                  </span>
                ))
              ) : (
                <span
                  style={{ cursor: !disableModal ? "pointer" : "text" }}
                  onClick={() => {
                    openModal({
                      field,
                      label,
                      details,
                      mandatory_read_message,
                      pdfViewerData,
                      iframe
                    });
                  }}
                >
                  <FormattedMessage html id={label} />
                </span>
              )}
            </Box>
          </Flex>
        )
      )}
      {modalData.isOpen && (
        <CriticalInfoModal
          id={modalData.field}
          field={modalData.field}
          isOpen={modalData.isOpen}
          closeModal={closeModal}
          critical_info={modalData.label}
          details={modalData.details}
          mandatory_read_message={modalData.mandatory_read_message}
          themeColors={themeColors}
          iframe={modalData.iframe}
          pdfViewerData={modalData.pdfViewerData}
          publishGA4Events={publishGA4Events}
        />
      )}
    </>
  );
};

export default CriticalInfoSummary;

const tncEventData = planDetails => {
  const {
    customer_id,
    plan_name,
    plan_type,
    value,
    field,
    checked,
    journey_type,
    number_type,
    sim_type
  } = planDetails;

  return {
    event: GA4EVENTS.TNC_CHECKED,
    ecommerce: {
      customer_id,
      plan_name,
      journey_type,
      plan_type,
      sim_type,
      value,
      number_type,
      condition_name: field,
      condition_value: checked
    }
  };
};
