import React, { useEffect } from "react";
import get from "lodash/get";
import find from "lodash/find";
import includes from "lodash/includes";
import DeliveryAddressForm3 from "components/DeliveryAddress/Form3";

const DELIVERY_ADDRESS_FORM_3 = ({
  addressComponentMappings,
  fetchAddressComponentMappings,
  change,
  postalCodeValidation,
  setPostalCodeValidation = () => {},
  formValues,
  fieldPrefix = "delivery"
}) => {
  const { allCities, districts, address_lookups, allDistricts } =
    addressComponentMappings || {};

  useEffect(
    () => {
      fetchAddressComponentMappings && fetchAddressComponentMappings({});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const selectedDeliveryMethod = get(formValues, "delivery_method", null);
  if (!selectedDeliveryMethod) return null;
  const fields = get(selectedDeliveryMethod, "fields");

  const updateAddressFields = (foundLookup, fields) => {
    const { district_id, city_id, code } = foundLookup || {};
    const district = district_id
      ? find(allDistricts, { value: district_id })
      : null;
    const city = city_id ? find(allCities, { value: city_id }) : null;
    includes(fields, "district") && change(`${fieldPrefix}_district`, district);
    includes(fields, "city") && change(`${fieldPrefix}_city`, city);
    includes(fields, "code") && change(`${fieldPrefix}_zip_code`, code);
    setPostalCodeValidation(`${fieldPrefix}_zip_code`, { status: !!code });
  };

  const onZipCodeChange = zipCode => {
    const foundLookup = find(address_lookups, lookup => {
      return zipCode.target.value === lookup.code;
    });
    updateAddressFields(foundLookup, ["district", "city"]);
  };

  const onCityChange = city => {
    const foundLookup = find(address_lookups, lookup => {
      return city.value === lookup.city_id;
    });
    updateAddressFields(foundLookup, ["code", "district"]);
  };

  const onDistrictChange = district => {
    const foundLookup = find(address_lookups, lookup => {
      return district.value === lookup.district_id;
    });
    updateAddressFields(foundLookup, ["code", "city"]);
  };

  return (
    <DeliveryAddressForm3
      fields={fields}
      fieldPrefix={fieldPrefix}
      formValues={formValues}
      cityOptions={allCities}
      districtOptions={districts}
      onZipCodeChange={onZipCodeChange}
      onCityChange={onCityChange}
      onDistrictChange={onDistrictChange}
      postalCodeValidation={postalCodeValidation}
    />
  );
};

export default DELIVERY_ADDRESS_FORM_3;
