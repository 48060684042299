import React from "react";
import ReactModal from "react-modal";
import isEmpty from "lodash/isEmpty";
import { Box, Flex } from "ui-components";
import { isMobile } from "utils/helpers";
import Spinner from "components/Spinner";
import { renderPageComponents } from "../renderPageComponent";

/**
 * TBD: To resolve renderSmartComponents function.
 * renderPageComponents not included because slightly complicated to include it. Not sure where to migrate function renderPageComponents into the new smart component file
 */

const SELF_ACTIVATION_DELIVERY_MODAL = props => {
  let {
    styles,
    pageProps,
    appSettings,
    screenshotEnabled,
    components: pageComponents,
    padding,
    width
  } = props;

  if (!isMobile())
    styles.modalStyle.content = {
      ...styles.modalStyle.content,
      padding,
      width
    };
  return (
    <>
      <ReactModal style={styles.modalStyle} isOpen={true}>
        <div data-testid="smart-render">
          <Box>
            {isEmpty(pageComponents) ? (
              <Flex mt={3} justifyContent="center">
                <Spinner size={200} sx={styles.fullPage} />
              </Flex>
            ) : (
              renderPageComponents({
                pageComponents,
                pageProps,
                appSettings,
                screenshotEnabled
              })
            )}
          </Box>
        </div>
      </ReactModal>
    </>
  );
};

export default SELF_ACTIVATION_DELIVERY_MODAL;
