import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import { Flex, Text, Box } from "ui-components";
import map from "lodash/map";
import slice from "lodash/slice";
import get from "lodash/get";

import FieldError from "components/FormFields/FieldError";
import Label from "components/FormFields/Label";
import PopStationItem from "components/PopStationItem";
import FormattedMessage from "components/FormattedMessage";
import { ChevronUp, ChevronDown } from "components/Icons";
import { COLORS } from "ui-components/theme";
import Spinner, { styles as spinnerStyles } from "components/Spinner";

const renderField = ({
  see_more_text,
  see_less_text,
  variant,
  popStations,
  selectedPopStationOutlet,
  isFetchingPopStations,
  handleToggleViewMoreLess,
  moreToShow,
  moreItemCount,
  handleSelect,
  limitItems,
  primaryColor,
  tooltip,
  required,
  label,
  meta: { asyncValidating, touched, error },
  labelMessage
}) => {
  function renderPopStationItems() {
    return map(slice(popStations, 0, moreItemCount), (popStation, index) => {
      return (
        <PopStationItem
          key={popStation.outlet_id}
          popStation={popStation}
          isSelected={
            popStation.outlet_id === selectedPopStationOutlet.outlet_id
          }
          onClick={handleSelect}
        ></PopStationItem>
      );
    });
  }

  return (
    <>
      {label && (
        <Label
          label={<FormattedMessage id={label} />}
          tooltip={tooltip}
          required={required}
        />
      )}
      <Box mb={6}>
        <FieldError touched={touched} error={error} />
      </Box>

      {isFetchingPopStations ? (
        <Spinner size={200} sx={spinnerStyles.inlineMessage} />
      ) : (
        <>
          {renderPopStationItems()}
          {limitItems && (
            <Flex
              data-testid="addon-select-toggle"
              id="addon-toggle"
              textAlign="center"
              alignItems="center"
              justifyContent="center"
              onClick={handleToggleViewMoreLess}
              color={primaryColor}
              fontWeight="500"
              fontSize={["14px", "16px"]}
              sx={{ cursor: "pointer", "& > svg": { margin: 0 } }}
            >
              <FormattedMessage
                id={moreToShow ? see_more_text : see_less_text}
              />
              &nbsp;
              {moreToShow ? (
                <ChevronDown color={primaryColor} />
              ) : (
                <ChevronUp color={primaryColor} />
              )}
            </Flex>
          )}
        </>
      )}
    </>
  );
};

const PopStationBox = props => {
  const {
    see_more_text,
    see_less_text,
    initialItems,
    deliveryInfoVariant,
    popStations,
    popStationsDeliverySlots,
    themeColors,
    isFetchingPopStations,
    data,
    updateFormValues,
    required,
    formValues,
    field,
    tooltip,
    label
  } = props;

  const sundayNoDelivery = get(
    data,
    "sunday_no_delivery",
    "delivery_info_sunday_no_delivery_info"
  );
  const emptyPopStation = get(data, "empty_pop_station", "empty_pop_station");
  const noPopStationDeliverySlot = get(
    data,
    "no_pop_station_delivery_slot",
    "no_pop_station_delivery_slot"
  );

  const variant = deliveryInfoVariant;

  const itemCount = get(popStations, "length", 0);
  const [moreItemCount, setMoreItemCount] = useState(initialItems || itemCount);
  const limitItems = initialItems > 0 && itemCount > initialItems;
  const moreToShow = itemCount > moreItemCount;
  const primaryColor = get(themeColors, "primary", COLORS.blue[4]);

  const [popStationDeliveryInfo, setPopStationDeliveryInfo] = useState(
    sundayNoDelivery
  );
  const [hasAtLeastOnePostalFetch, setHasAtLeastOnePostalFetch] = useState(
    false
  );

  useEffect(() => {
    if (!moreItemCount && !initialItems && itemCount) {
      setMoreItemCount(itemCount);
    }
  }, [moreItemCount, initialItems, itemCount]);

  useEffect(() => {
    if (isFetchingPopStations && !hasAtLeastOnePostalFetch)
      setHasAtLeastOnePostalFetch(true);
  }, [isFetchingPopStations, hasAtLeastOnePostalFetch]);

  useEffect(() => {
    let localeID = sundayNoDelivery;

    if (!isFetchingPopStations) {
      if (popStations.length === 0) {
        localeID = emptyPopStation;
      } else if (
        popStations.length > 0 &&
        popStationsDeliverySlots.length === 0
      ) {
        localeID = noPopStationDeliverySlot;
      }
    }

    setPopStationDeliveryInfo(localeID);
  }, [
    emptyPopStation,
    isFetchingPopStations,
    noPopStationDeliverySlot,
    popStations,
    popStationsDeliverySlots,
    sundayNoDelivery
  ]);

  const handleSelect = e => {
    let selectedOutletId = e.currentTarget.dataset.outletId;
    let selected = popStations.find(
      popStation => popStation.outlet_id === selectedOutletId
    );

    //dispatch
    updateFormValues(selected);
  };

  const handleToggleViewMoreLess = () => {
    setMoreItemCount(moreToShow ? itemCount : initialItems);
  };

  return (
    <>
      {hasAtLeastOnePostalFetch ? (
        <Box p="4" mb="8" variant={variant} alignItems="center" width="100%">
          <Text as="span" size="sm">
            <FormattedMessage id={popStationDeliveryInfo} />
          </Text>
        </Box>
      ) : null}

      <Box>
        <>
          <Field
            name={field}
            required={required}
            tooltip={tooltip}
            component={renderField}
            formValues={formValues}
            hasAtLeastOnePostalFetch={hasAtLeastOnePostalFetch}
            handleToggleViewMoreLess={handleToggleViewMoreLess}
            moreToShow={moreToShow}
            see_more_text={see_more_text}
            see_less_text={see_less_text}
            moreItemCount={moreItemCount}
            handleSelect={handleSelect}
            limitItems={limitItems}
            primaryColor={primaryColor}
            popStations={popStations}
            selectedPopStationOutlet={get(
              formValues,
              "pop_station_outlet_full_data",
              {}
            )}
            isFetchingPopStations={isFetchingPopStations}
            label={label}
          />
        </>
      </Box>
    </>
  );
};

PopStationBox.defaultProps = {
  deliveryInfoVariant: "light",
  defaultValue: "",
  popStations: [],
  popStationsDeliverySlots: [],
  see_more_text: "see_more_pop_stations",
  see_less_text: "see_less_pop_stations",
  initialItems: 6,
  required: true,
  field: "pop_station_box_field",
  formValues: {},
  label: ""
};

export default PopStationBox;
