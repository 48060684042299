import React, { memo } from "react";
import { compose } from "redux";
import get from "lodash/get";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { reduxForm } from "redux-form";
import SmartRender from "containers/SmartRender/index";
import validate from "validations";
import {
  forms,
  paths,
  LIQUID_URL_CONSUMED_CODE,
  MOBILE_APP_BROWSER_PROTOCOL,
  LIQUID_EXPIRE_TIME
} from "utils/constants";
import {
  makeSelectOrderRef,
  makeSelectPageValidations,
  makeSelectzEIdentifyUserData,
  makeSelectConfirmKYCForPlanChange,
  makeSelectLiquidUrl,
  makeSelectLiquidUrlRequestTime
} from "ducks/selectors";
import { UserService } from "ducks/actions";
import { isWebview } from "utils/localStorage";
import {
  gotoUrl,
  getDeepLink,
  activateZendeskWidget,
  getTimeDifferent
} from "utils/helpers";

const LIQUID = "LIQUID";

const ConfirmKYCMethod = Component =>
  function Comp(props) {
    const {
      orderRef,
      zEIdentify,
      formValues,
      liquidRequestedTime,
      liquidUrl
    } = props;
    const dispatch = useDispatch();
    const selectedKycOption = get(formValues, "kyc_for_plan_change.value");

    const getLiquidRedirectUrl = () => {
      const deeplink = getDeepLink();
      return `${deeplink}${paths.APPLOGIN}`;
    };

    const liquidAPICallback = (result, errors, status) => {
      const liquidUrl = get(result, "application_url");
      if (!errors && liquidUrl) {
        const finalUrl = isWebview()
          ? liquidUrl.replace("https", MOBILE_APP_BROWSER_PROTOCOL)
          : liquidUrl;

        gotoUrl(finalUrl);
      } else if (status === LIQUID_URL_CONSUMED_CODE) {
        gotoUrl(paths.USER_DETAILS);
      }
    };

    const liquidUrlRequestingRequired = () => {
      if (liquidRequestedTime) {
        const timeDifferent = getTimeDifferent(liquidRequestedTime);
        return 0 > timeDifferent || timeDifferent > LIQUID_EXPIRE_TIME;
      }
      return true;
    };

    const proceedWithKYC = () => {
      if (selectedKycOption === LIQUID) {
        if (!liquidUrlRequestingRequired()) {
          const finalUrl = isWebview()
            ? liquidUrl.replace("https", MOBILE_APP_BROWSER_PROTOCOL)
            : liquidUrl;

          gotoUrl(finalUrl);
        } else {
          // call liquid url api
          const bodyParams = {
            order_id: orderRef,
            redirect_url: getLiquidRedirectUrl()
          };
          dispatch(
            UserService.V4.fetchLiquidUrl(bodyParams, liquidAPICallback)
          );
        }
      } else {
        // open chat
        activateZendeskWidget(zEIdentify);
        // goto manage order with kycChat=true
        gotoUrl(paths.MANAGE_ORDER, true, { kycChat: true });
      }
    };
    return <Component {...props} proceedWithKYC={proceedWithKYC} />;
  };

const mapStateToProps = createStructuredSelector({
  formValidations: makeSelectPageValidations(forms.CONFIRM_KYC_FOR_PLAN_CHANGE),
  formValues: makeSelectConfirmKYCForPlanChange(),
  orderRef: makeSelectOrderRef(),
  zEIdentify: makeSelectzEIdentifyUserData(),
  liquidUrl: makeSelectLiquidUrl(),
  liquidRequestedTime: makeSelectLiquidUrlRequestTime()
});
const mapDispatchToProps = {};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.CONFIRM_KYC_FOR_PLAN_CHANGE,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(ConfirmKYCMethod(SmartRender));
