import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Field } from "redux-form";
import { Box, Textarea } from "ui-components";
import Label from "./Label";
import FieldError from "./FieldError";
import isFunction from "lodash/isFunction";
import { handleRelatedTargetAfterBlur } from "utils/helpers";

const renderField = ({
  tooltip,
  input,
  label,
  required,
  readOnly,
  sx,
  meta: { touched, error },
  onBlur
}) => {
  const handleOnBlur = (event, newValue, previousValue, name) => {
    if (isFunction(onBlur)) {
      onBlur(event, newValue, previousValue, name);
    } else if (isFunction(input.onBlur)) {
      input.onBlur(event, newValue, previousValue, name);
    }
    handleRelatedTargetAfterBlur(event);
  };

  return (
    <>
      <Label
        label={<FormattedMessage id={label} />}
        tooltip={tooltip}
        required={required}
      />

      <Textarea
        sx={sx}
        data-testid="text-area-element"
        styles={{ resize: "vertical" }}
        {...input}
        onBlur={handleOnBlur}
        disabled={readOnly}
      />

      <FieldError touched={touched} error={error} />
    </>
  );
};

const TextAreaField = ({
  field,
  type,
  pattern,
  label,
  tooltip,
  placeholder,
  onChange,
  normalize,
  readOnly,
  required,
  sx
}) => {
  if (!field) {
    return null;
  }

  return (
    <Box mb={8} data-testid={field}>
      <Field
        readOnly={readOnly}
        name={field}
        required={required}
        component={renderField}
        type={type}
        pattern={pattern}
        label={label}
        placeholder={placeholder}
        onChange={onChange}
        normalize={normalize}
        tooltip={tooltip}
        sx={sx}
      />
    </Box>
  );
};

export default TextAreaField;
