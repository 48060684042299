import { createSelector } from "reselect";
import {
  genericSelector,
  userDetailsSelector,
  currentPhoneNumberSelector
} from "ducks/selectors";
import get from "lodash/get";
import { splitJapaneseName } from "utils/helpers";
/**
 * Returns porout form values state
 * @param {object} state redux state
 */
const requestPortoutDomain = state =>
  get(state, "form.RequestPortOutForm.values");

export const portoutSelector = createSelector(
  requestPortoutDomain,
  portout => portout
);

/**
 * Returns portout form values
 */
export const makeSelectPortoutRequestForm = () => portoutSelector;
/**
 * Returns initial values for Portout form
 */
export const makeSelectPortoutFormInitialValues = () =>
  createSelector(
    genericSelector,
    userDetailsSelector,
    currentPhoneNumberSelector,
    (generic, userDetails, contact_number) => {
      const country_code = get(generic, "contact_number.prefix");
      const first_name =
        get(userDetails, "telco_info.first_name", "") ||
        get(userDetails, "telco_info.kanji_name", "");
      const last_name =
        get(userDetails, "telco_info.last_name", "") ||
        get(userDetails, "telco_info.katakana_name", "");
      const [last_name_kanji, first_name_kanji] = splitJapaneseName(first_name);
      const [last_name_katakana, first_name_katakana] = splitJapaneseName(
        last_name
      );

      const initialValues = {
        first_name,
        last_name,
        last_name_kanji,
        first_name_kanji,
        last_name_katakana,
        first_name_katakana,
        country_code,
        contact_number
      };

      return initialValues;
    }
  );
