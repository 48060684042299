import React, { useEffect, memo, useCallback, useState } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { forms, paths } from "utils/constants";
import validate, { asyncValidate } from "validations";
import {
  makeSelectUserProfileDetails,
  makeSelectIsFetchingData,
  makeSelectAddressUpdateFormDetails,
  makeSelectAddressUpdateInitialValues,
  makeSelectPageValidations,
  makeSelectAddressUpdatePayload,
  makeSelectIsModalView,
  makeSelectModalType,
  makeSelectAuthToken,
  makeSelectUserUpdatedAddress
} from "ducks/selectors";
import SmartRender from "containers/SmartRender";
import { UserService, Quilt, setData } from "ducks/actions";
import { gotoUrl, isWebView, pathWithChannel, urlParam } from "utils/helpers";
import { getDeviceId } from "utils/localStorage";

const OTP_FAILED_ERROR_CODE = 4000361;
const AddressUpdate = Component =>
  function Comp(props) {
    const [showOTPModal, setShowOTPModal] = useState(false);
    const {
      userProfileDetails,
      addressUpdateParams,
      showAddressUpdateSuccessModal,
      showAddressUpdateFailureModal,
      hideMoreInfoModal,
      reset
    } = props;
    const dispatch = useDispatch();
    const authToken = useSelector(makeSelectAuthToken());

    useEffect(() => {
      if (isEmpty(userProfileDetails)) {
        dispatch(Quilt.V1.fetchUserProfileDetails());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hideModalAndNavigate = () => {
      dispatch(reset(forms.ADDRESS_UPDATE_FORM));
      hideMoreInfoModal();
      if (isWebView()) {
        const webviewReturnUrl = urlParam("return_url") || paths.CLOSEWEBVIEW;
        const mobileParams = {
          device_id: getDeviceId(),
          auth_token: authToken
        };
        gotoUrl(webviewReturnUrl, false, {}, true, mobileParams);
      } else {
        gotoUrl(pathWithChannel(`${paths.PROFILE}`, "web"), false, {}, false);
      }
    };

    const onSubmit = useCallback(
      (_, data = {}) => {
        const { otp_payload: otpParams } = data;
        let requestPayLoad = {
          otp_payload: {
            auth_id: otpParams.auth_id,
            otp: otpParams.otp_code
          },
          billing_detail: addressUpdateParams
        };
        dispatch(
          UserService.V4.updateAddress(
            requestPayLoad,
            (result = {}, errors) => {
              if (!errors) {
                showAddressUpdateSuccessModal();
                setShowOTPModal(false);
              } else if (result.code !== OTP_FAILED_ERROR_CODE) {
                showAddressUpdateFailureModal();
                setShowOTPModal(false);
              }
            }
          )
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    );

    return (
      <Component
        {...props}
        onSubmit={onSubmit}
        showOTPModal={showOTPModal}
        setShowOTPModal={setShowOTPModal}
        hideModalAndNavigate={hideModalAndNavigate}
        navigateTo={navigate => navigate && navigate()}
      />
    );
  };

const mapStateToProps = createStructuredSelector({
  isModalView: makeSelectIsModalView(),
  modalType: makeSelectModalType(),
  userProfileDetails: makeSelectUserProfileDetails(),
  isFetchingData: makeSelectIsFetchingData(["userProfileDetails"]),
  formValidations: makeSelectPageValidations(forms.ADDRESS_UPDATE_FORM),
  initialValues: makeSelectAddressUpdateInitialValues(),
  formValues: makeSelectAddressUpdateFormDetails(),
  addressUpdateParams: makeSelectAddressUpdatePayload(),
  userUpdatedAddress: makeSelectUserUpdatedAddress()
});

export function mapDispatchToProps(dispatch) {
  return {
    showAddressUpdateSuccessModal: (isModalView = true) => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView, type: "AddressUpdateSuccessModal" }
        })
      );
    },
    showAddressUpdateFailureModal: (isModalView = true) => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView, type: "AddressUpdateFailureModal" }
        })
      );
    },
    hideMoreInfoModal: (isModalView = false) => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView, type: "" }
        })
      );
    }
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  reduxForm({
    form: forms.ADDRESS_UPDATE_FORM,
    destroyOnUnmount: false,
    validate,
    asyncValidate
  }),
  memo
)(AddressUpdate(SmartRender));
