import React from "react";
import PropTypes from "prop-types";
import { Text } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import "./style.css";

const HighlightText = ({ text, backgroundColor, color }) => {
  return (
    <Text
      mx={6}
      mb={1}
      fontSize={1}
      lineHeight="1"
      color={color}
      fontWeight="400"
    >
      <span
        style={{
          background: backgroundColor
        }}
        className="highlight-text"
      >
        {text && <FormattedMessage id={text} />}
      </span>
    </Text>
  );
};

HighlightText.defaultProps = {
  text: "",
  backgroundColor: "black",
  color: "white"
};

HighlightText.propTypes = {
  text: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string
};

export default HighlightText;
