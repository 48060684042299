import Analytics from "../Analytics";
import get from "lodash/get";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import { BRAND_NAME, COUNTRY } from "utils/constants";
import {
  GTM_DATALAYER_EVENT_MAPPING,
  GTM_EVENTS_NAME
} from "../../constants/AnalyticsTypeConstants";
import TagManager from "react-gtm-module";

class GoogleTagManagerAnalytics extends Analytics {
  pushEvent(event, data, message, country = COUNTRY?.toLowerCase()) {
    /**
     * Push events to Google Tag Manager
     * @param {string} event type of event triggered based on user actions, page load and etc.
     * @param {object} data data associated with triggered event
     */
    const currentUrl = get(window, "location.pathname", "");
    this.pushToDataLayer(event, data, currentUrl, country);
  }

  pushToDataLayer(
    event,
    data,
    currentUrl,
    country,
    googleTagManagerId = get(window, "GOOGLE_TAG_MANAGER_ID"),
    tagManager = TagManager
  ) {
    /**
     * Pushes Analytics events to Google Tag Manager
     * @param {string} event type of event triggered based on user actions, page load and etc.
     * @param {object} data data associated with triggered event
     * @param {string} country current country
     * @param {string} googleTagManagerId account ID of Google Tag Manager, initialized through ConfigManager
     */
    const gtmDataLayerEvent = GTM_DATALAYER_EVENT_MAPPING[event];

    // check if event needs to trigger google tag manager
    if (gtmDataLayerEvent) {
      // check if current country uses google tag manager
      const countrySupported = find(gtmDataLayerEvent, country);

      if (countrySupported) {
        // check if google tag manager needs to trigger on currentUrl
        const urlSupported = find(gtmDataLayerEvent.valid_countries[country], [
          "trigger_urls",
          [currentUrl]
        ]);

        if (urlSupported) {
          // push to dataLayer only if google tag manager ID exists
          if (googleTagManagerId) {
            const dataLayerParams = constructDataLayerParams(
              data,
              urlSupported.event
            );
            const tagManagerArgs = {
              gtmId: googleTagManagerId,
              dataLayer: dataLayerParams
            };

            console.log("gtm: ", { dataLayer: tagManagerArgs.dataLayer });
            // push to Google Tag Manager
            tagManager.initialize(tagManagerArgs);
          }
        }
      }
    }
  }
}

export const constructDataLayerParams = (
  data,
  event = GTM_EVENTS_NAME.ECOMM_EVENT
) => {
  /**
   * Constructs data to be pushed into Google Tag Manager
   * @param {object} data Data associated with event
   * @return {object} Data to be pushed into Google Tag Manager
   */

  switch (event) {
    case GTM_EVENTS_NAME.ECOMM_EVENT:
      return buildEcommDataLayer(data, event);
    case GTM_EVENTS_NAME.CUSTOM_PURCHASE:
      return buildCustomPurchaseDataLayer(data, event);
    default:
      break;
  }

  return {};
};

const buildEcommDataLayer = (data, event) => ({
  event,
  transactionId: get(data, "order_ref", null), // going into dataLayer, therefore fallback on null value
  transactionProductType: get(data, "plan_name", null), // going into dataLayer, therefore fallback on null value
  transactionTotal: get(data, "orderParams.order_summary.onetime_total_fee", 0),
  transactionQuantity: 1
});

const buildCustomPurchaseDataLayer = (data, event) => ({
  event,
  plan_name: data?.plan_name,
  transaction_id: data.order?.order_ref,
  value: data.orderParams.order_summary?.onetime_total_fee,
  tax: 0,
  shipping: data.orderParams.order_summary?.delivery_slot_fee,
  currency: data?.currency,
  coupon: data?.referral_code,
  // should updated for SG
  simCount: 1,
  // hard coded for JP
  simType: data.orderParams?.sim_type,
  journeyType: "Non-SAD New",
  items: constructItems(data)
});

const constructItems = data => {
  const { sku, textName, basicPrice } = data?.planService;
  const orderItems = data?.orderParams?.order_items || [];
  const planItem = {
    item_name: textName,
    item_id: sku,
    price: basicPrice,
    item_brand: BRAND_NAME,
    item_category: "PLAN",
    item_variant: "PLAN",
    quantity: "1"
  };
  const items = !isEmpty(orderItems)
    ? orderItems.map(item => ({
        item_name: item?.title || "",
        item_id: item?.sku || "",
        price: item?.price || 0,
        item_brand: BRAND_NAME,
        item_category: item?.type || "ADDON",
        item_variant: item?.type || "ADDON",
        quantity: "1"
      }))
    : [];
  items.unshift(planItem);
  return items;
};

export default new GoogleTagManagerAnalytics();
