import React, { useEffect } from "react";
import { Box, Text } from "rebass";
import { Field } from "redux-form";
import isFunction from "lodash/isFunction";
import "./style.css";

const Tab = ({ active, onClick, children }) => (
  <Box
    as="button"
    onClick={onClick}
    className={active ? "tabButton active" : "tabButton"}
  >
    <Text>{children}</Text>
  </Box>
);

const Tabs = ({ tabs, selectedTab, setSelectedTab }) => (
  <Box mb={3} display="flex">
    {tabs.map((tab, index) => (
      <Tab
        key={index}
        active={selectedTab === tab.value}
        onClick={() => setSelectedTab(tab.value)}
      >
        {tab.label}
      </Tab>
    ))}
  </Box>
);

const DisplayTabs = props => {
  const { data: { tabLabels } = {}, change, field } = props;

  const [selectedTab, setSelectedTab] = React.useState(tabLabels[0].value);

  useEffect(() => {
    isFunction(change) && change(field, selectedTab);
  }, [change, field, selectedTab]);

  return (
    <Box p={4}>
      <Tabs
        tabs={tabLabels}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    </Box>
  );
};

const TAB_VIEW = ({ field, data, change }) => (
  <Field
    name={field}
    component={DisplayTabs}
    data={data}
    field={field}
    change={change}
  />
);

export default TAB_VIEW;
