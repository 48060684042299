import React from "react";
import MultiTextField from "components/FormFields/MultiTextField";
import { constructOnChangeHandler } from "utils/helpers";

const MULTI_TEXT_FIELD = ({ data, ...props }) => {
  return (
    <MultiTextField
      {...props}
      {...data}
      onChange={constructOnChangeHandler(props)}
    />
  );
};

export default MULTI_TEXT_FIELD;
