import { createSelector } from "reselect";
import get from "lodash/get";

const referralCodeDomain = state => get(state.api, "referralCode.data", {});
const referralCodeErrorReasonDomain = state =>
  get(state.api, "referralCode.errorReason", "");
const isLoading = state => get(state.api, "referralCode.loading", false);

// ***************************************************************
// ****************** referral code selectors ********************
// ***************************************************************

export const referralCodeSelector = createSelector(
  referralCodeDomain,
  referralCodeErrorReasonDomain,
  isLoading,
  (referralCode, errorReason, loading) => {
    return {
      loading,
      benefits: referralCode.benefits,
      title: referralCode.title,
      reason: referralCode.reason || referralCode.message_code || errorReason,
      valid: referralCode.code === 0,
      value: referralCode.referral_code,
      port_in_only: referralCode.port_in_only
    };
  }
);

export const validReferralCodeResponseSelector = createSelector(
  referralCodeDomain,
  validReferralCodeResponse => validReferralCodeResponse || {}
);
// ***************************************************************
// ****************** referral code makeSelect *******************
// ***************************************************************

export const makeSelectReferralCode = () => referralCodeSelector;
