import React, { useState, useEffect } from "react";
import map from "lodash/map";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import FormattedMessage from "components/FormattedMessage";
import { Button, H2, Flex, Input, Text, Box, Link } from "ui-components";
import Spinner, { styles } from "components/Spinner";
import { compactJoin, sanitizeHTML } from "utils/helpers";
import { getButtonColorVariant, COLORS } from "ui-components/theme";

const PromoCodeBox = props => {
  const {
    defaultPromoCodeOffers,
    label,
    defaultValue,
    onChange,
    onSubmit,
    loading,
    valid,
    reason,
    title,
    benefits,
    locale,
    themeColors,
    readonly,
    hideBenefits
  } = props;

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const errorReason = reason && `promo_code.${reason.toLowerCase()}`;
  const buttonTheme = get(themeColors, "button", {});
  const infoColor = get(themeColors, "info", COLORS.cyan[0]);
  const successColor = get(themeColors, "success", COLORS.green[1]);
  const errorColor = get(themeColors, "error", COLORS.red[2]);
  const submitted = value && (valid || reason);
  const buttonVariant = submitted ? "outline" : "primary";
  const buttonStyles = getButtonColorVariant(buttonTheme, buttonVariant);
  const buttonId = submitted ? "change" : "apply";
  const className = submitted ? (valid ? "valid" : "invalid") : "";
  const placeholder = get(
    locale,
    "enter_promo_code_here",
    "Enter promo code here"
  );
  const promoBenefits = !isEmpty(benefits)
    ? compactJoin(map(benefits, "title"), "<br/>")
    : "";
  const promoDetails =
    !hideBenefits && promoBenefits
      ? compactJoin(
          [
            "<div style='font-weight: bold'>",
            promoBenefits,
            "</div><br/><br/>",
            title
          ],
          ""
        )
      : title;
  const handleClick = () => {
    if (submitted) {
      setValue("");
      onChange();
    } else {
      value && onSubmit(value);
    }
  };

  return (
    <>
      <Flex>
        {(!readonly || (readonly && submitted)) && label && (
          <FormattedMessage id={label}>
            {msg => (
              <H2>
                {msg}
                {readonly && submitted ? <span>&nbsp;:&nbsp;</span> : ""}
              </H2>
            )}
          </FormattedMessage>
        )}
        {readonly && submitted && (
          <span
            data-testid="promo-code-used"
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              color: valid ? successColor : errorColor
            }}
            className={className}
          >
            {value}
          </span>
        )}
      </Flex>

      {!readonly && (
        <Flex flexWrap="wrap" alignItems="center" mx={-1}>
          <Box width={[3 / 5, 4 / 5, 4 / 5, 4 / 5]} sx={{ px: 1 }}>
            <Input
              autoComplete="off"
              placeholder={placeholder}
              disabled={loading}
              data-testid="promo-code-input"
              value={value}
              className={className}
              onChange={e => {
                setValue(e.target.value);
                onChange();
              }}
              onKeyDown={e =>
                e && e.key === "Enter" && value && onSubmit(value)
              }
              style={{ fontSize: "1.2rem" }}
            />
          </Box>

          <Box width={[2 / 5, 1 / 5, 1 / 5, 1 / 5]} sx={{ px: 1 }}>
            <FormattedMessage id={buttonId}>
              {msg => (
                <Button
                  data-testid={`promo-code-${buttonId}`}
                  disabled={loading}
                  variant={buttonVariant}
                  sx={buttonStyles}
                  onClick={handleClick}
                  width="100%"
                >
                  {loading ? <Spinner size={19} sx={styles.button} /> : msg}
                </Button>
              )}
            </FormattedMessage>
          </Box>
        </Flex>
      )}

      {value && promoDetails && (
        <Flex
          p="4"
          mb="4"
          variant={"light"}
          alignItems="center"
          width="100%"
          backgroundColor={infoColor}
        >
          <Text
            fontWeight="500"
            size="sm"
            dangerouslySetInnerHTML={{ __html: sanitizeHTML(promoDetails) }}
          />
        </Flex>
      )}

      {value && errorReason && (
        <FormattedMessage id={errorReason}>
          {errorMsg => (
            <Text fontWeight="500" my={1} size="xs" color={errorColor}>
              {errorMsg}
            </Text>
          )}
        </FormattedMessage>
      )}

      {!readonly &&
        !valid &&
        map(defaultPromoCodeOffers, ({ id, prefix, value, postfix }) => {
          return (
            <Box
              p="4"
              mb="4"
              variant={"light"}
              alignItems="center"
              width="100%"
              key={`default-offer-${id}`}
            >
              <Text as="span" size="sm">
                {prefix}
              </Text>
              <Text
                fontWeight="700"
                as={Link}
                color={themeColors?.cta}
                onClick={() => {
                  setValue(value);
                  onSubmit(value);
                }}
              >
                {value}
              </Text>
              <Text as="span" size="sm">
                {postfix}
              </Text>
            </Box>
          );
        })}
    </>
  );
};

PromoCodeBox.defaultProps = {
  title: "",
  defaultValue: "",
  defaultPromoCodeOffers: []
};

export default PromoCodeBox;
