import DELIVERY_ADDRESS_FORM_1 from "smart-components/DELIVERY_ADDRESS_FORM_1";

const BILLING_ADDRESS_FORM = props => {
  return DELIVERY_ADDRESS_FORM_1({
    ...props,
    fieldPrefix: "billing"
  });
};

export default BILLING_ADDRESS_FORM;
