import React from "react";
import { Box } from "ui-components";
import PayItemCard from "components/PayItemCard";

const PAY_TODAY_CARD = ({ payTodayItems, data: { append = [], ...rest } }) => {
  let items = [...payTodayItems.items, ...append];

  return (
    <Box mb={6}>
      <PayItemCard
        {...payTodayItems}
        items={items}
        title={"pay_today"}
        {...rest}
      />
    </Box>
  );
};

export default PAY_TODAY_CARD;
