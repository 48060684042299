import React, { memo } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Helmet } from "react-helmet";
import map from "lodash/map";
import isPlainObject from "lodash/isPlainObject";
import {
  makeSelectPlugin,
  makeSelectPlugins,
  makeSelectTitle
} from "ducks/selectors/appSettings";
import { processPlugins } from "utils/helpers";
import {
  BRAND_NAME,
  BRAND_FAVICON,
  BRAND_TITLE,
  BRAND_DESCRIPTION
} from "utils/constants";
const unescapeJs = require("unescape-js");

const defaultIcon = `${BRAND_NAME ? BRAND_NAME.toLowerCase() : "favicon"}.ico`;
const favicon = BRAND_FAVICON || defaultIcon;

const Helm = ({ title, description, pluginData, plugins }) => {
  const brandTitle = title || BRAND_TITLE;
  const brandDescription = description || BRAND_DESCRIPTION;
  const { jsLibs, cssLibs, escapedScript, noScripts } = processPlugins(
    plugins,
    pluginData
  );
  return (
    <Helmet>
      <meta charset="utf-8" />
      <link rel="shortcut icon" href={`/${favicon}?v=2`} />
      <meta name="format-detection" content="telephone=no" />
      <meta
        http-equiv="Content-Security-Policy"
        content="default-src * 'self' 'unsafe-inline' 'unsafe-eval' data: gap: content:"
      />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, user-scalable=no, minimal-ui, viewport-fit=cover"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="theme-color" content="#4285f4" />
      <meta name="msapplication-tap-highlight" content="no" />
      <title>{brandTitle}</title>
      <meta name="description" content={brandDescription} />
      <meta property="og:image" content={`/${favicon}?v=2`} />
      <meta property="og:title" content={brandTitle} />
      <meta property="og:description" content={brandDescription} />

      {map(jsLibs, ({ src, ...attributes }) => (
        <script key={src} src={src} {...attributes}></script>
      ))}
      {map(noScripts, noScript => {
        return <noscript key={noScript}>{noScript}</noscript>;
      })}
      {map(cssLibs, url => {
        if (isPlainObject(url)) {
          const { src, ...attributes } = url;
          return (
            <link
              key={src}
              href={src}
              rel={"stylesheet"}
              {...attributes}
            ></link>
          );
        } else {
          return <link key={url} href={url} rel={"stylesheet"}></link>;
        }
      })}

      <script>{unescapeJs(escapedScript)}</script>
    </Helmet>
  );
};

const mapStateToProps = (state, props) => {
  const pluginData = { paymentprice: "10SGD" };
  return createStructuredSelector({
    plugin: makeSelectPlugin(),
    plugins: makeSelectPlugins(),
    title: makeSelectTitle(),
    pluginData: () => pluginData
  });
};

const mapDispatchToProps = {};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(Helm);
