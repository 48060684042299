import { createSelector } from "reselect";
import get from "lodash/get";
import { forms } from "utils/constants";
import { orderRefSelector } from "../order";
import { sessionTokenSelector } from "./manageOrder";
import { orderDetailsSelector } from "../orderDetails";
import { userSINSelector } from "../authInfo";
import { planChangeReasonSelector } from "../simDetails";

const planChangeDetailsDomain = state =>
  get(state, `form.${forms.PLAN_CHANGE_DETAILS_FORM}.values`, {});

export const planChangeDetailsSelector = createSelector(
  planChangeDetailsDomain,
  planChangeDetails => planChangeDetails
);

export const planChangeSimTypeSelector = createSelector(
  planChangeDetailsDomain,
  ({ sim_type }) => sim_type
);

export const planChangeKycOptionSelector = createSelector(
  planChangeDetailsDomain,
  ({ kyc_for_plan_change }) => kyc_for_plan_change?.value
);

export const planChangeNetWorkTypeSelector = createSelector(
  planChangeDetailsDomain,
  ({ network_type }) => network_type
);

export const makeSelectPlanChangePayload = () =>
  createSelector(
    planChangeDetailsDomain,
    orderRefSelector,
    sessionTokenSelector,
    orderDetailsSelector,
    userSINSelector,
    planChangeReasonSelector,
    planChangeKycOptionSelector,
    (
      planChangeDetails,
      order_ref,
      session_token,
      orderDetails,
      accountNumber,
      simReplacementForPlanChange,
      selectedKycType
    ) => {
      const planChangePayload = {
        accountNumber,
        order_ref,
        session_token,
        primary_id: get(planChangeDetails, "primary_id"),
        switch_to_esim: false,
        skip_form_submission:
          get(orderDetails, "state") === "DELIVERY_FAILED" ||
          get(orderDetails, "in_progress") ||
          get(planChangeDetails, "sim_type.value") === "ESIM",
        inventoryType: get(planChangeDetails, "sim_type"),
        reason: simReplacementForPlanChange,
        nric:
          get(planChangeDetails, "primary_id") || get(planChangeDetails, "nric")
      };

      if (selectedKycType === "CH_AGENT") {
        planChangePayload.subReason = "contact_ch";
      }

      return planChangePayload;
    }
  );

export const makeSelectPanChangeDetailsForm = () => planChangeDetailsSelector;
export const makeSelectPlanChangeSimType = () => planChangeSimTypeSelector;
export const makeSelectPanChangeKycOption = () => planChangeKycOptionSelector;
export const makeSelectPlanChangeNetworkType = () =>
  planChangeNetWorkTypeSelector;
