import { createSelector } from "reselect";
import { selectedDeliveryMethodSelector } from "./deliveryInfo";
import get from "lodash/get";
import { forms } from "utils/constants";

const addressUpdateDomain = state =>
  get(state, `form.${forms.ADDRESS_UPDATE_FORM}.values`, {});

export const addressUpdateFormValueSelector = createSelector(
  addressUpdateDomain,
  addressDetails => addressDetails
);

export const addressUpdateInitialValuesSelector = createSelector(
  selectedDeliveryMethodSelector,
  addressUpdateFormValueSelector,
  (data, formData) => {
    return {
      delivery_method: data,
      ...formData
    };
  }
);

export const userUpdatedAddressSelector = createSelector(
  addressUpdateFormValueSelector,
  addressDetails => {
    const { zip_code, state, city, street_name, hse_blk_tower } =
      addressDetails || {};

    return `〒${zip_code}<br/>${state}${city}${street_name}<br/>${
      hse_blk_tower ? hse_blk_tower : ""
    }`;
  }
);

export const addressUpdatePayloadSelector = createSelector(
  addressUpdateDomain,
  addressDetails => {
    const { zip_code, state, city, street_name, hse_blk_tower, country } =
      addressDetails || {};
    return {
      post_code: zip_code,
      state: state,
      city: city,
      street: street_name,
      AddressOne: "",
      AddressTwo: "",
      country: country,
      floor: "",
      district: state,
      unit: "",
      building_no: hse_blk_tower || ""
    };
  }
);

export const makeSelectAddressUpdateFormDetails = () =>
  addressUpdateFormValueSelector;

export const makeSelectAddressUpdateInitialValues = () =>
  addressUpdateInitialValuesSelector;

export const makeSelectAddressUpdatePayload = () =>
  addressUpdatePayloadSelector;

export const makeSelectUserUpdatedAddress = () => userUpdatedAddressSelector;
