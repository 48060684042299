import React from "react";
import get from "lodash/get";
import AddressLookup from "components/AddressLookup";

const ADDRESS_LOOKUP = props => {
  const { formValues } = props;
  const selectedDeliveryMethod = get(formValues, "delivery_method");
  if (!selectedDeliveryMethod) return null;

  return <AddressLookup {...props} />;
};

export default ADDRESS_LOOKUP;
