import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { IS_PATHS_SECURED, publicPaths } from "utils/constants";
import {
  getChannelFromUrl,
  gotoUrl,
  pathWithChannel,
  urlParam
} from "utils/helpers";
import { makeSelectAuthToken, makeSelectIsUserLoggedIn } from "ducks/selectors";
import useAuthTokenFromUrl from "hooks/useAuthTokenFromUrl";

const AuthorizedRoute = props => {
  const requestPath = window.location.pathname || "/";
  const requestPathQueryString = window.location.search;
  const validateTokenAndAllowAccess = useAuthTokenFromUrl(requestPath);
  const isLoggedIn = useSelector(makeSelectIsUserLoggedIn());
  const authToken = useSelector(makeSelectAuthToken());
  // Used as a flag to avoid reading the same url param token at every re-render
  const [refreshTokenCaptured, setRefreshTokenCaptured] = useState(false);

  // If no path security in place, redirect directly to route
  if (!IS_PATHS_SECURED) return <Route {...props} />;

  const redirectToLoginPage = () => {
    const defaultChannel = "web";
    const allowedChannels = ["web", "au_to_povo"];
    const currentChannel = getChannelFromUrl();
    const redirect =
      urlParam("redirect") || pathWithChannel(requestPath, currentChannel);

    /**
     * some channels have itw own login routes.
     * If a channel other than allowed chanel requests a login it will be redirected to default channel login workflow
     */
    const channel =
      allowedChannels.indexOf(currentChannel) >= 0
        ? currentChannel
        : defaultChannel;

    gotoUrl(pathWithChannel(publicPaths.LOGIN_PAGE, channel), {}, {}, false, {
      redirect: `${redirect}${requestPathQueryString}`
    });
  };

  const authTokenFromURl = urlParam("auth_token");
  if (
    !refreshTokenCaptured &&
    authTokenFromURl &&
    authTokenFromURl !== authToken
  ) {
    setRefreshTokenCaptured(true);
    /**
     * This will validate the user with the provided token
     * and if token is invalid the user will be marked as not logged in
     */
    validateTokenAndAllowAccess(authTokenFromURl);
    /* Need to return from here as validateTokenAndAllowAccess will take care of where to return.
    The component howeve needs to return something for the route to render. */
    return null;
  }

  if (isLoggedIn) return <Route {...props} />;

  redirectToLoginPage();
  return null;
};

export default AuthorizedRoute;
