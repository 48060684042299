import styled from "@emotion/styled";
import { Heading } from "rebass";

export const H1 = styled(Heading)``;

export const H2 = styled(Heading)``;

export const H3 = styled(Heading)``;

export const H4 = styled(Heading)``;

export const H5 = styled(Heading)``;

H1.defaultProps = {
  color: "gray.3",
  as: "h1",
  fontSize: [5]
};

H2.defaultProps = {
  color: "gray.2",
  as: "h2",
  fontSize: [3, 4]
};

H3.defaultProps = {
  color: "gray.2",
  as: "h3",
  fontSize: [2, 3]
};

H4.defaultProps = {
  as: "h4",
  fontSize: [1, 2]
};

H5.defaultProps = {
  color: "gray.2",
  as: "h5",
  fontSize: [1, 1]
};
