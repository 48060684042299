import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import styles from "./styles";
import { reduxForm, reset } from "redux-form";
import SmartRender from "containers/SmartRender";
import validate from "validations";
import { submitTermination, submitWinback } from "ducks/actions";
import { gotoUrl } from "utils/helpers";
import { makeSelectTermination } from "ducks/selectors";
import isEmpty from "lodash/isEmpty";
import { forms } from "utils/constants";

const mapStateToProps = createStructuredSelector({
  styles: () => styles,
  termination: makeSelectTermination()
});

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    submitTermination: ({
      orderRef,
      actionType,
      terminationFeedback,
      terminationReason,
      terminationDate,
      successPath
    }) => {
      const params = {
        order_ref: orderRef,
        action_type: actionType,
        feedback: terminationFeedback,
        reason: terminationReason,
        termination_date: terminationDate
      };

      const callback = (res, err) => {
        try {
          dispatch(reset(forms.TERMINATE_NUMBER_FORM));
          if (!isEmpty(res) && successPath) {
            setTimeout(() => {
              gotoUrl(successPath);
            }, 0);
          }
        } catch (err) {
          console.error("Error on submitTermination callback", err);
        }
      };

      dispatch(submitTermination(params, callback));
    },
    submitWinback: ({
      orderId,
      orderRef,
      winbackCode,
      winbackId,
      winbackOffer,
      terminationPhoneNumber,
      terminationFeedback,
      terminationReason,
      terminationDate,
      winbackPath
    }) => {
      const params = {
        order_id: orderId,
        order_ref: orderRef,
        winback_code: winbackCode,
        winback_id: winbackId,
        winback_description: winbackOffer,
        phone_number: terminationPhoneNumber,
        feedback: terminationFeedback,
        reason: terminationReason,
        termination_date: terminationDate
      };

      const callback = res => {
        try {
          if (!isEmpty(res) && winbackPath) {
            setTimeout(() => {
              gotoUrl(winbackPath);
            }, 0);
          }
        } catch (err) {
          console.error("Error on submitWinback callback", err);
        }
      };

      dispatch(submitWinback(params, callback));
    },
    dismissTermination: dismissPath => {
      if (dismissPath) {
        gotoUrl(dismissPath);
      }
    }
  };
}

const enhance = compose(
  reduxForm({
    form: forms.TERMINATE_NUMBER_FORM,
    validate,
    destroyOnUnmount: false
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(SmartRender);
