import { createSelector } from "reselect";
import get from "lodash/get";
import {
  formatCurrency,
  formatDisplayInvoiceDate,
  getURLParams
} from "utils/helpers";

import { currencyFormatSelector } from "../appSettings";
import map from "lodash/map";
import {
  validateSignatureDomainSelector,
  verifyOTPSelector
} from "ducks/selectors";

export const payOutstandingBillsDomain = state => {
  return get(state, "api.payOutstandingBills.data", {});
};

export const TransactionStatusDomain = state => {
  return get(state, "api.checkTransactionStatus.data", {});
};

export const outstandingBillsDomain = state => {
  return get(state, "api.outstandingBills.data", {});
};

export const outstandingBillsFormSelector = state =>
  get(state, "form.OutstandingBillsForm.values", {
    //set default form keys here
    session_token: null,
    referral_type: null,
    query_params: {
      an: null,
      expiry: null,
      sig: null
    },
    order_ref: null,
    otp_validated: null,
    form_type: {},
    validate_signature: {},
    outstanding_bills: {}
  });

export const makeSelectOutstandingBillsDomain = () =>
  createSelector(
    outstandingBillsDomain,
    currencyFormatSelector,
    (data, currencyFormat) => data
  );
export const makeSelectPayOutstandingBillsDomain = () =>
  createSelector(
    payOutstandingBillsDomain,
    payOutstandingBillsData => payOutstandingBillsData
  );

export const makeSelectcheckTransactionStatusDomain = () =>
  createSelector(TransactionStatusDomain, data => data);

export const makeSelectFormattedOutstandingBillsInvoices = () =>
  createSelector(
    outstandingBillsFormSelector,
    currencyFormatSelector,
    (formValues, currencyFormat) => {
      let invoices = get(formValues, "outstanding_bills.invoices", []);
      let formattedInvoices = map(invoices, invoice => {
        return {
          ...invoice,
          formattedPrice: formatCurrency(invoice.amount, currencyFormat, false),
          formattedDate: formatDisplayInvoiceDate({
            unix: invoice.invoiceDate
          })
        };
      });

      return formattedInvoices;
    }
  );

export const makeSelectOutstandingBillsForm = () =>
  outstandingBillsFormSelector;

export const makeSelectOutstandingBillTotalAmount = () =>
  createSelector(outstandingBillsDomain, outstandingBills =>
    get(outstandingBills, "total.amount")
  );

export const makeSelectOutstandingBillToken = () =>
  createSelector(
    validateSignatureDomainSelector,
    verifyOTPSelector,
    (validateSignature, verifyOtp) => {
      const { token } = getURLParams();
      if (token) {
        return get(validateSignature, "session_token");
      }
      return get(verifyOtp, "session_token");
    }
  );
