import { createSelector } from "reselect";
import get from "lodash/get";
import { forms } from "utils/constants";
import { userEmailSelector } from "../authInfo";
import { userloginEmailSelector as contractDetailsLoginEmail } from "../forms/contractDetails";

/**
 * Extracting User Login from values
 * @param {object} state
 */
const loginFormDomain = state =>
  get(state, `form.${forms.USER_LOGIN_FORM}.values`, {});

/**
 * ************ Login Form Selectors ************
 */
export const loginEmailSelector = createSelector(loginFormDomain, loginForm => {
  const email = get(loginForm, "email");
  return typeof email === "object" ? email.value : email;
});

export const loginFormMessageDataSelector = createSelector(
  loginEmailSelector,
  contractDetailsLoginEmail,
  userEmailSelector,
  (loginEmail, contractDetailsEmail, userEmail) => ({
    userEmail,
    loginEmail,
    email: userEmail || contractDetailsEmail || loginEmail
  })
);

/**
 * ************ Make Selectors ************
 */
export const makeSelectLoginEmail = () => loginEmailSelector;

export const makeSelectLoginFormMessageData = () =>
  loginFormMessageDataSelector;
