import React, { useRef, useEffect } from "react";
import CompountTimer from "react-compound-timer";
import PropTypes from "prop-types";
import { getItem, setItem, removeItem } from "utils/localStorage";
import FormattedMessage from "components/FormattedMessage";

/**
 * The Id for this Timer should be unique. This will be used to keep track of the
 * timer state. New Id basically means new timer instance.
 * @param {object} props
 *
 * @type checkpoints is an array of { time, callback },
 * the callbacks will be triggered upon reaching the time. Time should be in ms
 */
const Timer = props => {
  const {
    id,
    durationInMiliSeconds,
    direction = "backward",
    onTimerExpired,
    label,
    expireTimerText = "00:00",
    resetOnExpire = false,
    checkpoints = []
  } = props;

  const KEY = `${id}.timer`;
  const START_KEY = `${KEY}.startrecord`;
  const timerRef = useRef(null);
  const startTime = durationInMiliSeconds - getElapsedTime();
  const expireAt = direction === "backward" ? 0 : durationInMiliSeconds;

  useEffect(() => {
    // Ref copy to make sure the component is not changed at the time of unmount
    const _timerRef = timerRef.current;

    // start the timer at component mount.
    _timerRef.reset();
    _timerRef.start();

    // If no previous key is found set the start time to now.
    // This is useful to figure out a returning user (if the timer was already initiated)
    if (getItem(START_KEY) === null) {
      setItem(START_KEY, Date.now());
    }

    return () => {
      _timerRef.stop();
    };
  }, [START_KEY]);

  /**
   * Timer expired callback function.
   * This will trigger the props onTimerExpired if available
   */
  const timerExpired = () => {
    if (onTimerExpired) onTimerExpired();
    if (resetOnExpire) removeItem(START_KEY);
  };

  /**
   * Returns formatted time in the format of mm:ss
   * When timer is expired it will return the expire timer text
   * @param {Function} getTime function provided by timer library to get current executing time
   */
  const getFormattedTime = getTime => {
    const time = Math.floor(getTime() / 1000);

    if (time < 0) {
      return expireTimerText;
    }

    let secondsNow = time % 60;
    secondsNow = secondsNow < 10 ? `0${secondsNow}` : secondsNow;

    let minutesNow = Math.floor(time / 60);
    minutesNow = minutesNow < 10 ? `0${minutesNow}` : minutesNow;

    return `${minutesNow}:${secondsNow}`;
  };

  /**
   * Return localized timer label
   */
  const getLocalizedTimerLabel = () => {
    if (label) return <FormattedMessage id={label} />;

    return null;
  };

  /**
   * Returns already spent time.
   * This uses the timer start timestamp to figure out how much time
   * has gone up to this point.
   */
  function getElapsedTime() {
    const time = getItem(START_KEY);
    const elapsedTime = Date.now() - time;
    return time === null ? 0 : elapsedTime;
  }

  return (
    <div>
      <CompountTimer
        ref={timerRef}
        startImmediately={false}
        initialTime={startTime}
        direction={direction}
        checkpoints={[
          {
            time: expireAt,
            callback: timerExpired
          },
          ...checkpoints
        ]}
      >
        {({ getTime }) => {
          return (
            <div>
              {getLocalizedTimerLabel(getTime)}
              &nbsp;
              {getFormattedTime(getTime)}
            </div>
          );
        }}
      </CompountTimer>
    </div>
  );
};

Timer.propTypes = {
  id: PropTypes.string.isRequired,
  durationInMiliSeconds: PropTypes.number.isRequired,
  onTimerExpired: PropTypes.func,
  direction: PropTypes.oneOf(["forward", "backward"]),
  label: PropTypes.string,
  expireTimerText: PropTypes.string,
  resetOnExpire: PropTypes.bool,
  checkpoints: PropTypes.array
};

export default Timer;
