import React, { memo, useCallback, useState } from "react";
import { compose } from "redux";
import SmartRender from "containers/SmartRender/index";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { reduxForm } from "redux-form";
import { forms, paths, TELCO_API_ERRORS } from "utils/constants";
import validate from "validations";
import { setData, Telco } from "ducks/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  makeSelectPageValidations,
  makeSelectAuthToken,
  makeSelectCountryCodePrefixSelector,
  makeSelectCurrentPhoneNumber,
  makeSelectFormValues,
  makeSelectServiceInstanceNumber,
  makeSelectIsModalView,
  makeSelectModalType
} from "ducks/selectors";
import { gotoUrl, pathWithChannel, urlParam } from "utils/helpers";
import { getDeviceId, isWebview } from "utils/localStorage";

const UpdateUserPIN = Component =>
  function Comp(props) {
    const dispatch = useDispatch();
    const [showOTPModal, setShowOTPModal] = useState(false);
    const accountNumber = useSelector(makeSelectServiceInstanceNumber());
    const msisdn = useSelector(makeSelectCurrentPhoneNumber());
    const authToken = useSelector(makeSelectAuthToken());
    const countryCodePrefix = useSelector(
      makeSelectCountryCodePrefixSelector()
    );
    const formValues = useSelector(
      makeSelectFormValues(forms.CONFIRM_PIN_CHANGE_FORM)
    );

    const onSubmit = useCallback(
      (_, data = {}) => {
        const { otp_payload } = data;
        let requestPayLoad = {
          setting: "pin",
          msisdn,
          otp_payload,
          account_number: accountNumber,
          value: formValues?.new_pin,
          prefix: countryCodePrefix
        };

        dispatch(
          Telco.V1.UpdatePIN({}, requestPayLoad, (result = {}, errors) => {
            if (!errors) {
              setShowOTPModal(false);
              //call pin update success route with url params
              gotoUrl(pathWithChannel(paths.PIN_UPDATE_SUCCESS), true);
            } else {
              const { title } = errors || {};
              if (title !== TELCO_API_ERRORS.UNAUTHORISED) {
                setShowOTPModal(false);
              }
            }
          })
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [accountNumber, msisdn, formValues]
    );

    const navigateToProfile = navigate => {
      if (isWebview()) {
        const mobilePage = urlParam("return_url") || paths.CLOSEWEBVIEW;
        const mobileParams = {
          device_id: getDeviceId(),
          auth_token: authToken
        };
        gotoUrl(mobilePage, false, {}, true, mobileParams);
      } else {
        //navigate to profile route
        navigate && navigate();
      }
    };

    return (
      <Component
        {...props}
        showOTPModal={showOTPModal}
        setShowOTPModal={setShowOTPModal}
        onSubmit={onSubmit}
        navigateToProfile={navigateToProfile}
        cancelPINUpdate={navigateToProfile}
      />
    );
  };

const mapStateToProps = createStructuredSelector({
  formValidations: makeSelectPageValidations(forms.CONFIRM_PIN_CHANGE_FORM),
  isModalView: makeSelectIsModalView(),
  modalType: makeSelectModalType()
});
export function mapDispatchToProps(dispatch, ownProps) {
  return {
    hideModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
    },
    onNextClickInOffHours: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "offlineHourAction" }
        })
      );
    }
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  reduxForm({
    form: forms.CONFIRM_PIN_CHANGE_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(UpdateUserPIN(SmartRender));
