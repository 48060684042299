import React from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { Box } from "ui-components";
import { isMobile } from "utils/helpers";
import { getLocaleOrDefault } from "utils/localStorage";

const IMAGE = ({ data = {} }) => {
  const { alt, style, boxVariant, boxPadding, boxMarginBottom } = data;
  const locale = getLocaleOrDefault();
  const device = isMobile() ? "mobile" : "desktop";
  const src = get(data, ["src", locale, device], data.src);
  return isEmpty(src) ? null : (
    <Box
      variant={boxVariant || "transparent"}
      padding={boxPadding || "0"}
      mb={boxMarginBottom || "0"}
    >
      <img alt={alt} src={src} style={style} width="100%" height="auto" />
    </Box>
  );
};

export default IMAGE;
