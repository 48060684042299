import Alert from "react-s-alert";

const dismissNative = () => {
  try {
    /* global DataActivationController */
    DataActivationController.doneSelected();
  } catch (e) {
    console.error(e);
  }
};

const downLoadEsimProfile = (paramsWithEid = {}) => {
  try {
    DataActivationController.dataPlanTransferAccountUpdatedWithInfo(
      paramsWithEid
    );
  } catch (e) {
    console.error(e);
    Alert.error(e.message);
  }
};

export default {
  dismiss: dismissNative,
  dataPlan: downLoadEsimProfile
};
