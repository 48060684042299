import React from "react";
import isFunction from "lodash/isFunction";
import "./styles.css";
// https://docs.adyen.com/checkout/web-sdk/

class AdyenForm extends React.Component {
  componentDidMount() {
    const { paymentRequest, onSuccess, onFail } = this.props;
    const init = setInterval(() => {
      if (window.chckt && isFunction(window.chckt.checkout)) {
        clearInterval(init);

        const sdkConfigObj = {
          context: paymentRequest.context
          // translations: {
          //   "payButton": { "zh_TW" : "支付 元" }
          // }
        };

        // Initiate the Checkout form.
        window.chckt.checkout(
          paymentRequest.payment_session,
          "#payment",
          sdkConfigObj
        );

        try {
          const list = document.getElementsByClassName("chckt-pm__name");

          for (let item of list) {
            const html = item.innerHTML || "";
            if (html.includes("Credit Card")) {
              item.innerHTML = "Credit/Debit Card";
            }
          }
        } catch (e) {
          console.log(e);
        }
        window.chckt.hooks.beforeComplete = function(node, paymentData) {
          if (paymentData.resultCode === "authorised") {
            onSuccess(paymentData);
          } else {
            onFail(paymentData);
          }
        };
      }
    }, 1000);
  }

  render() {
    return <div id="payment" />;
  }
}

export default AdyenForm;
