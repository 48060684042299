import React from "react";
import { Box, Flex, Text } from "ui-components";
import { COLORS, iconThemes } from "ui-components/theme";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import { map } from "lodash";

const PhoneDetailsItem = ({ items = {}, primaryColor }) => {
  const phoneItems = filter(items, { type: "phone" });
  return isEmpty(phoneItems) ? null : (
    <Box my={5}>
      {map(phoneItems, phoneItem => (
        <Flex
          py={1}
          justifyContent="space-between"
          alignItems="center"
          px={"1rem"}
        >
          <Box paddingRight={4} justifySelf="flex-start" flex={1}>
            <Text
              fontWeight="bold"
              py="4px"
              color={phoneItem.benefit ? COLORS.green[1] : COLORS.gray[3]}
            >
              {phoneItem.label}
            </Text>
          </Box>
          <Box width={["fit-content"]}>
            <Text
              fontWeight="bold"
              py="4px"
              color={phoneItem.benefit ? COLORS.red[1] : COLORS.blue[4]}
            >
              {phoneItem.price}
            </Text>
          </Box>
        </Flex>
      ))}
    </Box>
  );
};

PhoneDetailsItem.defaultProps = {
  items: {},
  primaryColor: iconThemes.black
};

export default PhoneDetailsItem;
