import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Flex, Text, Button } from "ui-components";
import { urlParam, getMyInfoAuthorisedURL } from "utils/helpers";
import FormattedMessage from "components/FormattedMessage";
import Logo from "assets/images/MyInfo-logo.png";
import isEmpty from "lodash/isEmpty";
import { COLORS, getButtonColorVariant } from "ui-components/theme";
import styles from "./styles";
import { setData } from "ducks/actions";

const MyInfoBox = ({
  title,
  buttonTheme,
  btnLabel,
  fieldNameToClear,
  clearInfoLabel,
  changeField,
  setMyInfoIsUsed,
  fetchMyInfoDetails,
  setMyInfoCode,
  myInfoCode,
  clearMyInfoFormFields,
  clearMyInfoUrlParam,
  clearMyInfoDomain,
  myInfoDetails,
  setMyInfoDetailsToForm,
  myInfoIsUsed
}) => {
  const link = getMyInfoAuthorisedURL();
  const dispatch = useDispatch();
  const handleOnloginWithMyInfoClick = () => {
    dispatch(setData({ key: "MyInfoEvent" }));
  };

  useEffect(() => {
    setMyInfoCode();
  }, [myInfoCode, setMyInfoCode]);

  //if myInfoCode exist or updated, fire fetch myinfo details.
  useEffect(() => {
    const myInfoCondition =
      !isEmpty(myInfoCode) &&
      !isEmpty(urlParam("state")) &&
      urlParam("code") === myInfoCode;
    if (myInfoIsUsed && myInfoCondition && isEmpty(myInfoDetails)) {
      fetchMyInfoDetails({
        auth_code: myInfoCode,
        redirect_uri: window.location.origin + window.location.pathname
      });
    }
    // eslint-disable-next-line
  }, [myInfoCode, myInfoIsUsed]);

  useEffect(() => {
    if (myInfoIsUsed && !isEmpty(myInfoDetails)) {
      setMyInfoDetailsToForm(myInfoDetails, changeField);
      clearMyInfoUrlParam();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoIsUsed, myInfoDetails]);

  useEffect(() => {
    if (myInfoCode) {
      setMyInfoIsUsed(true);
    }
  }, [myInfoCode, setMyInfoIsUsed]);

  const onClearButtonClicked = () => {
    clearMyInfoUrlParam();
    clearMyInfoFormFields(fieldNameToClear);
    clearMyInfoDomain();
    setMyInfoIsUsed(false);
    setMyInfoCode();
  };

  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      flexDirection={"column"}
      pt={3}
      pb={3}
      mb={3}
    >
      <Flex
        width="100%"
        alignItems={"center"}
        justifyContent={"center"}
        textAlign={"center"}
        sx={styles.title}
      >
        <div>
          <FormattedMessage html id={title} values={{ logo: Logo }} />
        </div>
      </Flex>

      {myInfoIsUsed ? (
        <Button
          variant="primary"
          sx={getButtonColorVariant(buttonTheme, "primary")}
          onClick={onClearButtonClicked}
        >
          <FormattedMessage id={clearInfoLabel || "my_info_clear_info_button"}>
            {msg => <Text color={COLORS.white}>{msg}</Text>}
          </FormattedMessage>
        </Button>
      ) : (
        <Button
          variant="primary"
          sx={getButtonColorVariant(buttonTheme, "primary")}
          as="a"
          href={link}
          onClick={handleOnloginWithMyInfoClick}
        >
          <FormattedMessage id={btnLabel || "my_info_retrieve_button"}>
            {msg => <Text color={COLORS.white}>{msg}</Text>}
          </FormattedMessage>
        </Button>
      )}
    </Flex>
  );
};

MyInfoBox.defaultProps = {
  title: "my_info_title",
  fieldNameToClear: [],
  btnLabel: "my_info_retrieve_button",
  clearInfoLabel: "my_info_clear_info_button",
  myInfoCode: "",
  setMyInfoCode: () => {}
};

export default MyInfoBox;
