import React from "react";
import isEmpty from "lodash/isEmpty";
import { Box } from "ui-components";
import { renderPageComponents } from "../renderPageComponent";

/**
 * TBD: To resolve renderSmartComponents function.
 * renderPageComponents not included because slightly complicated to include it. Not sure where to migrate function renderPageComponents into the new smart component file
 */

const ORDER_SUCCESS_VIEW = props => {
  let {
    style,
    pageProps,
    appSettings,
    screenshotEnabled,
    components: pageComponents
  } = props;
  return (
    <>
      <div data-testid="smart-render">
        <Box style={style}>
          {isEmpty(pageComponents)
            ? null
            : renderPageComponents({
                pageComponents,
                pageProps,
                appSettings,
                screenshotEnabled
              })}
        </Box>
      </div>
    </>
  );
};

export default ORDER_SUCCESS_VIEW;
