import React, { memo, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import validate, { asyncValidate } from "validations";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import {
  forms,
  AUTH_MODES,
  ERROR_CODES,
  paths,
  SIM_TYPES,
  CONTRACT_TYPES,
  CONFLICTING_ORDERS_STATUS,
  ONESTOP_MNP_PORTIN_CALLBACK_API,
  MOBILE_APP_BROWSER_PROTOCOL,
  ONESTOP_MNP_PORTIN_IOS_BROWSER_CALLBACK_API,
  AU_TO_POVO_CHANNEL,
  DEFAULT_CHANNEL
} from "utils/constants";
import {
  getDeviceId,
  removeTimerKey,
  setOneStopPortInParams
} from "utils/localStorage";
import usePostDocumentAPI from "../../hooks/usePostDocumentAPI";
import {
  makeSelectPageValidations,
  makeSelectMonthOptions,
  makeSelectDateOptions,
  makeSelectYearOptions,
  makeSelectMinimumAge,
  makeSelectUserDetailsConfirmation,
  makeSelectMnpProviderName,
  makeSelectDeliveryMethods,
  makeSelectUserDetailsPayload,
  makeSelectParentalConsentPayloadSelector,
  makeSelectPortinExpiryDateOptions,
  makeSelectUserDetailsInitialValues,
  makeSelectUserDetailsKycPayload,
  makeSelectUserDetailsOrderUpdatePayload,
  makeSelectDeliveryDetailsItems,
  makeSelectDeliveryDates,
  makeSelectCombinedDeliveryDates,
  makeSelectCvsDeliveryDates,
  makeSelectFreeDeliverySlots,
  makeSelectSpecialDeliverySlots,
  makeSelectPostOfficeRegions,
  makeSelectPostOfficeDeliverySlots,
  makeSelectSelectedPostOffice,
  makeSelectIsUploadIDLater,
  makeSelectPostalCodeValidation,
  makeSelectAddressComponentMappings,
  makeSelectCityList,
  makeSelectDistrictList,
  makeSelectStreetList,
  makeSelectStoreList,
  makeSelectGuestOrder,
  makeSelectIsFetchingData,
  makeSelectLiquidOcrData,
  makeSelectConnectionDetailsForm,
  makeSelectIsPortingUser,
  makeSelectIsPortingUserWithPortingNumber,
  makeSelectConsent,
  makeSelectIsModalView,
  makeSelectOrderParams,
  makeSelectOrderRef,
  makeSelectMaxAgeParentalConsent,
  makeSelectModalType,
  makeSelectThemeColors,
  makeSelectAuthId,
  makeSelectPortInUpdatePayload,
  makeSelectServiceInstanceNumber,
  makeSelectIsReInitiatePortIn,
  makeSelectIsSimReplacementKYC,
  makeSelectIsSignUpWithIPDB,
  makeSelectDeliveryDetailsSelector,
  makeSelectFetchDeliverySlotAPIParams,
  makeSelectHasUnprocessableEntityError,
  makeSelectUnavailableUserRequiredData,
  makeSelectzEIdentifyUserData,
  makeSelectOneStopMnpOnlyIOSBrowserSupportingTelcos,
  makeSelectOneStopMnpProvidersNameCodeMap,
  makeSelectUserInVerifyKycState,
  makeSelectGA4PlanDetails,
  makeSelectUserDetailsConfirmationFormErrors,
  makeSelectEligiblePlanRequestChannel
} from "ducks/selectors";
import {
  changeFormField,
  initializeForm,
  uploadSignature,
  fetchAddressLookup,
  resetDeliverySlots,
  fetchPostOfficeDeliverySlots,
  fetchAddressComponentMappings,
  findAddressLookup,
  validatePostalCode,
  resetPostalCodeValidation,
  setPostalCodeValidation,
  fetchCityList,
  fetchDistrictList,
  fetchStreetList,
  fetchStoreList,
  resetDistrictList,
  resetStreetList,
  resetStoreList,
  fetchUserOrders,
  setData,
  clearData,
  selectPlan
} from "ducks/actions";
import { buttonThemes } from "ui-components/theme";
import SmartRender from "containers/SmartRender";
import Spinner, { styles } from "components/Spinner";
import {
  gotoUrl,
  getChannelFromUrl,
  isMobile,
  pathWithChannel,
  portinProviders,
  sortAndFilterOrders,
  getDeliveryDates,
  deliverySlotParams,
  activateZendeskWidget,
  loggedInUserDetailsAPIParams,
  getHash,
  isWebView,
  isIOS
} from "utils/helpers";
import OTPModal from "components/OTPModal";
import { UserService, OMS, Laas, Telco } from "ducks/actions";
import head from "lodash/head";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";

const MNO_ID_FOR_MVNO = "mvno";
const OTP_MODAL_TYPE = "otpModal";
const ONESTOP_MNP_COMPLETED_MODAL_TYPE = "onestopMNPProcedureCompleted";

const UserDetailsConfirmation = Component =>
  function Comp(props) {
    const {
      getLiquidOcrData,
      fetchUserOrders,
      order,
      liquidOcrData,
      isUpdatingOrder,
      isFetchingRequiredData,
      initialValues,
      initializeForm,
      isPortingUser,
      isSavingKycDetails,
      clearOrder,
      orderRef,
      isReInitiatePortIn,
      hideModal,
      themeColors,
      requestParentEmailOTP,
      submitUserDetailsWithParentalConsent,
      parentalConsentPayload,
      isSimReplacementKYC,
      isOTPModalOpen: showOTPModal,
      webviewNotSupportedMNOs,
      showConfirmMNPCompletedModal,
      providerNameCodeMap,
      planChangeVerifyKyc,
      GA4EventPlanDetails
    } = props;
    const ocrDataLoaded = get(initialValues, "ocrDataLoaded");
    const kycDataLoaded = get(initialValues, "kycDataLoaded");
    const loading = isSavingKycDetails || isUpdatingOrder;
    const buttonTheme = get(themeColors, "button", buttonThemes.povodarkgray);

    const { submitPostApi, cleanup } = usePostDocumentAPI();

    const queryParams = queryString.parse(window.location.search);
    const handleOneStopParams = ({ mno, onestop, param, valid }) => {
      const hasMnpParams = mno || onestop || param || valid;
      hasMnpParams && setOneStopPortInParams(queryParams);
    };

    // const contractorIsUser = props.formValues?.contractor_is_user;

    const redirectToSourceProvider = () => {
      //URL TO be USED LATER
      let URL = "";
      const mnoProviderName = props?.formValues?.select_mnp_providers;
      let mvnoProviderName = null;

      if (mnoProviderName === MNO_ID_FOR_MVNO) {
        mvnoProviderName = props?.formValues?.mvno_provider_name?.value;
        const mvnoProviderUrl =
          props?.appSettings?.generic?.mvno_providers_options[mvnoProviderName];
        URL = mvnoProviderUrl;
      } else {
        const mnoProviderUrl =
          props?.appSettings?.generic?.mnp_providers_options[mnoProviderName];
        URL = mnoProviderUrl;
      }

      const params = {
        onestop: "1",
        param: getHash(orderRef),
        callback: `${window.location.origin}${ONESTOP_MNP_PORTIN_CALLBACK_API}`
      };

      /**
       * Some telco's does not support webview. For those telcos the procedure is transfered to do in native browser
       * These will be calling a separate API as the 302 will be different (ONESTOP_MNP_PORTIN_IOS_BROWSER_CALLBACK_API)
       */
      if (isMNOActivityTobeInBrowser(mvnoProviderName || mnoProviderName)) {
        const originWithBrowserProtocol = window.location.origin.replace(
          "https",
          MOBILE_APP_BROWSER_PROTOCOL
        );
        const urlWithBrowserProtocol = `${originWithBrowserProtocol}${paths.ONESTOP_MNP_BROWSER_INIT}`;
        const queryParams = {
          ...params,
          url: URL,
          reset: true,
          callback: `${window.location.origin}${ONESTOP_MNP_PORTIN_IOS_BROWSER_CALLBACK_API}`
        };

        // Giving some time to show the modal. So user does not see the modal before the redirection
        setTimeout(showConfirmMNPCompletedModal, 100);
        gotoUrl(urlWithBrowserProtocol, false, queryParams);
      } else {
        submitPostApi(URL, params);
      }
    };

    const isMNOActivityTobeInBrowser = selectedMnoOrMVNO => {
      if (isWebView() && isIOS()) {
        return webviewNotSupportedMNOs.includes(selectedMnoOrMVNO);
      } else {
        return false;
      }
    };

    /**
     * Used to handle cases like Rakuten mobile, where the login procedure does not work in Webview.
     * Here the valid is assumed to be immediate and mnoCode is extracted from the CCM config
     */
    const storeOnestopParamsAndCloseAllModalsAndNavigate = () => {
      let providerName = null;

      if (props.formValues?.select_mnp_providers === MNO_ID_FOR_MVNO) {
        providerName = props?.formValues?.mvno_provider_name?.value;
      } else {
        providerName = props?.formValues?.select_mnp_providers;
      }
      const mnoCode = providerNameCodeMap[providerName];
      const valid = "000000000000";
      setOneStopPortInParams({
        mno: mnoCode,
        valid,
        onestop: "1"
      });
      props.hideAllModals();
      gotoUrl(paths.MNP_LANDING_PAGE);
    };

    useEffect(() => {
      if (!isPortingUser && !planChangeVerifyKyc) {
        if (
          orderRef &&
          (isEmpty(liquidOcrData) || liquidOcrData?.orderRef !== orderRef) &&
          !isReInitiatePortIn
        ) {
          getLiquidOcrData(orderRef);
        }
        if (isEmpty(order)) {
          fetchUserOrders();
        }
      }

      return () => cleanup();
      // eslint-disable-next-line
    }, []);

    // GA4 events form start
    useEffect(() => {
      // if the form is dirty and its been user interacted
      if (window.location.pathname === paths.USER_DETAILS) {
        GA4EventsService.publish(
          personalDetailsSubmitEventData(
            GA4EVENTS.PERSONAL_DETAILS_FORM_START,
            GA4EventPlanDetails
          )
        );
      } else if (window.location.pathname === paths.DELIVERY_DETAILS) {
        GA4EventsService.publish(
          personalDetailsSubmitEventData(
            GA4EVENTS.DELIVERY_DETAILS_FORM_START,
            GA4EventPlanDetails
          )
        );
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //   if (contractorIsUser === undefined) return;

    //   if (window.location.pathname === paths.OWNER_DETAILS) {
    //     GA4EventsService.publish(
    //       personalDetailsSubmitEventData(
    //         GA4EVENTS.CONFIRMATION_CHOICE_SELECTED,
    //         props.GA4EventPlanDetails
    //       )
    //     );
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [contractorIsUser]);

    // Handle onestop MNP query params
    useEffect(() => {
      queryParams && handleOneStopParams(queryParams);
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (
        ocrDataLoaded ||
        kycDataLoaded ||
        planChangeVerifyKyc ||
        (isSimReplacementKYC && isPortingUser)
      ) {
        initializeForm(initialValues);
      }
      // eslint-disable-next-line
    }, [ocrDataLoaded, kycDataLoaded]);

    useEffect(
      () => {
        if (!isEmpty(order?.error)) {
          clearOrder();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [order?.error]
    );

    return !isPortingUser &&
      !planChangeVerifyKyc &&
      ((isEmpty(liquidOcrData) && !isReInitiatePortIn) ||
        isFetchingRequiredData ||
        isUpdatingOrder) ? (
      <Spinner size={200} sx={styles.fullPage} />
    ) : (
      <>
        <Component
          loading={loading}
          {...props}
          redirectToSourceProvider={redirectToSourceProvider}
          storeOnestopParamsAndCloseAllModalsAndNavigate={
            storeOnestopParamsAndCloseAllModalsAndNavigate
          }
        />
        <OTPModal
          modal_title="verify_parent_guardian_email"
          modal_subtitle="verify_account_by_otp_instructions"
          showModal={showOTPModal}
          showCloseButton={false}
          closeModal={hideModal}
          buttonTheme={buttonTheme}
          hideOtpResend={true}
          enableBackButton={true}
          info_message={"otp_help_faq"}
          headerStyles={{
            alignItems: "center",
            textAlign: "center",
            padding: "0.5rem",
            width: "100%",
            marginBottom: "2rem",
            overflowWrap: "anywhere"
          }}
          headerValues={{
            email: parentalConsentPayload.email
          }}
          otpBoxes={{
            size: 6,
            onSubmit: submitUserDetailsWithParentalConsent,
            buttonColor: buttonTheme,
            instruction: "otp_code",
            label: "continue",
            timerKey: "parent_otp",
            buttonWidth: ["100%", "60%"],
            timer: {
              label: "expiring_in",
              durationInSeconds: 120,
              enableResendLinkInSeconds: 60,
              resendLinkCallback: null
            }
          }}
          resendOTPLink={{
            resend: requestParentEmailOTP,
            isDisable: false
          }}
        />
      </>
    );
  };

const mapStateToProps = createStructuredSelector({
  formValidations: makeSelectPageValidations(
    forms.USER_DETAILS_CONFIRMATION_FORM
  ),
  formValues: makeSelectUserDetailsConfirmation(),
  mnpProviderName: makeSelectMnpProviderName(),
  order: makeSelectGuestOrder(),
  monthOptions: makeSelectMonthOptions(),
  dateOptions: makeSelectDateOptions(),
  yearOptions: makeSelectYearOptions(),
  minimumAge: makeSelectMinimumAge(),
  portinExpiryDateOptions: makeSelectPortinExpiryDateOptions(),
  payload: makeSelectUserDetailsPayload(),
  parentalConsentPayload: makeSelectParentalConsentPayloadSelector(),
  kycPayload: makeSelectUserDetailsKycPayload(),
  orderUpdatePayload: makeSelectUserDetailsOrderUpdatePayload(),
  deliveryMethods: makeSelectDeliveryMethods(),
  initialValues: makeSelectUserDetailsInitialValues(),
  deliveryDetailsItems: makeSelectDeliveryDetailsItems(),
  deliverySlotDates: makeSelectDeliveryDates(),
  combinedDeliverySlotDates: makeSelectCombinedDeliveryDates(),
  cvsDeliverySlotDates: makeSelectCvsDeliveryDates(),
  freeDeliverySlotTime: makeSelectFreeDeliverySlots(),
  specialDeliverySlotTime: makeSelectSpecialDeliverySlots(),
  postOfficeRegions: makeSelectPostOfficeRegions(),
  postOfficeDeliverySlots: makeSelectPostOfficeDeliverySlots(),
  selectedPostOffice: makeSelectSelectedPostOffice(),
  uploadIDLater: makeSelectIsUploadIDLater(),
  postalCodeValidation: makeSelectPostalCodeValidation(),
  addressComponentMappings: makeSelectAddressComponentMappings(),
  cityList: makeSelectCityList(),
  districtList: makeSelectDistrictList(),
  streetList: makeSelectStreetList(),
  storeList: makeSelectStoreList(),
  liquidOcrData: makeSelectLiquidOcrData(),
  isUpdatingOrder: makeSelectIsFetchingData("order"),
  isFetchingRequiredData: makeSelectIsFetchingData([
    "liquidOcrData",
    "liquidOCRRegister",
    "loggedInUserDetails"
  ]),
  isPortingUser: makeSelectIsPortingUser(),
  isPortingUserWithPortingNumber: makeSelectIsPortingUserWithPortingNumber(),
  connectionDetails: makeSelectConnectionDetailsForm(),
  isConsentReviewed: makeSelectConsent(),
  isModalView: makeSelectIsModalView(),
  modalType: makeSelectModalType(),
  planChangeVerifyKyc: makeSelectUserInVerifyKycState(),
  orderParams: makeSelectOrderParams(),
  orderRef: makeSelectOrderRef(),
  isSavingKycDetails: makeSelectIsFetchingData("submitUserKycDetails"),
  isFetchingDeliverySlots: makeSelectIsFetchingData("deliverySlots"),
  maxAgeParentalConsent: makeSelectMaxAgeParentalConsent(),
  themeColors: makeSelectThemeColors(),
  authId: makeSelectAuthId(),
  updatePortInPayload: makeSelectPortInUpdatePayload(),
  accountNumber: makeSelectServiceInstanceNumber(),
  isReInitiatePortIn: makeSelectIsReInitiatePortIn(),
  isSimReplacementKYC: makeSelectIsSimReplacementKYC(),
  isSignUpWithAuIPDB: makeSelectIsSignUpWithIPDB(),
  deliverySlotsAPiParams: makeSelectFetchDeliverySlotAPIParams(),
  hasUnprocessableEntityError: makeSelectHasUnprocessableEntityError(),
  deliveryDetails: makeSelectDeliveryDetailsSelector(),
  unavailableUserRequiredData: makeSelectUnavailableUserRequiredData(),
  zEIdentify: makeSelectzEIdentifyUserData(),
  webviewNotSupportedMNOs: makeSelectOneStopMnpOnlyIOSBrowserSupportingTelcos(),
  providerNameCodeMap: makeSelectOneStopMnpProvidersNameCodeMap(),
  GA4EventPlanDetails: makeSelectGA4PlanDetails(),
  formErrors: makeSelectUserDetailsConfirmationFormErrors(),
  storedEligiblePlanChannel: makeSelectEligiblePlanRequestChannel()
});

export const mapDispatchToProps = dispatch => {
  return {
    clearOrder: () => {
      dispatch(clearData("order"));
    },
    uploadSignature: payload => dispatch(uploadSignature(payload)),
    changeField: (field, value) => {
      dispatch(
        changeFormField({
          form: forms.USER_DETAILS_CONFIRMATION_FORM,
          field: field,
          value: value
        })
      );
    },
    initializeForm: initialValues => {
      dispatch(
        initializeForm({
          form: forms.USER_DETAILS_CONFIRMATION_FORM,
          data: initialValues
        })
      );
      dispatch(
        setData({
          key: "isConsentReviewed",
          data: { isConsentReviewed: false }
        })
      );
      dispatch(setData({ key: "isModalView", data: { isModalView: true } }));
    },
    consentReviewed: () => {
      dispatch(
        setData({ key: "isConsentReviewed", data: { isConsentReviewed: true } })
      );
    },
    consentReviewedFalse: () => {
      dispatch(
        setData({
          key: "isConsentReviewed",
          data: { isConsentReviewed: false }
        })
      );
    },
    hideModal: () => {
      dispatch(
        setData({ key: "isModalView", data: { isModalView: false, type: "" } })
      );
      dispatch(clearData(["parentalConsentOtp"]));
    },
    hideAllModals: () => {
      dispatch(
        setData({ key: "isModalView", data: { isModalView: false, type: "" } })
      );
    },
    updateOrder: (payload, callback) =>
      dispatch(OMS.V4.updateOrder(payload, callback)),
    getLiquidOcrData: (orderRef, callback) => {
      dispatch(UserService.V4.getLiquidOcrData(orderRef, callback));
    },
    registerOcrData: (payload, callback) => {
      dispatch(UserService.V4.registerOcrData(payload, callback));
    },
    fetchPostOfficeDeliverySlots: params => {
      dispatch(fetchPostOfficeDeliverySlots(params));
    },
    fetchDeliverySlots: (params, callback) => {
      dispatch(Laas.V1.fetchDeliverySlots(params, callback));
    },
    resetDeliverySlots: () => dispatch(resetDeliverySlots()),
    fetchAddressLookup: params => {
      dispatch(fetchAddressLookup(params));
    },
    fetchAddressComponentMappings: params => {
      dispatch(fetchAddressComponentMappings(params));
    },
    findAddressLookup: (params, callback) => {
      dispatch(findAddressLookup(params, callback));
    },
    validatePostalCode: (postal_code, field) => {
      dispatch(validatePostalCode({ postal_code }, field));
    },
    resetPostalCodeValidation: field => {
      dispatch(resetPostalCodeValidation(field));
    },
    setPostalCodeValidation: (field, data, loading) => {
      dispatch(setPostalCodeValidation(field, data, loading));
    },
    fetchCityList: params => {
      dispatch(fetchCityList(params));
    },
    fetchDistrictList: params => {
      dispatch(fetchDistrictList(params));
    },
    fetchStreetList: params => {
      dispatch(fetchStreetList(params));
    },
    fetchStoreList: params => {
      dispatch(fetchStoreList(params));
    },
    resetDistrictList: () => {
      dispatch(resetDistrictList());
    },
    resetStreetList: () => {
      dispatch(resetStreetList());
    },
    resetStoreList: () => {
      dispatch(resetStoreList());
    },
    fetchUserOrders: () => {
      dispatch(fetchUserOrders({}));
    },
    updateUserKYCDetails: (orderRef, kycPayload, navigate) => {
      const providers = portinProviders();
      const types = providers.map(provider => `${provider}_PORTIN`);
      const updateKycPayload = {
        kyc_identity: {
          provider: providers?.join(",") || "",
          kyc_type: types?.join(",") || "",
          identifier: orderRef
        },
        user_kyc: {
          ...kycPayload,
          order_id: orderRef
        }
      };
      dispatch(
        UserService.V4.submitUserKycDetails(
          updateKycPayload,
          (_, error, status) => {
            if (isEmpty(error) || [409, 404].includes(status)) {
              navigate();
            } else {
              const { code } = _;

              if (code === ERROR_CODES.missingResourceForSubmitKycRequest) {
                gotoUrl(
                  paths.CONNECTION_DETAILS.replace(
                    ":channel",
                    getChannelFromUrl()
                  )
                );
              }
            }
          }
        )
      );
    },
    createInitialOrder: (orderParams, callback) => {
      dispatch(OMS.V4.createInitialOrder(orderParams, callback));
    },
    // Request OTP authentication for the parent email provided
    makeParentEmailOTP: bodyParams => {
      const headers = {};
      dispatch(
        UserService.V4.userLoginOTP(headers, bodyParams, (_, errors) => {
          if (!errors) {
            dispatch(
              setData({
                key: "isModalView",
                data: { isModalView: true, type: OTP_MODAL_TYPE }
              })
            );
          }
        })
      );
    },
    // Submit User provided OTP. On Success user will be taken to next step
    submitMinorsDetails: (bodyParams, successCallback) => {
      const headers = {};
      dispatch(
        OMS.V4.updateParentalConsent(
          headers,
          bodyParams,
          (response, errors) => {
            if (isEmpty(errors)) {
              dispatch(
                setData({
                  key: "isModalView",
                  data: { isModalView: false, type: "" }
                })
              );
              successCallback();
            } else {
              const { message } = response;
              dispatch(
                setData({
                  key: "parentalConsentOtp",
                  data: { errorMessage: message }
                })
              );
            }
          }
        )
      );
    },
    updatePortInDetails: (updatePortInPayload, callback) => {
      dispatch(OMS.V4.updatePortInDetails(updatePortInPayload, callback));
    },
    reInitiatePortIn: (payload, callback) => {
      dispatch(Telco.V2.reInitiatePortIn(payload, callback));
    },
    fetchUserOrdersAndNavigateToManageOrder: () => {
      dispatch(
        fetchUserOrders({}, orders => {
          const filteredOrders = sortAndFilterOrders(orders);
          if (!isEmpty(filteredOrders)) {
            gotoUrl(paths.MANAGE_ORDER, true, {
              reset: null,
              iccid: null
            });
          }
        })
      );
    },
    fetchLoggedInUserDetails: () => {
      const params = loggedInUserDetailsAPIParams();
      dispatch(UserService.V4.fetchLoggedInUserDetails({}, params));
    },
    openLiveChat: zEIdentify => {
      activateZendeskWidget(zEIdentify);
    },
    verifyPlanChangeKycDetails: () => {
      gotoUrl(paths.MANAGE_ORDER, false, {}, true);
    },
    showConfirmMNPCompletedModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: ONESTOP_MNP_COMPLETED_MODAL_TYPE }
        })
      );
    },
    selectPlan: plan => dispatch(selectPlan(plan)),
    fetchEligiblePlans: (params, callBack) => {
      dispatch(OMS.V4.fetchEligiblePlans(params, callBack));
    }
  };
};

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  isOTPModalOpen:
    stateProps.isModalView && stateProps.modalType === OTP_MODAL_TYPE,
  registerOcrDataAndNavigate: navigate => {
    dispatchProps.registerOcrData(stateProps.kycPayload, (_, error, status) => {
      if (isEmpty(error) || [409, 404].includes(status)) {
        navigate();
      }
    });
  },
  updateOrderAndNavigate: navigate => {
    dispatchProps.updateOrder(stateProps.orderUpdatePayload, (_, error) => {
      if (isEmpty(error)) {
        // GA4EventsService.publish(
        //   personalDetailsSubmitEventData(
        //     GA4EVENTS.APP_SUBMITTED_FOR_VERIFICATION,
        //     stateProps.GA4EventPlanDetails
        //   )
        // );
        navigate();
      }
    });
  },
  registerOcrDataAndUpdateOrderAndNavigate: navigate => {
    const simType =
      stateProps?.order?.simType || stateProps?.connectionDetails?.sim_type;
    const orderUpdate = orderUpdatePayload => {
      dispatchProps.updateOrder(orderUpdatePayload, (_, error) => {
        if (isEmpty(error)) {
          dispatchProps.registerOcrData(
            stateProps.kycPayload,
            (_, error, status) => {
              if (isEmpty(error) || [409, 404].includes(status)) {
                // GA4EventsService.publish(
                //   personalDetailsSubmitEventData(
                //     GA4EVENTS.APP_SUBMITTED_FOR_VERIFICATION,
                //     stateProps.GA4EventPlanDetails
                //   )
                // );
                navigate();
              }
            }
          );
        }
      });
    };
    const { delivery_slot } = stateProps.orderUpdatePayload;
    if (
      simType === SIM_TYPES.PHYSICAL_SIM &&
      (isEmpty(delivery_slot) || stateProps.hasUnprocessableEntityError)
    ) {
      //if there is a no valid deliver slot ,retry the fetchDeliverySlots API
      dispatchProps.fetchDeliverySlots(
        stateProps.deliverySlotsAPiParams,
        result => {
          const slots = getDeliveryDates(result, stateProps.deliveryDetails);
          const userDetailsFormValue = {
            ...stateProps.formValues,
            delivery_slot_full_data: head(slots)?.value || "",
            delivery_slot_fee: head(slots)?.cost || 0,
            delivery_slot_id: head(slots)?.slotId || 0,
            delivery_slot: head(slots)?.deliverySlot || ""
          };
          const deliverySlot = deliverySlotParams(userDetailsFormValue);
          const orderParams = {
            ...stateProps.orderUpdatePayload,
            ...deliverySlot
          };
          orderUpdate(orderParams);
        }
      );
    } else {
      orderUpdate(stateProps.orderUpdatePayload);
    }
  },
  createInitialOrderAndUpdateKycDetailsAndNavigate: navigate => {
    const { orderParams, kycPayload, storedEligiblePlanChannel } = stateProps;

    const createOrder = () => {
      dispatchProps.createInitialOrder(orderParams, (order, error) => {
        const { status } = error || {};
        if (isEmpty(error)) {
          const { order_ref } = order;
          dispatchProps.updateUserKYCDetails(order_ref, kycPayload, navigate);
        } else if (status === CONFLICTING_ORDERS_STATUS) {
          if (stateProps.isSignUpWithAuIPDB)
            console.log("409 while creating order");
          else dispatchProps.fetchUserOrdersAndNavigateToManageOrder();
        }
      });
    };

    if (
      ![AU_TO_POVO_CHANNEL, DEFAULT_CHANNEL].includes(storedEligiblePlanChannel)
    ) {
      const planParams = { channel: AU_TO_POVO_CHANNEL };
      dispatchProps.fetchEligiblePlans(planParams, (response, error) => {
        if (isEmpty(error)) {
          const selectedPlan = head(response);
          dispatchProps.selectPlan(selectedPlan);
          orderParams.selected_planid = selectedPlan?.id;
          createOrder();
        }
      });
    } else {
      createOrder();
    }
  },
  requestParentEmailOTP: timerKey => {
    const email = stateProps?.formValues?.parent_email;
    const bodyParams = {
      email,
      auth_mode: AUTH_MODES.ENHANCED_EMAIL_OTP
    };
    dispatchProps.makeParentEmailOTP(bodyParams);
    timerKey && removeTimerKey(timerKey);
  },
  submitUserDetailsWithParentalConsent: (_, { current_otp }) => {
    const formValues = stateProps?.formValues || {};
    const otpCode = current_otp || formValues.otp || "";
    const authId = stateProps?.authId;
    const parentDetails = stateProps.parentalConsentPayload;
    const simType =
      stateProps?.order?.simType || stateProps?.connectionDetails?.sim_type;
    const contractType =
      stateProps?.order?.contractType ||
      stateProps?.connectionDetails?.number_type;
    const orderRef = stateProps?.orderRef;
    const dob = stateProps?.formValues?.dob || "";

    const bodyParams = {
      order_ref: orderRef,
      dob: dob,
      otp: {
        otp_code: otpCode,
        auth_id: authId,
        auth_mode: AUTH_MODES.ENHANCED_EMAIL_OTP,
        device: {
          app_type: "ecosystem",
          device_id: getDeviceId(),
          device_type: isMobile ? "Mobile" : "web"
        }
      },
      parent: parentDetails
    };
    dispatchProps.submitMinorsDetails(bodyParams, () => {
      if (stateProps.isPortingUserWithPortingNumber) {
        const nextUrl =
          simType === SIM_TYPES.PHYSICAL_SIM
            ? pathWithChannel(paths.DELIVERY_DETAILS)
            : pathWithChannel(paths.ORDER_SUMMARY);
        return gotoUrl(nextUrl, false, {});
      } else {
        if (contractType === CONTRACT_TYPES.PORT_IN)
          return gotoUrl(pathWithChannel(paths.PORTIN_DETAILS), false, {});
        if (simType === SIM_TYPES.PHYSICAL_SIM)
          return gotoUrl(pathWithChannel(paths.DELIVERY_DETAILS), false, {});
        else if (simType === SIM_TYPES.E_SIM) {
          dispatchProps.updateOrder(
            stateProps.orderUpdatePayload,
            (_, error) => {
              if (isEmpty(error)) {
                dispatchProps.registerOcrData(
                  stateProps.kycPayload,
                  (_, error, status) => {
                    if (isEmpty(error) || [409, 404].includes(status)) {
                      // GA4EventsService.publish(
                      //   personalDetailsSubmitEventData(
                      //     GA4EVENTS.APP_SUBMITTED_FOR_VERIFICATION,
                      //     stateProps.GA4EventPlanDetails
                      //   )
                      // );
                      gotoUrl(paths.MANAGE_ORDER, false, {});
                    }
                  }
                );
              }
            }
          );
        }
      }
    });
  },
  updatePortInDetailsAndNavigate: navigate => {
    dispatchProps.updatePortInDetails(
      stateProps.updatePortInPayload,
      (_, error) => {
        if (isEmpty(error)) {
          navigate();
        }
      }
    );
  },
  reInitiatePortInAndNavigate: navigate => {
    const payload = {
      account_number: stateProps.accountNumber,
      donor_ref_id: get(stateProps.formValues, "mnp_reservation_number"),
      source: "ECOM"
    };
    dispatchProps.reInitiatePortIn(payload, (_, error) => {
      if (isEmpty(error)) {
        navigate();
      }
    });
  },
  openLiveChat: () => {
    dispatchProps.openLiveChat(stateProps.zEIdentify);
  },
  closeModalAndretryLoggedInUserDetails: () => {
    dispatchProps.hideAllModals();
    dispatchProps.fetchLoggedInUserDetails();
  },
  personalDetailsFormCompleteGAEventAndNavigate: navigate => {
    GA4EventsService.publish(
      personalDetailsSubmitEventData(
        GA4EVENTS.PERSONAL_DETAILS_FORM_COMPLETE,
        stateProps.GA4EventPlanDetails
      )
    );
    navigate && navigate();
  },
  deliveryDetailsFormCompleteGAEventAndNavigate: navigate => {
    const GA4EventPlanDetails = stateProps.GA4EventPlanDetails;

    GA4EventsService.publish(
      personalDetailsSubmitEventData(
        GA4EVENTS.DELIVERY_DETAILS_FORM_COMPLETE,
        GA4EventPlanDetails
      )
    );

    GA4EventsService.publish(
      personalDetailsSubmitEventData(
        GA4EVENTS.ADD_SHIPPING_INFO,
        GA4EventPlanDetails,
        {
          currency: "JPY",
          value: 0,
          tax: 0,
          shipping: 0,
          sim_count: 1,
          coupon: GA4EventPlanDetails.coupon,
          coupon_type: GA4EventPlanDetails.coupon_type,
          items: [
            {
              item_name: GA4EventPlanDetails.item_name,
              item_id: GA4EventPlanDetails.item_id,
              price: 0,
              item_brand: "povo",
              item_category: GA4EventPlanDetails.item_category,
              item_variant: GA4EventPlanDetails.item_variant,
              index: 0,
              quantity: 1
            }
          ]
        }
      )
    );
    navigate && navigate();
  }
});

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.USER_DETAILS_CONFIRMATION_FORM,
    destroyOnUnmount: false,
    validate,
    asyncValidate
  }),
  memo
)(UserDetailsConfirmation(SmartRender));

const personalDetailsSubmitEventData = (
  event,
  details = {},
  extraDetails = {}
) => {
  const {
    customer_id,
    plan_name,
    plan_type,
    sim_type,
    order_ref,
    journey_type,
    number_type
  } = details;

  return {
    event,
    ecommerce: {
      customer_id,
      order_ref,
      plan_name,
      journey_type,
      plan_type,
      sim_type,
      number_type,
      ...extraDetails
    }
  };
};
