import { getEnvVariableValue } from "./constants";

const getFeatureFlag = featureFlagEnvKey => {
  const featureFlagEnvValue = getEnvVariableValue(featureFlagEnvKey) || "false";
  return JSON.parse(featureFlagEnvValue);
};

const featureFlag5GSAEnabled = () =>
  getFeatureFlag("REACT_APP_FF_5G_SA_ENABLED");

const featureFlagMergeStepEnabled = () =>
  getFeatureFlag("REACT_APP_MERGE_STEP_ENABLED");

const featureFlagPaidyEnabled = () =>
  getFeatureFlag("REACT_APP_FF_PAIDY_ENABLED");

const featureFlagEsimActivationApi = () =>
  getFeatureFlag("REACT_APP_ESIM_API_ENABLED");

const featureFlagAddresUpdateEnabled = () =>
  getFeatureFlag("REACT_APP_ADDRESS_UPDATE_ENABLED");

const featureFlagVoiceMailEnabled = () =>
  getFeatureFlag("REACT_APP_VOICE_MAIL_ENABLED");

const featureFlagGA4Enabled = () => getFeatureFlag("REACT_APP_GA4_ENABLED");

const featureFlagDataOnlyPlanEnabled = () =>
  getFeatureFlag("REACT_APP_DATA_ONLY_PLAN_ENABLED");

const featureFlagEnableConviva = () =>
  getFeatureFlag("REACT_APP_CONVIVA_ENABLED");

const supportedFeatureFlags = () => ({
  "5G_SA_ENABLED": featureFlag5GSAEnabled(),
  PAIDY_ENABLED: featureFlagPaidyEnabled(),
  MERGE_STEP: featureFlagMergeStepEnabled(),
  ESIM_API_ENABLED: featureFlagEsimActivationApi(),
  VOICE_MAIL_ENABLED: featureFlagVoiceMailEnabled(),
  ADDRESS_UPDATE_ENABLED: featureFlagAddresUpdateEnabled(),
  GA4_ENABLED: featureFlagGA4Enabled(),
  DATA_ONLY_PLAN_ENABLED: featureFlagDataOnlyPlanEnabled()
});

export {
  supportedFeatureFlags,
  featureFlag5GSAEnabled,
  featureFlagMergeStepEnabled,
  featureFlagEsimActivationApi,
  featureFlagPaidyEnabled,
  featureFlagVoiceMailEnabled,
  featureFlagAddresUpdateEnabled,
  featureFlagGA4Enabled,
  featureFlagDataOnlyPlanEnabled,
  featureFlagEnableConviva
};
