import Session from "session";
import { getLocaleOrDefault } from "utils/localStorage";
import {
  addUrlParam,
  compactJoin,
  getAuthorizationHeader
} from "utils/helpers";
import submitWithRecaptcha from "components/Recaptcha";
import get from "lodash/get";
import axios from "axios";

const MAX_RETRY_ATTEMPTS_FOR_CAPTCHA = 3;

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
// function parseJSON(response) {
//   if (response.status === 204 || response.status === 205) {
//     return null;
//   }
//   return response.json();
// }

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response = {}) {
  const { status, statusText } = response;

  if (status >= 200 && status < 300) {
    return response;
  }

  return response.text().then(reponseData => {
    const errorMessage = getResponseError(reponseData);
    const error = new Error(errorMessage);
    error.response = reponseData;
    error.status = status;
    error.statusText = statusText;
    throw error;
  });

  function getResponseError(response) {
    let responseError;
    try {
      responseError = JSON.parse(response);
    } catch (e) {
      responseError = {};
    }

    const errorMessage = Array.isArray(responseError.error)
      ? responseError["error"][0]
      : get(responseError, "error", compactJoin([status, statusText], " : "));

    return errorMessage;
  }
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default async function request(url, options = {}) {
  const { token, timeStamp } = getAuthorizationHeader();
  const defaultHeaders = {
    deviceid: Session.getDeviceId(),
    "H-Locale": getLocaleOrDefault(),
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    "P-Token": token,
    "P-Timestamp": timeStamp
  };
  const headers = options.headers || {};
  const fetchOptions = {
    ...options,
    data: options.body,
    headers: {
      ...defaultHeaders,
      ...headers
    }
  };

  const data = await axios({ url, ...fetchOptions })
    .then(res => res.data || {})
    .catch(err => {
      const responseData = err?.response?.data;
      const responseError = responseData?.error;
      const responseErrorMessage = Array.isArray(responseError)
        ? responseError[0]
        : responseError?.message || responseError;
      const status = err?.response?.status;
      const statusText = err?.response?.statusText;
      const statusMessage = compactJoin([status, statusText], " : ");
      const errorMessage =
        responseErrorMessage || err?.message || statusMessage;
      const error = new Error(errorMessage);
      error.response = responseData;
      error.status = status;
      error.statusText = statusText;
      throw error;
    });

  return data;
}
/**
 * Fetches a stream of data from an api (can be image, video) and
 * store the stream data as a string in redux store
 * @param {string} url API Url
 * @param {object} options API options
 */
export function requestDataStream(url, options = {}, base64conversion = true) {
  const defaultHeaders = {
    deviceid: Session.getDeviceId(),
    "H-Locale": getLocaleOrDefault(),
    "Cache-Control": "no-cache",
    "Content-Type": "application/json;application/octet-stream"
  };
  const headers = options.headers || {};
  const fetchOptions = {
    ...options,
    headers: {
      ...defaultHeaders,
      ...headers
    }
  };

  const data = fetch(url, fetchOptions)
    .then(checkStatus)
    .then(response => {
      return response
        .arrayBuffer()
        .then(buffer => {
          const responseAsString = base64conversion
            ? arrayBufferToBase64(buffer)
            : buffer;
          return { success: true, result: responseAsString };
        })
        .catch(err => {
          const errorMessage = "Failed to fetch image details";
          const error = new Error(errorMessage);
          error.response = err;

          console.error(errorMessage);
          throw error;
        });
    });

  return data;
}

/**
 * POST Request is intercepted to perform a recaptcha.
 * Once the recaptcha is completed the captcha token will be included into POST API body
 *
 * @param {string} url API
 * @param {object} options fetch api options
 */
export function postRequestWithCaptcha(
  url,
  options = { body: null, headers: {} },
  retryCount = 0
) {
  return new Promise(resolve =>
    submitWithRecaptcha(({ recaptchaToken: re_captcha_token }) => {
      if (re_captcha_token) {
        // Older API contract expects captcha value to be present in request body
        const body = JSON.parse(options.body);
        options.body = JSON.stringify({ ...body, re_captcha_token });

        // Newer API contract expects the captcha value to be present in request header
        const captchaHeaders = generateCaptchaHeader(
          options.meta,
          re_captcha_token
        );
        options.headers = { ...options.headers, ...captchaHeaders };

        resolve(request(url, options));
      } else {
        // In an event of recaptcha failure we do a retry
        if (retryCount < MAX_RETRY_ATTEMPTS_FOR_CAPTCHA) {
          const RETRY_INTERVAL = (retryCount + 1) * 1000;
          setTimeout(() => {
            console.debug("Retrying recaptcha. Timestamp: ", new Date());
            resolve(postRequestWithCaptcha(url, options, ++retryCount));
          }, RETRY_INTERVAL);
        } else {
          // Even after max attempts recaptcha isnt recieved, we will throwing an error for the user
          console.error(
            "Retrying recaptcha failed. Terminating retry and requesting user to perform the action again. url: ",
            url
          );
          return resolve({ error: "Something went wrong. Please try again" });
        }
      }
    })
  ).then(data => data);
}

/**
 * Get Request is intercepted to perform a recaptcha.
 * Once the recaptcha is completed the captcha token will be included into API as a url param
 *
 * @param {string} url API
 * @param {object} options fetch api options
 */
export function getRequestWithCaptcha(url, options, retryCount = 0) {
  return new Promise(resolve =>
    submitWithRecaptcha(({ recaptchaToken: re_captcha_token }) => {
      if (re_captcha_token) {
        // Older API contract expects captcha value to be present as query params in GET request
        const urlWithQueryParam = addUrlParam(
          "re_captcha_token",
          re_captcha_token,
          url
        );

        // Newer API contract expects the captcha value to be present in request header
        const captchaHeaders = generateCaptchaHeader(
          options.meta,
          re_captcha_token
        );
        options.headers = { ...options.headers, ...captchaHeaders };

        resolve(request(urlWithQueryParam, options));
      } else {
        if (retryCount < MAX_RETRY_ATTEMPTS_FOR_CAPTCHA) {
          // In an event of recaptcha failure we do a retry
          const RETRY_INTERVAL = (retryCount + 1) * 1000;
          setTimeout(() => {
            console.debug("Retrying recaptcha. Timestamp: ", new Date());
            resolve(getRequestWithCaptcha(url, options, ++retryCount));
          }, RETRY_INTERVAL);
        } else {
          // Even after max attempts recaptcha isnt recieved, we will throwing an error for the user
          console.error(
            "Retrying recaptcha failed. Terminating retry and requesting user to perform the action again"
          );
          return resolve({ error: "Something went wrong. Please try again" });
        }
      }
    })
  ).then(data => data);
}

/**
 * PUT Request is intercepted to perform a recaptcha.
 * Once the recaptcha is completed the captcha token will be included into POST API body
 *
 * @param {string} url API
 * @param {object} options fetch api options
 */
export function putRequestWithCaptcha(
  url,
  options = { body: null },
  retryCount = 0
) {
  return new Promise(resolve =>
    submitWithRecaptcha(({ recaptchaToken: re_captcha_token }) => {
      if (re_captcha_token) {
        const body = JSON.parse(options.body);
        options.body = JSON.stringify({ ...body, re_captcha_token });
        resolve(request(url, options));
      } else {
        // In an event of recaptcha failure we do a retry
        if (retryCount < MAX_RETRY_ATTEMPTS_FOR_CAPTCHA) {
          const RETRY_INTERVAL = (retryCount + 1) * 1000;
          setTimeout(() => {
            console.debug("Retrying recaptcha. Timestamp: ", new Date());
            resolve(putRequestWithCaptcha(url, options, ++retryCount));
          }, RETRY_INTERVAL);
        } else {
          // Even after max attempts recaptcha isnt recieved, we will throwing an error for the user
          console.error(
            "Retrying recaptcha failed. Terminating retry and requesting user to perform the action again"
          );
          return resolve({ error: "Something went wrong. Please try again" });
        }
      }
    })
  ).then(data => data);
}

function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  console.log(bytes);

  bytes.forEach(b => (binary += String.fromCharCode(b)));

  return window.btoa(binary);
}

/**
 * Create the encoded captcha token header object
 * @param {object} metaData action meta data
 * @param {string} recaptchaToken recaptcha token generated for the action
 * @returns encoded captcha details object
 */
function generateCaptchaHeader(metaData, recaptchaToken) {
  const DEFAULT_ACTION = "GENERAL";
  const DEFAULT_VERSION = "v1";

  const action = get(metaData, "captchaAction", DEFAULT_ACTION);
  const version = get(metaData, "captchaVersion", DEFAULT_VERSION);

  const captchaDetailsObj = {
    action,
    version,
    token: recaptchaToken
  };

  return {
    "X-Captcha": btoa(JSON.stringify(captchaDetailsObj))
  };
}
