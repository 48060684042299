import React from "react";
import { Box, Button } from "ui-components";
import FormattedMessage from "components/FormattedMessage";

/**
 * Submit button component
 * Pass in the react form props and config props
 * @param {object} props
 */
const SubmitButton = props => {
  const { label, handleSubmit, onSubmit } = props;

  /**
   * IIFE to figure if button should be disabled
   */
  const disabled = (() => {
    // https://redux-form.com/8.2.2/docs/api/props.md/#-code-pristine-boolean-code-
    const { pristine, invalid } = props;
    return pristine || invalid;
  })();

  /**
   * Wrapper function to perform submit via react forms handleSubmit
   */
  const onClick = handleSubmit(onSubmit);

  return (
    <Box sx={{ textAlign: "center" }}>
      <Button disabled={disabled} px={[64, 128]} onClick={onClick}>
        <FormattedMessage id={label} />
      </Button>
    </Box>
  );
};

export default SubmitButton;
