import { createSelector } from "reselect";
import get from "lodash/get";
import map from "lodash/map";
import head from "lodash/head";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";
import {
  numberFormatSelector,
  localeSelector,
  paymentStatusDetailsSelector,
  loggedInUserDetailsSelector,
  completeOrderParamsSelector
} from "ducks/selectors";
import {
  constructOrderDetails,
  contructTerminationStatusStep,
  contructPortoutStatusStep,
  sortAndFilterOrders
} from "utils/helpers";
import { selectedDeliveryMethodSelector } from "./forms/deliveryInfo";
import { eligibilityActionsStatusSelector } from "./eligibilitySettings";
import compact from "lodash/compact";
import isArray from "lodash/isArray";
import includes from "lodash/includes";
import split from "lodash/split";

const guestOrderAPI = state => get(state.api, "guestOrder", {});
const guestOrderDomain = state => get(state.api, "guestOrder.data", {});
const userOrdersDomain = state => get(state.api, "userOrders.data", {});
const userOrdersErrorDomain = state => get(state, "api.userOrders.error", {});

// ***************************************************************
// ******************* guestOrder selectors **********************
// ***************************************************************

export const userOrdersSelector = createSelector(
  userOrdersDomain,
  sortAndFilterOrders
);

export const userOrderChannelSelector = createSelector(
  userOrdersSelector,
  userOrders => {
    let channel = get(userOrders[0], "channel", false) === "data_only";
    return channel;
  }
);

export const userOrdersErrorsSelector = createSelector(
  userOrdersErrorDomain,
  error => error
);
export const isUserOrdersFailed = createSelector(
  userOrdersErrorsSelector,
  error => !!error
);

export const userOrderRefsSelector = createSelector(
  userOrdersSelector,
  userOrders => map(userOrders, "order_ref")
);

export const guestOrderSelector = createSelector(
  guestOrderDomain,
  userOrdersSelector,
  eligibilityActionsStatusSelector,
  localeSelector,
  (guestOrder, userOrders, eligibilityActionsStatus, locale) => {
    let order = isEmpty(guestOrder) ? head(userOrders) : guestOrder;

    if (!isEmpty(eligibilityActionsStatus) && !isEmpty(order)) {
      const newSteps = compact([
        contructTerminationStatusStep(eligibilityActionsStatus, locale),
        contructPortoutStatusStep(eligibilityActionsStatus, locale)
      ]);
      const allSteps = [...get(order, "steps", []), ...newSteps];
      order = { ...order, steps: uniq(allSteps) };
    }

    return order;
  }
);

export const networkTypeSelector = createSelector(
  guestOrderSelector,
  userOrder => get(userOrder, "network_type")
);

export const isFetchingUserOrders = createSelector(guestOrderAPI, guestOrder =>
  get(guestOrder, "loading", false)
);

export const userOrdersDeliveryDetailsSelector = createSelector(
  guestOrderSelector,
  guestOrder => get(guestOrder, "order_deliver_detail")
);

export const isReInitiateOrReSubmitPortInSelector = createSelector(
  guestOrderSelector,
  guestOrder =>
    get(guestOrder, "next_step.action") === "reinitiate_number_transfer" ||
    get(guestOrder, "next_step.action") === "resubmit_mnp_number"
);

/**
 * Returns true
 *  - if userOrders API is called and it returned an empty array.
 * All other cases false
 */
export const isOrderDetailsEmptySelector = createSelector(
  state => get(state.api, "userOrders.data"),
  userOrders => {
    if (isArray(userOrders) && isEmpty(userOrders)) return true;
    return false;
  }
);

/**
 * Retrurns true if user has requested sim replacement and is in the process of completing kyc
 * False on all other cases
 */
export const isSimReplacementKYCSelector = createSelector(
  guestOrderSelector,
  guestOrder => {
    const nextStep = guestOrder?.next_step?.action;
    return (
      nextStep === "confirm_identity_sim_replace" ||
      nextStep === "confirm_identity_plan_change" ||
      nextStep === "retry_kyc_sim_replace" ||
      nextStep === "retry_kyc_plan_change"
    );
  }
);

// ***************************************************************
// ****************** guestOrder makeSelect **********************
// ***************************************************************

export const makeSelectGuestOrder = () =>
  createSelector(
    guestOrderSelector,
    paymentStatusDetailsSelector,
    numberFormatSelector,
    localeSelector,
    completeOrderParamsSelector(),
    loggedInUserDetailsSelector,
    constructOrderDetails
  );

export const makeSelectGuestOrderOrderRef = () =>
  createSelector(guestOrderSelector, guestOrder =>
    get(guestOrder, "order_ref")
  );

export const makeSelectGuestOrderEsimProfile = () =>
  createSelector(guestOrderSelector, guestOrder =>
    get(guestOrder, "esim_profile")
  );

export const userOrdersDetailsSelector = createSelector(
  userOrdersDeliveryDetailsSelector,
  selectedDeliveryMethodSelector,
  (deliveryDetails, deliveryMethod) => {
    if (isEmpty(deliveryDetails)) return {};

    const {
      zip_code,
      prefecture,
      city,
      address_line_1,
      address_line_2,
      contact_number
    } = deliveryDetails;

    const [prefix, suffix] = includes(zip_code, "-")
      ? split(zip_code, "-")
      : [zip_code?.substring(0, 3), zip_code?.substring(3)];

    return {
      delivery_zip_code: zip_code,
      delivery_state: prefecture,
      delivery_city: city,
      delivery_street_name: address_line_1,
      delivery_hse_blk_tower: address_line_2,
      contact_number: contact_number,
      delivery_method: deliveryMethod,
      delivery_zip_code_part1: prefix,
      delivery_zip_code_part2: suffix
    };
  }
);

export const makeSelectUserOrders = () => userOrdersSelector;
export const makeSelectUserOrderRefs = () => userOrderRefsSelector;
export const makeSelectIsUserOrdersFailed = () => isUserOrdersFailed;
export const makeSelectIsFetchingUserOrders = () => isFetchingUserOrders;
export const makeSelectUserOrderDetailsSelector = () =>
  userOrdersDetailsSelector;
export const makeSelectIsReInitiatePortIn = () =>
  isReInitiateOrReSubmitPortInSelector;
export const makeSelectIsOrderDetailsEmpty = () => isOrderDetailsEmptySelector;
export const makeSelectIsSimReplacementKYC = () => isSimReplacementKYCSelector;
export const makeSelectUserOrderChannel = () => userOrderChannelSelector;
