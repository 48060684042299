import React from "react";
import { Flex, Box } from "ui-components";
import styles from "./styles";

const TimeLineItem = ({ color, children, className, lastStep }) => {
  const style = lastStep
    ? {}
    : {
        backgroundRepeat: "repeat-y",
        backgroundPosition: "center",
        backgroundSize: "2px",
        backgroundImage:
          "repeating-linear-gradient(180deg, #999 0 5px, #999 0 15px)"
      };
  return (
    <Flex data-testid="time-line-item" as="li" sx={styles.wrapperStyles}>
      <Box
        sx={{ minWidth: "auto", width: "auto", padding: "0 1rem", ...style }}
      >
        <Box
          sx={{ ...styles.badgeStyles, backgroundColor: color }}
          className={className}
        ></Box>
      </Box>
      <Box
        sx={{ width: "fit-content", paddingBottom: "2rem", marginTop: "-4px" }}
      >
        <Box>{children}</Box>
      </Box>
    </Flex>
  );
};

export default TimeLineItem;
