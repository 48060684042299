import React, { useEffect, memo } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import {
  changeFormField,
  fetchOrderDetails,
  fetchValidateSignature,
  fetchOutstandingBills,
  payOutstandingBills,
  checkTransactionStatus,
  clearData
} from "ducks/actions";
import {
  makeSelectIsFetchingData,
  makeSelectGuestOrderOrderRef,
  makeSelectOrderDetails,
  makeSelectSessionToken,
  makeSelectValidateSignatureDomain,
  makeSelectOutstandingBillsDomain,
  makeSelectOutstandingBillsForm,
  makeSelectPayOutstandingBillsDomain,
  makeSelectcheckTransactionStatusDomain,
  makeSelectFormattedOutstandingBillsInvoices,
  makeSelectIsOTPVerified,
  makeSelectOutstandingBillTotalAmount,
  makeSelectOutstandingBillToken
} from "ducks/selectors";

import {
  forms,
  ORDER_ACTION_TYPES,
  OUTSTANDING_BILL_REFERRAL_FROM_TYPE,
  paths,
  TRANSACTION_STATUS_TIMEOUT,
  SUCCESS
} from "utils/constants";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import SmartRender from "../../SmartRender";
import { reduxForm, reset } from "redux-form";
import {
  getOutstandingBillReferralType,
  getURLParams,
  gotoUrl
} from "utils/helpers";
import Spinner, { styles } from "components/Spinner";

const OutstandingBillsActionHOC = Component =>
  function Comp(props) {
    const {
      formValues,
      setReferralType,
      setDirectLinkQueryParams,
      outstandingBillsDomain,
      payOutstandingBillsDomain,
      validateSignatureDomain,
      checkTransactionStatusDomain,
      fetchValidateSignature,
      fetchOutstandingBills,
      checkTransactionStatus,
      changeField,
      isFetchingOutstandingBills,
      isFetchingValidateSignature,
      orderRef,
      resetOutstandingBillData,
      outstandingBillToken
    } = props;

    const { cc_update, token } = getURLParams();
    // Update data after credit card update.
    useEffect(() => {
      if (cc_update && outstandingBillToken) {
        const params = {
          token: outstandingBillToken
        };

        fetchOutstandingBills(params);
      }
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      setReferralType(getOutstandingBillReferralType());
      setDirectLinkQueryParams(getURLParams());
    }, [setDirectLinkQueryParams, setReferralType]);

    //get and set outstanding reference number when referral_type changes
    useEffect(() => {
      const referralType = formValues.referral_type;

      switch (referralType) {
        case OUTSTANDING_BILL_REFERRAL_FROM_TYPE.DIRECT_LINK: {
          if (!isFetchingValidateSignature) {
            if (
              !validateSignatureDomain.session_token &&
              !validateSignatureDomain.order_ref
            ) {
              let params = { token: get(formValues, "query_params.token") };
              fetchValidateSignature(params);
            }
            changeField("order_ref", validateSignatureDomain.order_ref);
          }
          break;
        }
        case OUTSTANDING_BILL_REFERRAL_FROM_TYPE.MANAGE_ORDER: {
          changeField("order_ref", orderRef);
          break;
        }
        default:
      }
    }, [
      changeField,
      fetchValidateSignature,
      isFetchingValidateSignature,
      formValues.query_params,
      validateSignatureDomain,
      orderRef,
      formValues.referral_type,
      formValues
    ]);

    useEffect(() => {
      if (!isFetchingValidateSignature) {
        changeField("validate_signature", validateSignatureDomain);
      }
    }, [changeField, isFetchingValidateSignature, validateSignatureDomain]);

    //store the data in form
    useEffect(() => {
      if (!isFetchingOutstandingBills) {
        changeField("outstanding_bills", outstandingBillsDomain);
      }
    }, [changeField, isFetchingOutstandingBills, outstandingBillsDomain]);

    //get outstanding bills when order reference number is available.
    useEffect(() => {
      let params = {
        token: outstandingBillToken
      };
      if (
        !isFetchingOutstandingBills &&
        outstandingBillToken &&
        isEmpty(formValues.outstanding_bills)
      ) {
        fetchOutstandingBills(params);
      }
    }, [
      fetchOutstandingBills,
      formValues.outstanding_bills,
      isFetchingOutstandingBills,
      outstandingBillToken
    ]);

    // listen to payment api and handle routing to payment status page accordingly.
    useEffect(() => {
      let interval;
      if (payOutstandingBillsDomain && payOutstandingBillsDomain.success) {
        const params = { externalId: get(formValues, "order_ref", "") };
        let counter = 0;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        interval = setInterval(() => {
          if (counter++ === TRANSACTION_STATUS_TIMEOUT) {
            gotoUrl(paths.OUTSTANDING_BILLS_PAYMENT_STATUS_IN_PROGRESS);
          }
          checkTransactionStatus(params);
        }, 2000);
      }

      if (checkTransactionStatusDomain.payment_status === SUCCESS) {
        resetOutstandingBillData();
        gotoUrl(paths.OUTSTANDING_BILLS_PAYMENT_STATUS_SUCCESS);
      }

      return () => {
        clearInterval(interval);
      };
    }, [
      checkTransactionStatusDomain,
      payOutstandingBillsDomain,
      payOutstandingBillsDomain.success,
      formValues,
      checkTransactionStatus,
      resetOutstandingBillData
    ]);

    if (token && isEmpty(validateSignatureDomain)) {
      return <Spinner size={200} sx={styles.fullPage} />;
    } else if (isEmpty(outstandingBillsDomain)) {
      return <Spinner size={200} sx={styles.fullPage} />;
    } else {
      return <Component {...props} />;
    }
  };

const mapStateToProps = createStructuredSelector({
  orderRef: makeSelectGuestOrderOrderRef(),
  orderDetails: makeSelectOrderDetails(),
  isFetchingOrderDetails: makeSelectIsFetchingData("orderDetails"),
  token: makeSelectSessionToken,
  isFetchingOutstandingBills: makeSelectIsFetchingData("outstandingBills"),
  isFetchingValidateSignature: makeSelectIsFetchingData("validateSignature"),
  isFetchingPayOutstandingBills: makeSelectIsFetchingData(
    "payOutstandingBills"
  ),
  isFetchingCheckTransactionStatus: makeSelectIsFetchingData(
    "checkTransactionStatus"
  ),
  outstandingBillsDomain: makeSelectOutstandingBillsDomain(),
  validateSignatureDomain: makeSelectValidateSignatureDomain(),
  payOutstandingBillsDomain: makeSelectPayOutstandingBillsDomain(),
  checkTransactionStatusDomain: makeSelectcheckTransactionStatusDomain(),
  formValues: makeSelectOutstandingBillsForm(),
  initialValues: state => makeSelectOutstandingBillsForm()(state),
  invoices: makeSelectFormattedOutstandingBillsInvoices(),
  styles: () => styles,
  isOtpVerified: makeSelectIsOTPVerified(),
  totalBillAmount: makeSelectOutstandingBillTotalAmount(),
  outstandingBillToken: makeSelectOutstandingBillToken()
});

export const mapDispatchToProps = dispatch => {
  return {
    fetchOrderDetails: orderRef => {
      const params = {
        type: ORDER_ACTION_TYPES.OUTSTANDING_BILLS,
        order_ref: orderRef
      };

      dispatch(fetchOrderDetails(params));
    },
    changeField: (field, value) => {
      dispatch(
        changeFormField({
          form: forms.OUTSTANDING_BILLS_FORM,
          field: field,
          value: value
        })
      );
    },
    setReferralType: value => {
      dispatch(
        changeFormField({
          form: forms.OUTSTANDING_BILLS_FORM,
          field: "referral_type",
          value: value
        })
      );
    },
    setDirectLinkQueryParams: value => {
      dispatch(
        changeFormField({
          form: forms.OUTSTANDING_BILLS_FORM,
          field: "query_params",
          value: value
        })
      );
    },
    fetchValidateSignature: params => {
      dispatch(
        fetchValidateSignature(params, (result, error) => {
          if (!isEmpty(error)) {
            gotoUrl(paths.DIRECT_LINK_EXPIRED);
          }
        })
      );
    },
    fetchOutstandingBills: params => {
      dispatch(fetchOutstandingBills(params));
    },
    payOutstandingBills: params => {
      dispatch(
        payOutstandingBills(params, (result, error) => {
          if (!isEmpty(error)) {
            gotoUrl(
              paths.OUTSTANDING_BILLS_PAYMENT_STATUS_FAILURE,
              true,
              {},
              false
            );
          }
        })
      );
    },
    checkTransactionStatus: params => {
      dispatch(checkTransactionStatus(params));
    },
    resetOutstandingBillData: () => {
      dispatch(reset(forms.OUTSTANDING_BILLS_FORM));
      dispatch(
        clearData([
          "payOutstandingBills",
          "checkTransactionStatus",
          "outstandingBills"
        ])
      );
    }
  };
};

OutstandingBillsActionHOC.defaultProps = {
  fetchOrderDetails: () => {},
  payOutstandingBills: () => {},
  checkTransactionStatus: () => {}
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  reduxForm({
    form: forms.OUTSTANDING_BILLS_FORM,
    destroyOnUnmount: false
  }),
  memo
)(OutstandingBillsActionHOC(SmartRender));
