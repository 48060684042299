import React, { memo, useEffect, useState } from "react";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import {
  clearData,
  fetchPaymentRequest,
  uploadSignature,
  confirmPaymentOption,
  fetchUpfrontPaymentDetails,
  processUpfrontPayment,
  fetchPaymentStatus,
  updateOrder,
  fetchUserOrders,
  Laas,
  selectPlan
} from "ducks/actions";
import {
  makeSelectIsFetchingData,
  makeSelectOrder,
  makeSelectOrderParams,
  makeSelectPayMonthlyItems,
  makeSelectPayTodayItems,
  makeSelectDeliveryDetailsItems,
  makeSelectReferralCode,
  makeSelectPaymentRequest,
  makeSelectOrderSummaryForm,
  makeSelectOrderSummaryFormInitialValues,
  makeSelectOrderSummaryPayload,
  makeSelectESigned,
  makeSelectPaymentModes,
  makeSelectFormattedSelectedNumber,
  makeSelectAddons,
  makeSelectDefaultPlanAddons,
  makeSelectPlanDetails,
  makeSelectDefaultPromoCodeOffers,
  makeSelectSessionToken,
  makeSelectPayDevice,
  makeSelectWorkflow,
  makeSelectOrderRef,
  makeSelectPersonalDetailsForm,
  makeSelectPaymentDetails,
  makeSelectPlanName,
  makeSelectCompleteOrderParams,
  makeSelectIsPortingUser,
  makeSelectUserDetailsOrderUpdatePayload,
  makeSelectIsPortingUserWithPortingNumber,
  makeSelectConnectionDetailsForm,
  makeSelectIsSignUpWithIPDB,
  makeSelectHasUnprocessableEntityError,
  makeSelectDeliveryDetailsSelector,
  makeSelectFetchDeliverySlotAPIParams,
  makeSelectEligiblePlans,
  makeSelectGA4PlanDetails,
  makeSelectEligiblePlanRequestChannel
} from "ducks/selectors";
import SmartRender from "containers/SmartRender";
import {
  paths,
  forms,
  AU_TO_POVO_CHANNEL,
  DEFAULT_CHANNEL
} from "utils/constants";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import get from "lodash/get";
import find from "lodash/find";
import Spinner, { styles } from "components/Spinner";
import usePaymentResponseHandler from "hooks/usePaymentResponseHandler";
import { SIM_TYPES, CONFLICTING_ORDERS_STATUS } from "utils/constants";
import {
  deliverySlotParams,
  getDeliveryDates,
  gotoUrl,
  pathWithChannel,
  sortAndFilterOrders
} from "utils/helpers";
import { OMS, Rewards } from "ducks/actions";
import head from "lodash/head";
import {
  featureFlag5GSAEnabled,
  featureFlagMergeStepEnabled
} from "utils/featureFlags";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";

const OrderSummaryHOC = Component =>
  function Comp(props) {
    const {
      order,
      orderParams,
      clearOrder,
      selectPlan,
      planDetails,
      eligiblePlans,
      createInitialOrder,
      clearPaymentRequest,
      clearESignature,
      referralCode,
      isCreatingOrder,
      isPortingUserWithPortingNumber,
      clearMyInfoDomain,
      clearPaymentDetails
    } = props;
    /**
     * Initial order creation is enabled via configurations.
     * Some countries (eg: JP) do not initial order creation until consent is provided
     */
    const workflow = useSelector(makeSelectWorkflow());
    const currentWorkflowPage = find(
      workflow,
      page => page.path === window.location.pathname
    );
    const shouldCreateInitialOrder = get(
      currentWorkflowPage,
      "createInitialOrder",
      true
    );

    const [lastPromoCode, setLastPromoCode] = useState(
      get(referralCode, "value")
    );
    const [promoFlag, setPromoCode] = useState(false);

    useEffect(
      () => {
        // !isPortingUserWithPortingNumber is for; if user wants to port in number from users account, initial order has been already created in the
        // user-details page. So we shouldn't clear the order here.
        if (
          get(order, "channel") !== SIM_TYPES.ESIM &&
          !isPortingUserWithPortingNumber
        ) {
          clearOrder();
          clearPaymentRequest();
          clearPaymentDetails();
          clearESignature();
          clearMyInfoDomain();
          if (shouldCreateInitialOrder) createInitialOrder(orderParams);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [shouldCreateInitialOrder, lastPromoCode]
    );

    useEffect(() => {
      if (
        (featureFlag5GSAEnabled() || featureFlagMergeStepEnabled()) &&
        isEmpty(planDetails)
      ) {
        const planDetails = head(eligiblePlans);
        selectPlan(planDetails);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }
    }, [eligiblePlans, planDetails, selectPlan]);

    useEffect(
      () => {
        if (!isEmpty(order.error) && !isPortingUserWithPortingNumber) {
          clearOrder();
          clearMyInfoDomain();
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [order.error]
    );

    useEffect(
      () => {
        const promoCode = get(referralCode, "value");
        promoCode && setPromoCode(true);
        if (promoCode !== lastPromoCode) {
          setLastPromoCode(promoCode);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [referralCode, lastPromoCode, setLastPromoCode]
    );

    return isCreatingOrder && !promoFlag ? (
      <Spinner size={200} sx={styles.fullPage} />
    ) : (
      <Component {...props} />
    );
  };

const mapStateToProps = createStructuredSelector({
  personalDetailFormsValues: makeSelectPersonalDetailsForm(),
  formValues: makeSelectOrderSummaryForm(),
  payload: makeSelectOrderSummaryPayload(),
  order: makeSelectOrder(),
  paymentRequest: makeSelectPaymentRequest(),
  orderParams: makeSelectOrderParams(),
  isCreatingOrder: makeSelectIsFetchingData("order"),
  isFetchingPaymentRequest: makeSelectIsFetchingData("paymentRequest"),
  payMonthlyItems: makeSelectPayMonthlyItems(),
  payTodayItems: makeSelectPayTodayItems("OrderSummary"),
  deliveryDetailsItems: makeSelectDeliveryDetailsItems(),
  referralCode: makeSelectReferralCode(),
  eSigned: makeSelectESigned(),
  paymentModes: makeSelectPaymentModes(),
  initialValues: makeSelectOrderSummaryFormInitialValues(),
  selectedNumber: makeSelectFormattedSelectedNumber(),
  addons: makeSelectAddons(),
  defaultPlanAddons: makeSelectDefaultPlanAddons(),
  planDetails: makeSelectPlanDetails(),
  token: makeSelectSessionToken(),
  defaultPromoCodeOffers: makeSelectDefaultPromoCodeOffers(),
  selectedPhone: makeSelectPayDevice(),
  orderRef: makeSelectOrderRef(),
  planName: makeSelectPlanName(),
  isFetchingPaymentDetails: makeSelectIsFetchingData("paymentDetails"),
  paymentDetails: makeSelectPaymentDetails(),
  completeOrderParams: makeSelectCompleteOrderParams(),
  isPortingUser: makeSelectIsPortingUser(),
  orderUpdatePayload: makeSelectUserDetailsOrderUpdatePayload(),
  isPortingUserWithPortingNumber: makeSelectIsPortingUserWithPortingNumber(),
  connectionDetails: makeSelectConnectionDetailsForm(),
  isSignUpWithAuIPDB: makeSelectIsSignUpWithIPDB(),
  deliverySlotsAPiParams: makeSelectFetchDeliverySlotAPIParams(),
  deliveryDetails: makeSelectDeliveryDetailsSelector(),
  hasUnprocessableEntityError: makeSelectHasUnprocessableEntityError(),
  eligiblePlans: makeSelectEligiblePlans(),
  GA4EventPlanDetails: makeSelectGA4PlanDetails(),
  storedEligiblePlanChannel: makeSelectEligiblePlanRequestChannel()
});

export function mapDispatchToProps(dispatch) {
  return {
    clearOrder: () => {
      dispatch(clearData("order"));
    },
    clearPaymentRequest: () => {
      dispatch(clearData("paymentRequest"));
    },
    clearESignature: () => {
      dispatch(clearData("eSignature"));
    },
    clearMyInfoDomain: () => {
      dispatch(clearData("myInfoDetails"));
    },
    createInitialOrder: (orderParams, callback) => {
      if (!isEmpty(orderParams)) {
        dispatch(OMS.V4.createInitialOrder(orderParams, callback));
      }
    },
    fetchPaymentRequest: params => {
      dispatch(fetchPaymentRequest(params));
    },
    confirmPaymentOption: (orderRef, cb) => {
      dispatch(confirmPaymentOption({ order_ref: orderRef }, cb));
    },
    validateReferralCode: (data, callback) => {
      dispatch(Rewards.V1.fetchReferralCode(data, callback));
    },
    resetReferralCode: () => dispatch(clearData("referralCode")),
    uploadSignature: payload => dispatch(uploadSignature(payload)),
    onSubmit: (_, payload) => {
      dispatch(OMS.V4.postPaymentRequest(payload, usePaymentResponseHandler));
    },
    fetchUpfrontPaymentDetails: (params, callback) => {
      dispatch(fetchUpfrontPaymentDetails(params, callback));
    },
    processUpfrontPayment: (params, callback) => {
      dispatch(processUpfrontPayment(params, callback));
    },
    fetchPaymentStatus: (params, callback) => {
      dispatch(fetchPaymentStatus(params, callback));
    },
    updateOrder: (payload, callback) =>
      dispatch(updateOrder(payload, callback)),
    clearPaymentDetails: () => {
      dispatch(clearData("paymentDetails"));
    },
    fetchUserOrdersAndNavigateToManageOrder: () => {
      dispatch(
        fetchUserOrders({}, orders => {
          const filteredOrders = sortAndFilterOrders(orders);
          if (!isEmpty(filteredOrders)) {
            gotoUrl(paths.MANAGE_ORDER, true, {
              reset: null,
              iccid: null
            });
          }
        })
      );
    },
    fetchDeliverySlots: (params, callback) => {
      dispatch(Laas.V1.fetchDeliverySlots(params, callback));
    },
    navigatePlanChangeSimSelection: () => {
      gotoUrl(pathWithChannel(paths.PLAN_CHANGE_SIM_SELECTION, "manage"));
    },
    selectPlan: plan => dispatch(selectPlan(plan)),
    fetchEligiblePlans: (params, callBack) => {
      dispatch(OMS.V4.fetchEligiblePlans(params, callBack));
    }
  };
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  createInitialOrderAndNavigate: navigate => {
    const { orderParams, storedEligiblePlanChannel } = stateProps;
    const createOrder = () => {
      dispatchProps.createInitialOrder(orderParams, (_, error) => {
        const { status } = error || {};
        if (isEmpty(error)) {
          isFunction(navigate) && navigate();
        } else if (status === CONFLICTING_ORDERS_STATUS) {
          if (stateProps.isSignUpWithAuIPDB)
            console.log("409 while creating order");
          else dispatchProps.fetchUserOrdersAndNavigateToManageOrder();
        }
      });
    };

    if (
      ![AU_TO_POVO_CHANNEL, DEFAULT_CHANNEL].includes(storedEligiblePlanChannel)
    ) {
      const planParams = { channel: DEFAULT_CHANNEL };
      dispatchProps.fetchEligiblePlans(planParams, (response, error) => {
        if (isEmpty(error)) {
          const selectedPlan = head(response);
          dispatchProps.selectPlan(selectedPlan);
          orderParams.selected_planid = selectedPlan?.id;
          createOrder();
        }
      });
    } else {
      createOrder();
    }
  },
  createInitialOrderAndSubmit: (navigate, payload) => {
    dispatchProps.createInitialOrder(stateProps.orderParams, (_, error) => {
      if (isEmpty(error)) {
        dispatchProps.onSubmit(navigate, payload);
      }
    });
  },
  // Update order when user in the account porting flow
  updateOrderAndNavigate: navigate => {
    const simType =
      stateProps?.order?.simType || stateProps?.connectionDetails?.sim_type;
    const orderUpdate = orderUpdatePayload => {
      dispatchProps.updateOrder(orderUpdatePayload, (_, error) => {
        if (isEmpty(error)) {
          navigate();
        }
      });
    };
    const { delivery_slot } = stateProps.orderUpdatePayload;
    if (
      simType === SIM_TYPES.PHYSICAL_SIM &&
      (isEmpty(delivery_slot) || stateProps.hasUnprocessableEntityError)
    ) {
      dispatchProps.fetchDeliverySlots(
        stateProps.deliverySlotsAPiParams,
        result => {
          const slots = getDeliveryDates(result, stateProps.deliveryDetails);
          const userDetailsFormValue = {
            ...stateProps.formValues,
            delivery_slot_full_data: head(slots)?.value || "",
            delivery_slot_fee: head(slots)?.cost || 0,
            delivery_slot_id: head(slots)?.slotId || 0,
            delivery_slot: head(slots)?.deliverySlot || ""
          };
          const deliverySlot = deliverySlotParams(userDetailsFormValue);
          const orderParams = {
            ...stateProps.orderUpdatePayload,
            ...deliverySlot
          };
          orderUpdate(orderParams);
        }
      );
    } else {
      orderUpdate(stateProps.orderUpdatePayload);
    }
  },
  validateReferralCode: (referral_code, plan_name, device_sku = null) => {
    const data = {
      referral_code,
      plan_name,
      ...(device_sku && { device_sku: true })
    };
    dispatchProps.validateReferralCode(data, (data, errors) => {
      if (!errors) {
        GA4EventsService.publish(
          promocodeEventData({
            ...stateProps.GA4EventPlanDetails,
            coupon: referral_code,
            coupon_type: data.code_type
          })
        );
      }
    });
  }
});

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.ORDER_SUMMARY_FORM,
    destroyOnUnmount: false
  }),
  memo
)(OrderSummaryHOC(SmartRender));

const promocodeEventData = (details = {}) => {
  const {
    customer_id,
    plan_name,
    plan_type,
    coupon,
    coupon_type,
    sim_type,
    number_type,
    journey_type
  } = details;

  return {
    event: GA4EVENTS.PROMOCODE_APPLIED,
    ecommerce: {
      order_ref: "",
      plan_name,
      customer_id,
      journey_type,
      plan_type,
      sim_type,
      number_type,
      coupon,
      coupon_type
    }
  };
};
