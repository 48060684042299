export const Adjust = {
  onCreate: function(adjustConfig) {
    if (adjustConfig && !adjustConfig.getSdkPrefix()) {
      adjustConfig.setSdkPrefix(this.getSdkPrefix());
    }
    this.adjustConfig = adjustConfig;
    if (window.AdjustBridge) {
      window.AdjustBridge.onCreate(JSON.stringify(adjustConfig));
    }
  },

  getConfig: function() {
    return this.adjustConfig;
  },

  trackEvent: function(adjustEvent) {
    if (window.AdjustBridge) {
      window.AdjustBridge.trackEvent(JSON.stringify(adjustEvent));
    }
  },

  trackAdRevenue: function(source, payload) {
    if (window.AdjustBridge) {
      window.AdjustBridge.trackAdRevenue(source, payload);
    }
  },

  onResume: function() {
    if (window.AdjustBridge) {
      window.AdjustBridge.onResume();
    }
  },

  onPause: function() {
    if (window.AdjustBridge) {
      window.AdjustBridge.onPause();
    }
  },

  setEnabled: function(enabled) {
    if (window.AdjustBridge) {
      window.AdjustBridge.setEnabled(enabled);
    }
  },

  isEnabled: function(callback) {
    if (!window.AdjustBridge) {
      return undefined;
    }
    // supports legacy return with callback
    if (arguments.length === 1) {
      // with manual string call
      if (typeof callback === "string" || callback instanceof String) {
        this.isEnabledCallbackName = callback;
      } else {
        // or save callback and call later
        this.isEnabledCallbackName = "Adjust.adjust_isEnabledCallback";
        this.isEnabledCallbackFunction = callback;
      }
      window.AdjustBridge.isEnabled(this.isEnabledCallbackName);
    } else {
      return window.AdjustBridge.isEnabled();
    }
  },

  adjust_isEnabledCallback: function(isEnabled) {
    if (window.AdjustBridge && this.isEnabledCallbackFunction) {
      this.isEnabledCallbackFunction(isEnabled);
    }
  },

  appWillOpenUrl: function(url) {
    if (window.AdjustBridge) {
      window.AdjustBridge.appWillOpenUrl(url);
    }
  },

  setReferrer: function(referrer) {
    if (window.AdjustBridge) {
      window.AdjustBridge.setReferrer(referrer);
    }
  },

  setOfflineMode: function(isOffline) {
    if (window.AdjustBridge) {
      window.AdjustBridge.setOfflineMode(isOffline);
    }
  },

  sendFirstPackages: function() {
    if (window.AdjustBridge) {
      window.AdjustBridge.sendFirstPackages();
    }
  },

  addSessionCallbackParameter: function(key, value) {
    if (window.AdjustBridge) {
      window.AdjustBridge.addSessionCallbackParameter(key, value);
    }
  },

  addSessionPartnerParameter: function(key, value) {
    if (window.AdjustBridge) {
      window.AdjustBridge.addSessionPartnerParameter(key, value);
    }
  },

  removeSessionCallbackParameter: function(key) {
    if (window.AdjustBridge) {
      window.AdjustBridge.removeSessionCallbackParameter(key);
    }
  },

  removeSessionPartnerParameter: function(key) {
    if (window.AdjustBridge) {
      window.AdjustBridge.removeSessionPartnerParameter(key);
    }
  },

  resetSessionCallbackParameters: function() {
    if (window.AdjustBridge) {
      window.AdjustBridge.resetSessionCallbackParameters();
    }
  },

  resetSessionPartnerParameters: function() {
    if (window.AdjustBridge) {
      window.AdjustBridge.resetSessionPartnerParameters();
    }
  },

  setPushToken: function(token) {
    if (window.AdjustBridge) {
      window.AdjustBridge.setPushToken(token);
    }
  },

  gdprForgetMe: function() {
    if (window.AdjustBridge) {
      window.AdjustBridge.gdprForgetMe();
    }
  },

  disableThirdPartySharing: function() {
    if (window.AdjustBridge) {
      window.AdjustBridge.disableThirdPartySharing();
    }
  },

  trackThirdPartySharing: function(adjustThirdPartySharing) {
    if (window.AdjustBridge) {
      window.AdjustBridge.trackThirdPartySharing(
        JSON.stringify(adjustThirdPartySharing)
      );
    }
  },

  trackMeasurementConsent: function(consentMeasurement) {
    if (window.AdjustBridge) {
      window.AdjustBridge.trackMeasurementConsent(consentMeasurement);
    }
  },

  getGoogleAdId: function(callback) {
    if (window.AdjustBridge) {
      if (typeof callback === "string" || callback instanceof String) {
        this.getGoogleAdIdCallbackName = callback;
      } else {
        this.getGoogleAdIdCallbackName = "Adjust.adjust_getGoogleAdIdCallback";
        this.getGoogleAdIdCallbackFunction = callback;
      }
      window.AdjustBridge.getGoogleAdId(this.getGoogleAdIdCallbackName);
    }
  },

  adjust_getGoogleAdIdCallback: function(googleAdId) {
    if (window.AdjustBridge && this.getGoogleAdIdCallbackFunction) {
      this.getGoogleAdIdCallbackFunction(googleAdId);
    }
  },

  getAmazonAdId: function(callback) {
    if (window.AdjustBridge) {
      return window.AdjustBridge.getAmazonAdId();
    } else {
      return undefined;
    }
  },

  getAdid: function() {
    if (window.AdjustBridge) {
      return window.AdjustBridge.getAdid();
    } else {
      return undefined;
    }
  },

  getAttribution: function(callback) {
    if (window.AdjustBridge) {
      window.AdjustBridge.getAttribution(callback);
    }
  },

  getSdkVersion: function() {
    if (window.AdjustBridge) {
      return this.getSdkPrefix() + "@" + window.AdjustBridge.getSdkVersion();
    } else {
      return undefined;
    }
  },

  getSdkPrefix: function() {
    if (this.adjustConfig) {
      return this.adjustConfig.getSdkPrefix();
    } else {
      return "web-bridge4.30.0";
    }
  },

  teardown: function() {
    if (window.AdjustBridge) {
      window.AdjustBridge.teardown();
    }
    this.adjustConfig = undefined;
    this.isEnabledCallbackName = undefined;
    this.isEnabledCallbackFunction = undefined;
    this.getGoogleAdIdCallbackName = undefined;
    this.getGoogleAdIdCallbackFunction = undefined;
  }
};
