import React from "react";
import DropdownField from "components/FormFields/DropdownField";

const SUSPENSION_START_DATE = props => {
  const { change, suspensionStartDates } = props;

  return (
    <DropdownField
      options={suspensionStartDates}
      {...props}
      onChange={() => {
        change("suspension_end_date", undefined);
      }}
    />
  );
};

export default SUSPENSION_START_DATE;
