import { createSelector } from "reselect";
import get from "lodash/get";

const paymentDomain = state => get(state, "form.PaymentForm.values", {});

// ***************************************************************
// ************** PaymentForm selectors ******************
// ***************************************************************

export const paymentFormSelector = createSelector(
  paymentDomain,
  payment => payment
);

// ***************************************************************
// ************** PaymentForm makeSelect *****************
// ***************************************************************

export const makeSelectPaymentForm = () => paymentFormSelector;
