import {
  OMS_URL,
  OMS_VERSION_PATH,
  OMS_LOG_MESSAGE,
  USE_API_GATEWAY,
  API_GATEWAY_URL,
  API_GATEWAY_VERSION_PATH,
  API_GATEWAY_LOG_MESSAGE,
  DEFAULT_API_VERSION,
  BASE,
  COUNTRY,
  IS_PATHS_SECURED
} from "utils/constants";
import request from "utils/request";
import replace from "lodash/replace";
import trim from "lodash/trim";
import { isDesktop, getChannelFromUrl } from "utils/helpers";
import Session from "session";
import {
  ANALYTICS_EVENT_STEPS,
  ANALYTICS_EVENT_TYPES,
  FLOW_EXCLUDE_MAPPING
} from "../constants/AnalyticsTypeConstants";
import { getLocaleOrDefault } from "utils/localStorage";

const PRIMARY_CATEGORY = "checkout";
const PAGE_TYPE = "shop";
const LOG_INTERVAL = 2000;
const MESSAGE_LIMIT = 200;
const SATELLITE_TIMEOUT = 1000;

class Analytics {
  messages = [];
  constructor() {
    setInterval(() => {
      this.postMessages();
    }, LOG_INTERVAL);
  }

  logMessage(message) {
    message && this.messages.push(message);
  }

  postMessage(message) {
    let requestURL = USE_API_GATEWAY
      ? `${API_GATEWAY_URL}${API_GATEWAY_VERSION_PATH}${API_GATEWAY_LOG_MESSAGE}`
      : `${OMS_URL}${OMS_VERSION_PATH}${OMS_LOG_MESSAGE}`;
    requestURL = requestURL.replace("{LOCALE}", getLocaleOrDefault());
    const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
    requestURL = requestURL.replace("{VERSION}", DEFAULT_API_VERSION[base]);
    const body = JSON.stringify({ message });

    const options = {
      method: "POST",
      body
    };

    request(requestURL, options).then(
      () => console.log("Logged message"),
      err => console.log("error logging message", err)
    );
  }

  postMessages() {
    const startLength = this.messages.length;
    const messageData = [];
    while (this.messages.length) {
      messageData.push(this.messages.shift());
      if (messageData.length === MESSAGE_LIMIT) break;
    }
    if (messageData.length && !IS_PATHS_SECURED) {
      // stop posting messages for secure app
      console.log("Start Posting Log Messages ", startLength);
      this.postMessage(messageData);
      console.log("Finish Posting Log Messages ", this.messages.length);
    }
  }

  logEventStart(type, event, info, data) {
    this.logMessage({
      step: ANALYTICS_EVENT_STEPS.START,
      customerID: Session.getDeviceId(),
      ipAddress: Session.getIpAddress(),
      url: this.getURL(),
      type,
      event,
      info,
      data
    });
  }

  logEventFinish(type, event, info, data) {
    this.logMessage({
      step: ANALYTICS_EVENT_STEPS.FINISH,
      customerID: Session.getDeviceId(),
      ipAddress: Session.getIpAddress(),
      url: this.getURL(),
      type,
      event,
      info,
      data
    });
  }

  trackPage(type, locationObj) {
    if (window.digitalData) {
      this.logEventStart(type, ANALYTICS_EVENT_TYPES.TRACK_PAGE);
      window.digitalData.page = this.getPageObj(locationObj);
      this.logEventFinish(type, ANALYTICS_EVENT_TYPES.TRACK_PAGE);
    }
  }

  trackEvent(type, event, data) {
    if (window._satellite) {
      this.trackAdobeSatelliteEvent(type, event, data);
    } else {
      window.setTimeout(() => {
        this.trackEvent(type, event, data);
      }, SATELLITE_TIMEOUT);
    }
  }

  trackAdobeSatelliteEvent(type, event, data) {
    if (window._satellite) {
      this.logEventStart(type, event, "SatelliteTrack", data);
      window._satellite.track(event, data);
      console.log(`Pushed trackAdobeSatelliteEvent ${event}`, data);
      this.logEventFinish(type, event, "SatelliteTrack", data);
    }
  }

  getPageObj(locationObj) {
    return {
      pageInfo: {
        pageName: this.getAdobePageName(locationObj),
        site: this.getSiteName(),
        sysEnv: isDesktop() ? "desktop" : "mobile",
        pageLoad: "true",
        customerID: Session.getDeviceId()
        // ipAddress: Session.getIpAddress()
      },
      category: {
        primaryCategory: this.getPrimaryCategory(),
        subCategory1: this.getSubCategory(locationObj),
        subCategory2: "",
        subCategory3: "",
        pageType: this.getPageType()
      }
    };
  }

  getAdobePageName(locationObj) {
    const path = this.getPathName(locationObj);
    return `page:${this.sanitizePath(path)}`;
  }

  getSiteName() {
    return window.location.hostname;
  }

  getPrimaryCategory = () => {
    return PRIMARY_CATEGORY;
  };

  getSubCategory = locationObj => {
    const path = this.getPathName(locationObj);
    return this.sanitizePath(path);
  };

  getPageType() {
    return PAGE_TYPE;
  }

  getPathName = locationObj => {
    return locationObj ? locationObj.pathname : window.location.pathname;
  };

  getURL = () => this.sanitizeURL(window.location.href);

  sanitizePath = path => {
    const sanitizePath = replace(path, /[^0-9a-zA-Z *]/g, " ").replace(
      /[0-9]+/g,
      ""
    );
    return trim(sanitizePath);
  };

  sanitizeURL = path => {
    const sanitizePath = replace(path, /[^0-9a-zA-Z *&?\-=_:/]/g, " ");
    return trim(sanitizePath);
  };

  // check which flow to excluded from analytics
  isFlowExcluded = data => {
    let isExclude = false;
    // get the country flow to be excluded.
    const excludeMapping = FLOW_EXCLUDE_MAPPING[COUNTRY];
    for (const channel in excludeMapping) {
      const currChannel = getChannelFromUrl();
      if (channel === currChannel) {
        const excludeParams = excludeMapping[channel];
        for (const paramKey in excludeParams) {
          if (paramKey === "url") {
            const urls = excludeParams[paramKey];
            if (window.location.pathname.includes(urls)) {
              isExclude = true;
              break;
            }
          }
          // else if (paramKey === "plan_name") {
          //   const planNames = excludeParams[paramKey]
          //   const planName = data[paramKey]
          //   if (planNames.includes(planName)) {
          //     isExclude = true
          //     break;
          //   }
          // }
        }
      }
    }
    return isExclude;
  };
}

export default Analytics;
