import React from "react";
import TextField from "components/FormFields/TextField";
import Label from "components/FormFields/Label";
import FormattedMessage from "components/FormattedMessage";
import Tooltip from "components/Tooltip";
import get from "lodash/get";
import isFunction from "lodash/isFunction";
import { safeEval } from "utils/helpers";

const PortInTelcoAccountNumber = ({ data, normalize, label, ...props }) => {
  const accountNumberMaxLength = get(
    props,
    "formValues.telco.accountNumberLength.max"
  );
  const normalizeAccountNumber = value => {
    const allowedValue =
      value && accountNumberMaxLength
        ? value.substring(0, accountNumberMaxLength)
        : value;
    return isFunction(normalize) ? normalize(allowedValue) : allowedValue;
  };

  const {
    label: tooltipLabel,
    message: tooltipMessage,
    image: tooltipImage
  } = get(data, "tooltip", {});

  const tooltipImageUrl = safeEval(
    get,
    props.formValues,
    tooltipImage,
    undefined,
    props
  );

  const tooltipProps = {
    tooltipLabel,
    tooltipMessage,
    tooltipImageUrl,
    tooltipId: "telcoAccountNumber",
    position: "top",
    mode: "light"
  };

  return (
    <>
      <Label
        label={<FormattedMessage id={label} />}
        required={props.required}
      />
      {tooltipLabel && <Tooltip {...tooltipProps} />}
      <TextField
        {...props}
        normalize={normalizeAccountNumber}
        inputStyle={{ fontSize: "1.2rem" }}
      />
    </>
  );
};

PortInTelcoAccountNumber.defaultProps = {
  label: ""
};

export default PortInTelcoAccountNumber;
