import React from "react";
import get from "lodash/get";
import DeliveryAddressForm1 from "components/DeliveryAddress/Form1";

const DELIVERY_ADDRESS_FORM_1 = ({ formValues, fieldPrefix = "delivery" }) => {
  const selectedDeliveryMethod = get(formValues, "delivery_method", null);
  if (!selectedDeliveryMethod) return null;
  const fields = get(selectedDeliveryMethod, "fields");
  return (
    <DeliveryAddressForm1
      fields={fields}
      fieldPrefix={fieldPrefix}
      formValues={formValues}
    />
  );
};

export default DELIVERY_ADDRESS_FORM_1;
