import {
  convivaAppTracker,
  trackPageView,
  setUserId
} from "@convivainc/conviva-js-appanalytics";
import { PerformanceTimingPlugin } from "@convivainc/conviva-js-appanalytics-performance-timing";
import {
  ErrorTrackingPlugin,
  enableErrorTracking
} from "@convivainc/conviva-js-appanalytics-error-tracking";
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
  enableButtonClickTracking
} from "@convivainc/conviva-js-appanalytics-click-tracking";
import { featureFlagEnableConviva } from "utils/featureFlags";

function enableConvivaAppTracker() {
  convivaAppTracker({
    appId: "povo-web", // set this to your understandable application name
    convivaCustomerKey: "0940eeda9890029fd339c1b9705837cecae3ba89", //customer key for c3.CirclesLife-Production Conviva account.
    appVersion: "1.1.0", //set app version in string format
    contexts: {
      performanceTiming: true
    },

    plugins: [
      PerformanceTimingPlugin(),
      ErrorTrackingPlugin(),
      LinkClickTrackingPlugin()
    ]
  });
}

function enableTracking() {
  trackPageView(); // default page title is document.title
  enableLinkClickTracking(); // Tracks all link clicks on the page
  enableButtonClickTracking();
  enableErrorTracking();
}

export default function initConviva() {
  const convivaDebugMode = localStorage.getItem("conviva");
  if (featureFlagEnableConviva()) {
    try {
      enableConvivaAppTracker();
      enableTracking();
      // initial user Id setting until user logs in
      setUserId("GUEST");
    } catch (error) {
      console.error("failing initializing conviva. ", error);
    }
  } else if (convivaDebugMode) {
    window.pcv = {};
    window.pcv.ecat = enableConvivaAppTracker;
    window.pcv.eat = enableTracking;

    try {
      enableConvivaAppTracker();
      enableTracking();
      // initial user Id setting until user logs in
      setUserId("GUEST");
    } catch (error) {
      console.error("failing initializing conviva. ", error);
    }
  }
}
