import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Box, Button, Text } from "ui-components";
import PageHeader from "components/PageHeader";
import { gotoUrl } from "utils/helpers";

const ScheduleDeliveryStatus = props => {
  const { orderType, deliveryType, deliveryStatus, label, url } = props;
  const scheduleDeliveryPath =
    orderType &&
    deliveryType &&
    deliveryStatus &&
    `schedule_delivery_${orderType}_${deliveryType}_${deliveryStatus}`;
  const pageHeaderKey = scheduleDeliveryPath;
  const statusMessageKey =
    scheduleDeliveryPath && `${scheduleDeliveryPath}_message`;

  return (
    <Box
      textAlign="left"
      variant="main"
      padding="2rem"
      data-testid="schedule-delivery-success"
    >
      {pageHeaderKey && <PageHeader title={pageHeaderKey} />}
      {statusMessageKey && (
        <Text>
          <FormattedMessage html id={statusMessageKey} values={props} />
        </Text>
      )}
      <Box textAlign="center" paddingTop="2rem">
        <Button
          data-testid="schedule-delivery-button"
          onClick={() => gotoUrl(url, false, {}, true)}
        >
          {label}
        </Button>
      </Box>
    </Box>
  );
};

ScheduleDeliveryStatus.defaultProps = {
  label: "Go to Manage Order",
  url: "/manage/order"
};

export default ScheduleDeliveryStatus;
