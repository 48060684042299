import { createSelector } from "reselect";
import get from "lodash/get";

const walletDomain = state => get(state.api, "wallet.data", {});

export const walletSelector = createSelector(walletDomain, wallet => {
  return wallet;
});

export const walletDevicePointsSelector = createSelector(
  walletDomain,
  wallet => {
    return get(wallet, "wallet.PVD", 0) / 100;
  }
);

export const makeSelectWallet = () => walletSelector;

export const makeSelectWalletDevicePoints = () => walletDevicePointsSelector;
