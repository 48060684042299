import React from "react";
import { Flex, H1, H2, Text } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import { MinusCircleOutline, PlusCircleOutline } from "components/Icons";
import { COLORS } from "ui-components/theme";

const SIMCountSelector = ({
  title,
  subtitle,
  main_title,
  minNumber,
  disabled,
  maxNumber,
  handleChange,
  fieldName,
  simCount
}) => {
  // Increment for Decrement the SIM count based on the type.
  const handleSIMCountChange = type => {
    const count = type === "inc" ? simCount + 1 : simCount - 1;

    handleChange(fieldName, count);
  };

  return (
    <>
      {main_title && (
        <H2>
          <FormattedMessage id={main_title}></FormattedMessage>
        </H2>
      )}
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        pt={3}
        pb={4}
        width={"100%"}
        maxWidth={"680px"}
        margin={"15px auto"}
      >
        <Flex justifyContent={"center"} alignItems={"center"} width={"100%"}>
          <button
            onClick={() => handleSIMCountChange("dec")}
            type="button"
            disabled={simCount <= minNumber || disabled}
          >
            <MinusCircleOutline size="36px" color={COLORS.blue[4]} />
          </button>

          <H1 display="flex" marginX="10px" fontSize="34px">
            {simCount}
            <FormattedMessage id={title}>
              {msg => <Text marginLeft="5px">{msg}</Text>}
            </FormattedMessage>
          </H1>

          <button
            type="button"
            onClick={() => handleSIMCountChange("inc")}
            disabled={simCount >= maxNumber || disabled}
          >
            <PlusCircleOutline size="36px" color={COLORS.blue[4]} />
          </button>
        </Flex>

        <Flex justifyContent={"center"} alignItems={"center"} width={"100%"}>
          <FormattedMessage id={subtitle} values={{ maxNumber }}>
            {msg => (
              <Text
                color={COLORS.gray[1]}
                marginTop="5px"
                opacity="0.6"
                fontSize="14px"
              >
                {msg}
              </Text>
            )}
          </FormattedMessage>
        </Flex>
      </Flex>
    </>
  );
};

SIMCountSelector.defaultProps = {
  minNumber: 1,
  maxNumber: 6,
  title: "sim_count_text_1",
  subtitle: "sim_count_text_2",
  fieldName: "sim_count"
};

export default SIMCountSelector;
