import { createSelector } from "reselect";
import get from "lodash/get";

const eSignatureDomain = state => get(state.api, "eSignature.data", {});

// ***************************************************************
// **************** paymentRequest selectors **********************
// ***************************************************************

export const eSignatureSelector = createSelector(
  eSignatureDomain,
  eSignature => {
    return eSignature;
  }
);

// ***************************************************************
// *************** eSignature makeSelect **********************
// ***************************************************************

export const makeSelectESigned = () =>
  createSelector(
    eSignatureSelector,
    eSignatureSelector => get(eSignatureSelector, "doc_id", "").length > 0
  );
