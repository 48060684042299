import React from "react";
import TotalPrice from "components/TotalPrice";
import PromoCodeBenefits from "components/PromoCodeBenefits";
import NumberItem from "components/NumberItem";
import RegistrationFeeItem from "components/RegistrationFeeItem";
import DeliveryFeeItem from "components/DeliveryFeeItem";
import PhoneDetailsItem from "components/PhoneDetailsItem";
import TaxAmountItem from "components/TaxAmountItem";
import { Box } from "ui-components";
import { iconThemes } from "ui-components/theme";
import get from "lodash/get";
import round from "lodash/round";

const PayTodayCard = ({ payTodayItems, referralCode, data, themeColors }) => {
  const {
    hideTotalPrice,
    hideSelectedNumber,
    hideDeliveryFee,
    primaryColor: primary
  } = data;

  const defaultColor = get(themeColors, "primary") || iconThemes.blue;
  const primaryColor = primary ? iconThemes[primary] || primary : defaultColor;
  const priceColor = get(data, "priceColor", iconThemes.white);
  const iconColor = get(themeColors, "icon") || iconThemes.blue;
  const excludeTax = get(data, "excludeTax");
  const totalAmountWithoutTax = get(payTodayItems, "totalAmountWithoutTax", 0);
  const totalTaxAmount = get(payTodayItems, "totalTaxAmount", 0);
  const totalTaxPrice = get(payTodayItems, "totalTaxPrice");
  const totalTaxPercent = round(
    (totalTaxAmount / totalAmountWithoutTax) * 100,
    2
  );

  return (
    <Box
      variant="borderSection"
      mb={8}
      padding={[0, 0]}
      paddingBottom={"20px"}
      sx={{
        color: primaryColor,
        borderColor: primaryColor,
        borderWidth: "6px"
      }}
    >
      {!hideTotalPrice && (
        <TotalPrice
          title={get(data, "title", "pay_today")}
          {...payTodayItems}
          bgColor={primaryColor}
          priceColor={priceColor}
        />
      )}
      <PhoneDetailsItem items={get(payTodayItems, "items")} />
      <RegistrationFeeItem
        payTodayItems={payTodayItems}
        primaryColor={iconColor}
      />
      {!hideSelectedNumber && (
        <NumberItem payTodayItems={payTodayItems} primaryColor={primaryColor} />
      )}
      {!hideDeliveryFee && (
        <DeliveryFeeItem
          payTodayItems={payTodayItems}
          primaryColor={iconColor}
        />
      )}
      <PromoCodeBenefits
        items={get(payTodayItems, "items")}
        referralCode={referralCode}
      />
      {excludeTax && totalTaxAmount > 0 && (
        <TaxAmountItem
          taxPrice={totalTaxPrice}
          taxPercent={totalTaxPercent}
          primaryColor={iconColor}
        />
      )}
    </Box>
  );
};

PayTodayCard.defaultProps = {
  addons: [],
  planDetails: {},
  payTodayItems: {},
  referralCode: {},
  data: {}
};

export default PayTodayCard;
