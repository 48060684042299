import React, { useEffect } from "react";
import get from "lodash/get";
import map from "lodash/map";
import PageHeader from "components/PageHeader";
import ProductItem from "components/ProductItem";
import PaymentBox from "components/PaymentBox";
import MockPaymentBox from "components/MockPaymentBox";

const SHOP_ORDER_SUMMARY = ({
  orderDetails,
  clearOrderDetails,
  fetchOrderDetails,
  type,
  txnId,
  data,
  mock,
  mockPaymentSuccess,
  mockPaymentFailure,
  mockPayment,
  isMockingPayment,
  clearMockPayment
}) => {
  const orderParams = { type, order_ref: txnId };
  const payment = get(orderDetails, "payment", {});

  useEffect(
    () => {
      clearOrderDetails();
      clearMockPayment();
      fetchOrderDetails(orderParams);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const mockPaymentProps = {
    mockPaymentSuccess,
    mockPaymentFailure,
    mockPayment,
    isMockingPayment,
    txnId
  };

  return (
    <>
      <PageHeader {...data} />
      {map(get(orderDetails, "items"), (item, index) => (
        <ProductItem key={index} {...item} />
      ))}
      {mock ? (
        <MockPaymentBox {...mockPaymentProps} />
      ) : (
        <PaymentBox payment={payment} />
      )}
    </>
  );
};

export default SHOP_ORDER_SUMMARY;
