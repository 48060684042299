import { createSelector } from "reselect";
import get from "lodash/get";

export const eligibilitySettingsDomain = state =>
  get(state, "api.eligibilitySettings.data", {});

const eligiblePlansDomain = state =>
  get(state, "api.eligiblePlans.data[0]", {});

export const eligibilityActionsStatusSelector = createSelector(
  eligibilitySettingsDomain,
  eligibilityActions => get(eligibilityActions, "actions", null)
);

export const makeSelectEligibleActions = () =>
  createSelector(
    eligibilitySettingsDomain,
    eligibilitySettings => eligibilitySettings
  );

export const makeSelectEligiblePlansServices = () =>
  createSelector(eligiblePlansDomain, eligiblePlans => eligiblePlans);

export const makeSelectMNPValidityInDays = () =>
  createSelector(eligibilitySettingsDomain, eligibilitySettings =>
    get(
      eligibilitySettings,
      "actions.portout.mnpReservationNoValidityInDays",
      -1
    )
  );

export const makeSelectKddiOct = () =>
  createSelector(eligibilitySettingsDomain, eligibilitySettings =>
    get(eligibilitySettings, "actions.portout.kddiOct")
  );

export const makeSelectOnestopPortoutStatus = () =>
  createSelector(eligibilitySettingsDomain, eligibilitySettings =>
    get(eligibilitySettings, "actions.portout.status", null)
  );

export const makeSelectTerminationEligibility = () =>
  createSelector(eligibilitySettingsDomain, eligibilitySettings => {
    return get(eligibilitySettings, "terminate_service", false);
  });

export const makeSelectPinChangeEligibility = () =>
  createSelector(eligibilitySettingsDomain, eligibilitySettings => {
    return !get(eligibilitySettings, "active_sim_change_request", true) && !get(eligibilitySettings, "active_portout", true)
  });

export const makeSelectHasRequestedEligibility = () =>
  createSelector(eligibilitySettingsDomain, eligibilitySettings => {
    return Object.keys(eligibilitySettings).length !== 0
  });

export const makeSelectExistingMNPDetails = () =>
  createSelector(eligibilitySettingsDomain, eligibilitySettings =>
    get(eligibilitySettings, "actions.portout", {})
  );
