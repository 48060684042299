import React from "react";
import { H1, P } from "ui-components";
import FormattedMessage from "components/FormattedMessage";

const TnCDetails = props => {
  const { mainSection = {}, subSection = {} } = props.data || {};

  const {
    title: mainTitle,
    description: maindescription,
    headerStyles: mainHeaderStyles = {},
    paraStyles: mainParaStyles = {}
  } = mainSection;

  const {
    title: subTitle,
    description: subDescription,
    headerStyles: subHeaderStyles = {},
    paraStyles: subParaStyles = {}
  } = subSection;

  return (
    <div>
      <div style={headerContainerStyles}>
        {mainTitle && (
          <H1
            mb={1}
            data-testid="tnc_details_title"
            className="page-title"
            style={{ ...defaultHeaderStyles, ...mainHeaderStyles }}
          >
            <FormattedMessage html id={mainTitle} values={props} />
          </H1>
        )}
        {maindescription && (
          <P
            sx={{ ...defaultParaStyles, ...mainParaStyles }}
            {...props}
            data-testid="tnc_details_description"
          >
            <FormattedMessage id={maindescription} values={props} html />
          </P>
        )}

        <div style={subHeaderContainerStyles}>
          {subTitle && (
            <H1
              mb={1}
              data-testid="tnc_details_sub_title"
              className="page-title"
              style={{ ...defaultSubHeaderStyles, ...subHeaderStyles }}
            >
              <FormattedMessage html id={subTitle} values={props} />
            </H1>
          )}
          {subDescription && (
            <P
              sx={{ ...defaultSubParaStyles, ...subParaStyles }}
              {...props}
              data-testid="tnc_details_sub_description"
            >
              <FormattedMessage id={subDescription} values={props} html />
            </P>
          )}
        </div>
      </div>
    </div>
  );
};

const headerContainerStyles = {
  backgroundColor: "#f7f7f7",
  height: "fit-content",
  padding: "1rem",
  borderRadius: "0.25rem",
  border: "1px solid #ebecee"
};

const subHeaderContainerStyles = {
  height: "fit-content",
  marginTop: "2rem",
  borderTop: 0
};

const defaultHeaderStyles = {
  backgroundColor: "#FFF500",
  color: "#333333",
  borderRadius: "2rem",
  padding: "0.5rem",
  paddingLeft: "1.5rem"
};

const defaultSubHeaderStyles = {
  backgroundColor: "#ebecee",
  color: "#333333",
  borderRadius: "2rem 2rem 0 0",
  padding: "0.5rem",
  paddingLeft: "1.5rem",
  textAlign: "center",
  marginBottom: 0
};

const defaultParaStyles = {
  marginTop: "2rem"
};

const defaultSubParaStyles = {
  border: "1px solid #ebecee",
  borderTop: "0",
  borderRadius: "0 0 1rem 1rem",
  padding: "2rem 1rem"
};

export default TnCDetails;
