import { createAction, createActions } from "redux-actions";
import { change, initialize } from "redux-form";
import Session from "session";
import {
  API_GATEWAY_APP_SETTINGS,
  OMS_APP_SETTINGS,
  API_GATEWAY_LOCALE,
  OMS_LOCALE,
  OMS_BASE_PLAN,
  OMS_ELIGIBLE_PLANS,
  OMS_NUMBERS,
  OMS_GUEST_ORDER,
  OMS_DELIVERY_SLOTS,
  OMS_POST_OFFICE_DELIVERY_SLOTS,
  OMS_CREATE_ORDER_TEMP,
  OMS_CREATE_SAD_ORDER,
  OMS_CONFIRM_PAYMENT_OPTION,
  OMS_PAYMENT_RETURN,
  OMS_NUMBER_LOCK,
  OMS_UPLOAD_IMAGE,
  OMS_UPLOAD_SCREENSHOT,
  OMS_VALIDATE_REFERRAL_CODE,
  OMS_SEARCH_ADDRESS_LOOKUP,
  OMS_ADDRESS_COMPONENT_MAPPINGS,
  OMS_VERIFY_IDENTITY,
  OMS_REQUEST_OTP,
  OMS_VERIFY_OTP,
  OMS_SUBMIT_SUSPENSION,
  OMS_CANCEL_SUSPENSION,
  OMS_SUBMIT_NUMBER_CHANGE,
  OMS_CANCEL_NUMBER_CHANGE,
  OMS_SUSPENSION_PAYMENT_DETAILS,
  OMS_SUBMIT_TERMINATION,
  OMS_SUBMIT_WINBACK,
  OMS_CANCEL_TERMINATION,
  OMS_DELIVERY_INFO,
  OMS_SUBMIT_REPLACE_SIM,
  OMS_SUBMIT_DELIVERY,
  OMS_ORDER_DETAILS,
  OMS_VALIDATE_PORT_IN_NUMBER,
  OMS_VALIDATE_POSTAL_CODE,
  OMS_UPLOAD_IDENTITY_DETAILS,
  OMS_SUBMIT_UPLOAD_IDENTITY,
  OMS_PROCESS_PREPAID_ORDER,
  OMS_VALIDATE_ICCID,
  OMS_FETCH_PAYMENT_REQUEST,
  OMS_VERIFY_PAYMENT,
  OMS_DO_KYC,
  OMS_FETCH_SURVEY,
  OMS_GET_CITY_LIST,
  OMS_GET_DISTRICT_LIST,
  OMS_GET_STREET_LIST,
  OMS_GET_STORE_LIST,
  OMS_FIND_ADDRESS_LOOKUP,
  OMS_TRANSACTION_DETAILS,
  OMS_ACTIVATE_SIM_CARD,
  OMS_REINITIATE_PORT_IN,
  OMS_RESCHEDULE_PORT_IN,
  OMS_CANCEL_PORT_IN,
  OMS_IDENTITY_VERIFICATION_STATUS,
  USER_SERVICE_OTP,
  USER_SERVICE_LOGIN,
  USER_SERVICE_AUTHENTICATE,
  BASE,
  USER_SERVICE_LIQUID_URL,
  USER_SERVICE_LIQUID_OCR_DATA,
  USER_SERVICE_LIQUID_OCR_REGISTER,
  USER_SERVICE_LOGGEDIN_USER_DETAILS,
  OMS_PHONES,
  OMS_CUSTOMER_INFO,
  OMS_USER_WALLET,
  USE_API_GATEWAY,
  API_GATEWAY_CREATE_ORDER,
  API_GATEWAY_UPDATE_ORDER,
  API_GATEWAY_PAYMENT_REQUEST,
  API_GATEWAY_MANAGE_ORDER,
  CREATE_PORTOUT_REQUEST,
  USER_SERVICE_VALIDATE_OTP_TRIGGER,
  USER_SERVICE_VALIDATE_OTP,
  USER_SERVICE_UPDATE_EMAIL,
  USER_SERVICE_LOGOUT,
  API_GATEWAY_ESIM_QR,
  PROCESS_PAYMENT_METHOD,
  PROCESS_CARD_UPDATE_PAYMENT_METHOD,
  SAVE_AND_GET_CARD_DETAILS,
  UPFRONT_PAYMENT,
  API_GATEWAY_ACTIVATE_SIM_CARD,
  LOGISTIC_DELIVERY_SLOTS,
  TELCO_PROFILE,
  API_GATEWAY_ELIGIBLE_PLANS,
  API_GATEWAY_BASE_PLAN,
  API_GATEWAY_INITIATE_PORTIN,
  API_GATEWAY_CANCEL_PORT_IN,
  API_GATEWAY_SUBMIT_WINBACK,
  API_GATEWAY_SUBMIT_TERMINATION,
  API_GATEWAY_CANCEL_TERMINATION,
  API_GATEWAY_PAYMENT_DETAILS,
  API_GATEWAY_PAYMENT_VERIFY,
  REWARDS_VALIDATE_REFERRAL_CODE,
  API_GATEWAY_DOWNLOAD_SERVICE_AGREEMENT,
  API_GATEWAY_SUBMIT_DELIVERY,
  TELCO_REPORT_LOST_SIM,
  OMS_JUMIO_VERIFICATION_URL,
  OMS_UPDATE_SAD_ORDER,
  OMS_EKYC_COMPLETED,
  OMS_FETCH_ORDER_STATUS,
  OMS_SAD_VALIDATE_ICCID,
  QUILT_ELIGIBILITY,
  REVERSE_PORTIN,
  API_GATEWAY_DELIVERY_DETAILS,
  API_GATEWAY_REQUEST_OTP,
  API_GATEWAY_ORDER_CANCELLATION,
  TELCO_REQUEST_TERMINATE,
  TELCO_EMAIL_SERVICE_AGREEMENT,
  API_GATEWAY_ORDER_CANCELLATION_STATUS,
  OMS_ORDER_CANCELLATION_STATUS,
  OMS_ORDER_CANCELLATION,
  CANCEL_TERMINATION,
  USER_SERVICE_USER_KYC,
  USER_SERVICE_SUBMIT_USER_KYC,
  USER_SERVICE_LINK_SOCIAL,
  QUILT_WINBACK_AGGREGATION,
  QUILT_PROFILE_AGGREGATION,
  API_GATEWAY_ACTIVATE_REPLACE_SIM_CARD,
  OMS_VALIDATE_SIGNATURE,
  OMS_OUTSTANDING_BILLS,
  OMS_CHECK_PAYMENT_STATUS,
  OMS_POP_STATIONS,
  LATITUDE_TRANSACTIONS_STATUS,
  PAYMENT_TRANSACTION_STATUS,
  CIS_REQUEST,
  CIS_REQUEST_HR,
  OMS_PAY_OUTSTANDING_BILLS,
  OMS_AUTHORIZE_ORDER,
  OMS_SET_AUTHORIZE_ORDER,
  OMS_RESEND_ESIM_QR_CODE,
  OMS_SUBMIT_SWITCH_TO_ESIM,
  OMS_SECURED_FETCH_PAYMENT_REQUEST,
  OMS_MYINFO,
  OMS_MYINFO_OTP,
  OMS_MYINFO_VERIFY_OTP,
  CIS_PARTNERS,
  CIS_PARTNERS_REWARDS_FORM_FIELDS,
  CIS_PARTNERS_CMS_FORM_FIELDS,
  API_GATEWAY_UPDATE_PARENTAL_CONSENT,
  TELCO_RE_INITIATE_NUMBER_PORT_IN,
  API_GATEWAY_RESUBMIT_PORTIN_DETAILS,
  USE_REWARDS_CIS,
  BILL_DETAILS,
  BILL_PAYMENT_INTENT,
  BILL_PAYMENT_STATUS,
  USER_SERVICE_REQUEST_OTP_V4_SECURE,
  USER_SERVICE_IPDB_ID_ASSERTION,
  USER_SERVICE_IPDB_AUTHENTICATE
} from "utils/constants";
import {
  prepareImageDataForUpload,
  prepareScreenshotDataForUpload
} from "utils/helpers";
import { getLocaleOrDefault } from "utils/localStorage";
import OMS from "./OMS";
import UserService from "./UserService";
import Payment from "./Payment";
import Quilt from "./Quilt";
import Laas from "./Laas";
import Telco from "./Telco";
import Rewards from "./Rewards";
import Billing from "./Billing";
import BSSMW from "./BSSMW";

export * from "./uiActions";

export const clearData = createAction("CLEAR_DATA");
export const clearUIData = createAction("CLEAR_UI_DATA");
export const clearAllData = createAction("CLEAR_ALL_DATA");
export const setData = createAction("SET_DATA");
export const resetLoadingApis = createAction("RESET_LOADING_APIS");

export const fetchAppSettings = callback => {
  const path = USE_API_GATEWAY ? API_GATEWAY_APP_SETTINGS : OMS_APP_SETTINGS;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({ key: "appSettings", path, base, callback });
};

// Moved to services for JP
export const fetchLocale = callback => {
  const locale = getLocaleOrDefault();
  const path = USE_API_GATEWAY ? API_GATEWAY_LOCALE : OMS_LOCALE;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "locale",
    path,
    base,
    params: { locale },
    callback
  });
};

export const fetchPlan = (params, callback) => {
  const path = USE_API_GATEWAY ? API_GATEWAY_BASE_PLAN : OMS_BASE_PLAN;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "planService",
    path,
    base,
    params,
    callback
  });
};

// Moved to services for JP to services for JP
export const fetchEligiblePlans = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_ELIGIBLE_PLANS
    : OMS_ELIGIBLE_PLANS;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "eligiblePlans",
    path,
    base,
    params,
    callback
  });
};

export const selectPlan = (data, callback) => {
  return setData({
    key: "planService",
    data,
    callback
  });
};

export const fetchNumbers = () => {
  return fetchRequested({ key: "numbers", path: OMS_NUMBERS });
};

export const fetchGuestOrder = (params, callback) => {
  const path = USE_API_GATEWAY ? API_GATEWAY_MANAGE_ORDER : OMS_GUEST_ORDER;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "guestOrder",
    path,
    base,
    method: "POST",
    params,
    callback,
    override: true
  });
};

export const fetchAuthorizerDetails = (params, callback) => {
  const path = OMS_AUTHORIZE_ORDER.replace("{id}", params.order_id);
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "authorizerDetails",
    path,
    base,
    method: "GET",
    params,
    callback,
    override: true
  });
};

export const setAuthorizerDetails = (params, callback) => {
  const path = OMS_SET_AUTHORIZE_ORDER.replace("{id}", params.order_id);
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "setAuthorizerDetails",
    path,
    base,
    method: "POST",
    params,
    callback,
    override: true
  });
};

// Moved
export const fetchUserOrders = (params, callback) => {
  const path = USE_API_GATEWAY ? API_GATEWAY_MANAGE_ORDER : OMS_GUEST_ORDER;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "userOrders",
    path,
    base,
    method: "POST",
    params,
    callback,
    override: true
  });
};

export const fetchOrderDetails = (params, callback) => {
  return fetchRequestedWithCaptcha({
    key: "orderDetails",
    path: OMS_ORDER_DETAILS,
    method: "POST",
    params,
    callback
  });
};

export const fetchUploadIdentityDetails = params => {
  return fetchRequested({
    key: "uploadIdentityDetails",
    path: OMS_UPLOAD_IDENTITY_DETAILS,
    method: "POST",
    params
  });
};

// Moved
export const fetchReferralCode = ({ referral_code, plan_name }) => {
  const params = {
    referral_code,
    plan_name,
    service: "telco",
    team: "ECOM"
  };
  let path = USE_API_GATEWAY
    ? REWARDS_VALIDATE_REFERRAL_CODE
    : OMS_VALIDATE_REFERRAL_CODE;
  const base = USE_API_GATEWAY ? BASE.REWARDS : BASE.OMS;
  path = path.replace("{REFERRAL_CODE}", referral_code);
  const successData = {
    referral_code
  };
  return fetchRequestedWithCaptcha({
    key: "referralCode",
    path,
    base,
    params,
    successData,
    whitelistedCodes: [400],
    meta: {
      captchaAction: "promos_validate"
    }
  });
};

export const fetchOTP = (params, callback) => {
  const path = USE_API_GATEWAY ? API_GATEWAY_REQUEST_OTP : OMS_REQUEST_OTP;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "requestOTP",
    path,
    base,
    method: "POST",
    params,
    callback
  });
};

// Moved
export const fetchOTPSecured = (params, callback) => {
  return fetchRequested({
    key: "requestOTPV4",
    base: BASE.USER_SERVICE,
    path: USER_SERVICE_REQUEST_OTP_V4_SECURE,
    method: "POST",
    params,
    callback
  });
};

// Moved
export const fetchValidateOTP = (params, callback) => {
  return fetchRequestedWithCaptcha({
    key: "validateOTP",
    base: BASE.USER_SERVICE,
    path: USER_SERVICE_VALIDATE_OTP,
    method: "POST",
    params,
    callback
  });
};

// Moved
export const fetchValidateOTPTrigger = (params, callback) => {
  return fetchRequestedWithCaptcha({
    key: "validateOTPTrigger",
    base: BASE.USER_SERVICE,
    path: USER_SERVICE_VALIDATE_OTP_TRIGGER,
    method: "POST",
    params,
    callback
  });
};

// Moved
export const fetchUpdateNewEmail = (params, callback) => {
  return fetchRequested({
    key: "updateNewEmail",
    base: BASE.USER_SERVICE,
    path: USER_SERVICE_UPDATE_EMAIL,
    method: "PATCH",
    params,
    callback
  });
};

export const fetchPaymentRequest = params => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_PAYMENT_REQUEST
    : OMS_FETCH_PAYMENT_REQUEST;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "paymentRequest",
    path,
    base,
    params
  });
};

export const fetchSecuredPaymentRequest = params => {
  return fetchRequestedWithCaptcha({
    key: "paymentRequest",
    path: OMS_SECURED_FETCH_PAYMENT_REQUEST,
    method: "POST",
    params
  });
};

export const setEkycCompleted = params => {
  return fetchRequestedWithCaptcha({
    key: "ekyc",
    path: OMS_EKYC_COMPLETED,
    method: "POST",
    params
  });
};

export const fetchOrderStatus = (params, callback) => {
  return fetchRequestedWithCaptcha({
    key: "orderStatus",
    path: OMS_FETCH_ORDER_STATUS,
    method: "GET",
    params,
    callback
  });
};

export const postPaymentRequest = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_PAYMENT_REQUEST
    : OMS_FETCH_PAYMENT_REQUEST;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;

  return fetchRequestedWithCaptcha({
    key: "paymentRequest",
    path,
    base,
    method: "POST",
    params,
    callback,
    meta: {
      captchaAction: "payment_request"
    }
  });
};

//TODO need be implemented
export const applePayMerchantValidation = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_PAYMENT_REQUEST
    : OMS_FETCH_PAYMENT_REQUEST;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;

  return fetchRequestedWithCaptcha({
    key: "applePayMerchantValidationRequest",
    path,
    base,
    method: "POST",
    params,
    callback
  });
};

export const changeFormField = ({ form, field, value }) => {
  return change(form, field, value);
};

export const initializeForm = ({ form, data, keepDirty = true }) => {
  return initialize(form, data, keepDirty);
};

// Moved.
export const fetchPaymentReturn = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_PAYMENT_DETAILS
    : OMS_PAYMENT_RETURN;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "paymentReturn",
    path,
    base,
    method: "POST",
    params,
    callback,
    meta: {
      captchaAction: "payment_details"
    }
  });
};

export const fetchDeliverySlots = (params, callback) => {
  const path = USE_API_GATEWAY ? LOGISTIC_DELIVERY_SLOTS : OMS_DELIVERY_SLOTS;
  const base = USE_API_GATEWAY ? BASE.LOGISTIC : BASE.OMS;
  return fetchRequested({
    key: "deliverySlots",
    path,
    base,
    params,
    callback
  });
};

export const fetchPostOfficeDeliverySlots = params => {
  return fetchRequested({
    key: "postOfficeDeliverySlots",
    path: OMS_POST_OFFICE_DELIVERY_SLOTS,
    params
  });
};

export const fetchAddressLookup = params => {
  return fetchRequested({
    key: "addressLookup",
    path: OMS_SEARCH_ADDRESS_LOOKUP,
    params
  });
};

export const fetchAddressComponentMappings = params => {
  return fetchRequested({
    key: "addressComponentMappings",
    path: OMS_ADDRESS_COMPONENT_MAPPINGS,
    params,
    override: true
  });
};

export const findAddressLookup = (params, callback) => {
  return fetchRequested({
    key: "addressComponentMappings",
    path: OMS_FIND_ADDRESS_LOOKUP,
    params,
    callback,
    override: true
  });
};

export const fetchSuspensionPaymentDetails = params => {
  return fetchRequested({
    key: "suspensionPaymentDetails",
    path: OMS_SUSPENSION_PAYMENT_DETAILS,
    params
  });
};

export const fetchDeliveryDetails = params => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_DELIVERY_DETAILS
    : OMS_DELIVERY_INFO;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "scheduleDeliveryDetails",
    path,
    base,
    params
  });
};

export const fetchTransactionDetails = (params, callback) => {
  return fetchRequested({
    key: "transactionDetails",
    path: OMS_TRANSACTION_DETAILS,
    params,
    callback
  });
};

export const numberLock = params => {
  return fetchRequested({
    key: "numbers",
    path: OMS_NUMBER_LOCK,
    params
  });
};

export const uploadImage = payload => {
  const [primaryOrSecondary, frontOrBack, file, binaryStr] = payload;
  const reference_number = Session.getDeviceId();
  const params = { reference_number };
  params[`${frontOrBack}_image`] = prepareImageDataForUpload(file, binaryStr);

  return fetchRequested({
    key: `${primaryOrSecondary}_${frontOrBack}_ImagesUpload`,
    params,
    path: OMS_UPLOAD_IMAGE,
    merge: { reference_number },
    method: "POST"
  });
};

export const uploadSignature = binaryStr => {
  const file = { name: "eSignature.png" };

  const reference_number = Session.getDeviceId();
  const params = {
    reference_number,
    esignImage: prepareImageDataForUpload(file, binaryStr)
  };

  return fetchRequested({
    key: "eSignature",
    params,
    path: OMS_UPLOAD_IMAGE,
    merge: { reference_number },
    method: "POST"
  });
};

export const uploadScreenshot = payload => {
  const { screenshot } = payload;
  const reference_number = Session.getDeviceId();

  const name = "screenshot_of_consent";
  const file = { name };
  const params = {
    reference_number,
    ...prepareScreenshotDataForUpload(file, screenshot)
  };

  return fetchRequested({
    key: "screenshot",
    params,
    path: OMS_UPLOAD_SCREENSHOT,
    merge: { reference_number },
    method: "POST"
  });
};

export const verifyIdentity = (params, callback) => {
  return fetchRequested({
    key: "verifyIdentity",
    path: OMS_VERIFY_IDENTITY,
    params,
    method: "POST",
    callback
  });
};

export const identityVerificationStatus = (params, callback) => {
  return fetchRequested({
    key: "identityVerificationStatus",
    path: OMS_IDENTITY_VERIFICATION_STATUS,
    params,
    method: "POST",
    callback
  });
};

export const validatePortInNumber = (params, callback) => {
  return fetchRequested({
    key: "validatePortInNumber",
    path: OMS_VALIDATE_PORT_IN_NUMBER,
    params,
    callback
  });
};

export const validatePostalCode = (params, field, callback) => {
  return fetchRequested({
    key: `validatePostalCode.${field}`,
    path: OMS_VALIDATE_POSTAL_CODE,
    params,
    callback
  });
};

export const resetPortInNumberValidation = () =>
  clearData("validatePortInNumber");

export const resetDeliverySlots = () => clearData("deliverySlots");

export const resetPostalCodeValidation = field =>
  clearData(`validatePostalCode.${field}`);

export const setPostalCodeValidation = (field, data = {}, loading) =>
  setData({ key: `validatePostalCode.${field}`, data, loading });

export const verifyOTP = (params, callback) => {
  return fetchRequested({
    key: "verifyOTP",
    params,
    path: OMS_VERIFY_OTP,
    method: "POST",
    callback
  });
};

export const submitNumberChange = (params, callback) => {
  return fetchRequested({
    key: "submitNumberChange",
    params,
    path: OMS_SUBMIT_NUMBER_CHANGE,
    method: "POST",
    callback
  });
};

export const submitCancelNumberChange = (params, callback) => {
  return fetchRequested({
    key: "cancelNumberChange",
    params,
    path: OMS_CANCEL_NUMBER_CHANGE,
    method: "POST",
    callback
  });
};

export const submitSuspension = (params, callback) => {
  return fetchRequested({
    key: "submitSuspension",
    params,
    path: OMS_SUBMIT_SUSPENSION,
    method: "POST",
    callback
  });
};

export const submitCancelSuspension = (params, callback) => {
  return fetchRequested({
    key: "cancelSuspension",
    params,
    path: OMS_CANCEL_SUSPENSION,
    method: "POST",
    callback
  });
};

export const submitScheduleDelivery = (params, callback) => {
  return fetchRequested({
    key: "submitScheduleDelivery",
    params,
    path: OMS_SUBMIT_DELIVERY,
    method: "POST",
    callback
  });
};

export const submitAuthorizeDelivery = (params, callback) => {
  const path = OMS_SET_AUTHORIZE_ORDER.replace("{id}", params.order_id);
  return fetchRequested({
    key: "submitAuthorizeDelivery",
    params,
    path,
    method: "POST",
    callback
  });
};

export const submitReplaceSim = (params, callback) => {
  return fetchRequested({
    key: "submitReplaceSim",
    params,
    path: OMS_SUBMIT_REPLACE_SIM,
    method: "POST",
    callback
  });
};

export const submitReplaceSimToTelco = (headers, params, callback) => {
  const base = BASE.TELCO_SERVICE;
  const path = TELCO_REPORT_LOST_SIM;
  return fetchRequested({
    key: "submitReplaceSimToTelco",
    headers,
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const submitSwitchToEsim = (params, callback) => {
  return fetchRequested({
    key: "submitSwitchToEsim",
    params,
    path: OMS_SUBMIT_SWITCH_TO_ESIM,
    method: "POST",
    callback
  });
};

export const submitResendEsimQR = (params, callback) => {
  return fetchRequestedWithCaptcha({
    key: "resendEsimQRCode",
    params,
    path: OMS_RESEND_ESIM_QR_CODE,
    method: "POST",
    callback
  });
};

export const submitTermination = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_SUBMIT_TERMINATION
    : OMS_SUBMIT_TERMINATION;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "submitTermination",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const submitWinback = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_SUBMIT_WINBACK
    : OMS_SUBMIT_WINBACK;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "submitWinback",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const cancelTermination = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_CANCEL_TERMINATION
    : OMS_CANCEL_TERMINATION;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "cancelTermination",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const submitUploadIdentity = (params, callback) => {
  return fetchRequested({
    key: "submitUploadIdentity",
    params,
    path: OMS_SUBMIT_UPLOAD_IDENTITY,
    method: "POST",
    callback
  });
};

export const validateIccId = (params, callback) => {
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "validateIccId",
    params,
    path: OMS_VALIDATE_ICCID,
    base,
    callback,
    whitelistedCodes: [422]
  });
};

export const validateIccIdForSelfActivation = (params, callback) => {
  return fetchRequestedWithCaptcha({
    key: "validateIccId",
    params,
    path: OMS_SAD_VALIDATE_ICCID,
    method: "POST",
    callback,
    whitelistedCodes: [422]
  });
};

// what is this? @veera
export const mockPayment = (params, callback) => {
  return fetchRequested({
    key: "mockPayment",
    params,
    path: OMS_PROCESS_PREPAID_ORDER,
    method: "POST",
    callback
  });
};

export const createInitialOrder = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_CREATE_ORDER
    : OMS_CREATE_ORDER_TEMP;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "order",
    params,
    path,
    base,
    method: "POST",
    callback,
    meta: {
      captchaAction: "create_order"
    }
  });
};

export const updateOrder = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_UPDATE_ORDER
    : OMS_CREATE_ORDER_TEMP;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "order",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const updateSadOrder = (params, callback) => {
  return fetchRequestedWithCaptcha({
    key: "order",
    params,
    path: OMS_UPDATE_SAD_ORDER,
    method: "PUT",
    callback
  });
};

export const updateLatitudePaymentStatus = (params, callback) => {
  return fetchRequested({
    key: "updateLatitudePaymentStatus",
    params,
    base: BASE.PAYMENTS_SERVICE,
    path: LATITUDE_TRANSACTIONS_STATUS,
    method: "POST",
    callback
  });
};

export const getJumioVerificationUrl = (params, callback) => {
  return fetchRequestedWithCaptcha({
    key: "jumio",
    params,
    path: OMS_JUMIO_VERIFICATION_URL,
    method: "GET",
    callback
  });
};

export const confirmPaymentOption = (params, callback) => {
  return fetchRequested({
    key: "confirmPaymentOption",
    params,
    path: OMS_CONFIRM_PAYMENT_OPTION,
    method: "POST",
    callback
  });
};

export const verifyPayment = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_PAYMENT_VERIFY
    : OMS_VERIFY_PAYMENT;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "verifyPayment",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const doKyc = (params, callback) => {
  return fetchRequested({
    key: "kyc",
    params,
    path: OMS_DO_KYC,
    method: "POST",
    callback
  });
};

export const activateSimCard = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_ACTIVATE_SIM_CARD
    : OMS_ACTIVATE_SIM_CARD;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "activateSimCard",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const activateReplaceSimCard = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_ACTIVATE_REPLACE_SIM_CARD
    : OMS_ACTIVATE_SIM_CARD;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequestedWithCaptcha({
    key: "lostSimActivate",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const initiatePortIn = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_INITIATE_PORTIN
    : OMS_ACTIVATE_SIM_CARD;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "initiatePortIn",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const reinitiatePortIn = (params, callback) => {
  return fetchRequested({
    key: "reinitiatePortIn",
    params,
    path: OMS_REINITIATE_PORT_IN,
    method: "POST",
    callback
  });
};

export const reschedulePortIn = (params, callback) => {
  return fetchRequested({
    key: "reschedulePortIn",
    params,
    path: OMS_RESCHEDULE_PORT_IN,
    method: "POST",
    callback
  });
};

export const cancelPortIn = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_CANCEL_PORT_IN
    : OMS_CANCEL_PORT_IN;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "cancelPortIn",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const createSelfActivationDeliveryOrder = (params, callback) => {
  return fetchRequestedWithCaptcha({
    key: "selfActivationDeliveryOrder",
    params,
    path: OMS_CREATE_SAD_ORDER,
    method: "POST",
    callback
  });
};

export const fetchSurvey = params => {
  return fetchRequested({ key: "survey", path: OMS_FETCH_SURVEY, params });
};

export const fetchCityList = params => {
  return fetchRequested({ key: "cityList", path: OMS_GET_CITY_LIST, params });
};

export const fetchDistrictList = ({ city }) => {
  return fetchRequested({
    key: "districtList",
    path: OMS_GET_DISTRICT_LIST,
    params: { city }
  });
};

export const fetchStreetList = ({ city, district }) => {
  return fetchRequested({
    key: "streetList",
    path: OMS_GET_STREET_LIST,
    params: { city, district }
  });
};

export const fetchStoreList = ({
  city,
  district,
  street,
  portin = false,
  type = "sim-only"
}) => {
  return fetchRequested({
    key: "storeList",
    path: OMS_GET_STORE_LIST,
    params: { city, district, street, portin, type }
  });
};

export const fetchPhones = (params, callback) => {
  return fetchRequested({
    key: "phones",
    path: OMS_PHONES,
    params,
    callback
  });
};

export const fetchCustomerInfo = (params, callback) => {
  return fetchRequested({
    key: "customerInfo",
    params,
    path: OMS_CUSTOMER_INFO,
    method: "POST",
    callback
  });
};

export const fetchUserWallet = (params, callback) => {
  return fetchRequested({
    key: "wallet",
    params,
    path: OMS_USER_WALLET,
    method: "POST",
    callback
  });
};

// Moved to services for JP to services for JP.
export const userLoginAction = (headers, params, callback) => {
  return fetchRequestedWithCaptcha({
    key: "userLogin",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_LOGIN,
    method: "POST",
    callback,
    meta: {
      captchaAction: "Login"
    }
  });
};

// Moved.
export const fetchLiquidUrl = (params, callback) => {
  return fetchRequested({
    key: "liquidURL",
    base: BASE.USER_SERVICE,
    params,
    path: USER_SERVICE_LIQUID_URL,
    method: "POST",
    callback
  });
};

//moved
export const getLiquidOcrData = (orderRef, callback) => {
  const path = `${USER_SERVICE_LIQUID_OCR_DATA}/${orderRef}`;
  const successData = { orderRef };

  return fetchRequested({
    key: "liquidOcrData",
    base: BASE.USER_SERVICE,
    path,
    callback,
    successData
  });
};

export const registerOcrData = (params, callback) => {
  return fetchRequested({
    key: "liquidOCRRegister",
    base: BASE.USER_SERVICE,
    params,
    method: "POST",
    path: USER_SERVICE_LIQUID_OCR_REGISTER,
    callback
  });
};

// Moved.
export const userLoginOTP = (headers, params, callback) => {
  return fetchRequested({
    key: "userLoginOTP",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_OTP,
    method: "POST",
    callback
  });
};

// Moved.
export const userAuthenticate = (headers, params, callback) => {
  return fetchRequested({
    key: "userAuthenticate",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_AUTHENTICATE,
    method: "POST",
    callback
  });
};

export const auIpdbUserAuthenticate = (headers, params, callback) => {
  return fetchRequested({
    key: "userAuthenticate",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_IPDB_AUTHENTICATE,
    method: "POST",
    callback
  });
};

export const fetchLoggedInUserDetails = (headers, params, callback) => {
  return fetchRequested({
    key: "loggedInUserDetails",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_LOGGEDIN_USER_DETAILS,
    callback
  });
};
//Moved
export const fetchUserProfileDetails = () => {
  return fetchRequested({
    key: "userProfileDetails",
    base: BASE.QUILT_SERVICE,
    path: QUILT_PROFILE_AGGREGATION
  });
};

export const requestMNPReservationNumber = (headers, params, callback) => {
  return fetchRequested({
    key: "requestMNPReservationNumber",
    base: BASE.TELCO_SERVICE,
    headers,
    params,
    path: CREATE_PORTOUT_REQUEST,
    method: "POST",
    callback
  });
};

export const fetchEsimQR = (headers, params, callback) => {
  return fetchRequested({
    key: "esimQR",
    base: BASE.API_GATEWAY,
    headers,
    params,
    path: API_GATEWAY_ESIM_QR,
    method: "POST",
    callback,
    mediaStream: true
  });
};

export const storeSocialLoginData = data => {
  return setData({
    key: "socialMediaAuthentication",
    data
  });
};

export const clearSocialLoginData = () => {
  return clearData([
    "AuIPDBData",
    "socialMediaAuthentication",
    "socialMediaMultipleEmails"
  ]);
};

export const storeSocialMultipleEmails = data => {
  return setData({
    key: "socialMediaMultipleEmails",
    data
  });
};

export const storeUserLostServiceData = data => {
  return setData({
    key: "userLostServiceData",
    data
  });
};

// fetch data to display payment option for upfront payment and card update payment
export const fetchUpfrontPaymentDetails = ({ token, ...params }, callback) => {
  return fetchRequested({
    key: "paymentDetails",
    base: BASE.PAYMENTS,
    params,
    headers: {
      "x-oms-token": token
    },
    path: UPFRONT_PAYMENT,
    method: "POST",
    callback
  });
};
//Moved
export const saveAndGetPaymentCardDetails = (
  { token, ...params },
  callback,
  version
) => {
  /* 
  TODO: Singapore doesn't use the v4 version of the payment's api, `payment/save_and_get_card_details`. Hence we are using v3
        for Singapore until v4 of the payment service deployed to singapore.
  */
  if (version === "v3") {
    return fetchRequested({
      key: "paymentDetails",
      base: BASE.PAYMENTS_SERVICE,
      params,
      path: SAVE_AND_GET_CARD_DETAILS,
      method: "POST",
      callback
    });
  }

  return fetchRequested({
    key: "paymentDetails",
    base: BASE.PAYMENTS,
    params,
    headers: {
      "x-oms-token": token
    },
    path: SAVE_AND_GET_CARD_DETAILS,
    method: "POST",
    callback
  });
};

// process payment for upfront payment and update card payment
export const processUpfrontPayment = ({ token, ...params }, callback) => {
  return fetchRequested({
    key: "processPayment",
    base: BASE.PAYMENTS,
    params,
    headers: {
      "x-oms-token": token
    },
    path: PROCESS_PAYMENT_METHOD,
    method: "POST",
    callback
  });
};
//Moved
export const processCardUpdatePayment = (
  { token, ...params },
  callback,
  version
) => {
  /* 
  TODO: japan doesn't use the v4 version of the payment's api, `payment/save_and_get_card_details`. Hence we are using v3
        for Singapore until v4 of the payment service deployed to singapore.
  */
  if (version === "v3") {
    return fetchRequested({
      key: "processPayment",
      base: BASE.PAYMENTS,
      params,
      path: PROCESS_CARD_UPDATE_PAYMENT_METHOD,
      method: "POST",
      version: "v3",
      callback
    });
  }

  return fetchRequested({
    key: "processPayment",
    base: BASE.PAYMENTS,
    params,
    headers: {
      "x-oms-token": token
    },
    path: PROCESS_CARD_UPDATE_PAYMENT_METHOD,
    method: "POST",
    callback
  });
};

export const fetchPaymentStatus = ({ token, ...params }, callback) => {
  return fetchRequested({
    key: "paymentStatus",
    base: BASE.PAYMENTS,
    params,
    headers: {
      "x-oms-token": token
    },
    version: "v3",
    path: PAYMENT_TRANSACTION_STATUS,
    method: "GET",
    callback
  });
};

export const fetchTelcoUserProfile = (headers, callback) => {
  return fetchRequested({
    key: "telcoUserProfile",
    base: BASE.MOBILE_API,
    headers,
    path: TELCO_PROFILE,
    method: "GET",
    callback
  });
};

export const userLogout = (headers, params, callback) => {
  return fetchRequested({
    key: "userLogout",
    base: BASE.USER_SERVICE,
    headers,
    params,
    path: USER_SERVICE_LOGOUT,
    method: "POST",
    callback
  });
};

export const fetchAgreementForm = (headers, params, callback) => {
  return fetchRequested({
    key: "contractualAgreement",
    base: BASE.API_GATEWAY,
    headers,
    params,
    path: API_GATEWAY_DOWNLOAD_SERVICE_AGREEMENT,
    method: "GET",
    callback,
    mediaStream: true,
    base64conversion: false
  });
};

export const emailAgreementForm = callback => {
  return fetchRequested({
    key: "emailAgreementForm",
    base: BASE.TELCO_SERVICE,
    path: TELCO_EMAIL_SERVICE_AGREEMENT,
    method: "POST",
    callback
  });
};

//moved
export const cancelPortOutRequest = (params, callback) => {
  return fetchRequested({
    key: "cancelPortOut",
    base: BASE.TELCO_SERVICE,
    path: CREATE_PORTOUT_REQUEST,
    method: "PATCH",
    callback,
    params
  });
};

export const submitScheduleDeliveryNewSim = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_SUBMIT_DELIVERY
    : OMS_SUBMIT_DELIVERY;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;

  return fetchRequested({
    key: "submitScheduleDelivery",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};
export const fetchEligibleServices = (headers, params, callback) => {
  return fetchRequested({
    key: "eligibilitySettings",
    base: BASE.QUILT_SERVICE,
    path: QUILT_ELIGIBILITY,
    method: "GET",
    headers,
    params,
    callback
  });
};

export const requestReversePortin = (headers, params, callback) => {
  return fetchRequested({
    key: "requestReversePortin",
    base: BASE.TELCO_SERVICE,
    path: REVERSE_PORTIN,
    method: "POST",
    headers,
    params,
    callback
  });
};

export const submitOrderCancellation = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_ORDER_CANCELLATION
    : OMS_ORDER_CANCELLATION;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "submitOrderCancellation",
    base,
    path,
    method: "POST",
    params,
    callback
  });
};

// Moved
export const postTerminateNumberRequest = (headers, params, callback) => {
  const path = USE_API_GATEWAY
    ? TELCO_REQUEST_TERMINATE
    : OMS_SUBMIT_TERMINATION;
  const base = USE_API_GATEWAY ? BASE.TELCO_SERVICE : BASE.OMS;
  return fetchRequested({
    key: "postTerminateNumberRequest",
    headers,
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const requestToWinback = callback => {
  const path = USE_API_GATEWAY ? QUILT_WINBACK_AGGREGATION : OMS_SUBMIT_WINBACK;
  const base = USE_API_GATEWAY ? BASE.QUILT_SERVICE : BASE.OMS;
  return fetchRequested({
    key: "winback",
    path,
    base,
    method: "GET",
    callback
  });
};

//Moved to reward service folder
export const cancelTerminationNumber = (params, callback) => {
  const path = USE_API_GATEWAY ? CANCEL_TERMINATION : OMS_CANCEL_TERMINATION;
  const base = USE_API_GATEWAY ? BASE.REWARDS_SERVICE : BASE.OMS;
  return fetchRequested({
    key: "cancelTermination",
    params,
    path,
    base,
    method: "POST",
    callback
  });
};

export const fetchUserKycDetails = (params, callback) => {
  return fetchRequested({
    key: "userKycDetails",
    base: BASE.USER_SERVICE,
    params,
    path: USER_SERVICE_USER_KYC,
    method: "GET",
    callback,
    whitelistedCodes: [400118]
  });
};

//moved
export const submitUserKycDetails = (params, callback) => {
  return fetchRequested({
    key: "submitUserKycDetails",
    base: BASE.USER_SERVICE,
    params,
    path: USER_SERVICE_SUBMIT_USER_KYC,
    method: "POST",
    callback
  });
};

//moved
export const updatePortInDetails = (params, callback) => {
  return fetchRequested({
    key: "updatePortInDetails",
    base: BASE.API_GATEWAY,
    path: API_GATEWAY_RESUBMIT_PORTIN_DETAILS,
    method: "POST",
    params,
    callback
  });
};

export const reInitiatePortIn = (params, callback) => {
  return fetchRequested({
    key: "reInitiatePortIn",
    params,
    path: TELCO_RE_INITIATE_NUMBER_PORT_IN,
    base: BASE.TELCO_PORT_IN_SERVICE,
    method: "POST",
    callback
  });
};

export const fetchOrderCancellationStatus = (params, callback) => {
  const path = USE_API_GATEWAY
    ? API_GATEWAY_ORDER_CANCELLATION_STATUS
    : OMS_ORDER_CANCELLATION_STATUS;
  const base = USE_API_GATEWAY ? BASE.API_GATEWAY : BASE.OMS;
  return fetchRequested({
    key: "fetchOrderCancellationStatus",
    base,
    path,
    method: "GET",
    params,
    callback
  });
};

//moved to User service folder
export const requestLinkSocialAccount = (params, callback) => {
  return fetchRequested({
    key: "linkSocialAccount",
    base: BASE.USER_SERVICE,
    params,
    path: USER_SERVICE_LINK_SOCIAL,
    method: "POST",
    callback
  });
};

/**
 * This is a special scenario where we need to update the user token which is received
 * in the url params. When the webapp is used in mobile app, mobile app will launch the
 * webview with the auth_token as a query param. In such cases we need to update the state
 * replacing existing auth_token
 *
 * @param {string} auth_token new auth token
 */
export const updateAuthToken = auth_token => {
  return fetchSucceeded({
    key: "userAuthenticate",
    data: { auth_token }
  });
};

export const fetchPopStations = params => {
  return fetchRequested({
    key: "popStations",
    params,
    path: OMS_POP_STATIONS,
    method: "GET"
  });
};

export const resetDistrictList = () => clearData("districtList");

export const resetStreetList = () => clearData("streetList");

export const resetStoreList = () => clearData("storeList");

export const fetchValidateSignature = (params, callback) => {
  return fetchRequested({
    key: "validateSignature",
    params,
    path: OMS_VALIDATE_SIGNATURE,
    method: "GET",
    callback
  });
};

export const fetchOutstandingBills = (params, callback) => {
  return fetchRequested({
    key: "outstandingBills",
    params,
    path: OMS_OUTSTANDING_BILLS,
    method: "POST",
    callback
  });
};

export const payOutstandingBills = (params, callback) => {
  return fetchRequested({
    key: "payOutstandingBills",
    params,
    path: OMS_PAY_OUTSTANDING_BILLS,
    method: "POST",
    callback
  });
};

export const checkTransactionStatus = (params, callback) => {
  return fetchRequested({
    key: "checkTransactionStatus",
    base: BASE.PAYMENTS_SERVICE,
    params,
    path: OMS_CHECK_PAYMENT_STATUS,
    method: "GET",
    callback
  });
};

export const { fetchRequested, fetchSucceeded, fetchFailed } = createActions({
  FETCH_REQUESTED: payload => payload,
  FETCH_SUCCEEDED: payload => payload,
  FETCH_FAILED: payload => payload
});

export const fetchRequestedWithCaptcha = params =>
  fetchRequested({ ...params, captcha: true });

export const uploadCisForm = (params, callback) => {
  return fetchRequested({
    key: "uploadCisForm",
    path: CIS_REQUEST,
    method: "POST",
    params,
    callback
  });
};

export const uploadCisHRForm = (params, callback) => {
  return fetchRequested({
    key: "uploadCisHRForm",
    path: CIS_REQUEST_HR,
    method: "POST",
    params,
    callback
  });
};

export const uploadCisPartnersForm = (params, callback) => {
  return fetchRequested({
    key: "uploadCisPartnersForm",
    path: CIS_PARTNERS,
    method: "POST",
    params,
    callback
  });
};

export const getCisPartnersField = (params, callback) => {
  const base = USE_REWARDS_CIS ? BASE.REWARDS : BASE.OMS;
  const path = USE_REWARDS_CIS
    ? `${CIS_PARTNERS_REWARDS_FORM_FIELDS}/${params.name}`
    : `${CIS_PARTNERS_CMS_FORM_FIELDS}`;
  params = USE_REWARDS_CIS ? {} : params;

  return fetchRequested({
    key: "cisPartner",
    path,
    method: "GET",
    base,
    params,
    callback
  });
};

export const fetchMyInfoDetails = (params, callback) => {
  return fetchRequested({
    key: "myInfoDetails",
    path: OMS_MYINFO,
    method: "GET",
    params,
    callback
  });
};

export const fetchMyInfoOTPToken = (params, callback) => {
  return fetchRequested({
    key: "myInfoOTPToken",
    path: OMS_MYINFO_OTP,
    method: "POST",
    params,
    callback
  });
};

export const verifyMyInfoOTP = (params, callback) => {
  return fetchRequested({
    key: "verifyMyInfoOTP",
    path: OMS_MYINFO_VERIFY_OTP,
    method: "POST",
    params,
    callback
  });
};

export const updateParentalConsent = (headers, params, callback) => {
  return fetchRequested({
    key: "updateParentalConsent",
    base: BASE.API_GATEWAY,
    path: API_GATEWAY_UPDATE_PARENTAL_CONSENT,
    method: "POST",
    headers,
    params,
    callback
  });
};

//moved
export const fetchOutstandingBillsInfo = (params, callback) => {
  return fetchRequested({
    key: "outstandingBillPayments",
    base: BASE.BILLING,
    path: BILL_DETAILS,
    method: "GET",
    params,
    callback,
    override: true
  });
};

export const fetchOutstandingPaymentIntent = (params, callback) => {
  return fetchRequested({
    key: "outstandingBillPaymentIntent",
    base: BASE.BILLING,
    path: BILL_PAYMENT_INTENT,
    method: "POST",
    params,
    callback,
    override: true
  });
};

//moved
export const fetchOutstandingPaymentStatus = (params, callback) => {
  return fetchRequested({
    key: "outstandingBillPaymentStatus",
    base: BASE.BILLING,
    path: BILL_PAYMENT_STATUS,
    method: "GET",
    params,
    callback,
    override: true
  });
};

export const submitAuIdAssertion = (params, callback) => {
  return fetchRequested({
    key: "auIdAssertion",
    base: BASE.USER_SERVICE,
    path: USER_SERVICE_IPDB_ID_ASSERTION,
    method: "POST",
    params,
    callback,
    override: true
  });
};

export {
  OMS,
  UserService,
  Payment,
  Quilt,
  Laas,
  Telco,
  Rewards,
  Billing,
  BSSMW
};
