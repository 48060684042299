import React, { useEffect, useState } from "react";
import { IMaskMixin } from "react-imask";
import IMask from "imask";
import { Input } from "@rebass/forms";

const MaskedInputElement = IMaskMixin(({ inputRef, ...props }) => (
  <Input {...props} ref={inputRef} />
));

export default ({ value: _value, onChange, onBlur, ...props }) => {
  const [value, setValue] = useState(_value);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }

    if (props.onChangeForAnalytics) props.onChangeForAnalytics(value);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, onChange]);

  useEffect(() => {
    setValue(_value);
  }, [_value]);

  const handleOnAccept = unMaskedValue => {
    setValue(unMaskedValue);
  };

  const handleOnBlur = e => {
    if (onBlur) {
      onBlur(e, value);
    }
  };

  return (
    <MaskedInputElement
      value={value}
      onAccept={handleOnAccept}
      onBlur={handleOnBlur}
      {...props}
    />
  );
};

export { IMask };
