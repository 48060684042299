import React, { memo, useEffect } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import queryString from "query-string";

import validate from "validations";
import { forms } from "utils/constants";
import SmartRender from "containers/SmartRender";
import usePostDocumentAPI from "hooks/usePostDocumentAPI";

/**
 * The container is used to support external Telcos that has issues doing onestop portout in webview
 */
const OneStopMnpBrowserInit = Component =>
  function Comp(props) {
    const { submitPostApi, cleanup } = usePostDocumentAPI();

    const redirectToSourceProvider = () => {
      const queryParams = queryString.parse(window.location.search);
      const URL = queryParams?.url;
      const params = {
        onestop: queryParams.onestop,
        param: queryParams.param,
        callback: queryParams.callback
      };
      submitPostApi(URL, params);
    };

    useEffect(() => {
      redirectToSourceProvider();
      return () => cleanup();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Component {...props} />;
  };

const mapStateToProps = createStructuredSelector({});
const mapDispatchToProps = {};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  reduxForm({
    form: forms.ONE_STOP_MNP_BROWSER_INIT,
    destroyOnUnmount: true,
    validate
  }),
  memo
)(OneStopMnpBrowserInit(SmartRender));
