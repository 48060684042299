import { createSelector } from "reselect";
import get from "lodash/get";
import { forms } from "utils/constants";

/**
 * Extracting Profile from values
 * @param {object} state
 */
const profileFormDomain = state =>
  get(state, `form.${forms.PROFILE_FORM}.values`, {});

/**
 * ************ Profile Form Selectors ************
 */
export const profileFormSelector = createSelector(
  profileFormDomain,
  profileForm => profileForm
);

/**
 * ************ Make Selectors ************
 */
export const makeSelectProfileForm = () => profileFormSelector;
