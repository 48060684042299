import { ANALYTICS_TYPES } from "../constants/AnalyticsTypeConstants";
import CleverTapAnalytics from "./type/CleverTapAnalytics";
import AdobeAnalytics from "./type/AdobeAnalytics";
import GoogleTagManagerAnalytics from "./type/GoogleTagManagerAnalytics";

class AnalyticsFactory {
  get() {
    const type = window.ANALYTICS_TYPE;
    if (!type) {
      //ByDefault initializing CleverTapAnalytics
      return CleverTapAnalytics;
    }
    const analyticsFile = ANALYTICS_TYPES[type];
    if (!analyticsFile) {
      throw new Error(
        "Invalid Analytics Type defined in environment config : " + type
      );
    }
    //Right now returning Adobe Analytics and we can change to return based on string later
    return AdobeAnalytics;
  }
  pushProfile(event, data, message) {
    AdobeAnalytics.pushProfile(event, data, message);
    CleverTapAnalytics.pushProfile(event, data, message);
  }
  pushEvent(event, data, message) {
    AdobeAnalytics.pushEvent(event, data, message);
    CleverTapAnalytics.pushEvent(event, data, message);
    GoogleTagManagerAnalytics.pushEvent(event, data, message);
  }
}

export default new AnalyticsFactory();
