import { createSelector } from "reselect";
import get from "lodash/get";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import {
  ORDER_ACTION_STATES,
  DEFAULT_TERMINATION_REASONS
} from "utils/constants";
import { localeSelector } from "ducks/selectors";
import {
  constructTerminationDates,
  constructTerminationReasons,
  formatDisplayNumber,
  formatCurrency
} from "utils/helpers";
import { numberFormatSelector, currencyFormatSelector } from "./appSettings";
import { terminationSelector } from "./forms/terminateNumber";

const orderDetailsDomain = state => get(state, "api.orderDetails.data", {});

const isWinbackModalViewDomain = state =>
  get(state, "api.isWinbackModalView.data", {});

const isLoading = state =>
  get(state, "api.selfActivationDeliveryOrder.loading", false);

const selfActivationOrderDetailsDomain = state =>
  get(state, "api.selfActivationDeliveryOrder.data", {});

const winbackDetailsDomain = state =>
  get(state, "api.winback.data.winback_options", {});

const winbackEligibleDomain = state => get(state, "api.winback.data", {});

const submitNumberChangeDomain = state =>
  get(state, "api.submitNumberChange.data", {});

export const makeSelectWinbackEligible = () =>
  createSelector(winbackEligibleDomain, winbackEligible => {
    return get(winbackEligible, "termination_eligibility", false);
  });

export const makeSelectSelectedWinback = () =>
  createSelector(terminationSelector, terminationDetails => {
    const selected_winback = terminationDetails?.selected_winback;
    let winback = "";
    if (selected_winback) {
      try {
        winback = JSON.parse(selected_winback);
      } catch (error) {
        winback = selected_winback;
      }
    }
    return winback;
  });

export const winbackDetailsSelector = createSelector(
  winbackDetailsDomain,
  winbackDetails => winbackDetails
);

export const orderDetailsSelector = createSelector(
  orderDetailsDomain,
  orderDetails => (isEmpty(orderDetails) ? null : orderDetails)
);

export const isWinbackModalViewSelector = createSelector(
  isWinbackModalViewDomain,
  isWinbackModalView => get(isWinbackModalView, "isWinbackModalView")
);

export const makeSelectOrderDetails = () =>
  createSelector(orderDetailsSelector, orderDetails => orderDetails);

export const makeSelectSelfActivationDeliveryOrder = () =>
  createSelector(selfActivationOrderDetailsDomain, order => order);

export const makeSelectOrderCreationStatus = () => isLoading;

export const makeSelectSelectedDate = createSelector(
  orderDetailsSelector,
  orderDetails => {
    return get(orderDetails, "selected_date");
  }
);

export const makeSelectNumberChangeDates = () =>
  createSelector(
    orderDetailsSelector,
    makeSelectSelectedDate,
    (orderDetails, selectedDate) => {
      return map(get(orderDetails, "numberChange_date"), date => {
        return {
          id: date,
          label: moment(date).format("ddd, DD MMM YYYY"),
          value: date
        };
      });
    }
  );

export const makeSelectOldPhoneNumber = () =>
  createSelector(orderDetailsSelector, orderDetails => {
    return get(orderDetails, "phone_number");
  });

export const formattedOldNumberSelector = createSelector(
  numberFormatSelector,
  orderDetailsSelector,
  (numberFormat, orderDetails) => {
    const number = get(orderDetails, "phone_number");
    return {
      formattedNumber: formatDisplayNumber(number, numberFormat)
    };
  }
);

export const formattedNewNumberSelector = createSelector(
  numberFormatSelector,
  orderDetailsSelector,
  (numberFormat, orderDetails) => {
    const number = get(orderDetails, "new_number");
    return {
      formattedNumber: formatDisplayNumber(number, numberFormat)
    };
  }
);

export const makeSelectFormattedOldNumber = () => formattedOldNumberSelector;

export const makeSelectFormattedNewNumber = () => formattedNewNumberSelector;

export const makeSelectCanCancelNumberChange = () =>
  createSelector(orderDetailsSelector, orderDetails => {
    return get(orderDetails, "can_cancel") === true;
  });

export const makeSelectCanRequestNumberChange = () =>
  createSelector(orderDetailsSelector, orderDetails => {
    return get(orderDetails, "eligibility.value") === true;
  });

export const makeSelectChangeNumberFee = () =>
  createSelector(
    orderDetailsSelector,
    currencyFormatSelector,
    (orderDetails, currencyFormat) => {
      let numberChangeFee = get(orderDetails, "number_change_fee");
      let price = get(numberChangeFee, "value", 0);

      //construct the structure for PayTodayCard
      return {
        items: [
          {
            description: formatCurrency(price, currencyFormat),
            discounted_value: 0,
            label: "manage_change_number_administration_fee",
            price: formatCurrency(price, currencyFormat),
            price_without_tax: formatCurrency(price, currencyFormat),
            type: "base",
            value: price
          }
        ],
        totalAmount: price,
        totalFixedPrice: formatCurrency(price, currencyFormat),
        totalPrice: formatCurrency(price, currencyFormat)
      };
    }
  );

export const makeSelectChangeNumberFeeTotalPrice = () =>
  createSelector(
    orderDetailsSelector,
    currencyFormatSelector,
    (orderDetails, currencyFormat) => {
      let numberChangeFee = get(orderDetails, "number_change_fee");
      let price = get(numberChangeFee, "value", 0);

      return formatCurrency(price, currencyFormat);
    }
  );

export const makeSelectCanCancelSuspension = () =>
  createSelector(orderDetailsSelector, orderDetails => {
    return (
      get(orderDetails, "state") === ORDER_ACTION_STATES.SUSPENSION_CANCELABLE
    );
  });

export const makeSelectCanRequestSuspension = () =>
  createSelector(orderDetailsSelector, orderDetails => {
    return get(orderDetails, "state") === ORDER_ACTION_STATES.SUSPENDABLE;
  });

export const makeSelectCanCancelTermination = () =>
  createSelector(orderDetailsSelector, orderDetails => {
    return get(orderDetails, "cancellable.value") === true;
  });

export const makeSelectCanRequestTermination = () =>
  createSelector(orderDetailsSelector, orderDetails => {
    return [
      ORDER_ACTION_STATES.TERMINATABLE,
      ORDER_ACTION_STATES.CANCEL_AND_TERMINATE,
      ORDER_ACTION_STATES.CANCELABLE
    ].includes(get(orderDetails, "state"));
  });

export const makeSelectSuspensionDetails = () =>
  createSelector(orderDetailsSelector, orderDetails => {
    return {
      suspensionPhoneNumber: get(orderDetails, "phone_number"),
      suspensionPricePerMonth: get(orderDetails, "price_per_month"),
      suspensionStartDate: moment(get(orderDetails, "start_date")).format(
        "DD MMMM YYYY"
      ),
      suspensionEndDate: moment(get(orderDetails, "end_date")).format(
        "DD MMMM YYYY"
      ),
      suspensionAmount: get(orderDetails, "amount")
    };
  });

export const makeSelectFormattedSuspensionNumber = () =>
  createSelector(
    orderDetailsSelector,
    numberFormatSelector,
    (orderDetails, numberFormat) => {
      const number = get(orderDetails, "phone_number");

      return {
        formattedNumber: formatDisplayNumber(number, numberFormat)
      };
    }
  );

export const makeSelectFormattedSuspensionTotalAmount = () =>
  createSelector(
    orderDetailsSelector,
    currencyFormatSelector,
    (orderDetails, currencyFormat) => {
      return {
        formattedPrice: formatCurrency(
          get(orderDetails, "amount"),
          currencyFormat
        )
      };
    }
  );

export const makeSelectTerminationDetails = () =>
  createSelector(
    orderDetailsSelector,
    localeSelector,
    (orderDetails, locale) => {
      const defaultTerminationReasons = DEFAULT_TERMINATION_REASONS.map(
        reason => ({
          title: get(locale, reason, reason)
        })
      );
      return isEmpty(orderDetails)
        ? {}
        : {
            state: get(orderDetails, "state"),
            terminationPhoneNumber: get(orderDetails, "phone_number"),
            terminationReasons: constructTerminationReasons(
              get(orderDetails, "reasons", defaultTerminationReasons)
            ),
            terminationDates: constructTerminationDates(
              get(orderDetails, "termination_date", [])
            ),
            terminationAmount: get(orderDetails, "amount", 25),
            winbacks: get(orderDetails, "winback", [])
          };
    }
  );

export const makeSelectTerminationMessageData = () =>
  createSelector(orderDetailsSelector, orderDetails => {
    const scheduleTerminationDate = get(
      orderDetails,
      "schedule_termination_date",
      ""
    );
    return {
      terminationPhoneNumber: get(orderDetails, "phone_number", ""),
      terminationDate: scheduleTerminationDate
        ? moment(scheduleTerminationDate).format("DD MMMM YYYY")
        : ""
    };
  });

export const makeSelectEligibility = () =>
  createSelector(orderDetailsSelector, orderDetails => {
    return get(orderDetails, "eligibility", {});
  });

export const makeSelectTerminationActionType = () =>
  createSelector(orderDetailsSelector, orderDetails => {
    const state = get(orderDetails, "state", "");
    return ORDER_ACTION_STATES.TERMINATABLE === state
      ? ORDER_ACTION_STATES.TERMINATE
      : state;
  });

export const winbackSelector = createSelector(
  winbackDetailsSelector,
  winback => {
    const rewards = map(winback, rewards => {
      return {
        header: rewards.reward,
        label: rewards.title,
        value: JSON.stringify(rewards)
      };
    });
    return rewards;
  }
);

export const makeSelectIsWinbackModalView = () => isWinbackModalViewSelector;
export const makeSelectwinback = () => winbackSelector;
export const makeSelectTerminationInformation = () => terminationSelector;
export const makeSelectInProgressReplaceSim = () =>
  createSelector(orderDetailsDomain, order => get(order, "in_progress", false));

export const makeSelectSwitchToEsimValues = props =>
  createSelector(orderDetailsSelector, orderDetails => {
    if (orderDetails) {
      const { phone_number, doc_type } = orderDetails;
      return {
        phone_number,
        doc_type: { value: doc_type }
      };
    }
  });

export const makeSelectReplaceWithEsimMessageData = () =>
  createSelector(orderDetailsSelector, orderDetails => {
    const {
      phone_number: phoneNumber,
      sim_replacement_fee: simReplacementFee,
      address,
      delivery_date: deliveryDate,
      delivery_slot: deliverySlot,
      state_message: stateMessage,
      instant_pickup_start_date_time: instantPickupStartDateTime,
      post_office_name: postOfficeName
    } = orderDetails || {};

    return {
      phoneNumber,
      simReplacementFee,
      address,
      deliveryDate,
      deliverySlot,
      stateMessage,
      instantPickupStartDateTime,
      postOfficeName
    };
  });

export const submitNumberChangeSelector = createSelector(
  submitNumberChangeDomain,
  submitNumberChangeDetail => {
    return submitNumberChangeDetail || { message: "" };
  }
);
export const makeSelectOrderActionStatusMessageData = () =>
  createSelector(
    orderDetailsSelector,
    submitNumberChangeSelector,
    (orderDetails, submitNumberChangeDetail) => {
      return {
        ...get(orderDetails, "eligibility", {}),
        ...submitNumberChangeDetail
      };
    }
  );
