import { createActions } from "redux-actions";

export const { fetchRequested, fetchSucceeded, fetchFailed } = createActions({
  FETCH_REQUESTED: payload => payload,
  FETCH_SUCCEEDED: payload => payload,
  FETCH_FAILED: payload => payload
});

export const fetchRequestedWithCaptcha = params =>
  fetchRequested({ ...params, captcha: true });
