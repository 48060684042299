import React from "react";
import { connect } from "react-redux";
import { Box } from "ui-components";
import PageHeader from "components/PageHeader";

const PaymentPending = ({ status }) => {
  return (
    <Box data-testid="payment-pending" variant="main">
      <PageHeader
        title={`payment_${status || "pending"}`}
        subtitle={`payment_${status || "pending"}_message`}
      />
    </Box>
  );
};

const mapStateToProps = state => {
  return {};
};

export const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPending);
