import React, { useEffect } from "react";
import { Box, Button, Flex, Text } from "ui-components";
import { COLORS, ROBOTO } from "ui-components/theme";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import map from "lodash/map";
import filter from "lodash/filter";
import sortBy from "lodash/sortBy";
import isFunction from "lodash/isFunction";
import { urlParam, safeEval } from "utils/helpers";
import { PHONE_PAYMENT_OPTIONS } from "utils/constants";
import FormattedMessage from "components/FormattedMessage";
import CheckboxField from "components/FormFields/CheckboxField";
import NumberSlider from "components/NumberSlider/";

const PhoneDetails = ({ phones, change, formData, data }) => {
  const {
    showPaymentCheckBox,
    showPaymentInfo,
    paymentTermSlider,
    paymentOptionsInfo
  } = data || {};
  const { phoneColorCode, phoneCapacity, phoneVariant, paymentTerm } =
    formData || {};
  const phoneId = urlParam("id");
  const phone = find(phones, { id: phoneId || get(phoneVariant, "phoneId") });
  const phonePaymentOption =
    get(formData, "phonePaymentOption") || PHONE_PAYMENT_OPTIONS.IPP;

  const resetSelection = () => {
    change("paymentTerm", null);
    change("phonePaymentCheckbox", null);
    change("phoneColorCode", null);
    change("phoneColorName", null);
    change("phoneCapacity", null);
    change("phoneVariant", null);
    change("phonePaymentOption", null);
    change("checkBoxValue", null);
  };

  useEffect(
    () => {
      if (phoneId && phoneVariant && phoneVariant.phoneId !== phoneId) {
        resetSelection();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [phone, phoneId, phoneVariant]
  );

  const getPhoneVariant = data => {
    const { colorCode = phoneColorCode, capacity = phoneCapacity } = data || {};
    return find(get(phone, "allPhoneVariants"), variant => {
      return variant.colorCode === colorCode && variant.capacity === capacity;
    });
  };

  useEffect(() => {
    isFunction(change) &&
      change(
        "phoneVariant",
        getPhoneVariant({ colorCode: phoneColorCode, capacity: phoneCapacity })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentTerm]);

  if (isEmpty(phone)) return null;

  const getCapacitiesForColor = () => {
    const allPhoneVariantsSelectedColor = filter(
      phone.allPhoneVariants,
      variant => {
        return variant.colorCode === phoneColorCode;
      }
    );

    return sortBy(
      map(allPhoneVariantsSelectedColor, variant => variant.capacity)
    );
  };

  const selectCheckBox = event => {
    change("phonePaymentCheckbox", event.target.checked);
  };

  const selectColor = ({ code, name }) => {
    change("phoneColorCode", code);
    change("phoneColorName", name);
    change("phoneCapacity", null);
    change("phoneVariant", null);
    change("phonePaymentOption", null);
  };

  const selectCapacity = capacity => {
    change("phoneCapacity", capacity);
    change(
      "phonePaymentOption",
      phonePaymentOption || PHONE_PAYMENT_OPTIONS.FULL
    );
    change("phoneVariant", getPhoneVariant({ capacity }));
  };

  const selectPaymentOption = paymentOption => {
    change("phonePaymentOption", paymentOption);
    change("phoneVariant", getPhoneVariant());
  };

  const renderColorButton = ({ name, code }) => {
    const selected = phoneColorCode === code;
    const size = selected ? [22, 28, 32, 34] : [20, 26, 30, 32];
    return (
      <Box key={code} px={0.5} mx={1} flexWrap={"wrap"}>
        <Button
          onClick={() => {
            selectColor({ name, code });
          }}
          data-testid="phone-color-btn"
          minWidth="20px"
          sx={{
            padding: ["0.25rem", "0.5rem", "0.5rem"],
            borderColor: selected ? "blue.4" : "gray.0",
            backgroundColor: "white !important",
            borderRadius: "50%"
          }}
          variant="outline"
          key={name}
        >
          <Box
            sx={{
              width: size,
              height: size,
              borderRadius: "50%",
              backgroundColor: code
            }}
          />
        </Button>
      </Box>
    );
  };

  const renderPaymentTermSlider = () => {
    const { condition, enable, label, field, data } = paymentTermSlider;
    // eslint-disable-next-line no-eval
    const canRender = safeEval(get, formData, condition, true);
    if (canRender && enable) {
      // from ims
      const {
        phoneVariant: {
          minInstallmentMonth,
          maxInstallmentMonth,
          installmentStep
        }
      } = formData;
      // from configmanager
      const { marks, min, max, step, defaultValue } = data;
      let markList = {};
      let count = minInstallmentMonth;
      while (count <= maxInstallmentMonth) {
        let data = count;
        markList[data] = data;
        count = count + installmentStep;
      }
      return (
        <Box flexDirection="column" mb={1} data-testid="number_slider_box">
          <NumberSlider
            field={field}
            label={label}
            marks={isEmpty(markList) ? marks : markList}
            min={minInstallmentMonth || min}
            max={maxInstallmentMonth || max}
            onChange={paymentTerm => {}}
            step={installmentStep || step}
            defaultValue={maxInstallmentMonth || defaultValue}
            formValues={formData}
            labelMessage={"payment_term"}
            justifyContent={"center"}
          />
        </Box>
      );
    }
  };

  const renderPhonePaymentCheckBox = () => {
    const { condition, enable, label, field } = showPaymentCheckBox;
    // eslint-disable-next-line no-eval
    const canRender = safeEval(get, formData, condition, true);
    if (canRender && enable) {
      return (
        <Box flexDirection="column" data-testid="phone_paymentCheck_box">
          <CheckboxField
            onChange={selectCheckBox}
            field={field}
            label={label}
            // input={{ checked: phonePaymentCheckbox }}
          />
        </Box>
      );
    }
  };

  const renderPaymentInfo = () => {
    const { condition, enable, content } = showPaymentInfo;
    // eslint-disable-next-line no-eval
    const canRender = safeEval(get, formData, condition, true);
    if (canRender && enable) {
      const style = {
        backgroundColor: COLORS.cyan[0],
        padding: "12px",
        borderRadius: "12px"
      };
      return (
        <Box
          sx={style}
          flexDirection="column"
          mb={1}
          data-testid="payment-info-box"
        >
          <Text mb={1} fontSize={1} fontFamily={ROBOTO} fontWeight="400">
            <FormattedMessage html id={content} />
          </Text>
        </Box>
      );
    }
  };

  const renderColorOptions = () => {
    return (
      <Box mb={4}>
        <Text mb={1} fontSize={3} fontFamily={ROBOTO} fontWeight="700">
          <FormattedMessage id="choose_color" />
        </Text>

        <Flex mx={-1} mb={1} flexWrap="wrap">
          {map(phone.variantColors, renderColorButton)}
        </Flex>
      </Box>
    );
  };

  const renderStorageOptions = () => {
    return (
      <Box mb={5}>
        <Text mb={1} fontSize={3} fontFamily={ROBOTO} fontWeight="700">
          <FormattedMessage id="choose_capacity" />
        </Text>

        <Flex mx={-1} flexWrap="wrap">
          {map(getCapacitiesForColor(), capacity => {
            const selected = phoneCapacity === capacity;

            return (
              <Button
                data-testid="btn-select-capacity"
                onClick={() => selectCapacity(capacity)}
                width={[0.45, 0.3]}
                minWidth={"60px"}
                sx={{
                  padding: ["0.5rem", "0.5rem", "0.75rem"],
                  borderColor: selected ? "blue.4" : "gray.0",
                  backgroundColor: "white !important"
                }}
                variant="outline"
                key={capacity}
                mx={1}
              >
                <Text
                  fontSize={["0.8rem", "0.9rem", "1rem"]}
                  fontFamily="Roboto"
                  fontWeight={selected ? "700" : "400"}
                >
                  {capacity} GB
                </Text>
              </Button>
            );
          })}
        </Flex>
      </Box>
    );
  };

  const renderPaymentOptions = () => {
    const phoneVariant = getPhoneVariant();
    if (isEmpty(phoneVariant)) return null;

    const sortedPaymentOptionsInfo = sortBy(paymentOptionsInfo, ["index"]);

    return (
      <Flex flexDirection="column" mb={7} data-testid="payment-option-section">
        <Text
          mb={1}
          mt={2}
          fontSize={3}
          fontFamily={ROBOTO}
          fontWeight="700"
          textAlign="center"
        >
          <FormattedMessage id="payment_options" />
        </Text>

        <Flex mx={-1} flexWrap={"wrap"} justifyContent="center">
          {renderInstallmentPaymentOption(phoneVariant)}
          {renderSplitPaymentOption(phoneVariant)}
          {renderFullPaymentOption(phoneVariant)}
        </Flex>
        {/* Add additional info under the paymentOption buttons */}
        {sortedPaymentOptionsInfo && sortedPaymentOptionsInfo.length > 0 && (
          <Flex mx={-1} flexWrap={"wrap"} justifyContent="center">
            {sortedPaymentOptionsInfo.map(data => {
              const label = get(data, "label");
              return (
                <Box
                  key={data.index}
                  width={[1 / 2, 1 / 3, 1 / 3]}
                  px={1}
                  mb={"0.5rem"}
                >
                  {label && <FormattedMessage html id={label} />}
                </Box>
              );
            })}
          </Flex>
        )}
      </Flex>
    );
  };

  const renderInstallmentPaymentOption = phoneVariant => {
    const eligible = get(
      phoneVariant,
      `paymentOption.${PHONE_PAYMENT_OPTIONS.IPP}.eligible`
    );
    if (!eligible) return null;
    const {
      installmentMonths,
      formattedInstallmentCost,
      discount,
      formattedOriginalInstallmentCost
    } = phoneVariant;
    return (
      <Box width={[1 / 2, 1 / 3, 1 / 3]} px={1} mb={"0.5rem"}>
        <Button
          onClick={() => {
            selectPaymentOption(PHONE_PAYMENT_OPTIONS.IPP);
          }}
          data-testid="instalment-payment-btn"
          minHeight={120}
          width={1}
          height="100%"
          sx={{
            "&:hover": {
              backgroundColor: "white"
            },
            borderRadius: "1.5rem",
            borderColor:
              phonePaymentOption === PHONE_PAYMENT_OPTIONS.IPP
                ? "blue.4"
                : "gray.0"
          }}
          variant="outline"
        >
          <Text fontFamily="Roboto" mb={2}>
            <FormattedMessage id="pay_amount_now" values={{ amount: "$0" }} />
          </Text>
          <Text fontFamily="Roboto" mb={1}>
            <FormattedMessage
              id="pay_amount_monthly"
              values={{ amount: formattedInstallmentCost }}
            />
          </Text>
          {discount > 0 && (
            <Text
              fontFamily="Roboto"
              fontWeight={300}
              my={1}
              style={{ textDecoration: "line-through" }}
            >
              <FormattedMessage
                id="pay_amount_monthly"
                values={{ amount: formattedOriginalInstallmentCost }}
              />
            </Text>
          )}
          <Text fontFamily="Roboto" size="sm" fontWeight={300}>
            <FormattedMessage
              id="installment_months_on_supported_card"
              values={{ installmentMonths }}
            />
          </Text>
        </Button>
      </Box>
    );
  };

  const renderSplitPaymentOption = phoneVariant => {
    const eligible = get(
      phoneVariant,
      `paymentOption.${PHONE_PAYMENT_OPTIONS.SPLIT}.eligible`
    );
    if (!eligible) return null;
    const {
      installmentMonths,
      formattedComboUpfrontCost,
      formattedComboInstallmentCost,
      discount,
      formattedOriginalComboInstallmentCost
    } = phoneVariant;
    return (
      <Box width={[1 / 2, 1 / 3, 1 / 3]} px={1} mb={"0.5rem"}>
        <Button
          onClick={() => {
            selectPaymentOption(PHONE_PAYMENT_OPTIONS.SPLIT);
          }}
          data-testid="split-payment-btn"
          minHeight={120}
          width={1}
          height="100%"
          sx={{
            "&:hover": {
              backgroundColor: "white"
            },
            borderRadius: "1.5rem",
            borderColor:
              phonePaymentOption === PHONE_PAYMENT_OPTIONS.SPLIT
                ? "blue.4"
                : "gray.0"
          }}
          variant="outline"
        >
          <Text fontFamily="Roboto" mb={2}>
            <FormattedMessage
              id="pay_amount_now"
              values={{ amount: formattedComboUpfrontCost }}
            />
          </Text>
          <Text fontFamily="Roboto" mb={1}>
            <FormattedMessage
              id="pay_amount_monthly"
              values={{ amount: formattedComboInstallmentCost }}
            />
          </Text>
          {discount > 0 && (
            <Text
              fontFamily="Roboto"
              fontWeight={300}
              my={1}
              style={{ textDecoration: "line-through" }}
            >
              <FormattedMessage
                id="pay_amount_monthly"
                values={{ amount: formattedOriginalComboInstallmentCost }}
              />
            </Text>
          )}
          <Text fontFamily="Roboto" size="sm" fontWeight={300}>
            <FormattedMessage
              id="installment_months_on_supported_card"
              values={{ installmentMonths }}
            />
          </Text>
        </Button>
      </Box>
    );
  };

  const renderFullPaymentOption = phoneVariant => {
    const eligible = get(
      phoneVariant,
      `paymentOption.${PHONE_PAYMENT_OPTIONS.FULL}.eligible`
    );
    if (!eligible) return null;
    const { formattedCost, discount, formattedOriginalCost } = phoneVariant;
    return (
      <Box width={[1 / 2, 1 / 3, 1 / 3]} px={1} mb={"0.5rem"}>
        <Button
          onClick={() => {
            selectPaymentOption(PHONE_PAYMENT_OPTIONS.FULL);
          }}
          data-testid="full-payment-btn"
          minHeight={120}
          width={1}
          height="100%"
          sx={{
            "&:hover": {
              backgroundColor: "white"
            },
            borderRadius: "1.5rem",
            borderColor:
              phonePaymentOption === PHONE_PAYMENT_OPTIONS.FULL
                ? "blue.4"
                : "gray.0"
          }}
          variant="outline"
        >
          <Text fontFamily="Roboto" mb={2}>
            <FormattedMessage id="full" />
          </Text>
          <Text fontFamily="Roboto">{formattedCost}</Text>
          {discount > 0 && (
            <Text
              fontFamily="Roboto"
              fontWeight={300}
              my={1}
              style={{ textDecoration: "line-through" }}
            >
              {formattedOriginalCost}
            </Text>
          )}
          <Text fontFamily="Roboto" size="sm" fontWeight={300}>
            <FormattedMessage id="due_on_checkout" />
          </Text>
          <Text fontFamily="Roboto" size="sm" fontWeight={300}>
            <FormattedMessage id="all_cards_accepted" />
          </Text>
        </Button>
      </Box>
    );
  };

  return (
    <Box
      data-testid="phone-details-box"
      px={6}
      variant="main"
      maxWidth="600px"
      mx="auto"
      padding={["1rem", "1.5rem"]}
      style={{ marginBottom: window.stickyFooterHeight }}
    >
      <Box mb={3}>
        <Text
          fontSize={["1.2rem", "1.4rem", "1.6rem", "1.8rem"]}
          fontFamily="Roboto"
          fontWeight="900"
          textAlign="center"
          color="black.2"
        >
          {phone.name}
        </Text>
      </Box>

      <Flex>
        <Box width={[0.3]}>
          <img src={phone.phoneImage} alt="phone" />
        </Box>
        <Box width={[0.7]} ml={6} mt={5}>
          {renderColorOptions()}
          {phoneColorCode && renderStorageOptions()}
        </Box>
      </Flex>
      <Flex flexDirection="column">
        {getPhoneVariant() && renderPaymentOptions()}
      </Flex>

      <Flex flexDirection="column">
        {showPaymentCheckBox && renderPhonePaymentCheckBox()}
        {showPaymentInfo && renderPaymentInfo()}
        {paymentTermSlider && renderPaymentTermSlider()}
      </Flex>
    </Box>
  );
};

export default PhoneDetails;
