import React from "react";
import DateOfBirthField from "components/FormFields/DateOfBirthField";

const DOI = ({ doiYearOptions, yearOptions, ...props }) => {
  const updatedProps = {
    ...props,
    yearOptions: doiYearOptions,
    field_prefix: "doi"
  };
  return <DateOfBirthField {...updatedProps} />;
};

export default DOI;
