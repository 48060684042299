import React from "react";
import { Box, StyledLink } from "ui-components";
import FormattedMessage from "components/FormattedMessage";

const HTML_LINK = ({
  label,
  textColor,
  onsubmit,
  underLine,
  containerStyles,
  textStyle,
  ...props
}) => {
  const underLineLink = underLine
    ? { textDecoration: "underline", ...textStyle }
    : { ...textStyle };
  const color = textColor ? { color: textColor } : { color: "#000000" };
  return (
    <Box
      mt={[24, 36]}
      sx={{ textAlign: "center", ...containerStyles }}
      data-testid="html_link"
      onClick={props[onsubmit]}
    >
      <StyledLink sx={underLineLink} style={color}>
        <FormattedMessage id={label} />
      </StyledLink>
    </Box>
  );
};

export default HTML_LINK;
