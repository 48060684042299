import React from "react";
import { Box, Flex, Button } from "ui-components";
import PageHeader from "components/PageHeader";
import FormattedMessage from "components/FormattedMessage";
import { MANAGE_ORDER_URL, forms } from "utils/constants";

const TERMINATION_STATUS = props => {
  const {
    manageAnotherOrder,
    reset,
    data,
    messageData,
    termination: { winbackOffer }
  } = props;

  const { title, banner, message } = data || {};

  return (
    <>
      <Box>
        <PageHeader title={title} />
        <Box>
          {banner && (
            <Flex justifyContent="space-evenly">
              <img
                width="auto"
                height="auto"
                margin="0 auto"
                src={banner}
                alt="termination-status-banner"
              />
            </Flex>
          )}

          {message && (
            <>
              <Flex justifyContent="space-evenly">
                <FormattedMessage
                  id={message}
                  values={{ ...messageData, winbackOffer }}
                  html
                />
              </Flex>
              <br />
            </>
          )}
        </Box>
      </Box>
      <Flex justifyContent="space-evenly" padding="1rem">
        <Button
          as="a"
          href={MANAGE_ORDER_URL}
          onClick={() => {
            if (reset) {
              reset(forms.MANAGE_ORDER_FORM);
              reset(forms.TERMINATE_NUMBER_FORM);
            }
            manageAnotherOrder && manageAnotherOrder();
          }}
        >
          <FormattedMessage id="manage_order_manage_another_order" />
        </Button>
      </Flex>
    </>
  );
};

export default TERMINATION_STATUS;
