import { useDispatch, useSelector } from "react-redux";
import { UserService } from "ducks/actions";
import isEmpty from "lodash/isEmpty";
import { paths } from "utils/constants";
import {
  gotoUrl,
  pathWithChannel,
  isWebView,
  loggedInUserDetailsAPIParams,
  updateTokenToApp,
  isAUPortinUser
} from "utils/helpers";
import useFetchOrdersAndRedirect from "./useFetchOrdersAndRedirect";
import {
  makeSelectGA4PlanDetails,
  makeSelectHasVisitedContractDetailsPage,
  makeSelectIsSignUpWithIPDB
} from "ducks/selectors";
import AdjustAnalytics from "analytics/type/AdjustAnalytics/AdjustAnalytics";
import { ADJUST_EVENTS } from "constants/AnalyticsTypeConstants";
import { featureFlagMergeStepEnabled } from "../utils/featureFlags";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";

const useAuthorizedUserRedirection = redirectChannel => {
  const dispatch = useDispatch();
  const fetchOrdersAndRedirect = useFetchOrdersAndRedirect(redirectChannel);
  const isSignUpWithAuIPDB = useSelector(makeSelectIsSignUpWithIPDB());
  const initiateFlowFromContractDetails = useSelector(
    makeSelectHasVisitedContractDetailsPage()
  );
  const GA4EventPlanDetails = useSelector(makeSelectGA4PlanDetails());

  /**
   * Decides what actions to take with the loggedin user details
   * Based on user types the redirection pages will defer
   * @param {object} authResponse Auth Api response
   */
  const onFetchUserDetailsSuccessful = (authResponse, userDetails, channel) => {
    const { first_login: firstLogin, auth_token } = authResponse;
    GA4EventsService.publish(
      userLogedInEventData({
        ...GA4EventPlanDetails,
        customer_id: userDetails?.external_id
      })
    );

    if (auth_token) updateTokenToApp(auth_token);

    // In both normal signup and AU ipdb signup if it is first login, account created adjust event is triggered
    if (firstLogin) {
      AdjustAnalytics.pushEvent(ADJUST_EVENTS.ACCOUNT_CREATED);
    }

    const reload = isWebView();

    if (featureFlagMergeStepEnabled()) {
      // checking if the user is previously AU portin tried user. (AU linked)
      const isPreviousAUUser = isAUPortinUser(userDetails);
      // JP-2486: All AU ipdb signup users to go through order creation. (i.e. No signin to old account)
      // JP-14750 All normal sign up users go through the new contract details page.
      if (
        firstLogin &&
        !isSignUpWithAuIPDB &&
        initiateFlowFromContractDetails &&
        !isPreviousAUUser
      ) {
        return gotoUrl(
          pathWithChannel(paths.ORDER_SUMMARY, redirectChannel),
          false,
          {},
          reload
        );
      } else if (firstLogin || isSignUpWithAuIPDB) {
        return gotoUrl(
          pathWithChannel(paths.CONNECTION_DETAILS, redirectChannel),
          false,
          {},
          reload
        );
      }
    } else {
      // JP-2486: All AU ipdb signup users to go through order creation. (i.e. No signin to old account)
      if (firstLogin || isSignUpWithAuIPDB) {
        return gotoUrl(
          pathWithChannel(paths.CONNECTION_DETAILS, redirectChannel),
          false,
          {},
          reload
        );
      }
    }

    return fetchOrdersAndRedirect(channel, null, userDetails);
  };

  /**
   * Triggers user routing upon successful authentication
   * @param {object} authResponse success response result
   * @param {object} errors API errors only if failed
   */
  const getLoggedinUserDetails = (authResponse, errors, channel) => {
    if (isEmpty(errors)) {
      const header = {
        "X-AUTH": authResponse.auth_token
      };
      const params = loggedInUserDetailsAPIParams();
      dispatch(
        UserService.V4.fetchLoggedInUserDetails(
          header,
          params,
          (userDetails, errors) =>
            isEmpty(errors) &&
            onFetchUserDetailsSuccessful(authResponse, userDetails, channel)
        )
      );
    }
  };

  /**
   * When user signup via au first time, user is redirected to the
   * au lost service page, otherwise call getLoggedinUserDetails api
   */
  return (authResponse, errors, channel) => {
    getLoggedinUserDetails(authResponse, errors, channel);
  };
};

export default useAuthorizedUserRedirection;

const userLogedInEventData = (details = {}) => {
  const {
    customer_id,
    plan_name,
    plan_type,
    journey_type
  } = details;

  return {
    event: GA4EVENTS.USER_LOGGED_IN,
    ecommerce: {
      customer_id,
      plan_name,
      journey_type,
      plan_type,
      sim_type: "",
      number_type: ""
    }
  };
};
