import React from "react";
import { Avatar } from "ui-components";
import { gotoUrl } from "utils/helpers";
import { paths } from "utils/constants";

const ProfileIcon = ({ profileImageUrl, visibility = false, data = {} }) => {
  const { avatarStyles = {}, defaultProfileImageUrl } = data;

  const onClickEvent = () => {
    gotoUrl(paths.PROFILE);
  };

  if (!visibility) return null;

  return (
    <Avatar
      src={profileImageUrl || defaultProfileImageUrl}
      onClick={onClickEvent}
      data-testid="profile-image"
      style={avatarStyles}
    />
  );
};

ProfileIcon.defaultProps = {};

export default ProfileIcon;
