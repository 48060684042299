import React, { useEffect, memo } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import get from "lodash/get";
import Spinner, { styles } from "components/Spinner";
import ScheduleDeliveryStatus from "./ScheduleDeliveryStatus";
import ScheduleDeliveryForm from "./ScheduleDeliveryForm";
import { paths } from "utils/constants";
import { fetchUserOrders, setData } from "ducks/actions";

import {
  makeSelectIsFetchingData,
  makeSelectScheduleDeliveryDetails,
  makeSelectGuestOrderOrderRef,
  makeSelectUserOrderDetailsSelector,
  makeSelectIsModalView,
  makeSelectScheduleDeliveryPayload,
  makeSelectSessionToken
} from "ducks/selectors";
import NotEligible from "components/NotEligible";
import { OMS } from "ducks/actions";

const ScheduleDelivery = props => {
  const {
    match,
    fetchDeliveryDetails,
    scheduleDeliveryDetails,
    location,
    isSubmitting,
    token
  } = props;

  const {
    order_type: orderType,
    delivery_type: deliveryType,
    status: deliveryStatus
  } = get(match, "params", {});

  const nextPage = get(location, "pathname", "") + "/success";

  const updatedProps = {
    ...props,
    orderType,
    deliveryType,
    deliveryStatus,
    token,
    nextPage
  };

  useEffect(
    () => {
      if (!deliveryStatus) {
        const params = {
          order_type: orderType,
          delivery_type: deliveryType,
          token
        };
        fetchDeliveryDetails(params);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderType, deliveryType, deliveryStatus, token]
  );
  if (!token) {
    return <NotEligible />;
  }

  if (!scheduleDeliveryDetails || isSubmitting)
    return <Spinner size={200} sx={styles.fullPage} />;

  const ScheduleDeliveryComponent =
    deliveryStatus && deliveryStatus !== paths.SCHEDULE_DELIVERY_CONFIRMATION
      ? ScheduleDeliveryStatus
      : ScheduleDeliveryForm;

  return <ScheduleDeliveryComponent {...updatedProps} />;
};

const mapStateToProps = state => {
  return createStructuredSelector({
    scheduleDeliveryDetails: makeSelectScheduleDeliveryDetails(),
    isSubmitting: makeSelectIsFetchingData("submitScheduleDelivery"),
    isFetchingDeliverySlots: makeSelectIsFetchingData("deliverySlots"),
    orderRef: makeSelectGuestOrderOrderRef(),
    initialValues: makeSelectUserOrderDetailsSelector(),
    isModalView: makeSelectIsModalView(),
    payload: makeSelectScheduleDeliveryPayload(),
    token: makeSelectSessionToken()
  });
};

export const mapDispatchToProps = dispatch => {
  return {
    fetchDeliveryDetails: params =>
      dispatch(OMS.V4.fetchDeliveryDetails(params)),
    fetchUserOrders: () => {
      dispatch(fetchUserOrders({}));
    },
    scheduleDeliverNewSim: () => {
      dispatch(setData({ key: "isModalView", data: { isModalView: true } }));
    },
    hideModal: () => {
      dispatch(setData({ key: "isModalView", data: { isModalView: false } }));
    }
  };
};

ScheduleDelivery.defaultProps = {
  fetchUserOrders: () => {}
};
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ScheduleDelivery);
