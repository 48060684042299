import React from "react";
import PropTypes from "prop-types";
import { Box, Flex, Text, H2 } from "ui-components";
import { CheckCircle, PlusCircleOutline } from "components/Icons";
import FormattedMessage from "components/FormattedMessage";
import HighlightText from "components/HighlightText";

const SpecialAddOnCard = ({
  onSelect,
  id,
  description,
  subtitle,
  price,
  selected,
  primaryColor,
  title,
  disabled,
  highlightText,
  themeColors
}) => {
  return (
    <Box mb={8}>
      {title && (
        <H2 data-testid="addon-select-title" mb={4}>
          <FormattedMessage id={title} />
        </H2>
      )}
      <Flex
        alignItems="center"
        justifyContent="space-between"
        data-testid="addon-card"
        sx={{
          // cursor: disabled ? "none" : "",
          opacity: disabled ? 0.2 : 1
        }}
      >
        <Flex
          width="80%"
          alignItems="center"
          // sx={{ cursor: !disabled ? "pointer" : "none !important" }}
          onClick={() => (!disabled ? onSelect(id, !selected) : () => {})}
        >
          <Box width="150" data-testid="addon-card-check" minWidth="36px">
            {selected ? (
              <CheckCircle size="36px" color={primaryColor} />
            ) : (
              <PlusCircleOutline size="36px" color={primaryColor} />
            )}
          </Box>

          <Box
            width={1}
            sx={{
              wordBreak: "break-word"
            }}
          >
            <Text mx={6} mb={1} fontWeight="700" lineHeight="1.2">
              <FormattedMessage id={subtitle} />
            </Text>
            {highlightText && (
              <HighlightText
                text={highlightText}
                {...(themeColors?.badge || {})}
              />
            )}
            <Text size="sm" mx={6} lineHeight="1.2">
              <FormattedMessage id={description} />
            </Text>
          </Box>
        </Flex>

        <Box textAlign="center">
          <Text lineHeight="1.2" fontWeight="100">
            <b>{price}</b>
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};

SpecialAddOnCard.defaultProps = {
  label: "",
  description: "",
  price: "",
  value: 0
};

SpecialAddOnCard.propTypes = {
  onSelect: PropTypes.func,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.number.isRequired
};

export default SpecialAddOnCard;
