import React from "react";
import get from "lodash/get";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import { Box, Flex, Text, H1, Button } from "ui-components";
import { getButtonColorVariant, buttonThemes } from "ui-components/theme";
import FormattedMessage from "components/FormattedMessage";
import DropdownField from "components/FormFields/DropdownField";
import TextField from "components/FormFields/TextField";
import DateOfBirthField from "components/FormFields/DateOfBirthField";
import Label from "components/FormFields/Label";
import { normalizerTypes } from "normalizers";
import { paths } from "utils/constants";
import { gotoUrl, urlParam } from "utils/helpers";

const ICC_ID = ({
  iccIdPrefix,
  label,
  required,
  manualMode,
  setManualMode,
  iccIdInUrlParam,
  iccIdValid,
  iccIdError,
  resetValidateIccId,
  resetIccIdError,
  themeColors,
  thirdParty,
  scanOnly,
  iccIdDetails,
  minimumAge,
  dateOptions,
  monthOptions,
  yearOptions,
  change,
  sendScanBarcodeEvent = () => {},
  sendManualBarcodeEvent = () => {},
  ...props
}) => {
  const showDob = includes(get(iccIdDetails, "required", []), "dob");
  const showIccIdField =
    (!scanOnly && manualMode && !iccIdInUrlParam) || showDob;
  const showScanButton =
    scanOnly || (!manualMode && (!iccIdInUrlParam || iccIdError));
  const showManualButton =
    !scanOnly &&
    ((!manualMode && !iccIdInUrlParam) || (iccIdInUrlParam && iccIdError));
  const buttonTheme = get(themeColors, "button") || buttonThemes.blue;
  const thirdPartyTenants = get(thirdParty, "tenants", []);
  const thirdPartyTenant = get(thirdParty, "tenant", "");
  const thirdPartyOrderRefRequired = get(thirdParty, "orderRefRequired");
  const thirdPartyOrderRefRegex = get(thirdParty, "orderRefRegex");
  const thirdPartyOrderRefMaxLength = get(thirdParty, "orderRefMaxLength");
  const userNricRequired = get(thirdParty, "nricRequired");
  const userNricRegex = get(thirdParty, "nricRegex");
  const userNricMaxLength = get(thirdParty, "nricMaxLength");
  const userDobRequired = get(thirdParty, "dobRequired");
  const thirdPartyValid = get(thirdParty, "valid");

  const handleDOBChange = (...args) => {
    change(...args);
    resetIccIdError && resetIccIdError();
  };

  return (
    <>
      {showIccIdField && (
        <Flex
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: "fit-content",
            flexDirection: "column",
            margin: "0 auto"
          }}
        >
          {label && (
            <Flex sx={{ justifyContent: "flex-start", width: "100%" }}>
              <Label
                label={<FormattedMessage id={label} />}
                required={required}
              />
            </Flex>
          )}
          <Flex sx={{ justifyContent: "center", alignItems: "baseline" }}>
            <Text mb={8} mr={2}>
              <input
                type="text"
                readOnly
                value={iccIdPrefix}
                style={{ textAlign: "center" }}
              />
            </Text>
            <TextField
              {...props}
              onChange={() => resetValidateIccId && resetValidateIccId()}
            />
          </Flex>
        </Flex>
      )}

      {iccIdInUrlParam && !showDob && (
        <Flex sx={{ justifyContent: "center", alignItems: "center" }}>
          <H1 mb={4}>{urlParam("iccid")}</H1>
        </Flex>
      )}

      {iccIdValid && !isEmpty(thirdPartyTenants) && (
        <Box
          sx={{
            width: "324px",
            maxWidth: "100%",
            margin: "auto"
          }}
        >
          <DropdownField
            label="where_did_you_purchase_the_sim"
            options={thirdPartyTenants}
            field="third_party_tenant"
            required={true}
            onChange={() => resetValidateIccId && resetValidateIccId()}
          />
          {thirdPartyOrderRefRequired && (
            <TextField
              label={`enter_your_${thirdPartyTenant}_order_number`}
              field="third_party_order_ref"
              pattern={thirdPartyOrderRefRegex}
              required={thirdPartyOrderRefRequired}
              onChange={() => resetValidateIccId && resetValidateIccId()}
              inlineValidation={{ valid: thirdPartyValid }}
              normalize={normalizerTypes.THIRD_PARTY_ORDER_REF({
                max_length: thirdPartyOrderRefMaxLength,
                upper_case: true
              })}
            />
          )}
          {userNricRequired && (
            <TextField
              label="personal_details_nric"
              placeholder="personal_details_nric_placeholder"
              normalize={normalizerTypes.ALPHANUMERIC_WITH_MAX_LENGTH({
                max_length: userNricMaxLength,
                upper_case: true
              })}
              field="nric"
              pattern={userNricRegex}
              required={userNricRequired}
              onChange={() => resetValidateIccId && resetValidateIccId()}
              inlineValidation={{ valid: thirdPartyValid }}
            />
          )}
          {userDobRequired && (
            <DateOfBirthField
              required={userDobRequired}
              label="personal_details_date_of_birth"
              minimumAge={minimumAge}
              dateOptions={dateOptions}
              monthOptions={monthOptions}
              yearOptions={yearOptions}
              change={handleDOBChange}
              {...props}
            />
          )}
        </Box>
      )}
      {showDob && (
        <Flex
          sx={{
            justifyContent: "center",
            alignItems: "baseline",
            width: "100%"
          }}
        >
          <DateOfBirthField
            required={showDob}
            minimumAge={minimumAge}
            dateOptions={dateOptions}
            monthOptions={monthOptions}
            yearOptions={yearOptions}
            change={handleDOBChange}
            {...props}
          />
        </Flex>
      )}
      {iccIdError && (
        <Flex sx={{ justifyContent: "center", alignItems: "center" }}>
          <H1 mb={4} color="red">
            <FormattedMessage id={iccIdError} />
          </H1>
        </Flex>
      )}
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "fit-content",
          margin: "auto"
        }}
      >
        {showScanButton && (
          <Button
            mx={1}
            width={[1]}
            sx={getButtonColorVariant(buttonTheme)}
            onClick={() => {
              sendScanBarcodeEvent();
              window.location.href = paths.SCANNER;
            }}
          >
            <FormattedMessage id="scan_barcode_with_camera" />
          </Button>
        )}

        {showManualButton && (
          <Button
            mx={1}
            width={[1]}
            variant="outline"
            sx={getButtonColorVariant(buttonTheme, "outline")}
            onClick={() => {
              sendManualBarcodeEvent();
              gotoUrl(
                window.location.pathname,
                true,
                { reset: undefined, iccid: undefined },
                false
              );
              resetValidateIccId && resetValidateIccId();
              setManualMode(true);
            }}
          >
            <FormattedMessage id="enter_code_manually" />
          </Button>
        )}
      </Flex>
    </>
  );
};

export default ICC_ID;
