import { Flex, Image } from "rebass";
import { Input, Select, Checkbox, Radio, Textarea } from "@rebass/forms";
import { ThemeProvider } from "emotion-theming";
import { Global, css } from "@emotion/core";
import { Tiles } from "@rebass/layout";
import Box from "./src/Box";
import Button from "./src/Button";
import Text from "./src/Text";
import { H1, H2, H3, H4, H5 } from "./src/Heading";
import NavLink from "./src/NavLink";
import Label from "./src/Label";
import Link, { StyledLink } from "./src/Link";
import P from "./src/Paragraph";
import Avatar from "./src/Avatar";
import Badge from "./src/Badge";
import MaskedInput, { IMask } from "./src/MaskedInput";
import ImageFromBase64 from "./src/ImageFromBase64";
import theme from "./theme";

import roboto_v27_latin_regular_eot from "./fonts/roboto/roboto-v27-latin-regular.eot";
import roboto_v27_latin_regular_ttf from "./fonts/roboto/roboto-v27-latin-regular.ttf";
import roboto_v27_latin_regular_woff from "./fonts/roboto/roboto-v27-latin-regular.woff";
import roboto_v27_latin_regular_woff2 from "./fonts/roboto/roboto-v27-latin-regular.woff2";
import roboto_v27_latin_regular_svg from "./fonts/roboto/roboto-v27-latin-regular.svg";

const globalStyles = css`
  @font-face {
    font-family: "Roboto";
    src: url("${roboto_v27_latin_regular_eot}?#iefix") format("embedded-opentype"), /* IE6-IE8 */
       url(${roboto_v27_latin_regular_ttf}) format("truetype"), /* Safari, Android, iOS */
       url(${roboto_v27_latin_regular_woff}) format("woff"), /* Modern Browsers */
       url(${roboto_v27_latin_regular_woff2}) format("woff2"), /* Super Modern Browsers */
       url("${roboto_v27_latin_regular_svg}#Roboto") format("svg"); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
  }
}

  * {
    box-sizing: border-box;
  }
  body {
    background-color: #f7f7f7;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
  }
  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  a,
  button {
    color: inherit;
    text-decoration: none;
    user-select: none;
  }
  input[type="radio"] {
    display: none;
  }
  label {
    cursor: pointer;
  }
  *:focus {
    outline: none;
  }
  main {
    min-height: calc(100vh - 245px);
  }
`;

const DesignCore = {
  ThemeProvider,
  Global,
  globalStyles,
  theme
};

export {
  DesignCore,
  Box,
  Button,
  Checkbox,
  Radio,
  Flex,
  H1,
  H2,
  H3,
  H4,
  H5,
  Input,
  Label,
  Link,
  StyledLink,
  NavLink,
  Select,
  Text,
  Textarea,
  P,
  Avatar,
  Image,
  Tiles,
  Badge,
  MaskedInput,
  IMask,
  ImageFromBase64
};
