import get from "lodash/get";
import { createSelector } from "reselect";

const contractDetailsDomain = state => get(state, "ui.contractDetails", {});

export const hasVisitedContractDetailsPage = createSelector(
  contractDetailsDomain,
  contractDetails => get(contractDetails, "pageVisited", false)
);

export const makeSelectHasVisitedContractDetailsPage = () =>
  hasVisitedContractDetailsPage;
