import React from "react";
import get from "lodash/get";
import { Box } from "ui-components";
import FormattedMessage from "components/FormattedMessage";

const HTML_MESSAGE = props => {
  const { data, messageData, styles } = props;
  return (
    <Box mb={get(data, "marginBottom", 8)} sx={{ ...styles }}>
      <FormattedMessage html id={data.message} values={messageData} />
    </Box>
  );
};

export default HTML_MESSAGE;
