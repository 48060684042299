import React, { useState } from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import get from "lodash/get";
import slice from "lodash/slice";
import isEmpty from "lodash/isEmpty";
import FormattedMessage from "components/FormattedMessage";
import NumberSelectButton from "components/NumberSelectButton";
import { Flex, Box, Label, Button } from "ui-components";
import {
  ChevronDown,
  ChevronUp,
  PlusCircleOutline,
  MinusCircleOutline
} from "components/Icons";
import { iconThemes, COLORS, buttonThemes } from "ui-components/theme";
import Spinner, { styles } from "components/Spinner";

const NumberSelection = ({
  items,
  onSelect,
  initialItems,
  collapsed,
  minItems,
  maxItems,
  price,
  title,
  choose_another_text,
  see_more_text,
  see_less_text,
  theme,
  themeColors
}) => {
  const [moreNumberCount, setMoreNumberCount] = useState(initialItems);
  const [showNumbers, setShowNumbers] = useState(!collapsed);

  const labelValues =
    get(price, "price") > 0 && get(price, "formattedPrice")
      ? { price: get(price, "formattedPrice") }
      : {};

  const primaryColor = get(themeColors, "primary") || COLORS.blue[4];
  const themeColor = get(iconThemes, [theme], primaryColor);
  const primaryButtonTheme = get(themeColors, "button") || buttonThemes["blue"];
  const buttonTheme = get(buttonThemes, [theme], primaryButtonTheme);

  return (
    <Box
      mb={6}
      variant="borderSection"
      sx={{
        color: themeColor,
        borderColor: themeColor
      }}
    >
      {title && (
        <Label
          sx={{
            color: "inherit",
            fontSize: [
              "1rem !important",
              "1.1rem !important",
              "1.2rem !important"
            ],
            cursor: "pointer"
          }}
          mb={showNumbers ? 4 : 0}
          onClick={() => setShowNumbers(!showNumbers)}
        >
          <Flex justifyContent="space-between" alignItems="center" width="100%">
            <FormattedMessage html id={title} values={labelValues} />
            <Button variant="closeButton" as="a">
              {showNumbers ? (
                <ChevronUp size={"30px"} color={themeColor} />
              ) : (
                <ChevronDown size={"30px"} color={themeColor} />
              )}
            </Button>
          </Flex>
        </Label>
      )}
      {showNumbers && isEmpty(items) && <Spinner sx={styles.modalContainer} />}
      {showNumbers && !isEmpty(items) && (
        <>
          <Flex flexWrap="wrap" mx={-1} mb={3}>
            {map(slice(items, 0, moreNumberCount), item => {
              return (
                <NumberSelectButton
                  key={item.id}
                  onSelect={onSelect}
                  buttonTheme={buttonTheme}
                  {...item}
                />
              );
            })}
          </Flex>

          <Flex
            data-testid="number-select-toggle"
            textAlign="center"
            alignItems="center"
            justifyContent="center"
            onClick={() => {
              const numberCount =
                moreNumberCount < minItems || moreNumberCount === maxItems
                  ? minItems
                  : maxItems;
              setMoreNumberCount(numberCount);
            }}
            color="inherit"
            fontWeight="500"
            fontSize={["14px", "16px"]}
            sx={{
              cursor: "pointer",
              "& > svg": { margin: 0 },
              color: `${primaryColor} !important`
            }}
          >
            <FormattedMessage
              id={
                moreNumberCount === 0
                  ? choose_another_text
                  : items.length > moreNumberCount
                  ? see_more_text
                  : see_less_text
              }
            />
            &nbsp;
            {items.length > moreNumberCount ? (
              <PlusCircleOutline color={primaryColor} />
            ) : (
              <MinusCircleOutline color={primaryColor} />
            )}
          </Flex>
        </>
      )}
    </Box>
  );
};

NumberSelection.defaultProps = {
  initialItems: 0,
  minItems: 12,
  maxItems: 30,
  items: [],
  price: {},
  title: "",
  choose_another_text: "choose_another_number",
  see_more_text: "see_more_numbers",
  see_less_text: "see_less_numbers",
  collapsed: true
};

NumberSelection.propTypes = {
  items: PropTypes.array.isRequired
};

export default NumberSelection;
