import React, { useEffect } from "react";
import { ChevronRight } from "components/Icons";
import { Box, Flex } from "ui-components";
import { CALL_BACK_URL } from "utils/constants";
import queryString from "query-string";
import { useParams } from "react-router-dom";
import useLoadAmazonScript from "hooks/useLoadAmazonSript";
import Alert from "react-s-alert";

const AmazonLoginBtnWidget = props => {
  const { channel } = useParams();
  const { js_sdk_url, seller_id, client_id, login_btn_config, enable } = props;
  const { isScriptLoaded } = useLoadAmazonScript({ js_sdk_url, client_id });

  const showButton = () => {
    window.OffAmazonPayments.Button("AmazonPayButton", seller_id, {
      ...login_btn_config,
      authorization: () => {
        const loginOptions = { scope: "profile payments:widget", popup: true };
        const callBackUrl = queryString.stringifyUrl(
          { url: CALL_BACK_URL.AMAZON_PAY, query: { channel: channel } },
          { skipNull: true }
        );
        window.amazon.Login.authorize(loginOptions, callBackUrl);
      },
      onError: function(error) {
        Alert.error(error.getErrorMessage());
      }
    });
  };

  useEffect(() => {
    if (isScriptLoaded) {
      showButton();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScriptLoaded]);

  return !enable ? null : (
    <Flex justifyContent="space-between" alignItems="center">
      <Box id="AmazonPayButton" />
      <Box width={["36px"]} mx={1}>
        {<ChevronRight size="30px" color={"#333333"} />}
      </Box>
    </Flex>
  );
};

export default AmazonLoginBtnWidget;
