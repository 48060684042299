import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import get from "lodash/get";
import { Box, Flex, Text, H1, Button } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import moment from "moment";

const REQUEST_SUSPENSION_MODAL = props => {
  const {
    styles,
    handleSubmit,
    invalid,
    showCardChargePrompt,
    suspensionPhoneNumber,
    selectedSuspensionStartDate,
    selectedSuspensionEndDate,
    suspensionPaymentDetails,
    submitSuspension,
    suspensionPricePerMonth,
    orderRef,
    fetchSuspensionPaymentDetails
  } = props;

  const [showModal, setShowModal] = useState(false);

  useEffect(
    () => {
      if (selectedSuspensionStartDate && selectedSuspensionEndDate) {
        fetchSuspensionPaymentDetails({
          orderRef,
          startDate: selectedSuspensionStartDate,
          endDate: selectedSuspensionEndDate,
          amount: suspensionPricePerMonth
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedSuspensionStartDate, selectedSuspensionEndDate]
  );

  const submit = values => {
    submitSuspension({
      orderRef,
      suspensionPhoneNumber,
      suspensionPricePerMonth,
      ...values,
      ...suspensionPaymentDetails
    });
  };

  const cardNetwork = get(suspensionPaymentDetails, "card_network", "");
  const cardNumber = get(suspensionPaymentDetails, "card_number", "");
  const chargeableAmount = get(suspensionPaymentDetails, "amount", "");

  return (
    <>
      {showCardChargePrompt && (
        <>
          <FormattedMessage
            id="order_action_suspend_number_your_card_will_be_charged"
            values={{
              cardNetwork,
              cardNumber,
              chargeableAmount
            }}
          >
            {msg => (
              <Text mb={8}>
                {msg}
                &nbsp;
                <FormattedMessage id="order_action_suspend_number_you_will_not_be_billed_for_duration_of_suspension" />
              </Text>
            )}
          </FormattedMessage>
        </>
      )}

      <Flex justifyContent="center">
        <FormattedMessage id="order_action_suspend_number_submit_suspension">
          {msg => (
            <Button onClick={() => setShowModal(true)} disabled={invalid}>
              {msg}
            </Button>
          )}
        </FormattedMessage>
      </Flex>

      <ReactModal style={styles.modalStyle} isOpen={showModal}>
        <Box>
          <Box mb={8}>
            <FormattedMessage id="order_action_suspend_number_request_temporary_suspension">
              {msg => <H1>{msg}</H1>}
            </FormattedMessage>
          </Box>

          <Box>
            <FormattedMessage
              id="order_action_suspend_number_on_clicking_confirm"
              values={{
                suspensionPhoneNumber,
                suspensionStartDate: moment(selectedSuspensionStartDate).format(
                  "DD MMMM YYYY"
                ),
                suspensionEndDate: moment(selectedSuspensionEndDate).format(
                  "DD MMMM YYYY"
                ),
                chargeableAmount
              }}
            >
              {msg => <Text>{msg}</Text>}
            </FormattedMessage>
          </Box>
        </Box>

        <Flex justifyContent="center" padding="1rem">
          <Button onClick={handleSubmit(submit)}>
            <FormattedMessage id="confirm" />
          </Button>
          <Button onClick={() => setShowModal(false)} variant="outline">
            <FormattedMessage id="cancel" />
          </Button>
        </Flex>
      </ReactModal>
    </>
  );
};

export default REQUEST_SUSPENSION_MODAL;
