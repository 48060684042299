import { createSelector } from "reselect";
import get from "lodash/get";

export const uploadIdentityDetailsDomain = state =>
  get(state, "api.uploadIdentityDetails.data", {});

export const uploadIdentityDetailsSelector = createSelector(
  uploadIdentityDetailsDomain,
  uploadIdentityDetails => uploadIdentityDetails
);

export const makeSelectUploadIdentityDetails = () =>
  uploadIdentityDetailsSelector;
