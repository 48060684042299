import React, { useEffect } from "react";
import DropdownField from "components/FormFields/DropdownField";
import RadioGroup from "components/FormFields/RadioGroup";
import PostOfficeCard from "components/PostOfficeCard";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

const PostOfficePickUp = props => {
  const {
    regions,
    fetchPostOfficeDeliverySlots,
    onRegionChange,
    onStoreChange,
    deliverySlots,
    selectedNumber
  } = props;

  useEffect(() => {
    if (!regions.length) {
      const params = {
        selectedNumber,
        portin: false,
        selectedRegion: null,
        type: "sim-only"
      };
      fetchPostOfficeDeliverySlots && fetchPostOfficeDeliverySlots(params);
    }
    // eslint-disable-next-line
  }, [regions]);

  const deliverySlotOptions = map(deliverySlots, slot => {
    return {
      ...slot,
      label: <PostOfficeCard {...slot} />,
      value: slot.id
    };
  });

  return (
    <>
      <DropdownField
        required
        field="region"
        label="delivery_info_select_your_region"
        options={regions}
        onChange={onRegionChange}
      />

      {!isEmpty(deliverySlotOptions) && (
        <RadioGroup
          required
          options={deliverySlotOptions}
          onChange={onStoreChange}
          field="post_office_id"
          label="delivery_info_select_post_office_to_collect"
          render={deliverySlot => deliverySlot.label}
        />
      )}
    </>
  );
};

PostOfficePickUp.defaultProps = {
  deliverySlots: [],
  regions: []
};

export default PostOfficePickUp;
