import { createSelector } from "reselect";
import { genericSelector } from "../appSettings";
import get from "lodash/get";
import replace from "lodash/replace";
import startsWith from "lodash/startsWith";
import isEmpty from "lodash/isEmpty";
import { sessionTokenSelector } from "./manageOrder";
import { orderRefSelector } from "../order";
import { urlParam } from "utils/helpers";
import { networkTypeSelector } from "../guestOrder";
import { NETWORK_TYPES } from "utils/constants";
import { featureFlag5GSAEnabled } from "utils/featureFlags";

const simVerificationDomain = state =>
  get(state, "form.SimVerificationForm.values", {});

// ***************************************************************
// ************** SimVerificationForm selectors ******************
// ***************************************************************

export const simVerificationSelector = createSelector(
  simVerificationDomain,
  simVerification => simVerification
);

// ***************************************************************
// ************** SimVerificationForm makeSelect *****************
// ***************************************************************

export const makeSelectSimVerificationForm = () => simVerificationSelector;

export const makeSelectSimVerificationFormInitialValues = () =>
  createSelector(
    genericSelector,
    networkTypeSelector,
    (generic, networkType) => {
      //TODO after 5g release remove this line and uncomment the below line
      const iccIdPrefixName =
        featureFlag5GSAEnabled() && networkType === NETWORK_TYPES.SA
          ? "icc_id_sa.prefix"
          : "icc_id.prefix";
      //const iccIdPrefixName = (networkType === NETWORK_TYPES.SA) ? "icc_id_sa.prefix" : "icc_id.prefix";
      const icc_id_prefix = get(generic, iccIdPrefixName);
      const iccIdInUrlParam = urlParam("iccid");
      const icc_id =
        /^\d+$/.test(iccIdInUrlParam) &&
        startsWith(iccIdInUrlParam, icc_id_prefix)
          ? replace(iccIdInUrlParam, icc_id_prefix, "")
          : "";
      return {
        icc_id_prefix,
        icc_id
      };
    }
  );

export const makeSelectSimVerificationPayload = (_, iccIdInUrlParam) =>
  createSelector(
    simVerificationSelector,
    ({
      icc_id_prefix,
      icc_id,
      third_party_tenant,
      third_party_order_ref,
      dob,
      nric
    }) => ({
      iccid: iccIdInUrlParam ? iccIdInUrlParam : `${icc_id_prefix}${icc_id}`,
      third_party_tenant: get(third_party_tenant, "value"),
      third_party_order_ref,
      nric,
      dob
    })
  );

export const makeSelectSimActivationPayload = (_, iccIdInUrlParam) =>
  createSelector(
    simVerificationSelector,
    orderRefSelector,
    sessionTokenSelector,
    ({ icc_id_prefix, icc_id, scanned_icc_id }, order_ref, session_token) => ({
      iccid: icc_id
        ? `${icc_id_prefix}${icc_id}`
        : scanned_icc_id || iccIdInUrlParam,
      order_ref,
      session_token
    })
  );

export const makeSelectThirdPartySimVerification = props =>
  createSelector(
    simVerificationSelector,
    ({ third_party_tenant, third_party_order_ref, nric, dob }) => {
      const tenants = get(props, "appSettings.generic.third_party_tenants", []);
      const tenant = get(third_party_tenant, "value", "");
      const orderRefRequired = get(third_party_tenant, "validate_purchase");
      const orderRefRegex = get(
        third_party_tenant,
        "order_ref_regex",
        "[0-9a-zA-Z]+"
      );
      const orderRefMaxLength = get(
        third_party_tenant,
        "order_ref_max_length",
        14
      );
      const nricRequired = get(third_party_tenant, "validate_nric");
      const nricRegex = get(
        third_party_tenant,
        "nric_regex",
        "^[STFG]\\d{7}[A-Z]$"
      );
      const nricMaxLength = get(third_party_tenant, "nric_max_length", 9);
      const dobRequired = get(third_party_tenant, "validate_dob");
      const dobRegex = get(
        third_party_tenant,
        "dob_regex",
        "^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\\d{4}$"
      );
      const orderRefValid =
        !orderRefRequired ||
        (orderRefRequired &&
          third_party_order_ref &&
          RegExp(orderRefRegex).test(third_party_order_ref));
      const nricValid =
        !nricRequired || (nricRequired && nric && RegExp(nricRegex).test(nric));
      const dobValid =
        !dobRequired || (dobRequired && dob && RegExp(dobRegex).test(dob));
      const valid =
        isEmpty(tenants) || (tenant && orderRefValid && nricValid && dobValid);

      return {
        tenants,
        tenant,
        orderRefRequired,
        orderRefRegex,
        orderRefMaxLength,
        nricRequired,
        nricRegex,
        nricMaxLength,
        dobRequired,
        dobRegex,
        valid
      };
    }
  );
