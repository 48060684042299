import get from "lodash/get";
import { createSelector } from "reselect";

const auIPDBDataDomain = state => get(state, "api.AuIPDBData.data", {});
const auIPDBIdAssertionDomain = state => get(state, "api.auIdAssertion", {});

export const isSignUpWithIPDBSelector = createSelector(
  auIPDBDataDomain,
  auIPDBData => get(auIPDBData, "isAuIPDBSignup", false)
);

export const userPINVerifiedSuccessSelector = createSelector(
  auIPDBIdAssertionDomain,
  auIPDBData => !!auIPDBData?.data
);

export const userPINVerifiedFailureSelector = createSelector(
  auIPDBIdAssertionDomain,
  auIPDBData => !!auIPDBData?.error
);

export const makeSelectIsSignUpWithIPDB = () => isSignUpWithIPDBSelector;
export const makeSelectIsUserPINVerifiedSuccess = () =>
  userPINVerifiedSuccessSelector;
export const makeSelectIsUserPINVerifiedFailure = () =>
  userPINVerifiedFailureSelector;
