import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, Text } from "ui-components";
import { ROBOTO } from "ui-components/theme";
import styles from "./styles";
import trim from "lodash/trim";

const Footer = () => {
  const socialIcons = ["facebook", "twitter", "instagram", "linkedin", "line"];
  const renderSocialIcon = icon => (
    <FormattedMessage key={icon} html id={`footer_social_${icon}`}>
      {msg =>
        !trim(msg) ? null : (
          <Box sx={styles.socialIconStyles}>
            <FormattedMessage html id={`footer_social_${icon}`} />
          </Box>
        )
      }
    </FormattedMessage>
  );
  return (
    <Flex sx={styles.wrapperStyle}>
      <Flex>
        <Box width={[1 / 2, 2 / 3]}>
          <Box px={0} mb={3} sx={styles.linkStyles}>
            <FormattedMessage html id="footer_faq" />
          </Box>

          <Box px={0} mb={3} sx={styles.linkStyles}>
            <FormattedMessage html id="footer_tnc" />
          </Box>

          <Box px={0} mb={6} sx={styles.linkStyles}>
            <FormattedMessage html id="footer_privacy" />
          </Box>
        </Box>

        <Box width={[1 / 2, 1 / 3]}>
          <Text
            sx={{
              fontFamily: ROBOTO,
              mb: 1
            }}
            color="white"
          >
            <FormattedMessage html id="footer_we_are_social" />
          </Text>

          <Flex>{socialIcons.map(renderSocialIcon)}</Flex>
        </Box>
      </Flex>

      <Text size="sm" color="white">
        <FormattedMessage id="tm_circles" />
      </Text>
      <Text size="sm" color="white">
        <FormattedMessage id="cookies" />
        &nbsp;
        <FormattedMessage id="agree_to_terms" />
      </Text>
    </Flex>
  );
};

Footer.defaultProps = {
  site: { footer: { links: [] } }
};

export default Footer;
