import React from "react";
import get from "lodash/get";
import DeliveryAddressForm5 from "components/DeliveryAddress/Form5";

const DELIVERY_ADDRESS_FORM_5 = ({
  formValues,
  fieldPrefix = "delivery",
  linkedFieldPrefix,
  setPostalCodeValidation = () => {},
  change,
  enable,
  readOnly
}) => {
  const selectedDeliveryMethod = get(formValues, "delivery_method", null);
  if (!selectedDeliveryMethod || !enable) return null;
  const fields = get(selectedDeliveryMethod, "fields");
  return (
    <DeliveryAddressForm5
      fields={fields}
      fieldPrefix={fieldPrefix}
      linkedFieldPrefix={linkedFieldPrefix}
      formValues={formValues}
      change={change}
      readOnly={readOnly}
      setPostalCodeValidation={setPostalCodeValidation}
    />
  );
};

export default DELIVERY_ADDRESS_FORM_5;
