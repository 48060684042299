import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, H3 } from "ui-components";
import get from "lodash/get";
import {
  makeSelectUserEmail,
  makeSelectSupportedPlanIdMap,
  makeSelectLoggedinUserPlanName
} from "ducks/selectors";

const FeatureToggle = props => {
  const { component, render, children, appSettings, width = "100%" } = props;
  const { allowedAccounts = [] } = component || {};
  const debugMode = localStorage.getItem("debug") === "1";
  let wrapperStyles = { width };
  let titleStyle = { display: "none" };

  if (debugMode) {
    wrapperStyles = {
      borderColor: "gray.1",
      borderWidth: 1,
      borderRadius: 2,
      borderStyle: "dashed",
      my: 2,
      p: 4
    };

    titleStyle = {
      display: "block",
      mb: 4
    };
  }

  const userEmail = useSelector(makeSelectUserEmail());
  const supportedPlans = useSelector(makeSelectSupportedPlanIdMap());
  const currentPlanType = useSelector(makeSelectLoggedinUserPlanName());

  const enabledForUsersEmail = useMemo(() => {
    // if allowedAccounts is not specified defaults to true
    if (allowedAccounts.length === 0) return true;

    return (
      allowedAccounts.findIndex(
        acc => acc.toLocaleLowerCase() === userEmail?.toLocaleLowerCase()
      ) !== -1
    );
  }, [userEmail, allowedAccounts]);

  /** If a component mention the supported_plan_ids attribute, the method will;
   *    - return true, if current plan type is mentioned in the supported_plan_ids array
   *    - return false, if current plan type is not available in the supported_plan_ids array
   *  If a component does not mention the supported_plan_ids attribute, then by default
   *  the function considers its a component that is available is all cases.
   *  Therefore default case will return as true
   *
   * For maintenance improvements webfront config will maintain an object of plan types
   * "supported_plans": {
   *    "povo2": "povo2.0",
   *    "dataonly": "PovoDataOnly"
   * }
   */
  const isEnabledForPlan = () => {
    const planIds = get(component, "supported_plan_ids", []);

    // since majority of the components are general components tackling the default case first
    if (planIds.length === 0) return true;

    const planIdForCurrentPlan = Object.keys(supportedPlans).find(
      key => supportedPlans[key] === currentPlanType
    );
    return planIds.includes(planIdForCurrentPlan);
  };

  const enabled =
    (get(component, "enable") === true ||
      get(appSettings, `${get(component, "lookup")}.enable`, false)) &&
    isEnabledForPlan();

  return (
    <Box sx={wrapperStyles}>
      <H3 color="green" sx={titleStyle}>
        {get(component, "name", "")}
      </H3>

      {enabled && enabledForUsersEmail
        ? render
          ? render({ ...component })
          : children
        : null}
    </Box>
  );
};

export default FeatureToggle;
