import React from "react";
import get from "lodash/get";
import map from "lodash/map";
import range from "lodash/range";
import { Box, Flex, Text } from "ui-components";
import styles from "./styles";
import { COLORS, iconThemes } from "ui-components/theme";

const Stepper = ({
  step,
  total_steps,
  activeColor,
  inactiveColor,
  themeColors,
  standalone
}) => {
  const active = activeColor
    ? get(iconThemes, activeColor, activeColor)
    : get(themeColors, "stepper.activeColor") || COLORS.magenta[3];
  const inactive = inactiveColor
    ? get(iconThemes, inactiveColor, inactiveColor)
    : get(themeColors, "stepper.inactiveColor") || COLORS.gray[0];
  const styleOverrides = standalone ? { mb: [30] } : {};
  const containerStyle = { ...styles.containerStyle, ...styleOverrides };
  return (
    <Box sx={containerStyle}>
      <Flex sx={styles.stepWrapperStyle}>
        {map(range(total_steps), n => {
          let circlesStyle = {
            ...styles.baseCircleStyle,
            backgroundColor: inactive
          };
          if (n < step) {
            circlesStyle = {
              ...circlesStyle,
              ...styles.activeCircleStyle,
              backgroundColor: active
            };
          }

          return (
            <Box data-testid="step" key={n} sx={circlesStyle}>
              <Text sx={styles.baseCircleTextStyle}>{n + 1}</Text>
            </Box>
          );
        })}
      </Flex>

      <Box width={1} sx={{ ...styles.barStyle, borderColor: inactive }}></Box>
    </Box>
  );
};

Stepper.defaultProps = {
  step: 0,
  total_steps: 0
};

export default Stepper;
