import React, { memo, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import {
  makeSelectIsModalView,
  makeSelectModalType,
  makeSelectTermsAndConditions,
  makeSelectSelectedToppings,
  makeSelectEligibleToppings,
  makeSelectIsFetchingData,
  makeSelectEligiblePlans,
  makeSelectEligiblePlanRequestChannel,
  makeSelectSelectedToppingLearnMore,
  makeSelectSelectedToppingTnCs,
  makeSelectToppingFormValue,
  makeSelectPageValidations
} from "ducks/selectors";
import validate from "validations";
import { forms } from "utils/constants";
import { setData, OMS, clearData, Rewards, selectPlan } from "ducks/actions";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import head from "lodash/head";
import { maintenanceOnError } from "utils/helpers";

import SmartRender from "containers/SmartRender";
import Spinner, { styles } from "components/Spinner";
import { makeSelectReferralCode } from "ducks/selectors";

const ToppingSelectionPage = Component =>
  function Comp(props) {
    const {
      fetchEligiblePlans,
      isFetchingEligiblePlans,
      eligiblePlans,
      match,
      eligiblePlanChannel,
      selectPlan,
      selectedToppings,
      selectedToppingTermsConditions
    } = props;

    const { channel } = get(match, "params", {});
    const messageData = {
      selectedToppingName: head(selectedToppings)?.name,
      selectedToppingTnCLink: selectedToppingTermsConditions?.link
    };

    const planParams = {};
    if (channel) {
      planParams["channel"] = channel;
      planParams["eligible_toppings"] = true;
    }

    useEffect(
      () => {
        if (
          (isEmpty(eligiblePlans) || eligiblePlanChannel !== channel) &&
          !isFetchingEligiblePlans
        ) {
          fetchEligiblePlans(planParams);
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isFetchingEligiblePlans, eligiblePlanChannel]
    );

    useEffect(() => {
      if (!isEmpty(eligiblePlans) && !isFetchingEligiblePlans) {
        selectPlan(head(eligiblePlans));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eligiblePlans, isFetchingEligiblePlans]);

    return isFetchingEligiblePlans ? (
      <Spinner size={200} sx={styles.fullPage} />
    ) : (
      <Component {...props} messageData={messageData} />
    );
  };

const mapStateToProps = createStructuredSelector({
  formValidations: makeSelectPageValidations(forms.TOPPING_SELECTION_FORM),
  formValues: makeSelectToppingFormValue(),
  isModalView: makeSelectIsModalView(),
  modalType: makeSelectModalType(),
  termsAndConditions: makeSelectTermsAndConditions(),
  addonData: makeSelectEligibleToppings(),
  referralCode: makeSelectReferralCode(),
  selectedToppings: makeSelectSelectedToppings(),
  selectedToppingLearnMoreDetails: makeSelectSelectedToppingLearnMore(),
  selectedToppingTermsConditions: makeSelectSelectedToppingTnCs(),
  isFetchingEligiblePlans: makeSelectIsFetchingData("eligiblePlans"),
  eligiblePlans: makeSelectEligiblePlans(),
  eligiblePlanChannel: makeSelectEligiblePlanRequestChannel()
});

export function mapDispatchToProps(dispatch) {
  return {
    handleTermsConditions: type => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: `${type}TermsAndConditions` }
        })
      );
    },
    hideModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
    },
    fetchEligiblePlans: params => {
      dispatch(OMS.V4.fetchEligiblePlans(params, maintenanceOnError));
    },
    validateReferralCode: (referral_code, plan_name, device_sku) =>
      dispatch(
        Rewards.V1.fetchReferralCode({
          referral_code,
          plan_name,
          ...(device_sku && { device_sku })
        })
      ),
    resetReferralCode: () => dispatch(clearData("referralCode")),
    selectPlan: plan => dispatch(selectPlan(plan))
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
  reduxForm({
    form: forms.TOPPING_SELECTION_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(ToppingSelectionPage(SmartRender));
