import queryString from "query-string";
import { getDeviceId, getLocaleOrDefault } from "utils/localStorage";
import {
  postRequestWithCaptcha,
  getRequestWithCaptcha,
  putRequestWithCaptcha
} from "utils/request";
import {
  BASE,
  COUNTRY,
  DEFAULT_API_VERSION,
  VERSION_PATH,
  BASE_URL
} from "utils/constants";

/**
 * API builder function
 */
function api() {
  let base,
    baseUrl,
    version,
    method,
    path,
    apiParams,
    authToken,
    options,
    queryParams,
    requestWithCaptcha;

  /**
   * Picks up the correct base for the given base type
   * and return version setter
   * @param {string} _base BASE type
   */
  const baseFor = _base => {
    base = _base;
    baseUrl = BASE_URL[base];

    return { apiVersionFor };
  };

  /**
   * Picks up the correct version for given base
   * and return the builder function
   * @param {string} base BASE type
   */
  const apiVersionFor = (base, _version) => {
    const apiVersion = _version || DEFAULT_API_VERSION?.[base] || "";
    version = VERSION_PATH[base];

    if (apiVersion) {
      version = version.replace("{VERSION}", apiVersion);
    }

    return { apiOptions };
  };

  /**
   * Create API options based on HTTP method
   *
   * @param {string} _method HTTP MEthod
   * @param {string} _path api
   * @param {Map} headers API headers
   * @param {Map} _params api params. If it is a
   *  - GET call these will be query params
   *  - POST call these will be body params
   */
  const apiOptions = (
    _method,
    _path,
    headers,
    _params,
    _authToken,
    meta = {}
  ) => {
    method = _method;
    apiParams = _params;
    path = _path;
    authToken = _authToken;

    options = {
      method,
      headers: { ...getDefaultHeaders(), ...headers },
      meta
    };

    if (method === "POST") {
      createPostRequestAPI();
    } else if (method === "PATCH") {
      createPatchRequestAPI();
    } else if (method === "PUT") {
      createPutRequestAPI();
    } else {
      createRequestAPI();
    }

    return { build };
  };

  /**
   * Build the API with base url and version url
   */
  const build = () => {
    let requestURL = `${baseUrl}${version}${path}`;
    requestURL = requestURL.replace("{LOCALE}", getLocaleOrDefault());
    if (queryParams) {
      requestURL = `${requestURL}?${queryParams}`;
    }

    return {
      requestURL,
      options,
      requestWithCaptcha
    };
  };

  /** ----- private functions ----- */
  const createPostRequestAPI = () => {
    options = { ...options, body: JSON.stringify(apiParams) };
    requestWithCaptcha = postRequestWithCaptcha;
  };

  const createPutRequestAPI = () => {
    options = { ...options, body: JSON.stringify(apiParams) };
    requestWithCaptcha = putRequestWithCaptcha;
  };

  const createPatchRequestAPI = () => {
    options = { ...options, body: JSON.stringify(apiParams) };
  };

  const createRequestAPI = () => {
    queryParams = queryString.stringify(apiParams);
    requestWithCaptcha = getRequestWithCaptcha;
  };

  const getDefaultHeaders = () => {
    switch (base) {
      case BASE.MOBILE_API:
      case BASE.USER_SERVICE: {
        const headers = {
          "Accept-Language": `${getLocaleOrDefault()}-${COUNTRY.toUpperCase()}`,
          "X-DeviceId": getDeviceId()
        };
        if (authToken) {
          headers["X-AUTH"] = authToken;
        }
        return headers;
      }
      case BASE.LOGISTIC:
      case BASE.PAYMENTS:
      case BASE.PAYMENTS_SERVICE:
      case BASE.REWARDS_SERVICE:
      case BASE.API_GATEWAY:
      case BASE.TELCO_SERVICE:
      case BASE.TELCO_PORT_IN_SERVICE:
      case BASE.QUILT_SERVICE:
      case BASE.BILLING:
        return {
          "X-AUTH": authToken,
          "X-DeviceId": getDeviceId()
        };
      default:
        return {};
    }
  };

  // api build starting function
  return { baseFor };
}

export default api;
