import React, { memo } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import {
  makeSelectPageValidations,
  makeSelectDeliveryInfoForm,
  makeSelectSelectedNumber,
  makeSelectDeliveryMethods,
  makeSelectDeliveryDates,
  makeSelectCombinedDeliveryDates,
  makeSelectFreeDeliverySlots,
  makeSelectSpecialDeliverySlots,
  makeSelectPostOfficeRegions,
  makeSelectPostOfficeDeliverySlots,
  makeSelectSelectedPostOffice,
  makeSelectIsUploadIDLater,
  makeSelectPostalCodeValidation,
  makeSelectAddressComponentMappings,
  makeSelectCityList,
  makeSelectDistrictList,
  makeSelectStreetList,
  makeSelectStoreList,
  makeSelectScheduleDeliveryMessageData,
  makeSelectScheduleDeliveryPayload,
  makeSelectScheduleDeliveryInitialValues,
  makeSelectDeliveryDetailsItems,
  makeSelectScheduleDeliveryUserInitialValues,
  makeSelectMonthOptions,
  makeSelectDateOptions,
  makeSelectNricIssueYearOptions,
  makeSelectPopStations,
  makeSelectPopStationDeliverySlots,
  makeSelectIsFetchingData,
  makeSelectUserOrderDetailsSelector
} from "ducks/selectors";
import SmartRender from "containers/SmartRender";
import { forms } from "utils/constants";
import { paths, LOST_SIM } from "utils/constants";
import {
  fetchAddressLookup,
  resetDeliverySlots,
  fetchPostOfficeDeliverySlots,
  fetchAddressComponentMappings,
  findAddressLookup,
  validatePostalCode,
  resetPostalCodeValidation,
  setPostalCodeValidation,
  fetchCityList,
  fetchDistrictList,
  fetchStreetList,
  fetchStoreList,
  resetDistrictList,
  resetStreetList,
  resetStoreList,
  changeFormField,
  fetchPopStations,
  clearData,
  submitScheduleDelivery,
  Laas
} from "ducks/actions";
import validate, { asyncValidate } from "validations";
import { gotoUrl } from "utils/helpers";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import NotEligible from "components/NotEligible";
import { OMS } from "ducks/actions";

const ScheduleDeliveryFormHOC = Component =>
  function Comp(props) {
    const { scheduleDeliveryDetails } = props;

    const { value, message, reason } = get(
      scheduleDeliveryDetails,
      "eligibility",
      {}
    );
    if (!value) {
      return (
        <NotEligible
          html
          description={message || reason}
          themeColors={props?.themeColors}
        />
      );
    }
    return <Component {...props} />;
  };

const mapStateToProps = (_, props) => {
  return createStructuredSelector({
    formValidations: makeSelectPageValidations(forms.DELIVERY_INFO_FORM),
    formValues: makeSelectDeliveryInfoForm(),
    selectedNumber: makeSelectSelectedNumber(),
    deliveryMethods: makeSelectDeliveryMethods(),
    deliverySlotDates: makeSelectDeliveryDates(),
    combinedDeliverySlotDates: makeSelectCombinedDeliveryDates(),
    freeDeliverySlotTime: makeSelectFreeDeliverySlots(),
    specialDeliverySlotTime: makeSelectSpecialDeliverySlots(),
    postOfficeRegions: makeSelectPostOfficeRegions(),
    postOfficeDeliverySlots: makeSelectPostOfficeDeliverySlots(),
    selectedPostOffice: makeSelectSelectedPostOffice(),
    uploadIDLater: makeSelectIsUploadIDLater(),
    popStations: makeSelectPopStations(),
    popStationsDeliverySlots: makeSelectPopStationDeliverySlots(),
    isFetchingPopStations: makeSelectIsFetchingData("popStations"),
    postalCodeValidation: makeSelectPostalCodeValidation(),
    addressComponentMappings: makeSelectAddressComponentMappings(),
    cityList: makeSelectCityList(),
    districtList: makeSelectDistrictList(),
    streetList: makeSelectStreetList(),
    storeList: makeSelectStoreList(),
    messageData: makeSelectScheduleDeliveryMessageData(),
    payload: makeSelectScheduleDeliveryPayload(),
    deliveryDetailsItems: makeSelectDeliveryDetailsItems(),
    monthOptions: makeSelectMonthOptions(),
    dateOptions: makeSelectDateOptions(),
    yearOptions: makeSelectNricIssueYearOptions(),
    initialValues: state => {
      return {
        ...makeSelectUserOrderDetailsSelector(props)(state),
        ...makeSelectScheduleDeliveryInitialValues(props)(state),
        ...makeSelectScheduleDeliveryUserInitialValues(props)(state)
      };
    }
  });
};

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    fetchPostOfficeDeliverySlots: params => {
      dispatch(fetchPostOfficeDeliverySlots(params));
    },
    fetchDeliverySlots: params => {
      dispatch(Laas.V1.fetchDeliverySlots(params));
    },
    resetDeliverySlots: () => dispatch(resetDeliverySlots()),
    fetchAddressLookup: params => {
      dispatch(fetchAddressLookup(params));
    },
    fetchAddressComponentMappings: params => {
      dispatch(fetchAddressComponentMappings(params));
    },
    findAddressLookup: (params, callback) => {
      dispatch(findAddressLookup(params, callback));
    },
    validatePostalCode: (postal_code, field) => {
      dispatch(validatePostalCode({ postal_code }, field));
    },
    resetPostalCodeValidation: field => {
      dispatch(resetPostalCodeValidation(field));
    },
    fetchPopStations: params => {
      dispatch(clearData("popStations"));
      dispatch(fetchPopStations(params));
    },
    setPostalCodeValidation: (field, data, loading) => {
      dispatch(setPostalCodeValidation(field, data, loading));
    },
    fetchCityList: params => {
      dispatch(fetchCityList(params));
    },
    fetchDistrictList: params => {
      dispatch(fetchDistrictList(params));
    },
    fetchStreetList: params => {
      dispatch(fetchStreetList(params));
    },
    fetchStoreList: params => {
      dispatch(fetchStoreList(params));
    },
    resetDistrictList: () => {
      dispatch(resetDistrictList());
    },
    resetStreetList: () => {
      dispatch(resetStreetList());
    },
    resetStoreList: () => {
      dispatch(resetStoreList());
    },
    changeField: (field, value) => {
      dispatch(
        changeFormField({
          form: forms.DELIVERY_INFO_FORM,
          field: field,
          value: value
        })
      );
    },
    onSubmit: (navigate, payload) => {
      dispatch(
        submitScheduleDelivery(payload, (_, error) => {
          isEmpty(error) && navigate();
        })
      );
    },
    submitScheduleDelivery: navigate => {
      ownProps.hideModal();
      const orderParam = {
        order_type: ownProps.orderType,
        delivery_type: ownProps.deliveryType
      };
      const currentPayload = ownProps.payload;
      let payload = {
        ...currentPayload,
        ...orderParam,
        ...(ownProps.orderType === "sim_replacement" && {
          shipment_type: LOST_SIM
        })
      };
      dispatch(
        OMS.V4.submitScheduleDeliveryNewSim(payload, (_, error) => {
          isEmpty(error) &&
            setTimeout(() => {
              gotoUrl(paths.MANAGE_ORDER, null, null, null, null, true);
            }, 0);
        })
      );
    }
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.DELIVERY_INFO_FORM,
    destroyOnUnmount: false,
    validate,
    asyncValidate
  }),
  memo
)(ScheduleDeliveryFormHOC(SmartRender));
