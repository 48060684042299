import React from "react";
import Banner from "components/Banner";

const PLAN_BANNER = ({ planName, data, appSettings }) => (
  <Banner
    type={"planBanners"}
    value={planName}
    data={data}
    appSettings={appSettings}
  />
);

export default PLAN_BANNER;
