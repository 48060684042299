import { createSelector } from "reselect";
import get from "lodash/get";
import { FAILED_TO_HANDLE_OTP_REQUEST } from "utils/constants";

export const selectApi = state => get(state, "api");
export const verifyOTPDomain = state => get(state, "api.verifyOTP.data", {});
export const uiOTPDomain = state => get(state, "ui.otp", {});
export const verifyOTPSelector = createSelector(verifyOTPDomain, verifyOTP => {
  return verifyOTP;
});
export const verifiedSelector = createSelector(verifyOTPDomain, verifyOTP => {
  return verifyOTP.is_valid_otp;
});

export const isResendActivationEnabled = createSelector(uiOTPDomain, otp =>
  get(otp, "resend_activation", false)
);

export const makeSelectIsOTPFail = (key = null) =>
  createSelector(selectApi, apiStore => {
    const errorObj = get(apiStore, `${key}.error`, {});
    const code = get(errorObj, "result.code", null);

    return code === FAILED_TO_HANDLE_OTP_REQUEST;
  });

export const makeSelectIsOTPVerified = () => verifiedSelector;
export const makeSelectIsResendActivationEnabled = () =>
  isResendActivationEnabled;
