import React, { useEffect } from "react";
import { reduxForm } from "redux-form";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import SmartRender from "containers/SmartRender";
import Spinner, { styles } from "components/Spinner";
import Alert from "react-s-alert";
import isFunction from "lodash/isFunction";
import { createStructuredSelector } from "reselect";
import {
  clearData,
  setData,
  fetchOrderDetails,
  initiatePortIn,
  fetchOrderCancellationStatus,
  submitOrderCancellation,
  userLogout,
  UserService,
  Telco,
  Billing
} from "ducks/actions";
import {
  makeSelectMinimumAge,
  makeSelectDateOptions,
  makeSelectMonthOptions,
  makeSelectYearOptions,
  makeSelectGuestOrder,
  makeSelectIsFetchingData,
  makeSelectManageOrderForm,
  makeSelectAppSettings,
  makeSelectThemeColors,
  makeSelectLocale,
  makeSelectSessionToken,
  makeSelectAuthToken,
  makeSelectUserOrders,
  makeSelectUserOrderRefs,
  makeSelectSimActivationPayload,
  makeSelectFormattedSelectedNumber,
  makeSelectOrderRef,
  makeSelectContractualAgreementForm,
  makeSelectWorkflow,
  makeSelectContractType,
  makeSelectReportLostSimForm,
  makeSelectLoggedInUserDetails,
  makeSelectEligibleActions,
  makeSelectEligiblePlans,
  makeSelectReversePortinStatus,
  makeSelectDeliveryDetailsItems,
  makeSelectPageValidations,
  makeSelectIsModalView,
  makeSelectModalType,
  makeSelectIsDeliveryInfoAvailable,
  makeSelectzEIdentifyUserData,
  makeSelectPageData,
  makeSelectServiceInstanceNumber,
  makeSelectCurrentPhoneNumber,
  makeSelectOutstandingBillAmount,
  makeSelectIsActiveTelcoUser,
  makeSelectIsOutstandingPayable,
  makeSelectIsOrderDetailsEmpty,
  makeSelectIsPortingUser,
  makeSelectOrderCancellationPayload,
  makeSelectExternalUserId,
  makeSelectIsSimReplacementKYC,
  makeSelectOnestopPortoutStatus,
  makeSelectGA4PlanDetails,
  makeSelectUserOrderChannel,
  makeSelectLoggedinUserPlanName
} from "ducks/selectors";
import {
  urlParam,
  canAllowAction,
  getActionUrl,
  gotoUrl,
  nextPageWithParams,
  getAuthorizationHeader,
  handleOnLoadAction,
  pathWithChannel,
  isEligibilityAPISupported,
  activateZendeskWidget,
  loggedInUserDetailsAPIParams,
  getDeepLink
} from "utils/helpers";
import {
  paths,
  forms,
  MOBILE_APP_BROWSER_PROTOCOL,
  CONTRACT_TYPES,
  ORDER_ACTION_TYPES,
  LIQUID_URL_CONSUMED_CODE
} from "utils/constants";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import { buttonThemes } from "ui-components/theme";
import {
  getDeviceId,
  isEnableESimActivationApi,
  isWebview
} from "utils/localStorage";
import validate from "validations";
import { v4 as uuid } from "uuid";
import { Quilt, OMS } from "ducks/actions";
import AdjustAnalytics from "analytics/type/AdjustAnalytics/AdjustAnalytics";
import { ADJUST_EVENTS } from "constants/AnalyticsTypeConstants";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";

const ManageOrderHOC = Component =>
  function Comp(props) {
    const {
      formValues,
      isActionInProgress,
      fetchGuestOrder,
      fetchUserOrders,
      fetchEligibleActions,
      fetchLoggedInUserDetails,
      selectUserOrder,
      order,
      isOrderDetailsEmpty,
      themeColors,
      locale,
      change,
      userToken,
      orderRef,
      contractualAgreementForm,
      zEIdentify,
      serviceInstanceNumber,
      currentPhoneNumber,
      fetchOutstandingBillsInfo,
      isActiveTelcoUser,
      hideModal,
      openOrderCancellationModal
    } = props;
    const dispatch = useDispatch();
    const redirectAction = urlParam("redirect");
    const urlOrderRef = urlParam("order_ref");
    const buttonTheme = get(themeColors, "button") || buttonThemes.blue;

    useEffect(() => {
      if (userToken) {
        // temp meassure to use eligibility API only for selected countries.

        isEligibilityAPISupported() && fetchEligibleActions();
        fetchUserOrders();
        fetchLoggedInUserDetails();
        hideModal();
      } else {
        get(order, "orderRef") && fetchGuestOrder(formValues);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (urlOrderRef && urlOrderRef !== get(order, "orderRef", "")) {
        change("orderRefOrPhoneNumber", urlOrderRef);
        selectUserOrder(urlOrderRef);
      } else {
        const redirectAction = urlParam("redirect");
        const channel = get(order, "channel", "");
        const steps = get(order, "steps", []);
        const allowAction = canAllowAction(redirectAction, steps);
        const redirectUrl =
          allowAction && getActionUrl(redirectAction, channel);
        channel && redirectUrl && gotoUrl(redirectUrl);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirectAction, order, urlOrderRef]);

    /**
     * Outstanding bill handling
     */
    useEffect(() => {
      if (userToken && isActiveTelcoUser) {
        fetchOutstandingBillsInfo();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      handleOnLoadAction({
        ...props,
        downloadContractualAgreementForm
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadContractualAgreementForm = () => {
      const { token, timeStamp } = getAuthorizationHeader();
      const params = { order_ref: orderRef };
      const headers = {
        "p-token": token,
        "p-timestamp": timeStamp
      };
      dispatch(
        OMS.V4.fetchAgreementForm(headers, params, data => {
          if (data) {
            setTimeout(() => {
              downloadContractualAgreement(data);
            }, 0);
          }
        })
      );
    };

    const emailContractualAgreement = () => {
      dispatch(
        Telco.V1.emailAgreementForm((_, errors) => {
          if (isEmpty(errors))
            Alert.success(
              get(
                locale,
                "contractual_agreement_form_sent_to_email",
                "Your Contractual Agreement Form will be sent to your email for download"
              )
            );
          // GA4EventsService.publish(
          //   personalDetailsSubmitEventData(
          //     GA4EVENTS.SEND_CONTRACT_BY_EMAIL,
          //     props.GA4EventPlanDetails
          //   )
          // );
        })
      );
    };

    const cancelPortOut = () => {
      const payload = {
        accountNumber: serviceInstanceNumber,
        msisdn: currentPhoneNumber,
        status: "CANCEL_REQUEST"
      };
      dispatch(
        Telco.V1.cancelPortOutRequest(payload, (_, errors) => {
          if (isEmpty(errors)) {
            gotoUrl(paths.MANAGE_ORDER, true, {}, true);
          }
        })
      );
    };

    const reportLostSim = () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "reportLostSim" }
        })
      );
      // GA4EventsService.publish(
      //   personalDetailsSubmitEventData(
      //     GA4EVENTS.REPLACE_AND_REISSUE_CLICK,
      //     props.GA4EventPlanDetails
      //   )
      // );
    };

    const showPlanChangeModal = () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "planChange" }
        })
      );
    };

    const onNextClickInOffHours = () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "offlineHourAction" }
        })
      );
    };

    const showInitiatePortInModal = () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "initiatePortIn" }
        })
      );
    };

    const openLiveChat = () => {
      activateZendeskWidget(zEIdentify);
    };

    const downloadContractualAgreement = (data = contractualAgreementForm) => {
      const downloadAnchor = document.createElement("a");
      const file = new Blob([data], {
        type: "application/pdf"
      });
      downloadAnchor.href = URL.createObjectURL(file); //`${CONTRACTUAL_AGREEMENT_FORM}`;
      downloadAnchor.download = "contractual_agreement.pdf";
      downloadAnchor.click();
    };

    if (isActionInProgress) return <Spinner size={200} sx={styles.fullPage} />;

    // ------ Rendering manage order page for countries with route authentication  ------
    /**
     * Manage Order page with authorized access
     * For logged in customers if they do not have an order, manage order page will be blank.
     * Therefore they are redirected to plan purchase journey in this case.
     */
    if (isOrderDetailsEmpty)
      gotoUrl(pathWithChannel(paths.PRE_CHECKOUT, "web"));

    /**
     * Manage Order page with authorized access
     * If Customer has an order and logged in show detailed manage order page
     */
    return (
      <Component
        {...props}
        {...order}
        orderOrPhone={get(formValues, "orderRefOrPhoneNumber", "")}
        dob={get(formValues, "dob", "")}
        buttonTheme={buttonTheme}
        formValues={formValues}
        locale={locale}
        downloadContractualAgreementForm={downloadContractualAgreementForm}
        downloadContractualAgreement={downloadContractualAgreement}
        emailContractualAgreement={emailContractualAgreement}
        onNextClickInOffHours={onNextClickInOffHours}
        reportLostSim={reportLostSim}
        cancelPortOut={cancelPortOut}
        openLiveChat={openLiveChat}
        showInitiatePortInModal={showInitiatePortInModal}
        showPlanChangeModal={showPlanChangeModal}
        openOrderCancellationModal={openOrderCancellationModal}
      />
    );
  };

const navigateToOrderAction = (
  orderActionName,
  fullyQualifiedURL,
  anchorTarget
) => {
  let urlToUse = fullyQualifiedURL
    ? fullyQualifiedURL
    : `/manage/${orderActionName.replace(/_/g, "-")}`;

  //to check with c
  if (orderActionName === ORDER_ACTION_TYPES.OUTSTANDING_BILLS) {
    gotoUrl(paths.OUTSTANDING_BILLS);
    return;
  }

  if (anchorTarget === "_self") {
    gotoUrl(urlToUse);
  } else {
    window.open(urlToUse, anchorTarget);
  }
};

const mapStateToProps = createStructuredSelector({
  formValues: makeSelectManageOrderForm(),
  formValidations: makeSelectPageValidations(forms.MANAGE_ORDER_FORM),
  lostSimFormValues: makeSelectReportLostSimForm(),
  loggedInUserDetail: makeSelectLoggedInUserDetails(),
  loggedInUserPlanName: makeSelectLoggedinUserPlanName(),
  isActionInProgress: makeSelectIsFetchingData([
    "guestOrder",
    "orderDetails",
    "userOrders",
    "emailAgreementForm",
    "cancelPortOut",
    "outstandingBillPayments"
  ]),
  order: makeSelectGuestOrder(),
  minimumAge: makeSelectMinimumAge(),
  locale: makeSelectLocale(),
  monthOptions: makeSelectMonthOptions(),
  dateOptions: makeSelectDateOptions(),
  yearOptions: makeSelectYearOptions(),
  appSettings: makeSelectAppSettings(),
  themeColors: makeSelectThemeColors(),
  selectedNumber: makeSelectFormattedSelectedNumber(),
  sessionToken: makeSelectSessionToken(),
  userToken: makeSelectAuthToken(),
  userOrders: makeSelectUserOrders(),
  userOrderRefs: makeSelectUserOrderRefs(),
  simActivationPayload: makeSelectSimActivationPayload(),
  orderRef: makeSelectOrderRef(),
  workflow: makeSelectWorkflow(),
  contractualAgreementForm: makeSelectContractualAgreementForm(),
  numberType: makeSelectContractType(),
  initialValues: () => ({
    orderRefOrPhoneNumber: urlParam("order_ref") || urlParam("phone_number")
  }),
  eligibileActions: makeSelectEligibleActions(),
  eligiblePlans: makeSelectEligiblePlans(),
  reversePortinEligibility: makeSelectReversePortinStatus(),
  deliveryDetailsItems: makeSelectDeliveryDetailsItems(),
  isModalView: makeSelectIsModalView(),
  modalType: makeSelectModalType(),
  isDeliveryInfoAvailable: makeSelectIsDeliveryInfoAvailable(),
  dateOfBirthFieldOrder: makeSelectPageData("dateOfBirthFieldOrder"),
  zEIdentify: makeSelectzEIdentifyUserData(),
  serviceInstanceNumber: makeSelectServiceInstanceNumber(),
  currentPhoneNumber: makeSelectCurrentPhoneNumber(),
  outstandingBillAmount: makeSelectOutstandingBillAmount(),
  isActiveTelcoUser: makeSelectIsActiveTelcoUser(),
  hasOutstandingPayments: makeSelectIsOutstandingPayable(),
  isOrderDetailsEmpty: makeSelectIsOrderDetailsEmpty(),
  isPortingUser: makeSelectIsPortingUser(),
  orderCancellationPayload: makeSelectOrderCancellationPayload(),
  external_id: makeSelectExternalUserId(),
  isSimReplacementKYC: makeSelectIsSimReplacementKYC(),
  onestopPortoutStatus: makeSelectOnestopPortoutStatus(),
  enabledEsimAPI: isEnableESimActivationApi,
  GA4EventPlanDetails: makeSelectGA4PlanDetails(),
  orderChannel: makeSelectUserOrderChannel()
});

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    setData: payload => {
      dispatch(setData(payload));
    },
    fetchGuestOrder: values => {
      const {
        nric,
        orderRefOrPhoneNumber,
        dob,
        sessionToken,
        userToken
      } = values;
      const params = {
        nric,
        order_or_phone: orderRefOrPhoneNumber,
        dob: dob,
        activate: false,
        update_ttl: true,
        session_token: sessionToken,
        user_token: userToken
      };
      dispatch(
        OMS.V4.fetchGuestOrder(params, order => {
          if (!isEmpty(order)) {
            gotoUrl(paths.MANAGE_ORDER, true, {
              reset: null,
              iccid: null
            });
          }
        })
      );
    },
    fetchLiquidUrl: (bodyParams, onSuccess) => {
      dispatch(UserService.V4.fetchLiquidUrl(bodyParams, onSuccess));
    },
    fetchUserOrders: () => {
      dispatch(
        OMS.V4.fetchUserOrders({}, orders => {
          if (!isEmpty(orders)) {
            gotoUrl(paths.MANAGE_ORDER, true, {
              reset: null,
              iccid: null
            });
          }
        })
      );
    },
    manageAnotherOrder: () => {
      dispatch(
        clearData([
          forms.MANAGE_ORDER_FORM,
          "guestOrder",
          "verifyOTP",
          "orderDetails",
          "suspensionPaymentDetails",
          "eligibilitySettings"
        ])
      );
    },
    resetData: () => {
      dispatch(
        clearData(["verifyOTP", "orderDetails", "suspensionPaymentDetails"])
      );
    },
    setGuestOrder: order => {
      dispatch(setData({ key: "guestOrder", data: order }));
    },
    // setOrderAction: (action, setShowModal) => {
    //   setShowModal(true);
    //   dispatch(
    //     setData({ key: "guestOrder", data: { order_action_name: action } })
    //   );
    // },
    setOrderAction: selectedActionConfig => {
      dispatch(
        setData({
          key: "guestOrder",
          data: { order_action_name: selectedActionConfig.action }
        })
      );
      dispatch(
        setData({
          key: "guestOrder",
          data: { otp_action_type: selectedActionConfig.otpActionType }
        })
      );
    },
    activateSimCard: (params, callback) =>
      dispatch(OMS.V4.activateSimCard(params, callback)),
    activateReplaceSimCard: (params, callback) => {
      dispatch(clearData(["PlanChangeVerifyKyc"]));
      dispatch(OMS.V4.activateReplaceSimCard(params, callback));
    },
    fetchLoggedInUserDetails: () => {
      const params = loggedInUserDetailsAPIParams();
      const newToken = urlParam("auth_token");
      const tokenHeader = newToken ? { "X-AUTH": newToken } : {};
      dispatch(UserService.V4.fetchLoggedInUserDetails(tokenHeader, params));
    },
    fetchOrderDetailsAndNavigate: ({
      orderRef,
      orderActionName,
      sessionToken,
      closeModal,
      locale,
      fullyQualifiedURL,
      anchorTarget
    }) => {
      const params = {
        type: orderActionName,
        order_ref: orderRef,
        session_token: sessionToken
      };

      dispatch(
        fetchOrderDetails(params, details => {
          if (!isEmpty(details)) {
            isFunction(closeModal) && closeModal();

            navigateToOrderAction(
              orderActionName,
              fullyQualifiedURL,
              anchorTarget
            );
          } else {
            Alert.error(
              get(locale, "order_details_not_found", "Order Details Not Found")
            );
          }
        })
      );
    },
    initiatePortIn: (params, callback) =>
      dispatch(initiatePortIn(params, callback)),
    fetchEligibleActions: () => {
      const headers = { "X-REQUEST-ID": uuid() };
      dispatch(Quilt.V1.fetchEligibleServices(headers));
    },
    contactCustomerService: zEIdentify => {
      activateZendeskWidget(zEIdentify);
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "reportLostSim" }
        })
      );
    },
    hideModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
    },
    fetchOutstandingBillsInfo: () => {
      dispatch(Billing.V4.fetchOutstandingBillsInfo());
    },
    fetchOrderCancellationStatus: (stateProps, callback) => {
      const params = {
        order_ref: get(stateProps, "orderRef", "")
      };
      dispatch(fetchOrderCancellationStatus(params, callback));
    },
    cancelOrder: stateProps => {
      const { orderCancellationPayload, external_id } = stateProps;
      dispatch(
        submitOrderCancellation(orderCancellationPayload, (result, errors) => {
          if (!errors) {
            const headers = {};
            const params = {
              device_id: getDeviceId(),
              user_id: external_id
            };
            dispatch(
              userLogout(headers, params, () => {
                if (isWebview())
                  gotoUrl(paths.LOGGED_OUT, false, {}, true, { reset: true });
                else {
                  gotoUrl(
                    pathWithChannel(`${paths.LOG_IN}`, "web"),
                    false,
                    {},
                    true,
                    {
                      reset: true
                    }
                  );
                }
              })
            );
            dispatch(
              setData({
                key: "isModalView",
                data: { isModalView: false, type: "" }
              })
            );
          } else {
            dispatch(
              setData({
                key: "isModalView",
                data: { isModalView: false, type: "" }
              })
            );
          }
        })
      );
    },
    verifyKycDetailsForPlanChange: navigate => {
      dispatch(
        setData({
          key: "PlanChangeVerifyKyc",
          data: { inPlanChangeVerifyKycState: true }
        })
      );
      navigate();
    },
    scheduleReplaceSimDelivery: navigate => {
      dispatch(clearData(["PlanChangeVerifyKyc"]));
      navigate();
    }
  };
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  nextPage: nextPageWithParams(
    paths.DASHBOARD,
    stateProps.selectedNumber,
    stateProps.userToken
  ),
  selectUserOrder: order_ref => {
    const userOrder = find(stateProps.userOrders, { order_ref });
    isEmpty(userOrder)
      ? dispatchProps.manageAnotherOrder()
      : dispatchProps.setGuestOrder(userOrder);
  },
  getLiquidUrl: () => {
    const deeplink = getDeepLink();
    let LiquidRedirectUrl = `${window.location.origin}${paths.USER_DETAILS}`;
    if (stateProps.isSimReplacementKYC) {
      LiquidRedirectUrl = `${deeplink}${paths.APPLOGIN}`;
    } else if (isWebview()) {
      LiquidRedirectUrl = `${deeplink}${paths.WEBLOGIN}`;
    }
    const bodyParams = {
      order_id: stateProps.orderRef,
      redirect_url: LiquidRedirectUrl
    };

    const onSuccess = (result, errors, status) => {
      const liquidUrl = get(result, "application_url");
      if (!errors && liquidUrl) {
        const finalUrl = isWebview()
          ? liquidUrl.replace("https", MOBILE_APP_BROWSER_PROTOCOL)
          : liquidUrl;

        gotoUrl(finalUrl);
      } else {
        if (status === 409) {
          gotoUrl(paths.USER_DETAILS);
        }
      }
    };

    dispatchProps.fetchLiquidUrl(bodyParams, onSuccess);
  },
  activateSimCardAndNavigate: navigate => {
    const isPortIn =
      get(stateProps, "order.contractType") === CONTRACT_TYPES.PORT_IN;

    const activateMethod = isPortIn
      ? dispatchProps.initiatePortIn
      : dispatchProps.activateSimCard;

    activateMethod(stateProps.simActivationPayload, (_, error) => {
      if (isEmpty(error)) {
        AdjustAnalytics.pushEvent(ADJUST_EVENTS.PLAN_ACTIVATED);
        GA4EventsService.publish(
          personalDetailsSubmitEventData(
            GA4EVENTS.SIM_ACTIVATION,
            stateProps.GA4EventPlanDetails
          )
        );
        navigate();
      }
    });
    // GA4EventsService.publish(
    //   personalDetailsSubmitEventData(
    //     GA4EVENTS.ISSUE_ESIM_CLICK,
    //     stateProps.GA4EventPlanDetails
    //   )
    // );
  },
  // reActivate esim is available only for regular esim orders
  reActivateSimCardAndNavigate: navigate => {
    dispatchProps.activateReplaceSimCard(
      stateProps.simActivationPayload,
      (_, error) => {
        if (isEmpty(error)) {
          navigate();
        }
      }
    );
  },
  onActionClicked: (
    params,
    action,
    otpActionType,
    anchorTarget,
    fullyQualifiedURL,
    requireOTPCall
  ) => {
    dispatchProps.setOrderAction({ action, otpActionType });
    if (requireOTPCall) {
      params.setShowModal(true);
    } else {
      navigateToOrderAction(action, fullyQualifiedURL, anchorTarget);
    }
  },
  contactCustomerService: () => {
    dispatchProps.contactCustomerService(stateProps.zEIdentify);
  },
  openOrderCancellationModal: () => {
    dispatchProps.fetchOrderCancellationStatus(stateProps, (result, error) => {
      if (!error) {
        dispatchProps.setData({
          key: "isModalView",
          data: { isModalView: true, type: "orderCancellation" }
        });
      } else {
        dispatchProps.setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        });
      }
    });
  },

  hideModalAndCancelOrder: () => {
    dispatchProps.cancelOrder(stateProps);
  },
  getLiquidUrlForPlanChange: navigate => {
    /* fetchLiquidUrl API call in manage order page and use it in
    confirm-kyc-method page*/
    const deeplink = getDeepLink();
    let liquidRedirectUrl = `${window.location.origin}${paths.USER_DETAILS}`;
    if (isWebview()) {
      liquidRedirectUrl = `${deeplink}${paths.APPLOGIN}`;
    }

    const liquidParams = {
      order_id: stateProps.orderRef,
      redirect_url: liquidRedirectUrl
    };

    const onSuccess = (result, errors, status) => {
      const liquidUrl = get(result, "application_url");
      if (!errors && liquidUrl) {
        navigate();
      } else if (status === LIQUID_URL_CONSUMED_CODE) {
        gotoUrl(paths.USER_DETAILS);
      }
    };

    dispatchProps.fetchLiquidUrl(liquidParams, onSuccess);
  }
});

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);

const enhance = compose(
  withConnect,
  reduxForm({
    form: forms.MANAGE_ORDER_FORM,
    validate: validate,
    destroyOnUnmount: false
  })
);

export default enhance(ManageOrderHOC(SmartRender));

const personalDetailsSubmitEventData = (event, details = {}) => {
  const {
    customer_id,
    plan_name,
    plan_type,
    sim_type,
    order_ref,
    journey_type,
    number_type
  } = details;

  return {
    event,
    ecommerce: {
      customer_id,
      order_ref,
      plan_name,
      journey_type,
      plan_type,
      sim_type,
      number_type
    }
  };
};
