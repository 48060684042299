import React from "react";
import { Box } from "ui-components";
import ESignCard from "components/ESignCard";

const ESIGN_CARD = ({ uploadSignature, data }) => {
  return (
    <Box mb={6}>
      <ESignCard onUpload={uploadSignature} {...data} />
    </Box>
  );
};

export default ESIGN_CARD;
