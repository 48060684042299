import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, Text, Link } from "ui-components";
import { COLORS, iconThemes } from "ui-components/theme";

const PlanPrice = ({
  price,
  price_label,
  paySummaryItems,
  message,
  bgColor,
  pillColor,
  textColor,
  fitPillContent,
  isPrepaid,
  productLink
}) => (
  <Box
    my={5}
    width={["85%", "50%", "50%"]}
    style={{ position: "relative", left: "-6px" }}
  >
    <Flex
      py={1}
      justifyContent="space-between"
      alignItems="center"
      backgroundColor={bgColor}
      height={"3rem"}
      textAlign={fitPillContent ? "left" : "right"}
      width={fitPillContent ? ["fit-content"] : ["100%"]}
    >
      <Box
        width={fitPillContent ? ["fit-content"] : ["calc(100% - 3rem)"]}
        paddingLeft={fitPillContent ? "2rem" : "1.5rem"}
      >
        <Text
          fontWeight="bolder"
          fontSize={["1.2rem", "1.4rem", "1.5rem"]}
          style={{ whiteSpace: "nowrap" }}
          py="4px"
          color={textColor || COLORS.white}
        >
          {!price_label ? (
            isPrepaid ? (
              price
            ) : (
              <FormattedMessage id={"price_per_month"} values={{ price }} />
            )
          ) : (
            <FormattedMessage id={price_label} values={paySummaryItems} />
          )}
        </Text>
      </Box>
      <Box
        height={"3rem"}
        width={"3rem"}
        backgroundColor={pillColor}
        style={{ position: "relative", right: "-1.5rem", borderRadius: "50%" }}
      ></Box>
    </Flex>
    {message && (
      <Flex
        marginLeft={["1.5rem", "2rem", "3rem"]}
        my={4}
        width={["fit-content"]}
        color={COLORS.black}
      >
        <FormattedMessage html id={message} />
      </Flex>
    )}

    {productLink && (
      <Link
        href={productLink}
        target="_blank"
        sx={{
          marginLeft: ["1.5rem", "2rem", "3rem"],
          lineHeight: "1.2",
          opacity: "0.8",
          color: COLORS.black,
          textDecoration: "underline !important"
        }}
      >
        <FormattedMessage id="more_details" />
      </Link>
    )}
  </Box>
);

PlanPrice.defaultProps = {
  price: "",
  price_label: "",
  paySummaryItems: {},
  message: "",
  bgColor: iconThemes.blue,
  pillColor: iconThemes.cyan
};

export default PlanPrice;
