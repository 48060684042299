import React from "react";
import PlanDetailsCard from "components/PlanDetailsCard";

const PAY_MONTHLY_DETAILS_CARD = ({ data, ...props }) => (
  <PlanDetailsCard
    {...props}
    data={{ ...data, showSummary: true }}
    data-testid="pay-monthly-details-card"
  />
);

export default PAY_MONTHLY_DETAILS_CARD;
