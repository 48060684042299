import React, { memo, useEffect } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect, useSelector, useDispatch } from "react-redux";
import get from "lodash/get";
import head from "lodash/head";
import { createStructuredSelector } from "reselect";
import validate from "validations";
import { forms, publicPaths, AUTH_MODES } from "utils/constants";
import SmartRender from "containers/SmartRender";
import { getDeviceId } from "utils/localStorage";
import { makeSelectLoginEmail } from "ducks/selectors/forms/loginForm";
import { gotoUrl, getChannelFromUrl } from "utils/helpers";
import {
  makeSelectPageValidations,
  makeSelectThemeColors,
  makeSelectLoginFormMessageData,
  makeSelectSocialLoginEmails,
  makeSelectOnFailureRedirectUrlSelector,
  makeSelectIsFetchingData,
  makeSelectUserConfirmLostService
} from "ducks/selectors";
import Spinner, { styles } from "components/Spinner";
import { UserService } from "ducks/actions";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";

const EmailSelection = Component =>
  function Comp(props) {
    const { emailList, change, isFetchingData } = props;
    const dispatch = useDispatch();
    const selectedEmail = useSelector(makeSelectLoginEmail());
    const haveSingleEmail = emailList && emailList.length === 1;
    const VerifyOTPPath = publicPaths.VERIFY_OTP.replace(
      ":channel",
      getChannelFromUrl()
    );

    useEffect(() => {
      if (haveSingleEmail) {
        const email = get(head(emailList), "value");
        change("email", email);
        callOtpSigningFlow(email);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [haveSingleEmail]);

    /**
     * Dispatches the OTP for email request.
     * Upon successful response this will redirect user to OTP page
     * @param {string} email user provided email
     */
    const requestOTPForEmail = email => {
      const additionalHeaders = {};
      const bodyParams = {
        email,
        auth_mode: AUTH_MODES.ENHANCED_EMAIL_OTP
      };
      /**
       * redirect user to OTP page if successful
       * @param {object} response success response result
       * @param {object} errors API errors only if failed
       */
      const onSubmitCallback = (response, errors) => {
        const success = !errors;
        if (success) {
          const queryParams = { key: Date.now(), isSocialLogin: true };
          gotoUrl(VerifyOTPPath, false, queryParams);
        } else {
          console.error("Request OTP failed. Cause: ", errors);
        }
      };
      dispatch(
        UserService.V4.userLoginOTP(
          additionalHeaders,
          bodyParams,
          onSubmitCallback
        )
      );
    };
    /**
     * Dispatches the User Login action event.
     * Upon response this will dispatch the OTP request
     */
    const onClickContinueButton = () => {
      callOtpSigningFlow(selectedEmail);
    };

    const callOtpSigningFlow = email => {
      const deviceId = getDeviceId();
      const headers = {};
      const bodyParams = {
        email: email,
        device_id: deviceId
      };
      const onSuccess = (_, errors) => {
        if (!errors) {
          requestOTPForEmail(email);
        }
      };
      dispatch(UserService.V4.userLogin(headers, bodyParams, onSuccess));
      GA4EventsService.publish(
        emailEventData(GA4EVENTS.EMAIL_SUBMITTED, props.GA4EventPlanDetails)
      );
    };

    return isFetchingData ? (
      <Spinner size={200} sx={styles.fullPage} />
    ) : (
      <Component {...props} onSubmit={onClickContinueButton} nobox />
    );
  };
const mapStateToProps = createStructuredSelector({
  formValidations: makeSelectPageValidations(forms.USER_LOGIN_FORM),
  emailList: makeSelectSocialLoginEmails(),
  failureUrl: makeSelectOnFailureRedirectUrlSelector(),
  loginEmail: makeSelectLoginEmail(),
  themeColors: makeSelectThemeColors(),
  messageData: makeSelectLoginFormMessageData(),
  isFetchingData: makeSelectIsFetchingData(["userLoginOTP", "userLogin"]),
  userConfirmLostService: makeSelectUserConfirmLostService()
});
const withConnect = connect(mapStateToProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.USER_LOGIN_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(EmailSelection(SmartRender));


const emailEventData = (event, details = {}) => {
  const {
    customer_id,
    plan_name,
    plan_type,
  } = details;

  return {
    event,
    ecommerce: {
      customer_id,
      plan_name,
      journey_type: "au-portin",
      plan_type
    }
  };
};