import React, { useCallback, useEffect } from "react";
import FormattedMessage from "components/FormattedMessage";
import { useDropzone } from "react-dropzone";
import { Box, Flex, Text, Input } from "ui-components";
import Spinner from "components/Spinner";
import { ReactComponent as IconCamera } from "assets/icons/icon-camera.svg";
import { ReactComponent as IconTick } from "assets/icons/icon-green-tick.svg";
import isFunction from "lodash/isFunction";
import Alert from "react-s-alert";
import { Button } from "rebass";

function DropIdentity(props) {
  const {
    loading,
    completed,
    type,
    frontOrBack,
    label,
    onDrop,
    name,
    testid,
    onChange,
    onUpload,
    uploadButtonSx = {},
    uploadButton = false,
    uploadButtonText,
    accept = "image/*,application/pdf",
    maxSize = 8 * 1024 * 1024,
    multiple = false
  } = props;

  const onDropCallback = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach(file => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          if (file.size > maxSize) {
            Alert.warning(
              `File size should be less than ${maxSize / (1024 * 1024)} MB`
            );
          } else {
            onUpload && onUpload(reader.result);
            onDrop && onDrop(type, frontOrBack, file, reader.result);
          }
        };

        // reader.readAsArrayBuffer(file);
        reader.readAsDataURL(file);
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    isFunction(onChange) && onChange(completed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completed]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDropCallback,
    accept,
    multiple
  });

  /**
   * The component is capable to to render as drop zone or as an uploader button
   */
  return uploadButton ? (
    <Box {...getRootProps()} style={{ width: "max-content" }}>
      <FormattedMessage id="or" />
      <Button sx={uploadButtonSx} variant="outline">
        <FormattedMessage id={uploadButtonText} />
        <Input {...getInputProps()} />
      </Button>
    </Box>
  ) : (
    <Flex
      {...getRootProps()}
      sx={{
        mx: -1,
        my: 4,
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        borderColor: "gray.0",
        borderStyle: "dashed",
        borderWidth: 2,
        borderRadius: 1,
        height: 120
      }}
      data-testid={`${testid}_DropIdentity`}
      id={`${testid}_DropIdentity`}
    >
      <Box sx={{ textAlign: "center" }} data-testid={`${testid}_field`}>
        {loading ? (
          <Spinner sx={{ color: "blue" }} />
        ) : (
          <>
            <Input
              name={name}
              {...getInputProps()}
              data-testid={`${testid}_input`}
            />

            <Flex mb={2} justifyContent="center">
              {completed ? (
                <IconTick className={`${testid}_field__completed`} />
              ) : (
                <IconCamera className={`${testid}_field__pending`} />
              )}
            </Flex>
            <Flex justifyContent="center" px="2">
              <FormattedMessage id={label}>
                {msg => <Text fontWeight={500}>{msg}</Text>}
              </FormattedMessage>
            </Flex>
          </>
        )}
      </Box>
    </Flex>
  );
}

DropIdentity.defaultProps = {
  label: "translation_missing"
};

export default DropIdentity;
