import { loggedInUserDetailsSelector } from "ducks/selectors";
import get from "lodash/get";
import { createSelector } from "reselect";

export const selectzEIdentifyUserData = createSelector(
  loggedInUserDetailsSelector,
  userDetails => {
    const { email, telco_info } = userDetails;
    const name = get(telco_info, "first_name") || get(telco_info, "kanji_name");
    const phone = get(telco_info, "phone_no");
    return {
      name,
      email,
      phone
    };
  }
);

export const makeSelectzEIdentifyUserData = () => selectzEIdentifyUserData;
