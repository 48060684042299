import React, { memo, useEffect } from "react";
import queryString from "query-string";
import Spinner, { styles } from "components/Spinner";
import {
  getItem,
  isLinkAccount,
  SOCIAL_MEDIA_STATE,
  SOCIAL_MEDIA_CLIENT_ID,
  getRedirectURL
} from "utils/localStorage";
import {
  LOGIN_REDIRECT_CHANNEL_MAPPING,
  publicPaths,
  SOCIAL_MEDIA_TYPES
} from "utils/constants";
import { gotoUrl } from "utils/helpers";
import { useSocialAuthUserServiceHandler, useLinkUserAccount } from "hooks";
import get from "lodash/get";
import SmartRender from "containers/SmartRender";
import { connect, useDispatch } from "react-redux";
import {
  makeSelectIsModalView,
  makeSelectIsSignUpWithIPDB,
  makeSelectModalType,
  makeSelectLinkSocialAccJWLError
} from "ducks/selectors";
import { setData } from "ducks/actions";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

const SocialAuthRedirection = Component =>
  function Comp(props) {
    const dispatch = useDispatch();
    const { showModalView, isSignUpWithAuIPDB } = props;
    let onFailureRedirectUrl = "";
    let channel = "";
    let redirectChannel = "";

    const queryParams = queryString.parse(window.location.search);
    const code = queryParams.code;

    if (queryParams && queryParams.state) {
      const backRoute = queryParams.state.substring(
        queryParams.state.indexOf("z")
      );
      onFailureRedirectUrl = backRoute.replace(/z/g, "/");
      channel = onFailureRedirectUrl.split("/")[1];
      redirectChannel = get(LOGIN_REDIRECT_CHANNEL_MAPPING, channel, channel);
    }

    const linkUserAcount = useLinkUserAccount();
    const authenticateWithUserService = useSocialAuthUserServiceHandler(
      channel,
      onFailureRedirectUrl,
      redirectChannel
    );

    const getProviderName = () => {
      const pathName = window.location.pathname;
      switch (pathName) {
        case publicPaths.LINE_AUTHENTICATION:
          return SOCIAL_MEDIA_TYPES.LINE;
        case publicPaths.YAHOO_AUTHENTICATION:
          return SOCIAL_MEDIA_TYPES.YAHOOJP;
        case publicPaths.GOOGLE_AUTHENTICATION:
          return SOCIAL_MEDIA_TYPES.GOOGLE;
        case publicPaths.FACEBOOK_AUTHENTICATION:
          return SOCIAL_MEDIA_TYPES.FACEBOOK;
        case publicPaths.APPLE_AUTHENTICATION:
          return SOCIAL_MEDIA_TYPES.APPLE;
        case publicPaths.AU_AUTHENTICATION:
          return SOCIAL_MEDIA_TYPES.AU_LOGIN;
        default:
          return "";
      }
    };

    const getSocialAuthDetails = () => {
      const provider = getProviderName();
      let socialAuthData = {
        authorization_code: code,
        redirect_uri: `${window.location.origin}${window.location.pathname}`,
        provider: provider
      };

      if (
        provider === SOCIAL_MEDIA_TYPES.GOOGLE ||
        provider === SOCIAL_MEDIA_TYPES.AU_LOGIN
      ) {
        socialAuthData.client_id = getItem(SOCIAL_MEDIA_CLIENT_ID);
      }

      if (isSignUpWithAuIPDB) {
        socialAuthData = {
          ...socialAuthData,
          pin_redirect_uri: `${window.location.origin}${publicPaths.AU_IPDB_PIN_VERIFY}`,
          client_id: getItem(SOCIAL_MEDIA_CLIENT_ID)
        };
      }
      return socialAuthData;
    };

    useEffect(() => {
      const state = queryParams.state;
      if (
        Object.prototype.hasOwnProperty.call(queryParams, "error") ||
        !code ||
        state !== getItem(SOCIAL_MEDIA_STATE)
      ) {
        if (isSignUpWithAuIPDB) {
          dispatch(
            setData({
              key: "isModalView",
              data: { isModalView: true, type: "invalidCode" }
            })
          );
        } else {
          return gotoUrl(onFailureRedirectUrl);
        }
      } else {
        /**
         * Check if the user is requesting link_account. If yes we do "Link Account" API call.
         * Else it will be regular user authentication with user service API
         * Note:
         *  This is only for social logins that has page redirections.
         *  Social logins that doesnt do redirection (eg: Apple) will not be handled here.
         *  Those should be managed in respecting Components
         */
        if (isLinkAccount()) {
          const payload = { social_auth: getSocialAuthDetails() };
          linkUserAcount(payload);
        } else {
          authenticateWithUserService(getSocialAuthDetails());
        }
      }
      return () => props.hideModal();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return showModalView ? (
      <Component {...props} />
    ) : (
      <Spinner size={200} sx={styles.fullPage} />
    );
  };

const mapStateToProps = createStructuredSelector({
  showModalView: makeSelectIsModalView(),
  modalType: makeSelectModalType(),
  isSignUpWithAuIPDB: makeSelectIsSignUpWithIPDB(),
  messageData: makeSelectLinkSocialAccJWLError()
});

export function mapDispatchToProps(dispatch) {
  return {
    hideModal: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
    },
    hideModalAndRedirect: () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
      if (getRedirectURL()) {
        return gotoUrl(getRedirectURL(), false, {}, true);
      }
    },
    hideModalAndRedirectOnError: () => {
      let onFailureRedirectUrl = "";
      const queryParams = queryString.parse(window.location.search);
      if (queryParams && queryParams.state) {
        const backRoute = queryParams.state.substring(
          queryParams.state.indexOf("z")
        );
        onFailureRedirectUrl = backRoute.replace(/z/g, "/");
      }
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
      gotoUrl(onFailureRedirectUrl);
    }
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect, memo)(SocialAuthRedirection(SmartRender));
