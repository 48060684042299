import React, { memo, useEffect } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import SmartRender from "containers/SmartRender";
import { useSelector, connect, useDispatch } from "react-redux";
import { forms, publicPaths } from "utils/constants";
import validate from "validations";
import Spinner, { styles } from "components/Spinner";
import {
  makeSelectIsValidESimTransferOTP,
  makeSelectIsFetchingData,
  makeSelectSimTransferSuccess,
  makeSelectSimTransSuccessData,
  makeSelectSimTransferFailed,
  makeSelectSimTransFailureData,
  makeSelectEsimTransferUserConcent,
  makeSelectEsimTransferOtpPayload,
  makeSelectDataPlanActivatePayload,
  makeSelectbssmwOTPFailureData,
  makeSelectEsimTransferPlanChangeConsent,
  makeSelectIsModalView,
  makeSelectModalType
} from "ducks/selectors";
import {
  BSSMW,
  esimTransferPlanChangeConsent,
  esimTransferUserConcent,
  setData
} from "ducks/actions";

import isArray from "lodash/isArray";
import { gotoUrl, urlParam } from "utils/helpers";
import DataActivationControllerHelper from "../DataActivationControllerHelper";
import { removeItem } from "utils/localStorage";
import FormattedMessage from "components/FormattedMessage";
import { featureFlag5GSAEnabled } from "utils/featureFlags";

const ESimTransferStaticContent = SmartRender =>
  function Comp(props) {
    const dispatch = useDispatch();
    const compatibleNetworkType = urlParam("network_type");
    const matchedParams = props?.match?.params;
    const termsAgreed = useSelector(makeSelectEsimTransferUserConcent());
    const agreedToChangePlan = useSelector(
      makeSelectEsimTransferPlanChangeConsent()
    );
    const isValidOTP = useSelector(makeSelectIsValidESimTransferOTP());
    const transferSuccess = useSelector(makeSelectSimTransferSuccess());
    const successData = useSelector(makeSelectSimTransSuccessData());
    const transferFailed = useSelector(makeSelectSimTransferFailed());
    const failureData = useSelector(makeSelectSimTransFailureData());
    const bssmwOtpfailureData = useSelector(makeSelectbssmwOTPFailureData());
    const isTransferInProgress = useSelector(
      makeSelectIsFetchingData("submitESimTransfer")
    );
    const dataPlanActivatePayload = useSelector(
      makeSelectDataPlanActivatePayload()
    );
    const esimTransferOTPPayload = useSelector(
      makeSelectEsimTransferOtpPayload()
    );
    const showModalView = useSelector(makeSelectIsModalView());
    const modalType = useSelector(makeSelectModalType());

    const requestToken = urlParam("requestToken");
    const msisdn = urlParam("msisdn");

    useEffect(() => {
      if (transferSuccess) {
        DataActivationControllerHelper.dataPlan(dataPlanActivatePayload);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transferSuccess]);

    const verifySimTransferOTP = (navigate, payload) => {
      // redirects to consent screen if user is trying to access without concent is provided
      if (!termsAgreed)
        return gotoUrl(publicPaths.ESIM_TRANSFER_USER_CONCENT, true);

      // redirects to plan change consent screen if user required to do plan change
      if (
        featureFlag5GSAEnabled() &&
        compatibleNetworkType &&
        !agreedToChangePlan
      ) {
        return gotoUrl(publicPaths.ESIM_TRANSFER_PLAN_CHANGE_CONSENT, true);
      }

      const current_otp = payload?.esimtrasnfer_otp;
      const otp = isArray(current_otp) ? current_otp.join("") : current_otp;

      const apiPayload = {
        ...esimTransferOTPPayload,
        requestToken,
        otp
      };
      const whitelistedCodes = [400];

      dispatch(
        BSSMW.V2.submitESimTransfer(
          apiPayload,
          () => {
            navigate && navigate();
          },
          whitelistedCodes
        )
      );
    };

    const requestOTPForSimTransfer = callback => {
      const payload = {
        requestToken
      };
      const errorCodesToWhitelist = [400034, 400033, 400];

      dispatch(BSSMW.V2.requestOTP(payload, callback, errorCodesToWhitelist));
    };

    const showPlanChangeConsentModal = () => {
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: true, type: "PlanChangeForESim" }
        })
      );
    };

    const agreeToSimTransfer = navigate => {
      dispatch(esimTransferUserConcent(true));

      //remove if block after 5g release
      if (!featureFlag5GSAEnabled()) {
        requestOTPForSimTransfer((_, errors) => {
          if (!errors) navigate && navigate();
        });
      } else {
        // redirects to plan change consent screen if user required to do plan change
        if (compatibleNetworkType) {
          showPlanChangeConsentModal();
        } else {
          requestOTPForSimTransfer((_, errors) => {
            if (!errors) navigate && navigate();
          });
        }
      }
    };

    const agreeToPlanChange = navigate => {
      dispatch(esimTransferPlanChangeConsent(true));
      navigate && navigate();
    };

    const disagreeToSimTransfer = () => {
      dispatch(esimTransferUserConcent(false));
      dismissNative();
    };

    const disagreeToPlanChange = () => {
      dispatch(esimTransferPlanChangeConsent(false));
      dispatch(
        setData({
          key: "isModalView",
          data: { isModalView: false, type: "" }
        })
      );
      dismissNative();
    };

    const resendOTP = () => {
      const callback = () => {
        // removing existing timer key
        removeItem("esimtransfer.timer.startrecord");
        window.location.reload();
      };
      requestOTPForSimTransfer(callback);
    };

    function dismissNative() {
      DataActivationControllerHelper.dismiss();
    }

    if (isTransferInProgress) {
      const spinnerStyle = {
        ...styles.fullPage,
        paddingTop: "30vh"
      };
      delete spinnerStyle.top;

      return (
        <div style={{ backgroundColor: "white", height: "100vh" }}>
          <FormattedMessage id="esim-transfer_inprogress_msg" html />
          <Spinner size={200} sx={spinnerStyle} />
        </div>
      );
    }

    return (
      <SmartRender
        {...props}
        messageData={{
          ...matchedParams,
          msisdn,
          errorStatus: failureData?.status || 500,
          otpErrorMsg: bssmwOtpfailureData.message,
          otpErrorCode: bssmwOtpfailureData.code
        }}
        isValidOTP={isValidOTP}
        msisdn={msisdn}
        timerKey="esimtransfer"
        termsAgreed={termsAgreed}
        transferSuccess={transferSuccess}
        transferFailed={transferFailed}
        verifySimTransferOTP={verifySimTransferOTP}
        onSubmit={verifySimTransferOTP}
        dismiss={dismissNative}
        agreeToSimTransfer={agreeToSimTransfer}
        agreeToPlanChange={agreeToPlanChange}
        disagreeToSimTransfer={disagreeToSimTransfer}
        disagreeToPlanChange={disagreeToPlanChange}
        resendOTP={resendOTP}
        successData={successData}
        failureData={failureData}
        bssmwOtpfailureData={bssmwOtpfailureData}
        showModalView={showModalView}
        modalType={modalType}
      />
    );
  };

const mapStateToProps = createStructuredSelector({});
const mapDispatchToProps = createStructuredSelector({});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.ESIM_TRANSFER_OTP_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(ESimTransferStaticContent(SmartRender));
