import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { makeSelectHideRequiredStar } from "ducks/selectors/appSettings";
import ReactTooltip from "react-tooltip";
import { Flex, Box, Label, Text } from "ui-components";
import { COLORS, iconThemes } from "ui-components/theme";
import FormattedMessage from "components/FormattedMessage";
import max from "lodash/max";
import get from "lodash/get";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import split from "lodash/split";
import { safeEval, isMobile } from "utils/helpers";
import { IconStack, Information, HelpCircle, Circle } from "components/Icons";

const CustomLabel = ({
  label,
  required,
  optional,
  tooltip,
  infoTip,
  formValues,
  sx,
  css,
  locale,
  labelMessage,
  messageData = {},
  justifyContent = "space-between",
  themeColors,
  onInfoIconClick,
  bdColor,
  onClick = () => {}
}) => {
  const hideRequiredStar = useSelector(makeSelectHideRequiredStar());
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [tooltip, formValues]);

  const iconColor = get(themeColors, "primary", iconThemes.blue);
  const iconForeColor = get(infoTip || tooltip, "iconForeColor", "transparent");

  let tooltipContent = tooltip;
  if (isPlainObject(tooltip) && !isEmpty(tooltip)) {
    const { message: tooltipMessage, image: tooltipImage } = tooltip;
    const tooltipContentStyle =
      get(tooltip, "contentStyle") ||
      "max-width: 140px; min-height: 140px; min-width: 50px;";

    tooltipContent = `<div class='tooltip-content'  style='${tooltipContentStyle}'>`;
    const imageUrl = safeEval(get, formValues, tooltipImage);
    const imageAlt = last(split(imageUrl, "/"));
    const message = get(locale, "tooltipMessage", tooltipMessage);

    if (imageUrl) {
      tooltipContent += `<img class="tooltip-image" src="${imageUrl}" alt="${imageAlt}" />`;
    }

    if (message) {
      tooltipContent += `<span class='tooltip-message'>${message}</span>`;
    }
    tooltipContent += "</div>";
  }

  const offset = isMobile()
    ? { bottom: max([80, 80]), right: -25 }
    : { bottom: max([215, 215]), right: -25 };

  return (
    <>
      <Flex flexWrap="wrap">
        {label && (
          <Label
            alignItems="center"
            sx={sx}
            css={css}
            display="flex"
            justifyContent={justifyContent}
            flexWrap="wrap"
            onClick={onClick}
          >
            <Flex>
              {label}
              {required && !hideRequiredStar && (
                <>
                  &nbsp;
                  <Text as="span" color={COLORS.red[1]}>
                    *
                  </Text>
                </>
              )}
              {!required && optional && (
                <Text as="span">
                  &#40;
                  <FormattedMessage id="optional" />
                  &#41;
                </Text>
              )}
              {tooltipContent && (
                <>
                  &nbsp;
                  <Box
                    height={16}
                    width={16}
                    data-tip={tooltipContent}
                    data-for={"label-tooltip"}
                    data-place="top"
                    data-type="dark"
                    data-effect="float"
                    data-multiline={true}
                    data-background-color={bdColor ? bdColor : COLORS.cyan[0]}
                    data-text-color={COLORS.black}
                  >
                    <IconStack size="16px">
                      <Circle size="14px" color={iconForeColor} />
                      <HelpCircle color={iconColor} size="16px" />
                    </IconStack>
                  </Box>
                </>
              )}
              {isPlainObject(infoTip) && !isEmpty(infoTip) && (
                <>
                  &nbsp;
                  <Box height={16} width={16} onClick={onInfoIconClick}>
                    <IconStack size="16px">
                      <Circle size="14px" color={iconForeColor} />
                      <Information color={iconColor} size="16px" />
                    </IconStack>
                  </Box>
                </>
              )}
            </Flex>
            {labelMessage && (
              <Flex
                fontStyle="italic"
                fontWeight={500}
                color={COLORS.gray[1]}
                alignSelf={"flex-end"}
                right={0}
              >
                <FormattedMessage id={labelMessage} values={messageData} />
              </Flex>
            )}
          </Label>
        )}
      </Flex>
      {tooltipContent && (
        <ReactTooltip
          html={true}
          id={"label-tooltip"}
          scrollHide={true}
          resizeHide={true}
          offset={offset}
          overridePosition={({ left, top }) => {
            return { left: max([0, left]), top };
          }}
        />
      )}
    </>
  );
};

export default CustomLabel;
