import React, { memo, useCallback, useState, useEffect } from "react";
import { compose } from "redux";
import { reduxForm } from "redux-form";
import { connect, useSelector, useDispatch } from "react-redux";

import validate from "validations";
import { forms, paths, TELCO_API_ERRORS } from "utils/constants";
import SmartRender from "containers/SmartRender";
import { Telco } from "ducks/actions";
import {
  makeSelectThemeColors,
  makeSelectMinimumAge,
  makeSelectDateOptions,
  makeSelectMonthOptions,
  makeSelectYearOptions,
  makeSelectPageValidations,
  makeSelectPortoutFormInitialValues,
  makeSelectServiceInstanceNumber,
  makeSelectCurrentPhoneNumber,
  makeSelectFormValues,
  makeSelectIsFetchingData,
  makeSelectGuestOrder,
  makeSelectDeliveryDetailsItems,
  makeSelectEligibleActions,
  makeSelectkddiSubBrandsHosts,
  makeSelectOnestopPortoutStatus
} from "ducks/selectors";
import { createStructuredSelector } from "reselect";
import { getDOBSecurityHeader, gotoUrl, pathWithChannel } from "utils/helpers";
import usePostDocumentAPI from "hooks/usePostDocumentAPI";
import { getOneStopPortOutParams } from "utils/localStorage";

const RequestPortOut = Component =>
  function Comp(props) {
    const dispatch = useDispatch();
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [hasOneStopParam, setHasOneStopParam] = useState(false);

    const { submitPostApi } = usePostDocumentAPI("submit-portout-1stop");
    const accountNumber = useSelector(makeSelectServiceInstanceNumber());
    const msisdn = useSelector(makeSelectCurrentPhoneNumber());
    const dob = props?.formValues?.dob;
    const { kddiSubBrandsHosts } = props;

    useEffect(() => {
      const { onestop } = getOneStopPortOutParams() || {};

      if (onestop) {
        setHasOneStopParam(() => true);
      } else {
        setHasOneStopParam(() => false);
      }
    }, []);

    const oneStopMNPPortoutPost = (successData = {}) => {
      const { callback, onestop, param } = getOneStopPortOutParams() || {};
      const { valid, mnoCode: mno, kddiOct: kddioct } = successData;
      const params = {
        onestop,
        valid,
        mno
      };
      // adding kddioct param only for kddi sub brands
      if (isKDDISubBrand(callback)) {
        params.kddioct = kddioct;
      }
      const paramValue = param === "" || param;
      paramValue && (params["param"] = param);
      submitPostApi(callback, params);
    };

    const isKDDISubBrand = callbackUrl => {
      if (callbackUrl) {
        const urlObj = new URL(callbackUrl);
        const callbackUrlHost = urlObj.hostname;
        return kddiSubBrandsHosts.includes(callbackUrlHost);
      } else {
        return false;
      }
    };

    const pageNavigate = (isOneStop = false, oneStopData) => {
      if (isOneStop) {
        oneStopMNPPortoutPost(oneStopData);
      } else {
        setTimeout(() => {
          gotoUrl(pathWithChannel(paths.MANAGE_ORDER));
        }, 0);
      }
    };

    const navigateToPortOutTerminate = navigate => {
      navigate();
    };

    const onSubmit = useCallback(
      (_, data = {}) => {
        const { callback: oneStopPortOutCallBack = null } =
          getOneStopPortOutParams() || {};
        const isOneStop = !!oneStopPortOutCallBack;
        const { otp_payload } = data;
        const headers = getDOBSecurityHeader(dob);
        let requestPayLoad = {
          accountNumber,
          msisdn,
          otp_payload
        };
        if (isOneStop) {
          requestPayLoad = {
            ...requestPayLoad,
            onestop: {
              onestop: 1
            }
          };
        }

        dispatch(
          Telco.V1.requestMNPReservationNumber(
            headers,
            requestPayLoad,
            (result = {}, errors) => {
              if (!errors) {
                const { onestop } = result;
                setShowOTPModal(false);
                pageNavigate(isOneStop, onestop);
              } else {
                const { title } = errors || {};
                if (title !== TELCO_API_ERRORS.UNAUTHORISED) {
                  setShowOTPModal(false);
                }
              }
            }
          )
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [accountNumber, msisdn, dob]
    );

    return (
      <Component
        {...props}
        navigateToPortOutTerminate={navigateToPortOutTerminate}
        onSubmit={onSubmit}
        showOTPModal={showOTPModal}
        setShowOTPModal={setShowOTPModal}
        oneStopMNPPortoutPost={oneStopMNPPortoutPost}
        hasOneStopParam={hasOneStopParam}
      />
    );
  };

const mapStateToProps = createStructuredSelector({
  formValidations: makeSelectPageValidations(forms.REQUEST_PORTOUT_FORM),
  formValues: makeSelectFormValues(forms.REQUEST_PORTOUT_FORM),
  initialValues: makeSelectPortoutFormInitialValues(),
  isFetchingDetails: makeSelectIsFetchingData("requestMNPReservationNumber"),
  monthOptions: makeSelectMonthOptions(),
  dateOptions: makeSelectDateOptions(),
  yearOptions: makeSelectYearOptions(),
  minimumAge: makeSelectMinimumAge(),
  themeColors: makeSelectThemeColors(),
  order: makeSelectGuestOrder(),
  deliveryDetailsItems: makeSelectDeliveryDetailsItems(),
  eligibileActions: makeSelectEligibleActions(),
  kddiSubBrandsHosts: makeSelectkddiSubBrandsHosts(),
  onestopPortoutStatus: makeSelectOnestopPortoutStatus()
});
const mapDispatchToProps = {};
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.REQUEST_PORTOUT_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(RequestPortOut(SmartRender));
