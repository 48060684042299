import React from "react";
import get from "lodash/get";
import map from "lodash/map";
import isString from "lodash/isString";
import isEmpty from "lodash/isEmpty";
import { safeEval, parseConditionVariables } from "utils/helpers";
import normalizers from "normalizers";
import { Box, Flex } from "ui-components";
import PageFaqs from "components/PageFaqs";
import FeatureToggle from "components/FeatureToggle";
import featureMapping from "smart-components";

const showComponent = ({
  renderProps,
  pageProps,
  sharedConfig,
  formValues
}) => {
  const enableComponent = get(renderProps, "enable", false);
  const allowComponent = safeEval(
    get,
    formValues,
    parseConditionVariables(
      get(pageProps, "appSettings.conditions"),
      get(renderProps, "condition_variable")
    ) || get(renderProps, "condition"),
    true,
    pageProps
  );
  const enabledInConfig = get(sharedConfig, "enable", true);
  return (enableComponent || enabledInConfig) && allowComponent;
};

const componentProps = ({
  renderProps,
  pageProps,
  sharedConfig,
  formValues,
  appSettings,
  normalize,
  screenshotEnabled,
  component
}) => {
  const required = get(
    sharedConfig,
    "required",
    get(renderProps, "required", false)
  );

  const disabled = safeEval(
    get,
    formValues,
    get(renderProps, "disabled"),
    false,
    renderProps
  );

  const labelSuffix = safeEval(
    get,
    formValues,
    get(renderProps, "label_suffix"),
    "",
    renderProps
  );

  let label = get(renderProps, "label");
  label =
    labelSuffix && isString(labelSuffix) ? `${label}_${labelSuffix}` : label;

  const placeholderSuffix = safeEval(
    get,
    formValues,
    get(renderProps, "placeholder_suffix"),
    "",
    renderProps
  );

  let placeholder = get(renderProps, "placeholder");
  placeholder =
    placeholderSuffix && isString(placeholderSuffix)
      ? `${placeholder}_${placeholderSuffix}`
      : placeholder;

  return {
    ...appSettings,
    ...renderProps,
    ...pageProps,
    normalize,
    label,
    placeholder,
    required,
    disabled,
    screenshotEnabled,
    component,
    pageProps
  };
};

const renderSmartComponent = ({
  renderProps,
  pageProps,
  appSettings,
  normalize,
  screenshotEnabled,
  RenderComponent,
  component
}) => {
  const formValues = get(pageProps, "formValues", {});
  const lookup = get(renderProps, "lookup");
  const sharedConfig = get(appSettings, lookup);

  return showComponent({ renderProps, pageProps, sharedConfig, formValues }) ? (
    <RenderComponent
      {...componentProps({
        renderProps,
        pageProps,
        sharedConfig,
        formValues,
        appSettings,
        normalize,
        screenshotEnabled,
        component
      })}
    />
  ) : null;
};

const renderPageBanner = ({ pageBanner }) => {
  if (isEmpty(pageBanner)) return null;
  const data = {
    boxVariant: "banner",
    alt: "pageBanner",
    ...pageBanner
  };
  return featureMapping["IMAGE"]({ data });
};

const renderPageStepper = ({ pageStepper, padding }) => {
  if (isEmpty(pageStepper)) return null;
  const mt = pageStepper.marginTop || 0;
  const mb = pageStepper.marginBottom || 0;
  const pad = pageStepper.padding || padding;
  return (
    <Box variant="main" padding={pad} mb={mb} mt={mt}>
      {featureMapping["STEPPER"]({ ...pageStepper, standalone: true })}
    </Box>
  );
};

const renderPageFaqs = ({ pageFaqs = {}, messageData = {}, themeColors }) => (
  <PageFaqs {...pageFaqs} messageData={messageData} themeColors={themeColors} />
);

const renderPageComponents = ({
  pageComponents,
  pageProps,
  appSettings,
  screenshotEnabled
}) => {
  const FLEX_START = "FLEX_START";
  const FLEX_END = "FLEX_END";
  // Merge reusable components and page specific private components
  const allComponents = { ...featureMapping, ...pageProps.privateComponents };
  // Flex box wrapping related variables
  let flexStarted = false,
    flexEndReached = false,
    flexWrappedComponents = [],
    flexProps = {},
    eachItemProps = {};

  return map(pageComponents, (component, index) => {
    const { name, width } = component;
    // Checks if components should be wrapped inside a flex container
    if (name === FLEX_START) {
      flexStarted = true;
      flexWrappedComponents = [];
      flexProps = get(component, "data", {});
      eachItemProps = get(component, "eachItem", {});
      return null;
    }

    // Checks for the Flex container end component.
    if (name === FLEX_END) {
      flexStarted = false;
      flexEndReached = true;
    }

    const RenderComponent = allComponents[name];
    if (!RenderComponent && !flexEndReached) return null;
    const normalize = normalizers(component, appSettings);

    const FeatureToggledComponent = (
      <FeatureToggle
        appSettings={appSettings}
        component={component}
        key={`${name}-${index}`}
        width={width}
        render={renderProps =>
          renderSmartComponent({
            renderProps,
            pageProps,
            appSettings,
            normalize,
            screenshotEnabled,
            RenderComponent,
            component
          })
        }
      />
    );

    /**
     * If Flex wraping started all components are stored in an array.
     * This array then will be used to return the components wrappped in a flex box
     */
    if (flexStarted) {
      flexWrappedComponents.push(FeatureToggledComponent);
      return null;
    }

    /**
     * Once the flex end is reached all the components defined will be wrapped under flex box
     */
    if (flexEndReached) {
      // Need to set it to false to indicate the flex end is treated
      flexEndReached = false;
      return (
        <Flex
          key="flex-start-box"
          {...flexProps}
          data-testid="flex-start-box-id"
        >
          {flexWrappedComponents.map((flexWrapComponent, index) => {
            const { component: { parentStyle } = {} } =
              flexWrapComponent?.props || {};
            return (
              <Box key={index} {...eachItemProps} style={parentStyle}>
                {flexWrapComponent}
              </Box>
            );
          })}
        </Flex>
      );
    }

    return FeatureToggledComponent;
  });
};

export {
  renderPageBanner,
  renderPageComponents,
  renderPageStepper,
  renderPageFaqs
};
