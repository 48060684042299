import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Box, Flex, Text, Label } from "ui-components";
import { COLORS, iconThemes } from "ui-components/theme";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import find from "lodash/find";

const PayTodayNumberItem = ({ numberItem = {}, primaryColor }) => {
  const { label, value, price } = numberItem;
  return isEmpty(label) ? null : (
    <Box my={5} px={"1rem"}>
      <Flex py={1} justifyContent="space-between" alignItems="center">
        <Box paddingRight={1} justifySelf="flex-start" flex={1}>
          <Text>{label}</Text>
        </Box>
        <Box width={["fit-content"]}>
          {value > 0 && (
            <Text color={primaryColor} fontWeight="bold" py="4px">
              {price}
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

const SelectedNumberItem = ({ selectedNumber = {}, primaryColor }) => {
  const { type, formattedNumber, formattedPrice, price } = selectedNumber;
  return isEmpty(selectedNumber) || !formattedNumber ? null : (
    <Box my={5} px={"1rem"}>
      <Label color={COLORS.gray[3]} mb={2}>
        <FormattedMessage html id={`your_${type}`} />
      </Label>
      <Flex py={1} justifyContent="space-between" alignItems="center">
        <Box paddingRight={1} justifySelf="flex-start" flex={1}>
          <Text>{formattedNumber}</Text>
        </Box>
        <Box width={["fit-content"]}>
          {price > 0 && (
            <Text color={primaryColor} fontWeight="bold" py="4px">
              {formattedPrice}
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

const NumberItem = ({ payTodayItems, selectedNumber, primaryColor }) => {
  const numberItem = find(get(payTodayItems, "items"), {
    type: "number"
  });

  return !isEmpty(numberItem) ? (
    <PayTodayNumberItem numberItem={numberItem} primaryColor={primaryColor} />
  ) : (
    <SelectedNumberItem
      selectedNumber={selectedNumber}
      primaryColor={primaryColor}
    />
  );
};

NumberItem.defaultProps = {
  payTodayItems: {},
  selectedNumber: {},
  primaryColor: iconThemes.blue
};

export default NumberItem;
