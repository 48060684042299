import React from "react";
import FormattedMessage from "components/FormattedMessage";
import { Flex, Button, Text } from "ui-components";
import { getButtonColorVariant } from "ui-components/theme";
import get from "lodash/get";
import { gotoUrl } from "utils/helpers";

const OrderAuthorizationCard = props => {
  const { order, buttonTheme, data } = props;

  const buttonWidth = get(data, "buttonWidth", [1, 0.8, 0.6]);
  const buttonVariant = get(data, "buttonVariant", "outline");
  const authorized = get(order, "authorizeEnabled");
  const orderId = get(order, "id");
  const authorisePath = "/orders/authorize";

  return (
    <>
      {authorized && (
        <>
          <Flex flexDirection="column" alignItems="left">
            <Text fontWeight="bold">
              <FormattedMessage id={"can_not_be_there"} />
            </Text>
          </Flex>
          <Flex flexDirection="column" alignItems="center" paddingY="1rem">
            <Button
              key={"action-"}
              data-testid={"order-action-button"}
              my={1}
              variant={buttonVariant}
              sx={getButtonColorVariant(buttonTheme, buttonVariant)}
              width={buttonWidth}
              onClick={() => {
                gotoUrl(
                  authorisePath,
                  true,
                  {
                    order_id: orderId
                  },
                  true
                );
              }}
            >
              <FormattedMessage id={"authorize_someone_else"} />
            </Button>
          </Flex>
        </>
      )}
    </>
  );
};

export default OrderAuthorizationCard;
