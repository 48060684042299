import React from "react";
import get from "lodash/get";
import RadioGroupProviders from "components/FormFields/RadioGroupProviders";

const RADIO_GROUP_PROVIDERS = ({
  data: { ...dataProps } = {},
  styles,
  ...props
}) => {
  const width = get(styles, "width", [1 / 2, 1 / 3, 1 / 3]);
  const border = get(styles, "border", "1px solid #cccccc");
  const margin = get(styles, "margin", "5px");
  const borderRadius = get(styles, "borderRadius", "4px");
  const padding = get(styles, "padding", "5px 10px");

  return (
    <RadioGroupProviders
      {...dataProps}
      {...props}
      width={width}
      border={border}
      margin={margin}
      borderRadius={borderRadius}
      padding={padding}
    />
  );
};

export default RADIO_GROUP_PROVIDERS;
