import React from "react";
import get from "lodash/get";
import { Box, Flex } from "ui-components";
import { COLORS } from "ui-components/theme";
import FormattedMessage from "components/FormattedMessage";
import DropIdentityField from "components/FormFields/DropIdentityField";
import Label from "components/FormFields/Label";

const ID_UPLOADER = ({
  imageUploading,
  imageUploaded,
  uploadImage,
  appSettings,
  data,
  dropIdentityValidations,
  ...props
}) => {
  const config = get(appSettings, props.lookup, {});
  const { label, tooltip, required } = props;
  const type = get(data, "type") || "primary";
  const dropIdentityValidate = get(dropIdentityValidations, [type]);

  const accept = "image/jpeg,image/png,image/tiff,application/pdf";
  const maxSize = 8 * 1024 * 1024;

  const renderDropIdentity = side => (
    <Box width="49%" px={1}>
      <DropIdentityField
        label={get(config, `${side}_image_label`)}
        type={type}
        frontOrBack={side}
        loading={get(imageUploading, `${type}.${side}`)}
        onDrop={uploadImage}
        completed={get(imageUploaded, `${type}.${side}`)}
        field={`${props.lookup}.${side}_image`}
        testid={`${type}_${side}_image_uploader`}
        accept={accept}
        maxSize={maxSize}
        multiple={false}
        validate={dropIdentityValidate}
      />
    </Box>
  );

  return (
    <>
      {label && (
        <Label
          label={<FormattedMessage id={label} />}
          tooltip={tooltip}
          required={required}
        />
      )}
      <Label
        label={
          <FormattedMessage
            id="accepted_files_less_than_max_size"
            values={{ maxSize: `${maxSize / (1024 * 1024)} MB` }}
          />
        }
        css={{
          fontSize: "0.9rem !important",
          fontStyle: "italic",
          color: COLORS.gray[0],
          fontWeight: "normal !important"
        }}
      />
      <Flex justifyContent="space-between">
        {config.front_image && renderDropIdentity("front")}
        {config.back_image && renderDropIdentity("back")}
      </Flex>
    </>
  );
};

export default ID_UPLOADER;
