import { createSelector } from "reselect";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { v4 as uuid } from "uuid";

const esimtransferDomain = state =>
  get(state, "api.submitESimTransfer.data", {});
const bssmwOtpRequestDomain = state =>
  get(state, "api.bssmwRequestOTP.data", {});

const esimtransferErrorDomain = state =>
  get(state, "api.submitESimTransfer.error");
const bssmwOtpRequestDomainErrorDomain = state =>
  get(state, "api.bssmwRequestOTP.error", {});
const esimTransferUserConcentDomain = state =>
  get(state, "ui.esimTransfer", {});

const esimTransferPlanChangeConsentDomain = state =>
  get(state, "ui.esimTransfer.consent", {});

export const simTransferSuccess = createSelector(
  esimtransferDomain,
  data => !isEmpty(data)
);

export const simTransferSuccessData = createSelector(
  esimtransferDomain,
  data => data
);

export const simTransferFailed = createSelector(
  esimtransferErrorDomain,
  errData => !!errData
);

export const simTransferFailureData = createSelector(
  esimtransferErrorDomain,
  errData => errData
);

export const bssmwOtpFailureData = createSelector(
  bssmwOtpRequestDomainErrorDomain,
  errData => get(errData, "response.errorBody", {})
);

export const esimTransferUserConcent = () =>
  createSelector(
    esimTransferUserConcentDomain,
    (domain = {}) => domain.concent || false
  );

export const esimTransferPlanChangeConsent = () =>
  createSelector(
    esimTransferPlanChangeConsentDomain,
    (domain = {}) => domain.plan_change || false
  );

export const esimTransferOtpPayload = () =>
  createSelector(bssmwOtpRequestDomain, bssmwOtpRequestDomain => {
    const apiPayload = {
      requestID: `wfsr_${uuid()}`,
      targetIMEI: null,
      sourceIMSI: null,
      apiName: "tranfer-request",
      eSIMContrMgmtName: "kdl",
      targetEID: null,
      requestToken: null,
      otp: null,
      auth_id: get(bssmwOtpRequestDomain, "auth_id")
    };

    return apiPayload;
  });

export const dataPlanActivatePayload = createSelector(
  esimtransferDomain,
  (responseData = {}) => {
    const {
      targetEID: eid = "",
      targetICCID = [],
      sourceICCID = ""
    } = responseData;
    const paramsWithEid = {
      eid,
      targetIccids: targetICCID.map(iccid => ({
        iccid,
        "src-iccid": sourceICCID,
        state: "use-ds"
      }))
    };

    return paramsWithEid;
  }
);

export const makeSelectSimTransferSuccess = () => simTransferSuccess;
export const makeSelectSimTransSuccessData = () => simTransferSuccessData;
export const makeSelectSimTransferFailed = () => simTransferFailed;
export const makeSelectSimTransFailureData = () => simTransferFailureData;
export const makeSelectEsimTransferUserConcent = () =>
  esimTransferUserConcent();
export const makeSelectEsimTransferPlanChangeConsent = () =>
  esimTransferPlanChangeConsent();
export const makeSelectEsimTransferOtpPayload = () => esimTransferOtpPayload();
export const makeSelectDataPlanActivatePayload = () => dataPlanActivatePayload;
export const makeSelectbssmwOTPFailureData = () => bssmwOtpFailureData;
