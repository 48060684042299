const styles = {
  containerStyle: {
    mb: [50, 40, 60]
  },
  stepWrapperStyle: {
    justifyContent: "space-between",
    mb: 8
  },
  baseCircleStyle: {
    backgroundColor: "gray.0",
    borderRadius: 19,
    borderColor: "white",
    borderWidth: 3,
    borderStyle: "solid",
    width: 38,
    height: 38,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  activeCircleStyle: {
    backgroundColor: "magenta.3"
  },
  baseCircleTextStyle: {
    fontSize: 14,
    fontWeight: "500",
    color: "white !important",
    backgroundColor: "#858585",
    borderColor: "#858585",
    borderRadius: "33px",
    width: "25px",
    height: "25px",
    textAlign: "center",
    padding: "5px"
  },
  barStyle: {
    marginTop: -52,
    borderWidth: 2,
    borderColor: "gray.0",
    borderStyle: "solid"
  },
  active1: {
    backgroundColor: "black",
    borderColor: "black",
    borderRadius: "33px",
    width: "25px",
    height: "25px",
    textAlign: "center",
    padding: "4px"
  }
};

export default styles;
