import React from "react";
import get from "lodash/get";
import DropdownField from "components/FormFields/DropdownField";
import { constructOnChangeHandler, safeEval } from "utils/helpers";

const NATIONALITY_DROPDOWN = props => {
  const { disabled, formValues, topCountries } = props;

  const isDisabled = safeEval(get, formValues, disabled, false, props);

  return (
    <DropdownField
      options={topCountries}
      {...props}
      onChange={constructOnChangeHandler(props)}
      disabled={isDisabled}
    />
  );
};

export default NATIONALITY_DROPDOWN;
