import { UserService } from "ducks/actions";

/**
 * Calls user authenticate and then save the auth_token
 * @param {object} headers auth api headers
 * @param {object} params auth api params
 * @param {Function} callback callback function
 * @param {Function} dispatch dispatch function
 */
const authenticateUserAndSaveToken = (headers, params, callback, dispatch) => {
  const onUserAuthenticateCallback = (response, errors) => {
    callback && callback(response, errors);
  };

  dispatch(
    UserService.V4.userAuthenticate(headers, params, onUserAuthenticateCallback)
  );
};

export default authenticateUserAndSaveToken;
