import React from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { H1, H3, Button, Box } from "ui-components";
import { gotoUrl, sanitizeHTML } from "utils/helpers";
import { makeSelectLocale } from "ducks/selectors";
import { buttonThemes, getButtonColorVariant } from "ui-components/theme";

const NotEligible = ({
  description,
  disclaimer,
  label,
  url,
  showButton = true,
  html = false,
  themeColors
}) => {
  const locale = useSelector(makeSelectLocale());
  const buttonTheme = get(themeColors, "button", buttonThemes.blue);
  const buttonStyles = getButtonColorVariant(buttonTheme);
  const localizedLabel = get(
    locale,
    "go_to_manage_order",
    "Go to Manage Order"
  );

  return (
    <>
      <Box
        textAlign="center"
        variant="main"
        padding="2rem"
        data-testid="not-eligible-page"
      >
        {html ? (
          <>
            <Box
              mb={8}
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(description) }}
            />
            <Box
              mb={8}
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(disclaimer) }}
            />
          </>
        ) : (
          <>
            <H1 mb={8}>{description}</H1>
            <H3 mb={4}>{disclaimer}</H3>
          </>
        )}
        {showButton && (
          <Button
            onClick={() => gotoUrl(url, false, {}, true)}
            sx={buttonStyles}
          >
            {label || localizedLabel}
          </Button>
        )}
      </Box>
    </>
  );
};

NotEligible.defaultProps = {
  description: "Invalid Session",
  disclaimer: "",
  url: "/manage/order"
};

export default NotEligible;
