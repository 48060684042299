import React from "react";
import ReactModal from "react-modal";
import isEmpty from "lodash/isEmpty";
import { Box, Flex } from "ui-components";
import Spinner from "components/Spinner";
import { renderPageComponents } from "../renderPageComponent";

/**
 * TBD: To resolve renderSmartComponents function.
 * renderPageComponents not included because slightly complicated to include it. Not sure where to migrate function renderPageComponents into the new smart component file
 */

const SMART_MODAL = props => {
  let {
    styles,
    pageProps,
    appSettings,
    screenshotEnabled,
    components: pageComponents,
    modalStyles
  } = props;
  const modalStyleOverride = modalStyles
    ? modalStyles.modalStyle
    : styles?.modalStyle;
  const overrideOverlayStyle = modalStyleOverride?.overlay || {};
  const overrideContentStyle = modalStyleOverride?.content || {};
  const defaultOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.70)",
    zIndex: 2000
  };
  const defaultContentStyle = {
    position: "absolute",
    border: "1px solid #ccc",
    background: "white",
    overflow: "hidden",
    borderRadius: "12px",
    outline: "none",
    padding: "10px",
    maxWidth: "570px",
    maxHeight: "570px",
    margin: "auto",
    inset: "1rem",
    minHeight: "fit-content"
  };

  const modalStyle = {
    overlay: { ...defaultOverlayStyle, ...overrideOverlayStyle },
    content: { ...defaultContentStyle, ...overrideContentStyle }
  };
  const modalWrapper = styles?.modalWrapper || {};
  return (
    <>
      <ReactModal style={modalStyle} isOpen={true}>
        <div data-testid="smart-render">
          <Box style={{ padding: "30px", ...modalWrapper }}>
            {isEmpty(pageComponents) ? (
              <Flex mt={3} justifyContent="center">
                <Spinner size={200} sx={styles?.fullPage} />
              </Flex>
            ) : (
              renderPageComponents({
                pageComponents,
                pageProps,
                appSettings,
                screenshotEnabled
              })
            )}
          </Box>
        </div>
      </ReactModal>
    </>
  );
};

export default SMART_MODAL;
