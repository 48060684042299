import { featureFlagDataOnlyPlanEnabled } from "./featureFlags";

const STAGING = "staging";
const PRE_PROD = "preproduction";
const PT = "perftest";
const PRODUCTION = "production";

export const getEnvironment = () => {
  const hostName = window.location.hostname;
  switch (hostName) {
    case process.env.REACT_APP_STAGING:
    case process.env.REACT_APP_DEVELOPMENT:
      return STAGING;
    case process.env.REACT_APP_PRE_PROD:
      return PRE_PROD;
    case process.env.REACT_APP_PT:
      return PT;
    default:
      return PRODUCTION;
  }
};

export const getEnvVariableValue = envVariable => {
  const env = getEnvironment();
  if (env === STAGING) {
    return process.env[`${envVariable}_STAGING`];
  } else if (env === PRE_PROD) {
    return process.env[`${envVariable}_PRE_PROD`];
  } else if (env === PT) {
    return process.env[`${envVariable}_PT`];
  } else {
    return process.env[envVariable];
  }
};

/******* Environment specific variable ***/
export const GOOGLE_RECAPTCHA_CLIENT_KEY = getEnvVariableValue(
  "REACT_APP_GOOGLE_RECAPTCHA_CLIENT_KEY"
);
export const MOBILE_APP_DEEP_LINK =
  getEnvVariableValue("REACT_APP_MOBILE_APP_DEEP_LINK") || "";
export const API_GATEWAY_URL = getEnvVariableValue("REACT_APP_API_GATEWAY_URL");
export const ADJUST_APP_ENVIRONMENT =
  getEnvVariableValue("REACT_APP_ADJUST_ENVIRONMENT") || null;
export const GMO_TOKEN_URL =
  getEnvVariableValue("REACT_APP_GMO_TOKEN_URL") || "";
export const GMO_SHOP_ID = getEnvVariableValue("REACT_APP_GMO_SHOP_ID") || "";
export const BETA_USER_EMAILS =
  getEnvVariableValue("REACT_APP_BETA_USER_EMAILS") || "";
export const ENV = getEnvVariableValue("REACT_APP_ENV");
export const PAIDY_API_KEY =
  getEnvVariableValue("REACT_APP_PAIDY_API_KEY") || "";

export const OTP_SECURE_ROUTE_EMAIL =
  getEnvVariableValue("REACT_APP_OTP_SECURE_ROUTE_EMAIL") || "";

export const LOCALE = process.env.REACT_APP_LOCALE;
export const VALID_LOCALES = ["en", "id", "zh", "ja"];
export const COUNTRY = process.env.REACT_APP_COUNTRY;
export const ECOM_API_KEY = process.env.REACT_APP_ECOM_API_KEY;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const ROLLBAR_ACCESS_TOKEN = process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN;
//export const CLEVERTAP_ACCOUNT_ID = process.env.REACT_APP_CLEVERTAP_ACCOUNT_ID; // used by legacy
export const MYINFO_ENDPOINT = process.env.REACT_APP_MYINFO_ENDPOINT;
export const MYINFO_CLIENT_ID = process.env.REACT_APP_MYINFO_CLIENT_ID;
export const CIRCLES_PAYMENT_PLUGIN_ORIGIN_KEY =
  process.env.REACT_APP_ORIGIN_KEY;
export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME || "Circles.Life";
export const BRAND_FAVICON =
  process.env.REACT_APP_BRAND_FAVICON || "circles.life.ico";
export const BRAND_TITLE =
  process.env.REACT_APP_BRAND_TITLE ||
  "Finally a telco for the people, by the people. | Circles.Life";
export const BRAND_DESCRIPTION =
  process.env.REACT_APP_BRAND_DESCRIPTION || "The Best No-contract Mobile Plan";
export const SESSION_TIMEOUT = process.env.REACT_APP_SESSION_TIMEOUT || 55;
export const PAYMENT_STATUS_POLL_INTERVAL =
  process.env.REACT_APP_PAYMENT_STATUS_POLL_INTERVAL || 5000;
export const IDENTITY_CHECK_POLL_MAX_COUNT = 6;
export const OLD_WEBFRONT_URL = process.env.REACT_APP_OLD_WEBFRONT_URL || "";
export const MOBILE_APP_BROWSER_PROTOCOL = "browser";
export const GTM_ID = process.env.REACT_APP_GTM_ID || "";
export const DELIVERY_SLOT_TYPE_SAD_SIM = "sad-sim-only";
export const DELIVERY_SLOT_TYPE_SIM = "sim-only";
export const DELIVERY_SLOT_TYPE_SIM_AND_PHONE = "sim-and-phone";
export const DELIVERY_SLOT_TYPE_PHONE = "phone-only";
export const IOS_DEVICE = "ios";
export const ANDROID_DEVICE = "android";
export const USE_REWARDS_CIS = process.env.REACT_APP_USE_REWARDS_CIS === "true";
export const TELCO_TENANT = process.env.REACT_APP_TELCO_TENANT;
export const PAYMENT_ENVIRONMENT =
  process.env.REACT_APP_PAYMENT_ENVIRONMENT || "TEST";
export const GOOGLE_EMBED_MAP_URL =
  "https://www.google.com/maps/embed/v1/place";
export const LIQUID_URL_CONSUMED_CODE = 409;

export const COUNTRIES_WITH_API_GATEWAY = ["jp"];
export const COUNTRIES_WITH_SECURED_PATHS = ["jp"];
export const COUNTRIES_WITH_LEGACY_APP = ["sg"];
export const LEGACY_APP_ENABLED = COUNTRIES_WITH_LEGACY_APP.includes(COUNTRY);
export const USE_API_GATEWAY = COUNTRIES_WITH_API_GATEWAY.includes(COUNTRY);
export const IS_PATHS_SECURED = COUNTRIES_WITH_SECURED_PATHS.includes(COUNTRY);
export const PORTING_ACCOUNTS = process.env.REACT_APP_PORTING_ACCOUNTS || "";
/******* THEME COLORS *******/
export const SECONDARY_COLOR =
  process.env.REACT_APP_BRAND_SECONDARY_COLOR || "#0000FF";
export const PRIMARY_COLOR =
  process.env.REACT_APP_BRAND_PRIMARY_COLOR || "#0000FF";

export const MOBILE_APP_DEEP_LINK_QA =
  process.env.REACT_APP_MOBILE_APP_DEEP_LINK_QA;

/******* ADJUST  *******/
export const ADJUST_APP_KEY = process.env.REACT_APP_ADJUST_KEY;

/******* Onestop MNP *******/
export const ONESTOP_MNP_PORTIN_CALLBACK_API =
  process.env.REACT_APP_1STOP_MNP_PORTIN_CALLBACK_API;
export const ONESTOP_MNP_PORTIN_IOS_BROWSER_CALLBACK_API =
  process.env.REACT_APP_1STOP_MNP_PORTIN_IOS_BROWSER_CALLBACK_API;

export const BASE = {
  OMS: "OMS",
  USER_SERVICE: "USER_SERVICE",
  API_GATEWAY: "API_GATEWAY",
  LOGISTIC: "LOGISTIC",
  PAYMENTS: "PAYMENTS",
  PAYMENTS_SERVICE: "PAYMENTS_SERVICE",
  REWARDS: "REWARDS",
  MOBILE_API: "MOBILE",
  TELCO_SERVICE: "TELCO_SERVICE",
  TELCO_PORT_IN_SERVICE: "TELCO_PORT_IN_SERVICE",
  QUILT_SERVICE: "QUILT_SERVICE",
  REWARDS_SERVICE: "REWARDS_SERVICE",
  BILLING: "BILLING",
  BSSMW: "BSSMW",
  BFF: "BFF"
};

export const BASE_URL = {
  [BASE.OMS]: getEnvVariableValue("REACT_APP_OMS_URL") || API_GATEWAY_URL,
  [BASE.USER_SERVICE]:
    getEnvVariableValue("REACT_APP_USER_SERVICE_URL") || API_GATEWAY_URL,
  [BASE.PAYMENTS]: API_GATEWAY_URL,
  [BASE.PAYMENTS_SERVICE]:
    getEnvVariableValue("REACT_APP_PAYMENT_SERVICE_URL") || API_GATEWAY_URL,
  [BASE.QUILT_SERVICE]:
    getEnvVariableValue("REACT_APP_QUILT_SERVICE_URL") || API_GATEWAY_URL,
  [BASE.TELCO_SERVICE]:
    getEnvVariableValue("REACT_APP_TELCO_SERVICE_URL") || API_GATEWAY_URL,
  [BASE.TELCO_PORT_IN_SERVICE]:
    getEnvVariableValue("REACT_APP_TELCO_SERVICE_URL") || API_GATEWAY_URL,
  [BASE.API_GATEWAY]: API_GATEWAY_URL,
  [BASE.LOGISTIC]: API_GATEWAY_URL,
  [BASE.REWARDS]: API_GATEWAY_URL,
  [BASE.REWARDS_SERVICE]: API_GATEWAY_URL,
  [BASE.MOBILE_API]: API_GATEWAY_URL,
  [BASE.BILLING]: API_GATEWAY_URL,
  [BASE.BSSMW]:
    getEnvVariableValue("REACT_APP_BSSMW_SERVICE_URL") || API_GATEWAY_URL,
  // Defaults to root since the BFF server will be serving the web app in production
  [BASE.BFF]: process.env.REACT_APP_BFF_URL ?? ""
};

export const OMS_URL = BASE_URL[BASE.OMS];
export const USER_SERVICE_URL = BASE_URL[BASE.USER_SERVICE];

/******** API version paths ********/
export const VERSION_PATH = {
  [BASE.OMS]: `/api/{VERSION}/${COUNTRY}/webfront`,
  [BASE.USER_SERVICE]: "/api/{VERSION}/user-service",
  [BASE.API_GATEWAY]: `/{VERSION}/${COUNTRY}/{LOCALE}-${COUNTRY?.toUpperCase()}/webfront`,
  [BASE.LOGISTIC]: `/{VERSION}/${COUNTRY}/{LOCALE}-${COUNTRY?.toUpperCase()}/webfront/laas`,
  [BASE.PAYMENTS]: `/{VERSION}/${COUNTRY}/{LOCALE}-${COUNTRY?.toUpperCase()}/webfront`,
  [BASE.PAYMENTS_SERVICE]: `/{VERSION}/${COUNTRY}/{LOCALE}-${COUNTRY?.toUpperCase()}/webfront`,
  [BASE.MOBILE_API]: `/{VERSION}/${COUNTRY}/{LOCALE}/mobile`,
  [BASE.REWARDS]: "/{VERSION}/ecom/raas",
  [BASE.QUILT_SERVICE]: "/api/{VERSION}/quilt",
  [BASE.TELCO_SERVICE]: `/{VERSION}/${COUNTRY}/{LOCALE}-${COUNTRY?.toUpperCase()}/${TELCO_TENANT}`,
  [BASE.TELCO_PORT_IN_SERVICE]: `/{VERSION}/${COUNTRY}/{LOCALE}/${TELCO_TENANT}`,
  [BASE.REWARDS_SERVICE]: `/{VERSION}/${COUNTRY}/{LOCALE}/mobile/rewards`,
  [BASE.BILLING]: `/{VERSION}/${COUNTRY}/{LOCALE}/webfront`,
  [BASE.BSSMW]: `/{VERSION}/${COUNTRY}/{LOCALE}/bssmw`,
  [BASE.BFF]: `/api/${COUNTRY}`
};

export const OMS_VERSION_PATH = VERSION_PATH[BASE.OMS];
export const USER_SERVICE_VERSION_PATH = VERSION_PATH[BASE.USER_SERVICE];
export const API_GATEWAY_VERSION_PATH = VERSION_PATH[BASE.API_GATEWAY];

export const DEFAULT_API_VERSION = {
  [BASE.OMS]: "v3",
  [BASE.USER_SERVICE]: "v3",
  [BASE.API_GATEWAY]: "v4",
  [BASE.LOGISTIC]: "v1",
  [BASE.PAYMENTS]: "v4",
  [BASE.PAYMENTS_SERVICE]: "v3",
  [BASE.REWARDS]: "v1",
  [BASE.MOBILE_API]: "v4",
  [BASE.QUILT_SERVICE]: "v1",
  [BASE.TELCO_SERVICE]: "v1",
  [BASE.TELCO_PORT_IN_SERVICE]: "v2",
  [BASE.REWARDS_SERVICE]: "v4",
  [BASE.BILLING]: "v4",
  [BASE.BSSMW]: "v2"
};

/****** APIs *******/
export const OMS_APP_SETTINGS = "/checkout/app_settings";
export const OMS_LOCALE = "/locale";
export const OMS_BASE_PLAN = "/checkout/base_plan";
export const OMS_ELIGIBLE_PLANS = "/checkout/eligible_plans";
export const OMS_DELIVERY_SLOTS = "/checkout/delivery_slots";
export const OMS_NUMBERS = "/checkout/numbers";
export const OMS_PHONES = "/checkout/phones";
export const OMS_POST_OFFICE_DELIVERY_SLOTS =
  "/checkout/post_office_delivery_slots";
export const OMS_ORDER_STATUS = "/orders/status";
export const OMS_AUTHORIZE_ORDER = "/orders/{id}/get_authorizer";
export const OMS_SET_AUTHORIZE_ORDER = "/orders/{id}/set_authorizer";
export const OMS_GUEST_ORDER = "/orders/guest_order";
export const OMS_DO_KYC = "/orders/do_kyc";
export const OMS_VALIDATE_REFERRAL_CODE = "/checkout/verify_referral_code";
export const OMS_VALIDATE_PORT_IN_NUMBER = "/checkout/port_number_check";
export const OMS_VALIDATE_POSTAL_CODE = "/checkout/check_postal_valid";
export const OMS_SEARCH_ADDRESS_LOOKUP = "/checkout/search_address_lookup";
export const OMS_LOAD_ADDRESS_LOOKUP = "/checkout/load_address_lookup";
export const OMS_FIND_ADDRESS_LOOKUP = "/checkout/find_address_lookup";
export const OMS_ADDRESS_COMPONENT_MAPPINGS =
  "/checkout/address_component_mappings";
export const OMS_NUMBER_LOCK = "/checkout/number_lock";
export const OMS_VERIFY_SELF_ACTIVATION_DELIVERY_NRIC =
  "/checkout/sad/validate_nric";
export const OMS_VERIFY_IDENTITY = "/checkout/verify_identity";
export const OMS_VALIDATE_ICCID = "/checkout/validate_iccid";
export const OMS_SAD_VALIDATE_ICCID = "/checkout/sad_order/validate_identity";
export const OMS_ORDER_DETAILS = "/orders/details";
export const OMS_PAYMENT_RETURN = "/orders/payment_details";
export const OMS_SELF_ACTIVATION_DELIVERY = "/orders/details";
export const OMS_SUSPENSION_PAYMENT_DETAILS = "/suspension/payment_details";
export const OMS_SUBMIT_NUMBER_CHANGE = "/number_change/submit";
export const OMS_CANCEL_NUMBER_CHANGE = "/number_change/cancel";
export const OMS_SUBMIT_SUSPENSION = "/suspension/submit";
export const OMS_CANCEL_SUSPENSION = "/suspension/cancel";
export const OMS_SUBMIT_WINBACK = "/termination/winback";
export const OMS_SUBMIT_TERMINATION = "/termination/submit";
export const OMS_CANCEL_TERMINATION = "/termination/cancel";
export const OMS_CANCEL_PORT_IN = "/orders/cancel_port_in";
export const OMS_REINITIATE_PORT_IN =
  "/orders/reinitiate_portin_update_details";
export const OMS_RESCHEDULE_PORT_IN = "/orders/reschedule_port_in";
export const OMS_ACTIVATE_SIM_CARD = "/orders/activate_sim_card";
export const OMS_CREATE_ORDER = "/orders/create_order";
export const OMS_CREATE_ORDER_TEMP = "/orders/create_order_temp";
export const OMS_UPDATE_SAD_ORDER = "/orders/sad_orders";
export const OMS_RESEND_ESIM_QR_CODE = "/orders/send_esim_qr_code";
export const OMS_CREATE_SAD_ORDER = "/orders/sad_orders";
export const OMS_EKYC_COMPLETED = "/checkout/ekyc_completed";
export const OMS_FETCH_ORDER_STATUS = "/orders/status";
export const OMS_CONFIRM_PAYMENT_OPTION = "/paas/ecpay/confirm_payment_option";
export const OMS_FETCH_PAYMENT_REQUEST = "/orders/payment_request";
export const OMS_SECURED_FETCH_PAYMENT_REQUEST =
  "/orders/secured_payment_request";
export const OMS_VERIFY_PAYMENT = "/orders/verify_payment";
export const OMS_SUBMIT_REPLACE_SIM = "/replace_sim/submit";
export const OMS_SUBMIT_SWITCH_TO_ESIM = "/replace_sim/submit";
export const OMS_DELIVERY_INFO = "/deliveries/info";
export const OMS_SUBMIT_DELIVERY = "/deliveries/submit";
export const OMS_UPLOAD_IDENTITY_DETAILS = "/identities/info";
export const OMS_SUBMIT_UPLOAD_IDENTITY = "/identities/submit";
export const OMS_MYINFO_OTP = "/checkout/myinfo/send_otp";
export const OMS_FETCH_SURVEY = "/surveys/new";
export const OMS_SUBMIT_SURVEY = "/surveys/submit";
export const OMS_IDENTITY_VERIFICATION_STATUS = "/checkout/get_dvs_status";
export const OMS_JUMIO_VERIFICATION_URL = "/checkout/jumio/verification";
export const OMS_CUSTOMER_INFO = "/checkout/get_customer_info";
export const OMS_USER_WALLET = "/wallet/get_user_wallet";

export const OMS_UPLOAD_IMAGE = "/checkout/upload_image";
export const OMS_UPLOAD_SCREENSHOT = "/checkout/upload_screenshot";
export const OMS_REQUEST_OTP = "/auth/otp";
export const OMS_VERIFY_OTP = "/auth/verify_otp";
export const OMS_LOG_MESSAGE = "/analytics/log_message";

export const PHONES_URL = "/shop/phones";
export const MANAGE_ORDER_URL = "/manage/order";
export const SIM_ACTIVATION_URL = "/sim_activation/sim";
export const OMS_PROCESS_PREPAID_ORDER = "/orders/prepaid/process";
export const OMS_TRANSACTION_DETAILS = "/billing/transaction";

export const OMS_GET_CITY_LIST = "/post_offices/get_city_list";
export const OMS_GET_DISTRICT_LIST = "/post_offices/get_district_list_by_city";
export const OMS_GET_STREET_LIST =
  "/post_offices/get_street_by_district_and_city";
export const OMS_GET_STORE_LIST =
  "/post_offices/get_store_by_street_district_city";
export const OMS_ORDER_CANCELLATION_STATUS = "/cancellation/account";
export const OMS_ORDER_CANCELLATION = "/cancellation/submit";

export const OMS_POP_STATIONS = "/checkout/popstations";

export const OMS_VALIDATE_SIGNATURE = "/auth/validate_signature";
export const OMS_OUTSTANDING_BILLS = "/billing/outstanding_bills";
export const OMS_PAY_OUTSTANDING_BILLS = "/billing/pay_outstanding_bills";
export const OMS_CHECK_PAYMENT_STATUS = "/transaction/status";

export const OMS_MYINFO = "/checkout/myinfo";
export const OMS_MYINFO_SEND_OTP = "/checkout/myinfo/send_otp";
export const OMS_MYINFO_VERIFY_OTP = "/checkout/myinfo/verify_otp";

/** User service APIs */
export const USER_SERVICE_LOGIN = `/v4/${COUNTRY}/{LOCALE}/webfront/users/login/action`;
export const USER_SERVICE_OTP = `/v4/${COUNTRY}/{LOCALE}/webfront/otp`;
export const USER_SERVICE_SMS_OTP = `/v4/${COUNTRY}/{LOCALE}/webfront/users/sms/otp`;
export const USER_SERVICE_SMS_VALIDATE_OTP = `/v4/${COUNTRY}/{LOCALE}/webfront/users/contact_number/validate`;
export const USER_SERVICE_DATA_ONLY_EMAIL_OTP = `/v4/${COUNTRY}/{LOCALE}/webfront/users/plan/register`;
export const USER_SERVICE_DATA_ONLY_EMAIL_OTP_VALIDATE = `/v4/${COUNTRY}/{LOCALE}/webfront/users/plan/auth`;
export const USER_SERVICE_AUTHENTICATE = `/v4/${COUNTRY}/{LOCALE}/webfront/users/auth`;
export const USER_SERVICE_SOCIAL_LOGIN_AUTHENTICATE = `/v4/${COUNTRY}/{LOCALE}/webfront/users/social/auth`;
export const USER_SERVICE_IPDB_AUTHENTICATE = `/v4/${COUNTRY}/{LOCALE}/webfront/users/auth/ipdb`;
export const USER_SERVICE_REFRESH_TOKEN = `/v4/${COUNTRY}/${LOCALE}/webfront/users/token`;
export const USER_SERVICE_LOGGEDIN_USER_DETAILS = `/v4/${COUNTRY}/{LOCALE}/mobile/users`;
export const USER_SERVICE_LINK_SOCIAL = `/v4/${COUNTRY}/{LOCALE}/webfront/users/link-social`;
export const USER_SERVICE_LIQUID_URL = `/v4/${COUNTRY}/en/webfront/kyc/url`;
export const USER_SERVICE_LIQUID_OCR_DATA = `/v4/${COUNTRY}/en/webfront/kyc/ocr-result`;
export const USER_SERVICE_LIQUID_OCR_REGISTER = `/v4/${COUNTRY}/en/webfront/kyc/submit`;
export const USER_SERVICE_REQUEST_OTP_V4_SECURE = featureFlagDataOnlyPlanEnabled()
  ? `/v4/${COUNTRY}/{LOCALE}/web/users/sms/secure-otp`
  : `/v4/${COUNTRY}/{LOCALE}/mobile/secure-otp`;
export const USER_SERVICE_VALIDATE_OTP = featureFlagDataOnlyPlanEnabled()
  ? `/v4/${COUNTRY}/{LOCALE}/webfront/users/email/otp/validate`
  : `/v4/${COUNTRY}/{LOCALE}/webfront/users/otp/validate`;
export const USER_SERVICE_VALIDATE_OTP_TRIGGER = featureFlagDataOnlyPlanEnabled()
  ? `/v4/${COUNTRY}/{LOCALE}/webfront/users/sms/otp/email/validate`
  : `/v4/${COUNTRY}/{LOCALE}/webfront/otp/validate/trigger`;
export const USER_SERVICE_UPDATE_EMAIL = `/v4/${COUNTRY}/{LOCALE}/webfront/users/attributes`;
export const USER_SERVICE_DELETE = `/v4/${COUNTRY}/{LOCALE}/webfront/users/delete`;
export const USER_LOGOUT_PATH = "/users/logout";
export const USER_SERVICE_LOGOUT = `/v4/${COUNTRY}/{LOCALE}/webfront${USER_LOGOUT_PATH}`;
export const USER_SERVICE_USER_KYC = `/v4/${COUNTRY}/{LOCALE}/webfront/fetch-mno-kyc`;
export const USER_SERVICE_SUBMIT_USER_KYC = `/v4/${COUNTRY}/{LOCALE}/webfront/submit-mno-kyc`;
export const USER_SERVICE_IPDB_ID_ASSERTION = `/v4/${COUNTRY}/{LOCALE}/webfront/users/auth/au-assertion`;
export const USER_SERVICE_GUEST_OTP = `/v4/${COUNTRY}/{LOCALE}/mobile/otp`;
export const USER_SERVICE_AUTH_OTP_REQUEST = `/v4/${COUNTRY}/{LOCALE}/mobile/users/otp`;
export const USER_SERVICE_AUTH_SECURE_OTP_REQUEST = `/v4/${COUNTRY}/{LOCALE}/mobile/users/sms/secure-otp`;
export const USER_SERVICE_ADDRESS_UPDATE = `/v4/${COUNTRY}/{LOCALE}/mobile/users/confirm_update/billing_address`;
export const USER_SERVICE_ESIM_CONTACT_UPDATE = `/v4/${COUNTRY}/{LOCALE}/webfront/users/init_update/contact_number`;
export const USER_SERVICE_ESIM_VALIDAT_CONTACT_NO_UPDATE = `/v4/${COUNTRY}/{LOCALE}/webfront/users/confirm_update/contact_number`;

/** Api Gateway APIs */
export const API_GATEWAY_CREATE_ORDER = "/oms/orders/create_order";
export const API_GATEWAY_CREATE_DATA_ONLY_ORDER =
  "/oms/orders/create_custom_order";
export const API_GATEWAY_UPDATE_ORDER = "/oms/orders/update";
export const API_GATEWAY_APP_SETTINGS = "/oms/checkout/app_settings";
export const API_GATEWAY_LOCALE = "/oms/locale";
export const API_GATEWAY_BASE_PLAN = "/oms/checkout/base_plan";
export const API_GATEWAY_ELIGIBLE_PLANS = "/oms/checkout/eligible_plans";
export const API_GATEWAY_PAYMENT_REQUEST = "/oms/orders/payment_request";
export const API_GATEWAY_PAYMENT_DETAILS = "/oms/orders/payment_details";
export const API_GATEWAY_PAYMENT_VERIFY = "/oms/paas/v2/oms/payment/verify";
export const API_GATEWAY_MANAGE_ORDER = "/oms/manage_order";
export const API_GATEWAY_ESIM_QR = "/oms/manage_order/get_esim_qr_code";
export const API_GATEWAY_SIM_ACTIVATION_STATUS = "/oms/manage_order/sim_status";
export const API_GATEWAY_ACTIVATE_SIM_CARD = "/oms/manage_order/activate";
export const API_GATEWAY_ACTIVATE_REPLACE_SIM_CARD =
  "/oms/manage_order/activate_sim_replacement";
export const API_GATEWAY_INITIATE_PORTIN = "/oms/manage_order/initiate_portin";
export const API_GATEWAY_CANCEL_PORT_IN = "/oms/manage_order/cancel_port_in";
export const API_GATEWAY_LOG_MESSAGE = "/oms/analytics/log_message";
export const API_GATEWAY_SUBMIT_WINBACK = "/oms/termination/winback";
export const API_GATEWAY_SUBMIT_TERMINATION = "/oms/termination/submit";
export const API_GATEWAY_CANCEL_TERMINATION = "/oms/termination/cancel";
export const API_GATEWAY_DELIVERY_DETAILS = "/oms/deliveries/info";
export const API_GATEWAY_DOWNLOAD_SERVICE_AGREEMENT =
  "/oms/orders/download_service_agreement_form";
export const API_GATEWAY_DOWNLOAD_PARENTAL_CONSENT =
  "/oms/orders/download_parental_consent";
export const API_GATEWAY_VALIDATE_REFERRAL_CODE =
  "/oms/checkout/verify_referral_code";
export const API_GATEWAY_SUBMIT_DELIVERY = "/oms/deliveries/submit";
export const API_GATEWAY_REQUEST_OTP = "/oms/auth/otp";
export const API_GATEWAY_ORDER_CANCELLATION_STATUS =
  "/oms/cancellation/account";
export const API_GATEWAY_ORDER_CANCELLATION = "/oms/cancellation/submit";
export const API_GATEWAY_TRANSACTION_STATUS = "/oms/transaction/status";
export const API_GATEWAY_UPDATE_PARENTAL_CONSENT =
  "/oms/orders/parental_consent";
export const API_GATEWAY_RESUBMIT_PORTIN_DETAILS =
  "/oms/manage_order/resubmit_mnp_number";

/** Rewards service APIs */
export const REWARDS_VALIDATE_REFERRAL_CODE =
  "/user/promos/{REFERRAL_CODE}/validate";
export const REWARDS_REQUEST_WINBACK = "/winback/promotion";
export const CANCEL_TERMINATION = "/winback/claim";
/** Logistic service APIs */
export const LOGISTIC_DELIVERY_SLOTS = "/slot/libertywireless/list";

/* Payment service APIs */
export const PROCESS_CARD_UPDATE_PAYMENT_METHOD =
  "/payment/save_card/process_payment_method";
export const PROCESS_PAYMENT_METHOD = "/payment/process_payment_method";
export const SAVE_AND_GET_CARD_DETAILS = "/payment/save_and_get_card_details";
export const UPFRONT_PAYMENT = "/payment/upfront";
export const LATITUDE_TRANSACTIONS_STATUS = "/latitude_transactions/status";
export const PAYMENT_TRANSACTION_STATUS = "/transaction/status";

export const TELCO_PROFILE = "/telco/profile";
export const CREATE_PORTOUT_REQUEST = "/portouts/account/create";
export const CREATE_PORTOUT_REQUEST_ONESTOP =
  "/portouts/account/create/onestop";
export const REVERSE_PORTIN = "/portouts/account/reverse-portin";

export const BILL_DETAILS = "/customers/bills/outstanding";
export const BILL_PAYMENT_INTENT = "/customers/bills/outstanding/payintent";
export const BILL_PAYMENT_STATUS =
  "/customers/bills/outstanding/payintent/status";
export const PAYMENT_METHOD_SWITCH_OR_DELETE = "/payment/update_card";

/* Quilt service APIs */
export const QUILT_ELIGIBILITY = "/settings/eligibility";
export const QUILT_WINBACK_AGGREGATION = "/settings/winback-aggregation";
export const QUILT_PROFILE_AGGREGATION = "/settings/profile-aggregation";

/* Telco service APIs */
export const TELCO_REPORT_LOST_SIM = "/account-workflows/inventory/sim/change";
export const TELCO_REQUEST_TERMINATE = "/account-workflows/status/terminate";
export const TELCO_EMAIL_SERVICE_AGREEMENT = "/paradocs/account-subscriptions";
export const TELCO_RE_INITIATE_NUMBER_PORT_IN = "/portins/reinitiate";
export const TELCO_REQUEST_PIN_UPDATE = "/telco_settings/settings";

/* CIS service APIs */
export const CIS_REQUEST = "/partners/request_for_cis_as_hr";
export const CIS_REQUEST_HR = "/partners/cis_request_to_hr";
export const CIS_PARTNERS = "/customers/generate_referral_code";
export const CIS_PARTNERS_REWARDS_FORM_FIELDS = "/promotions/partner";
export const CIS_PARTNERS_CMS_FORM_FIELDS = "/partners";

/* BSSMW service APIs */
export const BSSMW_SUBMIT_ESIM_TRANSFER = "/esim-transfer/request";
export const BSSMW_REQUEST_OTP = "/esim-transfer/request/otp";
/* BFF endpoints */
export const BFF_APP_SETTINGS = "/data/app_settings";
export const BFF_LOCALE = "/data/locale";

export const ORDER_ACTION_TYPES = {
  SUSPEND_NUMBER: "suspend_number",
  CHANGE_NUMBER: "change_number",
  REPORT_LOST_SIM_CARD: "report_lost_sim_card",
  REPORT_LOST_ESIM_CARD: "report_lost_esim_card",
  TERMINATE_NUMBER: "terminate_number",
  ACTIVATE_SIM_CARD: "activate_sim_card",
  SWITCH_TO_ESIM: "switch_to_esim",
  RESEND_ESIM: "resend_esim",
  OUTSTANDING_BILLS: "pay_outstanding_bills",
  ADD_CC: "add_cc"
};

export const STEP_ACTIONS = {
  ACTIVATE_SIM_CARD: "activate_sim_card",
  INITIATE_PORTIN: "initiate_portin",
  REINITIATE_PORTIN: "reinitiate_portin",
  RESCHEDULE_PORT_IN: "reschedule_port_in",
  CANCEL_PORTIN: "cancel_portin"
};

export const RESTRICTED_STEP_ACTIONS = [
  STEP_ACTIONS.ACTIVATE_SIM_CARD,
  STEP_ACTIONS.INITIATE_PORTIN,
  STEP_ACTIONS.REINITIATE_PORTIN,
  STEP_ACTIONS.RESCHEDULE_PORT_IN,
  STEP_ACTIONS.CANCEL_PORTIN
];

export const PAYMENT_STATES = {
  PENDING_ECPAY: "PENDING_ECPAY"
};

export const PAYMENT_STATUSES = {
  PENDING: "Pending",
  SUCCESS: "Success",
  FAILED: "Failed"
};

export const SIM_ACTIVATION_STATUSES = {
  ORDER_PLACED: "OP",
  SUCCESS: "FD",
  CANCELLED: "CAN"
};

export const ORDER_ACTION_STATES = {
  SUSPENDABLE: "SUSPENDABLE",
  SUSPENSION_CANCELABLE: "SUSPENSION_CANCELABLE",
  TERMINATABLE: "TERMINATABLE",
  TERMINATE: "TERMINATE",
  CANCEL_AND_TERMINATE: "CANCEL_AND_TERMINATE",
  CANCELABLE: "CANCELABLE"
};

export const MANAGE_ORDER_FEATURE_MAPPINGS = {
  change_number: "SHOW_GUEST_ORDER_CHANGE_NUMBER_ACTION",
  suspend_number: "SHOW_GUEST_ORDER_SUSPEND_NUMBER_ACTION",
  report_lost_sim_card: "SHOW_GUEST_ORDER_REPORT_LOST_SIM_CARD_ACTION",
  terminate_number: "SHOW_GUEST_ORDER_TERMINATE_NUMBER_ACTION",
  cancel_termination: "SHOW_GUEST_ORDER_CANCEL_TERMINATION_ACTION",
  pay_outstanding_bills: "SHOW_GUEST_ORDER_PAY_OUTSTANDING_BILLS"
};

export const MANAGE_ORDER_TRANSLATION_MAPPINGS = {
  cancel_port_in: "manage_order_cancel_port_in",
  change_number: "manage_order_change_number",
  suspend_number: "manage_order_suspend_number",
  report_lost_sim_card: "manage_order_report_lost_sim_card",
  report_lost_esim_card: "manage_order_report_lost_esim_card",
  terminate_number: "manage_order_terminate_number",
  cancel_termination: "manage_order_cancel_termination",
  authorise_action: "manage_order_authorise_action",
  predeliver_action: "manage_order_predeliver_action",
  manage_order: "manage_order",
  activate_sim_card: "manage_order_activate_sim_card",
  switch_to_esim: "manage_order_switch_to_esim",
  resend_esim: "manage_order_resend_esim",
  pay_outstanding_bills: "manage_order_pay_outstanding_bills",
  add_cc: "manage_order_add_cc"
};

export const MANAGE_ORDER_OTP_MAPPINGS = {
  report_lost_sim_card: "email",
  switch_to_esim: "email",
  resend_esim: "email",
  manage_order: "email",
  default: "sms"
};

export const MANAGE_PROFILE_OTP_MAPPINGS = {
  ENHANCED_SMS: "ENHANCED_SMS",
  ENHANCED_EMAIL_OTP: "ENHANCED_EMAIL_OTP",
  GENERIC_VERIFY_MOBILE: "GENERIC_VERIFY_MOBILE",
  APP_TYPE: 1
};

export const MANAGE_PROFILE_REQUEST_TYPE_MAPPINGS = {
  VERIFY_MOBILE: "VERIFY_MOBILE",
  VERIFY_EMAIL_OTP: "VERIFY_EMAIL_OTP",
  VERIFY_EMAIL_CHANGE_OTP: "VERIFY_EMAIL_CHANGE_OTP"
};

export const MANAGE_ORDER_ACTION_QUERY_PARAM_REQUIRED = {
  terminate_number: "showTerminate"
};

export const CALENDAR_YEAR_OFFSET = {
  MINGUO: 1911
};

export const PREPAID_PLAN_SUB_TYPES = ["RETAIL_PREPAID"];

export const paths = {
  MNP_LANDING_PAGE: "/web/one-stop-mnp-landing",
  MNP_FALLBACK_ERROR_PAGE: "/web/one-stop-mnp-error",
  LANDING_PAGE: "/",
  MANAGE_ORDER: "/manage/order",
  OTP_VERIFICATION: "/manage/otp",
  CHANGE_NUMBER_INELIGIBLE: "/manage/change-number/ineligible",
  CHANGE_NUMBER_FAIL: "/manage/change-number/fail",
  CHANGE_NUMBER_CANCEL_FAIL: "/manage/change-number/cancel-fail",
  REPORT_LOST_SIM: "/manage/report-lost-sim-card",
  TERMINATE_NUMBER: "/manage/terminate-number/:status?",
  TERMINATE_INFO: "/manage/terminate-info",
  CANCEL_TERMINATION: "/manage/cancel-termination/:status?",
  CANCEL_TERMINATION_SUCCESS: "/manage/cancel-termination/success",
  REQUEST_PORT_OUT: "/:channel/port-out-request",
  REVERSE_PORTIN: "/:channel/reverse-portin",
  ORDER_CANCELLATION: "/:channel/order-cancellation",
  OUTSTANDING_BILL_PAYMENT: "/payment/outstanding-bill-payment",
  PAYMENT_RETURN_V3: "/payment/return",
  PAYMENT: "/:channel/payment",
  PAYMENT_CARD: "/:channel/payment/card",
  PAYMENT_AMAZON: "/payment-amazon",
  PAYMENT_RETURN: "/:channel/payment-return",
  LEGACY_PAYMENT_RETURN: "/:channel/payment_return/:order_id",
  PAYMENT_STATUS: "/:channel/payment-status",
  PAYMENT_SUCCESS: "/:channel/payment-success",
  PAYMENT_FAILED: "/:channel/payment-failed",
  PAYMENT_PENDING: "/:channel/payment-pending",
  ACTIVATE: "/:channel/activate",
  PRE_CHECKOUT: "/:channel/pre-checkout",
  ORDER_SUMMARY: "/:channel/order-summary",
  PLAN_CHANGE_TERMS_AND_CONDITIONS: "/:channel/plan-change-term-conditions",
  PLAN_CHANGE_SIM_SELECTION: "/:channel/plan_change_sim_selection",
  PLAN_CHANGED_SIM_STATUS: "/:channel/plan_change/submit",
  SIM_VERIFICATION: "/:channel/sim",
  VERIFY_IDENTITY: "/:channel/verify-identity",
  SCHEDULE_DELIVERY: "/schedule_delivery/:order_type/:delivery_type/:status?",
  PHONE: "/shop/phones/:id",
  PAYMENT_CARD_UPDATE: "/manage/update-card",
  SCANNER: "/loadBarcode",
  DASHBOARD: "/dashboard",
  WEBLOGIN: "/loginweb",
  APPLOGIN: "/loginapp",
  CLOSEWEBVIEW: "/closewebview",
  EKYC_VERIFY: "/ekyc_verify",
  UPDATE_CARD_SUCCESS: "/updateCardSuccess",
  PROFILE_UPDATE_EMAIL_SUCESS: "/profileUpdateEmailSuccess",
  PING: "/ping",
  ESIM_TYPE_SELECTION: "/:channel/esim-selection",
  PHYSICAL_SIM_TYPE_SELECTION: "/:channel/physical-sim-selection",
  CONNECTION_DETAILS: "/:channel/connection-details",
  PROFILE: "/profile",
  PROFILE_EMAIL: "/:channel/email",
  USER_DETAILS_CONFIRMATION: "/:channel/user-details-confirmation",
  SIM_ACTIVATION_STATUS: "/:channel/sim-activation-status",
  SIM_ACTIVATION_SUCCESS: "/:channel/sim-activation-success",
  SIM_ACTIVATION_PENDING: "/:channel/sim-activation-pending",
  USER_DETAILS: "/web/user-details",
  AU_USER_DETAILS: "/web/au-user-details",
  OWNER_DETAILS: "/web/owner-details",
  PORTIN_DETAILS: "/web/portin-details",
  PORTIN_DETAILS_ONE_STOP: "/beta/portin-details",
  DELIVERY_DETAILS: "/web/delivery-details",
  CONTACT_DETAILS: "/web/contact-details",
  MANAGE_SIM_ACTIVATION: "/manage/sim-activation",
  MANAGE_ACTIVATE_SIM_CARD: "/manage/activate-sim-card",
  MANAGE_SIM_ACTIVATION_SUCCESS: "/manage/sim-activation-success", // Need to remove /sim-activation-success with faq
  MANAGE_ESIM_QR: "/manage/esim-qr",
  MANAGE_ESIM_SETTING: "/manage/esim_setting",
  LOG_IN: "/:channel/login",
  LOGGED_OUT: "/logged_out",
  APP_LINK_AU_ACCOUNT_SUCCESS: "/auidLinkingSuccess",
  SIM_REPLACEMENT_CONNECTION_DETAILS:
    "/:channel/sim-replacement-connection-details",
  SIM_REPLACEMENT_ESIM_ACTIVATION: "/manage/replace-sim-esim-qr",
  MANAGE_REPLACE_SIM: "/manage/replace-sim",
  MANAGE_REPLACE_SIM_STATUS: "/manage/replace-sim/:status",
  SIM_REPLACEMENT_DELIVERY_DETAILS: "/web/replace-sim-delivery-details",
  SIM_REPLACEMENT_CONTACT_DETAILS: "/web/sim-replacement-contact-details",
  SCHEDULE_DELIVERY_CONFIRMATION: "/confirmation",
  PORTIN_NUMBER_SELECTION: "/:channel/portin-number-selection",
  CHANNEL_OTP: "/:channel/otp",
  OUTSTANDING_BILLS: "/payment/outstanding_bills",
  OUTSTANDING_BILLS_PAYMENT_STATUS: "/payment/outstanding_bills/:status",
  OUTSTANDING_BILLS_PAYMENT_STATUS_SUCCESS:
    "/payment/outstanding_bills/success",
  OUTSTANDING_BILLS_PAYMENT_STATUS_FAILURE: "/payment/outstanding_bills/fail",
  OUTSTANDING_BILLS_PAYMENT_STATUS_IN_PROGRESS:
    "/payment/outstanding_bills/in_progress",
  CIRCLES1_SAD: "/circles1/sa_delivery",
  ERROR_404: "/404",
  ERROR_500: "/500",
  MAINTENANCE: "/maintenance",
  ONESTOP_MNP_DASHBOARD: "/onestop-mnp/dashboard",
  ONESTOP_MNP_BROWSER_INIT: "/onestop-mnp/init-in-web-portal",
  ONESTOP_MNP_LAUNCH_APP: "/onestop-mnp/launch-app",
  ONESTOP_MNP_PORT_OUT_REQUEST_CONFIRM: "/onestop-mnp/request-confirm",
  CONFIRM_KYC_METHOD: "/:channel/confirm-kyc-method",
  CONTRACT_DETAILS_PAGE: "/:channel/contract-details",
  CONFIRM_PIN_CHANGE: "/:channel/confirm-pin-change",
  UPDATE_USER_PIN: "/:channel/update-user-pin",
  PIN_UPDATE_SUCCESS: "/:channel/pin-update-success",
  APP_LINK_MANAGE_PIN_CLOSE: "/managePinClose",
  PAYMENT_DETAILS_PAGE: "/:channel/payment-details",
  PAYMENT_ADD_EDIT_DELETE: "/:channel/:method/add-edit-delete",
  UPDATE_REGISTER_PAIDY: "/:channel/:method/register-paidy",
  PAIDY_REGISTRATION_TERMS_CONDITIONS: "/manage/paidy/terms-and-conditions",
  ADDRESS_UPDATE_PAGE: "/manage/address-update",
  ADDRESS_UPDATE_CONFIRM_PAGE: "/manage/confirm-address-update",
  E_SIM_SET_UP: "/:channel/esim-setup",
  E_SIM_PROCESSING: "/:channel/esim-processing",
  E_SIM_SET_UP_BY_API: "/:channel/esim-setup-by-api",
  TERMS_AND_CONDITIONS: "/:channel/paidy-terms-and-conditions",
  DATA_ONLY_PAYMENT: "/:channel/topping-payment",
  CHANGE_CONTACT_NUMBER_ESIM: "/manage/change-contact-number",
  ESIM_NUMBER_CHANGE_OTP: "/manage/contact-number-change-otp",
  PORT_OUT_REQUEST_CONFIRM: "/manage/permission",
  WEBSDK_PLAYGROUND: "/test-websdk"
};

export const forms = {
  PRE_CHECKOUT_FORM: "PreCheckoutForm",
  PERSONAL_DETAILS_FORM: "PersonalDetailsForm",
  CONNECTION_DETAILS_FORM: "ConnectionDetailsForm",
  PORT_IN_FORM: "PortInForm", //Not in use need to visit back to check functionality
  PLAN_CHANGE_DETAILS_FORM: "PlanChangeDetailsForm",
  NEW_NUMBER_FORM: "NewNumberForm",
  DELIVERY_INFO_FORM: "DeliveryInfoForm",
  ORDER_SUMMARY_FORM: "OrderSummaryForm",
  SIM_VERIFICATION_FORM: "SimVerificationForm",
  IDENTITY_VERIFICATION_FORM: "IdentityVerificationForm", //Not in use need to visit back to check functionality
  // SELF_ACTIVATION_FORM: "SelfActivationForm",  //Not in use need to visit back to check functionality
  // UPLOAD_IDENTITY_FORM: "UploadIdentityForm",
  MANAGE_ORDER_FORM: "ManageOrderForm",
  OUTSTANDING_BILLS_FORM: "OutstandingBillsForm",
  USER_LOGIN_FORM: "UserLoginForm",
  USER_LOGIN_OTP_FORM: "UserLoginOTPForm",
  USER_DETAILS_CONFIRMATION_FORM: "UserDetailsConfirmationForm",
  PHONES_FORM: "PhonesForm",
  PAYMENT_FORM: "PaymentForm",
  REPORT_LOST_SIM_FORM: "ReportLostSimForm",
  REQUEST_PORTOUT_FORM: "RequestPortOutForm",
  REVERSE_PORTIN_FORM: "ReversePortInForm",
  ORDER_CANCELLATION_FORM: "OrderCancellationForm",
  PROFILE_FORM: "ProfileForm",
  OUTSTANDING_BILL_PAYMENT_FORM: "OutstandingBillPaymentForm",
  SUSPEND_NUMBER_FORM: "SuspendNumberForm",
  // WIRECARD_ONE_TIME_PAYMENT_FORM: "WireCardOneTimePaymentForm",    //Not in use need to visit back to check functionality
  CHANGE_NUMBER_FORM: "ChangeNumberForm",
  // AUTHORIZER_FORM: "AuthorizerForm", //Not in use need to visit back to check functionality
  SWITCH_TO_ESIM_FORM: "SwitchToEsimForm",
  TERMINATE_NUMBER_FORM: "TerminateNumberForm",
  // CIS_PARTNERS_FORM: "CisPartnersForm",    //Not in use need to visit back to check functionality
  ESIM_TRANSFER_OTP_FORM: "ESimTransferOTPForm",
  ONE_STOP_MNP_FALLBACK_PAGE: "OneStopMnpFallbackForm",
  ONE_STOP_MNP_BROWSER_INIT: "OneStopMnpBrowserInit",
  ONE_STOP_MNP_LANDING: "OneStopMnpLandingForm",
  CONFIRM_KYC_FOR_PLAN_CHANGE: "confirmKYCForPlanChange",
  CONTRACT_DETAILS_FORM: "ContractDetailsForm",
  CONFIRM_PIN_CHANGE_FORM: "ConfirmPINChangeForm",
  TOPPING_SELECTION_FORM: "ToppingSelectionForm",
  PAYMENT_ADD_EDIT_DELETE_FORM: "PaymentAddEditeDeleteForm",
  PAYMENT_METHOD_UPDATE_FORM: "PaymentMethodUpdate",
  ADDRESS_UPDATE_FORM: "AddressUpdateForm",
  TERMS_AND_CONDITIONS_FORM: "TermsAndConditions",
  DATA_ONLY_USER_REGISTRATION_FORM: "UserRegistrationForm",
  E_SIM_SETUP_FORM: "EsimSetupForm",
  CONTACT_NUMBER_CHANGE: "ContactNumberChange",
  TERMINATION_CONFRIMATION_FORM: "TerminationConfirmationForm"
};

export const DEFAULT_KEYS_TO_FETCH = ["appSettings", "locale"];

export const PENDING = "PENDING";
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const STATUS = "STATUS";

export const ACTION_URLS = {
  activate_sim_card: paths.ACTIVATE,
  initiate_portin: paths.INITIATE_PORT_IN,
  reinitiate_portin: paths.REINITIATE_PORT_IN,
  reschedule_port_in: paths.RESCHEDULE_PORT_IN,
  cancel_portin: paths.CANCEL_PORT_IN,
  payment_status: paths.PAYMENT_STATUS,
  payment_success: paths.PAYMENT_SUCCESS,
  payment_pending: paths.PAYMENT_PENDING,
  payment_failed: paths.PAYMENT_FAILED,
  reupload_identity: paths.UPLOAD_IDENTITY,
  report_lost_esim_card: paths.REPORT_LOST_SIM
};

export const PAYMENT_STATUS_URLS = {
  success: paths.PAYMENT_SUCCESS,
  pending: paths.PAYMENT_PENDING,
  failed: paths.PAYMENT_FAILED
};

export const OUTSTANDING_PAYMENT_STATUS_URLS = {
  [SUCCESS]: paths.OUTSTANDING_BILLS_PAYMENT_STATUS_SUCCESS,
  [PENDING]: paths.OUTSTANDING_BILLS_PAYMENT_STATUS_IN_PROGRESS,
  [FAILURE]: paths.OUTSTANDING_BILLS_PAYMENT_STATUS_FAILURE,
  [STATUS]: paths.OUTSTANDING_BILLS_PAYMENT_STATUS.replace(":status", "status")
};

export const WEBSITE_V2 = "website_v2";
export const SELF_ACT_DEL = "self_act_del";
export const SELF_ACTIVATION_SIM = "self_activation";
export const SIM_ACTIVATION = "sim_activation";
export const ESIM = "esim";
export const DATA_ONLY = "data_only";

export const ORDER_CHANNEL_MAPPING = {
  web: WEBSITE_V2,
  data_only: DATA_ONLY,
  "e-sim": ESIM
};

export const ROOT_CHANNEL = ":channel";
export const PHONE_ONLY_CHANNEL = "phoneonly";
export const COMBO_CHANNEL = "combo";
export const CIRCLES1_CHANNEL = "circles1";
export const CIRCLES2_CHANNEL = "circles2";
export const CIRCLES100_CHANNEL = "circles100";
export const FAMILY_PLAN = "familyplan";
export const CIRCLESLARPU = "circleslarpu";
export const WEB_CHANNEL = "web";
export const PARTNERS_CHANNEL = "partners";
export const ROADSHOW_CHANNEL = "roadshow";
export const PAYMENT_CHANNEL = "payment";

export const WEB_CHANNELS = [
  WEB_CHANNEL,
  "website",
  "website_v2",
  CIRCLES2_CHANNEL,
  CIRCLES100_CHANNEL,
  COMBO_CHANNEL,
  "data_only",
  "circles3",
  CIRCLES1_CHANNEL,
  FAMILY_PLAN,
  CIRCLESLARPU,
  PHONE_ONLY_CHANNEL,
  "liveon2",
  "liveon3",
  "liveon4",
  "liveon5",
  "liveon6",
  "liveon7",
  "cis",
  "e-sim",
  ROADSHOW_CHANNEL
];

export const SIM_ACTIVATION_CHANNELS = [
  "sim_activation",
  "sim_activation_id",
  "blackhawk",
  "self_activation",
  "e-sim"
];

export const AU_TO_POVO_CHANNEL = "au_to_povo";
export const DEFAULT_CHANNEL = "web";
export const DATA_ONLY_CHANNERL = "data_only";

export const WORKFLOW_CHANNELS = [
  ...WEB_CHANNELS,
  ...SIM_ACTIVATION_CHANNELS,
  AU_TO_POVO_CHANNEL,
  DATA_ONLY_CHANNERL
];

export const TELCO_BENEFIT_TYPES = {
  TELCO_DATA: "telco_data",
  TELCO_VOICE: "telco_voice",
  TELCO_SMS: "telco_sms",
  TELCO_ADDON: "telco_addon"
};

export const TELCO_WAIVER_TYPES = {
  TELCO_BILL_WAIVER: "telco_bill_waiver",
  TELCO_ADDON_WAIVER: "telco_addon_waiver"
};

export const TELCO_DISCOUNT_TYPES = {
  TELCO_REGISTRATION_DISCOUNT: "telco_registration_discount",
  TELCO_SUBSCRIPTION_DISCOUNT: "telco_subscription_discount"
};

export const ONE_TIME_DISCOUNT_TYPES = [
  TELCO_DISCOUNT_TYPES.TELCO_REGISTRATION_DISCOUNT,
  TELCO_DISCOUNT_TYPES.TELCO_SUBSCRIPTION_DISCOUNT
];

export const STANDALONE_WORKFLOW_PATHS = [
  "/web/activate",
  "/web/success",
  "/web/login",
  "/web/signup",
  "/web/confirm-email",
  "/web/verify-otp",
  "/web/user-details",
  "/web/owner-details",
  "/web/portin-details",
  "/web/delivery-details",
  "/web/contact-details",
  "/verify-identity/ekyc",
  "/verify-identity/order-summary",
  "/verify-identity/success",
  "/au_to_povo/login",
  "/au_to_povo/signup"
];

export const STANDALONE_WORKFLOW_ROUTES = [paths.PAYMENT_SUCCESS];

export const DEFAULT_TERMINATION_REASONS = [
  "termination_reason_different_number",
  "termination_reason_contract",
  "termination_reason_incorrect_details",
  "termination_reason_mind",
  "termination_reason_other"
];

// These paths are open to all visitors
export const publicPaths = {
  LOGIN_PAGE: "/:channel/login",
  MNP_PORTOUT_LOGIN_PAGE: "/:channel/weblogin",
  SIGNUP_PAGE: "/:channel/signup",
  CONFIRM_EMAIL: "/:channel/confirm-email",
  SELECT_EMAIL: "/:channel/select-email",
  VERIFY_OTP: "/:channel/verify-otp",
  LINE_AUTHENTICATION: "/line_authentication",
  YAHOO_AUTHENTICATION: "/yahoo_authentication",
  GOOGLE_AUTHENTICATION: "/google_authentication",
  FACEBOOK_AUTHENTICATION: "/facebook_authentication",
  APPLE_AUTHENTICATION: "/apple_authentication",
  AU_AUTHENTICATION: "/au_authentication",
  AU_IPDB_PIN_VERIFY: "/au_verfy_pin_code",
  ESIM_TRANSFER_OTHER_ORDER_INPROGRESS:
    "/other-order-in-progress/:time_stamp/:current_imsi/:new_device_imei",
  ESIM_TRANSFER_BLOCKED:
    "/simtransfer_blocked/:time_stamp/:current_imsi/:new_device_imei",
  ESIM_TRANSFER_BLOCKED_TOURIST_ESIM:
    "/simtransfer_blocked_inbound/:time_stamp/:current_imsi/:new_device_imei",
  ESIM_TRANSFER_OUT_OF_SERVICE:
    "/out-of-service/:time_stamp/:current_imsi/:new_device_imei",
  ESIM_TRANSFER_NO_POVO_CUSTOMER:
    "/no-povo-customer/:time_stamp/:current_imsi/:new_device_imei",
  ESIM_TRANSFER_TARGET_DEVICE_INVALID:
    "/target-device-invalid/:time_stamp/:current_imsi/:new_device_imei",
  ESIM_TRANSFER_PLAN_CHANGE:
    "/planchange/:time_stamp/:current_imsi/:new_device_imei",
  ESIM_TRANSFER_FALLBACK:
    "/fallback/:time_stamp/:current_imsi/:new_device_imei",
  ESIM_TRANSFER_UNEXPECTED_ERROR: "/unexpected-error",
  ESIM_TRANSFER_VERIFY_USER: "/esim_transfer/verify-user",
  ESIM_TRANSFER_USER_CONCENT: "/esim_transfer/user-concent",
  ESIM_TRANSFER_PLAN_CHANGE_CONSENT: "/esim_transfer/plan-change-consent",
  E_SIM_TRANSFER_TERMS_CONDITIONS: "/esim_transfer/term-and-condition",
  // this path is same as post data api. The device loads the path and inject the response (can be verified only in device)
  ESIM_TRANSFER_USER_CONCENT_WITH_POST_DATA:
    "/api/v1/:country/:locale/webfront/validate_sim_transfer",
  ESIM_TRANSFER_SUBMIT_STATUS: "/esim_transfer/submit-status",
  ONESTOP_MNP_LOGIN: "/onestop-mnp/weblogin",
  // 1stop MNP Sample routes - Tobe removed after dev testing
  ONESTOP_MNP_SOURCE_LOGIN: "/onestop-mnp/sample-weblogin",
  ONESTOP_MNP_WEB_LOGIN_ERROR: "/onestop-mnp/weblogin-error",
  ONESTOP_MNP_DESTINATION_PROVIDER_LANDING:
    "/onestop-mnp/continue-to-destination",
  ONESTOP_MNP_INITIATE_PORTOUT: "/onestop-mnp/initiate-portout",
  // end 1stop MNP Sample routes
  CONTRACT_DETAILS_PAGE: "/:channel/contract-details",
  USER_DETAILS_REGISTRATION: "/:channel/user-details-registration",
  DATA_ONLY_TOPPING_SELECTION: "/:channel/topping-selection"
};

export const AUTH_MODES = {
  ENHANCED_EMAIL_OTP: "ENHANCED_EMAIL_OTP"
};
// This is the default web view value send by the mobile application via query params
export const DEFAULT_WEB_VIEW_VALUE = "1";
export const tabTypes = {
  PILL: "PILL"
};

// constant to check whether payment action is a redirect or submit
export const POST_PAYMENT_REQUEST_ACTION_TYPES = {
  SUBMIT: "submit",
  REDIRECT: "redirect"
};

export const PAYMENT_METHODS = {
  AMAZON_PAY: "amazonPay",
  APPLE_PAY: "applePay",
  GOOGLE_PAY: "googlePay",
  PAIDY: "paidy",
  CREDIT_CARD: "card"
};

export const PAYMENT_GATEWAYS = {
  GMO: "gmo",
  PAIDY: "paidy"
};

export const PAYMENT_TYPE = {
  WALLET_PAYMENT: "wallet payment"
};

export const NODE_ENVIRONMENT = {
  DEVELOPMENT: "development",
  PRODUCTION: "production"
};

export const CALL_BACK_URL = {
  LINE: `${window.location.origin}${publicPaths.LINE_AUTHENTICATION}`,
  YAHOO: `${window.location.origin}${publicPaths.YAHOO_AUTHENTICATION}`,
  GOOGLE: `${window.location.origin}${publicPaths.GOOGLE_AUTHENTICATION}`,
  FACEBOOK: `${window.location.origin}${publicPaths.FACEBOOK_AUTHENTICATION}`,
  AMAZON_PAY: `${window.location.origin}${paths.PAYMENT_AMAZON}`,
  APPLE: `${window.location.origin}${publicPaths.APPLE_AUTHENTICATION}`,
  AU: `${window.location.origin}${publicPaths.AU_AUTHENTICATION}`
};

export const SIM_TYPES = {
  PHYSICAL_SIM: "PHYSICAL_SIM",
  E_SIM: "E_SIM",
  PHYSICALSIM: "physicalsim",
  ESIM: "esim"
};

export const CONTRACT_TYPES = {
  NEW: "NEW",
  PORT_IN: "PORT_IN"
};

export const NETWORK_TYPES = {
  SA: "SA",
  NSA: "NSA"
};

export const EXPRESS_DELIVERY = "Express delivery";

export const NUMBER_TYPES = {
  PORT_IN: "port_in",
  NEW_NUMBER: "new_number"
};

export const PHONE_PAYMENT_OPTIONS = {
  FULL: "full",
  IPP: "installment",
  SPLIT: "split"
};

export const OUTSTANDING_BILL_REFERRAL_FROM_TYPE = {
  MANAGE_ORDER: "MANAGE_ORDER",
  DIRECT_LINK: "DIRECT_LINK"
};

export const RESPONSE_STATUS = {
  FAILURE: "FAILURE",
  SUCCESS: "SUCCESS",
  SUBMITTED: "SUBMITTED"
};

export const SOCIAL_MEDIA_TYPES = {
  LINE: "LINE",
  YAHOOJP: "YAHOOJP",
  GOOGLE: "GOOGLE",
  FACEBOOK: "FACEBOOK",
  APPLE: "APPLE",
  TWITTER: "TWITTER",
  AU_LOGIN: "AU"
};

export const COUNTRIES = {
  JP: "jp"
};

export const ERROR_CODES = {
  missingResourceForSubmitKycRequest: 400119,
  planNotAllowed: 400042
};
export const FAILED_TO_HANDLE_OTP_REQUEST = 400015;

export const TERMINATE_ACTION_STATUS = {
  UNFINISHED: "UNFINISHED",
  FAIL: "FAIL"
};

export const PORTOUT_IN_PROGRESS = "IN_PROGRESS";
export const TERMINATION_PENDING_STATUSES = [
  "REGISTERED",
  "PENDING",
  "SUBMITTED",
  "IN_PROGRESS",
  "UNFINISHED"
];
export const PORTOUT_PENDING_STATUSES = [
  "ONGOING_QUEUED",
  "QUEUED",
  "ONGOING_PUBLISHED",
  "PUBLISHED",
  PORTOUT_IN_PROGRESS
];
export const PORTOUT_FAILED_STATUSES = ["FAILED"];
export const PORTOUT_COMPLETED_STATUSES = [
  "NETWORK_PORTOUT",
  "SCHEDULED_TERMINATION",
  "DONE"
];

export const ESIM_FAQ_URL =
  "https://circlesasiasupport.zendesk.com/hc/en-us/categories/900000106743-ESIM";

export const PLAN_SOURCE_COMBO = "SPLIT_COMBO_FLOW";
export const PLAN_SOURCE_PHONE_ONLY = "SPLIT_PHONE_ONLY";
export const PLAN_SOURCE_DEFAULT = "DEFAULT";
export const REPORT_LOST_SIM_REASON = "Customer initiated due to lost sim";

export const DELIVERY_METHOD_TYPE = {
  HOME: "home",
  POP_STATION: "pop_station",
  INSTANT_PICK_UP: "instant_pick_up"
};
//contains value to differentiate between our default page flow or a direct link
export const REFERER_TYPE = {
  DEFAULT: "DEFAULT",
  DIRECT_LINK: "DIRECT_LINK"
};

export const LOGIN_REDIRECT_CHANNEL_MAPPING = {
  [AU_TO_POVO_CHANNEL]: DEFAULT_CHANNEL
};

export const VERIFY_IDENTITY_CHANNEL = "verify-identity";

export const TRANSACTION_STATUS_TIMEOUT = 10;

export const STATUS_TYPE = {
  TRANSACTION: "transaction"
};

export const PAYMENT_TYPES = {
  ADYEN: "adyen",
  STRIPE: "stripe"
};

export const LOST_SIM = "LOST_SIM";

export const STD = "STD";

export const SPECIALPATHS = {
  "/web/login": "https://povo.jp/chat/?shop=web_login",
  "/web/signup": "https://povo.jp/chat/?shop=web_signup",
  "/web/connection-details":
    "https://povo.jp/chat/?shop=web_connection-details",
  "/web/pre-checkout": "https://povo.jp/chat/?shop=web_pre-checkout",
  "/web/contract-details": "https://povo.jp/chat/?shop=web_contract-details",
  "/web/verify-otp": "https://povo.jp/chat/?shop=web_verify-otp",
  "/web/order-summary": "https://povo.jp/chat/?shop=web_order-summary",
  "/web/payment": "https://povo.jp/chat/?shop=web_payment",
  "/web/payment/card": "https://povo.jp/chat/?shop=web_payment_card",
  "/web/paidy-terms-and-conditions":
    "https://povo.jp/chat/?shop=web_paidy-terms-and-conditions",
  "/web/payment-success": "https://povo.jp/chat/?shop=web_payment-success",
  "/manage/order": "https://povo.jp/chat/?shop=manage_order",
  "/web/user-details": "https://povo.jp/chat/?shop=web_user-details",
  "/web/owner-details": "https://povo.jp/chat/?shop=web_owner-details",
  "/manage/sim-activation-pending":
    "https://povo.jp/chat/?shop=manage_sim-activation-pending",
  "/manage/esim_setting": "https://povo.jp/chat/?shop=manage_esim_setting",
  "/manage/esim-qr": "https://povo.jp/chat/?shop=manage_esim_qr",
  "/web/delivery-details": "https://povo.jp/chat/?shop=web_delivery-details",
  "/manage/sim-activation": "https://povo.jp/chat/?shop=manage_sim-activation",
  "/web/portin-details": "https://povo.jp/chat/?shop=web_portin-details",
  "/au_to_povo/pre-checkout":
    "https://povo.jp/chat/?shop=au_to_povo_pre-checkout",
  "/au_to_povo/signup": "https://povo.jp/chat/?shop=au_to_povo_signup",
  "/web/select-email": "https://povo.jp/chat/?shop=web_select-email",
  "/web/au-user-details": "https://povo.jp/chat/?shop=web_au-user-details",
  "/profile": "https://povo.jp/chat/?shop=profile",
  "/data_only/topping-selection":
    "https://povo.jp/chat/?shop=data_only_topping-selection",
  "/data_only/user-details-registration":
    "https://povo.jp/chat/?shop=data_only_user-details-registration",
  "/data_only/payment/card":
    "https://povo.jp/chat/?shop=data_only_payment_card",
  "/data_only/paidy-terms-and-conditions":
    "https://povo.jp/chat/?shop=data_only_paidy-terms-and-conditions",
  "/data_only/esim-processing":
    "https://povo.jp/chat/?shop=data_only_esim-processing",
  "/data_only/esim-setup": "https://povo.jp/chat/?shop=data_only_esim-setup"
};

export const INFO_TIP_NAMECHANGE_URL =
  "https://povo.jp/chat/?shop=profile_namechange";

export const DOB_SEPARATOR = "-";
export const DOB_FORMAT = "DD-MM-YYYY";
export const SECURITY_DOB_FORMAT = "YYYY-MM-DD";
export const KYC_DOB_FORMAT = "YYYYMMDD";
export const JP_DOB_FORMAT = "YYYY年MM月DD日";

export const OUTSTANDING_PAYMENT_STATUS = {
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "OK"
};

export const OUTSTANDING_BILL_STATUS = {
  OK: "OK",
  INVALID_SYNC_OR_SEND_STATUS: "INVALID_SYNC_OR_SEND_STATUS",
  NO_OPEN_BILLS: "NO_OPEN_BILLS",
  IN_PROGRESS: "IN_PROGRESS"
};

export const UPDATE_TOKEN_TO_APP_URI = "webfront://updatetoken";

// http status error codes
export const CONFLICTING_ORDERS_STATUS = 409;
export const UNAUTHORIZED_HTTP_CODE = 401;
export const TOKEN_INVALID_HTTP_CODE = 403;
export const UNPROCESSABLE_ENTITY = 422;

//Recaptcha error codes
export const RECAPTURE_TOKEN_BROWSER_ERROR = 401015;

export const DUPLICATE_CONTACT_NUMBER_ERROR_CODES = [400141, 400145];
export const ORDER_CANCEL_REASON = "order cancel reason";

export const INTERVAL_TIME = 50;
//Time is define in minutes
export const LIQUID_EXPIRE_TIME = 20;

export const TELCO_API_ERRORS = {
  UNAUTHORISED: "ERROR_UNAUTHORISED",
  UNAUTHORISED_DOB: "ERROR_UNAUTHORISED_DOB"
};

export const EMAIL_OTP_MODAL = "EmailOtpModal";
export const CONTACT_NUM_OTP_MODAL = "ContactNUmOtpModal";

export const PAIDY_PAYMENT_ERROR_CODE = 12345678; // Replace the error code with the proper one once received from oms

export const MODAL_TYPE = {
  PLAN_NOT_ALLOWED: "planNotAllowed"
};
