import {
  COMBO_CHANNEL,
  WEB_CHANNEL,
  CIRCLES1_CHANNEL,
  CIRCLES2_CHANNEL,
  CIRCLESLARPU,
  CIRCLES100_CHANNEL,
  // SELF_ACTIVATION_SIM,
  ROOT_CHANNEL,
  PHONE_ONLY_CHANNEL,
  // FAMILY_PLAN,
  paths,
  forms
} from "utils/constants";
import { flattenObject, removeSensitiveData } from "utils/helpers";
import get from "lodash/get";

//After adding Analytics files in the folder under src/analytics/type then add it's constant here also
export const ANALYTICS_TYPES = {
  adobe: "AdobeAnalytics",
  cleverTap: "CleverTapAnalytics"
};

export const ANALYTICS_EVENT_TYPES = {
  TRACK_PAGE: "TRACK_PAGE",
  TRACK_EMAIL_SUBMITTED: "TRACK_EMAIL_SUBMITTED",
  UPDATE_TRANSACTION: "UPDATE_TRANSACTION",
  UPDATE_PAGE_DATA: "UPDATE_PAGE_DATA",
  PUSH_PROFILE: "PUSH_PROFILE",
  PUSH_EVENT: "PUSH_EVENT"
};

export const ANALYTICS_EVENT_STEPS = {
  START: "START",
  FINISH: "FINISH"
};

export const SATELLITE_EVENTS = {
  PURCHASE: "PURCHASE",
  CHARGED: "CHARGED",
  SADPURCHASE: "SADPURCHASE",
  ACTIVATE: "ACTIVATE",
  MYINFO: "MYINFO"
};

// country: { channel: { // put in your params to compare and exclude the flow } }
export const FLOW_EXCLUDE_MAPPING = {
  sg: {
    [CIRCLES1_CHANNEL]: {
      url: [paths.CIRCLES1_SAD] // ignore this url
    }
    // [SELF_ACT_DEL]: {
    //   plan_name: [PLAN_NAME_CIRCLES_ONE] // ignore this plan_name in data
    // }
  }
};

export const ADOBE_EVENTS_MAPPING = {
  planService_api_call_succeeded: {
    category: "PLAN",
    event: "prodView"
  },
  numbers_api_call_succeeded: {
    category: "Number",
    event: "number selection",
    object: "selected_number"
  },
  selectedAddons_requested: {
    true: {
      category: "AddonSelected",
      event: "addons selected",
      object: "addon"
    },
    false: {
      category: "AddonUnselected",
      event: "addons unselected",
      object: "addon"
    }
  },
  PersonalDetailsForm_submitted: {
    event: "personal details",
    transaction: true
  },
  PhonesForm_submitted: {
    event: "phone details",
    transaction: true
  },
  DeliveryInfoForm_submitted: {
    event: "delivery-info"
  },
  order_api_call_succeeded: {
    event: "order-summary",
    transaction: true
  },
  // payment v3, can be removed when payment is fully migrated
  paymentReturn_api_call_succeeded: {
    event: "purchase",
    trackEvent: SATELLITE_EVENTS.PURCHASE, // This will track purchase event through satellite
    transaction: false, // We should not set digitalData.transaction anymore
    object: "order_product_info"
  },
  // payment v4, new api call for payment
  paymentStatus_api_call_succeeded: {
    event: "purchase",
    trackEvent: SATELLITE_EVENTS.PURCHASE, // This will track purchase event through satellite
    transaction: false // We should not set digitalData.transaction anymore
  },
  selfActivationDeliveryOrder_api_call_succeeded: {
    event: "purchase",
    trackEvent: SATELLITE_EVENTS.SADPURCHASE,
    transaction: true
  },
  activateSimCard_api_call_succeeded: {
    event: "activate",
    trackEvent: SATELLITE_EVENTS.ACTIVATE,
    transaction: true
  },
  MyInfoEvent_requested: {
    event: "click",
    trackEvent: SATELLITE_EVENTS.MYINFO
  }
};

export const ADOBE_PROFILE_MAPPING = {
  email: {
    event: "Email Submitted",
    getData: formValues => {
      return {
        email_address: formValues.email,
        profile_source: "ecomm"
      };
    }
  },
  contact_number: {
    event: "ContactNo Submitted",
    getData: formValues => {
      return {
        contact_number: formValues.contact_number,
        profile_source: "ecomm"
      };
    }
  },
  delivery_hse_blk_tower: {
    event: "Delivery House Block Tower Submitted"
  }
};

export const CLEVERTAP_EVENTS = {
  CHARGED: "Charged",
  PROFILE: "Profile",
  SELECTED_PROMOTION: "selected_promotion",
  PROMOTION_STATUS: "promotion_status",
  SELECTED_NUMBER: "selected_number",
  SELECTED_NUMBER_PORT_IN: "selected_number_port_in"
};

export const ADJUST_EVENTS = {
  ACCOUNT_CREATED: "5geifp",
  PLAN_ORDERED: "x5yuiv",
  PLAN_ACTIVATED: "6gfa01",
  ESIM_PROCESSING: "jnevlh"
};

export const FACEBOOK_ACTION_TYPE = {
  track: "track"
};

export const FB_EVENTS_NAME = {
  INITIATE_CHECKOUT: "InitiateCheckout",
  ADD_PAYMENT_INFO: "AddPaymentInfo",
  PURCHASE: "Purchase",
  COMPLETE_REGISTRATION: "CompleteRegistration"
};

export const GTM_EVENTS_NAME = {
  ECOMM_EVENT: "ecomm_event",
  CUSTOM_PURCHASE: "custom_purchase"
};

export const FACEBOOK_VALID_COUNTRIES = {
  SG: "sg",
  AU: "au"
};

export const FACEBOOK_EVENTS_MAPPING = {
  // will break when v3 is migrated to v4, remove when we fully migrated payment v4
  page_visit: {
    valid_countries: {
      au: [
        {
          events: [FB_EVENTS_NAME.INITIATE_CHECKOUT],
          trigger_urls: [
            `${paths.PRE_CHECKOUT.replace(ROOT_CHANNEL, WEB_CHANNEL)}`,
            `${paths.PRE_CHECKOUT.replace(ROOT_CHANNEL, CIRCLES2_CHANNEL)}`,
            `${paths.PRE_CHECKOUT.replace(ROOT_CHANNEL, CIRCLES100_CHANNEL)}`
          ]
        },
        {
          events: [FB_EVENTS_NAME.ADD_PAYMENT_INFO],
          trigger_urls: [
            `${paths.ORDER_SUMMARY.replace(ROOT_CHANNEL, COMBO_CHANNEL)}`,
            `${paths.ORDER_SUMMARY.replace(ROOT_CHANNEL, WEB_CHANNEL)}`,
            `${paths.ORDER_SUMMARY.replace(ROOT_CHANNEL, CIRCLES2_CHANNEL)}`,
            `${paths.ORDER_SUMMARY.replace(ROOT_CHANNEL, CIRCLES100_CHANNEL)}`
          ]
        }
      ],
      sg: [
        {
          events: [FB_EVENTS_NAME.ADD_PAYMENT_INFO],
          trigger_urls: [`${paths.SELF_ACTIVATION_ORDER_SUMMARY}`]
        }
      ]
    }
  },
  // success_page_visit fires on all :channel/success pages
  success_page_visit: {
    valid_countries: {
      // sg: [
      //   {
      //     events: [FB_EVENTS_NAME.PURCHASE],
      //     trigger_urls: [
      //       `${paths.SUCCESS.replace(ROOT_CHANNEL, SELF_ACTIVATION_SIM)}`
      //     ]
      //   }
      // ]
    }
  },
  PersonalDetailsForm_submitted: {
    valid_countries: {
      au: [
        {
          events: [FB_EVENTS_NAME.COMPLETE_REGISTRATION],
          condition: (data, fbq, action, eventName) => {
            /**
             * facebookpixel event pushed only when second "next" button clicked
             * in /personal-details pages of
             * /:channel/pre-checkout flows
             */
            const eventData = removeSensitiveData(
              flattenData(get(data, "formValues", {}))
            );
            const delivery_slot_id = get(eventData, "delivery_slot_id");
            delivery_slot_id && fbq(action, eventName, data);
          },
          trigger_urls: [
            `${paths.ORDER_SUMMARY.replace(ROOT_CHANNEL, COMBO_CHANNEL)}`,
            `${paths.ORDER_SUMMARY.replace(ROOT_CHANNEL, WEB_CHANNEL)}`,
            `${paths.ORDER_SUMMARY.replace(ROOT_CHANNEL, CIRCLES2_CHANNEL)}`,
            `${paths.ORDER_SUMMARY.replace(ROOT_CHANNEL, CIRCLES100_CHANNEL)}`
          ]
        }
      ]
    }
  },
  // will break when v3 is migrated to v4, remove when we fully migrated payment v4
  paymentReturn_api_call_succeeded: {
    valid_countries: {
      au: [
        {
          events: [FB_EVENTS_NAME.PURCHASE],
          trigger_urls: [
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, COMBO_CHANNEL)}`,
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, WEB_CHANNEL)}`,
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, CIRCLES2_CHANNEL)}`,
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, CIRCLES100_CHANNEL)}`
          ]
        }
      ],
      sg: [
        {
          events: [FB_EVENTS_NAME.PURCHASE],
          trigger_urls: [
            // for ":channel/sa_delivery" ESIM flows
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, CIRCLES1_CHANNEL)}`,
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, CIRCLESLARPU)}`,
            // phoneonly flow
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, PHONE_ONLY_CHANNEL)}`
          ]
        }
      ]
    }
  },
  // new payment call for payment v4
  paymentStatus_api_call_succeeded: {
    valid_countries: {
      au: [
        {
          events: [FB_EVENTS_NAME.PURCHASE],
          trigger_urls: [
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, COMBO_CHANNEL)}`,
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, WEB_CHANNEL)}`,
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, CIRCLES2_CHANNEL)}`,
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, CIRCLES100_CHANNEL)}`
          ]
        }
      ]
    }
  }
};

export const GTM_DATALAYER_FACEBOOK_EVENT_TYPES = {
  CONVERSION: "facebook_conversion_event",
  ACTIVATION: "fb_activation_event"
};

export const GTM_DATALAYER_EVENT_MAPPING = {
  // will break when v3 is migrated to v4, remove when we fully migrated payment v4
  paymentReturn_api_call_succeeded: {
    valid_countries: {
      au: [
        {
          trigger_urls: [
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, WEB_CHANNEL)}`,
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, CIRCLES2_CHANNEL)}`,
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, CIRCLES100_CHANNEL)}`
          ],
          event: GTM_EVENTS_NAME.ECOMM_EVENT
        }
      ],
      jp: [
        {
          trigger_urls: [
            `${paths.PAYMENT_STATUS.replace(ROOT_CHANNEL, WEB_CHANNEL)}`,
            `${paths.PAYMENT_PENDING.replace(ROOT_CHANNEL, WEB_CHANNEL)}`
          ],
          event: GTM_EVENTS_NAME.CUSTOM_PURCHASE
        }
      ]
    }
  },
  // new payment call for payment v4
  paymentStatus_api_call_succeeded: {
    valid_countries: {
      au: [
        {
          trigger_urls: [
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, WEB_CHANNEL)}`,
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, CIRCLES2_CHANNEL)}`,
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, CIRCLES100_CHANNEL)}`
          ]
        }
      ]
    }
  }
};

export const GTM_DATALAYER_FACEBOOK_EVENT_MAPPING = {
  // will break when v3 is migrated to v4, remove when we fully migrated payment v4
  paymentReturn_api_call_succeeded: {
    valid_countries: {
      sg: [
        {
          formType: forms.DELIVERY_INFO_FORM,
          dataLayerEventType: GTM_DATALAYER_FACEBOOK_EVENT_TYPES.CONVERSION,
          trigger_urls: [
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, PHONE_ONLY_CHANNEL)}`
          ]
        },
        {
          // for ":channel/sa_delivery" ESIM flows
          formType: forms.PERSONAL_DETAILS_FORM,
          dataLayerEventType: GTM_DATALAYER_FACEBOOK_EVENT_TYPES.CONVERSION,
          trigger_urls: [
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, CIRCLES1_CHANNEL)}`,
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, CIRCLESLARPU)}`
          ]
        }
      ]
    }
  },
  // new payment call for payment v4
  paymentStatus_api_call_succeeded: {
    valid_countries: {
      sg: [
        {
          formType: forms.DELIVERY_INFO_FORM,
          dataLayerEventType: GTM_DATALAYER_FACEBOOK_EVENT_TYPES.CONVERSION,
          trigger_urls: [
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, PHONE_ONLY_CHANNEL)}`
          ]
        },
        {
          // for ":channel/sa_delivery" ESIM flows
          formType: forms.PERSONAL_DETAILS_FORM,
          dataLayerEventType: GTM_DATALAYER_FACEBOOK_EVENT_TYPES.CONVERSION,
          trigger_urls: [
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, CIRCLES1_CHANNEL)}`,
            `${paths.PAYMENT_RETURN.replace(ROOT_CHANNEL, CIRCLESLARPU)}`
          ]
        }
      ]
    }
  },
  // success_page_visit fires on all :channel/success pages
  success_page_visit: {
    valid_countries: {
      // sg: [
      //   {
      //     // for "self_activation/sim" flow
      //     formType: forms.PERSONAL_DETAILS_FORM,
      //     dataLayerEventType: GTM_DATALAYER_FACEBOOK_EVENT_TYPES.ACTIVATION,
      //     trigger_urls: [
      //       `${paths.SUCCESS.replace(ROOT_CHANNEL, SELF_ACTIVATION_SIM)}`
      //     ]
      //   }
      // ]
    }
  },
  // this API only fires on the page BEFORE order success.
  // therefore the paths here are NOT paths.SUCCESS
  selfActivationDeliveryOrder_api_call_succeeded: {
    valid_countries: {
      // sg: [
      //   {
      //     // for ":channel/sa_delivery" physical SIM flows
      //     formType: forms.SELF_ACTIVATION_FORM,
      //     dataLayerEventType: GTM_DATALAYER_FACEBOOK_EVENT_TYPES.CONVERSION,
      //     trigger_urls: [
      //       `${paths.SELF_ACTIVATION_SIM_DELIVERY.replace(
      //         ROOT_CHANNEL,
      //         FAMILY_PLAN
      //       )}`,
      //       `${paths.SELF_ACTIVATION_SIM_DELIVERY.replace(
      //         ROOT_CHANNEL,
      //         CIRCLES1_CHANNEL
      //       )}`,
      //       `${paths.SELF_ACTIVATION_SIM_DELIVERY.replace(
      //         ROOT_CHANNEL,
      //         CIRCLESLARPU
      //       )}`
      //     ]
      //   }
      // ]
    }
  }
};

const flattenData = data => flattenObject(data, "", "->");
