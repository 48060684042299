import React from "react";
import isEmpty from "lodash/isEmpty";
import { P } from "ui-components";
import FormattedMessage from "components/FormattedMessage";

const PARAGRAPH = ({
  content: id,
  styles = {},
  data = {},
  onEnter,
  ...props
}) => {
  const { values: _values } = data;
  const values = {};

  if (!isEmpty(_values)) {
    _values.forEach(value => {
      values[value] = props[value];
    });
  }

  return (
    <P sx={styles} {...props}>
      <FormattedMessage id={id} values={values} html />
    </P>
  );
};

export default PARAGRAPH;
