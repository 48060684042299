import React from "react";
import { Box, Flex, Text } from "ui-components";
import FormattedMessage from "components/FormattedMessage";

const HR_WITH_TEXT = ({ label, styles = {} }) => {
  return (
    <Flex mt={24} sx={styles}>
      <Box width={1 / 2} mr={12} my="auto">
        <hr />
      </Box>
      <FormattedMessage id={label}>
        {text => <Text style={{ minWidth: "max-content" }}>{text}</Text>}
      </FormattedMessage>
      <Box width={1 / 2} ml={12} my="auto">
        <hr />
      </Box>
    </Flex>
  );
};

export default HR_WITH_TEXT;
