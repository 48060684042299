import React from "react";
import get from "lodash/get";
import { Text } from "ui-components";
import { COLORS } from "ui-components/theme";
import FormattedMessage from "components/FormattedMessage";
import { Field } from "redux-form";

const ADDRESS_ERROR_HANDLER = ({ change, formValues, type = "delivery" }) => {
  const field = `${type}_location_error`;
  const id = get(formValues, field) || "";
  return !id ? null : (
    <Field
      name={field}
      change={change}
      msg={id}
      component={({ change, msg }) => (
        <Text
          mt={[-4]}
          mb={4}
          size="xs"
          color={COLORS.red[1]}
          sx={{ cursor: "pointer", width: "fit-content" }}
          onClick={() => change(`${type}_show_all_address_fields`, true)}
        >
          <FormattedMessage id={msg} />
        </Text>
      )}
    />
  );
};

export default ADDRESS_ERROR_HANDLER;
