import React, { memo, useEffect, useState } from "react";
import { compose } from "redux";
import { connect, useDispatch } from "react-redux";
import { reduxForm } from "redux-form";
import { createStructuredSelector } from "reselect";
import {
  makeSelectConnectionDetailsForm,
  makeSelectEligiblePlans,
  makeSelectIsPortingUser,
  makeSelectPageValidations,
  makeSelectPlanDetails,
  makeSelectUserLineNumbers,
  makeSelectIsFetchingData,
  makeSelectIsPortingUserWithPortingNumber,
  makeSelectPortinExpiryDateOptions,
  makeSelectNoActiveAUNumbersSelector,
  makeSelectSocialMediaOptions,
  makeSelectExternalUserId,
  makeSelectContactDetailsSimType,
  makeSelectGA4PlanDetails,
  makeSelectEligiblePlanRequestChannel
} from "ducks/selectors";
import SmartRender from "containers/SmartRender";
import validate from "validations";
import {
  forms,
  CONTRACT_TYPES,
  DEFAULT_CHANNEL,
  AU_TO_POVO_CHANNEL
} from "utils/constants";
import { selectPlan, UserService } from "ducks/actions";
import Spinner, { styles } from "components/Spinner";
import isFunction from "lodash/isFunction";
import isEmpty from "lodash/isEmpty";
import head from "lodash/head";
import {
  portinProviders,
  setNewRelicAttribute,
  gotoUrl,
  isWebView,
  maintenanceOnError
} from "utils/helpers";
import { OMS } from "ducks/actions";
import { getDeviceId } from "utils/localStorage";
import {
  APP_BRIDGE_TAG,
  DEVICE_ESIM,
  NativeAppFunctions
} from "utils/app-bridge/NativeAppDetails";
import { featureFlag5GSAEnabled } from "utils/featureFlags";
import GA4EventsService, { GA4EVENTS } from "analytics/GA4EventsService";
import get from "lodash/get";

const ConnectionDetailsHOC = Component =>
  function Comp(props) {
    const {
      isPortingUser,
      appSettings,
      isFetchingMNOKycData,
      isPortingUserWithPortingNumber,
      fetchEligiblePlans,
      isFetchingEligiblePlans,
      change,
      match,
      eligiblePlans,
      contractDetailsFormSimType,
      portInNumbers,
      formValues,
      storedEligiblePlanChannel
    } = props;

    const dispatch = useDispatch();
    const { channel } = get(match, "params", {});
    const selectedPortinNumber = formValues?.port_number?.value;

    const [deviceESimStatus, setDeviceESimStatus] = useState(
      DEVICE_ESIM.CANNOT_IDENTIFY
    );
    const [isDetectingESimStatus, setIsDetectingESimStatus] = useState(true);

    useEffect(
      () => {
        if (
          (![AU_TO_POVO_CHANNEL, DEFAULT_CHANNEL].includes(
            storedEligiblePlanChannel
          ) ||
            isEmpty(eligiblePlans)) &&
          !isFetchingEligiblePlans
        ) {
          if (channel === "sample-redirect") return;
          if (channel) {
            const planParams = { channel };
            fetchEligiblePlans(planParams);
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [eligiblePlans, storedEligiblePlanChannel]
    );

    useEffect(
      () => {
        if (!isEmpty(eligiblePlans)) {
          const selectedPlan = head(eligiblePlans);
          dispatch(selectPlan(selectedPlan));
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [eligiblePlans]
    );

    useEffect(() => {
      if (isPortingUser) {
        const providers = portinProviders();
        const kycTypes = providers.map(provider => `${provider}_PORTIN`);

        const params = {
          kyc_provider: providers?.join(",") || "",
          kyc_type: kycTypes?.join(",") || ""
        };

        dispatch(
          UserService.V4.fetchUserKycDetails(params, (result = {}) => {
            setNewRelicAttribute(
              "mno_kyc_first_name",
              result?.first_name,
              "rsa"
            );
            setNewRelicAttribute("mno_kyc_last_name", result?.last_name, "rsa");
            setNewRelicAttribute("mno_kyc_birthday", result?.birthday, "rsa");
          })
        );
      }
    }, [dispatch, isPortingUser, appSettings]);

    useEffect(() => {
      if (isPortingUserWithPortingNumber && isFunction(change)) {
        change("number_type", CONTRACT_TYPES.PORT_IN);
        contractDetailsFormSimType &&
          change("sim_type", contractDetailsFormSimType);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPortingUserWithPortingNumber]);

    useEffect(() => {
      //TODO after feature deployed remove if condition
      if (!featureFlag5GSAEnabled()) return;
      if (isWebView()) {
        NativeAppFunctions.getIsESimCompatible()
          .then(eSIMStatus => {
            console.debug(APP_BRIDGE_TAG, eSIMStatus);
            setDeviceESimStatus(eSIMStatus);
            setIsDetectingESimStatus(false);
          })
          .catch(error => {
            setDeviceESimStatus(DEVICE_ESIM.CANNOT_IDENTIFY);
            setIsDetectingESimStatus(false);
            console.debug(APP_BRIDGE_TAG, error);
          });
      } else {
        setDeviceESimStatus(DEVICE_ESIM.CANNOT_IDENTIFY);
        setIsDetectingESimStatus(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (portInNumbers.length > 0 && selectedPortinNumber) {
        GA4EventsService.publish(
          transferPhoneNumEventData(props.GA4EventPlanDetails)
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPortinNumber]);

    // useEffect(() => {
    //   if (
    //     window.location.pathname ===
    //     pathWithChannel(paths.CONNECTION_DETAILS, "sample-redirect")
    //   ) {
    //     GA4EventsService.publish(
    //       orderCancelEventData(props.GA4EventPlanDetails)
    //     );
    //   }
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    //TODO after feature deployed remove if else block and uncomment comment code block
    if (featureFlag5GSAEnabled()) {
      return (isPortingUser && isFetchingMNOKycData) ||
        isDetectingESimStatus ? (
        <Spinner size={200} sx={styles.fullPage} />
      ) : (
        <Component
          {...props}
          eSimCompatibilityStatus={deviceESimStatus}
          toggleSwitchRefresh={isDetectingESimStatus}
        />
      );
    } else {
      return isPortingUser && isFetchingMNOKycData ? (
        <Spinner size={200} sx={styles.fullPage} />
      ) : (
        <Component {...props} />
      );
    }

    /* return (isPortingUser && isFetchingMNOKycData) || isDetectingESimStatus ? (
          <Spinner size={200} sx={styles.fullPage} />
      ) : (
          <Component
              {...props}
              eSimCompatibilityStatus={deviceESimStatus}
              toggleSwitchRefresh={isDetectingESimStatus}
          />
      );*/
  };

const mapStateToProps = createStructuredSelector({
  formValues: makeSelectConnectionDetailsForm(),
  contractDetailsFormSimType: makeSelectContactDetailsSimType(),
  portInNumbers: makeSelectUserLineNumbers(),
  noActiveAUNumbers: makeSelectNoActiveAUNumbersSelector(),
  isFetchingEligiblePlans: makeSelectIsFetchingData("eligiblePlans"),
  initialValues: _ => {
    return {
      switch_active_line: "true",
      have_mnp_reservation: "false"
    };
  },
  formValidations: makeSelectPageValidations(forms.CONNECTION_DETAILS_FORM),
  isPortingUser: makeSelectIsPortingUser(),
  isPortingUserWithPortingNumber: makeSelectIsPortingUserWithPortingNumber(),
  isFetchingMNOKycData: makeSelectIsFetchingData("userKycDetails"),
  portinExpiryDateOptions: makeSelectPortinExpiryDateOptions(),
  eligiblePlans: makeSelectEligiblePlans(),
  planDetails: makeSelectPlanDetails(),
  socialMediaOptions: makeSelectSocialMediaOptions(),
  external_id: makeSelectExternalUserId(),
  GA4EventPlanDetails: makeSelectGA4PlanDetails(),
  storedEligiblePlanChannel: makeSelectEligiblePlanRequestChannel()
});

export function mapDispatchToProps(dispatch) {
  return {
    fetchEligiblePlans: params => {
      dispatch(OMS.V4.fetchEligiblePlans(params, maintenanceOnError));
    },
    logoutUser: (stateProps, callBack) => {
      const headers = {};
      const params = {
        device_id: getDeviceId(),
        user_id: stateProps.external_id
      };
      dispatch(UserService.V4.userLogout(headers, params, callBack));
    },
    logoutAUUserAndRedirect: (targeturl, auLogoutUrl) => {
      const urlParams = {
        targeturl: targeturl
      };
      if (auLogoutUrl) {
        //redirect user to AU logout Page
        gotoUrl(auLogoutUrl, false, {}, true, urlParams);
      }
    }
  };
}

export const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  logoutUserWithNoActiveLine: (navigate, event, data = {}) => {
    const { auLogoutUrl } = stateProps.socialMediaOptions["AU_IPDB"] || {};
    const targeturl = `${window.location.origin}${data.next?.path}`;
    dispatchProps.logoutUser(stateProps, () => {
      dispatchProps.logoutAUUserAndRedirect(targeturl, auLogoutUrl);
    });
  }
});

const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
export default compose(
  withConnect,
  reduxForm({
    form: forms.CONNECTION_DETAILS_FORM,
    destroyOnUnmount: false,
    validate
  }),
  memo
)(ConnectionDetailsHOC(SmartRender));

const transferPhoneNumEventData = (details = {}) => {
  const {
    customer_id,
    plan_name,
    plan_type,
    sim_type,
    order_ref,
    journey_type,
    number_type
  } = details;

  return {
    event: GA4EVENTS.TRANSFER_PHONE_NUMBER_SUBMITTED,
    ecommerce: {
      plan_name,
      customer_id,
      journey_type,
      plan_type,
      order_ref,
      sim_type,
      number_type
    }
  };
};

// const orderCancelEventData = (details = {}) => {
//   const {
//     customer_id,
//     plan_name,
//     plan_type,
//     sim_type,
//     order_ref,
//     contract_type,
//     journey_type,
//     number_type
//   } = details;

//   return {
//     event: GA4EVENTS.CANCEL_CLICK,
//     ecommerce: {
//       customer_id,
//       order_ref,
//       plan_name,
//       journey_type,
//       contract_type,
//       plan_type,
//       sim_type,
//       number_type
//     }
//   };
// };
