import React from "react";
import map from "lodash/map";
import range from "lodash/range";
import { Box, Flex, Text } from "ui-components";
import styles from "./styles";
import FormattedMessage from "../FormattedMessage";

const StepperBreadCrumb = ({
  step,
  total_steps,
  activeColor,
  inactiveColor,
  stepLable
}) => {
  const active = activeColor;
  const inactive = inactiveColor;
  const containerStyle = { ...styles.containerStyle };
  return (
    <Box sx={containerStyle}>
      <Flex sx={styles.stepWrapperStyle}>
        {map(range(total_steps), n => {
          let circlesStyle = {
            ...styles.baseCircleStyle,
            backgroundColor: inactive
          };
          let isNumberActive = {};
          if (n < step) {
            circlesStyle = {
              ...circlesStyle,
              ...styles.activeCircleStyle,
              backgroundColor: n + 1 === step ? active : inactive,
              fontWeight: n + 1 === step ? "bold" : "none"
            };
            isNumberActive = {
              backgroundColor: "black",
              borderColor: "black"
            };
          }

          return (
            <Box
              data-testid="step"
              key={n}
              sx={circlesStyle}
              style={{ width: "auto", fontSize: "12px", padding: "6px" }}
            >
              <Text sx={styles.baseCircleTextStyle} style={isNumberActive}>
                {n + 1}
              </Text>
              {stepLable && stepLable[n] && (
                <Text style={{ padding: "5px" }}>
                  <FormattedMessage id={stepLable[n]} html></FormattedMessage>
                </Text>
              )}
            </Box>
          );
        })}
      </Flex>

      <Box width={1} sx={{ ...styles.barStyle, borderColor: "black" }}></Box>
    </Box>
  );
};

StepperBreadCrumb.defaultProps = {
  step: 0,
  total_steps: 0
};

export default StepperBreadCrumb;
