import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserOrders } from "ducks/actions";
import { makeSelectGuestOrder } from "ducks/selectors";
import { paths, SIM_ACTIVATION_STATUSES } from "utils/constants";
import { gotoUrl, pathWithChannel } from "utils/helpers";
import Spinner, { styles } from "components/Spinner";

const SimActivationStatus = () => {
  const dispatch = useDispatch();
  const order = useSelector(makeSelectGuestOrder());

  const getUserOrders = () => {
    dispatch(fetchUserOrders({}));
  };

  useEffect(() => {
    getUserOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (order === null) return;

    if (order.state === SIM_ACTIVATION_STATUSES.SUCCESS)
      return gotoUrl(paths.MANAGE_ORDER);
    else if (order.state === SIM_ACTIVATION_STATUSES.CANCELLED)
      return console.error("Sorry, your sim activation got cancelled");
    else {
      // if the order state is not FD or CAN send user to the activation pending page
      return gotoUrl(pathWithChannel(paths.SIM_ACTIVATION_PENDING));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  return <Spinner sx={styles.modalContainer} />;
};

export default SimActivationStatus;
