import React, { useState } from "react";
import ReactModal from "react-modal";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { Box, Flex, H1, Button } from "ui-components";
import FormattedMessage from "components/FormattedMessage";
import PageNavigation from "components/PageNavigation";

const REQUEST_TERMINATION_MODAL = props => {
  const [showModal, setShowModal] = useState(props.showModal);

  const {
    styles,
    handleSubmit,
    submitTermination,
    submitWinback,
    dismissTermination,
    orderId,
    orderRef,
    actionType,
    orderDetails: { terminationPhoneNumber },
    termination: {
      terminationFeedback,
      terminationReason,
      terminationDate,
      winbackId,
      winbackCode,
      winbackOffer,
      selectedWinback
    },
    data
  } = props;

  const title = get(data, "title", "Terminate Number");
  const submitLabel = get(data, "submit.label", "Submit");
  const cancelLabel = get(data, "cancel.label", "Cancel");
  const winbackEnabled =
    get(data, "winback.enable", false) && !isEmpty(selectedWinback);
  const winbackMessage = winbackEnabled && get(data, "winback.message", "");
  const winbackBanner = winbackEnabled && get(data, "winback.banner", "");
  const successPath = get(data, "submit.path", "");
  const winbackPath = get(data, "winback.path", "");
  const dismissPath = get(data, "dismiss.path", "");

  const submit = () => {
    submitTermination({
      orderRef,
      actionType,
      terminationFeedback,
      terminationReason,
      terminationDate,
      successPath
    });
  };

  const cancel = () => {
    winbackEnabled && submitWinback && winbackId
      ? submitWinback({
          orderId,
          orderRef,
          winbackId,
          winbackCode,
          winbackOffer,
          terminationPhoneNumber,
          terminationFeedback,
          terminationReason,
          terminationDate,
          winbackPath
        })
      : (() => {
          setShowModal(false);
          dismissTermination(dismissPath);
        })();
  };

  const onNextClick = (() => {
    // check if winback is eligible
    if (!(winbackEnabled && submitWinback && winbackId)) {
      return handleSubmit(() => submit());
    }

    return handleSubmit
      ? handleSubmit(() => setShowModal(true))
      : setShowModal(true);
  })();

  return (
    <>
      <PageNavigation onNextClick={onNextClick} {...data} />
      <ReactModal style={styles.modalStyle} isOpen={showModal}>
        <Box mb={8}>
          <FormattedMessage id={title}>
            {msg => <H1>{msg}</H1>}
          </FormattedMessage>
        </Box>

        {winbackBanner && (
          <>
            <Flex justifyContent="space-evenly">
              <img
                style={{ width: "auto", height: "300px" }}
                src={winbackBanner}
                alt="winback-banner"
              />
            </Flex>
            <br />
          </>
        )}

        {winbackMessage && (
          <>
            <Box width={9 / 10} mx="auto">
              <FormattedMessage
                id={winbackMessage}
                values={{ winbackOffer }}
                html
              />
            </Box>
            <br />
          </>
        )}

        <Flex justifyContent="space-evenly">
          <Button onClick={cancel} variant="outline">
            <FormattedMessage id={cancelLabel} />
          </Button>
          <Button onClick={submit}>
            <FormattedMessage id={submitLabel} />
          </Button>
        </Flex>
      </ReactModal>
    </>
  );
};

export default REQUEST_TERMINATION_MODAL;
